<template>
    <!-- 인쇄용 2줄 -->
    <div v-if="web_view !== true">
        <div class="d-lg-flex justify-content-between">
            <section v-for="topic_type in report_topic_types"
                :key="topic_type.id"
                class="content-section"
                style="min-width: 49%"
                >
                <!-- Universal Disclosures -->
                <div v-if="topic_type.topic_type_e === 'Universal Disclosures'">
                    <div v-for="category in report_universal_categories"
                        :key="category.category_id"
                        >
                        <div class="pb-1 my-2" :style="main_color">
                            GRI - {{ category.category_id }} {{lang !== 'ENG' ? '일반 공개사항' : 'General Disclosures' }}
                        </div>
                        <div v-for="subtopic, index in getUniqueSubtopicsByCategoryIds(category.category_id)"
                            :key="subtopic.subtopic_code"
                            class="pb-1 border-bottom"
                            >
                            {{ index + 1 }}. {{ lang !== 'ENG' ? subtopic.subtopic_name : subtopic.subtopic_name_e }}
                        </div>
                        <hr class="border-0">
                    </div>
                </div>
                <!-- topic Specific Disclosures -->
                <div v-else> 
                    <div v-for="category in report_topic_specific_categories"
                        :key="category.category_id"
                        > 
                        <div class="pb-1 my-2" :style="main_color">
                            GRI {{ lang !== 'ENG' ? category.category_name : category.category_name_e }}
                        </div>
                        <div v-for="topic in getUniqueTopicsByCategoryIds(category.category_id)"
                            :key="topic.id"
                            class="pb-1 border-bottom"
                            >
                            {{ topic.topic_id }} : {{ lang == 'ENG' ? topic.topic_name_e : topic.topic_name }}
                            <materiality-badge :topic_id="topic.topic_id" />
                        </div>
                        <hr class="border-0">
                    </div>
                </div>
            </section>
        </div>
        <div>
            <b-badge variant="advance" class="ml-1"> M </b-badge> <span class="">: {{ lang!='ENG'? '중요주제 (Material Topic)':'Material Topic'}}</span>
        </div>
    </div>
    
    <!-- 웹용 4줄 -->
    <b-container v-else fluid class="d-flex flex-column flex-grow-1">
        <b-row>
            <b-col v-for="category, category_index in report_total_categories"
                :key="`category-${category.id}-${category_index}-title`"
                :class="['d-flex flex-column align-items-center', { 'border-right': (category_index !== report_total_categories.length - 1) } ,'contents-full-table']"
                >

                <div class="d-flex justify-content-between align-items-center w-100 mb-3 flex-grow-1">
                    <h5 class="mb-0 cursor" @click="navAnchor('#rp' + category.category_id)">
                        <span class="mr-2">{{ `GRI - ${category.category_id}` }}:</span>
                        <span>{{ lang !== 'ENG' ? category.category_name : category.category_name_e }}</span>
                    </h5>

                    <div class="contents-full-table-link cursor" :style="[{ color: main_bg_color }]">
                        <fa icon="arrow-down" class="mb-1"></fa>
                    </div>
                </div>

                <div class="w-100 mb-3" :style="[{ backgroundColor: main_bg_color }, { height: '5px' }]" />
            </b-col>
        </b-row>
        <b-row class="report-table-contents-list flex-grow-1">
            <b-col v-for="category, category_index in report_total_categories"
                :key="`category-${category.id}-${category_index}-topics`"
                :class="['d-flex flex-column align-items-center',  { 'border-right': category_index !== (report_total_categories.length - 1)} , 'contents-full-table']"
                >
                <template v-if="category.type === 'universal'">
                    <div v-for="subtopic, index in category.subtopics"
                        :key="subtopic.subtopic_id"
                        class="p-1 f-95 text-left w-100"
                        :class="[{'cursor hover-text-dark hover-transparent-dark': preview_mode == true}]" 
                        @click="navAnchor('#rp'+subtopic.subtopic_code)"
                        >
                        {{ index + 1 }}. {{ lang !== 'ENG' ? subtopic.subtopic_name : subtopic.subtopic_name_e }}
                    </div>

                </template>
                <template v-else>
                    <div v-for="topic in category.subtopics"
                        :key="topic.id"
                        class="p-1 f-95 text-left w-100"
                        :class="[{ 'cursor hover-text-dark hover-transparent-dark' : preview_mode === true }]" 
                        @click="navAnchor('#trp'+topic.topic_id)"
                        >
                        {{ topic.topic_id }} : {{ lang !== 'ENG' ? topic.topic_name : topic.topic_name_e }}
                        <materiality-badge :topic_id="topic.topic_id"></materiality-badge>
                    </div>
                </template>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import * as ih from '@/components/util'
import MaterialityBadge from './MaterialityBadge'

export default {
    name: 'ContentsListNoImage',
    components:{
        MaterialityBadge,
    },
    data() {
        return {
            show_data_table: {},
            show_business_case: {},
            low_height: false,
        }
    },    
    props:{
        web_view: {type: Boolean, default: false},
        preview_mode: {type: Boolean, default: false},
        content_list_open: {type:Boolean, default: false }
    },
    computed:{
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapState('gri2021_report_view', ['report_structure', 'report_contents_loaded']),
        ...mapGetters('gri2021_report_view', ['report_topics', 'report_categories', 'report_topic_specific_categories', 'report_universal_categories', 'report_topic_types']),
        ...mapGetters('report_list', ['selected_report']),
        main_color(){
            return `color:`+this.selected_report.main_color
        },
        main_color_sub(){
            return `color:`+this.selected_report.main_color+`; border-top: 1px solid `+this.selected_report.main_color+`!important`+`; border-bottom: 1px solid `+this.selected_report.main_color
        },
        main_bg_color(){
            if (this.selected_report) {
                return this.selected_report.main_color
            } else {
                return `#494949`
            }
        },
        report_total_categories () {
            const _report_universal_categories = this.report_universal_categories.map(_category => ({ type: 'universal', ..._category, subtopics: this.getUniqueSubtopicsByCategoryIds(_category.category_id) }))
            const _report_topic_specific_categories = this.report_topic_specific_categories.map(_category => ({ type: 'special', ..._category, subtopics: this.getUniqueTopicsByCategoryIds(_category.category_id) }))

            return [
                ..._report_universal_categories,
                ..._report_topic_specific_categories
            ]
        }
    },
    methods: {
        ...mapActions('gri2021_report_view', ['readReportContents']),
        getUniqueSubtopicsByCategoryIds(category_id){
        //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id === category_id )       
            let unique_subtopics = []
            filtered_structure.forEach( item => {
                if ( unique_subtopics.findIndex( x=> { return x.subtopic_code === item.subtopic_code }) < 0) {
                    unique_subtopics.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e, 
                        subtopic_code: item.subtopic_code, subtopic_name: item.subtopic_name, subtopic_name_e: item.subtopic_name_e })
                }
            })
            return unique_subtopics            
        },
        getUniqueTopicsByCategoryIds(category_id){
        //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id === category_id )       
            let unique_topics = []
            filtered_structure.forEach( item => {
                if ( unique_topics.findIndex( x=> { return x.topic_id === item.topic_id }) < 0) {
                    unique_topics.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e, 
                        topic_id: item.topic_id, topic_name: item.topic_name, topic_name_e: item.topic_name_e })
                }
            })
            return unique_topics
        },
        navAnchor(anchor){
            ih.anchorGo(anchor)
        },
    },
    created(){

    }
}
</script>

<style lang="scss">

.contents-full-table {
    &-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        padding-top: 5PX;
    }
}


.btn.custom-btn-hover{
    transition: all 0.15s ease;
    border: 0;
    width: 90%;
}

.custom-btn-hover:hover{
    opacity: 0.8;
    width: 96%;
}

.content-section-compact {
    min-width: 24%;
    width: 33%;
}

.content-section-compact-sub {
    min-width: 33%;
    width: 33%;
}

@media (max-width: 992px) {
    .btn.custom-btn-hover{
        width: 100%;
    }

    .content-section-compact,
    .content-section-compact-sub {
        width: auto;
    }
}
</style>
<template>
  <b-container fluid>

    <AddCustomProductForm />



    <!-- Main table element -->
    <b-table
      :items="products"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
      hover
      empty-filtered-html="검색 결과가 없습니다 😥"
      empty-text="결제 내역이 없습니다."
      tbody-tr-class="payment-row"
    >
      <template #cell(payment_status)="row">
        <!-- 0 - 결제 실패, 1- 결제 성공, 2 - 결제 대기, 3 - 결제 취소 -->
        <p v-if="row.value === 1">결제 성공</p>
        <p v-if="row.value === 2">결제 대기</p>
        <p v-if="row.value === 3">결제 취소</p>
        <p v-if="row.value === 0">결제 실패</p>
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="info(row.item, row.index, $event.target)"
          class="mr-1"
        >
          Info modal
        </b-button>
        <b-button
          v-if="row.item.payment_status === 1"
          variant="danger"
          size="sm"
          v-b-modal.cancelCustomProduct-modal
          @click="selectCancelInfo(row.item)"
        >
          결제취소
        </b-button>

        <b-button
          variant="primary"
          size="sm"
          @click="showReceipt(row.item.product_id, row.item.payment_status)"
        >
          매출전표
        </b-button>
      </template>
    </b-table>

    <!-- User Interface controls -->
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Info modal -->
    <b-modal
      :id="infoModal.id"
      :title="infoModal.title"
      ok-only
      @hide="resetInfoModal"
    >
      <pre>{{ infoModal.content }}</pre>
    </b-modal>
    <template>
      <div>
        <b-modal
          v-model="isReceiptOpen"
          title="매출 전표"
          size="lg"
          no-scrolling
          hide-footer
        >
          <iframe
            :src="receiptUrl"
            width="100%"
            height="700px"
            overflow-y="hidden"
          ></iframe>
        </b-modal>
      </div>
    </template>

    <b-modal
      v-if="selectedProduct"
      id="cancelCustomProduct-modal"
      ref="cancelCustomProduct-modal"
      title="상품 결제취소"
      hide-footer
    >
      <b-form @submit="submitCancelProductPayment">
        <b-form-group
          id="input-group-1"
          label="결제 취소 사유:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            type="text"
            v-model="cancelReason"
            placeholder="결제 취소 사유 (어드민님이 알아서 넣어주세요)"
          ></b-form-input>
        </b-form-group>

        <br /><br />

        <div>
          <!-- {{ selectedSubscriptionHistory }} -->
          {{ selectedProduct.payer_email }} 님의 <br />
          <b>{{ selectedProduct.product_name }}</b> 결제 내역을
          취소하시겠습니까?
        </div>

        <div class="mt-4">
          <!-- {{ selectedSubscriptionHistory }} -->
          환불금액:
          <b class="red italic">{{
            formatCurrency(selectedProduct.amount_price)
          }}</b
          >원(부가세 포함)
        </div>

        <br /><br />

        <b-button type="submit" variant="primary">환불시키기</b-button>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import { formatCurrency } from "@/components/payment/payment";
import AddCustomProductForm from "@/components/admin/AddCustomProductForm"
export default {

  components: {
    AddCustomProductForm
  },
  data() {
    return {
      cancelReason: "",
      selectedProduct: null,
      isReceiptOpen: false,
      receiptUrl: "",
      products: [],
      items: [
        { isActive: false, age: 26, name: { first: "Mitzi", last: "Navarro" } },
        {
          isActive: false,
          age: 22,
          name: { first: "Genevieve", last: "Wilson" },
        },
        { isActive: true, age: 38, name: { first: "John", last: "Carney" } },
        { isActive: false, age: 29, name: { first: "Dick", last: "Dunlap" } },
      ],
      fields: [
        {
          key: "product_id",
          label: "merchant_uid(product_id)",
          sortable: true,
          class: "text-center",
          filterByFormatted: true,
        },
        {
          key: "product_name",
          label: "상품명",
          sortable: true,
          class: "text-center",
          filterByFormatted: true,
        },
        {
          key: "amount_price",
          label: "결제가격(부가세포함)",
          sortable: true,
          class: "text-center",
          formatter: (value) => {
            return formatCurrency(value) + "원";
          },
        },
        {
          key: "product_price",
          label: "상품가격(부가세제외)",
          sortable: true,
          class: "text-center",
          formatter: (value) => {
            return formatCurrency(value) + "원";
          },
        },
        {
          key: "payer_email",
          label: "유저 메일",
          sortable: true,
          class: "text-center",
          filterByFormatted: true,
        },
        {
          key: "in_charge_admin_email",
          label: "담당자메일",
          sortable: true,
          class: "text-center",
          filterByFormatted: true,
        },
        {
          key: "paid_time",
          label: "결제시각",
          sortable: true,
          class: "text-center",
          filterByFormatted: true,
        },
        {
          key: "payment_status",
          label: "결제상태",
          sortable: true,
          class: "text-center",
          filterByFormatted: true,
        },
        {
            key: "cancelled_reason",
            label: "취소 사유",
            sortable: true,
            class: "text-center",
            filterByFormatted: true,
        },
        
        {
          key: "description",
          label: "설명",
          sortable: true,
          class: "text-center",
          filterByFormatted: true,
          },
          

        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 30, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.products.length;

    this.fetchCustomProducts();
  },
  methods: {
    formatCurrency,
    async submitCancelProductPayment(event) {
      event.preventDefault();
      console.log("cancel");

      try {
          const path =
            this.$store.state.backend_host + "/payment/general_product/" + this.selectedProduct.product_id;
    
          const response = await axios
              .delete(path, {
                'data': { cancelReason: this.cancelReason }
            })
    
            if (response.data.code === true) {
                if (response.data.code === true) {
                  window.alert("해당 내역에 대한 결제가 취소되었습니다.");
                  this.$refs["cancelCustomProduct-modal"].hide();
                  this.fetchCustomProducts();
                } else {
                  window.alert(response.data.message);
                }
       
                this.showModal = false;
    
            }
    
          this.cancelReason = "";
          this.selectedProduct = null;
        
      } catch (error) {
          window.alert(error)
          if (error.response) {
            window.alert(error.response.data?.msg)
          }
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      console.log("herere");
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async fetchCustomProducts() {
      console.log("fetch custom products");
      const response = await axios.get(
        this.$store.state.backend_host + "/payment/general_product"
      );

      if (response?.status === 200) {
        this.products = response.data.result;
      } else {
        window.alert("정보를 가져오지 못했습니다.");
      }
      console.log(response);
    },

    cancelPayment() {},
    selectCancelInfo(rowInfo) {
      this.selectedProduct = rowInfo;
    },

    showReceipt(payment_history_id, payment_status) {
      console.log("herere");
      axios
        .get(
          this.$store.state.backend_host +
            `/payment/getPaymentData/pg_tid?merchant_uid=${payment_history_id}`
        )
        .then((response) => {
          if (response?.data?.code) {
            if (payment_status == 1) {
              this.receiptUrl = `https://iniweb.inicis.com/app/publication/apReceiptOpenMarket100.jsp?noTid=${response?.data.result}&noMerchantoid=&flag=0&noMethod=1&tStat=null&re_mail=null`;
            } else {
              this.receiptUrl = `https://iniweb.inicis.com/app/publication/apReceiptOpenMarket100.jsp?noTid=${response?.data.result}&noMerchantoid=&flag=1&noMethod=1&tStat=null&re_mail=null`;
            }

            this.isReceiptOpen = true;
            // window.open(`https://iniweb.inicis.com/app/publication/apReceiptOpenMarket100.jsp?noTid=${response?.data.result}&noMerchantoid=&flag=0&noMethod=1&tStat=null&re_mail=null`, '_blank')
          } else {
            if (response?.data?.message) {
              window.alert(response?.data?.message);
            } else {
              window.alert(
                "매출전표를 가져오지 못했습니다. 관리자에게 문의해주세요."
              );
            }
          }
        });
    },
  },
};
</script>

<template>
<div>
    <b-overlay :show="loadingShow" no-wrap></b-overlay>
    
    <router-view name="custom_theme_editor" ></router-view>

    <template v-if="is_all_report_list_loaded==true && selected_report && not_found === ''">
        <router-view></router-view>
    </template>
    <template v-else-if="not_found!=''">
        <div class="vh-100 text-center bgray-200"> 
            <div style="height: 70vh;" class="d-flex justify-content-center align-items-center">
                <h2 class="teal8 pt-5 pb-2 border-bottom-teal7 px-md-5"> 
                    <span v-if="not_found == 'not_published'">아직 공개되지 않은 보고서 입니다.</span>
                    <span v-else-if="not_found == 'not_exitst'">존재하지 않는 보고서 입니다.</span>
                    <span v-else> 알 수 없는 에러입니다. 시스템 관리자에 문의해 주세요.</span>
                </h2>
            </div>
            <div>
                <b-button variant="icon-teal" href="http://report.qesg.co.kr" >ESG Reporting Tool 바로가기 <fa icon="external-link-square-alt" class="ml-1"/> </b-button>  
                <b-button variant="icon-teal" href="http://qesg.co.kr" target="_blank" class="m-0 mx-sm-3">QESG 바로가기 <fa icon="external-link-square-alt" class="ml-1"/></b-button>
                <b-button variant="icon-teal" href="http://qesg.co.kr/#i-5" target="_blank" >문의하기 <fa icon="question-circle" class="ml-1"/></b-button>
            </div>
        </div>
    </template>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            not_found: '',
            layout_type: 'FullView2021',
            loadingShow: true
        }
    },
    computed:{
        ...mapState('report_list', ['all_report_list', 'is_all_report_list_loaded', 'report_code']),
        ...mapGetters('report_list', ['selected_report'])

    },
    methods:{
        ...mapMutations('report_list', ['update_report_code', 'update_all_report_list_loaded', 'update_view_mode', 'change_lang']),
        ...mapActions('report_list', ['readAllReportListForViewer']),
        ...mapActions('report_indicators', ['readReportTopics','readReportIndicators', 'readSelectedTopics']),
        ...mapActions('report_contents', ['readReportContents']),
        ...mapActions('data_tables', ['readDataTableList']),
        ...mapActions('documents', ['readDocumentList']),
        ...mapActions('business_cases', ['readBusinessCaseList']),
        ...mapActions('assurance', ['readAssuranceListByReportCode', 'readAssuranceStatusByReportCode']),
        readAll(report_code){
            this.readReportContents({report_code: report_code})
            this.readSelectedTopics({report_code: report_code})
            this.readDataTableList({report_code: report_code})
            this.readDocumentList({report_code: report_code})
            this.readBusinessCaseList({report_code: report_code})
            this.readReportTopics({report_code: report_code})
            this.readReportIndicators({report_code: report_code})
            this.readAssuranceListByReportCode({report_code: report_code})           
        }, 
        fetch_theme_layout() {
            const urlParams = new URL(location.href).searchParams;
            let parsed_report_url = urlParams.get('r').split("?")[0] !== null ? urlParams.get('r').split("?")[0] : process.env.VUE_APP_REPORT_CODE;

            axios.get(this.$store.state.backend_host+"/custom_theme/get_layout_type?rc=" + parsed_report_url)
                .then((result) => {
                    if (result.data.code) {
                        this.layout_type = result.data.layout_code
                        this.loadingShow = false
                    } else {
                        window.alert(result.data.message)
                        this.loadingShow = false
                    }
                })
                .catch((error) => {
                    this.loadingShow = false
                })
        }
    },
    async created() {
        this.fetch_theme_layout();
        
        //1. report_code가 직접 넘어왔을 경우, 대상 보고서 업데이트
        const urlParams = new URL(location.href).searchParams;
        let parsed_report_code = urlParams.get('r') !== null ? urlParams.get('r') : process.env.VUE_APP_REPORT_CODE;
        if(parsed_report_code != null){
            this.update_report_code(parsed_report_code)
        }

        //2. report_code를 라우터로 부터 추출
        if(this.$route.query.r.split("?")[0] != undefined && this.$route.query.r.split("?")[0] != null){
            parsed_report_code = this.$route.query.r.split("?")[0]
        }

        //3. url로 넘어왔을 경우 report_code로 변환
        await this.readAssuranceStatusByReportCode({report_code: parsed_report_code})
        await this.readAllReportListForViewer() // 1. viewer에 필요한 report list 읽고 시작합니다
        if(parsed_report_code != null) {
            //console.log(this.all_report_list)
            let idx = this.all_report_list.findIndex(x => x.report_url == parsed_report_code) // 2. report_code 실존여부 확인
            if (idx >-1){
                if (this.all_report_list[idx].state == 4) { // 3. report 공개 여부 확인
                    let temp_report_code = this.all_report_list[idx].report_code
                    //console.log('VIEWER_MASTER_LOAD:'+temp_report_code)
                    this.update_report_code(temp_report_code) // 4. report_list.js의 report_code 변경, selected_report 변경
                    this.readReportContents({report_code: temp_report_code})
                    this.readDataTableList({report_code: temp_report_code})
                    this.readDocumentList({report_code: temp_report_code})
                    this.readBusinessCaseList({report_code: temp_report_code})
                    this.readAssuranceListByReportCode({report_code: temp_report_code})           
                }
                else {
                    
                    this.not_found = 'not_published'
                    console.log('아직 공개되지 않은 보고서 입니다.');
                }
            }
            else {
                this.not_found = 'not_exitst'
                console.log('존재하지 않는 보고서 입니다');
            }
        }
        else this.not_found = 'donno'

        // language set
        if (this.$route.query.l=='ENG'){
            this.change_lang('ENG')
        }

        this.update_view_mode('viewer')

    },
    beforeDestroy(){
        this.update_all_report_list_loaded(false)
        this.update_view_mode('editor')
    },
    components:{
    }
}
</script>

<style>

</style>
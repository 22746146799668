<template lang="">
  <div>
    <b-card-group deck>
      <ManageSubscriptionCouponVue />
      <!--TODO: 일반상품 결제 관련 컴포넌트 생성 예정-->
      <!-- <ManageProductCoupon />  -->

    </b-card-group>

    <ManageSubscriptionCouponTable />
  </div>
</template>
<script>
import ManageSubscriptionCouponVue from "./ManageSubscriptionCoupon.vue";
// import ManageProductCoupon from "./ManageProductCoupon.vue";
import ManageSubscriptionCouponTable from "./ManageSubscriptionCouponTable.vue";




export default {
  components: {
    ManageSubscriptionCouponVue,
    ManageSubscriptionCouponTable,
    // ManageProductCoupon
  },
};
</script>
<style lang=""></style>

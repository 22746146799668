<template>
    <div>
        <b-modal 
            id="open-how-gri-material-should" 
            size="lg" 
            scrollable 
            hide-footer
            title="GRI에 따른 보고(in accordance with)란?"
        >   
            <GRIAccordanceNotice />
        </b-modal>
        
        <div role="tablist">
            <b-card class="material-check-card shadow-sm"> 
                <div v-b-toggle.material-list-2021 role="tab" class="cursor">
                    <div class="fw-400 mb-2 d-flex justify-content-between">
                        <span v-if="is_in_accordance_with">
                            GRI에 따른(in accordance with) 상태
                        </span>
                        <span v-else>
                            GRI를 참고한(with reference to) 상태
                        </span>
                        <!-- <p class="f-90 mb-0 green" v-if="false">
                            <fa icon="check-circle"/> GRI를 참고하여(with reference to) 작성 중
                        </p>
                    
                        <p class="f-90 mb-0 red" v-else>
                            <fa icon="exclamation-circle"/>  GRI에 따라(in accordance with) 작성 중
                        </p> -->
                        
                        <!-- <div>
                            <span class="fw-500 f-95">{{current_material_topics.length}}</span>개
                        </div> -->
                    </div>
                    
                </div>
            
                

                <b-collapse v-model="visible" id="material-list-2021" role="tabpanel" accordion="my-accordion">
                    <div id="material-collapse" class="border-top-bottom overflow-auto py-2 f-90 my-2" style="max-height: calc(85vh - 580px);">
                        <p class="f-85 gray-550 mb-0 pb-4">
                            GRI에 따라(in accordance with) 작성하려면?
                        </p>

                        <b-card v-if="todoMaterialInfo !== null" class="bgray-200 border-0 px-2">

                            
                            <div class="mb-2 gray-600 line-height-sm" v-for="key in Object.keys(todoMaterialInfo)" :key="`gri_material_${key}`">
                                <!-- {{ todoMaterialInfo[key] }} -->
                                <span class="mr-1 fw-300">{{key}}</span> 
                                {{todoMaterialInfo[key]['indicator_name']}} 
                                <span class="f-75 mb-0 gray-400 fw-300">
                                    <!-- {{todoMaterialInfo[key]['indicator_name_e']}}  -->
                                    <b-icon v-if="todoMaterialInfo[key]['status'] === 'in_progress'" scale="1.4" variant="success" icon="check2" aria-hidden="true" />
                                    
                                    <span v-else>
                                        <b-icon scale="1.4" variant="danger" icon="x" aria-hidden="true" />작성 필요
                                    </span>


                                </span>

<!--                                 
                                <span class="mr-1 fw-300">{{item.topic_id}}</span> 
                                {{item.topic_name}} 
                                <span class="f-75 mb-0 gray-400 fw-300">{{item.topic_name_e}}</span> -->
                            </div>            
                        </b-card>

                    </div>
                </b-collapse>

                <a href="#" @click="openHowGriMaterialShould">
                    <fa icon="info-circle" class="mr-1"/>GRI에 따른 보고(in accordance with)란?
                    <fa icon="expand" class="ml-1"/> 
                </a>

                <b-button v-b-toggle.material-list-2021 variant="teal" size="sm" class="mt-4 float-right">
                    <span v-if="!visible">자세히 보기</span>
                    <span v-else>닫기</span>
                </b-button>
            </b-card>
        </div>
    
        <b-modal id="core-option-info" size="lg" title="보고 요건 충족 기준" hide-footer scrollable>
            <core-or-comprehensive></core-or-comprehensive>
        </b-modal>
    
        <b-modal id="material-topic-info" class="mt-2" title="중요 주제 선택 가이드" size="lg" hide-footer centered>
            <material-topic-info class="pb-3"></material-topic-info>
        </b-modal>
    
    </div>
    </template>
    
<script>
import CoreOrComprehensive from '@/components/static_examples/CoreOrComprehensive'
import MaterialTopicInfo from '@/components/managing_indicators/indicator2016_components/MaterialTopicInfo'
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import GRIAccordanceNotice from '@/components/managing_indicators/gri2021_indicators/GRIAccordanceNotice.vue'
    
export default {
    data() {
        return {
            visible: false,
            todoMaterialInfo: null,
            is_in_accordance_with: null
        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState('report_indicators', ['indicators_list', 'topics_list']),
        ...mapGetters('report_indicators', ['gri_200to400_all_topics']),
        ...mapState('report_indicators', ['gri_option_status']),
        current_material_topics(){
            return this.gri_200to400_all_topics.filter(x => x.material==1)
        },
        current_gri_option(){
            if (this.gri_option_status.is_core_aligned==true&&this.gri_option_status.is_comprehensive_aligned==false){
                return '핵심 부합 (Core option) 충족'
            }
            else if (this.gri_option_status.is_comprehensive_aligned==true){
                return '포괄적 부합 (Comprehensive option) 충족'
            }
            else return '핵심 부합 (Core option) 미충족'
        }
    },
    methods:{
        ...mapMutations('report_indicators', ['update_gri_option_status']),
        ...mapActions('report_indicators', ['checkGriOption']),
        openHowGriMaterialShould() {
            this.$bvModal.show("open-how-gri-material-should");

        },
        async fetchTodoMaterialList() {
            try {
                const path = `${this.$store.state.backend_host}/check_material_indicator_written?rc=${this.report_code}`
                const result = await axios.get(path);

                this.todoMaterialInfo = result.data.indicator_status;
                this.is_in_accordance_with = result.data.is_in_accordance_with;
                
            } catch (error) {
                window.alert(error)
            }
        }

    },
    created(){
        if(this.gri_option_status=={}||this.gri_option_status.is_core_aligned==undefined){
            this.checkGriOption(this.report_code)
        }

        this.fetchTodoMaterialList();
    },
    mounted(){
        // console.log(this.gri_option_status);
    },
    beforeDestroy(){
        this.update_gri_option_status({})
    },
    components:{
        CoreOrComprehensive,
        MaterialTopicInfo,
        GRIAccordanceNotice

    }

}
</script>
    
<style>
.material-check-card > .card-body {
    padding: 1rem 1.25rem;
}

#material-collapse{
    padding-right: 8px;
}
</style>
<template>
    <div>
<!-- <pre>
{{ chart_field }}
{{ dc_key_info[2]}}
</pre> -->
<!-- {{ selected_checkbox_chart_list }}
{{ selected_chart_list }}
{{ editing_mode }}
{{ initial_checkbox_chart_list }} -->
<!-- {{ show_edit_chart }}
{{ show_manage_chart}} -->
        <div class="pt-2 pb-4" v-if="editing_gri_code!=''">
            <h5 class="f-110 tail-line-dark">{{editing_gri_code}} 지표에 차트 추가 </h5>
        </div>

        <div class="pt-2 pb-4" v-if="dc_key_info.length>0">
            <h5 class="f-110 tail-line-dark">{{ dc_key_info[0]['name'] }} > {{ dc_key_info[1]['name'] }} 지표에 차트 추가 </h5>
        </div>
        <b-table 
            v-if="chart_data"
            class="bor-table mb-45"
            :fields="chart_field"
            :items="chart_data"
            responsive 
            hover
        >
            <template #cell(choice)="row" >
                <b-form-group v-if="dc_key_info.length>0">
                    <b-form-radio
                        :value="row.item.chart_id"
                        v-model="selected_radio_chart_id"
                    />
                </b-form-group>
                
                <b-form-group>
                    <b-form-checkbox-group 
                    v-if="editing_gri_code !=''"
                    v-model="selected_checkbox_chart_list"
                    
                    >
                        <b-form-checkbox
                            :value="row.item.chart_id"
                        />
                    </b-form-checkbox-group>
                </b-form-group>

                
                <!-- {{ row.item }} -->
            </template>

            <template #cell(view_chart)="row">
                <a class="cursor" @click="row.toggleDetails">
                    {{ row.detailsShowing? '닫기':'보기' }}<fa icon="expand" class="ml-1"/>
                </a>
            </template>

            <template #cell(edit_chart)="row">
                <b-button size="sm" @click="editSelectedChart(row)">
                    수정
                </b-button>
            </template>

            <template #cell(mng_chart)="row">
                <b-button size="sm" @click="manageSelectedChart(row)">
                    관리
                </b-button>
            </template>


            <template #cell(indicators)="row" >
                <div v-if="gri_linked_indicators[row.item.chart_id]">
                    <span v-for="(item1, index1) in gri_linked_indicators[row.item.chart_id]" :key="`gri_${index1}`">
                        <a class="cursor" @click="goHash(item1, 'GRI')"> GRI {{ item1 }} <fa icon="link" class="ml-1"/></a> <br>
                    </span>
                </div>

                <div v-if="sasb_linked_indicators[row.item.chart_id]">
                    <span v-for="(item1, index1) in sasb_linked_indicators[row.item.chart_id]" :key="`sasb_${index1}`">
                        <a class="cursor" @click="goHash(item1, 'SASB')"> SASB {{ item1 }} <fa icon="link" class="ml-1"/></a> <br>
                    </span>
                </div>

                <div v-if="tcfd_linked_indicators[row.item.chart_id]">
                    <span v-for="(item1, index1) in tcfd_linked_indicators[row.item.chart_id]" :key="`tcfd_${index1}`">
                        <a class="cursor" @click="goHash(item1, 'TCFD')"> TCFD {{ item1 }} <fa icon="link" class="ml-1"/></a> <br>
                    </span>
                </div>

                <span v-for="(item, index) in chart_linked_indicators[row.item.chart_id]" :key="`gri_${index}`">
                    <a class="cursor" @click="goHash(item, 'DATA_CENTER')"> Data Center {{ item['lv1_info']['name'] }} > {{ item['lv2_info']['name'] }} <fa icon="link" class="ml-1"/></a> <br>
                </span>


            </template>
            

            <template #row-details="row">
                <b-card>
                    <ChartCanvasViewer 
                        :viewer_mode="true"
                        :chart_id="row.item.chart_id" 
                    ></ChartCanvasViewer>
                </b-card>
            </template>

        </b-table> 

        <b-card class="make-new-card text-center cursor bg-transparent" @click="addOrEditChartCase()"><fa icon="plus" class="mr-2"/>새 차트 생성</b-card>
        
        <b-modal ref="edit-chart-case" id="global-chart-editor-modal" title="차트 사례" hide-footer size="xl" @hidden="closeEditChartModal" scrollable>
            <DefaultChart
                v-if="show_edit_chart"
                @closeEditChartModal = "closeEditChartModal"
            />

            <ManageChart
                v-if="show_manage_chart"
                @closeEditChartModal = "closeEditChartModal"
            >
            </ManageChart>
        </b-modal>


        <div class="pt-5 pb-3" v-if="dc_key_info.length>0">
            <b-button variant="teal" class="mr-3" @click="applyChartFromDC" >선택한 차트 적용</b-button>
            <b-button @click="closeEditChartModal">취소</b-button>
        </div>

        <div class="pt-5 pb-3" v-if="editing_gri_code!=''">
            <b-button variant="teal" class="mr-3" @click="applyChart" >선택한 차트 적용</b-button>
            <b-button @click="closeEditChartModal">취소</b-button>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'
import ChartCanvasViewer from "@/components/writing_report/chart_subs/ChartCanvasViewer.vue";
import DefaultChart from '@/components/writing_report/chart_subs/DefaultChart'
import ManageChart from '@/components/writing_report/chart_subs/ManageChart'
export default {
    mixins: [gri_mixin],
    name: 'ChartCaseList',
    props: {
        dc_key_info: { type: Array, default: () => [] },
        editing_mode: { type: Boolean, default: () => false },
        selected_chart_list: { type: Array, default: () => [] },
        selected_mng_chart_id: { type: Number, default: null },


    },
    components: {
        "DefaultChart": DefaultChart,
        "ManageChart": ManageChart,
        "ChartCanvasViewer": ChartCanvasViewer,
    },
    data() {
        return {
            selected_radio_chart_id: this.selected_mng_chart_id,
            selected_checkbox_chart_list: [],
            // initial_radio_state: this.dc_key_info.length > 0 ? this.dc_key_info[2]['item_id'] : '',
            initial_checkbox_chart_list: this.selected_chart_list, // state에 넣은 값을 초기값으로 넣어주기
            show_edit_chart: false,
            show_manage_chart: false
        }
    },
    watch: {
        selected_chart_list: {
            immediate: true,
            handler(newV) {
                this.selected_checkbox_chart_list = this.selected_chart_list
            }
        },
        // selected_radio_chart_id: {
        //     immediate: true,
        //     handler(newV){
        //         if (newV === '' && this.dc_key_info.length > 0) {
        //             this.selected_radio_chart_id = this.dc_key_info[2].item_id;
        //         }
        //     }
        // }  
    },
    computed:{
        ...mapState('charts', ['gri_linked_indicators', 'sasb_linked_indicators', 'tcfd_linked_indicators']),
        ...mapState('report_list', ['report_code', 'standard_mode']),
        ...mapState('business_cases', ['business_case_list']),
        ...mapState('data_tables', ['table_list']),
        ...mapState('cursor', ['editing_gri_code']),
        ...mapState('charts', ['chart_data', 'chart_field', 'chart_linked_indicators', 'selected_chart_id', 'selected_chart_data'])
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        // if (this.dc_key_info.length > 0 && this.dc_key_info[2].type === 'chart') {
        //     this.selected_radio_chart_id = this.dc_key_info[2].item_id;
        // }

        if (!this.editing_mode) {
            this.getChartList({
                report_code: this.report_code,
                is_editing: false,
            });

        } else {
            this.selected_checkbox_chart_list = this.initial_checkbox_chart_list
        }
    },
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() { },
    beforeDestroy() {
        if (!this.editing_mode) {
            this.update_selected_chart_id(null)
            this.update_selected_chart_data(null)
        } else {

        }
        // this.selected_chart_id = null;
        // this.selected_chart_data = null;  
    },
    methods: {
        ...mapActions("charts", ["saveAddChart", "updateDCIndicator", "updateChartIndicator", "getChartList", "getDCToC"]),
        ...mapMutations('charts', ['update_selected_chart_id', 'update_selected_chart_data']),
        addOrEditChartCase() {
            

            this.update_selected_chart_id(null)
            this.update_selected_chart_data(null)
            // this.selected_chart_id = null;
            // this.selected_chart_data = null;

            if (this.editing_mode) {
                this.$emit("flag_modal_mode", "chart_edit")
            } else {
                this.show_edit_chart = true;
                this.show_manage_chart = false;
                this.$refs['edit-chart-case'].show()
            }
            // const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            // if (!shouldEdit) return
            // if (this.editing_gri_code==''){ //관리 페이지에서 생성-수정시
            //     if(business_case_id){
            //         this.$emit('open-edit-modal', business_case_id)
            //     }
            //     else {
            //         this.$emit('open-edit-modal', '')
            //     }
            // }else { //gri 작성 모달에서 생성-수정시
            //     if(business_case_id){
            //         this.$emit('edit-business-case', business_case_id)
            //     }
            //     else {
            //         this.$emit('edit-business-case')
            //     }
            // }
        },
        editSelectedChart(payload) {
            this.show_edit_chart = true;
            this.show_manage_chart = false;
            this.update_selected_chart_id(payload.item.chart_id)
            this.update_selected_chart_data(payload.item.form)

            if (this.editing_mode) {
                this.$emit("flag_modal_mode", "chart_edit")
            } else {
                this.$refs['edit-chart-case'].show()
            }
            

        },
        manageSelectedChart(payload) {
            this.show_edit_chart = false;
            this.show_manage_chart = true;

            this.update_selected_chart_id(payload.item.chart_id)
            this.update_selected_chart_data(payload.item.form)

            if (this.editing_mode) {
                this.$emit("flag_modal_mode", "chart_manage")
            } else {
                this.$refs['edit-chart-case'].show()
            }
        },
        mngChart(){

        },
        closeEditChartModal() {
            if (this.editing_mode) {
                this.selected_radio_chart_id = "";
                this.$emit("closeModal")
                this.selected_checkbox_chart_list = this.initial_checkbox_chart_list

            } else {
                this.$refs['edit-chart-case'].hide()
            }

            this.show_edit_chart = false;
            this.show_manage_chart = false;
        },
        applyChartFromDC() {
            if (this.selected_radio_chart_id === '' || this.selected_radio_chart_id === null) {
                window.alert("차트를 선택해주세요.")
            } else {
                this.updateDCIndicator({
                    "report_code": this.report_code,
                    "type": "chart",
                    "id": this.selected_radio_chart_id,
                    "payload": this.dc_key_info,
                })

                this.closeEditChartModal();
            }
        },

        applyChart() {
            if (this.selected_checkbox_chart_list.length === 0) {
                window.alert("차트를 선택해주세요")
            } else {
                this.updateChartIndicator({
                    "report_code": this.report_code,
                    "type": this.standard_mode,
                    "editing_code": this.editing_gri_code,
                    "payload": this.selected_checkbox_chart_list
                })

                this.initial_checkbox_chart_list = this.selected_checkbox_chart_list;
                this.closeEditChartModal();
            }
        }

        
    }
}
</script>
<style>
</style>
<template>
<div>
    <div class="pt-2 pb-4" v-if="editing_gri_code!=''">
        <h5 class="f-110 tail-line-dark">{{editing_gri_code}} 지표에 문서 및 링크 추가 </h5>
    </div>

    <b-tabs content-class="pt-4" pills class="hj-nav">
        <b-tab title="문서 List" :active="document_mode=='docs'" @click="change_document_mode('docs')">
            <b-table-simple responsive hover class="bor-table mb-45">
                <b-thead><b-tr>
                    <b-td v-if="editing_gri_code!=''">선택</b-td><b-td>No.</b-td><b-td>지표</b-td><b-td>문서명</b-td><b-td>업로드일</b-td><b-td>보기</b-td><b-td>수정</b-td><b-td>관리</b-td>
                </b-tr></b-thead>
                <b-tbody>
                    <b-tr v-for="(item, index) in doc_list" :key="item.id">
                        <b-td v-if="editing_gri_code!=''"><b-form-checkbox v-model="local_docs_select[item.document_id]"></b-form-checkbox></b-td>
                        <b-td class="gray-600">{{ index+1 }}</b-td>
                        <b-td>
                            <span v-for="(item, index) in item['used_codes']" :key="index">
                                <a class="cursor" @click="goHash(item, 'GRI')"> GRI {{ item }} <fa icon="link" class="ml-1"/></a>
                            </span>
                            <span v-for="(item, index) in item['used_codes_sasb']" :key="index">
                                <a class="cursor" @click="goHash(item, 'SASB')"> 
                                    SASB {{ item }} 
                                    <fa icon="link" class="ml-1"/></a>
                            </span>
                            <span v-for="(item, index) in item['used_codes_tcfd']" :key="index">
                                <a class="cursor" @click="goHash(item, 'TCFD')"> 
                                    TCFD {{ item }} 
                                    <fa icon="link" class="ml-1"/></a>
                            </span>
                        </b-td>
                        <b-td> <div class="">{{ item.title }}</div> </b-td>
                        <b-td> <p class="mb-0">{{ item.time_stamp }}</p> </b-td>
                        <b-td>
                            <a class="cursor" :href="$store.state.static_host+item.path" target="_blank">보기 <fa icon="file-download" class="ml-1"/></a>
                        </b-td>
                        <b-td> <b-button variant="dark-border" class="py-1 px-2" @click="selected_report && handleEdit(selected_report.is_able_to_edit) && addOrEditDocument(item.document_id)"><fa icon="pen"/></b-button> </b-td>
                        <b-td> <b-button variant="dark-border" class="py-1 px-2" @click="selected_report && handleEdit(selected_report.is_able_to_edit) && mgtDocument(item.document_id)"><fa icon="wrench"/></b-button> </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-card class="make-new-card text-center cursor bg-transparent" @click="selected_report && handleEdit(selected_report.is_able_to_edit) && addOrEditDocument()"><fa icon="plus" class="mr-2"/>새 문서 추가 </b-card>
        </b-tab>

        <b-tab title="외부 링크 List" :active="document_mode=='link'" @click="change_document_mode('link')">
            <b-table-simple responsive hover class="bor-table mb-45">
                <b-thead><b-tr>
                    <b-td v-if="editing_gri_code!=''">선택</b-td><b-td>No.</b-td><b-td>URL 명</b-td><b-td>업로드일</b-td><b-td>보기</b-td><b-td>수정</b-td><b-td>관리</b-td>
                </b-tr></b-thead>
                <b-tbody>
                    <b-tr v-for="(item, index) in url_list" :key="item.id">
                        <b-td v-if="editing_gri_code!=''"><b-form-checkbox v-model="local_url_select[item.document_id]"></b-form-checkbox></b-td>
                        <b-td class="gray-600">{{ index+1 }}</b-td>
                        <b-td> <div class="">{{ item.title }}</div> </b-td>
                        <b-td> <p class="mb-0">{{ item.time_stamp }}</p> </b-td>
                        <b-td>
                            <a class="cursor" :href="item.link" target="_blank">링크 <fa icon="external-link-square-alt" class="ml-1"/></a>
                        </b-td>
                        <b-td> <b-button variant="dark-border" class="py-1 px-2" @click="selected_report && handleEdit(selected_report.is_able_to_edit) && addOrEditDocument(item.document_id)"><fa icon="pen"/></b-button> </b-td>
                        <b-td> <b-button variant="dark-border" class="py-1 px-2" @click="selected_report && handleEdit(selected_report.is_able_to_edit) && mgtDocument(item.document_id)"><fa icon="wrench"/></b-button> </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-card class="make-new-card text-center cursor bg-transparent" @click="selected_report && handleEdit(selected_report.is_able_to_edit) && addOrEditDocument()"><fa icon="plus" class="mr-2"/>새 외부 링크 추가 </b-card>
        </b-tab>
    </b-tabs>

    <div class="pt-5 pb-3" v-if="editing_gri_code!=''">
        <b-button variant="teal" class="mr-3" @click="handleSelect"> 선택한 {{document_mode=='docs'? '문서':'링크'}} 적용</b-button>
        <b-button @click="handleCancle">취소</b-button>
    </div>

</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import * as ih from '@/components/util'
import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'

export default {
    mixins: [gri_mixin],
    data() {
        return {
            local_url_select: {},
            local_docs_select: {}        
        }
    },
    computed:{
        ...mapState('report_list', ['report_code', 'standard_mode']),
        ...mapState('cursor', ['editing_gri_code','document_mode']),
        ...mapState('documents', ['document_list']),
        ...mapGetters('documents', ['doc_list','url_list'])
    },
    methods:{
        ...mapMutations('cursor', ['change_document_mode']),
        ...mapActions('documents', ['updateDocumentRelation']),
        addOrEditDocument(document_id){
            if (this.editing_gri_code==''){ //관리 페이지에서 생성-수정시
                if(document_id){
                    this.$emit('open-edit-modal', document_id)
                }
                else {
                    this.$emit('open-edit-modal', '')
                }
            }else { //gri 작성 모달에서 생성-수정시
                if(document_id){
                    this.$emit('edit-link', document_id)
                }
                else {
                    this.$emit('edit-link')
                }
            }
        },
        mgtDocument(document_id){
            if (this.editing_gri_code==''){ //관리 페이지에서 생성-수정시
                if(document_id){
                    this.$emit('open-manage-modal', document_id)
                }
                else {
                    this.$emit('open-manage-modal', '')
                }
            }else { //gri 작성 모달에서 생성-수정시
                if(document_id){
                    this.$emit('document-manage', document_id)
                }
                else {
                    this.$emit('document-manage')
                }
            }
        },
        initiateLocalUrlSelect(){
            this.local_url_select = {}
            for (let i=0; i<this.url_list.length; i++){
                let used_idx = this.url_list[i].used_codes.findIndex( k => k == this.editing_gri_code)
                let temp_url_id = this.url_list[i].document_id
                if (used_idx > -1){
                    this.local_url_select[temp_url_id] = true
                }
                else this.local_url_select[temp_url_id] = false
            }
        },
        initiateLocalDocsSelect(){
            this.local_docs_select = {}
            for (let i=0; i<this.doc_list.length; i++){
                let used_idx = this.doc_list[i].used_codes.findIndex( k => k == this.editing_gri_code)
                let temp_docs_id = this.doc_list[i].document_id
                if (used_idx > -1){
                    this.local_docs_select[temp_docs_id] = true
                }
                else this.local_docs_select[temp_docs_id] = false
            }
        },
        async handleSelect(){
            // console.log(this.document_mode);
            let new_payload = {...this.local_docs_select}
            if (this.document_mode=='link'){
                new_payload = {...this.local_url_select}
            }
            // console.log(new_payload);
            await this.updateDocumentRelation({
                report_code: this.report_code,
                gri_code: this.editing_gri_code,
                link_payload: new_payload,
                framework: this.standard_mode 
            })
            this.handleCancle()
        },
        handleCancle(){
            this.$emit('hide-edit')
        }
    },
    created(){
        if (this.editing_gri_code!=''){
            this.initiateLocalUrlSelect()
            this.initiateLocalDocsSelect()
        }
    },
    updated(){
        // console.log(this.document_mode);
    }
}
</script>

<style>

</style>
<template>
<div>
    <h5 class="f-110 tail-line-dark">전자계약서</h5>
    <div class="d-flex justify-content-between mb-4"> 
        <p class="f-90 mb-0">{{view_mode!=true?'계약서의 내용을 확인 후 관련 정보를 입력 및 서명해주세요.' :''}}</p>
        <a class="cursor">계약서 다운로드 <fa icon="file-download" class="ml-1"/> </a>
    </div>
    <div class="mb-5" :class="view_mode!=true?'bg-white border-dark py-3 px-3':''">
        <contract-sheet></contract-sheet>
    </div>    

    <hr>

    <!-- <p class="fw-400 f-90 mb-2">세금계산서 발행처 {{view_mode!=true? '정보 입력':''}}</p>
    <div v-if="view_mode!=true" class="d-flex justify-content-between mb-4">
        <b-form-input v-model="tax_name" placeholder="담당자 성함" class="mr-3"></b-form-input>
        <b-form-input v-model="tax_e_mail" placeholder="세금계산서 받으실 이메일" class="mr-3"></b-form-input>
        <b-form-input v-model="tax_tel" placeholder="담당자 전화번호"></b-form-input>
    </div>
    <div v-else class="mb-4 gray-600">
        {{ tax_name }} <span class="mx-3">{{ tax_e_mail }}</span> {{ tax_tel }}
    </div>

    <p class="fw-400 f-90 mb-2">사업자등록증 첨부</p>
    <div v-if="view_mode!=true" class="mb-4">
        <b-form-file v-model="business_file.file" placeholder="여기로 끌어오기 또는 파일찾기" ></b-form-file>
    </div>
    <div v-else class="mb-4">
        <a class="cursor"> {{ 'filename' }} <fa icon="file-download" class="ml-1"/> </a>
    </div>

    <p class="fw-400 f-90 mb-2">계약 대상 사업자등록증 및 통장사본</p>
    <div class="mb-45">
        <a class="cursor" href="$store.state.static_host+item.path" target="_blank">퀀티파이드이에스지(QESG) 사업자등록증 <fa icon="file-download" class="ml-1"/></a>
        <a class="cursor ml-3" href="$store.state.static_host+item.path" target="_blank"> 통장사본 <fa icon="file-download" class="ml-1"/></a>
    </div>

    <hr> -->

    <b-form-checkbox v-if="view_mode!=true" v-model="signed" class="pt-3 pb-4" >
        위 계약 내용을 숙지하였으며 이에 동의합니다. 또한 ‘서명하기’ 버튼이 본 전자서명 날인에 해당하는 효력이 있음을 확인하였습니다.
    </b-form-checkbox>

    <div v-if="view_mode!=true" class="pt-5 pb-4">
        <b-button variant="red" class="px-3 mr-3" @click="handleSave"> 서명하기 </b-button>
        <b-button class=""> 취소 </b-button>
    </div>
    <!-- <div v-else class="py-4">
        <b-button variant="teal-border" @click="is_editing=true"> 세금계산서 발행처 정보 수정 </b-button>

    </div> -->
   
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import ContractSheet from './ContractSheet'

export default {
    data() {
        return {
            tax_name: '',
            tax_e_mail: '',
            tax_tel: '',
            signed: false,
            business_file: {},
            is_editing: false
        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapGetters('report_list', ['selected_report']),
        ...mapState('assurance', ['assurance_list']),
        ...mapGetters('assurance', ['assurance_id']),
        view_mode(){
            if (this.assurance_list[0].contract_signed==true && this.is_editing==false) {
                return true
            }else return false
        }

    },
    methods:{
        ...mapActions('assurance', ['saveAssuranceContract']),
        ...mapMutations('assurance', ['update_show_apply_estimate']),
        async handleSave(){
            if(this.checkForm()==true){
            
                await this.saveAssuranceContract({
                    assurance_id : this.assurance_id, 
                    signed: this.signed
                })
                this.$EventBus.$emit('make-toast', '서명완료', '전자계약서 서명을 완료하였습니다.')
                this.$emit('close-make-contract')
            }
        },
        checkForm(){
            // if(this.tax_name==''){ alert('세금계산서 받으실 담당자 성함을 입력해주세요') 
            //     return false
            // }
            // else if(this.tax_e_mail==''){ alert('세금계산서 받으실 이메일을 입력해주세요')
            //     return false
            // }
            // else if(this.tax_tel==''){ alert('세금계산서 받으실 담당자 전화번호를 입력해주세요')
            //     return false
            // }
            // else if(this.business_file=={}){ alert('사업자등록증을 업로드해주세요')
            //     return false
            // }
            if(this.signed==false){ alert('계약 내용에 동의하신 후 서명해주세요')
                return false
            }
            else return true
        },
        initiateForm(){
            if (this.assurance_list[0].contract_signed!=undefined){
                // this.tax_name = this.assurance_list[0].contract_tax_name
                // this.tax_e_mail = this.assurance_list[0].contract_tax_e_mail
                // this.tax_tel = this.assurance_list[0].contract_tax_tel
                this.signed = this.assurance_list[0].contract_signed
                // this.business_file = this.assurance_list[0].contract_business_file
            }  
        }
    },
    created(){
        this.initiateForm()
    },
    components:{
        ContractSheet
    }
}
</script>

<style>

</style>
<template>
  <div>
    <div>
      <div class="mb-4" v-if="is_editing">
        <b-button size="sm" class="mr-1" variant="teal" @click="saveToc"
          >저장하기</b-button
        >
        <b-button size="sm" @click="editToc(false)">목록 편집 취소</b-button>
      </div>

      <div v-if="!is_editing">
        <b-card
          v-for="(item, index) in toc_list"
          :key="index"
          border-variant="light"
          :header="item.name"
          class="text-left bgray-200"
          header-bg-variant="white"
        >
          <b-card-text
            @click="showTocInfo(child)"
            v-for="(child, childIndex) in item.children"
            :key="childIndex"
            no-body
            >{{ child.name }}</b-card-text
          >
        </b-card>
      </div>

      <b-card v-else class="bgray-200 border-0 px-2 mt-1">
        <template>
          <div>
            <draggable 
            v-model="temp_toc_list" 
            @end="onLv1DragEnd"
            v-bind="dragOptions"

            >
              <div v-for="(item, index) in temp_toc_list" :key="index">
                <b-card no-body class="mt-3 pt-1 pb-1">
                  <div style="white-space: nowrap">
                    <b-row>
                      <b-col cols="8">
                        <b-input v-model="item.name" class="ml-3 mt-2 mb-2" />
                      </b-col>
                      <b-col cols="4">
                        <span class="pl-2">
                          <b-icon
                            v-b-toggle="`toc_${item.key}`"
                            class="float-right"
                            scale="1"
                            icon="chevron-double-right"
                            aria-hidden="true"
                          ></b-icon>

                          <b-icon
                            @click="deleteToc(item)"
                            class="float-right mr-3 mt-3 mb-2"
                            scale="1"
                            icon="trash"
                            aria-hidden="true"
                          ></b-icon>

                          <b-button
                            @click="addChildToc(item)"
                            class="p-1 ml-3 mt-2 mb-2"
                            variant="teal"
                          >
                            <b-icon
                              class="float-right"
                              scale="1"
                              icon="plus"
                              aria-hidden="true"
                            ></b-icon>
                          </b-button>
                        </span>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>

                <!-- 2레벨 -->
                <b-collapse :id="`toc_${item.key}`" visible>
                  <draggable
                    v-if="item.children && item.children.length"
                    v-model="item.children"
                    v-bind="dragOptions"
                    @end="deselectedCard"
                  >
                    <!-- @start="isDragging = true"
                                    @end="isDragging = false" -->
                    
                    <b-card
                      v-for="(child, childIndex) in item.children"
                      :key="childIndex"
                      no-body
                      class="ml-4 mt-1 pt-1 pb-1"
                      @mousedown="selectedCard"
                      :id="'toc_' + child.key"
                    >
                      <b-row >
                        <b-col cols="8" :class="'toc_' + child.key">
                          <b-input v-model="child.name" class="ml-3 mt-2 mb-2" :class="'toc_' + child.key" />
                        </b-col>

                        <b-col cols="4" :class="'toc_' + child.key">
                          <span class="pl-2">
                            <b-icon
                              @click="deleteToc(child, item)"
                              class="float-right mr-3 mt-3 mb-2"
                              scale="1"
                              icon="trash"
                              aria-hidden="true"
                            ></b-icon>
                          </span>
                        </b-col>
                      </b-row>
                    </b-card>
                  </draggable>
                </b-collapse>
              </div>
            </draggable>
          </div>
        </template>
        

        <b-button class="p-1 mt-3" variant="teal" @click="addToc">
          <b-icon scale="1" icon="plus" aria-hidden="true"></b-icon>
          <span> 대분류 목록 추가하기 </span>
        </b-button>
      </b-card>

      <b-button @click="editToc(true)" class="mt-3 mb-3" variant="outline-info">
        <span> 목록 편집하기 </span>
        <b-icon scale="1" icon="pencil-square" aria-hidden="true"></b-icon>
      </b-button>
    </div>

  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import draggable from "vuedraggable";
import { generateRandomString } from "@/components/util";
export default {
  computed: {
    ...mapState('charts', ['chart_data', 'toc_list']),
    ...mapState("report_list", ["report_code"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  components: {
    draggable,
  },
  mounted() {
    this.temp_toc_list = [...this.toc_list];
    
  },
  data() {
    return {
      is_editing: false,
      temp_toc_list: null,
    };
  },
  methods: {
    ...mapMutations('charts', ['update_toc_list']),
    generateRandomString,
    async initToc() {
      // getTocInfo
      try {
        const response = await axios.get(
          this.$store.state.backend_host + "/getTocInfo?rc=" + this.report_code
        );

        if (response.data.code) {
            this.toc_list = response.data.result.contents;
        } else {
          window.alert("목차정보를 불러오는데 실패했습니다. 다시 시도해주세요.");
        }
      } catch (error) {
        window.alert("목차정보를 불러오는데 실패했습니다. 다시 시도해주세요.");
      }
    },
    
    deleteToc(item, parent = null) {
      //   const confirm = window.confirm(
      //     "목차를 제거할 경우 하위에 연결된 차트가 해제될 수 있습니다. 삭제하시겠습니까?"
      //   );
      //   if (!confirm) return;
      if (parent !== null) {
        parent.children = parent.children.filter(
          (toc_info) => toc_info.key !== item.key
        );
      } else {
        this.temp_toc_list = this.toc_list.filter(
          (toc_info) => toc_info.key !== item.key
        );
      }
    },
    showTocInfo(e) {
      console.log("kjflkjlske");
    },
    onLv1DragEnd(event) {
      // Handle drag end event if needed
    },
    onLv2DragEnd(event) {
      // Handle drag end event if needed
    },

    editToc(toggle) {
      if (this.$route.path === "/write_report/data_centers") {
        const confirm = window.confirm(
          "데이터센터 목록 편집 페이지로 이동하시겠습니까?"
        );
        if (confirm) {
          this.$router.push({ name: "SelectSasbIndicators" });
        } else {
          return;
        }
      }

      this.is_editing = toggle;
      if (!toggle) {
        // 편집한 것 원상복구
        this.temp_toc_list = [...this.toc_list];
      }
    },

    addChildToc(item) {
      item.children.push({
        key: this.generateRandomString(10),
        name: "lv2 예시",
        children: [
          {
            "key": this.generateRandomString(10),
            "contents": "",
            "contents_em": null,
            "contents_ea": null,
            "type": "",
            "item_id": ""
          }
        ],
      });
    },
    addToc() {
      this.temp_toc_list.push({
        key: this.generateRandomString(10),
        name: "lv1 예시",
        name_ea: null,
        name_em: null,
        children: [],
      });
    },

    async saveToc() {
      try {
        const response = await axios.post(
          this.$store.state.backend_host + "/update_toc",
          {
            report_code: this.report_code,
            contents: this.temp_toc_list,
          }
        );

        if (response.data.code) {
          this.update_toc_list(this.temp_toc_list);
          
          this.editToc(false);
          this.$EventBus.$emit(
            "make-toast",
            "저장 완료",
            "Data Center의 목차가 저장되었습니다."
          );
        } else {
          console.log(reponse.data.msg)
          window.alert("저장에 실패했습니다. 다시 시도해주세요.");
        }
      } catch (error) {
          console.log(error)
        window.alert("저장에 실패했습니다. 다시 시도해주세요.");
      }
    },

    selectedCard(e) {
      if (e.target.tagName === 'svg') {
        return;
      }
      const className = e.target.className.split(" ")
      .filter(element => element.split(' ').some(className => className.includes('toc')))
      .shift();

      if (document.getElementById(className)) {
        document.getElementById(className).classList.add('selected-card')
      }
    },

    deselectedCard(e) {
      document.querySelectorAll('.selected-card').forEach(item => {
        item.classList.remove('selected-card')
      })
    },
  },
};
</script>
<style></style>

const state = () => ({
  selectedCategoryId: null,
  selectedSubtopicCode: null,
  selectedTopicId: null,
  report_type: ''
});

const mutations = {
  SET_SELECTED_CATEGORY_ID(state, categoryId) {
    state.selectedCategoryId = categoryId;
  },
  SET_SELECTED_SUBTOPIC_CODE(state, subtopicCode) {
    state.selectedSubtopicCode = subtopicCode;
  },
  SET_SELECTED_TOPIC_ID(state, topicId) {
    state.selectedTopicId = topicId;
  },
  SET_REPORT_TYPE(state, type) {
    state.report_type = type;
  }
};

const actions = {
  setSelectedCategoryId({ commit }, categoryId) {
    commit('SET_SELECTED_CATEGORY_ID', categoryId);
  },
  setSelectedSubtopicCode({ commit }, subtopicCode) {
    commit('SET_SELECTED_SUBTOPIC_CODE', subtopicCode);
  },
  setSelectedTopicId({ commit }, topicId) {
    commit('SET_SELECTED_TOPIC_ID', topicId);
  },
  setReportType({ commit }, type) { 
    commit('SET_REPORT_TYPE', type);
  }
};

const getters = {
  selectedCategoryId: state => state.selectedCategoryId,
  selectedSubtopicCode: state => state.selectedSubtopicCode,
  selectedTopicId: state => state.selectedTopicId,
  report_type: state => state.report_type 
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

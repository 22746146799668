<template>
  <div>
    <b-overlay :show="loadingShow" no-wrap></b-overlay>
    <Invoice
      v-if="showInvoiceModal"
      :selectedPaymentId="selectedPaymentId"
      @closeInvoiceModal="showInvoiceModal = false"
    />
    <!-- <Invoice
      :selectedPaymentId="selectedPaymentId"
      @closeInvoiceModal="showInvoiceModal = false"
    /> -->
    <template>
      <div>
        <b-modal
          v-model="isReceiptOpen"
          title="매출 전표"
          size="lg"
          no-scrolling
          hide-footer
        >
          <iframe
            :src="receiptUrl"
            width="100%"
            height="700px"
            overflow-y="hidden"
          ></iframe>
        </b-modal>
      </div>
    </template>

    <h6 class="pb-1 mb-3 border-bottom-dark">
      이용 중인 서비스
    </h6>

    <div style="display: flex; justify-content: space-between;">
      <p
        class="f-120 fw-500"
        v-if="subscription_service_info_list && licenseInfo"
      >
        현재 사용중인 서비스는 '{{
          subscription_service_info_list[licenseInfo.subscriptionId][
            "service_name"
          ]
        }}({{ licenseInfo.subscriptionId }})' 입니다.
      </p>

      <p>
        <a href="/static_img/gri_notice.pdf" target="_blank">
          서비스 플랜 자세히 보기
        </a>
      </p>
    </div>

    <table style="width: 100%;">
      <tr>
        <td>
          <div v-if="licenseInfo">
            <b-card
              class="mt-4 mb-5"
              border-variant="primary"
              header-bg-variant="primary"
              header-text-variant="white"
              style="max-width: 30rem; border: none;"
              align="center"
            >
              <!-- <b-card-text>안녕하세요 {{ user_name }}님</b-card-text> -->
              <div v-if="licenseInfo.isUsed">
                <!-- {{ licenseInfo }} -->

                <b-card-text>
                  <table class="text-left">
                    <tr>
                      <th class="fw-500">
                        이용 서비스 명
                      </th>
                      <td>
                        {{ licenseInfo.subscriptionId }}
                      </td>
                    </tr>
                    <tr>
                      <th class="fw-500">최초 결제일</th>
                      <td>{{ licenseInfo.enrollment_date }}</td>
                    </tr>
                    <tr>
                      <th class="fw-500">마지막 결제일</th>
                      <td>{{ licenseInfo.lastPaymentDate }}</td>
                    </tr>
                    <tr>
                      <th class="fw-500">현재 서비스 이용 기간</th>
                      <td>{{ serviceDuration }}개월</td>
                    </tr>
                    <tr>
                      <th class="fw-500">
                        <span v-if="!licenseInfo.should_be_cancelled">
                          다음 결제일
                          <!-- {{ licenseInfo }} -->
                        </span>
                        
                        <span v-else class="red fw-600">
                          구독 만료일
                        </span>


                      </th>
                      <td v-if="!licenseInfo.should_be_cancelled" class="teal7 fw-600">
                        {{ nextPaymentDate }}
                      </td>
                      <td v-else class="red fw-600">
                        {{ nextPaymentDate }}
                      </td>
                    </tr>
                  </table>
                </b-card-text>
              </div>
            </b-card>
          </div>

          <div v-else>
            <h5>
              이용 중인 서비스가 없습니다.
            </h5>
          </div>
        </td>
        <td v-if="licenseInfo">
          <p style="text-align: end;">
            <a href="#" v-b-modal.need-help>정기결제 취소 문의</a>
          </p>
        </td>
      </tr>
    </table>

    <b-modal id="need-help" hide-footer size="" title="문의하기" scrollable>
      <need-help></need-help>
    </b-modal>
    <hr v-if="licenseInfo" />
    <br />
    <br />
    <br />

    <!-- <b-container fluid>
      {{ licenseInfo }}
    </b-container> -->

    <h6 class="pb-1 mb-3 border-bottom-dark">
      정기 결제 내역
    </h6>
    <b-container fluid v-if="subscriptingPayHistory.length > 0">
      <!-- Main table element -->
      <b-table
        :items="subscriptingPayHistory"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
        empty-filtered-html="검색 결과가 없습니다 😥"
        empty-text="정기 결제 내역이 없습니다."
      >
        <template #table-caption>
          총
          <span v-if="totalRows !== null" class="mb-0 red">{{
            totalRows
          }}</span>
          건의 내역이 조회됨
        </template>

        <template #cell(service_name)="row">
          <div
            v-b-tooltip.hover
            :title="subscriptionServiceDesc"
            @mouseover="updateSubscriptionServiceDesc(row.value)"
          >
            {{ subscription_service_info_list[row.value]["service_name"] }}
            <!-- ({{row.value}}) -->
          </div>
        </template>

        <template #cell(actions)="row">
          <div style="width: 6rem; display: flex;">
            <b-button
              v-if="row.item.amount_price !== 0"
              variant="info"
              style="padding: 0.2rem; height: 2rem; font-size: 0.9rem;"
              @click="
                showReceipt(
                  row.item.subscription_pay_history_id,
                  row.item.payment_status
                )
              "
              >매출전표</b-button
            >
            <b-button
              v-if="row.item.payment_status === 1 || row.item.amount_price === 0"
              variant="info"
              style="padding: 0.2rem; height: 2rem; font-size: 0.9rem;"
              class="ml-1"
              @click="
                showInvocie(
                  row.item.subscription_pay_history_id,
                  'subscription'
                )
              "
            >
              거래명세서
            </b-button>
            <!-- <b-button
            size="sm"
            @click="info(row.item, row.index, $event.target)"
            class="mr-1"
          >
            Info modal
          </b-button>
          <b-button size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? "Hide" : "Show" }} Details
          </b-button> -->
          </div>
        </template>

        <template #row-details="row">
          <b-card>
            <!-- Detail 부분 -->
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>

      <!-- Info modal -->
      <b-modal
        :id="infoModal.id"
        :title="infoModal.title"
        ok-only
        @hide="resetInfoModal"
      >
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <!-- User Interface controls -->

      <b-row>
        <b-col lg="4"></b-col>
        <b-col lg="4" class="my-1">
          <b-form-group
            label="검색"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="내역 검색"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- <b-col sm="5" md="8" class="my-5"> -->
        <b-col lg="4" sm="5" md="4" class="my-1">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm=""></b-col>
        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>

    <div v-else>
      <h5>정기 결제 내역이 없습니다.</h5>
    </div>

    <!-- {{ subscriptingPayHistory }}
    {{ subscriptingPayHistory.length }} -->

    <!-- <CancelSubscription class="mt-5" style="padding-top: 10rem;" /> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import CancelSubscription from "@/components/payment/CancelSubscription.vue";
import Invoice from "@/components/payment/Invoice.vue";
import { formatCurrency } from "@/components/payment/payment";
import ChannelService from "@/components/ChannelService";

export default {
  components: {
    CancelSubscription,
    Invoice,
  },
  data() {
    return {
      subscriptionServiceDesc: "",
      selectedPaymentId: null,
      showInvoiceModal: false,
      // 서비스 이용기간
      serviceDuration: 0,

      nextPaymentDate: null,
      isReceiptOpen: false,
      receiptUrl: "",
      subscriptingPayHistory: [],
      loadingShow: true,
      fields: [
        {
          key: "paid_time",
          label: "결제 일자",
          sortable: true,
          sortDirection: "text-center",
        },
        {
          key: "service_name",
          label: "결제 서비스 명",
          sortable: true,
          class: "text-center",
        },
        {
          key: "payment_status",
          label: "상태",
          formatter: (value, key, item) => {
            if (value === 0) return "결제실패";
            else if (value === 1) return "결제성공";
            else if (value === 2) return "결제대기";
            else if (value === 3) return "결제취소";
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "subscription_pay_history_id",
          label: "결제 코드",
          formatter: (value, key, item) => {
            return value.split("-")[1].substr(0, 8) + "**";
          },
          sortable: true,
          class: "text-center",
        },
        {
          key: "discount_code",
          label: "할인코드",
          formatter: (value, key, item) => {
            return value === null ? "(미적용)" : value;
          },
          sortable: true,
          class: "text-center",
        },
        {
          key: "pay_method_content",
          label: "지불수단",
          sortable: true,
          class: "text-center",
        },
        {
          key: "amount_price",
          label: "지불금액",
          sortable: true,
          class: "text-center",
          formatter: (value) => {
            return formatCurrency(value) + "원";
          },
        },
        {
          key: "subscription_pay_history_id",
          label: "결제 코드",
          sortable: true,
          class: "text-center",
        },
        { key: "actions", label: "영수증/거래명세서" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      subscription_service_info_list: null,
    };
  },
  computed: {
    ...mapState(["user_id", "e_mail", "user_name"]),
    ...mapState(["licenseInfo"]),

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  // beforeRouteLeave(to, from, next) {
  //   ChannelService.hideChannelButton();
  //   next();
  // },
  mounted() {
    // ChannelService.showChannelButton();

    // /payment/fetchSubscriptionServiceList
    const subscriptionServiceInfoPath =
      this.$store.state.backend_host + "/payment/fetchSubscriptionServiceList";
    axios
      .get(subscriptionServiceInfoPath)
      .then((res) => {
        this.subscription_service_info_list = res.data;
      })
      .catch((err) => {
        console.log(err);
      });

    // 다음 결제일을 계산합니다. (마지막 결제일로부터 30일)
    if (this.licenseInfo) {
      let nextDays = 30;
      if (this.licenseInfo.subscriptionId.split("_")[1] === "year") {
        nextDays = 365;
      }

      // lastPaymentDate를 기반으로 Date 객체 생성
      const lastPaymentDate = new Date(this.licenseInfo.lastPaymentDate);

      // nextDays를 추가하기
      lastPaymentDate.setDate(lastPaymentDate.getDate() + nextDays);

      // ISO 문자열로 변환 및 포맷팅
      this.nextPaymentDate = lastPaymentDate.toISOString().slice(0, 10);

      // 더 명확한 날짜 포맷팅
      const year = lastPaymentDate.getFullYear();
      const month = String(lastPaymentDate.getMonth() + 1).padStart(2, "0");
      const day = String(lastPaymentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      this.nextPaymentDate = formattedDate;


      
      const currentDate = new Date();
      const targetDate = new Date(this.licenseInfo.enrollment_date);

      // currentDate에서 targetDate까지의 전체 월 수 계산
      this.serviceDuration = (currentDate.getFullYear() - targetDate.getFullYear()) * 12 + (currentDate.getMonth() - targetDate.getMonth()) + 1;
    }

    this.fetchSubscriptionPayHistory();
    this.totalRows = this.subscriptingPayHistory.length;
  },
  methods: {
    updateSubscriptionServiceDesc(serviceId) {
      if (this.subscription_service_info_list) {
        const info = this.subscription_service_info_list[serviceId];

        const descText = `${info.service_name}상품입니다. ${info.billing_cycle}주기로, ${info.service_fee}원, ${info.available_report_cnt}개 사용가능한 서비스입니다.`;
        this.subscriptionServiceDesc = descText;
      }
    },
    async fetchSubscriptionPayHistory() {
      // /api/payment/get_user_subscription_paycheck_history
      try {
        const response = await axios
          .post(
            this.$store.state.backend_host +
              "/payment/get_user_subscription_paycheck_history",
            {
              user_id: this.e_mail,
            }
        )
  
        if (response) {
          this.loadingShow = false;
          this.subscriptingPayHistory = response.data.result;
          this.totalRows = this.subscriptingPayHistory.length;
          
        }
        
      } catch (e) {
        window.alert(e)
      }
      

    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showInvocie(payment_history_id, type) {
      // this.$router.push({ name: 'Invoice', query: { id: 123 } });
      this.showInvoiceModal = true;
      this.selectedPaymentId = payment_history_id;
    },
    showReceipt(payment_history_id, payment_status) {
      axios
        .get(
          this.$store.state.backend_host +
            `/payment/getPaymentData/pg_tid?merchant_uid=${payment_history_id}`
        )
        .then((response) => {
          if (response?.data?.code) {
            if (payment_status == 1) {
              this.receiptUrl = `https://iniweb.inicis.com/app/publication/apReceiptOpenMarket100.jsp?noTid=${response?.data.result}&noMerchantoid=&flag=0&noMethod=1&tStat=null&re_mail=null`;
            } else {
              this.receiptUrl = `https://iniweb.inicis.com/app/publication/apReceiptOpenMarket100.jsp?noTid=${response?.data.result}&noMerchantoid=&flag=1&noMethod=1&tStat=null&re_mail=null`;
            }
            //

            this.isReceiptOpen = true;
            // window.open(`https://iniweb.inicis.com/app/publication/apReceiptOpenMarket100.jsp?noTid=${response?.data.result}&noMerchantoid=&flag=0&noMethod=1&tStat=null&re_mail=null`, '_blank')
          } else {
            if (response?.data?.message) {
              window.alert(response?.data?.message);
            } else {
              window.alert(
                "매출전표를 가져오지 못했습니다. 관리자에게 문의해주세요."
              );
            }
          }
        });
    },
  },
};
</script>
<style lang=""></style>

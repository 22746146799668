<template>
    <div>
        <!-- 인쇄용 2줄 -->
        <div v-if="web_view !== true">
            <div class="d-lg-flex justify-content-between">
                <section v-for="topic_type in report_topic_types"
                    :key="topic_type.id"
                    class="content-section"
                    style="min-width: 49%"
                    >
                    <!-- Universal Disclosures -->
                    <div v-if="topic_type.topic_type_e === 'Universal Disclosures'">
                        <div v-for="category in report_universal_categories"
                            :key="category.category_id"
                            >
                            <div class="pb-1 my-2" :style="main_color"> GRI - {{ category.category_id }} {{ lang !== 'ENG' ? '일반 공개사항' : 'General Disclosures' }}</div>
                            <div v-for="subtopic, index in getUniqueSubtopicsByCategoryIds(category.category_id)"
                                :key="subtopic.subtopic_code"
                                class="pb-1 border-bottom"
                                > {{ index+1 }}. {{ lang!='ENG'? subtopic.subtopic_name : subtopic.subtopic_name_e }}
                            </div>
                            <hr class="border-0">
                        </div>
                    </div>
                    <!-- topic Specific Disclosures -->
                    <div v-else> 
                        <div v-for="category in report_topic_specific_categories"
                            :key="category.category_id"
                            >
                            <div class="pb-1 my-2" :style="main_color">
                                GRI {{lang!='ENG'? category.category_name : category.category_name_e }}
                            </div>
                            <div v-for="topic in getUniqueTopicsByCategoryIds(category.category_id)"
                                :key="topic.id"
                                class="pb-1 border-bottom"
                                >
                                {{ topic.topic_id }} : {{ lang == 'ENG' ? topic.topic_name_e : topic.topic_name }}
                                <materiality-badge :topic_id="topic.topic_id"></materiality-badge>
                            </div>
                            <hr class="border-0">
                        </div>
                    </div>
                </section>
            </div>
            <div>
                <b-badge variant="advance" class="ml-1"> M </b-badge>
                <span class="">: {{ lang !== 'ENG'? '중요주제 (Material Topic)':'Material Topic'}}</span>
            </div>
        </div>
        <!-- 웹용 4줄 -->
        <div v-else>
            <div class="mb-5 ">
                <!-- <b-button-group>
                    <b-button 
                        v-if="active_gri_table" 
                        @click="$emit('showTableView', 'GRI')" 
                        variant="dark-border"
                        size="lg"
                        :disabled = show_gri_table
                    >
                        GRI
                    </b-button>
    
                    <b-button 
                        v-if="active_sasb_table" 
                        @click="$emit('showTableView', 'SASB')" 
                         variant="dark-border"
                        size="lg"
                        :disabled = show_sasb_table
                    >
                        SASB
                    </b-button>
                    <b-button 
                        v-if="active_tcfd_table" 
                        @click="$emit('showTableView', 'TCFD')" 
                         variant="dark-border"
                        size="lg"
                        :disabled = show_tcfd_table
                    >
                        TCFD
                    </b-button>
                </b-button-group> -->
            </div>


            <div v-if="show_gri_table" class="d-lg-flex justify-content-between">
                <!-- GRI 100 -->
                <!-- GRI 100 -->
                <section class="content-section-compact">
                    <h6 class="pl-2 py-1 mb-3 text-white cursor" :style="main_bg_color" @click="navAnchor('#rp2')">Universal Standards</h6>
                    <div v-for="category in report_universal_categories"
                        :key="category.category_id"
                        class="mb-5"
                        >
                        <h6 class="pb-1 py-2 cursor" @click="navAnchor('#rp' + category.category_id)">GRI - {{  category.category_id }} {{lang!='ENG'? category.category_name : category.category_name_e }}</h6>
                        <div>
                            <div v-for="subtopic, index in getUniqueSubtopicsByCategoryIds(category.category_id)"
                                :key="subtopic.subtopic_code"
                                class="pb-1 border-bottom fw-300 cursor hover-gray"
                                @click="navAnchor('#rp'+subtopic.subtopic_code)"
                                >
                                {{ index+1 }}. {{ lang !== 'ENG' ? subtopic.subtopic_name : subtopic.subtopic_name_e }}
                            </div>
                            <hr class="border-0">
                        </div>
                    </div>
                </section>
                <!-- GRI 200 ~ 400 -->
                <!-- GRI 200 ~ 400 -->
                <section class="w-100 ml-lg-4" >
                    <h6 class="pl-2 py-1 mb-3 text-white cursor" :style="main_bg_color" @click="navAnchor('#rp200')" >Topic Standards Disclosures</h6>    
                    <template v-if="report_topic_specific_categories.length > 0">
                        <div class="d-lg-flex">
                            <div v-for="category in report_topic_specific_categories"
                                :key="category.category_id"
                                class="content-section-compact-sub"
                                >
                                <div class="pb-1 my-2 cursor" :style="main_color" @click="navAnchor('#rp' + category.category_id)">
                                    GRI Topic - {{ lang !== 'ENG' ? category.category_name : category.category_name_e }}
                                </div>
                                <div v-for="topic in getUniqueTopicsByCategoryIds(category.category_id)"
                                    :key="topic.id"
                                    class="pb-1 border-bottom fw-300 f-95 cursor hover-gray"
                                    @click="navAnchor('#trp'+topic.topic_id)"
                                    >
                                    {{ topic.topic_id }} : {{ lang=='ENG'? topic.topic_name_e : topic.topic_name }}
                                    <materiality-badge :topic_id="topic.topic_id"></materiality-badge>
                                </div>
                                <hr class="border-0">
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <b-skeleton class="mt-4 mb-3" width="50%"></b-skeleton>
                        <b-skeleton class="mb-2"></b-skeleton>
                        <b-skeleton class="mb-2"></b-skeleton>
                        <b-skeleton class="mt-5 mb-3" width="50%"></b-skeleton>
                        <b-skeleton class="mb-2"></b-skeleton>
                        <b-skeleton class="mb-2"></b-skeleton>
                    </template>
                </section>
            </div>
            <div v-if="report_topic_specific_categories.length > 0 && show_gri_table" class="f-95">
                <b-badge class=""> M </b-badge> <span class="">: {{ lang !== 'ENG' ? '중요주제 (Material Topic)' : 'Material Topic'}}</span>
            </div>

            <div v-if="$route.hash" class="mt-5">
                <b-button @click="navAnchor(null)" >
                    <b-icon-menu-button-fill /> {{ lang !== 'ENG' ? '전체보기' : 'Show All'}}
                </b-button>
            </div>

        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import * as ih from '@/components/util'
import MaterialityBadge from './MaterialityBadge'

export default {
    name: 'ContentsListBasicSkChemical',
    props:{
        web_view: {
            type: Boolean,
            default: false
        },
        preview_mode: {
            type: Boolean,
            default: false
        },
        content_list_open: {
            type:Boolean,
            default: false
        },
        active_gri_table: {
            type: Boolean,
            default: false,
        },
        active_sasb_table: {
            type: Boolean,
            default: false,
        },
        active_tcfd_table: {
            type: Boolean,
            default: false,
        },
        
        show_gri_table: {
            type: Boolean,
            default: false,
        },
        show_sasb_table: {
            type: Boolean,
            default: false,
        },
        show_tcfd_table: {
            type: Boolean,
            default: false,
        },
    },
    components:{
        MaterialityBadge,
    },
    data() {
        return {
            show_data_table: {},
            show_business_case: {},
            low_height: false,
        }
    },    
    computed:{
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapState('gri2021_report_view', ['report_structure', 'report_contents_loaded']),
        ...mapGetters('gri2021_report_view', ['report_topics', 'report_categories', 'report_topic_specific_categories', 'report_universal_categories', 'report_topic_types']),
        ...mapGetters('report_list', ['selected_report']),
        main_color(){
            return `color:`+this.selected_report.main_color
        },
        main_color_sub(){
            return `color:`+this.selected_report.main_color+`; border-top: 1px solid `+this.selected_report.main_color+`!important`+`; border-bottom: 1px solid `+this.selected_report.main_color
        },
        main_bg_color(){
            if(this.selected_report){
                return `background-color: `+ this.selected_report.main_color
            }
            else return `background-color: #494949`
        },
        
    },
    methods: {
        ...mapActions('gri2021_report_view', ['readReportContents']),
        getUniqueSubtopicsByCategoryIds(category_id){
        //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id == category_id )       
            let unique_subtopics = []
            filtered_structure.forEach( item => {
                if ( unique_subtopics.findIndex( x=> { return x.subtopic_code == item.subtopic_code }) < 0) {
                    unique_subtopics.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e, 
                        subtopic_code: item.subtopic_code, subtopic_name: item.subtopic_name, subtopic_name_e: item.subtopic_name_e })
                }
            })
            return unique_subtopics            
        },
        getUniqueTopicsByCategoryIds(category_id){
        //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id == category_id )       
            let unique_topics = []
            filtered_structure.forEach( item => {
                if ( unique_topics.findIndex( x=> { return x.topic_id == item.topic_id }) < 0) {
                    unique_topics.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e, 
                        topic_id: item.topic_id, topic_name: item.topic_name, topic_name_e: item.topic_name_e })
                }
            })
            return unique_topics
        },
        navAnchor(anchor){
            ih.anchorGo(anchor)
        },
    }
}
</script>

<style>
.slide-up-enter-active, .slide-up-leave-active{
     transition: all 0.3s ease;
}

.slide-up-enter-from,
.slide-up-leave-to {
  /* transform: translateY(10px); */
  opacity: 0;
}

.btn.custom-btn-hover{
    transition: all 0.15s ease;
    border: 0;
    width: 90%;
}

.custom-btn-hover:hover{
    opacity: 0.8;
    width: 96%;
}

.content-section-compact {
    min-width: 24%;
}

.content-section-compact-sub {
    min-width: 33%;
}

@media (max-width: 992px) {
    .btn.custom-btn-hover{
        width: 100%;
    }

    .content-section-compact,
    .content-section-compact-sub {
        width: auto;
    }
}
</style>

<template>
<div>
    <h4 id="is1">Universal Standards : GRI 100</h4>
    <p class="f-95 mb-4 gray-600">GRI Standards의 기본 보고사항을 선택합니다.</p>

    <b-card class="general-card mb-5 border-tealgray">
        <b-card-header class="d-flex justify-content-between border-bottom-tealgray">
            <h4 class="teal8">지표 선택 - GRI 102 <span class="fw-400 f-120 ml-1"> 일반 공개사항 General Disclosures</span></h4>
            <div><b-button @click="table_view=!table_view" variant="tealgray"><fa :icon="table_view==true?'th-large':'list'"></fa> </b-button></div>
        </b-card-header>
        <div class="py-3" v-for="(part, index) in series_100" :key="part.id">
            <h6 class="bg-tealgray150 tealgray500 pl-2 py-1" :class="table_view!=true?'mb-2':'mb-4'">{{index+1}}. {{ part.name }}</h6>
            <template v-if="has_selected_contents(index)==true||is_editing_indi==true">
                <b-table-simple v-if="table_view==true" responsive hover class="bor-table"> 
                    <b-thead><b-tr>
                        <b-td v-if="is_editing_indi==true">선택</b-td><b-td>지표번호</b-td><b-td>지표명</b-td><b-td style="width: 65%;">보고 요구사항</b-td>
                    </b-tr></b-thead>
                    <b-tbody v-if="is_editing_indi==true">
                        <b-tr v-for="item in series_contents(index)" :key="item.id">
                            <b-td><b-form-checkbox v-model="item.selected" :value="1" :unchecked-value="0"></b-form-checkbox></b-td>
                            <b-td>{{ item.gri_code }}</b-td>
                            <b-td>{{ item.indicator_name }}</b-td>
                            <b-td class="position-relative">
                                <div class="f-90 gray-600 fw-300 overflow-hidden" style="max-height: 26px; max-width: calc(100% - 50px)">
                                    <md :contents="item.indicator_description" ></md>
                                </div>
                                <b-collapse :id="item.gri_code+'txt'" class="f-90 gray-600 fw-300 overflow-hidden" style="margin-top: -26px; max-width: calc(100% - 50px)">
                                    <md :contents="item.indicator_description"></md>
                                </b-collapse>
                                <b-button v-if="item.indicator_description.length > 40" id="indi-readmore" variant="icon-gray" class="py-0 f-110" v-b-toggle="item.gri_code+'txt'"> </b-button>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody v-else>
                        <b-tr v-for="item in series_contents(index)" :key="item.id">
                            <template v-if="item.selected==1">
                                <b-td>{{ item.gri_code }}</b-td>
                                <b-td>{{ item.indicator_name }}</b-td>
                                <b-td class="position-relative">
                                    <div class="f-90 gray-600 fw-300 overflow-hidden" style="max-height: 26px; max-width: calc(100% - 50px)">
                                        <md :contents="item.indicator_description" ></md>
                                    </div>
                                    <b-collapse :id="item.gri_code+'txt'" class="f-90 gray-600 fw-300 overflow-hidden" style="margin-top: -26px; max-width: calc(100% - 50px)">
                                        <md :contents="item.indicator_description"></md>
                                    </b-collapse>
                                    <b-button v-if="item.indicator_description.length > 40" id="indi-readmore" variant="icon-gray" class="py-0 f-110" v-b-toggle="item.gri_code+'txt'"> </b-button>
                                </b-td>
                            </template>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <div v-else class="d-flex flex-wrap" >
                    <template v-if="is_editing_indi==true" >                        
                        <div v-for="item in series_contents(index)" :key="item.id" class="pr-3" style="width: 120px">
                            <div class="d-flex align-items-end">
                                <b-form-checkbox v-model="item.selected" :value="1" :unchecked-value="0" class=""></b-form-checkbox>
                                <p class="fw-400 mb-0 f-120">{{ item.gri_code }}</p> 
                            </div>
                            <p class="pt-1 mb-2 f-85 line-height-sm">{{ item.indicator_name }}</p>
                        </div>
                    </template>
                    <template v-else>                        
                        <div v-for="item in series_contents(index)" :key="item.id" class="pr-3" style="width: 120px">
                            <template v-if="item.selected==1">
                                <p class="fw-400 mb-1">{{ item.gri_code }}</p> <p class="pt-1 mb-2 gray-500 f-85 line-height-sm">{{ item.indicator_name }}</p>
                            </template>
                        </div>                        
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="gray-500 text-center bgray-200 py-2"> 선택된 지표가 없습니다.</div>
            </template>
        </div>
        <div class="py-4" :class="is_editing_indi==true ?'position-sticky indi-save-sticky':''">
            <b-button :variant="is_editing_indi==true?'teal':'teal-border'" 
            class="px-3 mr-3" 
            @click.stop.prevent="handleMultiIndi('#is1')"
            > GRI 100 지표 {{ is_editing_indi==true ?' 저장하기':` 선택하기`}} </b-button>
            <b-button v-if="is_editing_indi==true" class="px-3" @click="handleCancleIndi('#is1')"> 취소 </b-button>
        </div>
    </b-card>

</div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import * as ih from '@/components/util'
import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'


export default {
    mixins: [gri_mixin],
    data() {
        return {
            local_100_indicators: [],
            is_editing_indi: false,
            anchor_to:'',
            table_view: true
        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapGetters('report_list', ['selected_report']),
        ...mapState('report_contents', ['series_100']),
        ...mapState('report_indicators', ['indicators_list', 'topics_list']),
        ...mapGetters('report_indicators', ['gri_100_all_indicators']),
        series_contents(){
            return(index)=>{
                let temp_list = []
                for (let i=0; i<this.series_100[index].codes.length; i++){
                    let idx = this.gri_100_all_indicators.findIndex(x => x.gri_code == this.series_100[index].codes[i])
                    if (idx > -1) {                      
                        temp_list.push(this.gri_100_all_indicators[idx])
                    }
                }
                return temp_list
            }
        },
        has_selected_contents(){
            return(index)=>{
                let jdx = this.series_contents(index).findIndex(w => w.selected==1)
                if (jdx >-1){
                    return true
                }else return false
            }
        }
    },
    methods:{
        ...mapActions('report_indicators', ['linkReportIndicators']),
        ...mapActions('report_indicators', ['checkGriOption']),

        async handleSave(){
            this.makeProcessingToast()
            let new_link_payload = {}
            for(let i=0; i<this.gri_100_all_indicators.length; i++){
                let temp_gri_code = this.gri_100_all_indicators[i].gri_code
                new_link_payload[temp_gri_code] = this.gri_100_all_indicators[i].selected
            }            
            // console.log(new_link_payload);
            await this.linkReportIndicators({
                report_code: this.report_code,
                link_payload : new_link_payload
            })
            this.$EventBus.$emit('make-toast', '지표 저장', '선택한 지표를 저장하였습니다.')
            this.handleCancleIndi()
            this.checkGriOption(this.report_code)

            // console.log(this.gri_100_all_indicators);
        },
        initiateIndicators(){
            this.local_100_indicators = ih.deepCopy(this.gri_100_all_indicators)
        },
        async handleMultiIndi(anchor) {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return

            ih.anchorGo(anchor)
            // if (this.selected_report.state<4){
                if(this.is_editing_indi==true){
                    await this.handleSave()
                }
                else {
                    this.toggleEditIndi()
                }
            // }else alert(`공개된 보고서 입니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 지표를 수정할 수 있습니다.`)
        },
        handleCancleIndi(anchor){
            ih.anchorGo(anchor)
            this.initiateIndicators()
            this.toggleEditIndi()
        },
        toggleEditIndi(){
            this.is_editing_indi = !this.is_editing_indi
        }, 
        makeProcessingToast(){
            this.$bvToast.toast(`선택한 지표를 저장하고 있습니다.`, {
                title: '지표 저장중',
                autoHideDelay: 2000,
                appendToast: false,
                toaster: 'b-toaster-bottom-center',
            })
        },
    },
    beforeMount(){
       this.initiateIndicators()
    },
    mounted(){
        // console.log(this.series_100);
        // console.log(this.indicators_list);
        // console.log(this.gri_100_all_indicators);
    },
    watch:{
        // series_contents(newVal){
            // console.log(newVal);
        // }
    }

}
</script>

<style>

</style>
<template>
<div class="position-relative overflow-hidden" :style="'font-family: '+this.selected_report.font_family">
    <div class="position-relative">
        <b-navbar toggleable="lg" class="position-fixed">
            <b-navbar-brand v-if="selected_report.logo_path!=null">
                <b-img :src="$store.state.static_host+selected_report.logo_path" class="report-logo-xs"></b-img>
            </b-navbar-brand>
            <b-navbar-toggle target="preview-tools"></b-navbar-toggle>

            <b-collapse id="preview-tools" is-nav>
                <b-navbar-nav>
                <b-button variant="dark-border" size="lg" class="p-0 mr-2 preview-tool-btn" v-print="'#full-report-preview'"
                    v-b-tooltip.hover :title="lang!='ENG'? '출력하기':'Print to PDF'"> <fa icon="print"/></b-button>
                    <b-button variant="dark-border" size="lg" class="p-0 mr-2 preview-tool-btn" @click="toggleLang" v-b-tooltip.hover :title="lang!='ENG'?'영문보기':'Korean'"> <fa icon="language"/></b-button>  
                    <b-button v-if="view_mode!='viewer'" variant="dark-border" size="lg" class="p-0 mr-2 preview-tool-btn" @click="$router.push({name: 'CheckReport'})" v-b-tooltip.hover title="미리보기 닫기"> <fa icon="times"/></b-button> 
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <!-- <b-button variant="dark-border" size="lg" class="p-0 preview-tool-btn position-fixed web-preview-header" v-b-toggle.preview-tools.preview-navs> <fa icon="chevron-down"/></b-button>
        <b-collapse id="preview-tools" class="position-fixed pt-3 web-preview-header-bar">
            <b-button variant="dark-border" size="lg" class="p-0 preview-tool-btn" v-print="'#full-report-preview'"
            v-b-tooltip.hover.right :title="lang!='ENG'? '출력하기':'Print to PDF'"> <fa icon="print"/></b-button>
            <b-button variant="dark-border" size="lg" class="p-0 mb-3 preview-tool-btn" @click="toggleLang" v-b-tooltip.hover.right :title="lang!='ENG'?'영문보기':'Korean'"> <fa icon="language"/></b-button>  
            <b-button v-if="view_mode!='viewer'" variant="dark-border" size="lg" class="p-0 preview-tool-btn" @click="$router.push({name: 'CheckReport'})" v-b-tooltip.hover.right title="미리보기 닫기"> <fa icon="times"/></b-button>            
        </b-collapse> -->

        <!-- 아래 주석 별도 컴포넌트로 따기 -->
        <!-- <b-collapse visible id="preview-navs"> 
            <b-button-group vertical class="position-fixed web-preview-nav" v-if="show_nav==true">
                <b-button variant="icon-txt" class="text-right f-90 pt-1 hover-slide" @click="navAnchor('#rp100')"> {{lang!='ENG'?'일반': 'General Disclosures'}} - GRI 100</b-button>
                <b-button v-if="gri_200_all_contents.length>0" variant="icon-txt" class="text-right f-90 pt-1 hover-slide" @click="navAnchor('#rp200')">{{ lang!='ENG'?'경제': 'Economic' }} - GRI 200</b-button>
                <b-button v-if="gri_300_all_contents.length>0" variant="icon-txt" class="text-right f-90 pt-1 hover-slide" @click="navAnchor('#rp300')">{{ lang!='ENG'?'환경': 'Environmental' }} - GRI 300</b-button>
                <b-button v-if="gri_400_all_contents.length>0" variant="icon-txt" class="text-right f-90 pt-1 hover-slide" @click="navAnchor('#rp400')">{{ lang!='ENG'?'사회': 'Social' }} - GRI 400</b-button>
                <b-button v-if="show_table_list==true" variant="icon-txt" class="text-right f-90 pt-1 hover-slide" @click="navAnchor('#rpt0')"><fa icon="table" class="mr-1"/> {{ lang!='ENG'?'데이터 테이블': 'Data Tables' }} </b-button>
                <b-button v-if="show_business_case_list==true" variant="icon-txt" class="text-right f-90 pt-1 hover-slide" @click="navAnchor('#rpb0')"><fa icon="quote-left" class="mr-1"/> {{ lang!='ENG'?'비즈니스 사례': 'Business Cases' }} </b-button>
                <b-button v-if="request_status==6" variant="icon-txt" class="text-right f-90 pt-1 hover-slide" @click="navAnchor('#rpa0')"><fa icon="award" class="mr-1"/> {{ lang!='ENG'?'제3자 검증의견서': 'Independent Assurance Statement' }} </b-button>
            </b-button-group>
        </b-collapse> -->

        <div class="bg-white" style="height:40vh">
            <viewer-header></viewer-header>
        </div>

        <div class="bgray-200 min-vh-100">
            <b-container class="px-5 py-5">
                <b-card class="p-4 my-auto">
                    <h3 class="mb-5">Contents</h3>
                    <contents :preview_mode="true" :web_view="true"></contents>
                </b-card>
            </b-container>
        </div>
        
        <div>
            <b-container class="py-5 bg-white" v-view="viewHandlerNav"> 
                <statement-of-use></statement-of-use>
                <!-- <b-row>
                    <b-col class="col-3">
                        <viewer-side-nav></viewer-side-nav>
                    </b-col>
                    <b-col class="col-9" v-view="viewHandlerNav"> -->
                        <gri-table :modal_table="false" ></gri-table>
                    <!-- </b-col>
                </b-row> -->
                <hr class="border-0 my-5 py-5">
            </b-container>    
            
            <!-- <b-container class="py-5 px-5 bgray-100" v-if="assurance_status==6">
                <template v-if="show_table_list==true">
                    <h3 id="rpt0" class="pt-3 mb-5">Appendix 1. {{ lang!='ENG'?'데이터 테이블': 'Data Tables' }}</h3>
                    <appendix-data-tables></appendix-data-tables>
                    <hr class="mt-5 pb-5">
                </template>            

                <template v-if="show_business_case_list==true">
                    <h3 id="rpb0" class="pt-3 mb-5">Appendix {{show_table_list==true?'2.': '1.'}} {{ lang!='ENG'?'비즈니스 사례': 'Business Cases' }}</h3>
                    <appendix-business-cases class="pt-3"></appendix-business-cases>
                    <hr class="mt-5 pb-5">
                </template>    

                    <h3 id="rpa0" class="pt-3 mb-5">Appendix. {{ lang!='ENG'?'제3자 검증의견서': 'Independent Assurance Statement' }}</h3>
                    <view-assurance-statement :preview_mode="true"></view-assurance-statement>
            </b-container>  -->
        </div>
            <hr class="border-0 m-0 py-5">

        <div class="px-4 px-lg-5 py-5 gray-550 f-90 d-flex justify-content-between">
            <div class="tail-line" style="width: calc(100% - 200px);">{{lang!='ENG'? selected_report.report_name : selected_report.report_name_e}} </div>
            <div v-if="selected_report.logo_path!=null"><b-img :src="$store.state.static_host+selected_report.logo_path" class="report-logo-small"></b-img></div>
        </div>
    </div>

    <!-- 인쇄영역 -->
    <div class="position-absolute w-100" style="left: 0;top:0; margin-left:2000px;">
        <div id="full-report-preview">
            <b-container class="px-6 vh-100">
                <b-row no-gutters class="h-100">
                    <b-col cols="6" class="pl-3">
                        <h1 class="f-300 mb-5 line-height-lg" style="padding-top: 10vh">{{ lang!='ENG'? selected_report.report_name : selected_report.report_name_e }}</h1>
                        <h6 class="">{{ selected_report.start_date }} ~ {{ selected_report.end_date }}</h6>
                    </b-col>
                    <b-col class="d-flex justify-content-end align-items-end pr-5">
                        <div v-if="selected_report.logo_path!=null" style="padding-bottom: 7vh"><b-img :src="$store.state.static_host+selected_report.logo_path"  class="report-logo-regular"></b-img></div>
                    </b-col>
                </b-row>
            </b-container>
                
            <div class="bgray-200">
                <b-container class="px-5 py-5 min-vh-100">
                    <b-card class="p-4 my-auto">
                        <h3 class="mb-5">Contents</h3>
                        <contents></contents>
                    </b-card>
                </b-container>
            </div>

            <b-container class="px-5 py-5"> 
                <gri-table :modal-table="false"></gri-table>
                <hr class="border-0 my-5 py-5">
            </b-container>    

            <b-container class="py-5 px-5 bgray-100" v-if="assurance_status==6">
                <!-- <template>
                    <h3 id="rpb0" class="pt-3 mb-5">Appendix {{show_table_list==true?'2.': '1.'}} {{ lang!='ENG'?'비즈니스 사례': 'Business Cases' }}</h3>
                    <appendix-business-cases class="pt-3"></appendix-business-cases>
                    <hr class="mt-5 pb-5">
                </template>     -->
                
                <template>
                    <h3 id="rpa0" class="pt-3 mb-5">Appendix. {{ lang!='ENG'?'제3자 검증의견서': 'Independent Assurance Statement' }}</h3>
                    <view-assurance-statement :preview_mode="true"></view-assurance-statement>
                </template> 
            </b-container>
            
            <!-- <b-container class="py-5 px-5 bgray-100">
                <template>
                    <h3 id="rpt0" class="pt-3 mb-5">Appendix 1. {{ lang!='ENG'?'데이터 테이블': 'Data Tables' }}</h3>
                    <appendix-data-tables></appendix-data-tables>
                    <hr class="mt-5 pb-5">
                </template>            

               
            </b-container>  -->

            <hr class="border-0 m-0 py-5">

            <div class="px-4 px-lg-5 py-5 gray-550 f-90 d-flex justify-content-between">
                <div class="tail-line" style="width: calc(100% - 200px);">{{lang!='ENG'? selected_report.report_name : selected_report.report_name_e}} </div>
                <div v-if="selected_report.logo_path!=null"><b-img :src="$store.state.static_host+selected_report.logo_path"  class="report-logo-small"></b-img></div>
            </div>
        </div>
    </div>
</div>
</template>
    
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import * as ih from '@/components/util'
import DataTable from './viewer_components/DataTable.vue'
import Document from './viewer_components/Document.vue'
import BusinessCase from './viewer_components/BusinessCase.vue'
import MaterialityBadge from './viewer_components/MaterialityBadge.vue'
import Contents from './viewer_components/ContentsListIndicator.vue'
import GriTable from './viewer_components/GRITable2021.vue'
import AppendixDataTables from './viewer_components/AppendixDataTables.vue'
import AppendixBusinessCases from './viewer_components/AppendixBusinessCases.vue'
import ViewAssuranceStatement from '@/components/report_preview/appendix_subs/ViewAssuranceStatement'
import ViewerSideNav from '@/components/report_preview/gri2021_viewer/viewer_components/ViewerSideNav.vue'
import StatementOfUse from './viewer_components/StatementOfUse.vue'

export default {
    components:{
        DataTable,
        Document,
        BusinessCase,
        MaterialityBadge,
        Contents,
        "gri-table" : GriTable,
        AppendixDataTables,
        AppendixBusinessCases,
        ViewAssuranceStatement,
        ViewerSideNav,
        StatementOfUse
    },
    data() {
        return {
            low_height: false,
            show_nav: true
        }
    },    
    props:{
        show_table_list: { type: Boolean, default: false },
        show_business_case_list: { type: Boolean, default: false },
        reload: { type: Boolean, default: false }
    },
    computed:{
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapState('gri2021_report_view', ['report_structure', 'report_contents_loaded']),
        ...mapGetters('gri2021_report_view', ['report_topics', 'report_categories', 'report_topic_specific_categories', 'report_universal_categories', 'report_topic_types']),
        ...mapGetters('report_list', ['selected_report']),
        ...mapState('assurance',['assurance_status']),
        main_color(){
            return `color:`+this.selected_report.main_color
        },
        main_bg_color(){
            return `background-color:`+this.selected_report.main_color+`;important!`
        },
        main_color_sub(){
            return `color:`+this.selected_report.main_color+`; border-top: 1px solid `+this.selected_report.main_color+`!important`+`; border-bottom: 1px solid `+this.selected_report.main_color
        },
    },
    methods: {
        ...mapMutations('gri2021_report_view', ['update_report_contents_loaded']),
        ...mapMutations('report_list', ['change_lang']),
        ...mapActions('gri2021_report_view', ['readReportContents']),
        ...mapActions('data_tables', ['readDataTableList']),
        ...mapActions('business_cases', ['readBusinessCaseList']),
        ...mapActions('documents', ['readDocumentList']),
        ...mapActions('assurance', ['readAssuranceStatusByReportCode']),
        toggleLang(){
            if (this.lang=='KOR'){ //현재 국문이면 영문으로 바꾸기
                this.change_lang('ENG')
            }else this.change_lang('KOR')
        },
        viewHandlerNav(event){
            // console.log(event);
            if(event.type != 'progress'&&event.scrollPercent > 0.1) {
                // if( event.percentCenter >3.5 ) { 
                    this.show_nav = true
                // }
            }
            else this.show_nav = false
        },
    },
    created(){
        if(this.reload==true){
            this.update_report_contents_loaded(false)
        }

        if(this.report_contents_loaded != true){
            //console.log('FULL_VIEW_LOAD')
            this.readAssuranceStatusByReportCode({report_code: this.report_code})
            this.readReportContents({report_code : this.report_code})
            this.readDataTableList({report_code: this.report_code})
            this.readBusinessCaseList({report_code: this.report_code})
            this.readDocumentList({report_code: this.report_code})
        }
    },
    beforeDestroy(){
    }
}
</script>
<template>
    <div>
        <b-row>
            <b-col cols="3">
                <p class="mt-1 ml-3">
                    {{ name }}
                </p>
            </b-col> 
            <b-col cols="5">
                <b-input v-model="content" :disabled="!edit_mode" />
            </b-col>
            <b-col cols="4">
                <b-button v-if="!edit_mode" size="sm" variant="teal-border" @click="edit_mode=true"> 번역 수정하기 </b-button>
                <div v-else>
                    <b-button size="sm" variant="teal" class="mr-2" @click="update_toc_content(true)"> 
                        번역 초기화 <fa icon="redo" :class="initiating === true ? 'spinning-icon' : ''"/>
                    </b-button>
                
                    <b-button size="sm" variant="red" class="mr-2" @click="update_toc_content(false)"> 저장</b-button>
                    <b-button size="sm" class="mr-2" @click="cancelEdit"> 취소</b-button>


                    <div class="f-90 blue">
                        <template v-if="saving">
                            <b-spinner class="blue" small></b-spinner> 저장 중
                        </template>
                        <template v-if="saved">
                            <fa icon="check-circle" class="ml-1" />저장 완료
                        </template>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
    name: '',
    props: {
        name : { type: String, default: "" },
        name_e : { type: String, default: "" },
        lv1_info : { type: Object, default: null },
        lv2_info: { type: Object, default: null },

    },
    components: {},
    computed: {
        ...mapState("report_list", ["report_code"]),

    },
    data() {
        return {
            example: '',
            edit_mode: false,
            initiating: false,
            content: this.name_e,
            saving: false,
            saved: false
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {
        ...mapMutations('charts', ['update_toc_list']),
        cancelEdit() {
            this.edit_mode = false


            this.content = this.name_e
        },
        async update_toc_content(re_initiate) {

            this.saving = true

            // update_toc_content
            try {
                const response = await axios.post(this.$store.state.backend_host + "/update_parent_toc_content", {
                    "report_code": this.report_code,
                    "re_initiate": re_initiate,
                    "payload": {
                        "lv1_info": this.lv1_info,
                        "lv2_info": this.lv2_info,
                        content: this.content
                    }
                })

                this.update_toc_list(response.data.result.contents)

                this.edit_mode = false;
                this.content = response.data.result.new_content;

                
            } catch (error) {
                console.log(error)
            }

            this.saving = false
            this.saved = true
            setTimeout(() => {
                this.saved = false;
            }, 2000);

        }
    }
}
</script>
<style>
</style>
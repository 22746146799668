var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"mb-5"},[_vm._v("Data Center")]),_vm._l((_vm.toc_list),function(lv1_info,lv1_idx){return _c('div',{key:'toc_lv1_' + lv1_idx,staticClass:"mb-5"},[_c('h3',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(lv1_info.name)+" "),_c('span',{staticClass:"gray-500 f-105 fw-400 ml-1"},[_vm._v(" "+_vm._s(lv1_info.name_em ? lv1_info.name_em : lv1_info.name_ea)+" ")])]),_vm._l((lv1_info.children),function(lv2_info,lv2_idx){return _c('div',{key:'toc_lv2_' + lv2_idx},[_c('h5',{staticClass:"fw-600 mb-4 border-bottom-dark pb-2"},[_vm._v(" "+_vm._s(lv2_info.name)+" "),_c('span',{staticClass:"gray-500 f-105 fw-400 ml-1"},[_vm._v(" "+_vm._s(lv2_info.name_em ? lv2_info.name_em : lv2_info.name_ea)+" ")])]),_c('b-table-simple',{staticClass:"wri-table",attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-td',{staticStyle:{"width":"14%"}},[_vm._v("지표")]),_c('b-td',{staticStyle:{"width":"34%"}},[_vm._v("국문")]),_c('b-td',[_vm._v("번역하기")])],1)],1),_c('b-tbody',_vm._l((lv2_info.children),function(lv3_info,lv3_index){return _c('b-tr',{key:lv3_index},[_c('b-td',[_c('span',{staticClass:"f-120"},[_vm._v(" "+_vm._s(lv1_info.name)+" ")]),_c('p',{staticClass:"f-100"},[_vm._v(" "+_vm._s(lv2_info.name)+" ")])]),_c('b-td',[_c('div',{staticClass:"f-90 pb-3",staticStyle:{"word-wrap":"break-word","white-space":"no-wrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(lv3_info.contents)+" ")]),(lv3_info.type==='table')?_c('section',{staticClass:"mt-5 pr-3"},[_c('div',[_c('div',{staticClass:"f-90 mb-2 fw-500"},[_c('fa',{staticClass:"mr-1",attrs:{"icon":"table"}}),_vm._v(" "+_vm._s(_vm.hashed_data_tables[lv3_info.item_id].title)+" ")],1),_c('b-table-simple',{staticClass:"bor-bg-table",attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',_vm._l((_vm.hashed_data_tables[
                                lv3_info.item_id
                              ].header),function(property,col_index){return _c('b-td',{key:col_index},[_vm._v(_vm._s(property))])}),1)],1),_c('b-tbody',_vm._l((_vm.hashed_data_tables[lv3_info.item_id]
                              .contents),function(row_item,row_index){return _c('b-tr',{key:row_item.id},[_vm._l((_vm.hashed_data_tables[lv3_info.item_id].header),function(property){return [(
                                  _vm.differentPrevious(
                                    _vm.hashed_data_tables[lv3_info.item_id].contents,
                                    row_index,
                                    property,
                                    _vm.hashed_data_tables[lv3_info.item_id].header
                                  ) == true
                                )?_c('b-td',{key:property.id,attrs:{"rowspan":_vm.getSameLength(
                                    _vm.hashed_data_tables[lv3_info.item_id].contents,
                                    row_index,
                                    property,
                                    _vm.hashed_data_tables[lv3_info.item_id].header
                                  )}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formattedText(row_item[property]))}})]):_vm._e()]})],2)}),1),_c('caption',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.hashed_data_tables[lv3_info.item_id].footnote)+" ")])],1)],1)]):(lv3_info.type==='chart')?_c('section',[(lv3_info.item_id !=='' && lv3_info.type ==='chart')?_c('ChartTextCanvas',{key:`chart_${lv3_info.item_id}`,staticClass:"mb-5",attrs:{"chart_id":lv3_info.item_id,"viewer_mode":true}}):_vm._e()],1):_vm._e()]),_c('b-td',[_c('TranslateDC',{attrs:{"lv1_key":lv1_info.key,"lv2_key":lv2_info.key,"lv3_info":lv3_info}}),(lv3_info.item_id !=='' && lv3_info.type==='table')?_c('section',{staticClass:"mt-4 pr-3 border-right pt-2"},[_c('div',{staticClass:"f-90 mb-2 fw-500 d-flex justify-content-between align-items-start"},[_c('div',[_c('fa',{staticClass:"mr-1",attrs:{"icon":"table"}}),_vm._v(" "+_vm._s(_vm.hashed_data_tables[lv3_info.item_id].title_e)+" ")],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"py-1 px-2",attrs:{"variant":"teal-border","size":"sm","title":"번역 수정하기"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.translateTable(lv3_info.item_id)}}},[_c('fa',{attrs:{"icon":"pen"}})],1)],1),_c('b-table-simple',{staticClass:"bor-bg-table",attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',_vm._l((_vm.hashed_data_tables[lv3_info.item_id].header_e),function(property,col_index){return _c('b-td',{key:col_index},[_vm._v(_vm._s(property))])}),1)],1),_c('b-tbody',_vm._l((_vm.hashed_data_tables[lv3_info.item_id]
                            .contents_e),function(row_item){return _c('b-tr',{key:row_item.id},_vm._l((_vm.hashed_data_tables[lv3_info.item_id]
                            .header_e),function(property){return _c('b-td',{key:property.id},[_vm._v(_vm._s(row_item[property]))])}),1)}),1)],1),_c('div',{staticClass:"f-85 gray-600"},[_vm._v(" "+_vm._s(_vm.hashed_data_tables[lv3_info.item_id].footnote_e)+" ")])],1):_vm._e()],1)],1)}),1)],1)],1)})],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
      <h4>TCFD</h4>
      <p class="f-95 mb-5 gray-600">
        TCFD의 각 보고 지표에 대해 내용을 작성합니다. 
      </p>
      
      <WriteLoading 
        v-if="loading"
      />
      <section v-else>
        <div
          v-for="(category_info, category_idx) in tcfd_indicator_list"
          :key="category_idx"
          class="mb-5"
        > 
          <h5 :id="`w${category_info['category_code']}`" class="fw-600 mb-4 border-bottom-dark pb-2">
              {{ category_info['category_name']  }}
            <span class="gray-500 f-105 fw-400 ml-1">{{ category_info['category_name_e'] }}</span>

          </h5>
          <b-table-simple responsive class="wri-table">
            <b-thead>
              <b-tr>
                <b-td>지표</b-td>
                <b-td style="width: 45%">
                  <div class="d-inline-block float-right mx-2 f-90 green">
                    <fa icon="check-circle" class="ml-1" /> : 저장됨
                  </div>
                </b-td>
                <b-td style="width: 40%">작성 도우미</b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(indicator_info, indicator_idx) in category_info.indicators"
                :id="`w${indicator_info['tcfd_code']}`"
                :key="indicator_idx"
              >
                <b-td>
                  <p class="f-110 fw-600">
                    {{ indicator_info['tcfd_code'] }}
                  </p>
                  <p class="">{{ indicator_info.indicator_name }}</p>
                </b-td>

                <b-td>
                  <div
                    v-if="!is_writing_direct[indicator_info['tcfd_code']]"
                    @click.stop.prevent="
                      handleEdit(selected_report.is_able_to_edit) &&
                        changeWritingMode(indicator_info['tcfd_code'])
                    "
                    :class="
                      selected_report.is_able_to_edit === true
                        ? 'mb-3'
                        : 'is_not_able_to_edit_form mb-3'
                    "
                  >
                    <!-- 내용 작성 X, 보여주기 상태 -->
                    <md
                      v-if="indicator_info.direct_report"
                      :contents="indicator_info.direct_report"
                      :class="
                        selected_report.is_able_to_edit === true
                          ? 'hover-border pb-4'
                          : ''
                      "
                      style="
                        word-wrap: break-word;
                        white-space: no-wrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                      내용작성x
                    </md>
                    <div
                        v-if="!indicator_info.direct_report && !selected_report.is_able_to_edit"
                        class="gray-500 rounded pb-4"
                      >
                        작성된 내용이 없습니다.
                      </div>

                    <template
                      v-if="save_state[indicator_info['tcfd_code']] === 'saved'"
                      ><fa icon="check-circle" class="ml-1 green"
                    /></template>
                    <template
                      v-else-if="
                        save_state[indicator_info['tcfd_code']] === 'failed'
                      "
                      ><b-icon
                        icon="x"
                        scale="1.5"
                        variant="danger"
                        animation="fade"
                      /> <span class="gray-500 f-105 fw-400 ml-1">저장 실패(다시 시도해주세요)</span></template
                    >
                    <template
                      v-else-if="
                        save_state[indicator_info['tcfd_code']] == 'saving'
                      "
                      ><b-spinner class="green" small></b-spinner
                    ></template>

                    <div v-else>
                      <div
                        v-if="
                          selected_report.is_able_to_edit &&
                          !indicator_info.direct_report
                        "
                        class="hover-border gray-500 rounded pb-4"
                      >
                        클릭하여 작성하기
                      </div>
                      
                    </div>
                  </div>

                  <div v-else class="mb-45">
                    <!-- 내용 작성 시  -->
                    <b-form-textarea
                      v-model="indicator_info.direct_report"
                      rows="2"
                      placeholder="지표에 대해 보고할 내용을 직접 작성합니다."
                      @blur="
                        textInputSave(
                          indicator_info['tcfd_code'],
                          indicator_info.direct_report
                        )
                      "
                      :autofocus="
                        is_writing_direct[indicator_info['tcfd_code']]
                      "
                      lazy="true"
                    />
                    <template
                      v-if="save_state[indicator_info['tcfd_code']] === 'saved'"
                      ><fa icon="check-circle" class="ml-1 green"
                    /></template>
                    <template
                      v-else-if="
                        save_state[indicator_info['tcfd_code']] === 'failed'
                      "
                      ><b-icon
                        icon="x"
                        scale="1.5"
                        variant="danger"
                        animation="fade"
                      /> <span class="gray-500 f-80 fw-400 ml-1">저장 실패(다시 시도해주세요)</span></template
                    >
                    <template
                      v-else-if="
                        save_state[indicator_info['tcfd_code']] == 'saving'
                      "
                      ><b-spinner class="green" small></b-spinner
                    ></template>
                  </div>

                  <!-- ======================================================================================================================================= -->
                  <!-- 연결된 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
                <!-- 여기에 복붙 -->
                <section class="mb-5 px-3" v-if="indicator_info.data_tables.length>0 && hashed_data_tables">
                      <div class="border-bottom-gray-550 gray-600 f-90 pb-1"><fa icon="table" class="mr-1"/> 데이터 테이블</div>
                      <div v-for="dt in indicator_info.data_tables" :key="dt.id" > 
                          <div class="mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center"> <span v-if="hashed_data_tables && hashed_data_tables[dt]">{{ hashed_data_tables[dt].title }} </span>
                              <div style="min-width: 70px;">
                                  <b-button variant="light" :disabled="is_unlinked==true" class="py-1 px-2" v-b-tooltip.hover title="편집" @click.stop.prevent="addTables({gri_code: indicator_info['tcfd_code'], table_id: dt})"><fa icon="pen"/></b-button>
                                  <b-button variant="light" :disabled="is_unlinked==true" class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click.stop.prevent="handleUnlink({gri_code: indicator_info['tcfd_code'], table_id: dt})"><fa icon="unlink"/></b-button>
                              </div>
                          </div>
                          <b-table-simple responsive class="bor-bg-table" v-if="hashed_data_tables && hashed_data_tables[dt]">
                              <b-thead>
                                  <b-tr>
                                      <b-td v-for="(property, col_index) in hashed_data_tables[dt].header" :key="col_index">{{ property }}</b-td>
                                  </b-tr>
                              </b-thead>
                              <b-tbody>
                                  <b-tr v-for="(row_item, row_index) in hashed_data_tables[dt].contents" :key="row_item.id">
                                      <template v-for="property in hashed_data_tables[dt].header">
                                          <b-td 
                                              v-if="differentPrevious(hashed_data_tables[dt].contents, row_index, property, hashed_data_tables[dt].header)==true" 
                                              :key="property.id" 
                                              :rowspan="getSameLength(hashed_data_tables[dt].contents, row_index, property, hashed_data_tables[dt].header)"
                                          >
                                              <span 
                                                  v-html=formattedText(row_item[property])>
                                              </span>    
                                          </b-td>
                                      </template>
                                  </b-tr>
                              </b-tbody>
                              <caption class="mb-2">{{ hashed_data_tables[dt].footnote }}</caption>
                          </b-table-simple>
                      </div>
                  </section>


                  <section class="mb-5 px-3" v-if="indicator_info.documents.length>0"> 
                      <div class="border-bottom-gray-550 gray-600 f-90 pb-1"><fa icon="paperclip" class="mr-1"/> 문서 및 링크 </div>
                      <div v-for="x in indicator_info.documents" :key="x.id">
                          <div v-if="hashed_docs && hashed_docs[x] && hashed_docs[x].type!='link'" class="mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center">
                              <a class=" cursor" :href="$store.state.static_host+hashed_docs[x].path" target="_blank"><fa icon="file-alt" class="mr-1"/> {{ hashed_docs[x].title }} </a>  
                              <div style="min-width: 70px;">
                                  <b-button variant="light" :disabled="is_unlinked==true" class="py-1 px-2" v-b-tooltip.hover title="편집" @click.stop.prevent="addDocuments({gri_code: indicator_info['tcfd_code'], document_id: x, type: hashed_docs[x].type})"><fa icon="pen"/></b-button>
                                  <b-button variant="light" :disabled="is_unlinked==true" class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click.stop.prevent="handleUnlink({gri_code: indicator_info['tcfd_code'], document_id: x})"><fa icon="unlink"/></b-button>
                              </div>
                          </div>                                                         
                      </div>
                      <div v-for="y in indicator_info.documents" :key="y.id">
                          <div v-if="hashed_docs && hashed_docs[y] && hashed_docs[y].type=='link'" class="mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center">
                              <a class=" cursor" :href="hashed_docs[y].link" target="_blank"><fa icon="external-link-square-alt" class="mr-1"/> {{ hashed_docs[y].title }} </a>  
                              <div style="min-width: 70px;">
                                  <b-button variant="light" :disabled="is_unlinked==true" class="py-1 px-2" v-b-tooltip.hover title="편집" @click.stop.prevent="addDocuments({gri_code: indicator_info['tcfd_code'], document_id: y, type: hashed_docs[y].type})"><fa icon="pen"/></b-button>
                                  <b-button variant="light" :disabled="is_unlinked==true" class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click.stop.prevent="handleUnlink({gri_code: indicator_info['tcfd_code'], document_id: y})"><fa icon="unlink"/></b-button>
                              </div>
                          </div>
                      </div>
                  </section>


                  <section class="mb-5 px-3" v-if="indicator_info.business_cases.length>0">
                      <div class="border-bottom-gray-550 gray-600 f-90 pb-1"><fa icon="quote-left" class="mr-1"/> 비즈니스 사례</div>
                      <div v-for="(bc, idx) in indicator_info.business_cases" :key="idx">
                          <div class="mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center">
                              <a class=" cursor" v-b-modal="`bc_${bc}${indicator_info['tcfd_code']}`">
                                <span v-if="hashed_business_cases && hashed_business_cases[bc]">
                                  {{ hashed_business_cases[bc].title }}
                                </span>  
                                <fa icon="expand" class="ml-1"/>
                              </a>
                              <div style="min-width: 70px;">
                                  <b-button variant="light" :disabled="is_unlinked==true" class="py-1 px-2" v-b-tooltip.hover title="편집" @click.stop.prevent="addBusinessCases({gri_code: indicator_info['tcfd_code'], business_case_id: bc})"><fa icon="pen"/></b-button>
                                  <b-button variant="light" :disabled="is_unlinked==true" class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click.stop.prevent="handleUnlink({gri_code: indicator_info['tcfd_code'], business_case_id: bc})"><fa icon="unlink"/></b-button>
                              </div>
                          </div>
                          <b-modal :id="`bc_${bc}${indicator_info['tcfd_code']}`" hide-footer size="xl">
                              <template #modal-title>
                                  <b-badge variant="basic" class="mr-2">Case.</b-badge><span v-if="hashed_business_cases && hashed_business_cases[bc]">{{ hashed_business_cases[bc].title }}</span> 
                              </template>
                              <simple-business-case :business_case_id="bc"></simple-business-case>
                          </b-modal>
                      </div>
                  </section>
                  <!-- {{ indicator_info }} -->
                  <section class="mb-5 px-3" v-if="indicator_info.charts.length >0">
                      <div class="border-bottom-gray-550 gray-600 f-90 pb-1"><b-icon icon="bar-chart-fill" class="mr-1" /> 차트 </div>
                      <div v-for="chart_id in indicator_info.charts" :key="'chart_' + chart_id">
                          <ChartTextCanvas 
                              :is_able_to_edit="selected_report.is_able_to_edit"
                              :chart_id="chart_id"
                              :editing_gri_code="indicator_info.tcfd_code"
                              :selected_chart_list="indicator_info.charts"
                          /> 
                      </div>
                  </section>

                  <!-- 추가하기 버튼 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
                  <div :class="selected_report.is_able_to_edit === true? 'hover-border' : ''">
                      <b-button variant="light" :disabled="is_unlinked===true" class="py-1 px-2 mr-1" v-b-tooltip.hover.bottom title="데이터 테이블 추가" @click.stop.prevent="addTables({gri_code: indicator_info['tcfd_code']})"><fa icon="table"/></b-button>  
                      <b-button variant="light" :disabled="is_unlinked===true" class="py-1 px-2 mr-1" v-b-tooltip.hover.bottom title="문서 및 링크 추가" @click.stop.prevent="addDocuments({gri_code: indicator_info['tcfd_code']})"><fa icon="paperclip"/></b-button>                   
                      <b-button variant="light" :disabled="is_unlinked===true" class="py-1 px-2 mr-1" v-b-tooltip.hover.bottom title="비즈니스 사례 추가" @click.stop.prevent="addBusinessCases({gri_code: indicator_info['tcfd_code']})"><fa icon="quote-left"/></b-button>                   
                      <ChartBtn
                          :is_able_to_edit="selected_report.is_able_to_edit"
                          :editing_gri_code="indicator_info.tcfd_code"
                          :selected_chart_list="indicator_info.charts"
                      />
                  </div>

                  <!-- ======================================================================================================================================= -->


                </b-td>

                <b-td class="bgray-100" style="padding-left: 0.75rem">
                  <div  class="d-flex justify-content-between f-90 mb-2">
                    <p class="gray-600 mb-0">다음을 참고하여 작성해야 합니다.</p>
                  </div>
                  <div class="bg-white px-2 py-2">
                    <p class="mb-1 f-90 gray-600">
                      <span 
                          v-if="indicator_info.helper"
                          v-html=formattedText(indicator_info.helper)>
                      </span> 
                    </p>
                  </div>

                  
                  
                  <!-- <div class="d-flex justify-content-between f-90 mb-2"> <p class="gray-600 mb-0"> {{ show_example[indicator_info['tcfd_code']]!=true? '다음의 내용을 보고해야 합니다':'작성 예시'}} : </p>
                          <a @click.stop.prevent="showExample(indicator_info['tcfd_code'])" class="cursor"> 
                          {{ show_example[indicator_info['tcfd_code']]!=true?'예시보기':'예시닫기'}} <fa :icon="show_example[indicator_info['tcfd_code']]!=true?'expand':'compress-alt'"/>
                          </a>
                      </div>
                      <div v-if="show_example[indicator_info['tcfd_code']]!=true" class="bg-white px-2 py-2" >
                          <p class="mb-1 f-90 gray-600"> <md :contents="indicator_info.indicator_requirement"></md></p>
                      </div> 
                      <div v-else class="px-2 py-2">
                          <example :gri_code="indicator_info['tcfd_code']"></example>
                      </div>

                      <GPTForm 
                          :indicator_requirement="indicator_info.indicator_requirement" 
                          :user_writed="indicator_info.direct_report"
                      /> 
                  -->
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>

      </section>

    </div>
  </template>
  <script>
  import GPTForm from "../../helper_with_gpt/GPTForm.vue";
  import { gri_mixin } from "@/components/writing_report/write_report_subs/gri_mixin";
  import { formattedText } from '@/components/util'
  import { mapState, mapGetters, mapMutations } from 'vuex'
  import ChartBtn from "@/components/writing_report/chart_subs/ChartBtn";
  import ChartTextCanvas from "@/components/writing_report/chart_subs/ChartTextCanvas.vue";
  import WriteLoading from '@/components/writing_report/write_report_subs/WriteLoading';

  export default {
    mixins: [gri_mixin],
    name: "",
    props: {},
    components: {
      GPTForm,
      ChartBtn,
      ChartTextCanvas,
      WriteLoading
    },
    data() {
      return {
        is_writing_direct: {},
        save_state: {},
        tcfd_indicator_list: [],
        loading: true,
      };
    },
    computed: {
        ...mapState("report_list", ["report_code"]),
        ...mapGetters('report_contents', ['gri_2_all_contents']),
      
    },
    beforeCreate() {},
      created() {
      this.fetchSelectedTcfd();
    },
    beforeMount() { },
    beforeDestroy(){
        this.update_editing_gri_code('')
    },
    mounted() {
      setTimeout(() => {
        let elem = document.querySelector(window.location.hash);
        if (elem) {
          elem.scrollIntoView({
              behavior: "smooth"
          });
        }
      }, 2500);

    },
    beforeUpdate() {},
    updated() {},
      beforeUnmount() { },
      watch:{
          gri_2_all_contents:{
              immediate: true,
              handler(newV){
                  if(newV){
                      // console.log(newV);
                      // console.log(this.save_state);
                      this.fetchSelectedTcfd();                 
                  }
              }
          }
      },
    unmounted() {},
      methods: {
        ...mapMutations('cursor', ['update_editing_gri_code']), 
          formattedText,
          initiateSavingState(){
              this.save_state = {}
              for (let i=0; i<this.gri_2_all_contents.length; i++){
                  if(this.gri_2_all_contents[i].direct_report != ''&&this.gri_2_all_contents[i].direct_report != undefined) {
                      this.$set( this.save_state, this.gri_2_all_contents[i].gri_code, 'saved')
                  }
                  else this.$set( this.save_state, this.gri_2_all_contents[i].gri_code, '')
              }
          
          },
      initiateWritingMode() {
              this.is_writing_direct = {};
      },
      async textInputSave(tcfd_code, txt) {
        if (txt != null) {
          this.$set(this.save_state, tcfd_code, "saving");
  
          // this.$set(this.save_state, gri_code, "saving");
          //   update_tcfd_disclosure_contents
          try {
            const response = await axios.post(
              this.$store.state.backend_host +
                "/tcfd/save_tcfd_disclosure",
              {
                rc: this.report_code,
                tcfd_code: tcfd_code,
                direct_report: txt,
                    
              }
            );
  
            if (response.data.code) {
              this.$set(this.save_state, tcfd_code, "saved"); // axios response후에 넣고 밑에꺼만 settimeout에 넣기.
              this.initiateWritingMode();
            } else {
              this.$set(this.save_state, tcfd_code, "failed"); // axios response후에 넣고 밑에꺼만 settimeout에 넣기.
            }
            setTimeout(() => {
              this.$set(this.save_state, tcfd_code, "");
            }, 1000);
          } catch (error) {
            this.$set(this.save_state, tcfd_code, "failed"); // axios response후에 넣고 밑에꺼만 settimeout에 넣기.
          }
  
          // await this.saveDisclosure({
          //   report_code: this.report_code,
          //   gri_code: gri_code,
          //   direct_report: txt,
          // });
          // this.initiateWritingMode();
          // this.initiateSavingState();
  
          // this.initiateSavingState(tcfd_indicator_code);
        }
      },
      async fetchSelectedTcfd() {
        try {
          const response = await axios.get(
            `${this.$store.state.backend_host}/tcfd/read_tcfd_all_indicators_list_sort_by_category?rc=${this.report_code}`
          );
    
          this.tcfd_indicator_list = response.data.result;
          
        } catch (error) {
          console.log(error);
          
        }
        this.loading = false;
      },
      initiateSavingState(tcfd_code) {
        this.save_state = {};
        for (let i = 0; i < this.gri_2_all_contents.length; i++) {
          if (
            this.gri_2_all_contents[i].direct_report != "" &&
            this.gri_2_all_contents[i].direct_report != undefined
          ) {
            this.$set(this.save_state, tcfd_code, "saved");
          } else {
            this.$set(this.save_state, tcfd_code, "");
          }
        }
          },
      
    },
  };
  </script>
  <style></style>
  
<template>
    <div>
        <statement-of-use />
        <!-- Universal Disclosure -->
        <p>
            Universal Standards
        </p>

        <div v-for="category, index in report_universal_categories" :key="category.category_id"
        :class="{ 'first-subtopic': index === 0, 'new-page-subtopic': index > 0 }">

            <template v-if="hash_rp===null || hash_rp == category.category_id">
                <h3 :id="!print_mode ? 'rp' + category.category_id : ''" class="py-4 fw-600">GRI {{ category.category_id }}.
                    {{
                        lang != 'ENG' ? category.category_name : category.category_name_e }}</h3>
                <b-row responsive class="disclosure-table lg mb-2">
                    <b-col cols="3" style="min-width:22%; width:32%;">{{ lang != 'ENG' ? '보고 지표' : 'Disclosures' }}
                        <header-anchor :active_code="category.category_id" :print_mode="print_mode" />
                    </b-col>
                    <b-col cols="3" class="d-lg-flex justify-content-between">
                        {{ lang != 'ENG' ? '보고 내용' : 'Disclosure Contents' }}

                    </b-col>
                    <b-col cols="6" class=" text-right">
                        <fa icon="table" class="mr-1" /> {{ lang != 'ENG' ? '데이터 테이블' : 'Data Tables' }}
                        <fa icon="file-alt" class="ml-3 mr-1" />{{ lang != 'ENG' ? '문서' : 'Files' }}
                        <fa icon="external-link-square-alt" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '외부 링크' : 'URLs'
                        }}
                        <fa icon="quote-left" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '비즈니스 사례' : 'Business Cases' }}

                    </b-col>
                </b-row>
            </template>
            <template v-if="hash_rp===null || hash_rp == category.category_id">
                <div v-for="subtopic, index in getUniqueSubtopicsByCategoryIds(category.category_id)"
                        :key="subtopic.subtopic_code" 
                        :class="{ 'first-subtopic': index === 0, 'new-page-subtopic': index > 0 }">

                    <div 
                    class="mb-5 border-0">
                        <template>
                            <b-row @click="parse_hash(null, null, subtopic.subtopic_code)" v-if="hash_crp === null || hash_crp == subtopic.subtopic_code || subtopic.subtopic_code.split('_')[0] == hash_trp" >
                                <b-col cols="12" class="py-1 pl-1" :style="main_color_sub">
                                    <span class="fw-600 f-140" :id="!print_mode ? 'rp' + subtopic.subtopic_code : ''">
                                        {{ index + 1 }}. {{ lang != 'ENG' ? subtopic.subtopic_name : subtopic.subtopic_name_e }}
                                    </span>

                                    <span class="gray-500 f-90 fw-400 ml-1">
                                        {{ subtopic.topic_publication_date }}
                                    </span>
                                </b-col>
                            </b-row>
                            <div v-for="disclosure in getGRIContentsBySubtopicCodes(subtopic.subtopic_code)"
                            :key="disclosure.disclosure_id" :id="!print_mode ? 'w' + disclosure.gri_code : ''">
                                <b-row v-if="hash_crp === null || hash_crp === disclosure.gri_code || hash_crp === subtopic.subtopic_code" class="border-bottom py-4">
                                    <b-col cols="3">
                                        <div class="fw-500">{{ disclosure.gri_code }}</div>
                                        <p :id="!print_mode ? 'crp' + disclosure.gri_code : ''"> {{ lang != 'ENG' ?
                                            disclosure.indicator_name : disclosure.indicator_name_e }}</p>
                                    </b-col>

                                    <b-col cols="9" v-if="!disclosure.nan_category">
                                        <md style="word-wrap: break-word;"
                                            :contents="lang !== 'ENG' ? (disclosure.direct_report || '') : (disclosure.direct_report_e || '')"
                                            :class="{ 'mb-3': disclosure.direct_report }" />

                                        <!-- data_tables 넣을자리 -->
                                        <div v-for="data_table_id in disclosure.data_tables" :key="'data_table_' + data_table_id">
                                            <data-table :print_mode="print_mode" :table_mode="table_mode" :data_table_id="data_table_id"
                                                :modal_table="modal_table" :lazy_load="false" :table_style="table_style"
                                                :show_table_line="show_table_line" :show_table_outline="show_table_outline"
                                                :table_header_color="table_header_color"
                                                :table_header_font_color="table_header_font_color" />
                                        </div>

                                        <!-- documents 넣을자리 -->
                                        <div>
                                            <span v-for="document_id in disclosure.documents" :key="'document_' + document_id">
                                                <document :document_id="document_id" :lazy_load="false" />
                                            </span>
                                        </div>

                                        <!-- business_cases 넣을자리 -->
                                        <span v-for="business_case_id in disclosure.business_cases"
                                            :key="'business_case_' + business_case_id">
                                            <business-case :business_case_id="business_case_id" :lazy_load="true"
                                                :business_case_mode="business_case_mode" />

                                        </span>

                                        <div v-for="chart_id in disclosure.charts" :key="'chart_' + chart_id">
                                            <ChartCanvas v-if="chart_id !== ''" :print_mode="print_mode" :chart_id="chart_id"
                                                :key="`chart_${chart_id}`" :viewer_mode="true" class="mb-5" />

                                        </div>


                                        <!-- <div v-if="disclosure.gri_code === '3-2' && selected_indicator.custom_topic_indicator.length > 0"> -->
                                        <div
                                            v-if="disclosure.gri_code === '3-2' && selected_indicator && selected_indicator.custom_topic_indicator.length > 0">
                                            <!-- <div v-if="disclosure.gri_code === '3-2'"> -->
                                            <b-table-simple responsive
                                                :class="`${table_style}` === 'basic_table' ? 'edi-table' : 'no-line-table'"
                                                :borderless="!show_table_line" :outlined="show_table_outline">
                                                <b-thead>
                                                    <b-tr>
                                                        <b-td>{{ lang !== 'ENG' ? '우선순위' : 'Ranking' }}</b-td>
                                                        <b-td>{{ lang !== 'ENG' ? '중요 주제명' : 'Topic' }}</b-td>
                                                        <b-td>{{ lang !== 'ENG' ? '중요 주제와 연관된 GRI 지표' : 'GRI Topic Standards' }}</b-td>
                                                    </b-tr>
                                                </b-thead>
                                                <b-tbody>
                                                    <b-tr v-for="(item, idx) in selected_indicator.custom_topic_indicator" :key="idx">
                                                        <b-td>
                                                            {{ idx + 1 }}
                                                        </b-td>
                                                        <b-td>
                                                            {{ lang !== 'ENG' ? item.topic_name : item.topic_name_em ?
                                                                item.topic_name_em : item.topic_name_ea }}
                                                        </b-td>
                                                        <b-td>
                                                            <span v-for="(gri_code) in sort_indicator_arr(item.selected_cm_gri_code_list)"
                                                                :key="gri_code">
                                                                <a href="#" @click="navAnchor($event, gri_code)">{{ gri_code
                                                                    }}</a>&nbsp;
                                                            </span>
                                                        </b-td>
                                                        <!-- <template v-for="property in hashed_data_tables[dt].header"> -->

                                                        <!-- <b-td v-if="differentPrevious(hashed_data_tables[dt].contents, row_index, property, hashed_data_tables[dt].header)==true" :key="property.id" :rowspan="getSameLength(hashed_data_tables[dt].contents, row_index, property, hashed_data_tables[dt].header)">
                                                                {{ row_item[property] }}
                                                            </b-td> -->
                                                        <!-- </template> -->
                                                    </b-tr>
                                                </b-tbody>
                                            </b-table-simple>

                                        </div>
                                    </b-col>
                                    <b-col cols="9" v-else>
                                        <div>
                                            <p>{{ lang !== 'ENG' ? '보고 생략' : 'Omission in report' }}</p>
                                            <p>
                                                {{ lang !== 'ENG' ? '사유' : 'Reason' }}: [{{ lang !== 'ENG' ?
                                                    nan_table[disclosure.nan_category]['KOR'] :
                                                    nan_table[disclosure.nan_category]['ENG'] }}] {{ lang !== 'ENG' ?
                                                    disclosure.nan_reason_contents :
                                                    (disclosure.nan_reason_contents_em ? disclosure.nan_reason_contents_em :
                                                        disclosure.nan_reason_contents_ea) }}
                                            </p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>
        <!-- GRI 200 ~ 400 -->
        <p class="new-page">
            Topic Standards Disclosures
        </p>
        <div v-for="category, index in report_topic_specific_categories" :key="category.category_id"
        :class="{ 'first-subtopic': index === 0, 'new-page-subtopic': index > 0 }">
        
            <div v-if="(hash_rp===null || hash_rp == category.category_id )&&  getGRIContentsByCategoryIds(category.category_id).length > 0">
                <h3 :id="!print_mode ? 'rp' + category.category_id : ''" class="py-4 fw-600"> GRI Topic. {{ lang !=
                    'ENG' ?
                    category.category_name : category.category_name_e }}</h3>
                <b-row responsive class="disclosure-table lg mb-2">

                            <b-col cols="3" style="min-width:22%; width:32%;">{{ lang != 'ENG' ? '보고 지표' : 'Disclosures'
                                }}
                                <header-anchor :active_code="category.category_id" :print_mode="print_mode" />
                            </b-col>
                            <b-col cols="3" class="d-lg-flex justify-content-between">{{ lang != 'ENG' ? '보고 내용' : 'Disclosure Contents' }}
                            </b-col>
                            <b-col cols="6" class=" text-right">
                                <fa icon="table" class="mr-1" /> {{ lang != 'ENG' ? '데이터 테이블' : 'Data Tables' }}
                                <fa icon="file-alt" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '문서' : 'Files' }}
                                <fa icon="external-link-square-alt" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '외부 링크':'URLs' }}
                                <fa icon="quote-left" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '비즈니스 사례' : 'Business Cases' }}
                            </b-col>
                </b-row>
                <template >
                    <div v-for="topic, index in getUniqueTopicsByCategoryIds(category.category_id)" :key="topic.topic_id"
                    :class="{ 'first-subtopic': index === 0, 'new-page-subtopic': index > 0 }">
                        <div v-if="hash_trp === null || hash_trp == topic.topic_id"
                            class="mb-5 border-0">
                            <b-row>
                                <b-col cols="12" class="py-1 pl-1" :style="main_color_sub">
                                    <span class="fw-600 f-140" :id="!print_mode ? 'trp' + topic.topic_id : ''">
                                        GRI {{ topic.topic_id }} : {{ lang != 'ENG' ? topic.topic_name : topic.topic_name_e
                                        }}
                                    </span>
                                    <span class="gray-500 f-90 fw-400 ml-1">
                                        {{ topic.topic_publication_date }}
                                    </span>


                                </b-col>
                            </b-row>
                            <template>
                                <div v-for="disclosure in getGRIContentsByTopicIds(topic.topic_id) "
                                :key="disclosure.disclosure_id" :id="!print_mode ? 'w' + disclosure.gri_code : ''">
                                    <b-row v-if="hash_crp === null || hash_crp === disclosure.gri_code" 
                                        class="border-bottom py-4">
                                        <b-col cols="3">
                                            <div class="fw-500">{{ disclosure.gri_code }}</div>
                                            <p class="" :id="!print_mode ? 'crp' + disclosure.gri_code : ''"> {{ lang != 'ENG' ?
                                                disclosure.indicator_name : disclosure.indicator_name_e }}</p>
                                        </b-col>
                                        <b-col cols="9" v-if="!disclosure.nan_category" >
                                            <md style="word-wrap: break-word;"
                                                :contents="lang !== 'ENG' ? disclosure.direct_report : disclosure.direct_report_e"
                                                :class="{ 'mb-3': disclosure.direct_report }" />

                                            <!-- data_tables 넣을자리 -->
                                            <div v-for="data_table_id in disclosure.data_tables"
                                                :key="'data_table_' + data_table_id">
                                                <data-table :print_mode="print_mode" :table_mode="table_mode"
                                                    :data_table_id="data_table_id" :modal_table="modal_table" :lazy_load="false"
                                                    :table_style="table_style" :show_table_line="show_table_line"
                                                    :show_table_outline="show_table_outline"
                                                    :table_header_color="table_header_color"
                                                    :table_header_font_color="table_header_font_color" />

                                            </div>

                                            <!-- documents 넣을자리 -->
                                            <div>
                                                <span v-for="document_id in disclosure.documents" :key="'document_' + document_id">
                                                    <document :document_id="document_id" :lazy_load="false" />
                                                </span>
                                            </div>

                                            <!-- business_cases 넣을자리 -->
                                            <span v-for="business_case_id in disclosure.business_cases"
                                                :key="'business_case_' + business_case_id">
                                                <business-case :business_case_id="business_case_id" :lazy_load="false"
                                                    :business_case_mode="business_case_mode" />
                                            </span>

                                            <div v-for="chart_id in disclosure.charts" :key="'chart_' + chart_id">
                                                <ChartCanvas v-if="chart_id !== ''" :print_mode="print_mode" :chart_id="chart_id"
                                                    :key="`chart_${chart_id}`" :viewer_mode="true" class="mb-5" />

                                            </div>

                                        </b-col>
                                        <b-col cols="9" v-else>
                                            <div>
                                                    <p>{{ lang !== 'ENG' ? '보고 생략' : 'Omission in report' }}</p>
                                                    <p>
                                                        {{ lang !== 'ENG' ? '사유' : 'Reason' }}: [{{ lang !== 'ENG' ?
                                                            nan_table[disclosure.nan_category]['KOR'] :
                                                            nan_table[disclosure.nan_category]['ENG'] }}] {{ lang !== 'ENG' ?
                                                            disclosure.nan_reason_contents : (disclosure.nan_reason_contents_em ?
                                                                disclosure.nan_reason_contents_em : disclosure.nan_reason_contents_ea) }}
                                                    </p>


                                            </div>
                                        </b-col>
                                    </b-row>

                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import DataTable from './DataTable.vue'
import Document from './Document.vue'
import BusinessCase from './BusinessCase.vue'
import HeaderAnchor from './HeaderAnchor'
import { nan_table } from '@/components/util'
import StatementOfUse from './StatementOfUse.vue'
import ChartCanvas from "@/components/writing_report/chart_subs/ChartCanvas.vue";

export default {
    components: {
        DataTable,
        Document,
        BusinessCase,
        HeaderAnchor,
        StatementOfUse,
        ChartCanvas
    },
    data() {
        return {
            show_data_table: {},
            show_business_case: {},
            low_height: false,
            selected_indicator: null,
            hash_rp: null,
            hash_trp: null,
            hash_crp: null,
            nan_table
        }
    },
    props: {
        modal_table: { type: Boolean, default: false },
        reload: { type: Boolean, default: false },
        print_mode: { type: Boolean, default: false },
        business_case_mode: { type: String, default: "popup" },
        table_mode: { type: String, default: "accordion" },
        material_style_option: { type: String, default: "outline" },

        table_style: { type: String, default: 'basic_table' },
        show_table_line: { type: Boolean, default: true },
        show_table_outline: { type: Boolean, default: false },
        table_header_color: { type: String, default: '#dedede' },
        table_header_font_color: { type: String, default: '#fffff' },
    },
    computed: {
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapState('gri2021_report_view', ['report_structure', 'report_contents_loaded']),
        ...mapGetters('gri2021_report_view', ['report_topics', 'report_categories', 'report_universal_categories', 'report_topic_specific_categories']),
        ...mapGetters('report_list', ['selected_report']),
        main_color() {
            return `color:` + this.selected_report.main_color
        },
        main_color_sub() {
            if (this.material_style_option === 'fill') {
                return `background-color: ${this.selected_report.main_color}; color: white; border: 1px solid white; `
            } else if (this.material_style_option === 'outline') {
                return `color:` + this.selected_report.main_color + `; border-top: 1px solid ` + this.selected_report.main_color + `!important` + `; border-bottom: 1px solid ` + this.selected_report.main_color
            } else {
                return `background-color: white; border-left: 3px solid ${this.selected_report.main_color}; border-bottom: 1px solid ${this.selected_report.sub_color};border-top: 0px; border-right: 0px;`;
            }
        },
        main_bg_color() {
            if (this.selected_report) {
                return `background-color:` + this.selected_report.main_color
            }
            else return `background-color: #494949`
        }
    },
    methods: {
        ...mapMutations('gri2021_report_view', ['update_report_contents_loaded']),
        ...mapActions('gri2021_report_view', ['readReportContents']),
        ...mapActions('data_tables', ['readDataTableList']),
        ...mapActions('business_cases', ['readBusinessCaseList']),
        ...mapActions('documents', ['readDocumentList']),
        parse_hash(crp = null, trp = null, rp = null) {

            if (crp !== null) {

                return;
            } else if (trp !== null) {
                
                return;
            } else if (rp !== null) {
                this.hash_crp = rp;
                this.hash_trp = rp[0];
                this.hash_rp = rp[0];
                return;
            }
            
            const page_hash = this.$route.hash;
            if (page_hash.includes('crp')) {
                this.hash_crp = page_hash.split("crp")[1];
                this.hash_trp = null;
                this.hash_rp = null;

                this.hash_trp = this.hash_crp.split("-")[0]
                if (this.hash_trp.length > 2) {
                    this.hash_rp = this.hash_trp[0] + '00'
                } else {
                    this.hash_rp = this.hash_trp[0]
                }


                if (this.hash_crp.includes('3-3_')) {
                    this.hash_trp = this.hash_crp.split("3-3_")[1]
                    this.hash_rp = this.hash_trp[0] + '00'
                }


            } else if (page_hash.includes('trp')) {
                this.hash_crp = null;
                this.hash_trp = page_hash.split("trp")[1];

                if (this.hash_trp.length > 2) {
                    this.hash_rp = this.hash_trp[0] + '00'
                } else {
                    this.hash_rp = this.hash_trp[0]
                }

                 
            } else if (page_hash.includes('rp')) {
                this.hash_crp = null;
                this.hash_trp = null;
                this.hash_rp = page_hash.split("rp")[1];

                if (this.hash_rp.startsWith('2_') || this.hash_rp.startsWith('3_')) {
                    this.hash_crp = this.hash_rp;
                    this.hash_trp = this.hash_rp[0];
                    this.hash_rp = this.hash_rp[0];
                }

            } 

        },
        sort_indicator_arr(arr) {
            if (arr === undefined) {
                return null
            }

            return arr.slice().sort((a, b) => {
                if (a === undefined || b === undefined) {
                    return;
                }
                let [numA, subNumA] = a.split('-').map(Number);
                let [numB, subNumB] = b.split('-').map(Number);

                // 숫자 부분을 비교
                if (numA === numB) {
                    // 숫자가 같은 경우 하위 숫자 부분을 비교
                    return subNumA - subNumB;
                } else {
                    // 숫자가 다른 경우 숫자 부분을 비교
                    return numA - numB;
                }
            });
        },
        navAnchor(event, anchor) {
            event.preventDefault();
            if (anchor && document.querySelector("#w" + anchor)) {
                let elem = document.querySelector("#w" + anchor);
                if (elem) {
                    elem.scrollIntoView({ behavior: "smooth" });
                }
            }
        },
        async selected_gri_code_list() {
            const response = await axios.get(this.$store.state.backend_host + "/get_selected_gri_indicator_code?rc=" + this.report_code)
            if (response.data.code) {
                this.selected_indicator = response.data.result;
            }

        },
        getUniqueTopicsByCategoryIds(category_id) {
            //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id == category_id)
            let unique_topics = []
            filtered_structure.forEach(item => {
                if (unique_topics.findIndex(x => { return x.topic_id == item.topic_id }) < 0) {
                    unique_topics.push({
                        category_id: item.category_id,
                        category_name: item.category_name,
                        category_name_e: item.category_name_e,
                        topic_id: item.topic_id,
                        topic_name: item.topic_name,
                        topic_name_e: item.topic_name_e,
                        topic_publication_date: item.topic_publication_date

                    })
                }
            })
            return unique_topics
        },
        getUniqueSubtopicsByCategoryIds(category_id) {
            //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id == category_id)
            let unique_subtopics = []
            filtered_structure.forEach(item => {
                if (unique_subtopics.findIndex(x => { return x.subtopic_code == item.subtopic_code }) < 0) {
                    unique_subtopics.push({
                        category_id: item.category_id,
                        category_name: item.category_name,
                        category_name_e: item.category_name_e,
                        subtopic_code: item.subtopic_code,
                        subtopic_name: item.subtopic_name,
                        subtopic_name_e: item.subtopic_name_e,
                        topic_publication_date: item.topic_publication_date

                    })
                }
            })
            return unique_subtopics
        },
        getGRIContentsByCategoryIds(category_id) {
            //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e, inidcator_id }]
            let filtered_structure = this.report_structure.filter(item => item.category_id == category_id)
            return filtered_structure
        },
        getGRIContentsByTopicIds(topic_id) {
            // [{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e, gri_code, indicator_id, disclosure_id }]
            let filtered_structure = this.report_structure.filter(item => item.topic_id == topic_id)
            return filtered_structure
        },
        getGRIContentsBySubtopicCodes(subtopic_code) {
            // [{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e, gri_code, indicator_id, disclosure_id }]
            let filtered_structure = this.report_structure.filter(item => item.subtopic_code == subtopic_code)
            return filtered_structure
        },
    },
    watch: {
        $route: {
            handler(to, from) {
                this.parse_hash();
            }
        }
    },  
    created() {
        if (this.reload == true) {
            this.update_report_contents_loaded(false)
        }

        this.parse_hash();

        // if(this.report_contents_loaded == false){
        //     // console.log('GRI_TABLE_LOAD')
        //     this.readReportContents({report_code : this.report_code})
        //     this.readDataTableList({report_code: this.report_code})
        //     this.readBusinessCaseList({report_code: this.report_code})
        //     this.readDocumentList({ report_code: this.report_code })
        //     this.selected_gri_code_list()
        // }

        // fullview에서 호출하고 있으니 주석
        this.readReportContents({ report_code: this.report_code })
        this.readDataTableList({ report_code: this.report_code })
        this.readBusinessCaseList({ report_code: this.report_code })
        this.readDocumentList({ report_code: this.report_code })
        this.selected_gri_code_list()
    }
}
</script>
<style>
@media print {
    .new-page{
        page-break-before: always;
    }
  /* 첫 번째 서브토픽은 페이지 나누기 적용 안 함 */
  .first-subtopic {
    page-break-before: avoid;
  }

  /* 두 번째 서브토픽부터 새 페이지 시작 */
  .new-page-subtopic {
    page-break-before: always;
  }
}

</style>
import Vue from "vue";
import Router from "vue-router";
//import store from '@/store'

import LoginMaster from "@/components/login_components/LoginMaster";
import Login from "@/components/login_components/Login";
import Signup from "@/components/login_components/Signup";

import ConfirmUser from "@/components/auth/ConfirmUser";

import TermsOfUse from "@/components/terms/TermsOfUse";
import PrivacyPolicy from "@/components/terms/PrivacyPolicy";

import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Landing from "@/components/Landing";

import PreferenceMaster from "@/components/report_preference/PreferenceMaster";
import BasicSettings from "@/components/report_preference/BasicSettings";
import Publishing from "@/components/report_preference/Publishing";
import TeamSettings from "@/components/report_preference/TeamSettings";
import ThemeSettings from "@/components/report_preference/ThemeSettings";

import PaymentModal from "@/components/payment/PaymentModal";

import IndicatorsMaster from "@/components/managing_indicators/IndicatorsMaster";
import SelectGriIndicators from "@/components/managing_indicators/SelectGriIndicators";


import SelectSasbIndicators from "@/components/managing_indicators/sasb_2023/SelectSasbIndicators";
import SelectTcfdIndicators from "@/components/managing_indicators/tcfd_2023/SelectTcfdIndicators";
import WriteDataCenters from "@/components/managing_indicators/data_centers/WriteDataCenters";

import GetGriRecommendations from "@/components/managing_indicators/GetGriRecommendations";
import GetSasbRecommendations from "@/components/managing_indicators/sasb_2023/GetSasbRecommendations";
import GetTcfdRecommendations from "@/components/managing_indicators/tcfd_2023/GetTcfdRecommendations";
import EditDCToC from '@/components/managing_indicators/data_centers/EditDCToC'




import WritingMaster from "@/components/writing_report/WritingMaster";
import Gri100 from "@/components/writing_report/write_report_subs/Gri100";
import Gri200 from "@/components/writing_report/write_report_subs/Gri200";
import Gri2_2021 from "@/components/writing_report/write_report_subs/Gri2_2021";
import Gri3_2021 from "@/components/writing_report/write_report_subs/Gri3_2021";
import Gri300 from "@/components/writing_report/write_report_subs/Gri300";
import Gri400 from "@/components/writing_report/write_report_subs/Gri400";


import WriteTcfd from "@/components/writing_report/write_report_subs/WriteTcfd"
import WriteSasb from "@/components/writing_report/write_report_subs/WriteSasb"

import MgDataTables from "@/components/writing_report/MgDataTables";
import MgDocuments from "@/components/writing_report/MgDocuments";
import MgBusinessCases from "@/components/writing_report/MgBusinessCases";
import MgCharts from "@/components/writing_report/MgCharts";

import AssuranceMaster from "@/components/assurance/AssuranceMaster";
import CheckReport from "@/components/assurance/CheckReport";
import AssuranceInfo from "@/components/assurance/AssuranceInfo";
import ApplyAssurance from "@/components/assurance/ApplyAssurance";
import CoVerification from "@/components/assurance/CoVerification";
import GetAssuranceReport from "@/components/assurance/GetAssuranceReport";

import FullView2021 from "@/components/report_preview/gri2021_viewer/FullView2021";
import FullView2021CustomEditor from "@/components/report_preview/gri2021_custom_viewer/FullView2021CustomEditor";

import TranslateMaster from "@/components/translate/TranslateMaster";
import BasicTranslateSet from "@/components/translate/BasicTranslateSet";
import GriTranslateSet from "@/components/translate/GriTranslateSet";
import TcfdTranslateSet from "@/components/translate/TcfdTranslateSet";
import SasbTranslateSet from "@/components/translate/SasbTranslateSet";
import DataCenterTranslateSet from "@/components/translate/DataCenterTranslateSet";


import EGri2 from "@/components/translate/translate_subs/EGri2";
import EGri3 from "@/components/translate/translate_subs/EGri3";
import EGri100 from "@/components/translate/translate_subs/EGri100";
import EGri200 from "@/components/translate/translate_subs/EGri200";
import EGri300 from "@/components/translate/translate_subs/EGri300";
import EGri400 from "@/components/translate/translate_subs/EGri400";

import ESasb from "@/components/translate/translate_subs/ESasb";
import ETcfd from "@/components/translate/translate_subs/ETcfd";
import EDataCenter from "@/components/translate/translate_subs/EDataCenter";
import EToC from "@/components/translate/translate_subs/EToC";

import AdminMaster from "@/components/admin/AdminMaster";
import AllReports from "@/components/admin/AllReports";
import UserManagement from "@/components/admin/UserManagement";
import NoticeManagement from "@/components/admin/NoticeManagement";
import SubscriptionPaymentManagement from "@/components/admin/SubscriptionPaymentManagement";
import ManageLicense from "@/components/admin/ManageLicense";
import ManageCoupon from "@/components/admin/admin_manage_coupon/ManageCoupon";
import ManageIndicator from "@/components/admin/admin_manage_indicator/ManageIndicator";
import ManageGriIndicator from "@/components/admin/admin_manage_indicator/gri_2021/ManageGriIndicator";
import ManageTcfdIndicator from "@/components/admin/admin_manage_indicator/tcfd_2023/ManageTcfdIndicator";
import ManageSasbIndicator from "@/components/admin/admin_manage_indicator/sasb_2023/ManageSasbIndicator";



import AdminHeader from "@/components/admin/AdminHeader";
import OnlineAssurance from "@/components/admin/admin_report_subs/OnlineAssurance";
import WriteStatement from "@/components/admin/admin_report_subs/WriteStatement";
import TranslateStatement from "@/components/admin/admin_report_subs/TranslateStatement";
import CustomProductManagement from "@/components/admin/CustomProductManagement";
import PaymentMaster from "@/components/payment/PaymentMaster";
import PaymentList from "@/components/payment/PaymentList";

import TestPayment from "@/components/payment/TestPayment";
import TestPaymentCheck from "@/components/payment/TestPaymentCheck";
import ProductPay from "@/components/payment/ProductPay";

import TestPaymentRegular from "@/components/payment/TestPaymentRegular";
import AddPaymentMethod from "@/components/payment/AddPaymentMethod";

import Error from "@/components/Error";

import PaymentReports from "@/components/payment/PaymentReports";
import Invoice from "@/components/payment/Invoice";

import PurchaseHistory from "@/components/payment/PurchaseHistory";

import TestMaster from "@/components/store_testors/TestMaster";

const routes = [
  {
    path: process.env.VUE_APP_REPORT_CODE === undefined ? "" : "/",
    component: LoginMaster,
    children: [
      {
        path: process.env.VUE_APP_REPORT_CODE === undefined ? "" : "/",
        name: "Login",
        component: Login,
      },
      { path: "/signup", name: "Signup", component: Signup },
      { path: "/confirm_user", name: "ConfirmUser", component: ConfirmUser },
    ],
    props: true,
  },
  {
    path: "",
    component: Landing,
    children: [
      {
        path: "/main",
        name: "Main",
        components: { editor_header: Header, editor_footer: Footer },
      },
    ],
    props: true,
    //   meta: {requireAuth: true}
  },
  {
    path: "",
    component: PreferenceMaster,
    children: [
      {
        path: "/basic_settings",
        name: "BasicSettings",
        components: {
          editor_header: Header,
          default: BasicSettings,
          editor_footer: Footer,
        },
      },
      {
        path: "/publishing",
        name: "Publishing",
        components: {
          editor_header: Header,
          default: Publishing,
          editor_footer: Footer,
        },
      },
      {
        path: "/team_settings",
        name: "TeamSettings",
        components: {
          editor_header: Header,
          default: TeamSettings,
          editor_footer: Footer,
        },
      },
      {
        path: "/theme_settings",
        name: "ThemeSettings",
        components: {
          editor_header: Header,
          default: ThemeSettings,
          editor_footer: Footer,
        },
      },
    ],
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "",
    component: IndicatorsMaster,
    children: [
      {
        path: "/gri_indicators",
        name: "SelectGriIndicators",
        components: {
          editor_header: Header,
          default: SelectGriIndicators,
          editor_footer: Footer,
        },
      },
      {
        path: "/sasb_indicators",
        name: "SelectSasbIndicators",
        components: {
          editor_header: Header,
          default: SelectSasbIndicators,
          editor_footer: Footer,
        },
      },
      {
        path: "/tcfd_indicators",
        name: "SelectTcfdIndicators",
        components: {
          editor_header: Header,
          default: SelectTcfdIndicators,
          editor_footer: Footer,
        },
      },
      {
        path: "/editDCToC",
        name: "EditDCToC",
        components: {
          editor_header: Header,
          default: EditDCToC,
          editor_footer: Footer,
        },
      },
      {
        path: "/get_gri_recommendations",
        name: "GetGriRecommendations",
        components: {
          editor_header: Header,
          default: GetGriRecommendations,
          editor_footer: Footer,
        },
      },
      {
        path: "/get_sasb_recommendations",
        name: "GetSasbRecommendations",
        components: {
          editor_header: Header,
          default: GetSasbRecommendations,
          editor_footer: Footer,
        },
      },
      {
        path: "/get_tcfd_recommendations",
        name: "GetTcfdRecommendations",
        components: {
          editor_header: Header,
          default: GetTcfdRecommendations,
          editor_footer: Footer,
        },
      },
    ],
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "",
    component: WritingMaster,
    children: [
    // WriteDataCenters
      {
        path: "/write_report/data_centers",
        name: "WriteDataCenters",
        components: {
          editor_header: Header,
          default: WriteDataCenters,
          editor_footer: Footer,
        },
      },
      {
        path: "/write_report/gri_100",
        name: "Gri100",
        components: {
          editor_header: Header,
          default: Gri100,
          editor_footer: Footer,
        },
      },
      {
        path: "/write_report/gri_2",
        name: "Gri2",
        components: {
          editor_header: Header,
          default: Gri2_2021,
          editor_footer: Footer,
        },
      },
      {
        path: "/write_report/gri_3",
        name: "Gri3",
        components: {
          editor_header: Header,
          default: Gri3_2021,
          editor_footer: Footer,
        },
      },
      {
        path: "/write_report/gri_200",
        name: "Gri200",
        components: {
          editor_header: Header,
          default: Gri200,
          editor_footer: Footer,
        },
      },
      {
        path: "/write_report/gri_300",
        name: "Gri300",
        components: {
          editor_header: Header,
          default: Gri300,
          editor_footer: Footer,
        },
      },
      {
        path: "/write_report/gri_400",
        name: "Gri400",
        components: {
          editor_header: Header,
          default: Gri400,
          editor_footer: Footer,
        },
      },
      {
        path: "/write_report/tcfd",
        name: "WriteTcfd",
        components: {
          editor_header: Header,
          default: WriteTcfd,
          editor_footer: Footer,
        },
      },
      {
        path: "/write_report/sasb",
        name: "WriteSasb",
        components: {
          editor_header: Header,
          default: WriteSasb,
          editor_footer: Footer,
        },
      },
      {
        path: "/mg_data_tables",
        name: "MgDataTables",
        components: {
          editor_header: Header,
          default: MgDataTables,
          editor_footer: Footer,
        },
      },
      {
        path: "/mg_documents",
        name: "MgDocuments",
        components: {
          editor_header: Header,
          default: MgDocuments,
          editor_footer: Footer,
        },
      },
      {
        path: "/mg_business_cases",
        name: "MgBusinessCases",
        components: {
          editor_header: Header,
          default: MgBusinessCases,
          editor_footer: Footer,
        },
      },
      {
        path: "/mg_charts",
        name: "MgCharts",
        components: {
          editor_header: Header,
          default: MgCharts,
          editor_footer: Footer,
        },
      },
    ],
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "",
    component: AssuranceMaster,
    children: [
      {
        path: "/check_report",
        name: "CheckReport",
        components: {
          editor_header: Header,
          default: CheckReport,
          editor_footer: Footer,
        },
      },
      {
        path: "/assurance_info",
        name: "AssuranceInfo",
        components: {
          editor_header: Header,
          default: AssuranceInfo,
          editor_footer: Footer,
        },
      },
      {
        path: "/apply_assurance",
        name: "ApplyAssurance",
        components: {
          editor_header: Header,
          default: ApplyAssurance,
          editor_footer: Footer,
        },
      },
      {
        path: "/co_verification",
        name: "CoVerification",
        components: {
          editor_header: Header,
          default: CoVerification,
          editor_footer: Footer,
        },
      },
      {
        path: "/get_assurance_report",
        name: "GetAssuranceReport",
        components: {
          editor_header: Header,
          default: GetAssuranceReport,
          editor_footer: Footer,
        },
      },
    ],
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "",
    component: TranslateMaster,
    children: [
      {
        path: "/eng_basic",
        name: "BasicTranslateSet",
        components: {
          editor_header: Header,
          default: BasicTranslateSet,
          editor_footer: Footer,
        },
      },
      
      {
        path: "/gri_eng_basic",
        name: "GriTranslateSet",
        components: {
          editor_header: Header,
          default: GriTranslateSet,
          editor_footer: Footer,
        },
      },

      {
        path: "/tcfd_eng_basic",
        name: "TcfdTranslateSet",
        components: {
          editor_header: Header,
          default: TcfdTranslateSet,
          editor_footer: Footer,
        },
      },
      {
        path: "/sasb_eng_basic",
        name: "SasbTranslateSet",
        components: {
          editor_header: Header,
          default: SasbTranslateSet,
          editor_footer: Footer,
        },
      },
      {
        path: "/dc_eng_basic",
        name: "DataCenterTranslateSet",
        components: {
          editor_header: Header,
          default: DataCenterTranslateSet,
          editor_footer: Footer,
        },
      },

      {
        path: "/eng_gri2",
        name: "EGri2",
        components: {
          editor_header: Header,
          default: EGri2,
          editor_footer: Footer,
        },
      },
      {
        path: "/eng_gri3",
        name: "EGri3",
        components: {
          editor_header: Header,
          default: EGri3,
          editor_footer: Footer,
        },
      },
      {
        path: "/eng_gri100",
        name: "EGri100",
        components: {
          editor_header: Header,
          default: EGri100,
          editor_footer: Footer,
        },
      },
      {
        path: "/eng_gri200",
        name: "EGri200",
        components: {
          editor_header: Header,
          default: EGri200,
          editor_footer: Footer,
        },
      },
      {
        path: "/eng_gri300",
        name: "EGri300",
        components: {
          editor_header: Header,
          default: EGri300,
          editor_footer: Footer,
        },
      },
      {
        path: "/eng_gri400",
        name: "EGri400",
        components: {
          editor_header: Header,
          default: EGri400,
          editor_footer: Footer,
        },
      },
      {
        path: "/eng_sasb",
        name: "ESasb",
        components: {
          editor_header: Header,
          default: ESasb,
          editor_footer: Footer,
        },
      },
      {
        path: "/eng_tcfd",
        name: "ETcfd",
        components: {
          editor_header: Header,
          default: ETcfd,
          editor_footer: Footer,
        },
      },
      {
        path: "/eng_data_center",
        name: "EDataCenter",
        components: {
          editor_header: Header,
          default: EDataCenter,
          editor_footer: Footer,
        },
      },
      {
        path: "/eng_table_of_content",
        name: "EToC",
        components: {
          editor_header: Header,
          default: EToC,
          editor_footer: Footer,
        },
      },
    ],
  },
  {
    path: "",
    component: AdminMaster,
    children: [
      {
        path: "/all_reports",
        name: "AllReports",
        components: {
          editor_header: AdminHeader,
          default: AllReports,
          editor_footer: Footer,
        },
      },
      {
        path: "/online_assurance",
        name: "OnlineAssurance",
        components: {
          editor_header: AdminHeader,
          default: OnlineAssurance,
          editor_footer: Footer,
        },
      },
      {
        path: "/write_statement",
        name: "WriteStatement",
        components: {
          editor_header: AdminHeader,
          default: WriteStatement,
          editor_footer: Footer,
        },
      },
      {
        path: "/translate_statement",
        name: "TranslateStatement",
        components: {
          editor_header: AdminHeader,
          default: TranslateStatement,
          editor_footer: Footer,
        },
      },
      {
        path: "/custom_product_management",
        name: "CustomProductManagement",
        components: {
          editor_header: AdminHeader,
          default: CustomProductManagement,
          editor_footer: Footer,
        },
      },
      {
        path: "/user_management",
        name: "UserManagement",
        components: {
          editor_header: AdminHeader,
          default: UserManagement,
          editor_footer: Footer,
        },
      },
      {
        path: "/notice_management",
        name: "NoticeManagement",
        components: {
          editor_header: AdminHeader,
          default: NoticeManagement,
          editor_footer: Footer,
        },
      },
      {
        path: "/subscription_payment_management",
        name: "SubscriptionPaymentManagement",
        components: {
          editor_header: AdminHeader,
          default: SubscriptionPaymentManagement,
          editor_footer: Footer,
        },
      },
      {
        path: "/ManageLicense",
        name: "ManageLicense",
        components: {
          editor_header: AdminHeader,
          default: ManageLicense,
          editor_footer: Footer,
        },
      },
      {
        path: "/ManageCoupon",
        name: "ManageCoupon",
        components: {
          editor_header: AdminHeader,
          default: ManageCoupon,
          editor_footer: Footer,
        },
      },
      {
        path: "/ManageIndicator",
        name: "ManageIndicator",
        components: {
          editor_header: AdminHeader,
          default: ManageIndicator,
          editor_footer: Footer,
        },
      },
      {
        path: "/ManageGriIndicator",
        name: "ManageGriIndicator",
        components: {
          editor_header: AdminHeader,
          default: ManageGriIndicator,
          editor_footer: Footer,
        },
      },
      {
        path: "/ManageTcfdIndicator",
        name: "ManageTcfdIndicator",
        components: {
          editor_header: AdminHeader,
          default: ManageTcfdIndicator,
          editor_footer: Footer,
        },
      },
      {
        path: "/ManageGriIndicator",
        name: "ManageGriIndicator",
        components: {
          editor_header: AdminHeader,
          default: ManageGriIndicator,
          editor_footer: Footer,
        },
      },
    ],
  },

  {
    path: "",
    component: PaymentMaster,

    children: [
      {
        path: "/subscription_service",
        name: "TestPaymentRegular",
        components: {
          editor_header: Header,
          default: TestPaymentRegular,
          editor_footer: Footer,
        },
      },
      {
        path: "/subscription_payment_method",
        name: "AddPaymentMethod",
        components: {
          editor_header: Header,
          default: AddPaymentMethod,
          editor_footer: Footer,
        },
      },

      {
        path: "/subscription_settings",
        name: "PaymentModal",
        components: {
          editor_header: Header,
          default: PaymentModal,
          editor_footer: Footer,
        },
      },

      {
        path: "/user_payment_check",
        name: "TestPaymentCheck",
        components: {
          editor_header: Header,
          default: TestPaymentCheck,
          editor_footer: Footer,
        },
      },
      {
        path: "/product_pay",
        name: "ProductPay",
        components: {
          editor_header: Header,
          default: ProductPay,
          editor_footer: Footer,
        },
      },
      {
        path: "/advancing_report",
        name: "TestPayment",
        components: {
          editor_header: Header,
          default: TestPayment,
          editor_footer: Footer,
        },
      },
    ],
  },
  {
    path: "/report_preview",
    name: "FullView",
    component: FullView2021,
    props: true,
  },
  {
    path: "/custom_css_editor",
    name: "FullView2021CustomEditor",
    component: FullView2021CustomEditor,
    props: true,
  },

  { path: "/terms/terms_of_use", name: "TermsOfUse", component: TermsOfUse },
  {
    path: "/terms/privacy_policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/test",
    component: TestMaster,
  },
  {
    path: "/payment/testDetail",
    component: PurchaseHistory,
  },
  {
    path: "/error",
    component: Error,
  },
  {
    path: "/invoice",
    name: "Invoice",
    component: Invoice,
  },
];

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else return { x: 0, y: 0 };
  },
});

export default router;

<template>
<span class="line-height-sm">
    <span v-if="loaded==true" @click="openImgPopup">
        <div v-if="modal_business_case == true">
            
            <div v-if="business_case_mode === 'popup'" >
                <b-icon
                    class="mr-1"
                    icon="blockquote-left"
                    scale="1.6"
                    :style="`color: ${selected_report.main_color};`"
                /> 
                <a 
                    class="business-case-link cursor mr-3 mb-2 mt-2" 
                    :style="main_color" 
                    @click="showBusinessCase()">
                        <!-- <fa icon="quote-left" class="mr-1"/> -->
                        {{ lang!='ENG'? title : title_e }} 
                </a>
                
                <div  v-if="opened_modal_id.includes('bc_' + business_case_id)" class="hj-modal-example-lg bg-white rounded shadow pb-3">
                    <div class="hj-modal-header fw-500 d-flex align-items-center justify-content-between px-3">
                        <div>
                            <b-icon
                                class="mr-1"
                                icon="blockquote-left"
                                scale="1.6"
                                :style="`color: ${selected_report.main_color};`"
                            /> 
                            <!-- <b-badge variant="border-light" class="mr-2">Case.</b-badge>  -->
                            {{ lang!='ENG'? title : title_e }}
                        </div>
                        <div style="min-width: 74px" align="right"><b-button variant="icon-light" @click="low_height=!low_height" class="py-0 px-2 mr-1"><fa :icon="low_height==true?'expand' :'compress-alt'"></fa> </b-button><b-button variant="icon-light" @click="closeBusinessCase()" class="py-0 px-2"><fa icon="times"></fa> </b-button></div>
                    </div> 
                    <div class="hj-modal-body p-3" :style="low_height==true? 'height:calc(50vh - 50px)!important':''">
                        <template v-if="lang!='ENG'">
                            <div class="bc-simple-content pb-4" v-html="description" ></div>
                            <div v-if="business_case_id!=''&&files.length>0" class="f-95 gray-800 mb-45"> 첨부파일 : 
                                <span v-for="item in files" :key="item.id" class="mr-3"> 
                                    <a :href="$store.state.static_host+item.file_path" target="_blank">{{item.file_name}}</a> 
                                </span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="bc-simple-content pb-4" v-html="description_e" ></div>
                            <div v-if="business_case_id!=''&&files_e.length>0" class="f-95 gray-800 mb-45"> Attatched File : 
                                <span v-for="item in files_e" :key="item.id" class="mr-3"> 
                                    <a :href="$store.state.static_host+item.file_path" target="_blank">{{item.file_name}}</a> 
                                </span>
                            </div>
                        </template>
                    </div> 
    
                </div>
            </div>

            <div 
                v-if="business_case_mode === 'accordion'" 
                class="no-page-brake-inside"
            >
                <!-- :style="background-color: rgb(216, 253, 222);" -->
                <div class="fw-500 d-flex align-items-center justify-content-between">
                    <div 
                        class="business-case-link cursor mr-3 mb-2 mt-2"
                        @click="hideBusinessCaseFromAccordion"
                        :style="main_color">
                            <!-- <fa icon="quote-left" class="mr-1"/> -->

                            <b-icon
                                class="mr-1"
                                icon="blockquote-left"
                                scale="1.6"
                                :style="`color: ${selected_report.main_color};`"
                            /> 
                             <!-- <b-badge class="mr-2">Case.</b-badge>  -->
                             {{ lang!='ENG'? title : title_e }} 


                    </div>
                </div> 
                
                <div class="p-4 border"
                    :style="low_height==true? 'height:calc(50vh - 50px)!important':''"
                >
                    <template v-if="lang!=='ENG'">
                        <div class="bc-simple-content pb-4 f-95 gray-800 " v-html="description" ></div>
                        <div v-if="business_case_id!=''&&files.length>0" class="f-95 gray-800 mb-45"> 첨부파일 : 
                            <span v-for="item in files" :key="item.id" class="mr-3"> 
                                <a :href="$store.state.static_host+item.file_path" target="_blank">{{item.file_name}}</a> 
                            </span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="bc-simple-content pb-4" v-html="description_e" ></div>
                        <div v-if="business_case_id!=''&&files_e.length>0" class="f-95 gray-800 mb-45"> Attatched File : 
                            <span v-for="item in files_e" :key="item.id" class="mr-3"> 
                                <a :href="$store.state.static_host+item.file_path" target="_blank">{{item.file_name}}</a> 
                            </span>
                        </div>
                    </template>
                </div> 
            </div>
            <div 
                v-if="business_case_mode === 'newtab'" 
            >
                <!-- :style="background-color: rgb(216, 253, 222);" -->
                <div class="fw-500 d-flex align-items-center justify-content-between">
                    <a 
                        class="business-case-link cursor mr-3 mb-2 mt-2"
                        @click="redirectToSamplePage(description, description_e)"
                        :style="main_color">
                            <!-- <fa icon="quote-left" class="mr-1"/> -->
                             <!-- <b-badge class="mr-2">Case.</b-badge>  -->
                             <b-icon
                                class="mr-1"
                                icon="blockquote-left"
                                scale="1.6"
                                :style="`color: ${selected_report.main_color};`"
                            />  
                             {{ lang!='ENG'? title : title_e }} 
                    </a>
                </div> 
            </div>
        </div>
        <div v-else>
            <a 
                v-if="show_title==true" 
                class="business-case-link cursor mr-3 mb-2 mt-2" 
                :style="main_color">
                    <fa icon="quote-left" class="mr-1"/> {{ lang!='ENG'? title : title_e }} 
            </a>
            <template v-if="lang!='ENG'">
                <div class="bc-simple-content pb-4" v-html="description" ></div>
                <div v-if="business_case_id!=''&&files.length>0" class="f-95 gray-800 mb-45"> 첨부파일 : 
                    <span v-for="item in files" :key="item.id" class="mr-3"> 
                        <a :href="$store.state.static_host+item.file_path" target="_blank">{{item.file_name}}</a> 
                    </span>
                </div>
            </template>
            <template v-else>
                <div class="bc-simple-content pb-4" v-html="description_e" ></div>
                <div v-if="business_case_id!=''&&files_e.length>0" class="f-95 gray-800 mb-45"> Attatched File : 
                    <span v-for="item in files_e" :key="item.id" class="mr-3"> 
                        <a :href="$store.state.static_host+item.file_path" target="_blank">{{item.file_name}}</a> 
                    </span>
                </div>
            </template>
        </div>
    </span>
    <span v-else>
        <b-skeleton width="10%"></b-skeleton>
    </span>
</span>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import * as ih from '@/components/util'
import SimpleBusinessCase from '@/components/writing_report/business_case_subs/SimpleBusinessCase'
import FormGallery from '../../static_viewer/FormGallery.vue'

export default {
    components:{
        SimpleBusinessCase
    },
    data() {
        return {
            title: '',
            description: '',
            time_stamp: '',
            business_case_e_id: '',
            title_e:'',
            description_e: '',
            files: [],
            files_e: [],
            images: [],
            images_e: [],
            loaded: false,
            low_height: false
        }
    },    
    props:{
        business_case_id: {type: [String, Number], default: ''},
        business_case_obj: { type: Object, default() { return {} } },
        lazy_load : { type: Boolean, default: true },
        modal_business_case : { type: Boolean, default: true },
        show_title: { type: Boolean, default: true },
        business_case_mode: { type: String, default: "popup" },

    },
    computed:{
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),    
        ...mapState('gri2021_modal_open_status', ['opened_modal_id']), //open된 bc 확인,
        ...mapState('business_cases', ['business_case_list']),
        ...mapGetters('report_list', ['selected_report']),
        main_color(){
            return `color:`+this.selected_report.main_color
        },
          main_color_sub() {
            if (this.material_style_option === 'fill') {
                return `background-color: ${this.selected_report.main_color}; color: white; border: 1px solid white; `
            } else if (this.material_style_option === 'outline') {
                return `color:` + this.selected_report.main_color + `; border-top: 1px solid ` + this.selected_report.main_color + `!important` + `; border-bottom: 1px solid ` + this.selected_report.main_color
            } else {
                return `background-color: white; border-left: 3px solid ${this.selected_report.main_color}; border-bottom: 1px solid ${this.selected_report.sub_color};border-top: 0px; border-right: 0px;`;
            }
        }, 
    },
    methods: {
        ...mapMutations('gri2021_modal_open_status', ['add_opened_bc_id', 'delete_opened_bc_id']),
        openImgPopup(e) {
            const img = e.target;
            if (img.tagName === 'IMG') {
                this.$EventBus.$emit('image-modal', img.getAttribute('src'), true)
            }
        },
        readBusinessCaseEntity() {   
            const path = this.backend_host + '/read_business_case_entity'

            return axios.get(path, { params: {
                business_case_id : this.business_case_id,
                clean: true
            }})
            .then( response => {
                this.title = response.data.title
                this.description = response.data.description
                this.time_stamp = response.data.time_stamp
                this.business_case_e_id = response.data.business_case_e_id
                this.title_e = response.data.title_e
                this.description_e = response.data.description_e
                this.files = response.data.files
                this.files_e = response.data.files_e
                this.images = response.data.images
                this.images_e = response.data.images_e
                this.loaded = true
            })
        },
        showBusinessCase(){
            this.add_opened_bc_id(this.business_case_id)
        },
        closeBusinessCase(){
            this.delete_opened_bc_id(this.business_case_id)
        },
        hideBusinessCaseFromAccordion(event) {
            event.currentTarget.parentElement.nextElementSibling.classList.toggle('hide');
        },
        redirectToSamplePage(koHtmlStr, engHtmlStr) {
            // if (this.lang !== "ENG") {
            //     const newTab = window.open('', '_blank');
            //     if (newTab) {
            //         newTab.location.href = `/form_gallery?htmlString=${koHtmlStr}`;
            //     }

            // } else {
            //     const newTab = window.open('', '_blank');
            //     if (newTab) {
            //         newTab.location.href = `/form_gallery?htmlString=${engHtmlStr}`;
            //     }
            // }


            if (this.lang !== "ENG") {
                const newTab = window.open('', '_blank');
                if (newTab) {
                    newTab.location.href = `/form_gallery?type=bsc&id=${this.business_case_id}`;
                }

            } else {
                const newTab = window.open('', '_blank');
                if (newTab) {
                    newTab.location.href = `/form_gallery?type=bsc&id=${this.business_case_id}&lang=ENG`;
                }

            }
            
        }
    },
    created(){
        if(this.business_case_obj.hasOwnProperty('business_case_id')==true){
            this.title = this.business_case_obj.title
            this.description = this.business_case_obj.description
            this.time_stamp = this.business_case_obj.time_stamp
            this.business_case_e_id = this.business_case_obj.business_case_e_id
            this.title_e = this.business_case_obj.title_e
            this.description_e = this.business_case_obj.description_e
            this.files = this.business_case_obj.files
            this.files_e = this.business_case_obj.files_e
            this.images = this.business_case_obj.images
            this.images_e = this.business_case_obj.images_e
            this.loaded = true
        }

        if(this.lazy_load==true && this.loaded ==false) { this.readBusinessCaseEntity(); }

        //3. lazy load 하지 않고 미리 읽은 상태 & prop 전달 못 받은 경우, Store Table List에서 검색.
        if(this.lazy_load==false && this.loaded ==false) {
            let idx = this.business_case_list.findIndex(item => { return item.business_case_id == this.business_case_id })

            if(idx > -1){
                this.title = this.business_case_list[idx].title
                this.description = this.business_case_list[idx].description
                this.time_stamp = this.business_case_list[idx].time_stamp
                this.business_case_e_id = this.business_case_list[idx].business_case_e_id
                this.title_e = this.business_case_list[idx].title_e
                this.description_e = this.business_case_list[idx].description_e
                this.files = this.business_case_list[idx].files
                this.files_e = this.business_case_list[idx].files_e
                this.images = this.business_case_list[idx].images
                this.images_e = this.business_case_list[idx].images_e
                this.loaded = true
            }
            else{ //아직 로딩되지 않은 상태
                this.readBusinessCaseEntity()
            }
        }
    }
}


</script>

<style>

.bc-simple-content {
    white-space: normal;
}

.bc-simple-content > table > tbody > tr > td,
.bc-simple-content > table > tbody > tr > th{
    padding: 0.25rem 0.5rem;
    border-top: 1px solid var(--gray-400);
    border-left: 1px solid var(--gray-400);
}
.bc-simple-content > table > tbody > tr:last-child > td{
    border-bottom: 1px solid var(--gray-400);
}
.bc-simple-content > table > tbody > tr > th:last-child,
.bc-simple-content > table > tbody > tr > td:last-child{
    border-right: 1px solid var(--gray-400);
}
.bc-simple-content > table > tbody > tr > th{
    background-color: var(--gray-300);
}
.bc-simple-content > table > tbody > tr > td{
    background-color: white;
}
.bc-simple-content > table > tbody > tr > td> p{
    margin-bottom: 0;
}
.bc-simple-content > h3{
    font-size: 1.25rem;
}
.bc-simple-content > h2{
    font-size: 1.5rem;
}
.bc-simple-content > h1{
    font-size: 1.75rem;
}
.bc-simple-content > ol, 
.bc-simple-content > ul{   
    padding-inline-start: 20px;
}

.bc-simple-content > p > img{
    max-width: 100% !important;
}

.bc-simple-content > ul > li > p {
    margin-bottom: 0;
}


.hide {
    display: none;
}

</style>
<template>
<div>
    <h2 class="fw-600 mb-4">Universal Standards</h2>

    <h4 id="gr1" class="teal8 mb-4 bgray-200 py-2 rounded d-flex justify-content-between px-3" 
    >GRI 1 : Foundation 2021
    </h4>
    <p>GRI 1: Foundation 2021은 GRI 지속가능성 보고 기준의 목적과 시스템을 소개하며 지속가능성 보고에 대한 핵심 개념을 설명합니다. 또한, GRI 기준에 따라 보고하기 위해 조직이 준수해야 할 요구 사항과 보고 원칙을 구체적으로 명시합니다. GRI 1은 조직이 GRI 기준을 사용하여 어떻게 보고해야 하는지를 이해하기 위해 참고해야 하는 첫 번째 표준입니다.
    </p>
    <b-button 
        variant="teal" 
        class="mb-5"
        href="https://report.qesg.co.kr/document/[QESG] ESG_Reporting_Tool_GRI1_Foundation2021.pdf"
        target="_blank"
    >
        GRI 1 자세히 살펴보기 <fa icon="book" class="ml-2"></fa>
    </b-button>
    <hr class="border-0">

    <h4 id="gr2" class="teal8 mb-4 bgray-200 hover-bg d-flex justify-content-between px-3" 
    v-b-toggle="`gri2toggle`" @mouseenter="mouse_overed.splice(0,1,true)" @mouseleave="mouse_overed.splice(0,1,false)"
    >GRI 2 : 보고 조직에 대한 정보
        <div v-if="mouse_overed[0]==true" class=""><fa icon="angle-up"></fa> </div>
    </h4>

    <b-collapse visible id="gri2toggle">
        <div v-for="two_item in gri_2_list" :key="two_item.id" class="mb-5">

            <b-button variant="tealgray-light" block class="mb-4 f-110 fw-600 text-left" v-b-toggle="`n`+two_item.subtopic_id+two_item.subtopic_code">{{two_item.subtopic_id}}. {{ two_item.subtopic_name }}</b-button>
            
            <b-collapse visible :id="`n`+two_item.subtopic_id+two_item.subtopic_code">
                <b-table-simple v-if="is_gri_2_loaded==true" responsive hover class="bor-table">
                    <b-thead>
                        <b-tr>
                            <b-td>지표 번호</b-td><b-td>지표명</b-td><b-td style="width:80px;">보고 여부</b-td><b-td style="width:50%;">보고 요건</b-td><b-td style="width:70px;">작성 현황</b-td>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="two in hashed_gri_2[two_item.subtopic_code]" :key="two.indicator_id">
                            <b-td>{{two.gri_code}}</b-td>
                            <b-td>{{two.indicator_name}}</b-td>
                            <b-td>필수보고</b-td>
                            <b-td class="position-relative">
                                <div class="f-90 gray-600 fw-300 overflow-hidden" style="max-height: 26px; max-width: calc(100% - 50px)">
                                    <md :contents="two.indicator_requirement"></md>
                                </div>
                                <b-collapse :id="two.gri_code+'txt'" class="f-90 gray-600 fw-300 overflow-hidden" style="margin-top: -26px; max-width: calc(100% - 50px)">
                                    <md :contents="two.indicator_requirement"></md>
                                </b-collapse>
                                <b-button v-if="two.indicator_requirement.length > 40" id="indi-readmore" variant="icon-gray" class="py-0 px-1 f-110 mr-2" v-b-toggle="two.gri_code+'txt'"> </b-button>
                            </b-td>
                            <b-td><writing-status :gri_code="two.gri_code"></writing-status> </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-collapse>
        </div>
    </b-collapse>

    <hr class="border-0 py-2 my-0">
    <h4 id="gr3" class="teal8 mb-4 bgray-200 hover-bg d-flex justify-content-between px-3" v-b-toggle="`gri3toggle`"  
    @mouseenter="mouse_overed.splice(1,1,true)" @mouseleave="mouse_overed.splice(1,1,false)"
    >GRI 3 : 조직의 중요 주제에 대한 정보
        <div v-if="mouse_overed[1]==true" class=""><fa icon="angle-up"></fa> </div>
    </h4>
    
    <b-collapse visible id="gri3toggle">
        <b-table-simple v-if="is_gri_3_loaded==true" responsive hover class="bor-table">
            <b-thead>
                <b-tr>
                    <b-td>지표 번호</b-td><b-td>지표명</b-td><b-td style="width:80px;">보고 여부</b-td><b-td style="width:50%;">보고 요건</b-td><b-td style="width:70px;">작성 현황</b-td>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="three in gri_3" :key="three.indicator_id">
                    <b-td>{{three.gri_code}}</b-td>
                    <b-td>{{three.indicator_name}}</b-td>
                    <b-td>필수보고</b-td>
                    <b-td class="position-relative">
                        <div class="f-90 gray-600 fw-300 overflow-hidden" style="max-height: 26px; max-width: calc(100% - 50px)">
                            <md :contents="three.indicator_requirement"></md>
                        </div>
                        <b-collapse :id="three.gri_code+'txt'" class="f-90 gray-600 fw-300 overflow-hidden" style="margin-top: -26px; max-width: calc(100% - 50px)" >
                            <md :contents="three.indicator_requirement"></md>
                        </b-collapse>
                        <b-button v-if="three.indicator_requirement.length > 40" id="indi-readmore" variant="icon-gray" class="py-0 px-1 f-110 mr-2" v-b-toggle="three.gri_code+'txt'"> </b-button>
                    </b-td>
                    <b-td><writing-status :gri_code="three.gri_code"></writing-status> </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </b-collapse>

</div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import writingStatus from './writingStatus.vue'
export default {
    components: { writingStatus },
    data() {
        return {
            gri_2_list : [],
            gri_2 : [],
            gri_3 : [],
            is_gri_2_loaded : false,
            is_gri_3_loaded : false,
            mouse_overed: [false, false]
        }
    },
    async created(){
        await this.readSubtopics(2)
        this.readIndicatorsList(2)
        this.readIndicatorsList(3)
    },
    updated(){
        // console.log(this.hashed_gri_2);
    },
    computed:{
        ...mapState('report_list', ['report_code', 'standard_mode']),
        ...mapGetters('report_list', ['selected_report', 'gri_ver', 'tcfd_ver']),
        hashed_gri_2() {
            if(this.gri_2.length>0) {
                let temp_list = [...this.gri_2]
                let temp_obj = {}
                for (let i=0; i<temp_list.length; i++){
                    let temp_list_id = temp_list[i].subtopic_code
                    let new_list = temp_list.filter(x => x.subtopic_code == temp_list_id)
                    temp_obj[temp_list_id] = new_list
                }
                return temp_obj
            }     
        },
    },
    methods:{
        readIndicatorsList(filter){
            const path = this.$store.state.backend_host + '/read_all_indicators_list'

            let gri_group_filter = filter
            let gri_version = this.gri_ver
            return axios.get(path, { params: {
                gri_group_filter: gri_group_filter, gri_version: gri_version
            }})
            .then( response => {
                // console.log(response.data);
                if(response.data.code=='success'){
                    if(gri_group_filter == 2){
                        this.gri_2 = response.data.result
                        this.is_gri_2_loaded = true
                    }else {
                        this.gri_3 = response.data.result
                        this.is_gri_3_loaded = true
                    }
                }else console.log(response.data.msg);

            })
        },
        readSubtopics(filter){
            const path = this.$store.state.backend_host + '/read_subtopics'
            return axios.get(path, {params: {gri_subtopic_filter: filter}})
            .then( response =>{
                // console.log(response.data);
                if(response.data.code=='success'){
                    this.gri_2_list = response.data.result
                }else console.log(response.data.msg);
            })

        }
    }

}
</script>

<style>

</style>
<template>
<div>
    <h4>검토하기</h4>
    <p class="f-95 mb-4 gray-600">작성한 보고서를 미리보기로 확인합니다.</p>

    <b-card class="general-card mb-5">
        <b-card-header>보고서 미리보기</b-card-header>
        <p class="pt-3 gray-600 mb-2"> 작성한 보고서를 확인 및 출력합니다.  더이상 수정사항이 없을 경우 ‘공개하기’를 통해 공개할 수 있습니다.</p>
        <p class="f-90 gray-600 mb-45"> <fa icon="info-circle" class="mr-1"/> 제3자 검증의견서가 누락되어 있습니다. 
            <!-- <router-link to="/apply_assurance" class="cursor">검증받기</router-link> -->
            <router-link to="/assurance_info" class="cursor">검증받기</router-link>
            를 통해 제3자 검증의견서를 추가하세요. 
        </p>

        <h6 class="gray-600"> 미리보기 </h6>
        <b-button-group class="mb-45 w-100">
            <b-button 
                variant="teal" 
                class="mr-1"
                @click="$router.push({
                    name: 'FullView', 
                    params: {
                        reload: true
                    }
                })" 
            > 
                {{ selected_report.report_name }} <fa icon="expand" class="ml-1"/> 
            </b-button>
            <!-- <b-button variant="teal" v-b-modal="`compact-previewer`"> 
                GRI Table <fa icon="expand" class="ml-1"/> 
            </b-button> -->
        </b-button-group>

        <h6 class="gray-600"> 수정 바로가기 </h6>
        <b-card class="">
            <router-link 
                :to="{
                    name: 'ThemeSettings', 
                    hash: '#sub1'
                }" 
                class="cursor mr-4"
            >
                표지 수정하기 <fa icon="link" class="pl-1"/> 
            </router-link> 
            <a @click="goGri100" class="cursor">
                내용 수정하기 <fa icon="link" class="pl-1"/>  
            </a>
        </b-card>
    </b-card>
    <!-- <b-modal id="report-previewer" hide-footer size="xl" scrollable>
        <template #modal-title> {{ selected_report.report_name }} </template>
        <report-preview></report-preview>
    </b-modal> -->
    <b-modal id="compact-previewer" hide-footer size="xl" scrollable>
        <template #modal-title> {{ selected_report.report_name }} GRI Table </template>
        <div class="px-lg-4 py-3">
            <gri-table-view-2021 :reload="true"></gri-table-view-2021>
        </div>
    </b-modal>

    <!-- <b-card class="general-card mb-5">
        <b-card-header>보고서 목차</b-card-header>
        <div class="py-3">
            <report-contents-list />
        </div>
    </b-card>  -->

    <b-card class="general-card mb-5">

        <b-card-header>보고서 작성 내용 Excel 다운로드</b-card-header>
        <p class="pt-3 gray-600 mb-5"> '{{ selected_report.report_name }}'에 작성한 모든 내용을 엑셀파일로 일괄 다운로드 할 수 있습니다.</p>
        

        <h6 class="gray-600"> 엑셀 다운로드 </h6>
        <b-button-group class="mb-45 w-100">
            <b-button 
                variant="teal" 
                class="mr-1"
                @click="download_excel('KOR')" 

            > 
                한글 다운로드
                <b-icon icon="download" scale="1" />
            </b-button>
            <b-button 
                variant="teal" 
                class="mr-1"
                @click="download_excel('ENG')" 
            > 
                영문 다운로드
                <b-icon icon="download" scale="1" />
            </b-button>
            <!-- <b-button variant="teal" v-b-modal="`compact-previewer`"> 
                GRI Table <fa icon="expand" class="ml-1"/> 
            </b-button> -->
        </b-button-group>
    </b-card>

</div>
</template>

<script>
// import ReportPreviewMaster from '@/components/report_preview/ReportPreviewMaster'
// import CompactPreview from '@/components/report_preview/CompactPreview'
// import ReportContentsList from '@/components/report_preview/ReportContentsList'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import GRITableView2021 from '../report_preview/gri2021_viewer/GRITableView2021.vue'

import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'


export default {
    mixins: [gri_mixin],
    data() {
        return {
        }
    },
    computed:{
        ...mapState('report_list', ['report_code', 'view_mode']),
        ...mapGetters('report_list', ['selected_report', 'gri_ver']),
    },
    methods:{
        ...mapMutations('report_list', ['update_view_mode']),
        ...mapActions('report_contents', ['readReportContents']),
        ...mapActions('data_tables', ['readDataTableList']),
        ...mapActions('documents', ['readDocumentList']),
        ...mapActions('business_cases', ['readBusinessCaseList']),
        goGri100(){
            this.readReportContents({report_code: this.report_code})
            this.readDataTableList({report_code: this.report_code})
            this.readDocumentList({report_code: this.report_code})
            this.readBusinessCaseList({report_code: this.report_code})

            if(this.gri_ver!='GRIv2021'){
                    this.$router.push({name:'Gri100'})
                }else this.$router.push({name:'Gri2'})
        },
        download_excel(lang) {

            if (!this.handleEdit()) {
                return;
            }

            const link = document.createElement('a');
            link.href = `${this.$store.state.backend_host}/report/download_indicator_by_excel?rc=${this.report_code}&lang=${lang}`;
            document.body.appendChild(link);
            link.click();
            link.remove();
        }           
    },
    components:{
        // ReportPreviewMaster,
        // CompactPreview,
        // 'report-contents-list': ReportContentsList,
        'gri-table-view-2021' : GRITableView2021
    },
    mounted() {
        this.update_view_mode('editor')
    }

}
</script>

<style>

</style>
const data_center = {
    namespaced: true,
    state: {
        lv1_key: "",
        lv2_key: ""

    },
    mutations: {
        update_lv1_key(state, lv1_key) {
            state.lv1_key = lv1_key
        },
        update_lv2_key(state, lv2_key) {
            state.lv2_key = lv2_key
        },
    
    },

    actions: {
        
    }
};


export default data_center;


<template>
    <div>
        <b-container class="pt-3 pb-5 vh-adjust">
            <h3 class="mb-45">
                Data Center 목차 편집하기
            </h3>
            <DataCenterToC style="width: 70%"/>
        </b-container>

    </div>
</template>
<script>
import DataCenterToC from '@/components/managing_indicators/data_centers/DataCenterToC'
export default {
    name: '',
    props: {},
    components: {
        DataCenterToC
    },
    data() {
        return {
            example: '',
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {}
}
</script>
<style>
</style>
export const point_style_options = [
  {
    value: "circle",
    text: "circle",
  },
  {
    value: "cross",
    text: "cross",
  },
  {
    value: "crossRot",
    text: "crossRot",
  },
  {
    value: "dash",
    text: "dash",
  },
  {
    value: "line",
    text: "line",
  },
  {
    value: "rect",
    text: "rect",
  },
  {
    value: "rectRounded",
    text: "rectRounded",
  },
  {
    value: "rectRot",
    text: "rectRot",
  },
  {
    value: "star",
    text: "star",
  },
  {
    value: "triangle",
    text: "triangle",
  },
  {
    value: "false",
    text: "false",
  },
];

export const chart_type_options = [
  {
    value: "bar",
    text: "막대",
  },
  {
    value: "pie",
    text: "pie",
  },
  {
    value: "doughnut",
    text: "도넛",
  },
  {
    value: "line",
    text: "라인",
  },
  {
    value: "polarArea",
    text: "폴라",
  },
  {
    value: "radar",
    text: "radar",
  },
];

export const legend_position_options = [
  {
    value: "top",
    text: "top",
  },
  {
    value: "left",
    text: "left",
  },
  {
    value: "bottom",
    text: "bottom",
  },
  {
    value: "right",
    text: "right",
  },
  {
    value: "chartArea",
    text: "chartArea",
  },
];

export const y_options = [
  {
    text: "(좌측) Y축",
    value: "y-axis",
  },
  {
    text: "(우측) Y축",
    value: "y-axis-right",
  }
];

<template>
<div>
    <h5 class="f-110 mb-4 tail-line-dark">{{document_mode=='docs'?'문서 관리':'외부 링크 관리'}}</h5>
    <b-row no-gutters class="py-2 mb-2">
        <b-col cols="4"> {{document_mode=='docs'?'문서명':'URL(외부 링크)명'}}</b-col>
        <b-col> {{ local_document.title }} </b-col>
    </b-row>
    <b-row no-gutters class="py-2 mb-2" v-if="local_document.used_codes.length>0">
        <b-col cols="4"> 적용된 보고 지표</b-col>
        <b-col>
            <div v-for="code in local_document.used_codes" :key="code.id" class="mb-2">
                <!-- 
                    # validateUsedCodes()가 code를 넣어도 false를 뱉음. selected_indicators 가 항상 비어있음. 왜인지 모르겠으나...
                    <template v-if="validateUsedCodes(code)==true">
                    <a class="cursor" @click="goHash(code)"> GRI {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                </template> -->
                <template >
                    <a class="cursor" @click="goHash(code)"> GRI {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                </template>
            </div>

            <div v-for="code in local_document.used_codes_sasb" :key="code.id" class="mb-2">
                <!-- <template v-if="validateUsedCodes(code)==true"> -->
                <template >
                    <a class="cursor" @click="goHash(code)"> SASB {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                </template>
            </div>
            <div v-for="code in local_document.used_codes_tcfd" :key="code.id" class="mb-2">
                <!-- <template v-if="validateUsedCodes(code)==true"> -->
                <template >
                    <a class="cursor" @click="goHash(code)"> TCFD {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                </template>
            </div>



        </b-col>
    </b-row>
    <b-row no-gutters class="py-2 mb-2">
        <b-col cols="4"> 모든 지표에서 {{document_mode=='docs'?'문서':'URL'}} 삭제</b-col>
        <b-col>
            <p class="mb-3" v-if="local_document.used_codes.length>0">‘{{local_document.title}}’ {{document_mode=='docs'?'문서가':'URL이'}} 적용된 보고 지표가 있습니다. 삭제 시 적용된 모든 지표에서 해당 내용이 사라지며, 다시 복구할 수 없습니다.</p>
            <b-button variant="red" @click="handleDelete">{{document_mode=='docs'?'문서':'URL'}} 삭제하기</b-button>
        </b-col>
    </b-row>
    <b-button class="mt-5 mb-3" @click="handleCancle"> 리스트로 돌아가기 </b-button>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            local_document: {}
        }
    },
    props:{
        document_id : { default: '' }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState('cursor', ['editing_gri_code','document_mode']),
        ...mapGetters('documents', ['doc_list','url_list']),
        ...mapGetters('report_indicators', ['selected_indicators'])

    },
    methods:{
        ...mapActions('documents', ['deleteDocument']),
        handleCancle(hide_option){
            if(hide_option=='hide_all') {
                this.$emit('cancle-edit', hide_option)
            }else this.$emit('cancle-edit')
        },
        async handleDelete(){
            if (confirm('본 문서 및 링크를 사용하고 있는 모든 지표에서 삭제되며 되돌릴 수 없습니다. 삭제하시겠습니까?') ==true){
                await this.deleteDocument(this.local_document.document_id)
            }
            this.handleCancle('hide_all')
        },
        goHash(code){
            let hash_code = '#w'+code
            this.handleCancle('hide_all')
             // this.$router.push({ name: 'Gri'+code.slice(0,1)*100, hash: hash_code })
            if (code.split("-")[0].length > 1) {
                this.$router.push({ name: 'Gri'+code.slice(0,1)*100, hash: hash_code })
                
            } else {
                this.$router.push({ name: 'Gri'+code.split("-")[0], hash: hash_code })
            }
        },
        validateUsedCodes(code){
            if (code){
                let jdx = this.selected_indicators.findIndex(item => item.gri_code == code)
                if (jdx > -1) {
                    return true
                }
                else return false
            }
        }
    },
    created(){
        if (this.document_mode=='docs'){
            let idx = this.doc_list.findIndex(x=>x.document_id == this.document_id)
            if(idx > -1){
                this.local_document = this.doc_list[idx]
            }
        }else {
            let idx = this.url_list.findIndex(x=>x.document_id == this.document_id)
            if(idx > -1){
                this.local_document = this.url_list[idx]
            }
        }
        
    }

}
</script>

<style>

</style>
<template>
    <div>
<!-- <pre>
{{ hashed_data_tables[table_id] }}
</pre> -->
        <section class="mb-5 px-3" v-if="hashed_data_tables">
            <div v-if="edit_mode">
                <div class="mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center"> 
                    <div>
                        <span v-if="hashed_data_tables && hashed_data_tables[table_id]">
                            {{ hashed_data_tables[table_id].title }}
                        </span>
                    </div>
                    <div style="min-width: 70px;">
                        <DataTableBtn 
                            :edit_delete_mode="true"
                            :selected_mng_table_id="table_id"
                            :dc_key_info="dc_key_info"
                        />
                        <!-- <b-button 
                            variant="light" 
                            :disabled="is_unlinked===true" 
                            class="py-1 px-2" v-b-tooltip.hover title="편집" 
                            @click.stop.prevent="editChart"
                        >
                            <fa icon="pen"/>
                        </b-button> -->
                        <b-button 
                            variant="light" 
                            :disabled="is_unlinked===true" 
                            class="ml-1 py-1 px-2" 
                            v-b-tooltip.hover title="없애기" 
                            @click.stop.prevent="removeChild"
                        >
                            <fa icon="unlink"/>
                        </b-button>
                    </div>
                </div>

                
                <b-table-simple responsive class="bor-bg-table mt-3" v-if="hashed_data_tables && hashed_data_tables[table_id]">
                    <b-thead>
                        <b-tr>
                            <b-td v-for="(property, col_index) in hashed_data_tables[table_id].header" :key="col_index">{{ property }}</b-td>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="(row_item, row_index) in hashed_data_tables[table_id].contents" :key="row_item.id">
                            <template v-for="property in hashed_data_tables[table_id].header">
                                <b-td 
                                    v-if="differentPrevious(hashed_data_tables[table_id].contents, row_index, property, hashed_data_tables[table_id].header)==true" 
                                    :key="property.id" 
                                    :rowspan="getSameLength(hashed_data_tables[table_id].contents, row_index, property, hashed_data_tables[table_id].header)"
                                >
                                    <span 
                                        v-html=formattedText(row_item[property])>
                                    </span>    
                                </b-td>
                            </template>
                        </b-tr>
                    </b-tbody>
                    <caption class="mb-2">{{ hashed_data_tables[table_id].footnote }}</caption>
                </b-table-simple>
            </div>
            <div v-else>
                뷰어모드

            </div>

            
        </section> 
    </div>
</template>
<script>
import { gri_mixin } from "@/components/writing_report/write_report_subs/gri_mixin";
import { mapGetters } from 'vuex'
import { formattedText } from '@/components/util'
import DataTableBtn from "@/components/writing_report/chart_subs/DataTableBtn";

export default {
    mixins: [gri_mixin],
    name: '',
    props: {
        payload : { type: Object, default: null },
        table_id: { type: Number, default: null },
        edit_mode: { type: Boolean, default: null },

        lv2_info: { type: Object, default: () => null },
        lv3_idx: { type: Number, default: () => null },
        dc_key_info: { type: Array, default: [] },
    },
    computed: {
        ...mapGetters('data_tables', ['hashed_data_tables']),
    },
    components: {
        DataTableBtn,
    },
    data() {
        return {
            example: '',
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {
        formattedText,
        removeChild() {
            this.$emit("removeChild", this.lv2_info, this.lv3_idx);
        },

        editChart() {

            
        }
    }
}
</script>
<style>
</style>
<template>
    <div id="dc-nav">
      <div>
        <div class="search-box">
          <b-form-input class="search-input" :placeholder="lang !== 'ENG' ? '검색...' : 'search...'"
            @change="search_keyword" v-model="keyword">
          </b-form-input>
          <i class="fas fa-search search-icon"></i>
        </div>
        <b-card no-body v-if="search_result">
          <div v-if="search_result.length > 0">
            <b-button v-for="(search_info, search_idx) in search_result" :key="'search_toc' + search_idx"
              block squared variant="outline-none"
              @click="updateLvKeyBySearch(search_info)">
              {{ search_info.lv1_name }} > {{ search_info.lv2_name }}
            </b-button>
          </div>
          <div v-else>
            <p>{{ lang === 'ENG' ? 'no results.' : '검색 결과가 없습니다.' }}</p>
          </div>
        </b-card>
      </div>
  
      <div v-for="(lv1_info, lv1_idx) in toc_list" :key="'toc_lv1_' + lv1_idx">
        <b-button block :class="['lv1-btn', { 'active-lv1-btn': lv1_key === lv1_info.key }]" squared
          variant="outline-none" size="lg" @click="click_lv1(lv1_info['key'])">
          {{ lang !== 'ENG' ? lv1_info.name : lv1_info.name_em || lv1_info.name_ea }}
        </b-button>
  
        <b-collapse :id="lv1_info.key" class="mt-2"
          :visible="(lv1_idx === 0 && lv1_key === '') || lv1_key === lv1_info.key">
            <b-button 
              v-for="(lv2_info, lv2_idx) in lv1_info.children" :key="'toc_lv2_' + lv2_idx"
              block squared variant="outline-none"
              :class="['lv2-btn', 'my-0', { 'selected-lv2-btn': lv2_key === lv2_info.key }]"
              :id="'lv2_' + lv2_info.key"
              @click="click_lv2(lv2_info.key)">
              {{ lang !== 'ENG' ? lv2_info.name : lv2_info.name_em || lv2_info.name_ea }}
            </b-button>
        </b-collapse>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
  import { getParamsFromQueryString, updateParamsFromQueryString } from '@/components/util'
  export default {
    name: '',
    props: {},
    components: {},
    computed: {
      ...mapGetters('report_list', ['selected_report']),
      ...mapState('charts', ['toc_list']),
      ...mapState('report_list', ['report_code', 'lang']),
      ...mapState('data_center', ['lv1_key', 'lv2_key']),
      selected_nav_main_color() {
        return `color: ${this.selected_report.main_color}; border-left: 3px solid ${this.selected_report.main_color};`
      },
    },
    data() {
      return {
        example: '',
        keyword: "",
        search_result: null,
        original_url: window.location.href.split("&")[0]
      }
    },
    beforeCreate() { },
    created() { },
    beforeMount() { },
    mounted() {
      // console.log('nav')
      if (this.lv1_key === '' && this.toc_list.length > 0) {
        this.update_lv1_key(this.toc_list[0].key);
        this.update_lv2_key(this.toc_list[0].children[0].key);
  
        document.getElementById("lv2_" + this.lv2_key).classList.add('selected_lv2_toc');
        // "'lv2_' + lv2_info.key"
        // this.$root.$emit('bv::toggle::collapse', this.toc_list[0].key)
      }
      this.updateMainColor();
  
  
  
    },
    beforeDestroy() {
      this.update_lv1_key("")
    },
    beforeUpdate() { },
    updated() { },
    beforeUnmount() { },
    unmounted() { },
    methods: {
      // ...mapActions('data_center', ['search_toc_keyword']),
      getParamsFromQueryString,
      updateParamsFromQueryString,
      ...mapMutations('data_center', ['update_lv1_key', 'update_lv2_key']),
      initColoringLv2(lv1_key) {
        document.querySelectorAll('.selected_lv2_toc').forEach(item => {
          item.classList.remove('selected_lv2_toc')
        });
  
        document.getElementById(lv1_key).children[0].classList.add('selected_lv2_toc');
  
  
  
  
      },
      updateMainColor() {
        const mainColor = this.selected_report.main_color;
        console.log("Updating main color to:", mainColor);
        document.documentElement.style.setProperty('--main-color', mainColor);
      },
      click_lv1(lv1_key) {
        this.initColoringLv2(lv1_key);
        this.update_lv1_key(lv1_key)
  
        this.toc_list.forEach((lv1_info) => {
          if (lv1_info['key'] === lv1_key) {
            this.update_lv2_key(lv1_info['children'][0]['key'])
          }
        })
        this.updateParamsFromQueryString('lv1', this.lv1_key)
        this.updateParamsFromQueryString('lv2', this.lv2_key)
  
      },
      color_lv2(e) {
        document.querySelectorAll('.selected_lv2_toc').forEach(item => {
          item.classList.remove('selected_lv2_toc')
        })
        e.target.classList.add('selected_lv2_toc')
      },
      click_lv2(lv2Key) {
      this.update_lv2_key(lv2Key);
  
      const lv2Buttons = document.querySelectorAll('.lv2-btn');
      lv2Buttons.forEach(button => {
        button.classList.remove('selected-lv2-btn');
      });
  
      const selectedButton = document.getElementById(`lv2_${lv2Key}`);
      if (selectedButton) {
        selectedButton.classList.add('selected-lv2-btn');
      }
      this.updateParamsFromQueryString('lv1', this.lv1_key);
      this.updateParamsFromQueryString('lv2', this.lv2_key);
    },
  
      search_keyword() {
        this.search_toc_keyword({
          "report_code": this.report_code,
          "search_keyword": this.keyword,
          "lang": this.lang
        })
      },
  
      async search_toc_keyword({ report_code, search_keyword, lang }) {
  
  
        if (search_keyword.split(' ').join('') === "") {
          this.search_result = null;
          return
        }
  
        try {
          const response = await axios.get(this.$store.state.backend_host + "/searchTocInfo?rc=" + report_code + "&k=" + search_keyword + "&lang=" + lang)
          if (response.data.code) {
            this.search_result = response.data.result;
          }
  
        } catch (error) {
  
        }
      },
  
      updateLvKeyBySearch(search_info) {
        this.update_lv1_key(search_info.lv1_key)
        this.update_lv2_key(search_info.lv2_key)
  
        const newUrl = `${this.original_url}&lv1=${this.lv1_key}&lv2=${this.lv2_key}`;
        window.history.pushState({}, '', newUrl);
      }
    },
    watch: {
    'selected_report.main_color'(newVal) {
      this.updateMainColor(); // 주요 색상이 변경될 때마다 CSS 변수 업데이트
    }
  }
  }
  </script>
  <style scoped>

  .search-input:focus,
  .lv1-btn:hover,
  .lv2-btn:hover {
    transform: scale(1.05);
    transition: transform 0.1s ease-in-out;
  }
  
  .search-box {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    margin-bottom: 50px
  }
  
  .search-input {
    flex-grow: 1;
    border: 0;
    outline: 0;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }
  
  .search-input:focus {
    box-shadow: none;
  }
  
  .search-icon {
    color: black;
    position: absolute;
    right: 0;
  }

  #dc-nav {
    position: sticky;
    top: 100px; 
  }
  
  #dc-nav>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .buttons,
  .b-button,
  .lv1-btn,
  .lv2-btn {
    justify-content: start;
    text-align: left;
  }
  
  .active-lv1-btn::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 60%;
    width: 3px;
    background-color: var(--main-color);
  }
  
  .active-lv1-btn {
    position: relative;
  }
  
  .lv1-btn {
    font-weight: bold;
  }
  
  .lv2-btn {
    color: #00000080 !important;
  }
  
  .selected-lv2-btn {
    color: black !important;
  }
  
  </style>
<template>
<div>
    <h6 class="fw-600">중요 주제란?</h6>
    <p class=" mb-4">중요 주제는 보고 조직에 경제, 환경, 사회적으로 중대한 영향을 미치는 주제를 말합니다.<br>
    GRI Topic Standards 경제, 환경, 사회에서 보고하기로 선택한 주제 중 최소 1개 이상 중요 주제로 보고해야 합니다.<br>
    <span class="f-85">(GRI Standards 요건 충족 기준)</span></p>
    
    <h6 class="fw-600">중요 주제 보고 방법</h6>
    <p class=" mb-4"> 선택한 주제를 중요 주제로 보고할 경우, 해당 주제의 ‘3-3. 중요 주제 관리’ 지표를 작성해야 합니다.</p>

    <h6 class="fw-600">GRI 3: Material Topic에 기반한 중요 주제 결정 프로세스</h6>
    <b-card class="border-0 bgray-200 mb-3">
        <p class="fw-600 mb-1">Step 1) 조직의 맥락 이해하기</p>
        <p class="fw-600 mb-1">Step 2) 실질적, 잠재적 영향 식별하기</p>
        <p class="fw-600 mb-1">Step 3) 영향의 중요성 평가하기</p>
        <p class="fw-600 mb-0">Step 4) 중요주제의 보고 우선순위 정하기 <span class="fw-400 italic"><fa icon="arrow-right"></fa> 보고 조직(사용자)이 우선순위 정하여 GRI 3-2 보고 필요</span> </p>
    </b-card>

    <p class="">- 중요 주제를 결정하는 프로세스의 처음 세 단계(Step 1~3)는 조직의 지속적인 영향 식별 및 평가와 관련이 있습니다. 조직은 사업 활동과 관련 이해관계자 및 전문가와의 상호 작용의 일환으로 영향을 정기적으로 식별하고 평가합니다.
    </p>
    <p class="">- Step 1~3에서 진행하는 영향 식별과 평가는 조직이 변화에 따라 새로운 영향을 적극적으로 식별하고 관리할 수 있게 합니다.
    </p>
    <p class="">- 마지막 단계(Step 4)에서 조직은 가장 중요한 영향을 우선순위화 하여 중요한 주제를 결정합니다.
    </p>

    <div class="my-3">
        <b-button variant="teal" v-b-tooltip.hover.left title="GRI 보고 표준의 최신 버전 Universal Standards, Topic Standards, Sector Standards, 권고 및 지침, GRI 표준 용어집을 포함한 자세한 내용은 GRI 리소스 센터에서 확인하실 수 있습니다."
        href="https://www.globalreporting.org/how-to-use-the-gri-standards/resource-center/" target="_blank"
        >  GRI 리소스 센터에서 GRI 3:Material Topic 원문 및 관련 자료 보기 <fa icon="chevron-right" class="ml-1"></fa>
        </b-button>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
<template>
<div>
    <h5 class="f-110 tail-line-dark">문서 업로드</h5>
    <div class="py-3">
        <p class="fw-400 f-90 mb-2">문서 이름 설정</p>
        <b-form-input v-model="local_file.title" placeholder="문서명" class="mb-3"></b-form-input>
        <p class="fw-400 f-90 mb-2">파일 업로드</p>
        <div v-if="document_id!=''" class="f-95 teal7 mb-2">현재 업로드된 파일 : <a :href="$store.state.static_host+local_file.path" target="_blank">{{local_file.file_name}}</a> </div>
        <b-form-file v-model="local_file.file" placeholder="여기로 끌어오기 또는 파일찾기" class="mb-2"></b-form-file>
        <p class="f-85 mb-5 gray-600"> <fa icon="info-circle" class="mr-1"/> 크기가 20MB 이상인 파일은 업로드할 수 없습니다.</p>
    </div>
    <b-button variant="teal" class="mr-3 mb-3" @click="handleSave()"> 저장하기 </b-button>
    <b-button @click.once="handleCancle" class="mb-3"> 리스트로 돌아가기 </b-button>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            local_file : {}
        }
    },
    watch: {
        document_id: {
            handler(newVal) {
                this.initiateEdit();
            }
        }
    },
    props:{
        document_id : { default: '' }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapGetters('documents', ['doc_list'])
    },
    methods:{
        ...mapActions('documents', ['saveDocument']),
        async handleSave(){
            if (this.checkForm()==true ) {
                await this.saveDocument({
                    report_code: this.report_code,
                    document_id: this.local_file.document_id,
                    title: this.local_file.title,
                    type: this.local_file.type,
                    file: this.local_file.file
                    // link: this.local_file.link,
                })
                this.handleCancle()
            }
        },
        handleCancle(){
            this.initiateEdit()
            this.$emit('cancle-edit')
        },
        initiateEdit(){
            let temp_file = {
                document_id : null,
                report_code : this.report_code,
                title : null,
                description : null,
                type: 'file',
                file_name: null,
                path: null
            }
            if (this.document_id!=''){
                let idx = this.doc_list.findIndex(x => x.document_id == this.document_id)
                if(idx>-1){
                    this.local_file = {...this.doc_list[idx]}
                }
            }
            else this.local_file = temp_file
        },
        checkForm() {
            if ( this.local_file.title == null || this.local_file.title == undefined || this.local_file.title == '' || this.local_file.title == ' '){
                alert ('문서명을 입력해 주세요.') 
                return false
            }
            let idx = this.doc_list.findIndex( doc => doc.title == this.local_file.title ) // 동일한 title이 있는지 확인
            let doc_idx = this.doc_list.findIndex( doc_i => doc_i.document_id == this.document_id ) // document_id가 동일한지 확인
            if ( idx > -1 && idx != doc_idx ){
                alert ('중복되는 문서명이 있습니다. 다른 문서명을 입력해 주세요.') 
                return false 
            } else {
                return true
            }
        }
    },
    created(){
        this.initiateEdit()
        // console.log(this.doc_list)
    }

}
</script>

<style>

</style>
<template>
<div>
    <b-row no-gutters class="pt-4 mb-5">
        <b-col class="mr-3"> <div :class="translate_nav[0]==true?'border-bottom-dark-bold fw-500 gray-800':'border-bottom-gray-550 gray-550'" class="pb-1"> 보고서 영문명 설정하기</div> 
        </b-col>
        <b-col class="mr-3"> <div :class="translate_nav[1]==true?'border-bottom-dark-bold fw-500 gray-800':'border-bottom-gray-550 gray-550'" class="pb-1"> 보고서 자동 번역하기 </div> 
        </b-col>
    </b-row>

    <b-row no-gutters>
        <b-col class="">
            <b-card class="bgray-200 border-0 mb-5 px-5">
                <template v-if="translate_nav[0]==true">
                    <p class="fw-400"> 보고서 영문명 설정하기 </p>
                    <b-overlay :show="is_loading_report_name==true" spinner-small spinner-type="grow">   
                        <b-form-input v-model="report_name_e" placeholder="보고서 영문명" :disabled="is_loading_report_name==true"></b-form-input>
                    </b-overlay>
                </template>

                <template v-if="translate_nav[1]==true">                        
                    <p class="fw-400"> 번역하기 </p>
                    <p class=""> 머신러닝 기반 AI 자동번역을 제공합니다. </p>
                    <p class="red mb-2 fw-400"> <fa icon="exclamation-circle"/> 이미 수동 번역을 진행한 상태에서 번역하기를 누르면 수동 번역된 지표를 제외한 나머지의 지표들이 자동 번역으로 초기화됩니다. <br /> (수동번역을 자동번역으로 돌리려면 해당 지표로 돌아가 번역 초기화를 눌러주세요.) </p>
                    <p class="red mb-4 fw-400"> <fa icon="exclamation-circle"/> 보고서 번역에 약 15분 내외가 소요되며, 보고서 번역하기를 클릭하신 후 15분 후에 재접속 하여 주십시오.  </p>
                    
                    <div class="mb-4 text-center">
                        <b-button :disabled="is_translating==true" @click="handleTranslate" variant="teal" block class="px-3"> {{ is_translating===true? '번역 중' :'보고서 번역하기'}} <b-spinner v-if="is_translating==true" type="grow" style="margin-bottom:0.1rem" small></b-spinner> </b-button> 
                    </div>
                </template>

                <div class="d-flex justify-content-between pt-5">
                    <div>
                        <b-button v-if="translate_nav[0]!=true" variant="half-bg-gray" class="mr-3" @click.stop="pageSlideBack"> <fa icon="angle-left"></fa> <span class="f-95 pl-1">이전</span> </b-button>
                    </div>
                    <div>
                        <b-button v-if="translate_nav[0]==true" class="f-95 mr-2" variant="red" :disabled="is_saving==true" @click.stop="haneleSave"> {{is_saving==true?'저장 중':'저장하기'}} <b-spinner small v-if="is_saving==true"></b-spinner> </b-button>
                        <b-button v-if="translate_nav[1]!=true" variant="tealgray" class="" :disabled="is_saving==true" @click.stop="pageSlide"> <span class="f-95 pr-1">다음</span> <fa icon="angle-right"></fa> </b-button>
                    </div>
                </div>
            </b-card>           
        </b-col>
    </b-row>

</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'


export default {
    mixins: [gri_mixin],

    data() {
        return {
            is_translating: false,
            translate_nav: [true, false],
            report_name_e: '',
            is_saving: false,
            is_loading_report_name: false
        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapGetters('report_list', ['selected_report']),

    },
    methods:{
        ...mapActions('report_list', ['translateReportName', 'saveReportPreference']),
        ...mapActions('report_contents', ['readReportContents', 'translateReport']),
        ...mapActions('data_tables', ['readDataTableList']),
        ...mapActions('documents', ['readDocumentList']),
        ...mapActions('business_cases', ['readBusinessCaseList']),
        ...mapMutations('report_contents', ['update_is_report_translated']),

        async handleTranslate() {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return
            
            this.is_translating = true
            this.translateReport({report_code: this.report_code})
            alert(`번역이 시작되었습니다. 자동번역에 시간이 소요되니 15분 후 다시 '번역'탭으로 접속하여 주십시오.`)
            this.$router.push({name: 'Main'})
            // this.readAll() 
            this.is_translating = false
            // this.update_is_report_translated(true)
        },
        readAll(){
            this.readReportContents({report_code: this.report_code})
            this.readDataTableList({report_code: this.report_code})
            this.readDocumentList({report_code: this.report_code})
            this.readBusinessCaseList({report_code: this.report_code})
        },
        pageSlide(){
            let page_num = this.translate_nav.findIndex(x => x == true)
            this.translate_nav.splice(page_num, 1, false)
            this.translate_nav.splice(page_num+1, 1, true)
        },
        pageSlideBack(){
            let page_num = this.translate_nav.findIndex(x => x == true)
            this.translate_nav.splice(page_num, 1, false)
            this.translate_nav.splice(page_num-1, 1, true) 
        },
        async haneleSave(){
            this.is_saving = true
            await this.saveReportPreference({
                report_code : this.report_code,
                report_name_em: this.report_name_e
            })
            this.is_saving = false
            this.$EventBus.$emit('make-toast', '보고서 영문명 저장', `보고서 영문명을 저장하였습니다.`)
        },
        initiateReportName(){
            setTimeout(()=>{
                // console.log(this.selected_report);
                this.report_name_e = this.selected_report.report_name_e
                this.is_loading_report_name = false
            },800)
        }
    },
    async created(){
        this.is_loading_report_name = true
        // console.log(this.selected_report);
        if (!this.selected_report?.report_name_e){
            await this.translateReportName({report_code: this.report_code, report_name: this.selected_report.report_name})
        }
        this.initiateReportName()
    },
    mounted(){
        
    },
       


}
</script>

<style>

</style>
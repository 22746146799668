//USER 단위의 billing을 읽어와서, 저장하고 있는 Store

const custom_products = {
    namespaced: true, 
    state: {
        products: null
    },
    mutations: {


        update_products(state, new_products){
            state.products = new_products
        },





        update_product_state(state, payment_info){
            // TODO: developing
            let temp_list = [ ...state.products ]
            let idx = temp_list.findIndex(item => item.product_id == payment_info?.merchant_uid )
            if (idx > -1) {
                if (payment_info?.status === 'paid') {
                    temp_list[idx]['payment_status'] = 1
                }
            }
            //state 인식
            state.products = temp_list
        },

        delete_product(state, billing_id) {
            // TODO: developing
            let idx = state.billing_list.findIndex(item => item.billing_id == billing_id)
            if( idx > -1){
                state.billing_list.splice(idx, 1)
            }
        },

        
    },
    getters:{

    },
    actions: {

        async fetchProducts({ commit, rootState }, user_email) {
            try {
                const response = await axios.get(
                    rootState.backend_host + "/payment/general_product?e_mail=" + user_email
                );

                if (response.data.code) {
                    commit('update_products', response.data.result);
                } else {
                    window.alert("추가 결제 서비스 목록을 가져오는데 실패했습니다.");
                }
                
            } catch (e) {
                window.alert(e)
            }
            
        },




        async generalPayment({ commit, rootState }, {
            productName, productPrice, userName, userEmail, userTel, merchantUid,
        }){
            const IMP = window.IMP;
            // IMP.init(process.env.VUE_APP_IMP_PK_CODE); // 가맹점 식별코드(테스트)
            IMP.agency(process.env.VUE_APP_IMP_PK_CODE, 'GRI'); // 가맹점 식별코드(본에서는 이걸로)
            IMP.request_pay(
              {
                // param
                // pg: "html5_inicis.INIpayTest", // 테스트
                pg: "html5_inicis.MOIreportq",
          
                // pay_method: this.payOption,
                pay_method: 'card',
                merchant_uid: merchantUid,
                name: productName, // 상품명
                amount: productPrice, // 결제 가격
                buyer_email: userEmail,
                buyer_name: userName, //
                buyer_tel: userTel,
                // vbank_due: this.nextDay,
                // vbank_holder: "퀀티파이드이에스지",
                // buyer_addr: "구매자주소",
                // buyer_postcode: "구매자 우편번호",
              },
              (rsp) => {
                // callback
                if (rsp.success) {
                  commit('update_product_state', rsp)
                } else {
                  if (rsp['error_msg'] !== '사용자가 결제를 취소하셨습니다') {
                      window.alert(rsp['error_msg'] + " : " + rsp['merchant_uid'])
                  }
          
                }
              }
            );
        }
    }
}
export default custom_products
<template>
<div>
    <h4>Topic Standards : GRI 200 ~ 400</h4>
    <p class="f-95 mb-4 gray-600"> 경제, 환경, 사회 측면의 보고 주제 및 지표를 선택합니다.</p>

    <template v-if="indicators_list_loaded==true">
        <div v-for="(part, index) in structures" :key="part.id" class="pb-5">
            <!-- 주제 선택 카드 -->
            <b-card class="general-card mb-5" :class="is_editing_topic[index]==true?'bgray-100':'bgray-200'">
                <b-card-header :id="`is`+part.key_code"><h4>주제 선택 - GRI {{part.key_code}}00 <span class="fw-400 f-120 ml-1">{{ part.name }} {{ part.name_e }} </span></h4></b-card-header>
                <div class="pt-3">
                    <!-- 주제 선택 모드 -->
                    <div v-if="is_editing_topic[index]==true" class="py-2 mb-4">
                        <b-table-simple hover class="txt-table border-bottom mb-5">
                            <b-thead><b-tr>
                                <b-td>선택</b-td><b-td>주제 번호</b-td><b-td>주제명</b-td><b-td>중요주제 선택</b-td>
                            </b-tr></b-thead>
                            <b-tbody>
                                <b-tr v-for="topic in topics_part(part.key_code)" :key="topic.id">
                                    <b-td> <b-form-checkbox v-model="topic.selected" :value="1" :unchecked-value="0"></b-form-checkbox> </b-td>
                                    <b-td> {{ topic.topic_id }} </b-td> 
                                    <b-td> <span :id="'pop'+topic.topic_id">{{ topic.topic_name }} <span class="f-90"> ({{ topic.topic_name_e }})</span></span> </b-td>
                                    <b-td> <b-form-checkbox :disabled="topic.selected==0" v-model="topic.material" :value="1" :unchecked-value="0"></b-form-checkbox> </b-td>

                                    <b-popover v-if="topic.topic_id" :target="'pop'+topic.topic_id" triggers="hover focus" placement="top"> <template #title><div class="fw-600 f-95">보고 지표</div></template> <div v-html="topic_description(topic.topic_id)"></div>
                                    </b-popover>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>

                        <a v-b-toggle.material-topic-info class="cursor"> 중요 주제 선택 가이드 <fa icon="expand" class="ml-1"/> </a>
                        <b-collapse id="material-topic-info" visible class="mt-2">
                            <b-card class="bgray-200 border-0">
                                <material-topic-info></material-topic-info>
                            </b-card>
                        </b-collapse>
                    </div>
                    <!-- 선택한 주제 보기 -->
                    <b-row v-else no-gutters class="py-2 mb-4">
                        <b-col cols="3"><div class="gray-600">선택된 주제</div> </b-col>
                        <b-col v-if="isTopicSelected(part.key_code)==true">  
                            <p v-for="topic in topics_part(part.key_code)" :key="topic.id" class="mb-2 fw-400">
                                <template v-if="topic.selected == 1">
                                    <span :id="'popover'+topic.topic_id"> {{ topic.topic_id }} {{ topic.topic_name }} <span class="f-90"> ({{ topic.topic_name_e }})</span> </span>
                                    <b-badge v-if="topic.material==1" class="ml-2" variant="advance"> 중요주제 </b-badge>
                                    <b-popover v-if="topic.topic_id>0" :target="'popover'+topic.topic_id" triggers="hover focus" placement="top"> <template #title><div class="fw-600 f-95">보고 지표</div></template> <div v-html="topic_description(topic.topic_id)"></div>
                                    </b-popover>
                                </template>
                            </p>
                        </b-col>
                        <b-col v-else>
                            <p>선택된 주제가 없습니다. 보고 주제를 선택해주세요.</p>
                        </b-col>
                    </b-row>
                    <div class="py-3" :class="is_editing_topic[index]==true ?'position-sticky topic-save-sticky':''">
                        <b-button :variant="is_editing_topic[index]==true?'teal':'teal-border'" class="px-3 mr-3" @click.stop.prevent="handleEdit(selected_report.is_able_to_edit) && handleMultiTopic(index, part.key_code)"> GRI {{part.key_code}}00 주제 {{ is_editing_topic[index]==true ?'저장하기':` 선택하기`}} </b-button>
                        <b-button v-if="is_editing_topic[index]==true" class="px-3" @click.stop.prevent="handleCancleTopic(index, part.key_code)"> 취소 </b-button>
                    </div>
                </div>            
            </b-card>

            <!-- 지표 선택 카드 -->
            <!-- 지표 선택 카드 -->
            <!-- 지표 선택 카드 -->
            <b-card class="general-card mb-5 border-tealgray">
                <b-card-header :id="`isn`+part.key_code" class="d-flex justify-content-between border-bottom-tealgray"> 
                    <h4 class="teal8">지표 선택 - GRI {{part.key_code}}00 <span class="fw-400 f-120 ml-1">{{ part.name }} </span></h4>
                    <div><b-button @click="table_view=!table_view" variant="tealgray"><fa :icon="table_view==true?'th-large':'list'"></fa> </b-button></div>
                </b-card-header>
                <template v-if="isTopicSelected(part.key_code)==true">
                    <div v-for="topic in topics_part(part.key_code)" :key="topic.id">
                        <div v-if="topic.selected==1" class="py-3" >
                            <h6 class="bg-tealgray150 tealgray500 pl-2 py-1" :class="table_view!=true?'mb-2':'mb-4'"> {{ topic.topic_id }} - {{ topic.topic_name }} <span class="f-90 fw-400"> {{ topic.topic_name_e }}</span></h6>
                            <!-- 테이블 뷰 -->
                            <template v-if="table_view==true">
                                <!-- 지표 선택 모드 -->
                                <b-table-simple v-if="is_editing_indi[index]==true" responsive hover class="bor-table"> 
                                    <b-thead><b-tr>
                                        <b-td>선택</b-td><b-td>지표번호</b-td><b-td>지표명</b-td><b-td style="width: 65%;">보고 요구사항</b-td>
                                    </b-tr></b-thead> 
                                    <b-tbody>
                                        <b-tr v-for="indi in series_contents(topic.topic_id)" :key="indi.id">
                                            <b-td>
                                                <b-form-checkbox v-model="indi.selected" :value="1" :unchecked-value="0"></b-form-checkbox>
                                            </b-td>
                                            <b-td>{{ indi.gri_code }}</b-td>
                                            <b-td><p class="mb-1 f-95 fw-400">{{ indi.indicator_name }}</p></b-td>
                                            <b-td class="position-relative">
                                                <div class="f-90 gray-600 fw-300 overflow-hidden" style="max-height: 26px; max-width: calc(100% - 50px)">
                                                    <md :contents="indi.indicator_description" ></md>
                                                </div>
                                                <b-collapse :id="indi.gri_code+'txt'" class="f-90 gray-600 fw-300 overflow-hidden" style="margin-top: -26px; max-width: calc(100% - 50px)">
                                                    <md :contents="indi.indicator_description"></md>
                                                </b-collapse>
                                                <b-button v-if="indi.indicator_description.length > 40" id="indi-readmore" variant="icon-gray" class="py-0 f-110" v-b-toggle="indi.gri_code+'txt'"> </b-button>
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                                <!-- 선택한 지표 보기 -->
                                <b-table-simple v-else responsive hover class="bor-table"> 
                                    <template v-if="isIndiSelected(topic.topic_id)==true">
                                        <b-thead><b-tr>
                                            <b-td>지표번호</b-td><b-td>지표명</b-td><b-td style="width: 65%;">보고 요구사항</b-td>
                                        </b-tr></b-thead>
                                        <b-tbody>
                                            <b-tr v-for="indi in series_contents(topic.topic_id)" :key="indi.id">
                                                <!-- {{ indi.gri_code }} {{ indi.selected }} -->
                                                <template v-if="indi.selected==1">
                                                    <b-td>{{ indi.gri_code }}</b-td>
                                                    <b-td><p class="mb-1 f-95 fw-400">{{ indi.indicator_name }}</p></b-td>
                                                    <b-td class="position-relative">
                                                        <div class="f-90 gray-600 fw-300 overflow-hidden" style="max-height: 26px; max-width: calc(100% - 50px)">
                                                            <md :contents="indi.indicator_description" ></md>
                                                        </div>
                                                        <b-collapse :id="indi.gri_code+'txt'" class="f-90 gray-600 fw-300 overflow-hidden" style="margin-top: -26px; max-width: calc(100% - 50px)">
                                                            <md :contents="indi.indicator_description"></md>
                                                        </b-collapse>
                                                        <b-button v-if="indi.indicator_description.length > 40" id="indi-readmore" variant="icon-gray" class="py-0 f-110" v-b-toggle="indi.gri_code+'txt'"> </b-button>
                                                    </b-td>
                                                </template>
                                            </b-tr>
                                        </b-tbody>
                                    </template>
                                    <template v-else>
                                        <div class="gray-500 text-center bgray-200 py-2"> 선택된 지표가 없습니다.</div>
                                    </template>
                                </b-table-simple>
                            </template>
                            <!-- 모아보기 뷰 -->
                            <template v-else>
                                <div class="d-flex flex-wrap" >
                                    <template v-if="is_editing_indi[index]==true" >                        
                                        <div v-for="indi in series_contents(topic.topic_id)" :key="indi.id" class="pr-3" style="width: 150px">
                                            <div class="d-flex align-items-end">
                                                <b-form-checkbox v-model="indi.selected" :value="1" :unchecked-value="0" class=""></b-form-checkbox>
                                                <p class="fw-400 mb-0 f-120">{{ indi.gri_code }}</p> 
                                            </div>
                                            <p class="pt-1 mb-1 f-85 line-height-sm">{{ indi.indicator_name }}</p>
                                        </div>
                                    </template>
                                    <template v-else>                        
                                        <div v-for="indi in series_contents(topic.topic_id)" :key="indi.id" class="pr-3" style="width: 150px">
                                            <template v-if="indi.selected==1">
                                                <p class="fw-400 f-120 mb-1">{{ indi.gri_code }}</p> <p class="pt-1 mb-1 gray-500 f-85 line-height-sm">{{ indi.indicator_name }}</p>
                                            </template>
                                        </div>                        
                                    </template>
                                </div>
                            </template>
                        </div>              
                    </div>
                </template>
                <template v-else>
                    <p class="py-3"> '주제 선택'에서 보고 주제를 선택해주세요. 보고 주제를 선택하시면 선택 가능한 지표가 나타납니다. </p>
                </template>

                <div class="py-4" :class="is_editing_indi[index]==true ?'position-sticky indi-save-sticky':''">
                    <b-button :variant="is_editing_indi[index]==true?'teal':'teal-border'" class="px-3 mr-3" @click="handleMultiIndi(index, part.key_code)" :disabled="isTopicSelected(part.key_code)!=true"
                    > GRI {{part.key_code}}00 지표 {{ is_editing_indi[index]==true ?' 저장하기':` 선택하기`}} </b-button>
                    <b-button v-if="is_editing_indi[index]==true" class="px-3" @click="handleCancleIndi(index, part.key_code)"> 취소 </b-button>
                </div>
            </b-card>
        </div>
    </template>

</div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import * as ih from '@/components/util'
import MaterialTopicInfo from '@/components/managing_indicators/indicator2016_components/MaterialTopicInfo'

export default {
    data() {
        return {
            structures: [
                {
                    'key_code' : 2,
                    'name' : '경제',
                    'name_e' : 'Economic'
                },
                {
                    'key_code' : 3,
                    'name' : '환경',
                    'name_e' : 'Environmental'
                },
                {
                    'key_code' : 4,
                    'name' : '사회',
                    'name_e' : 'Social'
                },
            ],
            is_editing_topic: [false, false, false],
            is_editing_indi: [false, false, false],
            local_topics: [],
            local_indicators: [],
            anchor_part_code: 2,
            table_view: true
        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapGetters('report_list', ['selected_report']),
        ...mapState('report_indicators', ['indicators_list', 'topics_list', 'indicators_list_loaded','gri_option_status']),   
        ...mapGetters('report_indicators', ['gri_200to400_all_topics', 'gri_200to400_all_indicators']),  
  
        topics_part(){
            return (num) => {
                let temp_list = this.gri_200to400_all_topics
                return temp_list.filter(x => (x.topic_id > (num*100) && x.topic_id < (num*100 +100)))
            }
        },
        series_contents(){
            return (topic_id) => {
                let temp_list = []
                this.gri_200to400_all_indicators.forEach(x => { if (x.topic_id == topic_id){
                        temp_list.push(x)
                    }
                })
                return temp_list
            }
        },
        isTopicSelected(){
            return (num) => {
                let z = this.topics_part(num).findIndex(x => x.selected == 1)
                if (z > -1){
                    return true
                }else return false
            }
        },
        isIndiSelected(){
            return (topic_id) => {
                let y = this.series_contents(topic_id).findIndex(ind => ind.selected == 1 || ind.selected == true)
                if (y > -1){
                    return true
                }else return false
            }
        },
    },
    methods:{
        ...mapActions('report_indicators', ['linkReportTopics','linkReportIndicators']),
        ...mapActions('report_indicators', ['checkGriOption']),

        // 이하 topic 불러오기, 초기화, 저장, 취소, 모드 설정 등 handler 
        // 이하 topic 불러오기, 초기화, 저장, 취소, 모드 설정 등 handler 
        handleMultiTopic(index, part_code){
            // if (this.selected_report.state<4){
                if (part_code&&part_code!=undefined){
                this.anchor_part_code = part_code
                }
                if(this.is_editing_topic[index]==true){                
                    this.handleSaveTopic(index)
                }
                else {
                    this.toggleEditTopic(index)                
                }
            // } else alert(`공개된 보고서 입니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 지표를 수정할 수 있습니다.`)
        },
        handleCancleTopic(index, part_code){
            if (part_code&&part_code!=undefined){
                this.anchor_part_code = part_code
            }
            this.toggleEditTopic(index)
        },
        toggleEditTopic(index){
            if (this.is_editing_topic[index] ==true){
                this.is_editing_topic = [false, false, false]
            }
            else {
                this.is_editing_topic = [false, false, false]
                this.is_editing_topic.splice(index, 1, true)
            }
            ih.anchorGo('#is'+this.anchor_part_code)
        },
        async handleSaveTopic(index){
            this.makeProcessingTopicToast()
            let temp_topics = this.topics_part(this.structures[index].key_code)
            // console.log(temp_topics);
            let new_pay_load = {}
            for (let i=0; i<temp_topics.length; i++){
                let temp_topic_id = temp_topics[i].topic_id
                let temp_arr = []
                temp_arr.push(temp_topics[i].selected, temp_topics[i].material)
                this.$set(new_pay_load, temp_topic_id, temp_arr)
            }
            // console.log(new_pay_load);
            await this.linkReportTopics({
                report_code : this.report_code,
                link_payload: new_pay_load
            })
            this.$EventBus.$emit('make-toast', '주제 저장', '선택한 주제를 저장하였습니다.')
            this.handleCancleTopic(index)
            this.checkGriOption(this.report_code)
        },

        // 이하 indicator 불러오기, 초기화, 저장, 취소, 모드 설정 등 handler 
        // 이하 indicator 불러오기, 초기화, 저장, 취소, 모드 설정 등 handler 
        handleMultiIndi(index, part_code){
            // if (this.selected_report.state<4){
                if(this.is_editing_indi[index]==true){
                    this.handleSaveIndi(index)
                }
                else {
                    this.toggleEditIndi(index)
                }
                ih.anchorGo('#isn'+part_code)
            // } else alert(`공개된 보고서 입니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 지표를 수정할 수 있습니다.`)
        },
        handleCancleIndi(index, part_code){
            ih.anchorGo('#isn'+part_code)
            this.toggleEditIndi(index)
        },
        toggleEditIndi(index){
            if (this.is_editing_indi[index] ==true){
                this.is_editing_indi = [false, false, false]
            }
            else {
                this.is_editing_indi = [false, false, false]
                this.is_editing_indi.splice(index, 1, true)
            }
        },
        async handleSaveIndi(index){
            this.makeProcessingToast()
            let temp_indi = ih.deepCopy(this.gri_200to400_all_indicators)
            let new_indi_payload = {}
            for (let i=0; i<temp_indi.length; i++){
                this.$set(new_indi_payload, temp_indi[i].gri_code, temp_indi[i].selected)
            }
            // console.log(new_indi_payload);
            await this.linkReportIndicators({
                report_code : this.report_code,
                link_payload: new_indi_payload
            })
            this.$EventBus.$emit('make-toast', '지표 저장', '선택한 지표를 저장하였습니다.')
            this.handleCancleIndi(index)
            this.checkGriOption(this.report_code)
        },
        makeProcessingTopicToast(){
            this.$bvToast.toast(`선택한 주제를 저장하고 있습니다.`, {
                title: '주제 저장중',
                autoHideDelay: 2000,
                appendToast: false,
                toaster: 'b-toaster-bottom-center',
            })
        },
        makeProcessingToast(){
            this.$bvToast.toast(`선택한 지표를 저장하고 있습니다.`, {
                title: '지표 저장중',
                autoHideDelay: 2000,
                appendToast: false,
                toaster: 'b-toaster-bottom-center',
            })
        },
        topic_description(topic_id){
            let filtered = this.gri_200to400_all_indicators.filter(x => x.topic_id == topic_id)
            return filtered.map(y => y.indicator_name).join('<br>')
        }
    },
    mounted(){
        // console.log(this.gri_200to400_all_topics);
        // console.log(this.gri_200to400_all_indicators);
    },
    components:{
        MaterialTopicInfo
    }

}
</script>

<style>

</style>
<template>
<div>
        <router-view name="editor_header"></router-view>

        <b-container class="pt-3 pb-5 vh-adjust">
            <!-- 상단 process indicator -->
            <h3 class="mb-45" v-if="standard_mode==='GRI'">
                GRI 보고 지표 선택하기
            </h3>
            <h3 class="mb-45" v-if="standard_mode==='SASB'">
                SASB 보고 산업 선택하기
            </h3>
            <b-button-group class="mb-45 w-100" v-if="gri_ver!='not_report'&&tcfd_ver!='not_report'" >
                <b-button :variant="standard_mode=='GRI'?'teal-dark':'gray'" @click="setSelectMode('GRI')" :class="standard_mode=='GRI'?'fw-600':''">GRI</b-button>
                <b-button :variant="standard_mode=='TCFD'?'teal-dark':'gray'" @click="setSelectMode('TCFD')" :class="standard_mode=='TCFD'?'fw-600':''">TCFD</b-button>
            </b-button-group>
            <b-row class="mb-5" v-if="standard_mode==='GRI'">
            <!-- <b-row class="mb-5" > -->
                <b-col no-body class="border-0">
                    <div class="pt-2" :class="process_indicator[0]==true? 'border-bottom-dark-bold fw-500 gray-800 pb-2':'border-bottom gray-600'">
                        <div v-if="process_indicator[0]==true">1. {{standard_mode=='GRI'?'GRI':'SASB'}} 보고 지표 추천받기</div>
                        <b-button v-else variant="icon-teal" class="py-1 px-2" @click="pageGo()">1. {{standard_mode=='GRI'?'GRI':'SASB'}} 보고 지표 추천받기 <fa icon="magic" class="ml-1"/></b-button>
                    </div> 
                    <p v-if="process_indicator[0]==true" class="pt-2 f-90">산업 특성 및 기업 규모 등을 입력하고 보고 지표를 추천받으세요.</p>
                </b-col>
                <b-col no-body class="border-0">
                    <div class="py-2" :class="process_indicator[1]==true? 'border-bottom-dark-bold fw-500 gray-800':'border-bottom gray-600'">2. 지표 상세 선택</div> 
                    <p v-if="process_indicator[1]==true&&standard_mode=='GRI'" class="pt-2 f-90">{{is_recommended==true? '추천 지표가 선택되었습니다. 선택된 지표를 기반으로 보고할 지표를 상세 선택하세요.':'보고 주제 및 지표를 직접 선택하세요.' }}</p>
                </b-col>
            </b-row>
            <router-view @indicator_recommended="indicatorRecommended"></router-view>       
        </b-container>

        <b-button v-b-modal.need-help class="need-help-btn" variant="tealgray" v-b-tooltip.hover title="도움이 필요하신가요?"><fa icon="question"/></b-button>

        <router-view name="editor_footer"></router-view>        

    <b-modal id="todo-preference" ref="todo-modal" hide-footer size="xl" scrollable >
        <template #modal-header> <h4 class="mt-1">보고서 작성 로드맵</h4></template>
        <todo-roadmap @hide-todo-modal="hideTodoModal"></todo-roadmap>
    </b-modal>

    <b-modal id="need-help" hide-footer size="" title="문의하기" scrollable >
        <need-help></need-help>
    </b-modal>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            process_indicator: [true, false, false],
            is_recommended: false,
        }
    },
    computed:{
        ...mapState('report_list', ['report_code', 'standard_mode']),
        ...mapGetters('report_list', ['selected_report', 'gri_ver', 'tcfd_ver']),
    },
    methods:{    
        ...mapMutations('report_list', ['set_standard_mode']),
        ...mapMutations('report_indicators', ['update_indicators_list_loaded']),
        indicatorRecommended(){
            this.is_recommended = true
        },
        setTodoVisible (){
            if (localStorage) {
                let str_key = 'hide_todo_'+this.report_code
                let hide_todo_until = new Date(localStorage.getItem(str_key));
                if (hide_todo_until < new Date()) { // expire 시간이 지남 
                    return true
                }
                else return false  // 아직 expire 시간이 안지남 
            }
            return true;
        }, 
        pageGo() {
            if(confirm('지표 추천받기로 돌아가면 선택된 지표가 모두 초기화됩니다. 추천 받기로 돌아가시겠습니까?') === true){

                if (this.standard_mode === 'GRI') this.$router.push({name: 'GetGriRecommendations' })
                if (this.standard_mode === 'SASB') this.$router.push({name: 'GetSasbRecommendations' })
                if (this.standard_mode === 'TCFD') this.$router.push({name: 'GetTcfdRecommendations' })
            }
        },
        showTodoModal(){
            if (this.setTodoVisible() == true){
                setTimeout(() => {
                    this.$refs['todo-modal'].show()
                }, 200)
            }
        },
        hideTodoModal(){
            this.$refs['todo-modal'].hide()
        },
        setSelectMode(mode){
            this.set_standard_mode(mode)
        }
    },
    mounted(){
        if(this.selected_report.state==1){
            this.showTodoModal()
        }
    },
    beforeDestroy(){
        this.update_indicators_list_loaded(false)
    },
    watch:{
        $route:{
            immediate:true,
            handler(to, from){
                // console.log(to);
                if(to.path == '/get_gri_recommendations' || to.path == '/get_sasb_recommendations' || to.path == '/get_tcfd_recommendations'){
                    this.process_indicator = [true, false, false]
                }
                if(to.path == '/gri_indicators' || to.path == '/sasb_indicators' || to.path == '/tcfd_indicators'){
                    this.process_indicator = [false, true, false]
                }                
            }
        },
        report_code(newVal){
            if (newVal==undefined||newVal==''){
                this.$router.push({name:'Main'})
            }
        }
    }

}
</script>

<style>

</style>
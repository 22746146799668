<template>
    <div>
        <div v-for="(business_case, index) in business_case_list" :key="business_case.id">
            <b-card :id="'rpb'+business_case.business_case_id" no-body class="mb-5 border-dark">
                <b-card-header class="bg-transparent border-bottom-gray-600" v-b-toggle="'view'+business_case.business_case_id" >
                    <h6 class="mb-0" v-b-tooltip.hover.bottom title="클릭하여 자세히보기">CASE {{index+1}}. {{ business_case.title }}</h6>
                </b-card-header>
                <b-collapse  :id="'view'+business_case.business_case_id">
                    <b-card-body>
                        <business-case 
                            :lazy_load="false" 
                            :business_case_id="business_case.business_case_id"
                            :business_case_obj ="business_case"
                            :modal_business_case="false" 
                            :show_title="false">
                        </business-case>
                    </b-card-body>
                </b-collapse>
            </b-card>   
        </div>
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import BusinessCase from './BusinessCase.vue'

export default {
    components:{
        BusinessCase
    },
    data() {
        return {
            business_case_list: [],
            loaded: false
        }
    },
    computed:{
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),    
        ...mapState('gri2021_modal_open_status', ['opened_modal_id']), //open된 bc 확인
        ...mapGetters('report_list', ['selected_report']),
    },
    methods:{
        validateUsedCodes(used_codes){
            let temp_arr = []
            for (let i=0; i<used_codes.length; i++){
                let jdx = this.selected_indicators_gri_codes.findIndex(x => x == used_codes[i])
                if (jdx > -1){
                    return true
                    break;
                }
                else false
            }
        },
        readBusinessCaseList(){
            const path = this.backend_host + '/read_business_case_list'

            return axios.get(path, { params: {
                report_code: this.report_code,
                only_used: true,
                sort: 'gri'
            }})
            .then( response => {
                this.business_case_list = response.data
            })
        }
    },
    created(){
        this.readBusinessCaseList()
    },
    

}
</script>

<style>

</style>
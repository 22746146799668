<template>
    <div>
        <b-row no-gutters class="">
            <b-col v-for="category in report_universal_categories" :key="category.id" class="d-flex flex-column align-items-center pr-2">
                <b-button :variant="active_code==category.category_id?'dark':'gray'" pill @click="navAnchor('#rp'+category.category_id)">{{lang!='ENG'? category.category_name : category.category_name_e }}</b-button>
            </b-col>
            <b-col v-for="category in report_topic_specific_categories" :key="category.category_id" :class="category.category_id==400?'pr-0':'pr-2'" 
            class="d-flex flex-column align-items-center">
                <b-button :variant="active_code==category.category_id?'dark':'gray'" pill @click="navAnchor('#rp'+category.category_id)">{{lang!='ENG'? category.category_name : category.category_name_e }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import * as ih from '@/components/util'
import MaterialityBadge from './MaterialityBadge'

export default {
    components:{
        MaterialityBadge,
    },
    data() {
        return {
            show_data_table: {},
            show_business_case: {},
            low_height: false,
        }
    },    
    props:{
        web_view: {type: Boolean, default: false},
        preview_mode: {type: Boolean, default: false}
    },
    computed:{
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapState('gri2021_report_view', ['report_structure', 'report_contents_loaded', 'active_code']),
        ...mapGetters('gri2021_report_view', ['report_topics', 'report_categories', 'report_topic_specific_categories', 'report_universal_categories', 'report_topic_types']),
        ...mapGetters('report_list', ['selected_report']),
        main_color(){
            return `color:`+this.selected_report.main_color
        },
        main_color_sub(){
            return `color:`+this.selected_report.main_color+`; border-top: 1px solid `+this.selected_report.main_color+`!important`+`; border-bottom: 1px solid `+this.selected_report.main_color
        },
        main_bg_color(){
            if(this.selected_report){
                return `background-color: `+ this.selected_report.main_color
            }
            else return `background-color: #494949`
        }
    },
    methods: {
        ...mapActions('gri2021_report_view', ['readReportContents']),
        getUniqueSubtopicsByCategoryIds(category_id){
        //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id == category_id )       
            let unique_subtopics = []
            filtered_structure.forEach( item => {
                if ( unique_subtopics.findIndex( x=> { return x.subtopic_code == item.subtopic_code }) < 0) {
                    unique_subtopics.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e, 
                        subtopic_code: item.subtopic_code, subtopic_name: item.subtopic_name, subtopic_name_e: item.subtopic_name_e })
                }
            })
            return unique_subtopics            
        },
        getUniqueTopicsByCategoryIds(category_id){
        //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id == category_id )       
            let unique_topics = []
            filtered_structure.forEach( item => {
                if ( unique_topics.findIndex( x=> { return x.topic_id == item.topic_id }) < 0) {
                    unique_topics.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e, 
                        topic_id: item.topic_id, topic_name: item.topic_name, topic_name_e: item.topic_name_e })
                }
            })
            return unique_topics
        },
        navAnchor(anchor){
            ih.anchorGo(anchor)
        },
    },
    created(){

    }
}
</script>
<style>
.content-section-compact {
    min-width: 24%;
    width: 33%;
}
.content-section-compact-sub {
    min-width: 33%;
    width: 33%;
}
@media (max-width: 992px) {
    .content-section-compact,
    .content-section-compact-sub {
        width: auto;
    }
}
</style>
//유저 관점에서 보여야 하는 리포트 목록
//리포트 프리퍼런스를 변경하거나, Coauthors 정보가 변경되는 경우 여기가 변경되어야 함

const cursor = {
    namespaced: true, 
    state: {
        document_mode: 'docs',
        editing_gri_code: '',
        editing_dc_code: '',
    },
    mutations: {
        change_document_mode(state, new_mode){
            state.document_mode = new_mode;
        },
        update_editing_gri_code(state, new_gri_code){
            state.editing_gri_code = new_gri_code;
        },
        update_editing_dc_code(state, new_dc_code) {
            state.editing_dc_code = new_dc_code;
        }
    },
    getters: {

    },
    actions: {
    }
}

export default cursor
<template>
  <div>
    <router-view name="editor_header"></router-view>
    <PaymentModal 
      v-if="showSubscriptionPayModal" 
    />

    <div class="vh-adjust overflow-hidden">
      <b-container class="mt-4 py-5" v-if="is_report_list_loaded == true">

        <AdminReportForm v-if="is_admin === 4" />

        <b-card-group deck v-if="report_list.length == 0">
          <b-card
            class="new-reports-card mb-5 py-5 cursor"
            @click="selectReport()"
          >
            <fa icon="plus" class="mr-2" /> 새 보고서
          </b-card>
        </b-card-group>

        <b-card-group deck v-else>
          <b-card
            no-body
            :class="
              `reports-card mb-5 text-left ${stylingBox(item.is_able_to_edit)}`
            "
            v-for="item in report_list"
            :key="item.id"
            :style="`border-color:${item.main_color}`"
          >
            
            <b-overlay :show="is_confirmed != true">
              <div
                class="position-relative px-3 pt-3"
                v-b-tooltip.hover.topright
                :title="item.is_able_to_edit === true? '':'읽기 권한만 있음. 결제 후 편집 및 공개 가능합니다.'"
              >
                <div v-if="item.admin_info">
                  <b-badge v-if="item.admin_info.is_coauthor" variant="primary" > 참여중🙆</b-badge>
                </div>

                <h5
                  @click="selectReport(item.report_code, item.gri_use)"
                  class="cursor fw-400 report-title"
                  v-b-tooltip.hover.topright
                   :title="item.sample_report === true? '연습용 샘플로 자유롭게 편집해 보세요!':''"
                >
                <b-badge variant="info" v-if="item.sample_report">sample</b-badge> 
                {{ item.report_name }}
                </h5>
                

                <div v-if="is_admin===4">
                  <b-badge>
                    {{ item.report_code}}
                  </b-badge>
                </div>
                <div>
                  <b-badge
                    v-for="(type, idx) in item.report_type"
                    :item="type"
                    :key="idx"
                    class="mb-1 mr-1" 
                    :variant=get_badge_color(idx)
                    >
                    <!-- variant="info" -->
                    {{ item.report_type[idx]['description']}}
                  </b-badge>

                  <b-badge
                    v-if="item.data_center"
                    class="mb-1 mr-1"
                    variant="success"
                  >
                    Data Center
                  </b-badge>

                </div>

                <div class="position-absolute settings-btn">

                  <!-- bgray-200 -->
                  <b-button
                    :class="`border-0 position-relative overflow-hidden ${stylingBox(item.is_able_to_edit)}`"
                    :style="`color:${item.main_color};`"
                    v-b-toggle="`set` + item.report_code"
                  >
                    <fa
                      icon="ellipsis-h"
                      :style="`color:${item.main_color};`"
                    />
                    <div
                      class="settings-btn-deco position-absolute"
                      :style="
                        `background-color:${item.main_color}; opacity: 0;`
                      "
                    ></div>
                  </b-button>


                  <b-collapse
                    :id="`set` + item.report_code"
                    class="bg-white border rounded shadow-sm"
                  >
                    <!-- <b-button variant="icon" block class="mt-0"><fa icon="print" class="mr-1"/> <span class="f-100">출력</span></b-button> -->
                    <b-button
                      variant="icon"
                      block
                      class="mt-0"
                      @click="showShareModal(item)"
                      ><fa icon="external-link-square-alt" class="mr-1" />
                      <span class="f-100">공유</span></b-button
                    >
                    <b-button
                      variant="icon"
                      block
                      class="mt-0"
                      @click="
                        handleReportCopy(
                          item.report_code,
                          user_id,
                          item.report_name
                        )
                      "
                      ><fa :icon="['far', 'copy']" class="mr-1" />
                      <span class="f-100">복제</span></b-button
                    >
                  </b-collapse>
                </div>
                <p
                  class="gray-550 f-85 position-absolute hoverable-txt mb-5"
                  style="bottom:0;"
                >
                  {{ item.start_date }} ~ {{ item.end_date }}
                </p>
              </div>
              <div class="report-footer">
                <div class="status-footer-bar position-relative">
                  <div
                    class="status-bar"
                    :style="
                      `background-color:` +
                        item.main_color +
                        `;width:` +
                        (item.state / 4) * 100 +
                        `%`
                    "
                  ></div>
                </div>
                <div class="d-flex justify-content-between px-3 py-1">
                  <div class="gray-500 position-relative">
                    <b-button
                      v-b-toggle="`st` + item.report_code"
                      variant="icon-txt-dark"
                      class="p-0"
                    >
                      {{ stateToString(item.state) }} 중
                    </b-button>
                    <b-collapse
                      :id="`st` + item.report_code"
                      class="bg-white border rounded shadow-sm position-absolute px-3 py-2"
                      style="width: 240px; z-index: 1;"
                    >
                      <div class="mb-1">보고서 작성 단계</div>
                      <div
                        class="d-flex align-items-center pt-1"
                        v-for="(x, idx) in 5"
                        :key="x"
                      >
                        <p
                          class="mb-0 f-85"
                          :class="idx == item.state ? 'fw-500 gray-700' : ''"
                          style="width: 90px!important"
                        >
                          0{{ x }}. {{ stateToString(idx) }}
                        </p>
                        <div class="status-bg-bar position-relative">
                          <div
                            class="status-bar"
                            :style="
                              `background-color:` +
                                item.main_color +
                                `;width:` +
                                (idx / 4) * 100 +
                                `%`
                            "
                          ></div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                  <div class="gray-500">
                    <span
                      v-b-tooltip.hover.bottom
                      :title="'소유권한: ' + item.owner_e_mail"
                      ><fa icon="user" class="ml-2" style="color:gray"/></span
                    ><span
                      v-for="team_mate in item.coauthors"
                      :key="team_mate.id"
                      v-b-tooltip.hover.bottom
                      :title="'팀원: ' + team_mate.e_mail"
                    >
                      <fa icon="user" class="ml-2"
                    /></span>
                  </div>
                </div>
              </div>
              <template #overlay>
                <h6 class="text-left px-5 f-120 tealgray600">
                  이메일 계정 인증 후 <br />
                  참여 가능합니다.
                </h6>
              </template>
            </b-overlay>
          </b-card>

          <b-card class="new-reports-card mb-5 cursor" @click="selectReport()">
            <div
              class="d-flex justify-content-center align-items-center h-100 f-110"
            >
              <fa icon="plus" class="mr-2" />
              새 보고서
            </div>
          </b-card>
        </b-card-group>
      </b-container>
      <hr class="border-0 py-5" />
    </div>
    <b-button
      v-b-modal.need-help
      class="need-help-btn"
      variant="tealgray"
      v-b-tooltip.hover
      title="도움이 필요하신가요?"
      ><fa icon="question"
    /></b-button>

    <router-view name="editor_footer"></router-view>

    <b-modal id="need-help" hide-footer size="" title="문의하기" scrollable>
      <need-help></need-help>
    </b-modal>

    <b-modal id="share-link" hide-footer title="보고서 공유하기" scrollable>
      <share-report :report="sharable_report"></share-report>
    </b-modal>    

    <b-modal id="temporary-notice" hide-footer size="" title="업데이트 안내" scrollable>
      <h6> 플랫폼에서 업데이트된 사항을 확인해보세요!</h6>
      <div 
        v-for="(info, idx) in update_docs_info" 
        :key="idx"
      >
        <div class="mb-3">
          {{ info['update_date']}}월에 업데이트 된 주요 사항을 정리한 자료입니다.
        </div>

        <b-button 
          class="mb-5" 
          variant="teal" 
          :href="info.link" 
          target="_blank"
        >
          {{ info.text}} <fa icon="book-open" class="ml-2"></fa>
        </b-button>

      </div>

    </b-modal>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import * as ih from "@/components/util";
import ShareReport from "@/components/report_preference/ShareReport";
import PaymentModal from "@/components/payment/PaymentModal";
import ChannelService from "@/components/ChannelService";
import AdminReportForm from '@/components/admin/AdminReportForm.vue'

export default {
  components: {
    ShareReport,
    PaymentModal,
    AdminReportForm
  },
  data() {
    return {
      local_report_list: [],
      sharable_report: {},
      showSubscriptionPayModal: false,
      do_not_show_again: false,
      update_docs_info: [
        {
          text: "2024.03. 업데이트 내용 보기 ",
          link: "https://report.qesg.co.kr/document/(QESG) ESG Reporting_System Update_2024.03_v1.pdf",
          update_date: "2024.03"
        },
        {
          text: "2024.01 업데이트 내용 보기 ",
          link: "https://report.qesg.co.kr/document/QESG ESG Reporting_System Update_2024.01_v1.pdf",
          update_date: "2024.01"
          
        },
        // {
        //   text: "2023.09. 업데이트 내용 보기 ",
        //   link: "https://report.qesg.co.kr/document/(QESG) ESG Reporting_System Update_2023.10_v1.pdf"
        // }
      ]
    };
  },
  computed: {
    ...mapState("report_list", ["report_list", "is_report_list_loaded"]),
    ...mapState(["user_id", "is_confirmed", "is_admin"]),
    ...mapGetters("report_list", ["selected_report"]),
    ...mapState("assurance", ["assurance_status"]),
    ...mapState(["licenseInfo"]),
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapActions(["updateLicenseInfo"]),
    ...mapActions("charts", ["getDCToC", "getChartList"]),
    ...mapActions("report_list", ["readReportListByUserId"]),
    ...mapMutations("report_list", ["update_report_code", "set_standard_mode"]),
    ...mapActions("report_indicators", [
      "readReportTopics",
      "readReportIndicators",
    ]),
    ...mapActions("report_contents", ["readReportContents"]),
    ...mapActions("data_tables", ["readDataTableList"]),
    ...mapActions("documents", ["readDocumentList"]),
    ...mapActions("business_cases", ["readBusinessCaseList"]),
    ...mapActions("assurance", ["readAssuranceStatusByReportCode"]),
    get_badge_color(type_name) {
      if (type_name === 'SASBv2023') {
        return 'warning'
      } else if (type_name === 'TCFDv2021') {
        return 'primary'
      } else {
        return "light"
      }
    },
    async selectReport(report_code, gri_ver) {
      if (this.is_confirmed !== true) {
        alert("이메일 계정 인증 후 사용하실 수 있습니다.");
      } else {
        if (report_code) {
          await this.update_report_code(report_code);
          this.pageGo(report_code, gri_ver);
        } else {
          // 보고서 생성 권한 체크
          if (this.licenseInfo === null) {
            alert("플랫폼 사용료 결제 후 새 보고서를 생성하실 수 있습니다.");
            this.$router.push({ name: "PaymentModal" });
            return;
          } else {

            if (!this.licenseInfo) {
              alert("플랫폼 사용료 결제 후 새 보고서를 생성하실 수 있습니다.");
              this.$router.push({ name: "PaymentModal" });
              return;
            } else {
              if (!this.licenseInfo.payment_status) {
                // 사용 중이나 지불이 안된 경우
                alert(
                  "플랫폼 사용료 결제가 완료되지 않았습니다. 결제수단을 다시 등록해주세요."
                );
                this.$router.push("/subscription_payment_method");
                return;
              }

              if (!this.licenseInfo.available_report_cnt_info.code) {
                // 생성 가능 리포트 개수를 초과

                // window.alert(
                //   this.licenseInfo.available_report_cnt_info.message
                // );
                window.alert("프리미엄으로 계정 업그레이드 후 새 보고서를 생성하실 수 있습니다.")
                this.$router.push({ name: "PaymentModal" });

                
                return;
              }
            }
          }

          this.update_report_code("");
          this.$router.push({ name: "BasicSettings" });
        }
      }
    },
    go_editor_page() {
      const first_type = Object.keys(this.selected_report.report_type)[0]
      if (first_type === "GRIv2021") {
        this.set_standard_mode('GRI')
        this.$router.push({ name: "Gri2" });

      } else if (first_type === "GRIv2016") {
        this.set_standard_mode('GRI')
        this.$router.push({ name: "Gri100" });


      } else if (first_type === "SASBv2023") {
        this.set_standard_mode('SASB')
        this.$router.push("/write_report/sasb");

      } else if (first_type === "TCFDv2021") {
        this.set_standard_mode('TCFD')
        this.$router.push("/write_report/tcfd");

      }
    },
    async pageGo(report_code, gri_ver) {
      // console.log('?')
      if (this.selected_report.state == 1) {
        // if (this.selected_report.data_center) {
        //   this.getDCToC({ "report_code": report_code });
        // }

        // this.getChartList({
        //     report_code: report_code,
        //     is_editing: true,
        // });
        // this.readReportTopics({ report_code: report_code });
        // this.readReportIndicators({ report_code: report_code });
        this.$router.push({ name: "SelectGriIndicators" });
      } else if (this.selected_report.state == 2) {
        this.readAll(report_code, gri_ver);



        // if (gri_ver != "GRIv2021") {
        //   this.$router.push({ name: "Gri100" });
        // } else this.$router.push({ name: "Gri2" });
        this.go_editor_page();



      } else if (this.selected_report.state >= 3) {
        await this.readAssuranceStatusByReportCode({
          report_code: report_code,
        });
        this.readAll(report_code, gri_ver);
        if (this.assurance_status <= 3 && this.assurance_status > 1) {
          // this.$router.push({ name: "ApplyAssurance" });
          this.$router.push({ name: "CheckReport" });

        } else if (this.assurance_status >= 4 && this.assurance_status < 6) {
          this.$router.push({ name: "CoVerification" });
        } else if (this.assurance_status == 6) {
          this.$router.push({ name: "GetAssuranceReport" });
        } else {



          // if (gri_ver != "GRIv2021") {
          //   this.$router.push({ name: "Gri100" });
          // } else this.$router.push({ name: "Gri2" });
        this.go_editor_page();




        }
      } else {
        this.$router.push({ name: "BasicSettings" });
      }
    },
    readAll(report_code, gri_ver) {
      // if (this.selected_report.data_center) {
      //   this.getDCToC({ "report_code": report_code });
      // }
      if (gri_ver != "GRIv2021") {


        // this.readReportContents({
        //   report_code: report_code,
        //   editor_mode: true,
        // });
        // this.readDataTableList({ report_code: report_code });
        // this.readDocumentList({ report_code: report_code });
        // this.readBusinessCaseList({ report_code: report_code });
        // this.readReportTopics({ report_code: report_code });
        // this.readReportIndicators({ report_code: report_code });
      } else {
        // this.readReportContents({
        //   report_code: report_code,
        //   editor_mode: true,
        // });
        // this.getChartList({
        //     report_code: report_code,
        //     is_editing: true,
        // });
        // this.readDataTableList({ report_code: report_code });
        // this.readDocumentList({ report_code: report_code });
        // this.readBusinessCaseList({ report_code: report_code });
      }
    },
    initiateReportThumbs() {
      this.report_list.forEach(async (x) => {
        let new_thumb = await ih.getFile(
          x.featured_image_name,
          x.featured_image_path,
          true
        );
        this.$set(x, "thumb_img", new_thumb.link);
      });
    },
    async handleReportCopy(report_code, user_id, report_name) {
      if ((!this.licenseInfo) || (this.licenseInfo && this.licenseInfo.available_report_cnt_info.remain_report_cnt === 0)){
        if (!this.licenseInfo) {
          window.alert('플랫폼 사용료 결제 후 보고서를 복제하실 수 있습니다.')
          this.$router.push({ name: "PaymentModal" });


        } else {
          const requestOpenChannel = window.confirm("추가 생성 권한이 없습니다. 서비스를 업그레이드 하려면 별도로 문의를 부탁드립니다.")
          if (requestOpenChannel) ChannelService.showMessenger();

        }
      } else {
        this.makeProcessingToast();
        let new_report_code = this.makeReportCode();
        await this.copyReport(report_code, user_id, new_report_code);
        this.readReportListByUserId({ user_id: this.user_id });
        this.updateLicenseInfo();
  
        this.initiateReportThumbs();
        this.$EventBus.$emit(
          "make-toast",
          "보고서 복제 완료",
          `${report_name} 보고서가 복제되었습니다.`
        );

      }
      



    },
    makeReportCode() {
      var date = new Date();
      var str = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5);
      var yy = String(date.getFullYear()).slice(2);
      if (date.getMonth() < 9) {
        var mm = "0" + String(date.getMonth() + 1);
      } else {
        var mm = String(date.getMonth() + 1);
      }
      if (date.getDate() < 10) {
        var dd = "0" + String(date.getDate());
      } else {
        var dd = String(date.getDate());
      }
      return str + yy + mm + dd;
    },
    stateToString(state) {
      // TODO: 여기서 read-only를 설정..?
      if (state == 0) {
        return `기본설정`;
      } else if (state == 1) {
        return `지표 선택`;
      } else if (state == 2) {
        return `작성`;
      } else if (state == 3) {
        return `검증`;
      } else if (state == 4) {
        return `공개`;
      } else return "";
    },
    makeProcessingToast() {
      this.$bvToast.toast(`보고서를 복제하고 있습니다. 잠시만 기다려주세요.`, {
        title: "보고서 복제중",
        autoHideDelay: 3000,
        appendToast: false,
        toaster: "b-toaster-bottom-center",
      });
    },
    stylingBox(state) {
      /**
       *
       *
       *  */
      return !state ? "bgray-200" : "bg-white";
    },
    copyReport(report_code, user_id, new_report_code) {
      const path = this.$store.state.backend_host + "/copy_report";
      let formData = new FormData();
      formData.append("report_code", report_code);
      formData.append("user_id", user_id);
      formData.append("new_report_code", new_report_code);
      return axios.post(path, formData).then((response) => {
        console.log(response.data);
      });
    },
    showShareModal(report) {
      document.querySelector("#set" + report.report_code).style.display = 'none';
      if (report.sample_report) {
        alert("샘플 보고서는 공유할 수 없습니다.")
        // v-b-toggle="`set` + item.report_code"
        //this.$refs[report.report_code].hide();
        return;
      }

      if (report.state==4){
        this.sharable_report = report;
        this.$bvModal.show("share-link");
      } else alert('공개중이지 않은 보고서는 공유할 수 없습니다. 보고서 공개 후 공유해주세요.')
    },
    showEditableDesc(reportId, isAbleToEdit) {
      if (isAbleToEdit) {
        const descText = "읽기/편집이 가능합니다.";
        this.subscriptionServiceDesc = descText;
      } else {
      }
    },
    // makeDoNotShowAgain(){
    //           console.log(this.do_not_show_again);
    //           this.do_not_show_again = true
    //           console.log(this.do_not_show_again);
    //       }
  },
  created() {
    this.update_report_code("");
    this.readReportListByUserId({ user_id: this.user_id })
      .then(() => {
        this.initiateReportThumbs();
      })
      .catch((error) => {
        // console.log(error);
        alert(`다중 로그인이 감지되어 로그아웃 합니다. 다시 로그인해 주십시오.`)
        this.logout().then(() => this.$router.push({ name: "Login" }));
      });
  },
  mounted() {
      // if(this.do_not_show_again===false){
          this.$bvModal.show('temporary-notice')
      // }
    // ChannelService.showChannelButton();

    // console.log(this.user_id);
    // if( this.is_confirmed == false) this.$EventBus.$emit('make-toast', '인증 필요' ,'이메일로 발송된 인증번호를 확인해주시기 바랍니다.')
  },
  updated() {
    // console.log(this.report_list);

  },
  // beforeRouteLeave(to, from, next) {
  //   ChannelService.hideChannelButton();
  //   next();
  // },
  watch: {
    // user_id:{
    //     immediate: true,
    //     handler(newVal){
    //         if(newVal == '' || newVal == undefined || newVal == null){
    //             this.$router.push({name: 'Login'})
    //         }
    //     }
    // }
  },
};
</script>
<style>
@media (min-width: 576px) {
  .new-reports-card,
  .reports-card {
    min-width: 80% !important;
  }
}
@media (min-width: 992px) {
  .new-reports-card,
  .reports-card {
    min-width: 30% !important;
    /* max-width: 50%; */
  }
}
.reports-card {
  border-width: 1.4px !important;
  /* box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.05); */
}

/* .reports-card > .report-footer, */
.new-reports-card,
.reports-card {
  transition: all 0.2s;
}
.reports-card:hover {
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.075);
  margin-top: -10px;
}
.new-reports-card:hover {
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.075) !important;
}

.reports-card .card-body {
  background-color: rgba(255, 255, 255, 0.5);
}

.reports-card .report-title {
  width: calc(100% - 40px);
  height: 110px;
  transition: all 0.2s;
}
.reports-card:hover .report-title {
  color: black;
  height: 120px;
}

.reports-card .hoverable-txt {
  opacity: 0;
  transition: all 0.5s ease;
}
.reports-card:hover .hoverable-txt {
  transform: translateY(-7px);
  opacity: 1;
}

.settings-btn {
  left: calc(100% - 38px);
  width: 90px;
  top: 10px;
  z-index: 10;
}
.settings-btn > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 0.5s;
}
.reports-card:hover .settings-btn > .btn > .settings-btn-deco {
  opacity: 0.2 !important;
}
.settings-btn-deco {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s !important;
}
@media (max-width: 768px) {
  .settings-btn {
    left: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.new-reports-card {
  border: 1.4px dashed var(--tealgray500) !important;
  height: 175px;
  color: var(--tealgray500);
}
.new-reports-card:hover {
  border: 1.4px solid var(--tealgray600) !important;
  color: var(--tealgray600);
  /* box-shadow: 0 120px 0 0 rgba(116, 137, 138, 0.1) inset,
                0 -120px 0 0 rgba(116, 137, 138, 0.1) inset; */
}
.status-footer-bar,
.status-bg-bar {
  height: 6px;
  background-color: var(--gray-300);
}
.status-footer-bar {
  width: 100%;
}
.status-bg-bar {
  width: calc(100% - 90px);
}
.status-bar {
  height: 6px;
  top: 0;
  left: 0;
}

.reports-card > .b-overlay-wrap > .b-overlay > div {
  width: 100%;
}
</style>

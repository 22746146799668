<template>
<div>
    <h4>문서 및 링크 관리</h4>
    <p class="f-95 mb-5 gray-600">보고서 작성에 적용할 문서 및 링크를 관리합니다.</p>
    <documents-list @open-edit-modal="openEditModal" @open-manage-modal="openManageModal"></documents-list>   

    <b-modal ref="edit-document" title="문서 및 링크" id="global-editor-modal" hide-footer size="lg" scrollable>
        <template v-if="edit_mode==true">
            <edit-file v-if="document_mode=='docs'" :document_id="document_id" @cancle-edit="cancleEdit"></edit-file>
            <edit-link v-else :document_id="document_id" @cancle-edit="cancleEdit"></edit-link>
        </template>
        <template v-else>
            <manage-document :document_id="document_id" @cancle-edit="cancleEdit"></manage-document>
        </template>        
    </b-modal>
    <hr class="border-0 mb-5">
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import DocumentsList from '@/components/writing_report/documents_subs/DocumentsList'
import EditLink from '@/components/writing_report/documents_subs/EditLink'
import EditFile from '@/components/writing_report/documents_subs/EditFile'
import ManageDocument from '@/components/writing_report/documents_subs/ManageDocument'

export default {
    data() {
        return {
            document_id : '',
            edit_mode : false
        }
    },
    computed:{
        ...mapState('cursor', ['document_mode']),
    },
    methods:{
        ...mapMutations('cursor', ['change_document_mode']),
        openEditModal(document_id){
            this.edit_mode = true
            this.openModal(document_id)
        },
        cancleEdit(){
            this.$refs['edit-document'].hide()
        },
        openManageModal(document_id){
            this.edit_mode = false
            this.openModal(document_id)
        },
        openModal(document_id){
            this.document_id = document_id
            this.$refs['edit-document'].show()
        }

    },
    beforeDestroy(){
        this.change_document_mode('docs')
    },
    created(){
    },
    components:{
        DocumentsList,
        EditLink,
        EditFile,
        ManageDocument
    }
}
</script>

<style>

</style>
<template>
  <b-modal
    size="xl"
    title="거래명세서"
    no-close-on-backdrop
    v-model="showInvoiceModal"
    hide-footer
    id="invoiceModal"
  >
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h6>거래명세서</h6>
      <b-button
        size="sm"
        variant="outline"
        @click="$emit('closeInvoiceModal', false)"
      >
        <b-icon icon="x-circle" scale="1.5" variant="warning"></b-icon>
      </b-button>
    </template>

    <b-overlay :show="loadingShow" no-wrap></b-overlay>
    <b-card
      id="qesgReportingInvoice"
      ref="qesgReportingInvoice"
      class="mb-2"
      bg-variant=""
      v-if="invoiceInfo"
    >
      <b-row class="mt-4 mb-5">
        <b-col cols="4" class="text-left">
          <b-img src="/static_img/qesg.png" style="height:50%"></b-img
        ></b-col>
        <b-col cols="4" class="text-center"><h2>거래명세서</h2></b-col>
        <b-col cols="4 text-right">출력일: {{ today }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <h5>
            공급자
          </h5>
          <hr />
          <b-card-text class="fw-400">
            회사명: (주)퀀티파이드이에스지 <br />
            사업자등록번호 : 349-86-01679<br />
            대표자 : 배익현<br />
            주소 : 서울시 종로구 새문안로3길 12, 3층<br />
            전화번호 : 02-6956-2410<br />
            고객문의: info@qesg.co.kr
          </b-card-text>
        </b-col>
        <b-col cols="6">
          <h5>
            주문자
          </h5>
          <hr />
          <b-card-text class="fw-400">
            <span>성함: {{ invoiceInfo.buyer_name }}</span><br />
            <span>이메일: {{ invoiceInfo.user_id }}</span><br />
            <span v-if="invoiceInfo.tel">전화번호: {{ invoiceInfo.tel }}</span><br />
          </b-card-text>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col cols="6"><h5>세부 내역</h5></b-col>
        <b-col cols="6" class="text-right"
          ><p>
            (단위: 원)
          </p></b-col
        >
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table-simple
            style="background-color: white;"
            bordered
            class="text-center"
            caption-top
          >
            <thead class="thead-light table-sm">
              <tr>
                <th>결제일</th>
                <th>제품/서비스 명</th>
                <th>수량</th>
                <th>단가</th>
                <th>할인적용</th>
                <th>공급가액</th>
                <th>세액</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ invoiceInfo.paid_time }}</td>
                <td>{{ invoiceInfo.service_name }}</td>
                <td>1</td>
                <td>
                  {{ this.formatCurrency(invoiceInfo.original_service_price) }}
                </td>
                <td>
                  {{ this.formatCurrency(invoiceInfo.discount_price) }}
                  <p v-if="invoiceInfo.discount_code">
                    ({{ invoiceInfo.discount_code }})
                  </p>
                </td>
                <td>
                  {{ this.formatCurrency(invoiceInfo.original_service_price) }}
                </td>
                <td>
                  {{
                    this.formatCurrency((invoiceInfo.original_service_price * 10) / 100)
                  }}
                </td>
              </tr>
            </tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <b-row class="mt-5 ml-1">
        <h5>합계 금액</h5>
      </b-row>
      <b-row
        style="background-color: #404040; color: white; padding: 5px; margin: 5px;"
      >
        <b-col cols="9"></b-col>
        <b-col cols="3" class="text-right">
          {{ this.formatCurrency(invoiceInfo.amount_price) }}원
        </b-col>
      </b-row>
    </b-card>

    <b-button variant="tealgray" @click="downloadAsPDF" class="float-right"
      >다운로드</b-button
    >
  </b-modal>

  <!-- <b-button v-b-modal.invoice-modal variant="primary">lg modal</b-button> -->
</template>
<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatCurrency } from "@/components/payment/payment";

export default {
  name: "Invoice",
  components: {},
  data() {
    return {
      loadingShow: true,
      showInvoiceModal: true,
      today: new Date().toISOString().slice(0, 10),
      invoiceInfo: null,
    };
  },
  beforeCreate() {},
  created() {},
  watch: {},
  props: ["selectedPaymentId"],
  beforeMount() {},
  mounted() {
    const path = this.$store.state.backend_host + "/payment/fetchInvoiceInfo";
    axios
      .get(path, { params: { paymentHistoryId: this.selectedPaymentId } })
      .then((response) => {
        this.loadingShow = false;

        if (response?.data.code) {
          this.invoiceInfo = response.data.invoiceInfo;
        } else {
          window.alert(
            `거래명세서가 생성 불가합니다. \n관리자(info@qesg.co.kr)에게 \n${this.selectedPaymentId} 를 참고하여 문의해주세요.`
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(this.selectedPaymentId);
  },
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  methods: {
    formatCurrency,
    downloadAsPDF() {
      // PDF 다운로드 로직 구현
      // 예시: 사용자에게 다운로드 링크 제공
      const element = document.getElementById("qesgReportingInvoice");
      html2canvas(element).then((canvas) => {
        var imgData = canvas.toDataURL("image/png");
        var imgWidth = 190; // 이미지 가로 길이(mm) / A4 기준 210mm
        var pageHeight = imgWidth * 1.414; // 출력 페이지 세로 길이 계산 A4 기준
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        var margin = 10; // 출력 페이지 여백설정
        var doc = new jsPDF("p", "mm");
        var position = 0;

        doc.setDPI;

        // 첫 페이지 출력
        doc.addImage(imgData, "PNG", margin, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        // 한 페이지 이상일 경우 루프 돌면서 출력
        while (heightLeft >= 20) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        // 파일 저장
        doc.save("file-name.pdf");
      });
    },
    printInvoice() {
      window.print();
    },
  },
};
</script>
<style scoped>
#qesgReportingInvoice {
  padding: 20px;
}

@media print {
  #qesgReportingInvoice {
    width: 100%; /* 가로 프린트를 기본값으로 설정 */
  }
}
</style>

<template lang="">
  <div>
    <div>
      <!-- <div class="custom-header-5">
        결제수단 등록/관리
      </div> -->
      <h6 class="pb-1 mb-3 border-bottom-dark" v-if="isPage">결제수단 등록/관리</h6>

        <div v-if="bill_methods == null">
          <b-overlay :show="loadingShow" no-wrap></b-overlay>
        </div>
      
      <div class="py-3" style="padding:5px; margin-top:20px;">
        <p class="f-120 fw-500" v-if="!bill_methods || !bill_methods.length">
          등록된 신용/체크카드가 없습니다.
        </p>
        <p class="f-120 fw-500" v-else>
          현재 등록된 신용/체크카드는
          <span class="fw-500">{{ bill_methods[0].name }}</span> 입니다.
        </p>
      </div>
      <b-button variant="primary" class="mr-2" @click="addPaymentMethod">
        <span v-if="!bill_methods || bill_methods.length">
          정기결제 카드 변경하기
        </span>
        <span v-else>
          정기결제 카드 추가하기
        </span>
      </b-button>
      <b-button v-b-modal.need-help class="light-primary"
        >정기결제 문의하기</b-button
      >

      <!--

      <b-card style="border: none; max-width:50rem;">
        <template>
          <div>
            <b-form-select
              v-model="selectedMethod"
              :options="bill_methods"
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
            >
              <template #first v-if="!bill_methods || !bill_methods.length">
                <b-form-select-option :value="null" disabled
                  >-- 결제수단을 추가해주세요 --</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
        </template>

        <div v-if="bill_methods == null">
          <b-overlay :show="loadingShow" no-wrap></b-overlay>
        </div>
      </b-card>
    -->
      <!-- <b-button
      variant="warning"
      @click="removePaymentMethod"
      style="marginRight:39px;"
      >결제수단 제거</b-button
    > -->

      <br />

      <b-card
        class="text-left"
        style="border:none; margin-top:3rem;"
        no-body
        v-if="isPage"
      >
        <div
          class="bg-light"
          style="border-radius: 10px; padding:10px; width:70%"
        >
          <ul class="fw-400">
            <li>정기결제 수단은 신용/체크카드만 등록 가능합니다.</li>
            <li>
              결제일로부터 30일 이후, 이전 달에 선택한 정기결제 상품 타입에 따라
              요금이 청구됩니다.
            </li>
            <li>결제 수단은 1개만 등록 가능합니다.</li>
            <li>
              세금 계산서 발행 등 기타 결제 수단으로 결제를 원하는 경우
              <a href="#" v-b-modal.need-help>문의</a>하여 주십시오.
            </li>
          </ul>
        </div>
      </b-card>

      <b-modal id="need-help" hide-footer size="" title="문의하기" scrollable>
        <need-help />
      </b-modal>
    </div>
  </div>
</template>
<script>
// 로그인 한 회원의 정보를 조회하여 등록된 결제수단이 있는지에 대한 여부를 확인한다.
import { mapState } from "vuex";
import ChannelService from "@/components/ChannelService";
import { generateUPK, addPaymethodToQesg } from "@/components/payment/payment";

export default {
  mounted() {
    // ChannelService.showChannelButton();
    this.fetchBillingList();
    this.generateQB();
  },
  // beforeRouteLeave(to, from, next) {
  //   ChannelService.hideChannelButton();
  //   next();
  // },
  computed: {
    ...mapState([
      "e_mail",
      "user_name",
      "tel",
      "is_confirmed",
      "authenticated",
    ]),
  },
  props: {
    isPage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      bill_methods: null,
      loadingShow: true,
      newQBill: "",
      selectedMethod: null,
    };
  },
  methods: {
    generateQB() {
      generateUPK("qesgPay").then((res) => {
        this.newQBill = res;
      });
    },
    removePaymentMethod() {
      console.log(this.selectedMethod);
      axios
        .post(this.$store.state.backend_host + "/payment/removePaymentMethod", {
          selectedMethod: this.selectedMethod,
        })
        .then((response) => {
          if (response.status === 200) {
            this.fetchBillingList();
            window.alert("결제 수단 업데이트 완료");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addPaymentMethod() {
      // 잠시 주석처리!
      const IMP = window.IMP;
      IMP.agency(this.$store.state.imp_pk_code, 'GRI'); // 가맹점 식별코드

      IMP.request_pay(
        {
          pg: "html5_inicis.MOIrepbill", // 실제 계약 후에는 실제 상점아이디로 변경
          // pg: "kakaopay", // 실제 계약 후에는 실제 상점아이디로 변경
          pay_method: "card", // 'card'만 지원됩니다.
          //   merchant_uid: this.muid, // 상점에서 관리하는 주문 번호
          name: "QESG 정기결제 결제수단 등록",
          amount: 0, // 결제금액
          customer_uid: this.newQBill, // 필수 입력.
          //   customer_uid: "my_billing_key3", // 필수 입력. your-customer-unique-id232412
          buyer_email: this.e_mail,
          buyer_name: this.user_name,
          buyer_tel: this.tel,
          // billing_amount: 1000, //정기결제 요금
        },
        (rsp) => {
          // callback
          if (rsp.success) {
            addPaymethodToQesg(this.e_mail, this.newQBill).then((is_added) => {
              if (is_added) {
                this.fetchBillingList();
                window.alert("결제 수단 추가 완료");
              } else {
                window.alert("결제 오류 발생: 다시 시도해주세요");
                // window.location.reload();
              }
            });
          } else {
            // 결제 실패 시 로직,
            if (rsp.error_msg === "사용자가 결제를 취소하셨습니다") {
              window.alert("결제수단 등록 취소");
            } else {
              console.log("결제수단 등록 중 에러 발생: " + rsp.error_msg);
              window.alert("결제수단 등록에 실패했습니다. 다시 시도해주세요.");
            }
            // window.location.reload();
          }
        }
      );
    },
    fetchBillingList() {
      axios
        .post(this.$store.state.backend_host + "/payment/fetchPaymethodInfo", {
          user_email: this.e_mail,
        })
        .then((response) => {
          this.loadingShow = false;
          if (response.data.code && response.data.methods) {
            this.bill_methods = response.data.methods;

            if (response.data.methods.length > 0) {
              this.selectedMethod = this.bill_methods[0].item;
            } else {
              this.selectedMethod = null;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};


</script>

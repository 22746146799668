<template>
<div v-view="viewHandler"></div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
    props:{
        active_code: {default: null},
        print_mode: {type: Boolean, default: false}
    },
    methods: {
        ...mapMutations('gri2021_report_view', ['update_active_contents_list']),
        viewHandler(event){
            if(this.print_mode==false){
                // console.log(event);

                if(event.type != 'progress'&&event.scrollPercent > 0) { 
                    // console.log(this.active_code);

                    this.update_active_contents_list(this.active_code)
                }
            }
        }

    }
}
</script>

<style>

</style>
<template lang="">
  <div>

    <b-card no-body class="overflow-hidden" style="max-width: 70%;">
      <b-row no-gutters>
        <b-col md="6">
          <b-card-img
            src="http://qesg.co.kr/images/re_1.svg"
            alt="Image"
            class="rounded-0"
          ></b-card-img>
        </b-col>
        <b-col md="6">
          <b-card-body title="Gri Reporting">
            <b-card-text>
              문제가 발생했습니다. 관리자에게 문의해주세요.
            </b-card-text>
            <b-card-text>
                문의. info@qesg.co.kr
            </b-card-text>

            <b-button @click="$router.push('/')" >메인으로 돌아가기</b-button>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
export default {};
</script>
<style lang=""></style>

<template>
  <div>
    <b-overlay v-if="etc_info === null" :show='true' no-wrap></b-overlay>
    <div v-else>
      <Header
        :logo_path="etc_info.logo_path"
        :report_name="
          $route.query.lang !== 'ENG'
            ? `${etc_info.report_name}`
            : `${etc_info.report_name_e}`
        "
        :main_color="etc_info.main_color"
      />
      <!-- <router-view name="editor_header"></router-view> -->
      <div class="vh-adjust overflow-hidden">
        <b-container class="mt-4 py-5">
          <div v-if="etc_info">
            <h3 v-if="$route.query.lang !== 'ENG'">{{ etc_info.title }}</h3>
            <h3 v-else>{{ etc_info.title_e }}</h3>
          </div>

          <div v-if="$route.query.type === 'bsc'" class="m-1 mb-2 fw-500" @click="openImgPopup">
            <!-- 여기다가 가져온 json정보를 바탕으로 컴포넌트 뿌려주기 -->
            <div
              v-if="$route.query.lang !== 'ENG'"
              class="bc-simple-content pb-4"
              v-html="description"
            ></div>
            <div
              v-else
              class="bc-simple-content pb-4"
              v-html="description_e"
            ></div>

            <!-- <div class="bc-simple-content pb-4" v-html="this.$route.query.htmlString" ></div> -->

            <!-- <b-table-simple responsive class="edi-table" v-if="!this.$route.query.htmlString">
            <b-thead>
              <b-tr>
                <b-td>헤더1</b-td><b-td>헤더2</b-td><b-td>헤더3</b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(p, i) in [1, 2, 3, 4]" :key="i">
                <b-td>내용{{ i + 1 }}</b-td
                ><b-td>내용{{ i + 2 }}</b-td
                ><b-td>내용{{ i + 3 }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple> -->
          </div>

          <div
            v-if="this.$route.query.type === 'dtable'"
            class="m-1 mb-2 fw-500"
          >
            <!-- 여기다가 가져온 json정보를 바탕으로 컴포넌트 뿌려주기 -->

            <template v-if="table_data && $route.query.lang !== 'ENG'">
              <!-- :class="`${table_style}`==='basic_table'?'edi-table':'no-line-table'" -->
              <b-table-simple responsive class="edi-table">
                <b-thead>
                  <b-tr>
                    <b-td
                      v-for="(property, col_index) in table_data.header"
                      :key="`newtab-table-th-cell-${col_index}`"
                    >
                      {{ property }}
                    </b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(row_item, row_index) in table_data.contents"
                    :key="`newtab-table-tr-${row_item.id}-rows-${row_index}`"
                  >
                    <template
                      v-for="(property, header_index) in table_data.header"
                    >
                      <template
                        v-if="
                          differentPrevious(
                            table_data.contents,
                            row_index,
                            property,
                            table_data.header
                          ) == true
                        "
                      >
                        <b-td
                          :key="`newtab-table-td-${row_item.id}-rows-${row_index}-cell-${header_index}`"
                          :rowspan="
                            getSameLength(
                              table_data.contents,
                              row_index,
                              property,
                              table_data.header
                            )
                          "
                        >
                          {{ row_item[property] }}
                        </b-td>
                      </template>
                    </template>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
            <template v-else>
              <b-table-simple responsive class="edi-table">
                <template v-if="table_data && table_data.header_e != null">
                  <b-thead>
                    <b-tr>
                      <b-td
                        v-for="(property, col_index) in table_data.header_e"
                        :key="`newtab-table-th-cell-${col_index}`"
                      >
                        {{ property }}
                      </b-td>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(row_item, row_index) in table_data.contents_e"
                      :key="`newtab-table-td-${row_item.id}-rows-${row_index}`"
                    >
                      <template
                        v-for="(property, header_index) in table_data.header_e"
                      >
                        <template
                          v-if="
                            differentPrevious(
                              table_data.contents_e,
                              row_index,
                              property,
                              table_data.header_e
                            ) == true
                          "
                        >
                          <b-td
                            :key="`newtab-table-td-${row_item.id}-rows-${row_index}-cell-${header_index}`"
                            :rowspan="
                              getSameLength(
                                table_data.contents_e,
                                row_index,
                                property,
                                table_data.header_e
                              )
                            "
                          >
                            {{ row_item[property] }}
                          </b-td>
                        </template>
                      </template>
                    </b-tr>
                  </b-tbody>
                </template>
              </b-table-simple>
            </template>
          </div>
          <b-img
            id="static-header-img"
            style="height: 65px"
            :src="`${$store.state.static_host}${etc_info.logo_path.replaceAll(
              '\\',
              '/'
            )}`"
            class="mb-3 float-right"
          ></b-img>

        </b-container>

      </div>


      <Footer
        :report_name="
          $route.query.lang !== 'ENG'
            ? `${etc_info.report_name}`
            : `${etc_info.report_name_e}`
        "
        :report_url="etc_info.report_url"
        :report_date="`${etc_info.start_date} ~ ${etc_info.end_date}`"
      />
      <!-- <router-view name="editor_footer"></router-view> -->
    </div>
  </div>
</template>
<script>
import Header from "@/components/report_preview/static_viewer/StaticHeader";
import Footer from "@/components/report_preview/static_viewer/StaticFooter";

export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      example: "",
      description: null,
      description_e: null,
      title: null,
      business_case_e_id: null,
      table_data: null,
      etc_info: null,
    };
  },
  beforeCreate() {},
  created() {
    this.get_description();
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  methods: {
    openImgPopup(e) {
      const img = e.target;
      if (img.tagName === 'IMG') {
        this.$EventBus.$emit('image-modal', img.getAttribute('src'), true)
      }
    },
    change_header_desgin() {
      // const logo_img = document.querySelector("#static-header-img")
      // logo_img.src = this.$store.state.static_host + this.etc_info.logo_path.replaceAll("\\", "/")
      document.querySelector("title").textContent =
        this.$route.query.lang === "KOR"
          ? this.etc_info.report_name
          : this.etc_info.report_name_e;
      // document.querySelector("#static-header").style.backgroundColor = this.etc_info.main_color
      // document.querySelector("#static-header-title").style.backgroundColor = this.etc_info.main_color
      // document.querySelector("#static-header-title").textContent = this.$route.query.lang === "KOR"?this.etc_info.report_name : this.etc_info.report_name_e;
    },
    get_description() {
      console.log(this.$route.query);

      if (this.$route.query.type === "bsc") {
        const path =
          this.$store.state.backend_host + "/read_business_case_entity";

        return axios
          .get(path, {
            params: {
              business_case_id: this.$route.query.id,
              clean: true,
            },
          })
          .then((response) => {
            this.etc_info = response.data;
            this.change_header_desgin();

            this.title = response.data.title;
            this.description = response.data.description;
            // this.time_stamp = response.data.time_stamp
            // this.business_case_e_id = response.data.business_case_e_id
            // this.title_e = response.data.title_e
            this.description_e = response.data.description_e;
            // this.files = response.data.files
            // this.files_e = response.data.files_e
            // this.images = response.data.images
            // this.images_e = response.data.images_e
            // this.loaded = true
          });
      } else if (this.$route.query.type === "dtable") {
        const path = this.$store.state.backend_host + "/read_data_table_entity";

        return axios
          .get(path, {
            params: {
              data_table_id: this.$route.query.id,
            },
          })
          .then((response) => {
            this.etc_info = response.data;

            this.table_data = response.data;
            // this.title = response.data.title
            // this.description = response.data.description
            // // this.header = response.data.header
            // // this.contents = response.data.contents
            // // this.footnote = response.data.footnote
            // // this.options = response.data.options
            // // this.time_stamp = response.data.time_stamp
            // // this.time_stamp_e = response.data.time_stamp_e
            // // this.title_e = response.data.title_e
            // this.description_e = response.data.description_e
            // // this.header_e = response.data.header_e
            // // this.contents_e = response.data.contents_e
            // // this.footnote_e = response.data.footnote_e
            // // this.loaded = true
          });
      }
    },

    differentPrevious(o_array, index, key, header) {
      if (index - 1 < 0) return true;
      let key_idx = header.findIndex((x) => x == key);
      if (o_array[index][key] === o_array[index - 1][key] && key_idx == 0) {
        return false;
      } else {
        return true;
      }
    },
    getSameLength(o_array, index, key, header) {
      let rowspan = 1;
      let key_idx = header.findIndex((x) => x == key);
      for (let i = index; i < o_array.length; i++) {
        if (i + 1 >= o_array.length) {
          return rowspan;
        }
        if (o_array[i][key] === o_array[i + 1][key] && key_idx == 0) {
          rowspan += 1;
        } else {
          return rowspan;
        }
      }
    },
  },
};
</script>
<style></style>

//"선택"한 보고서가 가지고 있는 비즈니스 사례 리스트와, 
//각 비즈니스 사례의 내용 해시

const assurance = {
    namespaced: true, 
    state: {
        assurance_status: null, // request_status만 반환할때 사용 (Main ~ TodoRoadmap 까지만)
        assurance_list: [
            // {
            //     "index": 0,
            //     "assurance_id": 2,
            //     "report_code": "r13",
            //     "request_publish_date": "2020-10-30T00:00:00.000Z",
            //     "request_total_employees": 3014,
            //     "request_revenue": 100000000,
            //     "request_number_of_sites": 2,
            //     "request_status": "Pending", // assurance 전체 status control 0 ~ 6
            //     "request_onsite_date": null,
            //     "request_onsite_address": null,
            //     "request_onsite_name": null,
            //     "request_onsite_e_mail": null,
            //     "request_onsite_tel": null,
            //     "request_onsite_others": null,
            //     "request_time_stamp": "2020-10-12 18:36:14",
            //     "contract_tax_name": null, ----------->> 없애기
            //     "contract_tax_e_mail": null, ---------->> 없애기
            //     "contract_tax_tel": null, ---------->> 없애기
            //     "contract_signed": null, -------->> 없애기
            //     "contract_time_stamp": null, --------->> 없애기
            //     "contract_payed" :null // --------->> 없애기
            //     "assurance_report_id": 0, 
            //     "report_assurance_status": 0,
            //     "report_assurance_date": "2020-10-23T00:00:00.000Z",
            //     "report_statement": "Completed",
            //     "report_time_stamp": "2020-10-12 18:35:45"
            // }
        ],
        assurance_review_contents: [
            // {
            //     "index": 0,
            //     "review_item_id": 3,
            //     "assurance_report_id": 2,
            //     "gri_code": "404-1",
            //     "status": "open",
            //     "time_stamp": "2020-10-13 11:24:16",
            //     "comments": [
            //         {
            //             "index": 0,
            //             "comment_id": 1,
            //             "review_item_id": 3,
            //             "author_id": 1,
            //             "comments": "It's my comment",
            //             "author_type": "assurance_provider",
            //             "time_stamp": "2020-10-13 11:43:48",
            //             "files" : []
            //         }
            //     ]
            // }
        ],
        show_apply_estimate: true,
        assurance_list_loaded: false,
        admin_assurance: {}
    },
    mutations: {
        update_assurance_list(state, new_list_obj) {
            state.assurance_list = new_list_obj
        },
        update_assurance_list_loaded(state, flag){
            state.assurance_list_loaded = flag
        },
        update_assurance_status(state, new_state){
            state.assurance_status = new_state
        },
        update_assurance_review_contents(state, new_contents ){
            let temp_contents = new_contents.sort((a, b)=> parseFloat(a.gri_code) - parseFloat(b.gri_code))
            state.assurance_review_contents = temp_contents
        },
        add_or_update_assurance_request(state, new_request_obj){
            let temp_assurance_list = [ ...state.assurance_list ]
            let idx = temp_assurance_list.findIndex(item => item.assurance_id == new_request_obj.assurance_id)
            if( idx > -1 ){
                let update_target = temp_assurance_list[idx]
                for( const [key, value] of Object.entries(new_request_obj)){
                    update_target[key] = value
                }
            }
            else{ temp_assurance_list.push(new_request_obj)
            }
            //인식
            state.assurance_list = temp_assurance_list
        },
        add_or_update_assurance_contract(state, new_contract_obj){
            let temp_assurance_list = [ ...state.assurance_list ]
            let idx = temp_assurance_list.findIndex(item => item.assurance_id == new_contract_obj.assurance_id)
            if( idx > -1 ){
                let update_target = temp_assurance_list[idx]
                for ( const [key, value] of Object.entries(new_contract_obj)){
                    update_target[key] = value
                }
            }
            else{ temp_assurance_list.push(new_contract_obj)
            }
            //인식
            state.assurance_list = temp_assurance_list
        },
        update_show_apply_estimate(state, mode){
            state.show_apply_estimate = mode
        },
        add_or_update_review_comment(state, new_comment_obj){
            let temp_assurance_contents = [ ...state.assurance_review_contents ]
            let itm_idx = temp_assurance_contents.findIndex(item => item.review_item_id == new_comment_obj.review_item_id )
            if(itm_idx > -1){
                let temp_review_item = temp_assurance_contents[itm_idx]

                let idx = temp_review_item.comments.findIndex(item => item.comment_id == new_comment_obj.comment_id)
                if(idx > -1){
                    temp_review_item.comments.splice(idx, 1, new_comment_obj)
                }
                else{
                    temp_review_item.comments.push(new_comment_obj)
                }
            }
            //인식
            state.assurance_contents = temp_assurance_contents
        },
        update_delete_comment(state, deleted_comment_id){
            let temp_assurance_contents = [ ...state.assurance_review_contents ]
            temp_assurance_contents.forEach(x => {
                let ydx = x.comments.findIndex(y => y.comment_id==deleted_comment_id)
                if( ydx >-1 ){
                    x.comments.splice(ydx, 1)
                }
            })
            state.assurance_contents = temp_assurance_contents
        },
        update_admin_assurance(state, assurance_id) {
            let temp_assurance_list = [...state.assurance_list]
            let idx = temp_assurance_list.findIndex( a => a.assurance_id == assurance_id )
            if(idx > -1) {
                state.admin_assurance = temp_assurance_list[idx]
                // console.log(temp_assurance_list[idx]);
            }else console.log(assurance_id+'기반의 assurance정보를 찾을 수 없습니다');
        }
    },
    getters: {
        assurance_id(state){
            if (state.assurance_list.length>0){
                return state.assurance_list[state.assurance_list.length - 1].assurance_id
            }else return null
        },
        latest_assurance(state){
            if (state.assurance_list.length>0){
                return state.assurance_list[state.assurance_list.length - 1]
            }else return null
        },
        hashed_assurance(state){
            let temp_list = [ ...state.assurance_list]
            let temp_obj = {}
            for (let i=0; i<temp_list.length; i++){
                temp_obj[temp_list[i].report_code] = temp_list[i]
            }
            return temp_obj
        },
    },
    actions: {
        readAssuranceListByReportCode({ commit, state, rootState }, { report_code }) {
            const path = rootState.backend_host + '/read_assurance_list'

            return axios.get(path, { params: {
                report_code: report_code
            }})
                .then(response => {
                commit('update_assurance_list', response.data)
                commit('update_assurance_list_loaded', true)
            })

        },
        readAssuranceStatusByReportCode({commit, rootState}, {report_code} ){   // request_status만 반환할때 사용 (Main ~ TodoRoadmap 까지만)
            const path = rootState.backend_host + '/read_assurance_request_status_by_report_code'

            return axios.get(path, { params: {
                report_code: report_code
            }})
            .then( response => {
                // console.log(response.data);
                commit('update_assurance_status', response.data.request_status)
            })
        },
        async readAssuranceReviewContent({commit, rootState}, { assurance_id }){
            const path = rootState.backend_host + '/read_assurance_review_contents'

            const response = await axios.get(path, { params: {
                assurance_id: assurance_id
            }})

            if (response.data.code) {
                commit('update_assurance_review_contents', response.data.review_items )
                return true
            } else {
                const page_stay = window.confirm("서면 검증이 없습니다. 보고서 검토 페이지로 이동하시겠습니까?")

                if (page_stay) {
                    return false
                } else {
                    return true
                }
            }

        },
        saveAssuranceRequest({commit, rootState}, { report_code, publish_date, total_employees, revenue, number_of_sites, request_status, onsite_date, onsite_address, onsite_name, onsite_e_mail, onsite_tel, onsite_others }){
            const path = rootState.backend_host + '/save_assurance_request'

            let formData = new FormData()
            formData.append('report_code', report_code)
            if(publish_date != null && publish_date != undefined && publish_date != '') formData.append('publish_date', publish_date)
            // if(total_employees != null && total_employees != undefined) formData.append('total_employees', total_employees)
            if(revenue != null && revenue != undefined) formData.append('revenue', revenue)
            // if(number_of_sites != null && number_of_sites != undefined) formData.append('number_of_sites', number_of_sites)
            if(request_status != null && request_status != undefined) formData.append('request_status', request_status)
            // if(onsite_date != null && onsite_date != undefined && onsite_date != '') formData.append('onsite_date', onsite_date)
            // if(onsite_address != null && onsite_address != undefined) formData.append('onsite_address', onsite_address)
            // if(onsite_name != null && onsite_name != undefined) formData.append('onsite_name', onsite_name)
            // if(onsite_e_mail != null && onsite_e_mail != undefined) formData.append('onsite_e_mail', onsite_e_mail)
            // if(onsite_tel != null && onsite_tel != undefined) formData.append('onsite_tel', onsite_tel)
            // if(onsite_others != null && onsite_others != undefined) formData.append('onsite_others', onsite_others)
 
            return axios.post(path, formData)
            .then( response => {
                let new_request_obj = {
                    assurance_id: response.data.assurance_id,
                    report_code: response.data.report_code,
                    request_publish_date: response.data.publish_date,
                    request_total_employees: response.data.total_employees,
                    request_revenue: response.data.revenue,
                    request_number_of_sites: response.data.number_of_sites,
                    request_status: response.data.request_status,
                    // request_onsite_date: response.data.onsite_date,
                    // request_onsite_address: response.data.onsite_address, 
                    // request_onsite_name: response.data.onsite_name,
                    // request_onsite_e_mail: response.data.onsite_e_mail, 
                    // request_onsite_tel: response.data.onsite_tel, 
                    // request_onsite_others: response.data.onsite_others,  
                    request_time_stamp: response.data.time_stamp,
                }
                // console.log(new_request_obj);
                commit('add_or_update_assurance_request', new_request_obj)
                window.location.reload();
                
            })
        },
        saveAssuranceContract({commit, rootState}, { assurance_id, tax_name, tax_e_mail, tax_tel, signed }){
            const path = rootState.backend_host + '/save_assurance_contract'

            let formData = new FormData()
            formData.append('assurance_id', assurance_id)
            formData.append('signed', signed)
            // formData.append('tax_name', tax_name)
            // formData.append('tax_e_mail', tax_e_mail)
            // formData.append('tax_tel', tax_tel)

            return axios.post(path, formData)
            .then( response => {
                let new_contract_obj = {
                    assurance_id: response.data.assurance_id, 
                    // contract_tax_name: response.data.tax_name, 
                    // contract_tax_e_mail: response.data.tax_e_mail, 
                    // contract_tax_tel: response.data.tax_tel, 
                    contract_signed: response.data.signed, 
                    contract_time_stamp: response.data.time_stamp
                }
                // console.log(new_contract_obj);
                commit('add_or_update_assurance_contract', new_contract_obj)
            })
        },
        saveAssuranceReport({commit, rootState}, { assurance_id, assurance_status, assurance_date, assurance_statement, assurance_statement_em }){
            const path = rootState.backend_host + '/save_assurance_report'

            let formData = new FormData()
            formData.append('assurance_id', assurance_id)
            if(assurance_status != null && assurance_status != undefined) formData.append('assurance_status', assurance_status)  //---> 실제로 쓰지 않음. request_status로 통합 관리
            if(assurance_date != null && assurance_date != undefined) formData.append('assurance_date', assurance_date)
            if(assurance_statement != null && assurance_statement != undefined) formData.append('assurance_statement', assurance_statement)
            if(assurance_statement_em != null && assurance_statement_em != undefined) formData.append('assurance_statement_em', assurance_statement_em)

            return axios.post(path, formData)
            .then( response => {
                // console.log(response.data);
                let new_report_obj = {
                    assurance_report_id: response.data.assurance_report_id, 
                    assurance_id: response.data.assurance_id, 
                    report_assurance_status: response.data.assurance_status, 
                    report_assurance_date: response.data.assurance_date,
                    report_statement: response.data.assurance_statement,
                    report_statement_ea: response.data.assurance_statement_ea,
                    report_statement_em: response.data.assurance_statement_em,
                    report_time_stamp: response.data.time_stamp
                }
                commit('add_or_update_assurance_request', new_report_obj)
            })
        },
        saveAssuranceComment({commit, rootState}, { comment_id, review_item_id, author_id, comments, files_id }){
            const path = rootState.backend_host + '/save_assurance_review_comment'

            let formData = new FormData()
            if(comment_id!=null || comment_id!=undefined) formData.append('comment_id', comment_id)
            formData.append('review_item_id', review_item_id)
            formData.append('author_id', author_id)
            formData.append('comments', comments)
            formData.append('author_type', 'reporter') // 일반 유저는 'reporter'로 보내고, admin은 'auditor' 보내도록 수정 필요
            formData.append('files_id', JSON.stringify(files_id))

            return axios.post(path, formData)
            .then( response => {
                let new_comment_obj = {
                    comment_id: response.data.comment_id, 
                    review_item_id: response.data.review_item_id, 
                    author_id: response.data.author_id, 
                    comments: response.data.comments, 
                    author_type: response.data.author_type, 
                    time_stamp: response.data.time_stamp,
                    files: response.data.files
                }
                commit('add_or_update_review_comment', new_comment_obj)
            })           
        },
        deleteAssuranceComment({commit, rootState},  comment_id ){
            const path = rootState.backend_host + '/delete_assurance_review_comment'
            
            let formData = new FormData()
            formData.append('comment_id', comment_id)

            return axios.post(path, formData)
            .then( response =>{
                commit('update_delete_comment', response.data.comment_id)
            }) 
        }   
        
    }
}

export default assurance
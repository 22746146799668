<template>
  <div>
    <div 
        class="accordion" 
        role="tablist"
    >
    <div
      v-if="selected_report && (Object.keys(selected_report.report_type).includes('GRIv2021') || Object.keys(selected_report.report_type).includes('GRIv2016'))"
    >
      <b-card
        no-body
        v-for="(item, index) in writing_side"
        :key="item.id"
        class="shadow-sm"
      >
        <b-card-header header-tag="header" class="p-1 bg-white" role="tab">
          <b-button
            block
            :aria-controls="`sub` + index"
            @click="pageGo(index, item.nav)"
            :variant="
              visible_style[index] == true ? 'icon-txt-dark' : 'icon-txt'
            "
            class="text-left py-2"
            :class="visible_style[index] == true ? 'fw-500' : ''"
            >{{ item.nav }}</b-button
          >
        </b-card-header>
        <b-collapse
          v-if="standard_mode === 'GRI'"
          :id="`sub` + index"
          v-model="visible[index]"
          role="tabpanel"
          accordion
          class="bgray-100"
        >

          <div v-for="(sub_i, idx) in item.sub" :key="sub_i.id">
            <b-button
              block
              :aria-controls="`sub_i` + idx"
              :variant="sub_visible[idx] === true ? 'icon-teal' : 'icon-txt'"
              class="text-left pl-3 f-95 py-1 mt-0"
              :class="
                sub_visible[idx] === true ? 'fw-500 left-nav-indicator' : ''
              "
              @click="pageGoSub(sub_i.name)"
              >{{ sub_i.standards }}</b-button
            >

            <b-collapse
              :id="`sub_i` + idx"
              v-model="sub_visible[idx]"
              role="tabpanel"
              accordion
              class="bgray-200 py-2 overflow-auto"
              style="max-height: calc(80vh - 254px)"
            >
              <template v-if="is_sub_topic_loaded == false">
                <b-skeleton class="my-2 mx-3"></b-skeleton>
                <b-skeleton class="my-2 mx-3"></b-skeleton>
                <b-skeleton class="my-2 mx-3"></b-skeleton>
              </template>
              <template v-else>
                <b-button
                  v-for="(topic, jdx) in sub_i.topics"
                  :key="jdx"
                  block
                  class="text-left f-85 pl-3 py-0"
                  variant="icon"
                  @click="navAnchorGo(topic)"
                  :class="sub_topic_active == true ? 'fw-500' : ''"
                >
                  <!-- {{topic.topic_id}} -->
                  {{ sub_i.name[3] == "1" ? "." : "" }}
                  {{ topic.topic_name }}
                </b-button>
              </template>
        </b-collapse>
        </div></b-collapse>
      </b-card>
        
        
    </div>
  <div 
    v-if="selected_report && 
        (Object.keys(selected_report.report_type).includes('SASBv2023') || 
        Object.keys(selected_report.report_type).includes('TCFDv2021')) || 
        selected_report.data_center"
    >
    <SideNavDropdownBtn  />
  </div > 
      <SideMaterialCheck2021 
        v-if="standard_mode==='GRI'"
        class="pt-3" 
      />
    </div>
    <div 
      class="my-3 f-95"
      v-if="standard_mode==='GRI'"
    >
        <a class="fw-500" v-b-tooltip.hover.left title="GRI 보고 표준의 최신 버전 Universal Standards, Topic Standards, Sector Standards, 권고 및 지침, GRI 표준 용어집을 포함한 자세한 내용은 GRI 리소스 센터에서 확인하실 수 있습니다."
        href="https://www.globalreporting.org/how-to-use-the-gri-standards/resource-center/" target="_blank"
        >  GRI 리소스 센터에서 최신 표준 & 원문 보기 <fa icon="chevron-right" class="ml-1"></fa>
        </a>
    </div>
<!-- Object.keys(selected_report.report_type).includes('SASBv2023') -->
    

    <back-to-top></back-to-top>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import * as ih from "@/components/util";
import SideMaterialCheck2021 from "@/components/managing_indicators/gri2021_indicators/SideMaterialCheck.vue";
import SideNavDropdownBtn from '@/components/writing_report/SideNavDropdownBtn'



export default {
  components: {
        SideMaterialCheck2021,
        SideNavDropdownBtn
  },
  props: {
    report_type: { type: Object },
  },
  data() {
    return {
      writing_side: [
        {
          nav: "GRI",
          sub: [
            {
              standards: "일반(GRI 100)",
              name: "Gri100",
              topics: [
                { topic_id: "1", topic_name: "조직 프로필" },
                { topic_id: "2", topic_name: "전략" },
                { topic_id: "3", topic_name: "윤리 및 청렴성" },
                { topic_id: "4", topic_name: "지배구조" },
                { topic_id: "5", topic_name: "이해관계자 참여" },
                { topic_id: "6", topic_name: "보고 관행" },
              ],
            },
            { standards: "경제(GRI 200)", name: "Gri200", topics: [] },
            { standards: "환경(GRI 300)", name: "Gri300", topics: [] },
            { standards: "사회(GRI 400)", name: "Gri400", topics: [] },
          ],
        },
        
      ],
      visible_style: [true, false, false, false, false],
      is_sub_topic_loaded: false,
      gri2021_subobj: {
        nav: "GRI",
        sub: [
          {
            standards: "GRI 2 - 공통",
            name: "Gri2",
            topics: [
              {
                topic_id: "1",
                subtopic_code: "2_a",
                topic_name: "조직 및 보고 관행",
              },
              {
                topic_id: "2",
                subtopic_code: "2_b",
                topic_name: "활동 및 근로자",
              },
              { topic_id: "3", subtopic_code: "2_c", topic_name: "거버넌스" },
              {
                topic_id: "4",
                subtopic_code: "2_d",
                topic_name: "조직, 정책 및 관행",
              },
              {
                topic_id: "5",
                subtopic_code: "2_e",
                topic_name: "이해관계자 참여",
              },
            ],
          },
          {
            standards: "GRI 3 - 중요주제",
            name: "Gri3",
            topics: [{ topic_id: "", topic_name: "중요주제" }],
          },
          { standards: "Topic - 경제", name: "Gri200", topics: [] },
          { standards: "Topic - 환경", name: "Gri300", topics: [] },
          { standards: "Topic - 사회", name: "Gri400", topics: [] },
        ],
      },
    };
  },
  created() {
    if (this.gri_ver == "GRIv2021") {
      this.writing_side.splice(0, 1, this.gri2021_subobj);
      // console.log(this.writing_side);
      }


    //   if (Object.keys(this.selected_report.report_type)) {
        
    //   }


      
  },
  mounted() {
    this.makeSubTopicNames();
  },
  computed: {
    ...mapState("report_contents", ["report_contents"]),
    ...mapGetters("report_list", ["gri_ver", "selected_report"]),
      ...mapState("report_list", ["standard_mode"]),
    

    visible() {
      if (this.$route.path.includes("write_report") == true) {
        return [true, false, false, false];
      } else {
        return [false, false, false, false];
      }
    },
      sub_visible() {
      let list21 = [false, false, false, false, false];
      let list16 = [false, false, false, false];
      let new_list = [];
      if (this.gri_ver == "GRIv2021") {
        new_list = [...list21];
      } else {
        new_list = [...list16];
      }
      if (this.$route.name == "Gri3") {
        let num = new_list.length - 4;
        new_list.splice(num, 1, true);
        return new_list;
      }
      if (this.$route.name == "Gri200") {
        let num = new_list.length - 3;
        new_list.splice(num, 1, true);
        return new_list;
      }
      if (this.$route.name == "Gri300") {
        let num = new_list.length - 2;
        new_list.splice(num, 1, true);
        return new_list;
      }
      if (this.$route.name == "Gri400") {
        let num = new_list.length - 1;
        new_list.splice(num, 1, true);
        return new_list;
      } else {
        new_list.splice(0, 1, true);
        return new_list;
      }
    },
      topic_names() {
      let topics = [];
      for (let i = 0; i < this.report_contents.length; i++) {
        let new_topics = {
          topic_id: this.report_contents[i].topic_id,
          topic_name: this.report_contents[i].topic_name,
          topic_name_e: this.report_contents[i].topic_name_e,
        };
        let idx = topics.findIndex((x) => x.topic_id == new_topics.topic_id);
        if (idx == -1) {
          topics.push(new_topics);
        }
      }
      return topics;
    },
    sub_topic_active() {
      // 나중에 active 걸기
      // console.log(this.$route);
      return false;
    },
  },
    methods: {
    ...mapMutations('report_list', ['set_standard_mode']),
    pageGo(index, etc_info=null) {
      
      if (index == 0) {
          this.visible_style = [true, false, false, false];
          this.set_standard_mode('GRI')
        
        if (this.gri_ver != "GRIv2021") {
          this.$router.push("/write_report/gri_100");
        } else this.$router.push({ name: "Gri2" });
        window.location.reload()

      }


      //   else if (index == 1) {
      //     this.visible_style = [false, true, false, false];
      //     this.$router.push("/mg_data_tables");
      //   } else if (index == 2) {
      //     this.visible_style = [false, false, true, false];
      //     this.$router.push("/mg_documents");
      //   } else if (index == 3) {
      //     this.visible_style = [false, false, false, true];
      //     this.$router.push("/mg_business_cases");
      //   }
      else console.log("페이지고 에러");
    },
    pageGoSub(name) {
      this.$router.push({ name: name });
    },
    makeSubTopicNames() {
      this.is_sub_topic_loaded = false;
      if (this.topic_names.length > 0) {
        for (let a = 0; a < this.writing_side[0].sub.length; a++) {
          let arr = [];
          let temp_topic = [];
          if (this.gri_ver == "GRIv2021") {
            temp_topic = this.topic_names.filter(
              (x) => x.topic_id >= a * 100 && x.topic_id < (a + 1) * 100
            );
            temp_topic.forEach((b) =>
              arr.push({ topic_id: b.topic_id, topic_name: b.topic_name })
            );
            if (a > 1) {
              this.writing_side[0].sub[a].topics = arr;
            }
          } else {
            temp_topic = this.topic_names.filter(
              (x) => x.topic_id >= (a + 1) * 100 && x.topic_id < (a + 2) * 100
            );
            temp_topic.forEach((b) =>
              arr.push({ topic_id: b.topic_id, topic_name: b.topic_name })
            );
            if (a > 0) {
              this.writing_side[0].sub[a].topics = arr;
            }
          }
        }
      }
      this.is_sub_topic_loaded = true;
    },
    navAnchorGo(topic) {
      let anchor = null;
      if (topic.subtopic_code) {
        anchor = "#w" + topic.subtopic_code;
      } else {
        anchor = "#w" + topic.topic_id;
      }
      // console.log(anchor);
      ih.anchorGo(anchor);
    },
  },
  watch: {
    topic_names: {
      handler(newVal) {
        if (newVal.length > 1) {
          this.makeSubTopicNames();
        }
      },
    },
  },
  updated() {},
};
</script>

<template>
<div>
    <template v-if="is_topic_selected_loaded==true">
        <!--테스트
            selectedTopicId: {{ selectedTopicId }} <br>
            originalTopicSelected: {{ originalTopicSelected }}
        -->
        <!---------->
        <b-form-checkbox v-if="!saving" v-model="topic_selected" @change="updateTopicSelected">
            
            <b-icon v-if="show_completed" icon="check" scale="1.5" variant="success" animation="fade" />
            <span v-else>
                {{topic_selected===true?"선택":"선택안함"}}
            </span>
        </b-form-checkbox>

        <span v-else>
            <b-icon icon="circle-fill" animation="throb" font-scale="1" />
            saving..
        </span>

        <span v-if="failedToSave">
            <b-icon icon="x" scale="1.5" variant="danger" animation="fade" />저장 실패
        </span>


    </template>
    <template v-else>
        <b-spinner small type="grow"></b-spinner>
    </template>
</div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'
export default {
    data() {
        return {
            topic_selected : false,
            is_topic_selected_loaded: false,
            show_completed: false,
            saving: false,
            failedToSave: false
        }
    },
    props:{
        topic_id: { type: Number },
        selectedTopicId: {type: Number},
        originalTopicSelected: { type: Boolean },
        selectedGriCode: { type: String },
        originalIndicatorSelected: { type: Boolean },
        originalMaterialSelected: {type:Boolean},
        targetTopic: { type: Object },
        targetMaterial: { type: Object },

    },
    created(){
        this.checkReportTopicsSelected()
    },
    computed:{
        ...mapState('report_list', ['report_code', 'standard_mode']),
        ...mapGetters('report_list', ['selected_report', 'gri_ver', 'tcfd_ver']),
        ...mapState('gri2021_indicators', ['topic_payloads', 'changable_topic_id'])
        
    },
    methods:{
        ...mapMutations('gri2021_indicators', ['upload_topic_payload','upload_indicator_payload_topic', 'change_material_select', 'change_indicator_select_topic']),
        checkReportTopicsSelected(){
            const path = this.$store.state.backend_host + '/check_report_topics_selected'

            return axios.get(path, { params: {
                report_code: this.report_code,
                topic_ids: this.topic_id
            }})
            .then( response => {
                if(response.data.code=='success'&&response.data.is_selected==true){
                    this.topic_selected = true

                }
                if (response.data.code=='success'){
                    let result = response.data
                    let new_obj = {}
                    let new_arr = []
                    new_arr.push(result.is_selected)
                    new_arr.push(result.is_material)
                    new_obj[result.topic_id] = new_arr
                    this.$emit('load', result.is_material)


                    this.upload_topic_payload(new_obj)
                }else {
                    this.$emit('load', false)
                }
                this.is_topic_selected_loaded=true
            })
        },
        updateTopicSelected() {
            try {
                this.$emit('changeIndicatorFromTopic', this.topic_id, this.topic_selected);
                this.$emit('changeMaterialFromElse', this.topic_id, this.topic_selected);

                // 사실 여기선 의미 없고.. 그냥 보여주기 위함
                this.show_completed = true;
                setTimeout(() => {
                    this.show_completed = false;
                }, 1500);
            } catch (e) {
                // 
                this.failedToSave = true;
                setTimeout(() => {
                    this.failedToSave = false;
                }, 2000);
            }

            // this.uploadSelectedTopic();

            
            //Topic은 indicator에만 영향 주면 됨
            // let temp_obj = {}
            // let new_arr = []
            // //if (this.topic_selected == true) {
            // // test
            // this.$emit('changeOriginalTopic', this.topic_selected, this.topic_id)
            // this.$emit('changeOriginalMaterial', this.topic_selected, this.topic_id)

            // if (this.originalTopicSelected || this.topic_selected) {
            //     new_arr = [true, false]
            // }else {
            //     this.change_material_select(this.topic_id) // topic false 선택 시 material selected도 false 만들기
            //     this.change_indicator_select_topic(this.topic_id) // topic false 선택 시 하위 indicator 모두 unselect
                

            //     new_arr = [false, false]
            // }
            // temp_obj[this.topic_id] = new_arr

            // this.upload_topic_payload(temp_obj)

            // this.upload_indicator_payload_topic(this.topic_id)



        },
        async uploadSelectedTopic() {
            // originalMaterialSelected
            try {
                this.saving = true;
                const path = this.$store.state.backend_host + "/link_report_topics";
                const formData = new FormData()
                formData.append('report_code', this.report_code)
                // formData.append('link_payload', JSON.stringify({
                //     [this.topic_id]: [this.topic_selected, this.originalMaterialSelected]
                // }))
                formData.append('link_payload', JSON.stringify({
                    [this.topic_id]: [this.topic_selected, this.originalMaterialSelected]
                }))
    
                const result = await axios.post(path, formData);
                if (result.status === 200) {
                    this.show_completed = true;
                    setTimeout(() => {
                        this.show_completed = false;
                    }, 1500);
                    
                } else {
                    this.failedToSave = true;
                    setTimeout(() => {
                        this.failedToSave = false;
                    }, 2000);
                }
                
                this.saving = false;

                
            } catch (error) {
                this.saving = false;
                this.failedToSave = true;
                setTimeout(() => {
                    this.failedToSave = false;
                }, 2000);
            }



        }


    },
    watch: {
        targetTopic: {
            handler(newVal) {
                if (newVal !== null && newVal.topic_id === this.topic_id) {
                    this.topic_selected = newVal.topic_value
                    this.uploadSelectedTopic();
                }

            }
            
        },
        // selectedGriCode: {
        //     handler(newVal) {
        //         if (!this.originalIndicatorSelected) {
        //             this.topic_selected = this.originalIndicatorSelected


        //             let new_arr = []
        //             let new_obj = {}
        //             let temp_arr = [...this.topic_payloads]
        //             for (const i of temp_arr) {
        //                 if (Object.keys(i)[0] === this.topic_id.toString()) {
        //                     new_arr = i[Object.keys(i)[0]]
        //                     break
        //                 }
        //             }

        //             if (new_arr.length === 0) {
        //                 new_arr = [false, false]
        //             } else {
        //                 new_arr[this.originalIndicatorSelected, false]
        //             }
        //             new_obj[this.topic_id] = new_arr
        //             this.upload_topic_payload(new_obj)


        //         }
        //     }  
        // },
        // originalTopicSelected:{
        //     handler(newVal) {
        //         if (this.selectedTopicId === this.topic_id) {
        //             this.topic_selected = newVal
        //             let new_arr = []
        //             let new_obj = {}
        //             let temp_arr = [...this.topic_payloads]
        //             for (const i of temp_arr) {
        //                 if (Object.keys(i)[0] === this.topic_id.toString()) {
        //                     new_arr = i[Object.keys(i)[0]]
        //                     break
        //                 }
        //             }

        //             if (new_arr.length === 0) {
        //                 new_arr = [false, false]
        //             } else {
        //                 new_arr[this.originalIndicatorSelected, false]
        //             }
        //             new_obj[this.topic_id] = new_arr
        //             this.upload_topic_payload(new_obj)

        //         }
        //     }
        // },

        // changable_topic_id:{
        //     handler(newVal){
        //         if (newVal == this.topic_id) {
        //             console.log('설마 너냐?')
        //             this.topic_selected = true
        //         }
        //     },
        //     immediate: true
        // }
    }
}
</script>

<style>

</style>
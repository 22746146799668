<template>
  <div>
    <div
      class="d-flex flex-row justify-content-between align-items-stretch mb-5"
      style=""
    >
      <section class="todo-main" style="">
        <b-row v-for="task_0 in state_tasks" :key="task_0.id" class="mb-3">
          <b-col
            cols="12"
            md="2"
            class="teal7 d-flex align-items-start"
            style="min-width: 100px"
          >
            <div class="d-flex flex-column h-100 fw-500">
              <div class="f-85" style="margin-bottom: -5px">Step</div>
              <div class="f-160 mb-2">{{ task_0.num }}.</div>
              <div class="v-tail-line-teal"></div>
            </div>
            <h5 class="f-140 ml-2">{{ task_0.name }}</h5>
          </b-col>
          <b-col>
            <b-row no-gutters class="mb-2">
              <b-col cols="3" class="border-top-bold mr-3 mr-xl-4"></b-col>
              <b-col class="border-top-bold"></b-col>
            </b-row>
            <b-row
              no-gutters
              v-for="t_item in task_0.task"
              :key="t_item.id"
              class="py-2 mb-1 hover-gray"
            >
              <b-col cols="12" sm="3" class="mr-3 mr-xl-4">
                {{ t_item.task_name }}
                <!-- state 2일 경우 -->
                <template
                  v-if="
                    selected_report.state == 3 && t_item.task_name == '검증받기'
                  "
                >
                  <hr class="border-0 m-0" />
                  <div
                    v-for="(a_stage, index) in assurance_stages"
                    :key="a_stage.id"
                    class="pl-3 f-90 pb-2 mb-0"
                    :class="
                      assurance_status == index
                        ? 'border-left-teal-bold teal fw-500'
                        : 'border-left gray-600'
                    "
                  >
                    {{ a_stage }}
                  </div>
                </template>
              </b-col>
              <b-col cols="12" sm="8">
                <p class="pt-5 pt-sm-0 mb-0">{{ t_item.description }}</p>

                <!-- state 1일 경우 -->
                <template
                  v-if="
                    selected_report.state == 1 &&
                    t_item.task_name == '지표 상세 선택'
                  "
                >
                  <b-table-simple class="bor-bg-table f-85 mt-3">
                    <b-thead
                      ><b-tr
                        ><b-td>GRI</b-td
                        ><b-td style="width: 55%">선택한 보고</b-td></b-tr
                      ></b-thead
                    >
                    <b-tbody>
                      <b-tr v-if="selected_report.gri_use === 'GRIv2021'">
                        <b-td class="pr-2">GRI 2, GRI 3</b-td
                        ><b-td class="f-85">전체</b-td>
                      </b-tr>

                      <b-tr v-else>
                        <b-td class="pr-2">GRI 100</b-td
                        ><b-td class="f-85">102</b-td>
                        <!-- <b-td rowspan="4" class="align-middle pl-2" > 
                                                    <template v-if="gri_option_status_loaded==true" >
                                                        <div class="green indent-lg" v-if="current_gri_option!='핵심 부합 방법 (Core option) 미충족'"><fa icon="check-circle" class="mr-1"/> {{ current_gri_option }} </div>
                                                        <div class="red indent-lg" v-else><fa icon="exclamation-circle" class="mr-1"/> {{ current_gri_option }} </div>
                                                    </template>
                                                    <template v-else>
                                                        <b-skeleton></b-skeleton>
                                                    </template>
                                                </b-td>  -->
                      </b-tr>

                      <b-tr>
                        <b-td class="pr-2">경제</b-td
                        ><b-td style="width: 55%">
                          <div
                            v-if="gri_200_selected.length > 0"
                            class="d-flex flex-wrap f-85"
                            style="max-width: 290px"
                          >
                            <span
                              v-for="topic200 in gri_200_selected"
                              :key="topic200.id"
                              class="mr-2"
                              >{{ topic200.topic_id }}</span
                            >
                          </div>
                          <div v-else>-</div>
                        </b-td></b-tr
                      >
                      <b-tr>
                        <b-td class="pr-2">환경</b-td
                        ><b-td style="width: 55%">
                          <div
                            v-if="gri_300_selected.length > 0"
                            class="d-flex flex-wrap f-85"
                            style="max-width: 290px"
                          >
                            <span
                              v-for="topic300 in gri_300_selected"
                              :key="topic300.id"
                              class="mr-2"
                              >{{ topic300.topic_id }}</span
                            >
                          </div>
                          <div v-else>-</div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td class="pr-2">사회</b-td
                        ><b-td style="width: 55%">
                          <div
                            v-if="gri_300_selected.length > 0"
                            class="d-flex flex-wrap f-85"
                            style="max-width: 290px"
                          >
                            <span
                              v-for="topic400 in gri_400_selected"
                              :key="topic400.id"
                              class="mr-2"
                              >{{ topic400.topic_id }}</span
                            >
                          </div>
                          <div v-else>-</div>
                        </b-td></b-tr
                      >
                    </b-tbody>
                  </b-table-simple>
                </template>
                <!-- state 2일 경우 -->
                <template
                  v-if="
                    selected_report.state == 2 &&
                    t_item.task_name == '보고 내용 작성'
                  "
                >
                  <b-table-simple
                    v-if="writing_progress_loaded == true"
                    class="bor-bg-table f-85 mt-3 mb-2"
                  >
                    <b-thead
                      ><b-tr
                        ><b-td>GRI</b-td><b-td>작성한 지표 / 전체 지표 </b-td
                        ><b-td style="width: 45%"> 진행률 </b-td></b-tr
                      ></b-thead
                    >
                    <b-tbody>
                      <b-tr v-for="part in writing_progress" :key="part.id">
                        <b-td class="pr-2">{{ part.gri_category }}</b-td
                        ><b-td>{{ part.written }} / {{ part.all }}</b-td>
                        <b-td
                          ><div
                            class="position-relative bgray-200 roadmap-mini-bar"
                          >
                            <div
                              class="position-absolute bg-green roadmap-mini-progress"
                              :style="
                                `width:` + (part.written / part.all) * 100 + `%`
                              "
                            ></div></div
                        ></b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <b-table-simple v-else class="bor-bg-table f-85 mt-3 mb-2">
                    <b-thead
                      ><b-tr
                        ><b-td>GRI</b-td><b-td>작성한 지표 / 전체 지표 </b-td
                        ><b-td class="pl-2"> 진행률 </b-td></b-tr
                      ></b-thead
                    >
                    <b-tbody>
                      <b-tr v-for="n in 4" :key="n.id"
                        ><b-td><b-skeleton></b-skeleton></b-td
                        ><b-td><b-skeleton></b-skeleton></b-td
                        ><b-td><b-skeleton></b-skeleton></b-td
                      ></b-tr>
                    </b-tbody>
                  </b-table-simple>
                </template>
                <!-- state 3일 경우 -->
                <template
                  v-if="
                    selected_report.state == 3 && t_item.task_name == '검증받기'
                  "
                >
                  <hr class="border-0 m-0" />
                  <p
                    v-for="(a_task, idx) in assurance_tasks"
                    :key="a_task.id"
                    class="pl-3 f-90 pb-2 mb-0"
                    :class="
                      assurance_status == idx
                        ? 'border-left-teal-bold teal fw-400'
                        : 'border-left gray-600'
                    "
                    v-html="a_task"
                  ></p>
                </template>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </section>
      <section class="px-3 py-3 bgray-200 todo-side" style="width: 120px">
        <div
          v-if="selected_report.state >= 1"
          class="d-flex align-items-end gray-500 mb-1"
        >
          <div class="d-flex flex-column">
            <div class="f-75" style="margin-bottom: -5px">Step</div>
            <div class="f-130 fw-500">{{ steps[0].num }}.</div>
          </div>
          <div class="ml-1 fw-500 mb-1">{{ steps[0].name }}</div>
        </div>
        <div
          v-if="selected_report.state >= 2"
          class="d-flex align-items-end gray-500 mb-1"
        >
          <div class="d-flex flex-column">
            <div class="f-75" style="margin-bottom: -5px">Step</div>
            <div class="f-130 fw-500">{{ steps[1].num }}.</div>
          </div>
          <div class="ml-1 fw-500 mb-1">{{ steps[1].name }}</div>
        </div>
        <div
          v-if="selected_report.state >= 3"
          class="d-flex align-items-end gray-500 mb-1"
        >
          <div class="d-flex flex-column">
            <div class="f-75" style="margin-bottom: -5px">Step</div>
            <div class="f-130 fw-500">{{ steps[2].num }}.</div>
          </div>
          <div class="ml-1 fw-500 mb-1">{{ steps[2].name }}</div>
        </div>

        <div
          class="mb-2"
          :class="
            selected_report.state == 1
              ? 'v-head-line-gray-sm'
              : 'v-head-line-gray'
          "
        ></div>

        <div
          v-if="selected_report.state <= 1"
          class="d-flex align-items-end gray-700 mb-1"
        >
          <div class="d-flex flex-column">
            <div class="f-75" style="margin-bottom: -5px">Step</div>
            <div class="f-130 fw-500">{{ steps[2].num }}.</div>
          </div>
          <div class="ml-1 fw-500 mb-1">{{ steps[2].name }}</div>
        </div>
        <div
          v-if="selected_report.state <= 1"
          class="d-flex align-items-end gray-700 mb-1"
        >
          <div class="d-flex flex-column">
            <div class="f-75" style="margin-bottom: -5px">Step</div>
            <div class="f-130 fw-500">{{ steps[3].num }}.</div>
          </div>
          <div class="ml-1 fw-500 mb-1">{{ steps[3].name }}</div>
        </div>
        <div
          v-if="selected_report.state <= 2"
          class="d-flex align-items-end gray-700 mb-1"
        >
          <div class="d-flex flex-column">
            <div class="f-75" style="margin-bottom: -5px">Step</div>
            <div class="f-130 fw-500">{{ steps[4].num }}.</div>
          </div>
          <div class="ml-1 fw-500 mb-1">{{ steps[4].name }}</div>
        </div>
      </section>
    </div>

    <div
      v-if="opened_by_header == false"
      class="py-3 d-md-flex justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center">
        <b-button variant="teal" class="px-5" @click="startTask()"
          >시작하기</b-button
        >
        <b-form-checkbox v-model="show_control_24" class="ml-3 f-95">
          24시간 보지 않기
        </b-form-checkbox>
      </div>
      <div class="f-90 ml-md-2 pt-2 pt-md-0 fw-300 gray-600">
        작성 로드맵은 메인 메뉴 오른쪽
        <div :disabled="true" class="py-1 px-2 d-inline bgray-200 rounded">
          <fa icon="map-marker-alt"></fa>
        </div>
        에서 다시 볼 수 있습니다.
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      steps: [
        { num: "01", name: "기본설정" },
        { num: "02", name: "지표선택" },
        { num: "03", name: "작성" },
        { num: "04", name: "검증" },
        { num: "05", name: "공개" },
      ],
      state_0_task: [
        {
          num: "01",
          name: "기본설정",
          task: [
            {
              task_name: "보고서 기본 정보",
              description: "보고서 생성 시 입력한 정보를 확인하고 보완힙니다.",
            },
            {
              task_name: "디자인 테마 설정",
              description:
                "보고서 메인 컬러, 글씨체, 글씨 크기 등을 설정합니다.",
            },
            {
              task_name: "팀원 초대하기",
              description: "함께 작성할 팀원을 초대합니다. ",
            },
          ],
        },
        {
          num: "02",
          name: "지표 선택",
          task: [
            {
              task_name: "기본 지표 추천받기",
              description:
                "산업 및 보고 조직의 규모 등을 입력하여  기본 지표를 추천 받습니다. ",
            },
            {
              task_name: "지표 상세 선택",
              description: "보고할 지표를 상세 선택합니다.",
            },
          ],
        },
      ],
      state_1_task: [
        {
          num: "02",
          name: "지표 선택",
          task: [
            {
              task_name: "지표 상세 선택",
              description: "보고할 지표를 상세 선택합니다.",
            },
            {
              task_name: "지표 선택 완료하기",
              description:
                "지표 선택 완료하기를 클릭하여 보고서 작성을 시작합니다.",
            },
          ],
        },
      ],
      state_2_task: [
        {
          num: "03",
          name: "작성",
          task: [
            {
              task_name: "보고 내용 작성",
              description:
                "보고 내용을 작성합니다. 데이터 테이블, 문서 및 링크, 비즈니스 사례 등 보고 컨텐츠를 만들어 연결할 수 있습니다.",
            },
          ],
        },
        {
          num: "04",
          name: "검증",
          task: [
            {
              task_name: "검토하기",
              description: "작성한 보고서를 미리보기로 검토합니다.",
            },
            {
              task_name: "검증받기",
              description:
                "검증 서비스(유료)를 신청하여 보고 내용 전체에 대해 제3자 검증을 받습니다. 또한, 기존에 보유하고 있는 타 검증 의견서를 업로드 할 수 있습니다.",
            },
          ],
        },
      ],
      state_3_task: [
        {
          num: "04",
          name: "검증",
          task: [
            {
              task_name: "검토하기",
              description: "작성한 보고서를 미리보기로 검토합니다.",
            },
            {
              task_name: "검증받기",
              description: "검증 서비스(유료)를 확인하고 검증을 신청합니다.",
            },
          ],
        },
        {
          num: "05",
          name: "공개",
          task: [
            {
              task_name: "공개하기",
              description:
                "보고서를 대외 공개합니다. 웹 링크 공유 및 PDF 다운로드가 가능합니다.",
            },
          ],
        },
      ],
      assurance_stages: ["(별도 문의)"],
      assurance_tasks: [
        '유료 검증 서비스를 이용하고자 하실 경우 <a href="mailto:info@qesg.co.kr">info@qesg.co.kr</a>을 통해 별도 문의하여 주십시오.',
      ],
      // assurance_stages: ['견적 확인','전자 계약', '검증 신청', '서면검토', '검증의견 확인 및 보완','대면 인터뷰', '검증의견서 확인'],
      // assurance_tasks: ['검증 서비스 견적을 확인합니다.', '검증 서비스에 대해 전자 계약을 체결합니다', '검증 일정 및 정보를 입력하고 검증을 신청합니다.', 'ESG 전문 검증인이 서면 검토를 진행합니다.', '검증인의 검토 의견을 서면으로 확인하고 보완합니다.', '인터뷰를 통해 보고 내용 관련 운영 시스템을 확인하고 최종 보완합니다.', '검증의견서를 확인합니다.'],
      show_control_24: false,
      writing_progress: [],
      writing_progress_loaded: false,
    };
  },
  props: {
    opened_by_header: { type: Boolean, default: false },
  },
  computed: {
    ...mapState("report_list", ["report_code"]),
    ...mapGetters("report_list", ["selected_report"]),
    ...mapState("report_indicators", [
      "gri_option_status",
      "gri_option_status_loaded",
    ]),
    ...mapGetters("report_indicators", [
      "gri_200_topics",
      "gri_300_topics",
      "gri_400_topics",
    ]),
    ...mapState("assurance", ["assurance_list", "assurance_status"]),
    state_tasks() {
      if (this.selected_report.state == 0) {
        return this.state_0_task;
      }
      if (this.selected_report.state == 1) {
        return this.state_1_task;
      }
      if (this.selected_report.state == 2) {
        return this.state_2_task;
      }
      if (this.selected_report.state >= 3) {
        return this.state_3_task;
      }
    },
    current_gri_option() {
      if (
        this.gri_option_status.is_core_aligned == true &&
        this.gri_option_status.is_comprehensive_aligned == false
      ) {
        return "핵심 부합 (Core option) 충족";
      } else if (this.gri_option_status.is_comprehensive_aligned == true) {
        return "포괄적 부합 (Comprehensive option) 충족";
      } else return "핵심 부합 (Core option) 미충족";
    },
    gri_200_selected() {
      let temp_200 = this.gri_200_topics.filter((x) => x.selected == true);
      console.log(temp_200);
      return temp_200;
    },
    gri_300_selected() {
      let temp_300 = this.gri_300_topics.filter((x) => x.selected == true);
      return temp_300;
    },
    gri_400_selected() {
      let temp_400 = this.gri_400_topics.filter((x) => x.selected == true);
      return temp_400;
    },
  },
  methods: {
    ...mapActions("report_indicators", ["checkGriOption"]),
    startTask() {
      if (this.show_control_24 == true) {
        let expires = new Date(); // 현재 날짜 기준 24시간 이후로 새로 셋업
        expires = new Date(expires.setHours(expires.getHours() + 24));
        localStorage.setItem("hide_todo_" + this.report_code, expires);
      }

      this.hideTodoModal();
    },
    hideTodoModal() {
      this.$emit("hide-todo-modal");
    },
    readIndicatorsWritten() {
      // 다른 화면에서 사용할 경우 store로 옮겨서 사용하기
      const path = this.$store.state.backend_host + "/read_indicators_written";

      return axios
        .get(path, {
          params: {
            report_code: this.report_code,
          },
        })
        .then((response) => {
          //    console.log(response.data);
          this.writing_progress = response.data;
          this.writing_progress_loaded = true;
        });
    },
  },
  created() {
    if (this.selected_report.state == 1) {
      this.checkGriOption(this.report_code);
    } else if (this.selected_report.state == 2) {
      this.readIndicatorsWritten();
    }
  },
  beforeDestroy() {
    this.show_control_24 = false;
  },
};
</script>

<style>
.roadmap-mini-bar {
  width: 100%;
  height: 12px;
}
.roadmap-mini-progress {
  left: 0%;
  top: 0;
  height: 12px;
}
.todo-main {
  width: calc(100% - 135px);
}
@media (max-width: 992px) {
  .todo-main {
    width: 100%;
  }
  .todo-side {
    display: none;
  }
}
</style>

<template>
  <div>
    <div class="bgray-400 py-5">
      <b-container class="py-5">
        <b-row>
          <b-col cols="12" md="6" class="f-80">
            <p class="fw-300">
              <span class="pr-md-3 fw-400"> {{ report_name }} </span>
              <br>
              {{ this.report_date }}
            </p>

            <p class="fw-300">
                
              Powered by QuantifiedESG (QESG), <a href="https://qesg.co.kr/reporting_tool">ESG Reporting Tool</a>
            </p>
          </b-col>
          <!-- <b-col
            cols="12"
            md="6"
            class="f-90 d-flex justify-content-between justify-content-md-end mt-4 mt-md-0"
          >
            <div class="text-right">
              <b-button
                variant="icon"
                size="sm"
                class=""
                href="http://qesg.co.kr"
                target="_blank"
                >QESG 홈페이지
                <i
                  class="fas fa-external-link-square-alt ml-1 f-80"
                ></i></b-button
              ><br />
              <b-dropdown variant="icon" size="sm" class="outer-link">
                <template v-slot:button-content
                  ><span class="pr-1"> QESG 서비스 바로가기 </span></template
                >
                <template class="">
                  <b-dropdown-item
                    class="f-90"
                    target="_blank"
                    href="http://tierzero.kr"
                  >
                    공급망 평가 서비스 <b>TierZero</b>
                    <i class="fas fa-external-link-square-alt ml-1 f-80"></i
                  ></b-dropdown-item>
                  <b-dropdown-item
                    class="f-90"
                    target="_blank"
                    href="http://assessment.qesg.co.kr"
                  >
                    ESG 평가 서비스 <b>QESG Assessment</b
                    ><i class="fas fa-external-link-square-alt ml-1 f-80"></i
                  ></b-dropdown-item>
                  <b-dropdown-item
                    class="f-90"
                    target="_blank"
                    href="http://board.qesg.co.kr"
                  >
                    실시간 기업별 ESG 뉴스 플랫폼 <b>QESG Board</b>
                    <i class="fas fa-external-link-square-alt ml-1 f-80"></i
                  ></b-dropdown-item>
                </template>
              </b-dropdown>
            </div>
            <div class="text-right ml-md-5">
              <b-button
                variant="icon"
                size="sm"
                class="f-90"
                @click="$router.push({ name: 'PrivacyPolicy' })"
                >개인정보 처리방침</b-button
              ><br />
              <b-button
                variant="icon"
                size="sm"
                class="f-90"
                @click="$router.push({ name: 'TermsOfUse' })"
                >이용약관</b-button
              >
            </div>
          </b-col> -->
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        report_name: { type: String, default: "" },
        report_url: {type: String, default: ""},
        report_date: {type: String, default: ""}
    
  },

};
</script>

<template>
  <div>
    <b-overlay :show="loadingShow" no-wrap></b-overlay>
    <b-button
      id="subscription_pay_table_btn"
      v-b-toggle="'collapse-2'"
      variant="primary"
      class="m-5"
      @click="toggleButton"
    >
      <b-icon icon="credit-card" aria-hidden="true"></b-icon> 정기결제 쿠폰 관리
      내역
      <b-icon :icon="buttonIcon" aria-hidden="true"></b-icon>
    </b-button>
    <!-- Element to collapse -->
    <b-collapse id="collapse-2">
      <!-- Main table element -->
      <b-table
        :items="subscriptionCouponList"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
        hover
        empty-filtered-html="검색 결과가 없습니다 😥"
        empty-text="결제 내역이 없습니다."
        tbody-tr-class="payment-row"
      >
        <template #table-caption>
          총
          <span v-if="totalRows !== null" class="mb-0 red">{{
            totalRows
          }}</span>
          건의 내역이 조회됨
        </template>

        <template #cell(discount_price)="row">
          {{ row.value }}
        </template>

        <!-- <template #cell(subscription_discount_code)="row">
          <span v-if="row.value !== ''">
            <b-input-group>
              <b-form-input :placeholder="row.value"></b-form-input>

              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="applyDiscountCode"
                  class="addDiscount"
                  ><b-icon
                    icon="check-circle"
                    scale="1"
                    variant="primary"
                  ></b-icon
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </span>

          <div v-else>
            <b-icon icon="x-circle" scale="1" variant="secondary"></b-icon>
          </div>
        </template> -->

        <template #cell(payment_status)="row">
          <b-icon
            v-if="row.value == true"
            icon="check-circle"
            scale="1.5"
            variant="success"
          ></b-icon>
          <b-icon
            v-else
            icon="exclamation-circle-fill"
            scale="1.5"
            variant="danger"
            animation="throb"
          ></b-icon>
        </template>

        <template #cell(actions)="row">
          <b-button size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? "창 닫기" : "세부보기" }}
          </b-button>
        </template>

        <template #row-details="row">
          {{ row.childProduct }}

          <b-card>
            <!-- Detail 부분 -->
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
            <b-table-simple>
              <b-thead>
                <b-tr>
                  <b-th>서비스명</b-th>
                  <b-th>결제상태</b-th>
                  <b-th>가격</b-th>
                  <b-th>할인코드</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr variant="info">
                  <b-th sticky-column>{{
                    row.item.subscription_discount_code
                  }}</b-th>
                  <b-td>{{ row.item.payment_status }}</b-td>
                  <b-td>{{ row.item.discount_price }}</b-td>
                  <b-td>{{ row.item.subscription_discount_code }}</b-td>
                </b-tr>
                <b-tr v-for="(value, key) in row.item.childProduct" :key="key">
                  <b-td>{{ value.subscription_discount_code }}</b-td>
                  <b-td>{{ value.paymentSatus }}</b-td>
                  <b-td>{{ value.discount_price }}</b-td>
                  <b-td>-</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-button variant="outline-info">수정하기</b-button>
          </b-card>
        </template>
      </b-table>

      <!-- Info modal -->
      <b-modal
        :id="infoModal.id"
        :title="infoModal.title"
        ok-only
        @hide="resetInfoModal"
      >
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <!-- User Interface controls -->
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import { formatCurrency } from "@/components/payment/payment";

export default {
  data() {
    return {
      isToggled: false,
      fields: [
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
        {
          key: "subscription_id",
          label: "할인 적용 상품",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "subscription_discount_code",
          label: "코드명",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "discount_price",
          label: "책정가격",
          sortable: true,
          class: "text-center",
          formatter: (value) => {
            return formatCurrency(value) + "원";
          },
        },
        {
          key: "subscription_discount_code",
          label: "할인코드",
          sortable: true,
          class: "text-center",
        },
        {
          key: "authorizer",
          label: "발급인",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
        {
          key: "description",
          label: "기타 사항",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
        {
          key: "original_cnt",
          label: "총 할인 횟수",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
        {
          key: "used_cnt",
          label: "소모 횟수",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loadingShow: true,
      subscriptionCouponList: [],
    };
  },
  computed: {
    buttonIcon() {
      return this.isToggled ? "toggle-on" : "toggle-off";
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.subscriptionCouponList.length;

    const table_rows = document.querySelectorAll(".payment-row");
    table_rows.forEach((element) => {
      element.addEventListener("click", this.openRowDetail);
    });

    // 테이블 데이터 fetch callback에 넣어주기
    this.loadingShow = false;

    this.fetchSubscriptionCouponList();
  },
  methods: {
    fetchSubscriptionCouponList() {
      axios
        .get(
          this.$store.state.backend_host +
            "/payment/fetchSubscriptionCouponList"
        )
        .then((response) => {
          this.loadingShow = false;
          this.subscriptionCouponList = response.data.result;
          this.totalRows = this.subscriptionCouponList.length;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    toggleButton() {
      this.isToggled = !this.isToggled;
    },
    applyDiscountCode() {
      // console.log("할인쿠폰 변경 적용..");
    },
    openRowDetail(e) {
      e.stopPropagation();
      let currentElement = e.target;
      if (currentElement.tagName == "BUTTON") {
        try {
          currentElement.firstChild.querySelector("button").click();
        } catch (e) {
          return null;
        }
        return;
      }
      while (currentElement && currentElement.tagName !== "TR") {
        currentElement = currentElement.parentElement;
      }

      if (currentElement && currentElement.tagName === "TR") {
        // <tr> 태그를 찾은 경우에 대한 로직을 작성합니다.
        currentElement.firstChild.querySelector("button").click();
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

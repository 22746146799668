<template>
  <div>
    <h5 class="mb-4 fw-700">{{sasb_industry_name}} SASB 보고 지표</h5>

    

    <h6 class="mb-2 fw-600">지속가능성 공시 주제 및 지표</h6>
    <b-table-simple responsive class="edi-table">
      <b-thead>
        <b-tr>
          <b-td v-for="(header, idx) in fields" :key="idx">{{
            header.label
          }}</b-td>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="indicator_list&&indicator_list.operation">
          <b-tr
            v-for="(operation_data, idx) in indicator_list.sustainability"
            :key="idx"
          >
            <b-td v-for="(column, idx) in fields" :key="idx">
              <span v-if="column.key !=='metric'">
                {{ operation_data[column.key]? operation_data[column.key].replaceAll('', ' '):'-' }}

              </span>

              <span v-else v-html="operation_data[column.key]? operation_data[column.key].replace(/([^a-zA-Z0-9])\((\d+)\)/g, '$1<br>($2)').replaceAll(';', ';<br>') : '-'">
              </span>

            </b-td>
          </b-tr>
        </template>
        <template v-else>
          <b-skeleton v-for="x in 3" :key="x" class="my-2 pr-3"></b-skeleton>
        </template>
      </b-tbody>
    </b-table-simple>

    <h6 class="mb-2 fw-600">활동 지표</h6>
    <b-table-simple responsive class="edi-table">
      <b-thead>
        <b-tr>
          <b-td v-for="(header, idx) in fields" :key="idx">{{
            header.label
          }}</b-td>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="indicator_list&&indicator_list.operation">
          <b-tr
            v-for="(operation_data, idx) in indicator_list.operation"
            :key="idx"
          >
            <b-td v-for="(column, idx) in fields" :key="idx">{{
              operation_data[column.key]?operation_data[column.key]:'-'
            }}</b-td>
          </b-tr>
        </template>
        <template v-else>
         <b-skeleton v-for="x in 3" :key="x" class="my-2 pr-3"></b-skeleton>
        </template>
      </b-tbody>
    </b-table-simple>
    
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    sasb_industry_id: { type: Number },
    sasb_industry_name: { type: String },
  },
  components: {},
  data() {
    return {
      indicator_list: null,
      fields: [
        // A virtual column that doesn't exist in items
        { key: "topic", label: "주제" },
        // { key: "unit_measure", label: "단위" },
        { key: "sasb_indicator_code", label: "지표코드" },
        { key: "metric", label: "지표명" },
        { key: "category", label: "범주" },
        { key: "unit_measure", label: "단위" },
        { key: "note", label: "주석" },
      ],
      items: [
        { name: { first: "John", last: "Doe" }, sex: "Male", age: 42 },
        { name: { first: "Jane", last: "Doe" }, sex: "Female", age: 36 },
        { name: { first: "Rubin", last: "Kincade" }, sex: "Male", age: 73 },
        {
          name: { first: "Shirley", last: "Partridge" },
          sex: "Female",
          age: 62,
        },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.get_indicator_info_by_industry();
  },
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  methods: {
    async get_indicator_info_by_industry() {
      // this.sasb_industry_id
      const response = await axios.get(
        this.$store.state.backend_host +
          "/sasb/get_indicator_info_by_industry?sasb_industry_id=" +
          this.sasb_industry_id
      );

      console.log(response);
      this.indicator_list = response.data.result;
    },


  },
};
</script>
<style></style>

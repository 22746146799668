<template>

    <div fluid class="bgray-200 min-vh-100">

        <b-container class="pt-5 d-flex flex-column aligh-items-center justify-content-center" style="min-height:calc(100vh - 120px)">
            <!-- <div class="pt-lg-4 mb-3" align="center">
                <h1 class="teal8">ESG Reporting Tool</h1>
            </div> -->
            <div class="mb-4" align="center" style="height:80px;" :style="isIE==true?'margin-top:8vh;':''">
                <b-img src="/static_img/logo_basic.svg" style="height:100%"></b-img>
            </div>
            <router-view></router-view>
        </b-container>
        
        <b-button v-b-modal.need-help class="need-help-btn" variant="tealgray"><fa icon="question"/></b-button>

        <login-footer></login-footer>   

    <b-modal id="need-help" hide-footer size="" title="문의하기" scrollable >
        <need-help></need-help>
    </b-modal>

    </div>
</template>

<script>
import LoginFooter from '@/components/login_components/LoginFooter'

export default {
    data(){
        return{
          
        }
    },
    computed:{    
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) ){ 
                return true }
            else return false
        }    
    },
    methods:{       
    },
    components: {
        LoginFooter,
    }
}
</script>
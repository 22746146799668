<template>
    <div>
        <div>
            <h3 class="py-4 fw-600"> 
                <b-skeleton-img no-aspect height="30px" width="40%"></b-skeleton-img>
            </h3>
            <b-table-simple responsive class="disclosure-table lg border-bottom mb-5">
                <b-thead >
                    <b-tr>
                        <b-td style="min-width:22%; width:32%;">
                        </b-td>
                        <b-td class="d-lg-flex justify-content-between">
                            <div class="f-90"> 
                                <fa icon="table" class="mr-1"/> 
                                <fa icon="file-alt" class="ml-3 mr-1"/> <fa icon="external-link-square-alt" class="ml-3 mr-1"/> <fa icon="quote-left" class="ml-3 mr-1"/>
                            </div>
                        </b-td>
                    </b-tr>
                </b-thead>

                <b-tbody class="mb-2 border-0">
                    <b-tr >
                        <b-td colspan="2" class="py-1 pl-1">
                            <span class="fw-500 f-120">
                                <b-skeleton animation="wave" width="20%"></b-skeleton>
                            </span>
                        </b-td>
                    </b-tr>   
                    <b-tr >
                        <b-td class="pr-4 d-flex flex-column">
                            <div class="fw-500">
                                <b-skeleton animation="wave" width="30%"></b-skeleton>
                                <b-skeleton animation="wave" width="15%"></b-skeleton>
                            </div>
                            
                            <!-- <p class="pb-3">{{  lang === "KOR" ? disclosure.topic : disclosure.topic_e }}</p> -->
                        </b-td>
                        <b-td class="" style="max-width: 200mm;">
                            <b-skeleton animation="wave" width="85%"></b-skeleton>
                            <b-skeleton animation="wave" width="55%"></b-skeleton>
                            <b-skeleton animation="wave" width="70%"></b-skeleton>
                        </b-td>
                    </b-tr>
                    <b-tr >
                        <b-td class="pr-4 d-flex flex-column">
                            <div class="fw-500">
                                <b-skeleton animation="wave" width="30%"></b-skeleton>
                                <b-skeleton animation="wave" width="15%"></b-skeleton>
                            </div>
                            <!-- <p class="pb-3">{{  lang === "KOR" ? disclosure.topic : disclosure.topic_e }}</p> -->
                        </b-td>
                        <b-td class="" style="max-width: 200mm;">
                            <b-skeleton animation="wave" width="55%"></b-skeleton>
                            <b-skeleton animation="wave" width="35%"></b-skeleton>
                            <b-skeleton animation="wave" width="70%"></b-skeleton>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>


    </div>
</template>
<script>
export default {
}
</script>
<style>
</style>
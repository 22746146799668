<template>
<div>
    <b-card class="general-card" :class="new_report==true? 'border-0':'mb-5'" v-if="assurance_list_loaded==true">
        <b-card-header>검증 비용 및 예상 소요일</b-card-header>
        <div class="py-3">
            <b-row no-gutters class="hover-gray py-2">
                <b-col cols="3"><div class="gray-600">검증 소요일</div> </b-col>  
                <b-col>
                    <p class="fw-400">서면검증 <span class="f-110">{{online_assurance_days}}</span> 일  + 현장검증 <span class="f-110">{{offline_assurance_hours}}</span> 시간</p>
                </b-col>
            </b-row>
            <b-row no-gutters class="hover-gray py-2">
                <b-col cols="3"><div class="gray-600">검증 서비스 비용</div> </b-col>  
                <b-col>
                    <p class="fw-400 mb-2"><span class="f-120">{{cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span>원 (VAT 별도)</p>
                    <p class="fw-400"><a v-b-modal="`estimate-modal`" class="cursor"> 견적서 보기 <fa icon="link" class="ml-1"/></a></p>
                </b-col>
            </b-row>
            <b-row no-gutters class="hover-gray py-2"> 
                <b-col cols="3"><div class="gray-600">전자계약서</div> </b-col>  
                <b-col>
                    <p class="fw-400">{{ contracted!=true? '검증을 진행하려면 전자계약서를 작성하여 주십시오.' : `전자계약이 체결되었습니다. 아래 '검증 신청하기'에서 검증을 신청해 주세요.`}} </p>
                    <b-button @click="$router.push({name:'PaymentList'})" variant="teal-border" class="mb-2">계약 및 결제 관리 </b-button>

                    <!-- <a v-b-modal="`contract-modal`" class="cursor">전자계약서 확인 <fa icon="expand" class="ml-1"/></a> -->
                    <!-- <a class="cursor ml-3"> 계약서 다운로드 <fa icon="file-download" class="ml-1"/></a> -->
                </b-col>
            </b-row>
            <!-- <b-row v-if="contracted==true" no-gutters class="hover-gray py-2">
                <b-col cols="3"><div class="gray-600">비용 지불 현황</div> </b-col>  
                <b-col>
                        <div class="red"><fa icon="exclamation-circle" class="mr-1"/>비용이 지불되지 않았습니다. </div>
                        <p class="fw-400">전자계약서에 따라 비용 지불을 완료해주세요. 비용이 지불될때까지 검증의견서를 제공받을 수 없습니다.</p>
                </b-col>
            </b-row> -->

            <div class="pt-3">
                <b-button v-if="contracted!=true&&new_report!=true" variant="teal" class="mr-3" v-b-modal.contract-modal> 전자계약서 작성하기 </b-button>
                <b-button class="" @click="handleCancle"> {{ new_report!=true? '견적 다시 내기':'닫기'}} </b-button>
            </div>
        </div>
    </b-card>

    <b-modal id="estimate-modal" hide-footer size="lg" title="검증 상세 견적서">
        <estimate-sheet></estimate-sheet>
    </b-modal>
    <b-modal id="contract-modal" hide-footer size="lg" scrollable title="검증 전자계약서 작성">
        <make-contract @close-make-contract="$bvModal.hide('contract-modal')"></make-contract>
    </b-modal>
   

</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import EstimateSheet from './EstimateSheet'
import MakeContract from '@/components/payment/payment_subs/MakeContract'

export default {
    data() {
        return {
            online_assurance_days: 3,
            offline_assurance_hours: 4,
            cost: 3000000
        }
    },
    props: {
        new_report : { type: Boolean, default: false }
    },
    computed:{
        ...mapState('assurance', ['assurance_list', 'assurance_list_loaded']),
        ...mapGetters('assurance', ['latest_assurance']),
        contracted(){  // 일단 고칠 시간이 없어서 false로 돌려줌;;
            // if (this.latest_assurance.contract_signed==null){
                return false 
            // }
            // else return this.latest_assurance.contract_signed
        }
    },
    methods:{
        ...mapMutations('assurance', ['update_show_apply_estimate']),
        handleCancle(){
            if(this.new_report==true){
                this.$router.push({name: 'Main'})
            }
            else this.update_show_apply_estimate(true)
        },
        pageGo(to){
            this.$router.push(to)
        },
        getAssuranceSpec(){
            if(this.latest_assurance&&this.latest_assurance.hasOwnProperty('request_total_employees')){
                const path = this.$store.state.backend_host + '/get_assurance_spec'
                let formData = new FormData()
                formData.append('size', this.latest_assurance.request_total_employees)
                formData.append('revenue', this.latest_assurance.request_revenue)
                formData.append('number_of_sites', String(this.latest_assurance.request_number_of_sites))

                return axios.get(path, { params: {
                    size: this.latest_assurance.request_total_employees,
                    revenue: this.latest_assurance.request_revenue,
                    number_of_sites: this.latest_assurance.request_number_of_sites
                }})
                .then((response) => {
                    // console.log(response.data);
                    let result = response.data
                    this.online_assurance_days = result.ondesk_day
                    this.offline_assurance_hours = result.onsite_time
                    this.cost = result.service_cost
                })
            }
        }        
    },
    components:{
        EstimateSheet,
        MakeContract
    },
    mounted(){
        this.getAssuranceSpec()
    }

}
</script>

<style>

</style>
<template>
<div>
    <div class="py-2">
        <p class="fw-400 f-90 mb-2">URL(외부 링크) 영문명 설정</p>
        <b-form-input v-model="local_link.title_e" placeholder="URL 명" class="mb-3"></b-form-input>
        <p class="fw-400 f-90 mb-2">링크 넣기</p>
        <b-form-input v-model="local_link.link_e" placeholder="" class="mb-5"></b-form-input>
    </div>
    <p class="mb-3 f-85 teal"> <fa icon="info-circle"/> 외부링크 초기화 시 URL 영문명만 AI 자동 번역본으로 초기화되며, 업로드된 링크는 바뀌지 않습니다. </p>
    <b-button variant="teal" class="mr-3 mb-3" @click="handleInitiate" :disabled="initiating==true"> {{initiating==true? '초기화 중': '번역 초기화'}} <fa icon="redo" :class="initiating==true?'spinning-icon':''"/> </b-button>
    <b-button variant="red" class="mr-3 mb-3" @click="handleSave()" :disabled="is_saving==true||initiating==true"> 저장하기 <b-spinner small v-if="is_saving==true"></b-spinner></b-button>
    <b-button @click.once="handleCancle" class="mb-3"> 취소 </b-button>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            local_link : {
            },
            initiating: false,
            is_saving: false
        }
    },
    props:{
        document_id : { default: '' }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState('documents', ['document_list']),
        ...mapGetters('documents', ['url_list'])
    },
    methods:{
        ...mapActions('documents', ['saveDocumentEm', 'reInitiateDocument']),
        async handleSave(){
            if (this.checkForm()==true ) {
                this.is_saving = true
                await this.saveDocumentEm({
                    document_id: this.document_id,
                    title: this.local_link.title_e,
                    type: "link",
                    link: this.local_link.link_e,
                })
                this.$EventBus.$emit('make-toast', '외부링크 영문 저장', `${this.local_link.title_e} 영문 버전이 저장되었습니다.`)
                this.is_saving = false
                this.handleCancle()
            }
        },
        handleCancle(){
            this.initiateEdit()
            this.$emit('cancle-edit')
        },
        async handleInitiate(){
            this.initiating = true
            await this.reInitiateDocument({
                document_id: this.document_id,
                re_initiate: true
            })
            let idx = this.url_list.findIndex(x => x.document_id == this.document_id)
            if (idx > -1) {
                this.local_link = {...this.url_list[idx]}
                this.local_link.title_e = this.url_list[idx].title_ea
            }
            this.initiating = false
            this.$EventBus.$emit('make-toast', '외부링크 번역 초기화', `영문 번역이 AI 자동번역 상태로 초기화되었습니다.`)
        },
        initiateEdit(){
            // console.log(this.document_id);
            let idx = this.url_list.findIndex(x => x.document_id == this.document_id)
            if(idx>-1){
                this.local_link = {...this.url_list[idx]}
                if(this.url_list[idx].title_em!='' && this.url_list[idx].title_em != null){
                    this.local_link.title_e = this.url_list[idx].title_em
                } else this.local_link.title_e = this.url_list[idx].title_ea
            }
        },
        checkForm() {
            if ( this.local_link.title_e == null || this.local_link.title_e == undefined || this.local_link.title_e == '' || this.local_link.title_e == ' '){
                alert ('URL 영문명을 입력해 주세요.') 
                return false
            }
            let idx = this.url_list.findIndex( url => url.title_e == this.local_link.title_e ) // 동일한 title이 있는지 확인
            let current_url_idx = this.url_list.findIndex( c_url => c_url.document_id == this.document_id ) 
            if ( idx > -1 && idx != current_url_idx ){
                alert ('중복되는 URL 영문명이 있습니다. 다른 이름으로 입력해 주세요.') 
                return false 
            }
            if ( this.local_link.link_e.length < 10 ) {
                alert ('URL을 입력해 주세요.') 
                return false
            }
            if ( this.local_link.link_e.includes(`http`) != true ) {
                if (confirm (`링크에 'http://'가 없으면 보고서 공개시 링크 연결이 어렵습니다. 'http://' 없이 저장 하시겠습니까?`)== true) {
                    return true
                }
                else return false
            }
            else return true
        }
    },
    created(){
        this.initiateEdit()
        // console.log(this.local_link)
        // console.log(this.local_file)
    }

}
</script>

<style>

</style>
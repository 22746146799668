<template>
<div>

    <b-card no-body class="shadow-sm">  
        <!-- <b-card-header class="p-1 bg-white border-0">
            <b-button block @click="$router.push({name: 'BasicTranslateSet'})" variant="icon-txt" class="text-left py-2" > 번역 기본사항 </b-button>
        </b-card-header> -->
        <div v-if="standard_mode==='GRI'">
            <div v-for="(item, index) in translate_list" :key="item.id" class="p-1 bg-white border-top">
                <b-button block @click="pageGo(item.name)" :variant="sub_visible[index] === true?'teal':'icon-txt'" class="text-left py-2" >{{item.standards}}</b-button>
            </div>
        </div>

        <div v-else-if="standard_mode==='SASB'">
            <div class="p-1 bg-white border-top">
                <b-button block @click="pageGo('SasbTranslateSet')" :variant="$route.name==='SasbTranslateSet'?'teal':'icon-txt'" class="text-left py-2" >SASB 전체 번역</b-button>
                <b-button block @click="pageGo('ESasb')" :variant="$route.name!=='SasbTranslateSet'?'teal':'icon-txt'" class="text-left py-2" >SASB 지표 번역</b-button>
            </div>
        </div>


        <div v-else-if="standard_mode==='TCFD'">
            <div class="p-1 bg-white border-top">
                <b-button block @click="pageGo('TcfdTranslateSet')" :variant="$route.name==='TcfdTranslateSet'?'teal':'icon-txt'" class="text-left py-2" >번역 기본사항(TCFD)</b-button>
                <b-button block @click="pageGo('ETcfd')" :variant="$route.name!=='TcfdTranslateSet'?'teal':'icon-txt'" class="text-left py-2" >TCFD 지표 번역</b-button>
            </div>
        </div>

        <div v-else-if="standard_mode==='DATA_CENTER'">
            <div class="p-1 bg-white border-top">
                <b-button block @click="pageGo('DataCenterTranslateSet')" :variant="$route.name==='DataCenterTranslateSet'?'teal':'icon-txt'" class="text-left py-2" >번역 기본사항(Data Center)</b-button>
                <b-button block @click="pageGo('EToC')" :variant="$route.name==='EToC'?'teal':'icon-txt'" class="text-left py-2" >Data Center 목차 번역</b-button>
                <b-button block @click="pageGo('EDataCenter')" :variant="$route.name==='EDataCenter'?'teal':'icon-txt'" class="text-left py-2" >Data Center 지표 번역</b-button>
            </div>
        </div>

        <div v-else>
            <div class="p-1 bg-white border-top">
                <b-button disabled block @click="pageGo('BasicTranslateSet')" :variant="$route.name==='BasicTranslateSet'?'teal':'icon-txt'" class="text-left py-2" >전체 지표 번역</b-button>
            </div>
        </div>
        <!-- <div class="p-1 bg-white border-top">
            <b-button 
                block 
                :variant="false?'teal':'icon-txt'" 
                class="text-left py-2"
            >
                SASB
            </b-button>
        </div>
        
        <div class="p-1 bg-white border-top">
            <b-button 
                block 
                :variant="false?'teal':'icon-txt'" 
                class="text-left py-2"
            >
                TCFD
            </b-button>
        </div> -->
    </b-card>
    
    <back-to-top></back-to-top>

</div>    
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import * as ih from '@/components/util'

export default {
    data() {
        return {
            translate_side: [
                {
                    'standards': '번역 기본사항(GRI 2016)', 'name': 'BasicTranslateSet'
                },
                {
                    'standards': '일반(GRI 100)', 'name': 'EGri100', 'topics': [{ topic_id: '1', topic_name: '조직 프로필' }, { topic_id: '2', topic_name: '전략' }, { topic_id: '3', topic_name: '윤리 및 청렴성' }, { topic_id: '4', topic_name: '지배구조' }, { topic_id: '5', topic_name: '이해관계자 참여' }, { topic_id: '6', topic_name: '보고 관행' }]
                },
                {
                    'standards': '경제(GRI 200)', 'name': 'EGri200', 'topics': []
                },
                {
                    'standards': '환경(GRI 300)', 'name': 'EGri300', 'topics': []
                },
                {
                    'standards': '사회(GRI 400)', 'name': 'EGri400', 'topics': []
                }
            ],
            translate_side_2021: [
                {
                    'standards': '번역 기본사항(GRI 2021)', 'name': 'BasicTranslateSet'
                },
                {
                    'standards': '일반 공개사항', 'name': 'EGri2', 'topics': [{ topic_id: '1', subtopic_code: '2_a', topic_name: '조직 및 보고 관행' }, { topic_id: '2', subtopic_code: '2_b', topic_name: '활동 및 근로자' }, { topic_id: '3', subtopic_code: '2_c', topic_name: '거버넌스' }, { topic_id: '4', subtopic_code: '2_d', topic_name: '조직, 정책 및 관행' }, { topic_id: '5', subtopic_code: '2_e', topic_name: '이해관계자 참여' }]
                },

                {
                    'standards': '중요주제', 'name': 'EGri3', 'topics': [{ topic_id: '', topic_name: '중요주제' }]
                },

                {
                    'standards': '경제', 'name': 'EGri200', 'topics': []
                },
                {
                    'standards': '환경', 'name': 'EGri300', 'topics': []
                },
                {
                    'standards': '사회', 'name': 'EGri400', 'topics': []
                }
            ]
        }
    },
    computed:{
        ...mapGetters('report_list', ['gri_ver']),
        ...mapState("report_list", ["standard_mode"]),

        translate_list(){
            if(this.gri_ver === 'GRIv2021'){
                return this.translate_side_2021
            }else return this.translate_side
        },
        sub_visible(){
            let list21 = [false, false, false, false, false, false]
            let list16 = [false, false, false, false, false]
            let new_list = []
            if(this.gri_ver === 'GRIv2021'){
                new_list = [...list21]
            }else {
                new_list = [...list16]
            }
            if(this.$route.name === 'EGri2'){
                let num = new_list.length-5
                new_list.splice(num,1,true)
                return new_list
            }
            if(this.$route.name === 'EGri3'){
                let num = new_list.length-4
                new_list.splice(num,1,true)
                return new_list
            }
            if(this.$route.name === 'EGri200'){
                let num = new_list.length-3
                new_list.splice(num,1,true)
                return new_list
            }
            if(this.$route.name === 'EGri300'){
                let num = new_list.length-2
                new_list.splice(num,1,true)
                return new_list
            }
            if(this.$route.name === 'EGri400'){
                let num = new_list.length-1
                new_list.splice(num,1,true)
                return new_list
            }
            else {
                new_list.splice(0,1,true)
                return new_list
            }
        },

    },
    methods:{
        pageGo(name){
            this.$router.push({name: name})
        },
    },
    watch:{
    },
    updated(){
    },
    components:{
    }
}
</script>
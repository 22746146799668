<template>
<div>
    <div v-if="cursor > -1" class="py-2 px-1">
        <p class="f-95"> {{ examples[cursor].description }} </p>
        <div v-if="examples[cursor].image_path" class="mb-2 shadow-sm cursor" @click.stop.prevent="showExampleModal"><b-img fluid :src="$store.state.static_host + examples[cursor].image_path" /></div>
        <p class="f-90 mb-1 pt-2 gray-550">출처: {{ examples[cursor].report_name }}</p>
        <p><a v-if="examples[cursor].report_path!=undefined" :href="examples[cursor].report_path" target="_blank" class="f-85">{{ examples[cursor].report_path.substring(0,50) }}... </a></p>
        <div v-if="examples.length > 1" class="text-center f-85 gray-550">
            <b-button @click="movePrevious()" variant="icon-gray" class="mr-1 py-0 f-90"><fa icon="angle-left" class="mr-2"/>이전</b-button>|
            <b-button @click="moveNext()" variant="icon-gray" class="ml-1 py-0 f-90">다음<fa icon="angle-right" class="ml-2"/></b-button>
        </div>
    </div>
    <div v-else>
       <p class="text-center mb-0 gray-550 f-90"> <fa icon="seedling" class="mr-1"/> 아직 등록된 예시가 없습니다. </p>
    </div>

   <div v-show="show_item_modal==true" class="hj-modal-example bg-white rounded shadow" :style="low_height==true? 'height:50vh!important':''">
        <div class="hj-modal-header d-flex align-items-center justify-content-between px-4"> 작성 예시 보기
            <div><b-button variant="icon-light" @click="low_height=!low_height"><fa :icon="low_height==true?'expand' :'compress'"></fa> </b-button><b-button variant="icon-light" @click="hideExampleModal"><fa icon="times"></fa> </b-button></div>
        </div> 
        <div class="hj-modal-body px-3" :style="low_height==true? 'height:calc(50vh - 50px)!important':''">
            <div v-if="cursor > -1" class="py-2 px-1">
                <p class="f-95"> {{ examples[cursor].description }} </p>
                <div v-if="examples[cursor].image_path" class="mb-2"><b-img fluid :src="$store.state.static_host + examples[cursor].image_path" /></div>
                <p class="f-90 mb-1 pt-2 gray-550">출처: {{ examples[cursor].report_name }}</p>
                <p><a v-if="examples[cursor].report_path!=undefined" :href="examples[cursor].report_path" target="_blank" class="f-85">{{ examples[cursor].report_path.substring(0,50) }}... </a></p>
                <div v-if="examples.length > 1" class="text-center f-85 gray-550">
                    <b-button @click="movePrevious()" variant="icon-gray" class="mr-1 py-0 f-90"><fa icon="angle-left" class="mr-2"/>이전</b-button>|
                    <b-button @click="moveNext()" variant="icon-gray" class="ml-1 py-0 f-90">다음<fa icon="angle-right" class="ml-2"/></b-button>
                </div>
            </div>
        </div>
    </div>

</div>
</template>
<script>

export default {
    data(){
        return {
            examples: [
                //{
                  //example_id, 
                  //gri_code, 
                  //organization_name, 
                  //reporting_year, 
                  //report_name, 
                  //report_path, 
                  //image_name, 
                  //image_path, 
                  //description, 
                  //time_stamp  
                //}
            ],
            cursor: -1,
            show_item_modal: false,
            low_height: false,
        }
    },
    props:{
        gri_code: { type: String, default: '417-1'}
    },
    methods:{
        readExamples(){
            const path = this.$store.state.backend_host + '/read_example_list'

            return axios.get(path, { params: {
                'gri_code': this.gri_code
            }})
            .then( response => {
                // console.log(response.data)
                this.examples = response.data
                if(this.examples.length > 0){
                    this.cursor = Math.floor(Math.random() * 100) % this.examples.length // example 길이 미만의 정수로 0 ~ 랜덤번째 example을 가리킨다
                    // console.log(this.cursor)
                }
            })
        },
        moveNext(){
            if(this.examples.length > 0){
                if( this.cursor < this.examples.length - 1){
                    this.cursor += 1
                }
                else{
                    this.cursor = 0
                }
            }
            else{
                this.cursor = -1
            }
        },
        movePrevious(){
            if(this.examples.length > 0){
                if ( this.cursor > 0){
                    this.cursor -= 1
                }
                else {
                    this.cursor = this.examples.length - 1
                }
            }
            else{
                this.cursor = -1
            }
        },
        showExampleModal(){
            if (this.show_item_modal==false){
                this.show_item_modal = true
            }
        },
        hideExampleModal(){
            this.show_item_modal = false
            this.low_height = false
        },
    },
    created(){
        this.readExamples(this.gri_code)
    },
    updated(){
        // console.log(this.show_item_modal);
    },
    watch:{
       
    }
    
}
</script>
<template>
    <div>
        tcfd 인디케이터??
    </div>
</template>
<script>
export default {
    name: '',
    props: {},
    components: {},
    data() {
        return {
            example: '',
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {}
}
</script>
<style>
</style>
<template>
  <div class="report-basic-toc border-bottom">
    <b-container class="px-0">
      <div class="menu-container">
        <div class="categories-wrapper">
          <div class="categories">
            <div
              v-for="(category, c) in report_categories"
              :key="`category-${category.category_id}-${c}`"
              class="category-button"
              @click="selectCategory(category.category_id)"
            >
              <span
                class="f-120 fw-700 hover-transparent-dark"
                :class="['button-default', { 'button-active': selectedCategoryId === category.category_id }]"
              >
                {{ category.category_name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <div class="d-none d-lg-block arrow-buttons">
      <button :disabled="disableLeftArrow" class="arrow-button arrow-left-button" @click="scrollLeft"><i class="fas fa-chevron-left"></i></button>
      <button :disabled="disableScrollTopButton" class="arrow-button scroll-top-button" @click="scrollToTop"><i class="fas fa-chevron-up"></i></button>
      <button :disabled="disableScrollNextButton" class="arrow-button scroll-next-button" @click="scrollDown"><i class="fas fa-chevron-down"></i></button>
      <button :disabled="disableRightArrow" class="arrow-button arrow-right-button" @click="scrollRight"><i class="fas fa-chevron-right"></i></button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'ContentsListNew',
  props: {
    web_view: {
      type: Boolean,
      default: false
    },
    preview_mode: {
      type: Boolean,
      default: false
    },
    content_list_open: {
      type: Boolean,
      default: false
    },
    categoryType: {
      type: String,
      default: 'gri'
    }
  },
  data() {
    return {
      initialCategories: [],
      sasb_indicator_list: {},
      gri_categories_loaded: false,
      sasb_categories_loaded: false,
      selectedIndustryKey: null,
      categoryTypes: ['gri', 'sasb', 'tcfd', 'data center'],
      showScrollTopButton: false,
      showScrollNextButton: true,
      disableLeftArrow: false,
      disableRightArrow: false,
      disableScrollTopButton: false,
      disableScrollNextButton: false
    }
  },
  computed: {
    ...mapState('report_list', ['lang', 'report_code']),
    ...mapGetters('report_list', ['selected_report']),
    ...mapGetters('gri2021_report_view', ['report_universal_categories', 'report_topic_specific_categories']),
    ...mapGetters('category', ['selectedCategoryId']),
    main_color() {
      return `color:` + (this.selected_report?.main_color || '')
    },
    main_color_sub() {
      return `color:` + (this.selected_report?.main_color || '') + `; border-top: 1px solid ` + (this.selected_report?.main_color || '') + `!important` + `; border-bottom: 1px solid ` + (this.selected_report?.main_color || '')
    },
    main_bg_color() {
      if (this.selected_report) {
        return `background-color: ` + this.selected_report.main_color
      } else {
        return `background-color: #494949`
      }
    },
    report_categories() {
      if (this.categoryType === 'tcfd') {
        return [{ category_id: 'tcfd', category_name: 'TCFD' }];
      } else if (this.categoryType === 'data center') {
        return [{ category_id: 'data-center', category_name: 'Data Center' }];
      } else if (this.categoryType === 'sasb' && this.sasb_categories_loaded) {
        return Object.keys(this.sasb_indicator_list).map(key => ({
          category_id: key,
          category_name: this.sasb_indicator_list[key]?.industry_name || '',
          category_name_e: this.sasb_indicator_list[key]?.industry_name || '',
        }));
      } else if (this.categoryType === 'gri' && this.gri_categories_loaded) {
        let categories = [];
        if (this.report_universal_categories) {
          categories = categories.concat(this.report_universal_categories.map(category => ({
            category_id: category.category_id,
            category_name: category.category_name,
            category_name_e: category.category_name_e,
          })));
        }
        if (this.report_topic_specific_categories) {
          categories = categories.concat(this.report_topic_specific_categories.map(category => ({
            category_id: category.category_id,
            category_name: category.category_name,
            category_name_e: category.category_name_e,
          })));
        }
        return categories;
      } else {
        return [];
      }
    }
  },
  methods: {
    ...mapActions('category', [
      'setSelectedCategoryId'
    ]),
    selectCategory(categoryId) {
      this.setSelectedCategoryId(categoryId);
      const queryParam = this.categoryType === 'sasb' ? { industry_key: categoryId } : { categoryId };
      this.$router.push({
        query: { ...this.$route.query, ...queryParam }
      });
    },
    loadInitialCategories() {
      let categories = [];
      if (this.report_universal_categories) {
        categories = categories.concat(this.report_universal_categories.map(category => ({
          category_id: category.category_id,
          category_name: category.category_name,
          category_name_e: category.category_name,
        })));
      }
      if (this.report_topic_specific_categories) {
        categories = categories.concat(this.report_topic_specific_categories.map(category => ({
          category_id: category.category_id,
          category_name: category.category_name,
          category_name_e: category.category_name,
        })));
      }
      this.initialCategories = categories;
      this.gri_categories_loaded = true;
      if (categories.length > 0) {
        this.selectCategory(categories[0].category_id);  // 최초 카테고리 자동 선택
      }
    },
    async fetchSelectedSasb() {
      if (!this.report_code) {
        console.error('Report code is not defined');
        return;
      }

      try {
        const response = await axios.get(
          `${this.$store.state.backend_host}/sasb/get_indicator_disclosures_list_for_viewer?rc=${this.report_code}`
        );
        this.sasb_indicator_list = response.data.result;
        this.sasb_categories_loaded = true;
        this.loadIndustryKeyData(this.selectedIndustryKey);
      } catch (error) {
        window.alert(error);
      }
    },
    loadIndustryKeyData(industryKey) {
      this.selectedIndustryKey = industryKey;
      if (this.sasb_categories_loaded) {
        this.selectedIndustryData = this.sasb_indicator_list[industryKey];
      }
    },
    scrollLeft() {
      const currentIndex = this.report_categories.findIndex(category => category.category_id === this.selectedCategoryId);
      if (currentIndex > 0) {
        this.selectCategory(this.report_categories[currentIndex - 1].category_id);
      } else {
        this.switchCategoryType(-1);
      }
    },
    scrollRight() {
      const currentIndex = this.report_categories.findIndex(category => category.category_id === this.selectedCategoryId);
      if (currentIndex < this.report_categories.length - 1) {
        this.selectCategory(this.report_categories[currentIndex + 1].category_id);
      } else {
        this.switchCategoryType(1);
      }
    },
    switchCategoryType(direction) {
      const currentIndex = this.categoryTypes.indexOf(this.categoryType);
      const newIndex = (currentIndex + direction + this.categoryTypes.length) % this.categoryTypes.length;
      this.$router.push({
        query: { ...this.$route.query, t: this.categoryTypes[newIndex] }
      });
    },
    handleScroll() {
      const bottomReached = (window.innerHeight + window.scrollY) >= (document.documentElement.scrollHeight - 1); // -1 to account for potential rounding issues
      this.showScrollTopButton = window.scrollY > 0;
      this.disableScrollNextButton = bottomReached;
      this.disableScrollTopButton = window.scrollY === 0;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollDown() {
      window.scrollBy({ top: 300, behavior: 'smooth' });
    },
    updateArrows() {
      const currentIndex = this.report_categories.findIndex(category => category.category_id === this.selectedCategoryId);
      this.disableLeftArrow = currentIndex === 0;
      this.disableRightArrow = currentIndex === this.report_categories.length - 1;
    }
  },
  watch: {
    sasb_indicator_list(newList) {
      if (Object.keys(newList).length > 0) {
        this.sasb_categories_loaded = true;
        const firstCategoryId = Object.keys(this.sasb_indicator_list)[0];
        this.selectCategory(firstCategoryId);  // 최초 카테고리 자동 선택
        this.loadIndustryKeyData(this.selectedIndustryKey);
      }
    },
    '$route.query.t': {
      handler(newType) {
        this.categoryType = newType || 'gri';
        if (this.categoryType === 'sasb') {
          this.fetchSelectedSasb();
        } else {
          this.loadInitialCategories();
        }
        this.updateArrows();
      },
      immediate: true
    },
    '$route.query.industry_key': {
      handler(newKey) {
        this.loadIndustryKeyData(newKey);
      },
      immediate: true
    },
    selectedCategoryId() {
      this.updateArrows();
    }
  },
  created() {
    if (this.categoryType === 'sasb') {
      this.selectedIndustryKey = this.$route.query.industry_key || Object.keys(this.sasb_indicator_list)[0];
      this.fetchSelectedSasb();
    } else {
      this.loadInitialCategories();
    }

    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style lang="scss" scoped>
@import "~@fortawesome/fontawesome-free/css/all.css";

.button-default {
  border: none !important;
  background-color: transparent !important;
  color: black !important;
  position: relative;
  font-weight: bold;
  font-size: 1.2rem;
  height: 50px; 
  opacity: 0.5; 
  cursor: pointer;

  &:hover {
    opacity: 1; 
  }
}

.button-active {
  position: relative;
  opacity: 1 !important; 
}

.report-basic-toc {
  position: relative;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: transparent;
}

.menu-container {
  display: flex;
  align-items: center;
  height: 50px; 
}

.categories-wrapper {
  display: flex;
  overflow-x: auto;
  flex-grow: 1;
  scroll-behavior: smooth;
}

.categories {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.category-button {
  margin-right: 1.5vw;
}

.arrow-buttons {
  z-index: 1000; /* z-index 증가 */
}

.arrow-button {
  position: fixed;
  bottom: 30px;
  background: none;
  border: none;
  font-size: 3rem;
  cursor: pointer;
  color: #00000080;
  padding: 0 10px;
}

.arrow-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.arrow-button:hover:not(:disabled) {
  color: black;
}

.arrow-right-button {
  right: 79px;
}

.arrow-left-button {
  right: 137px;
}

.scroll-top-button {
  bottom: 60px;
  right: 100px;
}

.scroll-next-button {
  bottom: 0px;
  right: 100px;
}
</style>

import store from "@/store/index.js";
import { vue_instance } from "@/pages/editor/main.js";

function isJSON(jsonString) {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
}

function JSONParse(jsonString) {
  if (isJSON(jsonString)) return JSON.parse(jsonString);
  else return {};
}

function ArrayParse(jsonString) {
  if (isJSON(jsonString)) return JSON.parse(jsonString);
  else return [];
}

function stringToArray(string) {
  let new_array = [];

  if (typeof string == "string") {
    new_array = string.split(",");
    for (let i = 0; i < new_array.length; i++) {
      new_array[i] = new_array[i].replace(/^\s+|\s+$/g, "");
    }
    return new_array;
  } else return false;
}

function arrayToString(array) {
  if (Array.isArray(array)) {
    return array.toString(array);
  } else return false;
}

function deepCopy(obj) {
  if (obj === null || typeof obj !== "object") return obj;

  var copy = obj.constructor();

  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) {
      copy[attr] = deepCopy(obj[attr]);
    }
  }

  return copy;
}

function stringToDate(string) {
  //"2019-11-13" 형식의 데이터를 Javascript Date로 변환하여 반환

  let new_date = new Date(string);
  new_date.setHours(0);
  new_date.setMinutes(0);
  new_date.setSeconds(0);

  return new_date;
}

function dateToString(date) {
  //날짜를 "2019-11-13"로 변환하여 반환

  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function getTimeStamp() {
  let d = new Date();

  let s =
    leadingZeros(d.getFullYear(), 4) +
    "-" +
    leadingZeros(d.getMonth() + 1, 2) +
    "-" +
    leadingZeros(d.getDate(), 2);

  return s;
}

function leadingZeros(n, digits) {
  var zero = "";
  n = n.toString();

  if (n.length < digits) {
    for (let i = 0; i < digits - n.length; i++) zero += "0";
  }

  return zero + n;
}

function getImage(path, thumb) {
  let dirname_re = /.+[/\\]/g;
  let filename_re = /[/\\][^/\\]*$/g;
  let filename = filename_re.exec(path);
  filename = filename.toString().replace(/[/\\]/, "");
  let dirname = dirname_re.exec(path);

  let static_host = store.state.static_host;

  if (thumb == true) {
    filename = "thumb_" + filename;
  }
  return static_host + dirname + filename;
}

function getFile(filename, filepath, thumb) {
  const path = store.state.backend_host + "/get_file";

  let params = {
    filename: filename,
    filepath: filepath,
  };

  if (thumb == true) {
    params.thumb = true;
  }

  return axios
    .get(path, { params: params, responseType: "blob" })
    .then((response) => {
      let file_data = {};
      if (
        (navigator.appName == "Netscape" &&
          navigator.userAgent.toLowerCase().indexOf("trident") != -1) ||
        navigator.userAgent.toLowerCase().indexOf("msie") != -1 ||
        navigator.appVersion.indexOf("Edge") > -1
      ) {
        // ie 또는 edge일 경우 경우
        file_data = response.data;
        file_data.name = filename;
      } else {
        file_data = new File([response.data], filename);
      }
      // console.log(response)
      let new_file = {};
      new_file.link = URL.createObjectURL(file_data);
      new_file.filename = response.data.filename;

      return new_file;
    });
}

function getFileAsData(filename, filepath, thumb) {
  const path = store.state.backend_host + "/get_file";

  let params = {
    filename: filename,
    filepath: filepath,
  };
  if (thumb == true) {
    params.thumb = true;
  }

  return axios
    .get(path, { params: params, responseType: "blob" })
    .then((response) => {
      let file_data = {};
      if (
        (navigator.appName == "Netscape" &&
          navigator.userAgent.toLowerCase().indexOf("trident") != -1) ||
        navigator.userAgent.toLowerCase().indexOf("msie") != -1 ||
        navigator.appVersion.indexOf("Edge") > -1
      ) {
        // ie 또는 edge일 경우 경우
        file_data = response.data;
        file_data.name = filename;
      } else {
        file_data = new File([response.data], filename);
      }

      return file_data;
    });
}

function anchorGo(anchor, should_reload=false) {
  if (anchor === null) {
    anchor = ''
  }

  const baseURL = window.location.origin
  const queryString = window.location.href.split("?")[1]
  let params = new URLSearchParams(queryString)
  let newURL = null
  if (params.size > 0) {
    if (params.toString().split("%23").length > 1) {
      params = params.toString().split("%23")[0]
    }
    newURL = baseURL + window.location.pathname + "?" + params.toString() + anchor
  } else {
    newURL = baseURL + window.location.pathname + anchor
  }

  window.history.pushState({}, '', newURL);

  // const url = new URL(window.location);

  // if (anchor) {
  //   url.hash = anchor
    
  // } else {
  //   url.hash = '';
  // }


  console.log(window.location.hash);
  console.log('여기서 체크')
  // history.pushState(null, '', url)

  if (anchor === "" || should_reload) {
    window.location.reload()
    
  }
  
  if (anchor && document.querySelector(anchor)) {
    let elem = document.querySelector(anchor);
    // let elemTop = window.pageYOffset + elem.getBoundingClientRect().top;
    // // console.log(elemTop)
    // setTimeout(function () {
    //     window.scrollTo(0, elemTop-50)
    // }, 1)
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
    }
  }
}

function objectToFormData(obj, formData, parentKey) {
  formData = formData || new FormData();

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // FormData에 추가하기 전에 키를 설정합니다.
      const newKey = parentKey ? `${parentKey}[${key}]` : key;

      if (typeof value === "object" && !Array.isArray(value)) {
        // 중첩된 객체인 경우 재귀 호출
        objectToFormData(value, formData, newKey);
      } else if (Array.isArray(value)) {
        // 배열의 각 항목을 반복하면서 FormData에 추가
        for (let i = 0; i < value.length; i++) {
          const arrayKey = `${newKey}[${i}]`;
          formData.append(arrayKey, value[i]);
        }
      } else {
        // 문자열, 숫자 또는 불리언 값을 FormData에 추가
        formData.append(newKey, value);
      }
    }
  }

  return formData;
}

function formattedText(text) {
  // 개행 문자(\n)을 HTML 엔터티로 변환
  if (text) {
    return text.replace(/\n/g, "<br>");
  } else {
    return text
  }
}

const nan_table = {
  'nan': {
      'KOR': '해당 없음',
      'ENG': 'Not applicable'
  },
  'illegal': {
      'KOR': '법적 금지',
      'ENG': 'Legal prohibitions'
  },
  'non_disclosure': {
      'KOR': '비밀 유지 제한',
      'ENG': 'Confidentiality constraints'
  },
  'incomplete_info': {
      'KOR': '정보 미제공 / 완전',
      'ENG': 'Information unavailable / incomplete'
  }
}

const no_omission_gri_code = ['2-1', '2-2', '2-3', '2-4', '2-5', '3-1', '3-2']


function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}


function getParamsFromQueryString() {
  const currentURL = window.location.href;
  const queryString = currentURL.split('?')[1];

  if (queryString) {
    const paramsArray = queryString.split('&');
  
    const params = {};
  
    for (let i = 0; i < paramsArray.length; i++) {
        const param = paramsArray[i].split('=');
        const key = decodeURIComponent(param[0]);
        const value = decodeURIComponent(param[1]);
        params[key] = value;
    }
  
    return params
    
  } else {
    return null
  }

}

function updateParamsFromQueryString(key, value) {
    const baseURL = window.location.origin
    const queryString = window.location.href.split("?")[1]

    let params = new URLSearchParams(queryString)
  
    params.set(key, value)
    if (!value || value === 'null') {
      params.delete(key)
    }

    let newURL = null
  
    if (params.size > 0) {
      if (params.toString().split("%23").length > 1) {
        params = params.toString().split("%23")[0]
      }
      newURL = baseURL +window.location.pathname + "?" + params.toString() + window.location.hash
    } else {
      newURL = baseURL +window.location.pathname+ window.location.hash
    }

  

    window.history.pushState({}, '', newURL);
}

export {
  isJSON,
  JSONParse,
  ArrayParse,
  stringToArray,
  arrayToString,
  deepCopy,
  stringToDate,
  dateToString,
  getTimeStamp,
  getFile,
  getImage,
  getFileAsData,
  anchorGo,
  objectToFormData,
  formattedText,
  nan_table,
  no_omission_gri_code,
  generateRandomString,
  getParamsFromQueryString,
  updateParamsFromQueryString
};

<template>
  <div>
    <b-modal
      :id="`sasb-industry-${sasb_industry_id}`"
      size="xl"
      :title="sasb_industry_name+` 산업 세부지표 보기`"
      hide-footer
      scrollable
      @hide="showPopup=false"
    >
      <ShowIndustryDetailPopup
          v-if="showPopup"
          :sasb_industry_id = sasb_industry_id
          :sasb_industry_name=sasb_industry_name
      />
    </b-modal>

   
    <b-row no-gutters class="hover-tealgray">
      <b-col cols="10" >
        <b-form-checkbox
          v-model="industry_selected"
          type="checkbox"
          @change="updateIndustryChecked"
        >
          {{ sasb_industry_name }}
          <b-icon
            v-if="is_saving"
            icon="circle-fill"
            animation="throb"
            font-scale="1"
          />
          <b-icon
            v-if="saving_success"
            icon="check"
            scale="1.5"
            variant="success"
            animation="fade"
          />
          <b-icon
            v-if="saving_failed"
            icon="x"
            scale="1.5"
            variant="danger"
            animation="fade"
          />
        </b-form-checkbox>
      </b-col>
      <b-col cols="1">
        <b-button @click="showIndustryDetail" size="sm" variant="icon" v-b-tooltip.hover :title="`'`+sasb_industry_name+`' 산업 보고 지표 목록보기`">
          <b-icon            
            icon="gear-fill"
            aria-hidden="true"           
          />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ShowIndustryDetailPopup from "@/components/managing_indicators/sasb_2023/sasb_selector/ShowIndustryDetailPopup.vue";

export default {
  name: "",
  props: {
    sasb_industry_id: {
      type: Number
    },
    sasb_industry_name: {
      type: String
    },
    sasb_industry_name_e: {
      type: String
    },
    sasb_industry_checked: {
      type: Boolean
    },
  },
  computed: {
    ...mapState("report_list", ["report_code"]),
  },
  components: {
    ShowIndustryDetailPopup,
  },
  data() {
    return {
      industry_selected: this.sasb_industry_checked,

      is_saving: false,
      saving_failed: false,
        saving_success: false,

        showPopup: false
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  beforeDestroy(){
    this.showPopup = false;
  },
  methods: {
    showIndustryDetail() {
        // showPopup 없이 this.$bvModal.show(`sasb-industry-${this.sasb_industry_id}`); 만 하면
        // 렌더링된 모든 ShowIndustryDetailPopup컴포넌트에서 데이터요청 `/api/sasb/get_indicator_info_by_industry` 을 하기 때문에
        // 클릭한 산업의 지표정보만 요청할 수 있게 하기 위해 일단 이렇게 처리함. 
        // 2023.11.08 sykim
        this.showPopup = true;
        console.log(this.showPopup);
        // setTimeout(() => {
            this.$bvModal.show(`sasb-industry-${this.sasb_industry_id}`);
        // }, 10);
    },
    async updateIndustryChecked() {
      this.is_saving = true;

      try {
        const response = await axios.post(
          this.$store.state.backend_host + "/sasb/update_industry_checked",
          {
            report_code: this.report_code,
            sasb_industry_id: this.sasb_industry_id,
            checked: this.industry_selected,
          }
        );

        if (response.data.code) {
          this.is_saving = false;
          this.saving_success = true;
          setTimeout(() => {
            this.saving_success = false;
          }, 3000);
        } else {
          this.saving_failed = true;
          setTimeout(() => {
            this.saving_failed = false;
          }, 3000);
        }
      } catch (error) {
        this.is_saving = false;
        this.saving_failed = true;
        setTimeout(() => {
          this.saving_failed = false;
        }, 3000);
      }
    },
  },
  watch: {

    sasb_industry_checked: {
      immediate: true,
      handler(newVal) {
        this.industry_selected = this.sasb_industry_checked;
      }
    },
  }
};
</script>
<style></style>

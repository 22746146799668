<template>
  <div id="app">
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <router-view/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import HelloWorld from '@/components/HelloWorld'

export default {
    data(){
        return {
        
        }
    },
    computed:{
        // ...mapState(['user_id', 'access_token'])
    },
    methods:{
        makeToast(title, contents, append = false) {
            this.$bvToast.toast(`${contents}`, {
                title: title,
                toaster: 'b-toaster-bottom-center',
                solid: true,
                appendToast: append,
                // noAutoHide: true,
                autoHideDelay: 2000,
                variant: 'info'
            })
        },
        // checkLogin(){
        //     console.log(this.user_id);
            // if (this.user_id!=''&&this.user_id!=undefined&&this.user_id!=null){
            //     axios.defaults.headers.common['Authorization']= `Bearer ${this.access_token}`;
            // }
            // else {
            //     this.$router.push({name: 'Login'})
            // }
        // }
    },
    created(){
        this.$EventBus.$on('make-toast', this.makeToast)
        // this.checkLogin()
    }

}
</script>

<style>

</style>

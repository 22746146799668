<template>
  <div>
    <div class="bg-tealgray700 pt-5">
      <b-container>
        <div
          class="d-sm-flex justify-content-between align-items-end pb-2"
          style="height: 66px"
        >
          <div
            v-if="$route.name === 'Main'"
            class="mb-2 mb-sm-0 py-1 px-2"
            style="max-width: 200px"
          >
            <b-img src="/static_img/logo_flat3.svg" fluid></b-img>
          </div>
          <div v-else class="d-flex align-items-end">
            <b-button
              @click="$router.push({ name: 'Main' })"
              variant="icon"
              class="py-1 px-2 header-logo"
            >
              <b-img src="/static_img/logo_flat3.svg" fluid></b-img>
            </b-button>
            <h4
              v-if="show_landing_menu !== true && report_code !== ''"
              class="mb-0 pb-1 pl-2 pl-xl-3 d-inline fw-400 gray-400"
            >
              <b-badge variant="info" v-if="selected_report.sample_report"
                >sample</b-badge
              >

              <b-badge v-if="is_admin===4">
                {{ selected_report.report_code }}
              </b-badge>


              {{ selected_report.report_name }}
              <b-icon
                scale="1"
                v-if="selected_report.is_able_to_edit"
                variant="success"
                icon="check-circle"
                aria-hidden="true"
              ></b-icon>
              <b-icon
                v-else
                variant="danger"
                icon="x-circle"
                aria-hidden="true"
                v-b-tooltip.hover="
                  '읽기 권한만 있음. 결제 후 편집 및 공개 가능합니다.'
                "
              ></b-icon>
            </h4>
          </div>
          <div class="d-flex align-items-end mb-2 mb-sm-0">
            <!--신규 공지사항 개수 띄우기 tooltip<b-button :variant="new_notice == 0 ? 'half-bg-light' : 'red'" class="py-1 px-2" id="bell" @click="showNoticeSide" aria-controls="notice-sidebar" v-b-tooltip.hover :title="new_notice == 0 ? '공지 및 로그' : new_notice + '건의 새로운 공지가 있습니다.'"><fa :icon="['far','bell']"/> </b-button>-->
            <div
              v-if="
                licenseInfo &&
                licenseInfo.available_report_cnt_info &&
                show_landing_menu === true &&
                report_code === ''
              "
              style="color: white; font-size: 0.9rem; width: fit-content"
              class="py-1 px-2 mr-2 center"
              v-b-tooltip.hover
              :title="licenseInfo.subscriptionId + '이용 중'"
            >
              안녕하세요 {{ user_name }}님
              <!-- {{ licenseInfo.available_report_cnt_info.created_report_cnt }}개
              리포트를 생성하였습니다. -->
              {{ licenseInfo.available_report_cnt_info.remain_report_cnt }}개
              보고서를 추가로 생성할 수 있습니다.
            </div>

            <!-- <div
              style="color: white; font-size:0.9rem; width: fit-content; "
              class="py-1 px-2 mr-2 center"
              v-b-tooltip.hover :title="licenseInfo.subscriptionId+'이용 중'"
            >
              {{licenseInfo}}
            </div> -->

            <div
              v-else-if="
                licenseInfo === null &&
                show_landing_menu === true &&
                report_code === ''
              "
              style="color: white; font-size: 0.9rem; width: fit-content"
              class="py-1 px-2 mr-2 center"
            >
              안녕하세요 {{ user_name }}님.
              <router-link to="/subscription_service"> 결제 </router-link>후
              보고서 생성이 가능합니다.
            </div>
            <!-- <b-button
              v-if="licenseInfo !== null && licenseInfo.isUsed"
              style="background-color: #BF9EC4; font-size: small; max-width: 11rem; max-height: 2rem; text-wrap: nowrap;"
              class="py-1 px-2 mr-2 center"
            >
              {{ licenseInfo.subscriptionId }} 이용 중
              <div
                v-if="licenseInfo.isUsed && licenseInfo.payment_status == false"
              >
                이용료가 지불되지 않았습니다. 🥲
              </div>
            </b-button> -->
            <b-button
              variant="half-bg-light"
              class="py-1 px-2"
              id="bell"
              @click="showNoticeSide"
              aria-controls="notice-sidebar"
              v-b-tooltip.hover
              title="공지 및 로그"
              ><fa :icon="['far', 'bell']" />
            </b-button>
            <b-button
              :variant="is_confirmed === true ? 'half-bg-light' : 'red'"
              class="py-1 px-2 ml-2"
              id="my-info"
              @click.stop="showMyInfo()"
              ><fa :icon="['far', 'user']"
            /></b-button>
            <b-button
              :variant="payment_active === true ? 'light' : 'half-bg-light'"
              class="py-1 px-2 ml-2"
              id="my-payment"
              @click.stop="$router.push({ name: 'TestPaymentRegular' })"
              v-b-tooltip.hover
              title="결제 정보"
              ><fa icon="credit-card"
            /></b-button>
            <b-button
              :variant="manual_active === true ? 'light' : 'half-bg-light'"
              class="py-1 px-2 ml-2"
              id="manual"
              href="https://report.qesg.co.kr/document/QESG ESG_Reporting_Tool_How to use(full).pdf"
              target="_blank"
              v-b-tooltip.hover
              title="매뉴얼"
              ><fa icon="book"
            /></b-button>
            <b-button
              v-if="is_admin === 4"
              variant="icon-light"
              class="py-1 px-2 ml-2"
              @click="$router.push({ name: 'AllReports' })"
              >관리자</b-button
            >
            <b-button
              variant="icon-light"
              class="py-1 px-2 ml-2"
              @click="handleLogout()"
              >로그아웃</b-button
            >
          </div>
        </div>
      </b-container>
      <b-container id="header-menu" class="bg-white radious-top">
        <!-- :class="menu_active[2] == true ? 'border-top' : 'bg-white radious-top'" -->
        <div v-if="show_landing_menu === true" class="py-3">
          <template v-if="payment_active !== true">
            <b-button variant="icon" class="py-1 px-2 f-105 fw-500"
              >나의 보고서</b-button
            >
            <!-- <b-button variant="icon" class="py-1 px-2 f-105 fw-500 ml-2">분석하기 </b-button> -->
          </template>
          <template v-else>
            <b-button
              class="p-1 mr-3 f-105"
              :class="payment_menu[0] === true ? 'fw-500' : ''"
              :variant="payment_menu[0] === true ? 'bottom-circle' : 'icon-gray'"
              @click="$router.push({ name: 'TestPaymentRegular' })"
            >
              정기결제 안내
            </b-button>
            <b-button
              class="p-1 mr-3 f-105"
              :class="payment_menu[1] === true ? 'fw-500' : ''"
              :variant="payment_menu[1] === true ? 'bottom-circle' : 'icon-gray'"
              @click="$router.push({ name: 'AddPaymentMethod' })"
            >
              결제수단 관리
            </b-button>

            <b-button
              class="p-1 mr-3 f-105"
              :class="payment_menu[2] === true ? 'fw-500' : ''"
              :variant="payment_menu[2] === true ? 'bottom-circle' : 'icon-gray'"
              @click="$router.push({ name: 'TestPaymentCheck' })"
            >
              서비스 이용 내역
            </b-button>


            <b-button
              class="p-1 mr-3 f-105"
              :class="payment_menu[3] === true ? 'fw-500' : ''"
              :variant="payment_menu[3] === true ? 'bottom-circle' : 'icon-gray'"
              @click="$router.push({ name: 'ProductPay' })"
            >
              추가 서비스 결제
            </b-button>
          </template>
        </div>
        <div v-else class="py-3">
          <b-button
            class="p-1 mr-3 f-105"
            :class="menu_active[0] === true ? 'fw-500' : ''"
            :variant="menu_active[0] === true ? 'bottom-circle' : 'icon-gray'"
            @click="$router.push({ name: 'BasicSettings' })"
          >
            기본설정
          </b-button>
          <b-button
            class="p-1 mr-3 f-105"
            :class="menu_active[1] === true ? 'fw-500' : ''"
            :variant="menu_active[1] === true ? 'bottom-circle' : 'icon-gray'"
            @click="goIndicatorSelectorPage"
          >
            보고지표
          </b-button>
          <b-button
            class="p-1 mr-3 f-105"
            :class="menu_active[2] === true ? 'fw-500' : ''"
            :variant="menu_active[2] === true ? 'bottom-circle' : 'icon-gray'"
            @click="go_editor_page"
          >
            보고서 작성
          </b-button>
          <b-button
            class="p-1 mr-3 f-105"
            :class="menu_active[3] == true ? 'fw-500' : ''"
            :variant="menu_active[3] == true ? 'bottom-circle' : 'icon-gray'"
            @click="pageGo({ name: 'CheckReport' })"
          >
            검토 및 검증
          </b-button>
          <b-button
            class="p-1 mr-3 f-105"
            :class="menu_active[4] == true ? 'fw-500' : ''"
            :variant="menu_active[4] == true ? 'bottom-circle' : 'icon-gray'"
            @click="pageGo({ name: 'BasicTranslateSet' })"
          >
            번역
          </b-button>
          <!-- TODO: 상품결제 관리 임시 주석 -->
          <!-- <b-button
            class="p-1 mr-3 f-105"
            :class="menu_active[5] == true ? 'fw-500' : ''"
            :variant="menu_active[5] == true ? 'bottom-circle' : 'icon-gray'"
            @click="pageGo({ name: 'TestPayment' })"
          >
            결제 관리
          </b-button> -->
          <b-button
            class="py-1 px-2 float-right f-105"
            variant="light"
            @click="showTodoModal"
          >
            <!-- :variant="menu_active[2] == true ? 'half-bg-light' : 'light'" -->

            <fa icon="map-marker-alt" />
          </b-button>
        </div>

        <!-- <b-tooltip target="notice" triggers="hover"> 새 알림이 있습니다 </b-tooltip> -->
        <b-tooltip
          :show="is_confirmed != true"
          target="my-info"
          triggers="hover"
        >
          {{
            is_confirmed == true ? "내 계정 정보" : "이메일 계정을 인증해주세요"
          }}
        </b-tooltip>

        <b-modal
          ref="my-info"
          size="lg"
          title="내 계정 정보"
          id="modify-modal-top"
          hide-footer
        >
          <user-info></user-info>
        </b-modal>
      </b-container>
    </div>

    <b-sidebar
      id="notice-sidebar"
      v-model="show_notice_side"
      class="bg-tealgray150"
      title="Notice"
      shadow
    >
      <div class="px-3 py-2">
        <log-and-notice></log-and-notice>
      </div>
    </b-sidebar>


    <b-container :fluid="menu_active[2]">
      <div
        class="mng-nav pt-4 pb-3"  :class="menu_active[2]===true? 'px-5':''"
      > 
          <!-- <b-breadcrumb :items="items"></b-breadcrumb> -->
          <b-breadcrumb @click="switch_indicator" v-if="menu_active[2]">
              <b-breadcrumb-item 
                  href="#write_report"
                  :active="selected_indicator==='write_report'"
              >
                  <b-icon icon="pencil-fill" scale="1" shift-v="1" aria-hidden="true" /> 작성하기
              </b-breadcrumb-item>
              <b-breadcrumb-item 
                  href="#mng_dt"
                  :active="selected_indicator==='mng_dt'"
              >
                  <b-icon icon="table" scale="1" shift-v="1" aria-hidden="true" /> 
                  데이터 테이블 관리
              </b-breadcrumb-item>
              <b-breadcrumb-item 
                  href="#mng_doc"
                  :active="selected_indicator==='mng_doc'"
              >
                <fa icon="file-alt" class="mr-1"/> 
                  문서 및 링크 관리
              </b-breadcrumb-item>
              <b-breadcrumb-item 
                  href="#mng_bsc"
                  :active="selected_indicator==='mng_bsc'"
              >
                <b-icon icon="blockquote-left" scale="1.3" shift-v="1.25" aria-hidden="true" /> 
                  비즈니스 케이스 관리
              </b-breadcrumb-item>

              <b-breadcrumb-item 
                  href="#mng_charts"
                  :active="selected_indicator==='mng_charts'"
                >
                <b-icon icon="graph-up" scale="1.1" shift-v="1.25" aria-hidden="true" /> 
                  그래프 관리
              </b-breadcrumb-item>

              <!-- <b-breadcrumb-item 
                  :to="{ name: 'WriteDataCenters' }"
                  :active="$route.name === 'WriteDataCenters'"
                >
                <b-icon icon="graph-up" scale="1.1" shift-v="1.25" aria-hidden="true" /> 
                  데이터 센터
              </b-breadcrumb-item> -->

          </b-breadcrumb>
     
          <!-- <b-breadcrumb :items="items"></b-breadcrumb> -->
          <b-breadcrumb @click="switch_framework" v-if="menu_active[1]">
              <b-breadcrumb-item
                  v-if="selected_report && Object.keys(selected_report.report_type).includes('GRIv2021') || Object.keys(selected_report.report_type).includes('GRIv2016')"
                  href="#GRI"
                  :active="standard_mode==='GRI'"
              >
                  GRI
              </b-breadcrumb-item>
              <b-breadcrumb-item
                  v-if="selected_report && Object.keys(selected_report.report_type).includes('SASBv2023')"
                  href="#SASB"
                  :active="standard_mode==='SASB'"
              >
                  SASB
              </b-breadcrumb-item>
              <!-- <b-breadcrumb-item 
                  v-if="selected_report && Object.keys(selected_report.report_type).includes('TCFDv2021')"
                  href="#TCFD"
                  :active="standard_mode==='TCFD'"
              >
                  TCFD
              </b-breadcrumb-item> -->

              <b-breadcrumb-item 
                  v-if="selected_report && selected_report.data_center"
                  href="#DATA_CENTER"
                  :active="standard_mode==='DATA_CENTER'"
              >
                  Data Center
              </b-breadcrumb-item>
          </b-breadcrumb>

          <div class="d-flex" v-if="menu_active[4]">
            <b-breadcrumb class="mr-3" @click="translate_all_indicators">
              <b-breadcrumb-item
                :class="menu_active[4] == true ? 'fw-500' : ''"
                :variant="menu_active[4] == true ? 'bottom-circle' : 'icon-gray'"
                @click="pageGo({ name: 'BasicTranslateSet' })"
                :active="standard_mode===''"
              >
                전체 번역
              </b-breadcrumb-item>
            </b-breadcrumb>

            <b-breadcrumb @click="switch_translate_framework">
                <b-breadcrumb-item
                    v-if="selected_report && Object.keys(selected_report.report_type).includes('GRIv2021') || Object.keys(selected_report.report_type).includes('GRIv2016')"
                    href="#GRI"
                    :active="standard_mode==='GRI'"
                >
                    GRI
                </b-breadcrumb-item>
                <b-breadcrumb-item
                    v-if="selected_report && Object.keys(selected_report.report_type).includes('SASBv2023')"
                    href="#SASB"
                    :active="standard_mode==='SASB'"
                >
                    SASB
                </b-breadcrumb-item>
                <b-breadcrumb-item 
                    v-if="selected_report && Object.keys(selected_report.report_type).includes('TCFDv2021')"
                    href="#TCFD"
                    :active="standard_mode==='TCFD'"
                >
                    TCFD
                </b-breadcrumb-item>
  
                <b-breadcrumb-item 
                    v-if="selected_report && selected_report.data_center"
                    href="#DATA_CENTER"
                    :active="standard_mode==='DATA_CENTER'"
                >
                    DATA CENTER
                </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
      </div>
    </b-container>



    <b-modal
      id="todo-preference"
      ref="todo-modal"
      hide-footer
      size="xl"
      scrollable
      title="보고서 작성 로드맵"
    >
      <!-- <template #modal-header> <h4 class="mt-1">보고서 작성 로드맵</h4></template> -->
      <todo-roadmap
        @hide-todo-modal="hideTodoModal"
        :opened_by_header="true"
      ></todo-roadmap>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import UserInfo from "@/components/login_components/UserInfo";
import LogAndNotice from "@/components/LogAndNotice";
import ChannelService from "@/components/ChannelService";
import IndicatorsMaster from "@/components/managing_indicators/IndicatorsMaster";
import SelectGriIndicators from "@/components/managing_indicators/SelectGriIndicators";

export default {
  data() {
    return {
      show_landing_menu: true,
      menu_active: [true, false, false, false, false],
      payment_active: false,
      manual_active: false,
      payment_menu: [true, false, false, false],
      show_notice_side: false,
      selected_indicator: 'write_report',
      selected_framework: 'GRIv2021',
      items: [
        {
          id: 'test',
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Manage',
          href: '#'
        },
        {
          text: 'Library',
          active: true
        }
      ]
      
    };
  },
  computed: {
    ...mapState(["user_id", "is_confirmed", "is_admin", "e_mail", "user_name"]),
    ...mapState(["licenseInfo"]),
    ...mapState("report_list", ["report_code", "report_list", "standard_mode"]),
    ...mapState("logs", ["notice_list", "new_notice"]),
    ...mapGetters("report_list", ["selected_report"]),
    ...mapGetters("report_list", ["gri_ver"]),
  },
  methods: {
    ...mapMutations('report_list', ['set_standard_mode']),
    ...mapActions(["logout"]),
    ...mapActions("report_indicators", [
      "readReportTopics",
      "readReportIndicators",
    ]),

    ...mapActions("report_contents", ["readReportContents"]),
    ...mapActions("data_tables", ["readDataTableList"]),
    ...mapActions("documents", ["readDocumentList"]),
    ...mapActions("business_cases", ["readBusinessCaseList"]),
    ...mapActions(["updateLicenseInfo"]),

    ...mapActions("logs", ["read_logs_report_code", "readNoticesByUser"]),

    goIndicatorSelectorPage() {
      const first_type = Object.keys(this.selected_report.report_type)[0]
      if (first_type === "GRIv2021" || first_type === "GRIv2016") {
        this.set_standard_mode('GRI')
        this.pageGo({ name: 'GetGriRecommendations' })
      } else if (first_type === "SASBv2023") {
        this.set_standard_mode('SASB')
        this.pageGo({ name: 'GetSasbRecommendations' })
      } else if (first_type === "TCFDv2021") {
        this.set_standard_mode('TCFD')
        this.pageGo({ name: 'GetTcfdRecommendations' })
      }
    },
    switch_framework(e) {
      if (e.target.tagName !== 'A') return;
      
      this.selected_framework = e.target.href.split("#")[1];

      if (this.selected_framework == "GRI"){
        this.set_standard_mode("GRI")        
        this.$router.push({ name: "SelectGriIndicators" });
      } 
      else if (this.selected_framework == "SASB"){
        this.set_standard_mode("SASB")        
        this.$router.push({ name: "SelectSasbIndicators" });
      } 
      else if (this.selected_framework == "TCFD"){
        this.set_standard_mode("TCFD")        
        this.$router.push({ name: "SelectTcfdIndicators" });
      } 

      else if (this.selected_framework == "DATA_CENTER") {
        this.set_standard_mode("DATA_CENTER")        
        this.$router.push({ name: "EditDCToC" });
      }

    },
    translate_all_indicators() {
      this.set_standard_mode("")
      this.$router.push("/eng_basic")

      // @click="pageGo({ name: 'BasicTranslateSet' })"

    },
    switch_translate_framework(e) {
      if (e.target.tagName !== 'A') return;
      
      this.selected_framework = e.target.href.split("#")[1];

      if (this.selected_framework === "GRI"){
        this.set_standard_mode("GRI")        
        // this.$router.push({ name: "SelectGriIndicators" });
        this.$router.push("/gri_eng_basic")
        
      } 
      else if (this.selected_framework === "SASB"){
        this.set_standard_mode("SASB")        
        // this.$router.push({ name: "SelectSasbIndicators" });
        this.$router.push("/sasb_eng_basic")
        
      } 
      else if (this.selected_framework === "TCFD"){
        this.set_standard_mode("TCFD")        
        // this.$router.push({ name: "SelectTcfdIndicators" });
        this.$router.push("/tcfd_eng_basic")
        
      }

      else if (this.selected_framework === "DATA_CENTER") {
        this.set_standard_mode("DATA_CENTER")        
        this.$router.push("/dc_eng_basic")
        
      }


    },
    switch_indicator(e) {
      if (e.target.tagName !== 'A') return;

      this.selected_indicator = e.target.href.split("#")[1];
      
      if (this.selected_indicator === "write_report") {
        // this.$router.push("/mg_data_tables")
        this.go_editor_page()
      }

      if (this.selected_indicator === "mng_dt") this.$router.push("/mg_data_tables")
      else if (this.selected_indicator === "mng_doc") this.$router.push("/mg_documents")
      else if (this.selected_indicator === "mng_bsc") this.$router.push("/mg_business_cases")
      else if (this.selected_indicator === "mng_charts") this.$router.push("/mg_charts")

      
    },
    
    go_editor_page() {
      const first_type = Object.keys(this.selected_report.report_type)[0]
      if (first_type === "GRIv2021") {
        this.set_standard_mode('GRI')
        this.$router.push({ name: "Gri2" });

      } else if (first_type === "GRIv2016") {
        this.set_standard_mode('GRI')
        this.$router.push({ name: "Gri100" });


      } else if (first_type === "SASBv2023") {
        this.set_standard_mode('SASB')
        this.$router.push("/write_report/sasb");

      } else if (first_type === "TCFDv2021") {
        this.set_standard_mode('TCFD')
        this.$router.push("/write_report/tcfd");

      }
    },

    pageGo(to) {
      if (this.selected_report) {
        if (to.name === "GetGriRecommendations" || to.name === "GetSasbRecommendations" || to.name === "GetTcfdRecommendations") {
          if (this.selected_report.state < 1) {
            this.$router.push(to);
          } else if (this.selected_report.state >= 1) {
            this.readReportTopics({ report_code: this.report_code });
            this.readReportIndicators({ report_code: this.report_code });


            if (this.standard_mode === "GRI") this.$router.push({ name: "SelectGriIndicators" });
            else if (this.standard_mode === "SASB") this.$router.push({ name: "SelectSasbIndicators" });
            else if (this.standard_mode === "Tcfd") this.$router.push({ name: "SelectTcfdIndicators" });

          } else {
            alert(`보고서 기본 정보를 입력하고 보고서를 생성해 주세요.`);
          }
        } else if (to.name == "Gri100") {
            this.readAll();
            if (this.gri_ver != "GRIv2021") {
              this.$router.push(to);
            } else {
              this.$router.push({ name: "Gri2" });
            }
          // if (this.selected_report.state >= 2) {
          //   this.readAll();
          //   if (this.gri_ver != "GRIv2021") {
          //     this.$router.push(to);
          //   } else {
          //     this.$router.push({ name: "Gri2" });
          //   }
          // } else {
          //   console.log(" 으잉?");

          //   // 선택지표 없는지 확인
            


          //   alert(
          //     `'지표 선택 완료하기'를 클릭하여 지표 선택 완료 후 보고서 작성이 가능 합니다.`
          //   );
          // }
        } else if (to.name == "CheckReport") {
          if (this.selected_report.state >= 2) {
            // this.readAll();
            this.$router.push(to);
          } else {
            alert(`보고 내용을 최소 1개 이상 작성 후 검토 및 검증 가능합니다.`);

          }
        } else if (to.name == "BasicTranslateSet") {
          this.readAll(); // 번역페이지의 경우 다시 필요 정보 불러오기
          this.$router.push(to);
        } else if (to.name == "TestPayment") {
          // this.readAll();
          this.$router.push(to);
        }
      }
    },



    readAll() {
      if (this.gri_ver != "GRIv2021") {
        this.readReportContents({ report_code: this.report_code });
        this.readDataTableList({ report_code: this.report_code });
        this.readDocumentList({ report_code: this.report_code });
        this.readBusinessCaseList({ report_code: this.report_code });
        this.readReportTopics({ report_code: this.report_code });
        this.readReportIndicators({ report_code: this.report_code });
      } else {
        this.readReportContents({
          report_code: this.report_code,
          editor_mode: true,
        });
        this.readDataTableList({ report_code: this.report_code });
        this.readDocumentList({ report_code: this.report_code });
        this.readBusinessCaseList({ report_code: this.report_code });
      }
    },
    showMyInfo() {
      this.$refs["my-info"].show();
    },
    hideMyInfoModal() {
      this.$refs["my-info"].hide();
    },
    handleLogout() {
      this.logout();
    },
    showTodoModal() {
      this.$refs["todo-modal"].show();
    },
    hideTodoModal() {
      this.$refs["todo-modal"].hide();
    },
    showNoticeSide() {
      if (this.report_code != "") {
        this.read_logs_report_code({ report_codes: [this.report_code] });
      } else {
        let target_report_codes = [];
        this.report_list.forEach((item) =>
          target_report_codes.push(item.report_code)
        );
        // console.log(target_report_codes);
        this.read_logs_report_code({ report_codes: target_report_codes });
      }
      this.show_notice_side = !this.show_notice_side;
    },
    // landing page 로드시 user_id로 전체 공지사항 조회하기
    async initiateNotices() {
      await this.readNoticesByUser({ user_id: this.user_id });
    },
  },
  created() {
    ChannelService.boot();
    this.initiateNotices();
  },

  watch: {

    selected_report: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.selected_framework = Object.keys(newVal.report_type)[0]
        }
      }
    },
    $route: {
      immediate: true,
      handler(to, from) {
        // console.log(to);
        this.updateLicenseInfo(); // 사용자 결제정보 상시 업데이트
        const default_list = Array(5).fill(false);
        if (
          to.name != "Main" &&
          to.path != "/subscription_service" &&
          to.path != "/user_payment_check" &&
          to.path != "/product_pay" &&
          to.path != "/subscription_payment_method"
        ) {
          this.show_landing_menu = false;
          this.payment_active = false;
          if (
            to.path == "/basic_settings" ||
            to.path == "/publishing" ||
            to.path == "/team_settings"
          ) {
            // this.menu_active = [true, false, false, false, false]
            default_list[0] = true;
            this.menu_active = default_list;
          }
          if (
            to.path == "/get_gri_recommendations" ||
            to.path == "/get_sasb_recommendations" ||
            to.path == "/get_tcfd_recommendations" ||
            to.path == "/gri_indicators" ||
            to.path == "/sasb_indicators" ||
            to.path == "/tcfd_indicators" ||
            to.path == "/view_indicators_list"
          ) {
            // this.menu_active = [false, true, false, false, false]
            default_list[1] = true;
            this.menu_active = default_list;
          }
          if (
            to.name == "Gri100" ||
            to.name == "Gri2" ||
            to.name == "MgDataTables" ||
            to.name == "MgDocuments" ||
            to.name == "MgBusinessCases" ||
            to.name ==  "WriteTcfd" ||
            to.name == "WriteSasb" ||
            to.name == "MgCharts" ||
            to.name == "WriteDataCenters"
          ) {
            // this.menu_active = [false, false, true, false, false]
            default_list[2] = true;
            this.menu_active = default_list;

            if (to.name === "MgDataTables" || to.name === 'WriteDataCenters' || to.name === 'WriteTcfd' || to.name === 'WriteSasb' || to.name === 'Gri2') {
              this.selected_indicator = 'write_report'

              if (to.name === 'WriteSasb') {
                this.set_standard_mode('SASB')
              } else if (to.name === 'WriteTcfd') {
                this.set_standard_mode('TCFD')
              } else if (to.name === 'Gri2') {
                this.set_standard_mode('GRI')
              }

              
            } else if (to.name === "MgDataTables") {
              this.selected_indicator='mng_dt'
              
            } else if (to.name === "MgDocuments") {
              this.selected_indicator='mng_doc'
            } else if (to.name === "MgBusinessCases") {
              this.selected_indicator='mng_bsc'
            } else if (to.name === "MgCharts") {
              this.selected_indicator='mng_charts'
            } 
          }
          if (
            to.path == "/check_report" ||
            to.path == "/apply_assurance" ||
            to.path == "/co_verification" ||
            to.path == "/get_assurance_report"
          ) {
            // this.menu_active = [false, false, false, true, false]
            default_list[3] = true;
            this.menu_active = default_list;
          }

          if (to.name === "BasicTranslateSet" ||
            to.name === "ESasb" || to.name === "ETcfd" ||
            to.name === "EDataCenter" || to.name === "EToC" ||
            to.name === "DataCenterTranslateSet" ||
            to.name === "GriTranslateSet" || 
            to.name === "TcfdTranslateSet"
          ) {
            // this.menu_active = [false, false, false, false, true]
            if (to.name === "BasicTranslateSet") {
              this.set_standard_mode('')
            }
            default_list[4] = true;
            this.menu_active = default_list;
          }
          
          if (to.name == "TestPayment") {
            default_list[5] = true;
            this.menu_active = default_list;
          }
        } else if (to.path == "/subscription_service") {
          this.show_landing_menu = true;
          this.payment_menu = [true, false, false, false];
          this.payment_active = true;
        } else if (to.path == "/subscription_payment_method") {
          this.show_landing_menu = true;
          this.payment_menu = [false, true, false, false];
          this.payment_active = true;
        } else if (to.path == "/user_payment_check") {
          this.show_landing_menu = true;
          this.payment_menu = [false, false, true, false];
          this.payment_active = true;
        } else if (to.path == "/product_pay") {
          this.show_landing_menu = true;
          this.payment_menu = [false, false, false, true];
          this.payment_active = true;
        } else this.payment_active = false;
      },
    },
  },
  updated() {
    // console.log(this.selected_report);
  },
  components: {
    UserInfo,
    LogAndNotice,
    IndicatorsMaster,
    SelectGriIndicators
  },
};
</script>

<style>
.btn-bottom-circle {
  position: relative;
}
.btn-bottom-circle::after {
  position: absolute;
  bottom: -10px;
  left: 5px;
  width: calc(100% - 10px);
  height: 3px;
  /* border-radius: 4px; */
  background: var(--tealgray600);
  content: "";
}

#header-menu.border-top > div > .btn-icon-gray {
  color: var(--gray-300);
}
#header-menu.border-top > div > .btn-icon-gray:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
#notice-sidebar > header > button {
  transition: all 0.1s;
}
#notice-sidebar > header > button:hover {
  color: var(--teal) !important;
}


.mng-nav {
  display: inline-block;
}
.mng-nav ol {
  border-radius: 20px;
  
}
.mng-nav ol li span,
.mng-nav ol li a,
.mng-nav ol li::before{
 font-size: 1.05rem;
}
.mng-nav ol li.active {
  font-weight: 700;
  color: var(--gray-800)
}
/* 
.activity-log-group{
    left: 0;
    top: 100%;
    margin-top: 10px;
    width: 300px;
    height: auto;
    max-height: 
    
} */
</style>

<template>
  <div>
    <div v-if="has_background_image" :class="backgroundClass" :style="main_bg_color_gradient"></div>

    <b-navbar :class="['cover-navbar', { 'has-background-image': has_background_image, 'scrolled': isScrolled }]"
      variant="none">
      <b-container class="px-0">
        <b-navbar-brand v-if="selected_report.logo_path" @click="handleLogoClick">
          <b-img v-if="!theme_option.is_dark_option" :src="logoUrl" class="report-logo-sm logo-max-height"
            style="cursor: pointer;" />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="d-none d-sm-flex">
            <b-nav-item v-for="(table, index) in tables" :key="index" :class="{ 'button-active': table.active }"
              :variant="table.active ? 'dark-border-selected' : 'dark-border'" class="ml-3 item-button"
              @click="toggleTable(table.name)">
              <b-nav-text> {{ table.name }} </b-nav-text>
            </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto d-none d-md-flex">
            <b-nav-item v-if="theme_option.show_printing_btn && !selected_report.sample_report"
              v-print="'#full-report-preview'" v-b-tooltip.hover :title="printTitle" class="mr-2">
              <fa icon="print" size="lg" :style="{ color: iconColor }" />
            </b-nav-item>
            <b-nav-item v-if="theme_option.show_translation_btn" @click="toggleLang" v-b-tooltip.hover
              :title="translationTitle" class="mr-2">
              <fa icon="language" size="lg" :style="{ color: iconColor }" />
            </b-nav-item>
            <b-nav-item v-if="view_mode !== 'viewer'" @click="$router.push({ name: 'CheckReport' })" v-b-tooltip.hover
              title="미리보기 닫기" class="mr-2">
              <fa icon="times" size="lg" :style="{ color: iconColor }" />
            </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="d-sm-none">
            <b-dropdown no-caret variant="none">
              <template #button-content>
                <fa icon="list" size="lg" :style="{ color: iconColor }" />
              </template>
              <b-dropdown-item v-for="(table, index) in tables" :key="index" :class="{ 'button-active': table.active }"
                @click="toggleTable(table.name)">
                {{ table.name }}
              </b-dropdown-item>
            </b-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto d-md-none">
            <b-dropdown right no-caret variant="none">
              <template #button-content>
                <fa icon="list" size="lg" :style="{ color: iconColor }" />
              </template>
              <b-dropdown-item v-if="theme_option.show_printing_btn && !selected_report.sample_report"
                v-print="'#full-report-preview'" v-b-tooltip.hover :title="printTitle">
                <fa icon="print" size="lg" :style="{ color: iconColor }" /> {{ printTitle }}
              </b-dropdown-item>
              <b-dropdown-item v-if="theme_option.show_translation_btn" @click="toggleLang" v-b-tooltip.hover
                :title="translationTitle">
                <fa icon="language" size="lg" :style="{ color: iconColor }" /> {{ translationTitle }}
              </b-dropdown-item>
              <b-dropdown-item v-if="view_mode !== 'viewer'" @click="$router.push({ name: 'CheckReport' })"
                v-b-tooltip.hover title="미리보기 닫기">
                <fa icon="times" size="lg" :style="{ color: iconColor }" /> 미리보기 닫기
              </b-dropdown-item>
            </b-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>

    <b-container>
      <div>
        <div v-if="cover" class="main-cover" :style="backgroundStyle">
          <h1 :style="titleStyle">
            {{ lang !== 'ENG' ? selected_report.report_name : selected_report.report_name_e }}
          </h1>
        </div>
        <div v-else class="sub-cover" :style="{ color: dynamicTextColor }" ref="subCover">
          <h1 :style="titleStyle">
            {{ reportTitle }}
          </h1>
        </div>
      </div>
    </b-container>

    <contents-list-new :web_view="false" :preview_mode="true" :content_list_open="true" v-if="isContentsListVisible"
      :categoryType="categoryType"></contents-list-new>

    <div v-if="cover" class="main-under">
      <b-container class="px-0">
        <div class="button-container">
          <div v-for="(table, index) in tables" :key="index" @click="toggleTable(table.name)"
            :class="{ 'button-active': table.active, 'table-button': true }">
            <h4 class="navbar-text" :style="{ color: dynamicTextColor }"
              :class="{ 'has-background-image': has_background_image }"> {{ table.name }} <b-icon icon="chevron-right"
                class="ml-2"></b-icon> </h4>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import ContentsListNew from './contents_list_new/ContentsListNew.vue';

export default {
  name: 'ViewerHeaderCover',
  props: {
    theme_option: {
      type: Object,
      default: () => ({})
    },
    dark_logo_url: {
      type: [String, Boolean],
      default: null
    },
    main_bg_color_gradient: {
      type: [Object, String],
      default: () => ({})
    },
    content_list_open: {
      type: Boolean,
      default: false
    },
    active_gri_table: {
      type: Boolean,
      default: false,
    },
    active_sasb_table: {
      type: Boolean,
      default: false,
    },
    active_tcfd_table: {
      type: Boolean,
      default: false,
    },
    active_dc_table: {
      type: Boolean,
      default: false,
    },
    show_gri_table: {
      type: Boolean,
      default: false,
    },
    show_sasb_table: {
      type: Boolean,
      default: false,
    },
    show_tcfd_table: {
      type: Boolean,
      default: false,
    },
    show_dc_table: {
      type: Boolean,
      default: false
    },
  },
  components: {
    ContentsListNew,
  },
  data() {
    return {
      isContentsListVisible: false,
      cover: true,
      isScrolled: false,
      categoryType: ''  // Default category type
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
    ...mapGetters('report_list', ['selected_report']),
    main_color() {
      return this.selected_report.main_color;
    },
    has_background_image() {
      return !!this.main_bg_color_gradient['background-image'];
    },
    dynamicTextColor() {
      return this.has_background_image ? '#FFFFFF' : 'black/40';
    },
    backgroundStyle() {
      return this.has_background_image
        ? {
          backgroundImage: `url(${this.main_bg_color_gradient['background-image']})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
        : { backgroundColor: 'transparent' };
    },
    titleStyle() {
      return `font-size: ${this.theme_option.title_size * 1.25}rem; font-family:${this.selected_report.font_family}; color: ${this.dynamicTextColor};`;
    },
    backgroundClass() {
      return this.cover ? 'report-basic-header-bg background-img' : 'report-basic-header-bg2 background-img';
    },
    logoUrl() {
      return this.theme_option.is_dark_option ? this.dark_logo_url : `${this.$store.state.static_host}${this.selected_report.logo_path}`;
    },
    iconColor() {
      if (this.isScrolled) {
        return 'black';
      }
      return this.has_background_image ? '#FFFFFF' : 'black/40';
    },
    printTitle() {
      return this.lang !== 'ENG' ? '출력하기' : 'Print to PDF';
    },
    translationTitle() {
      return this.lang !== 'ENG' ? '영문보기' : 'Korean';
    },
    tables() {
      return [
        { name: 'GRI', active: this.show_gri_table },
        { name: 'SASB', active: this.show_sasb_table },
        { name: 'TCFD', active: this.show_tcfd_table },
        { name: 'Data Center', active: this.show_dc_table }
      ];
    },
    activeTable() {
      return this.tables.find(table => table.active)?.name;
    },
    reportTitle() {
      const tParam = this.$route.query.t ? this.$route.query.t.toLowerCase().replace(/%20/g, ' ') : '';
      switch (tParam) {
        case 'gri':
          return 'GRI';
        case 'sasb':
          return 'SASB';
        case 'tcfd':
          return 'TCFD';
        case 'data center':
          return 'Data Center';
        default:
          return '';
      }
    }
  },
  methods: {
    ...mapMutations('report_list', ['change_lang']),
    handleLogoClick() {
      this.setCoverTrue();
      this.$router.push({ path: this.$route.path });
    },
    setCoverTrue() {
      this.cover = true;
      this.tables.forEach(table => table.active = false);
      this.isContentsListVisible = false;
    },
    toggleTable(tableName) {
      this.cover = false;
      this.tables.forEach(table => {
        table.active = table.name === tableName;
      });
      this.isContentsListVisible = true;
      this.categoryType = tableName.toLowerCase();
      this.$router.push({
        query: { ...this.$route.query, t: this.categoryType }
      });
      this.$emit('showTableView', tableName);
    },
    toggleLang() {
      this.change_lang(this.lang === 'KOR' ? 'ENG' : 'KOR');
    },
    toggleContentsList() {
      this.isContentsListVisible = !this.isContentsListVisible;
    },
    handleScroll() {
      if (this.$refs.subCover) {
        this.isScrolled = window.scrollY > this.$refs.subCover.clientHeight;
      }
    },
  },
  watch: {
    selected_report(newVal) {
      if (newVal) {
        this.setCoverTrue();
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.report-basic-header-bg,
.report-basic-header-bg2 {
  animation: zoomBackground 40s infinite;
  background-size: cover;
  background-position: center;
}

.container {
  max-width: 1200px;
}

.report-basic-header {

  &-bg,
  &-bg2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  &-bg {
    height: 100vh;
  }

  &-bg2 {
    height: 40vh;
  }
}

.cover-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 0;
  z-index: 999;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;

  &.scrolled {
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid lightgray;

    .navbar-text,
    .b-nav-text,
    .fa,
    .b-nav-item {
      color: black !important;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: black;
  }

  &.has-background-image {

    .navbar-text,
    .b-nav-text {
      color: white;
    }

    .b-nav-item:hover,
    .b-nav-item:focus,
    .b-nav-item:active {
      background-color: rgba(255, 255, 255, 0.7);
    }

    &::after {
      background-color: white;
    }
  }
}

.navbar-text {
  font-size: 1.25rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.4);

  :hover,
  :focus,
  :active {
    color: rgba(0, 0, 0, 0.7);
  }

  .has-background-image & {
    color: white;

    :hover,
    :focus,
    :active {
      color: white;
    }
  }
}

.item-button {

  :hover,
  :focus,
  :active {
    color: rgba(0, 0, 0, 0.7);
  }
}

.logo-max-height {
  max-height: 60px;
}

.main-cover,
.sub-cover {
  padding: 0;
  display: flex;
  align-items: center;
  background-color: transparent;

  &.sub-cover {
    height: 40vh;
  }

  &.main-cover {
    height: 90vh;
  }
}

.main-under {
  height: 10vh;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: transparent;

  .navbar-text {
    font-size: 1.25rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.4);

    :hover,
    :focus,
    :active {
      color: rgba(0, 0, 0, 0.7);
    }

    .has-background-image & {
      color: white !important;

      :hover,
      :focus,
      :active {
        color: white !important;
      }
    }
  }
}


.button-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 575.98px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: 576px) {
    flex-direction: row;
  }
}

.table-button {
  flex: 1 1 calc(50% - 10px);
  height: 8vh;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;

  &:hover {
    background-color: #e0e0e030;
  }

  .has-background-image & {
    color: white;

    &:hover {
      color: white;
    }
  }

  @media (min-width: 576px) {
    flex: none;
    width: 25%;
    margin-bottom: 0;
  }
}
</style>

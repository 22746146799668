<template>
  <div>
    <b-overlay :show="loadingShow" no-wrap></b-overlay>

    <!-- {{ cancelReasonDesc }} -->
    <!-- {{ selectedSubscriptionHistory }} -->
    <b-modal
      v-if="selectedSubscriptionHistory"
      id="cancelSubscription-modal" 
      ref="cancelSubscription-modal" 
      title="결제취소(아직 개발 중)"
      hide-footer
    >
     
      <b-form @submit="submitCancelSubscriptionHistory" @reset="resetCancelSubscriptionHistory" v-if="show">
        <b-form-group
          id="input-group-1"
          label="결제 취소 사유:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="cancelSubscriptionForm.cancelReasonDesc"
            type="text"
            placeholder="결제 취소 사유 (어드민님이 알아서 넣어주세요)"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="결제 취소 금액:"
          label-for="input-2"
          description="결제 취소 금액을 정확히 입력해주세요!** 전액취소를 선택하고 그보다 낮은 금액을 입력하면 입력 값을 우선으로 환불됩니다***"
        >
          <b-form-checkbox
            v-model="refundAll"
            >전액 취소</b-form-checkbox
          >
          <b-form-checkbox v-model="cancelSubscriptionForm.refundWithLicense"
            >정기결제권 취소(해당 회원의 현재 사용 중인 정기결제권까지
            취소됩니다)</b-form-checkbox
          >
          <b-form-input
            id="input-2"
            v-model="cancelSubscriptionForm.refundAmount"
            placeholder="결제 금액을 넣어주세요"
            type="number"
            required
            :disabled="refundAll"
          ></b-form-input>
        </b-form-group>

        <br /><br />

        <div>
          <!-- {{ selectedSubscriptionHistory }} -->
          {{ selectedSubscriptionHistory.user_id }}님의
          {{ selectedSubscriptionHistory.service_name }} ({{
            formatCurrency(selectedSubscriptionHistory.amount_price)
          }}원) 결제 내역을 취소하시겠습니까?

          <p v-if="cancelSubscriptionForm.refundAmount">
            ({{ cancelSubscriptionForm.refundAmount }} 원이
            <b>
              {{
                Number(cancelSubscriptionForm.refundAmount) ===
                selectedSubscriptionHistory.amount_price
                  ? "전액환불"
                  : "부분환불"
              }}
            </b>
            됩니다.)
          </p>
        </div>
        전액취소: {{ refundAll }} <br />
        현재 사용 중인 정기결제까지 취소: {{ cancelSubscriptionForm.refundWithLicense }} <br><br>

        <pre>
        {{ cancelSubscriptionForm }}
        </pre>

        <br /><br />

        <b-button type="submit" variant="primary">환불시키기</b-button>
        <b-button type="reset" variant="danger">내용리셋</b-button>
      </b-form>
    </b-modal>

    <template>
      <div>
        <b-modal
          v-model="isReceiptOpen"
          title="매출 전표"
          size="lg"
          no-scrolling
          hide-footer
        >
          <iframe
            :src="receiptUrl"
            width="100%"
            height="700px"
            overflow-y="hidden"
          ></iframe>
        </b-modal>
      </div>
    </template>

    <h3>정기 결제 내역</h3>

    <b-container fluid>
      <!-- Main table element -->
      <b-table
        :items="subscriptingPayHistory"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
        hover
        empty-filtered-html="검색 결과가 없습니다 😥"
        empty-text="결제 내역이 없습니다."
        tbody-tr-class="payment-row"
      >
        <template #table-caption>
          총
          <span v-if="subscriptionTotalRows !== null" class="mb-0 red">{{
            subscriptionTotalRows
          }}</span>
          건의 내역이 조회됨
        </template>

        <template #cell(amount_price)="row">
          {{ row.value }}
        </template>

        <!-- <template #cell(discount_code)="row">
          <span v-if="row.value !== ''">
            <b-input-group style="width:150px;">
              <b-form-input :placeholder="row.value" :value="row.value"></b-form-input>

              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="applyDiscount_code"
                  class="addDiscount"
                  ><b-icon
                    icon="check-circle"
                    scale="1"
                    variant="primary"
                  ></b-icon
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </span>

          <div v-else>
            <b-icon icon="x-circle" scale="1" variant="secondary"></b-icon>
          </div>
        </template> -->

        <!--

          <template #cell(payment_status)="row">
            <b-icon
            v-if="row.value == true"
            icon="check-circle"
            scale="1.5"
            variant="success"
            ></b-icon>
            <b-icon
            v-else
            icon="exclamation-circle-fill"
            scale="1.5"
            variant="danger"
            animation="throb"
            ></b-icon>
          </template>
        -->

        <template #cell(actions)="row">
          <!-- <b-button size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? "창 닫기" : "세부보기" }}
          </b-button> -->

          <b-button
            size="sm"
            variant="primary"
            @click="
              showReceipt(
                row.item.subscription_pay_history_id,
                row.item.payment_status
              )
            "
            >매출전표</b-button
          >

          <b-button
            size="sm"
            variant="danger"
            v-if="row.item.pay_method_content && row.item.payment_status===1"
            v-b-modal.cancelSubscription-modal
            @click="selectCancelInfo(row.item)"
          >
            결제취소
          </b-button>
        </template>

        <template #row-details="row">
          {{ row.childProduct }}

          <b-card>
            <!-- Detail 부분 -->
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
            <!-- <b-table-simple>
              <b-thead>
                <b-tr>
                  <b-th>서비스명</b-th>
                  <b-th>결제상태</b-th>
                  <b-th>가격</b-th>
                  <b-th>할인코드</b-th>

                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr variant="info">
                  <b-th sticky-column>{{ row.item.service_name }}</b-th>
                  <b-td>{{ row.item.payment_status }}</b-td>
                  <b-td>{{ row.item.amount_price }}</b-td>
                  <b-td>{{ row.item.discount_code }}</b-td>
                </b-tr>
                <b-tr v-for="(value, key) in row.item.childProduct" :key="key">
                  <b-td>{{ value.service_name }}</b-td>
                  <b-td>{{ value.paymentSatus }}</b-td>
                  <b-td>{{ value.amount_price }}</b-td>
                  <b-td>-</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple> -->
            <b-button variant="outline-info">수정하기</b-button>
          </b-card>
        </template>
      </b-table>

      <!-- Info modal -->
      <b-modal
        :id="infoModal.id"
        :title="infoModal.title"
        ok-only
        @hide="resetInfoModal"
      >
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <!-- User Interface controls -->
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="subscriptionTotalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { formatCurrency } from "@/components/payment/payment";
// import requestWithQESG from "@/components/customAxios";

export default {
  computed: {
    ...mapState("admin", ["report_list", "report_code"]),
  },

  data() {
    return {
      refundAll: false,
      cancelSubscriptionForm: {
        merchantUid: "",
        cancelReasonDesc: "",
        refundAmount: "0",
        refundWithLicense: false
      },
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      show: true,
      selectedSubscriptionHistory: null,
      cancelSubscriptionPrompt: false,
      cancelReasonDesc: null,
      isReceiptOpen: false,
      receiptUrl: "",
      isToggled: false,
      subscriptingPayHistory: [],
      fields: [
        {
          key: "paid_time",
          label: "결제시각",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },

        {
          key: "user_id",
          label: "사용자아이디",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "service_name",
          label: "상품명",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "amount_price",
          label: "상품가격",
          sortable: true,
          class: "text-center",
          formatter: (value) => {
            return formatCurrency(value) + "원";
          },
        },
        {
          key: "discount_code",
          label: "할인코드",
          sortable: true,
          class: "text-center",
        },
        {
          key: "payment_status",
          label: "지불여부",
          formatter: (value, key, item) => {
            if (value === 0) return "결제실패";
            else if (value === 1) return "결제성공";
            else if (value === 2) return "결제대기";
            else if (value === 3) return "결제취소";
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
        {
          key: "pay_method_content",
          label: "결제수단",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
        {
          key: "subscription_pay_history_id",
          label: "merchant_uid",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
        {
          key: "cancelled_reason",
          label: "취소사유",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
      ],
      subscriptionTotalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loadingShow: true,
    };
  },
  watch: {
    refundAll (val) {
      if (val && this.selectedSubscriptionHistory) {
        // this.cancelSubscriptionForm.refundAmount = this.selectedSubscriptionHistory.amount_price;
        this.cancelSubscriptionForm.refundAmount = String(this.selectedSubscriptionHistory.amount_price)
      } 



    },
  },
  computed: {
    buttonIcon() {
      return this.isToggled ? "toggle-on" : "toggle-off";
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.fetchSubscriptionPayHistory();
    this.subscriptionTotalRows = this.subscriptingPayHistory.length;

    const table_rows = document.querySelectorAll(".payment-row");
    table_rows.forEach((element) => {
      element.addEventListener("click", this.openRowDetail);
    });

    // 테이블 데이터 fetch callback에 넣어주기
    this.loadingShow = false;
  },
  methods: {
    submitCancelSubscriptionHistory(event) {
      event.preventDefault();
      this.showModal = true;

      const path =
        this.$store.state.backend_host + "/payment/cancelSubscriptionPaid";

      axios
        .post(path, this.cancelSubscriptionForm)
        .then((response) => {
          // this.updateLicenseInfo();
          if (response.data.code === true) {
            window.alert("해당 내역에 대한 결제가 취소되었습니다.")
            this.$refs['cancelSubscription-modal'].hide()
            this.fetchSubscriptionPayHistory();

          } else {
            window.alert(response.data.message)
          }

          this.showModal = false;
          
        })
        .catch((error) => {
          window.alert(error);
        });

    },
    resetCancelSubscriptionHistory(event) {
      event.preventDefault();
      // Reset our form values
      this.cancelSubscriptionForm.cancelReasonDesc = "";
      this.cancelSubscriptionForm.refundAmount = 0;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    formatCurrency,
    selectCancelInfo(rowInfo) {
      this.refundAll = false;
      this.cancelSubscriptionForm.cancelReasonDesc = "";
      this.cancelSubscriptionForm.refundAmount = 0;
      this.cancelSubscriptionForm.refundWithLicense = false;
      this.cancelSubscriptionForm.merchantUid = rowInfo.subscription_pay_history_id
      this.selectedSubscriptionHistory = rowInfo;
    },
    // cancelSubscriptionPayment() {
    //   // this.cancelReasonDesc
    //   console.log("test");
    //   const path =
    //     this.$store.state.backend_host + "/payment/cancelSubscriptionPaid";

    //   axios
    //     .post(path, {
    //       merchant_uid: this.selectedSubscriptionHistory
    //         .subscription_pay_history_id,
    //       cancelReason: this.cancelReasonDesc,
    //     })
    //     .then((response) => {
    //       // this.updateLicenseInfo();
    //       this.showModal = true;
    //       window.alert(response.data?.message);
    //       this.$router.push("/main");
    //     })
    //     .catch((error) => {
    //       window.alert(error);
    //     });
    // },
    showCancelSubscriptionPrompt() {
      this.cancelSubscriptionPrompt = !this.cancelSubscriptionPrompt;
    },
    showReceipt(payment_history_id, payment_status) {
      axios.get(
        this.$store.state.backend_host +
            `/payment/getPaymentData/pg_tid?merchant_uid=${payment_history_id}`
      ).then((response) => {
        if (response?.data?.code) {
          if (payment_status == 1) {
            this.receiptUrl = `https://iniweb.inicis.com/app/publication/apReceiptOpenMarket100.jsp?noTid=${response?.data.result}&noMerchantoid=&flag=0&noMethod=1&tStat=null&re_mail=null`;
          } else {
            this.receiptUrl = `https://iniweb.inicis.com/app/publication/apReceiptOpenMarket100.jsp?noTid=${response?.data.result}&noMerchantoid=&flag=1&noMethod=1&tStat=null&re_mail=null`;
          }

          this.isReceiptOpen = true;
          // window.open(`https://iniweb.inicis.com/app/publication/apReceiptOpenMarket100.jsp?noTid=${response?.data.result}&noMerchantoid=&flag=0&noMethod=1&tStat=null&re_mail=null`, '_blank')
        } else {
          if (response?.data?.message) {
            window.alert(response?.data?.message);
          } else {
            window.alert(
              "매출전표를 가져오지 못했습니다. 관리자에게 문의해주세요."
            );
          }
        }
      });
    },

    fetchSubscriptionPayHistory() {
      // /api/payment/get_user_subscription_paycheck_history
      // requestWithQESG("POST", "/payment/get_admin_subscription_paycheck_history")
      axios
        .post(
          this.$store.state.backend_host +
            "/payment/get_admin_subscription_paycheck_history"
        )
        .then((response) => {
          this.loadingShow = false;
          if(response.data.code){
            this.subscriptingPayHistory = response.data.result;
            this.subscriptionTotalRows = this.subscriptingPayHistory.length;
          } else {
            window.alert(response.data.msg)
            location.href = "/"

          }
        })
        .catch((error) => {
          console.error(error);
          window.alert(error.message)
          location.href = "/"

        });
    },
    toggleButton() {
      this.isToggled = !this.isToggled;
    },
    applyDiscount_code() {
      console.log("할인쿠폰 변경 적용..");
    },
    openRowDetail(e) {
      e.stopPropagation();
      let currentElement = e.target;
      if (currentElement.tagName == "BUTTON") {
        try {
          currentElement.firstChild.querySelector("button").click();
        } catch (e) {
          return null;
        }
        return;
      }
      while (currentElement && currentElement.tagName !== "TR") {
        currentElement = currentElement.parentElement;
      }

      if (currentElement && currentElement.tagName === "TR") {
        // <tr> 태그를 찾은 경우에 대한 로직을 작성합니다.
        currentElement.firstChild.querySelector("button").click();
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.subscriptionTotalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style></style>

<template>
<div>
    <h4 id="ts2">소유 권한 양도</h4>
    <p class="f-95 mb-4 gray-600">보고서 소유자 권한을 타인에게 양도합니다.</p>

    <b-card class="general-card mb-5">
        <b-card-header>소유 권한 양도하기</b-card-header>
        <div v-if="check_owner==false" class="py-3 gray-600">
            권한이 없습니다.
        </div>
        <div v-else class="py-3">
            <template v-if="is_ownership_sent==false">
                <p class="gray-600">
                    본 보고서의 소유자 권한을 타인에게 양도합니다. <br><br>
                    소유 권한 양도는 본 보고서에 대한 {{ selected_report.owner_e_mail}} 님의 권한을 모두 양도하는 것이기 때문에 양도 계획에 대해 양수자 및 팀원과 사전에 명확하게 커뮤니케이션 한 후 진행해야 합니다. 
                </p>
                <a v-b-toggle.owner-transfer class="cursor"> 소유 권한 양도 가이드 <fa icon="expand" class="ml-1"/> </a>
                <b-collapse id="owner-transfer" class="mt-2">
                    <b-card class="bgray-200 border-0">
                        <p class="fw-500 f-95 mb-1">권한 양도 범위</p>
                        <p class="f-90 gray-600">본 보고서에 대한 {{ selected_report.owner_e_mail}} 님의 소유 권한 뿐만 아니라 편집 권한까지 모두 이전 됩니다.<br>
                        소유 권한을 양도한 후에도 본 보고서를 편집하려면 소유 권한 양도 후 새로운 소유자가 {{ selected_report.owner_e_mail}}님을 팀원으로 새로 초대해야 합니다. </p>
                        <p class="fw-500 f-95 mb-1">소유자 변경이 팀에 미치는 영향</p>
                        <p class="f-90 gray-600"> 소유자가 바뀌더라도 기존 팀원은 그대로 남아있으며, 편집 권한도 동일하게 유지됩니다.</p>
                    </b-card>
                </b-collapse>

                <hr class="border-0">

                <b-row no-gutters class="hover-gray py-2 mb-3">
                    <b-col cols="3"><div class="gray-600">소유 권한 양도</div> </b-col>  
                    <b-col>
                        <b-input-group class="mb-2">
                            <b-form-input class="narrow-input-form" placeholder="이메일 입력" v-model="target.e_mail" @keyup.enter="handleSendOwnership()" @input="onInputStopped" ></b-form-input>
                            <b-input-group-append> 
                                <b-button variant="red" class="f-95" @click="handleSendOwnership()" :disabled="waiting_for_mailing==true">
                                    {{waiting_for_mailing==true?'':'권한 보내기'}} <b-spinner small v-if="waiting_for_mailing==true"></b-spinner>
                                </b-button> 
                            </b-input-group-append>
                        </b-input-group>
                        
                        <!-- 
                            셀렉트폼만 로딩바를 뜨게 하고 싶다면 이걸로 아래를 감싸면 됨
                            <div style="width: 100%; max-width: 100%; overflow: hidden; position: relative;">
                            </div> 
                        -->
                        <b-overlay :show="loadingShow" no-wrap></b-overlay>
                        <b-form-select v-if="search_user_list.length > 0" v-model="target.e_mail" :options="search_user_list" :select-size="search_user_list.length < 10 ? search_user_list.length+1 : 10">
                        </b-form-select>





                        <p class="f-85 gray-600"> <fa icon="info-circle" class="mr-1"/>소유 권한 이메일 발송은 취소할 수 없습니다. 이메일을 정확히 확인하고 진행해 주세요.</p>
                    </b-col>
                </b-row>
            </template>

            <template v-else>
                <p class="gray-600 mb-5">
                    소유 권한 양도 이메일을 보냈습니다.<br>
                    {{ selected_report.owner_e_mail }} 소유 권한 양도 이메일을 보냈습니다.<br>
                    양수자가 소유 권한을 수락할 때 까지 소유 권한은 {{ selected_report.owner_e_mail }} 님에게 있습니다. <br><br>
                    ‘권한 다시 보내기’로 다른 사람에게 새로 소유 권한 양도 이메일을 보낼 경우, 기존에 송부된 양도 이메일은 효력이 없어집니다.
                </p>

                <b-row no-gutters class="py-2 mb-3">
                    <b-col cols="3"><div class="gray-600">양도 상태</div> </b-col>  
                    <b-col>
                        <b-table-simple class="txt-table" responsive hover>
                            <b-thead><b-tr>
                                <b-td>소유 권한 발송일</b-td><b-td>양수자 이메일</b-td><b-td>상태</b-td>
                            </b-tr></b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td>{{ target.request_date }}</b-td><b-td>{{ target.e_mail }}</b-td><b-td>{{ target.accepted ? '양도완료' : '미양도' }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
                <b-row no-gutters class="hover-gray py-2 mb-5">
                    <b-col cols="3"><div class="gray-600">소유자 전환 프로세스</div> </b-col>  
                    <b-col class="gray-600">
                    1) 소유 권한 이메일 발송  → 2) 수락 → 3) 소유 권한 양도 완료
                    </b-col>
                </b-row>

                <b-button variant="red-border" class="f-95 mb-5" @click="handleCancle()">권한 다시 보내기</b-button>
            </template>


        </div>
    </b-card>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import * as ih from '@/components/util'

export default {
    data() {
        return {
            loadingShow: false,
            // target.e_mail: null,
            search_user_list: [],
            copied_coauthors: [],
            target: {
                e_mail: '',
                accepted: false,
                request_date: null
            },
            is_ownership_sent: false, // 소유권한 양도 테스트를 위한 임시 로컬 데이터이며, 실제로는 store의 report_list.js의 selected_report 내부 요소로 관리되어야 함
            waiting_for_mailing: false
        }
    },
    computed:{
        ...mapState(['user_id']),
        ...mapState('report_list', ['report_code']),
        ...mapGetters('report_list', ['selected_report']),
        check_owner(){
            if (this.selected_report.owner_id == this.user_id){
                return true
            }
            else return false
        }
    },
    methods: {
        onInputStopped() {
            // 이전에 설정한 타이머가 있다면 제거하여 중복 호출을 방지합니다.
            if (this.inputTimer) {
                clearTimeout(this.inputTimer);
            }
            // 500ms(0.5초) 후에 입력이 멈췄을 때 이벤트를 발생시킵니다.
            this.inputTimer = setTimeout(() => {
                this.inputTimer = null; // 타이머를 제거하고 초기화합니다.
                this.showAvailableUser();
            }, 500);
        },
        showAvailableUser() {
            console.log(this.target.e_mail) 

            const path = this.$store.state.backend_host + '/report/searchAvailableForOwnership?q='+ this.target.e_mail
            this.loadingShow = true
            axios.get(path)
            .then(response => {
                this.search_user_list = response.data
                this.loadingShow = false

            })
            .catch(error => {
                this.loadingShow = false
                console.log(error)
            }
                
            )

        },
        handleSendOwnership(){
            if (this.checkForm()==true){           
                this.waiting_for_mailing = true
                this.moveOwnershipRequest(this.report_code, this.target.e_mail)
                .then( response => {
                    if(response.code!='failure') {
                        this.$EventBus.$emit('make-toast', '요청 성공', `${response.data.e_mail}님에게 소유 권한 양도를 요청하였습니다.`)
                    }
                    this.waiting_for_mailing = false
                    this.checkRequest()
                })
            }
        },
        checkForm(){
            if(this.target.e_mail == '' || this.target.e_mail == ' '){
                alert('소유권한을 양도할 이메일을 입력해 주세요')
                return false
            }
            else if (this.selected_report.owner_e_mail == this.target.e_mail) {
                alert('현재 소유자 계정을 입력하였습니다. 이메일 계정을 다시 확인해 주세요.')
                return false
            }
            else {
                


                return true
            }
        },
        moveOwnershipRequest(report_code, target_e_mail){
            const path = this.$store.state.backend_host + '/move_ownership_request'
            let formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('e_mail', target_e_mail)
            return axios.post(path, formData)
            .then( response => {
                return response
            })
        },
        handleCancle(){
            this.is_ownership_sent = false
            this.cancelMoveOwnershipRequest(this.report_code)
            .then( response => {
                this.$EventBus.$emit('make-toast', '권한 양도 취소 성공', `${response.data.e_mail}님에게 발송된 소유 권한 양도 요청이 취소하였습니다.`)
                this.target = { e_mail: '', accepted: false, request_date: null }
            })
        },
        cancelMoveOwnershipRequest(report_code){
            const path = this.$store.state.backend_host + '/cancel_move_ownership_request'
            let formData = new FormData()
            formData.append('report_code', report_code)
            return axios.post(path, formData)
            .then( response => {
                return response
            })
        },
        checkRequest(){
            if( this.user_id == this.selected_report.owner_id ){
                const path = this.$store.state.backend_host + '/ownership_request_check'
                return axios.get(path, {params: { report_code: this.report_code }})
                .then( response => {
                    if( response.data.code == 'failure' ){
                        console.log(response.data.msg)
                    } else {
                        this.target = response.data
                        // console.log(this.target)
                        this.is_ownership_sent = true
                    }
                })
            }
        }    
    },
    created(){
        this.copied_coauthors = ih.deepCopy(this.selected_report.coauthors)
        this.checkRequest() // owner id == user id 일 때 현재 상황 check
    }
}
</script>
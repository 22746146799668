<template>
<div>
    <div role="tablist">
        <b-button variant="tealgray" block class="text-left mb-2" v-b-toggle.notices role="tab"> 공지사항</b-button>
        <b-collapse id="notices" role="tabpanel" accordion="my-accordion">
            <template v-if="activity_logs_loaded==true">
                <!--공지사항 없을 때, <b-card v-if="local_notice_list.length<1" no-body class="px-3 py-2 w-100 f-85 mb-3">-->
                    <!-- v-if="local_notice_list.length === 0"  -->
                <b-card 
                    no-body 
                    class="px-3 py-2 w-100 f-85 mb-3"
                >
                    등록된 공지가 없습니다. 
                </b-card>
                
                <!--공지사항 있을 때, -->
                <!-- <b-card 
                    v-else 
                    v-for="notice in local_notice_list" 
                    :key="notice.index" 
                    no-body 
                    class="px-3 py-2 w-100 mb-3" 
                    :border-variant="notice.has_read === true? '': 'red'" 
                >
                    <div class="f-85" v-b-toggle="'n'+notice.index"> {{ notice.title }} <br/>
                    <span class="f-85"> {{ notice.time_stamp }}</span></div>
                    <b-collapse :id="'n'+notice.index">
                        <p class="f-85 mt-2"> {{ notice.content }} </p>
                        <div class="d-flex justify-content-between align-items-center">
                            <b-button size="sm" v-b-tooltip.hover :title="getReadState(notice.has_read)" variant="white" @click="handleNoticeRead(notice.notice_id, notice.has_read)" :disabled="notice.has_read==true"> <fa v-if="notice.has_read!=true" icon="envelope" /></b-button>
                        </div>
                    </b-collapse>
                </b-card> -->
            </template>
        </b-collapse>
        <b-button variant="tealgray" block class="text-left mb-2" v-b-toggle.activity-logs role="tab"> 활동 기록 </b-button>
        <b-collapse id="activity-logs" visible role="tabpanel" accordion="my-accordion">
            <template v-if="activity_logs_loaded==true">
                <b-card v-for="log in activity_logs" :key="log.id" no-body class="px-3 py-2 w-100">
                    <p class="f-85 "> {{ log.activity}} </p>
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="f-85 mb-0"> {{ log.time_stamp }}</p>
                        <div v-b-tooltip.hover :title="getReportName(log.report_code)" class="px-1" :style="`color:${getMainColor(log.report_code)}`"> <fa icon="bookmark" /></div>
                    </div>
                </b-card>
            </template>
        </b-collapse>
    </div>
</div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
    data() {
        return {
            // local_notice_list: [
            //    {
            //         index: 0,
            //         notice_id: 28,
            //         title: "notice제목",
            //         content: "notice내용",
            //         sender_email: "보낸사람",
            //         time_stamp: "수신일",
            //         new: true  // 읽음 처리하면 false가 됨.
            //    }
            // ],
        }
    },
    computed:{
        ...mapState('report_list', ['report_code', 'report_list']),
        ...mapState('logs', ['activity_logs', 'activity_logs_loaded', 'notice_list', 'new_notice']),
        ...mapState(['user_id']),
        local_notice_list(){
            return this.notice_list
        }
    },
    methods:{
        ...mapActions('logs', ['readNoticesByUser', 'makeNoticeRead']),
        getReportName(report_code){
            let idx = this.report_list.findIndex(x => x.report_code == report_code)
            if(idx > -1){
                return this.report_list[idx].report_name
            }
        },
        getMainColor(report_code){
            let idx = this.report_list.findIndex(x => x.report_code == report_code)
            if(idx > -1){
                return this.report_list[idx].main_color
            }
        },
        // 읽음 tooltip
        getReadState(has_read){
            if(has_read == false){
                return "읽음 처리하기"
            }
        },
        // 공지사항 읽음 처리
        handleNoticeRead(notice_id, has_read){
            this.makeNoticeRead({notice_id: notice_id, user_id: this.user_id, has_read: has_read})
        },

    },
    created(){
        // console.log(this.report_code);
    }

}
</script>

<style>

</style>
<template>
    <div 
        v-b-tooltip.hover
        title="보고를 생략하려면, 생략 사유를 선택 후 설명을 작성해주세요."
    >
    <b-form-checkbox
        v-model="nan_checked"
        @change="checkNan"
    >
        보고 생략하기
    </b-form-checkbox>
    
    <div v-if="nan_checked">
        보고를 생략하려면, 생략 사유를 선택 후 설명을 작성해주세요.
        <b-form-select v-model="selected_category" :options="nan_options" size="sm" class="mt-3" />
        
        <b-form-textarea
            v-if="selected_category"
            v-model="input_reason_contents" 
            rows="2" 
            placeholder="해당 생략 사유에 대한 설명을 작성하십시오."
            @blur="save_nan_reason" 
        />
        
        <fa v-if="save_status==='saved'" icon="check-circle" class="ml-1 green"/>
        <b-spinner v-if="save_status==='saving'" class="green" small></b-spinner>
        <b-icon
            v-if="save_status==='failed'"
            icon="x"
            scale="1.5"
            variant="danger"
            animation="fade"
        />

        <b-card v-if="!nan_category" class="bgray-200 border-0 px-2 mt-3">
            ※ 생략 사유 및 필요한 설명은 다음과 같습니다:
            <ul>
                <li>
                    해당 없음: 보고가 해당 사항이 없는 이유를 설명하십시오.
                </li>
                <li>
                    법적 금지: 보고가 법적으로 금지될 경우 이에 대해 설명하십시오.
                </li>
                <li>
                    비밀 유지 제한: 보고가 기업의 비밀에 해당하여 공개하기 어려운 경우, 이에 대해 설명하십시오.
                </li>
                <li>
                    정보 부재/ 불완전: 어떤 정보가 부재하거나 불완전한지 명시하며, 불완전한 정보에 대해 누락된 부분을 명시하십시오. 또한, 필요한 정보가 부재하거나 불완전한 이유를 설명하고, 해당 정보를 얻기 위해 진행 중인 단계와 예상되는 정보 획득 시기를 설명하십시오.
                </li>

            </ul>
            ※ 생략 사유는 다음을 제외한 모든 GRI 표준 공개에 허용됩니다:
            <ul class="f-85 fw-300 mb-0 pt-1">
                <li>
                    GRI 2-1 조직 세부사항
                </li>
                <li>
                    GRI 2-2 조직의 지속가능성 보고에 포함된 기관
                </li>
                <li>
                    GRI 2-3 보고 기간, 주기 및 문의처
                </li>
                <li>
                    GRI 2-4 정보의 수정
                </li>
                <li>
                    GRI 2-5 외부 검증
                </li>
                <li>
                    GRI 3-1 중요 주제 결정 프로세스
                </li>
                <li>
                    GRI 3-2 중요 주제 목록
                </li>
            </ul>
        </b-card>
    
        <!-- <b-card v-if="!nan_category" class="bgray-200 border-0 px-2 mt-3">
            If you want to omit reporting for this indicator, you have to choose a reason for omission and explain in detail why. <br>
            [Choose a reason for omission] <br>
            ※ The permitted reasons for omission and required explanations are the following:
            <ul>
                <li>
                    Not applicable: Explain why the disclosure or the requirement is considered not applicable.
                </li>
                <li>
                    Legal prohibitions: Describe the specific legal prohibitions.
                </li>
                <li>
                    Confidentiality constraints: Describe the specific confidentiality constraints.
                </li>
                <li>
                    Information unavailable / incomplete: Specify which information is unavailable or incomplete. When the information is incomplete, specify which part is missing (e.g., specify the entities for which the information is missing). Explain why the required information is unavailable or incomplete. Describe the steps being taken and the expected time frame to obtain the information.
                </li>

            </ul>
            ※ Reasons for omission are permitted for all disclosures from the GRI Standards except for:
            <ul class="f-85 fw-300 mb-0 pt-1">
                <li>
                    Disclosure 2-1 Organizational details
                </li>
                <li>
                    Disclosure 2-2 Entities included in the organization’s sustainability reporting
                </li>
                <li>
                    Disclosure 2-3 Reporting period, frequency and contact point
                </li>
                <li>
                    Disclosure 2-4 Restatements of information
                </li>
                <li>
                    Disclosure 2-5 External assurance
                </li>
                <li>
                    Disclosure 3-1 Process to determine material topics
                </li>
                <li>
                    Disclosure 3-2 List of material topics
                </li>
            </ul>
        </b-card> -->

    </div>





    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    name: '',
    props: {
        nan_category: { type: String },
        nan_reason_contents: { type: String },
        gri_code: { type: String },
        disclosure_id: { type: Number }



    },
    watch: {
        disclosure_id: {
            immediate: true,
            handler(newVal) {
                this.nan_checked = this.nan_category !== null ? true : false;
                this.selected_category = this.nan_category;
                this.input_reason_contents = this.nan_reason_contents;
            }
        },
    },
    components: {},
    computed: {
        ...mapState('report_list', ['report_code']),
        ...mapGetters("report_list", ["selected_report"]),

    },
    data() {
        return {
            nan_checked: this.nan_category !== null ? true : false,
            nan_options: [
                { value: null, text: '생략 사유 선택', disabled: true},
                { value: 'nan', text: '해당 없음' },
                { value: 'illegal', text: '법적 금지' },
                { value: 'non_disclosure', text: '비밀 유지 제한' },
                { value: 'incomplete_info', text: '정보 미제공 / 불완전' },
            ],
            selected_category: this.nan_category,
            input_reason_contents: this.nan_reason_contents, 



            save_status: null
    }
},
    beforeCreate() {},
    created() {
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {
        ...mapActions('report_contents', ['saveNaNDisclosure']),
        checkNan() {

            if (!this.selected_report.is_able_to_edit) {
                window.alert("정기결제가 필요합니다")
                this.nan_checked = !this.nan_checked;
                return;
            }



            if (this.nan_category !== null && this.nan_checked === false) {
                const uncheck_nan = window.confirm("해당 지표를 작성하시겠습니까?")
                if (uncheck_nan) {
                    const response =  this.saveNaNDisclosure({
                        report_code: this.report_code,
                        gri_code: this.gri_code,
                        nan_category: null,
                        nan_reason_contents: null
                    }).then((res) => {
                        if (res.data.code) {
                            this.save_status = "saved"
                            setTimeout(() => {
                                this.save_status = null
                            }, 2000)
                        } else {
                            this.save_status = "failed"
                            setTimeout(() => {
                                this.save_status = null
                            }, 3000)
                        }
                    })
                } else {
                    this.nan_checked = true
                }
            }
        },
        async save_nan_reason() {
            this.save_status = "saving"
            const response =  this.saveNaNDisclosure({
                report_code: this.report_code,
                gri_code: this.gri_code,
                nan_category: this.selected_category,
                nan_reason_contents: this.input_reason_contents
            }).then((res) => {
                if (res.data.code) {

                    this.save_status = "saved"
                    setTimeout(() => {
                        this.save_status = null
                    }, 2000)
                } else {
                    this.save_status = "failed"
                    setTimeout(() => {
                        this.save_status = null
                    }, 3000)
                }
            })
        }



    }
}
</script>
<style>
</style>
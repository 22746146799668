<template>
<div>
        <h5 class="mb-4 ">보고 기준 옵션</h5>

        <h6 class="mb-2"> 1. 핵심 부합 방법 (Core Option) </h6>
        <b-card class="mb-5">
            <p class="fw-400 f-95 mb-2"> 핵심 부합 방법(Core Option)은 보고 조직의 특성에 따른 중요 주제와 이와 관련한 사업적, 사회적 영향, 그리고 운영 현황에 대한 최소 정보에 대해 보고하고 있는지를 확인하는 요건입니다.
            </p>
            <p class="fw-400 f-95 mb-4"> 핵심 부합 방법을 충족하기 위해서는 최소 1개 이상의 중요 주제를 선택하고, 해당 중요 주제에 대해 경영접근방식(MA) 및 1개 이상의 주요 지표에 대해 보고해야 합니다. 
            </p>
            <p class="fw-400 f-95 teal7"> 본 시스템은 모든 보고서가 핵심 부합 방법을 충족할 수 있도록 기본 선택을 지원합니다.
            </p>
            <p class="f-90 mb-2 indent-sm px-2"> -  일반 공개사항 (GRI 102 - General disclosure): 핵심 부합 방법 요건 지표는 자동 선택되며, 선택을 해제할 수 없습니다. 
            </p>
            <p class="f-90 mb-0 indent-sm px-2"> -  주제 기반 공개 (GRI 200~ 400): 최소 1개 이상의 중요 주제를 선택하고, 해당 중요 주제에 대해 경영접근방식(MA) 및 1개 이상의 주요 지표에 대해 보고해야 합니다.
            </p>
        </b-card>

        <h6 class="mb-2"> 2. 포괄적 부합 방법 (Comprehensive Option) </h6>
        <b-card class="mb-4">
            <p class="fw-400 f-95 mb-2"> 포괄적 부합 방법(Comprehensive Option)은 핵심 부합 방법(Core Option)보다 더 나아간 단계로, 중요 주제와 관련한 지배구조, 조직의 전략 등에 대해 보다 광범위하게 보고해야 합니다. 
            </p>
            <p class="fw-400 f-95 mb-4"> 포괄적 부합 방법을 충족하기 위해서는 최소 1개 이상의 중요 주제를 선택하고, 해당 중요 주제의 모든 보고 지표에 대해 보고해야 합니다.
            </p>
            <p class="fw-400 f-95 teal7"> 본 시스템에서 제공하는 기본 지표 선택 외 다음 사항을 충족해야 합니다. 
            </p>
            <p class="f-90 mb-1 indent-sm px-2"> -  일반 공개사항(GRI 102 - General disclosure): GRI 102 의 모든 지표를 선택 및 보고해야 합니다. 
            </p>
            <p class="f-90 mb-0 indent-sm px-2"> -  주제기반 공개(GRI 200~ 400): 최소 1개 이상의 중요 주제를 선택하고, 해당 중요 주제의 모든 보고 지표에 대해 보고해야 합니다.
            </p>
        </b-card>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    props:{

    },
    computed:{

    },
    methods:{
 
    },
    watch:{
        
    },
    created(){
        
    }

}
</script>

<style>

</style>
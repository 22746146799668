<template>
<div>
    <b-overlay show>
        <div class="mb-4"><b-button variant="teal" disabled>서비스 추가 신청하기</b-button></div>
        <b-table-simple responsive hover class="wri-table ">
            <b-thead><b-tr>
                <b-td>선택</b-td><b-td>구분</b-td><b-td>이용 내역</b-td><b-td>이용 기간</b-td><b-td> 가격내역서 </b-td>
                <b-td  v-b-tooltip.hover title="계약서는 제3자 검증 서비스에만 생성됩니다." >계약서 <fa icon="info-circle"/> </b-td><b-td>가격</b-td><b-td class="pl-2">비용 처리 상태</b-td>
            </b-tr></b-thead>
            <b-tbody class="">
                <b-tr v-for="item in billing_list" :key="item.id">
                    <b-td> <b-form-checkbox></b-form-checkbox> </b-td>
                    <b-td> {{ getReportName(item.report_code) }}</b-td>
                    <b-td> {{ item.service_type }} </b-td>
                    <b-td> {{ item.start_date }} ~ {{ item.end_date }} </b-td>
                    <b-td> <b-button variant="tealgray" class="py-1 px-2 f-110"> <fa icon="file-invoice-dollar"/> </b-button> </b-td>
                    <b-td> <b-button v-if="item.assurance_contract_id!=null" class="py-1" @click="showContract(item)"> 계약서 </b-button> </b-td>
                    <b-td class="text-right"> {{ item.service_cost }} 원 </b-td>
                    <b-td class="pl-2"> 계산서 발행 요청 </b-td>
                </b-tr>
                <b-tr class="bg-tealgray150">
                    <b-td class="border-0"></b-td>
                    <b-td colspan="5" class="f-110 fw-500 teal8 border-0"> Total </b-td>
                    <b-td class="text-right f-110 fw-500 teal8 border-0"> {{ get_total_cost }} 원 </b-td>
                    <b-td class="border-0"></b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-card class="mt-5">
            <div class="mb-3"> 선택한 이용 내역에 대해  </div>
            <b-button class="mr-3" disabled> 세금계산서 발행 요청하기 </b-button>
            <b-button disabled> 서비스 이용 취소하기 </b-button>
        </b-card>
        <template #overlay>
            <h6>서비스 준비중 입니다.</h6>
            가격 및 결제 관련 문의사항이 있으시면 <a href="mailto:info@qesg.co.kr">info@qesg.co.kr</a> 또는 <b-button v-b-modal.need-help class="f-80 px-2 py-1" variant="tealgray" v-b-tooltip.hover title="도움이 필요하신가요?"><fa icon="question"/></b-button> 를 통해 문의해 주세요<div class=""></div>
        </template>
    </b-overlay>
    
    <b-modal id="contract-modal" hide-footer size="lg" scrollable title="검증 전자계약서">
        <make-contract></make-contract>
    </b-modal>

    <b-modal id="need-help" hide-footer size="" title="문의하기" scrollable >
        <need-help></need-help>
    </b-modal>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import MakeContract from '@/components/payment/payment_subs/MakeContract'

export default {
    data() {
        return {
            
            
        }
    },
    computed:{
        ...mapState('billings', ['billing_list']),
        ...mapState('report_list', ['report_code', 'report_list']),
        get_total_cost(){
            let temp_cost_arr = []
            this.billing_list.forEach(x => {
                if( x.payment!= true ) { 
                    temp_cost_arr.push(x.service_cost) 
                }                    
            })
        }

    },
    methods:{
        getReportName(report_code){
            let idx = this.report_list.findIndex(x => x.report_code == report_code)
            if(idx > -1){
                return this.report_list[idx].report_name
            }
        },
        showContract(billing_item){
            alert('아직 가격내역서가 생성되지 않았습니다.')
        }
    },
    mounted(){
        console.log(this.billing_list);
    },
    components:{
        MakeContract
    }

}
</script>

<style>

</style>
//"선택"한 보고서에 대한 indicators, topics 설정 읽어오기
//리포트의 중요주제, 지표 선택 상태는 이곳을 업데이트 해야 함

const report_indicators = {
    namespaced: true, 
    state: {
        indicators_list: [
            // {
            //     "index": 0,
            //     "gri_code": "404-3",
            //     "type": "core",
            //     "topic_id": 404,
            //     "indicator_name": "업무성과 및 경력개발에 대한 정기적인 검토를 받은 근로자 비율(성별, 근로자 범주별)",
            //     "indicator_name_e": "Percentage of employees receiving regular performance and career development reviews",
            //     "indicator_description": "보고기간 동안 업무성과 및 경력개발에 대한 정기적인 검토를 받은 근로자 비율을 성별, 근로자 범주별로 보고한다.",
            //     "indicator_description_e": "The reporting organization shall report the following information: ",
            //     "indicator_requirement": "",
            //     "indicator_requirement_e": "",
            //     "indicator_recommends": "",
            //     "indicator_recommends_e": "",
            //     "indicator_guidance": "",
            //     "indicator_guidance_e": "",
            //     "topic_name": "훈련 및 교육",
            //     "topic_name_e": "Training and Education",
            //     "topic_description": null,
            //     "selected": 0,
            //     "report_code": "r13"
            // }
        ], 
        topics_list: [
            // {
            //     "index": 0,
            //     "report_code": "r13",
            //     "topic_id": 100,
            //     "topic_name": "공통",
            //     "topic_name_e": "common",
            //     "topic_description": null,
            //     "selected": true,
            //     "material": false
            // },
        ],
        indicators_list_loaded: false,
        selected_contents: [],
        selected_contents_loaded: false,
        gri_option_status: {},
        gri_option_status_loaded: false
    },
    mutations: {
        update_topics_list(state, new_topics_list){
            state.topics_list = new_topics_list
        },
        update_indicators_list(state, new_indicators_list){
            state.indicators_list = new_indicators_list
        }, 
        update_report_topic_relation(state, { report_code: report_code, updated_relation: updated_relation }){
            let temp_topics_list = [ ...state.topics_list ]
            //0. updated_relation을 찾아다니면서 topics_list를 업데이트 함
            temp_topics_list.forEach( topic => {
                if(topic.report_code==report_code && updated_relation.hasOwnProperty(topic.topic_id)){
                    topic.selected = updated_relation[topic.topic_id][0] // hash 첫번째 아이템이 selected flag
                    topic.material = updated_relation[topic.topic_id][1] // hash 두번째 아이템이 material flag
                }
            })
            //인식시킴
            state.topics_list = temp_topics_list
        },
        update_report_indicator_relation(state, {report_code: report_code, updated_relation: updated_relation }){
            let temp_indicators_list = [ ...state.indicators_list ]
            //0. updated_relation을 찾아다니면서 indicators_list를 업데이트 함. 
            // console.log(updated_relation);
            for (let [key, value] of Object.entries(updated_relation)){
                let idx = temp_indicators_list.findIndex( item => item.gri_code == key && item.report_code == report_code )
                if (idx > -1){
                    if (value ==true){ value = 1 
                    }else value = 0                    
                    temp_indicators_list[idx].selected = value
                }
            }
            //인식시킴
            state.indicators_list = temp_indicators_list
        },
        update_indicators_list_loaded(state, flag){
            state.indicators_list_loaded = flag
        },
        update_selected_contents(state, new_selected_list){
            state.selected_contents = new_selected_list
        },
        update_selected_contents_loaded(state, flag){
            state.selected_contents_loaded = flag
        },
        update_gri_option_status(state, new_gri_option_obj){
            state.gri_option_status = new_gri_option_obj
        },
        update_gri_option_status_loaded(state, flag){
            state.gri_option_status_loaded = flag
        }
    },
    getters: {
        gri_100_all_indicators(state){
            return state.indicators_list.filter( x => x.topic_id >= 100 && x.topic_id<=199)
        },
        gri_200to400_all_topics(state){
            return state.topics_list.filter( x => x.topic_id >= 200 && x.topic_id<=499)
        },
        gri_200to400_all_indicators(state){
            return state.indicators_list.filter( x => x.topic_id >= 200 && x.topic_id<=499)
        },
        gri_200_topics(state){
            let temp_200 = state.topics_list.filter( x => x.topic_id >= 200 && x.topic_id<=299)
            return temp_200.sort((a, b)=> parseFloat(a.topic_id) - parseFloat(b.topic_id))
        },
        gri_300_topics(state){
            let temp_300 = state.topics_list.filter( x => x.topic_id >= 300 && x.topic_id<=399)
            return temp_300.sort((a, b)=> parseFloat(a.topic_id) - parseFloat(b.topic_id))
        },
        gri_400_topics(state){
            let temp_400 = state.topics_list.filter( x => x.topic_id >= 400 && x.topic_id<=499)
            return temp_400.sort((a, b)=> parseFloat(a.topic_id) - parseFloat(b.topic_id))
        },
        selected_indicators(state){
            return state.indicators_list.filter( x => x.topic_id >= 100 && x.topic_id<=199 && x.selected == 1)
        },
        selected_indicators_gri_codes(state){ // business case, data table의 used_codes 에 있는 gri_codes 중 selected indicators 인 것만 가져오기 위함
            let temp_gri_code_list = []
            // 1. 102번대 붙이기
            let gri_102_selected = state.indicators_list.filter( x => x.topic_id >= 100 && x.topic_id<=199 && x.selected == 1)
            gri_102_selected.forEach(x => temp_gri_code_list.push(x.gri_code))
            // 2. 200~400번대 붙이기
            let temp_arr = [...state.selected_contents]
            for (let i=0; i<temp_arr.length; i++){
                temp_arr[i].gri_codes.forEach(item => temp_gri_code_list.push(item.gri_code))
            }
            return temp_gri_code_list
        },
    },
    actions: {
        readReportTopics({commit, rootState}, { report_code } ){
            const path = rootState.backend_host + '/read_report_topics'
            return axios.get( path, { params: {
                report_code: report_code
            }})
            .then( response => {
                // console.log(response.data);
                commit('update_topics_list', response.data)
            })
        },
        readReportIndicators({commit, rootState}, { report_code } ){
            const path = rootState.backend_host + '/read_report_indicators'

            return axios.get( path, { params: {
                report_code: report_code
            }})
            .then( response => {
                let temp_indicators = response.data
                // console.log(temp_indicators);
                temp_indicators.sort((a,b)=>parseFloat(a.gri_code) - parseFloat(b.gri_code))
                
                commit('update_indicators_list', temp_indicators)
                commit('update_indicators_list_loaded', true)
            })
        },
        linkReportTopics({state, commit, rootState}, { report_code, link_payload }){
            const path = rootState.backend_host + '/link_report_topics'

            const formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('link_payload', JSON.stringify(link_payload))
            
            //link_payload
            //{ "topic_id": [selected: true, material: false] } 형태 해시 테이블(dict)
            //ex. 401이 보고 주제로 선택되었으나, 중요주제는 아닌 경우
            //{ "401": [true, false] }

            return axios.post(path, formData)
            .then( response => {
                // console.log(response.data);
                commit('update_report_topic_relation', { report_code: response.data.report_code, updated_relation: response.data.updated_relation })
                //updated_relation : link_payload와 똑같이 생긴건데, DB저장된 결과를 반영한 것임 

                //topic 선택 변경으로 인해서 update된 indicator가 있는 경우 모두 업데이트
                // console.log(response.data.indicators_update);
                response.data.indicators_update.forEach( item => {
                    commit('update_report_indicator_relation', {report_code: response.data.report_code, updated_relation: item.updated_relation})
                })

            })

        },
        linkReportIndicators({state, commit, rootState}, { report_code, link_payload }){
            const path = rootState.backend_host + '/link_report_indicators'

            const formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('link_payload', JSON.stringify(link_payload)) //link_payload: # { "gri_code번호": true or false } selected의 true, false 여부를 표시한 해시 테이블(dict)

            return axios.post(path, formData)
            .then( response => {
                // console.log(response.data);
                commit('update_report_indicator_relation', { report_code: response.data.report_code, updated_relation: response.data.updated_relation })
                //updated_relation : link_payload와 똑같이 생긴건데, DB저장된 결과를 반영한 것임
            })
        },
        readSelectedTopics({ commit, rootState}, { report_code } ){
            const path = rootState.backend_host + '/read_selected_topics'

            return axios.get(path, { params: {
                report_code: report_code
            }})
            .then( response => {
                // console.log(response.data);
                let new_selected_contents = response.data
                new_selected_contents.sort((a,b)=>parseFloat(a.topic_id) - parseFloat(b.topic_id))

                commit('update_selected_contents', new_selected_contents)
                commit('update_selected_contents_loaded', true)
            })
        },
        checkGriOption({commit, rootState}, report_code){
            const path = rootState.backend_host + '/check_gri_option'

            return axios.get(path, { params: {
                report_code: report_code
            }})
            .then( response => {
                // console.log(response.data);
                let temp_obj = {...response.data}
                temp_obj.for_core_topic.sort((a,b)=>parseFloat(a.for_core_topic) - parseFloat(b.for_core_topic))
                temp_obj.for_comprehensive_topic.sort((a,b)=>parseFloat(a.for_comprehensive_topic) - parseFloat(b.for_comprehensive_topic))
    
                commit('update_gri_option_status', temp_obj)
                commit('update_gri_option_status_loaded', true)
            })
            .catch(error => {
                console.log(error);
                // if (error.response.status == 401) { 
                //     alert(`다중 로그인이 감지되어 로그아웃 합니다. 다시 로그인해 주십시오.`)
                //     dispatch('logout', null, { root: true }).then(() => window.location.href=rootState.frontend_host  )
                // }
                // else { alert(`편집하실 보고서를 다시 선택해 주세요`) 
                //         window.location.href=rootState.frontend_host  }
            })
        }
    }
}

export default report_indicators
import Vue from 'vue'
import Vuex from 'vuex'
import report_list from './report_list'
import report_indicators from './report_indicators'
import report_contents from './report_contents'
import data_tables from './data_tables'
import documents from './documents'
import business_cases from './business_cases'
import charts from './charts'
import data_center from './data_center'
import assurance from './assurance'
import cursor from './cursor'
import admin from './admin'
import logs from './logs'
import billings from './billings'
import custom_products from './custom_products'
import gri2021_indicators from './gri2021_indicators'
import gri2021_report_view from './new_viewer/gri2021_report_view'
import gri2021_modal_open_status from './new_viewer/gri2021_modal_open_status'
import categoryModule from './modules/categoryModule';

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        report_list: report_list, //특정 유저 또는 조직 단위로 볼 수 있는 보고서의 목록
        report_indicators: report_indicators, // 현재 선택되어서 보고 있는 보고서의 지표연결 목록
        report_contents: report_contents, //현재 선택되어서 보고 있는 보고서의 디크클로져 콘텐츠
        data_tables: data_tables, 
        documents: documents, 
        business_cases: business_cases,
        charts: charts,
        data_center: data_center,
        assurance: assurance,
        cursor: cursor,
        admin: admin,
        logs: logs,
        billings: billings,
        custom_products: custom_products,
        gri2021_indicators: gri2021_indicators,
        gri2021_report_view: gri2021_report_view,
        gri2021_modal_open_status: gri2021_modal_open_status,
        category: categoryModule

    },
    state: {
        //서버정보 
        backend_host: process.env.VUE_APP_BACKENDHOST,
        static_host: process.env.VUE_APP_STATICHOST,
        frontend_host: process.env.VUE_APP_FRONTHOST,

        // 포트원 가맹점 식별 코드
        imp_pk_code: process.env.VUE_APP_IMP_PK_CODE,


        //로그인
        user_id: localStorage.getItem('user_id') || '',
        e_mail: localStorage.getItem('e_mail') || '',
        user_name: localStorage.getItem('user_name') || '',
        tel: localStorage.getItem('tel') || '',
        authenticated: localStorage.getItem('authenticated') || '', //금번 접속시 로그인 여부 확인 - 'success', 'loading', 'error', ''
        access_token: localStorage.getItem('access_token') || '',
        subscription: localStorage.getItem('subscription') === 'true' || false,
        is_confirmed: localStorage.getItem('is_confirmed') === 'true' || false,
        is_admin: localStorage.getItem('is_admin') || null,
        selectedMethod: null,

        // hide_todo_until: localStorage.getItem('hide_todo_until') || '',


        // licenseInfo
        licenseInfo: JSON.parse(localStorage.getItem('licenseInfo')) || null, 

    },
    mutations: {
        logout (state) {
        state.user_id = ''
        state.e_mail = ''
        state.user_name = ''
        state.tel = ''
        state.authenticated = ''
        state.access_token = ''
        state.subscription = false
        state.is_confirmed = false
        state.is_admin = null
        state.licenseInfo = null;    
        delete localStorage.user_id
        delete localStorage.e_mail
        delete localStorage.user_name
        delete localStorage.tel
        delete localStorage.authenticated
        delete localStorage.access_token
        delete localStorage.subscription
        delete localStorage.is_confirmed
        delete localStorage.is_admin
        delete localStorage.licenseInfo
        delete localStorage.alertLicenseInfo
            
        localStorage.clear();
        sessionStorage.clear();
            
        axios.defaults.headers.common['Authorization'] = '';
        state = {}
        location.href = "/"
            
        },
        auth_request(state) {
            state.authenticated = 'loading'
            localStorage.setItem('authenticated', 'loading')
        },
        auth_success(state, { user_id, e_mail, user_name, tel, access_token, subscription, is_confirmed, is_admin }) {
            state.authenticated = 'success'
            localStorage.setItem('authenticated', 'success')

            state.user_id = user_id, 
            state.e_mail = e_mail, 
            state.user_name = user_name, 
            state.tel = tel, 
            state.access_token = access_token, 
            state.subscription = subscription, 
            state.is_confirmed = is_confirmed, 
            state.is_admin = is_admin

            localStorage.setItem('user_id', user_id)
            localStorage.setItem('e_mail', e_mail)
            localStorage.setItem('user_name', user_name)
            localStorage.setItem('tel', tel)
            localStorage.setItem('access_token', access_token)
            localStorage.setItem('subscription', subscription)
            localStorage.setItem('is_confirmed', is_confirmed)
            localStorage.setItem('is_admin', is_admin)
            

            axios.defaults.headers.common['Authorization']= `Bearer ${access_token}`;
        },
        auth_error(state){
            state.authenticated = 'error'
            localStorage.setItem('authenticated', 'error')
        },
        // update_hide_todo_until(state, new_time ){
            // localStorage.setItem('hide_todo_until', new_time )
        // }

        setLicenseInfo(state, licenseInfo) {
            if (licenseInfo !== null) {
                state.licenseInfo = licenseInfo;
                localStorage.setItem('licenseInfo', JSON.stringify(licenseInfo));
                localStorage.setItem('alertLicenseInfo', true)
            } else {
                state.licenseInfo = null;
                delete localStorage.licenseInfo;
            }
        },
        setSelectedMethod(state, data) {
            state.selectedMethod = data;
        }
    },
    actions: {
        login({ commit, dispatch, state }, { e_mail, password }) {
            sessionStorage.clear();
            localStorage.clear();
            const path = state.backend_host + '/login'
            
            let formData = new FormData()
            formData.append('e_mail', e_mail)
            formData.append('password', password)

            return axios.post(path, formData)
                .then(response => {
                //response: user_id, e_mail, user_name, tel, api_key, subscription, is_confirmed, is_admin
                let user_id = response.data['user_id']
                let e_mail = response.data['e_mail']
                let user_name = response.data['user_name']
                let tel = response.data['tel']
                let access_token = response.data['api_key']
                let subscription = response.data['subscription']
                let is_confirmed = response.data['is_confirmed']
                let is_admin = response.data['is_admin']
                commit('auth_success', { user_id, e_mail, user_name, tel, access_token, subscription, is_confirmed, is_admin })
                
                dispatch('updateLicenseInfo')
                return response
            })
            .catch( error=> {
                console.log(error)
                dispatch('logout')
                .then( () => {
                location.href = "/"

                })
            })
        },
        logout({ commit, state }) {
            setTimeout(() => {
                sessionStorage.clear();
                localStorage.clear();
            }, 1);
            const path = state.backend_host + '/logout'
            
            return axios.post(path)
            .then( response => {
                // console.log(response.data);
                commit('logout')
                location.href = "/"

            })
            .catch(error => {
                console.log(error)
            })
        },
        saveUserInfo( { commit, state }, { user_id, password_old, password_new, e_mail, user_name, tel, subscription, is_confirmed }){
            const path = state.backend_host + '/update_user'

            let formData = new FormData()
            formData.append('user_id', user_id)

            if(password_old != undefined && password_new != undefined && password_new != null) {
                formData.append('password_old', password_old)
                formData.append('password_new', password_new)
            }
            if(e_mail != undefined && e_mail != null){
                formData.append('e_mail', e_mail)
            }
            if(user_name != undefined && user_name != null){
                formData.append('user_name', user_name)
            }
            if(tel != undefined && tel != null){
                formData.append('tel', tel)
            }
            if(subscription != undefined && subscription != null){
                formData.append('subscription', subscription)
            }
            if(is_confirmed != undefined && is_confirmed != null){
                formData.append('is_confirmed', is_confirmed)
            }

            return axios.post(path, formData)
            .then(response => {
                console.log(response.data);
                if(response.data.code == 'success'){
                    let n = {
                        user_id: response.data.user_id,
                        e_mail: response.data.e_mail,
                        user_name: response.data.user_name, 
                        tel: response.data.tel, 
                        access_token: response.data.api_key,
                        subscription: response.data.subscription,
                        is_confirmed: response.data.is_confirmed,
                        is_admin: response.data.is_admin
                    }
                    commit('auth_success', { user_id: n.user_id , e_mail: n.e_mail, user_name: n.user_name, tel: n.tel, access_token: n.access_token, subscription: n.subscription, is_confirmed: n.is_confirmed, is_admin: n.is_admin })
                }
                else{
                    console.log(response.data.msg)
                    return response
                }
                return response
            })
        },
        userConfirmed({ commit, state }, { e_mail, confirm_code }){
            const path = state.backend_host + '/confirm_user'

            let formData = new FormData()
            formData.append('e_mail', e_mail)
            formData.append('confirm_code', confirm_code)
            return axios.post(path, formData)
            .then( response => {
                let r = response.data
                commit('auth_success', {user_id: r.user_id, e_mail: r.e_mail, user_name: r.user_name, tel: r.tell, access_token: r.api_key, subscription: r.subscription, is_confirmed: r.is_confirmed, is_admin: r.is_admin})
                return response
            })
        },
        updateLicenseInfo({ commit, state }) {
            axios.get(`${state.backend_host}/get_user_license_info`)
                .then((response) => {
                    if (response.data.code) {
                        commit('setLicenseInfo', response.data);
                    } else {
                        commit('setLicenseInfo', null)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    commit('setLicenseInfo', null)

                })

        },
        updateSelectedMethod({ commit, state }, e_mail) { 
            axios
                .post(`${state.backend_host}/payment/fetchPaymethodInfo`, {
                    user_email: e_mail,
                })
                .then((response) => {
                    if (response.data.code && response.data.methods) {
                        if (response.data.methods.length > 0) {
                            commit('setSelectedMethod', response.data.methods[0].item)
                        } else {
                            commit('setSelectedMethod', null)
                        }
                    }
                })
                .catch((error) => {
                    commit('setSelectedMethod', null)
                    console.error(error);
                });
                    
        }
    },
    
})

try {
    const savedState = JSON.parse(sessionStorage.getItem('vuex_state'));
    
    if (savedState && location.pathname !== "/v/") {
      store.replaceState(savedState);
    }
    
    store.subscribe((mutation, state) => {
        sessionStorage.setItem('vuex_state', JSON.stringify(state));
    })
    
} catch (e) {
    console.log(e)
}


export default store;

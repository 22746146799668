<template lang="">
  <div v-if="licenseInfo !== null">
    <b-button v-if="licenseInfo !== null" size="sm" variant="warning" @click="openCancelSubscriptionModal"
      >정기결제 취소</b-button
    >

    <b-modal
      id="cancelSubscriptionModal"
      title="정기결제를 취소하시겠습니까? 😥"
      v-model="showModal"
    >
      <b-overlay :show="loadingShow" no-wrap></b-overlay>

      <p class="my-4">
        안녕하세요 {{ user_name }}님 <br />
        현재 <b>{{ licenseInfo.subscriptionId }}</b> 서비스를 이용 중이십니다.
        <br />
        정기결제을 취소하시면 #@$#%#$@%#$% 하게 됩니다. <br>
        정말 취소하시겠어요?

        <br /><br /><br />
        서비스 등록일: {{ licenseInfo.enrollment_date }} <br />
        마지막 결제일: {{ licenseInfo.lastPaymentDate }} <br />
        <br />
        <template>
          <div>
            <b-form-textarea
              id="textarea"
              v-model="cancelReason"
              placeholder="결제 사유를 알려주세요 (최소 10글자 이상)"
              rows="5"
              max-rows="5"
              :state="cancelReason.length >= 10"
            ></b-form-textarea>
          </div>
        </template>

        <!--
          {{ licenseInfo }}
        -->
      </p>

      <template #modal-footer>
        <div class="w-80">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="showModal = false"
          >
            아니오
          </b-button>
        </div>

        <div class="w-20">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="cancelSubscription"
          >
            정기결제 취소
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
// import requestWithQESG from "@/components/customAxios";

export default {
  data() {
    return {
      showModal: false,
      loadingShow: true,
      cancelReason: "",
    };
  },
  computed: {
    ...mapState(["licenseInfo", "e_mail", "user_name"]),
  },
  methods: {
    ...mapActions(["updateLicenseInfo"]),
    cancelSubscription() {
      if (this.cancelReason.length >= 10) {
        // requestWithQESG("POST", "/payment/cancelSubscription", {
        //   user_id: this.e_mail,
        //   cancelReason: this.cancelReason,
        // })
        //   .then((response) => {
        //     this.updateLicenseInfo();
        //     this.showModal = true;
        //     window.alert(response.data?.message);
        //     this.$router.push("/main");
        //   })
        //   .catch((error) => {
        //     window.alert(error);
        //   });
        axios.post(this.$store.state.backend_host + "/payment/cancelSubscription", {
          user_id: this.e_mail,
          cancelReason: this.cancelReason,
        })
          .then((response) => {
            this.updateLicenseInfo();
            this.showModal = true;
            window.alert(response.data?.message);
            this.$router.push("/main");
          })
          .catch((error) => {
            window.alert(error);
          });

      }
    },
    openCancelSubscriptionModal() {
      this.showModal = true;

      this.loadingShow = false;
    },
  },
};
</script>
<style lang=""></style>

<template>
<div>
    <div class="mb-5 border-bottom-gray-550 py-2 d-flex justify-content-between sticky-top bg-white">
        <h3 class="mb-0"> 검증의견서 작성 </h3>
        <b-button-group>
            <b-button v-if="admin_assurance.request_status<5" @click="makeRequestSatus(5)" variant="red" class="f-110"> 작성 시작하기 </b-button>
            <b-button @click="makeRequestSatus(6)" :disabled="admin_assurance.request_status<5" :variant="admin_assurance.request_status>=5?'red':''" class="f-110"> {{admin_assurance.request_status==6?'검증의견서 배포 취소': '검증의견서 배포' }} </b-button>
        </b-button-group>
    </div>
    <b-overlay :show="admin_assurance.request_status<5">
        <div class="mb-5">
            <h6> 검증날짜 선택 <span class="fw-300 f-90 ml-3"><fa icon="info-circle" class="mr-1"/> 검증의견서에 표시될 검증 날짜를 입력해주세요. </span> </h6> 
            <b-form-datepicker v-model="assurance_date" auto-focus class="border-dark" style="max-width: 400px;" :disabled="admin_assurance.request_status==6"></b-form-datepicker>
        </div>

        <div class="mb-2">
            <b-button @click="show_preview=!show_preview" :variant="show_preview==true?'dark':'dark-border'" class="mr-2">{{show_preview==true? '편집하기':'미리보기'}}</b-button>
            <b-button @click="show_example=!show_example" :variant="show_example==true?'dark':'dark-border'">{{show_example==true? '예시닫기':'예시보기'}}</b-button>
        </div>
        <template v-if="show_preview==false">      
            <div class="d-flex mb-45">   
                <b-form-textarea v-model="statement_contents" rows="24" auto-focus placeholder="검증의견서 내용을 입력하세요." :class="show_example==true?'w-50':''" class="border-dark" :disabled="admin_assurance.request_status==6"></b-form-textarea>
                <b-form-textarea v-if="show_example==true" v-model="statement_example" rows="24" style="width: calc(50% - 15px)" class="ml-3" :disabled="admin_assurance.request_status==6"></b-form-textarea>
            </div>  
        </template>
        <template v-else>
            <div class="d-flex mb-45">
                <div class="p-4 border-dark rounded shadow-sm" :class="show_example==true?'w-50':'w-100'"> 
                    <h4 class="mb-4">제 3자 검증의견서</h4>
                    <md :contents="statement_contents"></md>
                    <div> 
                        <p> {{ assurance_date }} </p>
                        (일자, 서명, 로고 등 )
                    </div>
                </div>
                <div v-if="show_example==true" style="width: calc(50% - 15px)" class="p-4 border rounded shadow-sm ml-3">
                    <h4 class="mb-4">제 3자 검증의견서</h4>
                    <md :contents="statement_example"></md>
                </div>
            </div>
        </template>
        <template #overlay>
            '작성 시작하기' 클릭 후 작성 가능
        </template>
    </b-overlay>


    

    <div class="text-right mb-5 pb-5">
        <b-button variant="teal" @click="handleSave" class="px-4 f-110" :disabled="admin_assurance.request_status==6||is_saving==true">저장하기<b-spinner small v-if="is_saving==true"></b-spinner></b-button>
    </div>
    
    <br /><br />
    <hr />
    <div>

        <!-- v-model="search_report_keyword"  -->
  
        <b-row>
            <b-col>
                <!-- <b-input 
                    placeholder="일련번호"
                    v-b-tooltip.hover.bottom title="검증마크의 일련번호 값을 입력해주세요"
                />   -->
                검증마크 이미지 업로드
            </b-col>
            <b-col cols="10">
                <b-form-file 
                    type="text" 
                    v-model="verification_img" 
                    @change="handleVerificationChange"  
                    class="narrow-input-form" 
                    v-b-tooltip.hover.bottom title="검증마크 이미지 파일을 업로드해주세요"
                    accept="image/*"
                />
            </b-col>

        </b-row>
        <b-row>
            <b-col></b-col>
            <b-col cols="10">
                <b-img 
                    v-if="admin_assurance.verification_img_path && !verification_img"
                    :src="$store.state.static_host+admin_assurance.verification_img_path.replaceAll('\\', '/')"
                    fluid
                />
    
                <b-img 
                    v-if="verification_img && verification_img_path.startsWith('blob')"
                    :src="verification_img_path"
                    fluid
                />
            </b-col>

        </b-row>

    </div>
    <div class="text-right mb-5 pb-5 mt-4">
        <b-button 
            variant="teal" 
            @click="uploadVerificationImg" 
            class="px-4 f-110" 
        >
            검증마크 업로드
        <b-spinner small v-if="is_saving==true"></b-spinner>
    </b-button>
    </div>


</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {

            show_preview: false,
            statement_contents: '',
            show_example: false,
            statement_example: '#### 만들어서 local data로 가지고 있기',
            assurance_date: '',
            is_saving: false,


            verification_img: null,
            verification_img_path: null,
            verification_img_name: null
        }
    },
    computed:{
        ...mapState('assurance', ['admin_assurance']),
        ...mapState('admin', ['report_code']),

    },
    methods:{
        ...mapActions('assurance',['saveAssuranceRequest', 'saveAssuranceReport', 'readAssuranceListByReportCode']),
        ...mapMutations('assurance',['update_admin_assurance']),

        makeRequestSatus(state_num){     
            if(this.admin_assurance.request_status>=2){
                let request_status = 5
                if(state_num==5){
                    // if (confirm('보고서 소유자(Owner)에게 검증의견서 작성을 시작했다는 이메일을 보내고, assurance status를 5로 바꿉니다. 시작하시겠습니까?') == true) {
                        this.saveAssuranceRequest({
                            report_code : this.report_code,
                            request_status: request_status
                        })
                    // }
                }
                if (state_num == 6) {
                    if(this.statement_contents!=this.admin_assurance.report_statement) {
                        alert('변경된 내용을 저장 후 배포해주세요.')
                        return false
                    }
                    // 작성한 검증의견서 저장 먼저
                    if (this.admin_assurance.request_status>=6){
                        if (confirm('보고서 Owner에게 검증의견서를 수정한다는 이메일을 보내고, assurance status를 5로 바꿉니다. 진행하시겠습니까?') == true) {
                            this.saveAssuranceRequest({
                                report_code : this.report_code,
                                request_status: request_status
                            })

                        }
                    }
                    else {
                        request_status = 6
                        if (confirm('보고서 Owner에게 검증의견서 생성이 완료되었다는 이메일을 보내고, assurance status를 6로 바꿉니다. 완료하시겠습니까?') == true) {
                            this.saveAssuranceRequest({
                                report_code : this.report_code,
                                request_status: request_status
                            })
                        }
                    }                    
                }               
            }else alert('본 보고서는 검증 신청이 아직 완료되지 않아 서면검토를 시작할 수 없습니다.')            
        },
        async handleSave(){
            if(this.checkForm()==true){
                this.is_saving = true
                if(this.admin_assurance.request_status >= 4 && this.admin_assurance.request_status < 6){
                    await this.saveAssuranceReport({
                        assurance_id: this.admin_assurance.assurance_id,
                        assurance_date: this.assurance_date,
                        assurance_statement : this.statement_contents
                    })

                    this.update_admin_assurance(this.admin_assurance.assurance_id);

                    this.$EventBus.$emit('make-toast', '검증의견서 저장', '검증의견서를 저장하였습니다.')
                }
                this.is_saving = false     
            }                   
        },
        checkForm(){
            if(this.statement_contents==''){
                alert('검증의견서 내용을 작성해주세요.')
                return false
            }
            else if (this.assurance_date==''){
                alert('검증의견서에 나타날 검증 날짜를 선택해주세요.')
                return false
            }
            else return true
        },
        handleVerificationChange(e) {
            this.verification_img = e.target.files[0]
            this.verification_img_path = URL.createObjectURL(e.target.files[0]);
            this.verification_img_name = e.target.files[0].name
        },
        uploadVerificationImg_old() {
            if (this.admin_assurance.request_stats < 6) {
                const check = window.prompt("검증의견서 생성이 완료되지 않았습니다. 이미지를 업로드 하시겠습니까?")

                console.log(check)
            }
        

            if (!this.verification_img) {
                window.alert("검증마크 업로드가 되지 않았습니다.")
                return;
            } 

            const path = this.$store.state.backend_host + "/upload_verification_img";

            const formData = new FormData();
            formData.append("report_code", this.admin_assurance.report_code);
            formData.append("verification_img", this.verification_img, this.verification_img_name);


            axios.post(path, formData).then((response) => {

                if (response.data.code) {
                    this.readAssuranceListByReportCode({report_code: this.admin_assurance.report_code})
                    this.update_admin_assurance(this.admin_assurance.assurance_id);
    
                    this.$EventBus.$emit('make-toast', '검증마크 업로드', '검증마크가 업로드 되었습니다.')
                    
                } else {
                    window.alert(response.data.msg)
                }

            }).catch(e => {
                window.alert(e)
            });

        },
        async uploadVerificationImg() {
            try {
                
            
                if (!this.verification_img) {
                    window.alert("검증마크 업로드가 되지 않았습니다.")
                    return;
                } 

                if (this.admin_assurance.request_status < 6) {
                    const check = window.confirm("검증의견서 생성이 완료되지 않았습니다. 이미지를 업로드 하시겠습니까?")

                    if (!check) {
                        return
                    } 
                }


                const path = this.$store.state.backend_host + "/upload_verification_img";

                const formData = new FormData();
                formData.append("report_code", this.admin_assurance.report_code);
                formData.append("verification_img", this.verification_img, this.verification_img_name);


                const response = await axios.post(path, formData)
                if (response.data.code) {
                    this.readAssuranceListByReportCode({report_code: this.admin_assurance.report_code})
                    this.update_admin_assurance(this.admin_assurance.assurance_id);

                    this.$EventBus.$emit('make-toast', '검증마크 업로드', '검증마크가 업로드 되었습니다.')
                    
                } else {
                    window.alert(response.data.msg)
                }
            } catch (error) {
                window.alert(e)   
            }
        }
    },
    mounted(){
        // 저장된 assurance report 있으면 읽어오기
        // console.log(this.admin_assurance);
        this.readAssuranceListByReportCode({report_code: this.admin_assurance.report_code})
        this.update_admin_assurance(this.admin_assurance.assurance_id);

        if(this.admin_assurance.report_statement!=null&&this.admin_assurance.report_statement!=''){
            this.statement_contents = this.admin_assurance.report_statement
            this.assurance_date = this.admin_assurance.report_assurance_date
        }
    },
    beforeDestroy(){
        this.show_preview = false
        this.show_example = false
    }

}
</script>

<style>

</style>
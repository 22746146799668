<template>
    <div v-if="web_view == true" class="bg-white position-relative">
        <b-container class="position-relative h-100"
            :style="web_view_header_padding"
            >
            <div class="d-flex justify-content-between"
                :style="[selected_report.logo_path !== null ? { marginBottom: '2.5rem' } : { marginBottom: '2.0rem' }]"
                >
                <div class="flex-grow-1 pr-5">
                    <template v-if="selected_report.logo_path !== null">
                        <b-img :src="$store.state.static_host+selected_report.logo_path" class="report-logo-regular" 
                        :style="{ 'max-height': '40px' }" />
                    </template>
                    <template v-else>
                        <h2 class="fw-600 mb-3">
                            {{ lang !== 'ENG' ? selected_report.report_name : selected_report.report_name_e }}
                        </h2>
                    </template>
                    <!-- <b-img :src="$store.state.static_host+selected_report.logo_path" class="report-logo-regular" /> -->
                </div>
                <div class="flex-grow-0">
                    <b-button-group class="mr-3">
                        <b-button 
                            v-if="active_gri_table" 
                            @click="$emit('showTableView', 'GRI')" 
                            class="d-inline-flex f-140 py-1"
                            :variant="show_gri_table ? 'dark-border-selected' : 'dark-border'"
                            :disabled = show_gri_table
                        >
                            <div class="f-100 d-inline">GRI</div>
                        
                        </b-button>
        
                        <b-button 
                            v-if="active_sasb_table" 
                            @click="$emit('showTableView', 'SASB')" 
                            class="d-inline-flex f-140 py-1"
                            :variant="show_sasb_table ? 'dark-border-selected' : 'dark-border'"
                            :disabled = show_sasb_table
                        >
                            <div class="f-100 d-inline">SASB</div>
                        
                        </b-button>
                        <b-button 
                            v-if="active_tcfd_table" 
                            @click="$emit('showTableView', 'TCFD')" 
                            class="d-inline-flex f-140 py-1"
                            :variant="show_tcfd_table ? 'dark-border-selected' : 'dark-border'"
                            :disabled = show_tcfd_table
                        >
                            <div class="f-100 d-inline">TCFD</div>
                        
                        </b-button>

                        <b-button 
                            v-if="active_dc_table" 
                            @click="$emit('showTableView', 'DATA_CENTER')" 
                            class="d-inline-flex f-140 py-1"
                            :variant="show_dc_table ? 'dark-border-selected' : 'dark-border'"
                            :disabled = show_dc_table
                        >
                            <div class="f-100 d-inline">Data Center</div>
                        
                        </b-button>
                    </b-button-group>





                    <b-button v-if="show_printing_btn && !selected_report.sample_report"
                        variant="dark-border"
                        class="d-inline-flex f-140 py-1 mr-1"
                        v-print="'#full-report-preview'"
                        >
                        <div class="f-100 d-inline">Print</div>
                    </b-button> 
                    <b-button v-if="show_translation_btn"
                        variant="dark-border"
                        class="d-inline-flex f-140 py-1 mr-1"
                        @click="toggleLang"
                        >
                        <div class="f-100 d-inline">
                            {{ lang !== 'ENG' ? 'ENG' : 'KOR' }}</div>
                    </b-button>
                    <template v-if="view_mode !== 'viewer'">
                        <b-button 
                            variant="dark-border"
                            size="lg"
                            class="d-inline-flex f-110"
                            @click="$router.push({ name: 'CheckReport' })"
                            v-b-tooltip.hover
                            title="미리보기 닫기"
                            >
                            <fa icon="times"/>
                        </b-button>
                    </template>
                </div>
            </div>
            <h2 v-if="selected_report.logo_path !== null" class="fw-600 mb-3">
                {{ lang !== 'ENG' ? selected_report.report_name : selected_report.report_name_e }}</h2>
            <div class="">
                {{ selected_report.start_date }} ~ {{ selected_report.end_date }}
            </div>
        </b-container>
    </div>
    <div v-else>
        <div class="bg-white" style="height:40vh">
            <b-container class="position-relative h-100 px-5">
                <div v-if="selected_report.logo_path !== null"
                    class="position-absolute"
                    style="padding-top: 50px"
                    >
                    <b-img :src="$store.state.static_host+selected_report.logo_path" class="report-logo-regular" />
                </div>
                <h2 class="mb-4 fw-600" style="padding-top: calc(40vh - 120px)">
                    {{ lang !== 'ENG' ? selected_report.report_name : selected_report.report_name_e}}
                </h2>
                <div>{{ selected_report.start_date }} ~ {{ selected_report.end_date }}</div>
            </b-container>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'ViewerHeaderBasicSkChemicalLayout',
    components:{},
    data() {
        return {
            show_data_table: {},
            show_business_case: {},
            low_height: false,

            
            
        }
    },    
    props:{
        web_view: { type: Boolean, default: false },
        show_printing_btn: { type: Boolean, default: true},
        show_translation_btn: { type: Boolean, default: true},
        active_gri_table: {
            type: Boolean,
            default: false,
        },
        active_sasb_table: {
            type: Boolean,
            default: false,
        },
        active_tcfd_table: {
            type: Boolean,
            default: false,
        },
        
        show_gri_table: {
            type: Boolean,
            default: false,
        },
        show_sasb_table: {
            type: Boolean,
            default: false,
        },
        show_tcfd_table: {
            type: Boolean,
            default: false,
        },
        active_dc_table: {
            type: Boolean,
            default: false
        },
        show_dc_table: {
            type: Boolean,
            default: false
        },
    },
    computed:{
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapState('gri2021_report_view', ['report_structure', 'report_contents_loaded']),
        ...mapGetters('gri2021_report_view', ['report_topics', 'report_categories', 'report_topic_specific_categories', 'report_universal_categories', 'report_topic_types']),
        ...mapGetters('report_list', ['selected_report']),
        main_color(){
            return this.selected_report.main_color
        },
        web_view_header_padding () {
            return this.selected_report.logo_path !== null
                ? 'padding-top: 40px;padding-bottom: 40px;'
                : 'padding-top: 50px;padding-bottom: 50px;'
        }
    },
    methods: {
        ...mapMutations('report_list', ['change_lang']),
        ...mapActions('gri2021_report_view', ['readReportContents']),
        toggleLang(){
            if (this.lang=='KOR'){ //현재 국문이면 영문으로 바꾸기
                this.change_lang('ENG');
                document.querySelector("title").textContent = this.selected_report.report_name_e;
                
            } else {
                this.change_lang('KOR');
                document.querySelector("title").textContent = this.selected_report.report_name;
            }
        }
    },
    created(){

    }
}


</script>
import Print from './printarea.js';

/**
 * @file 인쇄
 * 지시어 `v-print`, 기본적으로 전체 창을 인쇄합니다.
 * 전달된 인수 `v-print="'#id'"`는 부분적으로 인쇄할 박스의 ID입니다.
 */
export default {
    directiveName: 'print',
    bind(el, binding, vnode) {
        let vue = vnode.context;
        let closeBtn = true;
        let id = '';
        el.addEventListener('click', () => {
            vue.$nextTick(() => {
                if (typeof binding.value === 'string') {
                    id = binding.value;
                } else if (typeof binding.value === 'object' && !!binding.value.id) {
                    id = binding.value.id;
                    let ids = id.replace(new RegExp("#", "g"), '');
                    let elsdom = document.getElementById(ids);
                    if (!elsdom) console.log("id in Error"), id = '';
                }
                // 부분 인쇄
                if (id) {
                    localPrint();
                } else {
                    // 전체 창 인쇄
                    window.print();
                }
            });

        });

        // 부분 인쇄 함수
        const localPrint = () => {
            if (closeBtn) {
                closeBtn = false;
                new Print({
                    ids: id, // 부분 인쇄를 위한 ID
                    standard: '', // 문서 유형, 기본값은 html5, 선택 가능: html5, loose, strict
                    extraHead: binding.value.extraHead, // head 태그에 추가할 추가 태그, 쉼표로 구분
                    extraCss: binding.value.extraCss, // 추가할 CSS 링크, 여러 개는 쉼표로 구분
                    popTitle: binding.value.popTitle, // 타이틀 제목
                    endCallback() { // 인쇄 후 호출되는 콜백 함수
                        closeBtn = true;
                    }
                });
            }
        };
    }
};

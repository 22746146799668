// export const _chart_skeleton_data = {}
export const y_options = [
    {
      text: "(좌측) Y축",
      value: "y",
    },
    {
      text: "(우측) Y축",
      value: "y-right",
    }
];

export const chart_type_options_single = [
    {
      value: "bar",
      text: "막대",
    },
    {
      value: "pie",
      text: "pie",
    },
    {
      value: "doughnut",
      text: "도넛",
    },
    {
      value: "line",
      text: "라인",
    },
    {
      value: "polarArea",
      text: "폴라",
    },
    {
      value: "radar",
      text: "radar",
    },
];

export const chart_type_options_multi = [
    {
      value: "bar",
      text: "막대",
    },
    {
      value: "line",
      text: "라인",
    }
];

export const option_scales_types = {
    category: {
        type: 'category',
        display: true,
        beginAtZero: false,
        align: 'center', // 'start', 'center' and 'end'
        text: 'X축',
        color: null,
        stack: false,
        min: null,
        max: null
    },
    linear: {
        type: 'linear',
        display: true,
        beginAtZero: false,
        align: 'center', // 'start', 'center' and 'end'
        text: 'Y축',
        color: null,
        stack: false,
        min: null,
        max: null,
        stepSize: null
    }
}

export const option_scales_common = {
    'x': {
        id:"x",
        axis:"x",
        type: 'category',
        display: true,
        beginAtZero: false,
        align: 'center', // 'start', 'center' and 'end'
        text: 'X축',
        color: null,
        stack: false,
        min: null,
        max: null
    },
    'y': {
        id:"y",
        axis:"y",
        type: 'linear',
        display: true,
        beginAtZero: false,
        align: 'center', // 'start', 'center' and 'end'
        text: 'Y축',
        color: null,
        stack: false,
        min: null,
        max: null,
        stepSize: null
    },
    'y-right': {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
            display: false
        },
        align: 'center', // 'start', 'center' and 'end'
        text: '(우측) Y축',
        color: null,
        stack: false,
        min: null,
        max: null,
        stepSize: null
    }
}

export const data_skelton_multi = {
    label: '새 차트',
    data: {
        labels: [2021, 2022, 2023],
        datasets: [
            {
                type: 'bar',
                label: '새 데이터1',
                data: [182, 175, 179]
            },
            {
                type: 'line',
                label: '새 데이터2',
                data: [152, 168, 181]
            }
        ]
    },
    options: {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: '차트 제목',
                font: {
                    size: 20
                }
            },
            subtitle: {
                display: true,
                text: '차트 부제목',
                font: {
                    size: 16
                }
            },
            legend: {
                display: true,
                position: 'top'
            }
        },
        // scales: option_scales
    }
}

export const data_skelton_single = {
    label: '새 차트',
    data: {
        labels: [2021, 2022, 2023],
        datasets: [
            {
                type: 'bar',
                label: '새 데이터1',
                data: [182, 175, 179],
                borderColor: ['#e0e0e0', '#e0e0e0', '#e0e0e0'],
                backgroundColor: ['#71B99E', '#1FA540', '#45D67F'],
                barPercentage: 0.5
            }
        ]
    },
    options: {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: '차트 제목',
                font: {
                    size: 20
                }
            },
            subtitle: {
                display: true,
                text: '차트 부제목',
                font: {
                    size: 16
                }
            },
            legend: {
                display: true,
                position: 'top'
            },
        },
        scales: {
            x: {
                id: "x",
                axis: "x",
                position: "bottom",
                type: "category",
                grid: {
                    display: true,
                    drawOnChartArea: true,
                },
                display: true,
                beginAtZero: false,
                title: {
                    display: false,
                    text: "",
                    color: "#666"
                }
            },
            y: {
                id: "y",
                axis: "y",
                position: "left",
                type: "linear",
                beginAtZero: true,
                display: true,
                grid: {
                    display: true,
                    drawOnChartArea: true,
                },
                title: {
                    display: false,
                    text: null,
                    color: "#666"
                },
                ticks: {
                    stepSize: null
                }
            }
        },
        layout: {
            padding: 20
        }
    }
}

<template>
<div>
    
    <router-view name="editor_header"></router-view>
    <b-container class="py-5 vh-adjust">
            <b-overlay :show="loadingShow" no-wrap></b-overlay>

            <router-view></router-view>
    </b-container>
    <router-view name="editor_footer"></router-view>        

</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
    data() {
        return {
            loadingShow: true
        }
    },
    computed:{
        ...mapState(['user_id', 'is_admin']),
    },
    methods:{
        ...mapActions('admin', ['readAllReportListForAdmin', 'check_valid_admin_right']),
        ...mapActions('assurance', ['readAssuranceListByReportCode']),

        
    },
    created() {

        // this.readAllReportListForAdmin()
        this.readAssuranceListByReportCode({report_code: null})

    },
    mounted() {
        this.check_valid_admin_right();
        this.loadingShow = false;

    }

}
</script>

<style>

</style>
<template>
<div>
    <div role="tablist">
        <b-card class="material-check-card shadow-sm mb-2"> 
            <div v-b-toggle.option-status role="tab" class="cursor">
                <div class="fw-500 mb-2"> 보고 요건 충족 여부 </div>
                <p class="f-90 mb-0 green" v-if="gri_option_status.is_core_aligned!=false"><fa icon="check-circle"/> {{ current_gri_option }} </p>
                <p class="f-90 mb-0 red" v-else><fa icon="exclamation-circle"/> {{ current_gri_option }} </p>
            </div>
            <b-collapse id="option-status" role="tabpanel" visible accordion="my-accordion">
                <div class="overflow-auto f-90 mt-3" style="max-height: calc(85vh - 460px);">
                    <template v-if="gri_option_status.is_core_aligned==false">
                        <div class="bgray-200 gray-600 px-2 py-2">
                            <div class="mb-2"> Core option을 충족하려면, </div>
                            <span class="italic" v-for="(item, index) in gri_option_status.for_core_100" :key="item.id"> {{item}}{{ gri_option_status.for_core_100.length>=(index+2)? ',':' ' }}
                            </span>{{ gri_option_status.for_comprehensive_topic.length >0 ? ',' : ''}} 
                            <span class="fw-300"> 지표를 선택해 주세요. <br> 또한, 선택된 <span class="italic" v-for="(item, index) in gri_option_status.for_core_topic" :key="item.id"> {{item}}{{ gri_option_status.for_core_topic.length>=(index+2)? ',':' ' }}
                            </span> 주제에 대해 <span class="fw-500">MA(경영방침) 포함 2개 이상</span>의 지표를 선택해 주세요. </span>    
                        </div>
                    </template>
                    <template v-else-if="gri_option_status.is_core_aligned==true&&gri_option_status.is_comprehensive_aligned==false">
                        <div class="bgray-200 gray-600 px-2 py-2">
                            <div class="mb-2"> Comprehensive를 충족하려면, </div>
                            <span class="italic" v-for="(item, index) in gri_option_status.for_comprehensive_100" :key="item.id"> {{item}}{{ gri_option_status.for_comprehensive_100.length>=(index+2)? ',':' ' }}
                            </span>{{ gri_option_status.for_comprehensive_topic.length >0 ? ',' : ''}}
                            <span class="italic" v-for="(item, index) in gri_option_status.for_comprehensive_topic" :key="item.id"> {{item}}{{ gri_option_status.for_comprehensive_topic.length>=(index+2)? ',':' ' }}
                            </span> 지표를 선택해 주세요. 
                        </div>
                    </template>
                    <div class="pt-2"><a v-b-modal.core-option-info class="cursor f-90"><fa icon="info-circle"/> 보고 요건 가이드 <fa icon="expand" class="ml-1"/> </a></div>
                </div>
            </b-collapse>            
        </b-card>

        <b-card class="material-check-card shadow-sm"> 
            <div v-b-toggle.material-list role="tab" class="cursor">
                <div class="fw-500 mb-2 d-flex justify-content-between">선택된 중요 주제<div><span class="fw-500 f-95">{{current_material_topics.length}}</span>개</div></div>
                <p class="f-85 gray-550 mb-0">GRI Topic(경제, 환경, 사회) 주제 중 최소 1개 이상을 중요 주제로 선택해 주세요.</p>
            </div>
            <b-collapse id="material-list" role="tabpanel" accordion="my-accordion">
                <div class="border-top-bottom overflow-auto py-2 f-90 my-2" style="max-height: calc(85vh - 460px);">
                    <div class="mb-2 gray-600 line-height-sm" v-for="item in current_material_topics" :key="item.id"><span class="mr-1 fw-300">{{item.topic_id}}</span> {{item.topic_name}}
                        <!-- <span class="f-75 mb-0 gray-400 fw-300">{{item.topic_name_e}}</span> -->
                    </div>            
                </div>

                <div class="pt-1"><a v-b-modal.material-topic-info class="cursor f-90"><fa icon="info-circle"/> 중요 주제 선택 가이드 <fa icon="expand" class="ml-1"/> </a></div>
            </b-collapse>
        </b-card>
    </div>

    <b-modal id="core-option-info" size="lg" title="보고 요건 충족 기준" hide-footer scrollable>
        <core-or-comprehensive></core-or-comprehensive>
    </b-modal>

    <b-modal id="material-topic-info" class="mt-2" title="중요 주제 선택 가이드" size="lg" hide-footer centered>
        <material-topic-info class="pb-3"></material-topic-info>
    </b-modal>

</div>
</template>

<script>
import CoreOrComprehensive from '@/components/static_examples/CoreOrComprehensive'
import MaterialTopicInfo from '@/components/managing_indicators/indicator2016_components/MaterialTopicInfo'
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState('report_indicators', ['indicators_list', 'topics_list']),
        ...mapGetters('report_indicators', ['gri_200to400_all_topics']),
        ...mapState('report_indicators', ['gri_option_status']),
        current_material_topics(){
            return this.gri_200to400_all_topics.filter(x => x.material==1)
        },
        current_gri_option(){
            if (this.gri_option_status.is_core_aligned==true&&this.gri_option_status.is_comprehensive_aligned==false){
                return '핵심 부합 (Core option) 충족'
            }
            else if (this.gri_option_status.is_comprehensive_aligned==true){
                return '포괄적 부합 (Comprehensive option) 충족'
            }
            else return '핵심 부합 (Core option) 미충족'
        }
    },
    methods:{
        ...mapMutations('report_indicators', ['update_gri_option_status']),
        ...mapActions('report_indicators', ['checkGriOption']),

    },
    created(){
        if(this.gri_option_status=={}||this.gri_option_status.is_core_aligned==undefined){
            this.checkGriOption(this.report_code)
        }
    },
    mounted(){
        // console.log(this.gri_option_status);
    },
    beforeDestroy(){
        this.update_gri_option_status({})
    },
    components:{
        CoreOrComprehensive,
        MaterialTopicInfo
    }

}
</script>

<style>
.material-check-card > .card-body {
    padding: 1rem 1.25rem;
}
</style>
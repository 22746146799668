<template>
    <div>
<!-- <pre>
{{ toc_list }}
</pre> -->
        <h2 class="fw-700">Data Center</h2>
        <p class="f-95 mb-5 gray-600">
            Data Center에는 차트와 테이블이 들어갑니다.  
        </p>
        <div
            class="mb-5"
            v-for="(lv1_info, lv1_idx) in toc_list"
            :key="lv1_idx" 
        >
            <h3 :id="`w${lv1_info.key}`" class="mb-4">{{ lv1_info.name }} </h3>

            <div
                v-for="(lv2_info, lv2_idx) in lv1_info.children"
                :key="lv2_idx"
            >   
                <div class="fw-500 mb-4 border-bottom-dark pb-2">
                    <h5 :id="`w${lv2_info.key}`" >
                        {{ lv2_info.name }}
                    </h5>
                </div>
                <b-table-simple responsive class="wri-table">
                    <b-thead>
                        <b-tr>
                            <b-td style="width: 20%">지표</b-td>
                            <b-td style="width: 70%">
                                <div class="d-inline-block float-right mx-2 f-90 green">
                                    차트 & 데이터테이블
                                </div>
                            </b-td>
                            <b-td style="width: 10%">
                            </b-td>
                        </b-tr>
                    </b-thead>
                    
                </b-table-simple>
                <!-- <draggable v-model="lv2_info.children" class="mb-5" style="display: grid;" > -->
                    <b-tr 
                        class="data-center-table"
                        style="display: grid;"
                        v-b-tooltip.hover.bottom 
                        >
                        <!-- title="클릭을 통해 행을 잡아 순서를 바꿔보세요" -->
                        <div style="display: flex;"> 
                            <b-td style="width: 15%">
                                <span class="f-95 gray-500">
                                    {{ lv1_info.name }} > {{ lv2_info.name }}
                                </span>
                                
                            </b-td>
                                
                            <b-td style="width: 85%" class="p-2">
                                <div
                                    v-for="(item, index) in lv2_info.children" 
                                    :key="index" 
                                >
                                    <div :id="'w' + item.key">
                                        <div style="min-width: 10vh" class="mt-2 ml-3">
                                                
                                                <div class="mb-3" v-if="lv2_info.children.length > 1">
                                                    <b-button
                                                        v-if="index !== 0"
                                                        class="mr-1"
                                                        size="sm"
                                                        variant="light" 
                                                        v-b-tooltip.hover.bottomright 
                                                        title="행을 위로"
                                                        @click="shiftUp(lv2_info, index)"
                                                    >
                                                        <b-icon
                                                            icon="arrow-up"
                                                            aria-hidden="true"
                                                        ></b-icon>
                                                    </b-button>
                    
                                                    <b-button
                                                        v-if="index !== lv2_info.children.length -1"
                                                        size="sm"
                                                        variant="light" 
                                                        v-b-tooltip.hover.bottomright 
                                                        title="행을 아래로"
                                                        @click="shiftDown(lv2_info, index)"
                                                    >
                                                        <b-icon
                                                            icon="arrow-down"
                                                            aria-hidden="true"
                                                        ></b-icon>
                                                    </b-button>
                                                </div>
                                            </div>
                                        <div
                                            v-if="!is_writing_direct[item.key]"
                                            @click.stop.prevent="
                                            handleEdit(selected_report.is_able_to_edit) &&
                                                changeWritingMode(item.key)
                                            "
                                            :class="
                                            selected_report.is_able_to_edit === true
                                                ? 'mb-3'
                                                : 'is_not_able_to_edit_form mb-3'
                                            "
                                        >
                                            <md
                                                v-if="item.contents"
                                                :contents="item.contents"
                                                :class="
                                                    selected_report.is_able_to_edit === true
                                                    ? 'hover-border pb-4'
                                                    : ''
                                                "
                                                style="
                                                    word-wrap: break-word;
                                                    white-space: no-wrap;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                "
                                            >
                                            </md>

                                            <div 
                                                v-if="!item.contents && !selected_report.is_able_to_edit"
                                                class="gray-500 rounded pb-4"
                                            >
                                                작성된 내용이 없습니다.
                                            </div>
                                            <div 
                                                v-if="
                                                selected_report.is_able_to_edit &&
                                                !item.contents
                                                "
                                                class="hover-border gray-500 rounded pb-4"
                                            >
                                                클릭하여 작성하기
                                            </div>
                                        </div>
                                        <div 
                                            v-else 
                                            class="mb-45"
                                        >
                                            <DataCenterForm
                                                @updateChartContents = updateChartContents
                                                :auto_focus = is_writing_direct[item.key]
                                                :contents="item.contents"
                                                :lv1_key=lv1_info.key
                                                :lv2_key=lv2_info.key
                                                :lv3_key=item.key
                                            /> 
                                        </div>
                                        <div
                                            v-if="!item.contents && !selected_report.is_able_to_edit"
                                            class="gray-500 rounded pb-4"
                                        >
                                            작성된 내용이 없습니다.
                                        </div>


                                        <div v-if="item.item_id !==''">
        
                                            <DataViewer 
                                                v-if="item.type==='table'"
                                                class="mb-5"
                                                :table_id="item.item_id" 
                                                :key="`chart_${item.item_id}`"
                                                :lv2_info="lv2_info"
                                                :lv3_idx="index"
                                                :edit_mode="true"
                                                :dc_key_info="[lv1_info, lv2_info, item]"
                                                :is_able_to_edit="selected_report.is_able_to_edit"
                                                @removeChild="removeChild"
                                            />

                                            <ChartCanvas
                                                v-if="item.item_id !=='' && item.type==='chart'"
                                                class="mb-5"
                                                :chart_id="item.item_id" 
                                                :key="`chart_${item.item_id}`"
                                                :lv2_info="lv2_info"
                                                :lv3_idx="index"
                                                :edit_mode="true"
                                                :dc_key_info="[lv1_info, lv2_info, item]"
                                                :is_able_to_edit="selected_report.is_able_to_edit"
                                                @removeChild="removeChild"
                                            />
                                            
                                            </div>
                                        <hr v-if="index < lv2_info.children.length-1" >
                                    </div>
                                    </div>
                                    <div style="min-width: 10vh" :class="selected_report.is_able_to_edit === true? 'hover-border' : ''">
                                        <DataTableBtn
                                            :is_able_to_edit="selected_report.is_able_to_edit"
                                            :dc_key_info="[lv1_info, lv2_info]"
                                        />
                                        <ChartBtn 
                                            :is_able_to_edit="selected_report.is_able_to_edit"
                                            :dc_key_info="[lv1_info, lv2_info]"
                                        />
                                    </div>

                            </b-td>
                        </div>
                    </b-tr>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { gri_mixin } from "@/components/writing_report/write_report_subs/gri_mixin";
import draggable from "vuedraggable";
import ChartCanvas from "@/components/writing_report/chart_subs/ChartCanvas.vue";
import DataViewer from "@/components/managing_indicators/data_centers/DataViewer.vue";
import DataCenterForm from "@/components/managing_indicators/data_centers/DataCenterForm.vue";



// import {mapState, mapActions, mapGetters} from 'vuex'
// import BusinessCaseList from '@/components/writing_report/business_case_subs/BusinessCaseList'
// import ManageBusinessCase from '@/components/writing_report/business_case_subs/ManageBusinessCase'
import MixedChart from '@/components/writing_report/chart_subs/MixedChart'


import DataCenterToC from '@/components/managing_indicators/data_centers/DataCenterToC'
// import DefaultChart from '@/components/writing_report/chart_subs/DefaultChart'
// import DefaultChart2 from '@/components/writing_report/chart_subs/DefaultChart'



// import DefaultChart from '@/components/writing_report/chart_subs/DefaultChart'
// import DefaultChart from '@/components/writing_report/chart_subs/DefaultChart'
// import DefaultChart from '@/components/writing_report/chart_subs/DefaultChart_copy3'
import { generateRandomString } from "@/components/util";
import ChartBtn from "@/components/writing_report/chart_subs/ChartBtn";
import DataTableBtn from "@/components/writing_report/chart_subs/DataTableBtn";

export default {
    name: '',
    mixins: [gri_mixin],
    props: {},
    computed: {
        ...mapState('charts', ['chart_data', 'toc_list']),
    },
    components: {
        MixedChart,
        DataCenterToC,
        // DefaultChart,
        // DefaultChart2,
        draggable,
        ChartBtn,
        DataTableBtn,
        ChartCanvas,
        DataViewer,
        DataCenterForm
    },
    data() {
        return {
            example: '',
            chart_case_id: '',
            select_default_chart: true,
            is_writing_direct: {}
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        // this.getDCToC({"report_code": this.report_code});
        // this.getChartList({
        //     report_code: this.report_code,
        //     is_editing: true,
        // });
    },
    beforeDestroy() {
        if (this.report_code !== '') {
            this.getChartList({
                report_code: this.report_code,
                is_editing: false,
            });
        }
    },
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {
        ...mapActions("charts", ["getChartList", "updateDCToC"]),
        initiateWritingMode() {
            this.is_writing_direct = {};
        },
        cancleEdit_2() {
            this.$refs['edit-chart-case-2'].hide()
        },
        generateRandomString,
        ...mapActions("charts", ["getDCToC"]),
        cancleEdit(){
            this.$refs['edit-chart-case'].hide()
        },
        addOrEditChartCase() {
            this.$refs['edit-chart-case-2'].show()
        },
        cancleEdit_3(){
            this.$refs['edit-chart-case-3'].hide()
        },

        addOrEditChartCase_2() {
            this.$refs['edit-chart-case-3'].show()
        },
        addChildren(lv_info) {
            
            lv_info.children.push({
                "key": this.generateRandomString(10),
                "contents": "",
                "type": "",
                "item_id": ""
            })

            this.updateChartList();
        },
        editChild(lv2_info, index) {
            lv2_info.children.splice(index, 1)
            this.updateChartList();
        },
        removeChild(lv2_info, index) {
            lv2_info.children.splice(index, 1)
            this.updateChartList();
        },

        shiftUp(lv2_info, index) {
            const elementToMove = lv2_info.children.splice(index, 1)[0];
            lv2_info.children.splice(index-1, 0, elementToMove);
            this.updateChartList();
        },
        shiftDown(lv2_info, index) {
            const elementToMove = lv2_info.children.splice(index, 1)[0];
            lv2_info.children.splice(index+1, 0, elementToMove);
            this.updateChartList();
        },

        updateChartList() {
            this.updateDCToC({
                "report_code": this.report_code,
                "contents": this.toc_list
            })

            // this.getChartList({
            //     report_code: this.report_code,
            //     is_editing: true
            // });

            this.initiateWritingMode()

        },
        updateChartContents(payload) {
            this.temp_toc_list = [...this.toc_list]
            for (const lv1_info of this.temp_toc_list) {
                if (lv1_info.key === payload.lv1_key) {
                    for (const lv2_info of lv1_info.children){
                        if (lv2_info.key === payload.lv2_key) {
                            for (const lv3_info of lv2_info.children) {
                                if (lv3_info.key === payload.lv3_key) {
                                    lv3_info.contents = payload.contents;
                                    break;
                                }
                            }
                            break;
                        }
                    }
                    break;
                }
            }

            this.updateDCToC({
                "report_code": this.report_code,
                "contents": this.temp_toc_list
            });
            this.initiateWritingMode();
            
        }

    }
}
</script>
<style>
</style>
<template>
<div>
    <b-container :class="preview_mode==true?'p-5 bg-white rounded':'px-5 py-5 bg-white'" class="min-vh-100"> 
        <b-card class="px-3">

            <template v-if="assurance_list_loaded===true">
                <!-- <template  v-if="!$route.query.l || $route.query.l.toUpperCase()==='KOR'"> -->
                <template  v-if="lang.toUpperCase()==='KOR'">
                    <md class="statement-content" :contents="latest_assurance.report_statement"></md>
                    <div class="pt-5 f-90 d-flex flex-column align-items-end mr-5"> 
                        <div class="mb-4" style="width: 135px;"> {{ latest_assurance.report_assurance_date.slice(0,4) }}년 {{ latest_assurance.report_assurance_date.slice(5,7) }}월 </div>
                        <div class="border-bottom-dark fw-300 pb-1 mb-3 d-flex justify-content-between align-items-end" style="width: 135px;"> CEO <span class="fw-500 f-105">배 익 현</span></div>
                        <div class="mb-5 text-right" style="width: 135px;"> (주)퀀티파이드이에스지 </div>
                    </div>
                    <hr class="">
                    <div class="d-flex">
                        <b-img
                            v-if="latest_assurance && latest_assurance.verification_img_path"
                            :src="$store.state.static_host+latest_assurance.verification_img_path"
                            class="mr-4" 
                            style="width:180px;" 
                        />
                        <b-img
                            v-else
                            src="/static_img/aa1000as.jpg" 
                            class="mr-4" 
                            style="width:180px;" 
                        />


                        <b-img src="/static_img/qesg.svg" class="" style="width:150px;" />
                    </div>           
                </template>

                <template v-else>
                    <md class="statement-content" :contents="report_statement_e"></md>
                    <div class="pt-5 f-90 d-flex flex-column align-items-end mr-5"> 
                        <div class="mb-4" style="width: 128px;"> {{ getEngMonth(latest_assurance.report_assurance_date.slice(5,7)) }} <span class="ml-1">{{ latest_assurance.report_assurance_date.slice(0,4) }}</span></div>
                        <div class="border-bottom-dark fw-300 pb-1 mb-3 d-flex justify-content-between align-items-end" style="width: 128px;"> CEO <span class="fw-500 f-105">Ikhyun Bae</span></div>
                        <div class="mb-5 text-right" style="width: 128px;"> QuantifiedESG, Inc. </div>
                    </div>
                    <hr class="">
                    <div class="d-flex">
                        <b-img
                            v-if="latest_assurance && latest_assurance.verification_img_path"
                            :src="$store.state.static_host+latest_assurance.verification_img_path"
                            class="mr-4" 
                            style="width:180px;" 
                        />
                        <b-img
                            v-else
                            src="/static_img/aa1000as.jpg" 
                            class="mr-4" 
                            style="width:180px;" 
                        />
                        <b-img src="/static_img/qesg.svg" class="" style="width:150px;"></b-img>
                    </div>
                </template>
            </template>
            <template v-else>
                <b-spinner align="center"></b-spinner>
            </template>

        </b-card>
    </b-container>
</div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
    data() {
        return {
          
        }
    },
    props:{
        preview_mode: {type:Boolean, default: false}
    },
    computed:{
        ...mapState('report_list', ['report_code', 'lang']),
        ...mapState('assurance', ['assurance_list', 'assurance_list_loaded']),
        ...mapGetters('assurance', ['latest_assurance']),
        report_statement_e(){
            if(this.assurance_list_loaded == false) { return '' }

            if(this.latest_assurance.report_statement_em!=''&&this.latest_assurance.report_statement_em!=null)
            {
                return this.latest_assurance.report_statement_em
            } 
            else 
            {
                return this.latest_assurance.report_statement_ea
            }
        }
    },
    methods:{
        getEngMonth(month){
            if (month=='01') return 'January'
            if (month=='02') return 'February'
            if (month=='03') return 'March'
            if (month=='04') return 'April'
            if (month=='05') return 'May'
            if (month=='06') return 'June'
            if (month=='07') return 'July'
            if (month=='08') return 'August'
            if (month=='09') return 'September'
            if (month=='10') return 'October'
            if (month=='11') return 'November'
            if (month=='12') return 'December'
        },

    },
    mounted(){
        // console.log(this.latest_assurance)
        // if(this.latest_assurance.report_statement_em!=''&&this.latest_assurance.report_statement_em!=null){
        //     this.report_statement_e = this.latest_assurance.report_statement_em
        // }
        // else this.report_statement_e = this.latest_assurance.report_statement_ea

    }

}
</script>

<style scoped>

.statement-content {
    white-space: normal;
}

.statement-content >>> img{
    max-width: 100%;
}

.statement-content >>> table > thead > tr> th{
    padding: 0.25rem 0.5rem;
    border-top: 1px solid var(--gray-400);
    border-left: 1px solid var(--gray-400);
    background-color: var(--gray-300);
}

.statement-content >>> table > tbody > tr > td,
.statement-content >>> table > tbody > tr > th{
    padding: 0.25rem 0.5rem;
    border-top: 1px solid var(--gray-400);
    border-left: 1px solid var(--gray-400);
}
.statement-content >>> table > tbody > tr:last-child > td{
    border-bottom: 1px solid var(--gray-400);
}
.statement-content >>> table > tbody > tr > th:last-child,
.statement-content >>> table > tbody > tr > td:last-child{
    border-right: 1px solid var(--gray-400);
}
.statement-content >>> table > tbody > tr > th{
    background-color: var(--gray-300);
}
.statement-content >>> table > tbody > tr > td{
    background-color: white;
}
.statement-content >>> table > tbody > tr > td> p{
    margin-bottom: 0;
}

.statement-content >>> h6{
    margin-top: 30px;
    font-size: 1.25rem;
}
.statement-content >>> h5{
    margin-top: 30px;
    font-size: 1.5rem;
}
.statement-content >>> h4{
    margin-top: 30px;
    font-size: 1.75rem;
}
.statement-content >>> h3{
    margin-top: 30px;
    font-size: 2rem;
}
.statement-content >>> h2{
    margin-top: 30px;
    font-size: 2.5rem;
}
.statement-content >>> h1{
    margin-top: 30px;
    font-size: 3rem;
}

.statement-content >>> ol, 
.statement-content >>> ul{   
    padding-inline-start: 20px;
}
</style>
<template>
    <div>

    <h6 class="pb-1 mb-3 border-bottom-dark">
      추가 서비스 결제 목록
    </h6>

    <div v-if="products === null" class="position-relative p-4">
        <b-overlay :show="products === null" no-wrap></b-overlay>
    </div>

    <div v-else>
        <div v-if="products.length > 0">
            <div v-for="(product_info, product_idx) in products" :key="product_idx">
                <ProductCard
                    :product_info = product_info
                />
            </div>
        </div>
        <div v-else>
            <h5>
            추가 결제가 필요한 서비스가 없습니다.
            </h5>
        </div>
    </div>




    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import ProductCard from "@/components/payment/ProductCard";

export default {
    name: '',
    props: {},
    components: {
        ProductCard
    },
    computed: {
        ...mapState(['e_mail', 'user_name', 'tel', 'subscription', 'is_confirmed']),
        ...mapState('custom_products', ['products'])
    },
    data() {
        return {
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.fetchProducts(this.e_mail);
    },
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {
        // general_product
        ...mapActions("custom_products", ["fetchProducts"]),

    }
}
</script>
<style>
</style>
<template>
<div>
        <b-card no-body class="mb-4 shadow-sm">  
            <b-card-header class="p-1 bg-white py-2 px-3 d-flex justify-content-between align-items-center" > 
                <div class="py-1 fw-600">산업 카테고리</div>
                <b-button variant="icon-teal" class="py-1 px-2" @click="pageGo({name: 'GetSasbRecommendations'})" v-b-tooltip.hover title="산업 및 보고 지표 추천받기"> <fa icon="magic"/></b-button>
            </b-card-header>
            <div class="bgray-100 py-2">
                <template>
                    <b-button 
                        v-for="item in sector_list"
                        :key="item.id"
                        block 
                        variant="icon-gray" 
                        class="text-left pl-3 f-95 py-1 mt-0" 
                        @click="navAnchor(`#sasb-section-${item.id}`)"
                    > 
                         {{ item.sasb_sector_name }}
                    </b-button>


                </template>
            </div>
            <!-- <b-card-footer class="p-1 teal-hover py-2 px-3 cursor text-white text-center" @click="allIndicatorsDecided">지표 선택 완료하기 -->
            <!-- </b-card-footer> -->
            <!-- <b-card-footer v-else class="p-1 teal-hover py-2 px-3 cursor text-white text-center" :disable="is_saving_topics==true" @click="handleSaveTopics">지표 저장하기
            </b-card-footer> -->

        </b-card>

        <!-- <side-material-check ></side-material-check> -->


        <!-- <b-modal ref="check-selected" size="lg" hide-footer scrollable title="지표 선택 완료하기"> 
            <check-selected-indicators @complete-select="completeSelect"></check-selected-indicators>
        </b-modal> -->

        <back-to-top></back-to-top>

</div>    
</template>
<script>
import SideMaterialCheck from '@/components/managing_indicators/indicator2016_side_subs/SideMaterialCheck'

import { mapState, mapGetters, mapActions } from 'vuex'
import * as ih from '@/components/util'
import CheckSelectedIndicators from '@/components/managing_indicators/indicator2016_side_subs/CheckSelectedIndicators.vue'
import SideRequirementsCheck from '@/components/managing_indicators/gri2021_indicators/SideRequirementsCheck'

export default {
    data() {
        return {
            is_saving_topics: false,
            sector_list: null
        }
    },
    computed:{
        ...mapState('report_list', ['report_code', 'standard_mode']),
        ...mapGetters('report_indicators', ['gri_200to400_all_topics']),
        ...mapGetters('report_list', ['selected_report', 'gri_ver', 'tcfd_ver']),
        current_material_topics(){
            return this.gri_200to400_all_topics.filter(x => x.material==1)
        }                   
    },
    methods:{
        ...mapActions('report_list', ['saveReportPreference']),
        ...mapActions('report_indicators',['readSelectedTopics']),
        ...mapActions('report_contents', ['readReportContents', 'saveDisclosure']),
        ...mapActions('data_tables', ['readDataTableList']),
        ...mapActions('documents', ['readDocumentList']),
        ...mapActions('business_cases', ['readBusinessCaseList']),
        ...mapActions('gri2021_indicators',['saveLinkTopics', 'saveLinkIndicators']),
        pageGo(to){
            if(confirm('지표 추천받기로 돌아가면 선택된 지표가 모두 초기화됩니다. 추천 받기로 돌아가시겠습니까?') == true){
                this.$router.push(to)
            }
        },
        allIndicatorsDecided() {
            if (!this.selected_report.is_able_to_edit) {
                window.alert("정기 결제를 해야 합니다.")
                return
            }
            // if (this.selected_report.state<4){
                if(this.checkMaterialExist()==true) { //1. 중요주제 1개 이상 선택되었는지 확인 
                    this.readSelectedTopics({report_code: this.report_code}) //2. 현재 선택된 지표 전체를 모달로 보여주기
                    this.$refs['check-selected'].show()
                }
                else alert('중요 주제를 1개 이상 선택해주세요')
            // }else alert(`공개된 보고서 입니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 지표를 수정할 수 있습니다.`)
        },
        async completeSelect(){            
            this.$refs['check-selected'].hide()
            if (this.selected_report.state <= 1){
                // console.log(this.selected_report.state);
                await this.saveReportPreference({report_code: this.report_code, state: 2})
                await this.saveDisclosure({report_code: this.report_code, gri_code: '102-47', direct_report : this.current_material_topics.map(x => x.topic_name).join(', ')})
                await this.saveDisclosure({report_code: this.report_code, gri_code: '102-50', direct_report : this.selected_report.start_date+' ~ '+this.selected_report.end_date})
                await this.saveDisclosure({report_code: this.report_code, gri_code: '102-55', direct_report : '본 보고서'})
            }
            this.readReportContents({report_code: this.report_code})
            this.readDataTableList({report_code: this.report_code})
            this.readDocumentList({report_code: this.report_code})
            this.readBusinessCaseList({report_code: this.report_code})
            this.$router.push({name: 'Gri100'})
            this.$EventBus.$emit('make-toast', '지표 선택 완료', '전체 지표 선택이 완료되었습니다.')
        },
        navAnchor(anchor) {
            ih.anchorGo(anchor)
        },
        checkMaterialExist(){
            if(this.current_material_topics.length > 0){
                return true
            }else return false
        },
        async handleSaveTopics() {
            if (this.selected_report && !this.selected_report.is_able_to_edit) {
                window.alert("정기 결제를 해야 합니다.")
                return
            }
            this.is_saving_topics = true
            if (this.selected_report.state <= 1){
                // console.log(this.selected_report.state);
                await this.saveReportPreference({report_code: this.report_code, state: 2})
                // await this.saveDisclosure({report_code: this.report_code, gri_code: '102-47', direct_report : this.current_material_topics.map(x => x.topic_name).join(', ')})
                // await this.saveDisclosure({report_code: this.report_code, gri_code: '102-50', direct_report : this.selected_report.start_date+' ~ '+this.selected_report.end_date})
                // await this.saveDisclosure({report_code: this.report_code, gri_code: '102-55', direct_report : '본 보고서'})
            }
            await this.saveLinkTopics(this.report_code)
            this.saveLinkIndicators(this.report_code)
            this.$EventBus.$emit('make-toast', '지표 선택 완료', '전체 지표 선택이 완료되었습니다.')
            this.is_saving_topics = false            
        },
        async fetchSectorIndustryList() {
            const response = await axios.get(
                `${this.$store.state.backend_host}/sasb/get_sector_list?keyword=simple`
            );
            this.sector_list = response.data.result;
        },


        
    },
    mounted(){
        // console.log(this.current_material_topics)
    },
    created() {
        this.fetchSectorIndustryList();  
    },
    components:{
        SideMaterialCheck,
        CheckSelectedIndicators,
        SideRequirementsCheck
    }
}
</script>
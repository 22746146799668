<template>
    
<div id="custom-body" :style="'font-family: '+this.selected_report.font_family">
<!-- <b-overlay :show="loadingShow" no-wrap></b-overlay> -->

<!-- <iframe id="myIframe" height="500" style="width: 100%;" scrolling="no" title="QESG-report" src="https://codepen.io/qxbgypaz-the-sasster/embed/preview/LYMEREm?default-tab=html%2Cresult&editable=true" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true">
  See the Pen <a href="https://codepen.io/qxbgypaz-the-sasster/pen/LYMEREm">
  QESG-report</a> by 김소영 (<a href="https://codepen.io/qxbgypaz-the-sasster">@qxbgypaz-the-sasster</a>)
  on <a href="https://codepen.io">CodePen</a>.
</iframe> -->

    <div class="custom-header mt-5"> 
        <b-navbar toggleable="lg" class="custom-navigator">
            
            <b-navbar-brand v-if="selected_report.logo_path!=null">
                <b-img :src="$store.state.static_host+selected_report.logo_path" class="report-logo-xs"></b-img>
            </b-navbar-brand>
            <div v-if="content_list_open==true" class="ml-4">{{ lang!='ENG'? selected_report.report_name : selected_report.report_name_e }}</div>

            <b-navbar-toggle target="preview-tools"></b-navbar-toggle>
            <b-collapse id="preview-tools" is-nav>
                <contents-list-indicator v-if="show_nav==true" class="ml-auto"></contents-list-indicator>
            </b-collapse>

            <b-navbar-nav :class="content_list_open!=true&&show_nav==true?'ml-auto':'ml-3 ml-xl-4'" class="flex-row">
                <b-button variant="dark-border" size="lg" class="p-0 mr-2 preview-tool-btn" v-print="'#full-report-preview'"
                v-b-tooltip.hover :title="lang!='ENG'? '출력하기':'Print to PDF'"> <fa icon="print"/></b-button>
                <b-button variant="dark-border" size="lg" class="p-0 mr-2 preview-tool-btn" @click="toggleLang" v-b-tooltip.hover :title="lang!='ENG'?'영문보기':'Korean'"> <fa icon="language"/></b-button>  
                <b-button v-if="view_mode!='viewer'" variant="dark-border" size="lg" class="p-0 mr-2 preview-tool-btn" @click="$router.push({name: 'CheckReport'})" v-b-tooltip.hover title="스타일편집"> <fa icon="times"/></b-button> 
                <b-button @click="showEditor" variant="dark-border" size="lg" class="p-0 mr-2 preview-tool-btn" v-b-tooltip.hover title="보고서 스타일 편집하기"><b-icon
                    icon="pencil-fill"
                    scale="0.8"
                  ></b-icon
                ></b-button> 
            
            </b-navbar-nav>
            
  
        </b-navbar>       
        
        <div style="display: flex; height: 100vh; overflow: hidden;">
            <div style="flex:1; padding: 10px; padding-top: 8vh; overflow: auto; ">
                <b-form-textarea
                id="textarea"
                v-model="custom_css_text"
                placeholder="Enter something..."
                rows="12"
                max-rows="30"
                ></b-form-textarea>
                
                <b-button @click="saveCurrentCssOnce" variant="primary" v-b-tooltip.hover title="현재 미리보기 내 스타일이 적용됩니다." >저장하기</b-button>
                
                <span>
                    <b-button v-if="!auto_save" @click="toggleAutoSavingCss" variant="teal" v-b-tooltip.hover title="5초 간격으로 자동 저장/적용됩니다.">
                        자동저장 
                    </b-button>
                    <b-button v-else variant="teal" @click="toggleAutoSavingCss" v-b-tooltip.hover title="자동 저장 기능을 off합니다.">
                        자동저장 off 
                        <b-icon icon="circle-fill" animation="throb" font-scale="1"></b-icon>
                    </b-button>

                </span>
                
            </div>
    
            <!-- 표지
                <div class="position-relative" style="height:105vh" :style="main_bg_color_gradient" @scroll="handleContentList">
                    <b-container class="text-center d-flex justify-content-center align-items-center flex-column position-relative" :style="content_list_open!=true?'height: 80vh;':'height: 40vh;'">
                        <h1 v-if="content_list_open!=true" class="fw-600 f-290 line-height-lg">{{ lang!='ENG'? selected_report.report_name : selected_report.report_name_e }}</h1>
                        <h6 v-if="content_list_open!=true" class="">{{ selected_report.start_date }} ~ {{ selected_report.end_date }}</h6>
                    </b-container>
                    <div class="text-right w-100">
                        <b-button @click="content_list_open=!content_list_open" class="float-right f-90 d-none d-md-block" style="border-radius: 0.25rem 0 0 0.25rem ;!important">
                            <template v-if="lang!='ENG'">{{ content_list_open!=true? '목차 보기':'목차 닫기'}}</template>
                            <template v-else>{{ content_list_open!=true? 'See Full Contents':'Close'}}</template>
                        </b-button>
                        <contents-list-basic :preview_mode="true" :web_view="true" :content_list_open="content_list_open"></contents-list-basic>
                    </div>
                </div>
            -->
            
            <div id="custom-preview" style="flex: 1; overflow: auto;">
                <b-container class="py-5 bg-white" v-view="viewHandlerNav"> 
                    <!-- <b-row>
                        <b-col class="col-3">
                            <viewer-side-nav></viewer-side-nav>
                        </b-col>
                        <b-col class="col-9" v-view="viewHandlerNav"> -->
                            <!-- <gri-table :modal_table="false" ></gri-table> -->
                            <FullView2021 />
                        <!-- </b-col>
                    </b-row> -->
                    <hr class="custom-hr">
                </b-container>    
                <hr class="custom-hr">
        
                <div class="custom-bottom-style">
                    <div class="tail-line" style="width: calc(100% - 200px);">{{lang!='ENG'? selected_report.report_name : selected_report.report_name_e}} </div>
                    <div v-if="selected_report.logo_path!=null"><b-img :src="$store.state.static_host+selected_report.logo_path" class="report-logo-small"></b-img></div>
                </div>
            </div>



        </div>
    </div>

    <!-- 인쇄영역 -->
    <div class="position-absolute w-100" style="left: 0;top:0; margin-left:2000px;">
        인쇄
        <div id="full-report-preview">
            <b-container class="px-6 vh-100">
                <b-row no-gutters class="h-100">
                    <b-col cols="6" class="pl-3">
                        <h1 class="f-300 mb-5 line-height-lg" style="padding-top: 10vh">{{ lang!='ENG'? selected_report.report_name : selected_report.report_name_e }}</h1>
                        <h6 class="">{{ selected_report.start_date }} ~ {{ selected_report.end_date }}</h6>
                    </b-col>
                    <b-col class="d-flex justify-content-end align-items-end pr-5">
                        <div v-if="selected_report.logo_path!=null" style="padding-bottom: 7vh"><b-img :src="$store.state.static_host+selected_report.logo_path"  class="report-logo-regular"></b-img></div>
                    </b-col>
                </b-row>
            </b-container>
                
            <div class="bgray-200">
                <b-container class="px-5 py-5 min-vh-100">
                    <b-card class="p-4 my-auto">
                        <h3 class="mb-5">Contents</h3>
                        <contents-list-basic></contents-list-basic>
                    </b-card>
                </b-container>
            </div>

            <b-container class="px-5 py-5"> 
                <gri-table :modal-table="false" :print_mode="true"></gri-table>
                <hr class="custom-hr">
            </b-container>    

            <b-container class="py-5 px-5 bgray-100" v-if="assurance_status==6">
                <!-- <template>
                    <h3 id="rpb0" class="pt-3 mb-5">Appendix {{show_table_list==true?'2.': '1.'}} {{ lang!='ENG'?'비즈니스 사례': 'Business Cases' }}</h3>
                    <appendix-business-cases class="pt-3"></appendix-business-cases>
                    <hr class="mt-5 pb-5">
                </template>     -->
                
                <template>
                    <h3 id="rpa0" class="pt-3 mb-5">Appendix. {{ lang!='ENG'?'제3자 검증의견서': 'Independent Assurance Statement' }}</h3>
                    <view-assurance-statement :preview_mode="true"></view-assurance-statement>
                </template> 
            </b-container>
            
            <!-- <b-container class="py-5 px-5 bgray-100">
                <template>
                    <h3 id="rpt0" class="pt-3 mb-5">Appendix 1. {{ lang!='ENG'?'데이터 테이블': 'Data Tables' }}</h3>
                    <appendix-data-tables></appendix-data-tables>
                    <hr class="mt-5 pb-5">
                </template>            

               
            </b-container>  -->

            <hr class="custom-hr">

            <div class="custom-bottom-style">
                <div class="tail-line" style="width: calc(100% - 200px);">{{lang!='ENG'? selected_report.report_name : selected_report.report_name_e}} </div>
                <div v-if="selected_report.logo_path!=null"><b-img :src="$store.state.static_host+selected_report.logo_path"  class="report-logo-small"></b-img></div>
            </div>
        </div>
    </div>
</div>
</template>
    
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import * as ih from '@/components/util'
import DataTable from './viewer_components/DataTable.vue'
import Document from './viewer_components/Document.vue'
import BusinessCase from './viewer_components/BusinessCase.vue'
import MaterialityBadge from './viewer_components/MaterialityBadge.vue'
import ContentsListBasic from './viewer_components/ContentsListBasic.vue'
import ContentsListIndicator from './viewer_components/ContentsListIndicator.vue'
import GriTable from './viewer_components/GRITable2021Custom.vue'
import AppendixDataTables from './viewer_components/AppendixDataTables.vue'
import AppendixBusinessCases from './viewer_components/AppendixBusinessCases.vue'
import ViewAssuranceStatement from '@/components/report_preview/appendix_subs/ViewAssuranceStatement'
import ViewerSideNav from '@/components/report_preview/gri2021_viewer/viewer_components/ViewerSideNav.vue'
import FullView2021 from "@/components/report_preview/gri2021_viewer/FullView2021";


export default {
    components:{
        DataTable,
        Document,
        BusinessCase,
        MaterialityBadge,
        ContentsListBasic,
        ContentsListIndicator,
        "gri-table" : GriTable,
        AppendixDataTables,
        AppendixBusinessCases,
        ViewAssuranceStatement,
        ViewerSideNav,
        FullView2021
    },
    data() {
        return {
            showEditor: false,
            low_height: false,
            show_nav: true,
            content_list_open: false,
            custom_css_text: "",
            loadingShow: true,
            auto_save: false,
            auto_save_interval_id: null,
            theme_code: null
        }
    },    
    props:{
        show_table_list: { type: Boolean, default: false },
        show_business_case_list: { type: Boolean, default: false },
        reload: { type: Boolean, default: false }
    },
    computed:{
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapState('gri2021_report_view', ['report_structure', 'report_contents_loaded']),
        ...mapGetters('gri2021_report_view', ['report_topics', 'report_categories', 'report_topic_specific_categories', 'report_universal_categories', 'report_topic_types']),
        ...mapGetters('report_list', ['selected_report']),
        ...mapState('assurance',['assurance_status']),
        main_color(){
            return `color:`+this.selected_report.main_color
        },
        main_bg_color(){
            return `background-color:`+this.selected_report.main_color+`;important!`
        },
        main_color_sub(){
            return `color:`+this.selected_report.main_color+`; border-top: 1px solid `+this.selected_report.main_color+`!important`+`; border-bottom: 1px solid `+this.selected_report.main_color
        },
        main_bg_color_gradient(){
            return `background:linear-gradient(0deg, `+this.selected_report.main_color+` -50%, rgba(239,239,239,1) 60%);important!`
        }
    },
    methods: {
        ...mapMutations('gri2021_report_view', ['update_report_contents_loaded']),
        ...mapMutations('report_list', ['change_lang']),
        ...mapActions('gri2021_report_view', ['readReportContents']),
        ...mapActions('data_tables', ['readDataTableList']),
        ...mapActions('business_cases', ['readBusinessCaseList']),
        ...mapActions('documents', ['readDocumentList']),
        ...mapActions('assurance', ['readAssuranceStatusByReportCode']),
        initCss() {


            const beforeStyledSheet = document.getElementsByClassName("customized-css-styling")
            if (beforeStyledSheet.length > 0) {
                for (const element of beforeStyledSheet) {
                    element.remove()
                }
            }

            axios.post(process.env.VUE_APP_BACKENDHOST + '/custom_theme/add_custom_theme', {
                "report_code": this.selected_report.report_code
            }).then((result) => {
                this.custom_css_text = result.data.css_content;
                this.theme_code = result.data.theme_code;
                const styleElement = document.createElement('style');
                styleElement.classList.add("customized-css-styling");

                styleElement.innerHTML = this.custom_css_text;
                document.head.appendChild(styleElement);
                this.loadingShow = false;
            })
        },
        saveCurrentCssOnce() {
            console.log("saveCurrentCssOnce")
            // 일단 저장한다.
            axios.post(process.env.VUE_APP_BACKENDHOST + '/custom_theme/update_custom_theme', {
                "theme_code": this.theme_code,
                "css_contents": this.custom_css_text
            }).then((result) => {
                // 저장 콜백 후에 head에 다시 가져와서 추가한다.
                this.custom_css_text = result.data;

                const beforeStyledSheet = document.getElementsByClassName("customized-css-styling")
                if (beforeStyledSheet.length > 0) {
                    for (const element of beforeStyledSheet) {
                        element.remove()
                    }
                }
                const styleElement = document.createElement('style');
                styleElement.classList.add("customized-css-styling");

                styleElement.innerHTML = this.custom_css_text;
                document.head.appendChild(styleElement);














                // const styleElement = document.createElement('link');
                // styleElement.rel = 'stylesheet';
                // styleElement.type = 'text/css';
                // styleElement.href = process.env.VUE_APP_STATICHOST + `custom_theme/${this.theme_code}.css`
                // // <head> 요소 찾기
                // const headElement = document.head || document.getElementsByTagName('head')[0];
                // const existingLinkElement = document.querySelectorAll('head link[href="' + process.env.VUE_APP_STATICHOST + `custom_theme/${this.theme_code}.css"]`);

                // // link 요소를 <head>에 추가
                // if (existingLinkElement.length > 0) {
                //     // css파일을 2개 호출한 상태를 유지함
                //     // 1개인 상태로 유지하고싶지만.. 그러면 로드가 늦어졌을 때 화면이 자꾸 깜빡거리게 됨(로컬기준이긴 함)
                //     headElement.removeChild(existingLinkElement[0])
                //     // headElement.appendChild(styleElement);
                //     // document.querySelectorAll("head link")[document.querySelectorAll("head link").length-2].remove()
                //     headElement.appendChild(styleElement);
                // } else {
                //     headElement.appendChild(styleElement);
                // }
                // // headElement.appendChild(styleElement);








                this.loadingShow = false;
                this.$EventBus.$emit('make-toast', '테마 저장', '테마가 저장되었습니다.')
                this.loadingShow = true;
            })
        },
        toggleAutoSavingCss(){
            this.auto_save = !this.auto_save


            if (this.auto_save && !this.auto_save_interval_id) {
                this.auto_save_interval_id = setInterval(() => {
                    this.saveCurrentCssOnce(); 
                }, 5000);
            } 

            if (!this.auto_save) {
                clearInterval(this.auto_save_interval_id);
            }


        },
        toggleLang(){
            if (this.lang=='KOR'){ //현재 국문이면 영문으로 바꾸기
                this.change_lang('ENG')
            }else this.change_lang('KOR')
        },
        viewHandlerNav(event){
            // console.log(event);
            if(event.type != 'progress'&&event.scrollPercent > 0.06) {
                // if( event.percentCenter >3.5 ) { 
                    this.show_nav = true
                // }
            }
            else this.show_nav = false
        },
        handleContentList(event){
            // console.log(event);
            if(window.scrollY > 0 && window.innerWidth > 800){
                this.content_list_open = true
            }else this.content_list_open = false
        }
    },
    created() {
        this.initCss()


        if(this.reload==true){
            this.update_report_contents_loaded(false)
        }

        if(this.report_contents_loaded != true){
            //console.log('FULL_VIEW_LOAD')
            this.readAssuranceStatusByReportCode({report_code: this.report_code})
            this.readReportContents({report_code : this.report_code})
            this.readDataTableList({report_code: this.report_code})
            this.readBusinessCaseList({report_code: this.report_code})
            this.readDocumentList({report_code: this.report_code})
        }

        // const editor = new InlineStyleEditor();
        // document.body.addEventListener('click', (e) => {
        //     const target = e.target;
        //     editor.open(target, e.pageX, e.pageY);
        // });

    },
    mounted() {
        
        // setInterval(() => {
        //     this.$EventBus.$emit('make-toast', '테마 저장', '테마가 저장되었습니다.')

        // }, 10000)

        window.addEventListener('scroll', this.handleContentList)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleContentList)
    },
}
let lastScroll = document.documentElement.scrollTop || 0
document.addEventListener('scroll', function(){
  let scrollTop = document.documentElement.scrollTop
  if(scrollTop > lastScroll) {
    // down
  } else {
    //up
  }
  lastScroll = scrollTop
})
</script>

<style>

</style>
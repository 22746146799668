<template>
  <div>
    <b-button
      v-if="!translate_view"
      variant="light"
      class="py-1 px-2"
      @click="openCustomMaterialModel"
    >
    +사용자 정의 중요 주제 목록 생성
    </b-button>
    <div v-if="this.add_topic_list.length > 0">
        <div class="border-bottom-gray-550 gray-600 f-90 pb-1"> </div>
        <div :class="true? 'mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center' : ''">
            &nbsp;
            <div 
                v-if="!translate_view"
                style="min-width: 70px;"
            >
                <b-button variant="light" class="py-1 px-2" v-b-tooltip.hover title="편집"  @click="openCustomMaterialModel" ><fa icon="pen"/></b-button>
                <b-button variant="light" class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click="save_custom_topic(true)" ><fa icon="unlink"/></b-button>
            </div>
        </div>
        <b-table-simple responsive class="bor-bg-table">
            <b-thead>
                <b-tr>
                    <b-td>우선순위</b-td>
                    <b-td>중요 주제명</b-td>
                    <b-td>중요 주제와 연관된 GRI 지표</b-td>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="(item, idx) in add_topic_list" :key="idx">
                    <b-td>
                        {{  idx+1 }}
                    </b-td>
                    <b-td>{{ item.custom_topic_name }}</b-td>
                    <b-td>

                        <span v-for="(gri_code) in sort_indicator_arr(item.checked_indicators)" :key="gri_code">
                            <a href="#" @click="goHash(gri_code)">{{ gri_code }}</a>&nbsp;
                        </span>
                    </b-td>
                    <hr>
                    <!-- <template v-for="property in hashed_data_tables[dt].header"> -->
                        
                        <!-- <b-td v-if="differentPrevious(hashed_data_tables[dt].contents, row_index, property, hashed_data_tables[dt].header)==true" :key="property.id" :rowspan="getSameLength(hashed_data_tables[dt].contents, row_index, property, hashed_data_tables[dt].header)">
                            {{ row_item[property] }}
                        </b-td> -->
                    <!-- </template> -->
                </b-tr>
            </b-tbody>
        </b-table-simple> 

    </div>
    

    <b-modal
      @hidden="selected_gri_code_list"
      id="custom-material-popup"
      size="xl"
      scrollable
      hide-footer
      title="사용자 정의 중요 주제 목록 만들기"
    >
        <b-overlay :show="loadingShow" no-wrap></b-overlay>

      <div>
        <h5 class="fw-600">사용자 정의 중요 주제 목록</h5>
        <b-card class="f-95 mb-45 gray-600">
          보고 조직은 중요 주제를 정의하고 공개하는 데 있어 GRI Topic 중심으로 중요 주제를 선정할 수 있으나,
          필요에 따라 하나의 중요 주제에 대해 여러 GRI Topic을 연계하여 보고할 수 있습니다. 
          보고 조직이 결정한 중요 주제에 대해 관련 있는 GRI 주제 및 지표를 연계하기 위해
          사용자 정의 중요 주제 목록을 생성하고 관련 주제 및 지표를 작성해주세요. <br>
          생성된 목록에 연결된 주제 및 지표는 링크로 자동 연결됩니다.
          <p class="pt-2 mb-0">(ex. 중요주제 : 공급망 ESG 관리, 중요 주제와 연관된 GRI 지표 선택 : GRI 308-1, GRI 308-2, GRI 414-1, GRI 414-2)</p>
          <p class="pt-2 mt-3 mb-0"> 
              ※ 선택 가능한 GRI 지표는 '보고 지표' 페이지에서 3-3 (중요 주제 관리) 지표 보고를 선택한 주제의 지표만 표시됩니다.
          </p>
        </b-card>

        <b-row>
          <b-col cols="1" >
            <h6 class="fw-600 f-105 mb-0">
                우선순위
            </h6>
          </b-col>

          <b-col cols="3" class="">
              <h6 class="fw-600 f-105 mb-0">중요 주제명</h6>
            </b-col>
            <b-col cols="7" class="">
              <h6 class="fw-600 f-105 mb-0">중요 주제와 연관된 GRI 지표 선택</h6>
          </b-col>
          <b-col cols="1" class="">
              <h6 class="fw-600 f-105 mb-0">관리</h6>
          </b-col>
        </b-row>
        
        <div>

        <b-row v-for="(info, index) in add_topic_list" :key="index">
            <b-col cols="1">
                <div class="custom-material-table-end-line"></div>
                    {{ index+1 }}
            </b-col>
            <b-col cols="3">
                <div class="custom-material-table-end-line"></div>
                <b-form-input v-model="info.custom_topic_name" placeholder="중요 주제명 입력"></b-form-input>
            </b-col>
            <b-col cols="7">
                <div class="custom-material-table-end-line"></div>
                    <b-card class="minimal-card">
                        <b-table-simple responsive v-if="info.is_editing" class="bor-table sm mb-0">
                            <b-tbody>
                                <!-- {{ info[topic_key]['indicators'][gri_code] }} -->
                                <b-tr v-for="topic_key in Object.keys(info)" :key="topic_key" >
                                    <b-td v-if="topic_key !== 'checked_indicators' && topic_key !== 'custom_topic_name' && topic_key !== 'is_editing' && topic_key !== 'checked_topics'" style="width: 30%;">
                                        {{ topic_key }} {{  info[topic_key]["topic_name"] }}
                                    </b-td>
                                    <b-td v-if="topic_key !== 'checked_indicators' && topic_key !== 'custom_topic_name' && topic_key !== 'is_editing' && topic_key !== 'checked_topics'">
                                            <!-- {{ info[topic_key]['indicators'] }} -->
                                            <b-form-group
                                                v-slot="{ ariaDescribedby }"
                                                class="mb-1"
                                                >
                                                <b-form-checkbox
                                                    v-for="indicator_item in sort_indicator_arr(info[topic_key]['indicators'])"
                                                    v-model="info['checked_indicators']"
                                                    :key="indicator_item"
                                                    :value="indicator_item"
                                                    :aria-describedby="ariaDescribedby"
                                                    name="flavour-4a"
                                                    inline
                                                >
                                                
                                                    {{ indicator_item }}
                                                </b-form-checkbox>
                                            </b-form-group>
                                        
                                    </b-td>
                                   
                                </b-tr>
                            </b-tbody>
                            <!-- {{ info['checked_indicators']}} -->

                        </b-table-simple>

                        <b-table-simple responsive v-else>
                            <b-tbody>
                                <template v-for="topic_key in Object.keys(info)">
                                    <b-tr v-if="info.checked_topics.includes(topic_key) && topic_key !== 'checked_indicators' && topic_key !== 'custom_topic_name' && topic_key !== 'is_editing' && topic_key !== 'checked_topics'" :key="topic_key">
                                        <b-td style="width: 30%;">
                                            {{ topic_key }} {{ info[topic_key]["topic_name"] }}
                                        </b-td>
                                        <b-td>
                                            <span v-for="gri_code in sort_indicator_arr(info[topic_key]['indicators'])" :key="gri_code">
                                                <span v-if="info['checked_indicators'].includes(gri_code)">
                                                {{ gri_code }} &nbsp;
                                                </span>
                                            </span>
                                        </b-td>
                                    </b-tr>
                                </template>
                            </b-tbody>
                        </b-table-simple>

                    </b-card>
            </b-col>
            <b-col cols="1" class="">
               <div class="custom-material-table-end-line"></div>
                <b-button :variant="!info.is_editing?'teal':'danger'" size="sm">
                    <b-icon
                        v-if="!info.is_editing"
                        scale="1.3"
                        icon="pencil-square"
                        aria-hidden="true"
                        @click="info.is_editing=true"
                    />
                    <b-icon
                        v-else
                        scale="1.3"
                        icon="trash"
                        aria-hidden="true"
                        @click="removeAddedCustomIndicator(info)"
                    />
                </b-button>
            </b-col>
        </b-row>
        <b-button @click="addNewCustomTopic" class="float-right mt-3 mb-5" variant="teal">+ 중요 주제 목록</b-button>
      </div>

      <div class="mt-5 pt-5">
          <b-button variant="teal" class="mr-2" @click="save_custom_topic(false)" >저장하기</b-button>
          <b-button variant="" @click="$bvModal.hide('custom-material-popup')">리스트로 돌아가기</b-button>
      </div>

      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "",
  props: {
    translate_view: {type: Boolean, default: false},

        
  },
  components: {},
  data() {
    return {
        loadingShow: false,
        selected_indicator: null,
        input_info: {
            
        },
        add_topic_list: [],
    };
  },
  beforeCreate() {},
    created() {
        this.selected_gri_code_list();
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() { },
  computed: {
    ...mapState("report_list", ["report_code"]),
    
  },
    methods: {
    openCustomMaterialModel() {
        this.$bvModal.show('custom-material-popup');
        if (this.add_topic_list.length === 0) {
            // 기존에 추가된게 아예 없는 경우, 예시를 미리 추가
            for (let i in [0, 1]) {
                this.addNewCustomTopic();
            }
        } else if (this.add_topic_list.length === 1) {
            // 기존에 추가된게 하나인 경우, 예시를 하나 미리 추가
            this.addNewCustomTopic();
        }
    },
    showCustomMaterialIndicatorPopup() { },
    sort_indicator_arr(arr) {
        return arr.slice().sort((a, b) => {
            let [numA, subNumA] = a.split('-').map(Number);
            let [numB, subNumB] = b.split('-').map(Number);

            // 숫자 부분을 비교
            if (numA === numB) {
            // 숫자가 같은 경우 하위 숫자 부분을 비교
            return subNumA - subNumB;
            } else {
            // 숫자가 다른 경우 숫자 부분을 비교
            return numA - numB;
            }
        });
    },
    goHash(code){
        let hash_code = '#w'+code
            // this.$router.push({ name: 'Gri'+code.slice(0,1)*100, hash: hash_code })
        if (code.split("-")[0].length > 1) {
            window.location.href = `/write_report/gri_${code.slice(0, 1) * 100}${hash_code}`
            // this.$router.push({
            //     name: 'Gri' + code.slice(0, 1) * 100,
            //     hash: hash_code
            // })
        } else {
            window.location.href = `/write_report/gri_${code.split('-')[0]}${hash_code}`
            // this.$router.push({
            //     name: 'Gri' + code.split("-")[0],
            //     hash: hash_code
            // })
        }
    },
    async save_custom_topic(remove = false) {
        if (remove) {
            this.add_topic_list = []
        }
        
        this.loadingShow = true;
        const payload = []

        for (let ele of this.add_topic_list) {
            if (ele?.checked_indicators) {
                if (!ele?.custom_topic_name) {
                    window.alert("중요 주제 명을 입력해주세요")
                    this.loadingShow = false;

                    return;
                }

                payload.push({
                    "checked_indicators": ele.checked_indicators,
                    "custom_topic_name": ele.custom_topic_name
                })
            }

        }
        const response = await axios.post(this.$store.state.backend_host + "/update_custom_topic", {
            "rc": this.report_code,
            "payload": payload

        })

        if (!response.data.code) {
            window.alert("사용자 정의 목록 생성에 실패했습니다.")
        }

        this.loadingShow = false;
        this.$bvModal.hide('custom-material-popup')



        
    },
    removeAddedCustomIndicator(added_info) {
        const indexToRemove = this.add_topic_list.indexOf(added_info);
        if (indexToRemove !== -1) {

            this.add_topic_list.splice(indexToRemove, 1);
        }
    },
    addNewCustomTopic() {
        this.add_topic_list.push({
            ...this.selected_indicator.selected_indicator,
            checked_indicators: [],
            custom_topic_name: "",
            is_editing: true
        })
            
    },
    async selected_gri_code_list() {
        this.add_topic_list = []
        const response = await axios.get(this.$store.state.backend_host + "/get_selected_gri_indicator_code?rc=" + this.report_code)   
        if (response.data.code) {
            this.selected_indicator = response.data.result;

            // this.add_topic_list
            this.selected_indicator.custom_topic_indicator.map((ele, idx) => {
                this.add_topic_list.push({
                    ...this.selected_indicator.selected_indicator,
                    checked_topics: ele.selected_topic_list,
                    checked_indicators: ele.selected_cm_gri_code_list,
                    custom_topic_name: ele.topic_name,
                    is_editing: false
                })
            })
            
        }

    }
    
  },
};
</script>
<style>

.custom-material-table-end-line{
  border-bottom: 3px solid #dedede;
  margin-bottom: 13px;
  margin-top: 5px;
}

</style>

<template>
<div>
    <div class="pt-2 pb-4" v-if="editing_gri_code!=''">
        <h5 class="f-110 tail-line-dark">{{editing_gri_code}} 지표에 비즈니스 사례 추가 </h5>
    </div>
    <b-table-simple responsive hover class="bor-table mb-45">
        <b-thead><b-tr>
            <b-td v-if="editing_gri_code!=''">선택</b-td><b-td>No.</b-td><b-td>지표</b-td><b-td>비즈니스 사례</b-td><b-td>보기</b-td><b-td>수정</b-td><b-td>관리</b-td>
        </b-tr></b-thead>
        <b-tbody>
            <b-tr v-for="(item, index) in business_case_list" :key="`${index}_${item.business_case_id}`">
                <b-td v-if="editing_gri_code!=''"><b-form-checkbox v-model="local_bc_select[item.business_case_id]"></b-form-checkbox></b-td>
                <b-td class="gray-600">{{ index+1 }}</b-td>
                <b-td>
                    <span v-for="(item, index) in item['used_codes']" :key="`${index}_${item}`">
                        <a class="cursor" @click="goHash(item, 'GRI')"> 
                            GRI {{ item }} 
                            <fa icon="link" class="ml-1"/></a>
                    </span>
                    <span v-for="(item, index) in item['used_codes_sasb']" :key="`${index}_${item}`">
                        <a class="cursor" @click="goHash(item, 'SASB')"> 
                            SASB {{ item }} 
                            <fa icon="link" class="ml-1"/></a>
                    </span>
                    <span v-for="(item, index) in item['used_codes_tcfd']" :key="`${index}_${item}`">
                        <a class="cursor" @click="goHash(item, 'TCFD')"> 
                            TCFD {{ item }} 
                            <fa icon="link" class="ml-1"/></a>
                    </span>
                </b-td>
                <b-td>
                    <p class="mb-0 fw-500">{{ item.title }}</p>
                    <!-- <p class="mb-0 gray-600">{{ item.description }}</p> -->
                </b-td>
                <b-td>
                    <a class="cursor" v-b-modal="`bc_${item.business_case_id}`">보기 <fa icon="expand" class="ml-1"/></a>
                    <b-modal :id="`bc_${item.business_case_id}`" :title="item.title" hide-footer size="lg">
                        <simple-business-case :business_case_id="item.business_case_id"></simple-business-case>
                    </b-modal>
                </b-td>
                <b-td><b-button variant="dark-border" class="py-1 px-2" @click="addOrEditBC(item.business_case_id)"><fa icon="pen"/></b-button></b-td>
                <b-td><b-button variant="dark-border" class="py-1 px-2" @click="mgtBusinessCase(item.business_case_id)"><fa icon="wrench"/></b-button></b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>

    <b-card class="make-new-card text-center cursor bg-transparent" @click="addOrEditBC()"><fa icon="plus" class="mr-2"/>새 비즈니스 사례 생성</b-card>

    <div class="pt-5 pb-3" v-if="editing_gri_code!=''">
        <b-button variant="teal" class="mr-3" @click="handleSelect"> 선택한 비즈니스 사례 적용</b-button>
        <b-button @click="handleCancle">취소</b-button>
    </div>

    

</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import SimpleBusinessCase from '@/components/writing_report/business_case_subs/SimpleBusinessCase'
import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'


export default {
    mixins: [gri_mixin],
    data() {
        return {
            local_bc_select : {},
            is_bc_opened: false,
            opened_bc: {}            
        }
    },
    computed:{
        ...mapState('report_list', ['report_code', 'standard_mode']),
        ...mapState('business_cases', ['business_case_list']),
        ...mapState('cursor', ['editing_gri_code']),
    },
    methods:{
        ...mapActions('business_cases', ['updateBusinessCaseRelation']),
        addOrEditBC(business_case_id) {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return
            if (this.editing_gri_code==''){ //관리 페이지에서 생성-수정시
                if(business_case_id){
                    this.$emit('open-edit-modal', business_case_id)
                }
                else {
                    this.$emit('open-edit-modal', '')
                }
            }else { //gri 작성 모달에서 생성-수정시
                if(business_case_id){
                    this.$emit('edit-business-case', business_case_id)
                }
                else {
                    this.$emit('edit-business-case')
                }
            }
        },
        mgtBusinessCase(business_case_id) {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return
            
            if (this.editing_gri_code==''){ //관리 페이지에서 생성-수정시
                if(business_case_id){
                    this.$emit('open-manage-modal', business_case_id)
                }
                else {
                    this.$emit('open-manage-modal', '')
                }
            }else { //gri 작성 모달에서 생성-수정시
                if(business_case_id){
                    this.$emit('business-case-manage', business_case_id)
                }
                else {
                    this.$emit('business-case-manage')
                }
            }
        },
        initiateLocalBCSelect(){
            this.local_bc_select = {}
            for (let i=0; i<this.business_case_list.length; i++){
                let used_idx = this.business_case_list[i].used_codes.findIndex( k => k == this.editing_gri_code)
                let temp_business_case_id = this.business_case_list[i].business_case_id
                if (used_idx > -1){
                    this.local_bc_select[temp_business_case_id] = true
                }
                else this.local_bc_select[temp_business_case_id] = false
            }
        },
        async handleSelect() {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return

            let new_payload = {...this.local_bc_select}
            // console.log(new_payload);
            await this.updateBusinessCaseRelation({
                report_code: this.report_code,
                gri_code: this.editing_gri_code,
                link_payload: new_payload,
                framework: this.standard_mode 
            })
            this.handleCancle()
        },
        handleCancle(){
            this.$emit('hide-edit')
        },
    },
    created(){
        this.initiateLocalBCSelect()
    },
    components:{
        SimpleBusinessCase
    }

}
</script>

<style>

</style>
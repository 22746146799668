<template>
<div>
    <b-container class="mb-5">
        <b-row>
            <b-col cols="0" md="2" lg="3"></b-col>
            <b-col cols="12" md="8" lg="6" class="align-self-center">
                <b-card class="py-2 px-3 mx-md-3 mx-xl-5 rounded-2 border-light mb-4">
                    <h6 class="mb-3 tealgray400">회원가입</h6>
                    <div class="mb-1 f-90">이메일 주소</div>
                    <b-form-input class="login-card-input mb-3" v-model="user.e_mail" label="E-mail:" placeholder="name@company.com" :disabled="is_processing==true"></b-form-input>
                    <div class="mb-1 f-90">이름</div>
                    <b-form-input class="login-card-input mb-3" v-model="user.user_name" label="Name:" placeholder="이름" :disabled="is_processing==true"></b-form-input>
                    <!-- <div class="mb-1 f-90">전화번호</div> -->
                    <!-- <b-form-input class="login-card-input mb-2" v-model="user.tel" label="Tel:" placeholder="000-0000-0000"></b-form-input> -->
                    <div class="mb-1 f-90">비밀번호</div>
                    <b-form-input class="login-card-input mb-2" v-model="user.password_1" label="Password:" placeholder="비밀번호" type="password" :disabled="is_processing==true"></b-form-input>
                    <b-form-input class="login-card-input mb-2" v-model="user.password_2" label="Password:" placeholder="비밀번호 확인" type="password" @keyup.enter="registerUser()" :disabled="is_processing==true"></b-form-input>

                    <div v-if="user.password_2!='' & user.password_1!=user.password_2" class="red f-90"><fa icon="exclamation-circle"/> 비밀번호가 일치하지 않습니다.</div>
                    <div v-if="user.password_2!='' & user.password_1==user.password_2" class="tealgray500 f-90"><fa icon="check-circle"/> 비밀번호가 일치합니다.</div>
                    <!-- <b-form-checkbox class="mt-4 mb-4" id="checkbox-1" v-model="user.subscription" name="checkbox-1" :disabled="is_processing==true">ESG, 지속가능경영 관련 소식 구독하기</b-form-checkbox> -->
                    <b-button block variant="login" class="" @click="registerUser()">{{is_processing==false?'가입하기':'진행 중'}}<b-spinner small class="ml-1" v-if="is_processing==true"></b-spinner></b-button>
                </b-card>
                <div align="center"><span class="f-95 pr-1">이미 계정이 있으시다면,</span> <a @click="$router.push('/')" class="cursor">로그인</a> </div>
            </b-col>
            <b-col cols="0" md="2" lg="3"></b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    data(){
        return{
            user: {
                e_mail: '',
                user_name: '',
                tel: '',
                password_1: '',
                password_2: '',
                subscription: true
            },
            is_processing:false,
            coauthor_confirmed: false
        }
    },
    computed:{
        check_password(){
            if(this.user.password_1.length == 0) { return null }
            if(this.user.password_1 != this.user.password_2) { return false }
        }
    },
    methods: {
        ...mapActions(['login', 'logout']),
        registerUser(){
            this.is_processing=true
            const path = this.$store.state.backend_host + '/create_user'

            let formData = new FormData()
            formData.append('e_mail', this.user.e_mail)
            formData.append('subscription', this.user.subscription)
            formData.append('user_name', this.user.user_name)
            formData.append('tel', this.user.tel)
            formData.append('password', this.user.password_1)
            formData.append('is_admin', 0)

            return axios.post(path, formData)
            .then( response => {
                if ( response.data.code != 'success' ){
                    alert(response.data.msg)
                    this.is_processing=false
                }
                else { 
                    if(this.coauthor_confirmed==true){ // 공동작성 초대로 접근한 경우, report.qesg.co.kr로 새로 로그인하도록 해야함
                        window.location.href = 'http://report.qesg.co.kr/'
                    }else {
                        this.requestConfirm() //성공했을 경우 이메일로 인증 코드 발송
                    }                   
                }
            })
            .catch(error => {
                console.log(error);
                alert(`알 수 없는 오류로 회원가입에 실패하였습니다. 시스템 관리자에 문의해주세요.`) 
            })  
        },
        requestConfirm(){
            const path = this.$store.state.backend_host + '/request_confirm'

            let formData = new FormData()
            formData.append('e_mail', this.user.e_mail)

            return axios.post( path, formData )
            .then(response => {
                // console.log(response);
                this.$EventBus.$emit('make-toast', '이메일 인증', `${this.user.e_mail} 이메일로 인증 코드를 전송하였습니다. 이메일 확인 후 인증하여 주시기 바랍니다.`)
                setTimeout(
                    this.$router.push({name: 'ConfirmUser', query: {mode: 'signup', e_mail: this.user.e_mail}}),
                    1000
                )                
                this.is_processing=false
            })
            .catch(error => {
                console.log(error);
                alert(`알 수 없는 오류로 회원가입 인증 메일 전송에 실패하였습니다. 시스템 관리자에 문의해주세요.`) 
                this.is_processing=false
            })        
        }
    },
    created(){
        if(this.$route.query.e_mail != '') this.user.e_mail = this.$route.query.e_mail
        if(this.$route.query.mode=='coauthor_accepted'){
            this.coauthor_confirmed = true
        }
    }
}
</script>
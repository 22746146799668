<template>
<div>
    <h6>전체 보고서</h6> 
    <a :href="`http://report.qesg.co.kr/v?r=`+report.report_url" target="_blank" id="ShareUrl">http://report.qesg.co.kr/v?r={{report.report_url}}</a>
    <!-- <b-button variant="icon-teal" class="ml-2 py-0 px-2" v-b-tooltip.hover title="링크 복사하기" @click.stop.prevent="copyLink('full-link')"><fa :icon="['far','copy']"/></b-button>  -->
    
    <div v-if="Object.keys(report.report_type).includes('GRIv2021') || Object.keys(report.report_type).includes('GRIv2016')">
        <hr>
        <h6>GRI Table</h6> 
        <a :href="`http://report.qesg.co.kr/g?r=`+report.report_url" target="_blank"> http://report.qesg.co.kr/g?r={{report.report_url}}</a>
    </div>
    
    <div v-if="Object.keys(report.report_type).includes('SASBv2023')">
        <hr>
        <h6>SASB Table</h6> 
        <a :href="`http://report.qesg.co.kr/s?r=`+report.report_url" target="_blank"> http://report.qesg.co.kr/s?r={{report.report_url}}</a>
    </div>
    
    <div v-if="Object.keys(report.report_type).includes('TCFDv2021')">
        <hr>
        <h6>TCFD Table</h6> 
        <a :href="`http://report.qesg.co.kr/t?r=`+report.report_url" target="_blank"> http://report.qesg.co.kr/t?r={{report.report_url}}</a>
    </div>

    
    
    <!-- <b-button variant="icon-teal" class="ml-2 py-0 px-2" v-b-tooltip.hover title="링크 복사하기" @click.stop.prevent="copyLink('gri-link')"><fa :icon="['far','copy']"/></b-button>  -->

    <!-- <b-button @click="sendEmail"><fa icon="envelope"/></b-button> -->
</div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    props: {
        report: {type: Object}
    },
    computed:{

    },
    methods:{
        copyLink(mode){

            var inp = document.createElement('input');
            document.body.appendChild(inp)
            inp.value = `http://report.qesg.co.kr/v?r=${this.report.report_url}`;
            inp.select();
            document.execCommand('copy',false);
            inp.remove();

            // const el = document.createElement('textarea');  
            // if (mode=='full-link'){
            //     el.value = `http://report.qesg.co.kr/v?r=${this.report.report_url}`;  
            // }
            // else if (mode=='gri-link'){
            //     el.value = `http://report.qesg.co.kr/g?r=${this.report.report_url}`;  
            // }                        
            // // console.log(el.value);
            // el.setAttribute('readonly', '');                
            // el.style.position = 'absolute';                     
            // el.style.left = '-9999px';                      
            // document.body.appendChild(el);                  
            // let selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;         
            // console.log(selected);                           
            // el.select();          
            // el.setSelectionRange(0, 9999)
            // document.execCommand('copy');                   
            // document.body.removeChild(el);                  
            // if (selected) {                                 
            //     document.getSelection().removeAllRanges();    
            //     document.getSelection().addRange(selected);   
            // }
            this.$EventBus.$emit('make-toast', '공개 링크 복사', '보고서 공개 링크가 복사되었습니다.')
        },
    }

}
</script>

<style>

</style>
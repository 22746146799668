<template>
    <div>
      <b-button
        v-if="!translate_view"
        variant="light"
        class="py-1 px-2"
        @click="$bvModal.show('custom-material-popup')"
      >
      +사용자 정의 중요 주제 목록 생성
      </b-button>
      <div v-if="this.add_topic_list.length > 0">
          <div class="border-bottom-gray-550 gray-600 f-90 pb-1"> </div>
          <div :class="true? 'mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center' : ''">
              &nbsp;
              <div 
                  style="min-width: 70px;"
              >
                  <b-button variant="light" class="ml-5 py-1 px-2" v-b-tooltip.hover title="편집"  @click="edit_mode=!edit_mode" ><fa icon="pen"/></b-button>
              </div>
          </div>
          <b-table-simple responsive class="bor-bg-table">
              <b-thead>
                  <b-tr>
                      <b-td>Ranking</b-td>
                      <b-td>Topic</b-td>
                      <b-td v-if="edit_mode">GRI Topic Standards</b-td>
                  </b-tr>
              </b-thead>
              <b-tbody>
                  <b-tr v-for="(item, idx) in add_topic_list" :key="idx">
                      <b-td>
                          {{  idx+1 }}
                      </b-td>
                      <b-td>
                        <span v-if="!edit_mode">
                            {{ item.custom_topic_name_em ? item.custom_topic_name_em : item.custom_topic_name_ea  }}
                        </span>
                        
                        <div
                        v-else
                        >
                            <b-input
                                :placeholder="item.custom_topic_name_em ? item.custom_topic_name_em : item.custom_topic_name_ea"    
                                v-model="item.custom_topic_name_em"
                            />
                        </div>
                            


                      </b-td>
                      
                      <b-td>
                          <div  v-if="edit_mode">
                            
                            <b-button
                                variant="red"
                                size="sm"
                                class="mr-3 mb-1"
                                :disabled="is_saving || re_initiating"
                                @click="save_custom_topic_manually(item)"
                                >
                                {{ is_saving == true ? '저장 중' : '저장하기' }}
                                <b-spinner small v-show="is_saving === true"></b-spinner>
                            </b-button>
                            <b-button variant="teal" @click="re_initiate_translation(item)"  size="sm" class="mb-1 mr-1" :disabled="is_saving || re_initiating" > 
                                번역 초기화 <fa icon="redo" :class="re_initiating?'spinning-icon':''"/>
                            </b-button>
                            <b-button size="sm" class="mb-1" @click="edit_mode = false" :disabled="is_saving || re_initiating"> 취소 </b-button>
                          </div>
                      </b-td>
                      <hr>
                      <!-- <template v-for="property in hashed_data_tables[dt].header"> -->
                          
                          <!-- <b-td v-if="differentPrevious(hashed_data_tables[dt].contents, row_index, property, hashed_data_tables[dt].header)==true" :key="property.id" :rowspan="getSameLength(hashed_data_tables[dt].contents, row_index, property, hashed_data_tables[dt].header)">
                              {{ row_item[property] }}
                          </b-td> -->
                      <!-- </template> -->
                  </b-tr>
              </b-tbody>
          </b-table-simple> 
  
      </div>
      
    </div>
  </template>
  <script>
  import { mapState } from "vuex";
  
  export default {
    name: "",
    props: {
      translate_view: {type: Boolean, default: false},
  
          
    },
    components: {},
    data() {
      return {
          loadingShow: false,
          selected_indicator: null,
          input_info: {
              
          },
          add_topic_list: [],
          edit_mode: false,
          is_saving: false,
          custom_topic_e: "",
          re_initiating: false,

      };
    },
    beforeCreate() {},
      created() {
          this.selected_gri_code_list();
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() { },
    computed: {
      ...mapState("report_list", ["report_code"]),
      
    },
      methods: {
            showCustomMaterialIndicatorPopup() { },

      async re_initiate_translation(custom_topic_info) {
        // translate_custom_topic
        this.re_initiate = true;
        const response = await axios.post(this.$store.state.backend_host + "/translate_custom_topic", {
            "id": custom_topic_info.id,
            "re_initiate": true
        })

        this.re_initiate = false;
        custom_topic_info.custom_topic_name_em = response.data.subject_name_ea

      },
      async save_custom_topic_manually(custom_info) {

        this.is_saving = true
                
        const response = await axios.post(this.$store.state.backend_host + "/save_custom_topic_em", {
            "rc": this.report_code,
            "id": custom_info.id,
            "custom_topic_name_em": custom_info.custom_topic_name_em
        })


        if (!response.data.code) {
            window.alert(response.data.msg)
        }

        this.is_saving = false
        


        
      },
      
      goHash(code){
          let hash_code = '#w'+code
              // this.$router.push({ name: 'Gri'+code.slice(0,1)*100, hash: hash_code })
          if (code.split("-")[0].length > 1) {
              window.location.href = `/write_report/gri_${code.slice(0, 1) * 100}${hash_code}`
              // this.$router.push({
              //     name: 'Gri' + code.slice(0, 1) * 100,
              //     hash: hash_code
              // })
          } else {
              window.location.href = `/write_report/gri_${code.split('-')[0]}${hash_code}`
              // this.$router.push({
              //     name: 'Gri' + code.split("-")[0],
              //     hash: hash_code
              // })
          }
      },
      async selected_gri_code_list() {
          const response = await axios.get(this.$store.state.backend_host + "/get_selected_gri_indicator_code?rc=" + this.report_code)   
          if (response.data.code) {
              this.selected_indicator = response.data.result;
  
              // this.add_topic_list
              this.selected_indicator.custom_topic_indicator.map((ele, idx) => {
                  this.add_topic_list.push({
                      ...this.selected_indicator.selected_indicator,
                      id: ele?.id,
                      checked_topics: ele?.selected_topic_list,
                      checked_indicators: ele?.selected_cm_gri_code_list,
                      custom_topic_name: ele?.topic_name,
                      custom_topic_name_em: ele?.topic_name_em !== null ? ele?.topic_name_em : '',
                      custom_topic_name_ea: ele?.topic_name_ea !== null ? ele?.topic_name_ea : '',
                      is_editing: false
                  })
  
  
              })
  
  
  
          }
  
      }
      
    },
  };
  </script>
  <style>
  
  .custom-material-table-end-line{
    border-bottom: 3px solid #dedede;
    margin-bottom: 8px;
    margin-top: 5px;
  }
  
  </style>
  
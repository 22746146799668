export const data_sample_list = [
  {
    label: "E_온실가스배출량",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "line",
          label: "총 배출량 원단위(Scope 1&2) ",
          data: [4, 2, 1],
          borderColor: ["rgba(255, 127, 80)", "rgba(255, 127, 80)", "rgba(255, 127, 80)"],
          backgroundColor: ["rgba(255, 253, 208)", "rgba(255, 253, 208)", "rgba(255, 253, 208)"],
          yAxisID: "y-right",
          unit: "tCO2eq/억원",
        },
        {
          type: "bar",
          label: "직접 (Scope 1)",
          data: [10, 10, 10],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "tCO2eq",
        },
        {
          type: "bar",
          label: "간접 (Scope 2)",
          data: [5, 5, 5],
          borderColor: ["rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)"],
          backgroundColor: ["rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "tCO2eq",
        },
        {
          type: "bar",
          label: "기타 (Scope 3)",
          data: [8, 8, 8],
          borderColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          backgroundColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          yAxisID: "y",
          barPercentage: 0.4,
          unit: "tCO2eq",
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "온실가스 배출량",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: 'tCO2eq',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: "6",
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          max: 12,
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
        "y-right": {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: 'tCO2eq/억원',
            padding: {
              top: 4,
              bottom: 4,
            },
            color: "#666",
          },
          min: 0,
          max: 4.5,
          ticks: {
            stepSize: "2.25",
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          position: "right",
          grid: {
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          type: "linear",
          offset: false,
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y-right",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "E_에너지 사용량",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "line",
          label: "총 에너지 원단위",
          data: [4, 2, 1],
          borderColor: ["rgba(255, 127, 80)", "rgba(255, 127, 80)", "rgba(255, 127, 80)"],
          backgroundColor: ["rgba(255, 253, 208)", "rgba(255, 253, 208)", "rgba(255, 253, 208)"],
          yAxisID: "y-right",
          unit: "MJ/억원",
        },
        {
          type: "bar",
          label: "총 에너지",
          data: [10, 9, 11],
          borderColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          backgroundColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "MJ",
        },
        {
          type: "bar",
          label: "재생에너지",
          data: [3, 3, 4],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          yAxisID: "y",
          barPercentage: 0.4,
          unit: "MJ",
        },

      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "에너지 사용량",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: 'MJ',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: "6",
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          max: 12,
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
        "y-right": {
          axis: "y",
          display: true,
          title: {
            display: true,
            text:'MJ/억원',
            padding: {
              top: 4,
              bottom: 4,
            },
            color: "#666",
          },
          min: 0,
          max: 4.5,
          ticks: {
            stepSize: "2.25",
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          position: "right",
          grid: {
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          type: "linear",
          offset: false,
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y-right",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "E_용수취수량",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "line",
          label: "용수 취수량 원단위",
          data: [4, 2, 1],
          borderColor: ["rgba(255, 127, 80)", "rgba(255, 127, 80)", "rgba(255, 127, 80)"],
          backgroundColor: ["rgba(255, 253, 208)", "rgba(255, 253, 208)", "rgba(255, 253, 208)"],
          yAxisID: "y-right",
          unit: "톤/억원",
        },
        {
          type: "bar",
          label: "용수 취수량",
          data: [12, 11, 12],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.6)"],
          barPercentage: 0.3,
          yAxisID: "y",
          unit: "톤",
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "용수 취수량",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '톤(ton)',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 3,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          min: 6,
          max: 12,
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
        "y-right": {
          axis: "y",
          display: true,
          title: {
            display: true,
            text:'톤/억원',
            padding: {
              top: 4,
              bottom: 4,
            },
            color: "#666",
          },
          min: 0,
          max: 5,
          ticks: {
            stepSize: 2.5,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          position: "right",
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          type: "linear",
          offset: false,
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y-right",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "E_총대기오염물질배출량",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "bar",
          label: "총 대기오염물질 배출량",
          data: [8, 7, 9],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.6)"],
          barPercentage: 0.3,
          yAxisID: "y",
          unit: "톤",
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "대기오염물질 배출량",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '톤(ton)',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 5,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          max: 10,
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "E_폐기물 발생량 및 재활용률",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "line",
          label: "총 폐기물 재활용률",
          data: [93, 95, 99],
          borderColor: ["rgba(255, 127, 80)", "rgba(255, 127, 80)", "rgba(255, 127, 80)"],
          backgroundColor: ["rgba(255, 253, 208)", "rgba(255, 253, 208)", "rgba(255, 253, 208)"],
          yAxisID: "y-right",
          unit: "%",
        },
        {
          type: "bar",
          label: "일반폐기물 발생량",
          data: [2, 3, 2],
          borderColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          backgroundColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          barPercentage: 0.3,
          yAxisID: "y",
          unit: "톤",
        },
        {
          type: "bar",
          label: "지정폐기물 발생량",
          data: [1, 2, 1],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          yAxisID: "y",
          unit: "톤",
          barPercentage: 0.3,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "폐기물 발생량 및 재활용률",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          stacked: true,
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '톤(ton)',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          stacked: true,
          ticks: {
            stepSize: 3,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          max: 6,
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
        "y-right": {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '퍼센트(%)',
            padding: {
              top: 4,
              bottom: 4,
            },
            color: "#666",
          },
          stacked: true,
          min: 90,
          max: 100,
          ticks: {
            stepSize: 5,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          position: "right",
          grid: {
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          type: "linear",
          offset: false,
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y-right",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "S_구성원",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "bar",
          label: "남성 구성원 수",
          data: [60, 65, 65],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.6)"],
          barPercentage: 0.3,
          yAxisID: "y",
          unit: "명",
        },
        {
          type: "bar",
          label: "여성 구성원 수",
          data: [40, 42, 43],
          borderColor: ["rgba(255, 127, 80, 0.6)", "rgba(255, 127, 80, 0.6)", "rgba(255, 127, 80, 0.6)"],
          backgroundColor: ["rgba(255, 127, 80, 0.3)", "rgba(255, 127, 80, 0.45)", "rgba(255, 127, 80, 0.6)"],
          barPercentage: 0.3,
          yAxisID: "y",
          unit: "명",
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "구성원 현황",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          stacked: true,
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '명(people)',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          stacked: true,
          ticks: {
            stepSize: 60,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "S_다양성",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "line",
          label: "여성 관리직(직책자) 비율",
          data: [30, 40, 35],
          borderColor: ["rgba(255, 127, 80)", "rgba(255, 127, 80)", "rgba(255, 127, 80)"],
          backgroundColor: ["rgba(255, 253, 208)", "rgba(255, 253, 208)", "rgba(255, 253, 208)"],
          yAxisID: "y",
        },
        {
          type: "line",
          label: "주요 매출 발생 부서 여성 비율",
          data: [20, 15, 20],
          borderColor: ["#008080", "#008080", "#008080"],
          backgroundColor: ["rgba(0, 250, 250)", "rgba(0, 250, 250)", "rgba(0, 250, 250)"],
          yAxisID: "y",
        },
        {
          type: "line",
          label: "장애인 구성원 비율",
          data: [3, 2, 2],
          borderColor: ["#696969", "#696969", "#696969"],
          backgroundColor: ["#EEEEEE", "#EEEEEE", "#EEEEEE"],
          yAxisID: "y",
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "구성원 다양성 현황",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: "",
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          offset: true,
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: true,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '퍼센트(%)',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 25,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          max: 50,
          type: "linear",
          offset: false,
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "S_동등 급여",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "bar",
          label: "남성 기본급 대비 여성 기본급 비율",
          data: [83, 85, 90],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          barPercentage: 0.3,
          yAxisID: "y",
          unit: "%",
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "동등 급여",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: true,
          text: "",
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '퍼센트(%)',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 7,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          min: 78,
          max: 92,
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "S_안전보건",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          label: "구성원 - 근로손실재해율",
          data: [0.2, 0.15, 0.1],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          type: "bar",
          yAxisID: "y",
          unit: "LTIR",
          barPercentage: 0.4,
        },
        {
          label: "협력사 - 근로손실재해율",
          data: [0.1, 0.12, 0.1],
          borderColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          backgroundColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          type: "bar",
          yAxisID: "y",
          unit: "LTIR",
          barPercentage: 0.4,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "안전보건 관리 지표 (구성원, 협력사)",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: "LTIR",
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 0.15,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          max: 0.3,
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "S_공급망",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          label: "ESG 역량 강화를 지원한 협력사 수",
          data: [2, 10, 20],
          borderColor: ["#FFA07A", "#FFA07A", "#FFA07A"],
          backgroundColor: ["#FF8080", "#FF8080", "#FF8080"],
          type: "line",
          yAxisID: "y",
          unit: "개",
        },
        {
          label: "공급망 ESG 평가 대상 협력사 수",
          data: [100, 105, 110],
          borderColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          backgroundColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          type: "bar",
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "개",
        },
        {
          label: "공급망 ESG 평가에 따라 참여한 협력사 수",
          data: [20, 30, 40],
          borderColor: ["rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)"],
          backgroundColor: ["rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)"],
          type: "bar",
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "개",
        },
        {
          label: "평가 결과에 따라 리스크가 도출된 협력사 수",
          data: [10, 8, 12],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          type: "bar",
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "개",
        },
        {
          label: "리스크 도출된 협력사 중 개선계획 수립/이행한 협력사 수",
          data: [10, 8, 12],
          borderColor: ["rgba(0, 128, 128, 0.75)", "rgba(0, 128, 128, 0.75)", "rgba(0, 128, 128, 0.75)"],
          backgroundColor: ["rgba(0, 128, 128, 0.75)", "rgba(0, 128, 128, 0.75)", "rgba(0, 128, 128, 0.75)"],
          type: "bar",
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "개",
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "공급망 ESG 평가",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display:false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '개(EA)',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 60,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          max: 120,
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "S_지역사회",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "bar",
          label: "사회공헌 투자 비용",
          data: [500, 550, 530],
          borderColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          backgroundColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "백만원",
        },
        {
          type: "bar",
          label: "구성원 자원봉사 참가 시간",
          data: [1200, 1000, 1500],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "시간",
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "사회공헌 성과",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '백만원(Mil Won)',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 800,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
        'y-right': {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '시간(H)',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 800,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y-right",
          position: "right",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "S_정보보안",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "line",
          label: "정보보안 투자 비율",
          data: [14, 16, 17],
          borderColor: ["rgba(255, 127, 80)", "rgba(255, 127, 80)", "rgba(255, 127, 80)"],
          backgroundColor: ["rgba(255, 253, 208)", "rgba(255, 253, 208)", "rgba(255, 253, 208)"],
          yAxisID: "y",
          unit: "%",
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "정보보안",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          offset: true,
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: true,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '퍼센트(%)',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 3,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          min: 12,
          max: 18,
          type: "linear",
          offset: false,
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "G_이사회",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "line",
          label: "이사회 참석률",
          data: [90, 90, 95],
          borderColor: ["rgba(255, 127, 80)", "rgba(255, 127, 80)", "rgba(255, 127, 80)"],
          backgroundColor: ["rgba(255, 253, 208)", "rgba(255, 253, 208)", "rgba(255, 253, 208)"],
          yAxisID: "y-right",
          unit: "%",
        },
        {
          type: "bar",
          label: "사내이사",
          data: [2, 2, 2],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "명",
        },
        {
          type: "bar",
          label: "사외이사",
          data: [2, 3, 3],
          borderColor: ["rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)"],
          backgroundColor: ["rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "명",
        },
        {
          type: "bar",
          label: "기타비상무이사",
          data: [1, 1, 1],
          borderColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          backgroundColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "명",
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "이사회 구성 및 운영",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '명(people)',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 1.75,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          max: 3.5,
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
        "y-right": {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '퍼센트(%)',
            padding: {
              top: 4,
              bottom: 4,
            },
            color: "#666",
          },
          min: 87,
          max: 96,
          ticks: {
            stepSize: 4.5,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          position: "right",
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          type: "linear",
          offset: false,
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y-right",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "G_윤리경영",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "line",
          label: "윤리제보 처리율",
          data: [80, 83.33, 100],
          borderColor: ["rgba(255, 127, 80)", "rgba(255, 127, 80)", "rgba(255, 127, 80)"],
          backgroundColor: ["rgba(255, 253, 208)", "rgba(255, 253, 208)", "rgba(255, 253, 208)"],
          yAxisID: "y-right",
          unit: "%",
        },
        {
          type: "bar",
          label: "윤리제보 접수 건수",
          data: [10, 12, 11],
          borderColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          backgroundColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "건",
        },
        {
          type: "bar",
          label: "윤리제보 처리 건수",
          data: [8, 10, 11],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "건",
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "윤리제보 현황",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '건(cases)',
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 7,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          max: 14,
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
        "y-right": {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: '퍼센트(%)',
            padding: {
              top: 4,
              bottom: 4,
            },
            color: "#666",
          },
          min: 0,
          max: 120,
          ticks: {
            stepSize: 60,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          position: "right",
          grid: {
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          type: "linear",
          offset: false,
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y-right",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "G_재무실적",
    data: {
      labels: ["2021", "2022", "2023"],
      datasets: [
        {
          type: "line",
          label: "총 자산",
          data: [30, 36, 35],
          borderColor: ["rgba(255, 127, 80)", "rgba(255, 127, 80)", "rgba(255, 127, 80)"],
          backgroundColor: ["rgba(255, 253, 208)", "rgba(255, 253, 208)", "rgba(255, 253, 208)"],
          yAxisID: "y-right",
          unit: "억원",
        },
        {
          type: "bar",
          label: "총 매출액",
          data: [10, 15, 13],
          borderColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          backgroundColor: ["rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)", "rgba(0, 128, 128, 0.3)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "억원",
        },
        {
          type: "bar",
          label: "매출총이익",
          data: [8, 12, 10],
          borderColor: ["rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)"],
          backgroundColor: ["rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.45)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "억원",
        },
        {
          type: "bar",
          label: "총 영업이익",
          data: [6, 8, 7],
          borderColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          backgroundColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.6)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "억원",
        },
        {
          type: "bar",
          label: "총 법인세 비용",
          data: [1, 2, 1],
          borderColor: ["rgba(0, 0, 0, 0.3)", "rgba(0, 0, 0, 0.3)", "rgba(0, 0, 0, 0.3)"],
          backgroundColor: ["rgba(0, 0, 0, 0.3)", "rgba(0, 0, 0, 0.3)", "rgba(0, 0, 0, 0.3)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "억원",
        },
        {
          type: "bar",
          label: "당기순이익",
          data: [5, 6, 6],
          borderColor: ["rgba(0, 128, 128, 0.75)", "rgba(0, 128, 128, 0.75)", "rgba(0, 128, 128, 0.75)"],
          backgroundColor: ["rgba(0, 128, 128, 0.75)", "rgba(0, 128, 128, 0.75)", "rgba(0, 128, 128, 0.75)"],
          barPercentage: 0.4,
          yAxisID: "y",
          unit: "억원",
        },

      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "주요 재무 실적",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: null,
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {
        x: {
          axis: "x",
          display: true,
          title: {
            display: false,
            text: null,
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          type: "category",
          offset: true,
          grid: {
            offset: true,
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            color: "rgba(0,0,0,0.1)",
          },
          ticks: {
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          reverse: false,
          beginAtZero: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          border: {
            display: true,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.5)",
          },
          id: "x",
          position: "bottom",
        },
        y: {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: "억원(100Mil Won)",
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 8,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: true,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y",
          position: "left",
        },
        "y-right": {
          axis: "y",
          display: true,
          title: {
            display: true,
            text: "억원(100Mil Won)",
            color: "#666",
            padding: {
              top: 4,
              bottom: 4,
            },
          },
          ticks: {
            stepSize: 20,
            minRotation: 0,
            maxRotation: 50,
            mirror: false,
            textStrokeWidth: 0,
            textStrokeColor: "",
            padding: 3,
            display: true,
            autoSkip: true,
            autoSkipPadding: 3,
            labelOffset: 0,
            minor: {},
            major: {},
            align: "center",
            crossAlign: "near",
            showLabelBackdrop: false,
            backdropColor: "rgba(255, 255, 255, 0.75)",
            backdropPadding: 2,
            color: "#666",
          },
          type: "linear",
          beginAtZero: true,
          offset: false,
          reverse: false,
          bounds: "ticks",
          clip: true,
          grace: 0,
          grid: {
            display: false,
            lineWidth: 1,
            drawOnChartArea: true,
            drawTicks: true,
            tickLength: 8,
            offset: false,
            color: "rgba(0,0,0,0.1)",
          },
          border: {
            display: false,
            dash: [],
            dashOffset: 0,
            width: 1,
            color: "rgba(0,0,0,0.1)",
          },
          id: "y-right",
          position: "right",
        },
      },
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },

  {
    label: "G_주주",
    data: {
      labels: ["주주A 지분율", "주주B 지분율", "주주C 지분율", "기타"],
      datasets: [
        {
          type: "doughnut",
          label: "2023",
          data: [75, 15, 5, 5],
          borderColor: ["white", "white", "white", "white"],
          backgroundColor: ["rgba(0, 128, 128, 0.6)", "rgba(0, 128, 128, 0.45)", "rgba(0, 128, 128, 0.3)", "rgba(0, 0, 0, 0.3)"],
          unit: "%",
          hoverOffset: 4
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "FY2023 주주 현황",
          font: {
            lineHeight: 1.2,
            size: 20,
          },
          position: "top",
        },
        subtitle: {
          display: false,
          text: "",
          font: {
            lineHeight: 1.2,
            size: 16,
          },
          position: "top",
        },
        legend: {
          display: true,
          title: {
            padding: 3,
            display: false,
            text: null,
          },
          labels: {
            font: {
              size: 12,
            },
          },
          position: "bottom",
        },
        customCanvasBackgroundColor: {
          color: "white",
        },
      },
      scales: {},
      layout: {
        padding: 20,
      },
    },
    plugins: [
      {
        id: "customCanvasBackgroundColor",
      },
    ],
  },
];

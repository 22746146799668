<template>
  <div>
    <b-modal
      id="modal-footer-sm"
      v-model="showPaymentModal"
      header-bg-variant="light"
      header-text-variant="dark"
      content-class="shadow"
      button-size="md"
      size="xl"
      class="modal-open"
      no-close-on-backdrop
      scrollable
      hide-footer
    >
      <b-overlay :show="loadingShow" no-wrap></b-overlay>
      <template #modal-header>
        <!-- Emulate built in modal header close button action -->
        <div class="w-100">
          <a size="sm" @click.stop="$router.push({ name: 'Main' })">
            <b-icon
              class="cursor float-right"
              icon="x"
              aria-hidden="true"
              font-scale="2"
            ></b-icon>
            <h6 class="">
              <span>
                새 보고서를 생성하려면 결제가 필요합니다.
              </span>
              <!-- <span v-if="licenseInfo && licenseInfo.isUsed && licenseInfo.payment_status">
                구독 상품을 변경하려면 문의가 필요합니다.
              </span> -->
            </h6>
          </a>
          
        </div>
      </template>

      <b-form class="payment-modal-tab">
        <!-- https://bootstrap-vue.org/docs/components/modal#prevent-closing 참고 -->
        <b-tabs v-model="activeTab">
          <b-tab title="selectService" :disabled="!allowSelectService" >
            <template #title>
              <!-- <b-spinner v-if="!selectedService" type="grow" small /> -->
              <!-- <b-icon v-if="selectedService" icon="check" aria-hidden="true"></b-icon> -->
              상품 선택
            </template>

            <PaymentRegular ref="subscriptionServiceTable" />
            <!-- <b-form-group label="Field 1" invalid-feedback="Name is required">
              <b-form-input
                v-model="field1"
                autofocus
                class="wide-input-form border-teal bottom-teal-focus"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Field 2" invalid-feedback="Name is required">
              <b-form-input v-model="field2"></b-form-input>
            </b-form-group> -->

            <div class="text-right my-3">
              <b-button variant="teal" @click="nextTab">
                <span class="f-95 pr-1">다음</span> <fa icon="angle-right"></fa
              ></b-button>
            </div>
          </b-tab>

          <b-tab title="Tab 2" :disabled="!allowSlectedPayMethod">
            <template #title>
              <!-- <b-spinner type="grow" small /> -->
              <!-- <b-icon v-else icon="check" aria-hidden="true"></b-icon> -->
              <span v-if="isFree !== true">
                결제수단 등록
              </span>
              <span v-else>
                정기결제 등록
              </span>
            </template>

            <!-- <b-form-group label="Field 3" invalid-feedback="Name is required">
              <b-form-select
                v-model="field3"
                :options="options"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Field 4" invalid-feedback="Name is required">
              <b-form-checkbox v-model="field4"></b-form-checkbox>
            </b-form-group> -->

            <AddPaymentMethod
              v-if="isFree !== true"
              class="mt-3 ml-5"
              ref="paymethodComponent"
              :isPage="false"
            />


            <b-card
            class="mt-5 mb-3 ml-5 shadow-sm"
              header-tag="header"
              header="결제 예정 금액"
              style="max-width: 25rem;"
              no-footer
            >
            <template #header>
              <div class="f-105 fw-600">선택한 상품 및 결제 금액</div>
            </template>
              <div v-if="selectedPriceDiv">
                  <div v-if="discountCodeInfo !== null && discountCodeInfo.subscription_id === selectedService">
                    <!-- discount code info가 존재하는 경우 -->
                    <div class="d-flex w-100 justify-content-between mb-2">
                      <div class="mb-1 fw-400"> 
                        <span class="mb-0" v-if="selectedService.includes('year')"> {{ priceTable[selectedService]["service_name"]}} (12개월)</span>
                        <span class="mb-0" v-else> {{ priceTable[selectedService]["service_name"]}} (30일)</span>
                      </div>
                      <div>
                        <p v-if="selectedService.includes('year')" class="mb-0 text-right">
                          <del>
                            연 {{this.formatCurrency(priceTable[`${selectedService.split("_")[0]}_month`]["service_fee"]*12)}} 원
                          </del>
                        </p>
                        <p v-else class="mb-0">
                          <del>
                            월 {{this.formatCurrency(priceTable[`${selectedService.split("_")[0]}_month`]["service_fee"])}} 원
                          </del>
                        </p>

                        <p class="mb-0 fw-400"> 
                          <span class="badge badge-danger">쿠폰 할인가</span>
                          {{  this.formatCurrency(discountCodeInfo.discount_price) }}원
                        </p>
                      </div>
                    </div>

                    
                    <div class="d-flex w-100 justify-content-between mb-3">
                      <div>부가세(VAT)</div>
                      <div>{{  this.formatCurrency(discountCodeInfo.discount_price + discountCodeInfo.discount_price*0.1) }}원
                      </div>
                    </div>

                    <div class="border-top pt-3 d-flex w-100 justify-content-between" style="text-align: center;">
                      <div class="red7 fw-600">최종 결제 금액 (부가세 포함)</div>
                      <div class="red7 fw-600">
                        {{ this.formatCurrency(discountCodeInfo.discount_price + discountCodeInfo.discount_price*0.1) }}원 
                      </div>
                    </div>
                  </div>


                  <div v-else>
                      <div class="d-flex w-100 justify-content-between mb-2">
                      <div class="mb-1 fw-400"> {{ priceTable[selectedService]["service_name"] }}
                        <span class="mb-0" v-if="selectedService.includes('year')"> (12개월)</span>
                        <span class="mb-0" v-else> (30일)</span>
                      </div>
                      <div class="text-right">
                        <p v-if="selectedService.includes('year')" class="mb-0">
                          <del>
                            연 {{this.formatCurrency(priceTable[`${selectedService.split("_")[0]}_month`]["service_fee"]*12)}} 원
                          </del>
                        </p>
                        <p class="mb-0 fw-400"> 
                          <span v-if="selectedService.includes('year')" class="badge badge-danger">10% 할인</span>
                          {{  this.formatCurrency(priceTable[selectedService]["service_fee"]) }}원
                        </p>
                      </div>
                    </div>


                    <div class="d-flex w-100 justify-content-between mb-3">
                      <div>부가세(VAT)</div>
                      <div>{{  this.formatCurrency(priceTable[selectedService]["service_fee"]*0.1) }}원
                      </div>
                    </div>

                    <div class="border-top pt-3 d-flex w-100 justify-content-between" style="text-align: center;">
                      <div class="red7 fw-600">최종 결제 금액 (부가세 포함)</div>
                      <div class="red7 fw-600">
                        {{ this.formatCurrency(priceTable[selectedService]["service_fee"] + priceTable[selectedService]["service_fee"]/10) }}원 
                      </div>
                    </div>
                  </div>
              </div>
            </b-card>

            <div
              style=" white-space: nowrap; width: 100%; display: flex; justify-content: space-between;"
            >
              <div style="display: inline-block;" class="text-left my-3 ml-3">
                <b-button variant="tealgray" @click="prevTab">
                  <fa icon="angle-left"></fa><span class="f-95 pl-1">이전</span>
                </b-button>
              </div>
              <div style="display: inline-block;" class="text-right mt-3 mr-3">
                <b-button
                  variant="teal"
                  class="ml-3"
                  @click="checkSubscription"
                >
                  <span v-if="isFree !== true">
                    결제하기
                  </span>
                  <span v-else>
                    정기결제 서비스 등록
                  </span>
                </b-button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import PaymentRegular from "@/components/payment/modal/PaymentRegular.vue";
import AddPaymentMethod from "@/components/payment/AddPaymentMethod.vue";
import ChannelService from "@/components/ChannelService";

export default {
  mounted() {
    this.fetchRegularServicePriceTable();
  },
  computed: {
    ...mapState(["e_mail", "user_id", "is_confirmed", "licenseInfo"]),
  },
  data() {
    return {
      isFree: false,
      selectedPriceDiv: null,
      selectedService: null,
      showPaymentModal: true,
      activeTab: 0,
      options: ["Option 1", "Option 2", "Option 3"],
      allowSelectService: true,
      allowSlectedPayMethod: false,
      loadingShow: false,
      priceTable: null,
      discountCodeInfo: null
      
    };
  },
  updated() {
    this.$nextTick(() => {
      this.$watch(
        () => this.$refs.subscriptionServiceTable.selectedService,
        (newSelectedService) => {
          this.selectedService = newSelectedService;
        }
      );
    });
  },
  components: {
    PaymentRegular,
    AddPaymentMethod,
  },
  methods: {
    ...mapActions(["updateLicenseInfo"]),
    formatCurrency: function(amount) {
      // TODO: 이런건 util.js같이 utility 함수파일을 별도로 만들어서 쓸 수 있게 하고싶은데..
      const formatter = new Intl.NumberFormat("ko-KR");
      return formatter.format(amount);
    },
    fetchRegularServicePriceTable() {
      axios
        .get(
          this.$store.state.backend_host +
            "/payment/get_subscription_info_table"
        )
        .then((response) => {
          this.loadingShow = false;
          this.priceTable = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    nextTab() {

      // 기존에 구독중인 상품이 존재하는 경우, 다른 상품으로 바꾸려면 문의가 필요
      if (this.licenseInfo) {
        const requestOpenChannel = window.confirm("구독 상품을 변경을 원하실 경우 우측 하단의 채널톡으로 문의해주세요.")

        if (requestOpenChannel) ChannelService.showMessenger();
        return;
      }

      if (document.querySelector(".productSelectedBtn")) {
        this.selectedPriceDiv = document.querySelector(
          ".productSelectedBtn"
        ).childNodes[0].outerHTML;
        this.selectedService = this.$refs.subscriptionServiceTable.selectedService;
      } else {
        window.alert("상품을 선택해주세요!");
      }

      if (this.selectedService) {
        // if (this.activeTab == 0 && subscriptionServiceTable)
        this.allowSelectService = !this.allowSelectService;
        this.allowSlectedPayMethod = !this.allowSlectedPayMethod;
        this.activeTab += 1;
      }


      const discountCodeInfoRefs = this.$refs.subscriptionServiceTable
        .discountCodeInfo;


      if (discountCodeInfoRefs !== null && this.selectedService === discountCodeInfoRefs?.subscription_id) {
        this.discountCodeInfo = discountCodeInfoRefs;
      }
      if (
        this.selectedService === discountCodeInfoRefs?.subscription_id &&
        discountCodeInfoRefs.discount_price === 0
      ) {
        this.isFree = true;
      } else {
        this.isFree = false;
      }
    },
    prevTab() {
      this.allowSelectService = !this.allowSelectService;
      this.allowSlectedPayMethod = !this.allowSlectedPayMethod;
      this.activeTab -= 1;
    },
    checkSubscription() {
      
      let selectedMethod = null

      if (this.$refs.paymethodComponent) {
        selectedMethod= this.$refs.paymethodComponent.selectedMethod;
      } 



      const needMethods = !(
        this.discountCodeInfo && this.discountCodeInfo.subscription_id === this.selectedService &&
        this.discountCodeInfo.discount_price === 0
      );
      if (needMethods && !selectedMethod) {
        window.alert("결제수단을 추가해주세요");
      } else {
        this.loadingShow = true;

        const path =
          this.$store.state.backend_host + "/payment/make_subscription_license";

        axios
          .post(path, {
            e_mail: this.e_mail,
            user_id: this.user_id,
            selectedService: this.selectedService,
            discount_code:
              this.discountCodeInfo?.subscription_id === this.selectedService
                ? this.discountCodeInfo?.subscription_discount_code
                : null,
          })
          .then((response) => {
            // response.data
            this.loadingShow = false;
            if (response.data.code) {
              this.updateLicenseInfo();
              window.alert(response.data.message);
              this.$router.push("/");
            } else {
              window.alert(response.data.message);
            }
          })
          .catch((error) => {
            this.loadingShow = false;
            window.alert("정기결제 갱신 중 문제가 발생했습니다.");
            console.log(error);
          });
      }
    },
  },
};
</script>
<style>
.payment-modal-tab > .tabs > div > ul > li > .nav-link.active {
 background-color: transparent ;
 font-weight: 500;
 border-width: 1.8px;
 border-color: #dee2e6 #dee2e6 #F8F8F8 #dee2e6;
}
.payment-modal-tab > .tabs > div > ul{
  border-width: 1.8px;
}
</style>
<template>
    <div>
        <h4>그래프 관리</h4>
        <p class="f-95 mb-5 gray-600">보고서 작성에 적용할 그래프 목록을 관리합니다.</p>

        <ChartCaseList 
            :editing_mode = false
        />
        

        


    </div>
</template>
<script>
import ChartCaseList from '@/components/writing_report/chart_subs/ChartCaseList.vue'


export default {
    name: 'MgCharts',
    props: {},
    components: {
        ChartCaseList
    },
    data() {
        return {
            example: '',
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {}
}
</script>
<style>
</style>
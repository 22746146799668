<template>
<div>
    <h4 id="ts1">팀 구성</h4>
    <p class="f-95 mb-4 gray-600">보고서를 함께 편집할 팀을 구성합니다.</p>

    <b-card class="general-card mb-5">
        <b-card-header>팀 - {{ local_report.report_name }}</b-card-header>
        <div class="py-3">
            <b-row no-gutters class="py-2 mb-3">
                <b-col cols="3"><div class="gray-600">팀 현황</div> </b-col>  
                <b-col>
                    <b-table-simple class="txt-table" responsive hover>
                        <b-thead><b-tr>
                            <b-td>No.</b-td><b-td>이메일</b-td><b-td>권한</b-td><b-td>초대일</b-td><b-td>수락</b-td>
                            <b-td v-if="is_editing==true" class="red"> 삭제</b-td>
                        </b-tr></b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>{{ 1 }}</b-td><b-td>{{ local_report.owner_e_mail }}</b-td><b-td>소유자</b-td><b-td>-</b-td><b-td>-</b-td>
                                <b-td v-if="is_editing==true">-</b-td>
                            </b-tr>
                            <b-tr v-for="(user, index) in copied_coauthors" :key="user.e_mail">
                                <b-td>{{ index + 2 }}</b-td><b-td>{{ user.e_mail }}</b-td><b-td>팀원</b-td><b-td>{{ user.invited_date }}</b-td><b-td>{{ user.accepted ? '수락완료' : '아직 수락하지 않음' }}</b-td>
                                <b-td v-if="is_editing==true"><b-button variant="icon-red" class="p-0" @click="deleteInList(user.e_mail)"> <fa icon="minus-square"/> </b-button></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-button v-if="check_owner==true&&copied_coauthors.length>=0" :variant="is_editing==true?'teal':'teal-border'" class="px-3 mr-3" @click="handleMulti()"> {{ is_editing==true ?'팀원 저장하기':'팀원 관리하기'}} </b-button>
            <b-button v-if="is_editing==true" class="px-3" @click="handleCancle()"> 취소 </b-button>
        </div>
    </b-card>
    

    <b-card class="general-card mb-5">
        <b-card-header>팀원 초대하기</b-card-header>
        <div v-if="check_owner!=true" class="py-3">
            권한이 없습니다.
        </div>
        <div v-else class="py-3">
            <p class="gray-600">
                이메일 초대장을 보내 팀원을 초대합니다. <br>
                초대된 팀원은 본 보고서의  모든 컨텐츠를 편집할 수 있으며, 데이터 테이블, 정책문서, 비즈니스 사례 등에 제한없이 접근 가능합니다. 
                <span class="f-85">(<fa icon="info-circle"/> 단, 보고서 삭제 및 팀 구성 및 권한 관리에는 접근이 제한됩니다.)</span>
            </p>
            <b-row no-gutters class="hover-gray py-2 mb-3">
                <b-col cols="3"><div class="gray-600">팀원 초대하기</div> </b-col>  
                <b-col v-if="waiting_for_mailing != true">
                    <b-input-group class="">
                        <b-form-input class="narrow-input-form" placeholder="이메일 입력" v-model.trim="coauthor_email" @keyup.enter="handleInvitation()"></b-form-input>
                        <b-input-group-append> <b-button variant="teal" class="f-95" @click="handleInvitation()">이메일로 초대하기</b-button> </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col v-else>
                    <b-input-group class="">
                        <b-form-input class="narrow-input-form" placeholder="이메일 입력" v-model.trim="coauthor_email" disabled></b-form-input>
                        <b-input-group-append> <b-button variant="teal" class="f-95" disabled><b-spinner small></b-spinner></b-button></b-input-group-append>
                    </b-input-group>
                    <p class="f-85 gray-600"> <fa icon="info-circle" class="mr-1"/>팀원 초대 이메일을 발송 중입니다. 잠시만 기다려주세요.</p>
                </b-col>




            </b-row>
            <b-row no-gutters class="hover-gray py-2">
                <b-col cols="3"><div class="gray-600">팀원 추가 프로세스</div> </b-col>  
                <b-col class="gray-600">
                   1) 이메일 초대장 발송  → 2) 참여 수락 → 3) 초대 완료
                </b-col>
            </b-row>
        </div>
    </b-card>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import * as ih from '@/components/util'

export default {
    data() {
        return {
            is_editing: false,
            local_report: {},
            copied_coauthors: [],
            temp_delete_coauthors: [],
            coauthor_email: '',
            waiting_for_mailing: false
        }
    },
    computed:{
        ...mapState(['user_id']),
        ...mapGetters('report_list', ['selected_report']),
        is_published(){
            if(this.local_report.state == 'published'){
                return false
            } else return true
        },
        check_owner(){
            if (this.local_report.owner_id == this.user_id){
                return true
            }
            else return false
        }
    },
    methods:{
        ...mapActions('report_list', ['inviteCoauthor', 'kickOutCoauthor']),
        async showAvailableUser() {
            try {
                const path = this.$store.state.backend_host + '/report/searchAvailableForOwnership?q='+ this.coauthor_email
                this.loadingShow = true
                const result = await axios.get(path)
    
                if (result) {
                    this.search_user_list = result.data
                } 
                
            } catch (error) {
                
            }
            this.loadingShow = false

        },
        handleMulti(){
            if( this.is_editing==true ){
                this.saveCoauthorList()
            }
            else {
                this.toggleEdit()
            }
        },
        handleCancle(){
            this.local_report = ih.deepCopy(this.selected_report)
            this.toggleEdit()
            this.copyCoauthors()
        },
        toggleEdit(){
            this.is_editing = !this.is_editing
        },
        deleteInList(e_mail){
            // 1. copied_coauthors에서 선택된 항목을 제거함. 
            let idx = this.copied_coauthors.findIndex(item => item.e_mail == e_mail)
            if(idx > -1){
                this.temp_delete_coauthors.push(this.copied_coauthors[idx])
                this.copied_coauthors.splice(idx, 1)
                // console.log('선택된 coauthor가 임시 리스트에 포함됩니다.' + e_mail)
                // console.log('선택된 coauthor가 화면에 보이는 리스트에서 제거됩니다.' + e_mail)
            } else {
                console.log('push 실패, splice 실패')
            }
        },
        saveCoauthorList(){
            this.temp_delete_coauthors.forEach(item => {
                this.kickOutCoauthor({report_code: item.report_code, target_e_mail: item.e_mail})
                this.$EventBus.$emit('make-toast', '팀원 삭제', `${item.e_mail}님을 팀원에서 삭제하였습니다.`) 
            })
            this.toggleEdit()
        },
        handleInvitation(){
            if( this.coauthor_email != '' && this.coauthor_email != this.local_report.owner_e_mail ){
                this.waiting_for_mailing = !this.waiting_for_mailing
                this.inviteCoauthor({ report_code: this.local_report.report_code, target_e_mail: this.coauthor_email })
                .then( response => {
                    // console.log(response.data.msg)

                    if (response.data.code === 'failure') {
                        window.alert(response.data.msg)

                    } else {
                        this.$EventBus.$emit('make-toast', '초대 성공', `${response.data.e_mail}님을 팀원으로 초대하였습니다.`)
                        
                        this.local_report = ih.deepCopy(this.selected_report)
                        this.fetchCurrentCoauthor();

                    }
                    this.coauthor_email = ''
                    this.waiting_for_mailing = !this.waiting_for_mailing
                })
            } else if( this.coauthor_email == this.local_report.owner_e_mail ) {
                alert('본인을 공동작업자로 초대할 수 없습니다.') 
            } else {
                alert('초대에 실패하였습니다.')
            }
        },
        copyCoauthors(){
            this.fetchCurrentCoauthor();

            this.temp_delete_coauthors = []
            // console.log('취소를 눌렀습니다. 다시 원상복귀됩니다.')
            // console.log('copied_coauthors 항목 개수: ' + this.copied_coauthors.length)
            // console.log('temp_delete_coauthors 항목 개수: ' + this.temp_delete_coauthors.length)
        },
        toggleSpinner(){
            this.waiting_for_mailing = !this.waiting_for_mailing
        },
        async fetchCurrentCoauthor() {
            try {
                const path = this.$store.state.backend_host + "/fetch_current_coauthor?rc=" + this.local_report.report_code;
                const response = await axios.get(path)

                if (response.data.code) {
                    this.copied_coauthors = response.data.result
                } else {
                    window.alert("팀원의 정보를 가져오는데 실패했습니다.")
                }
                
            } catch (error) {
                window.alert("팀원의 정보를 가져오는데 실패했습니다.")
            }
        }
    },
    
    created(){
        this.local_report = ih.deepCopy(this.selected_report)
        this.fetchCurrentCoauthor();
    }
}
</script>

<style>

</style>
<template>
    <div>
        <h4>GRI Standards에 부합한 보고</h4>
        <p class="f-95 mb-4 gray-600">
            GRI 보고 표준에 부합한 방식으로 보고하기 위한 요건에 대해 안내 드립니다.
        </p>


        <b-card class="general-card mb-5">
            <b-card-header>GRI 'In accordance with' 요건</b-card-header>
            <div class="py-3">
                <p class="gray-600">
                    GRI 보고 표준에 부합한(in accordance with the GRI Standards) 보고를 통해 
                    조직은 경제, 환경, 사람, 인권에 대한 영향과, 해당 영향을 어떻게 관리하고 있는지에 대해 포괄적으로 제시할 수 있습니다. 
                </p>
                <p class="gray-600 mb-45"> 
                    GRI 보고 표준에 부합한 보고를 위해서는 9가지 요건을 충족해야 하며, 모든 요건을 충족하기 어려울 경우에는 
                    GRI에 부합한 보고가 아닌, GRI를 참고한 보고임을 보고서 내 명시해야 합니다. 
                </p>

                <div class="mb-2 fw-600">GRI에 부합한 보고(in accordance with)를 위한 9가지 요건</div>
                <b-card class="bgray-200 border-0 px-2  mb-45">
                    <p class="mb-3">GRI에 부합한 보고(in accordance with): 모든 중요 주제 및 관련 영향, 관리 방법에 대해 보고하는 방식으로, 조직이 경제, 환경 및 사회에 미치는 중요한 영향에 대해 포괄적인 정보를 보고합니다.<br>(GRI 1: Foundation 2021, 섹션 3, 11페이지 참조.)</p>
                    <b-table-simple
                        id="gri-accordance-notice" class="mb-0"
                        hover 
                        small 
                        bordered
                        responsive
                    >
                        <b-tr class="table-header text-center">
                            <b-th>요건 N.</b-th>
                            <b-th>요건 내용</b-th>
                            <b-th>Tool 사용 시 자동 충족</b-th>
                        </b-tr>
                        <b-tbody>
                            <b-tr>
                                <b-th class="fw-500 text-center">1</b-th>
                                <b-td>보고 원칙 적용</b-td>
                                <b-td class="text-center">O</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="fw-500 text-center">2</b-th>
                                <b-td>GRI 2: 일반 보고사항 적용</b-td>
                                <b-td class="text-center">O</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="fw-500 text-center">3</b-th>
                                <b-td>중요 주제 결정</b-td>
                                <b-td class="text-center">O</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="fw-500 text-center">4</b-th>
                                <b-td>GRI 3: 중요주제에 대한 보고</b-td>
                                <b-td class="text-center">O</b-td>
                            </b-tr>
                            <b-tr variant="info">
                                <b-th class="fw-500 text-center">5</b-th>
                                <b-td class="fw-700">각 중요 주제에 대해 보고</b-td>
                                <b-td class="text-center fw-700">X</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="fw-500 text-center">6</b-th>
                                <b-td>필수 보고 지표를 보고할 수 없는 경우, 관련 이유를 설명해야 함</b-td>
                                <b-td class="text-center">O</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="fw-500 text-center">7</b-th>
                                <b-td>GRI Content Index 발간</b-td>
                                <b-td class="text-center">O</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="fw-500 text-center">8</b-th>
                                <b-td>성명(statement of use) 제시</b-td>
                                <b-td class="text-center">O</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="fw-500 text-center">9</b-th>
                                <b-td>GRI에 보고</b-td>
                                <b-td class="text-center">O</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>

                    <div>
                        <p class="fw-500 pl-2 pr-3 pt-3">
                            <b-icon icon="check" scale="1.5"/>

                            '요건5. 각 중요 주제에 대해 보고' 요건을 충족하기 위해 '중요주제'로 선택한 주제에 대해 3-3 지표를 선택하고 보고해야 합니다. 
                        </p>
                        <p class="fw-500 pl-2 pr-3">
                            <b-icon icon="check" scale="1.5"/>

                            요건5를 충족하지 않는 경우, GRI를 참고하여(with reference to the GRI Standards) 보고하는 것으로 정리됩니다.
                        </p>
                        <p class="fw-500 pl-2 pr-3 mb-0">
                            <b-icon icon="check" scale="1.5"/>

                            GRI에 따른 보고(in accordance with) 또는 GRI를 참고한 보고(with reference to) 여부는 보고서에 게재되는 사용 성명(Statement of use)에 반영됩니다. 
                        </p>
                    </div>
                </b-card>


                <div class="mb-2 fw-600">GRI를 참조한 보고(with reference to)를 위한 3가지 요건</div>
                <b-card class="bgray-200 border-0 px-2">
                    <p class="mb-3">GRI를 참조한 보고(with reference to): GRI 표준에 따라 보고하는 요구 사항 중 일부를 충족할 수 없거나 특정 목적을 위해 특정 정보만을 보고하려는 경우 GRI 표준의 일부를 사용할 수 있습니다. <br>(GRI 1: Foundation 2021, 섹션 3, 18페이지 참조.)</p>

                    <b-table-simple
                        id="gri-accordance-notice" class="mb-0"
                        hover 
                        small 
                        bordered
                        responsive
                    >
                        <b-tr class="table-header text-center">
                            <b-th>요건 N.</b-th>
                            <b-th>요건 내용</b-th>
                            <b-th>Tool 사용 시 자동 충족</b-th>
                        </b-tr>
                        <b-tbody>
                            <b-tr>
                                <b-th class="fw-500 text-center">1</b-th>
                                <b-td>GRI Content Index 게시</b-td>
                                <b-td class="text-center">O</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="fw-500 text-center">2</b-th>
                                <b-td>사용 성명(Statement of Use) 제공</b-td>
                                <b-td class="text-center">O</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="fw-500 text-center">3</b-th>
                                <b-td>GRI에 통보</b-td>
                                <b-td class="text-center">O</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>

                </b-card>
            </div>
        </b-card>
    </div>
</template>
<script>
export default {
    name: '',
    props: {},
    components: {},
    data() {
        return {




    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {}
}
</script>
<style>
    #gri-accordance-notice{
        background: white;

    }

    #gri-accordance-notice tr{
        border-top: 2px solid black !important;
        border-bottom: 2px solid black !important;

    }
    #gri-accordance-notice tr td:nth-child(2), #gri-accordance-notice tr th:nth-child(2){
        border-left: 2px solid black !important;
        border-right: 2px solid black !important;

    }

    #gri-accordance-notice .table-header{
        background: #efefef;
    }


</style>
<template>
    <div id="app">
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <!-- v-if="process.env.VUE_APP_REPORT_CODE"   -->
        <FullView2021
        
        />
    </div>
  </template>
  
  <script>
  // import HelloWorld from '@/components/HelloWorld'
  // import ViewerMaster from '@/components/report_preview/ViewerMaster'
  import FullView2021 from '@/components/report_preview/gri2021_viewer/FullView2021'
  import { mapMutations } from 'vuex'

  
  export default {
      data(){
          return {
              qs: ''
          }
      },
      methods:{
          ...mapMutations('report_list', ['change_lang']),
          makeToast(title, contents, append = false) {
          this.$bvToast.toast(`${contents}`, {
              title: title,
              toaster: 'b-toaster-bottom-center',
              solid: true,
              appendToast: append,
              // noAutoHide: true,
              autoHideDelay: 2000,
              variant: 'info'
          })
          },
          getQueryStringObject() {
              let a = window.location.search.substr(1).split('&');
              if (a == "") return {};
              let b = {};
              for (let i = 0; i < a.length; ++i) {
                  let p = a[i].split('=', 2);
                  if (p.length == 1)
                      b[p[0]] = "";
                  else
                      b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
              }
              return b;
          }
      },
      created() {
          this.$EventBus.$on('make-toast', this.makeToast)
          this.qs = this.getQueryStringObject()
          if(this.qs.l && this.qs.l=='ENG'){
              this.change_lang('ENG')
          } else {
            this.change_lang('KOR')
          }
      },
      components: {
          FullView2021
      },
  }
  
  </script>
  
  <style>

  </style>
  
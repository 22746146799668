<template>
<div>
    <h4 class="mb-5">GRI Topic - Economic</h4>
    
    <div class="py-3 mb-4" v-for="item in gri_200_topics" :key="item.id">
        <h6 :id="'te'+item.topic_id" class="mb-4 pb-1 gray-600 border-bottom-gray-550">GRI {{item.topic_id}} - {{item.topic_name}}</h6>

        <template v-if="report_contents_loaded==true && business_case_loaded==true">
            <b-table-simple responsive class="wri-table"> 
                <b-thead><b-tr>
                    <b-td style="width:14%">지표</b-td><b-td style="width:34%">국문</b-td><b-td>번역하기</b-td>
                </b-tr></b-thead>
                <b-tbody>
                    <b-tr v-for="item in series_contents[item.topic_name]" :key="item.id">
                        <b-td><span :id="'te'+item.gri_code" class="f-140">{{ item.gri_code }}</span> <p class="f-90"> {{ item.indicator_name }}</p></b-td>
                        <b-td>
                            <div v-if="item.direct_report!=''&&item.direct_report!=undefined" class="f-90 pb-3">{{item.direct_report}}</div>
                            <!-- 연결된 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
                            <section class="mt-5 pr-3" v-if="item.data_tables.length>0">
                                <div v-for="dt in item.data_tables" :key="dt.id" >
                                    <div class="f-90 mb-2 fw-500"><fa icon="table" class="mr-1"/> {{ hashed_data_tables[dt].title }} </div>
                                    <b-table-simple responsive class="bor-bg-table">
                                        <b-thead><b-tr>
                                            <b-td v-for="(property, col_index) in hashed_data_tables[dt].header" :key="col_index">{{ property }}</b-td>
                                        </b-tr></b-thead>
                                        <b-tbody><b-tr v-for="row_item in hashed_data_tables[dt].contents" :key="row_item.id">
                                            <b-td v-for="property in hashed_data_tables[dt].header" :key="property.id">{{ row_item[property] }}</b-td>
                                        </b-tr></b-tbody>
                                    </b-table-simple>
                                    <div class="f-85 gray-600">{{ hashed_data_tables[dt].footnote }}</div>
                                </div>
                            </section>
                            <section class="mt-5 pr-3" v-if="item.documents.length>0"> 
                                <div v-for="x in item.documents" :key="x.id">
                                    <a  v-if="hashed_docs[x].type!='link'" class="fw-500 mb-2 cursor" :href="$store.state.static_host+hashed_docs[x].path" target="_blank"><fa icon="file-alt" class="mr-1"/> {{ hashed_docs[x].title }} </a>  
                                </div>
                                <div v-for="y in item.documents" :key="y.id">
                                    <a v-if="hashed_docs[y].type=='link'" class="fw-500 mb-2 cursor" :href="hashed_docs[y].link" target="_blank"><fa icon="external-link-square-alt" class="mr-1"/> {{ hashed_docs[y].title }} </a>  
                                </div>
                            </section>
                            <section class="mt-5 pr-3" v-if="item.business_cases.length>0">
                                <div v-for="bc in item.business_cases" :key="bc.id">
                                    <a class="fw-500 d-block mb-2 cursor" @click="showBCModal(bc)"><fa icon="quote-left" class="mr-1"/> {{ hashed_business_cases[bc].title }} </a>
                                    <div v-if="show_item_modal==true" class="hj-modal-left bg-white rounded shadow" :style="low_height==true? 'height:50vh!important':''">
                                        <div class="hj-modal-header bgray-400 gray-800 fw-500 d-flex align-items-center justify-content-between px-4">
                                            <div><b-badge variant="basic" class="mr-2">Case.</b-badge> {{ hashed_business_cases[bc_selected].title }}</div>
                                            <div><b-button variant="icon" @click="low_height=!low_height"><fa :icon="low_height==true?'expand' :'compress-alt'"></fa> </b-button><b-button variant="icon" @click="hideBCModal"><fa icon="times"></fa> </b-button></div>
                                        </div> 
                                        <div class="hj-modal-body px-4 py-3" :style="low_height==true? 'height:calc(50vh - 50px)!important':''">
                                            <simple-business-case :business_case_id="bc_selected"></simple-business-case>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="mt-5 pr-3" v-if="item.charts.length>0">
                                <div v-for="chart_id in item.charts" :key="chart_id">
                                    <ChartTextCanvas 
                                        class="mb-5"
                                        :chart_id="chart_id" 
                                        :key="`chart_${chart_id}`"
                                        :viewer_mode="true"
                                    />
                                </div>
                            </section>

                            <div
                                v-if='item.nan_category'
                                class="mt-5"
                            >
                                <i>
                                    <span class="fw-500">생략사유</span>
                                    [{{ nan_table[item.nan_category]['KOR'] }}] 
                                    <p>
                                        {{ item.nan_reason_contents}}
                                    </p>
                                </i>

                                
                            </div>
                        </b-td>
                        <b-td>
                            <template v-if="item.disclosure_id">

                                    <b-form-textarea v-if="item.direct_report_ea!=''&&item.direct_report_ea!=null" v-model="writing_eng[item.gri_code]" :disabled="is_editing_eng[item.gri_code]==false" rows="4" class="f-90 mb-2"></b-form-textarea>
                                    <div class="d-flex justify-content-between" v-if="selected_report.is_able_to_edit">
                                        <b-button v-if="is_editing_eng[item.gri_code]==false" variant="teal-border" size="sm" @click.stop="is_editing_eng[item.gri_code]=true"> 번역 수정하기 </b-button>
                                        <div v-else>
                                            <b-button variant="teal" size="sm" class="mr-2" @click="handleReInitiate(item.gri_code)" :disabled="re_initiating==true"> 번역 초기화 <fa icon="redo" :class="re_initiating==true?'spinning-icon':''"/></b-button>
                                            <b-button variant="red" size="sm" class="mr-2" @click="handleSave(item.gri_code)" :disabled="save_state[item.gri_code]=='saving'||re_initiating==true">저장</b-button>
                                            <b-button size="sm" @click="handleCancle(item.gri_code)"> 취소 </b-button>
                                        </div>
                                        <div class="f-90 blue"> 
                                            <template v-if="save_state[item.gri_code]=='saved'"><fa icon="check-circle" class="ml-1"/> 수동 번역 저장됨 </template>
                                            <template v-else-if="save_state[item.gri_code]=='saving'"><b-spinner class="blue" small></b-spinner> 저장 중</template>
                                        </div>
                                    </div> 
                                    <!-- 연결된 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
                                    <section class="mt-4 pr-3 border-right pt-2" v-if="item.data_tables.length>0">
                                        <div v-for="dt in item.data_tables" :key="dt.id" >
                                            <div class="f-90 mb-2 fw-500 d-flex justify-content-between align-items-start"><div><fa icon="table" class="mr-1"/> {{ hashed_data_tables[dt].title_e }} </div>
                                                <b-button variant="teal-border" size="sm" class="py-1 px-2" v-b-tooltip.hover title="번역 수정하기" @click.stop.prevent="translateTable(dt)"><fa icon="pen"/></b-button>
                                            </div>
                                            <b-table-simple responsive class="bor-bg-table">
                                                <b-thead><b-tr>
                                                    <b-td v-for="(property, col_index) in hashed_data_tables[dt].header_e" :key="col_index">{{ property }}</b-td>
                                                </b-tr></b-thead>
                                                <b-tbody><b-tr v-for="row_item in hashed_data_tables[dt].contents_e" :key="row_item.id">
                                                    <b-td v-for="property in hashed_data_tables[dt].header_e" :key="property.id">{{ row_item[property] }}</b-td>
                                                </b-tr></b-tbody>
                                            </b-table-simple>
                                            <div class="f-85 gray-600">{{ hashed_data_tables[dt].footnote_e }}</div>
                                        </div>
                                    </section>
                                    <section class="mt-4 pr-3 border-right pt-2" v-if="item.documents.length>0"> 
                                        <div v-for="x in item.documents" :key="x.id" class="mb-2 d-flex justify-content-between align-items-start">
                                            <template v-if="hashed_docs[x].type!='link'">
                                                <a  class="fw-500 cursor" :href="$store.state.static_host+hashed_docs[x].path_e" target="_blank"><fa icon="file-alt" class="mr-1"/> {{ hashed_docs[x].title_e }} </a>  
                                                <b-button variant="teal-border" size="sm" class="py-1 px-2" v-b-tooltip.hover title="번역 수정하기" @click.stop.prevent="translateDocument({ id: x, type: hashed_docs[x].type})"><fa icon="pen"/></b-button>
                                            </template>
                                            <template v-else>
                                                <a class="fw-500 cursor" :href="hashed_docs[x].link_e" target="_blank"><fa icon="external-link-square-alt" class="mr-1"/> {{ hashed_docs[x].title_e }} </a>  
                                                <b-button variant="teal-border" size="sm" class="py-1 px-2" v-b-tooltip.hover title="번역 수정하기" @click.stop.prevent="translateDocument({ id: x, type: hashed_docs[x].type})"><fa icon="pen"/></b-button>
                                            </template>
                                        </div>
                                    </section>
                                    <section class="mt-4 pr-3 border-right pt-2" v-if="item.business_cases.length>0">
                                        <div v-for="bc in item.business_cases" :key="bc.id">
                                            <div class="mb-2 fw-500 d-flex justify-content-between align-items-start">
                                                <a class="fw-500 cursor" @click="showBCModalE(bc)"><fa icon="quote-left" class="mr-1"/> {{ hashed_business_cases[bc].title_e }} </a>
                                                <b-button variant="teal-border" size="sm" class="ml-2 py-1 px-2" v-b-tooltip.hover title="번역 수정하기" @click.stop.prevent="translateBusinessCase(bc)"><fa icon="pen"/></b-button>
                                            </div>
                                            <div v-if="show_item_modal_e==true" class="hj-modal-right bg-white rounded shadow" :style="low_height_e==true? 'height:50vh!important':''">
                                                <div class="hj-modal-header bgray-600 gray-100 d-flex align-items-center justify-content-between px-4">
                                                    <div><b-badge variant="basic" class="mr-2">Case.</b-badge>  {{ hashed_business_cases[bc_selected_e].title_e }}</div> 
                                                    <div><b-button variant="icon-light" @click="low_height_e=!low_height_e"><fa :icon="low_height_e==true?'expand' :'compress-alt'"></fa> </b-button><b-button variant="icon-light" @click="hideBCModalE"><fa icon="times"></fa> </b-button></div>
                                                </div> 
                                                <div class="hj-modal-body px-4 py-3" :style="low_height_e==true? 'height:calc(50vh - 50px)!important':''">
                                                    <simple-business-case :business_case_id="bc_selected_e" :eng_mode="true"></simple-business-case>
                                                </div>
                                            </div>
                                        </div>
                                    </section> 

                                    <section class="mt-5 pr-3" v-if="item.charts.length>0">
                                        <div v-for="chart_id in item.charts" :key="chart_id">
                                            <ChartTranslateTextCanvas 
                                                class="mb-5"
                                                :chart_id="chart_id" 
                                                :key="`chart_${chart_id}`"
                                                :viewer_mode="true"
                                                lang="ENG"
                                            />
                                        </div>
                                    </section>
                                </template>         
                                <template v-else>
                                    <div>작성된 지표가 없습니다.</div>
                                </template>
                            <div
                                v-if='item.nan_category'
                                class="mt-5"
                            >
                                <i>
                                    <span class="fw-500">생략사유</span>
                                    [{{ nan_table[item.nan_category]['ENG'] }}]
                                    
                                    <b-form-textarea 
                                        v-model="item.nan_reason_contents_em" 
                                        :placeholder="item.nan_reason_contents_em ? item.nan_reason_contents_em : item.nan_reason_contents_ea"    
                                        :disabled="!edit_omission_reason[item.gri_code]" 
                                        rows="2" 
                                        class="f-90 mb-2"></b-form-textarea>
                                   
                                </i>

                                <div v-if="selected_report.is_able_to_edit">
                                    <div v-if="!edit_omission_reason[item.gri_code]" class="mt-3">
                                        <b-button variant="teal-border" size="sm" @click="edit_omission_reason[item.gri_code]=true"> 번역 수정하기 </b-button>
    
                                    </div>
                                    <div v-else class="mt-3">
                                        <b-button variant="teal" size="sm" class="mr-2" @click="handleOmissionReInitiate(item)" :disabled="re_initiating==true"> 번역 초기화 <fa icon="redo" :class="re_initiating==true?'spinning-icon':''"/></b-button>
                                        <b-button variant="red" size="sm" class="mr-2" @click="handleOmissionSave(item)" :disabled="edit_omission_state[item.gri_code]=='saving'||re_initiating==true">저장</b-button>
                                        <b-button size="sm" @click="cancelOmission(item)"> 취소 </b-button>
                                    </div>
                                </div>
                            </div>                       
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </template>
        <template v-else>
            <b-table-simple responsive class="wri-table">
                <b-thead><b-tr>
                    <b-td>지표</b-td><b-td style="width:45%">보고 내용 작성</b-td><b-td style="width:40%">작성 도우미</b-td>
                </b-tr></b-thead>
                <b-tbody>
                    <b-tr v-for="dummy in 5" :key="dummy">
                        <b-td><b-skeleton class="mb-5"></b-skeleton></b-td><b-td><b-skeleton class="mb-5"></b-skeleton></b-td><b-td><b-skeleton class="mb-5"></b-skeleton></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </template>     
    </div>
</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { nan_table } from '@/components/util'

import SimpleBusinessCase from '@/components/writing_report/business_case_subs/SimpleBusinessCase'
import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'
import ChartTextCanvas from "@/components/writing_report/chart_subs/ChartTextCanvas.vue";
import ChartTranslateTextCanvas from "@/components/writing_report/chart_subs/ChartTranslateTextCanvas.vue";

export default {
    mixins: [gri_mixin],
    data() {
        return {
            nan_table,
            is_editing_eng : {},
            writing_eng : {},
            save_state :{},
            show_item_modal: false,
            low_height: false,
            show_item_modal_e: false,
            low_height_e: false,
            re_initiating: false,
            bc_selected: 0,
            bc_selected_e: 0,
            edit_omission_reason: {},
            edit_omission_state: {}
        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState('report_contents', ['report_contents_loaded', 'series_100']),
        ...mapGetters('report_contents', ['gri_200_all_contents']),
        ...mapGetters('data_tables', ['hashed_data_tables']),
        ...mapGetters('documents', ['hashed_docs']),
        ...mapGetters('business_cases', ['hashed_business_cases']),
        ...mapState('business_cases', ['business_case_loaded']),
        gri_200_topics(){
            let topics = []
            for(let i=0; i<this.gri_200_all_contents.length; i++){
                let new_topics = {
                    topic_id: this.gri_200_all_contents[i].topic_id, 
                    topic_name: this.gri_200_all_contents[i].topic_name,
                    topic_name_e: this.gri_200_all_contents[i].topic_name_e
                }
                let idx = topics.findIndex(x => x.topic_id == new_topics.topic_id)
                if (idx == -1){
                    topics.push(new_topics)
                }
            }
            return topics
        },
        series_contents(){
            let temp_obj = {}
                let temp_list = []
                for (let w=0; w<this.gri_200_topics.length; w++){
                    temp_list = this.gri_200_all_contents.filter(x => x.topic_id == this.gri_200_topics[w].topic_id)                    
                    this.$set(temp_obj, this.gri_200_topics[w].topic_name, temp_list)
                }
            return temp_obj
        }
    },
    methods:{
        ...mapActions('report_contents', ['saveDisclosureE', 'saveTranslatedNaNDisclosure', 'reInitiateDisclosure', 'reInitiateNaNDisclosure']),
        cancelOmission(item) {
            this.edit_omission_reason[item.gri_code] = false  
        },
        async handleOmissionReInitiate(omission_info){
            this.re_initiating = true
            this.reInitiateNaNDisclosure({
                disclosure_id: omission_info.disclosure_id,
                nan_reason_contents_em: omission_info.nan_reason_contents_em,
                re_initiate: true
            })

            this.edit_omission_reason[omission_info.gri_code] = false  
            this.$EventBus.$emit('make-toast', '번역 초기화', omission_info.gri_code+'를 번역을 초기화 하였습니다.')
            this.re_initiating = false
        },
        async handleOmissionSave(omission_info){

            this.re_initiating = true
            this.saveTranslatedNaNDisclosure({report_code: this.report_code, disclosure_id: omission_info.disclosure_id, nan_reason_contents_em: omission_info.nan_reason_contents_em, re_initiate: true})

            this.edit_omission_reason[omission_info.gri_code] = false  

            this.$EventBus.$emit('make-toast', '번역 초기화', omission_info.gri_code+'를 번역을 초기화 하였습니다.')
            this.re_initiating = false
        },
        async handleSave(gri_code) {
            this.$set( this.save_state, gri_code, 'saving')
            await this.saveDisclosureE({report_code: this.report_code, gri_code: gri_code, direct_report_em: this.writing_eng[gri_code] 
            })
            this.is_editing_eng[gri_code] = false
            this.$set( this.save_state, gri_code, 'saved')
        },
        async handleReInitiate(gri_code){
            this.re_initiating = true
            let idx = this.gri_200_all_contents.findIndex(x => x.gri_code == gri_code)
            if(idx>-1){
                await this.reInitiateDisclosure({disclosure_id: this.gri_200_all_contents[idx].disclosure_id, re_initiate: true})
                this.initiateWritingMode()
                this.$EventBus.$emit('make-toast', '번역 초기화', gri_code+'를 번역을 초기화 하였습니다.')
                this.re_initiating = false
            }
        },
        handleInitiate(gri_code){
            let idx = this.gri_200_all_contents.findIndex(x => x.gri_code == gri_code)
            if(idx>-1){
                this.$set(this.writing_eng, gri_code, this.gri_200_all_contents[idx].direct_report_ea)
            }
        },
        handleCancle(gri_code){
            this.handleInitiate(gri_code)
            this.is_editing_eng[gri_code] = false
        },
        initiateEditingMode(){
            this.is_editing_eng = {}
            this.edit_omission_reason = {}
            let temp_200_contents = [...this.gri_200_all_contents]
            for (let h=0; h<temp_200_contents.length; h++){
                this.$set(this.is_editing_eng, temp_200_contents[h].gri_code, false)
                this.$set(this.edit_omission_reason, temp_200_contents[h].gri_code, false)
            }
        },
        initiateWritingMode(){
            this.writing_eng = {}
            let temp_200_contents = [...this.gri_200_all_contents]
            for (let h=0; h<temp_200_contents.length; h++){
                if(temp_200_contents[h].direct_report_em!=''&&temp_200_contents[h].direct_report_em!=null){
                    this.$set(this.writing_eng, temp_200_contents[h].gri_code, temp_200_contents[h].direct_report_em)
                }
                else this.$set(this.writing_eng, temp_200_contents[h].gri_code, temp_200_contents[h].direct_report_ea)
            }
        },
        initiateSavingState(){
            this.save_state = {}
            this.edit_omission_state = {}
            for (let i=0; i<this.gri_200_all_contents.length; i++){
                if(this.gri_200_all_contents[i].direct_report_em != ''&&this.gri_200_all_contents[i].direct_report_em != undefined) {
                    this.$set( this.save_state, this.gri_200_all_contents[i].gri_code, 'saved')
                    this.$set( this.edit_omission_state, this.gri_200_all_contents[i].gri_code, 'saved')
                }
                else {
                    this.$set(this.save_state, this.gri_200_all_contents[i].gri_code, '')
                    this.$set( this.edit_omission_state, this.gri_200_all_contents[i].gri_code, '')
                }
            }       
        },
        // disclosure items 번역 // disclosure items 번역 
        // disclosure items 번역 // disclosure items 번역  
        // disclosure items 번역 // disclosure items 번역 
        translateTable(table_id){
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return
            this.$emit('translate-table', table_id) //TranslateMaster로 이벤트 보내서 modal open
        },
        translateDocument(document_info) {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return
            this.$emit('translate-document', document_info) //TranslateMaster로 이벤트 보내서 modal open
        },
        translateBusinessCase(business_case_id) {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return
            this.$emit('translate-business-case', business_case_id) //TranslateMaster로 이벤트 보내서 modal open
        },
        hideBCModal(){
            this.show_item_modal=false
            this.low_height=false
        },
        showBCModal(bc){
            this.show_item_modal=true
            this.bc_selected = bc
        },
        showBCModalE(bc){
            this.show_item_modal_e=true
            this.bc_selected_e = bc
        },
        hideBCModalE(){
            this.show_item_modal_e=false
            this.low_height_e=false
        }
    },
    mounted(){

    },
    components:{
        SimpleBusinessCase,
        ChartTextCanvas,
        ChartTranslateTextCanvas
    },
    watch:{
        report_contents_loaded:{
            immediate: true,
            handler(newV){
                if(newV==true){
                    this.initiateEditingMode()
                    this.initiateWritingMode()
                    this.initiateSavingState()
                }
            }
        }
    }
}
</script>

<style>

</style>
<template>
<div>
    <b-card class="general-card" :class="new_report==true? 'border-0':'mb-5'">
        <b-card-header>검증 견적 확인</b-card-header>
        <div class="py-3">
            <p class="">다음의 정보를 입력하여 검증 비용 및 예상 소요 기간을 확인하세요. </p>
            <b-row no-gutters class="hover-gray py-2 mb-3">
                <b-col cols="3"><div class="gray-600" style="width:100px;">보고 조직의 임직원 규모</div> </b-col>  
                <b-col>
                    <p class="fw-400">보고 조직의 상시 근로자 규모를 선택해 주세요.</p>
                    <b-form-radio-group v-model="num_of_employee" :options="num_of_employee_options" ></b-form-radio-group>
                </b-col>
            </b-row>
            <b-row no-gutters class="hover-gray py-2 mb-3">
                <b-col cols="3"><div class="gray-600" style="width:100px;">보고 조직의 매출 규모</div> </b-col>  
                <b-col>
                    <p class="fw-400">보고 조직의 FY2020 매출 규모를 선택해 주세요.</p>
                    <b-form-radio-group v-model="revenue" :options="revenue_options" ></b-form-radio-group>
                </b-col>
            </b-row>            
            <b-row no-gutters class="hover-gray py-2">
                <b-col cols="3"><div class="gray-600" style="width:100px;">보고 조직의 사업장 수</div> </b-col>  
                <b-col>
                    <p class="fw-400">보고 조직의 사업장 수를 입력해 주세요.</p>
                    <b-form-input type="number" v-model="num_of_sites" ></b-form-input>
                </b-col>
            </b-row>
            <b-row no-gutters class="hover-gray py-2 mb-5">
                <b-col cols="3"><div class="gray-600" style="width:100px;">보고서 공개 예정일</div> </b-col>  
                <b-col>
                    <p class="fw-400">GRI 보고서 공개 예정일을 입력해 주세요.</p>
                    <b-form-datepicker v-model="target_publish_date" ></b-form-datepicker>
                </b-col>
            </b-row>

            <b-button variant="teal" class="mr-3 px-3" @click="handleSave" :disabled="is_checking_estimate==true"> 
                {{ is_checking_estimate==true? '견적 확인 중':'검증 비용 및 예상 소요일 확인하기'}} <b-spinner v-if="is_checking_estimate==true" small></b-spinner>
            </b-button>

        </div>
    </b-card>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import * as ih from '@/components/util'

export default {
    data() {
        return {
            num_of_employee: '',
            num_of_employee_options: [
                { text: '20인 미만', value: '20인 미만' },
                { text: '20인 ~ 100인', value: '20인 ~ 100인' },
                { text: '100인 ~ 500인', value: '100인 ~ 500인' },
                { text: '500인 이상', value: '500인 이상' }
            ],
            revenue: '',
            revenue_options: [
                { text: '30억원 미만', value: '30억원 미만' },
                { text: '30억원 ~ 100억원', value: '30억원 ~ 100억원' },
                { text: '100억원 ~ 500억원', value: '100억원 ~ 500억원' },
                { text: '500억원 ~ 2000억원', value: '500억원 ~ 2000억원' },
                { text: '2000억원 ~ 1조원', value: '2000억원 ~ 1조원' },
                { text: '1조원 이상', value: '1조원 이상' },
            ],
            num_of_sites: '',
            target_publish_date: '',
            is_checking_estimate: false
        }
    },
    props: {
        new_report : { type: Boolean, default: false }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState('assurance', ['assurance_review_contents']),
    },
    methods:{
        ...mapActions('assurance', ['saveAssuranceRequest']),
        ...mapMutations('assurance', ['update_show_apply_estimate']),
        async handleSave(){
            if (this.checkForm()==true){
                this.is_checking_estimate = true

                let request_status = null
                if(this.new_report==false){
                    request_status = 0
                }
                await this.saveAssuranceRequest({
                    report_code : this.report_code,
                    total_employees : this.num_of_employee,
                    revenue: this.revenue,
                    number_of_sites : this.num_of_sites,
                    publish_date: this.target_publish_date,
                    request_status: request_status
                })
                this.is_checking_estimate = false
                this.$EventBus.$emit('make-toast', '견적 생성', '검증 서비스 견적이 생성되었습니다')
                this.update_show_apply_estimate(false)
            }
        },
        checkForm(){
            let date = new Date()
            let min_target_date = new Date(date.setDate(date.getDate() + 7))
            // console.log(min_target_date);
            if(this.num_of_employee==''){ alert('임직원 규모를 선택해주세요') 
                return false
            }
            else if(this.revenue==''){ alert('매출 규모를 선택해주세요')
                return false
            }
            else if(this.num_of_sites==''){ alert('사업장 수를 선택해 주세요')
                return false
            }
            else if(this.num_of_sites<1){ alert('사업장 수는 1 이상이어야 합니다')
                return false
            }
            else if(this.target_publish_date==''){ alert('공개 예정일을 선택해 주세요')
                return false
            }
            else if(ih.stringToDate(this.target_publish_date) < min_target_date){ alert('공개 예정일은 현재 날짜로부터 최소 7일 이후여야 합니다')
                return false
            }
            else return true
        }

    },


}
</script>

<style>

</style>
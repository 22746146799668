<template>
    <div>
        <h5 class="f-110 tail-line-dark">
            비즈니스 사례 {{ business_case_id !== '' ? '편집' : '생성'}}
        </h5>
        <div class="py-3">
            <p class="fw-400 f-90 mb-2">제목 설정</p>
            <b-form-input v-model="title" placeholder="비즈니스 사례 제목" class="mb-4"></b-form-input>

            <p class="fw-400 f-90 mb-2"> 에디터</p>

<!--             
            <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }" v-if="isIE!=true">
                <div class="menubar mb-2 d-inline sticky-top">
                    <b-button class="menubar__button" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote"  :disabled="md_mode==true">
                        <b-img src="/tiptap_icons/quote.svg" ></b-img>
                    </b-button>
                    <b-button class="menubar__button" @click="showImagePrompt()" :disabled="md_mode==true">
                        <b-img src="/tiptap_icons/image.svg" ></b-img>
                    </b-button>
                </div>
            </editor-menu-bar>


            <b-button class="menubar__button txt" @click="changeEditMode" align="center" v-if="isIE!=true">
                <div class="f-90 text-center w-100" style="line-height:1.2">{{md_mode==true?'editor':'html'}}</div>
            </b-button>
            


            <editor-content v-if="md_mode!=true&&isIE!=true" id="editor" class="editor-container bg-white border-dark rounded p-2 mb-4" :editor="editor"/>

            <b-form-textarea v-else v-model="description" rows="10" class=" mb-4" autofocus></b-form-textarea> -->

            <b-btn-group class="mb-3 mr-2">
                <b-dropdown :text="!selected_typo_name ? '제목 선택' : selected_typo_name">
                    <b-dropdown-item-button v-for="(typography, t) in typographies"
                        :key="`typography-${typography.name}-${t}`"
                        :variant="editor && editor.isActive('heading', { level: t + 1 }) ? 'dark' : 'secondary'"
                        @click="setTypo(typography.tag, t)"
                        >
                        <template v-if="editor && editor.isActive('heading', { level: t + 1 })">
                            <fa icon="check" class="mr-1" />
                        </template>
                        <span class="mx-1">{{ typography.name }}</span>
                    </b-dropdown-item-button>
                </b-dropdown>
                <b-btn :variant="editor && editor.isActive('paragraph') ? 'dark' : 'secondary'"
                    @click="editor.chain().focus().setParagraph().run()"
                    >
                    <strong>P</strong>
                </b-btn>
                <b-btn :variant="editor && editor.isActive('bold') ? 'dark' : 'secondary'"
                    :disabled="editor ? !editor.can().chain().focus().toggleBold().run() : false"
                    @click="editor.chain().focus().toggleBold().run()"
                    >
                    <fa icon="bold" />
                </b-btn>
                <b-btn :variant="editor && editor.isActive('italic') ? 'dark' : 'secondary'"
                    :disabled="editor ? !editor.can().chain().focus().toggleItalic().run() : false"
                    @click="editor.chain().focus().toggleItalic().run()"
                    >
                    <fa icon="italic" />
                </b-btn>
                <b-btn :variant="editor && editor.isActive('underline') ? 'dark' : 'secondary'"
                    :disabled="editor ? !editor.can().chain().focus().toggleUnderline().run() : false"
                    @click="editor.chain().focus().toggleUnderline().run()"
                    >
                    <BIconTypeUnderline font-scale="1.45" />
                </b-btn>

                <b-btn :variant="editor && editor.isActive('strike') ? 'dark' : 'secondary'"
                    :disabled="editor ? !editor.can().chain().focus().toggleStrike().run() : false"
                    @click="editor.chain().focus().toggleStrike().run()"
                    >
                    <BIconTypeStrikethrough font-scale="1.45" />
                </b-btn>
                
                <b-btn :variant="editor && editor.isActive('bulletList') ? 'dark' : 'secondary'"
                    @click="editor.chain().focus().toggleBulletList().run()"
                    >
                    <fa icon="list-ul" />
                </b-btn>
                <b-btn :variant="editor && editor.isActive('orderedList') ? 'dark' : 'secondary'"
                    @click="editor.chain().focus().toggleOrderedList().run()"
                    >
                    <fa icon="list-ol" />
                </b-btn>
                <b-btn :variant="editor && editor.isActive('blockquote') ? 'dark' : 'secondary'"
                    @click="editor.chain().focus().toggleBlockquote().run()"
                    >
                    <b-img src="/tiptap_icons/quote.svg"></b-img>
                </b-btn>
                <b-btn  :variant="editor && editor.isActive('superscript') ? 'dark' : 'secondary'"
                    @click="editor.chain().focus().toggleSuperscript().run()"
                    >
                    <b-img src="/tiptap_icons/superscript.svg"></b-img>
                </b-btn>
                <b-btn :variant="editor && editor.isActive('subscript') ? 'dark' : 'secondary'"
                    @click="editor.chain().focus().toggleSubscript().run()">
                    <b-img src="/tiptap_icons/subscript.svg"></b-img>
                </b-btn>
                <b-btn @click="editor.chain().focus().setHorizontalRule().run()"
                    >
                    <b-img src="/tiptap_icons/hr.svg" ></b-img>
                </b-btn>
                <b-btn @click="editor.chain().focus().setHardBreak().run()"
                    >
                    <BIconArrowBarDown />
                </b-btn>
                <b-btn @click="editor.chain().focus().unsetAllMarks().run()">
                    <fa icon="remove-format" />
                </b-btn>
                <b-btn :disabled="!editor ? true : !editor.can().chain().focus().undo().run()"
                    @click="editor.chain().focus().undo().run()"
                    >
                    <b-img src="/tiptap_icons/undo.svg" ></b-img>
                </b-btn>
                <b-btn :disabled="!editor ? true : !editor.can().chain().focus().redo().run()"
                    @click="editor.chain().focus().redo().run()"
                    >
                    <b-img src="/tiptap_icons/redo.svg" ></b-img>
                </b-btn>
                <b-btn @click="changeEditMode">
                    {{ md_mode === true ? 'Editor' : 'HTML'}} Viewer
                </b-btn>
            </b-btn-group>
            
            <div class="mb-3">
                <div>
                    <p class="fw-400 f-90 mb-2"> 이미지 삽입 및 편집</p>
                </div>
                <b-btn class="mr-md-2" @click="showImagePrompt" :disabled="md_mode == true">
                    <b-img src="/tiptap_icons/image.svg" ></b-img>
                </b-btn>

                <!-- 이미지 사이즈 조절 -->
                <b-btn-group class="mr-md-2">
                    <b-btn
                        :class="{ 'is-active': editor.isActive('custom-image', { size: 'small' }) }"
                        :disabled="md_mode == true || !is_select_image"
                        @click="editor.chain().focus().setImage({ size: 'small' }).run()"
                        >
                        작게
                    </b-btn>
                    <b-btn
                        :class="{ 'is-active': editor.isActive('custom-image', { size: 'medium' }) }"
                        :disabled="md_mode == true || !is_select_image"
                        @click="editor.chain().focus().setImage({ size: 'medium' }).run()"
                        >
                        중간
                    </b-btn>
                    <b-btn
                        :class="{ 'is-active': editor.isActive('custom-image', { size: 'large' }) }"
                        :disabled="md_mode == true || !is_select_image"
                        @click="editor.chain().focus().setImage({ size: 'large' }).run()"
                        >
                        크게
                    </b-btn>
                </b-btn-group>

                <!-- 이미지 정렬 -->
                <b-btn-group>
                    <b-btn
                        :class="{ 'is-active': editor.isActive('custom-image', { float: 'left' }) }"
                        :disabled="md_mode == true || !is_select_image"
                        @click="editor.chain().focus().setImage({ float: 'left' }).run()"
                        >
                        왼쪽
                    </b-btn>
                    <b-btn
                        :class="{ 'is-active': editor.isActive('custom-image', { float: 'center' }) }"
                        :disabled="md_mode == true || !is_select_image"
                        @click="editor.chain().focus().setImage({ float: 'center' }).run()"
                        >
                        중앙
                    </b-btn>
                    <b-btn
                        :class="{ 'is-active': editor.isActive('custom-image', { float: 'right' }) }"
                        :disabled="md_mode == true || !is_select_image"
                        @click="editor.chain().focus().setImage({ float: 'right' }).run()"
                        >
                        오른쪽
                    </b-btn>
                </b-btn-group>
            </div>

            <div class="mb-3">
                <b-btn-group>
                    <b-btn @click="editor.commands.insertTable({ rows: 3, cols: 3, withHeaderRow: true })">
                        <b-img src="/tiptap_icon_businessCase/table_custom.svg" ></b-img>
                    </b-btn>
                    <b-btn
                        @click="editor.commands.deleteTable()"
                        :disabled="!editor.can().deleteTable()"
                        >
                        <b-img src="/tiptap_icon_businessCase/delete_table_custom.svg"></b-img>
                    </b-btn>
                    <b-btn @click="editor.chain().focus().addRowBefore().run()"
                        :disabled="!editor.can().addRowBefore()"
                        >
                        <b-img src="/tiptap_icon_businessCase/add_row_before_custom.svg"></b-img>
                    </b-btn>
                    <b-btn @click="editor.chain().focus().addRowAfter().run()"
                        :disabled="!editor.can().addRowAfter()"
                        >
                        <b-img src="/tiptap_icon_businessCase/add_row_after_custom.svg"></b-img>
                    </b-btn>
                    <b-btn @click="editor.chain().focus().deleteRow().run()"
                        :disabled="!editor.can().deleteRow()"
                        >
                        <b-img src="/tiptap_icon_businessCase/delete_row_custom.svg"></b-img>
                    </b-btn>
                    <b-btn @click="editor.chain().focus().addColumnBefore().run()"
                        :disabled="!editor.can().addColumnBefore()"
                        >
                        <b-img src="/tiptap_icon_businessCase/column_add_before.svg"></b-img>
                    </b-btn>
                    <b-btn @click="editor.chain().focus().addColumnAfter().run()"
                        :disabled="!editor.can().addColumnAfter()"
                        >
                        <b-img src="/tiptap_icon_businessCase/column_add_after.svg"></b-img>
                    </b-btn>
                    <b-btn @click="editor.chain().focus().deleteColumn().run()"
                        :disabled="!editor.can().deleteColumn()"
                        >
                        <b-img src="/tiptap_icon_businessCase/column_delete.svg"></b-img>
                    </b-btn>
                    <b-btn @click="editor.chain().focus().mergeCells().run()"
                        :disabled="!editor.can().mergeCells()"
                        >
                        <b-img src="/tiptap_icon_businessCase/cell_merge.svg"></b-img>
                    </b-btn>
                    <b-btn @click="editor.chain().focus().splitCell().run()"
                        :disabled="!editor.can().splitCell()"
                        >
                        <b-img src="/tiptap_icon_businessCase/cell_split.svg"></b-img>
                    </b-btn>

                    <b-btn @click="editor.chain().focus().toggleHeaderColumn().run()"
                        :disabled="!editor.can().toggleHeaderColumn()"
                        >
                        <b-img src="/tiptap_icon_businessCase/header_column_toggle.svg"></b-img>
                    </b-btn>
                    <b-btn @click="editor.chain().focus().toggleHeaderRow().run()"
                        :disabled="!editor.can().toggleHeaderRow()"
                        >
                        <b-img src="/tiptap_icon_businessCase/header_row_toggle.svg"></b-img>
                    </b-btn>
                    <b-btn @click="editor.chain().focus().toggleHeaderCell().run()"
                        :disabled="!editor.can().toggleHeaderCell()"
                        >
                        <b-img src="/tiptap_icon_businessCase/header_cell_toggle.svg"></b-img>
                    </b-btn>
                </b-btn-group>
            </div>

            <template v-if="md_mode !== true">
                <editor-content
                    id="editor"
                    :editor="editor"
                    class="border border-secondary rounded-sm bg-white p-2 mb-4"
                    />
            </template>
            <template v-else>
                <b-form-textarea v-model="description" rows="10" class=" mb-4" autofocus></b-form-textarea>
            </template>

            <p class="f-90 mb-2">파일 업로드</p>
            <div v-if="business_case_id!=''" class="f-95 gray-600 mb-2">현재 업로드된 파일 : 
                <div v-for="item in uploaded_files" :key="item.id" class="mr-3"> 
                    <a :href="$store.state.static_host+item.file_path" target="_blank">{{item.file_name}}</a> 
                    <b-button variant="icon-teal" class="f-95 py-0 px-2 ml-2" @click="handleDeleteFile(item.file_id)"><fa icon="trash-alt"></fa></b-button>
                </div>
            </div>


            <b-form-file v-model="file" placeholder="여기로 끌어오기 또는 파일찾기" class="mb-2" @input="handleUploadFile"></b-form-file>
            <p class="f-85 mb-5 gray-600"> <fa icon="info-circle" class="mr-1"/> 크기가 20MB 이상인 파일은 업로드할 수 없습니다.</p>
            

            <b-button variant="teal" class="mr-3 mb-3" @click="handleSaveBusinessCase"> 저장하기 </b-button>
            <b-button @click="handleCancel" class="mb-3"> 리스트로 돌아가기 </b-button>
        </div>

        <b-modal id="image-modal" hide-footer title="이미지 업로드" style="z-index: 9999">
            <p class="fw-400 f-90 mb-2">이미지 가져오기</p>
            <b-form-file v-model="image" @input="handleImageUpload" placeholder="여기로 끌어오기 또는 파일찾기" accept="image/*"></b-form-file>
            <p class="f-85 mb-5 gray-600"> <fa icon="info-circle" class="mr-1"/> 크기가 20MB 이상인 이미지는 업로드할 수 없습니다.</p>
            <b-button @click="$bvModal.hide('image-modal')">취소</b-button>
        </b-modal>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Editor, EditorContent } from '@tiptap/vue-2'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'

import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'

import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'

// import Image from '@tiptap/extension-image'
import Image from '../../../util/tiptap/extension-cusom-image'
import { BIconTypeUnderline, BIconTypeStrikethrough, BIconArrowBarDown } from 'bootstrap-vue';
import StarterKit from '@tiptap/starter-kit'
import * as ih from '@/components/util'

export default {
    props: {
        business_case_id: {
            default: ''
        }
    },
    components: {
        EditorContent,
        BIconTypeUnderline,
        BIconTypeStrikethrough,
        BIconArrowBarDown
    },
    data(){
        return {
            business_case_id_local: '',
            editor: new Editor({
                content: '',
                extensions: [
                    StarterKit,
                    Underline,
                    TextAlign.configure({
                        alignments: ['left', 'center', 'right', 'justify'],
                        types: ['heading', 'paragraph', 'image'],
                    }),
                    Image.configure({
                        HTMLAttributes: {
                            class: 'custom-image'
                        }
                    }),

                    Table,
                    TableCell,
                    TableHeader,
                    TableRow,
                    Subscript,
                    Superscript
                ]
            }),
            title: '',
            description: '',
            image: [],
            typographies: [
                { name: '제목 1', tag: 'h1' },
                { name: '제목 2', tag: 'h2' },
                { name: '제목 3', tag: 'h3' },
                { name: '제목 4', tag: 'h4' },
                { name: '제목 5', tag: 'h5' },
                { name: '제목 6', tag: 'h6' }
            ],
            selected_typo: null,
            uploaded_images: [],
            file: null,
            uploaded_files: [],
            md_mode : false
        }
    },
    computed:{
        ...mapState('report_list', ['report_code', 'lang']),
        ...mapState('business_cases', ['business_case_list']),
        image_ids(){
            return this.uploaded_images.map( x => x.image_id )
        },
        selected_typo_name () {
            if (!this.editor) {
                return null
            } else {
                let _selected_typo = null
                for (let t = 0, tlen = this.typographies.length; t < tlen; t++) {
                    if (this.editor.isActive('heading', { level: t + 1 })) {
                        _selected_typo = this.typographies[t]
                        break
                    }
                }
                
                if (!_selected_typo) {
                    return null
                }

                return _selected_typo.name
            }
        },
        is_select_image () {
            return this.editor.isActive('custom-image')
        }
    },
    created() {},
    mounted() {
        this.initiateBusinessCase()
    },
    beforeDestroy() {
        this.editor.destroy()
        this.business_case_id_local = ''
    },
    methods:{
        ...mapActions('business_cases', ['saveBusinessCase', 'deleteFile']),
        // isActiveImageAlign (alignment) {
        //     console.log(`isActiveImageAlign ~ `);
        //     console.log(` ~ alignment => `, alignment);
        //     if (!this.editor.isActive('custom-image')) {
        //         return false
        //     } else {
        //         const _attr_data = this.editor.getAttributes('img')
        //         if (!_attr_data || !Object.keys(_attr_data).length) {
        //             return false
        //         } else {
        //             if (!_attr_data.align) {
        //                 return false
        //             } else {
        //                 return _attr_data.align === alignment
        //             }
        //         }
        //     }
        // },
        setTypo (typography_tag, t) {
            this.editor.chain().focus().toggleHeading({ level: t + 1 }).run()

            if (this.selected_typo && this.selected_typo.name === this.typographies[t].name) {
                this.selected_typo = null
            } else {
                this.selected_typo = this.typographies[t]
            }
        },
        initiateBusinessCase() {
            this.business_case_id_local = this.business_case_id
            if (this.business_case_id !== '') {
                let idx = this.business_case_list.findIndex(x => x.business_case_id == this.business_case_id_local)
                if (idx > -1) {
                    this.title = this.business_case_list[idx].title
                    if (this.isIE !== true) {
                        if (this.editor) {
                            const isSame = this.editor.getHTML() === this.business_case_list[idx].description
                            if (isSame) {
                                return false
                            }
                        }

                        this.editor.commands.setContent(this.business_case_list[idx].description)
                    } else {
                        this.description = this.business_case_list[idx].description
                    }

                    this.uploaded_files = ih.deepCopy(this.business_case_list[idx].files)
                }
            }
        },
        showImagePrompt(){
            this.$bvModal.show('image-modal')
        },
        async handleImageUpload() {
            const path = this.$store.state.backend_host + '/upload_image'
            if (!this.image || (Array.isArray(this.image) && !this.image.length) || this.image.size / (1024*1024) > 20) {
                return false
            }

            let formData = new FormData()
            if (this.business_case_id_local !== '' && this.business_case_id_local !== null) { 
                formData.append('business_case_id', this.business_case_id_local)
            }
            formData.append('image', this.image, this.image.name)
            formData.append('report_code', this.report_code)

            const response = await axios.post(path, formData)
            this.uploaded_images.push(response.data)
            const src = this.$store.state.static_host + response.data.path
            this.editor.commands.setImage({ src })
            this.image = []
            this.$bvModal.hide('image-modal')
        },
        async handleSaveBusinessCase(){
            if (this.checkForm()==true){  
                let new_description = this.editor.getHTML()
                if(this.md_mode==true){
                    new_description = this.description
                }
                
                await this.saveBusinessCase({ 
                    report_code: this.report_code, 
                    business_case_id: this.business_case_id_local, 
                    title: this.title, 
                    description: new_description,  
                    files: this.uploaded_files, 
                    images: this.uploaded_images 
                })
                this.handleCancel()
            }
        },
        handleCancel(){
            // this.clearFiles()
            this.initiateBusinessCase()
            this.$emit('cancle-edit')
        },
        async handleUploadFile(){
            await this.uploadFile({
                report_code: this.report_code,
                business_case_id: this.business_case_id_local,
                type: 'file',
                file: this.file
            })
        },
        uploadFile({ report_code, business_case_id, type, file_id, file }){
            
            const path = this.$store.state.backend_host + '/upload_file'
            // console.log(this.file);

            let formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('type', type)
            if ( file_id != null && file_id != undefined){
                formData.append('file_id', file_id)
            }
            if ( file ){
                formData.append('file', file)
            }
            return axios.post(path, formData)
            .then( response => {
                let new_file_obj = {
                    business_case_id: response.data.business_case_id,
                    file_id: response.data.file_id, 
                    type: response.data.type, 
                    file_name: response.data.file_name, 
                    file_path: response.data.file_path,
                    time_stamp: response.data.time_stamp
                }
                // console.log(new_file_obj);
                this.uploaded_files.push(new_file_obj)
                // ==> new_file_obj를 바탕으로 local business case edit화면의 files를 업데이트 하면 됩니다.  
            })
        },
        clearFiles(){ // business case id 연결 안된 파일 찾아서 모두 지우기
            const path = this.$store.state.backend_host + '/clear_files'
            return axios.post(path)
        },
        async handleDeleteFile(file_id){
            // console.log(file_id);
            await this.deleteFile({file_id: file_id, business_case_id: this.business_case_id_local})
            this.initiateBusinessCase()
        },
        checkForm() {
            if ( this.title == null || this.title == undefined || this.title == ''){
                alert ('비즈니스 사례 제목을 기입해주시기 바랍니다.')
                return false 
            }
            let idx = this.business_case_list.findIndex( cases => cases.title == this.title ) // 동일한 title이 있는지 확인 
            let case_idx = this.business_case_list.findIndex( cases => cases.business_case_id == this.business_case_id ) // business_case_id가 동일한지 확인
            if ( idx == -1 || idx == case_idx ){  
                return true 
            } 
            else { alert ('중복되는 제목이 있습니다. 제목을 변경해주세요.') 
                return false
            }
        },
        changeEditMode() {
            if (this.md_mode === false) {
                this.description = this.editor.getHTML()
                this.md_mode = true
            } else {
                this.editor.commands.setContent(this.description)
                this.md_mode = false
            }
        }
    }
}
</script>

<style scoped lang="scss">
// #editor >>> .ProseMirror:focus {
//     outline: none;
// }

#editor /deep/ .ProseMirror {
    .ProseMirror-selectednode {
        img {
            outline: 2px solid var(--gray-200);
            padding: 6px;
            border-radius: 3px;
        }
    }

    img {
        width: 100%;
        height: auto;

        &.ProseMirror-selectednode {
            outline: 3px solid #68cef8;
        }
    }
    .custom-image-small {
        max-width: 200px;
    }
    .custom-image-medium {
        max-width: 500px;
    }
    .custom-image-large {
        max-width: 100%;
    }
    .custom-image-float-center {
        // float: none;
        text-align: center;
    }
    .custom-image-float-left {
        // float: left;
        text-align: left;
    }
    .custom-image-float-right {
        // float: right;
        text-align: right;
    }

    /* Table-specific styling */
    table {
        border-collapse: collapse;
        table-layout: fixed;
        // width: 100%;
        margin: 0;
        overflow: hidden;

        td,
        th {
            min-width: 1em;
            border: 2px solid #ced4da;
            padding: 3px 5px;
            vertical-align: top;
            box-sizing: border-box;
            position: relative;

            > * {
            margin-bottom: 0;
            }
        }

        th {
            font-weight: bold;
            text-align: left;
            background-color: #f1f3f5;
        }

        .selectedCell:after {
            z-index: 2;
            position: absolute;
            content: "";
            left: 0; right: 0; top: 0; bottom: 0;
            background: rgba(200, 200, 255, 0.4);
            pointer-events: none;
        }

        .column-resize-handle {
            position: absolute;
            right: -2px;
            top: 0;
            bottom: -2px;
            width: 4px;
            background-color: #adf;
            pointer-events: none;
        }

        p {
            margin: 0;
        }
    }
}

.ProseMirror [contenteditable="false"] {
    white-space: normal;
}
.ProseMirror [contenteditable="true"] {
    white-space: pre-wrap;
}

.menubar {
    -webkit-transition: visibility .2s .4s,opacity .2s .4s;
    transition: visibility .2s .4s,opacity .2s .4s;
}
.menubar__button {
    display: -webkit-inline-box;
    display: inline-flex;
    background: transparent;
    border: 0;
    padding: .3rem .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    width: 32px;
    height: 24px;
    color: black;
}
.menubar__button:hover{
    background-color: var(--gray-400);
}
.menubar__button.is-active {
    background-color: rgba(0,0,0,.1);
}
.menubar__button> img{
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
}
.menubar__button.txt{
    width: 50px;
}


.editor-container {
    min-height: 200px;
}
.editor-container >>> img{
    max-width: 100%;
}
.editor-container >>> p{
    margin-bottom:0.5rem;
    font-weight: 400;
}
.editor-container >>> table > tbody > tr > td,
.editor-container >>> table > tbody > tr > th{
    padding: 0.25rem 0.5rem;
    border-top: 1px solid var(--gray-400);
    border-left: 1px solid var(--gray-400);
}
.editor-container >>> table > tbody > tr:last-child > td{
    border-bottom: 1px solid var(--gray-400);
}
.editor-container >>> table > tbody > tr > th:last-child,
.editor-container >>> table > tbody > tr > td:last-child{
    border-right: 1px solid var(--gray-400);
}
.editor-container >>> table > tbody > tr > th{
    background-color: var(--gray-300);
}
.editor-container >>> table > tbody > tr > td{
    background-color: white;
}
.editor-container >>> table>tbody > tr > td> p{
    margin-bottom: 0;
}
.editor-container >>> h3{
    font-size: 1.25rem;
}
.editor-container >>> h2{
    font-size: 1.5rem;
}
.editor-container >>> h1{
    font-size: 1.75rem;
}
.editor-container >>> ol, 
.editor-container >>> ul{   
    padding-inline-start: 20px;
}
</style>
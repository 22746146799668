<template>
<div>
    <h5 class="f-110 tail-line-dark">외부 링크 업로드</h5>
    <div class="py-3">
        <p class="fw-400 f-90 mb-2">URL(외부 링크) 이름 설정</p>
        <b-form-input v-model="local_link.title" placeholder="URL 명" class="mb-3"></b-form-input>
        <p class="fw-400 f-90 mb-2">링크 넣기</p>
        <b-form-input v-model="local_link.link" placeholder="" class="mb-5"></b-form-input>
    </div>
    <b-button variant="teal" class="mr-3 mb-3" @click="handleSave()"> 저장하기 </b-button>
    <b-button @click="handleCancle" class="mb-3"> 리스트로 돌아가기 </b-button>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            local_link : {
            }
        }
    },
    props:{
        document_id : { default: '' }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState('documents', ['document_list']),
        ...mapGetters('documents', ['url_list'])

    },
    methods:{
        ...mapActions('documents', ['saveDocument']),
        async handleSave(){
            if (this.checkForm()==true ) {
                await this.saveDocument({
                    report_code: this.report_code,
                    document_id: this.local_link.document_id,
                    title: this.local_link.title,
                    type: this.local_link.type,
                    link: this.local_link.link,
                })
                this.handleCancle()
            }
        },
        handleCancle(){
            this.initiateEdit()
            this.$emit('cancle-edit')
        },
        initiateEdit(){
            let temp_link = {
                document_id : null,
                report_code : this.report_code,
                title : null,
                description : null,
                type: 'link',
                link: 'http://',
            }
            if (this.document_id!=''){
                let idx = this.url_list.findIndex(x => x.document_id == this.document_id)
                if(idx>-1){
                    this.local_link = {...this.url_list[idx]}
                }
            }
            else this.local_link = temp_link
        },
        checkForm() {
            if ( this.local_link.title == null || this.local_link.title == undefined || this.local_link.title == '' || this.local_link.title == ' '){
                alert ('URL 명을 입력해 주세요.') 
                return false
            }
            let idx = this.url_list.findIndex( url => url.title == this.local_link.title ) // 동일한 title이 있는지 확인
            let current_url_idx = this.url_list.findIndex( c_url => c_url.document_id == this.document_id ) 
            if ( idx > -1 && idx != current_url_idx ){
                alert ('중복되는 URL 명이 있습니다. 다른 URL 명을 입력해 주세요.') 
                return false 
            }
            if ( this.local_link.link.length < 10 ) {
                alert ('URL을 입력해 주세요.') 
                return false
            }
            if ( this.local_link.link.includes(`http`) != true ) {
                if (confirm (`링크에 'http://'가 없으면 보고서 공개시 링크 연결이 어렵습니다. 'http://' 없이 저장 하시겠습니까?`)== true) {
                    return true
                }
                else return false
            }
            else return true
        }
    },
    created(){
        this.initiateEdit()
        // console.log(this.local_link)
        // console.log(this.local_file)
    }

}
</script>

<style>

</style>
<template>
    <b-row>
        <b-col cols="12">
            <b-tabs content-class="border-right border-left border-bottom rounded-bottom">
                <b-tab title="차트 제목" active>
                    <!-- 차트 기본정보 -->
                    <div class="bg-light rounded p-3">
                        <b-row>
                            <b-col cols="12">
                                <h5>제목 설정</h5>
                            </b-col>
                        </b-row>
                        <b-row>
                            <!-- {{ defaultConfig }} -->
                            <b-col cols="12" md="3">
                                <b-form-group id="input-group-chart-title"
                                    label="차트 제목"
                                    label-for="input-chart-title"
                                    :description="usable_title===false?'기존 차트의 제목과 겹치지 않게 입력해주세요':''"                                    >
                                    
                                    <!-- v-model="options.plugins.title.text" -->
                                    <b-form-input id="input-chart-title"
                                        v-model="defaultConfig.title.text"
                                        placeholder="차트의 이름을 입력해주세요."
                                        required
                                        @input="updateChartTitle"
                                        :state="usable_title"
                                    >
                                    </b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group id="input-group-chart-title-position"
                                    label="제목 위치"
                                    label-for="input-chart-title-position"
                                    >
                                    <b-form-select id="input-chart-title-position"
                                        v-model="defaultConfig.title.position"
                                        :options="legend_position_options"
                                        @input="updateDefaultConfig"
                                        />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group
                                    :id="`input-group-chart-title-fontSize`"
                                    label="제목 크기"
                                    :label-for="`input-chart-title-fontSize`"
                                    >
                                    <b-form-spinbutton 
                                        :id="`input-chart-title-fontSize`"
                                        v-model="defaultConfig.title.fontSize"
                                        @input="updateDefaultConfig"
                                        :min="1" :step="1"
                                        />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group
                                    id="input-group-chart-title-lineheight"
                                    label="제목 글높이"
                                    label-for="input-chart-title-lineheight"
                                    >
                                    <b-form-spinbutton 
                                        id="input-chart-title-lineheight"
                                        v-model="defaultConfig.title.lineHeight"
                                        @input="updateDefaultConfig"
                                        :min="0" :step="0.1"
                                        />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row class="border-top pt-3">
                            <b-col cols="12">
                                <h5>부제목 설정</h5>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="3">
                                <b-form-group id="input-group-chart-subtitle"
                                    label="차트 부제목"
                                    label-for="input-chart-subtitle"
                                    >
                                    <b-form-input id="input-chart-subtitle"
                                        v-model="defaultConfig.subtitle.text"
                                        placeholder="차트의 이름을 입력해주세요."
                                        required
                                        @input="updateDefaultConfig"
                                        >
                                    </b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group id="input-group-chart-subtitle-position"
                                    label="부제목 위치"
                                    label-for="input-chart-subtitle-position"
                                    >
                                    <b-form-select id="input-chart-subtitle-position"
                                        v-model="defaultConfig.subtitle.position"
                                        :options="legend_position_options"
                                        @input="updateDefaultConfig"
                                        />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group
                                    :id="`input-group-chart-subtitle-fontSize`"
                                    label="부제목 크기"
                                    :label-for="`input-chart-subtitle-fontSize`"
                                    >
                                    <b-form-spinbutton 
                                        :id="`input-chart-subtitle-fontSize`"
                                        v-model="defaultConfig.subtitle.fontSize"
                                        @input="updateDefaultConfig"
                                        :min="1" :step="1"
                                        />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group
                                    id="input-group-chart-subtitle-lineheight"
                                    label="부제목 글높이"
                                    label-for="input-chart-subtitle-lineheight"
                                    >
                                    <b-form-spinbutton 
                                        id="input-chart-subtitle-lineheight"
                                        v-model="defaultConfig.subtitle.lineHeight"
                                        @input="updateDefaultConfig"
                                        :min="0" :step="0.1"
                                        />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>

                <b-tab title="차트 영역">
                    <div class="bg-light rounded p-3">
                        <b-row>
                            <b-col cols="12">
                                <h5>범례 설정</h5>
                            </b-col>
                            <b-col cols="12" lg="3">
                                <b-form-group label="범례 설정" v-slot="{ ariaDescribedby }">
                                    <b-form-checkbox v-model="defaultConfig.legend.state"
                                        :aria-describedby="ariaDescribedby"
                                        @input="updateDefaultConfig"
                                        switches
                                        >
                                        <span v-show="defaultConfig.legend.state">범례 보이기</span>
                                        <span v-show="!defaultConfig.legend.state">범례 숨기기</span>
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="3">
                                <b-form-group id="input-group-chart-legend-title"
                                    label="제목"
                                    label-for="input-chart-legend-title"
                                    >
                                    <b-form-input id="input-chart-legend-title"
                                        v-model="defaultConfig.legend.text"
                                        placeholder="범례의 제목을 작성해주세요."
                                        required
                                        @input="updateDefaultConfig"
                                        >
                                    </b-form-input>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="3">
                                <b-form-group
                                    :id="`input-group-chart-legend-fontSize`"
                                    label="글씨 크기"
                                    :label-for="`input-chart-legend-fontSize`"
                                    >
                                    <b-form-spinbutton 
                                        :id="`input-chart-legend-fontSize`"
                                        v-model="defaultConfig.legend.fontSize"
                                        @input="updateDefaultConfig"
                                        :min="1"
                                        :step="1"
                                        />
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" lg="3">
                                <b-form-group id="input-group-chart-legend-position"
                                    label="범례 위치"
                                    label-for="input-chart-legend-position"
                                    >
                                    <b-form-select id="input-chart-legend-position"
                                        v-model="defaultConfig.legend.position"
                                        :options="legend_position_options"
                                        @input="updateDefaultConfig"
                                        />
                                </b-form-group>
                            </b-col>



                            <b-col cols="12" md="3">
                                <b-form-group
                                    :id="`input-group-chart-legend-padding`"
                                    label="범례 여백"
                                    :label-for="`input-chart-legend-padding`"
                                    >
                                    <b-form-spinbutton 
                                        :id="`input-chart-legend-padding`"
                                        v-model="defaultConfig.legend.padding"
                                        @input="updateDefaultConfig"
                                        :min="1"
                                        :step="1"
                                        />
                                </b-form-group>
                            </b-col>
                            
                        </b-row>

                        <b-row class="border-top pt-3">
                            <b-col cols="12">
                                <h5>기타 설정</h5>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group
                                    id="input-group-chart-layout-padding"
                                    label="차트 영역 여백"
                                    label-for="input-chart-layout-padding"
                                    >
                                    <b-form-spinbutton 
                                        id="input-chart-layout-padding"
                                        v-model="defaultConfig.layoutPadding"
                                        @input="updateDefaultConfig"
                                        :min="1"
                                        :step="1"
                                        max="1000"
                                        />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-form-group id="input-group-chart-backgroundColor"
                                    label="차트 배경색:"
                                    label-for="input-chart-backgroundColor"
                                    >
                                    <b-dropdown size="lg" variant="link"
                                        toggle-class="text-decoration-none p-0"
                                        menu-class="p-0"
                                        no-caret
                                        block
                                        @hidden="updateDefaultConfig"
                                        >
                                        <template #button-content>
                                            <div class="border border-light rounded">
                                                <div class="h6 rounded text-white px-5 py-2 mb-0"
                                                    :style="{ backgroundColor: convertingColor(defaultConfig.backgroundColor) }"
                                                    >
                                                    배경색
                                                </div>
                                            </div>
                                        </template>

                                        <b-dropdown-form class="p-0" form-class="p-0">
                                            <b-form-group
                                                id="fieldset-input-chart-backgroundColor"
                                                label-for="input-chart-backgroundColor"
                                                class="mb-0"
                                                >
                                                <chrome-picker id="input-chart-backgroundColor"
                                                    v-model="defaultConfig.backgroundColor"
                                                    />
                                            </b-form-group>
                                        </b-dropdown-form>
                                    </b-dropdown>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group id="input-group-chart-footnote"
                                    label="차트 주석:"
                                    label-for="input-chart-footnote"
                                    >
                                    <b-form-input id="input-chart-footnote"
                                        v-model="defaultConfig.footnote"
                                        placeholder="주석을 입력해주세요"
                                        required
                                        @input="updateDefaultConfig"
                                        >
                                    </b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="pt-3">
                            <b-col cols="12" md="4">
                                <b-form-group
                                    id="input-group-chart-layout-height"
                                    label="차트 최대 세로 길이(px)"
                                    label-for="input-chart-layout-height"
                                >
                                    <b-form-spinbutton 
                                        id="input-chart-layout-height"
                                        v-model="defaultConfig.chart_height"
                                        @input="updateDefaultConfig"
                                        :min="200"
                                        :step="1"
                                        max="1000"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>

                <template v-if="multi_chart.includes(chart_type)">
                    <b-tab title="X축 설정">
                        <div class="bg-light rounded p-3">
                            <b-row>
                                <b-col cols="12" lg="3">
                                    <b-form-group id="input-group-chart-x-axis-name"
                                        label="X축 이름"
                                        label-for="input-chart-x-axis-name"
                                        class="mb-0"
                                        >
                                        <b-form-input id="input-chart-x-axis-name"
                                            v-model="x_axis_config.name"
                                            placeholder="X축의 이름을 입력해주세요"
                                            required
                                            @input="updateDefaultConfig"
                                            >
                                            <!-- @change="renderChart" -->
                                        </b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" lg="3">
                                    <b-form-group id="input-group-chart-x-axis-visible"
                                        label="X축 표시"
                                        label-for="input-chart-x-axis-visible"
                                        >
                                        <b-form-checkbox id="input-chart-x-axis-visible"
                                            v-model="x_axis_config.state"
                                            size="sm"
                                            switch
                                            @input="updateDefaultConfig"
                                            >
                                            <!-- @change="renderChart" -->
                                            <span v-show="x_axis_config.state">보이기</span>
                                            <span v-show="!x_axis_config.state">숨기기</span>
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" lg="3">
                                    <b-form-group id="input-group-chart-x-axis-stackable"
                                        label="X축 누적"
                                        label-for="input-chart-x-axis-stackable"
                                        >
                                        <b-form-checkbox id="input-chart-x-axis-stackable"
                                            v-model="x_axis_config.stacked"
                                            size="sm"
                                            switch
                                            @input="updateDefaultConfig"
                                            >
                                            <!-- @change="renderChart" -->
                                            <span v-show="!x_axis_config.stacked">일반</span>
                                            <span v-show="x_axis_config.stacked">누적</span>
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>

                    <template v-if="options.scales['y'] || options.scales['y-right']">
                        <b-tab title="Y축 설정">
                            <div class="bg-light rounded px-3">
                                <b-row :class="[{'border-bottom border-light': y_axis_config.y_state || y_axis_config.y_right_state },  'py-3']">
                                    <b-col cols="12" lg="3">
                                        <b-form-group id="input-group-chart-y-axis-stackable"
                                            label="좌측 Y축 누적"
                                            label-for="input-chart-y-axis-stackable"
                                            class="mb-0"
                                            >
                                            <b-form-checkbox id="input-chart-y-axis-stackable"
                                                v-model="y_axis_config.stacked"
                                                size="sm"
                                                switch
                                                @input="updateDefaultConfig"
                                                >
                                                <!-- @change="renderChart" -->
                                                <span v-show="!y_axis_config.stacked">일반</span>
                                                <span v-show="y_axis_config.stacked">누적</span>
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" lg="3">
                                        <b-form-group id="input-group-chart-y-left-axis-visible"
                                            label="좌측 Y축 표시"
                                            label-for="input-chart-y-left-axis-visible"
                                            class="mb-0"
                                            >
                                            <b-form-checkbox id="input-chart-y-left-axis-visible"
                                                v-model="y_axis_config.y_state"
                                                size="sm"
                                                switch
                                                :disabled="y_axis_config.y_state === null"
                                                @input="updateDefaultConfig"
                                                >
                                                <!-- @change="renderChart" -->
                                                <span v-show="!y_axis_config.y_state">숨기기</span>
                                                <span v-show="y_axis_config.y_state">보이기</span>
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" lg="3">
                                        <b-form-group id="input-group-chart-y-right-axis-visible"
                                            label="우측 Y축 누적"
                                            label-for="input-chart-y-right-axis-visible"
                                            class="mb-0"
                                            >
                                            <b-form-checkbox id="input-chart-y-right-axis-visible"
                                                v-model="y_axis_config.y_right_stacked"
                                                size="sm"
                                                switch
                                                :disabled="y_axis_config.y_right_stacked === null"
                                                @input="updateDefaultConfig"
                                                >
                                                <!-- @change="renderChart" -->
                                                <span v-show="!y_axis_config.y_right_stacked">일반</span>
                                                <span v-show="y_axis_config.y_right_stacked">누적</span>
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" lg="3">
                                        <b-form-group id="input-group-chart-y-right-axis-visible"
                                            label="우측 Y축 표시"
                                            label-for="input-chart-y-right-axis-visible"
                                            class="mb-0"
                                            >
                                            <b-form-checkbox id="input-chart-y-right-axis-visible"
                                                v-model="y_axis_config.y_right_state"
                                                size="sm"
                                                switch
                                                :disabled="y_axis_config.y_right_state === null"
                                                @input="updateDefaultConfig"
                                                >
                                                <!-- @change="renderChart" -->
                                                <span v-show="!y_axis_config.y_right_state">숨기기</span>
                                                <span v-show="y_axis_config.y_right_state">보이기</span>
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <template v-if="y_axis_config.y_state">
                                    <b-row :class="[{ 'border-bottom border-light': y_axis_config.y_state }, 'py-3']">
                                        <b-col cols="12">
                                            <h6>좌측 Y축 설정</h6>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-row>
                                                <b-col cols="12" lg="3">
                                                    <b-form-group id="input-group-chart-y-left-axis-name"
                                                        label="이름"
                                                        label-for="input-chart-y-left-axis-name"
                                                        class="mb-0"
                                                        >
                                                        <b-form-input id="input-chart-y-left-axis-name"
                                                            v-model="y_axis_config.y_name"
                                                            placeholder="좌측 Y축의 이름을 입력해주세요."
                                                            required
                                                            @input="updateDefaultConfig"
                                                            >
                                                            <!-- @change="renderChart" -->
                                                        </b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12" lg="3">
                                                    <b-form-group id="input-group-chart-y-left-axis-min"
                                                        label="최소값"
                                                        label-for="input-chart-y-left-axis-min"
                                                        class="mb-0"
                                                        >
                                                        <b-form-input id="input-chart-y-left-axis-min"
                                                            v-model="y_axis_config.y_min"
                                                            placeholder="좌측 Y축의 최소값을 입력해주세요."
                                                            required
                                                            @input="updateDefaultConfig"
                                                            >
                                                            <!-- @change="renderChart" -->
                                                        </b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12" lg="3">
                                                    <b-form-group id="input-group-chart-y-left-axis-max"
                                                        label="최대값"
                                                        label-for="input-chart-y-left-axis-max"
                                                        class="mb-0"
                                                        >
                                                        <b-form-input id="input-chart-y-left-axis-max"
                                                            v-model="y_axis_config.y_max"
                                                            placeholder="좌측 Y축의 최댓값을 입력해주세요."
                                                            required
                                                            @input="updateDefaultConfig"
                                                            >
                                                            <!-- @change="renderChart" -->
                                                        </b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12" lg="3">
                                                    <b-form-group id="input-group-chart-y-left-axis-step"
                                                        label="Step 값"
                                                        label-for="input-chart-y-left-axis-step"
                                                        class="mb-0"
                                                        >
                                                        <b-form-input id="input-chart-y-left-axis-step"
                                                            v-model="y_axis_config.y_stepSize"
                                                            placeholder="좌측 Y축의 step값을 입력해주세요."
                                                            required
                                                            @input="updateDefaultConfig"
                                                            >
                                                            <!-- @change="renderChart" -->
                                                        </b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </template>
                                
                                <template v-if="y_axis_config.y_right_state">
                                    <b-row class="py-3">
                                        <b-col cols="12">
                                            <h6>우측 Y축 설정</h6>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-row>

                                                <b-col cols="12" lg="3">
                                                    <b-form-group id="input-group-chart-y-right-axis-right"
                                                        label="이름"
                                                        label-for="input-chart-y-right-axis-min"
                                                        class="mb-0"
                                                        >

                                                        <b-form-input id="input-chart-y-right-axis-name"
                                                            v-model="y_axis_config.y_right_name"
                                                            placeholder="우측 Y축의 이름을 입력해주세요."
                                                            required
                                                            @input="updateDefaultConfig"
                                                            >
                                                            <!-- @change="renderChart" -->
                                                        </b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12" lg="3">
                                                    <!-- y_axis_config.name -->

                                                    


                                                    <b-form-group id="input-group-chart-y-right-axis-min"
                                                        label="최소값"
                                                        label-for="input-chart-y-right-axis-min"
                                                        class="mb-0"
                                                        >

                                                        <b-form-input id="input-chart-y-right-axis-min"
                                                            v-model="y_axis_config.y_right_min"
                                                            placeholder="우측 Y축의 최소값을 입력해주세요."
                                                            required
                                                            @input="updateDefaultConfig"
                                                            >
                                                            <!-- @change="renderChart" -->
                                                        </b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12" lg="3">
                                                    <b-form-group id="input-group-chart-y-right-axis-max"
                                                        label="최대값"
                                                        label-for="input-chart-y-right-axis-max"
                                                        class="mb-0"
                                                        >
                                                        <b-form-input id="input-chart-y-right-axis-max"
                                                            v-model="y_axis_config.y_right_max"
                                                            placeholder="우측 Y축의 최대값을 입력해주세요."
                                                            required
                                                            @input="updateDefaultConfig"
                                                            >
                                                            <!-- @change="renderChart" -->
                                                        </b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12" lg="3">
                                                    <b-form-group id="input-group-chart-y-right-axis-step"
                                                        label="Step 값"
                                                        label-for="input-chart-y-right-axis-step"
                                                        class="mb-0"
                                                        >
                                                        <b-form-input id="input-chart-y-right-axis-step"
                                                            v-model="y_axis_config.y_right_stepSize"
                                                            placeholder="우측 Y축의 step값을 입력해주세요."
                                                            required
                                                            @input="updateDefaultConfig"
                                                            >
                                                            <!-- @change="renderChart" -->
                                                        </b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </template>
                            </div>
                        </b-tab>
                    </template>
                </template>
                
                
            </b-tabs>
        </b-col>
    </b-row>
</template>

<script>
import { mapState } from "vuex";
import {
    // point_style_options,
    chart_type_options,
    legend_position_options
} from "./chart";

import { Chrome } from 'vue-color';
import debounce from 'lodash.debounce';
// import { scales } from 'chart.js';

export default {
    name: 'EditChartGlobalConfig',
    components: {
        'chrome-picker': Chrome
    },
    props: {
        // type: {
        //     type: String,
        //     required: true,
        //     default: 'bar'
        // },
        datasets: {
            type: Array,
            required: true,
            default: () => ([])
        },
        options: {
            type: Object,
            required: true,
            default: () => ({})
        },
        usable_title: {
            type: Boolean,
            required: false,
            default: () => null
        }

    },
    data() {
        return {
            chart_type: null,
            single_chart: ['pie', 'doughnut', 'polarArea', 'radar'],
            multi_chart: ['line', 'bar', 'mixed'],
            // point_style_options,
            
			chart_type_options,
			legend_position_options,

            defaultConfig: {
                title: {
                    text: null,
                    position: 'top',
                    fontSize: 12,
                    lineHeight: 1.2
                },
                subtitle: {
                    text: null,
                    position: 'top',
                    fontSize: 12,
                    lineHeight: 1.2
                },
                footnote: null,
                chart_height: null,
                legend: {
                    state: false,
                    text: null,
                    position: 'top',
                    fontSize: 12
                },
                backgroundColor: 'white',
                layoutPadding: 20
            },
            x_axis_config: {
                name: null,
                state: null,
                stacked: false
            },
            y_axis_config: {
                stacked: false,

                y_state: true,
                y_name: null,
                y_min: null,
                y_max: null,
                y_stepSize: null,

                y_right_stacked: false,
                y_right_state: false,
                y_right_name: null,
                y_right_min: null,
                y_right_max: null,
                y_right_stepSize: null
            }
        }
    },
    computed: {
        ...mapState('report_list', ['report_code']),

    },
    watch: {
        datasets: {
            deep: true,
            handler (_updated_datasets) {
                
                this.checkChartType(_updated_datasets)
            }
        },
        options: {
            deep: true,
            handler(_updated_options) {
                const { plugins, scales,  layout } = _updated_options
                this.processingGlobalConfig({ plugins, layout })
                this.processingScales(scales)
            }
        }
    },
    created() {
        this.checkChartType(this.datasets)
        if (this.options) {
            const { plugins, scales, layout } = this.options
            this.processingGlobalConfig({ plugins, layout })
            this.processingScales(scales)
        }
    },
    destroyed () {
        this.initializeGlobalConfig()
    },
    mounted() {},
    methods: {
        updateChartTitle: debounce(async function () {
            const { plugins, scales, layout } = { ...this.options }

            this.$emit("checkDuplicatedTitle", this.defaultConfig.title.text);
            const _updating_opt_plugins = this.generateUpdatingOpt_Plugin({ ...plugins })
            
            this.$emit('updateChartOption', {
                plugins: _updating_opt_plugins,
            })
        }, 450),
        updateDefaultConfig: debounce(function () {
            const { plugins, scales, layout, footnote, chart_height } = { ...this.options }

            // TODO: options에 들어가는 키가 많아지면 그냥 하나로 합쳐도 될거같다. 
            const _updating_opt_plugins = this.generateUpdatingOpt_Plugin({ ...plugins })
            const _updating_opt_scales = this.generateUpdatingOpt_Scales({ ...scales })
            const _updating_opt_layout = this.generateUpdatingOpt_Layout({ ...layout })
            
            this.$emit('updateChartOption', {
                plugins: _updating_opt_plugins,
                scales: _updating_opt_scales,
                layout: _updating_opt_layout,
                footnote: this.defaultConfig.footnote,
                chart_height: this.defaultConfig.chart_height,
            })
        }, 450),
        generateUpdatingOpt_Plugin(plugins) {
            return {
                    title: {
                        ...plugins.title,
                        display: Boolean(this.defaultConfig.title.text),
                        text: this.defaultConfig.title.text,
                        position: this.defaultConfig.title.position,
                        font: {
                            lineHeight: this.defaultConfig.title.lineHeight,
                            size: this.defaultConfig.title.fontSize
                        }
                    },
                    subtitle: {
                        ...plugins.subtitle,
                        display: Boolean(this.defaultConfig.subtitle.text),
                        text: this.defaultConfig.subtitle.text,
                        position: this.defaultConfig.subtitle.position,
                        font: {
                            lineHeight: this.defaultConfig.subtitle.lineHeight,
                            size: this.defaultConfig.subtitle.fontSize
                        }
                    },
                    legend: {
                        display: this.defaultConfig.legend.state,
                        title: {
                            padding: 3,
                            display: Boolean(this.defaultConfig.legend.text),
                            text: this.defaultConfig.legend.text,
                        },
                        labels: {
                            font: {
                                size: this.defaultConfig.legend.fontSize
                            },
                            padding: this.defaultConfig.legend.padding

                        },
                        position: this.defaultConfig.legend.position,
                    },
                customCanvasBackgroundColor: {
                        color: (typeof this.defaultConfig.backgroundColor === 'string'? this.defaultConfig.backgroundColor : this.defaultConfig.backgroundColor.hex8) || 'white'
                        // color: this.defaultConfig.backgroundColor.hex8 || 'white'
                    }
                }
        },
        generateUpdatingOpt_Layout() {
            return {
                padding: this.defaultConfig.layoutPadding || 20
            }
        },
        generateUpdatingOpt_Scales(scales) {
            let _updating_scales = {}

            if (scales?.x) {
                _updating_scales['x'] = {
                    display: this.x_axis_config.state,
                    title: {
                        display: Boolean(this.x_axis_config.name),
                        text: this.x_axis_config.name,
                        color: "#666"

                    },
                    stacked: this.x_axis_config.stacked
                }
            }

            if (scales?.y) {
                _updating_scales['y'] = {
                    display: this.y_axis_config.y_state,
                    title: {
                        display: Boolean(this.y_axis_config.y_name),
                        text: this.y_axis_config.y_name,
                        color: "#666"

                    },
                    stacked: this.y_axis_config.stacked,
                    ticks: {
                        stepSize: Number(this.y_axis_config.y_stepSize)
                    }
                }

                if (this.y_axis_config.y_min) {
                    _updating_scales['y']['min'] = Number(this.y_axis_config.y_min)
                }
                if (this.y_axis_config.y_max) {
                    _updating_scales['y']['max'] = Number(this.y_axis_config.y_max)
                }
                if (this.x_axis_config.x_min) {
                    _updating_scales['x']['min'] = Number(this.x_axis_config.x_min)
                }
                if (this.x_axis_config.x_max) {
                    _updating_scales['x']['max'] = Number(this.x_axis_config.x_max)
                }
            }

            if (scales['y-right']) {
                console.log('y-right')
                _updating_scales['y-right'] = {
                    display: this.y_axis_config.y_right_state,
                    title: {
                        display: Boolean(this.y_axis_config.y_right_name),
                        text: this.y_axis_config.y_right_name,
                        color: "#666"
                    },
                    stacked: this.y_axis_config.y_right_stacked,
                    min: Number(this.y_axis_config.y_right_min),
                    max: Number(this.y_axis_config.y_right_max),
                    ticks: {
                        stepSize: Number(this.y_axis_config.y_right_stepSize),
                    },
                    position: 'right',
                    // grid: {
                    //     display: false
                    // },
                }

                _updating_scales['y-right']['grid'] = { display: !scales?.y }
            }

            return _updating_scales
        },
        processingGlobalConfig({ plugins, layout }) {
            if (plugins) {
                this.defaultConfig = {
                    title: {
                        display: Boolean(plugins.title.text),
                        text: plugins.title.text || null,
                        position: plugins.title.position || 'top',
                        fontSize: plugins.title.font.size || 12,
                        lineHeight: plugins.title.font.lineHeight || 1.2
                    },
                    subtitle: {
                        display: Boolean(plugins.subtitle.text),
                        text: plugins.subtitle.text || null,
                        position: plugins.subtitle.position || 'top',
                        fontSize: plugins.subtitle.font.size || 12,
                        lineHeight: plugins.subtitle.font.lineHeight || 1.2
                    },
                    legend: {
                        state: plugins.legend.display,
                        text: plugins.legend?.title?.text || null,
                        position: plugins.legend.position || 'top',
                        padding: plugins.legend?.labels?.padding || 10,
                        fontSize: plugins.legend?.labels?.font?.size || 12
                    },
                    layoutPadding: layout?.padding || 20,
                    backgroundColor: plugins?.customCanvasBackgroundColor?.color || 'white',
                    footnote: !this.options.footnote ? null : this.options.footnote,
                    chart_height: !this.options.chart_height ? null : this.options.chart_height,
                    
                }
            } else {
                this.defaultConfig = {
                    title: null, subtitle: null, footnote: null, chart_height: null,
                    legend: { state: false, position: 'top' }
                }
            }
        },
        processingScales(scales) {
            if (scales?.x) {
                this.x_axis_config = {
                    name: scales.x?.title.text || null,
                    state: scales.x.display,
                    stacked: scales?.x.stacked
                }
            } else {
                this.x_axis_config = { name: null, state: null, stacked: false }
            }

            this.y_axis_config = {
                y_state: scales?.y?.display,
                stacked: scales?.y?.stacked,
                y_name: scales?.y?.title?.text || null,
                y_min: scales?.y?.min || null,
                y_max: scales?.y?.max || null,
                y_stepSize: scales?.y?.ticks?.stepSize || null
            }

            this.y_axis_config['y_right_state'] = scales['y-right'] ? scales['y-right'].display : null
            this.y_axis_config['y_right_min'] = scales['y-right'] ? scales['y-right'].min : null
            this.y_axis_config['y_right_max'] = scales['y-right'] ? scales['y-right'].max : null
            this.y_axis_config['y_right_stepSize'] = scales['y-right'] ? scales['y-right'].stepSize : null
            this.y_axis_config['y_right_name'] = scales['y-right'] ? scales['y-right'].title.text : null
            this.y_axis_config['y_right_stacked'] = scales['y-right'] ? scales['y-right'].stacked : null


            
            
        },
        checkChartType (dataset) {
            if (dataset.length) {
                dataset.forEach(({ type }) => {
                    this.chart_type = type

                    if (this.multi_chart.includes(type)) {
                        if (type !== this.chart_type) this.chart_type = 'mixed'
                    }
                });
            } else {
                this.chart_type = null
            }
        },
        initializeGlobalConfig () {
            this.defaultConfig = {
                title: null, subtitle: null, footnote: null, chart_height: null,
                legend: { state: false, position: 'top' }
            }
            this.x_axis_config = { name: null, state: null, stacked: false }
            this.y_axis_config = {
                stacked: false,
                y_state: true, y_name: null, y_min: null, y_max: null, y_stepSize: null,
                y_right_state: false, y_right_name: null, y_right_min: null, y_right_max: null, y_right_stepSize: null
            }
        },
        convertingColor(color) {
            if (typeof color === 'string') {
                return color
            } else if (typeof color === 'object' && 'hex' in color) {
                return color.hex8
            } else {
                return 'rgba(239, 239, 239, 1)'
            }
        },
    }
}
</script>
<template>
<div>
    <h4>GRI 3: Material Topics 기반 지표 추천</h4>

        <b-card class="general-card mb-5">
                <p class="">
                    GRI 보고를 위한 지표 추천 기능은 GRI 3: Material Topic에 기반한 영향 중요성(impact materiality)을 기반으로 조직이 보고해야 할 지표를 추천합니다. 
                </p>
                <p class="">
                    지표 추천 기능은 GRI 3: Material Topic에 제시된 중요 주제 선정 프로세스를 적용하며, 외부 전문가적 차원에서의 지원을 제공할 수 있는 Step1~3를 지원하고 있습니다. 
                </p>
                <p class="">
                    보고 조직은 제시된 추천 지표를 기반으로 조직의 맥락과 비즈니스  환경적 특성을 반영하여 보고할 지표를 최종적으로 수정/편집할 수 있으며, 우선순위를 정하여 GRI 3-2에 중요 주제 목록을 보고할 수 있습니다. 
                </p>
                <p class="gray-600 f-90 mb-45">
                    * 지표 추천 기능은 보고 조직의 산업 정보를 기반으로 제공되는 사항으로, 개별 보고 조직 입장에서 중요성이 떨어지는 지표가 포함되거나, 중요 주제가 누락되어 있을 수 있습니다. 보고 조직에 더욱 적합한 중요성 평가를 진행하기 위해서는 GRI 3를 참고하여 중요 주제를 결정해 주세요.
                </p>
                <h6 class="fw-600">GRI 3: Material Topic에 기반한 중요 주제 결정 프로세스</h6>
                <b-card class="border-0 bgray-200 mb-45">
                    <p class="fw-600 gray-900 mb-1">Step1) 조직의 맥락 이해하기</p>
                    <p class="fw-600 gray-900 mb-1">Step2) 실질적, 잠재적 영향 식별하기</p>
                    <p class="fw-600 gray-900 mb-1">Step3) 영향의 중요성 평가하기</p>
                    <p class="fw-600 gray-600 mb-0">Step4) 중요주제의 보고 우선순위 정하기 <span class="fw-400 italic"><fa icon="arrow-right"></fa> 보고 조직(사용자)이 우선순위 정하여 GRI 3-2 보고 필요</span> </p>
                </b-card>
                <h6 class="fw-600">지표 추천 기능의 Step 1~3 적용 방법론</h6>
                <b-card class="border-0 bgray-200">
                    <p class="fw-600 gray-900 mb-1">Step1) 조직의 맥락 이해하기</p>
                        <p class="gray-600 mb-1">조직의 산업 정보를 기반으로 산업적 맥락에 기반한 지속가능성 공시 지표를 평가합니다.</p>
                        <p class="gray-600 mb-1">- GRI Sector Standards가 발간된 산업은 해당 보고 표준에 제시된 예상 중요주제(likely material topics)를 우선적으로 적용합니다.</p>
                        <p class="gray-600 mb-1">- GRI Sector Standards가 발간되지 않은 산업의 경우, SASB Standards 정보 요구사항을 검토합니다.</p>
                        <p class="gray-600 mb-1">- 산업별 주요 글로벌 이니셔티브에서 다루고 있는 주제가 무엇인지 검토합니다.</p>
                        <p class="gray-600 mb-1">- 주요 글로벌 ESG Rating사가 해당 산업을 평가하기 위해 활용하는 지표를 검토합니다.</p>
                        <p class="gray-600 mb-1">- 최근 1년 내 미디어에서 노출된 산업별 이슈를 검토합니다.</p>
                        <p class="gray-600 mb-3">- 위 과정을 통해 도출된 이슈를 산업 ESG 이슈 Pool로 결정합니다.</p>
                    <p class="fw-600 gray-900 mb-1">Step2) 실질적, 잠재적 영향 식별하기</p>
                        <p class="gray-600 mb-3">실질적, 잠재적 영향을 식별하기 위해 산업 내 주요 사업 활동과 관련한 경제, 환경, 사람(인권)에 대한 영향을 분석하였습니다. 분석에 사용한 데이터는 QESG의 국내 기업 ESG Database와 Step 1에서 도출된 미디어 분석 자료에 기반합니다. </p>
                    <p class="fw-600 gray-900 mb-1">Step3) 영향의 중요성 평가하기</p>
                        <p class="gray-600 mb-1">영향의 중요성을 평가하는 과정은 외부 전문가 관점에서 진행되었습니다. 각 주제와 관련한 이슈가 산업 내에서 얼마나 자주 발생하는지, 이슈가 발생하였을 때 심각성 및 영향을 받는 이해관계자의 범위 등을 고려하여 중요성을 평가하였습니다. </p>
                    <p class="fw-600 gray-900 mb-1">Step4) 중요주제의 보고 우선순위 정하기</p>
                        <p class="gray-600 mb-1">조직은 Step 1~3를 통해 분석한 영향에 대해 가장 중요한 영향을 우선순위화 하여 보고할 중요 주제를 결정해야 합니다.</p>

                </b-card>

        </b-card>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
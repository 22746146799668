<template>
<div>
    <!-- <edit-business-case></edit-business-case> -->
</div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import * as ih from '@/components/util'
// import EditBusinessCase from '@/components/writing_report/write_report_subs/EditBusinessCase'

export default {
    components: {
        // EditBusinessCase
    },
    data(){
        return {
            new_file: {
                link: '',
                filename: ''
            }
        }  
    },
    computed:{
        ...mapState('report_list', ['report_code', 'report_list']),
        ...mapState('report_contents', ['report_contents']),
        ...mapState('data_tables', ['table_list']),
        ...mapState('report_indicators', ['topics_list', 'indicators_list'])
    },
    methods: {
        ...mapMutations('report_list', ['update_report_code']),
        ...mapActions('report_list', ['readReportListByUserId', 'saveReportPreference']),
        ...mapActions('report_contents', ['readReportContents', 'saveDisclosure']),
        ...mapActions('data_tables', ['readDataTableList', 'saveDataTable', 'updateDataTableRelation']),
        ...mapActions('report_indicators', ['readReportTopics', 'readReportIndicators', 'linkReportTopics', 'linkReportIndicators'])

    },
    async created(){
        // await this.readReportListByUserId({user_id: 1})
        await this.readReportTopics({report_code: 'r13'})
        await this.readReportIndicators({report_code: 'r13'})
        await this.readReportContents({report_code: 'r13'})
        this.update_report_code('r13')
        // console.log(this.report_contents)
        // await this.readDataTableList({report_code: 'r13'})
        // console.log(this.table_list)
        // await this.updateDataTableRelation({report_code: 'r13', table_ids: [5], gri_code:"404-3" })
        // console.log('변경 후')
        // console.log(this.report_contents)
        // console.log(this.table_list)
        // let new_report = 
        // {   report_code: 'r11', 
        //     report_name: 'original_report_name',
        //     organization_name: 'new_company',
        //     start_date: '2020-10-01',
        //     end_date: '2020-10-30',
        //     state: 'started',
        //     using_logo: true, 
        //     main_color: 'black',
        //     sub_color: 'red',
        //     font_family: 'malgun-gothic',
        //     font_size: 'normal',
        //     folded: true, 
        //     owner_id: 1,
        //     align_option: 'core',
        //     organization_name_e: 'new_company_e',
        //     registration_code: 1311,
        //     address: 'my hometown 1122'
        // }
        // await this.saveReportPreference(new_report)
        // console.log(this.report_list)

        // await this.linkReportTopics({ report_code: 'r13', link_payload: { "400": [true, true] } })
        // console.log(this.topics_list)

        //await this.linkReportIndicators({ report_code: 'r13', link_payload: { "401-1": true } })
        //console.log(this.indicators_list)

        //this.new_file = await ih.getFile('저장명.jpg', 'featured_images\\r15\\aqzdde0462.jpg', false)
        //this.new_file.link = ih.getImage('featured_images\\r15\\aqzdde0462.jpg', true)

    }
    
}
</script>
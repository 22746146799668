<template>
  <div>
    <h2 class="fw-600 mb-3 mt-4">SASB Index</h2>
    <div v-if="sasb_indicator_list.length === 0">
      <h3 class="py-4 fw-600">
        <b-skeleton-img no-aspect height="30px" width="40%"></b-skeleton-img>
      </h3>
      <b-table-simple responsive class="disclosure-table lg border-bottom mb-5">
        <b-thead v-if="show_table_header">
          <b-tr>
            <b-td style="min-width:22%; width:32%;">{{ lang !== 'ENG' ? '보고 지표' : 'Disclosures' }}</b-td>
            <b-td class="d-lg-flex justify-content-between">
              {{ lang != 'ENG' ? '보고 내용' : 'Disclosure Contents' }}
              <div class="f-90">
                <fa icon="table" class="mr-1" /> {{ lang != 'ENG' ? '데이터 테이블' : 'Data Tables' }}
                <fa icon="file-alt" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '문서' : 'Files' }}
                <fa icon="external-link-square-alt" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '외부 링크' : 'URLs' }}
                <fa icon="quote-left" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '비즈니스 사례' : 'Business Cases' }}
              </div>
            </b-td>
          </b-tr>
        </b-thead>

        <b-tbody class="mb-2 border-0">
          <b-tr>
            <b-td colspan="2" class="py-1 pl-1" :style="main_color_sub">
              <span class="fw-600 f-140">
                <b-skeleton animation="wave" width="20%"></b-skeleton>
              </span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="pr-4 d-flex flex-column">
              <div class="fw-600 f-140">
                <b-skeleton animation="wave" width="30%"></b-skeleton>
                <b-skeleton animation="wave" width="15%"></b-skeleton>
              </div>
            </b-td>
            <b-td class="" style="max-width: 200mm;">
              <b-skeleton animation="wave" width="85%"></b-skeleton>
              <b-skeleton animation="wave" width="55%"></b-skeleton>
              <b-skeleton animation="wave" width="70%"></b-skeleton>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="pr-4 d-flex flex-column">
              <div class="fw-600 f-140">
                <b-skeleton animation="wave" width="30%"></b-skeleton>
                <b-skeleton animation="wave" width="15%"></b-skeleton>
              </div>
            </b-td>
            <b-td class="" style="max-width: 200mm;">
              <b-skeleton animation="wave" width="55%"></b-skeleton>
              <b-skeleton animation="wave" width="35%"></b-skeleton>
              <b-skeleton animation="wave" width="70%"></b-skeleton>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>

    <div v-for="(industry_key, industry_idx) in Object.keys(sasb_indicator_list)" :key="industry_idx">
      <div v-if="Object.keys(sasb_indicator_list[industry_key]['sustainability_metrics']).length > 0">
        <h3 :id="'rp' + industry_key" class="py-4 fw-600">
          {{ industry_key }}. {{ sasb_indicator_list[industry_key]["industry_name"] }}
        </h3>
        <h4>지속가능성 공시 주제 및 지표</h4>
        <b-table-simple responsive class="disclosure-table lg border-bottom mb-5">
          <b-thead v-if="show_table_header">
            <b-tr>
              <b-td style="min-width:22%; width:32%;">{{ lang !== 'ENG' ? '보고 지표' : 'Disclosures' }}
                <header-anchor :active_code="industry_key" :print_mode="print_mode" />
              </b-td>
              <b-td class="d-lg-flex justify-content-between">
                {{ lang != 'ENG' ? '보고 내용' : 'Disclosure Contents' }}
                <div class="f-90">
                  <fa icon="table" class="mr-1" /> {{ lang != 'ENG' ? '데이터 테이블' : 'Data Tables' }}
                  <fa icon="file-alt" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '문서' : 'Files' }}
                  <fa icon="external-link-square-alt" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '외부 링크' : 'URLs' }}
                  <fa icon="quote-left" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '비즈니스 사례' : 'Business Cases' }}
                </div>
              </b-td>
            </b-tr>
          </b-thead>

          <b-tbody
            v-for="(topic_key, topic_idx) in Object.keys(sasb_indicator_list[industry_key]['sustainability_metrics'])"
            :id="'rp' + topic_idx" :key="topic_idx" class="mb-2 border-0">
            <b-tr>
              <b-td colspan="2" class="py-1 pl-1" :style="main_color_sub">
                <span class="fw-600 f-140">
                  {{ topic_idx + 1 }}. {{ lang !== 'ENG' ?
                    sasb_indicator_list[industry_key]['sustainability_metrics'][topic_key]["topic_name"]
                    :
                    sasb_indicator_list[industry_key]['sustainability_metrics'][topic_key]["topic_name_e"] }}
                </span>
                <span class="gray-500 f-90 fw-400 ml-1">
                  {{ sasb_indicator_list[industry_key]['sustainability_metrics'][topic_key]["topic_name_e"] }}
                </span>
              </b-td>
            </b-tr>

            <b-tr
              v-for="(disclosure) in sasb_indicator_list[industry_key]['sustainability_metrics'][topic_key]['children']"
              :key="disclosure.disclosure_id" :id="`w${disclosure.indicator_code}`">
              <b-td :id="'crp' + disclosure.disclosure_id" class="pr-4 d-flex flex-column">
                <div class="fw-500">{{ disclosure.indicator_code }}</div>
                <div class="pb-5 fw-300 f-90 italic">
                  <p
                    v-html="lang === 'KOR' ? disclosure.metric.replace(/\((\d+)\)/g, '<br>($1)') : disclosure.metric_e.replace(/\((\d+)\)/g, '<br>($1)')">
                  </p>
                </div>
              </b-td>
              <b-td class="" style="max-width: 200mm;">
                <md v-if="lang !== 'ENG' && disclosure.direct_report" style="word-wrap: break-word;"
                  :contents="disclosure.direct_report" :class="{ 'mb-3': disclosure.direct_report }" />
                <md v-if="lang === 'ENG' && (disclosure.direct_report_em || disclosure.direct_report_ea)"
                  style="word-wrap: break-word;"
                  :contents="disclosure.direct_report_em ? disclosure.direct_report_em : disclosure.direct_report_ea"
                  :class="{ 'mb-3': disclosure.direct_report }" />

                <!-- data_tables 넣을자리 -->
                <div v-for="data_table_id in disclosure.data_tables" :key="'data_table_' + data_table_id">
                  <data-table :table_mode="table_mode" :data_table_id="data_table_id"
                    :modal_table="modal_table" :lazy_load="false" :table_style="table_style"
                    :show_table_line="show_table_line" :show_table_outline="show_table_outline"
                    :table_header_color="table_header_color"
                    :table_header_font_color="table_header_font_color" />
                </div>

                <!-- documents 넣을자리 -->
                <div>
                  <span v-for="document_id in disclosure.documents" :key="'document_' + document_id">
                    <document :document_id="document_id" :lazy_load="false" />
                  </span>
                </div>

                <!-- business_cases 넣을자리 -->
                <span v-for="business_case_id in disclosure.business_cases" :key="'business_case_' + business_case_id">
                  <business-case :business_case_id="business_case_id" :lazy_load="false"
                    :business_case_mode="business_case_mode" />
                </span>

                <span v-for="chart_id in disclosure.charts" :key="'chart_' + chart_id">
                  <ChartCanvas v-if="chart_id !== ''" :chart_id="chart_id" :print_mode="print_mode"
                    :key="`chart_${chart_id}`" :viewer_mode="true" class="mb-5" />
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <h4 v-if="sasb_indicator_list[industry_key]['activity_metrics'].length > 0">활동 지표</h4>
        <b-table-simple v-if="sasb_indicator_list[industry_key]['activity_metrics'].length > 0" responsive
          class="disclosure-table lg border-bottom mb-5">
          <b-thead v-if="show_table_header">
            <b-tr>
              <b-td style="min-width:22%; width:32%;">{{ lang !== 'ENG' ? '보고 지표' : 'Disclosures' }}
                <header-anchor :active_code="industry_key" :print_mode="print_mode" />
              </b-td>
              <b-td class="d-lg-flex justify-content-between">
                {{ lang != 'ENG' ? '보고 내용' : 'Disclosure Contents' }}
                <div class="f-90">
                  <fa icon="table" class="mr-1" /> {{ lang != 'ENG' ? '데이터 테이블' : 'Data Tables' }}
                  <fa icon="file-alt" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '문서' : 'Files' }}
                  <fa icon="external-link-square-alt" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '외부 링크' : 'URLs' }}
                  <fa icon="quote-left" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '비즈니스 사례' : 'Business Cases' }}
                </div>
              </b-td>
            </b-tr>
          </b-thead>

          <b-tbody
            v-for="(disclosure, disclosure_idx) in sasb_indicator_list[industry_key]['activity_metrics']"
            :id="'rp' + disclosure_idx" :key="disclosure_idx" class="mb-2 border-0">
            <b-tr>
              <b-td colspan="2" class="py-1 pl-1" :style="main_color_sub">
                <span class="fw-600 f-140"></span>
                <span class="gray-500 f-90 fw-400 ml-1"></span>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td :id="'crp' + disclosure.disclosure_id" class="pr-4 d-flex flex-column">
                <div class="fw-500">{{ disclosure.indicator_code }}</div>
                <p class="pb-3">{{ lang === "KOR" ? disclosure.topic : disclosure.topic_e }}</p>
                <div class="pb-5 fw-300 f-90 italic">
                  <p
                    v-html="lang === 'KOR' ? disclosure.metric.replace(/\((\d+)\)/g, '<br>($1)') : disclosure.metric_e.replace(/\((\d+)\)/g, '<br>($1)')">
                  </p>
                </div>
              </b-td>
              <b-td class="" style="max-width: 200mm;">
                <md v-if="lang !== 'ENG' && disclosure.direct_report" style="word-wrap: break-word;"
                  :contents="disclosure.direct_report" :class="{ 'mb-3': disclosure.direct_report }" />
                <md v-if="lang === 'ENG' && (disclosure.direct_report_em || disclosure.direct_report_ea)"
                  style="word-wrap: break-word;"
                  :contents="disclosure.direct_report_em ? disclosure.direct_report_em : disclosure.direct_report_ea"
                  :class="{ 'mb-3': disclosure.direct_report }" />

                <!-- data_tables 넣을자리 -->
                <div v-for="data_table_id in disclosure.data_tables" :key="'data_table_' + data_table_id">
                  <data-table :table_mode="table_mode" :data_table_id="data_table_id"
                    :modal_table="modal_table" :lazy_load="false" :table_style="table_style"
                    :show_table_line="show_table_line" :show_table_outline="show_table_outline"
                    :table_header_color="table_header_color"
                    :table_header_font_color="table_header_font_color" />
                </div>

                <!-- documents 넣을자리 -->
                <div>
                  <span v-for="document_id in disclosure.documents" :key="'document_' + document_id">
                    <document :document_id="document_id" :lazy_load="false" />
                  </span>
                </div>

                <!-- business_cases 넣을자리 -->
                <span v-for="business_case_id in disclosure.business_cases" :key="'business_case_' + business_case_id">
                  <business-case :business_case_id="business_case_id" :lazy_load="false"
                    :business_case_mode="business_case_mode" />
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import DataTable from './DataTable.vue';
import Document from './Document.vue';
import BusinessCase from './BusinessCase.vue';
import HeaderAnchor from './HeaderAnchor';
import { nan_table } from '@/components/util';
import ChartCanvas from "@/components/writing_report/chart_subs/ChartCanvas.vue";

export default {
  name: "SASBTableMicro",
  components: {
    DataTable,
    Document,
    BusinessCase,
    HeaderAnchor,
    ChartCanvas
  },
  data() {
    return {
      show_data_table: {},
      show_business_case: {},
      low_height: false,
      selected_indicator: null,
      show_table_header: true,
      sasb_indicator_list: [],
      industry_key: null,
      nan_table
    };
  },
  props: {
    modal_table: { type: Boolean, default: false },
    reload: { type: Boolean, default: false },
    print_mode: { type: Boolean, default: false },
    business_case_mode: { type: String, default: "popup" },
    table_mode: { type: String, default: "accordion" },
    material_style_option: { type: String, default: "outline" },
    table_style: { type: String, default: 'basic_table' },
    show_table_line: { type: Boolean, default: true },
    show_table_outline: { type: Boolean, default: false },
    table_header_color: { type: String, default: '#dedede' },
    table_header_font_color: { type: String, default: '#fffff' },
  },
  computed: {
    ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
    ...mapGetters('report_list', ['selected_report']),
    main_color() {
      return `color:` + this.selected_report.main_color;
    },
    main_color_sub() {
      if (this.material_style_option === 'fill') {
        return `background-color: ${this.selected_report.main_color}; color: white; border: 1px solid white; `;
      } else if (this.material_style_option === 'outline') {
        return `color:` + this.selected_report.main_color + `; border-top: 1px solid ` + this.selected_report.main_color + `!important` + `; border-bottom: 1px solid ` + this.selected_report.main_color;
      } else {
        return `background-color: white; border-left: 3px solid ${this.selected_report.main_color}; border-bottom: 1px solid ${this.selected_report.sub_color};border-top: 0px; border-right: 0px;`;
      }
    },
    main_bg_color() {
      if (this.selected_report) {
        return `background-color:` + this.selected_report.main_color;
      }
      else return `background-color: #494949`;
    }
  },
  methods: {
    ...mapActions('data_tables', ['readDataTableList']),
    ...mapActions('business_cases', ['readBusinessCaseList']),
    ...mapActions('documents', ['readDocumentList']),
    async fetchSelectedSasb() {
      const urlParams = new URLSearchParams(window.location.search);
      this.industry_key = urlParams.get('industry_key');
      try {
        const response = await axios.get(
          `${this.$store.state.backend_host}/sasb/get_indicator_disclosures_list_for_viewer?rc=${this.report_code}&industry_key=${this.industry_key}`
        );
        this.sasb_indicator_list = response.data.result;
      } catch (error) {
        window.alert(error);
      }
    },
    navAnchor(event, anchor) {
      event.preventDefault();
      if (anchor && document.querySelector("#w" + anchor)) {
        let elem = document.querySelector("#w" + anchor);
        if (elem) {
          elem.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
  },
  watch: {
    '$route': 'fetchSelectedSasb' // URL 변화 감지
  },
  beforeRouteUpdate (to, from, next) {
    this.fetchSelectedSasb();
    next();
  },
  mounted() {
    this.fetchSelectedSasb();
  }
};
</script>

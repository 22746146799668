<template>
<div>
    <b-container class="mb-5" v-if="is_login_checked">
        <b-row>
            <b-col cols="0" md="2" lg="3"></b-col>
            <b-col cols="12" md="8" lg="6" class="align-self-center" >
                <b-card class="px-3 mx-md-3 mx-xl-5 rounded-2 border-0 mb-4">
                    <template v-if="password_finding_mode!=true">
                        <h6 class="mb-3 tealgray400">로그인</h6>
                        <div class="mb-1 f-90">이메일 주소</div>
                        <b-form-input class="login-card-input mb-2" v-model="user.e_mail" label="E-mail:" placeholder="name@company.com"></b-form-input>
                        <div class="mb-1 f-90">비밀번호 </div>
                        <b-form-input class="login-card-input mb-1" v-model="user.password" label="Password:" placeholder="" type="password" @keyup.enter="loginToServer()"></b-form-input>
                        <div class="mb-45"><a @click="findPassword()" class="cursor f-90">비밀번호를 잊으셨나요?</a></div>
                        
                        <b-button block @click="loginToServer()" variant="login" class="mb-3">로그인 하기</b-button>
                        <div v-if="login_fail_msg!=''" class="mb-2 f-95 text-center"><span class="red pr-1"><fa icon="exclamation-circle" /> {{ login_fail_msg }} </span></div>

                    </template>

                    <template v-if="password_finding_mode == true && pls_check_email != true">
                        <h6 class="mb-3 tealgray400">비밀번호 찾기</h6>
                        <div class="mb-1 f-90">가입한 이메일 주소 입력</div>
                        <b-form-input class="login-card-input mb-4" v-model="user.e_mail" label="E-mail:" placeholder="name@company.com"></b-form-input>
                        <div v-if="finding_status!='fail'">
                            <b-button variant="dark" block class="rounded-2 mb-4" @click="sendPassword()" :disabled="finding_status=='finding'">
                                {{ finding_status!='finding' ? '임시 비밀번호 받기' : '' }} <b-spinner small class="mb-1" v-if="finding_status=='finding'"></b-spinner>
                            </b-button>
                            <!-- <b-button block @click="loginRetry()" variant="login" class="mb-3">로그인 다시시도</b-button> -->
                        </div>
                        <div v-else class="mb-3 f-90"> <i class="fas fa-exclamation-circle mr-1"></i>등록된 사용자가 없습니다. 회원가입 후 로그인 해주세요. </div>                        
                    </template> 

                    <template v-else-if="password_finding_mode == true && pls_check_email == true">
                        <div class="py-5 tealgray600">입력하신 이메일로 임시 비밀번호가 발송되었습니다. <br> 다시 로그인해 주세요.</div>
                        <b-button variant="dark" block class="rounded-2 mb-5" @click="loginRetry()">다시 로그인하기</b-button>
                    </template>
                </b-card>
                <div align="center"><span class="f-95 pr-1" >아직 가입하지 않으셨다면,</span> <a @click="$router.push('/signup')" class="cursor">회원가입</a></div>
            </b-col>
            <b-col cols="0" md="2" lg="3"></b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ChannelService from "@/components/ChannelService";

export default {
    data(){
        return{
            user: {
                e_mail: '',
                password: ''
            },
            login_fail_msg: '',
            password_finding_mode: false,
            finding_status: '',
            pls_check_email: false,
            is_login_checked: false
        }
    },
    computed:{
        ...mapState(['user_id', 'e_mail', 'user_name', 'access_token', 'is_admin']),
        
    },
    methods:{
        ...mapActions(['login', 'logout']),
        loginToServer(){
            this.login({ e_mail: this.user.e_mail, password: this.user.password })
            .then( response => {
                if (response.data.code != 'failure') {
                    // ChannelService.updateUser();
                    
                    // ChannelService.shutdown();
                    ChannelService.boot();

                    this.$router.push({name:'Main'})
                }
                else {
                    this.login_fail_msg = response.data.msg
                }
            })
            .catch( error => {
                console.log(error)
                alert('알 수 없는 이유로 로그인에 실패하였습니다. 관리자에 문의해주세요.')
                this.logout().then(() => this.$router.push({ name: "Login" }));
            })
        },
        sendPassword(){
            this.finding_status = 'finding'
            const path = this.$store.state.backend_host + '/send_temp_password'

            axios.get(path, {params: {e_mail: this.user.e_mail}})
            .then(response => {
                if(response.data.e_mail != ''){
                    this.pls_check_email = true
                    this.finding_status = '' 
                    this.$EventBus.$emit('make-toast', '임시 비밀번호 발송', '가입하신 이메일로 임시 비밀번호를 발송하였습니다. 이메일을 확인 후 다시 로그인해주세요.')   
                }
            })
            .catch(error => {
                console.log(error.response)
                alert('등록되지 않은 이메일입니다.')
                this.finding_status = 'fail'
                this.pls_check_email = false
            })
        },
        findPassword(){
            this.login_fail_msg = ''
            this.password_finding_mode = true
        },
        loginRetry(){
            this.password_finding_mode = false
            this.user.password = ''
            this.finding_status = ''
        },
        confirm_login_status() {
            if (localStorage.getItem("access_token")) {
                axios.get(this.$store.state.backend_host + "/confirm_login_status?access_token=" + localStorage.getItem("access_token").replaceAll("+", "%2B")).then((response) => {
                    if (response.data.code) {
                        this.$router.push({ name: "Main" });
                    } else {
                        localStorage.clear();
                        sessionStorage.clear();
                    }
                    // else {
                    //     window.alert(response.data.msg);
                    // }
                    this.is_login_checked = true;
                })
                .catch(e => {
                    // console.log(e);
                    window.alert(e);
                    this.is_login_checked = true;
                    localStorage.clear();
                    sessionStorage.clear();
                })
            } else {
                this.is_login_checked = true;
                localStorage.clear();
                sessionStorage.clear();
            }
        }
    },
    mounted() {
        this.confirm_login_status();

    },
    created() {
        ChannelService.boot();
        // this.logout()
    }
}
</script>

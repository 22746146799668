<template>
    <div>
      <div>
        <div class="mb-4" v-if="is_editing">
          <b-button size="sm" @click="editToc(false)">목록 편집 취소</b-button>
        </div>
  
        <div v-if="!is_editing">
          <b-card
            v-for="(item, index) in toc_list"
            :key="index"
            border-variant="light"
            :header="item.name_em ? (item.name + ' ' + item.name_em) : (item.name + ' ' + item.name_ea)"
            class="text-left bgray-200"
            header-bg-variant="white"
          >
            <b-card-text
              @click="showTocInfo(child)"
              v-for="(child, childIndex) in item.children"
              :key="childIndex"
              no-body
              >
              {{ child.name }}
              {{ child.name_em ? child.name_em : child.name_ea }}
            </b-card-text
            >
          </b-card>
        </div>
  
        <b-card v-else class="bgray-200 border-0 px-2 mt-1">
          <template>
            <div>
                <div v-for="(item, index) in temp_toc_list" :key="index">
                  <b-card no-body class="mt-3 pt-1 pb-1">
                    <div style="white-space: nowrap">
                        <EToCBtn
                            class="mt-2"
                            :name="item.name"
                            :name_e="item.name_e"
                            :lv1_info="item"
                        />
                    </div>
                  </b-card>
  
                  <!-- 2레벨 -->
                  <b-collapse :id="`toc_${item.key}`" visible>
                      <b-card
                        v-for="(child, childIndex) in item.children"
                        :key="childIndex"
                        no-body
                        class="ml-4 mt-1 pt-1 pb-1"
                      > 

                        <EToCBtn 
                            class="mt-2"
                            :name="child.name"
                            :name_e="child.name_e"
                            :lv1_info="item"
                            :lv2_info="child"
                        />
                        
                      </b-card>
                  </b-collapse>
                </div>
            </div>
          </template>
  
        </b-card>
  
        <b-button v-if="!is_editing" @click="editToc(true)" class="mt-3 mb-3" variant="outline-info">
          <span> 목록 편집하기 </span>
          <b-icon scale="1" icon="pencil-square" aria-hidden="true"></b-icon>
        </b-button>
      </div>
  
    </div>
  </template>
  <script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import { generateRandomString } from "@/components/util";
  import EToCBtn from "@/components/translate/translate_subs/EToCBtn";

  export default {
    computed: {
      ...mapState('charts', ['chart_data', 'toc_list']),
      ...mapState("report_list", ["report_code"]),
    },
    components: {
        EToCBtn
    },
    mounted() {
        this.getDCToC({ "report_code": this.report_code });
        this.initToc();
    },
    data() {
      return {
        is_editing: false,
        temp_toc_list: null,
      };
    },
    methods: {
      ...mapActions("charts", ["getDCToC"]),
      ...mapMutations('charts', ['update_toc_list']),
      generateRandomString,
      async initToc() {
        // getTocInfo
        this.temp_toc_list = [...this.toc_list];

        for (let lv1_info of this.temp_toc_list) {
            lv1_info.name_e = lv1_info.name_em ? lv1_info.name_em : lv1_info.name_ea

            for (let lv2_info of lv1_info['children']) {
                lv2_info.name_e = lv2_info.name_em ? lv2_info.name_em : lv2_info.name_ea
                
            }
        }

        this.temp_toc_list = [...this.temp_toc_list];
      },
      
      showTocInfo(e) {
        console.log("kjflkjlske");
      },
  
      editToc(toggle) {
        if (this.$route.path === "/write_report/data_centers") {
          const confirm = window.confirm(
            "데이터센터 목록 편집 페이지로 이동하시겠습니까?"
          );
          if (confirm) {
            this.$router.push({ name: "SelectSasbIndicators" });
          } else {
            return;
          }
        }
  
        this.is_editing = toggle;
        if (!toggle) {
          // 편집한 것 원상복구
          this.temp_toc_list = [...this.toc_list];
        } else {
          this.initToc()
        }
      },
  
  
    },
  };
  </script>
  <style></style>
  
<template>
    <div>
      <h4 class="mb-5">Data Center</h4>
<!-- <pre>
{{ chart_data }}
</pre> -->

    <div
      v-for="(lv1_info, lv1_idx) in toc_list"
      :key="'toc_lv1_' + lv1_idx"
      class="mb-5"
    >

      <h3 class="mb-4">
        {{ lv1_info.name }}

        <span class="gray-500 f-105 fw-400 ml-1">
          {{ lv1_info.name_em ? lv1_info.name_em : lv1_info.name_ea }}
        </span>
      </h3>

      <div
        v-for="(lv2_info, lv2_idx) in lv1_info.children"
        :key="'toc_lv2_' + lv2_idx"
      >
        <h5
          class="fw-600 mb-4 border-bottom-dark pb-2"
        >
          {{ lv2_info.name }}
          <span class="gray-500 f-105 fw-400 ml-1">
            {{ lv2_info.name_em ? lv2_info.name_em : lv2_info.name_ea }}
          </span>
        </h5>


        <b-table-simple responsive class="wri-table">
          <b-thead>
            <b-tr>
              <b-td style="width: 14%">지표</b-td>
              <b-td style="width: 34%">국문</b-td>
              <b-td>번역하기</b-td>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(lv3_info, lv3_index) in lv2_info.children"
              :key="lv3_index"
            >
              <b-td>
                <span class="f-120">
                  {{lv1_info.name}}
                </span>
                <p class="f-100">
                  {{ lv2_info.name }}
                </p>
              </b-td>

              <b-td>
                <!-- ======================================================================================================================================= -->
                <!-- 연결된 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
                
                  <div
                    class="f-90 pb-3"
                    style="
                      word-wrap: break-word;
                      white-space: no-wrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ lv3_info.contents }}
                  </div>

                  <section
                    v-if="lv3_info.type==='table'"
                    class="mt-5 pr-3"
                  >
                    <div>
                      <div class="f-90 mb-2 fw-500">
                        <fa icon="table" class="mr-1"/> 
                        {{ hashed_data_tables[lv3_info.item_id].title }} 
                      </div>

                      <b-table-simple
                        responsive
                        class="bor-bg-table"
                      
                      >
                        <b-thead>
                          <b-tr>
                            <b-td
                              v-for="(property, col_index) in hashed_data_tables[
                                lv3_info.item_id
                              ].header"
                              :key="col_index"
                              >{{ property }}</b-td
                            >
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr
                            v-for="(row_item, row_index) in hashed_data_tables[lv3_info.item_id]
                              .contents"
                            :key="row_item.id"
                          >
                            <template
                              v-for="property in hashed_data_tables[lv3_info.item_id].header"
                            >
                              <b-td
                                v-if="
                                  differentPrevious(
                                    hashed_data_tables[lv3_info.item_id].contents,
                                    row_index,
                                    property,
                                    hashed_data_tables[lv3_info.item_id].header
                                  ) == true
                                "
                                :key="property.id"
                                :rowspan="
                                  getSameLength(
                                    hashed_data_tables[lv3_info.item_id].contents,
                                    row_index,
                                    property,
                                    hashed_data_tables[lv3_info.item_id].header
                                  )
                                "
                              >
                                <!-- {{ getSameLength(hashed_data_tables[lv3_info.item_id].contents, row_index, property, hashed_data_tables[dt].header) }} -->
                                <span v-html="formattedText(row_item[property])">
                                </span>
                              </b-td>
                            </template>
                          </b-tr>
                      </b-tbody>
                      <caption class="mb-2">
                        {{
                          hashed_data_tables[lv3_info.item_id].footnote
                        }}
                      </caption>
                      </b-table-simple>
                    </div>
                  </section>

                  <section
                    v-else-if="lv3_info.type==='chart'"
                  >
                    <ChartTextCanvas 
                        class="mb-5"
                        v-if="lv3_info.item_id !=='' && lv3_info.type ==='chart'"
                        :chart_id="lv3_info.item_id" 
                        :key="`chart_${lv3_info.item_id}`"
                        :viewer_mode="true"
                    />

                  </section>
              </b-td>

              <b-td>
                <TranslateDC
                  :lv1_key = "lv1_info.key"
                  :lv2_key = "lv2_info.key"
                  :lv3_info = "lv3_info"
                />



                <section
                    class="mt-4 pr-3 border-right pt-2"
                    v-if="lv3_info.item_id !=='' && lv3_info.type==='table'"
                >
                    <div
                    class="f-90 mb-2 fw-500 d-flex justify-content-between align-items-start"
                    >
                    <div>
                        <fa icon="table" class="mr-1" />
                        {{ hashed_data_tables[lv3_info.item_id].title_e }}
                    </div>
                    <b-button
                        variant="teal-border"
                        size="sm"
                        class="py-1 px-2"
                        v-b-tooltip.hover
                        title="번역 수정하기"
                        @click.stop.prevent="translateTable(lv3_info.item_id)"
                        ><fa icon="pen"
                    /></b-button>
                    </div>
                    <b-table-simple responsive class="bor-bg-table">
                    <b-thead
                        ><b-tr>
                        <b-td
                            v-for="(
                            property, col_index
                            ) in hashed_data_tables[lv3_info.item_id].header_e"
                            :key="col_index"
                            >{{ property }}</b-td
                        >
                        </b-tr></b-thead
                    >
                    <b-tbody
                        ><b-tr
                        v-for="row_item in hashed_data_tables[lv3_info.item_id]
                            .contents_e"
                        :key="row_item.id"
                        >
                        <b-td
                            v-for="property in hashed_data_tables[lv3_info.item_id]
                            .header_e"
                            :key="property.id"
                            >{{ row_item[property] }}</b-td
                        >
                        </b-tr></b-tbody
                    >
                    </b-table-simple>
                    <div class="f-85 gray-600">
                    {{ hashed_data_tables[lv3_info.item_id].footnote_e }}
                    </div>
                </section>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
        

    </div>
      
      
    </div>
  </template>
  <script>
  import { gri_mixin } from "@/components/writing_report/write_report_subs/gri_mixin";
  import { formattedText } from "@/components/util";
  import { mapState, mapGetters, mapActions } from "vuex";
  import SimpleBusinessCase from "@/components/writing_report/business_case_subs/SimpleBusinessCase";
  import ChartTextCanvas from "@/components/writing_report/chart_subs/ChartTextCanvas.vue";
  import TranslateDC from "@/components/translate/translate_subs/TranslateDC"

  export default {
    mixins: [gri_mixin],
    name: "",
    props: {},
    components: {
      SimpleBusinessCase,
      ChartTextCanvas,
      TranslateDC
    },
    data() {
      return {
        save_state: {},
      };
    },
    computed: {
      ...mapState("report_list", ["report_code"]),
      ...mapState('charts', ['toc_list']),
    },
    beforeCreate() {},
    created() {
      
      
    },
    beforeMount() {},
    mounted() {
      this.getDCToC({"report_code": this.report_code});
      this.getChartList({
          report_code: this.report_code,
          is_editing: false,
      });
    },
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    watch: {
    },
    unmounted() {},
    methods: {
      formattedText,
      ...mapActions("charts", ["getDCToC", "getChartList"]),
      initiateSavingState(tcfd_code) {
        this.save_state = {};
        for (let i = 0; i < this.gri_2_all_contents.length; i++) {
          if (
            this.gri_2_all_contents[i].direct_report != "" &&
            this.gri_2_all_contents[i].direct_report != undefined
          ) {
            this.$set(this.save_state, tcfd_code, "saved");
          } else {
            this.$set(this.save_state, tcfd_code, "");
          }
        }
      },
  
      translateTable(table_id) {
          const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit);
          if (!shouldEdit) return;
          console.log('hekfjlksjefklse')
          this.$emit("translate-table", table_id); //TranslateMaster로 이벤트 보내서 modal open
      },
      
    },
  };
  </script>
  <style></style>
  
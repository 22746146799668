const ansiRegex = require('ansi-regex')
const configOptions = {
    lintOnSave: true,
    // 만약 보고서 빌드가 안되면 이거 주석 풀기
    publicPath: './',
    devServer: {
         overlay: {
            warnings: false,
            errors: true
        },
        disableHostCheck: true,
        public: 'localhost'
    },
    // 하위 폴더에 빌드 시 이거 주석 풀기(안그러면 폰트가 반영 안됨)
    // chainWebpack: config => {
    // config.module
    //     .rule('fonts')
    //     .test(/\.(eot|ttf|woff|woff2)$/)
    //     .use('file-loader')
    //     .loader('file-loader')
    //     .tap(options => {
    //     return {
    //         name: '2023/fonts/[name].[hash:8].[ext]'
    //     };
    //     });
    // },
    pages:{
        index: {
            entry: 'src/pages/editor/main.js',
            // entry:  process.env.VUE_APP_REPORT_CODE === undefined ? 'src/pages/editor/main.js' : 'src/pages/viewer/main.js',
            filename: 'index.html',
            template: 'public/index.html'
        },
        viewer: {
           entry: 'src/pages/viewer/main.js',
           filename: 'v/index.html',
           template: 'public/index.html'
        },
        viewer_theme: {
            entry: 'src/pages/viewer_theme/main.js',
            filename: 'new_theme/index.html',
            template: 'public/index.html'
        },
        viewer_theme_editor: {
            entry: 'src/pages/viewer_theme_editor/main.js',
            filename: 'new_theme_editor/index.html',
            template: 'public/index.html'
        },
        form_gallery: {
            entry: 'src/pages/form_gallery/main.js',
            filename: 'form_gallery/index.html',
            template: 'public/index.html'
         },
        viewer_compact: {
            entry: 'src/pages/viewer_compact/main.js',
            filename: 'g/index.html',
            template: 'public/index.html'
        },
        viewer_sasb: {
            entry: 'src/pages/viewer_sasb/main.js',
            filename: 's/index.html',
            template: 'public/index.html'
        },
        viewer_tcfd: {
            entry: 'src/pages/viewer_tcfd/main.js',
            filename: 't/index.html',
            template: 'public/index.html'
        },
        viewer_assurance: {
            entry: 'src/pages/viewer_assurance/main.js',
            filename: 'a/index.html',
            template: 'public/index.html'
        },
        auth_confirm: {
           entry: 'src/pages/confirm_user/main.js',
           filename: 'confirm_user/index.html',
           template: 'public/index.html'
        }
    },
    transpileDependencies: [ansiRegex],
}
if (process.env.VUE_APP_REPORT_CODE) {
    delete configOptions.pages
}
module.exports = configOptions
export default class {
  constructor(option) {
    this.standards = {
      strict: 'strict',
      loose: 'loose',
      html5: 'html5'
    };
    this.selectArray = []; // select 요소를 저장하는 배열
    this.counter = 0;
    this.settings = {
      standard: this.standards.html5,
      extraHead: '', // head 태그에 추가할 요소들, 쉼표로 구분
      extraCss: '', // 추가할 CSS 링크들, 쉼표로 구분
      popTitle: '', // 타이틀 제목
      endCallback: null, // 인쇄 후 호출되는 콜백 함수
      ids: '' // 부분 인쇄를 위한 ID
    };
    Object.assign(this.settings, option);

    this.init();
  }

  // 초기화 함수
  init() {
    this.counter++;
    this.settings.id = `printArea_${this.counter}`;
    let PrintAreaWindow = this.getPrintWindow(); // iframe 생성
    this.write(PrintAreaWindow.doc); // 내용 작성
    this.print(PrintAreaWindow);
    this.settings.endCallback();
  }

  // 인쇄 함수
  print(PAWindow) {
    console.log('print print')
    let paWindow = PAWindow.win;
    const _loaded = () => {
      paWindow.focus();
      paWindow.print();
      try {
        let box = document.getElementById(this.settings.id);
        let canvasList = this.elsdom.querySelectorAll('.canvasImg')
        console.log(this.elsdom)
        console.log('print chart')
        for (let i = 0; i < canvasList.length; i++) {
          let _parent = canvasList[i].parentNode
          _parent.removeChild(canvasList[i])
        }
        box.parentNode.removeChild(box);
      } catch (e) {
        console.log(e);
      }
    };

    if (window.ActiveXObject) {
      paWindow.onload = _loaded();
      return false;
    }

    paWindow.onload = () => {
      _loaded();
    };
  }

  // 문서 작성 함수
  write(PADocument, $ele) {
    PADocument.open();
    PADocument.write(`${this.docType()}<html lang="ko">${this.getHead()}${this.getBody()}</html>`);
    PADocument.close();
  }

  // DOCTYPE 설정 함수
  docType() {
    return '<!DOCTYPE html>';

    if (this.settings.standard === this.standards.html5) {
      return '<!DOCTYPE html>';
    }
    var transitional = this.settings.standard === this.standards.loose ? ' Transitional' : '';
    var dtd = this.settings.standard === this.standards.loose ? 'loose' : 'strict';

    return `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01${transitional}//EN" "http://www.w3.org/TR/html4/${dtd}.dtd">`;
  }

  // head 태그 생성 함수
  getHead() {
    let extraHead = '';
    let links = '';
    let style = '';
    if (this.settings.extraHead) {
      this.settings.extraHead.replace(/([^,]+)/g, (m) => {
        extraHead += m;
      });
    }
    // 모든 link 태그 복사
    [].forEach.call(document.querySelectorAll('link'), function (item, i) {
      if (item.href.indexOf('.css') >= 0) {
        links += `<link type="text/css" rel="stylesheet" href="${item.href}" >`;
      }
    });

    // style 태그의 스타일 복사
    let domStyle = document.styleSheets;
    if (domStyle && domStyle.length > 0) {
      for (let i = 0; i < domStyle.length; i++) {
        try {
          if (domStyle[i].cssRules || domStyle[i].rules) {
            let rules = domStyle[i].cssRules || domStyle[i].rules;
            for (let b = 0; b < rules.length; b++) {
              style += rules[b].cssText;
            }
          }
        } catch (e) {
          console.log(domStyle[i].href + e);
        }
      }
    }

    if (this.settings.extraCss) {
      this.settings.extraCss.replace(/([^,\s]+)/g, (m) => {
        links += `<link type="text/css" rel="stylesheet" href="${m}">`;
      });
    }

    return `<head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>${this.settings.popTitle}</title>${extraHead}${links}<style type="text/css">${style}</style></head>`;
  }

  // body 태그 생성 함수
  getBody() {
    let ids = this.settings.ids;
    ids = ids.replace(new RegExp("#", "g"), '');
    this.elsdom = this.beforeHanler(document.getElementById(ids));
    let ele = this.getFormData(this.elsdom);
    let htm = ele.outerHTML;
    return '<body>' + htm + '</body>';
  }

  // 노드를 복제하기 전에 수행할 작업
  beforeHanler(elsdom) {
    let canvasList = elsdom.querySelectorAll('canvas');
    // canvas를 png 이미지로 변환
    for (let i = 0; i < canvasList.length; i++) {
      if (true) {
        let _parent = canvasList[i].parentNode
        let _canvasUrl = canvasList[i].toDataURL('image/png')
        let _img = new Image()
        _img.className = 'canvasImg'
        _img.style.display = 'none'
        _img.src = _canvasUrl
        _parent.appendChild(_img)
      }
    }
    return elsdom
  }

  // form 데이터를 처리하는 함수
  getFormData(ele) {
    let copy = ele.cloneNode(true);
    let copiedInputs = copy.querySelectorAll('input,select,textarea');
    let canvasImgList = copy.querySelectorAll('.canvasImg,canvas');
    let selectCount = -1;

    // 모든 canvas 요소 처리
    for (let i = 0; i < canvasImgList.length; i++) {
      let _parent = canvasImgList[i].parentNode
      let item = canvasImgList[i]
      if (item.tagName.toLowerCase() === 'canvas') {
        _parent.removeChild(item)
      } else {
        item.style.display = 'block';
        item.style.width = "100%";
      }
    }

    // 모든 입력 요소 처리
    for (let i = 0; i < copiedInputs.length; i++) {
      let item = copiedInputs[i];
      let typeInput = item.getAttribute('type');
      let copiedInput = copiedInputs[i];

      if (!typeInput) {
        typeInput = item.tagName === 'SELECT' ? 'select' : item.tagName === 'TEXTAREA' ? 'textarea' : '';
      }

      if (item.tagName === 'INPUT') {
        if (typeInput === 'radio' || typeInput === 'checkbox') {
          if (item.checked) {
            copiedInput.setAttribute('checked', item.checked);
          }
        } else {
          copiedInput.value = item.value;
          copiedInput.setAttribute('value', item.value);
        }
      } else if (typeInput === 'select') {
        selectCount++;
        for (let b = 0; b < ele.querySelectorAll('select').length; b++) {
          let select = ele.querySelectorAll('select')[b];
          !select.getAttribute('newbs') && select.setAttribute('newbs', b)
          if (select.getAttribute('newbs') == selectCount) {
            let opSelectedIndex = ele.querySelectorAll('select')[selectCount].selectedIndex;
            item.options[opSelectedIndex].setAttribute('selected', true);
          }
        }
      } else {
        copiedInput.innerHTML = item.value;
        copiedInput.setAttribute('html', item.value);
      }
    }
    
    return copy;
  }

  // 인쇄 창을 생성하는 함수
  getPrintWindow() {
    var f = this.Iframe();
    return {
      f: f,
      win: f.contentWindow || f,
      doc: f.doc
    };
  }

  // iframe을 생성하는 함수
  Iframe() {
    let frameId = this.settings.id;
    let iframe;
    let that = this
    try {
      iframe = document.createElement('iframe');
      document.body.appendChild(iframe);
      iframe.style.border = '0px';
      iframe.style.position = 'absolute';
      iframe.style.width = '0px';
      iframe.style.height = '0px';
      iframe.style.right = '0px';
      iframe.style.top = '0px';
      iframe.setAttribute('id', frameId);
      iframe.setAttribute('src', new Date().getTime());
      iframe.doc = null;
      iframe.doc = iframe.contentDocument ? iframe.contentDocument : (iframe.contentWindow ? iframe.contentWindow.document : iframe.document);
      iframe.onload = function () {
        var win = iframe.contentWindow || iframe;
        that.print(win);
      }
    } catch (e) {
      throw new Error(e + '. iframes may not be supported in this browser.');
    }

    if (iframe.doc == null) {
      throw new Error('Cannot find document.');
    }

    return iframe;
  }
}

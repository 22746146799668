import Vue from 'vue'
import Print from './print_plugin/print'
import axios from "axios";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faBell, faPlus, faEdit, faPrint , faExternalLinkSquareAlt, faInfoCircle, faMinusSquare, faExpand, faMagic, faPen, faWrench,
    faFileDownload, faChevronUp, faTable, faPaperclip, faFileAlt, faCompressAlt, faAngleLeft, faAngleRight, faAngleUp, faSeedling, faLink,
    faTrashAlt, faUnlink, faExclamationCircle, faReply, faEllipsisH, faEllipsisV, faCheck, faCheckCircle, faMapMarkerAlt, faBuilding, faImage,
    faList, faThLarge, faCog, faArrowRight, faArrowDown, faTimes, faSave, faCreditCard, faSearch, faChevronLeft, faChevronRight, faChevronDown, faRedoAlt,
    faQuestionCircle, faCompress, faHourglassHalf, faAward, faBookmark, faPaperPlane, faLocationArrow, faLanguage, faQuestion, faReceipt,
    faFileInvoiceDollar, faEnvelope, faBook,faBookOpen,
    faBold, faItalic, faRemoveFormat, faParagraph, faListUl, faListOl, faRulerHorizontal, faUndo, faRedo, faQuoteLeft, faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight, faCaretDown
} from '@fortawesome/free-solid-svg-icons'
import { faUser as farUser, faBell as farBell, faCopy as farCopy, faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { IconsPlugin } from 'bootstrap-vue'
import router from '../pages/editor/router';

library.add(faUser, faBell, faPlus, faEdit, faPrint, faExternalLinkSquareAlt, faInfoCircle, faMinusSquare, faExpand, faMagic, faPen, faWrench,
  faFileDownload, faChevronUp, faTable, faPaperclip, faFileAlt, faCompressAlt, faAngleLeft, faAngleRight, faAngleUp, faSeedling, faLink,
  faTrashAlt, faUnlink, faExclamationCircle, faReply, faEllipsisH, faEllipsisV, faCheck, faCheckCircle, faMapMarkerAlt, faBuilding, faImage,
  faList, faThLarge, faCog, faArrowRight, faArrowDown, faTimes, faSave, faCreditCard, faSearch, faChevronLeft, faChevronRight, faChevronDown, faRedoAlt,
  faQuestionCircle, faCompress, faHourglassHalf, faAward, faBookmark, faPaperPlane, faLocationArrow, faLanguage, faQuestion, faReceipt,
  faFileInvoiceDollar, faEnvelope, faBook,faBookOpen,
  faBold, faItalic, faRemoveFormat, faParagraph, faListUl, faListOl, faRulerHorizontal, faUndo, faRedo, faQuoteLeft, faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight, faCaretDown
  )
  library.add(farUser, farBell, farCopy, farCheckCircle)
  
Vue.component('fa', FontAwesomeIcon)

Print.install = function(Vue) {
  Vue.directive('print', Print);
};

Vue.use(Print)
Vue.use(IconsPlugin)

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.defaults.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
axios.defaults.headers['Pragma'] = 'no-cache'
axios.defaults.headers['Expires'] = '0'
axios.defaults.headers.common['Authorization']= `Bearer ${localStorage.getItem('access_token')}`

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    // router.push('/error') // 아직 에러가 좀더 잡히고 하는걸로
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)
export default Plugin;



const gri2021_report_view = {
    namespaced: true,
    state: {
        report_structure : [
            //{
            //    category_id: ,
            //    category_name: , 
            //    category_name_e: ,    
            //    topic_id: , 
            //    topic_name: , 
            //    topic_name_e: ,
            //    topic_type: , //일반공개, 특정 주제 공개
            //    topic_type_e: , //Universal Disclosures, Topic-Specific Disclosures
            //    subtopic_code: , 
            //    subtopic_name: , 
            //    subtopic_name_e: , 
            //    gri_code: ,
            //    
            //},
        ],
        report_contents_loaded: false,
        active_code: null
    },
    mutations: {
        update_report_structure(state, new_report_obj){
            state.report_structure = new_report_obj
        },
        update_report_contents_loaded(state, new_status){
            state.report_contents_loaded = new_status
        },
        update_active_contents_list(state, code){
            state.active_code = code
        }
    },
    getters: {
        report_topic_types(state){
            //[{ topic_type: , topic_type_e:  }]
            let unique_topic_types = []
            state.report_structure.forEach( item => {
                if ( unique_topic_types.findIndex( x=> { return x.topic_type == item.topic_type }) < 0){
                    unique_topic_types.push({ topic_type: item.topic_type, topic_type_e: item.topic_type_e })
                }
            })
            return unique_topic_types
        },
        report_categories(state){
            //[{ category_id, category_name, category_name_e }]
            let unique_categories = []
            state.report_structure.forEach( item => {
                if ( unique_categories.findIndex( x => { return x.category_id == item.category_id }) < 0 ) {
                    unique_categories.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e})
                }
            })
            return unique_categories
        },
        report_topic_specific_categories(state){
            //[{ category_id, category_name, category_name_e }]
            let unique_categories = []
            let filtered_structure = state.report_structure.filter(item => item.topic_type_e == 'Topic-specific Disclosures') 
            filtered_structure.forEach( item => {
                if ( unique_categories.findIndex( x => { return x.category_id == item.category_id }) < 0 ) {
                    unique_categories.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e})
                }
            })
            return unique_categories
        },
        report_universal_categories(state){
            //[{ category_id, category_name, category_name_e }]
            let unique_categories = []
            let filtered_structure = state.report_structure.filter(item => item.topic_type_e == 'Universal Disclosures') 
            filtered_structure.forEach( item => {
                if ( unique_categories.findIndex( x => { return x.category_id == item.category_id }) < 0 ) {
                    unique_categories.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e })
                }
            })
            return unique_categories
        },
        report_topics(state){
            //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let unique_topics = []
            state.report_structure.forEach( item => {
                if ( unique_topics.findIndex( x=> { return x.topic_id == item.topic_id }) < 0) {
                    unique_topics.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e, 
                        topic_id: item.topic_id, topic_name: item.topic_name, topic_name_e: item.topic_name_e })
                }
            })
            return unique_topics
        },
        report_subtopics(state){
            //[{ category_id, category_name, category_name_e, subtopic_code, subtopic_name, subtopic_name_e }]
            let unique_subtopics = []
            state.report_structure.forEach( item => {
                if ( unique_subtopics.findIndex( x=> { return x.subtopic_code == item.subtopic_code }) < 0 ){
                    unique_subtopics.push({ category_id: item.category_id, category_name: item.category_name, category_name_e : item.category_name_e, 
                        subtopic_code: item.subtopic_code, subtopic_name: item.subtopic_name, subtopic_name_e: item.subtopic_name_e })
                }
            })
        },
    },
    actions: {
        readReportContents({commit, rootState}, {report_code} ){  

            commit('update_report_contents_loaded', true) //중복소환 방지

            const path = rootState.backend_host + '/read_report_contents'

            return axios.get(path, { params: {
                report_code: report_code
            }})
            .then( response => {
                // console.log(response.data);
                commit('update_report_structure', response.data)
            })
        },
    }
}
export default gri2021_report_view
<template>
<div>
    <h4 id="bs3">보고서 삭제</h4>
    <p class="f-95 mb-4 gray-600">보고서를 삭제합니다.</p>

    <b-card class="general-card mb-5">
        <b-card-header>삭제하기</b-card-header>
        <p class="py-3 mb-4 gray-600">
            보고서 삭제 시 이미 공개된 보고서 또한 모두 삭제됩니다. <br>
            보고서를 삭제할 경우 설정된 사항, 저장된 파일 및 데이터가 모두 사라지며 되돌릴 수 없습니다.
        </p>
        <b-button variant="red" :disabled="check_owner" @click="handleDelete()" class="px-3"> 보고서 삭제하기 </b-button>
    </b-card>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import * as ih from '@/components/util'
import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'

export default {
    mixins: [gri_mixin],
    data() {
        return {
            is_editing: false,
            local_report: {}
        }
    },
    computed:{
        ...mapState(['user_id']),
        ...mapState('report_list', ['report_code']),
        ...mapGetters('report_list', ['selected_report']),
        check_owner(){
            if (this.selected_report.owner_id != this.user_id ){
                return true
            }
            else return false
        }
    },
    methods:{
        ...mapActions('report_list', ['deleteReport']),
        ...mapActions(["updateLicenseInfo"]),
        
        async handleDelete() {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return
            if(confirm('보고서를 삭제하시겠습니까?')==true){                
                this.makeProcessingToast()
                let report_name = this.selected_report.report_name
                await this.deleteReport({ report_code: this.report_code })
                this.updateLicenseInfo();
                this.$EventBus.$emit('make-toast', '보고서 삭제 완료', `${report_name} 보고서가 삭제되었습니다.`)
                this.$router.push({name:'Main'})
            }
        },
        makeProcessingToast(){
            this.$bvToast.toast(`보고서를 삭제하고 있습니다. 잠시만 기다려주세요.`, {
                title: '보고서 삭제중',
                autoHideDelay: 1000,
                appendToast: false,
                toaster: 'b-toaster-bottom-center',
            })
        }     
    },
}
</script>

<style>

</style>
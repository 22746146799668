<template>
<div>
        <router-view name="editor_header"></router-view>

        <b-container fluid class="pb-5 px-0 vh-adjust">
            
            <!-- 작성 진행도 -->
            <!-- <b-progress :max="100" class="sticky-top mb-5 writing-progress">
                <b-progress-bar 
                    v-if="report_contents_loaded==true&&business_case_loaded==true" 
                    :value="attach_loaded==true?writing_progress:0" 
                    :label="attach_loaded==true&&writing_progress>5?`${writing_progress.toFixed(1)}% 작성`:''" 
                    :key="progress_key"
                 />
                <b-progress-bar v-else :value="0" />

            </b-progress> -->
            
            <b-row class="mx-0 px-lg-5">
                <b-col cols="4" md="3" lg="2">
                    <side-nav-writing 
                        class="sticky-top pt-4"
                        :report_type = selected_report.report_type
                    />
                </b-col>
                <b-col class="pt-4">                        
                    <router-view 
                        @add-documents="addDocuments" 
                        @add-tables="addTables" 
                        @add-business-cases="addBusinessCases"
                        @add-charts="addCharts"
                        @add-dc-charts="addDCCharts"
                        @add-dc-tables="addDCTables"
                    />  
                </b-col>
            </b-row>
            <hr class="border-0">             
        </b-container>

        <router-view name="editor_footer"></router-view>        

        <!-- disclosure item modal control -->
        <div v-show="show_item_modal==true" :class="view_item_name=='비즈니스 사례'?'hj-modal-writing-bottom-lg':'hj-modal-writing-bottom'" class="bg-white rounded shadow" :style="low_height==true? 'height:50vh!important':''">
            <div class="hj-modal-header d-flex align-items-center justify-content-between px-4"> {{ view_item_name }}
                <div><b-button variant="icon-light" @click="low_height=!low_height"><fa :icon="low_height==true?'expand' :'compress'"></fa> </b-button><b-button variant="icon-light" @click="hideEditModal"><fa icon="times"></fa> </b-button></div>
            </div> 
            <div class="hj-modal-body px-4 overflow-auto" :style="low_height==true? 'height:calc(50vh - 50px)!important':''">
                
                <!-- 차트 리스트 -->
                
                <!-- 차트 편집 -->

                <!-- 차트 관리 -->



                <tables-list v-if="view_table[0]==true" @edit-table="editTable" @hide-edit="hideEditModal" @table-manage="tableManager"></tables-list>
                <edit-data-table v-else-if="view_table[1]==true" :table_id="table_id" @cancle-edit="cancleEditTable"></edit-data-table>
                <manage-tables v-else-if="view_table[2]==true" :table_id="table_id" @cancle-edit="cancleEditTable"></manage-tables>

                <documents-list v-if="view_document[0]==true" @edit-link="editLink" @hide-edit="hideEditDocument" @document-manage="documentManager"></documents-list>
                <edit-file v-else-if="view_document[1]==true&&document_mode=='docs'" :document_id="document_id" @cancle-edit="cancleEdit"></edit-file>
                <edit-link v-else-if="view_document[1]==true&&document_mode=='link'" :document_id="document_id" @cancle-edit="cancleEdit"></edit-link>
                <manage-document  v-else-if="view_document[2]==true" :document_id="document_id" @cancle-edit="cancleEdit"></manage-document>

                <business-case-list v-if="view_business_case[0]==true" @edit-business-case="editBusinessCase" @hide-edit="hideEditModal" @business-case-manage="businessCaseManager"></business-case-list>
                <edit-business-case v-else-if="view_business_case[1]==true" :business_case_id="business_case_id" @cancle-edit="cancleEditBusinessCase"></edit-business-case>
                <manage-business-case  v-else-if="view_business_case[2]==true" :business_case_id="business_case_id" @cancle-edit="cancleEditBusinessCase"></manage-business-case>

            </div>
        </div>

        <b-button v-b-modal.need-help class="need-help-btn" variant="tealgray" v-b-tooltip.hover title="도움이 필요하신가요?"><fa icon="question"/></b-button>

        <b-modal id="todo-preference" ref="todo-modal" hide-footer size="xl" scrollable >
            <template #modal-header> <h4 class="mt-1">보고서 작성 로드맵</h4></template>
            <todo-roadmap @hide-todo-modal="hideTodoModal"></todo-roadmap>
        </b-modal>

        <b-modal id="need-help" hide-footer size="" title="문의하기" scrollable >
            <need-help></need-help>
        </b-modal>

</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import SideNavWriting from '@/components/writing_report/SideNavWriting'

import DocumentsList from '@/components/writing_report/documents_subs/DocumentsList'
import EditLink from '@/components/writing_report/documents_subs/EditLink'
import EditFile from '@/components/writing_report/documents_subs/EditFile'
import ManageDocument from '@/components/writing_report/documents_subs/ManageDocument'

import TablesList from '@/components/writing_report/data_table_subs/TablesList'
import EditDataTable from '@/components/writing_report/data_table_subs/EditDataTable'
import ManageTables from '@/components/writing_report/data_table_subs/ManageTables'

import BusinessCaseList from '@/components/writing_report/business_case_subs/BusinessCaseList'
import EditBusinessCase from '@/components/writing_report/business_case_subs/EditBusinessCase'
import ManageBusinessCase from '@/components/writing_report/business_case_subs/ManageBusinessCase'

import * as ih from '@/components/util'

export default {
    data() {
        return {
            document_id: '',
            table_id: '',
            business_case_id : '',
            view_document: [false, false, false],
            view_table: [false, false, false],
            dc_table: [false, false, false],
            dc_chart: [false, false, false],
            view_business_case: [false, false, false],
            progress_key:10,

            show_item_modal : false,
            low_height: false,

            view_item_name: ''
        }
    },
   computed:{
        ...mapState('report_list', ['report_code']),
        ...mapGetters('report_list', ['selected_report']),
        ...mapState('report_contents', ['report_contents','report_contents_loaded', 'attach_loaded']),
        ...mapState('cursor', ['editing_gri_code','document_mode']),
        ...mapGetters('report_contents', ['writing_progress']),
        ...mapState('business_cases', ['business_case_loaded']),
    },
    methods:{
        ...mapMutations('cursor', ['update_editing_gri_code','change_document_mode']),
        ...mapMutations('report_contents', ['update_report_contents_loaded', 'update_attach_loaded']),
        ...mapActions("charts", ["getDCToC", "getChartList"]),
        ...mapActions('report_contents', ['readReportContents']),
        ...mapActions("data_tables", ["readDataTableList"]),
        ...mapActions('report_list', ['saveReportPreference', 'update_view_mode']),
        ...mapActions("documents", ["readDocumentList"]),
        ...mapActions("business_cases", ["readBusinessCaseList"]),
        ...mapState('business_cases', ['update_business_case_loaded']),
        ...mapActions("report_indicators", [
            "readReportTopics",
            "readReportIndicators",
        ]),
        hideEditModal(){
            this.update_editing_gri_code('')
            this.show_item_modal = false
            this.low_height = false
            this.initiateViewTable()
            this.initiateViewBusinessCase()
            this.view_item_name = ''
        },
        addCharts(event_table_id) {
            if(event_table_id){
                this.editTable(event_table_id)
            }
            else { this.view_chart = [true, false, false] }
            this.initiateViewDocument()
            this.initiateViewBusinessCase()
            this.show_item_modal = true
            this.view_item_name = '차트'
        },


        editCharts(){

        },
        cancleEditCharts(){

        },
        chartsManager(){

        },
        initiateViewCharts(){

        },

        addDCCharts() {
            if(event_table_id){
                this.table_id = event_table_id
                this.view_table = [false, true, false]
            }
            else {
                this.dc_chart = [true, false, false]
            }
            this.initiateViewDocument()
            this.initiateViewBusinessCase()
            this.show_item_modal = true
            this.view_item_name = '데이터 테이블' 
        },

        addDCTables(event_table_id) {
            if(event_table_id){
                this.table_id = event_table_id
                this.view_table = [false, true, false]
            }
            else {
                this.dc_table = [true, false, false]
            }
            this.initiateViewDocument()
            this.initiateViewBusinessCase()
            this.show_item_modal = true
            this.view_item_name = '데이터 테이블'
        },


        // table edit controls
        addTables(event_table_id){
            if(event_table_id){
                this.editTable(event_table_id)
            }
            else { this.view_table = [true, false, false] }
            this.initiateViewDocument()
            this.initiateViewBusinessCase()
            this.show_item_modal = true
            this.view_item_name = '데이터 테이블'
        },
        editTable(table_id){
            this.table_id = table_id
            this.view_table = [false, true, false]
        },        
        cancleEditTable(hide_all){
            if (hide_all){
                this.hideEditModal()
            }else this.view_table = [true, false, false] 
        },
        tableManager(table_id){
            this.table_id = table_id
            this.view_table = [false, false, true]
        },
        initiateViewTable(){
            this.view_table = [false, false, false]
        },
        // document and link edit controls
        // document and link edit controls
        addDocuments(event_doc_id){
            if(event_doc_id){
                this.editLink(event_doc_id)
            }
            else { this.view_document = [true, false, false] }
            this.initiateViewTable()
            this.initiateViewBusinessCase()
            this.view_item_name = '문서 및 링크'
            this.show_item_modal = true            
        },
        hideEditDocument(){
            this.change_document_mode('docs')
            this.initiateViewDocument()
            this.hideEditModal()            
        },
        editLink(document_id){
            this.document_id = document_id
            this.view_document = [false, true, false]
        },        
        cancleEdit(hide_all, hash){
            if(hide_all=='hide_all'){
                this.hideEditDocument()
                // if(hash){
                //     console.log('anchorGo합니다.')
                //     ih.anchorGo(hash)
                // } 
            }else this.view_document = [true, false, false]     
        },
        documentManager(document_id){
            this.document_id = document_id
            this.view_document = [false, false, true]
        },
        initiateViewDocument(){
            this.view_document = [false, false, false]
        },
        // business case controls
        // business case controls
        addBusinessCases(event_bc_id){
            if(event_bc_id){
                this.business_case_id = ''
                this.editBusinessCase(event_bc_id)
            }
            else ( this.view_business_case = [true, false, false])
            this.initiateViewTable()
            this.initiateViewDocument()
            this.view_item_name = '비즈니스 사례'
            this.show_item_modal = true
        },
        editBusinessCase(business_case_id){
            // console.log(business_case_id);
            this.business_case_id = business_case_id
            setTimeout(()=>{
                this.view_business_case = [false, true, false]
            },200)
        },        
        cancleEditBusinessCase(hide_all){
            if(hide_all=='hide_all'){
                this.initiateViewBusinessCase()
                this.hideEditModal()
                // if(hash){
                //     console.log('anchorGo합니다.')
                //     ih.anchorGo(hash)
                // } 
            }else this.view_business_case = [true, false, false]   
        },
        businessCaseManager(business_case_id){
            this.business_case_id = business_case_id
            this.view_business_case = [false, false, true]
        },
        initiateViewBusinessCase(){
            this.business_case_id = ''
            this.view_business_case = [false, false, false]
        },
        // Todo Roadmap 관련 설정
        // Todo Roadmap 관련 설정
        setTodoVisible (){
            if (localStorage) {
                let str_key = 'hide_todo_'+this.report_code
                let hide_todo_until = new Date(localStorage.getItem(str_key));
                if (hide_todo_until < new Date()) { // expire 시간이 지남 
                    return true
                }
                else return false  // 아직 expire 시간이 안지남 
            }
            return true;
        }, 
        showTodoModal(){
            if (this.setTodoVisible() == true){
                setTimeout(() => {
                    this.$refs['todo-modal'].show()
                }, 200)
            }
        },
        hideTodoModal(){
            this.$refs['todo-modal'].hide()
        }
    },
    beforeDestroy(){
        this.view_document = [true, false, false]
        this.view_table = [true, false, false]
        this.view_business_case = [true, false, false]
        this.view_chart = [true, false, false]
        this.update_report_contents_loaded(false)
        this.update_business_case_loaded(false)
        this.update_attach_loaded(false)
    },
    created(){
    },
    mounted() {
        this.update_view_mode('editor')

        if (this.selected_report.data_center) {
            this.getDCToC({ "report_code": this.report_code });
        }
        this.getChartList({
            report_code: this.report_code,
            is_editing: true,
        });

        this.readDataTableList({ report_code: this.report_code });
        this.readDocumentList({ report_code: this.report_code });
        this.readBusinessCaseList({ report_code: this.report_code });
        this.readReportTopics({ report_code: this.report_code });
        this.readReportIndicators({ report_code: this.report_code });

        this.readReportContents({
          report_code: this.report_code,
          editor_mode: true,
        });
        // if(this.selected_report.state==2){
        //     this.showTodoModal()
        // }
        // this.progress_key += 3
    },
    components:{
        SideNavWriting,
        DocumentsList,
        EditLink,
        EditFile,
        ManageDocument,
        TablesList,
        EditDataTable,
        ManageTables,
        BusinessCaseList,
        EditBusinessCase,
        ManageBusinessCase,
    },
    watch:{
        attach_loaded:{
            immediate:true,
            handler(newVal){
                if(newVal==true){
                    this.progress_key+=1
                }
            }
        }
        // writing_progress:{
        //     immediate: true,
        //     handler(newV){
        //         if(this.selected_report.state<=1 && newV>0){
        //             this.saveReportPreference({report_code: this.report_code, state: 2})
        //         }
        //     }
        // }
    }
}
</script>

<style>
.progress.writing-progress {
    border-radius: 0;
    background-color: var(--gray-300);
    z-index: 1015;
}
.progress.writing-progress > .progress-bar {
    background-color: var(--teal);
}
</style>
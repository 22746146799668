<template>
<div>
    <!-- <h6 class="mb-3 pb-1 border-bottom-gray-550"><b-badge variant="basic" class="mr-2">Case.</b-badge> {{ business_case.title }}</h6> -->
    <template v-if="eng_mode==false">
        <div class="bc-simple-content pb-4" v-html="business_case.description" ></div>
        <div v-if="business_case_id!=''&&business_case.files.length>0" class="f-95 gray-600 mb-45"> 첨부파일 : 
            <span v-for="item in business_case.files" :key="item.id" class="mr-3"> 
                <a :href="$store.state.static_host+item.file_path" target="_blank">{{item.file_name}}</a> 
            </span>
        </div>
    </template>
    <template v-else>
        <div class="bc-simple-content pb-4" v-html="business_case_e.description_e" ></div>
        <div v-if="business_case_id!=''&&business_case.files_e.length>0" class="f-95 gray-600 mb-45"> Attatched File : 
            <span v-for="item in business_case_e.files_e" :key="item.id" class="mr-3"> 
                <a :href="$store.state.static_host+item.file_path" target="_blank">{{item.file_name}}</a> 
            </span>
        </div>
    </template>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

export default {
    data() {
        return {
            business_case: { files: [], files_e: []  }, // length check에서 나는 에러를 방지하기 위해 넣어둠
            business_case_e: {}
        }
    },
    props:{
        business_case_id: {default: ''},
        eng_mode: {type: Boolean, default: false},
    },
    computed:{
        ...mapState('business_cases', ['business_case_list']),
        ...mapGetters('business_cases', ['business_case_list_e']),
       
    },
    methods:{
        findBusinessCase(){
            let idx = this.business_case_list.findIndex(x => x.business_case_id == this.business_case_id )
            if (idx>-1){
                this.business_case = this.business_case_list[idx]
            }
        },
        findBusinessCaseE(){
            let idx = this.business_case_list_e.findIndex(x => x.business_case_id == this.business_case_id )
            if (idx>-1){
                this.business_case_e = this.business_case_list_e[idx]
            }
        }
    },
    mounted(){
        // console.log(this.business_case_id);
        // viewr에서 부를 때 조건 설정을 위해 일단 국영문 둘다 읽어둠
        this.findBusinessCase()
        this.findBusinessCaseE()        

    },
    beforeDestroy(){
        // console.log(this.business_case_id);
        this.business_case.files = []
    }

}
</script>

<style scoped>
.bc-simple-content {
    white-space: normal;
}
.bc-simple-content >>> img{
    max-width: 100%;
}
.bc-simple-content >>> table > tbody > tr > td,
.bc-simple-content >>> table > tbody > tr > th{
    padding: 0.25rem 0.5rem;
    border-top: 1px solid var(--gray-400);
    border-left: 1px solid var(--gray-400);
}
.bc-simple-content >>> table > tbody > tr:last-child > td{
    border-bottom: 1px solid var(--gray-400);
}
.bc-simple-content >>> table > tbody > tr > th:last-child,
.bc-simple-content >>> table > tbody > tr > td:last-child{
    border-right: 1px solid var(--gray-400);
}
.bc-simple-content >>> table > tbody > tr > th{
    background-color: var(--gray-300);
}
.bc-simple-content >>> table > tbody > tr > td{
    background-color: white;
}
.bc-simple-content >>> table > tbody > tr > td> p{
    margin-bottom: 0;
}
.bc-simple-content >>> h3{
    font-size: 1.25rem;
}
.bc-simple-content >>> h2{
    font-size: 1.5rem;
}
.bc-simple-content >>> h1{
    font-size: 1.75rem;
}
.bc-simple-content >>> ol, 
.bc-simple-content >>> ul{   
    padding-inline-start: 20px;
}

.bc-simple-content >>> ul>li>p{
    margin-bottom: 0;
}

</style>
//USER 단위의 billing을 읽어와서, 저장하고 있는 Store

const billings = {
    namespaced: true, 
    state: {
        billing_list: [
            // {
            //     "index": 0,
            //     "report_code": "voiwj201125",
            //     "billing_id": 1,
            //     "service_type": "고품질번역",
            //     "assurance_contract_id": null,
            //     "start_date": "2021-01-04",
            //     "end_date": "2021-01-30",
            //     "service_cost": null,
            //     "payment": null,
            //     "tax_info_id": 1,
            //     "status": null,
            //     "invoice_contents": null,
            //     "time_stamp": "2021-01-04 17:10:16",
            //     "tax_name": "김혜주",
            //     "tax_email": "hjkim@qesg.co.kr",
            //     "tax_tel": "010-2313-4884",
            //     "br_name": null,
            //     "br_path": null,
            //     "assurance_id": null,
            //     "assurance_signed": null,
            //     "assurance_time_stamp": "None"
            // },
        ],
        billing_list_loaded: false
    },
    mutations: {
        update_billing_list(state, new_billing_list){
            state.billing_list = new_billing_list
        },
        update_billing_list_loaded(state, new_flag){
            state.billing_list_loaded = new_flag
        },
        add_or_update_billing(state, new_billing_obj ){
            let temp_list = [ ...state.billing_list ]
            let idx = temp_list.findIndex(item => item.billing_id == new_billing_obj.billing_id )
            if( idx > -1 ){
                temp_list[idx] = new_billing_obj
            }
            else{
                temp_list.push(new_billing_obj)
            }
            //state 인식
            state.billing_list = temp_list
        },
        delete_billing(state, billing_id){
            let idx = state.billing_list.findIndex(item => item.billing_id == billing_id)
            if( idx > -1){
                state.billing_list.splice(idx, 1)
            }
        }
    },
    getters:{

    },
    actions:{
        readBillingList({commit, rootState}, {user_id} ){
            const path = rootState.backend_host + '/read_billing_list'

            return axios.get(path, { params: {
                user_id: user_id
            }})
            .then( response => {
                let new_billing_list = response.data
                commit('update_billing_list', new_billing_list)
                commit('update_billing_list_loaded', true)
            })
        },
        saveBilling({commit, rootState}, { report_code, service_type, assurance_contract_id, start_date, end_date, revenue, service_cost, payment, tax_info_id, status, invoice_contents }){
            const path = rootState.backend_host + '/save_billing'

            let formData = new FormData()
            formData.append('report_code', report_code)
            if(service_type != null && service_type != undefined) formData.append('service_type', service_type)
            if(assurance_contract_id != null && assurance_contract_id != undefined) formData.append('assurance_contract_id', assurance_contract_id)
            if(start_date != null && start_date != undefined) formData.append('start_date', start_date)
            if(end_date != null && end_date != undefined) formData.append('end_date', end_date)
            if(revenue != null && revenue != undefined) formData.append('revenue', revenue)
            if(service_cost != null && service_cost != undefined) formData.append('service_cost', service_cost)
            if(payment != null && payment != undefined) formData.append('pyament', payment)
            if(tax_info_id != null && tax_info_id != undefined) formData.append('tax_info_id', tax_info_id)
            if(status != null && status != undefined) formData.append('status', status)
            if(invoice_contents != null && invoice_contents != undefined) formData.append('invoice_contents', invoice_contents)
        
            return axios.post(path, formData)
            .then( response => {
                console.log(response.data)
                commit('add_or_update_billing', response.data )  
            })
        }
    }
}
export default billings
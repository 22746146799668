<template>
  <div>
    <b-row>
      <b-col cols="2">
        <SasbSideNavBtn class="sticky-top pt-4" />
      </b-col>
      <b-col class="pt-4">
        <b-overlay 
          :show="loadingShow" 
          opacity="0.6"
          no-wrap 
        />
        <h4>SASB 보고 산업 선택하기(복수선택)</h4>
        <b-card class="bg-tealgray200 border-0 mb-5">
          <div>👉 산업명으로 필터링</div>
          <b-row class="mb-3" no-gutters>
            <b-col cols="10">
              <b-form-input
                v-model="keyword"
                @change="fetchSectorIndustryList"
                placeholder="부문, 산업을 검색하세요."
              ></b-form-input>
            </b-col>
            <b-col>
              <b-button @click="fetchSectorIndustryList">검색 (Enter)</b-button>
            </b-col>
          </b-row>
          참고) 상장사인 경우 <a href="https://sasb.org/find-your-industry/" target="_blank">SASB - SICS Finding My Industry</a>에서 공식적인 산업 적용
          기준을 검색하실 수 있습니다. <br />
        </b-card>
        
        <b-row>
          <b-col class="col-12 col-md-6 col-lg-4" v-for="(type, idx) in sector_list" :item="type" :key="idx">
            <SasbSectorSelector
              :id="`sasb-section-${type.id}`"
              :sasb_sector_id="type.id"
              :sasb_sector_name="type.sasb_sector_name"
              :sasb_sector_name_e="type.sasb_sector_name_e"
              :industries="type.industries"
            />
          </b-col>
        </b-row>
       
      </b-col>
    </b-row>
    <hr class="border-0" />
  </div>
</template>

<script>
import SasbSideNavBtn from "@/components/managing_indicators/sasb_2023/SasbSideNavBtn";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import SasbSectorSelector from "@/components/managing_indicators/sasb_2023/sasb_selector/SasbSectorSelector.vue";
import SasbIndustrySelector from "@/components/managing_indicators/sasb_2023/sasb_selector/SasbIndustrySelector.vue";

export default {
  data() {
    return {
      keyword: "",
      sector_list: null,
      loadingShow: false,
    };
  },
  props: {
    is_recommended: { type: Boolean, default: false }, // 추천받아서 온 건지, 추천없이 바로 선택하기로 온 건지 확인
  },
  computed: {
    ...mapState("report_indicators", ["indicators_list", "topics_list"]),
    ...mapState("report_list", ["report_code", "standard_mode"]),
    ...mapGetters("report_list", ["selected_report", "gri_ver", "tcfd_ver"]),
  },
  methods: {
    ...mapActions("report_indicators", [
      "readReportTopics",
      "readReportIndicators",
    ]),
    // ...mapMutations('report_indicators', ['update_report_code']),

    async fetchSectorIndustryList() {
      this.loadingShow = true;
      const response = await axios.get(
        `${this.$store.state.backend_host}/sasb/get_sector_list?keyword=${this.keyword}&rc=${this.report_code}`
      );

      this.sector_list = response.data.result;
      this.loadingShow = false;
    },
  },
  components: {
    SasbSideNavBtn,
    SasbSectorSelector,
    SasbIndustrySelector,
  },
  created() {
    this.fetchSectorIndustryList();
  },
};
</script>

<style></style>

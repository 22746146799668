<template>
    <div>
        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-group id="payer-email" label="사용자 아이디" label-for="input-2">
                <b-input-group class="mb-2">
                    <b-form-input placeholder="사용자 아이디" v-model="form.payer_email" @keyup.enter="handleSendOwnership()" @input="onInputStopped" required></b-form-input>
                    
                </b-input-group>

                <b-form-select @change="resetSearchList" v-if="search_user_list.length > 0" v-model="form.payer_email" :options="search_user_list" :select-size="search_user_list.length < 10 ? search_user_list.length+1 : 10">
                </b-form-select>
                
            </b-form-group>

            <b-form-group id="product-name" label="상품명:" label-for="input-2">
                <b-form-input
                id="input-2"
                v-model="form.product_name"
                placeholder="상품명"
                required
                ></b-form-input>
            </b-form-group>

            <b-form-group id="product-price" label="상품 가격(부가세 제외):" label-for="input-2">
                <b-form-input
                id="input-2"
                v-model="form.product_price"
                placeholder="상품가격(부가세 제외)"
                type="number"
                required
                ></b-form-input>
            </b-form-group>


            <b-button type="submit" variant="primary">상품 생성</b-button>
            <b-button class="ml-2" type="reset" variant="danger">내용 리셋</b-button>
            </b-form>
            <b-card class="mt-3" header="Form Data Result">
            <pre class="m-0">{{ form }}</pre>
            </b-card>


    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    payer_email: '',
    props: {},
    components: {},
    created() {
        this.$set(this.form, 'admin_email', this.e_mail);
    },
    computed: {
        ...mapState(['e_mail']),

    },
    data() {
        return {
            search_user_list: [],
            form: {
                payer_email: '',
                product_name: '',
                product_price: '',
            },
            show: true
    }
},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() { },
   
    methods: {
        async onSubmit(event) {
            event.preventDefault()
            console.log('on submit')
            this.form.product_price = Number(this.form.product_price)
            alert(JSON.stringify(this.form))

            try {
                const response = await axios.post(
                    this.$store.state.backend_host + "/payment/general_product",
                    this.form
                )
        
                if (response) {
                    if (response.data && response.data.code) {
                        window.alert(response.data.msg);
                        window.location.reload(true);
                    } else {
                        window.alert(response.data.msg);
                    }

                }
                
            } catch (error) {
                window.alert(error)
                if (error.response) {
                    window.alert(error.response.data?.msg)
                }
            }

        },
        onReset(event=null) {
            if (event) {
                event.preventDefault()
            }
            // Reset our form values
            this.form.payer_email = ''
            this.form.product_name = ''
            this.form.product_price = ''
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        },

        onInputStopped() {
            // 이전에 설정한 타이머가 있다면 제거하여 중복 호출을 방지합니다.
            if (this.inputTimer) {
                clearTimeout(this.inputTimer);
            }
            // 500ms(0.5초) 후에 입력이 멈췄을 때 이벤트를 발생시킵니다.
            this.inputTimer = setTimeout(() => {
                this.inputTimer = null; // 타이머를 제거하고 초기화합니다.
                this.showAvailableUser();
            }, 500);
        },

        showAvailableUser() {
            const path = this.$store.state.backend_host + '/report/searchAvailableForOwnership?q='+ this.form.payer_email
            axios.get(path)
            .then(response => {
                this.search_user_list = response.data

            })
            .catch(error => {
                console.log(error)
            }
                
            )

        },
        resetSearchList() {
            this.search_user_list = []
        }
    }
}
</script>
<style>
</style>
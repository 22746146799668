<template>
    <div>
      <div class = "mb-5">
        <h3 class="mb-4">
          <b-skeleton class="mt-4 mb-3" width="30%"></b-skeleton>
        </h3>
        <h3 class="mt-5">
          <b-skeleton class="mt-4 mb-3" width="20%"></b-skeleton>
        </h3>
        <h5 class="fw-500 mb-4 border-bottom-dark pb-2">
          <b-skeleton class="mt-4 mb-3" width="30%"></b-skeleton>
        </h5>
        <b-table-simple responsive class="wri-table mb-5">
            <b-thead>
              <b-tr>
                <b-td>지표</b-td>
                <b-td style="width: 50%">
                  <div class="d-inline-block float-right mx-2 f-90 green">
                    <fa icon="check-circle" class="ml-1" />
                  </div>
                </b-td>
                <b-td style="width: 35%"><b-skeleton width="30%"></b-skeleton></b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>
                  <p class="f-110 pb-3 fw-600"><b-skeleton></b-skeleton></p>
                  <span class="f-95 gray-500"><b-skeleton width="70%"></b-skeleton></span>
                </b-td>
                <b-td>
                  <div class="mb-3" >
                    <div
                        class="gray-500 rounded pb-4"
                    >
                      <b-skeleton width="70%"></b-skeleton>
                      <b-skeleton width="20%"></b-skeleton>
                      <b-skeleton width="10%"></b-skeleton>
                    </div>
                  </div>
                </b-td>
                <b-td class="bgray-100" style="padding-left: 0.75rem">
                  <div class="d-flex justify-content-between f-90 mb-2">
                  </div>
                  <div class="bg-white px-2 py-2">
                    <b-skeleton></b-skeleton>
                  </div>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>
                  <p class="f-110 pb-3 fw-600"><b-skeleton></b-skeleton></p>
                  <span class="f-95 gray-500"><b-skeleton width="70%"></b-skeleton></span>
                </b-td>
                <b-td>
                  <div class="mb-3" >
                    <div
                        class="gray-500 rounded pb-4"
                    >
                      <b-skeleton width="50%"></b-skeleton>
                      <b-skeleton width="30%"></b-skeleton>
                      <b-skeleton width="70%"></b-skeleton>
                    </div>
                  </div>
                </b-td>
                <b-td class="bgray-100" style="padding-left: 0.75rem">
                  <div class="d-flex justify-content-between f-90 mb-2">
                  </div>
                  <div class="bg-white px-2 py-2">
                    <b-skeleton width="40%"></b-skeleton>
                  </div>
                </b-td>
              </b-tr>


            </b-tbody>
          </b-table-simple>
      </div>

    </div>
</template>
<script>
export default {
    name: '',
    props: {},
    components: {},
    data() {
        return {
            example: '',
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {}
}
</script>
<style>
</style>
<template>
  <b-card style="display: flex;">
    <b-overlay :show="loadingShow" no-wrap></b-overlay>

    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <h4>정기결제 쿠폰</h4>
      <b-form-group id="input-group-3" label="쿠폰종류:" label-for="input-3">
        <b-form-select
          id="input-3"
          v-model="form.subscriptionService"
          :options="subcriptionServiceTypes"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-1"
        label="발급자 이메일:"
        label-for="input-1"
        description="쿠폰 발급하시는 담당자님의 이메일을 적어주세요."
      >
        <b-form-input
          id="input-1"
          v-model="form.authorizerEmail"
          type="email"
          :placeholder="this.e_mail"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        label="쿠폰 코드명:"
        label-for="input-2"
        type="text"
        description="쿠폰명은 최대 300개의 문자열을 저장 가능합니다. 늘리고 싶다면 문의해주세요. 중복도 안됩니다. 중복여부는 일단 입력하면 나옵니다."
      >
        <b-form-input
          id="input-2"
          v-model="form.discountCode"
          placeholder="발급하려는 쿠폰명을 적어주세요."
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-4"
        label="가격:"
        label-for="input-4"
        description="정기결제의 경우, 결제일마다 이뤄지는 가격을 적어주세요. 예를 들어 한달 정기결제에 가격을 10만원을 한다면 한달마다 10만원의 결제가 이뤄지며, 1년 정기결제의 경우 1년마다 10만원의 결제가 이뤄집니다."
      >
        <b-form-input
          id="input-4"
          v-model="form.discount_price"
          placeholder="해당 서비스의 가격을 적어주세요"
          type="number"
          min="0"
          step="10"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-5"
        label="사용횟수:"
        label-for="input-5"
        type="text"
        description="쿠폰을 사용하게 할 횟수를 정해주세요. 예를 들어 3을 입력하면 월 정기결제서비스에 3개월 간 할인이 적용됩니다. 연 정기결제서비스에 적용하면 3년간 할인이 적용됩니다."
      >
        <b-form-input
          id="input-5"
          v-model="form.valid_cnt"
          placeholder="쿠폰 사용 횟수"
          type="number"
          min="1"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-5"
        label="기타:"
        label-for="input-5"
        type="text"
        description="기타 사항을 입력해주세요. (사용 용도, 등등)"
      >
        <b-form-input
          id="input-5"
          v-model="form.description"
          placeholder="기타"
          type="text"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">생성</b-button>
      <b-button type="reset" variant="danger">초기화</b-button>
    </b-form>
    <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card>
  </b-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      form: {
        authorizerEmail: this.e_mail,
        discountCode: "",
        subscriptionService: null,
        valid_cnt: "1",
        discount_price: "0",
        description: ""
      },
      subcriptionServiceTypes: [
        { text: "할인을 적용할 정기결제 상품을 골라주세요", value: null },
      ],
      show: true,
      isToggled: false,

      loadingShow: false,
    };
  },
  computed: {
    ...mapState(["e_mail"]),

    buttonIcon() {
      return this.isToggled ? "toggle-on" : "toggle-off";
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.fetchRegularServicePriceTable();
  },
  methods: {
    fetchRegularServicePriceTable() {
      axios
        .get(
          this.$store.state.backend_host +
            "/payment/get_subscription_info_table"
        )
        .then((response) => {
          // subcriptionServiceTypes

          if (response.data) {
            const subscription_service_table = response.data;
            Object.keys(subscription_service_table).map((key) => {
              this.subcriptionServiceTypes.push({
                text: `${subscription_service_table[key]?.service_name} ${subscription_service_table[key]?.service_fee}원`,
                value: key,
              });
            });
          }

          this.loadingShow = false;
          this.priceTable = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async onSubmit(event) {
      event.preventDefault();
      this.form.authorizerEmail = this.e_mail;

      alert(JSON.stringify(this.form));

      this.loadingShow = true;
      try {
        const response = await axios.post(
            this.$store.state.backend_host + "/payment/make_subscription_coupon",
            JSON.stringify(this.form),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
        )
  
        if (response) {
          this.loadingShow = false;
            if (response.data && response.data.code) {
              window.alert(response.data.message);
              window.location.reload(true);
            } else {
              window.alert(response.data.message);
            }
        }
        
      } catch (e) {
        window.alert(e)
      }
        
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.discountCode = "";
      this.form.subscriptionService = null;
      this.form.valid_cnt = 1;
      this.form.authorizerEmail = this.e_mail;
      this.form.description = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

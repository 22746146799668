<template>
<div>
    <div class="bg-tealgray600 pt-5">
        <b-container>
            <div class="d-flex justify-content-between align-items-end pb-2" style="height:66px;">
                <div class="gray-400"> 
                    <b-button v-if="show_admin_menu==true" @click="pageGo({name:'Main'})" variant="icon-light" class="py-1 px-2">ESG Reporting Tool </b-button> 
                    <b-button v-else @click="pageGo({name:'AllReports'})" variant="icon-light" class="py-1 px-2">전체 보고서 </b-button> 
                    <h5 class="mb-0 pl-2 d-inline fw-400 " > Admin <span class="gray-100" v-if="selected_report"> <fa icon="angle-right" class="mx-2 gray-400"/> {{selected_report.report_name}} </span></h5>
                </div>
                <div class="d-flex align-items-end">
                    <b-button variant="icon-light" class="py-1 px-2" id="notice" @click.stop="showMyNotice()"><fa :icon="['far','bell']"/></b-button>
                    <b-button variant="icon-light" class="py-1 px-2 ml-2" id="my-info" @click.stop="showMyInfo()"><fa :icon="['far','user']"/></b-button>
                    <!-- <b-button v-if="is_admin==4" :variant="show_admin_menu==true? 'half-bg-light':'half-bg-light'" class="py-1 px-2 ml-2" @click="$router.push({name: 'Main'})">Landing Page</b-button> -->
                    <b-button variant="icon-light" class="py-1 px-2 ml-2" @click="handleLogout()">로그아웃</b-button>
                </div>
            </div>
        </b-container>

        <b-container id="header-menu" class="bg-white radious-top">        
            <div v-if="show_admin_menu==true" class="py-3">
                <b-button class="p-1 mr-3 f-105" :class="menu_active[0]==true?'fw-500':''" :variant="menu_active[0]==true?'icon':'icon-gray'" @click="pageGo({name: 'AllReports'})"> 전체 보고서 </b-button>
                <b-button class="p-1 mr-3 f-105" :class="menu_active[1]==true?'fw-500':''" :variant="menu_active[1]==true?'icon':'icon-gray'" @click="pageGo({name: 'UserManagement'})"> 사용자 관리 </b-button>
                <b-button class="p-1 mr-3 f-105" :class="menu_active[2]==true?'fw-500':''" :variant="menu_active[2]==true?'icon':'icon-gray'" @click="pageGo({name: 'NoticeManagement'})"> 공지 관리 </b-button>
                <b-button class="p-1 mr-3 f-105" :class="menu_active[3]==true?'fw-500':''" :variant="menu_active[3]==true?'icon':'icon-gray'" @click="pageGo({name: 'SubscriptionPaymentManagement'})"> 정기 결제 관리 </b-button>
                <b-button class="p-1 mr-3 f-105" :class="menu_active[4]==true?'fw-500':''" :variant="menu_active[4]==true?'icon':'icon-gray'" @click="pageGo({name: 'ManageLicense'})"> 라이센스 관리</b-button>
                <b-button class="p-1 mr-3 f-105" :class="menu_active[5]==true?'fw-500':''" :variant="menu_active[5]==true?'icon':'icon-gray'" @click="pageGo({name: 'ManageCoupon'})"> 쿠폰 관리</b-button>
                <b-button class="p-1 mr-3 f-105" :class="menu_active[6]==true?'fw-500':''" :variant="menu_active[6]==true?'icon':'icon-gray'" @click="pageGo({name: 'ManageIndicator'})"> 지표 관리</b-button>
                <b-button class="p-1 mr-3 f-105" :class="menu_active[7]==true?'fw-500':''" :variant="menu_active[7]==true?'icon':'icon-gray'" @click="pageGo({name: 'CustomProductManagement'})"> 커스텀 상품 관리</b-button>

            </div>
            <div v-else class="py-3">
                <!-- <b-button class="p-1 mr-3 f-105" :class="sub_menu_active[0]==true?'fw-500':''" :variant="sub_menu_active[0]==true?'icon':'icon-gray'" @click="pageGo({name: 'OnlineAssurance'})"> 서면 검토 </b-button> -->
                <b-button class="p-1 mr-3 f-105" :class="sub_menu_active[1]==true?'fw-500':''" :variant="sub_menu_active[1]==true?'icon':'icon-gray'" @click="pageGo({name: 'WriteStatement'})"> 검증의견서 작성 </b-button>
                <b-button class="p-1 mr-3 f-105" :class="sub_menu_active[2]==true?'fw-500':''" :variant="sub_menu_active[2]==true?'icon':'icon-gray'" @click="pageGo({name: 'TranslateStatement'})"> 검증의견서 수동 번역 </b-button>
            </div>

            <!-- <b-tooltip target="notice" triggers="hover"> 새 알림이 있습니다. </b-tooltip> -->
            <!-- <b-tooltip target="my-info" triggers="hover"> {{ is_confirmed!=false ? '내 계정 정보' : '이메일 계정을 인증해주세요.' }} </b-tooltip> -->

            <!-- <b-modal ref="my-info" size="lg" title="내 계정 정보" id="modify-modal-top" hide-footer><user-info></user-info></b-modal> -->
        </b-container>
    </div>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import UserInfo from '@/components/login_components/UserInfo'

export default {
    data() {
        return {
            show_admin_menu: true,
            menu_active : [true, false, false, false, false, false, false],
            sub_menu_active : [true, false, false],
        }
    },
    computed:{
        ...mapState('report_list', ['report_code', 'is_report_list_loaded']),
        ...mapGetters('admin', ['selected_report']),
        ...mapState('assurance', ['admin_assurance']),
        ...mapGetters('assurance', ['hashed_assurance']),
        ...mapState(['is_admin']),

    },
    methods:{
        ...mapActions(['logout']),
        ...mapActions('admin', ['check_valid_admin_right']),


        handleLogout(){
            this.logout()
            this.$router.push({name:'Login'})
        },
        pageGo(to){
            // console.log(this.admin_assurance);
            if (to.name =='WriteStatement'){
                if (this.admin_assurance&&this.admin_assurance.request_status>=2){
                    this.$router.push(to)
                }else {
                    alert('보고서의 상태를 확인할 수 없습니다. 개발팀에 문의하세요.')
                    
                }
            } 
            else if (to.name == 'TranslateStatement'){
                if (this.admin_assurance&&this.admin_assurance.request_status>=5){
                    this.$router.push(to)
                }else alert('국문 검증의견서가 저장되지 않아 수동 영문 번역을 진행할 수 없습니다. 국문 검증의견서를 작성 후 저정해주세요.')
            }
            else {
                this.$router.push(to)
            }
        },
    },
    created(){
    },
    watch:{
        $route:{
            immediate:true,
            handler(to, from){
                // console.log(to);
                if (to.path != '/') {
                    this.check_valid_admin_right();

                    if(to.name == 'AllReports' ){
                        this.menu_active = [true, false, false, false, false, false, false, false]
                        this.show_admin_menu = true
                    }
                    else if(to.name == 'UserManagement' ){
                        this.menu_active = [false, true, false, false, false, false, false, false]
                        this.show_admin_menu = true
                    }
                    else if(to.name == 'NoticeManagement' ){
                        this.menu_active = [false, false, true, false, false, false, false, false]
                        this.show_admin_menu = true
                    }
                    else if(to.name == 'SubscriptionPaymentManagement' ){
                        this.menu_active = [false, false, false, true, false, false, false, false]
                        this.show_admin_menu = true
                    }
                    else if (to.name == 'ManageLicense') {
                        this.menu_active = [false, false, false, false, true, false, false, false]
                        this.show_admin_menu = true
                    }
                    else if (to.name == 'ManageCoupon') {
                        this.menu_active = [false, false, false, false, false, true, false, false]
                        this.show_admin_menu = true
                    }
                    else if (to.name == 'ManageIndicator') {
                        this.menu_active = [false, false, false, false, false, false, true, false]
                        this.show_admin_menu = true
                    }
                    else if(to.name == 'OnlineAssurance' ){
                        this.sub_menu_active = [true, false, false, false, false, false, false],
                        this.show_admin_menu = false
                    }
                    else if(to.name == 'WriteStatement' ){
                        this.sub_menu_active = [false, true, false, false, false, false, false],
                        this.show_admin_menu = false
                    }
                    else if(to.name == 'TranslateStatement'){
                        this.sub_menu_active = [false, false, true, false, false, false, false],
                        this.show_admin_menu = false
                    }
                    else if (to.name == 'CustomProductManagement') {
                        this.menu_active = [false, false, false, false, false, false, false, true]
                        this.show_admin_menu = true

                    }
                    // if(to.path == '/online_assurance'){
                    //     this.show_admin_menu = false
                    // }
                }
            }
        }
    },
    updated(){
        // console.log(this.selected_report);
    },
    components: {
        UserInfo,
    }

}
</script>

<style>
.btn-bottom-circle{
    position: relative;
}
.btn-bottom-circle::after {
    position: absolute;
    bottom: -10px;
    left: 5px;
    width: calc(100% - 10px);
    height: 3px;
    /* border-radius: 4px; */
    background: var(--tealgray600);
    content: '';
}


#header-menu.border-top > div > .btn-icon-gray {
    color: var(--gray-300);
}
#header-menu.border-top > div > .btn-icon-gray:hover {
    background-color: rgba(255,255,255,0.2);
}
</style>
<template>
  <div>
    <b-row no-gutters class="mb-5">
      <b-col cols="1" xl="2"></b-col>
      <b-col class="mr-3">
        <div
          :class="
            page_check[0] == true
              ? 'border-bottom-dark-bold fw-500 gray-800'
              : 'border-bottom-gray-550 gray-550'
          "
          class="pb-1"
        >
          보고서 명 입력
        </div>
      </b-col>
      <b-col class="mr-3">
        <div
          :class="
            page_check[1] == true
              ? 'border-bottom-dark-bold fw-500 gray-800'
              : 'border-bottom-gray-550 gray-550'
          "
          class="pb-1"
        >
          보고 기간 입력
        </div>
      </b-col>
      <b-col class="mr-3">
        <div
          :class="
            page_check[2] == true
              ? 'border-bottom-dark-bold fw-500 gray-800'
              : 'border-bottom-gray-550 gray-550'
          "
          class="pb-1"
        >
          보고 기관 정보
        </div>
      </b-col>
      <b-col class="mr-3">
        <div
          :class="
            page_check[3] == true
              ? 'border-bottom-dark-bold fw-500 gray-800'
              : 'border-bottom-gray-550 gray-550'
          "
          class="pb-1"
        >
          보고 프레임워크 선택
        </div>
      </b-col>
      <!-- <b-col> <div :class="page_check[4]==true?'border-bottom-dark-bold fw-500 gray-800':'border-bottom-gray-550 gray-550'" class="pb-1"> 서비스 플랜 선택 </div> 
        </b-col> -->
      <b-col cols="1" xl="2"></b-col>
    </b-row>

    <b-row no-gutters>
      <b-col cols="1" xl="2"></b-col>
      <b-col class="">
        <b-card class="bgray-100 border-0 mb-5 px-5">
          <template v-if="page_check[0] == true">
            <p class=" fw-400">보고서 명을 입력하세요</p>
            <b-form-input
              type="text"
              class="wide-input-form border-teal bottom-teal-focus"
              autofocus
              placeholder="보고서 명 (ex. 2020년 퀀티파이드이에스지 지속가능경영 보고서)"
              v-model.trim="local_report.report_name"
            ></b-form-input>
          </template>
          <template v-else-if="page_check[1] == true">
            <p class=" fw-400">보고서의 보고 기간을 입력하세요</p>
            <div class="d-md-flex mb-4">
              <b-form-datepicker
                v-model="local_report.start_date"
                class="border-teal"
              ></b-form-datepicker
              ><span class="px-3">~</span>
              <b-form-datepicker
                v-model="local_report.end_date"
                class="border-teal"
              ></b-form-datepicker>
            </div>
            <p class="f-85 gray-600 mb-2">
              <fa icon="info-circle" class="mr-1" />보고 기간은 보고내용 전반에
              작성하실 사업 활동 및 성과 정보의 기간을 말합니다.
            </p>
            <p class="f-85 gray-600 mb-0 ml-3">
              직전 회계연도(e.x. 2020.1.1 ~ 2020.12.31) 기준으로 설정하실 것을
              권고드립니다.
            </p>
          </template>
          <template v-else-if="page_check[2] == true">
            <p class=" fw-400">보고 기관 정보를 입력하세요</p>
            <b-input-group>
              <b-form-input
                class="border-teal border-teal-focus"
                v-model="search_keyword"
                autofocus
                placeholder="기관명으로 기관 검색"
                @keyup.enter="searchOrganization()"
              ></b-form-input>
              <b-input-group-append
                ><b-button variant="teal" @click="searchOrganization()">
                  <fa icon="search" /> </b-button
              ></b-input-group-append>
            </b-input-group>
            <p
              class="f-85 fw-400 teal pt-2"
              v-if="empty_or_cannot_find == true"
            >
              <fa icon="info-circle" class="mr-1" />검색된 기관이 없습니다.
              아래의 항목을 기입해주세요.
            </p>
            <div class="mb-2" v-if="searched_organization_list.length > 0">
              <b-list-group
                id="company-search-list"
                v-if="is_selected != true"
                class="shadow-sm"
              >
                <b-list-group-item
                  button
                  class="px-3 py-2 search-result"
                  v-for="item in searched_organization_list"
                  :key="item.key"
                  @click="selectOrganization(item.id)"
                >
                  <div class="f-90 gray-700">
                    {{ item.organization_name }}, {{ item.address }}
                    <span class="f-80 gray-500">(주소)</span>
                  </div>
                </b-list-group-item>
              </b-list-group>
              <p v-if="is_selected == true" class="f-85 teal7 mt-2">
                <fa icon="check-circle" class="mr-1" />선택한 기관 정보가
                입력되었습니다.
              </p>
              <p v-else class="f-85 fw-400 teal pt-2">
                <fa icon="info-circle" class="mr-1" /> 목록에 찾으시는 기관이
                없다면 아래의 항목을 기입해주세요.
              </p>
            </div>

            <b-card
              no-body
              v-if="is_searched == true"
              class="bg-tealgray200 border-0 mt-4 p-3"
            >
              <b-row class="mb-2">
                <b-col cols="12" md="2"><div class="f-95">기관명</div> </b-col>
                <b-col class="d-xl-flex">
                  <b-form-input
                    type="text"
                    v-model.trim="local_report.organization_name"
                    class="narrow-input-form bottom-teal-focusmb-1 mb-xl-0 mr-0 mr-xl-2"
                    placeholder="기관명 국문"
                  ></b-form-input>
                  <b-form-input
                    type="text"
                    v-model.trim="local_report.organization_name_e"
                    class="narrow-input-form bottom-teal-focus"
                    placeholder="기관명 영문"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="12" md="2"
                  ><div class="f-95">본사주소</div>
                </b-col>
                <b-col
                  ><b-form-input
                    type="text"
                    v-model.trim="local_report.address"
                    class="narrow-input-form bottom-teal-focus"
                    placeholder="본사 주소"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="2"><div class="f-95">CI/로고</div> </b-col>
                <b-col v-if="is_selected == true || is_searched == true">
                  <b-form-file
                    type="text"
                    v-model="company_logo"
                    @change="eraseLogo"
                    class="narrow-input-form bottom-teal-focus"
                  ></b-form-file>
                  <b-img
                    v-if="local_report.logo_path"
                    :src="$store.state.static_host + local_report.logo_path"
                    fluid
                  ></b-img>
                  <b-img :src="previewImageUrl" v-if="previewImageUrl" fluid> </b-img>

                  <p v-if="local_report.logo_name" class="my-1 f-85">
                    {{ local_report.logo_name }}
                  </p>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-else-if="page_check[3]">
            <p class=" fw-600">보고 프레임워크를 선택해주세요</p>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                class="select-report-type"
                v-model="local_report.report_type"
                :options="report_types"
                :aria-describedby="ariaDescribedby"
                size="lg"
                stacked
                
              ></b-form-checkbox-group>
            </b-form-group>

          </template>
          <!-- <template v-else-if="page_check[4]==true"> -->
          <!-- <TestPaymentRegular /> -->
          <!-- <p class=" fw-400"> 서비스 이용 플랜을 선택해주세요 </p>
                    <div class="d-lg-flex justify-content-between mb-4">
                        <b-card no-body class="border-0 service-plan-card shadow">
                            <b-card-header class="gray-50 bg-teal8">
                                <h5 class="mb-0">기본 서비스<fa icon="check-circle" class="float-right mt-1" /> </h5>
                            </b-card-header>
                            <div class="px-3 py-2">
                                <b-form-checkbox v-model="default_plan" disabled id="default-disabled" v-b-tooltip.click.bottom title="기본 서비스는 필수항목입니다">보고서 작성 및 공개 </b-form-checkbox>
                            </div>
                        </b-card>
                        <b-card no-body class="border-0 service-plan-card" :class="selected_plan.length>0?'shadow':'shadow-sm'">
                            <b-card-header class="gray-50" :class="selected_plan.length>0? 'bg-teal8':'bg-tealgray400'">
                                <h5 class="mb-0">부가 서비스 <fa v-if="selected_plan.length>0" icon="check-circle" class="float-right mt-1" /> </h5>
                            </b-card-header>
                            <div class="px-3 py-2">
                                <b-form-checkbox-group v-model="selected_plan" :options="plan_options" stacked class="add-service-plan"></b-form-checkbox-group>
                            </div>
                        </b-card>
                    </div>     
                    <div class="mb-3 text-right"> <b-button variant="tealgray-border" size="sm" v-b-modal.check-estimate >표준 가격표 보기 </b-button> </div>

                    <div class="border-top py-3">
                        <p class="fw-400"> 보고기관의 연 매출 규모를 선택해주세요 </p>
                        <b-form-radio-group v-model="revenue" :options="revenue_options" ></b-form-radio-group>
                    </div>
                    <div class="border-top py-3">
                        <p class="fw-400"> 보고서 발간 예정일을 선택해주세요 </p>
                        <b-form-datepicker v-model="target_publish_date" style="max-width: 300px;" ></b-form-datepicker>
                    </div>
                    <div class="border-top pt-3">
                        <p class="fw-500"> 이용 요금: </p>
                        <template v-if="revenue!=''">
                            <b-row no-gutters class="mb-2"><b-col cols="1" md="2" lg="3"></b-col>
                                <b-col cols="7" md="5" lg="5" class="border-bottom">보고서 작성 및 공개 </b-col><b-col cols="6" md="5" lg="4" class="border-bottom text-right red"><span class="gray-550 line-through mr-1">{{ basic_cost.toLocaleString('ko-KR') }}</span> 0 원 </b-col>
                            </b-row>
                            <b-row no-gutters class="mb-2" v-if="selected_plan!=[]&&selected_plan.indexOf('제3자 검증')> -1"><b-col cols="1" md="2" lg="3"></b-col>
                                <b-col cols="7" md="5" lg="5" class="border-bottom">제3자 검증 </b-col><b-col cols="6" md="5" lg="4" class="border-bottom text-right">{{ assurance_cost.toLocaleString('ko-KR') }} 원 </b-col>
                            </b-row>
                            <b-row no-gutters class="mb-2" v-if="selected_plan!=[]&&selected_plan.indexOf('고품질 번역')> -1"><b-col cols="1" md="2" lg="3"></b-col>
                                <b-col cols="7" md="5" lg="5" class="border-bottom">고품질 번역 </b-col><b-col cols="6" md="5" lg="4" class="border-bottom text-right pr-3 gray-550">별도 문의 </b-col>
                            </b-row>
                            <b-row no-gutters class="mb-2" v-if="selected_plan!=[]&&selected_plan.indexOf('전용 웹 구축')> -1"><b-col cols="1" md="2" lg="3"></b-col>
                                <b-col cols="7" md="5" lg="5" class="border-bottom">전용 웹 구축 </b-col><b-col cols="6" md="5" lg="4" class="border-bottom text-right pr-3 gray-550">별도 문의 </b-col>
                            </b-row>
                            <b-row no-gutters class="mb-2" v-if="selected_plan!=[]&&selected_plan.indexOf('컨설팅/교육')> -1"><b-col cols="1" md="2" lg="3"></b-col>
                                <b-col cols="7" md="5" lg="5" class="border-bottom">컨설팅/교육 </b-col><b-col cols="6" md="5" lg="4" class="border-bottom text-right pr-3 gray-550">별도 문의</b-col>
                            </b-row>
                            <div class="text-right gray-550"><span class="line-through">{{ (basic_cost+assurance_cost).toLocaleString('ko-KR') }}</span> 원</div>
                            <div class="text-right red"> <span class="fw-400 mr-2 mr-xl-4 mb-0 f-90"><fa icon="arrow-right" /> 기간 할인 적용 </span> <span class="f-120 fw-500 mb-0"> {{ assurance_cost.toLocaleString('ko-KR') }} 원</span></div>
                        </template>
                    </div> -->
          <!-- </template> -->

          <!-- <div class="d-flex justify-content-between pt-5">
                    <b-button v-if="page_check[0]!=true" variant="half-bg-gray" class="mr-3" @click.stop="pageSlideBack"> <fa icon="angle-left"></fa> <span class="f-95 pl-1">이전</span> </b-button>
                    <div v-else> </div>


                    <div v-if="page_check[4]==true && !shouldPay">
                        <b-button variant="teal" > 보고서 생성하기 </b-button>
                    </div>
                    <div v-else>
                        <b-button variant="info" @click="hightlightPayInfo">정기결제 및 추가 결제</b-button>
                    </div>

                    <b-button v-if="page_check[4]!=true" variant="tealgray" class="" @click.stop="pageSlide"> <span class="f-95 pr-1">다음</span> <fa icon="angle-right"></fa> </b-button>
                </div> -->
          <div class="d-flex justify-content-between pt-5">
            <b-button
              v-if="page_check[0] != true"
              variant="half-bg-gray"
              class="mr-3"
              @click.stop="pageSlideBack"
            >
              <fa icon="angle-left"></fa> <span class="f-95 pl-1">이전</span>
            </b-button>
            <div v-else></div>
            <b-button
              v-if="page_check[3] == true"
              variant="teal"
              @click="handleSave"
            >
              보고서 생성하기
            </b-button>
            <b-button
              v-else
              variant="tealgray"
              class=""
              @click.stop="pageSlide"
            >
              <span class="f-95 pr-1">다음</span> <fa icon="angle-right"></fa>
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col cols="1" xl="2" class="d-flex align-items-end"></b-col>
    </b-row>
    <!-- <b-button variant="teal-border" size="sm" class="ml-1 mb-2" v-b-modal.assurance-info> 검증 서비스 자세히 보기 </b-button>
        <b-modal id="assurance-info" size="lg" scrollable hide-footer>
            <assurance-info></assurance-info>
        </b-modal> -->
    <b-modal
      id="check-estimate"
      size="lg"
      scrollable
      hide-footer
      title="표준 가격표"
    >
      <standard-price-table></standard-price-table>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import * as ih from "@/components/util";
import AssuranceInfo from "@/components/assurance/AssuranceInfo.vue";
import StandardPriceTable from "@/components/static_examples/StandardPriceTable.vue";
import TestPaymentRegular from "@/components/payment/TestPaymentRegular.vue";
import Vue from "vue";
import { testPayment } from "@/components/payment/payment";

Vue.component("TestPaymentRegular", TestPaymentRegular);

export default {
  created() {
    
  },
  mounted() {
    this.checkPaymentRequired();
  },
  data() {
    return {
      previewImageUrl: null,

      // 테스트 주석
      // page_check: [true, false, false, false, false],


      page_check: [true, false, false, false, false],

      shouldPay: true,
      local_report: {},
      report_types: [
          { value: 'GRIv2021', text: 'GRI Standards' },
          { value: 'SASBv2023', text: 'SASB Standards (IFRS S1)' },
          { value: 'TCFDv2021', text: 'TCFD Framework (IFRS S2)' }
      ],
      company_logo: null,
      report_code_for_toast: "",
      search_keyword: "",
      is_searched: false,

      searched_organization_list: [],
      empty_or_cannot_find: false,
      is_selected: false,

      default_plan: true,
      selected_plan: [],
      plan_options: [
        { text: "제3자 검증", value: "제3자 검증" },
        { text: "고품질 번역", value: "고품질 번역" },
        { text: "전용 웹 구축", value: "전용 웹 구축" },
        { text: "컨설팅/교육", value: "컨설팅/교육" },
      ],
      revenue: "",
      revenue_options: [
        { text: "20억원 미만", value: "20억원 미만" },
        { text: "20억원 ~ 500억원", value: "20억원 ~ 500억원" },
        { text: "500억원 이상", value: "500억원 이상" },
      ],
      target_publish_date: "",
    };
  },
  computed: {
    ...mapState("report_list", ["report_code"]),
    ...mapState(["licenseInfo"]),
    ...mapState(["user_id", "e_mail"]),
    ...mapGetters("report_list", ["selected_report"]),

    target_report_code() {
      var date = new Date();
      var str = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5);
      var yy = String(date.getFullYear()).slice(2);
      if (date.getMonth() < 9) {
        var mm = "0" + String(date.getMonth() + 1);
      } else {
        var mm = String(date.getMonth() + 1);
      }
      if (date.getDate() < 10) {
        var dd = "0" + String(date.getDate());
      } else {
        var dd = String(date.getDate());
      }
      let new_report_code = yy + mm + dd;
      return str + new_report_code;
    },
    basic_cost() {
      if (this.revenue == "20억원 미만") {
        return 1900000;
      }
      if (this.revenue == "20억원 ~ 500억원") {
        return 3900000;
      }
      if (this.revenue == "500억원 이상") {
        return 5900000;
      } else return 0;
    },
    assurance_cost() {
      if (
        this.selected_plan != [] &&
        this.selected_plan.indexOf("제3자 검증") > -1
      ) {
        if (this.revenue == "20억원 미만") {
          return 3500000;
        }
        if (this.revenue == "20억원 ~ 500억원") {
          return 5900000;
        }
        if (this.revenue == "500억원 이상") {
          return 8900000;
        }
      } else return 0;
    },
  },
  methods: {
    ...mapActions("report_list", ["saveReportPreference"]),
    ...mapActions("assurance", ["saveAssuranceRequest"]),
    ...mapActions("billings", ["saveBilling"]),
    testPayment,
    checkPaymentRequired() {
      // licenseInfo
      if (!(this.licenseInfo && this.licenseInfo.available_report_cnt_info && this.licenseInfo.available_report_cnt_info.remain_report_cnt > 0)){
        window.alert(
            "보고서 생성할 권한이 없습니다. 정기결제를 하거나 프리미엄으로 계정을 업그레이드 해 주세요."
          );
        this.$router.push("/subscription_settings");
         
      } 

      // axios
      //   .get(
      //     `${this.$store.state.backend_host}/get_user_license_info`
      //   )
      //   .then((response) => {
      //     if (response.data.code) {
      //       if (
      //         !response.data ||
      //         !response.data.available_report_cnt_info.code
      //       ) {
      //         window.alert(
      //           "보고서 생성 접근 권한이 없습니다. 라이센스를 구입/갱신해주세요."
      //         );
      //         this.$router.push("/main");
      //       }
      //     } else {
      //       // commit('setLicenseInfo', null)
      //       window.alert(
      //         "보고서 생성 접근 권한이 없습니다. 라이센스를 구입/갱신해주세요."
      //       );
      //       this.$router.push("/main");
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     commit("setLicenseInfo", null);
      //   });
    },
    hightlightPayInfo() {
      document
        .querySelector("#regularPaymentForm")
        .setAttribute("style", "border: 2px solid orange; border-radius: 5px;");
      // const elements = document.querySelectorAll("#regularPaymentForm tbody .payTicket");
      // elements.forEach(element => {
      // element.setAttribute("style", "border: 2px solid orange; border-radius: 5px;");
      // });
    },
    pageSlide() {
      let page_num = this.page_check.findIndex((x) => x == true);
      if (page_num == 0) {
        if (
          this.local_report.report_name == "" ||
          this.local_report.report_name == null ||
          this.local_report.report_name == undefined
        ) {
          alert("보고서의 이름을 입력하여 주십시오.");
        } else {
          return this.handleSlide(page_num);
        }
      }
      if (page_num == 1) {
        if (
          this.local_report.start_date == "" ||
          this.local_report.start_date == null ||
          this.local_report.start_date == undefined ||
          this.local_report.end_date == "" ||
          this.local_report.end_date == null ||
          this.local_report.end_date == undefined
        ) {
          alert("보고서의 보고기간을 선택하여 주십시오.");
        } else {
          return this.handleSlide(page_num);
        }
      }
      if (page_num == 2) {
        if (
          this.local_report.organization_name == "" ||
          this.local_report.organization_name == null ||
          this.local_report.organization_name == undefined
        ) {
          alert(
            "기관명을 검색하신 후, 검색된 기관명을 선택하거나 새로 기입하여 주십시오."
          );
        } else {
          return this.handleSlide(page_num);
        }
      }
      if (page_num == 3) return this.handleSlide(page_num);
    },
    handleSlide(page_num) {
      this.page_check.splice(page_num, 1, false);
      this.page_check.splice(page_num + 1, 1, true);
    },
    pageSlideBack() {
      let page_num = this.page_check.findIndex((x) => x == true);
      this.page_check.splice(page_num, 1, false);
      this.page_check.splice(page_num - 1, 1, true);
    },
    handlePay() {
      // console.log("test");
    },
    handleSave() {
      if (this.checkForm() == true) {
        this.saveReportPreference({
          report_code: this.target_report_code, //store의 report code → computed의 target_report_code
          report_name: this.local_report.report_name,
          start_date: this.local_report.start_date,
          end_date: this.local_report.end_date,
          state: 0, // 디폴트값으로 생성
          description: this.local_report.description,
          report_type: this.local_report.report_type,
          organization_name: this.local_report.organization_name,
          organization_name_e: this.local_report.organization_name_e,
          address: this.local_report.address,
          logo: this.company_logo,
          owner_id: this.user_id, // 디폴트값으로 생성
          main_color: "#008C95", // 디폴트값으로 생성
          sub_color: "#dfdfdf", // 디폴트값으로 생성
          font_family: "Noto Sans Kr", // 디폴트값으로 생성
          font_size: 15, // 디폴트값으로 생성
          // plan: this.selected_plan
        }).then((new_report_code) => {
          //보고서 생성 후 report_code 받아서 Billing 및 AssuranceRequest 생성
          // this.saveBilling({ report_code : new_report_code, service_type : '작성 및 공개', start_date : this.target_publish_date, service_cost : 0, revenue: this.revenue })
          // if (this.selected_plan.indexOf('고품질 번역')> -1){
          //     this.saveBilling({ report_code : new_report_code, service_type : '고품질 번역', start_date : this.target_publish_date, revenue: this.revenue })
          // }
          // if (this.selected_plan.indexOf('전용 웹 구축')> -1){
          //     this.saveBilling({ report_code : new_report_code, service_type : '전용 웹 구축', start_date : this.target_publish_date, revenue: this.revenue })
          // }
          // if (this.selected_plan.indexOf('컨설팅/교육')> -1){
          //     this.saveBilling({ report_code : new_report_code, service_type : '컨설팅/교육', start_date : this.target_publish_date, revenue: this.revenue })
          // }
          // if (this.selected_plan.indexOf('제3자 검증')> -1){
          //     this.saveAssuranceRequest({ report_code : new_report_code, publish_date : this.target_publish_date, request_status : 0, revenue: this.revenue })
          // }
          this.$EventBus.$emit(
            "make-toast",
            "보고서 생성",
            `${this.local_report.report_name}가 생성되었습니다.`
          );
          this.$router.push({ name: "Main" });
        });

        // if (this.selected_plan=='A'){
        //     this.$router.push({name: 'Main'})
        // }
        // else {
        //     this.update_report_code(this.target_report_code)
        //     this.$emit('show-assurance-estimate')
        // }
      }
    },
    initiateOrganization() {
      this.local_report.organization_name = null;
      this.local_report.organization_name_e = null;
      this.local_report.address = null;
      this.local_report.logo_name = null;
      this.local_report.logo_path = null;
      this.company_logo = null;
    },
    async initiateReport() {
      this.initiateOrganization();
      let temp_report = {
        report_name: null,
        created_date: null,
        start_date: null,
        end_date: null,
        description: null,
        report_type: ["GRIv2021"]
      };
      this.local_report = temp_report;
      this.search_keyword = "";
      this.searched_organization_list = [];
    },
    checkForm() {


      if (
        this.local_report.report_name == "" ||
        this.local_report.report_name == null
      ) {
        alert("보고서 명을 입력해 주세요");
        this.page_check = [false, false, false, false, false];
        this.page_check.splice(0, 1, true);
        return false;
      } else if (
        this.local_report.start_date == "" ||
        this.local_report.start_date == null
      ) {
        alert("보고 기간 시작 시점을 입력해 주세요");
        this.page_check = [false, false, false, false, false];
        this.page_check.splice(1, 1, true);
        return false;
      } else if (
        this.local_report.end_date == "" ||
        this.local_report.end_date == null
      ) {
        alert("보고 기간 종료 시점을 입력해 주세요");
        this.page_check = [false, false, false, false, false];
        this.page_check.splice(1, 1, true);
        return false;
      } else if (
        (this.local_report.organization_name == "") |
        (this.local_report.organization_name == null)
      ) {
        alert("보고 기관명을 입력해 주세요");
        this.page_check = [false, false, false, false, false];
        this.page_check.splice(2, 1, true);
        return false;
      } else if (this.local_report.report_type.length < 1) {
        alert("보고 프레임워크를 하나 이상 선택해주세요")
        return false;
      }
      // else if (this.revenue==''){ alert('보고기관의 연 매출 규모를 선택해주세요')
      //     return false
      // }
      // else if (this.target_publish_date==''){ alert('보고서 발간 예정일을 선택해주세요')
      //     return false
      // }
      else return true;
    },
    searchOrganization() {
      this.is_searched = true;
      this.is_selected = false;
      if (this.search_keyword == "") {
        this.empty_or_cannot_find = true;
        this.searched_organization_list = [];
        this.initiateOrganization();
      } else {
        const path = this.$store.state.backend_host + "/search_organization";
        return axios
          .get(path, {
            params: {
              search_keyword: this.search_keyword,
            },
          })
          .then((response) => {
            // 중복 제거 리스트만들기
            let temp_searched_list = response.data;
            let deduplicated_list = [];
            let count = 0;
            temp_searched_list.forEach((item) => {
              let idx = deduplicated_list.findIndex(
                (org) =>
                  org.organization_name == item.organization_name &&
                  org.address == item.address
              );
              if (idx < 0) {
                deduplicated_list.push(item);
              }
            });
            this.searched_organization_list = deduplicated_list;

            if (this.searched_organization_list.length < 1) {
              this.empty_or_cannot_find = true;
              this.local_report.organization_name = this.search_keyword;
            } else {
              this.empty_or_cannot_find = false;
            }
          });
      }
    },
    async selectOrganization(id) {
      let idx = this.searched_organization_list.findIndex(
        (org) => org.id == id
      );
      // console.log(this.searched_organization_list[idx]);
      this.local_report.organization_name = this.searched_organization_list[
        idx
      ].organization_name;
      this.local_report.organization_name_e = this.searched_organization_list[
        idx
      ].organization_name_e;
      this.local_report.address = this.searched_organization_list[idx].address;
      this.local_report.logo_name = this.searched_organization_list[
        idx
      ].logo_name;
      this.local_report.logo_path = this.searched_organization_list[
        idx
      ].logo_path;
      let new_logo = await ih.getFileAsData(
        this.searched_organization_list[idx].logo_name,
        this.searched_organization_list[idx].logo_path,
        false
      );
      // console.log(new_logo);
      this.company_logo = new_logo;
      this.is_selected = true;
    },
    eraseLogo(event) {
      // this.local_report.logo_name = "";
      // this.local_report.logo_path = "";

      // 파일을 읽어서 이미지 미리보기 URL 생성
      const file = event.target.files[0];
      if (!file) return; // 파일이 선택되지 않은 경우

      // 파일을 읽어서 이미지 미리보기 URL 생성
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewImageUrl = e.target.result;
      };
      reader.readAsDataURL(file);

    },
  },
  created() {
    this.initiateReport();
  },
  components: {
    AssuranceInfo,
    StandardPriceTable,
  },
  updated() {
    // console.log(this.local_report.logo);
  },
  // watch:{
  //     company_logo:{
  //         immediate: true,
  //         handler(newV, oldVal){
  //             console.log(oldVal);
  //             console.log(newV);
  //             if (oldVal !=null && newV != oldVal){
  //                 console.log('새 로고 업로드');
  //                 this.local_report.logo_name = ''
  //                 this.local_report.logo_path  = ''
  //             }
  //         }
  //     }
  // }
};
</script>
<style>
.service-plan-card {
  width: calc(50% - 15px);
}
@media (max-width: 960px) {
  .service-plan-card {
    width: 100%;
  }
}

.add-service-plan > .custom-checkbox {
  margin-bottom: 0.5rem;
}

#default-disabled
  > .custom-control-input:disabled
  ~ .custom-control-label::before,
.custom-control-input:disabled:checked ~ .custom-control-label::before {
  border-color: var(--gray-700) !important;
  background-color: var(--gray-700) !important;
}

#default-disabled > .custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: var(--gray-700) !important;
}
/* .service-plan-card .card-header{
    transition: all 0.01s ease-in-out;
}
.service-plan-card:hover .card-header{
    background-color: var(--tealgray500);
}

.service-plan-card > div > .show-select {
    top: -100%;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0;
    transition: all 0.1s ease-in-out;
}
.service-plan-card > div > .show-select:focus {
    background-color: var(--tealgray600);
}
.service-plan-card:hover > div > .show-select {
    top:0;
    left:0;
} */
</style>

<template>
    <div>
        <h2 class="fw-600 mb-3 mt-4">TCFD Index</h2>
        <TableLoading v-if="tcfd_contents.length === 0" />
        <div>
            <template>
                <b-table-simple responsive class="disclosure-table lg border-bottom mb-5">
                    <b-thead v-if="show_table_header">
                        <b-tr>
                            <b-td style="min-width:22%; width:32%;">{{ lang != 'ENG' ? '보고 지표' : 'Disclosures' }}
                                <!-- <header-anchor :active_code="sector_key" :print_mode="print_mode" /> -->
                            </b-td>
                            <b-td class="d-lg-flex justify-content-between">{{ lang != 'ENG' ? '보고 내용' : 'Disclosure Contents' }}
                                <div class="f-90">
                                    <fa icon="table" class="mr-1" /> {{ lang != 'ENG' ? '데이터 테이블' : 'Data Tables' }}
                                    <fa icon="file-alt" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '문서' : 'Files' }}
                                    <fa icon="external-link-square-alt" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '외부 링크' : 'URLs' }}
                                    <fa icon="quote-left" class="ml-3 mr-1" /> {{ lang != 'ENG' ? '비즈니스 사례' : 'Business Cases' }}
                                </div>
                            </b-td>
                        </b-tr>
                    </b-thead>

                    <b-tbody v-for="(category_info, category_idx) in tcfd_contents" :key="category_idx"
                        :id="'rp' + category_info.category_id" class="mb-2 border-0">
                        <b-tr v-if="category_info.indicators.length > 0">
                            <b-td colspan="2" class="py-1 pl-1" :style="main_color_sub">
                                <span class="fw-600 f-140">
                                    {{ category_idx + 1 }}.
                                    <!-- {{ industry_key }} : {{ tcfd_contents[sector_key]['children'][industry_key]["industry_name"] }} -->
                                    {{ lang != 'ENG' ? category_info.category_name : category_info.category_name_e }}
                                </span>
                                <span class="gray-500 f-90 fw-400 ml-1">
                                    <!-- {{ topic.topic_publication_date }} -->
                                </span>


                            </b-td>
                        </b-tr>

                        <b-tr v-for="(disclosure) in category_info.indicators" :key="disclosure.disclosure_id"
                            :id="`w${disclosure.indicator_code}`">
                            <b-td :id="'crp' + disclosure.disclosure_id" class="pr-4 d-flex flex-column">
                                <div class="fw-500">{{ disclosure.tcfd_code }}</div>
                                <div v-if="lang !== 'ENG'">
                                    <p>
                                        {{ disclosure.head_message }}
                                    </p>
                                    <p class="">
                                        {{ disclosure.indicator_name }}
                                    </p>
                                    <p class="pb-3">{{ disclosure.topic }}</p>
                                    <div class="fw-400">
                                        {{ disclosure.metric }}
                                    </div>
                                </div>
                                <div v-else>
                                    <p>
                                        {{ disclosure.head_message_e }}
                                    </p>
                                    <p class="">
                                        {{ disclosure.indicator_name_e }}
                                    </p>
                                    <p class="pb-3">{{ disclosure.topic }}</p>
                                    <div class="fw-400">
                                        {{ disclosure.metric }}
                                    </div>
                                </div>
                            </b-td>
                            <b-td class="" style="max-width: 200mm;">
                                <md v-if="lang !== 'ENG' && disclosure.direct_report" style="word-wrap: break-word;"
                                    :contents="disclosure.direct_report" :class="{ 'mb-3': disclosure.direct_report }" />
                                <md v-if="lang === 'ENG' && (disclosure.direct_report_em || disclosure.direct_report_ea)"
                                    style="word-wrap: break-word;"
                                    :contents="disclosure.direct_report_em ? disclosure.direct_report_em : disclosure.direct_report_ea"
                                    :class="{ 'mb-3': disclosure.direct_report }" />
                                <!-- data_tables 넣을자리 -->
                                <div v-for="data_table_id in disclosure.data_tables"
                                    :key="'data_table_' + data_table_id">
                                    <data-table :print_mode="print_mode" :table_mode="table_mode"
                                        :data_table_id="data_table_id" :modal_table="modal_table" :lazy_load="false"
                                        :table_style="table_style" :show_table_line="show_table_line"
                                        :show_table_outline="show_table_outline"
                                        :table_header_color="table_header_color"
                                        :table_header_font_color="table_header_font_color" />

                                </div>

                                <!-- documents 넣을자리 -->
                                <div>
                                    <span v-for="document_id in disclosure.documents" :key="'document_' + document_id">
                                        <document :document_id="document_id" :lazy_load="false" />
                                    </span>
                                </div>

                                <!-- business_cases 넣을자리 -->
                                <span v-for="business_case_id in disclosure.business_cases"
                                    :key="'business_case_' + business_case_id">
                                    <business-case :business_case_id="business_case_id" :lazy_load="false"
                                        :business_case_mode="business_case_mode" />
                                </span>

                                <span v-for="chart_id in disclosure.charts" :key="'chart_' + chart_id">
                                    <ChartCanvas v-if="chart_id !== ''" :chart_id="chart_id" :print_mode="print_mode"
                                        :key="`chart_${chart_id}`" :viewer_mode="true" class="mb-5" />
                                </span>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import DataTable from './DataTable.vue'
import Document from './Document.vue'
import BusinessCase from './BusinessCase.vue'
import HeaderAnchor from './HeaderAnchor'
import { nan_table } from '@/components/util'
import TableLoading from './TableLoading.vue'
import ChartCanvas from "@/components/writing_report/chart_subs/ChartCanvas.vue";

export default {
    components: {
        DataTable,
        Document,
        BusinessCase,
        HeaderAnchor,
        TableLoading,
        ChartCanvas
    },
    data() {
        return {
            show_data_table: {},
            show_business_case: {},
            low_height: false,
            selected_indicator: null,
            show_table_header: true,
            nan_table
        }
    },
    mounted() {
        this.fetchSelectedTcfd(this.report_code)

    },
    props: {
        modal_table: { type: Boolean, default: false },
        reload: { type: Boolean, default: false },
        print_mode: { type: Boolean, default: false },
        business_case_mode: { type: String, default: "popup" },
        table_mode: { type: String, default: "accordion" },
        material_style_option: { type: String, default: "outline" },

        table_style: { type: String, default: 'basic_table' },
        show_table_line: { type: Boolean, default: true },
        show_table_outline: { type: Boolean, default: false },
        table_header_color: { type: String, default: '#dedede' },
        table_header_font_color: { type: String, default: '#fffff' },
    },
    computed: {
        ...mapState('report_contents', ['tcfd_contents']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapGetters('report_list', ['selected_report']),
        main_color() {
            return `color:` + this.selected_report.main_color
        },
        main_color_sub() {
            if (this.material_style_option === 'fill') {
                return `background-color: ${this.selected_report.main_color}; color: white; border: 1px solid white; `
            } else if (this.material_style_option === 'outline') {
                return `color:` + this.selected_report.main_color + `; border-top: 1px solid ` + this.selected_report.main_color + `!important` + `; border-bottom: 1px solid ` + this.selected_report.main_color
            } else {
                return `background-color: white; border-left: 3px solid ${this.selected_report.main_color}; border-bottom: 1px solid ${this.selected_report.sub_color};border-top: 0px; border-right: 0px;`;
            }
        },
        main_bg_color() {
            if (this.selected_report) {
                return `background-color:` + this.selected_report.main_color
            }
            else return `background-color: #494949`
        }
    },
    watch:{
    },
    methods: {
        ...mapActions('data_tables', ['readDataTableList']),
        ...mapActions('business_cases', ['readBusinessCaseList']),
        ...mapActions('documents', ['readDocumentList']),
        ...mapActions('report_contents', ['fetchSelectedTcfd']),
        // async fetchSelectedTcfd() {
        //     const response = await axios.get(
        //         `${this.$store.state.backend_host}/tcfd/read_tcfd_all_indicators_list_sort_by_category_for_viewer?rc=${this.report_code}`
        //     );

        //     this.tcfd_indicator_list = response.data.result;
        // },
        navAnchor(event, anchor) {
            event.preventDefault();
            if (anchor && document.querySelector("#w" + anchor)) {
                let elem = document.querySelector("#w" + anchor);
                if (elem) {
                    elem.scrollIntoView({ behavior: "smooth" });
                }
            }
        },
        getUniqueTopicsByCategoryIds(category_id) {
            //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id == category_id)
            let unique_topics = []
            filtered_structure.forEach(item => {
                if (unique_topics.findIndex(x => { return x.topic_id == item.topic_id }) < 0) {
                    unique_topics.push({
                        category_id: item.category_id,
                        category_name: item.category_name,
                        category_name_e: item.category_name_e,
                        topic_id: item.topic_id,
                        topic_name: item.topic_name,
                        topic_name_e: item.topic_name_e,
                        topic_publication_date: item.topic_publication_date

                    })
                }
            })
            return unique_topics
        },
        getUniqueSubtopicsByCategoryIds(category_id) {
            //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id == category_id)
            let unique_subtopics = []
            filtered_structure.forEach(item => {
                if (unique_subtopics.findIndex(x => { return x.subtopic_code == item.subtopic_code }) < 0) {
                    unique_subtopics.push({
                        category_id: item.category_id,
                        category_name: item.category_name,
                        category_name_e: item.category_name_e,
                        subtopic_code: item.subtopic_code,
                        subtopic_name: item.subtopic_name,
                        subtopic_name_e: item.subtopic_name_e,
                        topic_publication_date: item.topic_publication_date

                    })
                }
            })
            return unique_subtopics
        },
        getGRIContentsByCategoryIds(category_id) {
            //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e, inidcator_id }]
            let filtered_structure = this.report_structure.filter(item => item.category_id == category_id)
            return filtered_structure
        },
        getGRIContentsByTopicIds(topic_id) {
            // [{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e, gri_code, indicator_id, disclosure_id }]
            let filtered_structure = this.report_structure.filter(item => item.topic_id == topic_id)
            return filtered_structure
        },
        getGRIContentsBySubtopicCodes(subtopic_code) {
            // [{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e, gri_code, indicator_id, disclosure_id }]
            let filtered_structure = this.report_structure.filter(item => item.subtopic_code == subtopic_code)
            return filtered_structure
        },
    },
    created() {
        // this.fetchSelectedTcfd()
        // if(this.reload==true){
        //     this.update_report_contents_loaded(false)
        // }

        // if(this.report_contents_loaded == false){
        //     // console.log('GRI_TABLE_LOAD')
        //     this.readReportContents({report_code : this.report_code})
        //     this.readDataTableList({report_code: this.report_code})
        //     this.readBusinessCaseList({report_code: this.report_code})
        //     this.readDocumentList({ report_code: this.report_code })
        //     this.selected_gri_code_list()
        // }
    }
}
</script>
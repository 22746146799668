<template>
  <div>
    <!-- 재귀형식으로 children을 부를까 했는데..1레벨 2레벨 3레벨 모두 스타일링이 다르다.. -->
    <div 
      v-if="loading"
      class="accordion" 
      role="tablist"
    >
      <b-card
      no-body
      class="shadow-sm"
      >
        <template >
          <b-skeleton class="my-2 mx-3"></b-skeleton>
          <b-skeleton class="my-2 mx-3"></b-skeleton>
          <b-skeleton class="my-2 mx-3"></b-skeleton>
        </template>
      </b-card>
    </div>


    <div v-else class="accordion" role="tablist">
      <!-- 로딩버튼 -->
      <b-card
        v-for="(lv1_info, lv1_idx) in side_nav_info"
        :key="lv1_idx"
        no-body
        class="shadow-sm"
      >
        <b-card-header header-tag="header" class="p-1 bg-white" role="tab">
          
          <b-button
          :id="`lv1-${lv1_info.nav_name}`"
            block
            :variant="
              lv1_info.selected ? 'icon-txt-dark' : 'icon-txt'
            "
            class="text-left py-2"
            :class="lv1_info.selected ? 'fw-500' : ''"
            @click="click_from_lv1(lv1_info)"
            >
            {{ lv1_info.nav_name}}
            </b-button
          >
        </b-card-header>
        <b-collapse
          v-model="lv1_info.active"
          role="tabpanel"
          accordion
          class="bgray-100"
        >
          <div
            v-for="(lv2_info, lv2_idx) in lv1_info.children"
            :key="lv2_idx"
          >
            <b-button
              @click="click_from_lv2(lv1_info, lv2_info)"
              block
              :aria-controls="`sub_i`"
              :variant= "lv2_info.selected ? 'icon-teal' : 'icon-txt'"
              class="text-left pl-3 f-95 py-1 mt-0"
              :class="
                lv2_info.selected ? 'fw-500 left-nav-indicator' : ''
              "
              >{{lv2_info.nav_name}}</b-button
            >

            <b-collapse
              v-model="lv2_info.active"
              role="tabpanel"
              accordion
              class="bgray-200 py-2 overflow-auto"
              style="max-height: calc(80vh - 254px)"
            >
              <template>
                <b-button
                  @click="click_from_lv3(lv1_info, lv2_info, lv3_info)"

                  v-for="(lv3_info, lv3_idx) in lv2_info.children"
                  :key="lv3_idx"
                  block
                  class="text-left f-85 pl-3 py-0"
                  variant="icon"
                  :class="lv3_info.selected ? 'fw-500' : ''"
                >
                  {{ lv3_info.nav_name}}
                </b-button>
              </template>
            </b-collapse>
          </div>
        </b-collapse>
      </b-card>
      

      <!-- <ChartCaseList v-if="standard_mode==='DATA_CENTER'"/> -->
      <!-- <DataCenterToC v-if="standard_mode==='DATA_CENTER'" class="mt-3"/> -->


    </div>

  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import * as ih from "@/components/util";
import SideMaterialCheck2021 from "@/components/managing_indicators/gri2021_indicators/SideMaterialCheck.vue";
import DataCenterToC from '@/components/managing_indicators/data_centers/DataCenterToC'



export default {
  components: {
    SideMaterialCheck2021,
    DataCenterToC
  },
  props: {
    report_type: { type: Object },
  },
  data() {
    return {
      loading: true,
      side_nav_info: [],
    };
  },
  created() {
    this.fetch_side_nav_info();
  },
  mounted() {
  },
  computed: {
    ...mapState("report_contents", ["report_contents"]),
    ...mapGetters("report_list", ["gri_ver", "selected_report"]),
      ...mapState("report_list", ["standard_mode", "report_code", "standard_mode"]),
    
  },
  methods: {
    ...mapMutations('report_list', ['set_standard_mode']),
    async fetch_side_nav_info() {
      this.loading = true
      try {
        let is_sasb_opened = 0
        if (this.$route.path === "/write_report/sasb") {
          is_sasb_opened = 1
        }
        const response = await axios.get(this.$store.state.backend_host + "/report/get_write_navigation_info?rc=" + this.report_code + "&is_sasb_opened=" + is_sasb_opened)
        this.side_nav_info = response.data.result;
        this.loading = false


        
      } catch (error) {
        window.alert("네비게이션 정보를 가져올 수 없습니다.")
      }


    },



    click_from_lv1(lv1_info) {
      // lv1에서는 페이지 라우팅
      if (lv1_info && this.standard_mode === lv1_info.nav_name) {
        lv1_info.active = true
        return null
      }

      if (lv1_info && lv1_info.children.length === 0) {
        
        if (lv1_info.nav_name === "SASB") {
          window.alert("SASB 지표를 추가해주세요")
          this.set_standard_mode("SASB")
          this.$router.push({ name: "SelectSasbIndicators" });
        } else if (lv1_info.nav_name === "TCFD") {
          window.alert("TCFD 지표를 추가해주세요")
          this.set_standard_mode("TCFD")
          this.$router.push({ name: "SelectTcfdIndicators" });
        } else if (lv1_info.nav_name === "Data Center") {
          this.set_standard_mode("DATA_CENTER")
          this.$router.push({ name: "WriteDataCenters" });
        }
        // this.$router.push({ name: "SelectTcfdIndicators" });
        return
      } else {
        if (lv1_info.nav_name === "SASB") {
          this.set_standard_mode("SASB")
          this.$router.push("/write_report/sasb");

        } else if (lv1_info.nav_name === "TCFD") {
          this.set_standard_mode("TCFD")
          this.$router.push("/write_report/tcfd");

        } else if (lv1_info.nav_name === "Data Center") {
          this.set_standard_mode("DATA_CENTER")
          this.$router.push({ name: "WriteDataCenters" });
        }

      }

      lv1_info.selected = true;
      lv1_info.active = true;

      lv1_info.children[0].selected = true;
      lv1_info.children[0].active = true;

    },
    click_from_lv2(lv1_info, lv2_info) {
      // lv2에서는 앵커 이동
      lv1_info.children.forEach(child => {
          if (lv2_info !== child) {
            child.selected = false;
            child.active = false;
            child.children.forEach(lv3 => {
            lv3.selected = false;
            lv3.active = false;
            })
            
          } else {
            lv2_info.selected = true;
            lv2_info.active = true;
            this.navAnchorGo("#w" + lv2_info.nav_code)
          }
      })





    },
    click_from_lv3(lv1_info, lv2_info, lv3_info) {
      // lv3도 앵커 이동
      this.navAnchorGo("#w" + lv3_info.nav_code)

    },




    navAnchorGo(anchor) {
      if (anchor && document.querySelector(anchor)) {
      let elem = document.querySelector(anchor);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    }
    },

    change_deactive() {
      
    }



    
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) { 
        for (const nav_info of this.side_nav_info) {
          for (const nav_child_info of nav_info.children) {
              this.$set(nav_child_info, 'selected', false);
              this.$set(nav_child_info, 'active', false);
          }


          if (nav_info.nav_name === "Data Center" && this.standard_mode ==="DATA_CENTER") {
            this.$set(nav_info, 'selected', true);
            this.$set(nav_info, 'active', true);
            this.$set(nav_info.children[0], 'selected', true);
            this.$set(nav_info.children[0], 'active', true);
          } else if (nav_info.nav_name !== this.standard_mode) {
            // nav_info.selected = false
            this.$set(nav_info, 'selected', false);
            this.$set(nav_info, 'active', false);

            
          }

          else {
            this.$set(nav_info, 'selected', true);
            this.$set(nav_info, 'active', true);
            this.$set(nav_info.children[0], 'selected', true);
            this.$set(nav_info.children[0], 'active', true);
          }
        }


      }
    }

  },
  updated() { },
  
};
</script>

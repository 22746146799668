<template>
    <div>
        <h4 id="bs2">디자인 테마</h4>
        <!-- {{  local_report }} <br><br> -->
        <!-- <pre>
{{  theme_option }}
</pre> -->
        <!-- {{  selected_report }} -->

        <!-- {{ selected_report }} -->
        <p class="f-95 mb-4 gray-600">보고서 디자인 테마를 설정합니다.</p>

        <b-card id="theme1" class="general-card mb-5">
            <b-card-header>디자인 테마 레이아웃 설정</b-card-header>
            <div class="py-3">
                <b-row no-gutters class="hover-gray py-2 mb-2" :class="is_layout_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">테마 레이아웃 설정</div>
                    </b-col>
                    <b-col>
                        <b-form-group label="테마 레이아웃을 설정할 수 있습니다." v-slot="{ ariaDescribedby }">
                            <b-form-radio-group v-if="is_layout_editing" id="btn-radios-2"
                                v-model="theme_option.layout_code" :options="layout_option"
                                :aria-describedby="ariaDescribedby" button-variant="outline-primary"
                                name="radio-btn-outline" buttons></b-form-radio-group>
                        </b-form-group>


                        <b-card
                            :style="`font-size: ${local_report.font_size}px; font-family:${local_report.font_family};`"
                            class="mt-2 mb-3 border-0 bgray-200">
                            <div v-if="theme_option.layout_code === 'full_view'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div class="m-1 mb-2 fw-500">
                                    <fa icon="table" class="mr-1" />기본테마 뷰 예시
                                </div>
                                <b-img src="/static_img/theme_example/default.png" v-b-modal.theme_default fluid
                                    thumbnail>
                                </b-img>


                                <b-img src="/static_img/theme_example/default_toc.png" v-b-modal.theme_default_toc fluid
                                    thumbnail>
                                </b-img>



                            </div>
                            <div v-if="theme_option.layout_code === 'sk_chemical'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div class="m-1 mb-2 fw-500">
                                    <fa icon="table" class="mr-1" />목차 중심 테마 예시
                                </div>
                                <b-img src="/static_img/theme_example/sk_chemical.png" fluid thumbnail
                                    v-b-modal.theme_sk_chemical>
                                </b-img>


                            </div>

                            <div v-if="theme_option.layout_code === 'no_image_background'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div class="m-1 mb-2 fw-500">
                                    <fa icon="table" class="mr-1" />배경색 중심의 테마 예시
                                </div>
                                <b-img src="/static_img/theme_example/no_backgorund_image.png" fluid thumbnail
                                    v-b-modal.theme_no_backgorund_image>
                                </b-img>



                                <b-img src="/static_img/theme_example/no_backgorund_image_toc.png" fluid thumbnail
                                    v-b-modal.theme_no_backgorund_image_toc>
                                </b-img>


                            </div>

                            <div v-if="theme_option.layout_code === 'gri_table'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                표지 없음
                            </div>

                            <div v-if="theme_option.layout_code === 'bh_test'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div class="m-1 mb-2 fw-500">
                                    <fa icon="table" class="mr-1" />소주제 테마 예시
                                </div>
                                <b-img src="/static_img/theme_example/new_theme.png" fluid thumbnail
                                    v-b-modal.theme_new_theme>
                                </b-img>
                                <b-img src="/static_img/theme_example/new_theme_toc.png" fluid thumbnail
                                    v-b-modal.theme_new_theme>
                                </b-img>
                            </div>
                        </b-card>
                    </b-col>

                </b-row>

                <!-- <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_layout_editing===true?'mb-3':''">
                    <b-col cols="3"><div class="gray-600">레이아웃</div> </b-col>  
                    <b-col v-if="is_layout_editing===true"> <b-form-checkbox  v-model="local_report.folded"> 전체 펼침</b-form-checkbox> </b-col>  
                    <b-col v-else> {{ local_report.folded===true?'전체 펼침':'카테고리별 접힘' }} </b-col>
                </b-row> -->
                <b-button :variant="is_layout_editing === true ? 'teal' : 'teal-border'" class="px-3 mr-3"
                    @click="handleLayoutMulti"> {{ is_layout_editing === true ? '디자인 테마 레이아웃 저장하기' : '디자인 테마 레이아웃 수정하기' }}
                </b-button>
                <b-button v-if="is_layout_editing === true" class="px-3" @click="is_layout_editing = !is_layout_editing">
                    취소 </b-button>
            </div>
        </b-card>

        <b-card id="theme2" class="general-card mb-5">
            <b-card-header>디자인 테마 기본 설정</b-card-header>
            <div class="py-3">
                <b-row no-gutters class="hover-gray py-2 mb-2" :class="is_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">컬러 설정</div>
                    </b-col>
                    <b-col>
                        <div class="d-flex mb-2">메인 컬러
                            <div v-if="is_editing === true" class="ml-4 bgray-200" style="width: 200px"><chrome-picker
                                    v-model="local_report.main_color" /></div>
                            <div v-else class="ml-4 d-flex align-items-center">
                                {{ get_color_name(local_report.main_color) }} <div class="ml-2 color-block"
                                    :style="`background-color:${get_color_name(local_report.main_color)}`"></div>
                            </div>
                        </div>
                        <div class="d-flex">보조 컬러
                            <div v-if="is_editing === true" class="ml-4 bgray-200" style="width: 200px"><chrome-picker
                                    v-model="local_report.sub_color" /></div>
                            <div v-else class="ml-4 d-flex align-items-center">
                                {{ get_color_name(local_report.sub_color) }} <div class="ml-2 color-block"
                                    :style="`background-color:${get_color_name(local_report.sub_color)}`"></div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row no-gutters class="hover-gray py-2 mb-2" :class="is_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">글씨체</div>
                    </b-col>
                    <b-col v-if="is_editing === true">
                        <b-form-select v-model="local_report.font_family"
                            :options="['Noto Sans Kr', 'Gothic A1', 'Nanum Myeongjo', 'Nanum Gothic']"></b-form-select>
                        <!-- <b-card :style="`font-family:${local_report.font_family};`" class="mt-3 border-0 bgray-200">ex. 123456789 안녕하세요, 2020 QuantifiedESG 보고서를 작성하고 있습니다! </b-card> -->
                    </b-col>
                    <b-col v-else> {{ local_report.font_family }}
                        <!-- <b-card :style="`font-family:${local_report.font_family};`" class="my-2 border-0 bgray-200">ex. 123456789 안녕하세요, 2020 QuantifiedESG 보고서를 작성하고 있습니다! </b-card> -->
                    </b-col>
                </b-row>
                <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">본문 글씨 크기</div>
                    </b-col>
                    <b-col v-if="is_editing === true">
                        <b-form-select v-model="local_report.font_size" :options="[13, 14, 15, 16, 17]"
                            style="width: calc(100% - 40px);" class="mr-1"></b-form-select> px
                        <b-card
                            :style="`font-size: ${local_report.font_size}px; font-family:${local_report.font_family};`"
                            class="mt-2 mb-3 border-0 bgray-200">ex. 123456789 안녕하세요, 2020 QuantifiedESG 보고서를 작성하고 있습니다!
                        </b-card>
                    </b-col>
                    <b-col v-else> {{ local_report.font_size }} px
                        <b-card
                            :style="`font-size: ${local_report.font_size}px; font-family:${local_report.font_family};`"
                            class="mt-2 mb-3 border-0 bgray-200">ex. 123456789 안녕하세요, 2020 QuantifiedESG 보고서를 작성하고 있습니다!
                        </b-card>
                    </b-col>
                </b-row>



                <!-- <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_editing===true?'mb-3':''">
                    <b-col cols="3"><div class="gray-600">레이아웃</div> </b-col>  
                    <b-col v-if="is_editing===true"> <b-form-checkbox  v-model="local_report.folded"> 전체 펼침</b-form-checkbox> </b-col>  
                    <b-col v-else> {{ local_report.folded===true?'전체 펼침':'카테고리별 접힘' }} </b-col>
                </b-row> -->
                <b-button :variant="is_editing === true ? 'teal' : 'teal-border'" class="px-3 mr-3" @click="handleMulti"> {{
                    is_editing === true ? '디자인 테마 저장하기' :'디자인 테마 수정하기'}} </b-button>
                <b-button v-if="is_editing === true" class="px-3" @click="handleCancle"> 취소 </b-button>
            </div>
        </b-card>

        <b-card id="theme3" class="general-card mb-5">
            <b-card-header>디자인 테마 세부 설정</b-card-header>

            <div class="py-3">
                <!-- <b-row no-gutters class="hover-gray py-2 mb-2" :class="is_detail_editing===true?'mb-3':''">
                    <b-col cols="3"><div class="gray-600">표지 이미지</div> </b-col>
                    <b-col v-if="is_detail_editing===true"> <b-form-file placeholder="표지 이미지" v-model="local_report.featured_image"></b-form-file> </b-col>  
                    <b-col v-else>
                        <template v-if="local_report.featured_image_name">
                            <b-img :src="local_report.featured_image_path" fluid></b-img>
                            <p class="f-85"> {{ local_report.featured_image_name }} </p>
                        </template>
<template v-else><p class="mb-0 gray-550"> (등록된 이미지가 없습니다.)</p></template>
</b-col>
</b-row> -->
                <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_detail_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">Title 글씨 크기</div>
                    </b-col>
                    <b-col v-if="is_detail_editing === true">
                        <div style="display: flex;">
                            <b-form-input id="title_size" class="mr-3" v-model="theme_option.title_size" type="number"
                                step="0.2"></b-form-input>
                            <label for="title_size">rem</label>
                        </div>
                        <b-card
                            :style="`font-size: ${theme_option.title_size}rem; font-family:${theme_option.title_size};`"
                            class="mt-2 mb-3 border-0 bgray-200">ex. 2020 QuantifiedESG 보고서 </b-card>
                    </b-col>
                    <b-col v-else> {{ theme_option.title_size }} rem
                        <b-card
                            :style="`font-size: ${theme_option.title_size}rem; font-family:${theme_option.title_size};`"
                            class="mt-2 mb-3 border-0 bgray-200">
                            ex. 123456789 안녕하세요, 2020 QuantifiedESG 보고서를 작성하고 있습니다!
                        </b-card>

                    </b-col>
                </b-row>

                <b-row no-gutters class="hover-gray py-2 mb-2" :class="is_detail_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">Title 컬러 설정</div>
                    </b-col>
                    <b-col>
                        <div class="d-flex mb-2">
                            <div v-if="is_detail_editing === true" class="ml-4 bgray-200" style="width: 200px">
                                <chrome-picker v-model="theme_option.title_color" /></div>
                            <div v-else class="ml-4 d-flex align-items-center">
                                {{ get_color_name(theme_option.title_color) }}<div class="ml-2 color-block"
                                    :style="`background-color:${get_color_name(theme_option.title_color)}`">
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>


                <b-row no-gutters class="hover-gray py-2 mb-2" :class="is_detail_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">네비게이션 바 컬러 설정</div>
                    </b-col>
                    <b-col>
                        <div class="d-flex mb-2">
                            <div v-if="is_detail_editing === true" class="ml-4 bgray-200" style="width: 200px">
                                <chrome-picker v-model="theme_option.nav_bar_color" /></div>
                            <div v-else class="ml-4 d-flex align-items-center">
                                {{ get_color_name(theme_option.nav_bar_color) }}<div class="ml-2 color-block"
                                    :style="`background-color:${get_color_name(theme_option.nav_bar_color)}`">
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>

                <!-- <b-row no-gutters class="hover-gray py-2 mb-2" :class="is_detail_editing===true?'mb-3':''">
                    <b-col cols="3"><div class="gray-600">보고서 배경화면 컬러 설정</div> </b-col>  
                    <b-col>
                        <div class="d-flex mb-2">
                            <div v-if="is_detail_editing===true" class="ml-4 bgray-200" style="width: 200px"><chrome-picker v-model="theme_option.viewer_background_color" /></div>
                            <div v-else class="ml-4 d-flex align-items-center">{{get_color_name(theme_option.viewer_background_color)}}<div class="ml-2 color-block" :style="`background-color:${get_color_name(theme_option.viewer_background_color)}`"></div></div>
                        </div>
                    </b-col>  
                </b-row> -->

                <!-- <b-row no-gutters class="hover-gray py-2 mb-2" :class="is_detail_editing===true?'mb-3':''">
                    <b-col cols="3"><div class="gray-600">텍스트 컬러 설정</div> </b-col>  
                    <b-col>
                        <div class="d-flex mb-2">
                            <div v-if="is_detail_editing===true" class="ml-4 bgray-200" style="width: 200px"><chrome-picker v-model="theme_option.text_color" /></div>
                            <div v-else class="ml-4 d-flex align-items-center">{{get_color_name(theme_option.text_color)}}<div class="ml-2 color-block" :style="`background-color:${get_color_name(theme_option.text_color)}`"></div></div>
                        </div>
                    </b-col>  
                </b-row> -->

                <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_detail_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">중요 주제 스타일 옵션 </div>
                    </b-col>
                    <b-col>
                        <b-form-group label="중요 주제 스타일 옵션을 설정할 수 있습니다." v-slot="{ ariaDescribedby }">
                            <b-form-radio-group v-if="is_detail_editing" id="btn-radios-2"
                                v-model="theme_option.material_style_option" :options="material_style_options"
                                :aria-describedby="ariaDescribedby" button-variant="outline-primary"
                                name="radio-btn-outline" buttons></b-form-radio-group>
                        </b-form-group>


                        <b-card
                            :style="`font-size: ${local_report.font_size}px; font-family:${local_report.font_family};`"
                            class="mt-2 mb-3 border-0 bgray-200">
                            <div v-if="theme_option.material_style_option === 'outline'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div class="m-1 mb-2 fw-500">
                                    <fa icon="table" class="mr-1" />아코디언 테이블 예시 👈(클릭)
                                </div>
                                <b-collapse visible id="collapse-3">
                                    <b-table-simple responsive class="edi-table">
                                        <b-thead>
                                            <b-tr>
                                                <b-td colspan="2" class="py-1 pl-1 f-120 fw-600"
                                                    :style="`background-color: white; color: ${selected_report.main_color}; border-top: 1px solid ${selected_report.main_color} !important; border-bottom: 1px solid ${selected_report.main_color}; border-right: 0px; border-left: 0px;`">
                                                    1. 중요지표
                                                </b-td>
                                            </b-tr>
                                        </b-thead>
                                    </b-table-simple>
                                </b-collapse>
                            </div>
                            <div v-if="theme_option.material_style_option === 'fill'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div class="m-1 mb-2 fw-500">
                                    <fa icon="table" class="mr-1" />아코디언 테이블 예시 👈(클릭)
                                </div>
                                <b-collapse visible id="collapse-3">
                                    <b-table-simple responsive class="edi-table">
                                        <b-thead>
                                            <b-tr>
                                                <b-td colspan="2" class="py-1 pl-1 f-120 fw-600"
                                                    :style="`color: white; background-color: ${selected_report.main_color}; border-top: 1px solid ${selected_report.main_color} !important; border-bottom: 1px solid ${selected_report.main_color}; border-right: 0px; border-left: 0px;`">
                                                    1. 중요지표
                                                </b-td>
                                            </b-tr>
                                        </b-thead>
                                    </b-table-simple>
                                </b-collapse>
                            </div>
                            <div v-if="theme_option.material_style_option === 'sideline'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div class="m-1 mb-2 fw-500">
                                    <fa icon="table" class="mr-1" />아코디언 테이블 예시 👈(클릭)
                                </div>
                                <b-collapse visible id="collapse-3">
                                    <b-table-simple responsive class="edi-table">
                                        <b-thead>
                                            <b-tr>
                                                <b-td colspan="2" class="py-1 pl-1 f-120 fw-600"
                                                    :style="`background-color: white; border-left: 3px solid ${this.selected_report.main_color}; border-bottom: 1px solid ${selected_report.sub_color};border-top: 0px; border-right: 0px;`">
                                                    1. 중요지표
                                                </b-td>
                                            </b-tr>
                                        </b-thead>
                                    </b-table-simple>
                                </b-collapse>
                            </div>

                        </b-card>
                    </b-col>
                </b-row>




                <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_detail_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">테이블 스타일 옵션</div>
                    </b-col>
                    <b-col>
                        <b-form-group label="테이블 스타일 옵션을 설정할 수 있습니다." v-slot="{ ariaDescribedby }">
                            <b-form-radio-group v-if="is_detail_editing" id="btn-radios-2"
                                v-model="theme_option.table_style" :options="table_style_options"
                                :aria-describedby="ariaDescribedby" button-variant="outline-primary"
                                name="radio-btn-outline" buttons></b-form-radio-group>
                        </b-form-group>


                        <b-card
                            :style="`font-size: ${local_report.font_size}px; font-family:${local_report.font_family};`"
                            class="mt-2 mb-3 border-0 bgray-200">
                            <div v-if="theme_option.table_style === 'basic_table'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div class="m-1 mb-2 fw-500">
                                    <fa icon="table" class="mr-1" />베이직 스타일
                                </div>
                                <b-collapse visible id="collapse-3">
                                    <b-table-simple responsive class="edi-table">
                                        <b-thead>
                                            <b-tr>
                                                <b-td>헤더1</b-td><b-td>헤더2</b-td><b-td>헤더3</b-td>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="(p, i) in [1, 2, 3, 4]" :key="i">
                                                <b-td>내용{{ i + 1 }}</b-td><b-td>내용{{ i + 2 }}</b-td><b-td>내용{{ i + 3 }}</b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-collapse>
                            </div>
                            <div v-if="theme_option.table_style === 'custom_table'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div class="m-1 mb-2 fw-500">
                                    <fa icon="table" class="mr-1" />커스텀 스타일
                                </div>


                                <table class="mb-5">
                                    <tr>
                                        <td>라인 보이기</td>
                                        <td>
                                            <b-form-checkbox v-model="theme_option.show_table_line" name="check-button"
                                                switch />
                                        </td>

                                        <td>아웃라인 보이기</td>
                                        <td>
                                            <b-form-checkbox v-model="theme_option.show_table_outline"
                                                name="check-button" switch />
                                        </td>


                                    </tr>
                                    <tr>
                                        <td>헤더 배경 색상 선택</td>
                                        <td>
                                            <div class="d-flex mb-2">
                                                <div v-if="is_detail_editing === true" class="ml-4 bgray-200"
                                                    style="width: 150px">
                                                    <chrome-picker v-model="theme_option.table_header_color" />
                                                </div>
                                                <div v-else class="ml-4 d-flex align-items-center">
                                                    {{ get_color_name(theme_option.table_header_color) }}<div
                                                        class="ml-2 color-block"
                                                        :style="`background-color:${get_color_name(theme_option.table_header_color)}`">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>헤더 글자 색상 선택</td>
                                        <td>
                                            <div class="d-flex mb-2">
                                                <div v-if="is_detail_editing === true" class="ml-4 bgray-200"
                                                    style="width: 150px">
                                                    <chrome-picker v-model="theme_option.table_header_font_color" />
                                                </div>
                                                <div v-else class="ml-4 d-flex align-items-center">
                                                    {{ get_color_name(theme_option.table_header_font_color) }}<div
                                                        class="ml-2 color-block"
                                                        :style="`background-color:${get_color_name(theme_option.table_header_font_color)}`">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>

                                <b-collapse visible id="collapse-3">
                                    <b-table-simple responsive class="no-line-table"
                                        :borderless="!theme_option.show_table_line"
                                        :outlined="theme_option.show_table_outline">
                                        <b-thead>
                                            <b-tr>
                                                <b-td
                                                    :style="true ? `background-color: ${get_color_name(theme_option.table_header_color)}; !important; color: ${get_color_name(theme_option.table_header_font_color)};` : ''">
                                                    헤더1
                                                </b-td>
                                                <b-td
                                                    :style="true ? `background-color: ${get_color_name(theme_option.table_header_color)}; !important; color: ${get_color_name(theme_option.table_header_font_color)};` : ''">
                                                    헤더2
                                                </b-td>
                                                <b-td
                                                    :style="true ? `background-color: ${get_color_name(theme_option.table_header_color)}; !important; color: ${get_color_name(theme_option.table_header_font_color)};` : ''">
                                                    헤더3
                                                </b-td>

                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="(p, i) in [1, 2, 3, 4]" :key="i">
                                                <b-td>내용{{ i + 1 }}</b-td><b-td>내용{{ i + 2 }}</b-td><b-td>내용{{ i + 3 }}</b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-collapse>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>











                <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_detail_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">테이블 동작 옵션</div>
                    </b-col>
                    <b-col>
                        <b-form-group label="테이블 동작 옵션을 설정할 수 있습니다." v-slot="{ ariaDescribedby }">
                            <b-form-radio-group v-if="is_detail_editing" id="btn-radios-2"
                                v-model="theme_option.table_mode" :options="link_options"
                                :aria-describedby="ariaDescribedby" button-variant="outline-primary"
                                name="radio-btn-outline" buttons></b-form-radio-group>
                        </b-form-group>


                        <b-card
                            :style="`font-size: ${local_report.font_size}px; font-family:${local_report.font_family};`"
                            class="mt-2 mb-3 border-0 bgray-200">
                            <div v-if="theme_option.table_mode === 'accordion'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div class="m-1 mb-2 fw-500">
                                    <fa icon="table" class="mr-1" />아코디언 테이블 예시 👈(클릭)
                                </div>
                                <b-collapse visible id="collapse-3">
                                    <b-table-simple responsive class="edi-table">
                                        <b-thead>
                                            <b-tr>
                                                <b-td>헤더1</b-td><b-td>헤더2</b-td><b-td>헤더3</b-td>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="(p, i) in [1, 2, 3, 4]" :key="i">
                                                <b-td>내용{{ i + 1 }}</b-td><b-td>내용{{ i + 2 }}</b-td><b-td>내용{{ i + 3 }}</b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-collapse>
                            </div>
                            <div v-if="theme_option.table_mode === 'popup'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div v-b-modal.modal-center class="m-1 mb-2 fw-500">
                                    <fa icon="table" class="mr-1" />팝업 테이블 예시 👈(클릭)
                                </div>
                                <b-modal id="modal-center" centered :title="'예시 테이블'" hide-footer>
                                    <b-table-simple responsive class="edi-table">
                                        <b-thead>
                                            <b-tr>
                                                <b-td>헤더1</b-td><b-td>헤더2</b-td><b-td>헤더3</b-td>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="(p, i) in [1, 2, 3, 4]" :key="i">
                                                <b-td>내용{{ i + 1 }}</b-td><b-td>내용{{ i + 2 }}</b-td><b-td>내용{{ i + 3 }}</b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-modal>
                            </div>
                            <div v-if="theme_option.table_mode === 'newtab'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div v-b-modal.modal-center class="m-1 mb-2 fw-500">
                                    <fa icon="table" class="mr-1" />

                                    <!--  -->
                                    <a href="https://report.qesg.co.kr/form_gallery/?type=dtable&id=104"
                                        target="_blank"> 테이블 새창에서 띄우기
                                        예시 👈(클릭)</a>
                                </div>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>

                <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_detail_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">비즈니스 사례 동작 옵션</div>
                    </b-col>
                    <b-col>
                        <b-form-group label="비즈니스 사례 동작 옵션을 설정할 수 있습니다." v-slot="{ ariaDescribedby }">
                            <b-form-radio-group v-if="is_detail_editing" id="btn-radios-2"
                                v-model="theme_option.business_case_mode" :options="link_options"
                                :aria-describedby="ariaDescribedby" button-variant="outline-primary"
                                name="radio-btn-outline" buttons></b-form-radio-group>
                        </b-form-group>

                        <b-card
                            :style="`font-size: ${local_report.font_size}px; font-family:${local_report.font_family};`"
                            class="mt-2 mb-3 border-0 bgray-200">
                            <div v-if="theme_option.business_case_mode === 'accordion'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div v-b-toggle.collapse-4 class="m-1 mb-2 fw-500">
                                    <!-- <fa icon="quote-left" class="mr-1"/> -->
                                    <!-- <b-badge class="mr-2">Case.</b-badge> -->
                                    <b-icon class="mr-1" icon="blockquote-left" scale="1.6" />


                                    비즈니스 사례 아코디언 예시 👈(클릭)
                                </div>
                                <b-collapse visible id="collapse-4">


                                    <div class="p-3">
                                        <b-img src="/static_img/qesg.png" />
                                        <div class="bc-simple-content pb-4 pt-4">
                                            <p>안녕하세요, 퀀티파이드이에스지입니다.</p>
                                            <p><strong>환경, 사회, 지배구조(ESG) 정보는 기업 지속가능성(Sustainability)의 근간</strong> 이지만,
                                                <strong>데이터의
                                                    비정형적 특성으로 인하여 </strong>정보의 생성과 활용의 양 측면에서 실패하고 있습니다. 정보제공자(기업)는 투입하는
                                                자원에 비하여 적절한 효익을
                                                얻지 못하고 있으며, 클라이언트, 소비자, 지역사회, 투자자 등 정보이용자는 필요로 하는 정보의 획득과 활용에 어려움을 겪고
                                                있습니다.</p>
                                            <p><strong>퀀티파이드이에스지는</strong> ESG 데이터 생성과 활용 과정에서 발생하는 <strong>데이터 부족, 질적
                                                    차이(Gap), 파편화 문제를
                                                    혁신적으로 해결하고자 하는 스타트업</strong>입니다. 기존의 일회적 ESG 보고를 넘어, 기술 기반 ESG 정보
                                                생성, 교환, 검증, 저장
                                                솔루션과 서비스를 제공하여 시장을 혁신하고자 합니다.</p>
                                            <p>정보이용자들은 지속가능성보고서 등 인쇄물에 기반한 고정적 정보 뿐만 아니라, 인공지능을 활용한 다양한 비고정적 기업 정보를 수집하는
                                                단계로 나아가고 있습니다.
                                                퀀티파이드이에스지는 <strong>공급망 내 ESG 원칙 적용 및 실사</strong>와 같은 기업간 정보 교환,
                                                <strong>전사 범위 ESG 전략 수립,
                                                    ESG 데이터의 체계적 수집과 관리, 웹 리포팅 등 새로운 채널의 정보 보고까지</strong> 전문 서비스를 제공하여
                                                여러분을 돕겠습니다.</p>
                                            <p>감사합니다.</p>
                                            <p></p>
                                            <p><strong>퀀티파이드이에스지 대표</strong></p>
                                            <p><strong>배익현</strong></p>
                                        </div><!---->
                                    </div>

                                </b-collapse>
                            </div>
                            <div v-if="theme_option.business_case_mode === 'popup'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div v-b-modal.modal-center class="m-1 mb-2 fw-500">
                                    <!-- <fa icon="quote-left" class="mr-1"/> -->
                                    <!-- <b-badge class="mr-2">Case.</b-badge> -->
                                    <b-icon class="mr-1" icon="blockquote-left" scale="1.6" />

                                    비즈니스 사례 팝업 예시 👈(클릭)
                                </div>
                                <b-modal id="modal-center" centered :title="'비즈니스 사례 예시'" hide-footer>
                                    <div class="p-3">
                                        <b-img src="/static_img/qesg.png" />
                                        <div class="bc-simple-content pb-4 pt-4">
                                            <p>안녕하세요, 퀀티파이드이에스지입니다.</p>
                                            <p><strong>환경, 사회, 지배구조(ESG) 정보는 기업 지속가능성(Sustainability)의 근간</strong> 이지만,
                                                <strong>데이터의
                                                    비정형적 특성으로 인하여 </strong>정보의 생성과 활용의 양 측면에서 실패하고 있습니다. 정보제공자(기업)는 투입하는
                                                자원에 비하여 적절한 효익을
                                                얻지 못하고 있으며, 클라이언트, 소비자, 지역사회, 투자자 등 정보이용자는 필요로 하는 정보의 획득과 활용에 어려움을 겪고
                                                있습니다.</p>
                                            <p><strong>퀀티파이드이에스지는</strong> ESG 데이터 생성과 활용 과정에서 발생하는 <strong>데이터 부족, 질적
                                                    차이(Gap), 파편화 문제를
                                                    혁신적으로 해결하고자 하는 스타트업</strong>입니다. 기존의 일회적 ESG 보고를 넘어, 기술 기반 ESG 정보
                                                생성, 교환, 검증, 저장
                                                솔루션과 서비스를 제공하여 시장을 혁신하고자 합니다.</p>
                                            <p>정보이용자들은 지속가능성보고서 등 인쇄물에 기반한 고정적 정보 뿐만 아니라, 인공지능을 활용한 다양한 비고정적 기업 정보를 수집하는
                                                단계로 나아가고 있습니다.
                                                퀀티파이드이에스지는 <strong>공급망 내 ESG 원칙 적용 및 실사</strong>와 같은 기업간 정보 교환,
                                                <strong>전사 범위 ESG 전략 수립,
                                                    ESG 데이터의 체계적 수집과 관리, 웹 리포팅 등 새로운 채널의 정보 보고까지</strong> 전문 서비스를 제공하여
                                                여러분을 돕겠습니다.</p>
                                            <p>감사합니다.</p>
                                            <p></p>
                                            <p><strong>퀀티파이드이에스지 대표</strong></p>
                                            <p><strong>배익현</strong></p>
                                        </div><!---->
                                    </div>
                                </b-modal>
                            </div>
                            <div v-if="theme_option.business_case_mode === 'newtab'" class="hj-modal-body p-3"
                                style="background-color: white;">
                                <div v-b-modal.modal-center class="m-1 mb-2 fw-500">
                                    <!-- <fa icon="quote-left" class="mr-1"/> -->
                                    <!-- <b-badge class="mr-2">Case.</b-badge> -->
                                    <b-icon class="mr-1" icon="blockquote-left" scale="1.6" />



                                    <!--  -->
                                    <a href="https://report.qesg.co.kr/form_gallery/?type=bsc&id=100" target="_blank">
                                        비즈니스 사례 새창에서 띄우기
                                        예시 👈(클릭)</a>
                                </div>
                            </div>
                        </b-card>

                    </b-col>
                </b-row>
                <!-- <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_detail_editing===true?'mb-3':''" >
                    <b-col cols="3"><div class="gray-600">비즈니스 사례 배경 컬러 </div> </b-col>
                    <b-col>
                        <div class="d-flex mb-2">
                            <div v-if="is_detail_editing===true" class="ml-4 bgray-200" style="width: 200px"><chrome-picker v-model="theme_option.business_case_background" /></div>
                            <div v-else class="ml-4 d-flex align-items-center">{{get_color_name(theme_option.business_case_background)}} <div class="ml-2 color-block" :style="`background-color:${get_color_name(theme_option.business_case_background)}`"></div></div>
                        </div>
                    </b-col>
                </b-row> -->

                <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_detail_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">언어 전환 버튼</div>
                    </b-col>
                    <b-col v-b-tooltip.hover.bottom title="언어 전환 버튼을 보여줄지 감출지 정할 수 있습니다.">
                        <div v-if="is_detail_editing">
                            <b-form-checkbox v-model="theme_option.show_translation_btn" name="check-button" switch />
                        </div>
                        <div v-else>
                            {{ theme_option.show_translation_btn === true ? "보이기" : "감추기" }}
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <div class="gray-600">출력 버튼</div>
                    </b-col>
                    <b-col v-b-tooltip.hover.bottom title="출력 버튼을 보여줄지 감출지 정할 수 있습니다.">
                        <div v-if="is_detail_editing">

                            <b-form-checkbox v-model="theme_option.show_printing_btn" name="check-button" switch />
                        </div>
                        <div v-else>
                            {{ theme_option.show_printing_btn === true ? "보이기" : "감추기" }}
                        </div>
                    </b-col>
                </b-row>


                <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_detail_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">
                        <div class="gray-600">배경화면 설정</div>
                    </b-col>
                    <b-col v-if="is_detail_editing" v-b-tooltip.hover.top title="가로 2000, 세로 1000 비율을 권장합니다.">

                        <b-row>
                            <b-col>
                                <b-form-checkbox v-model="theme_option.cover_active" name="check-button" switch />
                            </b-col>
                            <b-col cols="10">
                                <b-form-file type="text" v-model="theme_option.cover" @change="handleCoverChange"
                                    class="narrow-input-form" accept="image/*" />
                            </b-col>
                        </b-row>

                        <b-img id="cover-background"
                            v-if="theme_option.cover_path && theme_option.cover_path.startsWith('blob')"
                            :style="`opacity:${this.theme_option.cover_alpha}`" :src="theme_option.cover_path" fluid />

                        <b-img id="cover-background"
                            v-if="theme_option.cover_path && !theme_option.cover_path.startsWith('blob')"
                            :style="`opacity:${this.theme_option.cover_alpha}`"
                            :src="$store.state.static_host + theme_option.cover_path" fluid />
                        <p v-if="theme_option.cover_name" class="my-1 f-85">{{ theme_option.cover_name }} </p>

                        <div v-if="theme_option.cover_path">
                            <label for="range-1">투명도 설정</label>
                            <b-form-input id="range-1" v-model="temp_alpha" type="range" min="0" max="1"
                                step="0.01"></b-form-input>
                        </div>

                    </b-col>

                    <b-col v-if="!is_detail_editing">
                        <b-row>
                            {{ theme_option.cover_active === true ? "보이기" : "감추기" }}
                        </b-row>
                        <b-row>
                            <b-img id="cover-background"
                                v-if="theme_option.cover_path && !theme_option.cover_path.startsWith('blob')"
                                :src="$store.state.static_host + theme_option.cover_path" fluid style="opacity: 0.4;"
                                :style="`opacity:${this.theme_option.cover_alpha}`" />
                            <p v-if="theme_option.cover_name" class="my-1 f-85">{{ theme_option.cover_name }} </p>
                        </b-row>

                    </b-col>


                </b-row>

                <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_detail_editing === true ? 'mb-3' : ''">
                    <b-col cols="3">다크로고 업로드<div class="gray-600"></div> </b-col>
                    <b-col v-if="is_detail_editing" v-b-tooltip.hover.bottom
                        title="로고 배경색을 어둡게 설정할 수 있습니다. (로고 이미지를 변경하려면 기본설정 > 보고서 설정 > 기본정보 > 보고서 기본 정보 > 기본정보 수정하기 버튼 > CI/로고 설정)">
                        <b-form-file type="text" v-model="theme_option.dark_logo" @change="handleDarkLogoChange"
                            class="narrow-input-form" accept="image/*" />
                        <b-form-checkbox v-if="theme_option.dark_logo_path" class="switch-color"
                            v-model="theme_option.is_dark_option" name="check-button" switch>
                            <span v-if="theme_option.is_dark_option">dark logo</span><span v-else>light logo</span>
                        </b-form-checkbox>

                        <b-card :style="{ background: theme_option.is_dark_option ? 'black' : 'transparent' }"
                            class="mt-2 mb-3 border-0">
                            <b-img id="cover-background"
                                v-if="theme_option.dark_logo_path && theme_option.dark_logo_path.startsWith('blob')"
                                :style="`opacity:${this.theme_option.cover_alpha}`" :src="theme_option.dark_logo_path"
                                fluid />

                            <b-img id="cover-background"
                                v-if="theme_option.dark_logo_path && !theme_option.dark_logo_path.startsWith('blob')"
                                :style="`opacity:${this.theme_option.cover_alpha}`"
                                :src="$store.state.static_host + theme_option.dark_logo_path" fluid />
                        </b-card>
                        <p v-if="theme_option.dark_logo_name" class="my-1 f-85">{{ theme_option.dark_logo_name }} </p>

                    </b-col>
                    <b-col v-else v-b-tooltip.hover.bottom
                        title="로고 배경색을 어둡게 설정할 수 있습니다. (로고 이미지를 변경하려면 기본설정 > 보고서 설정 > 기본정보 > 보고서 기본 정보 > 기본정보 수정하기 버튼 > CI/로고 설정)">
                        <b-card :style="{ background: theme_option.is_dark_option ? 'black' : 'transparent' }"
                            class="mt-2 mb-3 border-0">
                            <b-img id="dark-logo"
                                v-if="theme_option.dark_logo_path && theme_option.dark_logo_path.startsWith('blob')"
                                :src="theme_option.dark_logo_path" fluid />

                            <b-img id="dark-logo"
                                v-if="theme_option.dark_logo_path && !theme_option.dark_logo_path.startsWith('blob')"
                                :src="$store.state.static_host + theme_option.dark_logo_path" fluid />
                        </b-card>
                        <p v-if="theme_option.dark_logo_name" class="my-1 f-85">{{ theme_option.dark_logo_name }} </p>

                    </b-col>
                </b-row>

                <!--TODO: 커스텀 스타일 시트 잠시 보류! -->
                <!-- <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_detail_editing===true?'mb-3':''" >
                    <b-col cols="3"><div class="gray-600">custom style sheet</div> </b-col>
                    <b-col v-b-tooltip.hover.bottom title="css 세부 설정을 할 수 있습니다.">
                        <b-form-checkbox  v-model="theme_option.custom_style_sheet" name="check-button" switch >
                        </b-form-checkbox>
                        <b-card :style="`font-size: ${theme_option.title_size}px; font-family:${theme_option.title_size};`" class="mt-2 mb-3 border-0 bgray-200">custom css editor페이지 이동</b-card>
                    </b-col>
                </b-row> -->


                <!-- <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_detail_editing===true?'mb-3':''">
                    <b-col cols="3"><div class="gray-600">레이아웃</div> </b-col>  
                    <b-col v-if="is_detail_editing===true"> <b-form-checkbox  v-model="local_report.folded"> 전체 펼침</b-form-checkbox> </b-col>  
                    <b-col v-else> {{ local_report.folded===true?'전체 펼침':'카테고리별 접힘' }} </b-col>
                </b-row> -->
                <b-button :variant="is_detail_editing === true ? 'teal' : 'teal-border'" class="px-3 mr-3"
                    @click="handleDetailMulti"> {{
                        is_detail_editing === true ? '테마 세부설정 저장하기' : '테마 세부설정 수정하기'}} </b-button>
                <b-button v-if="is_detail_editing === true" class="px-3" @click="is_detail_editing = false"> 취소
                </b-button>
            </div>
        </b-card>

        <b-card id="theme2" class="general-card mb-5">
            <b-card-header>
                커스텀 css 설정
                <b-badge variant="info">Beta</b-badge>
            </b-card-header>
            <div class="py-3">
                <b-button variant="teal" class="px-3 mr-3" @click="handleCustomCss"> 커스텀 css 편집 페이지 이동 </b-button>
            </div>
        </b-card>


        <!-- 디자인 테마 이미지 모달 -->
        <b-modal id="theme_default" size="lg" hide-footer hide-header centered>
            <b-img src="/static_img/theme_example/default.png" fluid />
        </b-modal>

        <b-modal id="theme_default_toc" size="lg" hide-footer hide-header centered>
            <b-img src="/static_img/theme_example/default_toc.png" fluid />
        </b-modal>

        <b-modal id="theme_sk_chemical" size="lg" hide-footer hide-header centered>
            <b-img src="/static_img/theme_example/sk_chemical.png" fluid />
        </b-modal>

        <b-modal id="theme_no_backgorund_image" size="lg" hide-footer hide-header centered>
            <b-img src="/static_img/theme_example/no_backgorund_image.png" fluid />
        </b-modal>

        <b-modal id="theme_no_backgorund_image_toc" size="lg" hide-footer hide-header centered>
            <b-img src="/static_img/theme_example/no_backgorund_image_toc.png" fluid />
        </b-modal>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import * as ih from '@/components/util'
import { Chrome } from 'vue-color'
import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'


export default {
    mixins: [gri_mixin],
    watch: {
        temp_alpha: {
            immediate: true,
            handler(newV) {
                if (this.theme_option.cover_path !== null && document.getElementById("cover-background")) {
                    document.getElementById("cover-background").style.opacity = Number(newV);
                    this.theme_option.cover_alpha = Number(newV);
                    this.theme_option = {
                        ...this.theme_option,
                    }

                }
            }
        }
    },
    data() {
        return {
            previewBackgroundUrl: null,
            is_layout_editing: false,
            is_editing: false,
            is_detail_editing: false,
            local_report: {},
            temp_alpha: 1,
            theme_option: {
                "show_translation_btn": false,
                "show_printing_btn": false,
                "cover": null,
                "cover_path": null,
                "cover_name": null,

                "dark_logo": null,
                "dark_logo_path": null,
                "dark_logo_name": null,

                "cover_alpha": 1,
                "is_dark_option": false,

                "material_style_option": "outline",

                "table_style": "basic_table",
                "show_table_line": true,
                "show_table_outline": false,
                "table_header_color": "#f5f5f5",
                "table_header_font_color": "#fffff",


                "table_mode": "accordion",
                "business_case_mode": "accordion",
                "business_case_background": "#040404",

                "custom_style_sheet": false,
                "title_color": "#040404",
                "title_size": 2.5,
                "layout_option": "full_view",

                "nav_bar_color": "#e9e9e9",
                "viewer_background_color": "#ffffff"

            },
            cover_file: "",
            material_style_options: [
                { text: '아웃라인', value: 'outline' },
                { text: '채우기', value: 'fill' },
                { text: '왼쪽라인', value: 'sideline' },

            ],
            link_options: [
                { text: '펼침', value: 'accordion' },
                { text: '팝업창', value: 'popup' },
                { text: '새창', value: 'newtab' }
            ],
            layout_option: [
                { text: '기본 테마', value: 'full_view' },
                { text: '목차 중심 테마', value: 'sk_chemical' },
                { text: '배경색 중심 테마', value: 'no_image_background' },
                // { text: '표지없음', value: 'gri_table' }
                { text: '소주제 테마', value: 'bh_test' }
            ],
            table_style_options: [
                { text: '기본 테이블', value: 'basic_table' },
                { text: '커스텀 테이블', value: 'custom_table' }
            ]




        }
    },
    computed: {
        ...mapState('report_list', ['report_code']),
        ...mapGetters('report_list', ['selected_report']),



    },
    mounted() {
        this.get_report_theme_info();
    },
    methods: {
        ...mapActions('report_list', ['saveReportPreference']),
        showDarkLogo() {

        },
        handleFileChange(e) {

            this.theme_option.cover = e.target.files[0]
            this.theme_option.cover_path = URL.createObjectURL(e.target.files[0]);
            this.theme_option.cover_name = e.target.files[0].name

            this.theme_option = {
                ...this.theme_option,
            }
        },
        get_color_name(color) {
            if (color != null && typeof color === 'object') {
                return color.hex8
            } else return color
        },
        get_report_theme_info() {
            axios.get(this.$store.state.backend_host + "/custom_theme/get_theme_info?report_code=" + this.report_code)
                .then((result) => {
                    if (result.data.code) {
                        this.theme_option = {
                            ...this.theme_option,
                            ...result.data.theme_info,
                        }
                        this.temp_alpha = this.theme_option.cover_alpha
                    } else {
                        window.alert(result.data.message);

                    }

                })
                .catch(e => {
                    window.alert(e)
                })


        },
        handleLayoutMulti() {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return

            if (this.is_layout_editing) {
                const path = this.$store.state.backend_host + "/custom_theme/update_layout";
                return axios.post(path, {
                    "layout_code": this.theme_option.layout_code,
                    "report_code": this.selected_report.report_code
                }).then((response) => {
                    if (response.data.code === false) {
                        this.$EventBus.$emit('make-toast', '디자인 테마 레이아웃 변경 실패', '잠시 후 다시 시도해주세요.');
                    } else {
                        this.is_layout_editing = false
                        this.$EventBus.$emit('make-toast', '디자인 테마 레이아웃 변경 완료', '디자인 테마 레이아웃이 변경되었습니다. 뷰어페이지에서 확인해주세요.');
                    }
                }).catch((error) => {
                    this.$EventBus.$emit('make-toast', '디자인 테마 레이아웃 변경 실패', '서버와의 통신 중 오류가 발생했습니다.');
                });
            } else {
                this.is_layout_editing = true
            }
        },
        handleDetailMulti() {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return

            if (this.is_detail_editing) {
                // if(logo != null && logo != undefined)
                // formData.append('logo', logo, logo.name)


                const color_list = [
                    "business_case_background", "title_color",
                    "nav_bar_color", "viewer_background_color",
                    "table_header_color", "table_header_font_color"
                ]


                color_list.map(key => {
                    this.theme_option[key] = this.get_color_name(this.theme_option[key])
                })
                // this.theme_option["business_case_background"] = this.get_color_name(this.theme_option["business_case_background"])
                // this.theme_option["title_color"] = this.get_color_name(this.theme_option["title_color"])
                // this.theme_option["nav_bar_color"] = this.get_color_name(this.theme_option["nav_bar_color"])
                // this.theme_option["viewer_background_color"] = this.get_color_name(this.theme_option["viewer_background_color"])


                const formData = ih.objectToFormData({
                    ...this.theme_option,
                    "report_code": this.selected_report.report_code
                })

                if (this.theme_option.cover != null && this.theme_option.cover != undefined) {
                    formData.append('cover', this.theme_option.cover, this.theme_option.cover_name)
                }

                if (this.theme_option.dark_logo != null && this.theme_option.dark_logo != undefined) {
                    formData.append('dark_logo', this.theme_option.dark_logo, this.theme_option.dark_logo_name)
                }
                const path = this.$store.state.backend_host + "/custom_theme/update_layout_detail";
                return axios.post(path, formData).then((response) => {
                    this.is_detail_editing = false
                    if (response.data.code) {
                        this.$EventBus.$emit('make-toast', '테마 세부사항 변경 완료', '테마 세부사항이 변경되었습니다. 뷰어페이지에서 확인해주세요.')
                    } else {
                        this.$EventBus.$emit('make-toast', '테마 세부사항 변경 실패', '잠시 후 다시 시도해주세요.')
                    }
                }).catch((e) => {
                    this.$EventBus.$emit('make-toast', '테마 세부사항 변경 실패', '서버에 문제가 생겼습니다. 잠시 후 다시 시도해주세요.' + e)
                });


            } else {
                this.is_detail_editing = true
            }


        },
        handleCoverChange(e) {
            this.theme_option.cover_active = true
            this.theme_option.cover = e.target.files[0]
            this.theme_option.cover_path = URL.createObjectURL(e.target.files[0]);
            this.theme_option.cover_name = e.target.files[0].name
            this.theme_option = {
                ...this.theme_option,
            }

        },

        handleDarkLogoChange(e) {
            this.theme_option.dark_logo = e.target.files[0]
            this.theme_option.dark_logo_path = URL.createObjectURL(e.target.files[0]);
            this.theme_option.dark_logo_name = e.target.files[0].name
            this.theme_option = {
                ...this.theme_option,
            }

        },


        handleMulti() {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return
            // if (this.selected_report.state<4){
            if (this.is_editing === true) {
                this.handleSave()
                this.$EventBus.$emit('make-toast', '디자인 테마가변경 완료', '디자인 테마가 변경되었습니다. 뷰어페이지에서 확인해주세요.')

            }
            else {
                this.toggleEdit()
            }
            //} else alert(`공개된 보고서는 수정할 수 없습니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 내용을 수정할 수 있습니다.`)
        },
        async handleCancle() {
            await this.initiateDesignTheme()
            this.toggleEdit()
        },
        toggleEdit() {
            this.is_editing = !this.is_editing
        },
        async handleSave() {
            let new_image = null
            if (this.local_report.featured_image) {
                new_image = this.local_report.featured_image
            }
            await this.saveReportPreference({
                report_code: this.report_code,
                main_color: this.local_report.main_color.hex,
                sub_color: this.local_report.sub_color.hex,
                font_family: this.local_report.font_family,
                font_size: this.local_report.font_size,
                folded: this.local_report.folded,
                featured_image: new_image
            })
            this.initiateDesignTheme()
            this.toggleEdit()
        },
        async initiateDesignTheme() {
            let temp_theme = {}
            temp_theme.main_color = this.selected_report.main_color
            temp_theme.sub_color = this.selected_report.sub_color
            temp_theme.font_family = this.selected_report.font_family
            temp_theme.font_size = this.selected_report.font_size
            temp_theme.folded = this.selected_report.folded
            temp_theme.featured_image_name = this.selected_report.featured_image_name
            let new_file = await ih.getFile(this.selected_report.featured_image_name, this.selected_report.featured_image_path)
            temp_theme.featured_image_path = new_file.link

            this.local_report = temp_theme
        },
        handleCustomCss() {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return
            this.$router.push({ name: "FullView2021CustomEditor" });

        }
    },
    created() {
        this.initiateDesignTheme()
    },
    components: {
        'chrome-picker': Chrome,
    },


}
</script>

<style>
.color-block {
    width: 20px;
    height: 20px;
}
</style>
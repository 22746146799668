<template>
    <div id="gpt-helper">
        <b-button 
            @click="toggleGPTForm" 
            class="mb-2" 
            variant="teal"
            :disabled="isSearching"
        >
            AI 작성 도우미 

            <b-icon v-if="showGPTForm" icon="stars" font-scale="1"/>
            <b-icon v-else icon="stars" animation="throb" font-scale="1"/>
        </b-button> 
        
        <div v-if="showGPTForm" class="gray-500">
            <i>
                질문을 입력한 후 Enter를 누르시면 맞춤 답변을 얻을 수 있습니다
            </i>
        </div>

        <div v-if="showGPTForm">
            <b-form-textarea
                id="textarea2"
                v-model="userInput"
                placeholder="추가적으로 요구할 사항이 있다면 입력해주세요. ex. 단위 환산해줘, ISO 14001 인증이란? (입력 후 Enter)"
                rows="7"
                @keydown.enter="request_question"
                :disabled="isSearching"
                />
                <!-- @click="clearUserInput" -->
            
            <p v-html="gptResult">
            </p>

            <!-- <b-button v-if="gptResult !== ''" class="ml-2 py-0 px-2" v-b-tooltip.hover title="링크 복사하기" @click.stop.prevent="copyContents('full')">
                내용 복사하기 <fa :icon="['far','copy']"/>
            </b-button>  -->

            
            <div v-if="isSearching" id="gpt-result-loading">
                <b-icon icon="circle-fill" animation="throb" font-scale="1" /> 
            </div>
        </div>

        

        <!-- <b-card v-if="showGPTForm" no-body class="overflow-hidden" style="width: 500px; border-radius: 30px;">
            <div id="gpt-result" >
                <b-row>
                    <b-col>
                        <p v-html="gptResult">
                        </p>
                        <b-icon v-if="isSearching" icon="circle-fill" animation="throb" font-scale="1" /> 
                

                    </b-col>
                </b-row>
            </div>
            <b-row>
                <b-col md="10">
                    <b-form-textarea
                        id="textarea2"
                        v-model="userInput"
                        placeholder="궁금한 내용을 입력해보세요"
                        rows="3"
                        @keydown.enter="request_question"
                        @click="clearUserInput"
                        :disabled="isSearching"
                    />
                </b-col>
                <b-col md="2">
                    <b-button 
                        size="sm"  
                        @click="request_question" 
                        :disabled="isSearching"
                    >
                        Enter<b-icon icon="stars" font-scale="1"/>
                    </b-button>

                </b-col>
            </b-row>
        </b-card>
        <b-button v-if="showGPTForm" size="sm" class="mt-5" @click="toggleGPTForm" >
            닫기<b-icon icon="x" font-scale="1"/>
        </b-button>

        <div @click="toggleGPTForm">
            <b-icon 
                id="gpt-icon"
                v-if="!showGPTForm" 
                icon="search" 
                scale="1.5"
            />

        </div>

         -->
        

        


        
    </div>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
    name: '',
    computed: {
        ...mapGetters('report_list', ['selected_report']),

    },
    props: {
        indicator_requirement: { type: String, default: "" },
        user_writed: {type: String, default: ''}
        

    },
    components: {
        
    },
    data() {
        return {
            userInput: '',
            isSearching: false,
            gptResult: "",
            showGPTForm: false
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {
        copyContents() {
            const textArea = document.createElement('textarea')
            textArea.value = this.gptResult.replaceAll("<br />", "/n")
            document.body.appendChild(textArea)
            textArea.select()
            document.execCommand('copy')
            document.body.removeChild(textArea)
            this.$EventBus.$emit('make-toast', '복사 완료', '검색 결과를 복사하였습니다.')

        },
        toggleGPTForm() {

            if (!this.selected_report.is_able_to_edit) {
                window.alert('서비스 정기 결제가 필요합니다')
                return;
            }
            this.showGPTForm = !this.showGPTForm
            this.gptResult = ""

            if (this.showGPTForm) {
                // 버튼 클릭 시 바로 ai요청
                this.request_question();
            }

        },
        clearUserInput() {
          this.userInput = ""  
        },
        async request_question() {
            this.isSearching = true
            const path = this.$store.state.backend_host + "/qesg_gpt/fill_form";

            if (this.userInput === "" && (this.user_writed !== "")) {
                this.userInput = this.user_writed
            }

            const response = await axios.post(path, {
                "s": this.indicator_requirement,
                "t": this.userInput
            })


            if (response?.data?.code) {
                let temp = ""
                response.data.msg.map((result) => {
                    console.log(result); 
                    temp += result.message.content.replace(/\n/g, '<br />')
                    temp += "<br />"
                })
                this.gptResult = temp;
                this.isSearching = false

            } else {
                this.gptResult = "죄송합니다. 정보를 가져올 수 없어요."
                this.isSearching = false

            }

            
        }


    }
}
</script>
<style>
#gpt-helper{
    padding-top: 20px;
}


#gpt-result-loading {
    text-align: center;
}

</style>
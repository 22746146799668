<template>
    <b-row>
        <b-col v-if="table_editor" cols="12">
            <div class="d-inline-block mr-3 mb-2">
                <p class="fw-400 f-100 mb-2">데이터 테이블 편집</p>
                <b-btn-group>
                    <b-btn variant="secondary" @click="table_editor.chain().focus().addColumnBefore().run()">
                        <!-- addColumnBefore -->
                        <b-img src="/tiptap_icons/add_col_before.svg"></b-img>
                    </b-btn>
                    <b-btn variant="secondary" @click="table_editor.chain().focus().addColumnAfter().run()">
                        <!-- addColumnAfter -->
                        <b-img src="/tiptap_icons/add_col_after.svg"></b-img>
                    </b-btn>
                    <b-btn variant="secondary" @click="table_editor.chain().focus().deleteColumn().run()">
                        <!-- deleteColumn -->
                        <b-img src="/tiptap_icons/delete_col.svg"></b-img>
                    </b-btn>
                    <b-btn variant="secondary" @click="table_editor.chain().focus().addRowBefore().run()">
                        <b-img src="/tiptap_icons/add_row_before.svg"></b-img>
                    </b-btn>
                    <b-btn variant="secondary" @click="table_editor.chain().focus().addRowAfter().run()">
                        <!-- addRowAfter -->
                        <b-img src="/tiptap_icons/add_row_after.svg"></b-img>
                    </b-btn>
                    <b-btn variant="secondary" @click="table_editor.chain().focus().deleteRow().run()">
                        <!-- deleteRow -->
                        <b-img src="/tiptap_icons/delete_row.svg"></b-img>
                    </b-btn>
                </b-btn-group>
            </div>
            <div class="d-inline-block mr-3">
                <p class="fw-400 f-100 mb-2">Cell 설정</p>
                <b-btn-group>
                    <b-btn :variant="table_editor && table_editor.isActive({ name: 'role-column' }) ? 'dark' : 'secondary'"
                        @click="table_editor.isActive({ name: 'role-column' })
                            ? table_editor.commands.setCellAttribute('name', '')
                            : table_editor.commands.setCellAttribute('name', 'role-column')
                            "
                            >
                            테이블 헤더
                    </b-btn>
                    <b-btn :variant="table_editor && table_editor.isActive({ name: 'role-label' }) ? 'dark' : 'secondary'"
                        @click="table_editor.isActive({ name: 'role-label' })
                            ? table_editor.commands.resetAttributes('td', 'name')
                            : table_editor.commands.setCellAttribute('name', 'role-label')
                            "
                        >
                        데이터 그룹명
                    </b-btn>
                </b-btn-group>
            </div>
            <editor-content :editor="table_editor" />
        </b-col>
        <!-- <b-col cols="12">
            <div class="text-right">
                <p v-show="!chartMultiMode && (datasets.length > 1 || datasets.length < 1)"
                    class="text-danger font-weight-bold"
                    >
                    단일비교 차트의 데이터 그룹은 1개입니다.
                </p>
            </div>
        </b-col> -->
        <!-- <b-col>
            <div>
                {{ labels }}
            </div>
            <div>
                {{ datasets }}
            </div>
        </b-col> -->
    </b-row>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import Table from '@tiptap/extension-table'
import StarterKit from '@tiptap/starter-kit'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import { parse } from 'node-html-parser';
import debounce from 'lodash.debounce';

TableRow.extend({
  content: '(tableCell | tableHeader)*',
    addAttributes() {
        return {
            ...this.parent?.()
        }
    }
})

const TipTapTableExtensionAttributes = {
    addAttributes () {
        return {
            ...this.parent?.(),
            name: {
                renderHTML: (attributes) => {
                    if (!attributes.name) return {}
                    return { name: attributes.name }
                }
            },
            role: {
                default: null,
                renderHTML: (attributes) => {
                    if (!attributes.role) return {}
                    return { role: attributes.role }
                }
            }
        }
    }
}

const TipTapExtensionTableCell = TableCell.extend(TipTapTableExtensionAttributes)
const TipTapExtensionTableHeader = TableHeader.extend(TipTapTableExtensionAttributes)

export default {
    name: 'EditChartDataTable',
    components: {
        EditorContent
    },
    props: {
        labels: {
            type: Array,
            required: true,
            default: () => ([])
        },
        datasets: {
            type: Array,
            required: true,
            default: () => ([ {} ])
        }
    },
    data() {
        return {
            table_editor: null,
            editible_datatable_html: '',
            editible_labels: [],
            editible_datasets: []
        }
    },
    computed: {},
    // watch: {},
    created() {
        this.generateEditibleTableHTML()
        this.generateEditibleDataTable()
        // this.generateEditableTable()
    },
    mounted() {},
    beforeDestroy() {
		if (this.table_editor) {
			this.table_editor.commands.deleteTable()
			this.table_editor = null
		}

        this.editible_datatable_html = ''
        this.editible_labels = []
        this.editible_datasets = []
	},
    methods: {
        generateEditibleTableHTML () {
            let [_header_array, _body_array] = [null, null]
            let [_header_html, _body_html] = [null, null]

            let _row_data_max_count = 0
            if (this.datasets.length) {
                _body_array = this.datasets.reduce((_body, _data) => {
                    let _row_data = []
                    if (_data.label) _row_data.push({ role: 'label', data: _data.label})
                    if (_data.data) _row_data.push(..._data.data.map(_cell_data => ({ role: 'data', data: _cell_data})))
                    _row_data_max_count = _row_data.length

                    let _row_html = _row_data.map((_cell) => `<td role="${_cell.role}" name="role-${_cell.role}">${_cell.data}</td>`).join('')
                    _row_html = `<tr>${_row_html}</tr>`
                    _body.push(_row_html)
                    return _body
                }, [])

                _body_html = `<tbody>${_body_array.join('')}</tbody>`
            }
            if (this.labels.length) {
                _header_array = this.labels.reduce((_header, _label) => {
                    _header.push(`<th role="column" name="role-column">${_label}</th>`)
                    return _header
                }, [])

                if (_row_data_max_count > _header_array.length) {
                    [...Array(_row_data_max_count - _header_array.length).keys()].forEach(() => {
                        _header_array.unshift('<th></th>')
                    });
                }

                _header_html = `<thead><tr>${_header_array.join('')}</tr></thead>`
            }

            this.editible_datatable_html = `<table>${_header_html}${_body_html}</table>`
        },
        generateEditibleDataTable () {
            this.table_editor = new Editor({
                extensions: [
                    StarterKit,
                    Table.configure({
                        resizable: true,
                        HTMLAttributes: {
                            id: `editable-table`,
                            class: 'table b-table table-bordered w-100 editable-table',
                            role: 'table'
                        },
                    }),
                    TableRow,
                    TipTapExtensionTableHeader,
                    TipTapExtensionTableCell
                ],
                content: this.editible_datatable_html,
                onUpdate: debounce(() => {
                    this.getDataTableFromHTML()
                }, 450)
            })
        },
        getDataTableFromHTML() {
            if (!this.table_editor) {
                return false
            }
            
            const _table_editor_html = this.table_editor.getHTML();
            
			const _parsed_table_editor = parse(_table_editor_html);

			// name type = "role-column" (헤더명), "role-label" (데이터 그룹명), "role-data" (데이터)
			const _parsed_column = _parsed_table_editor.querySelectorAll('[name="role-column"]')
			const _parsed_label = _parsed_table_editor.querySelectorAll('[name="role-label"]')
			// const _parsed_data = _parsed_table_editor.querySelectorAll('td[name="role-data"], td:not([name="role-column"]), td:not([name="role-label"])')
            const _parsed_data = _parsed_table_editor.querySelectorAll('td[name="role-data"], td:not([name="role-column"]):not([name="role-label"])')

			let _column_data = _parsed_column.reduce((_columns, _column) => {
				if (_column.text) {
					_columns.push(_column.text)
				}
				return _columns
			}, [])

			let _label_data = _parsed_label.reduce((_labels, _label) => {
				_labels.push(_label.text)
				return _labels
			}, [])

			let _data_data = _parsed_data.reduce((_columns, _column) => {
				if  (!isNaN(_column.text)) {
					if (typeof Number(_column.text) === 'number') {
						_columns.push(Number(_column.text))
					} else {
						_columns.push(0)
					}
				}
				return _columns
			}, [])

			if (_label_data.length) {
                _data_data = _label_data.reduce((_data_matrix) => {
                    _data_matrix.push(_data_data.splice(0, _column_data.length))
                    return _data_matrix
                }, [])
			}

            if (_label_data.length !==_data_data.length){
                return alert('입력되지 않은 데이터 그룹명이 있거나 설정되지 않은 칸이 있습니다. 입력 또는 데이터명 Cell 설정을 완료해주세요.')
            } else {
                const _updated_labels = _column_data
                const _updated_datasets = [ ..._data_data ].reduce((_datasets, _data, _data_idx) => {
                    let _datasets_data = {}
                    if (this.datasets[_data_idx]) {
                        _datasets_data = {
                            ...this.datasets[_data_idx],
                            label: _label_data[_data_idx],
                            data: _data
                        }
                    } else {
                        _datasets_data = {
                            ..._datasets[_data_idx - 1],
                            label: _label_data[_data_idx],
                            data: _data
                        }
                    }
                    
                    _datasets.push(_datasets_data)
                    return _datasets
                }, [])

                this.$emit('updateChartData', {
                    labels: _updated_labels,
                    datasets: _updated_datasets
                })

            }
		}
    }
}
</script>

<style lang="scss">
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
      text-align: left;
      font-size: 17px;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      &[name="role-column"],
      &[role="column"] {
        background-color: #f8f8f8;

        > p {
            font-weight: bold;
        }
      }
    }

    td {
        &[name="role-label"],
        &[role="label"] {
            background-color: #dee2e6;

            > p {
                font-weight: bold;
            }
        } 
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>
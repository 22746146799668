<template>
    <div>
        <h5 class="f-110 mb-4 tail-line-dark">차트 관리</h5>
        <b-row no-gutters class="py-2 mb-2">
            <b-col cols="4"> 차트 명</b-col>
            <b-col> {{ selected_chart_data.label  }} </b-col>
        </b-row>
        <!-- <b-row no-gutters class="py-2 mb-2" v-if="local_table.used_codes.length>0"> -->
        <b-row no-gutters class="py-2 mb-2" >
            <b-col cols="4"> 적용된 보고 지표</b-col>
            <b-col>
                <!-- 
                {{gri_linked_indicators}}
                {{sasb_linked_indicators}}
                {{tcfd_linked_indicators}} -->
                <div v-for="code in gri_linked_indicators[selected_chart_id]" :key="code.id" class="mb-2">
                    <template >
                        <a class="cursor" @click="goHash(code)"> GRI {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                    </template>
                </div>
                <div v-for="code in sasb_linked_indicators[selected_chart_id]" :key="code.id" class="mb-2">
                    <template >
                        <a class="cursor" @click="goHash(code)"> SASB {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                    </template>
                </div>
                <div v-for="code in tcfd_linked_indicators[selected_chart_id]" :key="code.id" class="mb-2">
                    <template >
                        <a class="cursor" @click="goHash(code)"> TCFD {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                    </template>
                </div> 
                <span v-for="(item, index) in chart_linked_indicators[selected_chart_id]" :key="`gri_${index}`">
                    <a class="cursor" @click="goHash(item, 'DATA_CENTER')"> Data Center {{ item['lv1_info']['name'] }} > {{ item['lv2_info']['name'] }} <fa icon="link" class="ml-1"/></a> <br>
                </span>
                <!-- 
            -->
            </b-col>
        </b-row>
        <b-row no-gutters class="py-2 mb-2">
            <b-col cols="4"> 모든 지표에서 차트 삭제</b-col>
            <b-col>
                <p 
                    v-if="gri_linked_indicators[selected_chart_id].length +sasb_linked_indicators[selected_chart_id].length +tcfd_linked_indicators[selected_chart_id].length +chart_linked_indicators[selected_chart_id].length > 0"
                    class="mb-3" 
                >
                    차트가 적용된 보고 지표가 있습니다. 삭제 시 적용된 모든 지표에서 해당 내용이 사라지며, 다시 복구할 수 없습니다.
                </p>
                <b-button variant="red" @click="handleDelete" >차트 삭제하기</b-button>
            </b-col>
        </b-row>
        <b-button class="mt-5 mb-3" @click="handleCancle"> 리스트로 돌아가기 </b-button>
    </div>
    </template>
    
    <script>
    import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
    import * as ih from '@/components/util'
    
    export default {
        data() {
            return {
                local_table: {},
                
            }
        },
        props:{
            table_id : { type: String, default: () => '' }, // table_id 반드시 받아야 함 (WritingMaster.vue 또는 MgDataTables로 부터)
		    editing_mode: { type: Boolean, default: () => false }
            // chart_data: { type: Object, default: () => null},
            // chart_id: { type: Number, default: () => -1},
        },
        computed:{
            ...mapState('report_list', ['report_code']),
            ...mapState('data_tables', ['table_list']),
		    ...mapState('charts', ['chart_data', 'chart_linked_indicators', 'selected_chart_id', 'selected_chart_data', 'gri_linked_indicators', 'sasb_linked_indicators', 'tcfd_linked_indicators']),
            ...mapGetters('report_indicators', ['selected_indicators_gri_codes']),
        },
        methods:{
            ...mapActions('charts',['update_delete_chart']),
            handleCancle() {
                if (this.editing_mode) {
				    this.$emit("flag_modal_mode", "chart_list");
				
                } else {
                    this.$emit("closeEditChartModal")
                }
            },
            handleDelete(){
                if (confirm('본 차트을 사용하고 있는 모든 지표에서 삭제되며 되돌릴 수 없습니다. 삭제하시겠습니까?') === true){
                    this.update_delete_chart({
                        "report_code": this.report_code,
                        "chart_id": this.selected_chart_id,
                        "linked_indicators": {
                            "gri_linked_indicators": this.gri_linked_indicators[this.selected_chart_id],
                            "sasb_linked_indicators": this.sasb_linked_indicators[this.selected_chart_id],
                            "tcfd_linked_indicators": this.tcfd_linked_indicators[this.selected_chart_id],
                            "chart_linked_indicators": this.chart_linked_indicators[this.selected_chart_id],
                        }
                    })


                    


                }
                this.handleCancle()
            },
            goHash(code){
                let hash_code = '#w'+code
                this.handleCancle()
                 // this.$router.push({ name: 'Gri'+code.slice(0,1)*100, hash: hash_code })
                 if (code.split("-")[0].length > 1) {
                    this.$router.push({ name: 'Gri'+code.slice(0,1)*100, hash: hash_code })
                    
                } else {
                    this.$router.push({ name: 'Gri'+code.split("-")[0], hash: hash_code })
                }
            },
            validateUsedCodes(code){
                if (code){
                    // console.log(code);
                    // console.log(this.selected_indicators);
                    let jdx = this.selected_indicators_gri_codes.findIndex(item => item == code)
                    if (jdx > -1) {
                        return true
                    }
                    else return false
                }
            }
        },
        created(){
            // let idx = this.table_list.findIndex(x=>x.table_id == this.table_id)
            // if(idx > -1){
            //     this.local_table = this.table_list[idx]
            // }
        }
    
    }
    </script>
    
    <style>
    
    </style>
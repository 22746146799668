<template>
<div>
        <router-view name="editor_header"></router-view>

        <b-container v-if="report_code!=''&&show_assurance_estimate!=true" class="py-5 vh-adjust">
            <b-row>
                <b-col cols="3">
                    <side-nav-basic class="sticky-top pt-4"></side-nav-basic>
                </b-col>
                <b-col class="pt-4">                        
                    <router-view></router-view> 
                </b-col>
            </b-row>
            <hr class="border-0 mb-5">                
        </b-container>
        <b-container v-else class="vh-adjust"></b-container>

        <router-view name="editor_footer"></router-view>

        <!-- todoRoadmap modal -->
        <b-modal id="todo-preference" ref="todo-modal" hide-footer size="xl" scrollable >
            <template #modal-header> <h4 class="mt-1">보고서 작성 로드맵</h4></template>
            <todo-roadmap @hide-todo-modal="hideTodoModal"></todo-roadmap>
        </b-modal>

        <!-- 새 보고서 만들기 modal -->
        <b-modal id="todo-preference" ref="new-register" hide-footer size="xl" scrollable no-close-on-backdrop no-close-on-esc>
            <template #modal-header>
                <b-row class="w-100">
                    <b-col cols="1" xl="2"> <b-button variant="icon-gray" class="mt-3" @click.stop="$router.push({name: 'Main'})"> <fa icon="chevron-left" class="mr-1"/> 메인으로 </b-button></b-col> 
                    <b-col><h4 class="pt-3">보고서 생성 </h4></b-col>
                    <b-col cols="1" xl="2"></b-col> 
                </b-row>
                <!-- <b-container v-else>
                    <h4 class="pt-3">Plan B. 검증 상세 견적 확인하기 </h4>
                </b-container> -->
            </template>
            <register-new-report @show-assurance-estimate="showAssuranceEstimate" />
            <!-- <template  v-if="show_assurance_estimate==true">
                <template v-if="show_apply_estimate==true">
                    <apply-estimate :new_report="true"></apply-estimate>
                </template>
                <template v-else>
                    <estimate-form :new_report="true"></estimate-form>
                </template>
            </template> -->
        </b-modal>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import SideNavBasic from '@/components/report_preference/SideNavBasic'
import RegisterNewReport from '@/components/report_preference/basic_settings_subs/RegisterNewReport'
import ApplyEstimate from '@/components/assurance/apply_assurance_subs/ApplyEstimate'
import EstimateForm from '@/components/assurance/apply_assurance_subs/EstimateForm'

export default {
    data() {
        return {
            show_assurance_estimate: false
        }
    },
    computed:{
        ...mapState('report_list', ['report_code','report_list', 'is_report_list_loaded']),
        ...mapGetters('report_list', ['selected_report']),
        ...mapState('assurance', ['show_apply_estimate']),

    },
    methods:{
        setTodoVisible (){
            if (localStorage) {
                let str_key = 'hide_todo_'+this.report_code
                let hide_todo_until = new Date(localStorage.getItem(str_key));
                if (hide_todo_until < new Date()) { // expire 시간이 지남 
                    return true
                }
                else return false  // 아직 expire 시간이 안지남 
            }
            return true;
        }, 
        showTodoModal(){
            if (this.setTodoVisible() == true){
                setTimeout(() => {
                    this.$refs['todo-modal'].show()
                }, 200)
            }
        },
        hideTodoModal(){
            this.$refs['todo-modal'].hide()
        },
        showNewRegister(){
            this.$refs['new-register'].show()
        },
        showAssuranceEstimate(){
            this.show_assurance_estimate = true
        }
    },
    mounted(){
        if(this.report_code==''){
            this.showNewRegister()
        }
        else if(this.report_code!=''&&this.selected_report.state==0){
            this.showTodoModal()
        }
    },
    components:{
        RegisterNewReport,
        SideNavBasic,
        ApplyEstimate,
        EstimateForm,
    }

}
</script>

<style>

</style>
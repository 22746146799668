<template>
<div class="pb-5">
    견적서가 아직 생성되지 않았습니다. <br> info@qesg.co.kr로 문의주세요.
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
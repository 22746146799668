<template>
<div>
    <template v-if="is_indicator_written_status_loaded==true">
        <div :class="status=='미작성'?'red7':''">{{status}}</div>
        </template>
    <template v-else>        
        <b-spinner small type="grow"></b-spinner>
    </template>
</div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    data() {
        return {
            status: null,
            is_indicator_written_status_loaded: false
        }
    },
    props:{
        gri_code: {type: String},
    },
    created(){
        this.checkIndicatorsWrittenStatus()
    },
    computed:{
        ...mapState('report_list', ['report_code', 'standard_mode']),

    },
    methods:{
        checkIndicatorsWrittenStatus(){
            const path = this.$store.state.backend_host + '/check_indicators_written_status'

            return axios.get(path, { params: {
                report_code: this.report_code,
                gri_codes: this.gri_code
            }})
            .then( response => {
                // console.log(response.data);
                if (response.data.status!=undefined){
                    this.status = this.statusToWord(response.data.status)
                }
                this.is_indicator_written_status_loaded=true
            })
        },
        statusToWord(txt){
            if(txt=='not_yet'){return '미작성'}
            else return '작성됨'
        }

    }

}
</script>

<style>

</style>
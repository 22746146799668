<template>
    <span v-if="show_this==true" variant="advance" class="ml-1">
        <b-badge varient="advance"> M </b-badge>
    </span>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import * as ih from '@/components/util'

export default {
    components:{
    },
    data() {
        return {
            show_this: false
        }
    },    
    props:{
        topic_id: {type: [String, Number], default: ''}
    },
    computed:{
        ...mapState('gri2021_report_view', ['report_structure', 'report_contents_loaded']),
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),        
    },
    methods: {
        checkTopicIsMaterial(topic_id){
            const path = this.backend_host + '/check_report_topics_selected'

            return axios.get(path, { params: {
                topic_ids : topic_id,
                report_code: this.report_code
            }})
            .then( response => {
                this.show_this = response.data.is_material
            })
        },
    },
    created(){
        this.checkTopicIsMaterial(this.topic_id)
    }
}


</script>
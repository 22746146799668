<template>
<div class="mb-5">
    <template>
        <b-row :id="'rpt' + data_table.data_table_id" v-for="data_table in data_table_list" :key="data_table.data_table_id" no-gutters class="mb-4 py-3 hover-gray200">
            <b-col cols="2" xl="3"><h6 class="mb-2"> {{ lang!='ENG'? data_table.title : data_table.title_e }} </h6></b-col>
            <b-col>
                    <data-table 
                    :lazy_load="false"
                    :data_table_id="data_table.data_table_id" 
                    :data_table_obj="data_table"
                    :modal_table="false" 
                    :show_title="false"></data-table>
            </b-col>
        </b-row>
    </template>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import DataTable from './DataTable.vue'

export default {
    components:{
        DataTable
    },
    data() {
        return {
            data_table_list: [],
            loaded: false
        }
    },
    computed:{
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),    
        ...mapState('gri2021_modal_open_status', ['opened_modal_id']), //open된 bc 확인
        ...mapGetters('report_list', ['selected_report']),
    },
    methods:{
        validateUsedCodes(used_codes){
            let temp_arr = []
            for (let i=0; i<used_codes.length; i++){
                let jdx = this.selected_indicators_gri_codes.findIndex(x => x == used_codes[i])
                if (jdx > -1){
                    return true
                    break;
                }
                else false
            }
        },
        readDataTableList(){
            const path = this.backend_host + '/read_data_table_list'

            return axios.get(path, { params: {
                report_code: this.report_code,
                only_used: true,
                sort: 'gri'
            }})
            .then( response => {
                this.data_table_list = response.data
            })
        }
    },
    created(){
        this.readDataTableList()
    },
    

}
</script>

<style>

</style>
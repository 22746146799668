<template>
    <div>
        <!-- 인쇄용 2줄 -->
        <div v-if="web_view !== true">
            <div class="d-lg-flex justify-content-between">
                <section v-for="topic_type in report_topic_types" class="content-section" style="min-width: 49%"
                    :key="topic_type.id">
                    <!-- Universal Disclosures -->
                    <div v-if="topic_type.topic_type_e === 'Universal Disclosures'">
                        <div v-for="category in report_universal_categories" :key="category.category_id">
                            <div class="pb-1 my-2" :style="main_color"> GRI - {{ category.category_id }}
                                {{ lang != 'ENG' ? '일반 공개사항' : 'General Disclosures' }}</div>
                            <div v-for="subtopic, index in getUniqueSubtopicsByCategoryIds(category.category_id)"
                                :key="subtopic.subtopic_code" class="pb-1 border-bottom"> {{ index + 1 }}.
                                {{ lang != 'ENG' ? subtopic.subtopic_name : subtopic.subtopic_name_e }}</div>
                            <hr class="border-0">
                        </div>
                    </div>
                    <!-- topic Specific Disclosures -->
                    <div v-else>
                        <div v-for="category in report_topic_specific_categories" :key="category.category_id">
                            <div class="pb-1 my-2" :style="main_color"> GRI
                                {{ lang != 'ENG' ? category.category_name : category.category_name_e }}</div>
                            <div v-for="topic in getUniqueTopicsByCategoryIds(category.category_id)" :key="topic.id"
                                class="pb-1 border-bottom">
                                {{ topic.topic_id }} : {{ lang == 'ENG' ? topic.topic_name_e : topic.topic_name }}
                                <materiality-badge :topic_id="topic.topic_id"></materiality-badge>
                            </div>
                            <hr class="border-0">
                        </div>
                    </div>
                </section>
            </div>
            <div>
                <b-badge variant="advance" class="ml-1"> M </b-badge>
                <span class="">:{{ lang !== 'ENG' ? '중요주제 (Material Topic)' : 'Material Topic' }}</span>
            </div>
            
        </div>

        <!-- 웹용 4줄 -->
        <b-container v-else :class="['report-basic-toc', { collapsed: !content_list_open }]">
            <b-row class="mb-5">
                <b-col v-for="(category, c) in report_categories"
                    :key="`category-${category.id}-${c}`"
                    >
                    <div class="d-flex flex-column align-items-center">
                        <b-button pill class="custom-btn-hover"
                            :style="main_bg_color"
                            @click="navAnchor('#rp' + category.category_id)"
                            >
                            {{ lang !== 'ENG' ? category.category_name : category.category_name_e }}
                        </b-button>
                    </div>
                    <div class="report-basic-toc-list">
                        <template v-if="category.type === 'universal'">
                            <div v-for="subtopic, index in category.topics"
                                :key="subtopic.subtopic_id"
                                class="p-1 f-100 text-left w-95 rounded"
                                :class="preview_mode == true ? 'cursor hover-text-dark hover-transparent-dark' : ''"
                                @click="navAnchor('#rp' + subtopic.subtopic_code)"
                                >
                                <!-- f-95 -->
                                {{ index + 1 }}. {{ lang !== 'ENG' ? subtopic.subtopic_name : subtopic.subtopic_name_e }}
                            </div>
                        </template>
                        <template v-else>
                            <div v-for="topic in category.topics"
                                :key="topic.id"
                                class="p-1 f-100 text-left w-95 rounded"
                                :class="preview_mode === true ? 'cursor hover-text-dark hover-transparent-dark' : ''"
                                @click="navAnchor('#trp' + topic.topic_id)"
                                >
                                <!-- f-95 -->
                                {{ topic.topic_id }} : {{ lang !== 'ENG' ? topic.topic_name : topic.topic_name_e }}
                                <materiality-badge :topic_id="topic.topic_id"></materiality-badge>
                            </div>
                        </template>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <div class="report-basic-toc-list f-95 text-left">
                        <b-badge class=""> M </b-badge>
                        <span class="">: {{ lang != 'ENG' ? '중요주제 (Material Topic)' : 'Material Topic' }}</span>
                        
                        <div v-if="$route.hash" class="mt-5">
                            <b-button @click="navAnchor(null)" >
                                <b-icon-menu-button-fill /> {{ lang !== 'ENG' ? '전체보기' : 'Show All'}}
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>

import { mapState, mapActions, mapGetters } from 'vuex'
import * as ih from '@/components/util'
import MaterialityBadge from './MaterialityBadge'

export default {
    name: 'ContentsListBasic',
    props: {
        web_view: {
            type: Boolean,
            default: false
        },
        preview_mode: {
            type: Boolean,
            default: false
        },
        content_list_open: {
            type: Boolean,
            default: false
        }
    },
    components: {
        MaterialityBadge,
    },
    data() {
        return {
            show_data_table: {},
            show_business_case: {},
            low_height: false,
        }
    },
    computed: {
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapState('gri2021_report_view', ['report_structure', 'report_contents_loaded']),
        ...mapGetters('gri2021_report_view', ['report_topics', 'report_categories', 'report_topic_specific_categories', 'report_universal_categories', 'report_topic_types']),
        ...mapGetters('report_list', ['selected_report']),
        main_color() {
            return `color:` + this.selected_report.main_color
        },
        main_color_sub() {
            return `color:` + this.selected_report.main_color + `; border-top: 1px solid ` + this.selected_report.main_color + `!important` + `; border-bottom: 1px solid ` + this.selected_report.main_color
        },
        main_bg_color() {
            if (this.selected_report) {
                return `background-color: ` + this.selected_report.main_color
            } else {
                return `background-color: transparent`
            }
        },
        report_categories () {
            let _report_categories = []
            if (this.report_universal_categories || this.report_universal_categories.lenth) {
                const _report_universal_categories = this.report_universal_categories.map(_category => ({ ..._category, type: 'universal', topics: this.getUniqueSubtopicsByCategoryIds(_category.category_id) }))
                _report_categories = [ ..._report_universal_categories ]
            }

            if (this.report_topic_specific_categories || this.report_topic_specific_categories.lenth) {
                const _report_specific_categories = this.report_topic_specific_categories.map(_category => ({ ..._category, type: 'special', topics: this.getUniqueTopicsByCategoryIds(_category.category_id) }))
                _report_categories = [ ..._report_categories, ..._report_specific_categories ]
            }

            return _report_categories
        }
    },
    methods: {
        ...mapActions('gri2021_report_view', ['readReportContents']),
        getUniqueSubtopicsByCategoryIds(category_id) {
            //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id === category_id)
            let unique_subtopics = []
            filtered_structure.forEach(item => {
                if (unique_subtopics.findIndex(x => { return x.subtopic_code === item.subtopic_code }) < 0) {
                    unique_subtopics.push({
                        category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e,
                        subtopic_code: item.subtopic_code, subtopic_name: item.subtopic_name, subtopic_name_e: item.subtopic_name_e
                    })
                }
            })
            return unique_subtopics
        },
        getUniqueTopicsByCategoryIds(category_id) {
            //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id === category_id)
            let unique_topics = []
            filtered_structure.forEach(item => {
                if (unique_topics.findIndex(x => { return x.topic_id === item.topic_id }) < 0) {
                    unique_topics.push({
                        category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e,
                        topic_id: item.topic_id, topic_name: item.topic_name, topic_name_e: item.topic_name_e
                    })
                }
            })
            return unique_topics
        },
        navAnchor(anchor) {
            ih.anchorGo(anchor, this.$route.hash !== '' )
        }
    }
}
</script>

<style lang="scss">
.btn.custom-btn-hover {
    transition: width 0.15s ease;
    border: 0;
    width: 90%;
    font-size: 1.1rem;

    &:hover {
        opacity: 0.8;
        width: 96%;
    }

    @media (max-width: 992px) {
        width: 100%;
    }
}

.content-section-compact {
    min-width: 24%;
    width: 33%;

    @media (max-width: 992px) {
        width: auto;
    }

    &-sub {
        min-width: 33%;
        width: 33%;

        @media (max-width: 992px) {
            width: auto;
        }
    }
}

.report-basic-toc {
    &-list {
        transition-property: opacity, height, margin-top;
        transition-duration: 0.7s, 0.7s, 0s;
        transition-delay: 1.0s, 1.3s, 0s;
        transition-timing-function: ease, ease, ease;
        height: 100%;
        opacity: 1;
        margin-top: 1rem;
    }

    &.collapsed {
        .report-basic-toc-list {
            transition-duration: 0.5s, 1s, 0.3s;
            transition-delay: 0s, 1.5s, 0.3s;
            height: 0%;
            opacity: 0;
            overflow-y: hidden;
            margin-top: 0rem;
        }
    }
}

</style>

<template>
<div>
    <h5 class="border-bottom-dark pt-3 pb-2"> 기본 이용료</h5>
    <b-table-simple class="wri-table">
        <b-thead><b-tr>
            <b-td>서비스</b-td><b-td>제공 내용</b-td><b-td>표준 가격<sup>1)</sup> (만원)</b-td><b-td>유지 비용<sup>2)</sup> (만원)</b-td><b-td>대상 기업 규모</b-td>
        </b-tr></b-thead>
        <b-tbody class="border-bottom-gray-550">
            <b-tr>
                <b-td rowspan="3"><div class="mb-2 fw-500 f-105 tealgray500">ESG 보고서 작성 및 공개 </div></b-td>
                <b-td rowspan="3">
                        <p class="mb-2 indent-sm f-90">- 중요성 평가 로보 기반 보고 지표 추천 받기 </p>
                        <p class="mb-1 indent-sm f-90">- 보고서 작성 Tool 사용 </p>
                        <p class="mb-1 indent-lg f-85 pl-2"><fa :icon="['far','check-circle']"/> 지표별 작성 요건 및 가이드, 상세 예시 제공 </p>
                        <p class="mb-1 indent-lg f-85 pl-2"><fa :icon="['far','check-circle']"/> 파일 업로드 100MB 지원</p>
                        <p class="mb-2 indent-lg f-85 pl-2"><fa :icon="['far','check-circle']"/> 작성 데이터 보관 (공개일로부터 3년) </p>
                        <p class="mb-1 indent-sm f-90">- 웹 보고서 오픈 링크 제공 (공개일로부터 1년, 연단위 연장 가능) </p>
                        <p class="mb-1 indent-sm f-90">- AI 머신러닝 기반 자동 번역(영문) </p>
                </b-td>
                <b-td>590</b-td>
                <b-td>60</b-td>
                <b-td><p class="mb-0 f-85">연 매출<br> 500억 초과</p></b-td>
            </b-tr>
            <b-tr>
                <b-td>390</b-td>
                <b-td>60</b-td>
                <b-td><p class="mb-0 f-85">연 매출<br> 500억 ~ 20억</p></b-td>
            </b-tr>
            <b-tr>
                <b-td>190</b-td>
                <b-td>60</b-td>
                <b-td><p class="mb-0 f-85">연 매출<br> 20억 미만</p></b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
    <p class="f-85 mb-1">1) 보고서 공개 비용입니다. 최초 공개 후 1년 간 오픈 링크 사용 비용을 포함합니다.</p>
    <p class="f-85 mb-1">2) 최초 공개 후 1년 경과 후, 보고서 공개 링크 유지여부에 따라 1년 단위로 유지 비용이 청구됩니다. </p>

    <h5 class="border-bottom-dark pt-5 pb-2"> 부가서비스 이용료</h5>
    <b-table-simple class="wri-table">
        <b-thead><b-tr>
            <b-td>서비스</b-td><b-td>제공 내용</b-td><b-td>표준 가격<sup>3)</sup> (만원)</b-td><b-td>대상 기업 규모</b-td>
        </b-tr></b-thead>
        <b-tbody class="border-bottom-gray-550">
            <b-tr>
                <b-td rowspan="3"><div class="mb-2 fw-500 f-105 tealgray500">제3자 검증 서비스 </div></b-td>
                <b-td rowspan="3">
                        <p class="mb-1 indent-sm f-90">- ESG 전문가의 보고서 검토 및 피드백 </p>
                        <p class="mb-2 indent-lg f-85 pl-2"><fa :icon="['far','check-circle']"/>  서면 검토 및 대면 인터뷰 </p>
                        <p class="mb-1 indent-sm f-90">- 국제 검증 표준(AA1000AS) 기반 제3자 검증의견서 제공 </p>
                        <p class="indent-lg f-85 pl-2"><fa :icon="['far','check-circle']"/>  보고 정보의 신뢰성 확보, 투자자 커뮤니케이션 등에 활용성 향상 </p>
                </b-td>
                <b-td>890</b-td>
                <b-td><p class="mb-0 f-85">연 매출<br> 500억 초과</p></b-td>
            </b-tr>
            <b-tr>
                <b-td>590</b-td>
                <b-td><p class="mb-0 f-85">연 매출<br> 500억 ~ 20억</p></b-td>
            </b-tr>
            <b-tr>
                <b-td>350</b-td>
                <b-td><p class="mb-0 f-85">연 매출<br> 20억 미만</p></b-td>
            </b-tr>

            <b-tr>
                <b-td ><div class="mb-2 fw-500 f-105 tealgray500">고품질 번역 서비스 </div></b-td>
                <b-td >
                        <p class="mb-1 indent-sm f-90">- 번역 전문가의 영문 번역 </p>
                        <p class="mb-2 indent-lg f-85 pl-2"><fa :icon="['far','check-circle']"/> 국/영문 감수 포함 </p>
                </b-td>
                <b-td>단어당 190원</b-td>
                <b-td>-</b-td>
            </b-tr>

            <b-tr>
                <b-td ><div class="mb-2 fw-500 f-105 tealgray500"> 전용 웹 구축 서비스 </div></b-td>
                <b-td >
                        <p class="mb-1 indent-sm f-90">- 웹 리포팅용 마이크로 웹사이트 제작 </p>
                        <p class="mb-2 indent-lg f-85 pl-2"><fa :icon="['far','check-circle']"/> 디자인 기획 및 개발 포함 </p>
                        <p class="mb-2 indent-lg f-85 pl-2"><fa :icon="['far','check-circle']"/> 웹 개발 및 별도 퍼블리싱 포함 </p>
                </b-td>
                <b-td>별도 문의</b-td>
                <b-td>-</b-td>
            </b-tr>

            <b-tr>
                <b-td ><div class="mb-2 fw-500 f-105 tealgray500"> ESG 보고서 작성 컨설팅/ 교육 서비스</div></b-td>
                <b-td >
                        <p class="mb-1 indent-sm f-90">- 보고서 기획 자문 </p>
                        <p class="mb-1 indent-sm f-90">- 원고작성 교육 </p>
                        <p class="mb-1 indent-sm f-90">- 보고서 감수 및 리뷰 </p>
                </b-td>
                <b-td>별도 문의</b-td>
                <b-td>-</b-td>
            </b-tr>           
        </b-tbody>
    </b-table-simple>
    <p class="f-85 mb-1">3) 검증 소요에 따라 상이하며, man-day 기반으로 산출됩니다.</p>

    <hr class="border-0 pb-5">

</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
<template>
    <div>
        <b-form-textarea
            v-model="content"
            :disabled="!edit_mode"
            class="mb-2"
        >
        </b-form-textarea>
        <!-- <b-form-textarea
        v-model="writing_eng[indicator_info.tcfd_code]"
        class="f-90 mb-2"
        :disabled="!is_editing_eng[indicator_info.tcfd_code]"
        rows="4"
        /> -->
        <div
            class="d-flex justify-content-between"
        >
            <b-button
                v-if="!edit_mode"
                variant="teal-border"
                size="sm"
                class="mr-2"
                @click="edit_mode=true"
                >번역 수정하기</b-button
            >
            <div v-if="edit_mode" >
                <b-button
                variant="teal"
                size="sm"
                class="mr-2"
                @click="initiateContent"
                >
                <!-- :disabled="re_initiating == true" -->
                번역 초기화
                <fa
                    icon="redo"
                    /></b-button>
                    <!-- :class="re_initiating == true ? 'spinning-icon' : ''" -->
                <b-button
                    @click="update_content"
                    variant="red"
                    size="sm"
                    class="mr-2"
                >저장</b-button
                >
                <!-- :disabled="
                    save_state[indicator_info.tcfd_code] == 'saving' ||
                    re_initiating == true
                " -->
                <b-button
                size="sm"
                @click="cancelEdit"
                >
                취소
                </b-button>
            </div>

        <div class="f-90 blue">
            <template v-if="lv3_info.contents_em">
                <fa icon="check-circle" class="ml-1" /> 수동 번역 저장됨
            </template>
            <template v-if="saving">
                <b-spinner class="blue" small>저장 중</b-spinner> 
            </template>
            <template v-if="saved">
                <fa icon="check-circle" class="ml-1" />저장 완료
            </template>
        </div>
        </div>


        
        <!-- 연결된 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
        <ChartTranslateTextCanvas
            v-if="lv3_info.item_id !=='' && lv3_info.type==='chart'"
            :chart_id="lv3_info.item_id" 
            :key="`chart_${lv3_info.item_id}`"
            class="mt-5"
            lang="ENG"
        />

        
    </div>
</template>
<script>
import ChartTranslateTextCanvas from "@/components/writing_report/chart_subs/ChartTranslateTextCanvas.vue";
import { gri_mixin } from "@/components/writing_report/write_report_subs/gri_mixin";
import { mapMutations } from "vuex";
export default {
    mixins: [gri_mixin],
    name: '',
    props: {
        lv1_key: { type: String, default: () => ""},
        lv2_key: { type: String, default: () => ""},
        lv3_info: { type: Object, default: () => { }},
    },
    components: {
        ChartTranslateTextCanvas
    },
    watch: {
        lv3_info: {
            handler(newVal) {
                this.content = newVal.contents_em ? newVal.contents_em : newVal.contents_ea;
            }
        }
    },
    data() {
        return {
            example: '',
            is_editing_eng: {},
            content: this.lv3_info.contents_em ? this.lv3_info.contents_em : this.lv3_info.contents_ea,
            edit_mode: false,
            saving: false,
            saved: false
        }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {
        ...mapMutations('charts', ['update_toc_list']),
        cancelEdit() {
            this.edit_mode = false;
            this.content = this.lv3_info.contents_em ? this.lv3_info.contents_em : this.lv3_info.contents_ea
        },
        async update_content() {
            console.log('update_content')


            this.saving = true

            // update_toc_content
            try {
                const response = await axios.post(this.$store.state.backend_host + "/update_toc_content", {
                    "report_code": this.report_code,
                    "re_initiate": false,
                    "payload": {
                        "lv1_key": this.lv1_key,
                        "lv2_key": this.lv2_key,
                        "lv3_key": this.lv3_info.key,
                        content: this.content
                    }
                })


                this.update_toc_list(response.data.result.contents)
                
            } catch (error) {
                console.log(error)
                
            }


            this.saving = false
            this.saved = true
            setTimeout(() => {
                this.saved = false;
            }, 2000);
            
            this.edit_mode = false;
        },
        async initiateContent() {
            console.log('update_content')

            this.saving = true

            // update_toc_content
            try {
                const response = await axios.post(this.$store.state.backend_host + "/update_toc_content", {
                    "report_code": this.report_code,
                    "re_initiate": true,
                    "payload": {
                        "lv1_key": this.lv1_key,
                        "lv2_key": this.lv2_key,
                        "lv3_key": this.lv3_info.key,
                        content: this.content
                    }
                })

                this.update_toc_list(response.data.result.contents)
                
            } catch (error) {
                console.log(error)
                
            }


            this.saving = false
            this.saved = true
            setTimeout(() => {
                this.saved = false;
            }, 2000);


            
            this.edit_mode = false;
            
        },

    }
}
</script>
<style>
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"mb-5"},[_vm._v("TCFD")]),_vm._l((_vm.tcfd_indicator_list),function(category_info,category_idx){return _c('div',{key:category_idx,staticClass:"mb-5"},[_c('h5',{staticClass:"fw-600 mb-4 border-bottom-dark pb-2",attrs:{"id":`w${category_info['category_code']}`}},[_vm._v(" "+_vm._s(category_info["category_name"])+" "),_c('span',{staticClass:"gray-500 f-105 fw-400 ml-1"},[_vm._v(_vm._s(category_info["category_name_e"]))])]),_c('b-table-simple',{staticClass:"wri-table",attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-td',{staticStyle:{"width":"14%"}},[_vm._v("지표")]),_c('b-td',{staticStyle:{"width":"34%"}},[_vm._v("국문")]),_c('b-td',[_vm._v("번역하기")])],1)],1),_c('b-tbody',_vm._l((category_info.indicators),function(indicator_info,indicator_idx){return _c('b-tr',{key:indicator_idx,attrs:{"id":`w${indicator_info['tcfd_code']}`}},[_c('b-td',[_c('span',{staticClass:"f-160"},[_vm._v(_vm._s(indicator_info["tcfd_code"]))]),_c('p',{staticClass:"f-120"},[_vm._v(_vm._s(indicator_info.indicator_category))]),_c('p',{},[_vm._v(_vm._s(indicator_info.metric))])]),_c('b-td',[(
                indicator_info.direct_report != '' &&
                indicator_info.direct_report != undefined
              )?_c('div',{staticClass:"f-90 pb-3",staticStyle:{"word-wrap":"break-word","white-space":"no-wrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(indicator_info.direct_report)+" ")]):_vm._e(),(
                indicator_info.data_tables.length > 0 && _vm.hashed_data_tables
              )?_c('section',{staticClass:"mb-5 px-3"},_vm._l((indicator_info.data_tables),function(dt){return _c('div',{key:dt.id},[_c('div',{staticClass:"mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center"},[(_vm.hashed_data_tables && _vm.hashed_data_tables[dt])?_c('span',[_c('fa',{staticClass:"mr-1",attrs:{"icon":"table"}}),_vm._v(" "+_vm._s(_vm.hashed_data_tables[dt].title)+" ")],1):_vm._e()]),(_vm.hashed_data_tables && _vm.hashed_data_tables[dt])?_c('b-table-simple',{staticClass:"bor-bg-table",attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',_vm._l((_vm.hashed_data_tables[dt]
                          .header),function(property,col_index){return _c('b-td',{key:col_index},[_vm._v(_vm._s(property))])}),1)],1),_c('b-tbody',_vm._l((_vm.hashed_data_tables[dt]
                        .contents),function(row_item,row_index){return _c('b-tr',{key:row_item.id},[_vm._l((_vm.hashed_data_tables[dt].header),function(property){return [(
                            _vm.differentPrevious(
                              _vm.hashed_data_tables[dt].contents,
                              row_index,
                              property,
                              _vm.hashed_data_tables[dt].header
                            ) == true
                          )?_c('b-td',{key:property.id,attrs:{"rowspan":_vm.getSameLength(
                              _vm.hashed_data_tables[dt].contents,
                              row_index,
                              property,
                              _vm.hashed_data_tables[dt].header
                            )}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formattedText(row_item[property]))}})]):_vm._e()]})],2)}),1),_c('caption',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.hashed_data_tables[dt].footnote)+" ")])],1):_vm._e()],1)}),0):_vm._e(),(indicator_info.documents.length > 0)?_c('section',{staticClass:"mb-5 px-3"},[_vm._l((indicator_info.documents),function(x){return _c('div',{key:x.id},[(
                    _vm.hashed_docs &&
                    _vm.hashed_docs[x] &&
                    _vm.hashed_docs[x].type != 'link'
                  )?_c('div',{staticClass:"mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center"},[_c('a',{staticClass:"cursor",attrs:{"href":_vm.$store.state.static_host + _vm.hashed_docs[x].path,"target":"_blank"}},[_c('fa',{staticClass:"mr-1",attrs:{"icon":"file-alt"}}),_vm._v(" "+_vm._s(_vm.hashed_docs[x].title)+" ")],1)]):_vm._e()])}),_vm._l((indicator_info.documents),function(y){return _c('div',{key:y.id},[(
                    _vm.hashed_docs &&
                    _vm.hashed_docs[y] &&
                    _vm.hashed_docs[y].type == 'link'
                  )?_c('div',{staticClass:"mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center"},[_c('a',{staticClass:"cursor",attrs:{"href":_vm.hashed_docs[y].link,"target":"_blank"}},[_c('fa',{staticClass:"mr-1",attrs:{"icon":"external-link-square-alt"}}),_vm._v(" "+_vm._s(_vm.hashed_docs[y].title)+" ")],1)]):_vm._e()])})],2):_vm._e(),(indicator_info.business_cases.length > 0)?_c('section',{staticClass:"mb-5 px-3"},_vm._l((indicator_info.business_cases),function(bc,idx){return _c('div',{key:idx},[_c('div',{staticClass:"mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center"},[_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(`bc_${bc}${indicator_info['tcfd_code']}`),expression:"`bc_${bc}${indicator_info['tcfd_code']}`"}],staticClass:"cursor"},[(
                        _vm.hashed_business_cases && _vm.hashed_business_cases[bc]
                      )?_c('span',[_c('fa',{staticClass:"mr-1",attrs:{"icon":"quote-left"}}),_vm._v(_vm._s(_vm.hashed_business_cases[bc].title))],1):_vm._e()])]),_c('b-modal',{attrs:{"id":`bc_${bc}${indicator_info['tcfd_code']}`,"hide-footer":"","size":"xl"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('b-badge',{staticClass:"mr-2",attrs:{"variant":"basic"}},[_vm._v("Case.")]),(
                        _vm.hashed_business_cases && _vm.hashed_business_cases[bc]
                      )?_c('span',[_c('fa',{staticClass:"mr-1",attrs:{"icon":"quote-left"}}),_vm._v(_vm._s(_vm.hashed_business_cases[bc].title))],1):_vm._e()]},proxy:true}],null,true)},[_c('simple-business-case',{attrs:{"business_case_id":bc}})],1),_c('b-modal',{attrs:{"id":`bc_e_${bc}${indicator_info.tcfd_code}`,"hide-footer":"","size":"xl"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('b-badge',{staticClass:"mr-2",attrs:{"variant":"basic"}},[_vm._v("Case.")]),(
                        _vm.hashed_business_cases && _vm.hashed_business_cases[bc]
                      )?_c('span',[_vm._v(_vm._s(_vm.hashed_business_cases[bc].title_e))]):_vm._e()]},proxy:true}],null,true)},[_c('simple-business-case',{attrs:{"business_case_id":bc,"eng_mode":true}})],1)],1)}),0):_vm._e(),(indicator_info.charts.length>0)?_c('section',{staticClass:"mt-5 pr-3"},_vm._l((indicator_info.charts),function(chart_id){return _c('div',{key:chart_id},[_c('ChartTextCanvas',{key:`chart_${chart_id}`,staticClass:"mb-5",attrs:{"chart_id":chart_id,"viewer_mode":true}})],1)}),0):_vm._e()]),_c('b-td',[(indicator_info.disclosure_id)?[_c('b-form-textarea',{staticClass:"f-90 mb-2",attrs:{"disabled":!_vm.is_editing_eng[indicator_info.tcfd_code],"rows":"4"},model:{value:(_vm.writing_eng[indicator_info.tcfd_code]),callback:function ($$v) {_vm.$set(_vm.writing_eng, indicator_info.tcfd_code, $$v)},expression:"writing_eng[indicator_info.tcfd_code]"}}),(_vm.selected_report.is_able_to_edit)?_c('div',{staticClass:"d-flex justify-content-between"},[(!_vm.is_editing_eng[indicator_info.tcfd_code])?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"teal-border","size":"sm"},on:{"click":function($event){$event.stopPropagation();_vm.is_editing_eng[indicator_info.tcfd_code] = true}}},[_vm._v("번역 수정하기")]):_c('div',[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"teal","size":"sm","disabled":_vm.re_initiating == true},on:{"click":function($event){return _vm.handleReInitiate(
                        indicator_info.tcfd_code,
                        indicator_info.disclosure_id
                      )}}},[_vm._v(" 번역 초기화 "),_c('fa',{class:_vm.re_initiating == true ? 'spinning-icon' : '',attrs:{"icon":"redo"}})],1),_c('b-button',{staticClass:"mr-2",attrs:{"variant":"red","size":"sm","disabled":_vm.save_state[indicator_info.tcfd_code] == 'saving' ||
                      _vm.re_initiating == true},on:{"click":function($event){return _vm.handleSave(
                        indicator_info.tcfd_code,
                        indicator_info.disclosure_id
                      )}}},[_vm._v("저장")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.handleCancle(
                        indicator_info.tcfd_code,
                        indicator_info.direct_report_ea
                      )}}},[_vm._v(" 취소 ")])],1),_c('div',{staticClass:"f-90 blue"},[(_vm.save_state[indicator_info.tcfd_code] === 'saved')?[_c('fa',{staticClass:"ml-1",attrs:{"icon":"check-circle"}}),_vm._v(" 수동 번역 저장됨 ")]:(
                      _vm.save_state[indicator_info.tcfd_code] === 'saving'
                    )?[_c('b-spinner',{staticClass:"blue",attrs:{"small":""}}),_vm._v(" 저장 중")]:_vm._e()],2)],1):_vm._e(),(indicator_info.data_tables.length > 0)?_c('section',{staticClass:"mt-4 pr-3 border-right pt-2"},_vm._l((indicator_info.data_tables),function(dt){return _c('div',{key:dt.id},[_c('div',{staticClass:"f-90 mb-2 fw-500 d-flex justify-content-between align-items-start"},[_c('div',[_c('fa',{staticClass:"mr-1",attrs:{"icon":"table"}}),_vm._v(" "+_vm._s(_vm.hashed_data_tables[dt].title_e)+" ")],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"py-1 px-2",attrs:{"variant":"teal-border","size":"sm","title":"번역 수정하기"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.translateTable(dt)}}},[_c('fa',{attrs:{"icon":"pen"}})],1)],1),_c('b-table-simple',{staticClass:"bor-bg-table",attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',_vm._l((_vm.hashed_data_tables[
                            dt
                          ].header_e),function(property,col_index){return _c('b-td',{key:col_index},[_vm._v(_vm._s(property))])}),1)],1),_c('b-tbody',_vm._l((_vm.hashed_data_tables[dt].contents_e),function(row_item){return _c('b-tr',{key:row_item.id},_vm._l((_vm.hashed_data_tables[dt].header_e),function(property){return _c('b-td',{key:property.id},[_vm._v(_vm._s(row_item[property]))])}),1)}),1)],1),_c('div',{staticClass:"f-85 gray-600"},[_vm._v(" "+_vm._s(_vm.hashed_data_tables[dt].footnote_e)+" ")])],1)}),0):_vm._e(),(indicator_info.documents.length > 0)?_c('section',{staticClass:"mt-4 pr-3 border-right pt-2"},_vm._l((indicator_info.documents),function(x){return _c('div',{key:x.id,staticClass:"mb-2 d-flex justify-content-between align-items-start"},[(_vm.hashed_docs[x].type != 'link')?[_c('a',{staticClass:"fw-500 cursor",attrs:{"href":_vm.$store.state.static_host + _vm.hashed_docs[x].path_e,"target":"_blank"}},[_c('fa',{staticClass:"mr-1",attrs:{"icon":"file-alt"}}),_vm._v(" "+_vm._s(_vm.hashed_docs[x].title_e)+" ")],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"py-1 px-2",attrs:{"variant":"teal-border","size":"sm","title":"번역 수정하기"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.translateDocument({
                          id: x,
                          type: _vm.hashed_docs[x].type,
                        })}}},[_c('fa',{attrs:{"icon":"pen"}})],1)]:[_c('a',{staticClass:"fw-500 cursor",attrs:{"href":_vm.hashed_docs[x].link_e,"target":"_blank"}},[_c('fa',{staticClass:"mr-1",attrs:{"icon":"external-link-square-alt"}}),_vm._v(" "+_vm._s(_vm.hashed_docs[x].title_e)+" ")],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"py-1 px-2",attrs:{"variant":"teal-border","size":"sm","title":"번역 수정하기"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.translateDocument({
                          id: x,
                          type: _vm.hashed_docs[x].type,
                        })}}},[_c('fa',{attrs:{"icon":"pen"}})],1)]],2)}),0):_vm._e(),(indicator_info.business_cases.length > 0)?_c('section',{staticClass:"mt-4 pr-3 border-right pt-2"},_vm._l((indicator_info.business_cases),function(bc){return _c('div',{key:bc.id},[_c('div',{staticClass:"mb-2 fw-500 d-flex justify-content-between align-items-start"},[_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(`bc_e_${bc}${indicator_info.tcfd_code}`),expression:"`bc_e_${bc}${indicator_info.tcfd_code}`"}],staticClass:"fw-500 cursor"},[_c('fa',{staticClass:"mr-1",attrs:{"icon":"quote-left"}}),_vm._v(" "+_vm._s(_vm.hashed_business_cases[bc].title_e)+" ")],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-2 py-1 px-2",attrs:{"variant":"teal-border","size":"sm","title":"번역 수정하기"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.translateBusinessCase(bc)}}},[_c('fa',{attrs:{"icon":"pen"}})],1)],1)])}),0):_vm._e(),(indicator_info.charts.length>0)?_c('section',{staticClass:"mt-5 pr-3"},_vm._l((indicator_info.charts),function(chart_id){return _c('div',{key:chart_id},[_c('ChartTranslateTextCanvas',{key:`chart_${chart_id}`,staticClass:"mb-5",attrs:{"chart_id":chart_id,"viewer_mode":true,"lang":"ENG"}})],1)}),0):_vm._e()]:[_c('div',[_vm._v("작성된 지표가 없습니다.")])]],2)],1)}),1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.loadingShow,"no-wrap":""}}),(!_vm.is_editing)?_c('h5',[_vm._v("ESG 정보공개 표준 선택")]):_vm._e(),(_vm.report_type_option)?_c('div',_vm._l((_vm.report_type_option['order']),function(type_id){return _c('b-row',{key:type_id,staticClass:"hover-gray py-2 mb-3",attrs:{"no-gutters":""}},[(_vm.report_type_option[type_id]['is_latest'])?_c('div',[(_vm.is_editing)?_c('b-col',[_c('b-form-checkbox',{attrs:{"value":type_id,"size":"lg"},model:{value:(_vm.local_report.report_type),callback:function ($$v) {_vm.$set(_vm.local_report, "report_type", $$v)},expression:"local_report.report_type"}},[_vm._v(" "+_vm._s(_vm.report_type_option[type_id]["type_name"])+" ")]),(
              type_id === 'GRIv2021' && _vm.local_report &&
              _vm.local_report.report_type.includes('GRIv2021')
            )?[_c('b-form-checkbox',{staticClass:"pt-2 ml-3",attrs:{"switch":""},model:{value:(_vm.is_gri_2021),callback:function ($$v) {_vm.is_gri_2021=$$v},expression:"is_gri_2021"}},[_vm._v(" "+_vm._s(_vm.is_gri_2021 === true ? "최신 버전 : 2021년 개정안" : "버전 : 2016년 구 버전")+" ")])]:_vm._e()],2):_c('b-col',[(_vm.local_report && _vm.local_report.report_type.includes(type_id))?_c('div',[_vm._v(" "+_vm._s(_vm.report_type_option[type_id]["type_name"])+" ("+_vm._s(_vm.report_type_option[type_id]["is_latest"] ? "최신 개정안" : "구버전")+") ")]):_vm._e(),(
              type_id === 'GRIv2021' && _vm.local_report &&
              _vm.local_report.report_type.includes('GRIv2016')
            )?_c('div',[_vm._v(" GRI Standards 2016(구버전) ")]):_vm._e()])],1):_vm._e()])}),1):_vm._e(),_c('b-button',{staticClass:"px-3 mr-3",attrs:{"variant":_vm.is_editing === true ? 'teal' : 'teal-border'},on:{"click":_vm.handleMulti}},[_vm._v(" "+_vm._s(_vm.is_editing == true ? "보고표준 저장하기" : "보고표준 수정하기")+" ")]),(_vm.is_editing == true)?_c('b-button',{staticClass:"px-3",on:{"click":_vm.handleCancle}},[_vm._v(" 취소 ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.etc_info === null)?_c('b-overlay',{attrs:{"show":true,"no-wrap":""}}):_c('div',[_c('Header',{attrs:{"logo_path":_vm.etc_info.logo_path,"report_name":_vm.$route.query.lang !== 'ENG'
          ? `${_vm.etc_info.report_name}`
          : `${_vm.etc_info.report_name_e}`,"main_color":_vm.etc_info.main_color}}),_c('div',{staticClass:"vh-adjust overflow-hidden"},[_c('b-container',{staticClass:"mt-4 py-5"},[(_vm.etc_info)?_c('div',[(_vm.$route.query.lang !== 'ENG')?_c('h3',[_vm._v(_vm._s(_vm.etc_info.title))]):_c('h3',[_vm._v(_vm._s(_vm.etc_info.title_e))])]):_vm._e(),(_vm.$route.query.type === 'bsc')?_c('div',{staticClass:"m-1 mb-2 fw-500",on:{"click":_vm.openImgPopup}},[(_vm.$route.query.lang !== 'ENG')?_c('div',{staticClass:"bc-simple-content pb-4",domProps:{"innerHTML":_vm._s(_vm.description)}}):_c('div',{staticClass:"bc-simple-content pb-4",domProps:{"innerHTML":_vm._s(_vm.description_e)}})]):_vm._e(),(this.$route.query.type === 'dtable')?_c('div',{staticClass:"m-1 mb-2 fw-500"},[(_vm.table_data && _vm.$route.query.lang !== 'ENG')?[_c('b-table-simple',{staticClass:"edi-table",attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',_vm._l((_vm.table_data.header),function(property,col_index){return _c('b-td',{key:`newtab-table-th-cell-${col_index}`},[_vm._v(" "+_vm._s(property)+" ")])}),1)],1),_c('b-tbody',_vm._l((_vm.table_data.contents),function(row_item,row_index){return _c('b-tr',{key:`newtab-table-tr-${row_item.id}-rows-${row_index}`},[_vm._l((_vm.table_data.header),function(property,header_index){return [(
                        _vm.differentPrevious(
                          _vm.table_data.contents,
                          row_index,
                          property,
                          _vm.table_data.header
                        ) == true
                      )?[_c('b-td',{key:`newtab-table-td-${row_item.id}-rows-${row_index}-cell-${header_index}`,attrs:{"rowspan":_vm.getSameLength(
                            _vm.table_data.contents,
                            row_index,
                            property,
                            _vm.table_data.header
                          )}},[_vm._v(" "+_vm._s(row_item[property])+" ")])]:_vm._e()]})],2)}),1)],1)]:[_c('b-table-simple',{staticClass:"edi-table",attrs:{"responsive":""}},[(_vm.table_data && _vm.table_data.header_e != null)?[_c('b-thead',[_c('b-tr',_vm._l((_vm.table_data.header_e),function(property,col_index){return _c('b-td',{key:`newtab-table-th-cell-${col_index}`},[_vm._v(" "+_vm._s(property)+" ")])}),1)],1),_c('b-tbody',_vm._l((_vm.table_data.contents_e),function(row_item,row_index){return _c('b-tr',{key:`newtab-table-td-${row_item.id}-rows-${row_index}`},[_vm._l((_vm.table_data.header_e),function(property,header_index){return [(
                          _vm.differentPrevious(
                            _vm.table_data.contents_e,
                            row_index,
                            property,
                            _vm.table_data.header_e
                          ) == true
                        )?[_c('b-td',{key:`newtab-table-td-${row_item.id}-rows-${row_index}-cell-${header_index}`,attrs:{"rowspan":_vm.getSameLength(
                              _vm.table_data.contents_e,
                              row_index,
                              property,
                              _vm.table_data.header_e
                            )}},[_vm._v(" "+_vm._s(row_item[property])+" ")])]:_vm._e()]})],2)}),1)]:_vm._e()],2)]],2):_vm._e(),_c('b-img',{staticClass:"mb-3 float-right",staticStyle:{"height":"65px"},attrs:{"id":"static-header-img","src":`${_vm.$store.state.static_host}${_vm.etc_info.logo_path.replaceAll(
            '\\',
            '/'
          )}`}})],1)],1),_c('Footer',{attrs:{"report_name":_vm.$route.query.lang !== 'ENG'
          ? `${_vm.etc_info.report_name}`
          : `${_vm.etc_info.report_name_e}`,"report_url":_vm.etc_info.report_url,"report_date":`${_vm.etc_info.start_date} ~ ${_vm.etc_info.end_date}`}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="position-relative bgray-100 vh-100" style="min-height: 490px;">
        
        <div :class="['bg-white w-100 h-100 shadow-sm report-header', { collapsed: content_list_open && show_gri_table }]">
            <div class="d-flex justify-content-between p-4 border-bottom report-header-nav">
                <div class="d-flex align-items-center">
                    <template v-if="logo_url">
                        <b-img :src="logo_url" class="mr-3" style="max-height: 40px;" />
                        <!-- report-logo-xs -->
                    </template>
                    <div class="fw-400 h6 gray-600 mb-0">
                        {{ lang !== 'ENG' ? selected_report.report_name : selected_report.report_name_e }}
                    </div>
                </div>

                <div v-show="theme_option.show_printing_btn || theme_option.show_translation_btn || view_mode">
                    <template v-if="theme_option.show_printing_btn && !selected_report.sample_report">
                        <b-button 
                            variant="dark-border" 
                            size="lg" 
                            class="p-0 mr-2 preview-tool-btn" 
                            v-print="'#full-report-preview'"
                            v-b-tooltip.hover 
                            :title="lang !== 'ENG'? '출력하기' : 'Print to PDF'"
                            > 
                            <fa icon="print"/>
                        </b-button>
                    </template>

                    <template v-if="theme_option.show_translation_btn">
                        <b-button 
                            variant="dark-border" 
                            size="lg" 
                            class="p-0 mr-2 preview-tool-btn" 
                            @click="toggleLang" 
                            v-b-tooltip.hover 
                            :title="lang!='ENG'?'영문보기':'Korean'"
                            >
                            <fa icon="language"/>
                        </b-button>  
                    </template>

                    <template v-if="view_mode !== 'viewer'">
                        <b-button 
                            variant="dark-border"
                            size="lg"
                            class="p-0 preview-tool-btn"
                            @click="$router.push({ name: 'CheckReport' })"
                            v-b-tooltip.hover
                            title="미리보기 닫기"
                            >
                            <fa icon="times"/>
                        </b-button>
                    </template>
                </div>
            </div>

            <div class="report-header-body">
                <div class="d-flex flex-column justify-content-center flex-grow-1 py-4 px-5" style="height: 50%;min-height: 300px;">
                    <h1 class="fw-600 display-4 line-height-sm mb-0 pl-3"
                        :style="`font-size: ${theme_option.title_size}rem; font-family:${selected_report.font_family}; border-left: 8px solid ${selected_report.main_color};`" 
                        >
                        {{ lang !== 'ENG' ? selected_report.report_name : selected_report.report_name_e }}
                    </h1>
                    <h5 class="fw-400 gray-600 mb-0 pl-4">
                        {{ selected_report.start_date }} ~ {{ selected_report.end_date }}
                    </h5>
                </div>
                
                <!-- <div class="mb-3 px-5">
                    <h4 class="mb-0">Table of Contents</h4>
                </div> -->
                
                <div class="mb-3 px-5">
                    

                
                    <b-button-group>
                        <b-button 
                            v-if="active_gri_table" 
                            @click="$emit('showTableView', 'GRI')" 
                            :variant="show_gri_table ? 'dark-border-selected' : 'dark-border'"
                            size="lg"
                            :disabled = show_gri_table
                        >
                            GRI
                        </b-button>
        
                        <b-button 
                            v-if="active_sasb_table" 
                            @click="$emit('showTableView', 'SASB')" 
                            :variant="show_sasb_table ? 'dark-border-selected' : 'dark-border'"
                            size="lg"
                            :disabled = show_sasb_table
                        >
                            SASB
                        </b-button>
                        <b-button 
                            v-if="active_tcfd_table" 
                            @click="$emit('showTableView', 'TCFD')" 
                            :variant="show_tcfd_table ? 'dark-border-selected' : 'dark-border'"
                            size="lg"
                            :disabled = show_tcfd_table
                        >
                            TCFD
                        </b-button>

                        <b-button 
                            v-if="active_dc_table" 
                            @click="$emit('showTableView', 'DATA_CENTER')" 
                            :variant="show_dc_table ? 'dark-border-selected' : 'dark-border'"
                            size="lg"
                            :disabled = show_dc_table
                        >
                            Data Center
                        </b-button>
                    </b-button-group>


                    <div v-if="$route.hash" class="mt-5">
                        <b-button @click="navAnchor(null)" >
                            <b-icon-menu-button-fill />
                            {{ lang !== 'ENG' ? '전체보기' : 'Show All'}}

                        </b-button>
                    </div>
                    
                </div>
                <template v-if="lang !== 'ENG'">
                    <div class="d-flex align-items-center flex-grow-1 pb-3 contents-table"
                        :style="[{ backgroundColor: selected_report.main_color }]"
                        >
                        <div v-for="(category, category_index) in report_categories"
                            :class="['contents-table-column py-4 px-5']"
                            :key="`category-${category.category_id}-${category_index}`"
                            :style="[{ width: `${100 / report_categories.length}%` }]"
                            >
                            
                            <!-- <div
                                v-if="show_gri_table" 
                            >
                                <div 
                                    class="d-flex justify-content-between align-items-end"
                                >
                                    <h4 class="text-white mb-0 contents-table-title">{{ category_index + 1 }}. {{ category.category_name }}</h4>
                                    
                                    <div class="cursor contents-table-link" 
                                        @click="navAnchor('#rp'+category.category_id)"
                                        >
                                        <fa icon="arrow-down"></fa>
                                    </div>
                                </div>
                                <div class="contents-table-divider" style=""></div>
                                <h6 class="mb-0 contents-table-title_en">{{ category.category_name_e }}</h6>
                            </div> -->
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="d-flex align-items-top flex-grow-0 py-4 contents-table"
                        :style="[{ backgroundColor: selected_report.main_color }]"
                        >
                        <div v-for="(category, category_index) in report_categories"
                            :class="['contents-table-column pt-3 pb-5 px-5']"
                            :key="`category-${category.category_id}-${category_index}`"
                            :style="[{ width: `${100 / report_categories.length}%` }]"
                            >
                            
                            <div v-if="show_gri_table">

                                <div class="contents-table-divider" style=""></div>
    
                                <div class="d-flex justify-content-between align-items-top">
                                    <h4 class="text-white mb-0 contents-table-title">{{ category_index + 1 }}. {{ category.category_name_e }}</h4>
                                    
                                    <div class="cursor contents-table-link" 
                                        @click="navAnchor('#rp'+category.category_id)"
                                        >
                                        <fa icon="arrow-down"></fa>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- <h6 class="mb-0 contents-table-title_en">{{ category.category_name_e }}</h6> -->
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div 
            v-if="show_gri_table"
            :class="['report-table-contents', 'px-3 py-3', { collapsed: !content_list_open }]">
            <div class="d-flex justify-content-between align-items-end border-bottom pb-4 mx-3 mb-4 pt-2">
                <h3 class="fw-600 mb-0">Table of Contents</h3>

                <div class="text-right f-95">
                    <b-badge class=""> M </b-badge>
                    <span class="">: {{ lang !== 'ENG' ? '중요주제 (Material Topic)' : 'Material Topic' }} </span>
                </div>

                
            </div>
            <contents-list-no-image
                class="report-table-contents-body"
                :content_list_open="content_list_open"
                :preview_mode="true"
                :web_view="true"
                >
            </contents-list-no-image>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import * as ih from '@/components/util'
import ContentsListNoImage from '@/components/report_preview/gri2021_viewer/viewer_components/ContentsListNoImage.vue'

export default {
    name: 'ViewerHeaderNoImageTheme',
    components: {
        ContentsListNoImage
    },
    props: {
        theme_option: {
            type: Object,
            required: true
        },
        dark_logo_url: {
            required: true,
            type: [String, Boolean]
        },
        content_list_open: {
            required: true,
            type: Boolean
        },
        main_bg_color_gradient: {
            required: true,
            type: [String, Object]
        },
        active_gri_table: {
            type: Boolean,
            default: () => false,
        },
        active_sasb_table: {
            type: Boolean,
            default: () => false,
        },
        active_tcfd_table: {
            type: Boolean,
            default: () => false,
        },



        show_gri_table: {
            type: Boolean,
            default: () => false,
        },
        show_sasb_table: {
            type: Boolean,
            default: () => false,
        },
        show_tcfd_table: {
            type: Boolean,
            default: () => false,
        },
        active_dc_table: {
            type: Boolean,
            default: false
        },
        show_dc_table: {
            type: Boolean,
            default: false
        },

        
    },
    data() {
        return {
            example: '',
        }
    },
    computed: {
        ...mapState(['static_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapGetters('gri2021_report_view',  ['report_categories' ]),
        ...mapGetters('report_list', ['selected_report']),
        logo_url () {
            return !this.selected_report.logo_path
                ? null
                : this.static_host + this.selected_report.logo_path
        }
    },
    created() {},
    mounted() {},
    methods: {
        ...mapMutations('report_list', ['change_lang']),

        toggleLang(){
            // const baseURL = window.location.href.split("?")[0]
            // const queryString = window.location.href.split("?")[1]

            // const params = new URLSearchParams(queryString)

            // console.log("toggleLang")

            // if (this.lang=='KOR'){ //현재 국문이면 영문으로 바꾸기
            //     this.change_lang('ENG')
            //     document.querySelector("title").textContent = this.selected_report.report_name_e

            //     params.set("l", "ENG")

            // } else {
            //     this.change_lang('KOR')
            //     document.querySelector("title").textContent = this.selected_report.report_name

            //     params.set("l", "KOR")

            // }

            // const newURL = baseURL + "?" + params.toString()
            // window.history.pushState({}, '', newURL);


            console.log('toggle lang')
            if (this.lang=='KOR'){ //현재 국문이면 영문으로 바꾸기
                this.change_lang('ENG')
                document.querySelector("title").textContent = this.selected_report.report_name_e
                ih.updateParamsFromQueryString("l", "ENG");
            } else {
                this.change_lang('KOR')
                document.querySelector("title").textContent = this.selected_report.report_name
                ih.updateParamsFromQueryString("l", "KOR");
            }


            
        },
        navAnchor(anchor){
            ih.anchorGo(anchor)
        },
    }
}
</script>

<style scoped lang="scss">

.report-header {
    min-height: 410px;

    &:not(.collapsed) {
        @media screen and (max-height: 649.98px) {
            overflow-y: auto;
        }
        min-height: 460px;
    }

    &.collapsed {
        overflow-y: hidden;

        .report-header-body {
            transition-duration: 0.5s;
            transition-delay: 0s;
            opacity: 0; 
        }
    }
    
    &-body {
        display: flex;
        flex-direction: column;
        height: calc(100% - 85.8px);

        transition-property: opacity;
        transition-duration: 0.5s;
        transition-delay: 0.5s;
        transition-timing-function: ease-in-out;
        opacity: 1;
    }
}

.report-table-contents {
    position: absolute;
    top: calc(85.8px + 1.5rem);
    width: calc(100% - 3rem);
    max-height: 100vh;

    display: flex;
    flex-direction: column;
    transition-property: opacity, z-index, transform;
    transition-duration: 0.6s, 0.3s, 0.6s;
    transition-delay: 0.3s, 0s, 0s;
    transition-timing-function: ease, ease, ease;
    opacity: 1;
    z-index: 1;
    overflow-y: hidden;
    height: 100%;
    min-height: 345px;
    transform: translateY(0px);

    
    &.collapsed {
        transition-duration: 0.5s, 0.3s, 0.3s;
        transition-delay: 0s, 0.7s, 0.3s;
        opacity: 0;
        z-index: -1;
        transform: translateY(-30px);
    }

    &-body {
        max-height: calc(100vh - 37.75px - 14.5rem);
        min-height: 240px;
        height: 100%;
        overflow-y: auto;
    }

    &-list {
        max-height: calc(100% - 61px);
        height: 100%;
    }
}

.contents-table {
    &-link {
        transition: margin-bottom 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-bottom: 0px;
        color: white;
        flex-grow: 0;
        flex-shrink: 0;

        &:hover {
            border-width: 2px;
            border-color: #F8F9F9;
            border-style: solid;
            margin-bottom: 1px;
        }
    }

    &-divider {
        height: 3px;
        background-color: white;
        margin-top: 10px;
        margin-bottom: 7px;
    }

    &-title_en {
        color: #f8f9fa;
    }
}

</style>
<template>
    <div>
        <DataCenterLoading
            v-if="toc_list.length === 0"

        />
        <!-- 
        <TableLoading 
        v-if="toc_list.length === 0"
        /> -->
        <template v-else >
            <b-row class="pt-5">
                <b-col cols="3 pt-5">
                    <DataCenterNav class="sticky-position"/>
                </b-col>
                <b-col cols="9">
                    <div>
                        <div>
                            <h2  v-if="selected_lv1_info"  class="fw-600 mb-3 mb-5 text-center pt-5">
                                {{ lang !== 'ENG' ? selected_lv1_info.name : selected_lv1_info.name_em ?
            selected_lv1_info.name_em : selected_lv1_info.name_ea }}
                            </h2>
                            <b-table-simple responsive class="table-borderless table-responsive">
                                <b-tbody
                                    class="mb-2 border-0"
                                >
                                    <b-tr>
                                        <b-td class="py-1 pl-1" :style="main_color_sub">
                                            <span class="fw-600 f-140">
                                                {{ lang !== 'ENG' ? selected_lv2_info.name : (selected_lv2_info.name_em ? selected_lv2_info.name_em : selected_lv2_info.name_ea)}}
                                            </span>
                                            <span class="gray-500 f-90 fw-400 ml-1">
                                            </span>
                                        </b-td>
                                    </b-tr>
        
                                    <b-tr
                                        v-for="(lv3_info, lv3_idx) in selected_lv2_info.children"
                                        :key="'data_center_lv2_' + lv3_idx"
                                    >
                                        <!-- <b-td 
                                            id="crp"
                                            class="pr-4 d-flex flex-column"
                                        >
                                            <div class="fw-500">
                                                {{ lang !== 'ENG' ? lv2_info.name : (lv2_info.name_em ? lv2_info.name_em : lv2_info.name_ea) }}
                                            </div>
                                        </b-td> -->
                                        <b-td class="" style="max-width: 200mm;">
                                            <!-- data_tables 넣을자리 -->
                                            <div class="mb-3">
        
                                                {{ lang !== 'ENG' ? lv3_info.contents : (lv3_info.contents_em ? lv3_info.contents_em : lv3_info.contents_ea ) }}
                                                <ChartCanvas 
                                                    v-if="lv3_info.item_id !=='' && lv3_info.type === 'chart'"
                                                    :chart_id="lv3_info.item_id" 
                                                    :print_mode="print_mode"
                                                    :key="`chart_${lv3_info.item_id}`"
                                                    :viewer_mode="true"
                                                    class="mb-5"
                                                />
                                            
                                                <data-table
                                                    v-if="lv3_info.item_id !== '' && lv3_info.type === 'table'"
                                                    :print_mode="print_mode"
                                                    :table_mode="table_mode" 
                                                    :data_table_id="lv3_info.item_id" 
                                                    :modal_table="modal_table" 
                                                    :lazy_load="false"
        
                                                    :table_style="table_style"
                                                    :show_table_line="show_table_line"
                                                    :show_table_outline="show_table_outline"
                                                    :table_header_color="table_header_color"
                                                    :table_header_font_color="table_header_font_color"
                                                />

                                                
        
                                                
                                                <!-- <hr v-if="lv3_idx < selected_lv2_info.children.length-1 && lv3_info.item_id !== ''"> -->
                                            </div>
        
                                            
                                            
                                        </b-td>
                                    </b-tr>
        
        
        
        
                                </b-tbody>
                            
                            </b-table-simple>
                        </div>
                    </div>
                </b-col>
            </b-row>
            
        </template>
        


    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from './DataTable.vue'
import TableLoading from './TableLoading.vue'
import DataCenterLoading from './DataCenterLoading.vue'
import DataCenterNav from './DataCenterNav.vue'
import ChartCanvas from "@/components/writing_report/chart_subs/ChartCanvas.vue";
export default {
    name: '',
    props: {
        modal_table: { type: Boolean, default: false},
        reload: { type: Boolean, default: false },
        print_mode: { type: Boolean, default: false },
        business_case_mode: { type: String, default: "popup" },
        table_mode: { type: String, default: "accordion" },
        material_style_option: { type: String, default: "outline" },

        table_style: {type: String, default: 'basic_table'},
        show_table_line: {type: Boolean, default: true},
        show_table_outline: {type: Boolean, default: false},
        table_header_color: {type: String, default: '#dedede'},
        table_header_font_color: {type: String, default: '#fffff'},
    },
    components: {
        TableLoading,
        DataTable,
        ChartCanvas,
        DataCenterNav,
        DataCenterLoading
    },
    data() {
        return {
            isSticky: false,
        }
    },
    computed: {
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapGetters('report_list', ['selected_report']),
        ...mapState('charts', ['toc_list']),
        ...mapState('data_center', ['lv1_key', 'lv2_key']),

        selected_lv1_info() {
            return this.toc_list.find(obj => obj.key === this.lv1_key);
        },

        selected_lv2_info() {
            if (this.lv1_key !== "" && this.lv2_key) {

                if (this.lv2_key === "") {
                    return this.toc_list.find(obj => obj['key'] === this.lv1_key).children[0]
                    
                } else {
                    return this.toc_list.find(obj => obj['key'] === this.lv1_key).children.find(obj => obj['key'] === this.lv2_key)

                }
            } else {
                return this.toc_list[0].children[0]
            }

        },
        
        main_color(){
            return `color:`+this.selected_report.main_color
        },
        main_color_sub() {
            if (this.material_style_option === 'fill') {
                return `background-color: ${this.selected_report.main_color}; color: white; border: 1px solid white; `
            } else if (this.material_style_option === 'outline') {
                return `color:` + this.selected_report.main_color + `; border-top: 1px solid ` + this.selected_report.main_color + `!important` + `; border-bottom: 1px solid ` + this.selected_report.main_color
            } else {
                return `background-color: white; border-left: 3px solid ${this.selected_report.main_color}; border-bottom: 1px solid ${this.selected_report.sub_color};border-top: 0px; border-right: 0px;`;
            }
        },
        main_bg_color(){
            if(this.selected_report){
                return `background-color:`+this.selected_report.main_color
            }
            else return `background-color: #494949`
        },
        main2_color() {
            return this.selected_report.main_color
        },
        sub_color() {
            return this.selected_report.sub_color
        },  
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.getDCToC({ "report_code": this.report_code });
        window.addEventListener('scroll', this.handleScroll);


    },
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {
        window.removeEventListener('scroll', this.checkSticky);
     },
    unmounted() { 
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        ...mapActions("charts", ["getDCToC"]),
        handleScroll() {
        const stickyStart = 400; 
        this.isSticky = window.scrollY > stickyStart;
    },
    }
}
</script>
<style>

.sticky-position {
    position: -webkit-sticky;
    position: sticky;
    z-index: 12;
  }
</style>
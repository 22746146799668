<template>
  <div>
    <div
      id="static-header"
      class="pt-5"
      :style="`background-color: ${main_color};`"
    >
      <b-container>
        <div
          class="d-sm-flex justify-content-between align-items-end pb-2"
          style="height: 66px"
        >
          <div class="d-flex align-items-end">
            <a href="#" class="py-1 px-2 header-logo">
              <!-- <b-img
                id="static-header-img"
                :src="`${$store.state.static_host}${logo_path.replaceAll(
                  '\\',
                  '/'
                )}`"
                fluid
              ></b-img> -->
            </a>

            <h3 id="static-header-title" class="gray-100 ml-3">
              {{ report_name }}
            </h3>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    logo_path: { type: String, default: "" },
    report_name: { type: String, default: "" },
    main_color: { type: String, default: "" },
  },
  computed: {},
  methods: {},
  created() {},
  watch: {},
  updated() {
    // console.log(this.selected_report);
  },
  components: {},
};
</script>

<style>
.btn-bottom-circle {
  position: relative;
}
.btn-bottom-circle::after {
  position: absolute;
  bottom: -10px;
  left: 5px;
  width: calc(100% - 10px);
  height: 3px;
  /* border-radius: 4px; */
  background: var(--tealgray600);
  content: "";
}

#header-menu.border-top > div > .btn-icon-gray {
  color: var(--gray-300);
}
#header-menu.border-top > div > .btn-icon-gray:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
#notice-sidebar > header > button {
  transition: all 0.1s;
}
#notice-sidebar > header > button:hover {
  color: var(--teal) !important;
}
/* 
.activity-log-group{
    left: 0;
    top: 100%;
    margin-top: 10px;
    width: 300px;
    height: auto;
    max-height: 
    
} */
</style>

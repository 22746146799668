<template>
<div>
    <h5 class="f-110 mb-4 tail-line-dark">비즈니스 사례 관리</h5>
    <b-row no-gutters class="py-2 mb-2">
        <b-col cols="4"> 비즈니스 사례 제목</b-col>
        <b-col> {{ local_business_case.title }} </b-col>
    </b-row>
    <b-row no-gutters class="py-2 mb-2" v-if="local_business_case.used_codes.length>0">
        <b-col cols="4"> 적용된 보고 지표</b-col>
        <b-col>
            <div v-for="(code, idx) in local_business_case.used_codes" :key="idx" class="mb-2">
                <!-- <template v-if="validateUsedCodes(code)==true"> -->
                <template >
                    <a class="cursor" @click="goHash(code)"> GRI {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                </template>
            </div>

            <div v-for="code in local_business_case.used_codes_sasb" :key="code.id" class="mb-2">
                <!-- <template v-if="validateUsedCodes(code)==true"> -->
                <template >
                    <a class="cursor" @click="goHash(code)"> SASB {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                </template>
            </div>
            <div v-for="code in local_business_case.used_codes_tcfd" :key="code.id" class="mb-2">
                <!-- <template v-if="validateUsedCodes(code)==true"> -->
                <template >
                    <a class="cursor" @click="goHash(code)"> TCFD {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                </template>
            </div>

        </b-col>
    </b-row>
    <b-row no-gutters class="py-2 mb-2">
        <b-col cols="4"> 모든 지표에서 비즈니스 사례 삭제</b-col>
        <b-col>
            <p class="mb-3" v-if="local_business_case.used_codes.length>0">‘{{local_business_case.title}}’ 비즈니스 사례가 적용된 보고 지표가 있습니다. 삭제 시 적용된 모든 지표에서 해당 내용이 사라지며, 다시 복구할 수 없습니다.</p>
            <b-button variant="red" @click="handleDelete"> 비즈니스 사례 삭제하기</b-button>
        </b-col>
    </b-row>
    <b-button class="mt-5 mb-3" @click="handleCancle"> 리스트로 돌아가기 </b-button>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            local_business_case: {}
        }
    },
    props:{
        business_case_id : { default: '' }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState('business_cases', ['business_case_list']),
        ...mapGetters('report_indicators', ['selected_indicators'])

    },
    methods:{
        ...mapActions('business_cases',['deleteBusinessCase']),
        handleCancle(hide_option){
            if(hide_option=='hide_all') {
                this.$emit('cancle-edit', hide_option)
            }else this.$emit('cancle-edit')
        },
        async handleDelete(){
            if (confirm('본 비즈니스 사례를 사용하고 있는 모든 지표에서 삭제되며 되돌릴 수 없습니다. 삭제하시겠습니까?') ==true){
                await this.deleteBusinessCase(this.local_business_case.business_case_id)
            }
            this.handleCancle('hide_all')
        },
        goHash(code){
            let hash_code = '#w'+code
            this.handleCancle('hide_all')
            // this.$router.push({ name: 'Gri'+code.slice(0,1)*100, hash: hash_code })
            if (code.split("-")[0].length > 1) {
                this.$router.push({ name: 'Gri'+code.slice(0,1)*100, hash: hash_code })
                
            } else {
                this.$router.push({ name: 'Gri'+code.split("-")[0], hash: hash_code })
            }


        },
        validateUsedCodes(code){
            if (code){
                let jdx = this.selected_indicators.findIndex(item => item.gri_code == code)
                if (jdx > -1) {
                    return true
                }
                else return false
            }
        }
    },
    created(){
        let idx = this.business_case_list.findIndex(x => x.business_case_id == this.business_case_id )
        if (idx>-1){
            this.local_business_case = this.business_case_list[idx]
        }
    }

}
</script>

<style>

</style>
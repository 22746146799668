<template>
<div>
    <!--테스트
        material_selected: {{ material_selected }} <br>
        topic_id: {{ topic_id }}
        originalMaterialSelected: {{ originalMaterialSelected  }}
    -->
    <!---------->
    <template v-if="is_material_selected_loaded==true">
        <b-form-checkbox v-if="!saving" v-model="material_selected" @change="updateMaterialSelected">
            
            <b-icon v-if="show_completed" icon="check" scale="1.5" variant="success" animation="fade" />
            <span v-else>
                {{material_selected===true?"선택":"선택안함"}}
            </span>
        </b-form-checkbox>

        <span v-else>
            <b-icon icon="circle-fill" animation="throb" font-scale="1" />
            saving..
        </span>

        <span v-if="failedToSave">
            <b-icon icon="x" scale="1.5" variant="danger" animation="fade" />저장 실패
        </span>


    </template>
    <template v-else>
        <b-spinner small type="grow"></b-spinner>
    </template>
</div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
export default {
    data() {
        return {
            material_selected : false,
            is_material_selected_loaded: false,
            show_completed: false,
            saving: false,
            failedToSave: false
        }
    },
    props:{
        topic_id: {type: Number},
        material_flag: { type: Boolean },
        selectedTopicId: { type: Number },
        originalTopicSelected: {type: Boolean},
        originalMaterialSelected: { type: Boolean },
        originalIndicatorSelected: {type: Boolean},
        selectedGriCode: { type: String },
        targetMaterial: {type: Object }

    },
    mounted(){
        // if(this.is_topic_selected_loaded==true){
        //     this.findTopicSelected()
        // }
    },
    computed:{
        ...mapState('report_list', ['report_code', 'standard_mode']),
        ...mapGetters('report_list', ['selected_report', 'gri_ver', 'tcfd_ver']),
        ...mapState('gri2021_indicators', ['topic_payloads', 'changable_material_id', 'changable_material_id_true'])        
    },
    methods:{
        ...mapMutations('gri2021_indicators', ['upload_topic_payload', 'upload_indicator_payload', 'change_topic_select', 'change_indicator_select']),
        updateMaterialSelected(){
            //material은 topic, indicator 중 3-3 영향 주면 됨
            this.$emit('changeOriginalMaterial', this.material_selected, this.topic_id, this.selectedGriCode)

            this.uploadSelectedMaterial();

            if (this.material_selected) {
                if (this.originalTopicSelected === false) {
                    // topic 값 변경 -> True
                    this.$emit('changeTopicFromIndicator', this.topic_id, true);

                }

                const material_indicator_selected = document.querySelectorAll(`.gri_topic_${this.topic_id} input`)[2].checked;
                if (material_indicator_selected === false) {
                    // indicator값 변경 -> True
                    this.$emit('changeMaterialIndicatorFromMaterial', `3-3_${this.topic_id}`, true);
                }

            }



            // let temp_obj = {}
            // let new_arr = []
            // const temp_arr = [...this.topic_payloads]
            // for (const item of temp_arr) {
            //     // 만약 topic_id가 동일하다면
            //     // 여기서 new_arr은 1번째 인덱스값인 material selected값만 바꾼다.
            //     // 그게 new_arr
            //     if (this.topic_id.toString() == Object.keys(item)[0]) {
            //         new_arr = [...item[Object.keys(item)[0]]]
            //         new_arr[1] = this.material_selected

            //         this.change_topic_select(this.topic_id) // 중요주제 true 선택 시 topic selected도 true 만들기
            //         this.change_indicator_select('3-3_'+this.topic_id)

            //     } else {
            //         break
            //     }
            // }

            // // if(this.material_selected===true){
            // //     new_arr = [true, true]
            // //     this.change_topic_select(this.topic_id) // 중요주제 true 선택 시 topic selected도 true 만들기
            // //     this.change_indicator_select('3-3_'+this.topic_id)
            // // }else{
            // //     new_arr = [true, false]
            // // }

            // if (new_arr.length === 0) {
            //     new_arr = [this.originalTopicSelected ?this.originalTopicSelected:false, this.material_selected ? this.material_selected:false]
            // }
            
            // temp_obj[this.topic_id] = new_arr
            // this.upload_topic_payload(temp_obj)

            // let new_obj = {}
            // new_obj['3-3_'+this.topic_id] = true
            // this.upload_indicator_payload(new_obj)
        },
        findTopicSelected(){
            let temp_arr = [...this.topic_payloads]
            let idx = temp_arr.findIndex(z => {return Object.keys(z)[0] == this.topic_id})
            if(idx >-1){
                let new_arr = Object.values(temp_arr[idx])[0]
                this.material_selected = new_arr[1]                
            }
            this.is_material_selected_loaded = true
        },
        async uploadSelectedMaterial() {
            // originalMaterialSelected
            try {
                this.saving = true;
                const path = this.$store.state.backend_host + "/link_report_topics";
                const formData = new FormData()
                formData.append('report_code', this.report_code)
                formData.append('link_payload', JSON.stringify({
                    [this.topic_id]: [this.originalTopicSelected, this.material_selected]
                }))
    
                const result = await axios.post(path, formData);
                if (result.status === 200) {
                    this.show_completed = true;
                    setTimeout(() => {
                        this.show_completed = false;
                    }, 1500);
                    
                } else {
                    this.failedToSave = true;
                    setTimeout(() => {
                        this.failedToSave = false;
                    }, 2000);

                }
                
                this.saving = false;

                
            } catch (error) {
                this.saving = false;
                this.failedToSave = true;
                setTimeout(() => {
                    this.failedToSave = false;
                }, 2000);
            }
        }
    },
    watch: {
        targetMaterial: {
            handler(newVal) {
                if (newVal !== null && newVal.topic_id === this.topic_id) {
                    this.material_selected = newVal.material_value
                    this.uploadSelectedMaterial();
                }
            }
        },
        // selectedGriCode: {
        //     handler(newVal) {
        //         if (this.selectedTopicId == this.topic_id && newVal.includes("3-3_")) {
        //             this.material_selected = this.originalIndicatorSelected

        //             let temp_arr = [...this.topic_payloads]
        //             let idx = temp_arr.findIndex(z => {return Object.keys(z)[0] == this.topic_id})
        //             if(idx >-1){
        //                 let new_arr = Object.values(temp_arr[idx])[0]
        //                 this.$emit('changeOriginalMaterial', new_arr[1], this.topic_id)
        //             }
        //         }
        //   }  
        // },
        // originalMaterialSelected: {
        //     handler(newVal) {
        //         if (this.selectedTopicId == this.topic_id) {
        //             this.material_selected = newVal
        //         }
        //     }
        // },
        // originalTopicSelected: {
        //     handler(newVal) {
        //         if(this.selectedTopicId == this.topic_id){
        //             //this.material_selected = newVal
        //             this.material_selected = this.newVal

        //         }
        //     }
        // },
        // changable_material_id:{
        //     handler(newVal){
        //         // console.log(newVal);
        //         if(newVal == this.topic_id){
        //             this.material_selected = false
        //         }
        //     },
        //     immediate: true
        // },
        // changable_material_id_true:{
        //     handler(newVal){
        //         // console.log(newVal);
        //         if(newVal == this.topic_id){
        //             this.material_selected = true
        //         }
        //     },
        //     immediate: true
        // },
        material_flag:{
            handler(newVal){
                // console.log(newVal);
                if(newVal==true||newVal==false){
                    this.findTopicSelected()
                }
            },
            immediate: true
        },
        
    }
}
</script>

<style>

</style>
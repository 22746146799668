<template>
<div>
    <div class="py-2">
        <p class="fw-400 f-90 mb-2">데이터 테이블 영문명</p>
        <b-form-input v-model="title" placeholder="데이터 테이블 영문명" class="mb-3"></b-form-input>

        <p class="fw-400 f-90 mb-2">영문 설명</p>
        <b-form-textarea v-model="description" rows="2" placeholder="데이터 테이블 영문 설명" class="mb-3"></b-form-textarea>
   
        <p class="fw-400 f-90 mb-2 pb-1">데이터 테이블 작성 (<fa icon="info-circle" class="mr-1"></fa> 테이블 셀을 클릭하여 표 영문 내용을 편집하세요.)</p>
            
        <div class="mb-2">
            <b-button variant="light" class="f-90 table-editor-icon" @click="addRowUpper()" v-b-tooltip.hover title="위쪽에 행 삽입"><b-img src="/tiptap_icons/add_row_before.svg"></b-img></b-button>
            <b-button variant="light" class="f-90 table-editor-icon" @click="addRowBelow()" v-b-tooltip.hover title="아래쪽에 행 삽입"><b-img src="/tiptap_icons/add_row_after.svg"></b-img></b-button>
            <b-button variant="light" class="f-90 table-editor-icon" @click="deleteRow()" v-b-tooltip.hover title="행 삭제"><b-img src="/tiptap_icons/delete_row.svg"></b-img></b-button>
            <b-button variant="light" class="f-90 table-editor-icon" @click="addColLeft()" v-b-tooltip.hover title="왼쪽에 열 삽입"><b-img src="/tiptap_icons/add_col_before.svg"></b-img></b-button>
            <b-button variant="light" class="f-90 table-editor-icon" @click="addColRight()" v-b-tooltip.hover title="오른쪽에 열 삽입"><b-img src="/tiptap_icons/add_col_after.svg"></b-img></b-button>
            <b-button variant="light" class="f-90 table-editor-icon" @click="deleteCol()" v-b-tooltip.hover title="열 삭제"><b-img src="/tiptap_icons/delete_col.svg"></b-img></b-button>
        </div>
        
            <b-table-simple id="table-inside" class="edi-table bg-white border-dark" responsive>
                <b-thead>
                    <b-tr>
                        <b-td v-for="(property, col_index) in table_header" :key="`property_${col_index}`" :class="{ 'is-selected':isThisSelectedProperty(col_index)}" @click="selectProperty(col_index)" class="hover-gray"> 
                            <div v-if="isThisSelectedProperty(col_index) != true">{{ property }}</div>
                            <div v-else>
                                <b-form-input :ref="`header_${col_index}`" type="text" class="edi-input" v-model="table_header_local[col_index]" :state="checkValidation(col_index)" 
                                :aria-describedby="`property-${col_index}-feedback`" @blur="updateProperty($event, col_index)"></b-form-input>
                                <b-form-invalid-feedback :id="`property-${col_index}-feedback`">
                                0자 이상 입력되어야 합니다. 중복 컬럼제목은 불가합니다.
                                </b-form-invalid-feedback>
                            </div> 
                        </b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(row_item, row_index) in table_data" :key="row_item.id">
                        <b-td v-for="(property, col_index) in table_header" :class="{ 'is-selected': isThisSelectedCell(col_index, row_index) }" class="hover-gray" :key="property" 
                        @click="selectAndEdit(property, row_item[property], col_index, row_index)"> 
                            <div v-if="isThisSelectedCell(col_index, row_index) != true">
                                <span 
                                    v-html=formattedText(row_item[property])>
                                </span> 
                            </div>
                            <div v-else>
                                <b-form-textarea
                                    :ref="`cell_${col_index}_${row_index}`" 
                                    type="text" 
                                    class="edi-input" 
                                    v-model="row_item[property]"
                                    max-rows="40"
                                    lazy="true"
                                ></b-form-textarea>
                            </div>                    
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>        

        <p class="fw-400 f-90 mb-2">영문 주석</p>
        <b-form-input v-model="footnote" class="mb-5" placeholder="데이터 표 하단에 덧붙일 영문 부가설명"></b-form-input>
    </div>
    <p v-if="initiating==true" class="f-90 teal"><fa icon="info-circle"/> 데이터 테이블은 초기화에 최대 1분 정도 소요될 수 있습니다. </p>
    <b-button variant="teal" class="mr-3 mb-3" @click="handleInitiate" :disabled="initiating==true"> {{initiating==true? '초기화 중': '번역 초기화'}} <fa icon="redo" :class="initiating==true?'spinning-icon':''"/> </b-button>
    <b-button variant="red" class="mr-3 mb-3" @click="handleSave" :disabled="is_saving==true||initiating==true"> {{is_saving==true? '저장 중' : '저장하기'}} <b-spinner small v-if="is_saving==true"></b-spinner></b-button>
    <b-button @click="handleCancle" class="mb-3"> 취소 </b-button>

</div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import * as ih from '@/components/util'
import { formattedText } from '@/components/util'

export default {
    data(){
        return {
            title: '',
            description: '',
            footnote: '',

            table_header: [ 'No.', '구분 1', '구분 2' ],
            table_header_local: [ 'No.', '구분 1', '구분 2'], //테이블 제목을 수정하는 경우, 실시간 반영시 꼬이기 때문에 buffer를 두기 위해서 v-model은 복제본에다가 걸고, 
                                                        //폼 수정이 끝나는 순간 validation해서 원본 table_header와 table_data에 일괄 반영토록함. 
            table_data: [ 
                { 'No.': '1', '구분 1': '', '구분 2': ''},
                { 'No.': '2', '구분 1': '', '구분 2': ''},
                { 'No.': '3', '구분 1': '', '구분 2': ''}
            ],
            selected_row: -1, 
            selected_col: -1,
            add_num: 1,

            click_outside: false,
            is_saving: false,
            initiating: false
        }
    },
    props:{
        table_id: {default:''}
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState('data_tables', ['table_list']),
        
    },
    methods: {
        formattedText,
        ...mapActions('data_tables', ['saveDataTableEm', 'reInitiateDataTable']),
        selectAndEdit(property, contents, col_index, row_index){
            this.selected_row = row_index
            this.selected_col = col_index
            this.$nextTick( () => {
                this.$refs[`cell_${this.selected_col}_${this.selected_row}`][0].focus()
            })
        },
        isThisSelectedCell(col_index, row_index){
            if(this.selected_col == col_index && this.selected_row == row_index) { return true }
            else { return false }
        },
        selectProperty(col_index){
            this.selected_row = -1
            this.selected_col = col_index
            this.$nextTick( () => {
                console.log(this.$refs)
                this.$refs[`header_${this.selected_col}`][0].focus()
            })
        },
        isThisSelectedProperty(col_index){
            if(this.selected_row < 0 && this.selected_col == col_index) { return true }
            else { return false }
        },
        checkValidation(idx){
            //table_header_local이 v-model에 의해 수정되고 있다. validation한다. 
            //1. 글자를 모두 지우지 않았는지?
            if( this.table_header_local[idx] == '' ){
                return false
            }
            //2. 똑같은 제목을 붙이지 않았는지? 표의 컬럼제목은 유일해야 한다. 
            let num_duplicated = 0
            for(let i=0; i < this.table_header_local.length; i++){
                if( this.table_header_local[i] == this.table_header_local[idx] ){
                    num_duplicated += 1
                }
            }
            if( num_duplicated != 1){ //해당 값이 유일하지 않다는 의미임
                return false
            }
            return null
        },
        updateProperty(evt, idx){
            //table_header_local이 v-model에 의해 수정되었다. validation 거친 후에, 문제 없으면 table_header에 덮어 쓴다. 
            if( this.checkValidation(idx) != null && this.checkValidation(idx) != true){
                //Validation 실패하면, 업데이트에 실패한다.
                this.table_header_local = [ ...this.table_header ]
            }
            else {
                //모든 키를 교환
                this.table_data.forEach( item => {
                    if( item.hasOwnProperty(this.table_header[idx]) ){
                        item[this.table_header_local[idx]] = item[this.table_header[idx]] //신규 key-property 생성
                        if(item[this.table_header_local[idx]] != item[this.table_header[idx]])
                            { 
                                //제목이 원래 것에서 변경된 경우에만
                                delete item[this.table_header[idx]] //기존 key-property 삭제
                            }
                    }
                })
                //업데이트 한다. 1) 테이블 제목 copy
                this.table_header =  [ ...this.table_header_local ]
            }
        },
        async handleSave(){
            if( this.checkForm() == true ){
                this.is_saving = true
                await this.saveDataTableEm({
                    table_id: this.table_id,
                    title: this.title,
                    description: this.description,
                    header: this.table_header,
                    contents: this.table_data,
                    footnote: this.footnote
                })
                this.$EventBus.$emit('make-toast', '데이터 테이블 번역 저장', `${this.title} 영문 번역이 저장되었습니다.`)
                this.is_saving = false
                this.handleCancle()
            }
        },
        handleCancle(){
            this.initiateTable()
            this.$emit('cancle-edit')
        },
        async handleInitiate(){
            this.initiating = true
            await this.reInitiateDataTable({
                table_id: this.table_id,
                re_initiate: true
            })
            this.initiateTable()
            this.initiating = false
            this.$EventBus.$emit('make-toast', '데이터 테이블 번역 초기화', `영문 번역이 AI 자동번역 상태로 초기화되었습니다.`)
        },
        initiateTable(){
            if (this.table_id!= ''){
                let temp_list = ih.deepCopy(this.table_list)
                let idx = this.table_list.findIndex(x => x.table_id == this.table_id)
                if (idx > -1){
                    // console.log(temp_list[idx]);
                    if(temp_list[idx].title_em!=''&&temp_list[idx].title_em!=null){
                        this.title = temp_list[idx].title_em
                    }else this.title = temp_list[idx].title_ea
                    if(temp_list[idx].description_em!=''&&temp_list[idx].description_em!=null){
                        this.description = temp_list[idx].description_em
                    }else this.description = temp_list[idx].description_ea
                    if(temp_list[idx].footnote_em!=''&&temp_list[idx].footnote_em!=null){
                        this.footnote = temp_list[idx].footnote_em
                    }else this.footnote = temp_list[idx].footnote_ea
                    if(temp_list[idx].header_em!=[]&&temp_list[idx].header_em!=['']&&temp_list[idx].header_em!=null){
                        this.table_header = temp_list[idx].header_em
                    }else this.table_header = temp_list[idx].header_ea
                    if(temp_list[idx].contents_em!=[]&&temp_list[idx].contents_em!=['']&&temp_list[idx].contents_em!=null){
                        this.table_data = temp_list[idx].contents_em
                    }else this.table_data = temp_list[idx].contents_ea
                }
                if(temp_list[idx].header_em!=[]&&temp_list[idx].header_em!=['']&&temp_list[idx].header_em!=null){
                this.table_header_local = temp_list[idx].header_em
                }else this.table_header_local = temp_list[idx].header_ea
            }            
        },
        checkForm() {
            if ( this.title == null || this.title == undefined || this.title == ''){
                alert ('데이터 테이블 영문명을 기입해주시기 바랍니다.') 
                return false
            }
            let idx = this.table_list.findIndex( table => table.title_em == this.title ) // 동일한 title이 있는지 확인
            let table_idx = this.table_list.findIndex( table => table.table_id == this.table_id ) // table_id가 동일한지 확인
            if ( idx == -1 || idx == table_idx ){
                return true
            } else {
                alert ('중복되는 데이터 테이블 영문명이 있습니다. 다른 이름으로 변경해주세요.') 
                return false 
            }
        },



        addRowUpper(){
            if(this.selected_row > -1 && this.selected_col > -1){
                //새 로우를 만든다
                let new_row = {}
                this.table_header.forEach(property => {
                    new_row[property] = ''
                })
                //지정된 위치에 추가한다
                this.table_data.splice(this.selected_row, 0, new_row)
            }
            else if (this.selected_row==-1){ alert('제목 행에는 행을 추가할 수 없습니다')
            }
            else alert('행을 추가할 위치를 클릭해주세요')
        },
        addRowBelow(){
            if(this.selected_col > -1){
                //새 로우를 만든다
                let new_row = {}
                this.table_header.forEach(property => {
                    new_row[property] = ''
                })
                //지정된 위치에 추가한다
                this.table_data.splice(this.selected_row + 1, 0, new_row )
            }else {  // 선택한 셀 없으면 그냥 맨 아래에 추가해주기
                let new_row = {}
                this.table_header.forEach(property => {
                    new_row[property] = ''
                })
                this.table_data.push(new_row)
            }
        },
        deleteRow(){
            if(this.selected_row > -1 && this.selected_col > -1 && this.table_data.length > 1){
                //로우 데이터 삭제
                this.table_data.splice(this.selected_row, 1)
            }else alert('삭제할 행을 클릭한 후 삭제 버튼을 눌러주세요')
        },
        addColLeft(){
            if(this.selected_col > -1){
                //왼쪽에 열을 삽입한다
                this.table_header.splice(this.selected_col, 0, '제목' + this.add_num)
                this.table_header_local = [...this.table_header]
                //각 로우에서 col번째 아이템 삽입
                for(let j=0; j<this.table_data.length; j++){
                    this.$set(this.table_data[j],'제목' + this.add_num, '')
                }
                this.add_num += 1
            }else alert('열을 추가할 위치를 클릭해주세요')
        },
        addColRight(){
            if(this.selected_col > -1){
                //오른쪽에 열을 삽입한다
                this.table_header.splice(this.selected_col + 1, 0, '제목' + this.add_num )
                this.table_header_local = [...this.table_header]
                //각 로우에서 col번째 아이템 삽입
                for(let j=0; j<this.table_data.length; j++){
                    this.$set(this.table_data[j], '제목' + this.add_num, '')
                }
                this.add_num += 1
            }else {
               this.table_header.push(this.add_num) // 선택한 셀 없으면 그냥 맨 오른쪽에 추가해주기
                this.table_header_local = [...this.table_header]
                //각 로우에서 col번째 아이템 삽입
                for(let j=0; j<this.table_data.length; j++){
                    this.$set(this.table_data[j], '제목' + this.add_num, '')
                }
                this.add_num += 1
            }
        },
        deleteCol(){
            if(this.selected_row > -1 && this.selected_col > -1 && this.table_header.length > 1){
                //각 로우 데이터 삭제
                for(let j=0; j<this.table_data.length; j++){
                    delete this.table_data[j][this.table_header[this.selected_col]]
                }
                //헤더 삭제
                this.table_header.splice(this.selected_col, 1)
                this.table_header_local = [...this.table_header]
            }else alert('삭제할 열을 클릭한 후 삭제 버튼을 눌러주세요')
        },



    },
    created(){
        // console.log(this.table_id);
        this.initiateTable()
        //시작할 때 복제해줘야 제대로 작동함
    },
    mounted(){
        //테이블 제목 또는 테이블셀 내부가 클릭되었는지 검출하는 이벤트 리스너를 DOM에 추가한다. 
        let specifiedElement = document.getElementById('table-inside');
        document.addEventListener('click', event => {
            let isClickInside = specifiedElement.contains(event.target);
            if (!isClickInside) {
                this.$emit('click-outside')
            }
        });

        //테이블 바깥쪽이 클릭되었다는 이벤트가 발생하면, 선택 커서를 초기화 하는 이벤트대응함수를 추가한다. 
        this.$on('click-outside', () => {
            this.selected_row = -1 
            this.selected_col = -1
        })
    }
}
</script>
<style scoped>
.is-selected {
    padding: 0.2rem 0.75rem 0;
}

.edi-input.form-control {
    border: 0;
    border-radius: 0;
    padding:  0.3rem 0.1rem;
    border-bottom: 3px solid var(--teal4);
}
.edi-input.form-control:focus{
    box-shadow: 0 0 0 0;
}

.table-editor-icon{
    padding: 0.25rem 0.5rem;
    margin-right: .2rem;
    background-color: var(--gray-300);
}
.table-editor-icon:hover{
    background-color: var(--gray-500);
}

.table-editor-icon > img{
    max-height: 20px;
}
</style>
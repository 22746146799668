<template>
<div>
         
    <register-report class="pb-3"></register-report>
    <!-- <design-theme class="pb-3"></design-theme> -->
    <delete-report class="pb-5"></delete-report>
</div>    
</template>
<script>
import RegisterReport from '@/components/report_preference/basic_settings_subs/RegisterReport'
import DesignTheme from '@/components/report_preference/basic_settings_subs/DesignTheme'
import DeleteReport from '@/components/report_preference/basic_settings_subs/DeleteReport'


export default {
    data() {
        return {
            
        }
    },
    computed:{

    },
    methods:{

    },
    components:{
        RegisterReport,
        DesignTheme,
        DeleteReport,
    }
    
}
</script>
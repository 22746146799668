const gri2021_indicators = {
    namespaced: true, 
    state: {
        topic_payloads : [],
        changable_topic_id : null,
        changable_material_id: null,
        changable_material_id_true: null,
        indicator_payloads : [],
        changable_gri_code: null,
        changable_gri_topics: null

    },
    mutations: {
        upload_topic_payload(state, new_obj) { // payload object 통으로 갈아끼우거나 넣음 
            // 오직 저장만을 위한 용도로, 화면 뿌리는 것은 여기서 관리하지 않음
            let new_arr = [...state.topic_payloads]
            let temp_id = Object.keys(new_obj)[0]
            let idz = new_arr.findIndex(z =>{
                return Object.keys(z)[0] == temp_id
            })
            if (idz >-1){
                new_arr.splice(idz, 1, new_obj)
            }else new_arr.push(new_obj)
            // console.log(new_arr);
            state.topic_payloads = new_arr
        },
        upload_indicator_payload(state, new_obj) { // payload object 통으로 갈아끼우거나 넣음 
            // 오직 저장만을 위한 용도로, 화면 뿌리는 것은 여기서 관리하지 않음
            let new_arr = [...state.indicator_payloads]
            let temp_id = Object.keys(new_obj)[0]
            let idz = new_arr.findIndex(z =>{
                return Object.keys(z)[0] == temp_id
            })
            if (idz >-1){
                new_arr.splice(idz, 1, new_obj)
            }else new_arr.push(new_obj)
            // console.log(new_arr);
            state.indicator_payloads = new_arr
        },
        upload_indicator_payload_topic(state, topic_id){
            let new_arr = [...state.indicator_payloads]
            new_arr.forEach(z =>{
                if(Object.keys(z)[0].includes(topic_id)==true){
                    let new_obj = {}
                    new_obj[Object.keys(z)[0]] = false
                    Object.assign(z, new_obj)
                }
            })
            // console.log(new_arr);
            state.indicator_payloads = new_arr
        },
        empty_topic_payloads(state){
            state.topic_payloads = []
        },
        empty_indicator_payloads(state){
            state.indicator_payloads = []
        },
        change_topic_select(state, topic_id){
            state.changable_topic_id = topic_id
        },
        change_material_select(state, topic_id){
            state.changable_material_id = topic_id
        },
        change_material_select_true(state, topic_id){
            state.changable_material_id_true = topic_id
        },
        change_indicator_select(state, gri_code){
            state.changable_gri_code = gri_code
        },
        change_indicator_select_topic(state, topic_id){
            state.changable_gri_topics = topic_id
        }
    },
    getters: {


    },
    actions: {
        saveLinkTopics({state, commit, rootState}, report_code){
            const path = rootState.backend_host + '/link_report_topics'

            const topic_list = [...state.topic_payloads]
            let promises = []
            console.log(topic_list);

            topic_list.forEach(item => {
                const formData = new FormData()
                formData.append('report_code', report_code)
                formData.append('link_payload', JSON.stringify(item))

                promises.push(
                    axios.post(path, formData)
                    .catch(error=> console.log(error))
                )
            })
            Promise.all(promises)
            .then(response =>{
                console.log(response);
            })
        },
        saveLinkIndicators({state, commit, rootState}, report_code){
            const path = rootState.backend_host + '/link_report_indicators'

            const indicator_list = [...state.indicator_payloads]
            let promises = []
            console.log(indicator_list);

            indicator_list.forEach(item => {
                const formData = new FormData()
                formData.append('report_code', report_code)
                formData.append('link_payload', JSON.stringify(item))

                promises.push(
                    axios.post(path, formData)
                    .catch(error=> console.log(error))
                )
            })
            Promise.all(promises)
            .then(response =>{
                console.log(response);
            })
        },


    }
}

export default gri2021_indicators
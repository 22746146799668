<template>
  <div>
    <b-overlay :show="loadingShow" no-wrap></b-overlay>

    <h5 v-if="!is_editing">ESG 정보공개 표준 선택</h5>
    <div v-if="report_type_option">
      <b-row
        v-for="type_id in report_type_option['order']"
        :key="type_id"
        no-gutters
        class="hover-gray py-2 mb-3"
      >
        <div v-if="report_type_option[type_id]['is_latest']">
          <b-col v-if="is_editing">
            <b-form-checkbox
              v-model="local_report.report_type"
              :value="type_id"
              size="lg"
            >
              {{ report_type_option[type_id]["type_name"] }}
            </b-form-checkbox>

            <template
              v-if="
                type_id === 'GRIv2021' && local_report &&
                local_report.report_type.includes('GRIv2021')
              "
            >
              <b-form-checkbox class="pt-2 ml-3" v-model="is_gri_2021" switch>
                {{
                  is_gri_2021 === true
                    ? "최신 버전 : 2021년 개정안"
                    : "버전 : 2016년 구 버전"
                }}
              </b-form-checkbox>
            </template>
          </b-col>

          <b-col v-else>
            <div v-if="local_report && local_report.report_type.includes(type_id)">
              {{ report_type_option[type_id]["type_name"] }}

              ({{
                report_type_option[type_id]["is_latest"]
                  ? "최신 개정안"
                  : "구버전"
              }})
            </div>
            <div
              v-if="
                type_id === 'GRIv2021' && local_report &&
                local_report.report_type.includes('GRIv2016')
              "
            >
              GRI Standards 2016(구버전)
            </div>
          </b-col>
        </div>
      </b-row>
    </div>

    <b-button
      :variant="is_editing === true ? 'teal' : 'teal-border'"
      class="px-3 mr-3"
      @click="handleMulti"
    >
      {{ is_editing == true ? "보고표준 저장하기" : "보고표준 수정하기" }}
    </b-button>
    <b-button v-if="is_editing == true" class="px-3" @click="handleCancle">
      취소
    </b-button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { gri_mixin } from "@/components/writing_report/write_report_subs/gri_mixin";

export default {
  mixins: [gri_mixin],
  data() {
    return {
      loadingShow: true,
      is_editing: false,
      local_report: null,
      standard_options: [
        { text: "GRI Standards", value: "GRI" },
        { text: "SASB Standards (IFRS S1)", value: "GRI" },
        { text: "GRI Standards", value: "GRI" },
        { text: "TCFD", value: "TCFDv2021" },
      ],
      report_type_option: null,
      selected_standard: [],
      is_gri_2021: true,
    };
  },
  computed: {
    ...mapState("report_list", ["report_code"]),
    ...mapState(["user_id"]),
    ...mapGetters("report_list", ["selected_report"]),
    gri_included() {
      if (this.local_report && this.selected_standard.includes("GRI") == true) {
        return true;
      } else false;
    },
  },
  methods: {
    ...mapActions("report_list", ["saveReportPreference"]),
    handleOldVer(type_id, checked) {
      // GRI버전에 대해서만 우선 하드코딩
      if (checked) {
        this.local_report.report_type = this.local_report.report_type.filter(
          (item) => item !== "GRIv2021"
        );
      } else {
        this.local_report.report_type.push("GRIv2021");
      }
    },
    handleMulti() {
      const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit);
      if (!shouldEdit) return;

      // if (this.selected_report.state<4){
      if (this.is_editing == true) {
        this.handleSave();
      } else {
        this.toggleEdit();
      }
      //} else alert(`공개된 보고서는 수정할 수 없습니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 내용을 수정할 수 있습니다.`)
    },
    async handleCancle() {
      await this.initiateReport();
      this.toggleEdit();
    },
    toggleEdit() {
      this.is_editing = !this.is_editing;
    },
    async getReportInfoList() {
      const response = await axios.get(
        this.$store.state.backend_host + "/get_report_framework_info"
      );

      this.report_type_option = response.data.result;
    },
    async handleSave() {
      if (this.local_report.report_type.length === 0) {
        alert("보고 표준을 1개 이상 선택해 주세요.");
        this.is_editing = true;
        return false;
      }

      if (!this.is_gri_2021) {
        this.local_report.gri_use = "GRIv2016";

        this.local_report.report_type = this.local_report.report_type.filter(
          (item) => item !== "GRIv2021"
        );

        if (local_report && !this.local_report.report_type.includes("GRIv2016")) {
          this.local_report.report_type.push("GRIv2016");
        }
      } else {
        this.local_report.gri_use = "GRIv2021";

        this.local_report.report_type = this.local_report.report_type.filter(
          (item) => item !== "GRIv2016"
        );
      }

      await this.saveReportPreference({
        report_code: this.report_code,
        report_name: this.local_report.report_name,
        gri_use: this.local_report.gri_use,
        tcfd_use: this.local_report.tcfd_use,
        report_type: this.local_report.report_type,
      });
      this.initiateReport();
      this.$EventBus.$emit(
        "make-toast",
        "보고 표준 저장",
        `${this.local_report.report_name} 보고 표준이 저장되었습니다.`
      );
      this.is_editing = false;
    },
    async initiateReport() {
      this.loadingShow = true;
      const temp_report = {};
      this.selected_standard = [];
      this.is_gri_2021 = true;
      if (this.selected_report != {} && this.selected_report != undefined) {
        temp_report.report_code = this.selected_report.report_code;
        temp_report.report_name = this.selected_report.report_name;
        temp_report.gri_use = this.selected_report.gri_use;
        temp_report.tcfd_use = this.selected_report.tcfd_use;

        // report framework정보 가져오기
        const response = await axios.get(
          this.$store.state.backend_host +
            "/get_current_report_framework_list?rc=" +
            this.selected_report.report_code
        );

        temp_report.report_type = response.data.result;
        // this.selected_report = response.data.result;
        // this.selected_report = {
        //     ...this.selected_report
        // }
        if (
          this.selected_report.gri_use != null &&
          this.selected_report.gri_use != "not_report"
        ) {
          this.selected_standard.push("GRI");
          if (this.selected_report.gri_use == "GRIv2016") {
            this.is_gri_2021 = false;
          }
        }
        if (
          this.selected_report.tcfd_use != null &&
          this.selected_report.tcfd_use != "not_report"
        ) {
          this.selected_standard.push(this.selected_report.tcfd_use);
        }
      }
        this.local_report = temp_report;
      this.loadingShow = false;
    },
  },
  created() {
    this.initiateReport();
    this.getReportInfoList();
  },
};
</script>

<style></style>

<template>
<div class="position-relative">

    <!-- GRI 100 -->
    <h6 class="mb-3">Universal Standards</h6>
    <h6 class="pl-2 py-1 my-2 tealgray500 bg-tealgray300">GRI 102 <span class="fw-300 f-100 ml-1"> General Indicators </span></h6>

    <b-row no-gutters v-for="(category, index) in series_100" :key="category.id"  class="ml-xl-2 hover-gray f-90 py-1 border-bottom">
        <b-col cols="5" xl="4" class=""> {{ index+1 }}. {{ category.name }} </b-col>
        <b-col class="pl-3">
            <template v-if="selected_contents_loaded==true">
                <span v-for="item in series_contents(index)" :key="item.id" class="mr-3 mb-2 fw-300" 
                v-b-tooltip.hover :title="item.indicator_name"> {{ item.gri_code }} </span>
            </template>
            <template v-else>
               <b-skeleton></b-skeleton>
            </template>
        </b-col>
    </b-row>

    <!-- GRI 200 ~ 400 -->
    <h6 class="pt-4 mb-3">Topic Standards Disclosures</h6>
    <div v-for="part in structures" :key="part.id" class="mb-4">
        <template v-if="checkTopicExist(part.key_code)==true">
            <h6 class="pl-2 py-1 my-2 tealgray500 bg-tealgray300"> GRI {{part.key_code}}00 <span class="fw-300 f-100 ml-1">{{part.name_e}}</span></h6>

            <template v-if="selected_contents_loaded==true">
                <b-row no-gutters v-for="topic in gri_topics(part.key_code)" :key="topic.id" class="ml-xl-2 hover-gray f-90 py-1 border-bottom">
                    <b-col cols="5" xl="4" class=""> GRI {{ topic.topic_id }} : {{ topic.topic_name }} 
                        <b-badge v-if="topic.material==true" variant="advance" > 중요주제 </b-badge>
                    </b-col>
                    <b-col class="pl-3">
                        <span v-for="item in topic.gri_codes" :key="item.id" class="mr-3 mb-2 fw-300"
                        v-b-tooltip.hover :title="item.indicator_name"> {{ item.gri_code }} </span>
                    </b-col>        
                </b-row>
            </template>
            <template v-else>
                <b-row no-gutters class="ml-xl-2 hover-gray f-90 py-1 border-bottom">
                    <b-col cols="5" xl="4" class=""><b-skeleton></b-skeleton> </b-col>
                    <b-col class="pl-3"><b-skeleton></b-skeleton> </b-col>
                </b-row>
            </template>
        </template>
    </div>
    <div class="text-center pt-5"><b-button v-if="selected_contents_loaded==true" variant="teal" class="shadow-sm complete-select" @click="completeSelect">지표 선택 완료</b-button></div>

</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {
            structures: [
                {
                    'key_code' : 2,
                    'name' : '경제',
                    'name_e' : 'Economic'
                },
                {
                    'key_code' : 3,
                    'name' : '환경',
                    'name_e' : 'Environmental'
                },
                {
                    'key_code' : 4,
                    'name' : '사회',
                    'name_e' : 'Social'
                },
            ],
        }
    },    
    computed:{
        ...mapState('report_contents', ['series_100']),
        ...mapState('report_indicators',['selected_contents', 'selected_contents_loaded']),
        ...mapGetters('report_indicators', ['selected_indicators']),   
        gri_topics(){
            return(num) =>{                
                if(num==2){
                    return this.selected_contents.filter( x => x.topic_id >= 200 && x.topic_id<=299)
                }
                else if(num==3){
                    return this.selected_contents.filter( x => x.topic_id >= 300 && x.topic_id<=399)
                }
                else if(num==4){
                    return this.selected_contents.filter( x => x.topic_id >= 400 && x.topic_id<=499)
                }
            }            
        },
        series_contents(){
            return(index)=>{
                // this.selected_indicators.filter(x => x.gri_code == this.series_100[index])
                let temp_list = []
                for (let i=0; i<this.series_100[index].codes.length; i++){
                    this.selected_indicators.forEach(x => {
                        if(x.gri_code == this.series_100[index].codes[i]){
                            temp_list.push(x)
                        }
                    })
                }
                return temp_list
            }
        },
    },
    methods:{
        completeSelect(){
            this.$emit('complete-select')
        },
        checkTopicExist(head_num){
            if (head_num == 2) {
                let sth = this.selected_contents.find(item => item.topic_id>200&&item.topic_id<299)
                if (sth!=undefined){
                    return true
                }
            }
            if (head_num == 3) {
                let nth = this.selected_contents.find(item => item.topic_id>300&&item.topic_id<399)
                if (nth!=undefined){
                    return true
                }
            }
            if (head_num == 4) {
                let eth = this.selected_contents.find(item => item.topic_id>400&&item.topic_id<499)
                if (eth!=undefined){
                    return true
                }
            }
        }
       
    },
    created(){
    },
    mounted(){
        // console.log(this.selected_indicators);
    },
    components:{
    },
    watch:{
    }
}
</script>

<style >
.complete-select{
    width: 200px; 
    /* bottom: 50px; 
    left: calc(50% - 100px); */
}
</style>
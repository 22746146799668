<template>
    <div>
        {{ selected_radio_table_id }}
        <div class="pt-2 pb-4" v-if="dc_key_info.length>0">
            <h5 class="f-110 tail-line-dark">{{ dc_key_info[0]['name'] }} > {{ dc_key_info[1]['name'] }} 지표에 데이터 테이블 추가 </h5>
        </div>
            <b-table-simple responsive hover class="bor-table mb-45">
                <b-thead>
                    <b-tr>
                        <b-td>선택</b-td>
                        <b-td>No.</b-td><b-td>지표</b-td>
                        <b-td>데이터 테이블 명</b-td>
                        <b-td>표 펼쳐보기</b-td>
                        <b-td>수정</b-td><b-td>관리</b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(item, index) in table_list" :key="`table_${index}`">
                        <b-td>
                            <b-form-group>
                                <b-form-radio 
                                    v-model="selected_radio_table_id"
                                    :value="item.table_id"
                                />
                            </b-form-group>
                        </b-td>
                        <b-td class="gray-600">
                            {{ index+1 }}
                        </b-td>
                        <b-td>
                            <!-- <span v-for="(item1, index1) in table_list[index]['used_codes']" :key="`gri_${index1}`"> -->
                            <span v-for="(item1, index1) in table_list[index]['used_codes']" :key="index1">
                                <a class="cursor" @click="goHash(item1, 'GRI')"> 
                                    GRI {{ item1 }} <fa icon="link" class="ml-1"/>
                                </a> 
                                <br>
                            </span>
                            <span v-for="(item2, index2) in table_list[index]['used_codes_sasb']" :key="`sasb_${index2}`">
                                <a class="cursor" @click="goHash(item2, 'SASB')"> 
                                    SASB {{ item2 }} <fa icon="link" class="ml-1"/>
                                </a> 
                                <br>
                            </span>
                            <span v-for="(item3, index3) in table_list[index]['used_codes_tcfd']" :key="`tcfd_${index3}`">
                                <a class="cursor" @click="goHash(item3, 'TCFD')"> 
                                    TCFD {{ item3 }} <fa icon="link" class="ml-1"/>
                                </a> 
                                <br>
                            </span>

                            <span v-for="(linked_info, index) in table_linked_indicators[`${item.table_id}`]" :key="`gri_${index}`">
                                <a class="cursor" @click="goHash(linked_info, 'DATA_CENTER')"> 
                                    Data Center {{ linked_info['lv1_info']['name'] }} > 
                                    {{ linked_info['lv2_info']['name'] }} <fa icon="link" class="ml-1"/>
                                </a> 
                                <br>
                            </span>
                            
                        </b-td>
                        <b-td> 
                            <div class="">{{ item.title }}</div> 
                            <p class="mb-0 f-90">{{ item.description }}</p> 
                        </b-td>
                        <b-td>
                            <a class="cursor" @click="showTable(item.table_id)">{{ opened_table.table_id==item.table_id? '닫기':'보기' }}<fa icon="expand" class="ml-1"/></a>
                        </b-td>
                        <b-td> 
                            <b-button variant="dark-border" class="py-1 px-2" @click="addOrEditTable(item.table_id)">
                                <fa icon="pen"/>
                            </b-button> 
                        </b-td>
                        <b-td>
                            <b-button variant="dark-border" class="py-1 px-2" @click="mgtTable(item.table_id)">
                                <fa icon="wrench"/>
                            </b-button> 
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        
            <!-- 데이터 테이블 펼쳐보기 -->
            <b-card no-body v-if="is_table_opened==true" class="mb-5 px-3 px-lg-4 py-2 border-0" :class="editing_gri_code==''?'bgray-100':''">
                <div class="mb-3 fw-500 gray-600"> {{ opened_table.title }}</div>
                <b-table-simple class="bor-table">
                    <b-thead>
                        <b-tr>
                            <b-td v-for="(property, col_index) in opened_table.header" :key="col_index">
                                {{ property }}
                            </b-td>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="row_item in opened_table.contents" :key="row_item.id">
                            <b-td v-for="property in opened_table.header" :key="property.id">
                                <span 
                                    v-html=formattedText(row_item[property])>
                                </span> 
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-card>
        
            <b-card class="make-new-card text-center cursor bg-transparent" @click=" addOrEditTable()"><fa icon="plus" class="mr-2"/>새 데이터 테이블 생성 </b-card>
        
            <div class="pt-5 pb-3">
                <b-button variant="teal" class="mr-3" @click="applyTable">
                    선택한 데이터 테이블 적용
                </b-button>
                <b-button @click="closeEditChartModal">
                    취소
                </b-button>
            </div>
        
        </div>
    </template>
    
    <script>
    import { mapState, mapActions } from 'vuex'
    import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'
    import { formattedText } from '@/components/util'
    
    export default {
        mixins: [gri_mixin],
        props: {
            dc_key_info: { type: Array, default: () => [] },
            editing_mode: { type: Boolean, default: () => false },
            table_id: { type: Number, default: () => null }
        },
        data() {
            return {
                local_table_select : {},
                is_table_opened: false,
                opened_table: {},

                selected_radio_table_id: '',
            }
        },
        mounted() {
            // if (this.dc_key_info.length > 0 && this.dc_key_info[2].type === 'table') {
            //     this.selected_radio_table_id = this.dc_key_info[2].item_id;
            // }

            if (this.table_id) {
                this.selected_radio_table_id = this.table_id;
            } else {
                this.selected_radio_table_id = '';

            }
        },
        computed:{
            ...mapState('report_list', ['report_code', 'standard_mode']),
            ...mapState('data_tables', ['table_list']),
            ...mapState('cursor', ['editing_gri_code']),
            ...mapState('charts', ['table_linked_indicators'])
        },
        methods: {
            formattedText,
            ...mapActions("charts", ["saveAddChart", "updateDCIndicator", "getDCToC"]),
            ...mapActions('data_tables', ['updateDataTableRelation']),
            addOrEditTable(table_id) {
                const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
                if (!shouldEdit) return
                if (this.editing_gri_code==''){ //관리 페이지에서 생성-수정시
                    if (table_id) {
                        if (!this.editing_mode) {
                            this.$emit('open-edit-modal', table_id)
                            
                        } else {
                            this.$emit('flag_modal_mode', 'table_edit', table_id)
                        }
                    }
                    else {
                        this.$emit('flag_modal_mode', 'table_edit')
                    }
                }else { //gri 작성 모달에서 생성-수정시
                    if(table_id){
                        this.$emit('edit-table', table_id)
                    }
                    else {
                        this.$emit('edit-table')
                    }
                }
            },
            mgtTable(table_id) {
                const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
                if (!shouldEdit) return
    
                if (this.editing_gri_code==''){ //관리 페이지에서 생성-수정시
                    if (table_id) {
                        if (!this.editing_mode) {
                            this.$emit('open-manage-modal', table_id)
                        } else {
                            this.$emit("flag_modal_mode", "table_manage", table_id)
                        } 
                            
                    }
                    else {
                        this.$emit('open-manage-modal', '')
                    }
                }else { //gri 작성 모달에서 생성-수정시
                    if(table_id){
                        this.$emit('table-manage', table_id)
                    }
                    else {
                        this.$emit('table-manage')
                    }
                }
            },
            initiateLocalTableSelect(){
                this.local_table_select = {}
                for (let i=0; i<this.table_list.length; i++){
                    let used_idx = this.table_list[i].used_codes.findIndex( k => k == this.editing_gri_code)
                    let temp_table_id = this.table_list[i].table_id
                    if (used_idx > -1){
                        this.local_table_select[temp_table_id] = true
                    }
                    else this.local_table_select[temp_table_id] = false
                }
                // console.log(this.local_table_select);
                
                
            },
            closeEditChartModal() {
                this.selected_radio_table_id = '';
                this.$emit("closeModal");
            },
            async applyTable() {
                const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
                if (!shouldEdit) return
    
                // let new_payload = {...this.local_table_select}
                // // console.log(new_payload);
                // await this.updateDataTableRelation({
                //     report_code: this.report_code,
                //     gri_code: this.editing_gri_code,
                //     link_payload: new_payload,
                //     framework: this.standard_mode 
                // })
                // this.handleCancle()
                if (this.selected_radio_table_id === '') {
                    window.alert("차트를 선택해주세요.")
                }
                this.updateDCIndicator({
                    "report_code": this.report_code,
                    "type": "table",
                    "id": this.selected_radio_table_id,
                    "payload": this.dc_key_info,
                })
                // this.initial_radio_state = this.selected_radio_table_id
                this.closeEditChartModal();

                // 2024.01.31 여기까지 완료.

            },
            showTable(table_id){
                if (this.opened_table.table_id == table_id){
                    this.is_table_opened = !this.is_table_opened
                }
                else this.is_table_opened = true
                let idx = this.table_list.findIndex(x => x.table_id == table_id)
                if (idx > -1){
                    this.opened_table = {...this.table_list[idx]}
                }
                //데이터 테이블 모두 닫힌 상태면 opended table 초기화
                if (this.is_table_opened == false){
                    this.opened_table = {}
                }
            }
    
        },
        created(){
            this.initiateLocalTableSelect()
        },
       
    
    }
    </script>
    
    <style>
    
    </style>
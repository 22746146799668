<template>
<div>
    <h4>검증의견서 확인 </h4>
    <p class="f-95 mb-4 gray-600"> 제3자 검증의견서를 확인합니다. </p>

    <b-card class="general-card mb-5">
        <b-card-header>검증의견서 보기</b-card-header>
        <p class="pt-3 gray-600 mb-45"> 검증의견서는 GRI 2-5 지표에 자동 업로드 됩니다. </p>

        <b-button variant="teal" v-b-modal.assurance-report class="mb-3 px-3 mr-3"> 제3자 검증의견서 보기 <fa icon="expand" class="ml-1"/> </b-button>
        <b-button variant="teal" v-b-modal.assurance-report-download class="mb-3 px-3 "> PDF 다운로드 <fa icon="file-download" class="ml-1"/> </b-button>
    </b-card>

    <b-modal id="assurance-report" hide-footer title="제3자 검증의견서" size="xl" scrollable>
        <view-assurance-statement></view-assurance-statement>
    </b-modal>
      <b-modal id="assurance-report-download" hide-footer title="제3자 검증의견서" size="xl" scrollable>
        <b-button variant="dark-border" size="lg" class="mb-3 preview-tool-btn" v-print="'#assurance-preview'"> 
            출력하기 <fa icon="print"/>
        </b-button>
        <view-assurance-statement id="assurance-preview"></view-assurance-statement>
    </b-modal>


</div>
</template>

<script>
import ViewAssuranceStatement from '@/components/report_preview/appendix_subs/ViewAssuranceStatement.vue'
export default {
    data() {
        return {
            
        }
    },
    computed:{

    },
    methods:{

    },
    components:{
        ViewAssuranceStatement,
    }

}
</script>

<style>

</style>

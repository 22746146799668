<template>
<div>
    <div class="mb-5 border-bottom-gray-550 py-2 d-flex justify-content-between sticky-top bg-white">
        <h3 class="mb-0"> 서면검토 하기 </h3>
        <!-- <b-button-group>
            <b-button v-if="admin_assurance.request_status<=3" @click="makeRequestSatus(3)" :disabled="admin_assurance.request_status>=3" :variant="admin_assurance.request_status<=2?'red':''" class="f-110"> {{admin_assurance.request_status>=3?'서면검토 중': '검토 시작하기' }} </b-button>
            <b-button @click="makeRequestSatus(4)" :disabled="admin_assurance.request_status<3" :variant="admin_assurance.request_status<=2?'':'red'" class="f-110"> {{admin_assurance.request_status>=4?'검토 완료 취소': '검토 완료하기' }} </b-button>
        </b-button-group> -->
    </div>


    <!-- GRI 100 -->
    <!-- GRI 100 -->
    <!-- GRI 100 -->
    <h4 class="pt-3 mb-4 teal7">Universal Standards: <span class="fw-400"> GRI 100 </span></h4>
        <b-table-simple responsive class="disclosure-table border-bottom">
            <b-thead><b-tr>
                <b-td style="width: 70px;" class="pr-0">보고 지표</b-td><b-td style="min-width: 120px; width:15%"></b-td>
                <b-td class=""><div class="mb-1">보고 내용</div>
                    <div class="f-85"> <fa icon="table" class="mr-1"/> 데이터 테이블 <fa icon="file-alt" class="ml-3 mr-1"/> 문서 <fa icon="external-link-square-alt" class="ml-3 mr-1"/> 외부 링크 <fa icon="quote-left" class="ml-3 mr-1"/> 비즈니스 사례 </div>
                </b-td>
                <!-- <b-td style="width:40%"> 검증 의견 </b-td> -->
            </b-tr></b-thead>
            <b-tbody v-for="(category, index) in series_100" :key="category.id" class="mb-2 border-0">
                <template v-if="report_contents_loaded==true&&table_list_loaded==true&&business_case_loaded==true">
                    <b-tr><b-td colspan="4" class="teal7 border-top-teal7 border-bottom-teal7 py-1 pl-2 fw-500"> {{ index+1 }}. {{ category.name }} </b-td></b-tr>                
                    <b-tr v-for="item in series_contents(index)" :key="item.id" >
                        <b-td class=""><span class="f-105 fw-500">{{ item.gri_code }}</span></b-td>
                        <b-td class="pr-4 "> <p class=""> {{ item.indicator_name }}</p></b-td>
                        <b-td class="">
                            <div class="mb-3 pr-3">{{item.direct_report}}</div>

                            <!-- disclosure items Compact 버전 -->
                            <div class="" v-if="item.data_tables.length>0||item.documents.length>0||item.business_cases.length>0">
                                <span v-for="dt in item.data_tables" :key="dt.id" class="">
                                    <a class="mb-2 cursor mr-3" v-b-modal="`bc_${hashed_data_tables[dt].table_id}${item.gri_code}`"><fa icon="table" class="mr-1"/> {{ hashed_data_tables[dt].title }} </a>
                                    <b-modal :id="`bc_${hashed_data_tables[dt].table_id}${item.gri_code}`" hide-footer size="lg">
                                        <template #modal-title>
                                            <b-badge variant="basic" class="mr-2">Data Table.</b-badge> {{ hashed_data_tables[dt].title }}
                                        </template>
                                        <b-table-simple responsive class="bor-bg-table">
                                            <b-thead><b-tr>
                                                <b-td v-for="(property, col_index) in hashed_data_tables[dt].header" :key="col_index">{{ property }}</b-td>
                                            </b-tr></b-thead>
                                            <b-tbody><b-tr v-for="row_item in hashed_data_tables[dt].contents" :key="row_item.id">
                                                <b-td v-for="property in hashed_data_tables[dt].header" :key="property.id">{{ row_item[property] }}</b-td>
                                            </b-tr></b-tbody>
                                        </b-table-simple>
                                    </b-modal> 
                                </span>
                                <span v-for="x in item.documents" :key="x.id">
                                    <span v-if="hashed_docs[x].type!='link'" class="mr-3">
                                        <a class=" cursor" :href="$store.state.static_host+hashed_docs[x].path" target="_blank"><fa icon="file-alt" class="mr-1"/> {{ hashed_docs[x].title }} </a>  
                                    </span>                                                         
                                </span>
                                <span v-for="y in item.documents" :key="y.id">
                                    <span v-if="hashed_docs[y].type=='link'" class="mr-3">
                                        <a class=" cursor" :href="hashed_docs[y].link" target="_blank"><fa icon="external-link-square-alt" class="mr-1"/> {{ hashed_docs[y].title }} </a>  
                                    </span>
                                </span>
                                <span v-for="bc in item.business_cases" :key="bc.id">
                                    <a class="mb-2 cursor mr-3" v-b-modal="`bc_${hashed_business_cases[bc].business_case_id}${item.gri_code}`"><fa icon="quote-left" class="mr-1"/> {{ hashed_business_cases[bc].title }} </a>
                                    <b-modal :id="`bc_${hashed_business_cases[bc].business_case_id}${item.gri_code}`" hide-footer size="lg">
                                        <template #modal-title>
                                            <b-badge variant="basic" class="mr-2">Case.</b-badge> {{ hashed_business_cases[bc].title }}
                                        </template>
                                        <simple-business-case :business_case_id="hashed_business_cases[bc].business_case_id"></simple-business-case>
                                    </b-modal>
                                </span>
                            </div>
                        </b-td>
                        <!-- <b-td>
                            <b-overlay :show="admin_assurance.request_status<3">
                                <b-form-checkbox v-model="open_review[item.gri_code]" switch @click="handlePrint()"></b-form-checkbox>
                                <b-form-textarea :disabled="open_review[item.gri_code]==false" v-model="review_contents"></b-form-textarea>
                                <div class="text-right pt-2"><b-button @click="handleSave()" size="sm">저장하기</b-button></div>

                                <template #overlay>'검토 시작하기' 클릭 후 작성 가능 </template>
                            </b-overlay>
                        </b-td> -->
                    </b-tr>
                </template>
                <template v-else><b-tr><b-td><b-skeleton></b-skeleton></b-td><b-td><b-skeleton></b-skeleton></b-td><b-td><b-skeleton></b-skeleton></b-td><b-td><b-skeleton></b-skeleton></b-td></b-tr>
                </template>
            </b-tbody>
        </b-table-simple>

    <!-- GRI 200 ~ 400 -->
    <!-- GRI 200 ~ 400 -->
    <!-- GRI 200 ~ 400 -->
    <div v-for="part in structures" :key="part.id">
        <h4 class="pt-5 mb-4 teal7">Topic Standards Disclosures: <span class="fw-400"> GRI {{part.key_code}}00 - {{part.name_e}}</span></h4>

        <b-table-simple responsive class="disclosure-table border-bottom">
            <b-thead><b-tr>
                <b-td style="width: 70px;" class="pr-0">보고 지표</b-td><b-td style="min-width: 120px; width:15%"></b-td>
                <b-td class=""><div class="mb-1">보고 내용</div>
                    <div class="f-85"> <fa icon="table" class="mr-1"/> 데이터 테이블 <fa icon="file-alt" class="ml-3 mr-1"/> 문서 <fa icon="external-link-square-alt" class="ml-3 mr-1"/> 외부 링크 <fa icon="quote-left" class="ml-3 mr-1"/> 비즈니스 사례 </div>
                </b-td>
                <!-- <b-td style="width:40%"> 검증 의견 </b-td> -->
            </b-tr></b-thead>
            <template v-if="report_contents_loaded==true&&table_list_loaded==true&&business_case_loaded==true">
                <b-tbody v-for="topic in gri_topics(part.key_code)" :key="topic.id" class="mb-2 border-0">
                    <b-tr><b-td colspan="4" class="teal7 border-top-teal7 border-bottom-teal7 py-1 pl-2 fw-500"> GRI {{ topic.topic_id }} : {{ topic.topic_name }}</b-td></b-tr>                
                    <b-tr v-for="item in hashed_contents(part.key_code)[topic.topic_id]" :key="item.id" >
                        <b-td class=""><span class="f-100">{{ item.gri_code }}</span></b-td>
                        <b-td class="pr-4 "> <p class=""> {{ item.indicator_name }}</p></b-td>
                        <b-td class="">
                            <div :class="item.direct_report? 'mb-3':''">{{item.direct_report}}</div>
                            <!-- 연결된 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
                            <div class="" v-if="item.data_tables.length>0||item.documents.length>0||item.business_cases.length>0">
                                <span v-for="dt in item.data_tables" :key="dt.id" class="">
                                    <a class="mb-2 cursor mr-3" v-b-modal="`bc_${hashed_data_tables[dt].table_id}${item.gri_code}`"><fa icon="table" class="mr-1"/> {{ hashed_data_tables[dt].title }} </a>
                                    <b-modal :id="`bc_${hashed_data_tables[dt].table_id}${item.gri_code}`" hide-footer size="lg">
                                        <template #modal-title>
                                            <b-badge variant="basic" class="mr-2">Data Table.</b-badge> {{ hashed_data_tables[dt].title }}
                                        </template>
                                        <b-table-simple responsive class="bor-bg-table">
                                            <b-thead><b-tr>
                                                <b-td v-for="(property, col_index) in hashed_data_tables[dt].header" :key="col_index">{{ property }}</b-td>
                                            </b-tr></b-thead>
                                            <b-tbody><b-tr v-for="row_item in hashed_data_tables[dt].contents" :key="row_item.id">
                                                <b-td v-for="property in hashed_data_tables[dt].header" :key="property.id">{{ row_item[property] }}</b-td>
                                            </b-tr></b-tbody>
                                        </b-table-simple>
                                    </b-modal>                                
                                </span>
                                <span v-for="x in item.documents" :key="x.id">
                                    <span v-if="hashed_docs[x].type!='link'" class="mr-3">
                                        <a class=" cursor" :href="$store.state.static_host+hashed_docs[x].path" target="_blank"><fa icon="file-alt" class="mr-1"/> {{ hashed_docs[x].title }} </a>  
                                    </span>                                                         
                                </span>
                                <span v-for="y in item.documents" :key="y.id">
                                    <span v-if="hashed_docs[y].type=='link'" class="mr-3">
                                        <a class=" cursor" :href="hashed_docs[y].link" target="_blank"><fa icon="external-link-square-alt" class="mr-1"/> {{ hashed_docs[y].title }} </a>  
                                    </span>
                                </span>
                                <span v-for="bc in item.business_cases" :key="bc.id">
                                    <a class="mb-2 cursor mr-3" v-b-modal="`bc_${hashed_business_cases[bc].business_case_id}${item.gri_code}`"><fa icon="quote-left" class="mr-1"/>{{ hashed_business_cases[bc].title }} </a>
                                    <b-modal :id="`bc_${hashed_business_cases[bc].business_case_id}${item.gri_code}`" hide-footer size="lg">
                                        <template #modal-title>
                                            <b-badge variant="basic" class="mr-2">Case.</b-badge> {{ hashed_business_cases[bc].title }}
                                        </template>
                                        <simple-business-case :business_case_id="hashed_business_cases[bc].business_case_id"></simple-business-case>
                                    </b-modal>
                                </span>
                            </div>
                        </b-td>
                        <!-- <b-td>
                            <b-form-textarea></b-form-textarea>
                        </b-td> -->
                    </b-tr>
                </b-tbody>
            </template>
            <template v-else><b-tbody>
                <b-tr><b-td><b-skeleton></b-skeleton></b-td><b-td><b-skeleton></b-skeleton></b-td><b-td><b-skeleton></b-skeleton></b-td><b-td><b-skeleton></b-skeleton></b-td></b-tr>
            </b-tbody></template>
        </b-table-simple>
    </div>

</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import SimpleBusinessCase from '@/components/writing_report/business_case_subs/SimpleBusinessCase'
import * as ih from '@/components/util'

export default {
    data() {
        return {
            structures: [
                {
                    'key_code' : 2,
                    'name' : '경제',
                    'name_e' : 'Economic'
                },
                {
                    'key_code' : 3,
                    'name' : '환경',
                    'name_e' : 'Environmental'
                },
                {
                    'key_code' : 4,
                    'name' : '사회',
                    'name_e' : 'Social'
                },
            ],
            open_review: {},
            review_contents:'' // open_review 처럼 gri_code 기반 hash 형태로 생성해야 함
        }
    },    
    computed:{
        ...mapState('assurance', ['admin_assurance']),

        ...mapState('admin', ['report_code']),

        ...mapState('report_contents', ['series_100', 'report_contents_loaded']),
        ...mapGetters('admin', ['selected_report']),
        ...mapGetters('report_contents', ['gri_100_all_contents','gri_2_all_contents', 'gri_3_all_contents', 'gri_200_all_contents','gri_300_all_contents','gri_400_all_contents',
        'hashed_gri_200','hashed_gri_300','hashed_gri_400']),       
        ...mapState('data_tables', ['table_list_loaded']),
        ...mapGetters('data_tables', ['hashed_data_tables']),
        ...mapGetters('documents', ['hashed_docs']),
        ...mapState('business_cases', ['business_case_loaded']),
        ...mapGetters('business_cases', ['hashed_business_cases']),
        gri_topics(){
            return(num) =>{                
                let topic_name_arr = []
                if(num==2){
                    let topics = []
                    for(let i=0; i<this.gri_200_all_contents.length; i++){
                        let new_topics = {
                            topic_id: this.gri_200_all_contents[i].topic_id, 
                            topic_name: this.gri_200_all_contents[i].topic_name,
                            topic_name_e: this.gri_200_all_contents[i].topic_name_e
                        }
                        let idx = topics.findIndex(x => x.topic_id == new_topics.topic_id)
                        if (idx == -1){
                            topics.push(new_topics)
                        }
                    }
                    return topics
                }
                else if(num==3){
                    let topics = []
                    for(let i=0; i<this.gri_300_all_contents.length; i++){
                        let new_topics = {
                            topic_id: this.gri_300_all_contents[i].topic_id, 
                            topic_name: this.gri_300_all_contents[i].topic_name,
                            topic_name_e: this.gri_300_all_contents[i].topic_name_e
                        }
                        let idx = topics.findIndex(x => x.topic_id == new_topics.topic_id)
                        if (idx == -1){
                            topics.push(new_topics)
                        }
                    }
                    return topics
                }
                else if(num==4){
                    let topics = []
                    for(let i=0; i<this.gri_400_all_contents.length; i++){
                        let new_topics = {
                            topic_id: this.gri_400_all_contents[i].topic_id, 
                            topic_name: this.gri_400_all_contents[i].topic_name,
                            topic_name_e: this.gri_400_all_contents[i].topic_name_e
                        }
                        let idx = topics.findIndex(x => x.topic_id == new_topics.topic_id)
                        if (idx == -1){
                            topics.push(new_topics)
                        }
                    }
                    return topics
                }
            }            
        },
        hashed_contents(){
            return(num) =>{
                if(num==2){
                    return this.hashed_gri_200
                }
                else if(num==3){
                    return this.hashed_gri_300
                }
                else if(num==4){
                    return this.hashed_gri_400
                }
            }            
        },
        series_contents(){
            return(index)=>{
                let temp_list = []
                for (let i=0; i<this.series_100[index].codes.length; i++){
                    let idx = this.gri_100_all_contents.findIndex(x => x.gri_code == this.series_100[index].codes[i])
                    if (idx > -1) {                      
                        temp_list.push(this.gri_100_all_contents[idx])
                    }
                }
                return temp_list
            }
        },
    },
    methods:{
        ...mapActions('assurance',['saveAssuranceRequest']),
        initiateOpenReview(){
            let temp100s = ih.deepCopy(this.gri_100_all_contents)
            let temp_obj = {}
            for (let i=0; i<temp100s.length; i++){
                temp_obj[temp100s[i].gri_code] = false
            }
            this.open_review = temp_obj
            // console.log(temp100s)
            // console.log(this.open_review);
        },
        handleSave(){
            // console.log('goodJob!')
        },
        makeRequestSatus(state_num){            
            if(this.admin_assurance.request_status>=2){
                let request_status = 3
                if(state_num==3){
                    if (confirm('보고서 소유자(Owner)에게 서면검토를 시작했다는 이메일을 보내고, assurance status를 3으로 바꿉니다. 시작하시겠습니까?') == true) {
                        this.saveAssuranceRequest({
                            report_code : this.report_code,
                            request_status: request_status
                        })
                    }
                }
                if(state_num==4){
                    if (this.admin_assurance.request_status>=4){
                        if (confirm('보고서 Owner에게 서면검토에 대해 다시 피드백을 작성한다는 이메일을 보내고, assurance status를 3으로 바꿉니다. 진행하시겠습니까?') == true) {
                            this.saveAssuranceRequest({
                                report_code : this.report_code,
                                request_status: request_status
                            })
                        }
                    }
                    else {
                        request_status = 4
                        if (confirm('보고서 Owner에게 서면검토가 완료되었다는 이메일을 보내고, assurance status를 4로 바꿉니다. 완료하시겠습니까?') == true) {
                            this.saveAssuranceRequest({
                                report_code : this.report_code,
                                request_status: request_status
                            })
                        }
                    }                    
                }               
            }else alert('본 보고서는 검증 신청이 아직 완료되지 않아 서면검토를 시작할 수 없습니다.')            
        }
    },
    created(){
        this.initiateOpenReview()
    },
    mounted(){
        // console.log(this.admin_assurance)
        // console.log(this.gri_topics(2));
    },
    components:{
        SimpleBusinessCase
    },
    beforeDestroy(){
    }
}
</script>

<style>
.disclosure-item-simple > .card-body{
    padding: 0.5rem 1rem
}
.disclosure-item-simple > .card-header {
    padding: 0.25rem 1rem
}
</style>
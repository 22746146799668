import { parse } from '@fortawesome/fontawesome-svg-core'
import Vue from 'vue';
// import axios from 'axios'

//"선택"한 보고서에 대한 내용을 읽어오기
//리포트 콘텐츠(Disclosures)가 변경되는 경우 여기를 업데이트 해야 함

const report_contents = {
    namespaced: true, 
    state: {
        report_contents: [{
            // "index": 0,
            // "report_code": "r11",
            // "gri_code": "404-1",
            // "type": "Economic",
            // "topic_id": "404",
            // "topic_name": "Economyyy",
            // "topic_name_e": "",
            // "topic_description": "",
            // "indicator_name": "ikhyun_indicator2",
            // "indicator_name_e": "ikhyun_indicator_ee",
            // "indicator_description": "what a good system",
            // "indicator_descirption_e": "what aa good system ee",
            // "indicator_requirement": "",
            // "indicator_requirement_e": "",
            // "indicator_recommends": "waht aaa good system",
            // "indicator_recommends_e": "what aaaa gooood system eee",
            // "indicator_guidance": "what aaaaa goooooood system",
            // "indicator_guidance_e": "what aaaaaa gooooooooood system eeeeee",
            // "disclosure_id": 2,
            // "direct_report": "I made a save ",
            // "direct_report_ea": "",
            // "direct_report_em" : "",
            // "time_stamp": "2020-08-13 17:02:14",
            // "data_tables": [0, 1, 2], //table_id
            // "documents": [0, 1, 2], //document_id
            // "business_cases": [0,1, 2], //business_case_id
            }
        ],
        series_100:[
            {
                'name' :'조직 프로필',
                'name_e' :'Organizational profile',
                'codes' : ['102-1','102-2','102-3','102-4','102-5','102-6','102-7','102-8','102-9','102-10','102-11','102-12','102-13'] 
            },
            {
                'name' :'전략',
                'name_e' : 'Strategy',
                'codes' : ['102-14','102-15'] 
            },
            {
                'name' :'윤리 및 청렴성',
                'name_e' : 'Ethics and integrity',
                'codes' : ['102-16','102-17'] 
            },
            {
                'name' :'지배구조',
                'name_e' : 'Governance',
                'codes' : ['102-18','102-19','102-20','102-21','102-22','102-23','102-24','102-25','102-26','102-27','102-28','102-29','102-30','102-31','102-32','102-33','102-34','102-35','102-36','102-37','102-38','102-39'] 
            },
            {
                'name' :'이해관계자 참여',
                'name_e' : 'Stakeholder engagement',
                'codes' : ['102-40','102-41','102-42','102-43','102-44'] 
            },
            {
                'name' :'보고 관행',
                'name_e' : 'Reporting practice',
                'codes' : ['102-45','102-46','102-47','102-48','102-49','102-50','102-51','102-52','102-53','102-54','102-55','102-56'] 
            },
        ],
        report_contents_loaded: false,
        is_report_translated: false,
        attach_loaded: false,

        sasb_contents: [],
        tcfd_contents: []
    },
    mutations: {
        update_sasb_contents(state, new_sasb_contents){
            state.sasb_contents = new_sasb_contents
        },
        update_tcfd_contents(state, new_tcfd_contents){
            state.tcfd_contents = new_tcfd_contents
        },
        update_report_contents(state, new_report_contents){
            state.report_contents = new_report_contents
        },
        update_disclosure(state, new_disclosure_obj){
            //1. new_disclosures의 gri_code와 report_code를 기준으로 업데이트 대상을 찾음. 
            let temp_report_contents = [ ...state.report_contents ]
            let idx = temp_report_contents.findIndex(item => item.report_code == new_disclosure_obj.report_code && item.gri_code == new_disclosure_obj.gri_code)
            if(idx > -1){
                temp_report_contents[idx].disclosure_id = new_disclosure_obj.disclosure_id
                temp_report_contents[idx].direct_report = new_disclosure_obj.direct_report
                temp_report_contents[idx].time_stamp = new_disclosure_obj.time_stamp
            }
            //2. state변경 인식시킴
            state.report_contents = temp_report_contents
        },
        update_disclosure_nan(state, new_disclosure_obj){
            //1. new_disclosures의 gri_code와 report_code를 기준으로 업데이트 대상을 찾음. 
            let temp_report_contents = [ ...state.report_contents ]
            let idx = temp_report_contents.findIndex(item => item.report_code == new_disclosure_obj.report_code && item.gri_code == new_disclosure_obj.gri_code)
            if(idx > -1){
                temp_report_contents[idx].nan_category = new_disclosure_obj.nan_category
                temp_report_contents[idx].nan_reason_contents = new_disclosure_obj.nan_reason_contents
            }
            //2. state변경 인식시킴
            state.report_contents = temp_report_contents
        },
        add_or_update_disclosure(state, new_disclosure_obj){
            let temp_report_contents = [ ...state.report_contents ]
            let idx = temp_report_contents.findIndex(item => item.report_code == new_disclosure_obj.report_code && item.gri_code == new_disclosure_obj.gri_code)
            if(idx > -1){
                temp_report_contents[idx].disclosure_id = new_disclosure_obj.disclosure_id
                temp_report_contents[idx].direct_report_em = new_disclosure_obj.direct_report_em
                temp_report_contents[idx].time_stamp_e = new_disclosure_obj.time_stamp_e
            }
            state.report_contents = temp_report_contents
        },
        add_or_update_nan_disclosure(state, new_disclosure_obj) {
            let temp_report_contents = [ ...state.report_contents ]
            let idx = temp_report_contents.findIndex(item => item.report_code == new_disclosure_obj.report_code && item.gri_code == new_disclosure_obj.gri_code)
            if(idx > -1){
                temp_report_contents[idx].disclosure_id = new_disclosure_obj.disclosure_id
                temp_report_contents[idx].nan_category = new_disclosure_obj.nan_category
                temp_report_contents[idx].nan_reason_contents = new_disclosure_obj.nan_reason_contents
            }
            state.report_contents = temp_report_contents
        },
        update_direct_report_ea(state, new_obj){
            let temp_report_contents = [ ...state.report_contents ]
            let temp_obj = {}
            let idx = temp_report_contents.findIndex(item => item.report_code == new_obj.report_code && item.gri_code == new_obj.gri_code)
            if(idx>-1){
                temp_obj = {...temp_report_contents[idx]}
                temp_report_contents[idx].direct_report_ea = new_obj.direct_report_ea
                temp_report_contents[idx].direct_report_em = new_obj.direct_report_em
            }  
            // console.log(temp_report_contents[idx]);          
        },
        update_direct_report_nan_e(state, new_obj) {
            let temp_report_contents = [ ...state.report_contents ]
            let temp_obj = {}
            let idx = temp_report_contents.findIndex(item => item.report_code == new_obj.report_code && item.gri_code == new_obj.gri_code)
            if(idx>-1){
                temp_obj = {...temp_report_contents[idx]}
                temp_report_contents[idx].nan_reason_contents_ea = new_obj.nan_reason_contents_ea
                temp_report_contents[idx].nan_reason_contents_em = new_obj.nan_reason_contents_em
            }  
        },
        update_chart_relation(state, chart_id_by_gri_code){
            let temp_report_contents = [ ...state.report_contents ]
            temp_report_contents.forEach( disclosure => {
                if(chart_id_by_gri_code.hasOwnProperty(disclosure.gri_code)){
                    disclosure.charts = chart_id_by_gri_code[disclosure.gri_code]
                }
            })
            state.report_contents = temp_report_contents
        },
        // disclosure item 관계 업데이트
        update_data_table_relation(state, table_ids_by_gri_code){
            let temp_report_contents = [ ...state.report_contents ]
            temp_report_contents.forEach( disclosure => {
                if(table_ids_by_gri_code.hasOwnProperty(disclosure.gri_code)){
                    disclosure.data_tables = table_ids_by_gri_code[disclosure.gri_code]
                }
            })
            state.report_contents = temp_report_contents
        },
        update_document_relation(state, document_ids_by_gri_code){
            let temp_report_contents = [ ...state.report_contents ]
            temp_report_contents.forEach( disclosure => {
                if(document_ids_by_gri_code.hasOwnProperty(disclosure.gri_code)){
                    disclosure.documents = document_ids_by_gri_code[disclosure.gri_code]
                }
            })
            state.report_contents = temp_report_contents
        },
        update_business_case_relation(state, business_case_ids_by_gri_code){
            let temp_report_contents = [ ...state.report_contents ]
            temp_report_contents.forEach( disclosure => {
                if(business_case_ids_by_gri_code.hasOwnProperty(disclosure.gri_code)){
                    disclosure.business_cases = business_case_ids_by_gri_code[disclosure.gri_code]
                }
            })
            state.report_contents = temp_report_contents
        },
        // disclosure item 삭제 업데이트
        update_delete_data_table(state, deleted_table_id){
            let temp_report_contents = [ ...state.report_contents ]
            temp_report_contents.forEach( disclosure => {
                let idx = disclosure.data_tables.indexOf(deleted_table_id)
                if (idx >-1){
                    disclosure.data_tables.splice(idx, 1)
                }
            })
            state.report_contents = temp_report_contents
        },

        update_delete_chart(state, chart_id) {
            let temp_report_contents = [ ...state.report_contents ]
            temp_report_contents.forEach( disclosure => {
                let idx = disclosure.charts.indexOf(chart_id)
                if (idx >-1){
                    disclosure.charts.splice(idx, 1)
                }
            })
            state.report_contents = temp_report_contents
        },
        update_delete_business_case(state, deleted_business_case_id){
            let temp_report_contents = [ ...state.report_contents ]
            temp_report_contents.forEach( disclosure => {
                let idx = disclosure.business_cases.indexOf(deleted_business_case_id)
                if (idx >-1){
                    disclosure.business_cases.splice(idx, 1)
                }
            })
            state.report_contents = temp_report_contents
        },
        update_delete_document(state, deleted_document_id){
            let temp_report_contents = [ ...state.report_contents ]
            temp_report_contents.forEach( disclosure => {
                let idx = disclosure.documents.indexOf(deleted_document_id)
                if (idx >-1){
                    disclosure.documents.splice(idx, 1)
                }
            })
            state.report_contents = temp_report_contents
        },
        update_report_contents_loaded(state, flag){
            // console.log(flag);
            state.report_contents_loaded = flag
        },
        update_is_report_translated(state, flag){
            state.is_report_translated = flag
        },
        attach_tables_and_documents(state, { disclosure_id, data_tables, documents, business_cases  }){
            let temp_report_contents = [...state.report_contents]

            let idx = temp_report_contents.findIndex(item => item.disclosure_id == disclosure_id)
            if (idx > -1){
                temp_report_contents[idx].data_tables = data_tables
                temp_report_contents[idx].documents = documents
                temp_report_contents[idx].business_cases = business_cases
            }
            // console.log(temp_report_contents[idx].business_cases)
            // console.log(temp_report_contents)
            state.report_contents = temp_report_contents
        },
        update_attach_loaded(state, flag){
            state.attach_loaded = flag
        }
    },
    getters: {
        report_categories(state){
            //[{ category_id, category_name, category_name_e }]
            let unique_categories = []
            state.report_contents.forEach( item => {
                if ( unique_categories.findIndex( x => { return x.category_id == item.category_id }) < 0 ) {
                    unique_categories.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e})
                }
            })
            return unique_categories
        },
        report_topics(state){
            //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let unique_topics = []
            state.report_contents.forEach( item => {
                if ( unique_topics.findIndex( x=> { return x.topic_id == item.topic_id }) < 0) {
                    unique_topics.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e, 
                        topic_id: item.topic_id, topic_name: item.topic_name, topic_name_e: item.topic_name_e })
                }
            })
            return unique_topics
        },
        report_subtopics(state){
            //[{ category_id, category_name, category_name_e, subtopic_code, subtopic_name, subtopic_name_e }]
            let unique_subtopics = []
            state.report_contents.forEach( item => {
                if ( unique_subtopics.findIndex( x=> { return x.subtopic_code == item.subtopic_code }) < 0 ){
                    unique_subtopics.push({
                        category_id: item.category_id,
                        category_name: item.category_name,
                        category_name_e: item.category_name_e, 
                        subtopic_code: item.subtopic_code,
                        subtopic_name: item.subtopic_name,
                        subtopic_name_e: item.subtopic_name_e,
                        topic_publication_date: item.topic_publication_date
                    })
                }
            })
        },
        gri_100_all_contents(state){
            let temp_100 = state.report_contents.filter( x => x.topic_id >= 100 && x.topic_id<=199)
            temp_100.sort((a, b)=> parseFloat(a.topic_id) - parseFloat(b.topic_id))
            for (let i=0; i<temp_100.length; i++){
                let temp_content = {...temp_100[i]}
                if(temp_100[i].direct_report_em!=''&&temp_100[i].direct_report_em!=null){
                    temp_content['direct_report_e'] = temp_100[i].direct_report_em
                }else temp_content['direct_report_e'] = temp_100[i].direct_report_ea

                temp_100[i] = temp_content
            }
            return temp_100
        },
        gri_2_all_contents(state){
            let temp_2 = state.report_contents.filter( x => x.topic_id == 2)
            return temp_2
        },
        gri_3_all_contents(state){
            let temp_3 = state.report_contents.filter( x => x.topic_id == 3)
            return temp_3
        },
        gri_200_all_contents(state){
            let temp_200 = state.report_contents.filter( x => x.topic_id >= 200 && x.topic_id<=299)
            return temp_200.sort((a, b)=> parseFloat(a.topic_id) - parseFloat(b.topic_id))
        },
        gri_300_all_contents(state){
            let temp_300 = state.report_contents.filter( x => x.topic_id >= 300 && x.topic_id<=399)
            return temp_300.sort((a, b)=> parseFloat(a.topic_id) - parseFloat(b.topic_id))
        },
        gri_400_all_contents(state){
            let temp_400 = state.report_contents.filter( x => x.topic_id >= 400 && x.topic_id<=499)
            return temp_400.sort((a, b)=> parseFloat(a.topic_id) - parseFloat(b.topic_id))
        },
        hashed_gri_200(state){
            let temp_200_contents = [...state.report_contents.filter( x => x.topic_id >= 200 && x.topic_id<=299)]
            let temp_obj = {}
            for (let i=0; i<temp_200_contents.length; i++){
                let topic_id = temp_200_contents[i].topic_id
                let temp_list = temp_200_contents.filter(x => x.topic_id == topic_id)     
                temp_list.forEach(item => {
                    if(item.direct_report_em!=''&&item.direct_report_em!=null){
                        item['direct_report_e'] = item.direct_report_em
                    }else item['direct_report_e'] = item.direct_report_ea
                })
                
                temp_obj[topic_id] = temp_list
            }
            return temp_obj
        },
        hashed_gri_300(state){
            let temp_300_contents = [...state.report_contents.filter( x => x.topic_id >= 300 && x.topic_id<=399)]
            let temp_obj = {}
            for (let i=0; i<temp_300_contents.length; i++){
                let topic_id = temp_300_contents[i].topic_id
                let temp_list = temp_300_contents.filter(x => x.topic_id == topic_id) 
                temp_list.forEach(item => {
                    if(item.direct_report_em!=''&&item.direct_report_em!=null){
                        item['direct_report_e'] = item.direct_report_em
                    }else item['direct_report_e'] = item.direct_report_ea
                })

                temp_obj[topic_id] = temp_list
            }
            return temp_obj
        },
        hashed_gri_400(state){
            let temp_400_contents = [...state.report_contents.filter( x => x.topic_id >= 400 && x.topic_id<=499)]
            let temp_obj = {}
            for (let i=0; i<temp_400_contents.length; i++){
                let topic_id = temp_400_contents[i].topic_id
                let temp_list = temp_400_contents.filter(x => x.topic_id == topic_id)
                temp_list.forEach(item => {
                    if(item.direct_report_em!=''&&item.direct_report_em!=null){
                        item['direct_report_e'] = item.direct_report_em
                    }else item['direct_report_e'] = item.direct_report_ea
                })

                temp_obj[topic_id] = temp_list
            }
            return temp_obj
        },
        writing_progress(state){
            return 0
            // let written_contents = []
            // let temp_contents = [...state.report_contents]
            // if(temp_contents.length>1){
            //     written_contents = temp_contents.filter(a => 
            //         (a.direct_report!=''&&a.direct_report!=null&&a.direct_report!=undefined) ||
            //         (a.data_tables.length > 0 || a.documents.length > 0 || a.business_cases.length > 0)
            //     )
            //     return written_contents.length / temp_contents.length *100
            // }else return 0
        },
    },
    actions: {
        readReportContents({ commit, dispatch, rootState}, { report_code, editor_mode } ){
            const path = rootState.backend_host + '/read_report_contents'

            return axios.get(path, { params: {
                report_code: report_code, editor_mode: editor_mode
            }})
                .then(response => {
                    if (response.data?.code === false) {
                        window.alert("잘못된 접근입니다.")
                        window.history.go(-1);
                        return;
                    } else {
                        let new_report_contents = response.data
        
        
                        //각 disclosures 돌면서 data_table, documents, business_cases를 id로 바꿔줌
                        // new_report_contents.forEach(disclosure=>{
                        //      disclosure.data_tables = disclosure.data_tables.map(table => table.table_id)
                        //      disclosure.documents = disclosure.documents.map(document => document.document_id)
                        //      disclosure.business_cases = disclosure.business_cases.map(business_case => business_case.business_case_id)
                        // })
                        commit('update_report_contents', new_report_contents)
                        commit('update_report_contents_loaded', true)
        
                        dispatch('newAttachTablesAndDocuments', { report_code: report_code })
                        
                    }
            })
            .catch(error => {
                console.log(error);
            })
        },
        newAttachTablesAndDocuments({commit, state, rootState}, {report_code}){
            const path = rootState.backend_host + '/new_attach_tables_and_documents'

            return axios.get(path, { params: {
                report_code : report_code
            }})
            .then(
                response => {
                    //repsponse.data는 [ { report_code, gri_code, attachment_id, type } ] 으로 이뤄진 배열임
                    //프론트엔드에서 후처리를 통해 각 disclosure별로 id를 붙이는 작업을 해야함

                    // console.log(response)

                    //1) data_table을 해시형태로 정리 table_ids_by_gri_code = { gri_code: [ data_table_ids ] }
                    let data_tables = response.data.filter( x => x.type == 'data_table')
                    let table_ids_by_gri_code = {}
                    data_tables.forEach( item => {
                        if( table_ids_by_gri_code.hasOwnProperty(item.gri_code) ){
                            table_ids_by_gri_code[item.gri_code].push(item.attachment_id)
                        }
                        else{
                            table_ids_by_gri_code[item.gri_code] = []
                            table_ids_by_gri_code[item.gri_code].push(item.attachment_id)
                        }
                    })

                    let documents = response.data.filter( x => x.type == 'document')
                    let document_ids_by_gri_code = {}
                    documents.forEach( item => {
                        if( document_ids_by_gri_code.hasOwnProperty(item.gri_code) ){
                            document_ids_by_gri_code[item.gri_code].push(item.attachment_id)
                        }
                        else{
                            document_ids_by_gri_code[item.gri_code] = []
                            document_ids_by_gri_code[item.gri_code].push(item.attachment_id)
                        }
                    })

                    let business_cases = response.data.filter( x => x.type == 'business_case')
                    let business_case_ids_by_gri_code = {}
                    business_cases.forEach( item => {
                        if( business_case_ids_by_gri_code.hasOwnProperty(item.gri_code) ){
                            business_case_ids_by_gri_code[item.gri_code].push(item.attachment_id)
                        }
                        else{
                            business_case_ids_by_gri_code[item.gri_code] = []
                            business_case_ids_by_gri_code[item.gri_code].push(item.attachment_id)
                        }
                    })

                    commit('update_data_table_relation', table_ids_by_gri_code)
                    commit('update_document_relation', document_ids_by_gri_code)
                    commit('update_business_case_relation', business_case_ids_by_gri_code)

                    //완료 되었다고 알림
                    commit('update_attach_loaded', true)
                }
            )

        },
        attachTablesAndDocuments({commit, state, rootState }, { report_code }){            
            const path = rootState.backend_host + '/attach_tables_and_documents'

            const disclosure_ids = state.report_contents.map( disclosure => disclosure.disclosure_id )
            let promises = []

            disclosure_ids.forEach(function (disclosure_id) {
                promises.push(
                    axios.get(path, { params: {disclosure_id: disclosure_id}})
                    .catch( error => { console.log(error)})
                )}                
            )
            return Promise.all(promises)
            .then( results => {
                results.forEach(
                    response => {
                        if (response != undefined){
                            let data_tables = response.data.data_tables
                            let documents = response.data.documents
                            let business_cases = response.data.business_cases 
                            commit('attach_tables_and_documents', { disclosure_id: response.config.params.disclosure_id, data_tables: data_tables, documents: documents, business_cases: business_cases })
                        }
                })    
            })
            .finally(()=>{
                commit('update_attach_loaded', true)
            })
        },
        saveDisclosure({commit, rootState}, { report_code, gri_code, direct_report, type='GRI'}){
            const path = rootState.backend_host + '/save_disclosure'

            let formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('gri_code', gri_code)
            formData.append('direct_report', direct_report)
            formData.append('type', type)

            return axios.post(path, formData )
                .then(response => {
                let new_disclosure_obj = {
                    disclosure_id: response.data.disclosure_id,
                    report_code: response.data.report_code, 
                    gri_code: response.data.gri_code, 
                    direct_report: response.data.direct_report,
                    time_stamp: response.data.time_stamp
                }
                // console.log(new_disclosure_obj);
                commit('update_disclosure', new_disclosure_obj )
            })
            .catch(error => {
                if (error.response.status == 401) { 
                    alert(`다중 로그인이 감지되어 로그아웃 합니다. 다시 로그인해 주십시오.`)
                    dispatch('logout', null, { root: true }).then(() => window.location.href=rootState.frontend_host)
                }
                else {  alert(`저장 오류가 발생하였습니다. 작성하실 보고서를 다시 선택해 주세요`) 
                window.location.href=rootState.frontend_host }
            })
        },
        async saveNaNDisclosure({commit, rootState}, { report_code, gri_code, nan_category, nan_reason_contents }){
            try {
                const path = rootState.backend_host + '/update_disclosure_nan'

                const response = await axios.post(
                    path, {
                    "rc": report_code,
                    "gri_code": gri_code,
                    "nan_category": nan_category,
                    "nan_reason_contents": nan_reason_contents
                })
                    
                if (response.data.code) {
                    let new_disclosure_obj = {
                        disclosure_id: response.data.disclosure_id,
                        report_code: response.data.report_code, 
                        gri_code: response.data.gri_code, 
                        nan_category: response.data.nan_category,
                        nan_reason_contents: response.data.nan_reason_contents
                    }
                    // console.log(new_disclosure_obj);
                    commit('update_disclosure_nan', new_disclosure_obj)
                    
                }
                return response;

            } catch (error) {
                if (error.response.status == 401) { 
                    alert(`다중 로그인이 감지되어 로그아웃 합니다. 다시 로그인해 주십시오.`)
                    dispatch('logout', null, { root: true }).then(() => window.location.href=rootState.frontend_host)
                }
                else {  alert(`저장 오류가 발생하였습니다. 작성하실 보고서를 다시 선택해 주세요`) 
                window.location.href=rootState.frontend_host }
                    
            }
        },

        async saveTranslatedNaNDisclosure({commit, rootState}, { report_code, disclosure_id, nan_reason_contents_em, re_initiate  }){
            try {
                const response = await axios.post(rootState.backend_host + "/save_omission_em", {
                    disclosure_id: disclosure_id,
                    nan_reason_contents_em: nan_reason_contents_em,
                    re_initiate: re_initiate
                })
                    
                if (response.data.code) {
                    let new_disclosure_obj = {
                        report_code: report_code,
                        disclosure_id: disclosure_id,
                        nan_reason_contents_em: nan_reason_contents_em
                    }
                    // console.log(new_disclosure_obj);
                    commit('update_direct_report_nan_e', new_disclosure_obj)
                    
                }
                return response;

            } catch (error) {
                if (error.response.status == 401) { 
                    alert(`다중 로그인이 감지되어 로그아웃 합니다. 다시 로그인해 주십시오.`)
                    dispatch('logout', null, { root: true }).then(() => window.location.href=rootState.frontend_host)
                }
                else {  alert(`저장 오류가 발생하였습니다. 작성하실 보고서를 다시 선택해 주세요`) 
                window.location.href=rootState.frontend_host }
                    
            }
        },
        translateReport({commit, rootState}, { report_code, specific_indicator='' }) {
            console.log('translate')
            const path = rootState.backend_host + '/translate_report'
            let formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('specific_indicator', specific_indicator)

            return axios.post(path, formData )
            .then( response => {
                // console.log(response);
                Vue.prototype.$EventBus.$emit('make-toast', '번역 완료', '보고서 전체 번역이 완료되었습니다.')
                
                // this.$EventBus의 경우 Vue컴포넌트 간에 통신을 할 때 사용하는 것
                // 따라서 .js에서 직접적으로 this를 사용할 수 없으므로 Vue인스턴스를 통해 전달받아야 한다.
                // this.$EventBus.$emit('make-toast', '번역 완료', '보고서 전체 번역이 완료되었습니다.')
                // this.$emit('make-toast', '번역 완료', '보고서 전체 번역이 완료되었습니다.')
            })
            .catch(error => {
                console.log(error);
                alert(`번역 오류가 발생하였습니다. 다시 시도해 주세요.`) 
                window.location.href=rootState.frontend_host
            })
        },

        saveDisclosureE({commit, rootState}, { report_code, gri_code, direct_report_em, type="GRI"}){ // 개별 gri_code 별 direct report em 직접저장
            const path = rootState.backend_host + '/save_disclosure_em'

            let formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('gri_code', gri_code)
            formData.append('direct_report_em', direct_report_em)
            formData.append('type', type)
            return axios.post(path, formData )
            .then( response => {
                let new_disclosure_obj = {
                    disclosure_id: response.data.disclosure_id,
                    report_code: response.data.report_code, 
                    gri_code: response.data.gri_code, 
                    direct_report_ea: response.data.direct_report_ea,
                    direct_report_em: response.data.direct_report_em,
                    ime_stamp: response.data.time_stamp,
                    time_stamp_e: response.data.time_stamp_e
                }
                // console.log(new_disclosure_obj);
                commit('add_or_update_nan_disclosure', new_disclosure_obj )
            })
        },
        checkReportTranslated({ commit, rootState},  report_code ){
            const path = rootState.backend_host + '/check_report_translated'

            return axios.get(path, { params: {
                report_code: report_code
            }})
            .then( response => {
                // console.log(response.data);
                commit('update_is_report_translated', response.data.is_report_translated)
            })

        },
        reInitiateDisclosure({commit, rootState}, { disclosure_id, re_initiate, type="GRI" }){
            const path = rootState.backend_host + '/translate_disclosure'

            let formData = new FormData()
            formData.append('disclosure_id', disclosure_id)
            formData.append('type', type)
            if(re_initiate != null && re_initiate != undefined) formData.append('re_initiate', re_initiate ) //re_initiate 를 true로 보내면, direct_report_em을 삭제함
            return axios.post(path, formData )
            .then( response => {
                // console.log(response.data);
                commit('update_direct_report_ea', response.data)
            })
        },
        async reInitiateNaNDisclosure({commit, rootState}, { disclosure_id, nan_reason_contents_em, re_initiate }){
            const response = await axios.post(rootState.backend_host + "/translate_omission_disclosure", {
                disclosure_id: disclosure_id,
                nan_reason_contents_em: nan_reason_contents_em,
                re_initiate: re_initiate
            })

            commit('update_direct_report_nan_e', response.data)
        },

        async fetchSelectedTcfd({commit, rootState}, report_code) {
            const response = await axios.get(
                `${rootState.backend_host}/tcfd/read_tcfd_all_indicators_list_sort_by_category_for_viewer?rc=${report_code}`
            );

            commit('update_tcfd_contents', response.data.result)
        },

        async fetchSelectedSasb({commit, rootState}, report_code) {
            console.log('fetch selected sasb')
            try {
                const response = await axios.get(
                    `${rootState.backend_host}/sasb/get_indicator_disclosures_list_for_viewer?rc=${report_code}`
                );

                if (response.data.code) {
                    // this.sasb_contents = response.data.result;
                    commit('update_sasb_contents', JSON.parse(response.data.result, (key, value) => {
                        // 2024-06-12: 백(파이썬)에서는 dictonary의 key 순서를 보장하나, js에서 그게 안돼서 다음과 같이 수정함
                        if (typeof value === 'object' && value !== null) {
                            if (!Array.isArray(value)) {
                                // 객체가 배열이 아닌 경우 원래 순서를 유지
                                return Object.keys(value).reduce((acc, k) => {
                                    acc[k] = value[k];
                                    return acc;
                                }, {});
                            } else {
                                // 객체가 배열인 경우 각 요소를 처리
                                return value.map(item => {
                                    if (typeof item === 'object' && item !== null && !Array.isArray(item)) {
                                        return Object.keys(item).reduce((acc, k) => {
                                            acc[k] = item[k];
                                            return acc;
                                        }, {});
                                    }
                                    return item;
                                });
                            }
                        }
                        return value;
                    }))
                }

            } catch (error) {
                window.alert(error)
            }
        },






    }
}

export default report_contents
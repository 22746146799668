<template>
<div>
    <b-card class="general-card mb-5">
        <b-card-header>검증 신청하기</b-card-header>
        <div class="py-3">
            <p v-if="request_status>=2"> 검증이 신청되었습니다. 신청하신 정보를 확인하기 위해 담당자님께 별도로 연락을 드리겠습니다. </p>
            <p v-else-if="request_status==0&&contracted==true" class="">전자계약서 서명이 완료되었습니다. 전체 검증 일정 조정을 위해 다음의 대면 인터뷰 관련 정보를 입력 후 검증을 신청해 주세요. 검증을 신청하사면 서면검증이 시작됩니다. </p>
            <p v-else class="">전자계약 체결 후 검증을 신청할 수 있습니다. <router-link :to="{name: 'PaymentList'}" class="fw-400">결제관리</router-link> 에서 비용 및 예상 소요일 확인 후 전자계약서에 서명하여 주십시오. </p>

            <b-row no-gutters class="hover-gray py-2">
                <b-col cols="3"><div class="gray-600">대면 인터뷰 희망 일정</div> </b-col>  
                <b-col>
                    <template v-if="is_editing==true">
                        <p class="fw-400">대면 인터뷰 희망 일정을 선택해 주세요.</p>
                        <b-form-datepicker class="mb-2" :disabled="contracted!=true" v-model="onsite_date"></b-form-datepicker>
                    </template>
                    <template v-else>
                        <p v-if="onsite_date!=null" class="fw-400"> {{ onsite_date }}  ({{ onsite_get_day }})</p>
                    </template>
                    <p class="f-85 mb-1 gray-600"> <fa icon="info-circle" class="mr-1"/>서면 검증 및 보완에 최소 3일 소요되며, 대면 인터뷰 후 검증의견서 산출까지 약 2일 소요됩니다.</p>
                    <p class="f-85 gray-600"> <fa icon="info-circle" class="mr-1"/>검증 전문가 일정에 따라 추후 일정 조정이 필요할 수 있습니다.</p>
                </b-col>
            </b-row>
            <b-row no-gutters class="hover-gray py-2">
                <b-col cols="3"><div class="gray-600">대면 인터뷰 장소</div> </b-col>  
                <b-col>
                    <template v-if="is_editing==true">
                        <p class="fw-400">대면 인터뷰 장소(보고 조직의 사업장)를 입력해주세요.</p>
                        <b-form-input type="text" class="mb-2" :disabled="contracted!=true" v-model="onsite_address"></b-form-input>
                    </template>
                    <template v-else>
                        <p class="fw-400"> {{ onsite_address }}</p>
                    </template>
                    <p class="f-85 gray-600"> <fa icon="info-circle" class="mr-1"/>대면 인터뷰는 보고 조직의 실제 운영 시스템을 확인하기 위한 것으로 검증 전문가가 보고 조직의 사업장으로 방문하여 관련 내용을 확인하는 대면 인터뷰가 필요합니다. </p>
                </b-col>
            </b-row>
            <b-row no-gutters class="hover-gray pt-2 pb-4">
                <b-col cols="3"><div class="gray-600">담당자 정보</div> </b-col>  
                <b-col>
                    <b-card v-if="is_editing==true" class="bgray-200 border-0">
                        <div class="d-inline-block" style="width: 80px"> 성함 </div>
                        <b-form-input type="text" v-model="onsite_name" placeholder="성함" class="narrow-input-form mb-3 d-inline" style="width: calc(100% - 80px)" :disabled="contracted!=true"></b-form-input>
                        <div class="d-inline-block" style="width: 80px"> 이메일 </div>
                        <b-form-input type="text"  v-model="onsite_e_mail" placeholder="이메일" class="narrow-input-form mb-3 d-inline" style="width: calc(100% - 80px)" :disabled="contracted!=true"></b-form-input>
                        <div class="d-inline-block" style="width: 80px"> 전화번호 </div>
                        <b-form-input type="text" v-model="onsite_tel" placeholder="전화번호" class="narrow-input-form d-inline" style="width: calc(100% - 80px)" :disabled="contracted!=true"></b-form-input>
                    </b-card>
                    <b-card v-else class="bgray-200 border-0">
                        <div class="mb-2"> <p class="mb-0 d-inline-block" style="width: 80px"> 성함 </p> {{ onsite_name? onsite_name : '-' }}</div>
                        <div class="mb-2"> <p class="mb-0 d-inline-block" style="width: 80px"> 이메일 </p> {{ onsite_e_mail? onsite_e_mail: '-' }}</div>
                        <div class="mb-2"> <p class="mb-0 d-inline-block" style="width: 80px"> 전화번호 </p> {{ onsite_tel? onsite_tel:'-' }}</div>
                    </b-card>
                </b-col>
            </b-row>
            <b-row no-gutters class="hover-gray py-2 mb-5">
                <b-col cols="3"><div class="gray-600">별도 요청사항</div> </b-col>  
                <b-col v-if="is_editing==true">
                    <b-form-textarea type="text" v-model="onsite_others" placeholder="서면검증 또는 대면 인터뷰 관련 별도 요청사항" :disabled="contracted!=true"></b-form-textarea>
                </b-col>
                <b-col v-else class="teal7">
                    {{ onsite_others==''? '-': onsite_others }}
                </b-col>
            </b-row>
            <b-button v-if="request_status<2" variant="teal" class="mr-3 px-3" :disabled="contracted!=true" @click="handleMulti"> 검증 신청하기 </b-button>
            <b-button v-else-if="request_status>=2" :variant="is_editing==true?'teal':'teal-border'" class="mr-3 px-3" :disabled="contracted!=true" @click="handleMulti"> {{ is_editing==true ? '검증 신청 정보 수정하기' :'수정사항 저장하기' }} </b-button>
            <b-button v-if="is_editing==true" class="px-3" @click="handleCancle"> 취소 </b-button>
        </div>
    </b-card>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import * as ih from '@/components/util'

export default {
    data() {
        return {
            onsite_date: '',
            onsite_address: '',
            onsite_name: '',
            onsite_e_mail: '',
            onsite_tel: '',
            onsite_others: '',
            is_editing: false
        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapGetters('report_list', ['selected_report']),
        ...mapState('assurance', ['assurance_list']),
        ...mapGetters('assurance', ['latest_assurance']),
        contracted(){  // 추후 latest_assurance.request_status>=1 로 수정 필요 (계약 인터페이스 생성 완료 후)
            if (this.assurance_list.length>0&&this.assurance_list[0].contract_signed == true) return true
            else return false
        },
        request_status(){
            return this.latest_assurance.request_status
        },
        onsite_get_day(){
            let day = ih.stringToDate(this.onsite_date)
            let yoil = ['일', '월', '화', '수', '목', '금', '토']
            let onsite_yoil = yoil[day.getDay()]
            return onsite_yoil
        }        
    },
    methods:{
        ...mapActions('report_list', ['saveReportPreference']),
        ...mapActions('assurance', ['saveAssuranceRequest']),
        handleMulti(){
            if(this.is_editing==true){
                this.handleSave()
            }
            else {
                this.is_editing = true
            }
        },
        async handleCancle(){
            await this.initiateForm()
        },
        async handleSave(){
            if (this.checkForm()==true){
                let temp_request_status = null
                if (this.selected_report.state <= 2){
                    this.saveReportPreference({report_code: this.report_code, state: 3})  
                }
                if(this.request_status<2){
                    temp_request_status = 2
                }
                await this.saveAssuranceRequest({
                    report_code : this.report_code,
                    onsite_date : this.onsite_date,
                    onsite_address : this.onsite_address,
                    onsite_name: this.onsite_name,
                    onsite_e_mail: this.onsite_e_mail,
                    onsite_tel: this.onsite_tel,
                    onsite_others: this.onsite_others,
                    request_status: temp_request_status
                })
                this.initiateForm()
                this.$EventBus.$emit('make-toast', '검증 신청 완료', '검증 및 대면 인터뷰가 신청되었습니다.')
                this.is_editing = false
            }
        },
        checkForm(){
            if(this.onsite_date==''){ alert('대면 인터뷰 희망 일정을 선택해 주세요.') 
                return false
            }
            else if(this.onsite_address==''){ alert('대면 인터뷰 장소를 입력해 주세요.')
                return false
            }
            else if(this.onsite_name==''){ alert('담당자 성함을 입력해 주세요.')
                return false
            }
            else if(this.onsite_e_mail==''){ alert('담당자 이메일을 입력해 주세요.')
                return false
            }
            else if(this.onsite_tel==''){ alert('담당자 전화번호를 입력해 주세요.')
                return false
            }
            else return true
        },
        initiateForm(){
            if (this.assurance_list[0].request_status>=0){
                this.onsite_date = this.latest_assurance.request_onsite_date
                this.onsite_address = this.latest_assurance.request_onsite_address
                this.onsite_name = this.latest_assurance.request_onsite_name
                this.onsite_e_mail = this.latest_assurance.request_onsite_e_mail
                this.onsite_tel = this.latest_assurance.request_onsite_tel
                this.onsite_others = this.latest_assurance.request_onsite_others
                this.is_editing = false
            }
        }
    },
    mounted(){
        this.initiateForm()
        // console.log(this.latest_assurance);
    }

}
</script>

<style>

</style>
<template>
    <span>
        <span v-if="!edit_delete_mode">
            <b-button
                variant="light" 
                :disabled="is_unlinked===true" 
                class="py-1 px-2" 
                v-b-tooltip.hover.bottom title="차트 사례 추가" 
                @click.stop.prevent="addCharts"
            >
                <b-icon
                    scale="1"
                    v-if="is_able_to_edit"
                    icon="bar-chart-line-fill"
                    aria-hidden="true"
                />
            </b-button>
            
        </span>
        <span v-else>
            <b-button 
                variant="light" 
                class="py-1 px-2" 
                v-b-tooltip.hover 
                title="편집" 
                @click.stop.prevent="addCharts"
            >
                <fa icon="pen"/>
            </b-button>
        </span>
        
        
        <div v-show="show_modal" class='hj-modal-writing-bottom-lg bg-white rounded shadow' :style="low_height==true? 'height:50vh!important':''">
            <div class="hj-modal-header d-flex align-items-center justify-content-between px-4">
                차트 편집
                <div>
                    <b-button variant="icon-light" @click="low_height=!low_height">
                        <fa :icon="low_height==true?'expand' :'compress'"></fa> 
                    </b-button>
                    <b-button variant="icon-light" @click="show_modal=false">
                        <fa icon="times"></fa> 
                    </b-button>
                </div>
            </div> 
            <div class="hj-modal-body px-4 overflow-auto" :style="low_height==true? 'height:calc(50vh - 50px)!important':''">
                <!-- 차트 리스트 -->
                <ChartCaseList
                    v-if="modal_mode==='chart_list'"
                    :editing_mode = true
                    :dc_key_info="dc_key_info"
                    :selected_chart_list="selected_chart_list"
                    :selected_mng_chart_id="selected_mng_chart_id"
                    @closeModal="closeModal"
                    @flag_modal_mode="flag_modal_mode"
                />

                <!-- 차트 편집 -->
                <DefaultChart 
                    v-else-if="modal_mode==='chart_edit'"
                    :editing_mode = true
                    @flag_modal_mode="flag_modal_mode"
                />
                
                <!-- 차트 관리 -->
                <ManageChart
                    v-else-if="modal_mode==='chart_manage'"
                    :editing_mode = true
                    @flag_modal_mode="flag_modal_mode"
                />
            </div>
        </div>

    </span>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ChartCaseList from '@/components/writing_report/chart_subs/ChartCaseList.vue';
import DefaultChart from '@/components/writing_report/chart_subs/DefaultChart'
import ManageChart from '@/components/writing_report/chart_subs/ManageChart'
export default {
    name: '',
    computed: {
        ...mapState("report_list", ["report_code"]),
        ...mapState('charts', ['chart_data', 'chart_field', 'chart_linked_indicators', 'selected_chart_id', 'selected_chart_data'])
    },
    props: {
        is_unlinked: { type: Boolean, default: () => false },
        is_able_to_edit: { type: Boolean, default: () => false },
        dc_key_info: { type: Array, default: () => [] },
        editing_gri_code: { type: String, default: () => '' },
        selected_chart_list: { type: Array, default: () => [] },
        edit_delete_mode: { type: Boolean, default: false },
        selected_mng_chart_id: { type: Number, default: null },
    },
    components: {
        ChartCaseList,
        DefaultChart,
        ManageChart
    },
    data() {
        return {
            example: '',
            show_modal: false,
            low_height: false,
            modal_mode: "chart_list" // "chart_list", "chart_edit", "chart_manage"
            
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {


    },
    beforeUpdate() {},
    updated() { },
    beforeDestroy() {
        this.update_editing_gri_code('');
        
    },
    beforeUnmount() {},
    unmounted() { },
    methods: {
        ...mapMutations('charts', ['update_selected_chart_id']),
        ...mapMutations('cursor', ['update_editing_gri_code']),
        ...mapActions("charts", ["getChartList"]),
        addCharts() {
            if (this.editing_gri_code !== '') {
                this.update_editing_gri_code(this.editing_gri_code);
            }
        
            this.show_modal = true;

            if (this.edit_delete_mode) {
                this.modal_mode = 'chart_edit'
                this.update_selected_chart_id(this.selected_mng_chart_id)
            }

            this.getChartList({
                report_code: this.report_code,
                is_editing: true,
            });
            this.$emit('add-dc-tables', null)
        },
        closeModal() {
            this.show_modal = false;
        },
        flag_modal_mode(flag) {
            this.modal_mode = flag
        }
    }
}
</script>
<style>
</style>
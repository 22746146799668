import Vue from 'vue'
import Router from 'vue-router'

import ViewerMaster from '@/components/report_preview/ViewerMaster'
import FullView2021Basic from '@/components/report_preview/gri2021_viewer/FullView2021Basic'
import { publicPath } from '@/../vue.config'

const routes = [
    { 
      path: '/',
      component: FullView2021Basic,
    //   children: [{
    //       path: '/', 
    //       name: 'ReportView',
    //       component: FullView2021Basic,
    //       props: true
    //     }
    //   ], 
      props: true,
    },    
]

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: publicPath,
    routes, 
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {
            selector: to.hash
            }
        }else return { x:0, y:0 }
    }
})

export default router
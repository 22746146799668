//유저 관점에서 보여야 하는 리포트 목록
//리포트 프리퍼런스를 변경하거나, Coauthors 정보가 변경되는 경우 여기가 변경되어야 함

// import Vue from 'vue'

const report_list = {
    namespaced: true, 
    state: {
        report_code: process.env.VUE_APP_REPORT_CODE ? process.env.VUE_APP_REPORT_CODE : '',
        standard_mode: 'GRI',
        report_list: [
            // {
            //     index: 0,
            //     report_id: 1,
            //     report_code: "r11",
            //     report_name: "GRI Test Report",
            //     created_date: "2020-11-26",
            //     start_date: "2019-01-01",
            //     end_date: "2019-12-31",
            //     published_date: "1900-01-01" // default값을 1900년 1월 1일로 설정해두었습니다.
            //     report_url: "qesg",
            //     organization_id: 1,
            //     organization_name : ''
            //     organization_name_e : ''
            //     address: ''
            //     logo_name: ''
            //     logo_path: ''
            //     featured_image_name: "18blthr4a4pokjpg.jpg",
            //     featured_image_path: "reports\\r11",
            //     state: 0,   // report state: 0 (생성됨) -> 1 (보고 지표 선택됨) -> 2 (작성중) -> 3 (공개됨)
            //     description: ''
            //     using_logo: true,
            //     main_color: "black",
            //     sub_color: "red",
            //     font_family: "malgun-gothic",
            //     font_size: "14",
            //     folded: false,
            //     owner_id: 1,
            //     owner_e_mail: '',
            //     align_option: "core",
            //     type: null,
            //     coauthors: [{
            //         "index": 0,
            //         "coauthor_id": 1,
            //         "report_code": "r11",
            //         "e_mail": "hjkim@qesg.co.kr",
            //         "accepted": true,
            //         "invited_date": "2020-11-03"
            //     }]
            // }
        ],
        is_report_list_loaded: false,
        all_report_list : [],
        is_all_report_list_loaded: false,
        lang: 'KOR', //영문일 경우 'ENG'
        view_mode: 'editor', // 'viewer'
    },
    mutations: {
        update_report_list(state, new_report_list){
            state.report_list = new_report_list
        },
        add_or_update_report(state, {report_code, new_report_obj }){
            let temp_report_list = [ ...state.report_list ]
            let idx = temp_report_list.findIndex(item => item.report_code == report_code)
            if (idx > -1){
                //coauthors가 있기 때문에, 제외하고 나머지 부분을 업데이트 해야 함
                for (const [key, value] of Object.entries(new_report_obj)) {
                    temp_report_list[idx][key]=value    
                }

                if(temp_report_list[idx].hasOwnProperty('coauthors')==false){
                    temp_report_list[idx]['coauthors'] = []
                }
            }
        },
        update_report_name_e(state, new_obj){
            let temp_report_list = [...state.report_list]
            let idx = temp_report_list.findIndex(x => x.report_code == new_obj.report_code)
            if(idx >-1){
                temp_report_list[idx]['report_name_e'] = new_obj.report_name_ea
            }
            // console.log(state.report_list);
        },
        update_report_code(state, new_report_code){
            state.report_code = new_report_code
        },
        update_report_list_loaded(state, flag){
            state.is_report_list_loaded = flag
        },
        add_or_update_coauthor(state, { report_code, new_coauthor_obj }){
            //1. find report
            // console.log(new_coauthor_obj)
            let temp_report_list = [ ...state.report_list ]
            let idx = temp_report_list.findIndex(item => item.report_code == report_code )
            if (idx > -1){
                //coauthor 안에 이미 존재하는지 확인하고 추가 또는 업데이트
                let temp_coauthors = temp_report_list[idx].coauthors
                let author_idx = temp_coauthors.findIndex(item => item.e_mail == new_coauthor_obj.e_mail )
                if (author_idx > -1){
                    // console.log(temp_coauthors[author_idx])
                    temp_coauthors.splice(author_idx, 1, new_coauthor_obj)
                    temp_report_list.coauthors = temp_coauthors
                }
                else{
                    temp_coauthors.push(new_coauthor_obj)
                    temp_report_list.coauthors = temp_coauthors
                }
            }
            //2. state 변경 인식시킴
            state.report_list = temp_report_list
        },
        delete_coauthor(state, { report_code, target_e_mail }){
            //1. find report
            let temp_report_list = [ ...state.report_list ]
            let idx = temp_report_list.findIndex(item => item.report_code == report_code )
            // console.log(temp_report_list)
            if (idx > -1 ){
                let temp_coauthors = temp_report_list[idx].coauthors
                let author_idx = temp_coauthors.findIndex(item => item.e_mail == target_e_mail )
                // console.log(temp_coauthors)
                if(author_idx > -1){
                    temp_coauthors.splice(author_idx, 1)
                }
            }
            //2. state 변경 인식시킴
            state.report_list = temp_report_list
        },
        delete_report(state, report_code){
            let idx = state.report_list.findIndex(item => item.report_code == report_code)
            if ( idx > -1){
                state.report_list.splice(idx, 1)
            }
        },
        update_all_report_list(state, new_list){
            state.all_report_list = new_list
        },
        update_all_report_list_loaded(state, flag){
            state.is_all_report_list_loaded = flag
        },
        change_lang(state, new_lang){
            state.lang = new_lang
        },
        update_view_mode(state, mode){
            state.view_mode = mode
        },
        set_standard_mode(state, mode){
            state.standard_mode = mode
        }
    },
    getters: {
        selected_report(state) {
            let idx = state.report_list.findIndex(item => item.report_code == state.report_code)
            
            if (idx > -1) {
                return state.report_list[idx]
            }

            idx = state.report_list.findIndex(item => item.report_url == state.report_code)
            if (idx > -1) {
                return state.report_list[idx]
            }

            //report_list (editor)에서 못찾은 경우, viewer 일 수 있으므로 all_list 에서 다시 찾음. 
            idx = state.all_report_list.findIndex(item => item.report_code == state.report_code)
            if (idx > -1){
                return state.all_report_list[idx]
            }

            idx = state.all_report_list.findIndex(item => item.report_url == state.report_code)
            if (idx > -1) {
                return state.all_report_list[idx]
            }

        },
        gri_ver(state) {
            // console.log("gri_ver???")
            // if (state.is_all_report_list_loaded === true){
            //     let idx = state.all_report_list.findIndex(item => item.report_code == state.report_code)
            //     if (idx > -1){
            //         if (state.all_report_list[idx].gri_use == null){
            //             return 'not_report'
            //         }
            //         return state.all_report_list[idx].gri_use
            //     }else return 'not_report'
            
            // } else if (state.is_report_list_loaded) {
            if (state.is_report_list_loaded) {

                let idx = state.report_list.findIndex(item => item.report_code == state.report_code)
                if (idx > -1){
                    if (state.report_list[idx].gri_use == null){
                        return 'not_report'
                    }
                    return state.report_list[idx].gri_use
                } else return 'not_report'
                
            } else {
                return 'not_report'
            }
        },
        tcfd_ver(state){
            if (state.is_all_report_list_loaded==false){
                let idx = state.report_list.findIndex(item => item.report_code == state.report_code)
                if (idx > -1){
                    if (state.report_list[idx].tcfd_use == null){
                        return 'not_report'
                    }
                    return state.report_list[idx].tcfd_use
                }else return 'not_report'
            }
            else {
                return 'not_report'
            }
        },
        get_report_name_e(state){
            let idx = state.report_list.findIndex(item => item.report_code == state.report_code)
            if (idx > -1){
                if (state.report_list[idx].report_name_em!=null&&state.report_list[idx].report_name_em!=undefined) {
                    return state.report_list[idx].report_name_em
                }
                else if (state.report_list[idx].report_name_ea!=null&&state.report_list[idx].report_name_ea!=undefined){
                    return state.report_list[idx].report_name_ea
                }
                // else return state.report_list[idx].report_name
            }
        }
    },
    actions: {
        readReportListByUserId({ commit, rootState }, { user_id }) {
            const path = rootState.backend_host + '/read_report_list_by_user_id'

            return axios.get(path, { params: {
                user_id: user_id
            }})
            .then( response => {
                let new_report_list = response.data
                // console.log(response.data);
                commit('update_report_list', new_report_list)
                commit('update_report_list_loaded', true)
            })
            .catch(e => {
                console.log(e)
                alert(`다중 로그인이 감지되어 로그아웃 합니다. 다시 로그인해 주십시오.`)
                this.logout().then(() => this.$router.push({ name: "Login" }));

            })
        },
        readReportListByKeywordFromAdmin({ commit, rootState }, { keyword, search_category, user_id }) {
            const path = rootState.backend_host + '/read_report_list_by_admin_search'

            return axios.post(path, {
                "k": keyword,
                "user_id": user_id,
                "search_category": search_category
            })
            .then( response => {
                let new_report_list = response.data
                // console.log(response.data);
                commit('update_report_list', new_report_list)
                commit('update_report_list_loaded', true)
            })
            .catch(e => {
                console.log(e)
                alert(`관리자 로그인이 아닙니다. 다시 로그인해주세요.`)
                this.logout().then(() => this.$router.push({ name: "Login" }));

            })
        },
        readAllReportListForViewer({ commit, rootState }) {
            sessionStorage.clear();
            localStorage.clear();
            const urlParams = new URL(location.href).searchParams;
            // let report_url = urlParams.get('r').split("?")[0] !== null ? urlParams.get('r').split("?")[0] : process.env.VUE_APP_REPORT_CODE;
            let report_url = process.env.VUE_APP_REPORT_CODE ? process.env.VUE_APP_REPORT_CODE : (urlParams.get('r').split("?")[0] !== null ? urlParams.get('r').split("?")[0] : null);

            if (report_url) {
                let path = rootState.backend_host + '/read_all_report_list_for_viewer?report_url=' + report_url
                

                if (process.env.VUE_APP_EXTRA_REPORT_CODE) {
                    path += "&extras=" + process.env.VUE_APP_EXTRA_REPORT_CODE
                    
                }

                return axios.get(path)
                    .then(response => {
                        
                        // 공개리포트 제한
                        // if (response.data?.code !== undefined && !response.data.code) {
                        //     window.alert(response.data.message);
                        //     // window.location.href = "https://qesg.co.kr/reporting_tool"
                        //     // return;
                        // }
                            
                        let new_all_report_list = response.data
                        commit('update_all_report_list', new_all_report_list)
                        commit('update_all_report_list_loaded', true)
                })
            } else {
                window.alert("존재하지 않는 보고서입니다.")
            }

        },
        saveReportPreference({dispatch, commit, rootState}, { report_code, report_name, report_name_em, start_date, end_date, published_date, 
            report_url, organization_id, organization_name, organization_name_e, registration_code, address, state, description, report_type,
            using_logo, main_color, sub_color, font_family, font_size, folded, owner_id, align_option, plan, logo, featured_image,
            gri_use, tcfd_use }){
            const path = rootState.backend_host + '/save_report'

            // console.log(report_code);
            let formData = new FormData()
            formData.append('report_code', report_code)
            if(report_name != null && report_name != undefined) formData.append('report_name', report_name)
            if(report_name_em != null && report_name_em != undefined) formData.append('report_name_em', report_name_em)
            if(start_date != null && start_date != undefined) formData.append('start_date', start_date)
            if(end_date != null && end_date != undefined) formData.append('end_date', end_date)
            if(organization_id != null && organization_id != undefined) formData.append('organization_id', organization_id) //organization_id를 안보내면 조직을 새로 생성함
            if(published_date != null && published_date != undefined) formData.append('published_date', published_date)
            if(report_url != undefined && report_url != null) formData.append('report_url', report_url)
            if(state != null && state != undefined) formData.append('state', state)
            if(description != null && description != undefined) formData.append('description', description)
            
            if (report_type != null && report_type != undefined) {
                for (var i = 0; i < report_type.length; i++){
                    formData.append('report_type[]', report_type[i]);

                }
            }
            
            if (using_logo != null && using_logo != undefined) formData.append('using_logo', using_logo)
            if(main_color != null && main_color != undefined) formData.append('main_color', main_color)
            if(sub_color != null && sub_color != undefined) formData.append('sub_color', sub_color)
            if(font_family != null && font_family != undefined) formData.append('font_family', font_family)
            if(font_size != null && font_size != undefined) formData.append('font_size', font_size)
            if(folded != null && folded != undefined) formData.append('folded', folded)
            if(owner_id != null && owner_id != undefined) formData.append('owner_id', owner_id)
            if(align_option != null && align_option != undefined) formData.append('align_option', align_option)
            if(plan != null && plan != undefined) formData.append('plan', plan)

            if(gri_use != null && gri_use != undefined) formData.append('gri_use', gri_use)
            if(tcfd_use != null && tcfd_use != undefined) formData.append('tcfd_use', tcfd_use)

            if(organization_name != null && organization_name != undefined) formData.append('organization_name', organization_name)
            if(organization_name_e != null && organization_name_e != undefined) formData.append('organization_name_e', organization_name_e)
            if(registration_code != null && registration_code != undefined) formData.append('registration_code', registration_code)
            if(address != null && address != undefined) formData.append('address', address)
            
            if(logo != null && logo != undefined)
                formData.append('logo', logo, logo.name)
            if(featured_image != null && featured_image != undefined)
                formData.append('featured_image', featured_image, featured_image.name)
            
            return axios.post(path, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }
            })
            .then( response => {
                // console.log(response.data)
                commit('add_or_update_report', { report_code: response.data.report_code, new_report_obj: response.data })
                return response.data.report_code            
            })
            // .catch(error => {
                // console.log(error);
                // if (error.response.status == 401) { 
                //     alert(`다중 로그인이 감지되어 로그아웃 합니다. 다시 로그인해 주십시오.`)
                //     dispatch('logout', null, { root: true }).then(() => { window.location.href=rootState.frontend_host })
                // }
                // else {  alert(`편집하실 보고서를 다시 선택해 주세요`) 
                //         window.location.href=rootState.frontend_host }
            // })

        },
        inviteCoauthor({commit, rootState}, { report_code, target_e_mail }){
            //Coauthor를 타깃 리포트의 목록에 추가함
            const path = rootState.backend_host + '/invite_coauthor'

            let formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('e_mail', target_e_mail)

            return axios.post(path, formData)
            .then( response => {
                if(response.data.code == 'success'){
                    let new_coauthor_obj = {
                        coauthor_id: response.data.coauthor_id, 
                        report_code: response.data.report_code, 
                        e_mail: response.data.e_mail,
                        accepted: response.data.accepted,
                        invited_date: response.data.invited_date
                    }
                    commit('add_or_update_coauthor', { report_code: report_code, new_coauthor_obj: new_coauthor_obj })
                    return response
                }
                else if(response.data.code == 'failure'){
                    // console.log(response.data.msg)
                    return response
                }
            })
        },
        kickOutCoauthor({ commit, rootState}, { report_code, target_e_mail }){
            //Coauthor 제거하고, 타깃 리포트에서도 제거토록 함
            const path = rootState.backend_host + '/kick_out_coauthor'

            let formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('e_mail', target_e_mail)

            return axios.post(path, formData)
            .then( response => {
                commit('delete_coauthor', { report_code: response.data.report_code, target_e_mail: response.data.e_mail })          
            })
            .catch( error => {
                console.log(error)
            })
        
        },
        deleteReport({dispatch, commit, rootState}, {report_code }){
            const path = rootState.backend_host + '/delete_report'

            let formData = new FormData()
            formData.append('report_code', report_code)

            return axios.post(path, formData)
            .then( response => {
                commit('delete_report', response.data.report_code )
            })
            .catch(error => {
                console.log(error)
                // if (error.response.status == 401) { 
                //     alert(`다중 로그인이 감지되어 로그아웃 합니다. 다시 로그인해 주십시오.`)
                //     dispatch('logout', null, { root: true }).then(() => { window.location.href=rootState.frontend_host })
                // }
                // else { alert(`편집하실 보고서를 다시 선택해 주세요`) 
                // window.location.href=rootState.frontend_host }
            })
        },
        translateReportName({dispatch, commit, rootState}, {report_code, report_name}){
            const path = rootState.backend_host + '/translate_report_name'
            let formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('report_name', report_name)

            return axios.post(path, formData)
            .then( response => {
                commit('update_report_name_e', response.data)
            })
            .catch(error => {
                console.log(error);
                // if (error.response.status == 401) { 
                //     alert(`다중 로그인이 감지되어 로그아웃 합니다. 다시 로그인해 주십시오.`)
                //     dispatch('logout', null, { root: true }).then(() => { window.location.href=rootState.frontend_host })
                // }
                // else { alert(`편집하실 보고서를 다시 선택해 주세요`) 
                // window.location.href=rootState.frontend_host }
            })
        }
    }
}

export default report_list
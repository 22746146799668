var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("검토하기")]),_c('p',{staticClass:"f-95 mb-4 gray-600"},[_vm._v("작성한 보고서를 미리보기로 확인합니다.")]),_c('b-card',{staticClass:"general-card mb-5"},[_c('b-card-header',[_vm._v("보고서 미리보기")]),_c('p',{staticClass:"pt-3 gray-600 mb-2"},[_vm._v(" 작성한 보고서를 확인 및 출력합니다. 더이상 수정사항이 없을 경우 ‘공개하기’를 통해 공개할 수 있습니다.")]),_c('p',{staticClass:"f-90 gray-600 mb-45"},[_c('fa',{staticClass:"mr-1",attrs:{"icon":"info-circle"}}),_vm._v(" 제3자 검증의견서가 누락되어 있습니다. "),_c('router-link',{staticClass:"cursor",attrs:{"to":"/assurance_info"}},[_vm._v("검증받기")]),_vm._v(" 를 통해 제3자 검증의견서를 추가하세요. ")],1),_c('h6',{staticClass:"gray-600"},[_vm._v(" 미리보기 ")]),_c('b-button-group',{staticClass:"mb-45 w-100"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"teal"},on:{"click":function($event){return _vm.$router.push({
                    name: 'FullView', 
                    params: {
                        reload: true
                    }
                })}}},[_vm._v(" "+_vm._s(_vm.selected_report.report_name)+" "),_c('fa',{staticClass:"ml-1",attrs:{"icon":"expand"}})],1)],1),_c('h6',{staticClass:"gray-600"},[_vm._v(" 수정 바로가기 ")]),_c('b-card',{},[_c('router-link',{staticClass:"cursor mr-4",attrs:{"to":{
                    name: 'ThemeSettings', 
                    hash: '#sub1'
                }}},[_vm._v(" 표지 수정하기 "),_c('fa',{staticClass:"pl-1",attrs:{"icon":"link"}})],1),_c('a',{staticClass:"cursor",on:{"click":_vm.goGri100}},[_vm._v(" 내용 수정하기 "),_c('fa',{staticClass:"pl-1",attrs:{"icon":"link"}})],1)],1)],1),_c('b-modal',{attrs:{"id":"compact-previewer","hide-footer":"","size":"xl","scrollable":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.selected_report.report_name)+" GRI Table ")]},proxy:true}])},[_c('div',{staticClass:"px-lg-4 py-3"},[_c('gri-table-view-2021',{attrs:{"reload":true}})],1)]),_c('b-card',{staticClass:"general-card mb-5"},[_c('b-card-header',[_vm._v("보고서 작성 내용 Excel 다운로드")]),_c('p',{staticClass:"pt-3 gray-600 mb-5"},[_vm._v(" '"+_vm._s(_vm.selected_report.report_name)+"'에 작성한 모든 내용을 엑셀파일로 일괄 다운로드 할 수 있습니다.")]),_c('h6',{staticClass:"gray-600"},[_vm._v(" 엑셀 다운로드 ")]),_c('b-button-group',{staticClass:"mb-45 w-100"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"teal"},on:{"click":function($event){return _vm.download_excel('KOR')}}},[_vm._v(" 한글 다운로드 "),_c('b-icon',{attrs:{"icon":"download","scale":"1"}})],1),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"teal"},on:{"click":function($event){return _vm.download_excel('ENG')}}},[_vm._v(" 영문 다운로드 "),_c('b-icon',{attrs:{"icon":"download","scale":"1"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <!-- {{ userLicenseList }} -->
    <b-overlay :show="loadingShow" no-wrap></b-overlay>
    <h3>라이센스 관리</h3>
    <b-container fluid>
      <!-- Main table element -->
      <b-table
        :items="userLicenseList"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
        hover
        empty-filtered-html="검색 결과가 없습니다 😥"
        empty-text="결제 내역이 없습니다."
        tbody-tr-class="payment-row"
      >
        <template #table-caption>
          총
          <span v-if="totalRows !== null" class="mb-0 red">{{
            totalRows
          }}</span>
          건의 내역이 조회됨
        </template>

        <template #cell(amount_price)="row">
          {{ row.value }}
        </template>

        <template #cell(subscription_discount_code)="row">
          <span v-if="row.value !== ''">
            <b-input-group style="width:150px;">
              <b-form-input
                :placeholder="row.value"
                :value="row.value"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="applyDiscount_code"
                  class="addDiscount"
                  ><b-icon
                    icon="check-circle"
                    scale="1"
                    variant="primary"
                  ></b-icon
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </span>

          <span v-else>
            <b-input-group style="width:150px;">
              <b-form-input
                :placeholder="row.value"
                :value="row.value"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="applyDiscount_code"
                  class="addDiscount"
                  ><b-icon
                    icon="plus-circle"
                    scale="1"
                    variant="primary"
                  ></b-icon
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </span>
        </template>

        <template #cell(is_used)="row">
          <b-icon
            v-if="row.value == true"
            icon="check-circle"
            scale="1.5"
            variant="success"
          ></b-icon>
          <b-icon
            v-else
            icon="exclamation-circle-fill"
            scale="1.5"
            variant="danger"
            animation="throb"
          ></b-icon>
        </template>

        <template #cell(description)="row">
          <div v-if="row.item.should_be_cancelled == true">
            <b-button disabled variant="warning" v-b-tooltip.hover title="구독 취소 예정입니다.">
              구독취소예정
            </b-button>
            <p>
              결제취소사유: 
              {{ row.value }}
            </p>
          </div>

          <div v-else>
            <b-button @click="cancelSubscription(row.item.license_key)" variant="danger" v-b-tooltip.hover title="사용자의 구독을 취소합니다.(환불은 이뤄지지않고 다음 결제만 되지 않도록 합니다.)">
              구독취소
            </b-button>
          </div>


        </template>

        <template #cell(payment_status)="row">
          <b-icon
            v-if="row.value == true"
            icon="check-circle"
            scale="1.5"
            variant="success"
          ></b-icon>
          <b-icon
            v-else
            icon="exclamation-circle-fill"
            scale="1.5"
            variant="danger"
            animation="throb"
          ></b-icon>
        </template>

        <template #cell(actions)="row">
          <b-button
            size="sm"
            @click="row.toggleDetails"
            v-if="row.item.coupon_history.length > 0"
          >
            {{ row.detailsShowing ? "창 닫기" : "쿠폰 내역" }}
          </b-button>

          <b-button v-else disabled>쿠폰 사용x</b-button>
        </template>

        <template #row-details="row">
          {{ row.childProduct }}

          <b-card>
            <!-- Detail 부분 -->
            <ul>
              <li v-for="(value, key) in row.item.coupon_history" :key="key">
                {{ value }}
              </li>
            </ul>
            <!-- <b-table-simple>
                <b-thead>
                  <b-tr>
                    <b-th>서비스명</b-th>
                    <b-th>결제상태</b-th>
                    <b-th>가격</b-th>
                    <b-th>할인코드</b-th>
  
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr variant="info">
                    <b-th sticky-column>{{ row.item.service_name }}</b-th>
                    <b-td>{{ row.item.payment_status }}</b-td>
                    <b-td>{{ row.item.amount_price }}</b-td>
                    <b-td>{{ row.item.discount_code }}</b-td>
                  </b-tr>
                  <b-tr v-for="(value, key) in row.item.childProduct" :key="key">
                    <b-td>{{ value.service_name }}</b-td>
                    <b-td>{{ value.paymentSatus }}</b-td>
                    <b-td>{{ value.amount_price }}</b-td>
                    <b-td>-</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple> -->
            <b-button variant="outline-info">수정하기</b-button>
          </b-card>
        </template>
      </b-table>

      <!-- Info modal -->
      <b-modal
        :id="infoModal.id"
        :title="infoModal.title"
        ok-only
        @hide="resetInfoModal"
      >
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <!-- User Interface controls -->
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
// import requestWithQESG from "@/components/customAxios";

import { formatCurrency } from "@/components/payment/payment";

export default {
  computed: {
    ...mapState("admin", ["report_list", "report_code"]),
  },
  data() {
    return {
      isToggled: false,
      userLicenseList: [],
      fields: [
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
        {
          key: "user_id",
          label: "사용자아이디",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "subscription_id",
          label: "상품명",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "amount_price",
          label: "상품가격",
          sortable: true,
          class: "text-center",
          formatter: (value) => {
            return formatCurrency(value) + "원";
          },
        },
        {
          key: "subscription_discount_code",
          label: "할인코드",
          sortable: true,
          class: "text-center",
        },
        {
          key: "payment_status",
          label: "지불여부",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
        {
          key: "is_used",
          label: "사용여부",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
        {
          key: "license_key",
          label: "라이센스 키",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
        {
          key: "last_payment_date",
          label: "마지막 결제일",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
        {
          key: "enrollment_date",
          label: "최초 등록일",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
        {
          key: "description",
          label: "정기결제 취소사유",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },


        

      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      loadingShow: true,
    };
  },
  computed: {
    buttonIcon() {
      return this.isToggled ? "toggle-on" : "toggle-off";
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.fetchSubscriptionPayHistory();
    this.totalRows = this.userLicenseList.length;

    const table_rows = document.querySelectorAll(".payment-row");
    table_rows.forEach((element) => {
      element.addEventListener("click", this.openRowDetail);
    });

    // 테이블 데이터 fetch callback에 넣어주기
    this.loadingShow = false;
  },
  methods: {
    cancelSubscription(license_key) {

      const request_cancel = window.prompt("취소 사유를 입력해주세요")

      if (request_cancel !== null) {
        this.loadingShow = true;
        axios.post(this.$store.state.backend_host + "/payment/subscription_should_be_cancelled", {
          "license_key": license_key,
          "cancel_reason": request_cancel
        }).then((response) => {
          this.fetchSubscriptionPayHistory();
          this.loadingShow = false;
          window.alert(response.data.message)
        })
          .error((e) => {
          window.alert('Error:', e);
        })
        
      } 

    },  
    fetchSubscriptionPayHistory() {
      // /api/payment/get_user_subscription_paycheck_history

      // requestWithQESG("POST", "/payment/manage_subscription_license")
      //   .then((response) => {
      //     this.loadingShow = false;
      //     this.userLicenseList = response.data.result;
      //     this.totalRows = this.userLicenseList.length;
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
      axios.post(this.$store.state.backend_host + "/payment/manage_subscription_license").then((response) => {
          this.loadingShow = false;
          this.userLicenseList = response.data.result;
          this.totalRows = this.userLicenseList.length;   
      })
      .catch((error) => {
        console.error(error);
      });
    },
    toggleButton() {
      this.isToggled = !this.isToggled;
    },
    applyDiscount_code() {
      // TODO: 할인쿠폰 변경 기능
      console.log("할인쿠폰 변경 적용..");
    },
    openRowDetail(e) {
      e.stopPropagation();
      let currentElement = e.target;
      if (currentElement.tagName == "BUTTON") {
        try {
          currentElement.firstChild.querySelector("button").click();
        } catch (e) {
          return null;
        }
        return;
      }
      while (currentElement && currentElement.tagName !== "TR") {
        currentElement = currentElement.parentElement;
      }

      if (currentElement && currentElement.tagName === "TR") {
        // <tr> 태그를 찾은 경우에 대한 로직을 작성합니다.
        currentElement.firstChild.querySelector("button").click();
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style></style>

<template>
<div>
    <h4>검증 안내</h4>
    <p class="f-95 mb-4 gray-600">검증 방법, 기준, 프로세스 및 산출물 등에 대해 안내드립니다. </p>

    <b-card class="general-card mb-5">
        <b-card-header>보고서 제3자 검증</b-card-header>
        <div class="py-3">
            <p class="gray-600">보고서 검증은 보고 주체로부터 독립적인 제3자 지속가능경영 전문가가 검증 원칙에 따라 작성된 보고서를 검토함으로써 보고 조직의 지속가능성 성과 및 운영시스템의 신뢰성과 유효성을 보장하는 과정입니다. </p>
            <p class="gray-600 mb-45"> 보고서 검증을 위해 검증 서비스를 신청하여 보고 내용에 대해 전문가로부터 검증을 받을 수 있습니다. 또한, 기존에 타 검증기관으로부터 받은 검증의견서가 있을 경우 업로드하여 공개할 수 있습니다.
            </p>

            <div class="mb-2">검증 방법</div>
            <b-card class="bgray-200 border-0 px-2">
                <h6 class="pb-1 mb-3 border-bottom-dark"> Option 1. 검증 서비스 신청 (유료)</h6>
                <p class="fw-400"><a href="#" v-b-modal.need-help>검증 신청</a>에서 독립적 검증 기관인 퀀티파이드이에스지(QESG)에 검증 서비스를 신청합니다. 작성한 보고서 전체 내용에 대해 직접 검증 받고 AA1000 기반 검증의견서를 제공받을 수 있습니다.
                
                </p>
                <ol>
                    <li class="fw-300 mb-2">검증 견적을 확인 후 전자 계약을 체결합니다.</li>
                    <li class="fw-300 mb-2">검증 일정 및 검증 담당자 정보를 입력하고 검증을 신청합니다.</li>
                    <li class="fw-300 mb-2">퀀티파이드이에스지(QESG)로 부터 서면 검증 시작 안내 메일을 받으면 검증이 시작됩니다. </li>
                    <li class="fw-300 mb-2">약 3일간의 서면 검증 후 서면 검증 의견이 생성됩니다.</li>
                    <li class="fw-300 mb-2">서면 검증 의견에 따라 보완 사항을 보완합니다.</li>
                    <li class="fw-300 mb-2">대면 인터뷰를 통해 보고 내용 관련 세부 사항을 확인하고 최종 보완합니다.</li>
                    <li class="fw-300 mb-2">대면 인터뷰 후 약 3일 이내에 검증의견서가 생성됩니다.</li>
                    <li class="fw-300 mb-2">검증의견서는 자동으로 2-5 지표에 연결되며, 보고서에 수록됩니다. </li>
                </ol>

                <h6 class="pt-5 pb-1 mb-3 border-bottom-dark"> Option 2. 타 검증의견서 업로드</h6>
                <p class="fw-400"> 타 검증의견서 업로드에서 타 검증기관으로부터 받은 검증의견서를 업로드할 수 있습니다. Option 1의 검증 서비스와 별도로 타 검증의견서를 업로드 할 수 있습니다.
                </p>
                <p class="ml-4">타 검증의견서는 보고서와 검증의견서 간 검증 범위, 보고 내용 등에 차이가 있을 수 있습니다. 업로드한 타 검증의견서의 경우 독자의 이해를 위해 하단에 관련 안내 메세지가 추가됩니다. 
                </p>
            </b-card>
        </div>
    </b-card>

    <b-card class="general-card mb-5">
        <b-card-header>QESG 제3자 검증 서비스 안내</b-card-header>
        <div class="py-3">
            <p class="fw-400">QESG의 제3자 검증은 전문가가 보고 내용을 상세히 검토하고 명확한 근거를 기반으로 각 내용의 객관적 사실 여부를 확인하는 과정으로 진행되며, AA1000 라이선스를 기반으로 보고 기준 준수 여부를 검토합니다. </p>
            <p class="gray-600 mb-45">QESG는 검증 전문가를 보유하며 검증 과정 전반에 전문가가 1:1로 도움을 드립니다. 
            </p>
            <b-row no-gutters class="hover-gray py-2 mb-2" >
                <b-col cols="3"><div class="gray-600">검증 산출물</div> </b-col>
                <b-col> <p class="mb-2">검증 산출물로 지속가능성 보고 검증 국제표준인 AA1000 라이선스에 따른 ‘검증 의견서’가 제공됩니다.</p> <a class="cursor" @click.stop="showSampleModal()">검증의견서 예시 보기 <fa icon="expand" class="ml-1"/></a>
                </b-col> 
            </b-row>
            <b-row no-gutters class="hover-gray py-2 mb-2" >
                <b-col cols="3"><div class="gray-600">소요 기간</div> </b-col>
                <b-col> <p class="mb-1">검증 신청 후 검증의견서 산출까지 최소 7일 정도 소요됩니다.</p>
                        <p>(서면 검증 2~3일, 대면 인터뷰 2~5시간, 검증의견서 산출 2일 포함)</p> 
                </b-col> 
            </b-row>
            <!-- <b-card class="bgray-200 border-0">
                <p class="fw-500 f-95 mb-1">QESG 제3자 검증</p>
                <p class="f-90 gray-600">QESG의 제3자 검증은 전문가가 보고 내용을 상세히 검토하고 명확한 근거를 기반으로 각 내용의 객관적 사실 여부를 확인하는 과정으로 진행되며, AA1000 라이선스를 기반으로 보고 기준 준수 여부를 검토합니다. 
                QESG는 검증 전문가를 보유하며 검증 과정 전반에 전문가가 1:1로 도움을 드립니다.
                </p>
                <p class="fw-500 f-95 mb-1">검증 산출물 <a class="cursor fw-400 ml-2"> 예시 확인 <fa icon="expand" class="ml-1"/> </a>
                </p>
                <p class="f-90 gray-600"> 검증 산출물로 지속가능성 보고 검증 국제표준인 AA1000 라이선스에 따른 ‘검증 의견서’가 제공됩니다.</p>
                <p class="fw-500 f-95 mb-1">소요 기간</p>
                <p class="f-90 gray-600 mb-1"> 서면검증 3일, 대면 인터뷰 3시간이 소요됩니다. </p>
            </b-card> -->
        </div>
    </b-card>

    <b-modal ref="assurance-sample-modal" hide-footer size="xl" title="검증의견서 예시 보기" scrollable>
        <assurance-sample ></assurance-sample>
    </b-modal>
</div>
</template>

<script>
import AssuranceSample from '@/components/static_examples/AssuranceSample'

export default {
    methods:{
        showSampleModal(){
            this.$refs['assurance-sample-modal'].show()
        },
        hideSampleModal(){
            this.$refs['assurance-sample-modal'].hide()
        }
    },
    components:{
        AssuranceSample
    }
}
</script>

<style>

</style>
<template>
<div>
  <div class="bgray-400 py-5">
        <b-container class="py-5">
            <b-row>
                <b-col cols="12" md="6" class="f-80">
                    <div class="pb-4 d-sm-flex align-items-center"> 
                        <!-- <b-img class="mr-4" style="width:170px;" src="/static_img/QESG_CI_clean.png"></b-img>
                        <b-img style="width:180px;" src="/static_img/Content used with permission.png"></b-img> -->
                        <b-img class="mr-4" style="width:85px;" src="/static_img/qesg_vertical.png"></b-img>
                        <b-img class="mr-4" style="width:75px;" src="/static_img/GRI-LicensingMarks-ContentPermission2024-mark.png"></b-img>
                        <b-img class="mr-1" style="width:58px;" src="/static_img/SASB-Inside-Interim-Vert.png"></b-img>
                        <b-img class="" style="width:150px;" src="/static_img/ISSB-Stdrds-Inside-RGB-Logo.png"></b-img>

                
                    </div>
                    <p class="fw-300"><span class="pr-md-3 fw-400">(주)퀀티파이드이에스지</span>
                    문의. info@qesg.co.kr</p>
                    대표. 배익현 | 사업자등록번호. 349-86-01679 | 통신판매업번호. 2022-서울종로-0575 <br>
                    문의. 02-6956-2410 (평일 09:00~17:00, 주말공휴일 휴무) <br>
                    주소. 서울특별시 종로구 새문안로3길 12 (신문로1가, 신문로빌딩), 3층 퀀티파이드이에스지 <br>

                    <p class="fw-300">Copyright 2024 QuantifiedESG, Inc. | All Rights Reserved</p>
                </b-col>
                <b-col cols="12" md="6" class="f-90 d-flex justify-content-between justify-content-md-end mt-4 mt-md-0">
                    <div class="text-right">
                        <b-button variant="icon" size="sm" class="" href="http://qesg.co.kr"  target="_blank">QESG 홈페이지 <i class="fas fa-external-link-square-alt ml-1 f-80"></i></b-button><br>
                        <b-dropdown variant="icon"  size="sm" class="outer-link">
                            <template v-slot:button-content><span class="pr-1"> QESG 서비스 바로가기 </span></template>
                            <template class="">
                            <b-dropdown-item class="f-90" target="_blank" href="http://tierzero.kr"> 공급망 평가 서비스 <b>TierZero</b> <i class="fas fa-external-link-square-alt ml-1 f-80"></i></b-dropdown-item>
                            <b-dropdown-item class="f-90" target="_blank" href="http://assessment.qesg.co.kr"> ESG 평가 서비스 <b>QESG Assessment</b><i class="fas fa-external-link-square-alt ml-1 f-80"></i></b-dropdown-item>                            
                            <b-dropdown-item class="f-90" target="_blank" href="http://board.qesg.co.kr"> 실시간 기업별 ESG 뉴스 플랫폼 <b>QESG Board</b> <i class="fas fa-external-link-square-alt ml-1 f-80"></i></b-dropdown-item>
                            </template>
                        </b-dropdown>
                    </div>
                    <div class="text-right ml-md-5">
                        <b-button variant="icon" size="sm" class="f-90" @click="$router.push({name: 'PrivacyPolicy'})">개인정보 처리방침</b-button><br>
                        <b-button variant="icon" size="sm" class="f-90" @click="$router.push({name: 'TermsOfUse'})">이용약관</b-button><br>
                        <b-button variant="icon" size="sm" class="f-90" v-b-modal.gri-sw-disclaimer-modal>GRI Software Disclaimer</b-button>
                    </div>
                </b-col>
            </b-row>

        </b-container>
    </div>
    <b-modal id="gri-sw-disclaimer-modal" title="GRI Software Disclaimer" hide-footer>
        <p>
            본 소프트웨어에 포함된 GRI 표준은 (주)퀀티파이드이에스지가 GRI로부터 허가를 받아 사용 중입니다. 
            GRI는 GRI 표준의 저작권 소유자로서 (주)퀀티파이드이에스지의 승인 받은 제품 내에서 GRI 표준이 정확하고 정확하게 표현되었음을 검증하고 승인했습니다. 
            이 확인은 라이선스 콘텐츠의 무결성, 신뢰성 및 정확성을 유지하기 위한 것으로 한정되었습니다. 따라서 GRI는 (주)퀀티파이드이에스지의 승인 받은 제품 또는 해당 제품에서 파생된 어떠한 제품에 대한 정확성, 준수, 신뢰성, 목적에의 적합성 또는 품질에 대한 묵시적 또는 실질적인 표현이나 보증을 제공하지 않으며, GRI 표준 저작권 콘텐츠의 사용에 대한 라이선스 사용에 대해 묵시적이거나 명시적인 표현을 일체 부인합니다. 
            라이선싱 기업이 생성한 보고서가 승인된 GRI 표준 보고서의 기준을 충족한다는 묵시적이거나 명시적인 표현을 일체 부인합니다.
        </p>
    </b-modal>
    
</div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    methods:{
        
    },
    mounted(){
        
    },
}
</script>
<template>
    <div>
        <!-- class="px-6 py-3" -->
        <div id="mng-admin-nav">
            <b-breadcrumb @click="switch_indicator">
                <b-breadcrumb-item 
                    href="#GRI"
                    :active="selected_indicator==='GRI'"
                >
                    <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true" /> GRI
                </b-breadcrumb-item>
                <b-breadcrumb-item 
                    href="#SASB"
                    :active="selected_indicator==='SASB'"
                >
                    SASB
                </b-breadcrumb-item>
                <b-breadcrumb-item 
                    href="#TCFD"
                    :active="selected_indicator==='TCFD'"
                >
                    TCFD
                </b-breadcrumb-item>
            </b-breadcrumb>
        </div> 

        <div>
            <ManageGriIndicator v-if="selected_indicator === 'GRI'"/>
            <ManageSasbIndicator v-if="selected_indicator === 'SASB'" />
            <ManageTcfdIndicator v-if="selected_indicator === 'TCFD'" />
        </div>

    </div>
</template>
<script>
import ManageGriIndicator from "@/components/admin/admin_manage_indicator/gri_2021/ManageGriIndicator";
import ManageTcfdIndicator from "@/components/admin/admin_manage_indicator/tcfd_2023/ManageTcfdIndicator";
import ManageSasbIndicator from "@/components/admin/admin_manage_indicator/sasb_2023/ManageSasbIndicator";




export default {
    name: '',
    props: {},
    components: {
        ManageGriIndicator,
        ManageSasbIndicator,
        ManageTcfdIndicator
    },
    data() {
        return {
            example: '',
            selected_indicator: 'GRI'
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {
        switch_indicator(e) {
            if (e.target.tagName !== 'A') return;
            this.selected_indicator = e.target.href.split("#")[1];
        }
    }
}
</script>
<style>
#mng-admin-nav {
  display: inline-block;
  
}
</style>
<template>
<div>
    <div class="mark-down-hj" v-html="md.render(contents)"></div>
</div>
</template>
<script>
import MarkdownIt from 'markdown-it'

export default {
    data(){
        return{
            md: new MarkdownIt({
                html: true,
                breaks: true, 
                linkfy: true
            })
        }
    },
    props:{
        contents: { type: String, default: ''}
    }
}
</script>
<style>
.mark-down-hj {
    line-height: 1.7;
}

.mark-down-hj ol {
    padding-inline-start: 15px;

}

/* 에디터 화면 내 도우미 부분 간격 좁히기 */
p .mark-down-hj > ol li {
    margin-top: -1.2em; 
}

.mark-down-hj > ol {
    list-style-type: lower-latin!important;
}

.mark-down-hj ul {
    font-weight: 300;
}

</style>
import { faTemperatureHigh } from '@fortawesome/free-solid-svg-icons'

const admin = {
    namespaced: true,
    state: {
        report_code: '',
        report_list: [],
        is_report_list_loaded: false,
    },
    mutations: {
        update_report_list(state, report_list){
            let temp_list = report_list
            state.report_list = temp_list
        },
        update_report_code(state, new_code){
            // console.log(new_code);
            state.report_code = new_code
        },
        update_report_list_loaded(state, flag){
            state.is_report_list_loaded = flag
        }
    },
    getters: {
        selected_report(state){
            let idx = state.report_list.findIndex(item => item.report_code == state.report_code)
            if (idx > -1){
                return state.report_list[idx]
            }
        },
        hashed_report_list(state){
            let temp_list = [ ...state.report_list]
            let temp_obj = {}
            for (let i=0; i<temp_list.length; i++){
                temp_obj[temp_list[i].report_code] = temp_list[i]
            }
            return temp_obj
        }
        
    },
    actions: {
        // 모든 리스트를 불러오기 => report_list.js와 읽어오는 데이터 다르므로 주의해야 합니다
        readAllReportListForAdmin({commit, rootState}){ 
            const path = rootState.backend_host + '/read_report_list_for_admin'

            return axios.get(path)
            .then( response => {
                // console.log(response.data)
                let report_list = response.data
                commit('update_report_list', report_list)
                commit('update_report_list_loaded', true)
            })

        },
        async check_valid_admin_right({ commit, rootState }) {
            try {
                const response = await axios.get(rootState.backend_host + "/check_valid_admin_right?access_token=" + rootState?.access_token.replaceAll("+", "%2B"))
                if(!response.data.code){
                    localStorage.clear();
                    sessionStorage.clear();
                    window.alert("어드민 로그인이 풀렸습니다. 다시 로그인해주세요")
                    location.href = "/";
                }
            } catch (e) {
                window.alert(e);
                localStorage.clear();
                sessionStorage.clear();
                location.href = "/";
            }
        },
        async check_valid_login_right({ commit, rootState }) {
            try {
                const response = await axios.get(rootState.backend_host + "/confirm_login_status?access_token=" + rootState?.access_token.replaceAll("+", "%2B"))
                if(!response.data.code){
                    localStorage.clear();
                    sessionStorage.clear();
                    window.alert("로그인이 풀렸습니다. 다시 로그인해주세요")
                    location.href = "/";
                }
            } catch (e) {
                window.alert(e);
                localStorage.clear();
                sessionStorage.clear();
                location.href = "/";
            }
        }
    }
}
export default admin

import store from "@/store/index.js";


/**
 * @description 사용자에게 결제창을 보여주기 앞서 포트원 서버에 미리 결제를 등록할 상품의 가격을 등록한다.
 * @param {string} merchantUid merchant_uid로 등록할 pk 값
 * @param {number} amount 가격
 * @param {object} payData {
        pg: "html5_inicis.INIpayTest", // 테스트
        pay_method: this.payOption,
        merchant_uid: this.muid,
        name: "QESG Reporting 결제", // 상품명
        amount: this.priceSum, // 결제 가격
        buyer_email: this.e_mail,
        buyer_name: this.user_name, //
        buyer_tel: this.tel,
        vbank_due: this.nextDay,
        // vbank_holder: "퀀티파이드이에스지",
        // buyer_addr: "구매자주소",
        // buyer_postcode: "구매자 우편번호",
    }
 * @returns Boolean
 */
export const preparePayment = async (merchantUid, amount, payData) => {
  const path = store.state.backend_host + "/payment/prepare";

  const sendData = {
    merchant_uid: merchantUid, // 가맹점 주문번호
    amount: amount, // 결제 예정금액
    payData: payData,
  };

  try {
    const response = await axios.post(path, sendData);
    return response.data.result;
  } catch (error) {
    console.log(result);
    // 오류 처리
    console.error(error);

    return error;
  }
};


export const formatCurrency = (amount) => {
  const formatter = new Intl.NumberFormat("ko-KR");
  return formatter.format(amount);
}

export const testPayment = () => {
};

export const generateUPK = async (request_type) => {
  try {
    const response = await axios.get(
      store.state.backend_host +
        "/payment/init_id?request_type=" +
        request_type
    );

    return response.data;
  } catch (error) {
    return error;
  }
};


export const addPaymethodToQesg = async (user_id, customer_uid) => {
  const path = store.state.backend_host + "/payment/addNewPayMethod";

  const sendData = {
    user_id,
    customer_uid
  };

  try {
    const response = await axios.post(path, sendData);

    return response.data.code;
  } catch (error) {
    // 오류 처리
    console.error(error);

    return error;
  }
  
}

export const regularPayment = async () => {};



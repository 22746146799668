<template>
<div>
    <h4>검증 신청</h4>
    <p class="f-95 mb-4 gray-600">제3자 검증을 신청합니다. </p>
    <template v-if="assurance_list_loaded==true">
        <template v-if="show_apply_estimate==true">
            <apply-estimate></apply-estimate>
        </template>
        <template v-else>
            <estimate-form></estimate-form>
        </template>

        <!-- 검증 신청하기 form 고정 -->
        <template v-if="assurance_list.length>0">
            <apply-form></apply-form>
        </template>
    </template>
    <template v-else>
        <b-card class="general-card mb-5">
            <b-card-header> <b-skeleton></b-skeleton> </b-card-header>
            <div class="py-3"> <b-skeleton v-for="x in 5" :key="x" class="mb-4"></b-skeleton> </div>
        </b-card>
    </template>
        
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import ApplyEstimate from '@/components/assurance/apply_assurance_subs/ApplyEstimate'
import ApplyForm from '@/components/assurance/apply_assurance_subs/ApplyForm'
import EstimateForm from '@/components/assurance/apply_assurance_subs/EstimateForm'


export default {
    data() {
        return {
        }
    },
    computed:{
        ...mapState('assurance', ['assurance_list', 'show_apply_estimate', 'assurance_list_loaded']),
    },
    methods:{
        ...mapMutations('assurance', ['update_show_apply_estimate']),

    },
    mounted(){
        if (this.assurance_list.length>0){
            this.update_show_apply_estimate(false)
        }
    },
    watch:{
        assurance_list_loaded: {
            handler(newV){
                if (newV==true){
                    // console.log(newV);
                    this.update_show_apply_estimate(false)
                }
            } 
        }
    },
    components:{
        ApplyForm,
        ApplyEstimate,
        EstimateForm
    },
    // mounted(){
    //     console.log(this.assurance_list);
    // }

}
</script>

<style>

</style>
<template>
    <div>
        <!-- 인쇄용 2줄 -->
        <div v-if="web_view!=true">
            <div class="d-lg-flex justify-content-between">
                <section v-for="topic_type in report_topic_types" class="content-section" style="min-width: 49%" :key="topic_type.id">
                    <!-- Universal Disclosures -->
                    <div v-if="topic_type.topic_type_e == 'Universal Disclosures'">
                        <div v-for="category in report_universal_categories" :key="category.category_id">
                            <div class="pb-1 my-2" :style="main_color"> GRI - {{ category.category_id }} {{lang!='ENG'? '일반 공개사항':'General Disclosures' }}</div>
                            <div v-for="subtopic, index in getUniqueSubtopicsByCategoryIds(category.category_id)" :key="subtopic.subtopic_code" class="pb-1 border-bottom"> {{ index+1 }}. {{ lang!='ENG'? subtopic.subtopic_name : subtopic.subtopic_name_e }}</div>
                            <hr class="border-0">
                        </div>
                    </div>
                    <!-- topic Specific Disclosures -->
                    <div v-else> 
                        <div v-for="category in report_topic_specific_categories" :key="category.category_id"> 
                            <div class="pb-1 my-2" :style="main_color"> GRI Topic - {{lang!='ENG'? category.category_name : category.category_name_e }}</div>
                            <div v-for="topic in getUniqueTopicsByCategoryIds(category.category_id)" :key="topic.id" class="pb-1 border-bottom">
                                {{ topic.topic_id }} : {{ lang=='ENG'? topic.topic_name_e : topic.topic_name }} <materiality-badge :topic_id="topic.topic_id"></materiality-badge>
                            </div>
                            <hr class="border-0">
                        </div>
                    </div>
                </section>
            </div>
            <div><b-badge variant="advance" class="ml-1"> M </b-badge> <span class="">: {{ lang!='ENG'? '중요주제 (Material Topic)':'Material Topic'}}</span></div>
        </div>
        
        <!-- 웹용 4줄 -->
        <b-container v-else class="px-xl-5" >
            <b-row>
                <!-- GRI 100 or 2 -->
                <b-col v-for="category in report_universal_categories" :key="category.id" class="d-flex flex-column align-items-center">
                    <b-button pill class="custom-btn-hover mb-2" :style="main_bg_color" @click="navAnchor('#rp'+category.category_id)">{{lang!='ENG'? category.category_name : category.category_name_e }}</b-button>
                    <template v-if="content_list_open==true">
                        <div v-for="subtopic, index in getUniqueSubtopicsByCategoryIds(category.category_id)" :key="subtopic.subtopic_id" class="p-1 f-95 text-left w-95 rounded" 
                        :class="preview_mode==true?'cursor hover-text-dark hover-transparent-dark':''" 
                        @click="navAnchor('#trp'+subtopic.subtopic_code)"> {{ index+1 }}. {{ lang!='ENG'? subtopic.subtopic_name : subtopic.subtopic_name_e }}
                        </div>
                    </template>
                </b-col>
                <!-- GRI 200~400 -->
                <b-col v-for="category in report_topic_specific_categories" :key="category.category_id" :class="category.category_id==400?'pr-0':'pr-3'" 
                class="d-flex flex-column align-items-center pb-45">
                    <b-button pill class="custom-btn-hover mb-2" :style="main_bg_color" @click="navAnchor('#rp'+category.category_id)">{{lang!='ENG'? category.category_name : category.category_name_e }}</b-button>
                    <template v-if="content_list_open==true">
                        <div v-for="topic in getUniqueTopicsByCategoryIds(category.category_id)" :key="topic.id" class="p-1 f-95 text-left w-95 rounded" 
                        :class="preview_mode==true?'cursor hover-text-dark hover-transparent-dark':''" 
                        @click="navAnchor('#trp'+topic.topic_id)">
                            {{ topic.topic_id }} : {{ lang!='ENG'? topic.topic_name : topic.topic_name_e }} <materiality-badge :topic_id="topic.topic_id"></materiality-badge>
                        </div>
                    </template>
                </b-col>
            </b-row>
            <div v-if="content_list_open==true" class="f-95"><b-badge class=""> M </b-badge> <span class="">: {{ lang!='ENG'? '중요주제 (Material Topic)':'Material Topic'}}</span></div>
        </b-container>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import * as ih from '@/components/util'
import MaterialityBadge from './MaterialityBadge'

export default {
    components:{
        MaterialityBadge,
    },
    data() {
        return {
            show_data_table: {},
            show_business_case: {},
            low_height: false,
        }
    },    
    props:{
        web_view: {type: Boolean, default: false},
        preview_mode: {type: Boolean, default: false},
        content_list_open: {type:Boolean, default: false }
    },
    computed:{
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapState('gri2021_report_view', ['report_structure', 'report_contents_loaded']),
        ...mapGetters('gri2021_report_view', ['report_topics', 'report_categories', 'report_topic_specific_categories', 'report_universal_categories', 'report_topic_types']),
        ...mapGetters('report_list', ['selected_report']),
        main_color(){
            return `color:`+this.selected_report.main_color
        },
        main_color_sub() {
            if (this.material_style_option === 'fill') {
                return `background-color: ${this.selected_report.main_color}; color: white; border: 1px solid white; `
            } else if (this.material_style_option === 'outline') {
                return `color:` + this.selected_report.main_color + `; border-top: 1px solid ` + this.selected_report.main_color + `!important` + `; border-bottom: 1px solid ` + this.selected_report.main_color
            } else {
                return `background-color: white; border-left: 3px solid ${this.selected_report.main_color}; border-bottom: 1px solid ${this.selected_report.sub_color};border-top: 0px; border-right: 0px;`;
            }
        },
        main_bg_color(){
            if(this.selected_report){
                return `background-color: `+ this.selected_report.main_color
            }
            else return `background-color: #494949`
        },
        
    },
    methods: {
        ...mapActions('gri2021_report_view', ['readReportContents']),
        getUniqueSubtopicsByCategoryIds(category_id){
        //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id == category_id )       
            let unique_subtopics = []
            filtered_structure.forEach( item => {
                if ( unique_subtopics.findIndex( x=> { return x.subtopic_code == item.subtopic_code }) < 0) {
                    unique_subtopics.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e, 
                        subtopic_code: item.subtopic_code, subtopic_name: item.subtopic_name, subtopic_name_e: item.subtopic_name_e })
                }
            })
            return unique_subtopics            
        },
        getUniqueTopicsByCategoryIds(category_id){
        //[{ category_id, category_name, category_name_e, topic_id, topic_name, topic_name_e }]
            let filtered_structure = this.report_structure.filter(item => item.category_id == category_id )       
            let unique_topics = []
            filtered_structure.forEach( item => {
                if ( unique_topics.findIndex( x=> { return x.topic_id == item.topic_id }) < 0) {
                    unique_topics.push({ category_id: item.category_id, category_name: item.category_name, category_name_e: item.category_name_e, 
                        topic_id: item.topic_id, topic_name: item.topic_name, topic_name_e: item.topic_name_e })
                }
            })
            return unique_topics
        },
        navAnchor(anchor){
            ih.anchorGo(anchor)
        },
    },
    created(){

    }
}
</script>
<style>
.slide-up-enter-active, .slide-up-leave-active{
     transition: all 0.3s ease;
}
.slide-up-enter-from,
.slide-up-leave-to {
  /* transform: translateY(10px); */
  opacity: 0;
}

.btn.custom-btn-hover{
    transition: all 0.15s ease;
    border: 0;
    width: 90%;
}
.custom-btn-hover:hover{
    opacity: 0.8;
    width: 96%;
}
.content-section-compact {
    min-width: 24%;
    width: 33%;
}
.content-section-compact-sub {
    min-width: 33%;
    width: 33%;
}
@media (max-width: 992px) {
    .btn.custom-btn-hover{
        width: 100%;
    }

    .content-section-compact,
    .content-section-compact-sub {
        width: auto;
    }
}
</style>
<!-- src/components/MyChart.vue -->

<template>
	<div>
<!-- <pre>
{{  chartData }}
</pre> -->
<!-- {{ selected_chart_id }}
{{ editing_mode }} -->
		<div class="d-flex align-items-center justify-content-end mb-3">
			<div>
				<b-button v-show="chartData" v-b-toggle.collapse-chart-sample variant="teal" class="mr-2" @click="viewSample = !viewSample">
					<b-icon-bar-chart-fill></b-icon-bar-chart-fill>
					샘플 보기
				</b-button>

				<b-button variant="primary" @click="initChart" class="mr-2" :disabled="!chartData">
					<b-icon-arrow-clockwise></b-icon-arrow-clockwise>
					리셋하기
				</b-button>

				
			</div>
		</div>
		<template v-if="chartData">
			<b-collapse id="collapse-chart-sample" class="mt-2 mb-3">
				<b-card>
					<p class="card-text">샘플을 적용해보세요</p>
					<b-button v-for="(chart_data, idx) in data_sample_list" :key="idx" size="sm" class="mr-2 mb-2"
						@click="applySample(idx)"
					>
						{{ chart_data.label }}
					</b-button>
				</b-card>
			</b-collapse>
		</template>
	
		<template v-if="!chartData">
			<b-card header="차트 생성/편집">
				<div class="text-center p-3">
					<b-btn variant="primary" class="mr-3" @click="initChart">신규 생성하기</b-btn>
					<b-btn variant="teal" v-show="!chartData" @click="viewSample = !viewSample">샘플로 시작하기 </b-btn>
				</div>

				<template v-if="!chartData">
					<b-collapse v-model="viewSample" id="collapse-chart-sample" class="mt-3">
						<b-card>
							<p class="card-text text-center fw-500">샘플을 적용해보세요!</p>
							<b-button v-for="(chart_data, idx) in data_sample_list" :key="`chart-sample-${idx}`"
								size="sm"
								class="mr-2 mb-2"
								@click="applySample(idx)"
								>
								{{ chart_data.label }}
							</b-button>
						</b-card>
					</b-collapse>
				</template>
			</b-card>
		</template>

		<template v-else>
			<canvas :style="'max-height: ' + chartData?.options?.chart_height + 'px;'" ref="myChart" class="border border-light shadow-sm rounded"></canvas>
			<small v-if="chartData && chartData.options" class="text-muted p-4">
				{{chartData.options.footnote}}
			</small>

			<b-card no-body class="mt-3">
				<b-tabs card>
					<b-tab title="차트 데이터 편집" active>
						<b-row>
							<b-col cols="12">
								<EditChartDataTable
									:labels="chartData.data.labels"
									:datasets="chartData.data.datasets"
									@updateChartData="updateChartData"
									/>
							</b-col>
						</b-row>
					</b-tab>

					<b-tab title="차트 편집" active>
						<b-row>
							<b-col cols="12">
								<EditChartGlobalConfig
									:labels="chartData.data.labels"
									:options="{ ...chartData.options }"
									:datasets="[ ...chartData.data.datasets ]"
									:usable_title="usable_title"
									@updateChartData="updateChartData"
									@updateChartOption="updateChartOption"
									@checkDuplicatedTitle="checkDuplicatedTitle"	
								/>
							</b-col>
						</b-row>
					</b-tab>

					<b-tab title="차트 세부 설정">
						<EditChartLocalConfig
							:datasets="[ ...chartData.data.datasets ]"
							:options="{ ...chartData.options }"
							:labels="{ ...chartData.data.labels }"
							:formData="chartData.formData"
							@updateChart="updateChart"
							@updateChartDataSets="updateChartDataSets"
							/>
					</b-tab>
				</b-tabs>
			</b-card>
		</template>

		<div class="pt-5 pb-3">
			<b-button
			variant="teal"
			class="mr-3"
			@click="
				handleReInitiate
			"
			:disabled="re_initiating === true"
			>
			번역 초기화
			<fa
				icon="redo"
				:class="re_initiating == true ? 'spinning-icon' : ''"
			/></b-button>
			
			<b-button 
				v-if="chart"
				class="mr-3"
				variant="red" 
				@click="saveChart" 
			>
				저장하기
			</b-button>

			<b-button
			@click="
				closeEditChartModal
			"
			>
			취소
			</b-button>
			<template v-if="re_initiating">
				<div class="f-90 blue">
					<b-spinner class="blue" small></b-spinner> 저장 중
				</div>
            </template>
		</div>



	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Chart from "chart.js/auto"; // 최신 버전의 Chart.js를 사용하려면 'chart.js/auto'를 사용합니다.
import { data_skelton_single } from '@/util/chart/chart.utils.js';
import { data_sample_list } from '@/util/chart/chart.sample.skelton.js';
import EditChartDataTable from '@/components/writing_report/chart_subs/EditChartDataTable.vue';
import EditChartGlobalConfig from './EditChartGlobalConfig.vue';
import EditChartLocalConfig from './EditChartLocalConfig.vue';
import { multiChart } from './chartSample';


export default {
	name: 'DefaultTranslateChart',
	components: {
		EditChartDataTable,
		EditChartGlobalConfig,
		EditChartLocalConfig
	},
	props: {
		editing_mode: { type: Boolean, default: () => false },
        lang: { type: String, default: () => 'KOR'},

	},
	computed: {
		...mapState("report_list", ["report_code"]),
		...mapState('charts', ['chart_data', 'chart_field', 'selected_chart_id', 'selected_chart_data']),
	},
	watch: {
		'chartData.options.chart_height': function (newVal, oldVal) {
		this.$nextTick(() => {
			this.renderChart();
		});
		}
	},
	data() {
		return {
			multiChart,
			data_sample_list,
			chart: null,
			chartData: null,
			initialData: { ...data_skelton_single },
			viewSample: true,
			usable_title: false,
			re_initiating: false,
		};
	},
	mounted() {
		if (this.selected_chart_data !== null) {
			this.initialData = { ...this.selected_chart_data }
			this.initChart();
		}
	},
	// mounted() {
	// 	if (this.chartData) {
	// 		this.renderChart();
	// 	}
	// },
	beforeDestroy() {
		if (this.chart) {
			this.chart.destroy();
			this.chart = null
		}

		this.chartData = null
	},
	methods: {
        ...mapActions("charts", ["saveAddChart", "updateDCIndicator", "reinitiateChartTranslate"]),
		initChart() {
			this.chartData = { ...this.initialData }
			this.$nextTick(() => {
				this.renderChart()
			})
		},
		updateChartOption(options) {
			this.chartData.options = { ...this.chartData.options, ...options }
			this.chartData = {
				...this.chartData
			}
			

			this.chartData.label = this.chartData.options.plugins.title.text;

			
			this.chart.options = this.chartData.options;
			this.chart.update();
		},
		updateChartDataSets (_datasets) {
			this.chartData.data.datasets = _datasets

			
			this.chart.data.datasets = this.chartData.data.datasets;
			this.chart.update()
		},
		updateChartData ({ labels, datasets }) {
			this.chartData.data.labels = labels
			this.chartData.data.datasets = datasets
			this.chart.update()
		},
		updateChart({ data, label, options }) {
			if (this.chart) {
				this.chart.destroy()
			}

			this.chartData.data = { ...this.chartData.data, ...data }
			this.chartData.options = { ...this.chartData.options, ...options }

			
			const ctx = this.$refs.myChart.getContext("2d");
			this.chart = new Chart(ctx, this.chartData)
		},
		renderChart() {

			this.checkDuplicatedTitle(this.chartData.options.plugins.title.text);

			this.chartData.plugins = [
				{
					id: 'customCanvasBackgroundColor',
					beforeDraw: (chart, args, options) => {
						const { ctx } = chart;
						ctx.save();
						ctx.globalCompositeOperation = 'destination-over';
						ctx.fillStyle = options.color || 'white';
						ctx.fillRect(0, 0, chart.width, chart.height);
						ctx.restore();
					}
				}
			]
			// this.chartData.plugins = {
			// 		id: 'customCanvasBackgroundColor',
			// 		beforeDraw: (chart, args, options) => {
			// 			const { ctx } = chart;
			// 			ctx.save();
			// 			ctx.globalCompositeOperation = 'destination-over';
			// 			ctx.fillStyle = options.color || 'white';
			// 			ctx.fillRect(0, 0, chart.width, chart.height);
			// 			ctx.restore();
			// 		}
			// }
			
			this.chartData.options.plugins['tooltip'] = {
              intersect: true,
              callbacks: {
                label: (context) => {
                  // return `${context.dataset.label}: ${context.parsed} (단위: ${context.dataset?.y_level})`;
                  return context.parsed.y !== undefined ? `${context.dataset.label}: ${context.parsed.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (단위: ${context.dataset?.unit})` : `${context.dataset.label}: ${context.parsed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (단위: ${context.dataset?.unit})`;
                }
              }
            }

			
			if (this.chart) {
				this.chart.destroy();
			}

			const ctx = this.$refs.myChart.getContext("2d");
			this.chart = new Chart(ctx, this.chartData)
			
		},
		resetChart () {
			console.log(`resetChart ~ `); 
			

		},
		applySample(idx) {
			this.chartData = Object.assign({}, this.chartData, 
				this.data_sample_list[idx]
			)

			this.$nextTick(() => {
				this.renderChart()
			})
		},
		closeEditChartModal() {
			if (this.editing_mode) {
				this.$emit("flag_modal_mode", "chart_list");
				
			} else {
				this.$emit("closeEditChartModal")

			}
			
		},

		async saveChart(payload) {
			this.re_initiating = true;
			if (this.usable_title !== true && this.selected_chart_id === null) {
				this.usable_title = false;
				window.alert("기존 차트들과 차트 제목이 중복되지 않게 수정해주세요")
				return
			} else {
				await this.saveAddChart({
					report_code: this.report_code, 
					payload: this.chartData,
					cid: this.selected_chart_id,
					lang: this.lang
				})

				this.$emit("initChart")
				this.closeEditChartModal();

				
			}

			this.re_initiating = false;
		},
		async checkDuplicatedTitle(input_title) {
			// 타이틀 여부 확인
            const response = await axios.get(`${this.$store.state.backend_host}/checkDuplicatedChartTitle?rc=${this.report_code}&title=${input_title}&cid=${this.selected_chart_id}`);
            if (response.data.result && input_title.length > 0) {
                // 타이틀 사용가능
                this.usable_title = true;
                setTimeout(() => {
                    this.usable_title = null;
                }, 5000)

            } else {
                // 타이틀 사용불가
                this.usable_title = false;
            }
		},

		async handleReInitiate() {
			this.re_initiating = true
			await this.reinitiateChartTranslate({
				"chart_id": this.selected_chart_id,
				"re_initiate": true
			})

			this.$emit("initChart")
			this.closeEditChartModal();

			this.re_initiating = false
			
		}
	}
};
</script>

<style lang="scss">
.btn.is-active {
    background-color: rgba(0,0,0,.1);
}
</style>
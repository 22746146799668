<template>
  <div>
    <b-row>
      <b-col cols="2">
        <side-nav-btn
          class="sticky-top pt-4"
        ></side-nav-btn>
      </b-col>
      <b-col class="pt-4">
        <template v-if="gri_ver == 'GRIv2016'">
          <gri100-indicator></gri100-indicator>
          <gri200to400-indicator></gri200to400-indicator>
        </template>
        <template v-else-if="gri_ver == 'GRIv2021'">
          <gri2to3-list class="mb-5 pb-5"></gri2to3-list>
          <gri200to300-list></gri200to300-list>
        </template>
      </b-col>
    </b-row>
    <hr class="border-0" />
  </div>
</template>

<script>
import SideNavBtn from "@/components/managing_indicators/SideNavBtn";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import * as ih from "@/components/util";
import Gri100Indicator from "@/components/managing_indicators/indicator2016_components/Gri100Indicator";
import Gri200to400Indicator from "@/components/managing_indicators/indicator2016_components/Gri200to400Indicator";
import Gri2to3List from "@/components/managing_indicators/gri2021_indicators/gri2to3List.vue";
import Gri200to300List from "@/components/managing_indicators/gri2021_indicators/gri200to300List.vue";

export default {
  data() {
    return {};
  },
  props: {
    is_recommended: { type: Boolean, default: false }, // 추천받아서 온 건지, 추천없이 바로 선택하기로 온 건지 확인
  },
  computed: {
    ...mapState("report_indicators", ["indicators_list", "topics_list"]),
    ...mapState("report_list", ["report_code", "standard_mode"]),
    ...mapGetters("report_list", ["selected_report", "gri_ver", "tcfd_ver"]),
  },
  methods: {
    ...mapActions("report_indicators", [
      "readReportTopics",
      "readReportIndicators",
    ]),
    // ...mapMutations('report_indicators', ['update_report_code']),
  },
  components: {
    SideNavBtn,
    Gri100Indicator,
    Gri200to400Indicator,
    Gri2to3List,
    Gri200to300List,
  },
  created() {},
};
</script>

<style></style>

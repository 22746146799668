<template>
<div>
    <b-container class="px-5 py-5 bg-white"> 
            <gri-table v-if="type==='gri'" :modal_table="false"></gri-table>
            <sasb-table v-if="type==='sasb'" :modal_table="false"></sasb-table>
            <tcfd-table v-if="type==='tcfd'" :modal_table="false"></tcfd-table>
            <hr class="border-0 my-5 py-5">
        </b-container>    
        <hr class="border-0 m-0 py-5">

    <div class="px-4 px-lg-5 py-5 gray-550 f-90 d-flex justify-content-between">
        <div class="tail-line" style="width: 100%">{{ lang!='ENG'? selected_report.report_name: selected_report.report_name_e }} </div>
        <div class="ml-3">{{ lang!='ENG'? selected_report.organization_name : selected_report.organization_name_e }}</div>
    </div>
</div>
</template>
    
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import * as ih from '@/components/util'
import DataTable from './viewer_components/DataTable.vue'
import Document from './viewer_components/Document.vue'
import BusinessCase from './viewer_components/BusinessCase.vue'
import GriTable from './viewer_components/GRITable2021'
import SASBTable from '@/components/report_preview/gri2021_viewer/viewer_components/SASBTable'
import TCFDTable from '@/components/report_preview/gri2021_viewer/viewer_components/TCFDTable'


export default {
    components:{
        DataTable,
        Document,
        BusinessCase,
        "gri-table": GriTable,
        "sasb-table": SASBTable,
        "tcfd-table": TCFDTable
    },
    data() {
        return {
            show_data_table: {},
            show_business_case: {},
            low_height: false,
            type: "gri"
        }
    },    
    props:{
        web_view: {type: Boolean, default: false},
        reload: {type:Boolean, default: false}
    },
    computed:{
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapState('gri2021_report_view', ['report_structure', 'report_contents_loaded']),
        ...mapGetters('gri2021_report_view', ['report_topics', 'report_categories', 'report_topic_specific_categories', 'report_universal_categories', 'report_topic_types']),
        ...mapGetters('report_list', ['selected_report']),
        main_color(){
            return `color:`+this.selected_report.main_color
        },
        main_color_sub(){
            return `color:`+this.selected_report.main_color+`; border-top: 1px solid `+this.selected_report.main_color+`!important`+`; border-bottom: 1px solid `+this.selected_report.main_color
        },
    },
    methods: {
        ...mapMutations('gri2021_report_view', ['update_report_contents_loaded']),
        ...mapActions('gri2021_report_view', ['readReportContents']),
        ...mapActions('data_tables', ['readDataTableList']),
        ...mapActions('business_cases', ['readBusinessCaseList']),
        ...mapActions('documents', ['readDocumentList']),
        check_type() {

            if (location.pathname.includes('/g')) {
                this.type = 'gri';
            } else if (location.pathname.includes('/s')) {
                this.type = 'sasb';
            } else if (location.pathname.includes('/t')) {
                this.type = 'tcfd';
            }
            
        }
    },
    created() {
        this.check_type();
        if(this.reload==true){
            this.update_report_contents_loaded(false)
        }

        if(this.report_contents_loaded != true){
            // console.log('GRI_TABLE_VIEW_LOAD')
            this.readReportContents({report_code : this.report_code})
            this.readDataTableList({report_code: this.report_code})
            this.readBusinessCaseList({report_code: this.report_code})
            this.readDocumentList({report_code: this.report_code})
        }
    }
}
</script>
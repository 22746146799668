<template>
<div>
    <b-modal 
        :id="`open-how-gri-material-should-${this.gri_code}`"
        size="lg" 
        scrollable 
        hide-footer
        title="GRI에 따른 보고(in accordance with)란?"
    >   
        <GRIAccordanceNotice />
    </b-modal>
    <b-modal 
        :id="`reference-notice${this.gri_code}`" 
        hide-footer
        
        size="" 
        title="안내" 
        scrollable
        no-close-on-backdrop
        hide-header-close
    >
      <!-- <h6> 플랫폼에서 업데이트된 사항을 확인해보세요!</h6> -->
      <div class="mb-3">
        중요주제에 대해 3-3 보고를 진행하지 않을 경우, <br>
        GRI에 따른 보고(in accordance with)를 미충족하여 <br>
        GRI를 참조한 보고(with reference to)로 보고 됩니다. <br><br>
        
        3-3보고 선택을 해제하시겠습니까? <br>
        
        <a 
            href="#"
            @click="openHowGriMaterialShould"
        >
            <fa icon="info-circle" class="mr-1"/>GRI에 따른 보고(in accordance with)란?
            <fa icon="expand" class="ml-1"/> 
        </a>


      
      </div>
      <b-button @click="deselectSeletecIndicator">취소하기</b-button>
      <b-button variant="teal" @click="closeReferenceNoticeModal" class="float-right">선택 해제하기</b-button>
    </b-modal>

    <template v-if="is_indicator_selected_loaded==true">
        <b-form-checkbox v-if="!saving" v-model="indi_selected" @change="updateIndicatorSelected">
            <b-icon v-if="show_completed" icon="check" scale="1.5" variant="success" animation="fade" />
            <span v-else>
                {{indi_selected===true?"선택":"선택안함"}}
            </span>


            
        </b-form-checkbox>
        
        <span v-else>
            <b-icon icon="circle-fill" animation="throb" font-scale="1" />
            saving..
        </span>

        <span v-if="failedToSave">
            <b-icon icon="x" scale="1.5" variant="danger" animation="fade" />저장 실패
        </span>

    </template>    
    <template v-else>
        <b-spinner small type="grow"></b-spinner>
    </template>
</div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
import SideMaterialCheckVue from '@/components/managing_indicators/gri2021_indicators/SideMaterialCheck.vue'
import GRIAccordanceNotice from '@/components/managing_indicators/gri2021_indicators/GRIAccordanceNotice.vue'


export default {
    components: {
        SideMaterialCheckVue,
        GRIAccordanceNotice
    },
    data() {
        return {
            indi_selected : false,
            is_indicator_selected_loaded: false,
            is_indicator_selecting: false,
            is_selected_all: false,

            saving: false,
            show_completed: false,

            failedToSave: false,

        }
    },
    props:{
        gri_code: {type: String},
        topic_id: { type: Number },
        originalTopicSelected: { type: Boolean },

        originalMaterialSelected: {type: Boolean},
        selectedTopicId: { type: Number },
        targetIndicator: { type: Object },
        targetMaterialIndicator: { type: Object}
    },
    created(){
        this.checkReportIndicatorsSelected()
    },
    computed: {
        
        ...mapState('report_list', ['report_code', 'standard_mode']),
        ...mapGetters('report_list', ['selected_report', 'gri_ver', 'tcfd_ver']),
        ...mapState('gri2021_indicators', ['indicator_payloads', 'changable_gri_code', 'changable_gri_topics'])
        
    },
    methods: {
        ...mapActions('report_list', ['saveReportPreference']),
        ...mapMutations('report_indicators', ['update_report_indicator_relation']),
        ...mapMutations('gri2021_indicators', ['upload_indicator_payload', 'change_topic_select', 
        'upload_topic_payload', 'change_material_select', 'change_material_select_true']),
        checkReportIndicatorsSelected() {
            const path = this.$store.state.backend_host + '/check_report_indicators_selected'

            return axios.get(path, { params: {
                report_code: this.report_code,
                gri_codes: this.gri_code
            }})
            .then( response => {
                // console.log(response.data);
                if(response.data.code=='success'&&response.data.is_selected==true){
                    this.indi_selected = true
                }
                if (response.data.code=='success'){
                    let result = response.data
                    let new_obj = {}
                    new_obj[result.gri_code] = result.is_selected

                    this.upload_indicator_payload(new_obj)
                }
                this.is_indicator_selected_loaded = true
            })
        },
        deselectSeletecIndicator() {
            this.indi_selected = true;
            this.$bvModal.hide("reference-notice"+this.gri_code)

        },
        closeReferenceNoticeModal() {
            // axios

            this.$bvModal.hide("reference-notice" + this.gri_code);
            this.checkValidTopicSelected();
            this.uploadSelectedIndicator();


        },
        checkValidTopicSelected() {
            const input_list = document.querySelectorAll(`.gri_topic_${this.topic_id} input`);
            let temp_checked = false;
            input_list.forEach((ele, key) => {
                if (key > 1) {
                    if (ele.checked) {
                        temp_checked = true
                    }
                    
                }
            })



            if (!temp_checked && this.originalTopicSelected) {
                // indicator가 하나도 선택되지 않고 + topic selector는 true인 경우,

                // originalTopicSelected를 False로 만들어야 함.
                this.$emit('changeTopicFromIndicator', this.topic_id, false)
                this.$emit('changeMaterialFromElse', this.topic_id, false)

            }

            if (temp_checked && !this.originalTopicSelected) {
                // indicator가 선택되어 있는데 topic은 선택되어 있지 않은 경우
                
                this.$emit('changeTopicFromIndicator', this.topic_id, true)

            }


            
        },
        updateIndicatorSelected(){
            //indicator는 3-3만 material 영향 주면 됨

            // 3-3인 경우 modal을 띄워 한번 더 확인
            if (this.gri_code.includes('3-3_') && this.indi_selected === false && this.originalMaterialSelected) {
                this.$bvModal.show("reference-notice"+this.gri_code)
                return;   
            }

            // topic indicator 확인
            this.checkValidTopicSelected();


            this.uploadSelectedIndicator();


            // this.is_indicator_selecting = true
            // this.$emit('changeOriginalIndicator', this.indi_selected, this.gri_code)

            
            // let temp_obj = {}
            // // if(this.indi_selected==true&&this.gri_code.includes('3-3_')==false){
            // //     // topic id 앞에 3-3인 것을 true로 바꾼 경우 material selected도 true 만들기
            // //     this.change_topic_select(this.topic_id)
            // // }
            // temp_obj[this.gri_code] = this.indi_selected
            // this.upload_indicator_payload(temp_obj)
            // let temp_arr = [...this.indicator_payloads]
            // // 안쓰임
            // // let idx = temp_arr.findIndex(z => {return Object.keys(z) == this.gri_code})
            // let isTopicSelectedAll = true

            // temp_arr.map((item) => {
            //     if (Object.keys(item)[0].includes(this.topic_id) && !item[Object.keys(item)[0]] || !this.indi_selected) {
            //         isTopicSelectedAll = false
            //     } 
            // })
            // this.$emit('changeOriginalTopic', isTopicSelectedAll, this.topic_id)


            // let new_arr = []
            // let new_obj = {}
            // if(this.gri_code.includes('3-3_')===true){
            //     if (!this.indi_selected) {
            //         this.$emit('changeOriginalMaterial', this.indi_selected, this.topic_id)
            //         new_arr = [isTopicSelectedAll, false]
            //     } else {
            //         new_arr = [isTopicSelectedAll, true]
            //     }

            // }else new_arr = [isTopicSelectedAll, false]
            // new_obj[this.topic_id] = new_arr

            // this.upload_topic_payload(new_obj)
            // this.is_indicator_selecting = false


        },
        async uploadSelectedIndicator() {
            try {
                this.saving = true;
                const path = this.$store.state.backend_host + "/link_report_indicators";
                const formData = new FormData()
                formData.append('report_code', this.report_code)
                formData.append('link_payload', JSON.stringify({
                    [this.gri_code]: this.indi_selected
                }))
    
                const response = await axios.post(path, formData);
                if (response.status === 200) {
                    this.show_completed = true;

                    // this.upload_indicator_payload({
                    //     [this.gri_code]: this.indi_selected
                    // })
                    this.update_report_indicator_relation({
                        report_code: response.data.report_code,
                        updated_relation: response.data.updated_relation
                    })



                    setTimeout(() => {
                        this.show_completed = false;
                    }, 1500);
                    
                } else {
                    this.failedToSave = true;
                    setTimeout(() => {
                        this.failedToSave = false;
                    }, 2000);

                }
                
                this.saving = false;

                // // 좋은 방법은 아닌데 일단 급한대로 추가
                // // TODO: 적절한 위치에 다시 옮기기
                // await this.saveReportPreference({report_code: this.report_code, state: 2})
                
            } catch (error) {
                this.saving = false;
                this.failedToSave = true;
                setTimeout(() => {
                    this.failedToSave = false;
                }, 2000);
            }


        },
        openHowGriMaterialShould() {

            this.$bvModal.show(`open-how-gri-material-should-${this.gri_code}`);
        }

    },
    watch: {
        targetIndicator: {
            handler(newVal) {
                if (newVal !== null && newVal.topic_id === this.topic_id) {
                    this.indi_selected = newVal.topic_value
                    this.uploadSelectedIndicator();
                }

            }
        },
        targetMaterialIndicator: {
            handler(newVal) {
                if (newVal !== null && newVal.gri_code === this.gri_code) {
                    this.indi_selected = newVal.value
                    this.uploadSelectedIndicator();
                }

            }
        }
        // changable_gri_code:{
        //     handler(newVal){
        //         // console.log(newVal);
        //         if(newVal == this.gri_code){
        //             this.indi_selected=true
        //         }
        //     },
        //     immediate: true
        // },
        // changable_gri_topics:{
        //     handler(newVal){
        //         // console.log(newVal);
        //         if (newVal == this.topic_id) {
        //             this.indi_selected=false
        //         }
        //     },
        //     immediate: true
        // }
    }
}
</script>

<style>

</style>
import store from "@/store/index.js";
import crypto from 'crypto';


class ChannelService {
  constructor() {
    if (process.env.VUE_APP_REPORT_CODE) return;

    this.loadScript();
  }

  loadScript() {
    (function () {
      var w = window;
      if (w.ChannelIO) {
        return w.console.error("ChannelIO script included twice.");
      }
      var ch = function () {
        ch.c(arguments);
      };
      ch.q = [];
      ch.c = function (args) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;
      function l() {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
        var x = document.getElementsByTagName("script")[0];
        if (x.parentNode) {
          x.parentNode.insertBefore(s, x);
        }
      }
      if (document.readyState === "complete") {
        l();
      } else {
        w.addEventListener("DOMContentLoaded", l);
        w.addEventListener("load", l);
      }
    })();
  }


  boot(option, callback) {
    if (process.env.VUE_APP_REPORT_CODE) return;

    if (!option) {
      option = {}
    }

    if (localStorage["e_mail"] !== undefined) {
      const secretKey = process.env.VUE_APP_CHANNELTALK_MEMBER_HASH_SECRET;
      const hash = crypto.createHmac('sha256', Buffer.from(secretKey, 'hex'))
                  .update(localStorage["e_mail"])
        .digest('hex');
      
      option['memberId'] = localStorage["e_mail"]
      option['memberHash'] = hash
      
      option["profile"] = {
        // id: store.state.user_id,
        email: store.state.e_mail,
        name: store.state.user_name,
        mobileNumber: store.state.tel,
      }
    }

    
    window.ChannelIO(
      "boot",
      {
        ...option,
        pluginKey: process.env.VUE_APP_CHANNELTALK_PLUGIN_KEY,
      },
      () => {
        if (typeof callback === "function") {
          callback();
        }
      }
    );
  }

  shutdown() {
    window.ChannelIO("shutdown");
  }

  showMessenger() {
    window.ChannelIO("showMessenger");
  }

  hideMessenger() {
    window.ChannelIO("hideMessenger");
  }

  openChat(chatId, message) {
    window.ChannelIO("openChat", chatId, message);
  }

  track(eventName, eventProperty) {
    window.ChannelIO("track", eventName, eventProperty);
  }

  onShowMessenger(callback) {
    window.ChannelIO("onShowMessenger", () => {
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  onHideMessenger(callback) {
    window.ChannelIO("onHideMessenger", () => {
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  onBadgeChanged(callback) {
    window.ChannelIO("onBadgeChanged", () => {
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  onChatCreated(callback) {
    window.ChannelIO("onChatCreated", () => {
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  onFollowUpChanged(callback) {
    window.ChannelIO("onFollowUpChanged", () => {
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  onUrlClicked(callback) {
    window.ChannelIO("onUrlClicked", () => {
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  clearCallbacks() {
    window.ChannelIO("clearCallbacks");
  }

  updateUser(userInfo, callback) {
    if (!userInfo) {
      userInfo = {}
    }
    window.ChannelIO("updateUser", {
      ...userInfo,

      profile: {
        email: store.state.e_mail,
        name: store.state.user_name,
        mobileNumber: store.state.tel,
      },
      
    }, () => {
      if (typeof callback === "function") {
        callback();
      }
    });

  }

  addTags(tags, callback) {
    window.ChannelIO("addTags", tags, () => {
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  removeTags(tags, callback) {
    window.ChannelIO("removeTags", tags, () => {
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  setPage(page) {
    window.ChannelIO("setPage", page);
  }

  resetPage() {
    window.ChannelIO("resetPage");
  }

  showChannelButton() {
    window.ChannelIO("showChannelButton");
  }

  hideChannelButton() {
    window.ChannelIO("hideChannelButton");
    this.hideMessenger();
  }

  setAppearance(appearance) {
    window.ChannelIO("setAppearance", appearance);
  }
}

export default new ChannelService();

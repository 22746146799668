//"선택"한 보고서가 가지고 있는 비즈니스 사례 리스트와, 
//각 비즈니스 사례의 내용 해시

const business_cases = {
    namespaced: true, 
    state: {
        business_case_list:[
            // {
            //     "index": 0,
            //     "business_case_id": 3,
            //     "report_code": "r11",
            //     "title": "",
            //     "description": "",
            //     "time_stamp": "2020-08-13 17:37:25",
            //     "used_codes": [
            //         "404-1"
            //     ],
            //     "files": [
            //         {
            //             "index": 0,
            //             "file_id": 1,
            //             "business_case_id": 3,
            //             "type": "file",
            //             "file_name": "Welcome to Show.show",
            //             "file_path": "C:\\Inetpub\\gri_backend\\app\\../uploads\\business_cases\\3",
            //             "time_stamp": "2020-08-13 17:38:44"
            //         }
            //     ],
            //     "images": [
            //         {
            //             "index": 0,
            //             "images_id": 1,
            //             "business_case_id": 3,
            //             "file_name": "231663_1244100169_large.jpg",
            //             "file_path": "C:\\Inetpub\\gri_backend\\app\\../uploads\\business_cases\\3",
            //             "time_stamp": "2020-08-13 17:48:12"
            //         },
            //         {
            //             "index": 1,
            //             "images_id": 2,
            //             "business_case_id": 3,
            //             "file_name": "1402725_55047002.jpg",
            //             "file_path": "C:\\Inetpub\\gri_backend\\app\\../uploads\\business_cases\\3",
            //             "time_stamp": "2020-08-13 17:50:10"
            //         }
            //     ]
            // }
        ],
        business_case_loaded: false
    },
    mutations: {
        update_business_case_list(state, new_business_case_list){
            state.business_case_list = new_business_case_list
        },
        add_or_update_business_case(state, new_business_case_obj ){
            let temp_business_case_list = [ ...state.business_case_list ]
            let idx = temp_business_case_list.findIndex(item => item.business_case_id == new_business_case_obj.business_case_id)
            if(idx > -1){
                let temp_business_case_obj = { ...new_business_case_obj }
                temp_business_case_obj.used_codes = temp_business_case_list[idx].used_codes
                temp_business_case_list.splice(idx, 1, temp_business_case_obj)
            }
            else{
                new_business_case_obj.used_codes = []
                temp_business_case_list.push(new_business_case_obj)
            }
            state.business_case_list = temp_business_case_list            
        },
        update_business_case_e(state, new_translated_obj){
            let temp_bc_list = [...state.business_case_list]
            let idx = temp_bc_list.findIndex(item => item.business_case_id == new_translated_obj.business_case_id)
            if(idx > -1){
                temp_bc_list[idx].title_em = new_translated_obj.title_em,
                temp_bc_list[idx].title_ea = new_translated_obj.title_ea,
                temp_bc_list[idx].description_em = new_translated_obj.description_em,
                temp_bc_list[idx].description_ea = new_translated_obj.description_ea,
                temp_bc_list[idx].files_e = new_translated_obj.files_e,
                temp_bc_list[idx].images_e = new_translated_obj.images_e
            }
            state.business_case_list = temp_bc_list
        },
        update_business_case_relation(state, gri_codes_by_business_case_id){
            let temp_business_case_list = [ ...state.business_case_list ]
            temp_business_case_list.forEach( business_case => {
                if(gri_codes_by_business_case_id.hasOwnProperty(business_case.business_case_id)){
                    business_case['used_codes'] = gri_codes_by_business_case_id[business_case.business_case_id]
                }
            })
            //State 인식
            state.business_case_list = temp_business_case_list
        },
        delete_file_from_business_case(state, {del_file_id, del_business_case_id}){
            let temp_business_case_list = [...state.business_case_list]
            let idx = temp_business_case_list.findIndex(item => item.business_case_id == del_business_case_id)
            if(idx > -1){
                let jdx = temp_business_case_list[idx].files.findIndex(x => x.file_id == del_file_id)
                if (jdx >-1){
                    temp_business_case_list[idx].files.splice(jdx, 1)
                }
            }
            state.business_case_list = temp_business_case_list
        },
        update_delete_business_case(state, deleted_business_case_id){
            let temp_business_case_list = [...state.business_case_list]
            let idx = temp_business_case_list.findIndex(x => x.business_case_id == deleted_business_case_id)
            if (idx > -1){
                temp_business_case_list.splice(idx, 1)
            }
            state.business_case_list = temp_business_case_list
        },
        update_business_case_loaded(state,flag){
            state.business_case_loaded = flag
        }
    },
    getters: {
        hashed_business_cases(state){
            let temp_bc_list = [...state.business_case_list]
            let temp_bc_obj = {}
            for (let i=0; i<temp_bc_list.length; i++){
                let bc_id = temp_bc_list[i].business_case_id
                let bc_item = {...temp_bc_list[i]}
                if(temp_bc_list[i].title_em!=''&&temp_bc_list[i].title_em!=null){
                    bc_item['title_e'] = temp_bc_list[i].title_em
                }else bc_item['title_e'] = temp_bc_list[i].title_ea
                if(temp_bc_list[i].description_em!=''&&temp_bc_list[i].description_em!=null){
                    bc_item['description_e'] = temp_bc_list[i].description_em
                }else bc_item['description_e'] = temp_bc_list[i].description_ea
                temp_bc_obj[bc_id] = bc_item
            }
            return temp_bc_obj
        },
        business_case_used_codes(state){
            let temp_list = []
            let bc_arr = [...state.business_case_list]
            for (let i=0; i<bc_arr.length; i++){
                bc_arr[i].used_codes.forEach(x =>{
                    temp_list.push(x)
                })
            }
            let temp_list_a = [...new Set(temp_list)]
            return temp_list_a
        },
        business_case_list_e(state){
            let temp_bc_list = [...state.business_case_list]
            for (let i=0; i<temp_bc_list.length; i++){
                let bc_item = {...temp_bc_list[i]}
                if(temp_bc_list[i].title_em!=''&&temp_bc_list[i].title_em!=null){
                    bc_item['title_e'] = temp_bc_list[i].title_em
                }else bc_item['title_e'] = temp_bc_list[i].title_ea
                if(temp_bc_list[i].description_em!=''&&temp_bc_list[i].description_em!=null){
                    bc_item['description_e'] = temp_bc_list[i].description_em
                }else bc_item['description_e'] = temp_bc_list[i].description_ea
                temp_bc_list[i] = bc_item
            }
            // console.log(temp_bc_list);
            return temp_bc_list      
        }

    },
    actions: {
        readBusinessCaseList({commit, rootState}, { report_code, only_used, sort }){
            const path = rootState.backend_host + '/read_business_case_list'

            return axios.get(path, { params: {
                report_code: report_code,
                only_used: only_used != undefined ? only_used : null,
                sort: sort != undefined ? sort : null
            }})
            .then( response => {
                // console.log(response.data);
                // let idx = response.data.findIndex(item =>item.business_case_id >300)
                commit('update_business_case_list', response.data)
                commit('update_business_case_loaded', true)
            })
        },
        saveBusinessCase({commit, rootState}, { report_code, business_case_id, title, description, files, images }){
            const path = rootState.backend_host + '/save_business_case'

            let formData = new FormData()
            if(business_case_id != null && business_case_id != undefined && business_case_id != ''){
                formData.append('business_case_id', business_case_id) //신규일 경우, business_case_id를 백엔드로 보내지 않아야 신규 생성하고 id를 부여받음
            }
            formData.append('report_code', report_code)
            formData.append('title', title)
            formData.append('description', description)
            formData.append('files_id', JSON.stringify(files.map(item => item.file_id)) ) //최종 연결된 files_id 배열을 보냄
            formData.append('images_id', JSON.stringify(images.map(item => item.image_id))) //최종 연결된 image_id 배열을 보냄

            return axios.post(path, formData)
            .then( response => {
                // console.log(response)
                let new_business_case_obj = {
                    business_case_id: response.data.business_case_id, 
                    report_code: response.data.report_code, 
                    title: response.data.title, 
                    description: response.data.description, 
                    time_stamp: response.data.time_stamp,
                    files: response.data.files,
                    images: response.data.images
                }
                commit('add_or_update_business_case', new_business_case_obj )
            })

        },
        updateBusinessCaseRelation({commit, rootState}, { report_code, gri_code, link_payload, framework }){
            const path = rootState.backend_host + '/link_disclosure_business_cases'

            let formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('gri_code', gri_code)
            formData.append('framework', framework)
            formData.append('link_payload', JSON.stringify(link_payload)) //{ "business_case_id": true } 형식의 object

            return axios.post(path, formData)
            .then( response=> {
                let business_case_ids_by_gri_code = response.data.business_case_ids_by_gri_code
                //{ gri_code: [ business_case_ids ] }
                let gri_codes_by_business_case_id = response.data.gri_codes_by_business_case_id
                //{ 'business_case_id': [ gri_codes ]}
                commit('update_business_case_relation', gri_codes_by_business_case_id )
                commit('report_contents/update_business_case_relation', business_case_ids_by_gri_code, {root: true }) 
            })
        },
        deleteFile({commit, rootState}, { file_id, business_case_id }){
            const path = rootState.backend_host + '/delete_file'

            let formData = new FormData()
            formData.append('file_id', file_id)

            return axios.post(path, formData)
            .then( response =>{
                // console.log(response);
                commit('delete_file_from_business_case', { del_file_id: response.data.file_id, del_business_case_id : business_case_id })
            })
        },
        deleteBusinessCase({commit, rootState},  business_case_id ){
            const path = rootState.backend_host + '/delete_business_case'
            
            // console.log(business_case_id);
            let formData = new FormData()
            formData.append('business_case_id', business_case_id)

            return axios.post(path, formData)
            .then( response =>{
                // console.log(response.data.business_case_id);
                let deleted_business_case_id = response.data.business_case_id
                commit('update_delete_business_case', deleted_business_case_id)
                commit('report_contents/update_delete_business_case', deleted_business_case_id, {root: true})
            }) 
        },
        saveBusinessCaseEm({commit, rootState}, { business_case_id, title, description, files, images }){
            const path = rootState.backend_host + '/save_business_case_em'

            let formData = new FormData()
            formData.append('business_case_id', business_case_id) 
            if(title != null && title != undefined) formData.append('title_em', title)
            if(description != null && description != undefined) formData.append('description_em', description)
            if(files != null && files != undefined && files!=[]) formData.append('files_id', JSON.stringify(files.map(item => item.file_id)) ) //최종 연결된 files_id 배열을 보냄
            if(images != null && images != undefined && images!=[]) formData.append('images_id', JSON.stringify(images.map(item => item.image_id))) //최종 연결된 image_id 배열을 보냄

            return axios.post(path, formData)
            .then( response => {
                // console.log(response.data)
                commit('update_business_case_e', response.data )
            })
        },
        reInitiateBusinessCase({commit, rootState}, { business_case_id, re_initiate }){
            const path = rootState.backend_host + '/translate_business_case'

            let formData = new FormData()
            formData.append('business_case_id', business_case_id)
            if(re_initiate != null && re_initiate != undefined) formData.append('re_initiate', re_initiate ) //re_initiate 를 true로 보내면, direct_report_em을 삭제함
            return axios.post(path, formData )
            .then( response => {
                // console.log(response.data);
                commit('update_business_case_e', response.data)
            })
        },        
    }
}

export default business_cases
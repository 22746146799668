<template>
<div>
    <b-overlay :show="loadingShow" no-wrap></b-overlay>
    <b-input-group prepend="검색" class="mt-3">
        <b-input 
            v-model="search_report_keyword" 
            placeholder="진행상태 필터가 걸린 상태로 검색됩니다.(보고서코드, 보고서명, 소유자 검색 가능), 초기화 하려면 공백으로 검색"
            @keyup.enter="get_current_reports"
        />
        <b-input-group-append>
        <b-button variant="outline-info" @click="get_current_reports">검색(엔터)</b-button>
        </b-input-group-append>
    </b-input-group>

    <b-table-simple>
        <b-thead>
            <b-tr>
                <b-td>No.</b-td><b-td>보고서 코드</b-td><b-td>보고서명</b-td><b-td>소유자</b-td>
                <b-td> 진행상태
                    <b-form-select v-model="filter_selected" :options="options" size="sm" class="border-red red" @change="get_current_reports"></b-form-select>
                </b-td>
                <b-td>검증</b-td><b-td>최초 생성일</b-td>
            </b-tr>
        </b-thead>
        <b-tbody v-if="current_reports.length>0">
            <b-tr v-for="(report, index) in current_reports" :key="report.id">
                <b-td>{{ index + 1 + (current_page - 1) * 10 }}</b-td>
                <b-td><p class="mb-0 f-80">{{ report.report_code }}</p></b-td>
                <b-td><a class="cursor" @click="reportGo(report.report_code)"> {{ report.report_name }}</a></b-td>
                <b-td>{{ report.owner_e_mail }}</b-td>
                <b-td>{{ stateToString(report.state) }} 
                    <b-button v-if="report.state==4" size="sm" variant="teal" class="ml-2" target="_blank"
                    :href="'https://report.qesg.co.kr/v?r='+`${report.report_url!=null?report.report_url:report.report_code}`"><fa icon="link" /></b-button>
                </b-td>
                <b-td>
                    <b-button size="sm" variant="teal" class="ml-2 f-85" @click="assuranceReportGo(report)">검증의견 작성하기</b-button>
                    <!-- <b-badge v-if="assuranceStatus(report.report_code)>=0" :variant="assuranceStatusClass(report.report_code)" class="f-80">{{assuranceStatus(report.report_code)}}. {{ assuranceStatusTxt(report.report_code) }} </b-badge><div v-else>-</div>  -->
                </b-td>
                <b-td>{{ report.created_date }}</b-td>
            </b-tr>
        </b-tbody>
        <b-tbody v-else>
            
            <b-tr v-if="loadingShow">
                <b-td v-for="i in 7" :key="i.id">
                    <b-skeleton></b-skeleton>
                </b-td>
            </b-tr>

            <b-tr v-else>
                <b-td colspan="7">해당하는 보고서가 없습니다! 진행상태(공개, 검증, 전체, 샘플) 필터를 바꿔서 다시 검색해주세요 :)</b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
    <div class="report-pagination pt-5">
        <b-pagination
            v-model="current_page"
            :total-rows="report_list.length"
            :per-page="per_page"
            aria-controls="report-table"
            align="center" class="report-seperator"
        >
            <template v-slot:prev-text><fa icon="angle-left"/></template>
            <template v-slot:next-text><fa icon="angle-right"/></template>
        </b-pagination>
    </div>
</div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
    data(){
        return {
            search_report_keyword: "",
            loadingShow: true,
            report_list: [],
            current_reports:[],
            per_page: 50,
            current_page: 1,
            filter_selected: 'checking',
            options: [
                { value: 'open', text: '공개된 것만 보기' },
                { value: 'checking', text: '검증중인 것만 보기' },
                { value: 'all', text: '전체보기(샘플제외)' },
                { value: 'sample', text: '샘플보기'}
            ]
        }
    },
    computed: {
        // ...mapState('admin', ['report_list', 'report_code']),
        ...mapState('admin', ['report_code']),

        ...mapGetters('admin', ['hashed_report_list']),
        ...mapGetters('assurance', ['hashed_assurance']),
        current_reports_old() {
            if(this.report_list.length>0){
                let temp_list = []
                if (this.filter_selected=='open') {
                    temp_list = [...this.report_list.filter(x=> x.state==4)]
                }
                else if (this.filter_selected=='checking') {
                    temp_list = [...this.report_list.filter(x=> x.state==3)]
                }else { 
                    temp_list = [...this.report_list]
                }
                let total_num = temp_list.length
                let start_index = (this.current_page - 1) * this.per_page
                let end_index = Math.min((this.current_page - 1) * this.per_page + this.per_page, total_num)
                return temp_list.slice(start_index, end_index)
            }
        },
        
    },
    methods: {
        ...mapMutations('admin', ['update_report_code']),
        ...mapMutations('assurance',['update_admin_assurance']),
        ...mapActions('report_contents', ['readReportContents']),
        ...mapActions('data_tables', ['readDataTableList']),
        ...mapActions('documents', ['readDocumentList']),
        ...mapActions('business_cases', ['readBusinessCaseList']),
        ...mapActions('report_list', ['saveReportPreference']),
        ...mapActions('assurance', ['saveAssuranceRequest']),
        stateToString(state){
            if (state == 0){ return `기본설정` }
            else if (state == 1){ return `지표 선택` }
            else if (state==2) { return `작성`}
            else if (state==3) { return `검증`}
            else if (state==4) { return `공개`}
            else return ''
        },
        assuranceStatus(report_code){
            if(this.hashed_assurance[report_code]){
                return this.hashed_assurance[report_code].request_status
            }else return -1
        },
        assuranceStatusTxt(report_code){
            if(this.hashed_assurance[report_code]){
                if (this.hashed_assurance[report_code].request_status == 0){ return `비용확인` }
                else if (this.hashed_assurance[report_code].request_status == 1){ return `계약완료` }
                else if (this.hashed_assurance[report_code].request_status==2) { return `신청완료`}
                else if (this.hashed_assurance[report_code].request_status==3) { return `서면검토 중`}
                else if (this.hashed_assurance[report_code].request_status==4) { return `서면검토 완료`}
                else if (this.hashed_assurance[report_code].request_status==5) { return `검증의견서 생성중`}
                else if (this.hashed_assurance[report_code].request_status==6) { return `검증의견서 생성 완료`}
                else return '-'
            }else return '-'
        },
        assuranceStatusClass(report_code){
            if(this.hashed_assurance[report_code]){
                if(this.hashed_assurance[report_code].request_status >=2 && this.hashed_assurance[report_code].request_status<6){
                    return 'red'
                }
                if(this.hashed_assurance[report_code].request_status ==6){
                    return 'green'
                }
                else return 'advance'
            }
        },
        async reportGo(report_code){
            await this.update_report_code(report_code)
            if (this.hashed_assurance[report_code]!= undefined){
                await this.update_admin_assurance(this.hashed_assurance[report_code].assurance_id)
            }            
            this.readReportContents({report_code: report_code})
            this.readDataTableList({report_code: report_code})
            this.readDocumentList({report_code: report_code})
            this.readBusinessCaseList({report_code: report_code})
            // console.log(this.hashed_assurance[report_code]);
            if(this.hashed_assurance[report_code]&&this.hashed_assurance[report_code].request_status>=5){
                this.$router.push({name: 'WriteStatement'})
            }
            else this.$router.push({name: 'OnlineAssurance'})
        },
        async assuranceReportGo(report){
            // console.log(report);
            await this.update_report_code(report.report_code)
            if (report.state <= 2){
                this.saveReportPreference({report_code: report.report_code, state: 3})  
            }
            // console.log(this.assuranceStatus(report.report_code));
            if(this.assuranceStatus(report.report_code)<0){
                await this.saveAssuranceRequest({
                    report_code : report.report_code,
                    request_status : 2
                })
            }
            // console.log(this.hashed_assurance);
            if (this.hashed_assurance[report.report_code]!= undefined){
                this.update_admin_assurance(this.hashed_assurance[report.report_code].assurance_id)
            }
            setTimeout(()=>{
                this.$router.push({name: 'WriteStatement'})
            },1500) 
        },
        get_current_reports() {
            this.loadingShow = true

            axios.get(
                this.$store.state.backend_host +
                `/read_report_list_for_admin?type=${this.filter_selected}&keyword=${this.search_report_keyword}`
            ).then((response) => { 
                this.current_reports = response.data

                this.loadingShow = false
            }).catch(e => {
                this.loadingShow = false
                window.alert(e)

            })

        }
    },
    mounted() {
        this.get_current_reports();
        // console.log(this.report_list);
        // console.log(this.hashed_assurance);
    }
    
}
</script>

<style>

</style>
<template>
    <div>
        GRI 인디케이터

        <b-container fluid class="py-2 overflow-hidden">
            <b-row no-gutters class="d-md-flex">
                <b-col cols="12" md="3" class="pr-3">
                    <div class="d-flex justify-content-between mb-3 border-bottom border-color-gray-700 pb-2 mb-2">
                        <h5 class="mb-0">1. 질문 리스트 생성 </h5>
                    </div>

                    
                    <GriIndicatorTree />




                    

                    
                    
                </b-col>
                <b-col  cols="12" md="9" class="px-3">
                    <b-input-group size="sm" class="mb-2">
                        <b-input-group-prepend is-text>
                            <b-icon icon="search"></b-icon>
                        </b-input-group-prepend>
                        <b-form-input type="search" placeholder="gri_code, 지표명, 내용 검색" />
                    </b-input-group>

                    <div class="d-flex justify-content-between border-bottom border-color-gray-700 mb-3">
                        <h5>2. 문항별 질문 생성/편집 </h5>
                    </div>

                    여기 질문지~



                </b-col>
            </b-row>
            
        </b-container>
        
    </div>
</template>
<script>
import GriIndicatorTree from '@/components/managing_indicators/gri_2021/GriIndicatorTree.vue';

GriIndicatorTree
export default {
    name: '',
    props: {},
    components: {
        GriIndicatorTree
    },
    data() {
        return {
            example: '',
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {}
}
</script>
<style>
</style>
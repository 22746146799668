<template>
    <div style="width: 100% !important" class="no-page-brake-inside">
        <b-overlay :show="is_rendered">
            <div>
                <div v-if="!viewer_mode"  class="mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center">
                    <!-- {{ chartData.label }} -->
                    <div
                        class="fw-500"
                    >
                        <span v-if="chartData">
                            {{ chartData.label }}
                        </span>
                    </div>
                    <div>
                        <!-- :disabled="is_unlinked==true"  -->
                        <ChartBtn 
                            :edit_delete_mode="true"
                            :selected_mng_chart_id="chart_id"
                            :dc_key_info="dc_key_info"
                        />
                        
                        <!-- <b-button 
                            variant="light" 
                            class="py-1 px-2" 
                            v-b-tooltip.hover 
                            title="편집" 
                            @click.stop.prevent="editChart"
                        >
                            <fa icon="pen"/>
                        </b-button> -->
                        <!-- :disabled="is_unlinked==true"  -->
                        <b-button 
                            variant="light" 
                            class="ml-1 py-1 px-2" 
                            v-b-tooltip.hover 
                            title="없애기" 
                            @click.stop.prevent="removeChild"
                        >
                            <fa icon="unlink"/>
                        </b-button>
                    </div>
                </div>

                
                <div
                    v-if="viewer_mode"
                    :id="`chart-${chart_id}`"
                    class="business-case-link cursor mb-2 fw-500 pt-4"
                    :style="main_color"
                    @click="toggleChartFromAccordion"
                >
                    <b-icon icon="graph-up" scale="1.1" shift-v="1.25" aria-hidden="true" /> 
                    <span v-if="chartData">
                        {{ lang!='ENG'? chartData.label: chartData_e.label }} 
                    </span>


                    <div v-if="!print_mode" class="float-right mb-2">
                        <b-button variant="outline-dark" size="sm mr-2 pr-2" @click="download_as_png">
                            <b-icon icon="file-earmark-image" scale="1" shift-v="1.25" aria-hidden="true" /> 
                            PNG
                        </b-button>
                        <b-button variant="outline-dark" size="sm pr-2" @click="download_as_pdf">
                            <b-icon icon="file-earmark-pdf-fill" scale="1" shift-v="1.25" aria-hidden="true" /> 
                            PDF
                        </b-button>

                    </div>

                </div>
                <div>
                    <canvas
                        :id="'chart_canvas_' + chart_id"
                        :style="'max-height: ' + chartData?.options?.chart_height + 'px;'"
                        ref="qesgChart" 
                        class="border border-light rounded mt-2"
                    ></canvas>
                    <small v-if="chartData && chartData.options" class="text-muted p-4">
                        <span v-if="lang === 'KOR'">
                            {{chartData.options.footnote}}
                        </span>
                        <span v-else>
                            {{ chartData_e.options.footnote }}
                        </span>
                    </small>
                    
                </div>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import Chart from "chart.js/auto"; // 최신 버전의 Chart.js를 사용하려면 'chart.js/auto'를 사용합니다.
import { mapState, mapGetters, mapMutations } from 'vuex'
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import ChartBtn from "@/components/writing_report/chart_subs/ChartBtn";


export default {
    props: {
        payload : { type: Object, default: () => null },
        chart_id: { type: Number, default: () => null },
        viewer_mode: { type: Boolean, default: () => false },
        print_mode: { type: Boolean, default: () => false },

        lv2_info: { type: Object, default: () => null },
        lv3_idx: { type: Number, default: () => null },
        dc_key_info: { type: Array, default: () => [] },
        
    },
    components: {
        ChartBtn,

    },
    computed: {
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']), 
        ...mapGetters('report_list', ['selected_report']),
        ...mapState('charts', ['chart_data', 'selected_chart_id', 'selected_chart_data', 'edited_chart_id']),

        main_color(){
            return `color:`+this.selected_report.main_color
        },

    },
    data() {
        return {
            chart: null,
            chartData: null,
            chartData_e: null,
            is_rendered: true,
            show_chart: true,
            imageDataURL: ''
        }
    },
    beforeCreate() {},
    created() {
    },
    watch: {
        lang:{
            immediate: true,
            handler(newVal) {
                this.renderChart();
            }
        },
        // chart_data: {
        //     handler(newVal) {
        //         if (newVal === this.chart_id) {
        //             this.renderChart();
		// 		    this.update_edited_chart_id(null);

        //         }
        //     }
        // },

        chart_data: {
            handler(newArray, oldArray) {
            // 배열을 순회하면서 특정 object의 값이 변경되었는지 확인하고 처리
                if (this.edited_chart_id === this.chart_id) {
                    this.chartData = newArray.find(obj => obj['chart_id'] === this.chart_id)['form']
                    this.renderChart();
				    this.update_edited_chart_id(null);
                }
                // this.renderChart();
            },
            deep: true // 객체 내부의 변화도 감지합니다.
        }
    },
    beforeMount() {},
    mounted() {
        if (this.payload !== null) {
            this.chartData = { ...this.payload };
        }
        // if (this.chart_id !== null) {
        //     this.getChartData();
        // }

        if (!this.print_mode) {
            this.$nextTick(() => {
                this.renderChart();
            });
            
        } else {

            
        }
    },
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {
        ...mapMutations('charts', ['update_edited_chart_id']),
        toggleChartFromAccordion(event) {
            event.target.parentElement.nextElementSibling.classList.toggle('hide');

        },
        async renderChart() {
            if (!this.$refs.qesgChart && this.$refs.qesgChart !== undefined) {
                return;
            }

            let chartOptions = null

            if (this.chartData === null) {
                if (this.print_mode) {
                    const response = await axios.get(`${this.$store.state.backend_host}/getChartDataById?id=${this.chart_id}`)
                    if (response.data.code) {
                        this.chartData = response.data.result.contents
                        this.chartData_e = response.data.result.contents_em ? response.data.result.contents_em : response.data.result.contents_ea
                    }
                    
                } else {
                    let response = this.chart_data.find(obj => obj['chart_id'] === this.chart_id)
                    if (!response) {
                        response = await axios.get(`${this.$store.state.backend_host}/getChartDataById?id=${this.chart_id}`)
                        if (response.data.code) {
                            this.chartData = response.data.result.contents
                            this.chartData_e = response.data.result.contents_em ? response.data.result.contents_em : response.data.result.contents_ea
                        }
                    } else {
                        this.chartData = response['form']
                        this.chartData_e = response['form_em'] ? response['form_em'] : response['form_ea']
                    }
                }
                

            }


            if (this.lang !== 'ENG') {
                chartOptions = {...this.chartData}
            } else {
                chartOptions = {...this.chartData_e}
            }

            chartOptions.plugins = [
				{
					id: 'customCanvasBackgroundColor',
					beforeDraw: (chart, args, options) => {
						const { ctx } = chart;
						ctx.save();
						ctx.globalCompositeOperation = 'destination-over';
						ctx.fillStyle = options.color || 'white';
						ctx.fillRect(0, 0, chart.width, chart.height);
						ctx.restore();
					}
				}
            ]

            chartOptions.options.plugins['tooltip'] = {
              intersect: true,
              callbacks: {
                label: (context) => {
                  // return `${context.dataset.label}: ${context.parsed} (단위: ${context.dataset?.y_level})`;
                  return context.parsed.y !== undefined ? `${context.dataset.label}: ${context.parsed.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (단위: ${context.dataset?.unit})` : `${context.dataset.label}: ${context.parsed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (단위: ${context.dataset?.unit})`;
                }
              }
            }
			
			if (this.chart) {
				this.chart.destroy();
			}


            
            const ctx = this.$refs.qesgChart.getContext("2d");
            this.chart = new Chart(ctx, chartOptions)
            this.is_rendered = false;

            // this.$nextTick(() => {
            //     this.imageDataURL = this.$refs.qesgChart.toDataURL('image/png');
            //     this.is_rendered = true;
            // });

            
            
        },

        async download_as_png() {
            const chartElement = this.$refs.qesgChart;

            try {
                // html2canvas를 사용하여 요소 캡처
                const canvas = await html2canvas(chartElement);

                // canvas의 데이터 URL 생성
                const dataURL = canvas.toDataURL('image/png');

                // 데이터 URL을 사용하여 이미지 다운로드
                const link = document.createElement('a');
                link.href = dataURL;

                const file_name = this.lang!=='ENG'? this.chartData.label: this.chartData_e.label
                link.download = file_name + '.png';
                link.click();

            } catch (error) {
                console.error('Error exporting component:', error);
            }

            
        },
        async download_as_pdf() {
            console.log('download as pdf')
            // ref로 지정된 canvas 요소 가져오기
            const canvas = this.$refs.qesgChart;

            const file_name = this.lang!=='ENG'? this.chartData.label: this.chartData_e.label
            try {
                html2pdf(canvas, {
                    margin: 1,
                    filename: file_name + '.pdf',
                    image: {
                        type: 'jpeg',
                        quality: 0.98
                    },
                    html2canvas: {
                        scale: 2,
                        width: canvas.width*1.4,
                    },
                    jsPDF: {
                        unit: 'in',
                        format: 'letter',
                        orientation: 'portrait'
                    }
                });
            } catch (error) {
                console.error('Error exporting canvas to PDF:', error);
            }

        },

        removeChild() {
            this.$emit("removeChild", this.lv2_info, this.lv3_idx);
        },

        editChart() {

        }

    }
}
</script>
<style>
</style>
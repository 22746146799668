import Image from '@tiptap/extension-image'
import { mergeAttributes } from '@tiptap/core'

export default Image.extend({
    name: 'custom-image',
    priority: 1000,

    addAttributes() {
        return {
            ...Image.config.addAttributes(),
            size: {
                default: 'small',
                rendered: true
            },
            float: {
                default: 'left',
                rendered: true
            }
        }
    },

    addCommands() {
        return {
            setImage: (options) => ({ tr, commands }) => {
                if (tr.selection?.node?.type?.name == 'custom-image') {
                    return commands.updateAttributes('custom-image', options)
                } else {
                    return commands.insertContent({
                        type: this.name,
                        attrs: options
                    })
                }
            },
        }
    },
    renderHTML({ node, HTMLAttributes }) {
        let _bootstrap_sizing_class = { small: 'w-25', medium: 'w-50', large: 'w-100' }
        HTMLAttributes.class = _bootstrap_sizing_class[node.attrs.size]

        return [
            "figure",
            { style: `text-align: ${node.attrs.float}`},
            ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
        ];
    }
})

<template>
  <div>
    <h4 class="mb-5">TCFD</h4>

    <div
      v-for="(category_info, category_idx) in tcfd_indicator_list"
      :key="category_idx"
      class="mb-5"
    >
      <h5
        :id="`w${category_info['category_code']}`"
        class="fw-600 mb-4 border-bottom-dark pb-2"
      >
        {{ category_info["category_name"] }}
        <span class="gray-500 f-105 fw-400 ml-1">{{
          category_info["category_name_e"]
        }}</span>
      </h5>
      <b-table-simple responsive class="wri-table">
        <b-thead>
          <b-tr>
            <b-td style="width: 14%">지표</b-td>
            <b-td style="width: 34%">국문</b-td>
            <b-td>번역하기</b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(indicator_info, indicator_idx) in category_info.indicators"
            :id="`w${indicator_info['tcfd_code']}`"
            :key="indicator_idx"
          >
            <b-td>
              <span class="f-160">{{ indicator_info["tcfd_code"] }}</span>
              <p class="f-120">{{ indicator_info.indicator_category }}</p>
              <p class="">{{ indicator_info.metric }}</p>
            </b-td>

            <b-td>
              <div
                v-if="
                  indicator_info.direct_report != '' &&
                  indicator_info.direct_report != undefined
                "
                class="f-90 pb-3"
                style="
                  word-wrap: break-word;
                  white-space: no-wrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ indicator_info.direct_report }}
              </div>

              <!-- ======================================================================================================================================= -->
              <!-- 연결된 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
              <section
                class="mb-5 px-3"
                v-if="
                  indicator_info.data_tables.length > 0 && hashed_data_tables
                "
              >
                <div v-for="dt in indicator_info.data_tables" :key="dt.id">
                  <div
                    class="mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center"
                  >
                    <span v-if="hashed_data_tables && hashed_data_tables[dt]">
                      <fa icon="table" class="mr-1" />
                      {{ hashed_data_tables[dt].title }}
                    </span>
                  </div>
                  <b-table-simple
                    responsive
                    class="bor-bg-table"
                    v-if="hashed_data_tables && hashed_data_tables[dt]"
                  >
                    <b-thead>
                      <b-tr>
                        <b-td
                          v-for="(property, col_index) in hashed_data_tables[dt]
                            .header"
                          :key="col_index"
                          >{{ property }}</b-td
                        >
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr
                        v-for="(row_item, row_index) in hashed_data_tables[dt]
                          .contents"
                        :key="row_item.id"
                      >
                        <template
                          v-for="property in hashed_data_tables[dt].header"
                        >
                          <b-td
                            v-if="
                              differentPrevious(
                                hashed_data_tables[dt].contents,
                                row_index,
                                property,
                                hashed_data_tables[dt].header
                              ) == true
                            "
                            :key="property.id"
                            :rowspan="
                              getSameLength(
                                hashed_data_tables[dt].contents,
                                row_index,
                                property,
                                hashed_data_tables[dt].header
                              )
                            "
                          >
                            <span v-html="formattedText(row_item[property])">
                            </span>
                          </b-td>
                        </template>
                      </b-tr>
                    </b-tbody>
                    <caption class="mb-2">
                      {{
                        hashed_data_tables[dt].footnote
                      }}
                    </caption>
                  </b-table-simple>
                </div>
              </section>
              <section
                class="mb-5 px-3"
                v-if="indicator_info.documents.length > 0"
              >
                <div v-for="x in indicator_info.documents" :key="x.id">
                  <div
                    v-if="
                      hashed_docs &&
                      hashed_docs[x] &&
                      hashed_docs[x].type != 'link'
                    "
                    class="mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center"
                  >
                    <a
                      class="cursor"
                      :href="$store.state.static_host + hashed_docs[x].path"
                      target="_blank"
                      ><fa icon="file-alt" class="mr-1" />
                      {{ hashed_docs[x].title }}
                    </a>
                  </div>
                </div>
                <div v-for="y in indicator_info.documents" :key="y.id">
                  <div
                    v-if="
                      hashed_docs &&
                      hashed_docs[y] &&
                      hashed_docs[y].type == 'link'
                    "
                    class="mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center"
                  >
                    <a
                      class="cursor"
                      :href="hashed_docs[y].link"
                      target="_blank"
                      ><fa icon="external-link-square-alt" class="mr-1" />
                      {{ hashed_docs[y].title }}
                    </a>
                  </div>
                </div>
              </section>
              <section
                class="mb-5 px-3"
                v-if="indicator_info.business_cases.length > 0"
              >
                <div
                  v-for="(bc, idx) in indicator_info.business_cases"
                  :key="idx"
                >
                  <div
                    class="mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center"
                  >
                    <a
                      class="cursor"
                      v-b-modal="`bc_${bc}${indicator_info['tcfd_code']}`"
                      ><span
                        v-if="
                          hashed_business_cases && hashed_business_cases[bc]
                        "
                        ><fa icon="quote-left" class="mr-1" />{{
                          hashed_business_cases[bc].title
                        }}</span
                      ></a
                    >
                  </div>
                  <b-modal
                    :id="`bc_${bc}${indicator_info['tcfd_code']}`"
                    hide-footer
                    size="xl"
                  >
                    <template #modal-title>
                      <b-badge variant="basic" class="mr-2">Case.</b-badge
                      ><span
                        v-if="
                          hashed_business_cases && hashed_business_cases[bc]
                        "
                        ><fa icon="quote-left" class="mr-1" />{{
                          hashed_business_cases[bc].title
                        }}</span
                      >
                    </template>
                    <simple-business-case
                      :business_case_id="bc"
                    ></simple-business-case>
                  </b-modal>
                  <b-modal
                    :id="`bc_e_${bc}${indicator_info.tcfd_code}`"
                    hide-footer
                    size="xl"
                  >
                    <template #modal-title>
                      <b-badge variant="basic" class="mr-2">Case.</b-badge
                      ><span
                        v-if="
                          hashed_business_cases && hashed_business_cases[bc]
                        "
                        >{{ hashed_business_cases[bc].title_e }}</span
                      >
                    </template>
                    <simple-business-case
                      :business_case_id="bc"
                      :eng_mode="true"
                    ></simple-business-case>
                  </b-modal>
                </div>
              </section>

              <section class="mt-5 pr-3" v-if="indicator_info.charts.length>0">
                  <div v-for="chart_id in indicator_info.charts" :key="chart_id">
                      <ChartTextCanvas 
                          class="mb-5"
                          :chart_id="chart_id" 
                          :key="`chart_${chart_id}`"
                          :viewer_mode="true"
                      />
                  </div>
              </section>
              <!-- ======================================================================================================================================= -->
            </b-td>

            <b-td>
              <template v-if="indicator_info.disclosure_id">
                <!-- :disabled="is_editing_eng[item.gri_code]==false" -->
                <b-form-textarea
                  v-model="writing_eng[indicator_info.tcfd_code]"
                  class="f-90 mb-2"
                  :disabled="!is_editing_eng[indicator_info.tcfd_code]"
                  rows="4"
                />
                <div
                  class="d-flex justify-content-between"
                  v-if="selected_report.is_able_to_edit"
                >
                  <!-- <div 
                        v-if="indicator_info.direct_report!=''&&indicator_info.direct_report!=undefined" 
                        class="f-90 pb-3"
                        style="word-wrap: break-word; white-space: no-wrap; overflow: hidden; text-overflow: ellipsis;"
                    >
                        {{indicator_info.tcfd_code}}
                    </div>  -->
                  <b-button
                    v-if="!is_editing_eng[indicator_info.tcfd_code]"
                    variant="teal-border"
                    size="sm"
                    class="mr-2"
                    @click.stop="
                      is_editing_eng[indicator_info.tcfd_code] = true
                    "
                    >번역 수정하기</b-button
                  >
                  <div v-else>
                    <b-button
                      variant="teal"
                      size="sm"
                      class="mr-2"
                      @click="
                        handleReInitiate(
                          indicator_info.tcfd_code,
                          indicator_info.disclosure_id
                        )
                      "
                      :disabled="re_initiating == true"
                    >
                      번역 초기화
                      <fa
                        icon="redo"
                        :class="re_initiating == true ? 'spinning-icon' : ''"
                    /></b-button>
                    <b-button
                      variant="red"
                      size="sm"
                      class="mr-2"
                      @click="
                        handleSave(
                          indicator_info.tcfd_code,
                          indicator_info.disclosure_id
                        )
                      "
                      :disabled="
                        save_state[indicator_info.tcfd_code] == 'saving' ||
                        re_initiating == true
                      "
                      >저장</b-button
                    >
                    <b-button
                      size="sm"
                      @click="
                        handleCancle(
                          indicator_info.tcfd_code,
                          indicator_info.direct_report_ea
                        )
                      "
                    >
                      취소
                    </b-button>
                  </div>

                  <div class="f-90 blue">
                    <template
                      v-if="save_state[indicator_info.tcfd_code] === 'saved'"
                      ><fa icon="check-circle" class="ml-1" /> 수동 번역 저장됨
                    </template>
                    <template
                      v-else-if="
                        save_state[indicator_info.tcfd_code] === 'saving'
                      "
                      ><b-spinner class="blue" small></b-spinner> 저장
                      중</template
                    >
                  </div>
                </div>

                <!-- 연결된 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
                <section
                  class="mt-4 pr-3 border-right pt-2"
                  v-if="indicator_info.data_tables.length > 0"
                >
                  <div v-for="dt in indicator_info.data_tables" :key="dt.id">
                    <div
                      class="f-90 mb-2 fw-500 d-flex justify-content-between align-items-start"
                    >
                      <div>
                        <fa icon="table" class="mr-1" />
                        {{ hashed_data_tables[dt].title_e }}
                      </div>
                      <b-button
                        variant="teal-border"
                        size="sm"
                        class="py-1 px-2"
                        v-b-tooltip.hover
                        title="번역 수정하기"
                        @click.stop.prevent="translateTable(dt)"
                        ><fa icon="pen"
                      /></b-button>
                    </div>
                    <b-table-simple responsive class="bor-bg-table">
                      <b-thead
                        ><b-tr>
                          <b-td
                            v-for="(property, col_index) in hashed_data_tables[
                              dt
                            ].header_e"
                            :key="col_index"
                            >{{ property }}</b-td
                          >
                        </b-tr></b-thead
                      >
                      <b-tbody
                        ><b-tr
                          v-for="row_item in hashed_data_tables[dt].contents_e"
                          :key="row_item.id"
                        >
                          <b-td
                            v-for="property in hashed_data_tables[dt].header_e"
                            :key="property.id"
                            >{{ row_item[property] }}</b-td
                          >
                        </b-tr></b-tbody
                      >
                    </b-table-simple>
                    <div class="f-85 gray-600">
                      {{ hashed_data_tables[dt].footnote_e }}
                    </div>
                  </div>
                </section>
                <section
                  class="mt-4 pr-3 border-right pt-2"
                  v-if="indicator_info.documents.length > 0"
                >
                  <div
                    v-for="x in indicator_info.documents"
                    :key="x.id"
                    class="mb-2 d-flex justify-content-between align-items-start"
                  >
                    <template v-if="hashed_docs[x].type != 'link'">
                      <a
                        class="fw-500 cursor"
                        :href="$store.state.static_host + hashed_docs[x].path_e"
                        target="_blank"
                        ><fa icon="file-alt" class="mr-1" />
                        {{ hashed_docs[x].title_e }}
                      </a>
                      <b-button
                        variant="teal-border"
                        size="sm"
                        class="py-1 px-2"
                        v-b-tooltip.hover
                        title="번역 수정하기"
                        @click.stop.prevent="
                          translateDocument({
                            id: x,
                            type: hashed_docs[x].type,
                          })
                        "
                        ><fa icon="pen"
                      /></b-button>
                    </template>
                    <template v-else>
                      <a
                        class="fw-500 cursor"
                        :href="hashed_docs[x].link_e"
                        target="_blank"
                        ><fa icon="external-link-square-alt" class="mr-1" />
                        {{ hashed_docs[x].title_e }}
                      </a>
                      <b-button
                        variant="teal-border"
                        size="sm"
                        class="py-1 px-2"
                        v-b-tooltip.hover
                        title="번역 수정하기"
                        @click.stop.prevent="
                          translateDocument({
                            id: x,
                            type: hashed_docs[x].type,
                          })
                        "
                        ><fa icon="pen"
                      /></b-button>
                    </template>
                  </div>
                </section>
                <section
                  class="mt-4 pr-3 border-right pt-2"
                  v-if="indicator_info.business_cases.length > 0"
                >
                  <div v-for="bc in indicator_info.business_cases" :key="bc.id">
                    <div
                      class="mb-2 fw-500 d-flex justify-content-between align-items-start"
                    >
                      <a
                        class="fw-500 cursor"
                        v-b-modal="`bc_e_${bc}${indicator_info.tcfd_code}`"
                      >
                        <fa icon="quote-left" class="mr-1" />
                        {{ hashed_business_cases[bc].title_e }}
                      </a>
                      <b-button
                        variant="teal-border"
                        size="sm"
                        class="ml-2 py-1 px-2"
                        v-b-tooltip.hover
                        title="번역 수정하기"
                        @click.stop.prevent="translateBusinessCase(bc)"
                        ><fa icon="pen"
                      /></b-button>
                    </div>
                    <!-- <div
                      v-if="show_item_modal_e == true"
                      class="hj-modal-right bg-white rounded shadow"
                      :style="
                        low_height_e == true ? 'height:50vh!important' : ''
                      "
                    >
                      <div
                        class="hj-modal-header bgray-600 gray-100 d-flex align-items-center justify-content-between px-4"
                      >
                        <div>
                          <b-badge variant="basic" class="mr-2">Case.</b-badge>
                          {{ hashed_business_cases[bc_selected_e].title_e }}
                        </div>
                        <div>
                          <b-button
                            variant="icon-light"
                            @click="low_height_e = !low_height_e"
                            ><fa
                              :icon="
                                low_height_e == true ? 'expand' : 'compress-alt'
                              "
                            ></fa> </b-button
                          ><b-button variant="icon-light" @click="hideBCModalE"
                            ><fa icon="times"></fa>
                          </b-button>
                        </div>
                      </div>
                      <div
                        class="hj-modal-body px-4 py-3"
                        :style="
                          low_height_e == true
                            ? 'height:calc(50vh - 50px)!important'
                            : ''
                        "
                      >
                        <simple-business-case
                          :business_case_id="bc_selected_e"
                          :eng_mode="true"
                        ></simple-business-case>
                      </div>
                    </div> -->
                  </div>
                </section>

                <section class="mt-5 pr-3" v-if="indicator_info.charts.length>0">
                    <div v-for="chart_id in indicator_info.charts" :key="chart_id">
                        <ChartTranslateTextCanvas 
                            class="mb-5"
                            :chart_id="chart_id" 
                            :key="`chart_${chart_id}`"
                            :viewer_mode="true"
                            lang="ENG"
                        />
                    </div>
                </section>
                
              </template>
              <template v-else>
                <div>작성된 지표가 없습니다.</div>
              </template>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>
<script>
import { gri_mixin } from "@/components/writing_report/write_report_subs/gri_mixin";
import { formattedText } from "@/components/util";
import { mapState, mapGetters } from "vuex";
import SimpleBusinessCase from "@/components/writing_report/business_case_subs/SimpleBusinessCase";
import ChartTextCanvas from "@/components/writing_report/chart_subs/ChartTextCanvas.vue";
import ChartTranslateTextCanvas from "@/components/writing_report/chart_subs/ChartTranslateTextCanvas.vue";

export default {
  mixins: [gri_mixin],
  name: "",
  props: {},
  components: {
    SimpleBusinessCase,
    ChartTextCanvas,
    ChartTranslateTextCanvas
  },
  data() {
    return {
      is_writing_direct: {},
      save_state: {},
      tcfd_indicator_list: [],
      writing_eng: {},
      is_editing_eng: {},
      re_initiating: false,
      show_item_modal: false,
      low_height: false,
      low_height_e: false,
      bc_selected: 0,
      bc_selected_e: 0,
    };
  },
  computed: {
    ...mapState("report_list", ["report_code"]),
    ...mapGetters("report_contents", ["gri_2_all_contents"]),
  },
  beforeCreate() {},
  created() {
    this.fetchSelectedTcfd();
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  watch: {
    gri_2_all_contents: {
      immediate: true,
      handler(newV) {
        if (newV) {
          // console.log(newV);
          // console.log(this.save_state);
          this.fetchSelectedTcfd();
        }
      },
    },
  },
  unmounted() {},
  methods: {
    formattedText,
    handleInitiate(tcfd_code, direct_report_ea) {
      this.$set(this.writing_eng, tcfd_code, direct_report_ea);
    },
    handleCancle(tcfd_code, direct_report_ea) {
      this.handleInitiate(tcfd_code, direct_report_ea);
      this.is_editing_eng[tcfd_code] = false;
    },
    initiateSavingState() {
      this.save_state = {};
      for (let i = 0; i < this.gri_2_all_contents.length; i++) {
        if (
          this.gri_2_all_contents[i].direct_report != "" &&
          this.gri_2_all_contents[i].direct_report != undefined
        ) {
          this.$set(
            this.save_state,
            this.gri_2_all_contents[i].gri_code,
            "saved"
          );
        } else
          this.$set(this.save_state, this.gri_2_all_contents[i].gri_code, "");
      }
    },
    initiateWritingMode(tcfd_indicator_code) {
      this.is_editing_eng[tcfd_indicator_code] = false;
    },
    async handleSave(indicator_code, indicator_id) {
      this.$set(this.save_state, indicator_code, "saving");

      let formData = new FormData();
      formData.append("report_code", this.report_code);
      formData.append("gri_code", indicator_code);
      formData.append("direct_report_em", this.writing_eng[indicator_code]);
      formData.append("type", "TCFD");
      const response = await axios.post(
        `${this.$store.state.backend_host}/save_disclosure_em`,
        formData
      );
        if (response.status === 200) {
      
        this.is_editing_eng[indicator_code] = false;
        this.writing_eng[indicator_code] = response.data.direct_report_em;
        this.$set(this.save_state, indicator_code, "saved");
      }
    },
    async handleReInitiate(indicator_code, disclosure_id) {
      this.re_initiating = true;

      let formData = new FormData();
      formData.append("disclosure_id", disclosure_id);
      formData.append("type", "TCFD");
      formData.append("re_initiate", true);
      const response = await axios.post(
        `${this.$store.state.backend_host}/translate_disclosure`,
        formData
      );

      if (response.status) {
        this.initiateWritingMode(indicator_code);
        this.writing_eng[indicator_code] = response.data.direct_report_ea;
        this.$EventBus.$emit(
          "make-toast",
          "번역 초기화",
          indicator_code + "를 번역을 초기화 하였습니다."
        );
        this.re_initiating = false;
      } else {
        alert("에러");
      }
    },
    async fetchSelectedTcfd() {
      const response = await axios.get(
        `${this.$store.state.backend_host}/tcfd/read_tcfd_all_indicators_list_sort_by_category?rc=${this.report_code}`
      );

      this.tcfd_indicator_list = response.data.result;

      for (let sector_key of Object.keys(response.data.result)) {
        const indicator_list = response.data.result[sector_key]["indicators"];

        for (let indicator_info of indicator_list) {
          this.$set(this.is_editing_eng, indicator_info.tcfd_code, false);

          if (
            indicator_info.direct_report_em != "" &&
            indicator_info.direct_report_em != null
          ) {
            this.$set(
              this.writing_eng,
              indicator_info.tcfd_code,
              indicator_info.direct_report_em
            );
          } else
            this.$set(
              this.writing_eng,
              indicator_info.tcfd_code,
              indicator_info.direct_report_ea
            );
        }
      }
    },
    initiateSavingState(tcfd_code) {
      this.save_state = {};
      for (let i = 0; i < this.gri_2_all_contents.length; i++) {
        if (
          this.gri_2_all_contents[i].direct_report != "" &&
          this.gri_2_all_contents[i].direct_report != undefined
        ) {
          this.$set(this.save_state, tcfd_code, "saved");
        } else {
          this.$set(this.save_state, tcfd_code, "");
        }
      }
    },

    translateTable(table_id) {
      const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit);
      if (!shouldEdit) return;
      this.$emit("translate-table", table_id); //TranslateMaster로 이벤트 보내서 modal open
    },
    translateDocument(document_info) {
      const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit);
      if (!shouldEdit) return;
      this.$emit("translate-document", document_info); //TranslateMaster로 이벤트 보내서 modal open
    },
    translateBusinessCase(business_case_id) {
      const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit);
      if (!shouldEdit) return;
      this.$emit("translate-business-case", business_case_id); //TranslateMaster로 이벤트 보내서 modal open
    },
  },
};
</script>
<style></style>

<template>
<div>
    <div class="accordion" role="tablist">
        <b-card no-body v-for="(item, index) in preference_side" :key="item.id" class="shadow-sm">  
            <b-card-header header-tag="header" class="p-1 bg-white" role="tab">
                <b-button block :aria-controls="`sub`+index" @click="pageGo(index)"
                variant="icon-txt" class="text-left py-2" :class="visible[index]==true? 'fw-500':''">{{item.nav}}</b-button>
            </b-card-header>
            <b-collapse :id="`sub`+index" v-model="visible[index]" role="tabpanel" accordion class="bgray-100 py-2">
                <b-button v-for="sub_title in item.sub_lv1" :key="sub_title.id" @click="navAnchor(sub_title.code)" block 
                 class="text-left pl-3 f-95 py-1 mt-0" :class="anchor_active==sub_title.code?'fw-500':''" :variant="anchor_active==sub_title.code? 'icon':'icon-gray'">
                {{sub_title.sub}}
                </b-button>
            </b-collapse>
        </b-card>
    </div>

</div>    
</template>
<script>
import * as ih from '@/components/util'

export default {
    data() {
        return {
            preference_side: [{
                    'nav':'보고서 설정',
                    'sub_lv1' : [
                        { sub: '기본 정보', code: '#bs1' },
                        // { sub: '디자인 테마', code: '#bs2' },
                        { sub: '보고서 삭제', code: '#bs3' }
                    ]
                },       
                {
                    'nav':'테마 설정',
                    'sub_lv1' : [
                        { sub: '디자인 테마 레이아웃 설정', code: '#theme1' },
                        { sub: '디자인 테마 기본 설정', code: '#theme2' },
                        { sub: '디자인 테마 세부 설정', code: '#theme3' },

                    ]
                },           
                {
                    'nav':'팀 구성 및 권한 관리',
                    'sub_lv1' : [
                        { sub: '팀 구성', code: '#ts1' }, 
                        { sub: '소유 권한 양도', code: '#ts2' }
                    ]
                },
                {
                    'nav':'공개 및 공유',
                    'sub_lv1' : [
                        { sub: '공개하기', code: '#ps1' }, 
                        { sub:'공개 링크 설정', code: '#ps2' }
                    ]
                },
            ],
            anchor_active : '#bs1'
        }
    },
    computed:{
        visible(){
            if(this.$route.path == '/basic_settings'){
                return [true, false, false, false]
            }
            if(this.$route.path == '/theme_settings'){
                return [false, true, false, false]
            }           
            if(this.$route.path == '/team_settings'){
                return [false, false, true, false]
            }
            if(this.$route.path == '/publishing'){
                return [false, false, false, true]
            }
        }        
    },
    methods:{
        pageGo(index){
            if(index == 0){
                this.$router.push('/basic_settings')
            }     
            if(index == 1){
                this.$router.push('/theme_settings')
            }           
            if(index == 2){
                this.$router.push('/team_settings')
            }
            if(index == 3){
                this.$router.push('/publishing')
            }
        },
        navAnchor(anchor){
            ih.anchorGo(anchor)
            this.anchor_active = anchor
        }
    },
    created(){
        // console.log(this.$route);
    }
}
</script>
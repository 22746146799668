<template>
    <div>
        <router-view name="editor_header"></router-view>
        <template v-if="translate_checked == true">
            <template v-if="is_report_translated==true">
                <b-container fluid class="pt-5 px-0 vh-adjust">
                    <b-row class="mx-0 px-lg-5">
                        <b-col cols="3" md="2" lg="2">
                            <side-nav-translate class="sticky-top pt-4"></side-nav-translate>
                        </b-col>
                        <b-col class="pt-4">
                            <router-view
                                @translate-table="translateTable"
                                @translate-document="translateDocument"
                                @translate-business-case="translateBusinessCase"
                                >
                            </router-view>
                        </b-col>
                    </b-row>
                    <hr class="border-0">             
                </b-container>
            </template>
            <template v-else>
                <b-container class="pt-5 px-0 vh-adjust">
                    <b-card>
                        <b-row class="w-100">
                            <b-col cols="1" xl="2"></b-col> 
                            <b-col>
                                <h4 class="pt-3">보고서 번역하기</h4>
                                <basic-translate-set></basic-translate-set>
                            </b-col>
                            <b-col cols="1" xl="2"></b-col> 
                        </b-row>                
                    </b-card>
                </b-container>
            </template>
        </template>
        <template v-else>
            <b-container class="pt-5 px-0 vh-adjust text-center">
                <b-row class="mx-0 px-lg-5 pt-5">
                    <b-col cols="3">
                        <b-skeleton-img class="rounded"></b-skeleton-img>
                    </b-col>
                    <b-col class="pt-4">
                        <b-skeleton class="mb-5"></b-skeleton>
                        <b-skeleton class="mb-3"></b-skeleton>
                        <b-skeleton class="mb-3"></b-skeleton>
                    </b-col>
                </b-row>
            </b-container>
        </template>

        <router-view name="editor_footer"></router-view>    

        <!-- disclosure item modal control -->
        <div v-show="view_item_name !== ''"
            class="hj-modal-example hj-modal-example-lg bg-white rounded shadow"
            :style="low_height == true ? 'height:50vh!important' : ''"
            >
            <div class="hj-modal-header d-flex align-items-center justify-content-between px-4">
                {{ view_item_name }}
                <div>
                    <b-button variant="icon-light" @click="low_height=!low_height">
                        <fa :icon="low_height==true?'expand' :'compress'"></fa>
                    </b-button>
                    <b-button variant="icon-light" @click="cancleEdit">
                        <fa icon="times"></fa>
                    </b-button>
                </div>
            </div> 
            <div class="hj-modal-body px-4"
                :style="low_height == true ? 'height:calc(50vh - 50px)!important' : ''"
                >
                <template v-if="view_item_name=='데이터 테이블 번역'">
                    <e-data-table :table_id="table_id" @cancle-edit="cancleEdit" />
                </template>
                <template v-if="view_item_name=='문서 번역본 업로드'">
                    <e-file :document_id="document_id" @cancle-edit="cancleEdit" />
                </template>
                <template v-if="view_item_name=='외부링크 번역본 편집'">
                    <e-link :document_id="document_id" @cancle-edit="cancleEdit" />
                </template>
                <template v-if="view_item_name=='비즈니스 사례 번역'">
                    <e-business-case :business_case_id="business_case_id" @cancle-edit="cancleEdit" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SideNavTranslate from '@/components/translate/SideNavTranslate'
import EDataTable from '@/components/translate/translate_disclosure_items/EDataTable'
import EFile from '@/components/translate/translate_disclosure_items/EFile'
import ELink from '@/components/translate/translate_disclosure_items/ELink'
import EBusinessCase from '@/components/translate/translate_disclosure_items/EBusinessCase.vue'
import BasicTranslateSet from '@/components/translate/BasicTranslateSet'

export default {
    name: 'TranslateMaster',
    components:{
        SideNavTranslate,
        EDataTable,
        EFile,
        ELink,
        EBusinessCase,
        BasicTranslateSet
    },
    data () {
        return {
            show_item_modal :false,
            low_height: false,
            view_item_name: '',
            table_id: '',
            document_id : '',
            business_case_id: '',
            translate_checked: false
        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState('report_contents', ['is_report_translated'])

    },
    async created () {
        await this.checkReportTranslated(this.report_code)
        this.translate_checked = true
    },
    methods:{
        ...mapActions('report_contents', ['checkReportTranslated']),
        translateTable(table_id){
            this.view_item_name='데이터 테이블 번역'
            this.table_id = table_id
        },
        translateDocument(document_info){
            // console.log(document_info);
            if (document_info.type == 'link') {
                this.view_item_name='외부링크 번역본 편집'
            } else {
                this.view_item_name='문서 번역본 업로드'
            }

            this.document_id = document_info.id
        },
        translateBusinessCase (business_case_id) {
            this.view_item_name='비즈니스 사례 번역'
            this.business_case_id = business_case_id
        },
        cancleEdit () {
            this.view_item_name=''
            this.table_id = ''
            this.document_id = '',
            this.business_case_id = ''
        },
        hideModal () {
            this.$refs['translate-modal'].hide()
        }
    }
}
</script>

<style>

</style>
<template>
<div>
    <h3 class="fw-600">Topic Standards : GRI 201 ~ 418</h3>

    <!--테스트-->
    <!-- {{selected_report}} <br><br>
    {{gri_ver}} <br><br>
    {{tcfd_ver}} <br> <br> -->
    <!---------->
    <p class="f-95 mb-4 gray-600">경제, 환경, 사회 측면의 보고 주제 및 지표를 선택합니다.</p>
    <div v-for="(part, num) in structures" :key="part.id">
        <h4 :id="part.idn" class="teal8 mb-4 bgray-200 hover-bg d-flex justify-content-between px-3"
        v-b-toggle="`toggle`+part.idn" @mouseenter="mouse_overed.splice(num,1,true)" @mouseleave="mouse_overed.splice(num,1,false)">
            {{ part.name }}  ({{ part.name_e }})
            <div v-if="mouse_overed[num]==true" class=""><fa icon="angle-up"></fa> </div>
        </h4>

        <b-collapse visible :id="`toggle`+part.idn">
            <b-table-simple v-if="is_gri_400_loaded==true" responsive hover class="bor-table pb-5">
                <b-thead>
                    <b-tr>
                        <b-td>코드</b-td><b-td>주제명</b-td><b-td>보고 여부</b-td><b-td>중요주제</b-td><b-td style="min-width:80px;">지표 번호</b-td><b-td>지표명</b-td><b-td>지표 보고 여부</b-td><b-td>작성 현황</b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr 
                        v-for="(item, index) in nowPart(part.key_code)" 
                        :key="item.id" 
                        @click="selectCurrentTopic(item.topic_id)"
                        :id = "`gri_code_${item.gri_code}`"
                        :class = "`gri_topic_${item.topic_id}`"

                    >
                        <template v-if="index-1 < 0 ? true: item.topic_id != nowPart(part.key_code)[index-1].topic_id">
                            <b-td :rowspan="getSameLength(nowPart(part.key_code), index)">{{ item.topic_id }}</b-td>
                            <b-td :rowspan="getSameLength(nowPart(part.key_code), index)">{{item.topic_name}}</b-td>
                            <b-td :rowspan="getSameLength(nowPart(part.key_code), index)">
                                
                                <!--테스트
                                    topic_id: {{ item.topic_id }} <br><br>
                                    selectedTopicId: {{  selectedTopicId  }}
                                -->
                                <!---------->
                                <topic-selector 
                                    :topic_id="item.topic_id" 
                                    @load="sendLoad"
                                    @changeOriginalTopic="changeOriginalTopic"
                                    :selectedTopicId="selectedTopicId"
                                    :originalTopicSelected="originalTopicSelected"
                                    :originalIndicatorSelected="originalIndicatorSelected"

                                    :originalMaterialSelected="originalMaterialSelected"
                                    :targetTopic="targetTopic"
                                    @changeIndicatorFromTopic="changeIndicatorFromTopic"
                                    @changeMaterialFromElse="changeMaterialFromElse"


                                ></topic-selector>
                            </b-td>
                            <b-td :rowspan="getSameLength(nowPart(part.key_code), index)">
                                <material-selector 
                                    :topic_id="item.topic_id" 
                                    :material_flag="material_flag"
                                    :originalTopicSelected="originalTopicSelected"
                                    :originalMaterialSelected="originalMaterialSelected"
                                    :selectedTopicId="selectedTopicId"
                                    :selectedGriCode="selectedGriCode"
                                    :targetMaterial="targetMaterial"

                                    @changeOriginalMaterial="changeOriginalMaterial"
                                    @changeMaterialIndicatorFromMaterial="changeMaterialIndicatorFromMaterial"
                                    @changeTopicFromIndicator="changeTopicFromIndicator"

                                >

                                </material-selector>
                            </b-td>
                        </template>
                        <b-td>
                            {{item.gri_code}}
                        </b-td>
                        <b-td>
                            {{item.indicator_name}}
                        </b-td>
                        <b-td>
                            <!--
                                test
                                selectedGriCode: {{ selectedGriCode }} <br>
                                originalIndicatorSelected: {{ originalIndicatorSelected }}
                            -->
                            <indicatorSelector 
                                :topic_id="item.topic_id" 
                                :gri_code="item.gri_code"
                                :originalTopicSelected="originalTopicSelected"
                                :selectedTopicId="selectedTopicId"
                                :originalMaterialSelected="originalMaterialSelected"
                                @changeOriginalMaterial="changeOriginalMaterial"
                                @changeOriginalTopic="changeOriginalTopic"
                                @changeOriginalIndicator="changeOriginalIndicator"
                                
                                @changeTopicFromIndicator="changeTopicFromIndicator"
                                :targetIndicator="targetIndicator"
                                @changeMaterialIndicatorFromMaterial="changeMaterialIndicatorFromMaterial"
                                @changeMaterialFromElse="changeMaterialFromElse"
                                :targetMaterialIndicator="targetMaterialIndicator"
                            />
                        </b-td>
                        <b-td><writing-status :gri_code="item.gri_code"></writing-status> </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-collapse>
    </div>
</div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'
import indicatorSelector from '@/components/managing_indicators/gri2021_indicators/indicatorSelector.vue'
import materialSelector from '@/components/managing_indicators/gri2021_indicators/materialSelector.vue'
import TopicSelector from '@/components/managing_indicators/gri2021_indicators/topicSelector.vue'
import writingStatus from '@/components/managing_indicators/gri2021_indicators/writingStatus.vue'

export default {
    components: {
        materialSelector,
        indicatorSelector,
        TopicSelector,
        writingStatus,
    },
    data() {
        return {
            structures: [
                {
                    'key_code' : 200,
                    'idn': 'gr200',
                    'name' : '경제',
                    'name_e' : 'Economic'
                },
                {
                    'key_code' : 300,
                    'idn': 'gr300',
                    'name' : '환경',
                    'name_e' : 'Environmental'
                },
                {
                    'key_code' : 400,
                    'idn': 'gr400',
                    'name' : '사회',
                    'name_e' : 'Social'
                },
            ],
            gri_200: [],
            gri_300: [],
            gri_400: [],     
            is_gri_400_loaded: false,
            material_flag: null,
            mouse_overed: [false, false, false],

            originalTopicSelected: null,
            originalMaterialSelected: null,
            originalIndicatorSelected: null,
            selectedTopicId: null,
            selectedGriCode: null,


            // 다른 컴포넌트에서 targetXXX 를 바꾸려고 할 때 쓰는 것
            targetTopic: null,
            targetMaterial: null,
            targetIndicator: null,
            targetMaterialIndicator: null,


        }
    },
    created(){
        this.readIndicatorsList(200)
        this.readIndicatorsList(300)
        this.readIndicatorsList(400)
        // this.readIndicatorsList(3)
    },
    computed:{
        ...mapState('report_list', ['report_code', 'standard_mode']),
        ...mapGetters('report_list', ['selected_report', 'gri_ver', 'tcfd_ver']),
        ...mapState('gri2021_indicators', ['indicator_payloads'])
        
    },
    watch: {
        // test
        // originalTopicSelected: {
        //     handler(newVal) {
        //         console.log("topic selected값 변경" + newVal)
        //     }
        // },
        // originalMaterialSelected: {
        //     handler(newVal) {
        //         console.log("material selected값 변경" + newVal)
                
        //     }
        // }
    },
    methods:{
        ...mapMutations('gri2021_indicators',['empty_topic_payloads', 'empty_indicator_payloads', 'upload_topic_payload']),
        readIndicatorsList(filter){
            const path = this.$store.state.backend_host + '/read_all_indicators_list'

            let gri_group_filter = filter
            let gri_version = this.gri_ver
            return axios.get(path, { params: {
                gri_group_filter: gri_group_filter, gri_version: gri_version
            }})
            .then( response => {
                // console.log(response.data);
                if(response.data.code=='success'){
                    if(gri_group_filter == 200){
                        this.gri_200 = response.data.result
                    }else if(gri_group_filter == 300){
                        this.gri_300 = response.data.result
                    }else {
                        this.gri_400 = response.data.result
                        this.is_gri_400_loaded = true
                    }
                }else console.log(response.data.msg);

            })
        },
        nowPart(num){
            if(num==200) {return this.gri_200}
            else if(num==300) {return this.gri_300}
            else return this.gri_400
            // if(num==200) {return this.makeHash(this.gri_200)}
            // else if(num==300) {return this.makeHash(this.gri_300)}
            // else return this.makeHash(this.gri_400)
        },
        getSameLength(o_array, index) {
            let rowspan = 1
            for (let i = index; i < o_array.length; i++) {
                if (i + 1 >= o_array.length) return rowspan
                if (o_array[i].topic_id == o_array[i + 1].topic_id) {
                rowspan += 1
                } else {
                return rowspan
                }
            }
        },
        sendLoad(flag){
            this.material_flag = flag
        },
        makeHash(new_list){
            if(new_list.length>0){
                let temp_list = [...new_list]
                let deep_list = []
                for (let i=0; i<temp_list.length; i++){
                    let temp_obj = {}
                    let temp_list_id = temp_list[i].topic_id
                    let new_list = temp_list.filter(x => x.topic_id == temp_list_id)
                    temp_obj[temp_list_id] = new_list

                    let new_idx = deep_list.findIndex(x => {return Object.keys(x) == temp_list_id})
                    if(new_idx==-1){
                        deep_list.push(temp_obj)
                    }                  
                }
                console.log(deep_list);
                return deep_list
            }   
        },
        // nowTopic(key_code, topic_id){
        //     let temp_arr = []
        //         if(key_code==200) {
        //             temp_arr = this.gri_200.map(x => x)
        //         }
        //         else if(key_code==300) {
        //             temp_arr = this.gri_300.map(x => x)
        //         }
        //         else {
        //             temp_arr = this.gri_400.map(x => x)
        //         }
        //     let new_arr = temp_arr.filter(x => x.topic_id = topic_id)
        //     console.log(new_arr);
        //     return new_arr.length
        // },
        // nowLength(key_code, topic_id){
        //     return this.nowTopic(key_code, topic_id) - (this.nowTopic(key_code, topic_id) - 1)

        // }

        changeOriginalTopic(flag, topic_id) {
            // topic selected값 변경

            // topic_id에 해당하는 gri_code를 가진 모든 indicator selected를 전부 변경

            let temp_arr = [...this.indicator_payloads]
            let new_obj = {}
            temp_arr.map((item) => {
                if (Object.keys(item)[0] === topic_id.toString()) {
                    new_obj[topic_id] = item[topic_id]
                } 
            })

            this.selectedTopicId = topic_id
            this.originalTopicSelected = flag

            if (Object.keys(new_obj).length === 0) {
                new_obj[topic_id] = [flag, this.originalMaterialSelected ? this.originalMaterialSelected : false]
            } else {
                new_obj[topic_id][0] = flag

            }

            this.upload_topic_payload(new_obj);

            if (!flag) {
                this.originalMaterialSelected = false
            }


            
        },
        changeOriginalMaterial(flag, topic_id, gri_code=null) {
            this.originalMaterialSelected = flag
            this.selectedTopicId = topic_id

            if (gri_code !== null && !flag) {
                this.changeOriginalIndicator(flag, gri_code)
            }

            let new_obj = {}
            new_obj[this.selectedTopicId] = [this.originalTopicSelected ?this.originalTopicSelected:false, this.originalMaterialSelected ? this.originalMaterialSelected:false]
            this.upload_topic_payload(new_obj);

        },
        changeOriginalIndicator(flag, gri_code){
            this.originalIndicatorSelected = flag
            this.selectedGriCode = gri_code
            
        },

        selectCurrentTopic(topic_id) {

            this.originalTopicSelected = document.querySelectorAll(`.gri_topic_${topic_id} input`)[0].checked;
            this.originalMaterialSelected = document.querySelectorAll(`.gri_topic_${topic_id} input`)[1].checked;
            this.selectedTopicId = topic_id;
        },

        changeTopicFromIndicator(topic_id, topic_value) {
            this.targetTopic = null
            if (topic_id === null || topic_value === null) {
            } else {
                this.targetTopic = {
                    "topic_id": topic_id,
                    "topic_value": topic_value
                }

            }

        },

        changeIndicatorFromTopic(topic_id, topic_value) {
            // topic에서 material값과 indicator값을 둘다 교체해보자!
            this.targetIndicator = null;
            if (topic_id === null || topic_value === null) {
                // this.targetMaterial = null;
            } else {
                this.targetIndicator = {
                    "topic_id": topic_id,
                    "topic_value": topic_value
                }
            }
            
        },


        changeMaterialFromElse(topic_id, material_value) {
            // topic에서 material값과 indicator값을 둘다 교체해보자!
            this.targetMaterial = null;

            if (topic_id === null || material_value === null) {
                
                // this.targetMaterial = null;
            } else {
                this.targetMaterial = {
                    "topic_id": topic_id,
                    "material_value": material_value
                }
            }
            
        },


        changeMaterialIndicatorFromMaterial(gri_code, value) {
            // topic에서 material값과 indicator값을 둘다 교체해보자!
            this.targetMaterialIndicator = null;
            if (gri_code === null || value === null) {
            } else {
                this.targetMaterialIndicator = {
                    "gri_code": gri_code,
                    "value": value
                }
            }
            
        },





    },
    beforeDestroy(){
        this.empty_topic_payloads()
        this.empty_indicator_payloads()
    }
}
</script>

<style>

</style>
<template>
<div class="py-4 mt-4 new-page">
    <!-- <b-card> -->
        <h2 class="fw-600 mb-3">GRI Content Index</h2>
        <b-table-simple 
            v-if="lang==='KOR'"
            class="disclosure-table lg border-bottom mb-5" 
        >
            
            <b-tbody>
                <b-tr>
                    <b-td class="bgray-200 px-3 fw-600">보고에 대한 성명</b-td>
                    <b-td class="px-3">{{ addJosa(selected_report.organization_name) }} 

                        <span v-if="is_in_accordance_with">
                            GRI 보고 표준에 따라 (in accordance with GRI Standards) 
                        </span>
                        <span v-else>
                            GRI 보고 표준을 참고하여 (with reference to GRI Standards) 
                        </span>
                        본 보고서를 작성 및 보고합니다.
                        
                        <br>
                        
                        (보고 기간 : {{selected_report.start_date}} ~ {{selected_report.end_date}})</b-td>
                </b-tr>
                <b-tr>
                    <b-td class="bgray-200 px-3 fw-600">GRI 기준</b-td>
                    <b-td class="px-3">GRI 1: Foundation 2021</b-td>
                </b-tr>
                <b-tr v-if="is_in_accordance_with">
                    <b-td class="bgray-200 px-3 fw-600">GRI 산업 표준 적용</b-td>
                    <b-td class="px-3">현재 적용 가능한 산업 표준 없음</b-td>
                </b-tr>
            </b-tbody>


        </b-table-simple>


        <b-table-simple 
            v-else-if="lang==='ENG'"
            class="disclosure-table lg border-bottom mb-5" 
        >
            <b-tbody >
                <b-tr>
                    <b-td class="bgray-200 px-3 fw-600">Statement of Use</b-td>
                    <b-td class="px-3">
                        <span v-if="is_in_accordance_with">
                            {{ selected_report.organization_name_e }} has reported in accordance with the GRI Standards <br> for the period {{selected_report.start_date}} ~ {{selected_report.end_date}} 
                        </span>
                        <span v-else>
                            {{ selected_report.organization_name_e }} has reported the information cited in this GRI content index <br> for the period {{selected_report.start_date}} ~ {{selected_report.end_date}} with reference to the GRI Standards.
                        </span>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td class="bgray-200 px-3 fw-600">GRI 1 used</b-td>
                    <b-td class="px-3">GRI 1: Foundation 2021</b-td>
                </b-tr>
                <b-tr v-if="is_in_accordance_with">
                    <b-td class="bgray-200 px-3 fw-600">Applicable GRI Sector Standard(s)</b-td>
                    <b-td class="px-3">Currently not applicable</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>



    <!-- </b-card> -->
</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {
            is_in_accordance_with: null,
        }
    },
    computed:{
        ...mapGetters('report_list', ['selected_report']),
        ...mapState('report_list', ['lang']),


    },
    methods: {
        async fetchTodoMaterialList() {
            try {
                const path = `${this.$store.state.backend_host}/check_material_indicator_written?rc=${this.selected_report.report_code}`
                const result = await axios.get(path);

                // console.log(result);

                this.is_in_accordance_with = result.data.is_in_accordance_with;
                
            } catch (error) {
                window.alert(error)
            }
        },
        addJosa(name) {
            const lastChar = name.charAt(name.length - 1);

            // 한글 범위의 유니코드 포인트 계산
            const koreanRange = (lastChar.charCodeAt(0) - '가'.charCodeAt(0));

            // 받침이 없으면 "는", 있으면 "은"을 추가
            return koreanRange % 28 === 0 ? name + "는" : name + "은";
        }

    },
    created() {
        this.fetchTodoMaterialList();
    }
    

}
</script>

<style scoped>
/* .disclosure-table > tbody > tr >.border-top-dark-bold {
    border-top: 2px solid var(--gray-700) !important;
}
.disclosure-table > tbody > tr >.border-bottom-dark-bold {
    border-bottom: 2px solid var(--gray-700) !important;
} */

@media print {
    .new-page{
        page-break-before: always;
    }
}
</style>
<template>
<div>
    <h4 id="bs1">기본정보</h4>
    <p class="f-95 mb-4 gray-600">보고서 기본 정보 입니다.</p>

    <b-card class="general-card mb-5">
        <b-card-header>보고서 현황</b-card-header>
        <div class="py-3">
            <b-row no-gutters class="hover-gray py-2">
                <b-col cols="3"><div class="gray-600">최초 생성일</div> </b-col>
                <b-col> {{ selected_report.created_date.slice(0,10) }} </b-col>
            </b-row>
            <b-row no-gutters class="hover-gray py-2">
                <b-col cols="3"><div class="gray-600">최신 업데이트</div> </b-col>
                <b-col> {{ latest_update != '' ? latest_update.slice(0,10) + ' ' + latest_update.slice(11,19) : '보고서 작성이 시작되면 이곳에 업데이트 일자가 기록됩니다.' }} </b-col>
            </b-row>
            <b-row no-gutters class="hover-gray py-2">
                <b-col cols="3"><div class="gray-600">소유자</div> </b-col>
                <b-col> {{ selected_report.owner_e_mail }} </b-col>
            </b-row>
            <b-row no-gutters class="hover-gray py-2">
                <b-col cols="3"><div class="gray-600">진행 상태</div> </b-col>
                <b-col> {{ stateToString(selected_report.state) }} 
                   <p class="pt-2 f-85 mb-0 gray-600"> <fa icon="info-circle" class="mr-1"/>진행 상태 순서: 1) 생성됨 → 2) 지표 선택 완료 → 3) 보고서 작성중 → 4) 공개됨</p>
                </b-col>
            </b-row>
        </div>
    </b-card>

    <b-card class="general-card mb-5">
        <b-card-header>보고 표준</b-card-header>
        <div class="py-3">
            <standard-setting />
        </div>       
    </b-card>      
    
    <b-card class="general-card mb-5">
        <b-card-header>
            Data Center
            <b-badge variant="info">Beta</b-badge> 
        </b-card-header>
        <div class="py-3">
            <h5
            v-if="!edit_data_center_use"
            >
                Data center 기능 사용
                <b-spinner 
                    v-if="use_data_center===null"
                    class="ml-2"
                    label="Spinning" 
                />
                <b-icon
                    v-else-if="use_data_center"
                    class="ml-2"
                    scale="1.5"
                    variant="success"
                    icon="check2-square"
                    aria-hidden="true"
                />
                <b-icon
                    v-else
                    class="ml-2"
                    scale="1.3"
                    variant="danger"
                    icon="dash-square"
                    aria-hidden="true"
                />
            </h5>
            
            <b-form-checkbox
                v-else
                class="mt-3"
                v-model="use_data_center"
            >
                Data Center 기능 사용
            </b-form-checkbox>

            

            <b-button
            :variant="edit_data_center_use === true ? 'teal' : 'teal-border'"
            class="px-3 mr-3 mt-4"
            @click="handleDataCenterUse"
            >
             <span
                v-if="!edit_data_center_use"
             >
                 사용여부 수정하기
             </span>
             <span
                v-else
             >
                사용여부 저장하기
             </span>
            </b-button>
        </div>       
    </b-card>   

    <b-card class="general-card mb-5">
        <b-card-header>보고서 기본 정보</b-card-header>
        <div class="py-3">
            <!--보고서 명칭-->
            <b-row no-gutters class="hover-gray py-2" :class="is_editing===true?'mb-3':''">
                <b-col cols="3"><div class="gray-600">보고서 명칭</div> </b-col>
                <b-col v-if="is_editing===true"> <b-form-input type="text" placeholder="보고서 명 (ex. 2020년 퀀티파이드이에스지 지속가능경영 보고서)" v-model.trim="local_report.report_name"></b-form-input> </b-col>  
                <b-col v-else> {{ local_report.report_name }} </b-col>
            </b-row>

            <!--보고 기간-->
            <b-row no-gutters class="hover-gray py-2" :class="is_editing===true?'mb-3':''">
                <b-col cols="3"><div class="gray-600">보고 기간</div> </b-col>  
                <b-col v-if="is_editing===true">
                    <div class="d-md-flex mb-2">
                        <b-form-datepicker v-model="local_report.start_date"></b-form-datepicker><span class="px-3">~</span> 
                        <b-form-datepicker v-model="local_report.end_date"></b-form-datepicker>
                    </div>
                    <p class="f-85 gray-600 mb-0"> <fa icon="info-circle" class="mr-1"/>보고기간은 회계연도를 기준으로 설정하실 것을 권고드립니다.</p>
                </b-col>  
                <b-col v-else> {{ local_report.start_date }} ~ {{ local_report.end_date }} </b-col>
            </b-row>

            <!--기관/조직-->
            <b-row no-gutters class="hover-gray py-2" :class="is_editing===true?'mb-3':''">
                <b-col cols="3"><div class="gray-600">기관/조직</div> </b-col>  
                <b-col v-if="is_editing===true"> 
                    <b-input-group>
                    <b-form-input class="mb-2" v-model="search_keyword" placeholder="기관명으로 검색" @keyup.enter="searchOrganization(search_keyword)"></b-form-input>
                    <b-input-group-append><b-button class="mb-2" variant="teal-border" @click="searchOrganization(search_keyword)"><fa icon="search" /></b-button></b-input-group-append>
                    </b-input-group>
                        <p class="f-85 gray-600" v-if="new_organization_name==true"> <fa icon="info-circle" class="mr-1"/>검색된 기관이 없습니다. 아래의 항목을 기입해주시기 바랍니다.</p>
                        <div class="mb-2" v-if="searched_organization_list.length > 0"> 
                            <b-list-group id="company-search-list">
                                <b-list-group-item button class="px-3 py-2 search-result" v-for="item in searched_organization_list" :key="item.key" @click="selectOrganization(item.id)">
                                    <div class="f-90 gray-700"> {{ item.organization_name }}, {{ item.address }} <span class="f-80 gray-500">(주소)</span></div>
                                </b-list-group-item>
                            </b-list-group>
                            <p v-if="is_selected == true" class="f-85 gray-600 mt-2"> <fa icon="info-circle" class="mr-1"/>선택된 기관 정보가 입력되었습니다.</p>
                            <p v-else class="f-85 gray-600 mt-2"> <fa icon="info-circle" class="mr-1"/>목록에 찾으시는 기관명이 없다면, 아래의 항목을 기입해주시기 바랍니다.</p>
                        </div>

                    <!-- 선택된 기관 또는 기관 새로 등록하기 -->
                    <b-card class="bgray-200 border-0">
                        <b-row class="mb-2">
                            <b-col cols="12" md="3"><div class="f-95">기관명</div> </b-col>  
                            <b-col class="d-xl-flex">
                                <b-form-input type="text" v-model.trim="local_report.organization_name" class="narrow-input-form mb-1 mb-xl-0 mr-0 mr-xl-2" placeholder="기관명 국문"></b-form-input> 
                                <b-form-input type="text" v-model.trim="local_report.organization_name_e" class="narrow-input-form" placeholder="기관명 영문"></b-form-input> 
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col cols="12" md="3"><div class="f-95">본사주소</div> </b-col>  
                            <b-col><b-form-input type="text" v-model="local_report.address" class="narrow-input-form" placeholder="본사 주소"></b-form-input> </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="3"><div class="f-95">CI/로고</div> </b-col>
                            <b-col>
                                <b-form-file type="text" v-model="local_report.logo" @change="handleLogoChange"  class="narrow-input-form" ></b-form-file>
                                <b-img v-if="local_report.logo_path && local_report.logo_path.startsWith('blob')" :src="local_report.logo_path" fluid></b-img>
                                <b-img v-if="local_report.logo_path && !local_report.logo_path.startsWith('blob')" :src="$store.state.static_host+local_report.logo_path" fluid></b-img>
                                <p v-if="local_report.logo_name" class="my-1 f-85">{{ local_report.logo_name }} </p>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col v-else> 
                    <p class="mb-2 fw-400"> <fa icon="building" class="mr-1"/> {{ local_report.organization_name }} {{ local_report.organization_name_e? '/':'' }} {{ local_report.organization_name_e }} </p>
                    <p v-if="local_report.address" class="fw-400"> <fa icon="map-marker-alt" class="mr-1"/> {{ local_report.address }} </p>
                    <p v-else class="mb-2"><fa icon="map-marker-alt" class="mr-1"/>(보고 조직의 주소를 입력해 주세요.)</p>
                    <div v-if="local_report.logo_name" class="bgray-200 py-1 px-3 mr-3">
                        <div style="height: 100px">
                        <b-img v-if="local_report.logo_path" :src="$store.state.static_host+local_report.logo_path" class="h-100"></b-img></div>
                        <a class="my-1 f-80 cursor" target="_blank" :href="local_report.logo_path"> {{ local_report.logo_name }} </a>
                    </div>
                    <div v-else>
                        <p><fa icon="image" class="mr-1"/> (보고 조직의 로고를 등록해 주세요.) </p>
                    </div>
                </b-col>
            </b-row>

            <!--보고서 개요-->
            <b-row no-gutters :class="is_editing===true?'mb-3':''" class="hover-gray py-2 mb-5">
                <b-col cols="3"><div class="gray-600">보고서 개요</div> </b-col>  
                <b-col v-if="is_editing===true"> <b-form-textarea rows="3" v-model="local_report.description" placeholder="보고서 작성 목적 등 개요를 작성해주세요."></b-form-textarea> </b-col> 
                <b-col v-else> {{local_report.description==undefined? '-': local_report.description }} </b-col> 
            </b-row>
            <b-button :variant="is_editing===true?'teal':'teal-border'" class="px-3 mr-3" @click="handleMulti"> {{ is_editing===true ?'기본정보 저장하기':'기본정보 수정하기'}} </b-button>
            <b-button v-if="is_editing===true" class="px-3" @click="handleCancle"> 취소 </b-button>
        </div>
    </b-card>

</div>    
</template>
<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import * as ih from '@/components/util'
import StandardSetting from './StandardSetting.vue'
import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'

export default {
    mixins: [gri_mixin],
    components: { StandardSetting },
    data() {
        return {
            is_editing: false,
            local_report : {},
            search_keyword: '',

            searched_organization_list: [],
            new_organization_name: false,
            is_selected: false,

            latest_update: '',
            edit_data_center_use: false,
            use_data_center: null,
        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState(['user_id']),
        ...mapGetters('report_list', ['selected_report']),
    },
    methods:{
        ...mapMutations('report_list', ['add_or_update_report']),
        ...mapActions('report_list', ['saveReportPreference']),
        ...mapActions("charts", ["getDCToC", "getChartList"]),
        handleLogoChange(e) {
            this.local_report.logo = e.target.files[0]
            this.local_report.logo_path = URL.createObjectURL(e.target.files[0]);
            this.local_report.logo_name = e.target.files[0].name

            this.local_report = {
                ...this.local_report,
            }

        },
        handleMulti(){
            // if (this.selected_report.state<4){
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return;

            if(this.is_editing===true){
                this.handleSave()
            }
            else {
                this.toggleEdit()
            }
            //} else alert(`공개된 보고서는 수정할 수 없습니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 내용을 수정할 수 있습니다.`)
        },
        async handleDataCenterUse() {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return;

            if(this.edit_data_center_use===true){
                // this.handleSave()
                this.edit_data_center_use = false;

                try {
                    const response = await axios.post(this.$store.state.backend_host + "/update_data_center_option", {
                        "report_code": this.report_code,
                        "use_dc": this.use_data_center
                    })
                    if (response.data.code) {
                        this.add_or_update_report({
                            report_code: this.report_code,
                            new_report_obj: {
                                ...this.selected_report,
                                data_center: response.data.result.use_dc
                            }
                        })

                        this.getDCToC({ "report_code": this.report_code });
                        this.getChartList({
                            report_code: this.report_code,
                            is_editing: true,
                        });

                    } else {
                        window.alert(response.data.msg)
                    }
                } catch (error) {
                    window.alert(error)
                }




            }
            else {
                // this.toggleEdit()
                this.edit_data_center_use = true;

            }
            
        },
        async handleCancle(){
            await this.initiateReport()
            this.toggleEdit()
        },
        toggleEdit(){
            this.is_editing = !this.is_editing
        },
        async handleSave(){
            let new_logo = null
            if (this.local_report.logo){ 
                new_logo = this.local_report.logo
            }
            if(this.checkForm()==true){
                await this.saveReportPreference( {
                    report_code : this.report_code,
                    report_name : this.local_report.report_name,
                    start_date : this.local_report.start_date,
                    end_date : this.local_report.end_date,
                    description : this.local_report.description,
                    organization_id: this.local_report.organization_id,
                    organization_name : this.local_report.organization_name,
                    organization_name_e : this.local_report.organization_name_e,
                    address : this.local_report.address,
                    logo : new_logo,
                })
                this.initiateReport()
                this.$EventBus.$emit('make-toast', '기본 정보 저장', `${this.local_report.report_name} 기본 정보가 저장되었습니다.`)
                this.is_editing = false
            }
        },
        async initiateReport(){
            let temp_report = {
                organization_name: null,
                organization_name_e: null,
                address:null,
                logo_name: null,
                logo_path: null
            }
            if (this.selected_report!={}&&this.selected_report!=undefined){
                temp_report.report_code = this.selected_report.report_code
                temp_report.report_name = this.selected_report.report_name
                temp_report.created_date = this.selected_report.created_date
                temp_report.start_date = this.selected_report.start_date
                temp_report.end_date = this.selected_report.end_date
                temp_report.description = this.selected_report.description
                temp_report.organization_id = this.selected_report.organization_id
                temp_report.organization_name = this.selected_report.organization_name
                temp_report.organization_name_e = this.selected_report.organization_name_e
                temp_report.address = this.selected_report.address
                temp_report.logo_name = this.selected_report.logo_name
                temp_report.logo_path = this.selected_report.logo_path
                let new_file = await ih.getFileAsData(this.selected_report.logo_name, this.selected_report.logo_path, false)
                temp_report.logo = new_file
            }

            this.local_report = temp_report
            this.search_keyword = ''
            this.searched_organization_list = []
            // console.log(this.local_report)

            this.use_data_center = this.selected_report.data_center
        },
        checkForm(){
            if (this.local_report.report_name==''||this.local_report.report_name==null){ alert('보고서 명칭을 입력해 주세요')
                return false
            }
            else if (this.local_report.start_date==''||this.local_report.start_date==null){ alert('보고 기간 시작 시점을 입력해 주세요')
                return false
            }
            else if (this.local_report.end_date==''||this.local_report.end_date==null){ alert('보고 기간 종료 시점을 입력해 주세요')
                return false
            }
            else if (this.local_report.organization_name==''|this.local_report.organization_name==null){ alert('보고 기관명을 입력해 주세요')
                return false
            }
            else return true
        },
        stateToString(state){
            if (state == 0){ return `기본설정` }
            else if (state == 1){ return `지표 선택` }
            else if (state==2) { return `작성`}
            else if (state==3) { return `검증`}
            else if (state==4) { return `공개`}
            else return ''
        },
        searchOrganization(search_keyword){
            if( this.search_keyword == '' ){
                this.$EventBus.$emit('make-toast', '기관명 입력', `검색할 기관명을 입력해주시기 바랍니다.`)
                this.new_organization_name = true
                this.searched_organization_list = []
            } else {
                const path = this.$store.state.backend_host + '/search_organization'
                return axios.get(path,{ params: {
                    search_keyword: this.search_keyword
                }})
                .then( response => {
                    // 중복 제거 리스트만들기
                    let temp_searched_list = response.data
                    let deduplicated_list = [] 
                    let count = 0
                    temp_searched_list.forEach( item => {
                        let idx = deduplicated_list.findIndex( org => org.organization_name == item.organization_name && org.address == item.address )
                        if( idx < 0 ){
                            deduplicated_list.push(item)
                        }
                    })
                    this.searched_organization_list = deduplicated_list
                    // console.log(this.searched_organization_list)

                    if( this.searched_organization_list.length < 1 ){
                        this.new_organization_name = true
                    } else {
                        this.new_organization_name = false
                    }
                })
            }
        },
        async selectOrganization(id){
            this.is_selected = true
            let idx = this.searched_organization_list.findIndex( org => org.id == id )
            this.local_report.organization_id = this.searched_organization_list[idx].id
            this.local_report.organization_name = this.searched_organization_list[idx].organization_name
            this.local_report.organization_name_e = this.searched_organization_list[idx].organization_name_e
            this.local_report.address = this.searched_organization_list[idx].address
            this.local_report.logo_name = this.searched_organization_list[idx].logo_name
            this.local_report.logo_path = this.searched_organization_list[idx].logo_path
            let new_logo = await ih.getFileAsData(this.searched_organization_list[idx].logo_name, this.searched_organization_list[idx].logo_path, false)
            this.local_report.logo = new_logo
        },
        readTimestamp(){
            const path = this.$store.state.backend_host + '/read_timestamp'
            return axios.get(path, {params : { report_code: this.report_code }})
            .then( response => {
                let temp_list = response.data
                temp_list.forEach( item => {
                    let idx = temp_list.findIndex( x => item.time_stamp < x.time_stamp )
                    if( idx == -1 ){
                        this.latest_update = item.time_stamp
                        return
                    }
                })
            })
        },
        // handleOrg(){
        //     this.local_report.organization_name = null
        //     this.local_report.organization_name_e = null
        //     this.local_report.address = null
        //     this.local_report.logo_name = null
        //     this.local_report.logo_path = null
        // }
    },
    created(){
        this.readTimestamp()
        this.initiateReport()
        // if( this.report_code == ''){console.log('저장된 report_code가 없어서 새로 만들었습니다.: ' + this.target_report_code)}else{console.log('지금 이 보고서의 report_code는 다음과 같습니다: '+ this.report_code)}
        // if( this.selected_report == undefined ){console.log('선택된 report가 없습니다.')}else{console.log(this.selected_report)})
    },
    mounted() {
        this.initiateReport()
        
    },
}
</script>
<template>
<div>
    <b-button-group class="mb-5">
        <b-button :variant="manager_mode=='read' ? 'dark' : 'gray'" :pressed="manager_mode=='read'" @click="manager_mode='read'"><h5 class="mb-2">보낸 공지사항</h5></b-button>
        <b-button :variant="manager_mode=='send' ? 'dark' : 'gray'" :pressed="manager_mode=='send'" @click="manager_mode='send'"><h5 class="mb-2">공지사항 보내기</h5></b-button>
    </b-button-group>

    <!--보낸 공지사항-->
    <b-container v-if="manager_mode=='read'">
        <b-table-simple>
            <b-thead>
                <b-tr>
                    <b-td>발신일</b-td><b-td>제목</b-td><b-td>발신인</b-td>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="notice in current_notices" :key="notice.id">
                    <b-td>{{ notice.time_stamp }}</b-td>
                    <b-td><a class="cursor" v-b-toggle="'n'+notice.notice_id">{{ notice.title }}</a>
                        <b-collapse :id="'n'+notice.notice_id">
                            <b-card>
                                    <b-button class="mb-1 mr-1" size="sm" variant="white" disabled>수신인:</b-button>
                                    <b-button class="mb-1 mr-1" size="sm" variant="light" v-for="receiver in notice.receivers" :key="receiver.index" @click="handleAddReceiver(receiver.e_mail)"> {{ receiver.e_mail }} </b-button>
                                <div><p>{{ notice.content }}</p></div>
                                <div align="right"><b-button class="mr-1" size="sm" variant="white" @click="resendForReceivers(notice.receivers)">수신인 전체 복사하기</b-button> <b-button class="mr-1" size="sm" variant="white" @click="copyContents(notice.title, notice.content)">제목과 내용 복사하기</b-button></div>
                            </b-card>
                        </b-collapse>
                    </b-td>
                    <b-td>{{ notice.sender_email }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    <div class="notice-pagination pt-5">
        <b-pagination
            v-model="current_page"
            :total-rows="local_notice_list.length"
            :per-page="per_page"
            aria-controls="notice-table"
            align="center" class="notice-seperator"
        >
            <template v-slot:prev-text><fa icon="angle-left"/></template>
            <template v-slot:next-text><fa icon="angle-right"/></template>
        </b-pagination>
    </div>
    </b-container>

    <!--공지사항 보내기-->
    <b-container class="mb-5" v-else>
        <b-row class="mb-3">
            <b-col class="col-2 px-0"><h5>수신인 선택하기</h5></b-col>
            <b-col>
                <b-button-group>
                    <b-button variant="light" :pressed="receiver_select_mode=='for_all'" @click="selectReceiver('for_all')" :disabled="on_sending==true">모두에게</b-button>
                    <b-button variant="light" :pressed="receiver_select_mode=='by_report'" @click="selectReceiver('by_report')" :disabled="on_sending==true">보고서에서 선택</b-button>
                    <b-button variant="light" :pressed="receiver_select_mode=='by_person'" @click="selectReceiver('by_person')" :disabled="on_sending==true">전체 사용자 목록에서 선택</b-button>
                </b-button-group>
            </b-col>
        </b-row>
        <!--보고서에서 선택-->
        <b-row class="mb-3" v-if="receiver_select_mode=='by_report'">
            <b-col class="col-2 px-0"></b-col>
            <b-col>
                <b-form-select class="mb-2" v-model="selected_report_name" :options="local_report_list" placeholder="보고서를 선택해주세요." :disabled="on_sending==true" @change="showByReport()"></b-form-select>
                <b-button class="mr-1 mb-1" v-if="selected_report_owner!=''" size="sm" variant="outline-dark" @click="addReceiver(selected_report_owner)">{{ selected_report_owner + ' | owner' }}</b-button>
                <b-button class="mr-1 mb-1" v-for="coauthor in selected_report_coauthor" :key="coauthor.index" size="sm" variant="outline-dark" @click="addReceiver(coauthor.e_mail)">{{ coauthor.e_mail + ' | coauthor' }}</b-button>
            </b-col>
        </b-row>
        <!--전체 사용자 목록에서 선택-->
        <b-row class="mb-3" v-if="receiver_select_mode=='by_person'">
            <b-col class="col-2 px-0"></b-col>
            <b-col>
                <b-input-group class="mb-2">
                <b-form-input v-model="search_keyword" placeholder="찾으시는 사용자 이름을 입력해주세요" @keyup.enter="searchUserName(search_keyword)"></b-form-input>
                <b-input-group-append><b-button @click="searchUserName(search_keyword)" variant="teal"><fa icon="search"/></b-button></b-input-group-append>
                </b-input-group>
                    <p class="f-85 red pt-2" v-if="not_found==true"> <fa icon="info-circle" class="mr-1"/>검색된 사용자가 없습니다. 검색어를 다시 입력하거나, 아래에서 직접 선택해주세요.</p>
                        <div class="mb-2"> 
                        <b-list-group id="industry-search-list">
                        <b-list-group-item button class="px-3 py-2 search-result" v-for="user in searched_user_list" :key="user.index" @click="addReceiver(user.e_mail)">
                        <div class="f-90 gray-700"> {{ user.e_mail + ' | ' + user.user_name }} </div>
                        </b-list-group-item>
                        </b-list-group>
                        <!--<p class="f-85 teal"> <fa icon="info-circle" class="mr-1"/>목록에 찾으시는 사용자가 없다면, 아래에서 직접 선택해주세요.</p>-->
                        </div>
                <b-button class="mr-1 mb-1" v-for="user in local_user_list" :key="user.index" size="sm" variant="outline-dark" @click="addReceiver(user.e_mail)">{{ user.user_name + ' | ' + user.e_mail }}</b-button>
            </b-col>
        </b-row>
        <!--수신인 목록 화면 표시-->
        <b-row class="mb-3">
            <b-col class="col-2 px-0"><h5>선택된 수신인</h5></b-col>
            <b-col>
                <b-button class="mr-1" v-for="email in target_emails" :key="email" variant="light" size="sm" @click="extractReceiver(email)">{{ email }}</b-button><b-button variant="white" disabled>{{ count_receivers }}</b-button><b-button v-if="target_emails.length>0 || receiver_select_mode=='for_all'" variant="outline-dark" @click="resetReceiver()"><fa icon="redo-alt"></fa></b-button>
            </b-col>
        </b-row>
        <!--내용 작성-->
        <b-row class="mb-3">
            <b-col class="col-2 px-0"><h5> 내용 작성 </h5></b-col>
            <b-col class="">
                <b-form-input v-model="notice_title" label="제목" placeholder="제목을 입력해 주세요" class="mb-3" :disabled="on_sending==true"></b-form-input>
                <b-form-textarea v-model="notice_content" placeholder="내용을 입력해 주세요" rows="8" max-rows="8" class="mb-3" :disabled="on_sending==true"></b-form-textarea>
            </b-col>
        </b-row>
        <!--공지사항 보내기-->
        <b-row class="mb-3 text-center">
            <b-col></b-col>
            <b-col v-if="on_sending!=true"><b-button variant="blue" @click="handleSend()" :disabled="on_sending==true"><h5><fa icon="paper-plane"></fa> 공지하기 </h5></b-button></b-col>
            <b-col v-else><b-button variant="blue" disabled><h5><b-spinner small></b-spinner> 전송중 </h5></b-button></b-col>
            <b-col></b-col>
        </b-row>

    </b-container>

    
</div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
    data(){
        return{
            local_notice_list: [],
            local_user_list: [],
            current_page: 1,
            per_page: 20,

            target_emails: [], // 공지를 받을 수신인 명단
            notice_title: '',
            notice_content: '',
            on_sending: false,

            manager_mode: 'read', // 'read': 보낸 공지사항, 'send': 공지사항 보내기
            receiver_select_mode: '', // 'for_all', 'by_report', 'by_person'

            searched_user_list: [],
            selected_report_name: '',
            selected_report_owner: '',
            selected_report_coauthor: [],

            search_keyword: '',
            not_found: false
        }
    },
    computed: {
        ...mapState(['user_id', 'e_mail']),
        ...mapState('admin', ['report_list', 'report_code']),
        current_notices(){
            let total_num = this.local_notice_list.length
            let start_index = (this.current_page - 1) * this.per_page
            let end_index = Math.min((this.current_page - 1) * this.per_page + this.per_page, total_num)
            return this.local_notice_list.slice(start_index, end_index)
        },
        local_report_list(){
            let temp_list = []
            this.report_list.forEach(report => temp_list.push(report.report_name))
            return temp_list
        },
        count_receivers(){
            return "총 " + this.target_emails.length + "명"
        }
    },
    methods: {
        readUsersForAdmin(){
            const path = this.$store.state.backend_host + '/read_users_for_admin'
            axios.get(path)
            .then(response => {
                this.local_user_list = response.data
            })
            .catch(error => 
                console.log(error)
            )
        },
        // 수신인 선택, 추가, 삭제
        selectReceiver(mode){
            if(mode == 'for_all'){
                if(confirm('모든 사용자를 선택하시겠습니까?') == true){
                    this.receiver_select_mode = mode
                    let temp_list = []
                    this.target_emails = []
                    this.local_user_list.forEach(item => temp_list.push(item.e_mail))
                    this.target_emails = temp_list
                }
            } else if(mode == 'by_report'){
                this.receiver_select_mode = mode
                console.log(mode)
            } else if(mode == 'by_person'){
                this.receiver_select_mode = mode
                console.log(mode)
            } else {
                console.log('mode가 잘못되었습니다.')
            }
        },
        // 선택한 이메일을 target_emails에 추가함. 
        addReceiver(email){
            let idx = this.target_emails.findIndex(item => item == email)
            if( idx > -1 ){
                alert(email+"은 이미 선택되었습니다.")
                return false
            }
            this.target_emails.push(email)
            if( this.manager_mode == 'read'){
                this.$EventBus.$emit('make-toast', '추가 완료', '수신인 목록에 추가하였습니다. 공지사항 보내기에서 확인하세요.')
            }
        },
        // targe_emails를 빈 배열로 초기화함.
        resetReceiver(){
            if(confirm('수신인을 다시 선택하시겠습니까? 수신인 목록이 초기화됩니다.') == true){
                this.target_emails = []
                if(this.receiver_select_mode != 'for_all'){

                }
                    this.receiver_select_mode = ''
            }
        },
        // 선택한 수신인을 target_emails에서 제외함.
        extractReceiver(email){
            if(confirm(email+"을 수신인에서 제외합니다.") == true){
                let idx = this.target_emails.findIndex( item => item == email)
                if(idx > -1){
                    this.target_emails.splice(idx, 1)
                }
            }
        },

        // 사용자 검색하기. 사용자 이름으로 키워드 검색함.
        searchUserName(keyword){
            const path = this.$store.state.backend_host + '/search_user_for_admin'
            axios.get(path, {params: { keyword: keyword }})
            .then( response => {
                this.searched_user_list = response.data
                if(this.searched_user_list.length == 0){
                    this.not_found = true
                } else {
                    this.not_fount = false
                }
            })
            .catch( error => {
                console.log(error)
            })
        },
        // 리포트 검색하기. 리포트 이름을 기준으로 검색하고, owner와 coauthor를 분리하여 data 저장함. 
        showByReport(){
            let idx = this.report_list.findIndex( report => report.report_name == this.selected_report_name)
            if( idx > -1 ){
                console.log(this.report_list[idx])  // 선택된 보고서 정보를 console에서 확인하세요.
                this.selected_report_owner = this.report_list[idx].owner_e_mail
                if( this.report_list[idx].coauthors.length > 0 ){
                    this.selected_report_coauthor = this.report_list[idx].coauthors
                }
            } else {
                console.log('해당 보고서 이름으로 찾을 수 없습니다.')
            }
        },

        // 공지 보내기
        handleSend(){
            if(this.notice_title=='' || this.notice_title==null || this.notice_title==undefined){
                alert('공지의 제목을 기입해주세요.')
                return false
            }
            if(this.notice_content=='' || this.notice_content==null || this.notice_content==undefined){
                alert('공지할 내용을 기입해주세요.')
                return false
            }
            if(this.target_emails.length<1){
                alert('공지사항의 수신인을 선택해주세요.')
                return false
            }
            this.on_sending = true
            //axios 통신
            const path = this.$store.state.backend_host + '/save_notice'
            let formData = new FormData()
            formData.append('title', this.notice_title)
            formData.append('content', this.notice_content)
            formData.append('sender_email', this.e_mail)
            formData.append('target_emails', JSON.stringify(this.target_emails))  // json으로 변환해야하는데...
            return axios.post(path, formData)
            .then(response => {
                this.$EventBus.$emit('make-toast', '발송 완료', '공지사항 발송이 완료되었습니다.')
                //발송 후 초기화
                this.notice_title = ''
                this.notice_content = ''
                this.target_emails = []
                this.on_sending = false
                this.readNoticesForAdmin()
            })
            .catch( error => {
                console.log(error)
            })
        },

        // 보낸 공지사항
        readNoticesForAdmin(){
            const path = this.$store.state.backend_host + '/read_notices_for_admin'
            return axios.get(path)
            .then(response => {
                this.local_notice_list = response.data
            })
            .catch(error => 
                console.log(error)
            )
        },
        // 다시 보내기. 선택한 공지사항의 수신인들로 target_emails를 대체함.
        resendForReceivers(receivers){
            if(confirm("전체 수신인에게 공지사항을 다시 작성하시겠습니까?")==true){
                let temp_list = []
                receivers.forEach( user => temp_list.push(user.e_mail))
                this.target_emails = temp_list
                this.receiver_select_mode = ''
                this.$EventBus.$emit('make-toast', '복사 완료', '수신인 목록을 복사하였습니다. 공지사항 보내기에서 확인하세요.')
            }
        },
        // 보낸 공지사항에서 선택한 이메일을 target_emails에 하나씩 추가함.
        handleAddReceiver(email){
            if( this.manager_mode == 'read' ){
                if( confirm(email+'을 수신인으로 선택하겠습니까?') == true ){
                    this.addReceiver(email)
                }
            }
        },
        // 보낸 공지사항 내용 복사하기
        copyContents(title, content){
            console.log(title, content)
            if(confirm('공지 제목과 내용을 복사하겠습니까?') == true){
                this.notice_title = title
                this.notice_content = content
                this.$EventBus.$emit('make-toast', '복사 완료', '공지 제목과 내용을 복사하였습니다. 공지사항 보내기에서 확인하세요.')
            }
        }
    },
    created(){
        this.readNoticesForAdmin()
        this.readUsersForAdmin()
    },
    watch:{
    }
}
</script>

<style>

</style>
<template>
    <div>
        <div role="tablist">    
            <b-card class="material-check-card shadow-sm"> 
                <div v-b-toggle.material-list role="tab" class="cursor">
                    <div class="fw-500 mb-2">선택된 중요 주제<div><span class="fw-500 f-95">: {{current_material_topics.length}}</span>개</div></div>
                    <!-- <p class="f-85 gray-550 mb-0">GRI 200 ~ 400의 중 최소 1개 이상을 중요 주제로 선택해 주세요.</p> -->
                    <a class="f-90">자세히 보기</a>
                </div>
                <b-collapse id="material-list" role="tabpanel" accordion="my-accordion">
                    <div class="border-top-bottom overflow-auto py-2 f-90 my-2" style="max-height: calc(85vh - 460px);">
                        <div class="mb-2 gray-600 line-height-sm" v-for="item in current_material_topics" :key="item.id"><span class="mr-1 fw-300">{{item.topic_id}}</span> {{item.topic_name}}
                            <!-- <span class="f-75 mb-0 gray-400 fw-300">{{item.topic_name_e}}</span> -->
                        </div>            
                    </div>
    
                    <div class="pt-1"><a v-b-modal.material-topic-info class="cursor f-90"><fa icon="info-circle"/> 중요 주제 선택 가이드 <fa icon="expand" class="ml-1"/> </a></div>
                </b-collapse>
            </b-card>
        </div>
    
        <b-modal id="material-topic-info" class="mt-2" title="중요 주제 선택 가이드" size="lg" hide-footer centered>
            <material-topic-info class="pb-3"></material-topic-info>
        </b-modal>
    
    </div>
    </template>
    
    <script>
    import MaterialTopicInfo from '@/components/managing_indicators/indicator2016_components/MaterialTopicInfo'
    import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
    
    export default {
        data() {
            return {
                
            }
        },
        computed:{
            ...mapState('report_list', ['report_code']),
            ...mapState('report_indicators', ['indicators_list', 'topics_list']),
            ...mapGetters('report_indicators', ['gri_200to400_all_topics']),
            // ...mapState('report_indicators', ['gri_option_status']),
            current_material_topics(){
                return this.gri_200to400_all_topics.filter(x => x.material==1)
            },
        },
        methods:{
            // ...mapMutations('report_indicators', ['update_gri_option_status']),
            // ...mapActions('report_indicators', ['checkGriOption']),
    
        },
        created(){
            // if(this.gri_option_status=={}||this.gri_option_status.is_core_aligned==undefined){
            //     this.checkGriOption(this.report_code)
            // }
        },
        mounted(){
            // console.log(this.gri_option_status);
        },
        beforeDestroy(){
            // this.update_gri_option_status({})
        },
        components:{
            MaterialTopicInfo
        }
    
    }
    </script>
    
    <style>
    .material-check-card > .card-body {
        padding: 1rem 1.25rem;
    }
    </style>
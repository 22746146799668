<template class="report-view">
    <div v-if="selected_report"
        class="position-relative overflow-hidden"
        :style="`font-family: ${selected_report.font_family}`"
    >   
        <b-overlay :show="loadingShow" no-wrap></b-overlay>
        <!-- <template v-if="theme_option && theme_option.layout_code === 'full_view' && show_dc_table">
            <viewer-header-d-c
                :theme_option="theme_option"
                :dark_logo_url="dark_logo_url"
                :main_bg_color_gradient="main_bg_color_gradient"
                :content_list_open="content_list_open"
                :show_nav="show_nav"
                :web_view="true"
                :show_sasb_table="show_sasb_table"
                :show_gri_table="show_gri_table"
                :show_tcfd_table="show_tcfd_table"
                :show_dc_table="show_dc_table"
                :active_dc_table="active_dc_table"
                :active_gri_table="active_gri_table"
                :active_sasb_table="active_sasb_table"
                :active_tcfd_table="active_tcfd_table"
                @showTableView="showTableView"
                />
        </template> -->

        <template v-if="theme_option && theme_option.layout_code === 'full_view'">
            <viewer-header-basic :theme_option="theme_option" :dark_logo_url="dark_logo_url"
                :main_bg_color_gradient="main_bg_color_gradient" :content_list_open="content_list_open"
                :show_nav="show_nav" :web_view="true" :show_sasb_table="show_sasb_table"
                :show_gri_table="show_gri_table" :show_tcfd_table="show_tcfd_table" :show_dc_table="show_dc_table"
                :active_dc_table="active_dc_table" :active_gri_table="active_gri_table"
                :active_sasb_table="active_sasb_table" :active_tcfd_table="active_tcfd_table"
                @showTableView="showTableView" />
        </template>

        <template>
            <DataCenterHeaderNav v-if="show_dc_table && theme_option.layout_code !== 'bh_test'"
                :theme_option="theme_option" :dark_logo_url="dark_logo_url"
                :main_bg_color_gradient="main_bg_color_gradient" :content_list_open="content_list_open"
                :show_nav="show_nav" :web_view="true" :show_sasb_table="show_sasb_table"
                :show_gri_table="show_gri_table" :show_tcfd_table="show_tcfd_table" :show_dc_table="show_dc_table"
                :active_dc_table="active_dc_table" :active_gri_table="active_gri_table"
                :active_sasb_table="active_sasb_table" :active_tcfd_table="active_tcfd_table"
                @showTableView="showTableView" />
        </template>
        <div v-if="theme_option && theme_option.layout_code === 'sk_chemical'">
            <div v-if="selected_report" class="position-relative overflow-hidden"
                :style="`font-family: ${selected_report.font_family}`">
                <div class="position-relative">
                    <viewer-header-basic-sk-chemical :web_view="true"
                        :show_printing_btn="theme_option ? theme_option.show_printing_btn : true"
                        :show_translation_btn="theme_option ? theme_option.show_translation_btn : true"
                        :show_sasb_table="show_sasb_table" :show_gri_table="show_gri_table"
                        :show_tcfd_table="show_tcfd_table" :show_dc_table="show_dc_table"
                        :active_dc_table="active_dc_table" :active_gri_table="active_gri_table"
                        :active_sasb_table="active_sasb_table" :active_tcfd_table="active_tcfd_table"
                        @showTableView="showTableView" />

                    <div v-if="show_gri_table" :class="`bgray-200 min-vh-100`">
                        <b-container class="py-5">
                            <b-card class="p-4 my-auto">
                                <!-- <h3 class="mb-5">Contents</h3> -->
                                <contents-list-basic-sk-chemical :preview_mode="true" :web_view="true"
                                    :content_list_open="content_list_open" :show_sasb_table="show_sasb_table"
                                    :show_gri_table="show_gri_table" :show_tcfd_table="show_tcfd_table"
                                    :show_dc_table="show_dc_table" :active_dc_table="active_dc_table"
                                    :active_gri_table="active_gri_table" :active_sasb_table="active_sasb_table"
                                    :active_tcfd_table="active_tcfd_table" @showTableView="showTableView">
                                </contents-list-basic-sk-chemical>
                            </b-card>
                        </b-container>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="theme_option && theme_option.layout_code === 'no_image_background'">
            <ViewerHeaderNoImageTheme :theme_option="!theme_option ? {} : theme_option"
                :dark_logo_url="dark_logo_url === null ? false : dark_logo_url" :content_list_open="content_list_open"
                :main_bg_color_gradient="main_bg_color_gradient" :show_sasb_table="show_sasb_table"
                :show_gri_table="show_gri_table" :show_tcfd_table="show_tcfd_table" :show_dc_table="show_dc_table"
                :active_dc_table="active_dc_table" :active_gri_table="active_gri_table"
                :active_sasb_table="active_sasb_table" :active_tcfd_table="active_tcfd_table"
                @showTableView="showTableView" />

            <!-- 
            <div class="pt-5" style="text-align: center;">
                <b-button v-if="active_gri_table" @click="showTableView('GRI')" pill variant="dark" class="mr-4" size="lg">GRI</b-button>
                <b-button v-if="active_sasb_table" @click="showTableView('SASB')" pill variant="info" class="mr-4" size="lg">SASB</b-button>
                <b-button v-if="active_tcfd_table" @click="showTableView('TCFD')" pill variant="success" class="mr-4" size="lg">TCFD</b-button>
            </div> -->

        </div>
        <div v-else-if="theme_option && theme_option.layout_code === 'bh_test'">
            <ViewerHeaderCover :theme_option="!theme_option ? {} : theme_option"
                :dark_logo_url="dark_logo_url === null ? false : dark_logo_url" :content_list_open="content_list_open"
                :main_bg_color_gradient="main_bg_color_gradient" :show_sasb_table="show_sasb_table"
                :show_gri_table="!show_gri_table" :show_tcfd_table="show_tcfd_table" :show_dc_table="show_dc_table"
                :active_dc_table="active_dc_table" :active_gri_table="active_gri_table"
                :active_sasb_table="active_sasb_table" :active_tcfd_table="active_tcfd_table"
                @showTableView="showTableView" />

            <!-- 
            <div class="pt-5" style="text-align: center;">
                <b-button v-if="active_gri_table" @click="showTableView('GRI')" pill variant="dark" class="mr-4" size="lg">GRI</b-button>
                <b-button v-if="active_sasb_table" @click="showTableView('SASB')" pill variant="info" class="mr-4" size="lg">SASB</b-button>
                <b-button v-if="active_tcfd_table" @click="showTableView('TCFD')" pill variant="success" class="mr-4" size="lg">TCFD</b-button>
            </div> -->

        </div>
        <div>
            <b-container class="bg-white px-0" v-view="viewHandlerNav">
                <WaterMark v-if="selected_report.sample_report" />

                <!-- 테스트 -->
                <tcfd-table v-if="show_tcfd_table" :modal_table="false"
                    :business_case_mode="theme_option !== null ? theme_option.business_case_mode : ''"
                    :table_mode="theme_option !== null ? theme_option.table_mode : ''"
                    :material_style_option="theme_option !== null ? theme_option.material_style_option : ''"
                    :table_style="theme_option !== null ? theme_option.table_style : ''"
                    :show_table_line="theme_option !== null ? theme_option.show_table_line : ''"
                    :show_table_outline="theme_option !== null ? theme_option.show_table_outline : ''"
                    :table_header_color="theme_option !== null ? theme_option.table_header_color : ''"
                    :table_header_font_color="theme_option !== null ? theme_option.table_header_font_color : ''">
                </tcfd-table>
                <SASBTableMicro v-if="show_sasb_table && theme_option && theme_option.layout_code === 'bh_test'"
                    :modal_table="false"
                    :business_case_mode="theme_option !== null ? theme_option.business_case_mode : ''"
                    :table_mode="theme_option !== null ? theme_option.table_mode : ''"
                    :material_style_option="theme_option !== null ? theme_option.material_style_option : ''"
                    :table_style="theme_option !== null ? theme_option.table_style : ''"
                    :show_table_line="theme_option !== null ? theme_option.show_table_line : ''"
                    :show_table_outline="theme_option !== null ? theme_option.show_table_outline : ''"
                    :table_header_color="theme_option !== null ? theme_option.table_header_color : ''"
                    :table_header_font_color="theme_option !== null ? theme_option.table_header_font_color : ''">
                </SASBTableMicro>
                <sasb-table v-else-if="show_sasb_table" :modal_table="false"
                    :business_case_mode="theme_option !== null ? theme_option.business_case_mode : ''"
                    :table_mode="theme_option !== null ? theme_option.table_mode : ''"
                    :material_style_option="theme_option !== null ? theme_option.material_style_option : ''"
                    :table_style="theme_option !== null ? theme_option.table_style : ''"
                    :show_table_line="theme_option !== null ? theme_option.show_table_line : ''"
                    :show_table_outline="theme_option !== null ? theme_option.show_table_outline : ''"
                    :table_header_color="theme_option !== null ? theme_option.table_header_color : ''"
                    :table_header_font_color="theme_option !== null ? theme_option.table_header_font_color : ''">
                </sasb-table>
                <g-r-i-table2021-micro v-if="show_gri_table && theme_option && theme_option.layout_code === 'bh_test'"
                    :modal_table="false"
                    :business_case_mode="theme_option !== null ? theme_option.business_case_mode : ''"
                    :table_mode="theme_option !== null ? theme_option.table_mode : ''"
                    :material_style_option="theme_option !== null ? theme_option.material_style_option : ''"
                    :table_style="theme_option !== null ? theme_option.table_style : ''"
                    :show_table_line="theme_option !== null ? theme_option.show_table_line : ''"
                    :show_table_outline="theme_option !== null ? theme_option.show_table_outline : ''"
                    :table_header_color="theme_option !== null ? theme_option.table_header_color : ''"
                    :table_header_font_color="theme_option !== null ? theme_option.table_header_font_color : ''">
                </g-r-i-table2021-micro>
                <gri-table v-else-if="show_gri_table" :modal_table="false"
                    :business_case_mode="theme_option !== null ? theme_option.business_case_mode : ''"
                    :table_mode="theme_option !== null ? theme_option.table_mode : ''"
                    :material_style_option="theme_option !== null ? theme_option.material_style_option : ''"
                    :table_style="theme_option !== null ? theme_option.table_style : ''"
                    :show_table_line="theme_option !== null ? theme_option.show_table_line : ''"
                    :show_table_outline="theme_option !== null ? theme_option.show_table_outline : ''"
                    :table_header_color="theme_option !== null ? theme_option.table_header_color : ''"
                    :table_header_font_color="theme_option !== null ? theme_option.table_header_font_color : ''">
                </gri-table>

            </b-container>

            <div class="dc-container">
                <data-center-table-micro v-if="show_dc_table && theme_option && theme_option.layout_code === 'bh_test'"
                v-view="dcViewHandlerNav" :modal_table="false"
                :business_case_mode="theme_option !== null ? theme_option.business_case_mode : ''"
                :table_mode="theme_option !== null ? theme_option.table_mode : ''"
                :material_style_option="theme_option !== null ? theme_option.material_style_option : ''"
                :table_style="theme_option !== null ? theme_option.table_style : ''"
                :show_table_line="theme_option !== null ? theme_option.show_table_line : ''"
                :show_table_outline="theme_option !== null ? theme_option.show_table_outline : ''"
                :table_header_color="theme_option !== null ? theme_option.table_header_color : ''"
                :table_header_font_color="theme_option !== null ? theme_option.table_header_font_color : ''">
            </data-center-table-micro>
            <data-center-table v-else-if="show_dc_table" v-view="dcViewHandlerNav" :modal_table="false"
                :business_case_mode="theme_option !== null ? theme_option.business_case_mode : ''"
                :table_mode="theme_option !== null ? theme_option.table_mode : ''"
                :material_style_option="theme_option !== null ? theme_option.material_style_option : ''"
                :table_style="theme_option !== null ? theme_option.table_style : ''"
                :show_table_line="theme_option !== null ? theme_option.show_table_line : ''"
                :show_table_outline="theme_option !== null ? theme_option.show_table_outline : ''"
                :table_header_color="theme_option !== null ? theme_option.table_header_color : ''"
                :table_header_font_color="theme_option !== null ? theme_option.table_header_font_color : ''">
            </data-center-table>
            </div>

            <div v-if="$route.hash" class="ml-5">
                <b-button variant="light" @click="goFullPage" >
                    <b-icon-menu-button-fill /> 
                    {{ lang !== 'ENG' ? '전체보기' : 'Show All'}}
                </b-button>
            </div>

            <hr class="border-0 m-0 py-5">

            <div class="px-4 px-lg-5 py-5 gray-550 f-90 d-flex justify-content-between">
                <div class="tail-line" style="width: calc(100% - 200px);">{{ lang != 'ENG' ? selected_report.report_name :
                    selected_report.report_name_e}} </div>
                <!-- dark_logo_url -->
                <div v-if="theme_option && !theme_option.is_dark_option && selected_report.logo_path !== null"><b-img
                        :src="$store.state.static_host + selected_report.logo_path" class="report-logo-small"
                        :style="{ 'max-height': '40px' }"></b-img></div>
                <div v-if="theme_option && theme_option.is_dark_option && dark_logo_url !== null"><b-img
                        :src="dark_logo_url" class="report-logo-small" :style="{ 'max-height': '40px' }"></b-img></div>
            </div>

        </div>
        

        <!-- 인쇄영역 -->
        <div class="position-absolute w-100" style="left: 0;top:0; margin-left:-9999999px;">
            <!-- <div class="position-absolute w-100" style="left: 0;top:0; margin-left:-5000000; "> -->
                <div id="full-report-preview">
                <b-container class="px-6 vh-100">
                    <b-row no-gutters class="h-100">
                        <b-col cols="6" class="pl-3">
                            <h1 class="f-300 mb-5 line-height-lg" style="padding-top: 10vh">{{ lang != 'ENG' ?
                                selected_report.report_name : selected_report.report_name_e }}</h1>
                            <h6 class="">{{ selected_report.start_date }} ~ {{ selected_report.end_date }}</h6>
                        </b-col>
                        <b-col class="d-flex justify-content-end align-items-end pr-5">
                            <div v-if="theme_option && !theme_option.is_dark_option && selected_report.logo_path != null"
                                style="padding-bottom: 7vh"><b-img
                                    :src="$store.state.static_host + selected_report.logo_path"
                                    class="report-logo-regular"></b-img></div>
                            <div v-if="theme_option && theme_option.is_dark_option && dark_logo_url !== null"
                                style="padding-bottom: 7vh"><b-img :src="dark_logo_url"
                                    class="report-logo-regular"></b-img>
                            </div>

                        </b-col>
                    </b-row>
                </b-container>

                <div class="bgray-200">
                    <b-container class="px-5 py-5 ">
                        <b-card class="p-4">
                            <h3 class="mb-5">Contents</h3>
                            <contents-list-basic></contents-list-basic>
                        </b-card>
                    </b-container>
                </div>


                <b-container class="px-5 py-5">
                    <!-- v-if="show_tcfd_table" -->
                    <tcfd-table v-if="show_tcfd_table" :modal_table="false" :print_mode="true"
                        :business_case_mode="theme_option !== null ? theme_option.business_case_mode : ''"
                        :table_mode="theme_option !== null ? theme_option.table_mode : ''"
                        :material_style_option="theme_option !== null ? theme_option.material_style_option : ''"
                        :table_style="theme_option !== null ? theme_option.table_style : ''"
                        :show_table_line="theme_option !== null ? theme_option.show_table_line : ''"
                        :show_table_outline="theme_option !== null ? theme_option.show_table_outline : ''"
                        :table_header_color="theme_option !== null ? theme_option.table_header_color : ''"
                        :table_header_font_color="theme_option !== null ? theme_option.table_header_font_color : ''">
                    </tcfd-table>
                    <!-- v-if="show_sasb_table" -->
                    <sasb-table v-if="show_sasb_table" :modal_table="false" :print_mode="true"
                        :business_case_mode="theme_option !== null ? theme_option.business_case_mode : ''"
                        :table_mode="theme_option !== null ? theme_option.table_mode : ''"
                        :material_style_option="theme_option !== null ? theme_option.material_style_option : ''"
                        :table_style="theme_option !== null ? theme_option.table_style : ''"
                        :show_table_line="theme_option !== null ? theme_option.show_table_line : ''"
                        :show_table_outline="theme_option !== null ? theme_option.show_table_outline : ''"
                        :table_header_color="theme_option !== null ? theme_option.table_header_color : ''"
                        :table_header_font_color="theme_option !== null ? theme_option.table_header_font_color : ''">
                    </sasb-table>

                    <!-- v-if="show_gri_table" -->
                    <gri-table v-if="show_gri_table" :modal_table="false" :print_mode="true"
                        :business_case_mode="theme_option !== null ? theme_option.business_case_mode : ''"
                        :table_mode="theme_option !== null ? theme_option.table_mode : ''"
                        :material_style_option="theme_option !== null ? theme_option.material_style_option : ''"
                        :table_style="theme_option !== null ? theme_option.table_style : ''"
                        :show_table_line="theme_option !== null ? theme_option.show_table_line : ''"
                        :show_table_outline="theme_option !== null ? theme_option.show_table_outline : ''"
                        :table_header_color="theme_option !== null ? theme_option.table_header_color : ''"
                        :table_header_font_color="theme_option !== null ? theme_option.table_header_font_color : ''">
                    </gri-table>



                    <data-center-table v-if="show_dc_table" :modal_table="false" :print_mode="true"
                        :business_case_mode="theme_option !== null ? theme_option.business_case_mode : ''"
                        :table_mode="theme_option !== null ? theme_option.table_mode : ''"
                        :material_style_option="theme_option !== null ? theme_option.material_style_option : ''"
                        :table_style="theme_option !== null ? theme_option.table_style : ''"
                        :show_table_line="theme_option !== null ? theme_option.show_table_line : ''"
                        :show_table_outline="theme_option !== null ? theme_option.show_table_outline : ''"
                        :table_header_color="theme_option !== null ? theme_option.table_header_color : ''"
                        :table_header_font_color="theme_option !== null ? theme_option.table_header_font_color : ''">
                    </data-center-table>




                </b-container>
                    
                
                <b-container class="assurance-statement py-5 px-5 bgray-100" v-if="assurance_status==6">
                    <template>
                        <h3 id="rpa0" class="pt-3 mb-5">Appendix. {{ lang != 'ENG' ? '제3자 검증의견서' : 'Independent Assurance Statement' }}</h3>
                        <view-assurance-statement :preview_mode="true"></view-assurance-statement>
                    </template>
                </b-container>

                <b-container class="px-4 px-lg-5 gray-550 f-90 d-flex justify-content-between footer">
                    <div class="tail-line">{{ lang != 'ENG' ? selected_report.report_name : selected_report.report_name_e }}
                    </div>
                    <div v-if="selected_report.logo_path != null"><b-img
                            :src="$store.state.static_host + selected_report.logo_path" class="report-logo-small"></b-img>
                    </div>
                </b-container>
            </div>
        </div>


        
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import * as ih from '@/components/util'
import DataTable from './viewer_components/DataTable.vue'
import Document from './viewer_components/Document.vue'
import BusinessCase from './viewer_components/BusinessCase.vue'
import MaterialityBadge from './viewer_components/MaterialityBadge.vue'
import ContentsListBasic from './viewer_components/ContentsListBasic.vue'
import ContentsListBasicSkChemical from './viewer_components/ContentsListBasicSkChemical.vue'
import ContentsListIndicator from './viewer_components/ContentsListIndicator.vue'
import GriTable from './viewer_components/GRITable2021.vue'
import SASBTable from './viewer_components/SASBTable.vue'
import TCFDTable from './viewer_components/TCFDTable.vue'
import DataCenterTable from './viewer_components/DataCenterTable.vue'
import DataCenterTableMicro from './viewer_components/DataCenterTableMicro.vue'
import AppendixDataTables from './viewer_components/AppendixDataTables.vue'
import AppendixBusinessCases from './viewer_components/AppendixBusinessCases.vue'
import ViewAssuranceStatement from '@/components/report_preview/appendix_subs/ViewAssuranceStatement'
import ViewerSideNav from '@/components/report_preview/gri2021_viewer/viewer_components/ViewerSideNav.vue'
import ViewerHeaderBasic from './viewer_components/ViewerHeaderBasic.vue'
import DataCenterHeaderNav from './viewer_components/DataCenterHeaderNav.vue'
import ViewerHeaderNoImageTheme from './viewer_components/ViewerHeaderNoImageTheme.vue'
import ViewerHeaderBasicSkChemical from './viewer_components/ViewerHeaderBasicSkChemical.vue'
import WaterMark from "@/components/report_preview/gri2021_viewer/WaterMark"
import ViewerHeaderNew from './viewer_components/ViewerHeaderNew.vue'
import ViewerHeaderDC from './viewer_components/ViewerHeaderDC.vue'
import ViewerHeaderCover from './viewer_components/ViewerHeaderCover.vue'
import GRITable2021Micro from './viewer_components/GRITable2021Micro.vue'
import SASBTableMicro from './viewer_components/SASBTableMicro.vue'

export default {
    name: 'FullView2021',
    components: {
        DataTable,
        Document,
        BusinessCase,
        MaterialityBadge,
        ContentsListBasic,
        ContentsListBasicSkChemical,
        ContentsListIndicator,
        ViewerHeaderBasic,
        ViewerHeaderBasicSkChemical,
        ViewerHeaderNoImageTheme,
        "gri-table": GriTable,
        "sasb-table": SASBTable,
        "tcfd-table": TCFDTable,
        "data-center-table": DataCenterTable,
        AppendixDataTables,
        AppendixBusinessCases,
        ViewAssuranceStatement,
        ViewerSideNav,
        WaterMark,
        DataCenterHeaderNav,
        ViewerHeaderNew,
        ViewerHeaderDC,
        ViewerHeaderCover,
        GRITable2021Micro,
        DataCenterTableMicro,
        SASBTableMicro,
    },
    data() {
        return {
            low_height: false,
            show_nav: true,
            content_list_open: false,
            theme_option: null,
            loadingShow: true,
            cover_img_url: null,
            dark_logo_url: null,

            show_gri_table: true,
            show_sasb_table: false,
            show_tcfd_table: false,

            show_dc_table: false,
            active_dc_table: false,

            active_gri_table: false,
            active_sasb_table: false,
            active_tcfd_table: false,
            type: null,

            original_url: window.location.href.split("&t")[0]

        }
    },
    props: {
        show_table_list: { type: Boolean, default: false },
        show_business_case_list: { type: Boolean, default: false },
        reload: { type: Boolean, default: false },
    },
    watch: {
        selected_report: {
            handler(newV) {
                if (newV !== undefined) {
                    this.get_report_theme_info();
                    this.check_report_type();

                    // 탭 제목 변경
                    document.querySelector("title").textContent = this.selected_report.report_name
                }
            }
        }
    },
    computed: {
        ...mapState(['backend_host', 'static_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapState('gri2021_report_view', ['report_structure', 'report_contents_loaded']),
        ...mapGetters('gri2021_report_view', ['report_topics', 'report_categories', 'report_topic_specific_categories', 'report_universal_categories', 'report_topic_types']),
        ...mapGetters('report_list', ['selected_report']),
        ...mapActions('assurance',['readAssuranceStatusByReportCode']),
        ...mapState('assurance',['assurance_status']),
        main_color(){
            return `color:`+this.selected_report.main_color
        },
        main_bg_color() {
            return `background-color:` + this.selected_report.main_color + `;important!`
        },
        main_color_sub() {
            return `color:` + this.selected_report.main_color + `; border-top: 1px solid ` + this.selected_report.main_color + `!important` + `; border-bottom: 1px solid ` + this.selected_report.main_color
        },
        main_bg_color_gradient() {
            if (!this.theme_option?.cover_active || (this?.cover_img_url === null || this?.cover_img_url === "")) {
                return `background:linear-gradient(0deg, ` + this.selected_report.main_color + ` -50%, rgba(239,239,239,1) 60%);important!`
            } else {
                const theme_style = {
                    'background-image': `url(${this.cover_img_url})`,
                    'background-size': 'cover',
                    'background-repeat': 'no-repeat',
                    'opacity': `${this.theme_option.cover_alpha}`,
                    'color': `${this.theme_option.title_color}`
                }

                return theme_style
            }
        }
    },
    async created() {
        if (process.env.VUE_APP_REPORT_CODE !== undefined) { 
            this.update_report_code(process.env.VUE_APP_REPORT_CODE);
        }
        if (this.reload == true) {
            this.update_report_contents_loaded(false)
        }
        await this.get_report_theme_info();

        this.check_report_type();
        // if(this.report_contents_loaded != true){
        if (process.env.VUE_APP_REPORT_CODE === undefined) {
            //console.log('FULL_VIEW_LOAD')
            // await this.readAssuranceStatusByReportCode({report_code: this.report_code})
            // await this.readReportContents({report_code : this.report_code})
            // await this.readDataTableList({report_code: this.report_code})
            // await this.readBusinessCaseList({report_code: this.report_code})
            // await this.readDocumentList({report_code: this.report_code})
            // this.update_view_mode('editor')


        } else {
            this.update_view_mode('viewer')
            // await this.readAssuranceStatusByReportCode({ report_code: this.report_code })
            await this.readAllReportListForViewer({ report_code: this.report_code })

            // gri table에서 어차피 부르고 있음 2024.01.23.
            // await this.readReportContents({ report_code: this.report_code })
            // await this.readDataTableList({report_code: this.report_code})
            // await this.readBusinessCaseList({report_code: this.report_code})
            // await this.readDocumentList({ report_code: this.report_code })
        }
        // this.check_report_type();

        
        await this.initCss();

        this.scrollToHash();

    },
    mounted() {
        window.addEventListener('scroll', this.handleContentList)

        if (this.view_mode !== 'editor') {
            const params = ih.getParamsFromQueryString()
            if (params && params?.lv1 !== undefined) {
                this.update_lv1_key(params.lv1)
                this.update_lv2_key(params.lv2)
            }
        }


    },
    beforeDestroy() {
        this.change_lang('KOR')
        window.removeEventListener('scroll', this.handleContentList)

    },
    methods: {
        ...mapMutations("report_list", ["update_report_code"]),
        ...mapMutations('data_center', ['update_lv1_key', 'update_lv2_key']),
        check_report_type() {
            if (this.selected_report) {
                for (let i of Object.keys(this.selected_report.report_type)) {
                    if (i.split("v")[0] === 'GRI') {
                        this.active_gri_table = true;
                    }
                    else if (i.split("v")[0] === 'SASB') {
                        this.fetchSelectedSasb(this.report_code);
                        this.active_sasb_table = true;
                    } else if (i.split("v")[0] === 'TCFD') {
                        this.fetchSelectedTcfd(this.report_code);
                        this.active_tcfd_table = true;
                    }
                }

                if (this.selected_report.data_center) {
                    this.active_dc_table = true;
                } else {
                    // console.log('datacenter check')
                }
            }
        },
        ...mapMutations('gri2021_report_view', ['update_report_contents_loaded']),
        ...mapMutations('report_list', ['update_view_mode', 'change_lang']),
        ...mapActions('gri2021_report_view', ['readReportContents']),
        ...mapActions('data_tables', ['readDataTableList']),
        ...mapActions('business_cases', ['readBusinessCaseList']),
        ...mapActions('documents', ['readDocumentList']),
        ...mapActions('report_list', ['readAllReportListForViewer']),
        ...mapActions('assurance', ['readAssuranceListByReportCode']),
        ...mapActions('report_contents', ['fetchSelectedSasb', 'fetchSelectedTcfd']),
        // createOGTag() {
        //     // 다른 meta 요소들도 동일한 방식으로 생성 및 추가
        //     // const metaTags = [
        //     //     { property: 'og:type', content: 'article' },
        //     //     { property: 'og:url', content: location.href },
        //     //     { property: 'og:description', content: 'This is a description. test' },
        //     //     { property: 'og:image', content: this.$store.state.static_host +this.selected_report.logo_path},
        //     //     { property: 'og:image:width', content: '1200' },
        //     //     { property: 'og:image:height', content: '627' }
        //     // ];
        //     const metaTags = [
        //         { property: 'og:title', content: 'gri reporting or test' },
        //         { property: 'og:url', content: location.href },
        //         { property: 'og:description', content: 'This is a description. test' },
        //         { property: 'og:image', content: this.$store.state.static_host +this.selected_report.logo_path},
        //     ];
        //     console.log(this.$store.state.static_host +this.selected_report.logo_path)

        //     metaTags.forEach(function (tag) {
        //         const metaTag = document.querySelector(`meta[property="${tag.property}"]`);
        //         metaTag.setAttribute('content', tag.content);

        //         // metaTag.setAttribute('property', tag.property);
        //         // metaTag.setAttribute('content', tag.content);
        //         // document.head.appendChild(metaTag);
        //     });

        // },
        showTableView(type) {
            if (type === 'GRI') {
                this.show_gri_table = true;
                this.show_sasb_table = false;
                this.show_tcfd_table = false;
                this.show_dc_table = false;
            } else if (type === 'SASB') {
                this.show_gri_table = false;
                this.show_sasb_table = true;
                this.show_tcfd_table = false;
                this.show_dc_table = false;
            } else if (type === 'TCFD') {
                this.show_gri_table = false;
                this.show_sasb_table = false;
                this.show_tcfd_table = true;
                this.show_dc_table = false;
            } else if (type === 'Data Center' || type === 'DATA_CENTER') {
                this.show_gri_table = false;
                this.show_sasb_table = false;
                this.show_tcfd_table = false;
                this.show_dc_table = true;
            } else {
                this.show_gri_table = true;
                this.show_sasb_table = false;
                this.show_tcfd_table = false;
                this.show_dc_table = false;
            }

            if (type) {
                ih.updateParamsFromQueryString('t', type.toLowerCase())
                // if (type==="DATA_CENTER") {
                //     if (window.location.href.includes('&lv')) {
                //         ih.updateParamsFromQueryString('lv1', null)
                //         ih.updateParamsFromQueryString('lv2', null)
                //     }
                // }
            }

        },
        toggleLang() {
            console.log('toggle lang')
            if (this.lang == 'KOR') { //현재 국문이면 영문으로 바꾸기
                this.change_lang('ENG')
                document.querySelector("title").textContent = this.selected_report.report_name_e

                let newUrl = `${window.location.href}&l=KOR`;
                if (window.location.href.includes('&l=')) {
                    window.history.pushState({}, '', newUrl);
                }
            } else {
                this.change_lang('KOR')
                document.querySelector("title").textContent = this.selected_report.report_name


                let newUrl = `${window.location.href}&l=ENG`;
                if (!window.location.href.includes('&l=')) {
                    window.history.pushState({}, '', newUrl);
                }

            }
        },
        viewHandlerNav(event) {
            if (event.type !== 'progress' && event.scrollPercent > 0.035) {
                this.show_nav = true
            } else {
                this.show_nav = false
            }

        },
        dcViewHandlerNav(event) {
            // console.log(event)

            // if (event.type === 'enter' || event.type === 'progress') {
            //     this.show_nav = true
            // } else if (event.type === 'exit') {
            //     this.show_nav = false
            // }

            // if (event.type === "exit" && event.scrollPercent > 0) {
            //     this.show_nav = true
            // }
            // else if (event.type === 'enter') {
            //     this.show_nav = false
            // }

            if (window.scrollY > 0 && window.innerWidth > 300) {
                this.show_nav = true
            } else {
                this.show_nav = false
            }

        },
        handleContentList(event) {
            if (window.scrollY > 0 && window.innerWidth > 800) {
                this.content_list_open = true
            } else {
                this.content_list_open = false
            }
        },
        async get_report_theme_info() {
            try {

                let params = {}

                if (this.view_mode !== 'editor') {
                    params = ih.getParamsFromQueryString()

                }

                if (params && params?.t !== undefined) {
                    this.type = params?.t.toUpperCase()
                } else {
                    this.type = null
                }


                if (this.selected_report) {
                    
                }
                let report_url = process.env.VUE_APP_REPORT_CODE ? process.env.VUE_APP_REPORT_CODE : (typeof this.selected_report === 'undefined'
                    ? params?.r
                    : this.selected_report.report_url)

                if (this.selected_report?.report_url) {
                    report_url = this.selected_report.report_url
                }
                this.showTableView(this.type)


                const request_url = report_url
                    ? "/custom_theme/get_theme_info?report_url=" + report_url
                    : "/custom_theme/get_theme_info?report_code=" + this.selected_report.report_code

                const _res_theme_option = await axios.get(this.backend_host + request_url)
                if (_res_theme_option.data.code) {
                    const _theme_option = { ..._res_theme_option.data.theme_info }

                    this.cover_img_url = !_theme_option.cover_path ? null : this.static_host + _theme_option.cover_path.replaceAll("\\", "/")
                    this.dark_logo_url = !_theme_option.dark_logo_path ? null : this.static_host + _theme_option.dark_logo_path.replaceAll("\\", "/")
                    this.theme_option = { ..._theme_option }

                    this.loadingShow = false;
                } else {
                    this.loadingShow = false;
                    window.alert(_res_theme_option.data.message);
                    window.location.href = "https://qesg.co.kr/reporting_tool"
                    return;
                }
            } catch (error) {
                console.log(error)
                window.alert(error)
            }
        },
        async initCss() {
            const beforeStyledSheet = document.getElementsByClassName("customized-css-styling")
            if (beforeStyledSheet.length > 0) {
                for (const element of beforeStyledSheet) {
                    element.remove()
                }
            }

            const result = await axios.post(process.env.VUE_APP_BACKENDHOST + '/custom_theme/add_custom_theme', {
                "report_code": this.report_code
            })

            // this.custom_css_text = result.data.css_content;
            // this.theme_code = result.data.theme_code;
            const styleElement = document.createElement('style');
            styleElement.classList.add("customized-css-styling");
            styleElement.innerHTML = result.data.css_content;
            document.head.appendChild(styleElement);

            this.loadingShow = false;
        },

        scrollToHash() {
            this.$nextTick(() => {
                const hash = window.location.hash;
                if (hash) {
                    // const element = decodeURIComponent(document.querySelector(hash));
                    const element = document.querySelector(hash);

                    if (element && element !== 'null') {
                        element.scrollIntoView({ block: 'start', behavior: "smooth" });
                        // element.scrollIntoView({ behavior: "smooth" });
                    }
                }
            });
        },

        goFullPage() {
            ih.anchorGo(null)
        }



    },
}
let lastScroll = document.documentElement.scrollTop || 0
document.addEventListener('scroll', function () {
    let scrollTop = document.documentElement.scrollTop
    if (scrollTop > lastScroll) {
        // down
    } else {
        //up
    }
    lastScroll = scrollTop
})
</script>
<style>
@media print {
    .footer {
        background-color: #fff;
        color: black;
        text-align: center;
        padding: 0;
        margin-top: auto;
    }
}


.dc-container {
    width: 100%;
    max-width: 1600px; 
    margin: 0 auto; 
    padding-right: 20px; 
    padding-left: 20px; 
}

@media (max-width: 1200px) {
    .dc-container {
        padding-right: 15px; 
        padding-left: 15px; 
    }
}

@media (max-width: 768px) {
    .dc-container {
        padding-right: 10px; 
        padding-left: 10px; 
    }
}

.assurance-statement{
    page-break-before: always;
    padding: 0;
    box-sizing: border-box;
    width: 100%; 
    max-width: 100%; 
    page-break-inside: avoid; 
}
</style>
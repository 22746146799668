<template>
<div>        
        <div class="mb-1 f-90"> 이름 *</div>
        <b-form-input v-model="local_name" :disabled="sending_message==true" class="mb-3"></b-form-input>
   
        <div class="mb-1 f-90"> 이메일 *</div>
        <b-form-input v-model="local_email" class="mb-3" :disabled="sending_message==true"></b-form-input>
    
        <div class="mb-1 f-90"> 문의 카테고리 </div>
        <b-form-group :disabled="sending_message==true"><b-form-radio-group v-model="category" :options="options" class="f-90"></b-form-radio-group></b-form-group>

        <div class="mb-3">
            <div class="mb-1 f-90"> 문의할 보고서 </div>
            <template v-if="report_code!=''&&report_code!=undefined&&user_id!=''">
                <div class="blue7">{{ local_report_name }}</div>
            </template>
            <template v-else>
                <b-form-select :disabled="sending_message==true" v-model="local_report_name" :options="local_list">
                    <b-form-select-option :value="null">문의할 보고서가 있다면 선택해 주세요</b-form-select-option>
                </b-form-select>
            </template>           
        </div>

        <div class="mb-1 f-90"> 문의내용 *</div>
        <b-form-textarea :disabled="sending_message==true" class="mb-3" v-model="local_contents" rows="5"></b-form-textarea>

        <p class="f-90 text-right mb-3">평균 1-2시간 이내에 답변 드립니다. *은 필수입력 항목입니다.</p>
        <div class="text-right mb-3">
            <b-button variant="blue" @click="sendMessage()" :disabled="sending_message==true"> {{ sending_message!=true ? '문의하기' : '전송중' }} <b-spinner small v-if="sending_message==true"></b-spinner> </b-button>
        </div>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            local_name: '',
            local_email: '',
            local_report_name: null,
            local_list: [],
            local_contents: '',

            category: '',
            options: [
              { text: '계정', value: '계정'}, 
              { text: '보고서 작성', value: '보고서 작성'},
              { text: '검증', value: '검증'},
              { text: '결제', value: '결제'},
              { text: '기타 서비스 문의', value: '기타'},
            ],           

            sending_message: false
        }
    },
    props:{

    },
    computed:{
        ...mapState(['user_id', 'user_name', 'e_mail']),
        ...mapState('report_list', ['report_list', 'report_code']),
        ...mapGetters('report_list', ['selected_report']),
        ...mapActions('report_list', ['readReportListByUserId'])
    },
    methods:{
        initiateForm(){
            let temp_list = []
            this.report_list.forEach( item => temp_list.push(item.report_name))
            this.local_list = temp_list

            this.local_email = this.e_mail
            this.local_name = this.user_name
            if(this.report_code != '' && this.report_code != null) {
                this.local_report_name = this.selected_report.report_name
            }
        },
        checkForm(){
            if(this.local_name == ''){
                alert('문의하신분의 성함을 입력해 주세요.')
                return false
            }
            if(this.local_email == ''){
                alert('문의 답변을 받으실 이메일을 입력해 주세요.')
                return false
            }
            if(this.local_contents == ''){
                alert('문의 내용을 입력해주세요')
                return false
            }
            else return true
        },
        sendMessage(){
            if(this.checkForm()==true) {
                this.sending_message = true
                const path = this.$store.state.backend_host + '/send_customer_service_mail'
                let formData = new FormData()
                formData.append('name', this.local_name)
                formData.append('e_mail', this.local_email)
                formData.append('contents', this.local_contents)
                formData.append('user_id', this.user_id)
                if(this.local_report_name!=null) formData.append('local_report_name', this.local_report_name)
                if(this.local_report_name!=null){
                    let idx = this.report_list.findIndex( item => item.report_name == this.local_report_name )
                    if(idx > -1) { formData.append('report_code', this.report_list[idx].report_code) }
                } 
                if(this.category!='') formData.append('category', this.category)
                
                return axios.post(path, formData)
                .then( response => {
                    this.$EventBus.$emit('make-toast', '문의 완료', `문의 메일 발송이 완료되었습니다. 문의하신 내용은 이메일에서 조회할 수 있으며, 답변은 1-2시간 내로 이메일로 발송됩니다.`)
                    this.sending_message = false
                })
                .catch( error => {
                    console.log(error)
                })
            }            
        }
    },
    created(){
        this.initiateForm()

    }
}
</script>

<style>

</style>
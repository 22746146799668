<template>
<div>
    <b-card no-body>  
        <b-card-header header-tag="header" class="bg-white p-0 shadow-sm">
            <b-button block @click="pageGo('CheckReport')" variant="icon-txt" class="text-left py-2 mb-0 mt-1" 
            :class="visible[0]==true? 'fw-500':''">검토하기</b-button>
            <div class="border-bottom" style="width:100%; height:1px;"></div>
            <b-button block @click="pageGo('AssuranceInfo')" variant="icon-txt" class="text-left py-2 mt-0 mb-1" 
            :class="visible[1]==true? 'fw-500':''" aria-controls="assure-sub">검증받기</b-button>
        </b-card-header>
        <b-collapse id="assure-sub" v-model="visible[1]" class="bgray-100 py-2" >
            <b-button v-for="(sub, i) in assure_subs" :key="sub.id" block @click="pageGoSub(i)" class="text-left pl-3 f-95 py-1 mt-0"
            :variant="visible_sub[i]==true? 'icon':'icon-gray'" :class="visible_sub[i]==true? 'fw-500':''" >{{sub}}
            </b-button>
        </b-collapse>
        <b-card-footer class="p-1 teal-hover py-2 px-3 cursor text-white text-center" @click="$router.push('/publishing')">공개하기
        </b-card-footer>
    </b-card>

    <b-modal id="other-statement" hide-footer title="타 검증 의견서 업로드">
        <div class="mb-3">
            <p class="mb-45 fw-400 f-105"> 2-5 지표에 문서 및 링크를 추가하여 주십시오. </p>
            <p class="mb-2"><fa icon="info-circle"></fa> 타 검증 의견서로 업로드하기에 적합한 문서의 예시입니다. </p>
            <div class="bg-white px-3 py-3 mb-3 shadow-sm">
                <ul class="ul-sm f-95 gray-600 mb-0">
                    <li class="mb-2"> 보고 기간, 내용, 보고 대상 등이 동일한 범위의 GRI Table에 대해 검증을 받은 검증의견서 </li>
                    <li class="mb-2"> 온실가스 검증의견서 등 환경 데이터 관련 검증의견서 </li>
                    <li class=""> 재무관련 정보를 보고할 경우 해당 정보에 대한 외부 감사보고서 </li>
                </ul>
            </div>
            <p class="mb-45 f-90 teal7"><fa icon="exclamation-circle" class="mr-1"></fa> 타 검증 의견서로 업로드된 문서는 ESG Reporting Tool에서 직접 검증받은 사항이 아니므로, 검증 의견서의 적합성 및 신뢰성에 대해 QESG가 보장하지 않습니다. 또한, 보고서 공개 시 관련 사실이 함께 고지됩니다. </p>
            
            <!-- <b-button variant="teal" @click.stop.prevent="$router.push({ name: 'Gri100', hash: '#w102-52'})">2-5 지표 바로가기 </b-button> -->
            <b-button variant="teal" @click.stop.prevent="$router.push({ name: 'Gri2', hash: '#w2-5'})">2-5 지표 바로가기 </b-button>

        </div>
    </b-modal>

</div>    
</template>
<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            // assure_subs : ['검증 안내', '검증 신청', '검증의견 확인 및 보완', '검증 의견서 확인', '타 검증의견서 업로드'],
            assure_subs : ['검증 안내', '검증 의견서 확인', '타 검증의견서 업로드'],

            path_name: [{ name: 'AssuranceInfo' }, { name: 'ApplyAssurance' }, { name: 'CoVerification' }, { name: 'GetAssuranceReport' }]
        }
    },
    computed:{
        ...mapState('assurance', ['assurance_list', 'show_apply_estimate', 'assurance_list_loaded']),
        ...mapGetters('assurance', ['latest_assurance']),
        visible(){
            if(this.$route.path == '/check_report'){
                return [true, false]
            }
            if(this.$route.path == '/assurance_info' || this.$route.path == '/apply_assurance' || this.$route.path == '/co_verification'|| this.$route.path == '/get_assurance_report'){
                return [false, true]
            }
        },
        visible_sub(){
            if (this.$route.path == '/assurance_info'){
                return [true, false, false, false]
            }
            if (this.$route.path == '/apply_assurance'){
                return [false, true, false, false]
            }
            if (this.$route.path == '/co_verification'){
                return [false, false, true, false]
            }
            if (this.$route.path == '/get_assurance_report'){
                return [false, false, false, true]
            }else return [false, false, false, false]
        }        
    },
    methods:{
        pageGo(to){
            if (to == 'AssuranceInfo') {
                if (this.$route.path != 'AssuranceInfo'){
                    this.$router.push({name: to})
                }else console.log('현재 위치이므로 페이지 이동 불가');
            } 
            else this.$router.push({name: to})
        },
        pageGoSub(index){
            if(index == 0){
                if (this.$route.path != '/assurance_info'){
                    this.$router.push(this.path_name[0])
                }
            }
            // else if(index == 1){
            //     this.$router.push(this.path_name[1])
            // }
            // else if(index == 2){  // 서면검토 완료 상태가 아닐 경우 페이지 이동 불가능하게 막아야 함
            //     if(this.assurance_list.length> 0 && this.latest_assurance.request_status >=3){
            //         this.$router.push(this.path_name[2])
            //     }
            //     else alert('검증인이 서면검토를 완료한 후 이용하실 수 있습니다.')
            // }
            // else if(index == 3){
            //     // 1. 대면인터뷰 후 의견서 산출 완료 상태가 아닐 경우 페이지 이동 불가능하게 막아야 함
            //     if(this.assurance_list.length> 0 &&this.latest_assurance.request_status ==6){
            //         // 2. 대면인터뷰 후 의견서 산출 완료 상태인 경우 이동
            //         this.$router.push(this.path_name[3])
            //     }
            //     else alert('검증 종료 후 이용하실 수 있습니다.')
            // }
            // else if(index == 4){
            //     this.$bvModal.show('other-statement')
            // }
            // else if(index == 1){
            //     this.$router.push(this.path_name[1])
            // }
            // else if(index == 2){  // 서면검토 완료 상태가 아닐 경우 페이지 이동 불가능하게 막아야 함
            //     if(this.assurance_list.length> 0 && this.latest_assurance.request_status >=3){
            //         this.$router.push(this.path_name[2])
            //     }
            //     else alert('검증인이 서면검토를 완료한 후 이용하실 수 있습니다.')
            // }
            else if(index == 1){
                // 1. 대면인터뷰 후 의견서 산출 완료 상태가 아닐 경우 페이지 이동 불가능하게 막아야 함
                if(this.assurance_list.length> 0 &&this.latest_assurance.request_status ==6){
                    // 2. 대면인터뷰 후 의견서 산출 완료 상태인 경우 이동
                    this.$router.push(this.path_name[3])
                }
                else alert('검증 종료 후 이용하실 수 있습니다.')
            }
            else if(index == 2){
                this.$bvModal.show('other-statement')
            }
        },

    },
    created(){
    }
}
</script>
<template>
    <div>
        <b-form-textarea
            class="mb-5"
            rows="2"
            placeholder="설명을 입력해주세요"
            v-model="description"
            @blur = "updateChartList"
            :autofocus="auto_focus"
            lazy="true"

        >
        </b-form-textarea>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: '',
    props: {
        auto_focus: {type: Boolean, default: false},
        contents: { type: String, default: "" },
        lv1_key: { type: String, default: "" },
        lv2_key: { type: String, default: "" },
        lv3_key: { type: String, default: "" },

    },
    components: {},
    data() {
        return {
            description: this.contents,
        }
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {
        ...mapActions("charts", ["getChartList", "updateDCToC"]),
        updateChartList() {
            this.$emit("updateChartContents", {
                "lv1_key": this.lv1_key,
                "lv2_key": this.lv2_key,
                "lv3_key": this.lv3_key,
                "contents": this.description
            })

        },


    }
}
</script>
<style>
</style>
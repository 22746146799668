<template>
<b-container>
    <b-row style="height:60vh">
        <b-col cols="0" md="2" lg="3"></b-col>
            <b-col cols="12" md="8" lg="6" class="align-self-center">
                <b-card v-if="already_confirmed==false" class="py-4 px-md-2 px-xl-5 border-light">
                    <h4 v-if="mode=='signup'">이메일 인증</h4>
                    <h4 v-else-if="mode=='coauthor'">팀원 - 공동작성 참여 인증</h4>
                    <h4 v-else-if="mode=='ownership'">보고서 소유권 이전 동의</h4>
                    <h6 class="pt-2 mb-4">서비스 이용을 위해 이메일을 인증해 주세요.</h6>
                    
                    <b-form-input :disabled="wait_response==true" v-model="target_e_mail" placeholder="이메일" class="mb-4"></b-form-input>
                    <div class="mb-2"> 인증 코드: </div>
                    <div v-if="target_code==''||target_code==null" class="red f-95 mb-2"><fa icon="exclamation-circle"/> 회원가입 시 입력하신 이메일로 인증코드가 발송되었습니다. </div>
                    <b-form-input :disabled="wait_response==true" placeholder="이메일로 받은 다섯자리 코드를 입력해주세요." v-model="target_code" class="mb-4" @keyup.enter="checkConfirm()"></b-form-input>
                    <b-button @click="checkConfirm()" :disabled="wait_response==true" variant="teal">{{wait_response==false?'인증하기':'인증 중'}} <b-spinner v-if="wait_response==true" small></b-spinner></b-button>
                    <p v-if="wait_response==true" class="f-90 teal"> <fa icon="info-circle" class="mr-1"/>인증 중입니다. 잠시만 기다려주세요.</p>
                    <p v-if="alert_msg!=''" class="f-90 red"> <fa icon="exclamation-circle" class="mr-1"/> {{ alert_msg }} </p>

                </b-card>                   
                <b-card v-else class="py-4 px-md-2 px-xl-5 border-light text-center">
                    <h5 ><fa icon="check-circle" class="mr-2"/>{{mode=='ownership'? '소유권 이전이 완료되었습니다.' :'인증이 완료되었습니다.' }} </h5>
                    <h5 class="mb-5">{{ confirm_msg }}</h5>
                    <b-button variant="teal" @click="goLoginOrSignup"> {{ signup_activate==false?'로그인 하기': '회원가입 하기' }}</b-button>
                </b-card>
            </b-col>
        <b-col cols="0" md="2" lg="3"></b-col>
    </b-row>
</b-container>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

export default {
    data(){
        return {
            target_e_mail: '',
            target_code: '',
            e_mail_form_disabled: false, 

            already_confirmed: false,

            alert_msg: '',
            is_sending_code: false,

            mode: '',
            wait_response: false,
            confirm_msg:'',
            signup_activate: false, // 회원가입 해야할 경우 true
        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState(['e_mail', 'user_id', 'is_confirmed']),
    },
    methods:{
        ...mapActions(['logout']),
        ...mapMutations('report_list', ['add_or_update_coauthor']),
        
        checkConfirm(){  // checkConfirm 파트 각 mode별로 내용 수정해야함. 
            if(this.formCheck()==false) {
                return false
            }
            this.wait_response = true
            let formData = new FormData()
            formData.append('e_mail', this.target_e_mail)
            formData.append('confirm_code', this.target_code)

            if( this.mode == 'signup' ){ // 회원가입 후 이메일 계정 인증 
                const path = this.$store.state.backend_host + '/confirm_user'
                return axios.post(path, formData)
                .then( response => {
                    if (response.data.code == 'success'){                        
                        this.already_confirmed = true
                        this.confirm_msg = '이메일 계정을 인증하였습니다. 로그인 후 이용해 주세요.'
                        this.$EventBus.$emit('make-toast', '계정 인증', this.confirm_msg)
                    }
                    else if(response.data.code == 'failure'){
                        this.alert_msg = response.data.msg
                    }
                })
            } else if( this.mode == 'coauthor' ) {
                const path = this.$store.state.backend_host + '/accept_coauthor_invitation'
                return axios.post(path, formData)
                .then( response => {
                    // console.log(response.data);
                    if (response.data.code == 'success'){
                        this.already_confirmed = true
                        if(this.signup_activate==false){
                            this.confirm_msg = '공동 작성 참여자로 등록되었습니다. 로그인 후 이용해 주세요.'                 
                            this.$EventBus.$emit('make-toast', '팀원 인증 완료', this.confirm_msg)         
                        }else {
                            this.confirm_msg = '회원가입 후 공동 작성자로 참여할 수 있습니다. 회원가입을 진행해 주세요.'
                            this.$EventBus.$emit('make-toast', '팀원 인증 완료', this.confirm_msg)                      
                        }                     
                    }
                    else if(response.data.code == 'failure'){
                        this.alert_msg = response.data.msg
                    }
                })
            } else if( this.mode == 'ownership'){
                const path = this.$store.state.backend_host + '/accept_move_ownership'
                return axios.post(path, formData)
                .then( response => {
                    if (response.data.code == 'success'){
                        this.already_confirmed = true
                        this.confirm_msg = '로그인 후 이용해 주세요.' // 여기도 coauthor 처럼 회원가입 여부 확인해야 하는지? 
                        this.$EventBus.$emit('make-toast', '소유권 이전 동의', this.confirm_msg )
                    }
                    else if(response.data.code == 'failure'){
                        this.alert_msg = response.data.msg
                    }
                })
            } 
            this.wait_response = false
        },
        initiateForm(){
            this.target_e_mail = this.$route.query.e_mail
            this.target_code = this.$route.query.code
        },
        checkMode(){
            if(this.mode=='signup'){
                if(this.e_mail == this.$route.query.e_mail){ // 로그인 되어있는 상태에서 인증 페이지로 넘어 왔을 경우
                    if (this.is_confirmed == true || this.is_confirmed == 'true'){
                        this.already_confirmed = true
                    }
                    else { this.initiateForm() }
                }
                else {
                    const path = this.$store.state.backend_host + '/check_user_confirmed'
                    return axios.get(path, {params: { e_mail: this.$route.query.e_mail, code: this.$route.query.code }})
                    .then( response => {
                        // console.log(response.data);
                        if (response.data.accepted==1||response.data.accepted==true) {
                            this.already_confirmed = true
                        }else this.initiateForm() 
                    })                    
                }
            }
            else if (this.mode=='coauthor'){
                const path = this.$store.state.backend_host + '/coauthor_accepted_check'
                return axios.get(path, {params: { e_mail: this.$route.query.e_mail, code: this.$route.query.code }})
                .then( response => {
                    // console.log(response.data);
                    if (response.data.user_exist==false){
                        this.signup_activate = true
                        this.initiateForm()
                    }
                    if (response.data.accepted==1||response.data.accepted==true) {
                        this.already_confirmed = true
                    }
                    else { this.initiateForm() }
                })
            }
            else if (this.mode=='ownership'){
                const path = this.$store.state.backend_host + '/ownership_request_check'
                return axios.get(path, {params: { e_mail: this.$route.query.e_mail }})
                .then( response => {
                    // console.log(response.data);
                    if (response.data.user_exist==false){
                        this.signup_activate = true
                        this.initiateForm()
                    }
                    if (response.data.accepted==1||response.data.accepted==true) {
                        this.already_confirmed = true
                    }
                    else { this.initiateForm() }
                })
            }
        },
        formCheck(){
            if (this.target_e_mail==''||this.target_e_mail==null){
                alert ('이메일 주소를 입력해 주세요.')
                return false
            }else if (this.target_code==''||this.target_code==null){
                alert ('이메일 초대장으로 받은 초대 코드를 입력해 주세요.')
                return false
            }else return true
        },
        goLoginOrSignup(){ 
            if(this.signup_activate==false){   //현재 page에서 완전히 나가서 새로 edit page 쪽으로 접근
                window.location.href = 'http://report.qesg.co.kr/'
            }else {  //현재 page내 라우터 이동, signup 후에는 다시 새로 report.qesg.co.kr 접근 필요
                let mode = 'coauthor_accepted'
                if(this.mode=='ownership') {
                    mode = ''
                }
                this.$router.push({name: 'Signup', query: {e_mail: this.target_e_mail, mode: mode}})
            }            
        }
    },
    created(){
        // console.log(this.$route.query);
        this.mode = this.$route.query.mode
        this.checkMode()
    },
    mounted(){
        
    }

}
</script>
<template>
<div>
    <router-view name="editor_header"></router-view>

    <b-container class="py-5 vh-adjust">
        <router-view></router-view>
    </b-container>

    <router-view name="editor_footer"></router-view>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    computed:{
        ...mapState(['user_id', 'is_confirmed', 'is_admin']),

    },
    methods:{
        ...mapActions('billings', ['readBillingList']),
        
    },
    created(){
        this.readBillingList({user_id: this.user_id})
    },

}
</script>

<style>

</style>
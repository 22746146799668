<template>
    <div>
        <div v-if="loaded==true" class="no-page-brake-inside">
            <div v-if="modal_table===false && table_mode === 'newtab'" class="line-height-lg">
                <div
                    :class="`data-table-${data_table_id}`"
                    v-if="show_title==true" 
                    class="business-case-link cursor mb-2 fw-500"
                    :style="main_color"
                    @click="redirectToSamplePage"
                >
                    <fa icon="table" class="mr-1"/>{{ lang!='ENG'? title: title_e }}
                </div>
                
    
                <div class="mb-4 hide">
                    <template v-if="lang !== 'ENG'">
                        <b-table-simple 
                            responsive
                            :class="`${table_style}`==='basic_table'?'edi-table':'no-line-table'"
                            :borderless="!show_table_line"
                            :outlined="show_table_outline"
                            >
                            <b-thead>
                                <b-tr>
                                    <b-td v-for="(property, col_index) in header"
                                        :key="`newtab-table-th-cell-${col_index}`"
                                        :style="`${table_style}`==='custom_table'?`color: ${table_header_font_color}; background-color: ${table_header_color}`:''"
                                        >
                                        {{ property }}
                                    </b-td>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(row_item, row_index) in contents"
                                    :key="`newtab-table-tr-${row_item.id}-rows-${row_index}`"
                                    >
                                    <template v-for="(property, header_index) in header">
                                        <template v-if="differentPrevious(contents, row_index, property, header)==true">
                                            <b-td :key="`newtab-table-td-${row_item.id}-rows-${row_index}-cell-${header_index}`"
                                                :rowspan="getSameLength(contents, row_index, property, header)"
                                                >
                                                <span 
                                                    v-html=formattedText(row_item[property])>
                                                </span>   
                                            </b-td>
                                        </template>
                                    </template>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </template>
                    <template v-else>
                        <b-table-simple 
                            responsive
                            :class="`${table_style}`==='basic_table'?'edi-table':'no-line-table'"
                            :borderless="!show_table_line"
                            :outlined="show_table_outline"
                            >
                            <template v-if="header_e != null">
                                <b-thead>
                                    <b-tr>
                                        <b-td v-for="(property, col_index) in header_e" 
                                            :key="`newtab-table-th-cell-${col_index}`"
                                            :style="`${table_style}`==='custom_table'?`color: ${table_header_font_color}; background-color: ${table_header_color}`:''"
                                            >
                                            {{ property }}
                                        </b-td>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="(row_item, row_index) in contents_e"
                                        :key="`newtab-table-td-${row_item.id}-rows-${row_index}`"
                                        >
                                        <template v-for="(property, header_index) in header">
                                            <template v-if="differentPrevious(contents, row_index, property, header)==true">
                                                <b-td :key="`newtab-table-td-${row_item.id}-rows-${row_index}-cell-${header_index}`"
                                                    :rowspan="getSameLength(contents, row_index, property, header)">
                                                    <span 
                                                    v-html=formattedText(row_item[property])>
                                                </span>   
                                                </b-td>
                                            </template>
                                        </template>
                                    </b-tr>
                                </b-tbody>
                            </template>
                            <caption v-if="footnote" class="f-85 gray-600 mb-0">
                                {{ lang!='ENG'? footnote : footnote_e }}
                            </caption>
                        </b-table-simple>
                    </template>
                    
                </div>
                
            </div>

            <div v-else-if="modal_table===false && table_mode === 'accordion'" class="line-height-lg">
                <div
                    :class="`data-table-${data_table_id}`"
                    v-if="show_title==true" 
                    class="mb-2 fw-500"
                    :style="main_color"
                    @click="hideDataTableFromAccordion"
                    >
                    <fa icon="table" class="mr-1"/>{{ lang!='ENG'? title: title_e }} 

                    <div v-if="!print_mode" class="float-right mb-2">
                        <b-button variant="outline-dark" size="sm" @click="download_table_excel">
                            <b-icon icon="file-earmark-excel-fill" scale="1" shift-v="1.25" aria-hidden="true" /> 
                            EXCEL
                        </b-button>
                    </div>
                </div>
                <div class="mb-4">                                                
                    <template v-if="lang !== 'ENG'">
                        <b-table-simple :class="`${table_style}`==='basic_table'?'edi-table':'no-line-table'"
                            :borderless="!show_table_line"
                            :outlined="show_table_outline"
                            responsive
                            >
                            <b-thead>
                                <b-tr>
                                    <b-td v-for="(property, col_index) in header" 
                                        :style="`${table_style}` === 'custom_table' ? `color: ${table_header_font_color}; background-color: ${table_header_color} !important;` : ''"
                                        :key="`accordion-table-th-cell-${col_index}`"
                                        >
                                        {{ property }} 
                                    </b-td>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(row_item, row_index) in contents"
                                    :key="`accordion-table-tr-${row_item.id}-rows-${row_index}`"
                                    >
                                    <template v-for="(property, header_index) in header">
                                        <template v-if="differentPrevious(contents, row_index, property, header) == true">
                                            <b-td :key="`accordion-table-tr-${row_item.id}-rows-${row_index}-cell-${header_index}`"
                                                :rowspan="getSameLength(contents, row_index, property, header)">
                                                <span 
                                                    v-html=formattedText(row_item[property])>
                                                </span>   
                                            </b-td>
                                        </template>
                                    </template>
                                </b-tr>
                            </b-tbody>
                            <caption v-if="footnote" class="f-85 gray-600 mb-0">
                                {{ lang!='ENG'? footnote : footnote_e }}
                            </caption>
                        </b-table-simple>
                    </template>
                    <!-- <b-table-simple 
                        v-if="lang!='ENG'" 
                        responsive 
                        class="edi-table"
                    >
                        <b-thead >
                            <b-tr>
                                <b-td
                                v-for="(property, col_index) in header" 
                                :key="col_index"
                                :style="true?`background-${main_color} !important; color: white;`:''"
                                 >
                                    {{ property }} 
                                </b-td>
                            </b-tr>
                    </b-thead>
                        <b-tbody>
                            <b-tr v-for="row_item in contents" :key="row_item.id">
                                <b-td v-for="property in header" :key="property.id">{{ row_item[property] }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple> -->
                    <template v-else>
                        <b-table-simple :class="`${table_style}`==='basic_table'?'edi-table':'no-line-table'"
                            :borderless="!show_table_line"
                            :outlined="show_table_outline"
                            responsive
                            >
                            <template v-if="header_e !== null">
                                <b-thead>
                                    <b-tr>
                                        <b-td v-for="(property, col_index) in header_e" 
                                            :key="`accordion-table-th-cell-${col_index}`"
                                            :style="`${table_style}`==='custom_table'?`color: ${table_header_font_color}; background-color: ${table_header_color}  !important;`:''"
                                            >
                                            {{ property }}
                                        </b-td>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="(row_item, row_index) in contents_e"
                                        :key="`accordion-table-tr-${row_item.id}-rows-${row_index}`"
                                        >
                                        <template v-for="(property, header_index) in header_e">
                                            <template v-if="differentPrevious(contents_e, row_index, property, header_e)==true">
                                                <b-td :key="`accordion-table-tr-${row_item.id}-rows-${row_index}-cell-${header_index}`"
                                                    :rowspan="getSameLength(contents_e, row_index, property, header_e)">
                                                    <span 
                                                        v-html=formattedText(row_item[property])>
                                                    </span> 
                                                </b-td>
                                            </template>
                                        </template>
                                    </b-tr>
                                </b-tbody>
                            </template>
                            <caption v-if="footnote" class="f-85 gray-600 mb-0">
                                {{ lang!='ENG'? footnote : footnote_e }}
                            </caption>
                        </b-table-simple>
                    </template>
                    
                </div>
            </div>
            <div v-else-if="modal_table===true || table_mode === 'popup'">
                <a class="mb-2 mr-3 cursor" :style="main_color" @click="showDataTable()"><fa icon="table" class="mr-1"/> {{ lang!='ENG'? title: title_e }} </a>
                <div v-show="opened_modal_id.includes('data_table_'+data_table_id)" class="hj-modal-example bg-white rounded shadow">
                    <div class="hj-modal-header fw-500 d-flex align-items-center justify-content-between px-3">
                        <div><b-badge variant="border-light" class="mr-2">Data Table.</b-badge> {{ lang!='ENG'? title: title_e }}</div>
                        <div style="min-width: 74px" align="right"><b-button variant="icon-light" @click="low_height=!low_height" class="py-0 px-2 mr-1"><fa :icon="low_height==true?'expand' :'compress-alt'"></fa> </b-button><b-button variant="icon-light" @click="closeDataTable()" class="py-0 px-2"><fa icon="times"></fa> </b-button></div>
                    </div> 
                    <div class="hj-modal-body p-3" :style="low_height==true? 'height:calc(50vh - 50px)!important':''">
                        <template v-if="lang !== 'ENG'">
                            <b-table-simple 
                                :class="`${table_style}`==='basic_table'?'bor-bg-table':'no-line-table'"
                                :borderless="!show_table_line"
                                :outlined="show_table_outline"
                                responsive
                                >
                                <b-thead>
                                    <b-tr>
                                        <b-td v-for="property, col_index in header" 
                                            :key="`popup-table-th-cell-${col_index}`"
                                            :style="`${table_style}`==='custom_table'?`color: ${table_header_font_color}; background-color: ${table_header_color}`:''"
                                            >
                                            {{ property }}
                                        </b-td>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="(row_item, row_index) in contents"
                                        :key="`popup-table-tr-${row_item.id}-rows-${row_index}`"
                                        >
                                        <template v-for="(property, header_index) in header">
                                            <template v-if="differentPrevious(contents, row_index, property, header)==true">
                                                <b-td :rowspan="getSameLength(contents, row_index, property, header)"
                                                    :key="`popup-table-tr-${row_item.id}-rows-${row_index}-cell-${header_index}`"
                                                    >
                                                    <span 
                                                        v-html=formattedText(row_item[property])>
                                                    </span> 
                                                    
                                                </b-td>
                                            </template>
                                        </template>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </template>
                        <template v-else>
                            <b-table-simple 
                                :class="`${table_style}`==='basic_table'?'bor-bg-table':'no-line-table'"
                                :borderless="!show_table_line"
                                :outlined="show_table_outline"
                                responsive
                                >
                                <b-thead>
                                    <b-tr>
                                        <b-td v-for="property, col_index in header_e"
                                            :key="`popup-table-th-cell-${col_index}`"
                                            >
                                            {{ property }}
                                        </b-td>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="(row_item, row_index) in contents_e"
                                        :key="`popup-table-tr-${row_item.id}-rows-${row_index}`"
                                        >
                                        <template v-for="(property, header_index) in header_e">
                                            <template v-if="differentPrevious(contents_e, row_index, property, header_e)==true">
                                                <b-td :rowspan="getSameLength(contents_e, row_index, property, header_e)"
                                                    :key="`popup-table-tr-${row_item.id}-rows-${row_index}-cell-${header_index}`"
                                                    >
                                                    <span 
                                                        v-html=formattedText(row_item[property])>
                                                    </span> 
                                                </b-td>
                                            </template>
                                        </template>
                                    </b-tr>
                                </b-tbody>
                                <caption v-if="footnote" class="f-85 gray-600 mb-0">
                                    {{ lang!='ENG'? footnote : footnote_e }}
                                </caption>
                            </b-table-simple>
                        </template>
                        
                    </div>
                </div>
            </div>
            <div v-else>
                <b-skeleton class="mb-4"></b-skeleton>
            </div>
        </div>
        <div v-else>
            <b-skeleton class="mb-4"></b-skeleton>
        </div>
    </div>
    </template>
    
    <script>
    import { mapState, mapGetters, mapMutations } from 'vuex'
    import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'
    import { formattedText } from '@/components/util'

    import * as ih from '@/components/util'
    
    export default {
        name: 'DataTable',
        components:{},
        mixins: [
            gri_mixin
        ],
        data() {
            return {
                title: '',
                description: '',
                header: [],
                contents: [],
                footnote: '',
                options: {},
                time_stamp: '',
                title_e: '',
                description_e: '',
                header_e: [],
                contents_e: [],
                footnote_e: '',
                time_stamp_e: '',
                loaded: false,
                low_height: false
            }
        },    
        props:{
            data_table_id: {type: [String, Number], default: ''},
            modal_table: { type: Boolean, default: true},
            lazy_load: { type: Boolean, default: true },
            data_table_obj: { type: Object, default() { return {} } },
            show_title: { type: Boolean, default: true },
            table_mode: { type: String, default: "accordion" },
            print_mode: { type: Boolean, default: () => false },
    
    
            table_style: {type: String, default: 'basic_table'},
            show_table_line: {type: Boolean, default: true},
            show_table_outline: {type: Boolean, default: false},
            table_header_color: {type: String, default: '#dedede'},
            table_header_font_color: {type: String, default: '#fffff'},
    
        },
        computed:{
            ...mapState(['backend_host']),
            ...mapState('report_list', ['report_code', 'lang', 'view_mode']), 
            ...mapState('gri2021_modal_open_status', ['opened_modal_id']), //open된 bc 확인
            ...mapState('data_tables', ['table_list']),
            ...mapGetters('report_list', ['selected_report']),
            main_color(){
                return `color:`+this.selected_report.main_color
            },
            main_color_sub(){
                return `color:`+this.selected_report.main_color+`; border-top: 1px solid `+this.selected_report.main_color+`!important`+`; border-bottom: 1px solid `+this.selected_report.main_color
            },          
        },
        methods: {
            ...mapMutations('gri2021_modal_open_status', ['add_opened_data_table_id', 'delete_opened_data_table_id']),
            formattedText,
            readDataTableEntity() {   
                const path = this.backend_host + '/read_data_table_entity'
    
                return axios.get(path, { params: {
                    data_table_id : this.data_table_id
                }})
                .then( response => {
                    this.title = response.data.title
                    this.description = response.data.description
                    this.header = response.data.header
                    this.contents = response.data.contents
                    this.footnote = response.data.footnote
                    this.options = response.data.options
                    this.time_stamp = response.data.time_stamp
                    this.time_stamp_e = response.data.time_stamp_e
                    this.title_e = response.data.title_e
                    this.description_e = response.data.description_e
                    this.header_e = response.data.header_e
                    this.contents_e = response.data.contents_e
                    this.footnote_e = response.data.footnote_e
                    this.loaded = true
                })
            },
            showDataTable(){
                this.add_opened_data_table_id(this.data_table_id)
            },
            closeDataTable(){
                this.delete_opened_data_table_id(this.data_table_id)
            },
            hideDataTableFromAccordion(event) {
                event.target.nextElementSibling.classList.toggle('hide');
            },
            redirectToSamplePage(e) {
                // const tableElement = e.target.nextSibling.cloneNode(true);
                // tableElement.classList.toggle('hide');
                // const newTab = window.open('', '_blank');
                // if (newTab) {
                //     newTab.location.href = `/form_gallery?htmlString=${tableElement.outerHTML}`;
                // }
                if (this.lang !== "ENG") {
                    const newTab = window.open('', '_blank');
                    if (newTab) {
                        newTab.location.href = `/form_gallery?type=dtable&id=${this.data_table_id}`;
                    }

                } else {
                    const newTab = window.open('', '_blank');
                    if (newTab) {
                        newTab.location.href = `/form_gallery?type=dtable&id=${this.data_table_id}&lang=ENG`;
                    }

                }

            },
            getSameLength(o_array, index, key, header) {
                let rowspan = 1
                let key_idx = header.findIndex(x => x == key)
                for (let i = index; i < o_array.length; i++) {
                    if (i + 1 >= o_array.length) { return rowspan }
                    if (o_array[i][key] === o_array[i + 1][key] && key_idx == 0) {
                        rowspan += 1
                    } else {
                        return rowspan
                    }
                }
            },
            differentPrevious(o_array, index, key, header) {
                if (index - 1 < 0) return true
                let key_idx = header.findIndex(x => x == key)
                if (o_array[index][key] === o_array[index-1][key] && key_idx == 0) {
                    return false 
                } else {
                    return true
                }
            },

            async download_table_excel() {


                const link = document.createElement('a');
                link.href = `${this.$store.state.backend_host}/report/download_table_excel?ti=${this.data_table_id}&lang=${this.lang}`;
                document.body.appendChild(link);
                link.click();
                link.remove();





                
            }
        },
        created(){
            //1. Prop 있는 경우 그냥 최우선
            if(this.data_table_obj.hasOwnProperty('table_id')==true){
                this.title = this.data_table_obj.title
                this.description = this.data_table_obj.description
                this.header = this.data_table_obj.header
                this.contents = this.data_table_obj.contents
                this.footnote = this.data_table_obj.footnote
                this.options = this.data_table_obj.options
                this.time_stamp = this.data_table_obj.time_stamp
                this.time_stamp_e = this.data_table_obj.time_stamp_e
                this.title_e = this.data_table_obj.title_e
                this.description_e = this.data_table_obj.description_e
                this.header_e = this.data_table_obj.header_e
                this.contents_e = this.data_table_obj.contents_e
                this.footnote_e = this.data_table_obj.footnote_e
                this.loaded = true
            }
    
            //2. Lazy Load 옵션 줘서 직접 읽는 경우
            if(this.lazy_load==true && this.loaded ==false) { this.readDataTableEntity(); }
    
            //3. lazy load 하지 않고 미리 읽은 상태 & prop 전달 못 받은 경우, Store Table List에서 검색.
            if(this.lazy_load==false && this.loaded ==false) {
                let idx = this.table_list.findIndex(item => { return item.table_id == this.data_table_id })
    
                if(idx > -1){
                    this.title = this.table_list[idx].title
                    this.description = this.table_list[idx].description
                    this.header = this.table_list[idx].header
                    this.contents = this.table_list[idx].contents
                    this.footnote = this.table_list[idx].footnote
                    this.options = this.table_list[idx].options
                    this.time_stamp = this.table_list[idx].time_stamp
                    this.time_stamp_e = this.table_list[idx].time_stamp_e
                    this.title_e = this.table_list[idx].title_e
                    this.description_e = this.table_list[idx].description_e
                    this.header_e = this.table_list[idx].header_e
                    this.contents_e = this.table_list[idx].contents_e
                    this.footnote_e = this.table_list[idx].footnote_e
                    this.loaded = true
                }
    
                else{ //아직 로딩되지 않은 상태
                    this.readDataTableEntity()
                }
    
            }
        }
    }
    
    
    </script>
    
    <style>
    .hide {
        display: none;
    }
    
    </style>
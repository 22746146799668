<template>
  <div>
    <b-row>
      <b-col cols="3">
        <b-form-select v-model="search_category" class="mb-3">
          <b-form-select-option value="title">보고서 제목</b-form-select-option>
          <b-form-select-option value="owner_email">보고서 소유자</b-form-select-option>
          <b-form-select-option value="participant_email"
            >보고서 참여자</b-form-select-option
          >
          <b-form-select-option value="report_code">보고서 코드</b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col cols="9">
        <b-form-input
          class="mb-5"
          placeholder="보고서명을 입력 후 엔터👈👈"
          @keyup.enter="get_report_list_by_keyword"
          v-model="admin_input"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import * as ih from "@/components/util";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      admin_input: null,
      search_category: "title",
    };
  },
  computed: {
    ...mapState("report_list", ["report_list", "is_report_list_loaded"]),
    ...mapState(["user_id", "is_admin", "e_mail"]),
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  methods: {
    ...mapActions("report_list", [
      "readReportListByUserId",
      "readReportListByKeywordFromAdmin",
    ]),

    get_report_list_by_keyword() {
      if (this.admin_input) {
        this.readReportListByKeywordFromAdmin({
          keyword: this.admin_input,
          search_category: this.search_category,
          user_id: this.e_mail,
        })
          .then(() => {
            this.initiateReportThumbs();
          })
          .catch((error) => {
            // console.log(error);
            alert(`관리자 로그인이 아닙니다. 다시 로그인해주세요.`);
            this.logout().then(() => this.$router.push({ name: "Login" }));
          });
      } else {
        this.readReportListByUserId({ user_id: this.user_id })
          .then(() => {
            this.initiateReportThumbs();
          })
          .catch((error) => {
            // console.log(error);
            alert(`다중 로그인이 감지되어 로그아웃 합니다. 다시 로그인해 주십시오.`);
            this.logout().then(() => this.$router.push({ name: "Login" }));
          });
      }
    },
    initiateReportThumbs() {
      this.report_list.forEach(async (x) => {
        let new_thumb = await ih.getFile(
          x.featured_image_name,
          x.featured_image_path,
          true
        );
        this.$set(x, "thumb_img", new_thumb.link);
      });
    },
  },
};
</script>
<style></style>

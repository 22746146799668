<template>
    <span>

        <span v-if="!edit_delete_mode">
    
            <b-button
                variant="light" 
                :disabled="is_unlinked===true" 
                class="py-1 px-2 mr-1"
                v-b-tooltip.hover.bottom title="데이터 테이블 추가" 
                @click.stop.prevent="addChartsFromDC"
            >
                <fa v-if="is_able_to_edit" icon="table"/>
            </b-button>
        </span>
        <span v-else>
            <b-button 
                variant="light" 
                class="py-1 px-2" 
                v-b-tooltip.hover 
                title="편집" 
                @click.stop.prevent="addChartsFromDC"
            >
                <fa icon="pen"/>
            </b-button>
        </span>
        
        <div v-show="show_modal" class='hj-modal-writing-bottom-lg bg-white rounded shadow' :style="low_height==true? 'height:50vh!important':''">
            <div class="hj-modal-header d-flex align-items-center justify-content-between px-4">
                데이터 테이블
                <div>
                    <b-button variant="icon-light" @click="low_height=!low_height">
                        <fa :icon="low_height==true?'expand' :'compress'"></fa> 
                    </b-button>
                    <b-button variant="icon-light" @click="show_modal=false">
                        <fa icon="times"></fa> 
                    </b-button>
                </div>
            </div> 
            <div class="hj-modal-body px-4 overflow-auto" :style="low_height==true? 'height:calc(50vh - 50px)!important':''">
                <!-- 데이터 테이블 리스트 -->
                <DCTablesList 
                    v-if="modal_mode==='table_list'"
                    :editing_mode = true
                    :dc_key_info="dc_key_info"
                    @closeModal="closeModal"
                    @flag_modal_mode="flag_modal_mode"
                    :table_id="selected_mng_table_id"
                />

                <!-- 데이터 테이블 편집 -->
                <EditDataTable
                    v-else-if="modal_mode==='table_edit'"
                    :editing_mode = true
                    :table_id="selected_mng_table_id"
                    @flag_modal_mode="flag_modal_mode"
                />

                <!-- 데이터 테이블 관리 -->
                <ManageTables 
                    v-else-if="modal_mode==='table_manage'"
                    :editing_mode = true
                    :table_id="selected_mng_table_id"
                    @flag_modal_mode="flag_modal_mode"
                />
            </div>
        </div>
    </span>

</template>
<script>
import { mapState } from "vuex";
import ChartCaseList from '@/components/writing_report/chart_subs/ChartCaseList.vue';
import DefaultChart from '@/components/writing_report/chart_subs/DefaultChart'
import DCTablesList from '@/components/writing_report/data_table_subs/DCTablesList'
import EditDataTable from '@/components/writing_report/data_table_subs/EditDataTable'
import ManageTables from '@/components/writing_report/data_table_subs/ManageTables'
export default {
    name: '',
    computed: {
        ...mapState('charts', ['chart_data', 'chart_field', 'chart_linked_indicators', 'selected_chart_id', 'selected_chart_data'])
    },
    props: {
        is_unlinked: { type: Boolean, default: false },
        is_able_to_edit: { type: Boolean, default: false },
        dc_key_info: { type: Array, default: [] },
        selected_mng_table_id: { type: Number, default: null },
        edit_delete_mode: { type: Boolean, default: false },

    },
    mounted() {
        
    },
    components: {
        ChartCaseList,
        DefaultChart,
        DCTablesList,
        EditDataTable,
        ManageTables
    },
    data() {
        return {
            example: '',
            show_modal: false,
            low_height: false,
            is_default_chart: false,
            modal_mode: "table_list", // "table_list", "table_edit", "table_manage"
            // selected_mng_table_id: ""
        }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() { },
    methods: {
        addChartsFromDC() {
            this.show_modal = true;

            if (this.edit_delete_mode) {
                this.modal_mode = 'table_edit'
            }
        },
        closeModal() {
            this.show_modal = false;
        },
        flag_modal_mode(flag, selected_mng_table_id = '') {
            this.modal_mode = flag

            if (selected_mng_table_id !== '') {
                this.selected_mng_table_id=selected_mng_table_id
            }
        }
    }
}
</script>
<style>
</style>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'



export const gri_mixin = {
    data() {
        return {
            is_unlinked: false
        }
    },
    created() {
    },
    mounted() {
    },
    computed:{
        ...mapState('report_list', ['report_code', 'standard_mode']),
      ...mapState("report_list", ["standard_mode"]),
        ...mapGetters('report_list', ['selected_report']),
        ...mapState('report_contents', ['report_contents']),
        ...mapGetters('data_tables', ['hashed_data_tables']),
        ...mapGetters('documents', ['hashed_docs']),
        ...mapGetters('business_cases', ['hashed_business_cases']),
    },
    methods:{
        ...mapMutations('cursor', ['update_editing_gri_code', 'change_document_mode', 'update_editing_dc_code']),
        ...mapActions('data_tables',['updateDataTableRelation']),
        ...mapActions('documents',['updateDocumentRelation']),
        ...mapActions('business_cases', ['updateBusinessCaseRelation']),
        scrollToHash() {
            this.$nextTick(() => {
                const hash = window.location.hash;
                if (hash) {
                    // const element = decodeURIComponent(document.querySelector(hash));
                    const element = document.querySelector(hash);
                    
                    if (element && element !== 'null') {
                        element.scrollIntoView({ block: 'start', behavior: "smooth" });
                        // element.scrollIntoView({ behavior: "smooth" });
                    }
                }  
                
            });
        },
        changeWritingMode(gri_code) { //임시 해제
            if (!this.handleEdit()) {
                return;
            }
            // if (this.selected_report.state<4){
                this.initiateWritingMode(gri_code)
                this.is_writing_direct[gri_code] = true
            // }
            // else alert(`공개된 보고서는 수정할 수 없습니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 내용을 수정할 수 있습니다.`)
        },
        // 데이터 테이블, 비즈니스 사례, 문서 및 링크 추가-삭제-편집
        addDocuments(code_id) {
            
            if (!this.handleEdit()) {
                return;
            }
            // if (this.selected_report.state<4){
            if(code_id.type=='link'){
                this.change_document_mode('link')
            }
            this.update_editing_gri_code(code_id.gri_code)
            this.$emit('add-documents', code_id.document_id) //WritingMaster로 이벤트 보내서 modal open
            // }else alert(`공개된 보고서는 수정할 수 없습니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 내용을 수정할 수 있습니다.`)
        },
        addTables(code_id) {
            if (!this.handleEdit()) {
                return;
            }
            // if (this.selected_report.state<4){
                this.update_editing_gri_code(code_id?.gri_code)
                this.$emit('add-tables', code_id?.table_id) //WritingMaster로 이벤트 보내서 modal open
            // }else alert(`공개된 보고서는 수정할 수 없습니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 내용을 수정할 수 있습니다.`)
        },
        addTablesFromDC(lv1_info) {
            // FromDC는 Data Center 전용 메소드
            if (!this.handleEdit()) {
                return;
            }
            // this.update_editing_dc_code(lv1_info?.gri_code)
            this.$emit('add-tables', lv1_info?.table_id) 
        },
        addChartsFromDC(lv1_info) {
            // FromDC는 Data Center 전용 메소드
            if (!this.handleEdit()) {
                return;
            }
            this.update_editing_dc_code(lv1_info?.gri_code)
            this.$emit('add-dc-tables', lv1_info?.table_id)
        },
        addBusinessCases(code_id) {
            if (!this.handleEdit()) {
                return;
            }
            // if (this.selected_report.state<4){
                this.update_editing_gri_code(code_id.gri_code)
                this.$emit('add-business-cases', code_id.business_case_id) //WritingMaster로 이벤트 보내서 modal open
            // }else alert(`공개된 보고서는 수정할 수 없습니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 내용을 수정할 수 있습니다.`)
        },
        addCharts(code_id) {
            if (!this.handleEdit()) {
                return;
            }
            // // if (this.selected_report.state<4){
            //     this.update_editing_gri_code(code_id.gri_code)
            //     this.$emit('add-charts', code_id.business_case_id) //WritingMaster로 이벤트 보내서 modal open
            // // }else alert(`공개된 보고서는 수정할 수 없습니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 내용을 수정할 수 있습니다.`)

        },
        handleUnlink(code_id){
            // if (this.selected_report.state<4){
            if (!this.handleEdit()) {
                return;
            }
            this.is_unlinked = true
            let xx = this.report_contents.findIndex(yy => yy.gri_code == code_id.gri_code)
            if (xx>-1){
                let temp_payload = {}
                if(code_id.table_id>0){
                    temp_payload[code_id.table_id] = false
                    this.updateDataTableRelation({report_code: this.report_code, gri_code: this.report_contents[xx].gri_code, link_payload: temp_payload, framework: this.standard_mode })
                }
                else if(code_id.document_id>0){
                    temp_payload[code_id.document_id] = false
                    this.updateDocumentRelation({report_code: this.report_code, gri_code: this.report_contents[xx].gri_code, link_payload: temp_payload, framework: this.standard_mode })
                }
                else if(code_id.business_case_id>0){
                    temp_payload[code_id.business_case_id] = false
                    this.updateBusinessCaseRelation({report_code: this.report_code, gri_code: this.report_contents[xx].gri_code, link_payload: temp_payload, framework: this.standard_mode })
                }

            } else {
                if (this.standard_mode.includes('SASB') || this.standard_mode.includes('TCFD')) {
                    let temp_payload = {}
                    if(code_id.table_id>0){
                        temp_payload[code_id.table_id] = false
                        this.updateDataTableRelation({report_code: this.report_code, gri_code: code_id.gri_code, link_payload: temp_payload, framework: this.standard_mode })
                    }
                    else if(code_id.document_id>0){
                        temp_payload[code_id.document_id] = false
                        this.updateDocumentRelation({report_code: this.report_code, gri_code: code_id.gri_code, link_payload: temp_payload, framework: this.standard_mode })
                    }
                    else if(code_id.business_case_id>0){
                        temp_payload[code_id.business_case_id] = false
                        this.updateBusinessCaseRelation({report_code: this.report_code, gri_code: code_id.gri_code, link_payload: temp_payload, framework: this.standard_mode })
                    }
                }
            }
            this.is_unlinked = false
            //} else alert(`공개된 보고서는 수정할 수 없습니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 내용을 수정할 수 있습니다.`)
        },
        handleEdit(is_able_to_edit) {
            if (!this.selected_report.is_able_to_edit) window.alert("서비스 정기 결제가 필요합니다")
            return this.selected_report.is_able_to_edit
        },
        goHash(code, item = 'GRI') {
            let hash_code = '#w' + code
            try {
                this.handleCancle('hide_all')
                
            } catch (error) {
                console.log("chart list move")
            }
            // this.$router.push({ name: 'Gri'+code.slice(0,1)*100, hash: hash_code })
            if (item === 'GRI') {
                if (code.split("-")[0].length > 1) {
                   this.$router.push({ name: 'Gri'+code.slice(0,1)*100, hash: hash_code })
                } else {
                    this.$router.push({ name: 'Gri'+code.split("-")[0], hash: hash_code })
                }
            } else if (item === 'SASB') {
                this.$router.push('/write_report/sasb' + hash_code.replace(".", "_"))
                
            } else if (item === 'TCFD') {
                this.$router.push('/write_report/tcfd' + hash_code)
            } else if (item === 'DATA_CENTER') {
                console.log('data center move')
                hash_code = '#w' + code.lv3_info.key
                this.$router.push('/write_report/data_centers' + hash_code.replace(".", "_"))
            }

        },
        getSameLength(o_array, index, key, header) {
            let rowspan = 1
            let key_idx = header.findIndex(x => x == key)
            for (let i = index; i < o_array.length; i++) {
                if (i + 1 >= o_array.length) { return rowspan }
                if (o_array[i][key] === o_array[i + 1][key] && key_idx == 0) {
                    rowspan += 1
                } else {
                    return rowspan
                }
            }
        },
        differentPrevious(o_array, index, key, header) {
            if (index - 1 < 0) return true
            let key_idx = header.findIndex(x => x == key)
            if (o_array[index][key] === o_array[index-1][key] && key_idx == 0) {
                return false 
            } else {
                return true
            }
        },
        getValueFromObject(object, key) {
            return object[key]
            if (Object.keys(object).includes(key)) {
                return object[key]
            } else {
                return null
            }

        }
    }
}
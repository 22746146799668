<template>
<div>
    <h4 id="bs2">디자인 테마</h4>
    <p class="f-95 mb-4 gray-600">보고서 디자인 테마를 설정합니다.</p>

    <b-card class="general-card mb-5">
        <b-card-header>디자인 테마 설정</b-card-header>
        <div class="py-3">
            <!-- <b-row no-gutters class="hover-gray py-2 mb-2" :class="is_editing==true?'mb-3':''">
                <b-col cols="3"><div class="gray-600">표지 이미지</div> </b-col>
                <b-col v-if="is_editing==true"> <b-form-file placeholder="표지 이미지" v-model="local_report.featured_image"></b-form-file> </b-col>  
                <b-col v-else>
                    <template v-if="local_report.featured_image_name">
                        <b-img :src="local_report.featured_image_path" fluid></b-img>
                        <p class="f-85"> {{ local_report.featured_image_name }} </p>
                    </template>
                    <template v-else><p class="mb-0 gray-550"> (등록된 이미지가 없습니다.)</p></template>
                </b-col>
            </b-row> -->
            <b-row no-gutters class="hover-gray py-2 mb-2" :class="is_editing==true?'mb-3':''">
                <b-col cols="3"><div class="gray-600">컬러 설정</div> </b-col>  
                <b-col>
                    <div class="d-flex mb-2">메인 컬러 
                        <div v-if="is_editing==true" class="ml-4 bgray-200" style="width: 200px"><chrome-picker v-model="local_report.main_color" /></div>
                        <div v-else class="ml-4 d-flex align-items-center"> {{local_main_color}} <div class="ml-2 color-block" :style="`background-color:${local_main_color}`"></div></div>
                    </div>
                    <div class="d-flex">보조 컬러 
                        <div v-if="is_editing==true" class="ml-4 bgray-200" style="width: 200px"><chrome-picker v-model="local_report.sub_color" /></div>
                        <div v-else class="ml-4 d-flex align-items-center"> {{local_sub_color}} <div class="ml-2 color-block" :style="`background-color:${local_sub_color}`"></div></div>
                    </div>
                </b-col>  
            </b-row>
            <b-row no-gutters class="hover-gray py-2 mb-2" :class="is_editing==true?'mb-3':''">
                <b-col cols="3"><div class="gray-600">글씨체</div> </b-col>
                <b-col v-if="is_editing==true"> 
                    <b-form-select v-model="local_report.font_family" :options="['Noto Sans Kr', 'Gothic A1', 'Nanum Myeongjo', 'Nanum Gothic']"></b-form-select> 
                    <!-- <b-card :style="`font-family:${local_report.font_family};`" class="mt-3 border-0 bgray-200">ex. 123456789 안녕하세요, 2020 QuantifiedESG 보고서를 작성하고 있습니다! </b-card> -->
                </b-col>
                <b-col v-else> {{ local_report.font_family }} 
                    <!-- <b-card :style="`font-family:${local_report.font_family};`" class="my-2 border-0 bgray-200">ex. 123456789 안녕하세요, 2020 QuantifiedESG 보고서를 작성하고 있습니다! </b-card> -->
                </b-col>
            </b-row>
            <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_editing==true?'mb-3':''">
                <b-col cols="3"><div class="gray-600">본문 글씨 크기</div> </b-col>
                <b-col v-if="is_editing==true">
                    <b-form-select v-model="local_report.font_size" :options="[13, 14, 15, 16, 17]" style="width: calc(100% - 40px);" class="mr-1"></b-form-select> px
                    <b-card :style="`font-size: ${local_report.font_size}px; font-family:${local_report.font_family};`" class="mt-2 mb-3 border-0 bgray-200">ex. 123456789 안녕하세요, 2020 QuantifiedESG 보고서를 작성하고 있습니다! </b-card>
                </b-col>
                <b-col v-else> {{ local_report.font_size }} px 
                    <b-card :style="`font-size: ${local_report.font_size}px; font-family:${local_report.font_family};`" class="mt-2 mb-3 border-0 bgray-200">ex. 123456789 안녕하세요, 2020 QuantifiedESG 보고서를 작성하고 있습니다! </b-card>
                </b-col>
            </b-row>
            <!-- <b-row no-gutters class="hover-gray py-2 mb-5" :class="is_editing==true?'mb-3':''">
                <b-col cols="3"><div class="gray-600">레이아웃</div> </b-col>  
                <b-col v-if="is_editing==true"> <b-form-checkbox v-model="local_report.folded"> 전체 펼침</b-form-checkbox> </b-col>  
                <b-col v-else> {{ local_report.folded==true?'전체 펼침':'카테고리별 접힘' }} </b-col>
            </b-row> -->
            <b-button :variant="is_editing==true?'teal':'teal-border'" class="px-3 mr-3" @click="handleMulti"> {{ is_editing==true ?'디자인 테마 저장하기':'디자인 테마 수정하기'}} </b-button>
            <b-button v-if="is_editing==true" class="px-3" @click="handleCancle"> 취소 </b-button>
        </div>
    </b-card>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import * as ih from '@/components/util'
import { Chrome } from 'vue-color'
import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'

export default {
    mixins: [gri_mixin],
    data() {
        return {
            is_editing: false,
            local_report: {}
        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapGetters('report_list', ['selected_report']),
        local_main_color(){
            if (typeof this.local_report.main_color == 'object'){
                return this.local_report.main_color.hex
            }else return this.local_report.main_color
        },
        local_sub_color(){
            if (typeof this.local_report.sub_color == 'object'){
                return this.local_report.sub_color.hex
            }else return this.local_report.sub_color
        }

    },
    methods:{
        ...mapActions('report_list', ['saveReportPreference']),
        handleMulti() {
            const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
            if (!shouldEdit) return
            // if (this.selected_report.state<4){
                if(this.is_editing==true){
                    this.handleSave()
                }
                else {
                    this.toggleEdit()
                }
            //} else alert(`공개된 보고서는 수정할 수 없습니다. [기본설정 > 공개 및 공유]에서 '비공개 전환하기' 클릭 후 내용을 수정할 수 있습니다.`)
        },
        async handleCancle(){
            await this.initiateDesignTheme()
            this.toggleEdit()
        },
        toggleEdit(){
            this.is_editing = !this.is_editing
        },
        async handleSave(){
            let new_image = null
            if (this.local_report.featured_image){
                new_image = this.local_report.featured_image
            }
            await this.saveReportPreference({
                report_code : this.report_code,
                main_color: this.local_report.main_color.hex,
                sub_color: this.local_report.sub_color.hex,
                font_family: this.local_report.font_family,
                font_size: this.local_report.font_size,
                folded: this.local_report.folded,
                featured_image : new_image
            })            
            this.initiateDesignTheme()
            this.toggleEdit()            
        },
        async initiateDesignTheme(){
            let temp_theme = {}
            temp_theme.main_color = this.selected_report.main_color
            temp_theme.sub_color = this.selected_report.sub_color            
            temp_theme.font_family = this.selected_report.font_family
            temp_theme.font_size = this.selected_report.font_size
            temp_theme.folded = this.selected_report.folded
            temp_theme.featured_image_name = this.selected_report.featured_image_name
            let new_file = await ih.getFile(this.selected_report.featured_image_name, this.selected_report.featured_image_path )
            temp_theme.featured_image_path = new_file.link

            this.local_report = temp_theme
        },
    },
    created(){
        this.initiateDesignTheme()
    },
    components:{
        'chrome-picker': Chrome,
    },


}
</script>

<style>
.color-block{
    width: 20px;
    height: 20px;
}
</style>
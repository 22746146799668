<template>
<div>
    
    <!-- <hr class=""> -->

    <b-container class="">
        <div class="pb-3 d-sm-flex align-items-center justify-content-center"> 
            <!-- <b-img class="mr-4" style="width:120px;" src="/static_img/QESG_CI_clean.png"></b-img> -->
            <!-- <b-img style="width:140px;" src="/static_img/Software and tools Partner 2023.png"></b-img> -->
                <b-img class="mr-3" style="width:80px;" src="/static_img/qesg_vertical.png"></b-img>
                <b-img class="mr-4" style="width:75px;" src="/static_img/GRI-LicensingMarks-Partner2024-v1.png"></b-img>
                <b-img class="mr-1" style="width:58px;" src="/static_img/SASB-Inside-Interim-Vert.png"></b-img>
                <b-img class="" style="width:150px;" src="/static_img/ISSB-Stdrds-Inside-RGB-Logo.png"></b-img>


            </div>

        <b-row>
            <b-col cols="0" md="2" lg="3">
            </b-col>

            <b-col cols="12" md="8" lg="6" class="d-flex justify-content-center align-items-center">
                <div class="f-90 gray-500">(주)퀀티파이드이에스지</div>
                <b-button @click="$router.push('/terms/privacy_policy')" variant="icon" class="ml-2 f-90">개인정보처리방침</b-button>
                <b-button @click="$router.push('/terms/terms_of_use')" variant="icon f-90">이용약관</b-button>
            </b-col>

            <b-col>
                    <!-- <b-button @click="$router.push('/terms/privacy_policy')" variant="icon">개인정보처리방침</b-button>
                    <b-button @click="$router.push('/terms/terms_of_use')" variant="icon">이용약관</b-button> -->
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
export default {

}
</script>
<template>
    <div>
        <b-row no-gutters>
            <b-col class="">
                <b-card class="bgray-200 border-0 mb-5 px-5">
    
                    <template >                        
                        <p class="fw-400"> 
                            DATA CENTER 지표 전체 번역하기 
                        </p>
                        <p class=""> 머신러닝 기반 AI 자동번역을 제공합니다. </p>
                        <p class="red mb-2 fw-400"> 
                            <fa icon="exclamation-circle"/> 이미 수동 번역을 진행한 상태에서 번역하기를 누르면 수동 번역된 지표를 제외한 나머지의 지표들이 자동 번역으로 초기화됩니다. 
                            <br /> 
                            (수동번역을 자동번역으로 돌리려면 해당 지표로 돌아가 번역 초기화를 눌러주세요.) 
                        </p>
                        <p class="red mb-4 fw-400"> 
                            <fa icon="exclamation-circle"/> 
                            보고서 번역에 약 15분 내외가 소요되며, 보고서 번역하기를 클릭하신 후 15분 후에 재접속 하여 주십시오.  
                        </p>
                        
                        <div class="mb-4 text-center">
                            <b-button :disabled="is_translating==true" @click="handleTranslate" variant="teal" block class="px-3"> {{ is_translating===true? '번역 중' :'보고서 번역하기'}} <b-spinner v-if="is_translating==true" type="grow" style="margin-bottom:0.1rem" small></b-spinner> </b-button> 
                        </div>
                    </template>
                </b-card>           
            </b-col>
        </b-row>
    
    </div>
    </template>
    
    <script>
    import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
    import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'
    
    
    export default {
        mixins: [gri_mixin],
    
        data() {
            return {
                is_translating: false,
                report_name_e: '',
                is_saving: false,
                is_loading_report_name: false
            }
        },
        computed:{
            ...mapState('report_list', ['report_code']),
            ...mapGetters('report_list', ['selected_report']),
    
        },
        methods:{
            ...mapActions('report_list', ['translateReportName', 'saveReportPreference']),
            ...mapActions('report_contents', ['readReportContents', 'translateReport']),
            ...mapActions('data_tables', ['readDataTableList']),
            ...mapActions('documents', ['readDocumentList']),
            ...mapActions('business_cases', ['readBusinessCaseList']),
            ...mapMutations('report_contents', ['update_is_report_translated']),
    
            async handleTranslate() {
                const shouldEdit = this.handleEdit(this.selected_report.is_able_to_edit)
                if (!shouldEdit) return
                
                this.is_translating = true
                this.translateReport({report_code: this.report_code, specific_indicator: 'data_center'})
                alert(`번역이 시작되었습니다. 자동번역에 시간이 소요되니 15분 후 다시 '번역'탭으로 접속하여 주십시오.`)
                this.$router.push({name: 'Main'})
                // this.readAll() 
                this.is_translating = false
                // this.update_is_report_translated(true)
            },
            readAll(){
                this.readReportContents({report_code: this.report_code})
                this.readDataTableList({report_code: this.report_code})
                this.readDocumentList({report_code: this.report_code})
                this.readBusinessCaseList({report_code: this.report_code})
            },
        },
        async created(){
            this.is_loading_report_name = true
            // console.log(this.selected_report);
        },
        mounted(){
            
        },
           
    
    
    }
    </script>
    
    <style>
    
    </style>
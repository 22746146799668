<template>
<div>
    <h3 class="mb-5"> 검증의견서 번역하기 </h3>

        <b-button @click="show_preview=!show_preview" :variant="show_preview==true?'dark':'dark-border'" class="mr-2 mb-4">{{show_preview==true? '편집하기':'미리보기'}}</b-button>
        <div class="d-flex mb-2">   
            <h5 class="w-50"> 자동번역 </h5>
            <h5 class="ml-3" style="width: calc(50% - 15px)"> 수동번역 </h5>
        </div>
        <template v-if="show_preview==false">      
            <div class="d-flex mb-45">   
                <b-form-textarea v-model="statement_contents_ea" rows="24" auto-focus class="w-50" disabled></b-form-textarea>
                <b-form-textarea v-model="statement_contents_em" rows="24" style="width: calc(50% - 15px)" placeholder="검증의견서 수동번역 내용을 입력하세요." class="border-dark ml-3"></b-form-textarea>
            </div>  
        </template>
        <template v-else>
            <div class="d-flex mb-45">
                <div class="p-4 border-dark rounded shadow-sm w-50"> 
                    <h4 class="mb-4">제 3자 검증의견서</h4>
                    <md :contents="statement_contents_ea"></md>                   
                </div>
                <div style="width: calc(50% - 15px)" class="p-4 border rounded shadow-sm ml-3">
                    <h4 class="mb-4">제 3자 검증의견서</h4>
                    <md :contents="statement_contents_em"></md>
                </div>
            </div>
        </template>

    <div class="text-right mb-5 pb-5">
        <b-button variant="teal" @click="handleSave" class="px-4 f-110" :disabled="is_saving==true">저장하기<b-spinner small v-if="is_saving==true"></b-spinner></b-button>
    </div>
        

</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {
            show_preview: false,
            statement_contents_ea: '',
            statement_contents_em: '',
            is_saving: false
        }
    },
    computed:{
        ...mapState('assurance', ['admin_assurance']),
        ...mapState('admin', ['report_code']),

    },
    methods:{
        ...mapActions('assurance',['saveAssuranceRequest', 'saveAssuranceReport']),

        async handleSave(){
            if(this.checkForm()==true){
                this.is_saving = true
                if(this.admin_assurance.request_status>=4&&this.admin_assurance.request_status<6){
                    await this.saveAssuranceReport({
                        assurance_id: this.admin_assurance.assurance_id,
                        assurance_statement_em : this.statement_contents_em
                    })
                    this.$EventBus.$emit('make-toast', '검증의견서 저장', '검증의견서를 저장하였습니다.')
                }
                else if (this.admin_assurance.request_status==6){
                    alert('검증의견서가 이미 배포된 상태입니다. 배포 취소 후 영문을 수정해주세요.')
                }
                this.is_saving = false
            }                    
        },
        checkForm(){
            if(this.statement_contents==''){
                alert('검증의견서 내용을 작성해주세요.')
                return false
            }
            else return true
        }

    },
    mounted(){
        console.log(this.admin_assurance);
        console.log(this.admin_assurance.report_statement_em);
        // 저장된 assurance report 있으면 읽어오기
        if(this.admin_assurance.report_statement_ea!=null&&this.admin_assurance.report_statement_ea!=''){
            this.statement_contents_ea = this.admin_assurance.report_statement_ea
        }
        if(this.admin_assurance.report_statement_em!=null&&this.admin_assurance.report_statement_em!=''){
            this.statement_contents_em = this.admin_assurance.report_statement_em
        }
    },
    beforeDestroy(){
        this.show_preview = false
    }

}
</script>

<style>

</style>
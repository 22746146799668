<template>
<div>
                  
    <manage-team class="pb-3"></manage-team>
    <ownership-transfer></ownership-transfer>

</div>    
</template>
<script>
import ManageTeam from '@/components/report_preference/team_settings_subs/ManageTeam'
import OwnershipTransfer from '@/components/report_preference/team_settings_subs/OwnershipTransfer'

export default {
    data() {
        return {
            
        }
    },
    computed:{

    },
    methods:{

    },
    components:{
        ManageTeam,
        OwnershipTransfer
    }
    
}
</script>
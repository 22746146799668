<template>
    <b-row class="mt-5">
        <b-col cols="3">
            <b-skeleton-img no-aspect height="30px"></b-skeleton-img>
            <br>
            <b-skeleton-img class="mb-3" no-aspect height="24px"></b-skeleton-img>
            <b-skeleton-img class="mb-3" no-aspect height="24px"></b-skeleton-img>
            <b-skeleton-img class="mb-3" no-aspect height="24px"></b-skeleton-img>
            <b-skeleton-img class="mb-3" no-aspect height="24px"></b-skeleton-img>
            <b-skeleton-img class="mb-3" no-aspect height="24px"></b-skeleton-img>

        </b-col>
        <b-col cols="9">
            <div>
                <div>
                    <h2 class="fw-600 mb-3 mb-5 text-center">
                        <b-skeleton-img no-aspect height="30px" width="40%"></b-skeleton-img>
                    </h2>
                    <b-table-simple responsive class="disclosure-table lg border-bottom mb-5">
                        <b-tbody
                            class="mb-2 border-0"
                        >
                            <b-tr>
                                <b-td class="py-1 pl-1" >
                                    <span class="fw-500 f-120">
                                        <b-skeleton-img no-aspect height="30px" width="40%"></b-skeleton-img>
                                    </span>
                                    <span class="gray-500 f-90 fw-400 ml-1">
                                    </span>
                                </b-td>
                            </b-tr>

                            <b-tr>
                                <!-- <b-td 
                                    id="crp"
                                    class="pr-4 d-flex flex-column"
                                >
                                    <div class="fw-500">
                                        {{ lang !== 'ENG' ? lv2_info.name : (lv2_info.name_em ? lv2_info.name_em : lv2_info.name_ea) }}
                                    </div>
                                </b-td> -->
                                <b-td class="" style="max-width: 200mm;">
                                    <!-- data_tables 넣을자리 -->
                                    <div class="mb-3">

                                        <b-skeleton-img no-aspect height="30px" width="40%"></b-skeleton-img>
                                        <b-skeleton-img no-aspect height="30px" width="40%"></b-skeleton-img>

                                        

                                        
                                        <!-- <hr v-if="lv3_idx < selected_lv2_info.children.length-1 && lv3_info.item_id !== ''"> -->
                                    </div>

                                    
                                    
                                </b-td>
                            </b-tr>




                        </b-tbody>
                    
                    </b-table-simple>
                </div>
            </div>
        </b-col>
    </b-row>
    
</template>
<script>
export default {
}
</script>
<style>
</style>
<template>
    <div>
        <div style="display: flex; justify-content: space-between;">
            <p
                class="f-120 fw-500"
            >
                {{ product_info.product_name }}
            </p>
        </div>
        <table style="width: 100%;">
        <tr>
            <td>
            <div>
                <b-card
                class="mt-4 mb-5"
                border-variant="primary"
                header-bg-variant="primary"
                header-text-variant="white"
                style="max-width: 30rem; border: none;"
                align="center"
                >
                <div>
                    <b-card-text>
                    <table class="text-left">
                        <tr>
                            <th class="fw-500">
                                서비스 명
                            </th>
                            <td>
                                {{ product_info.product_name }}
                            </td>
                        </tr>
                        <tr>
                            <th class="fw-500">
                                가격 (부가세 별도)
                            </th>
                            <td>
                                {{ formatCurrency(product_info.product_price) }}원
                            </td>
                        </tr>

                        <tr v-if="product_info.paid_time">
                            <th class="fw-500">결제일</th>
                            <td>{{ product_info.paid_time }}</td>
                        </tr>
                        
                        <tr>
                            <th class="fw-500">
                                담당자 이메일
                            </th>
                            <td>
                                {{ product_info.in_charge_admin_email }}
                            </td>
                        </tr>
                        <tr v-if="product_info.description">
                            <th class="fw-500">설명</th>
                            <td>{{ product_info.description }}</td>
                        </tr>

                        
                    </table>
                    </b-card-text>
                </div>
                </b-card>
            </div>


            </td>
            <td>
            <div style="text-align: end;">
                <!-- 0 - 결제 실패, 1- 결제 성공, 2 - 결제 대기, 3 - 결제 취소 -->
                <b-button v-if="product_info.payment_status === 2 || product_info.payment_status === 0" @click="payment" variant="teal"> 
                    결제하기
                </b-button>
                
                <div v-if="product_info.payment_status === 3">
                    <b class="red">결제 취소되었습니다.</b> 
                    <b-button
                        class="ml-2"  
                        variant="primary"
                        @click="
                        showReceipt(
                            product_info.product_id,
                            product_info.payment_status
                        )
                        "
                    >매출전표</b-button>

                </div>
                
                <div v-if="product_info.payment_status === 1">
                    <b class="red">결제 완료되었습니다.</b> 
                    <b-button
                        class="ml-2"  
                        variant="primary"
                        @click="
                        showReceipt(
                            product_info.product_id,
                            product_info.payment_status
                        )
                        "
                    >매출전표</b-button>
                </div>
            </div>
            </td>
        </tr>
        </table>
        <hr />

        <template>
            <div>
                <b-modal
                v-model="isReceiptOpen"
                title="매출 전표"
                size="lg"
                no-scrolling
                hide-footer
                >
                <iframe
                    :src="receiptUrl"
                    width="100%"
                    height="700px"
                    overflow-y="hidden"
                ></iframe>
                </b-modal>
            </div>
        </template>
        
    </div>


    
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { formatCurrency } from "@/components/payment/payment";

export default {
    name: '',
    props: {
        product_info : { type: Object, default: null }
    },
    computed: {
        ...mapState(['user_id', 'e_mail', 'user_name', 'tel', 'subscription', 'is_confirmed']),
        ...mapState(["licenseInfo"]),

    },
    components: {},
    data() {
        return {
            newQBill: '',
            isReceiptOpen: false,
            receiptUrl: "",
    }
},
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {
        ...mapActions("custom_products", ["formatCurrency", "generalPayment"]),
        formatCurrency,
        payment() {

            if (this.product_info !== null) {
                console.log('productCard')
                this.generalPayment({
                    "productName": this.product_info.product_name,
                    "productPrice": this.product_info.amount_price, // 부가세 포함가격으로 요청
                    "userName": this.user_name,
                    "userEmail": this.e_mail,
                    "userTel": this.tel,
                    "merchantUid": this.product_info.product_id
                })

                console.log('test')
                
            } else {
                window.alert("잘못된 요청입니다.")
            }

        },
        showReceipt(payment_history_id, payment_status) {
        axios.get(
            this.$store.state.backend_host +
                `/payment/getPaymentData/pg_tid?merchant_uid=${payment_history_id}`
        ).then((response) => {
            if (response?.data?.code) {
            if (payment_status == 1) {
                this.receiptUrl = `https://iniweb.inicis.com/app/publication/apReceiptOpenMarket100.jsp?noTid=${response?.data.result}&noMerchantoid=&flag=0&noMethod=1&tStat=null&re_mail=null`;
            } else {
                this.receiptUrl = `https://iniweb.inicis.com/app/publication/apReceiptOpenMarket100.jsp?noTid=${response?.data.result}&noMerchantoid=&flag=1&noMethod=1&tStat=null&re_mail=null`;
            }

            this.isReceiptOpen = true;
            // window.open(`https://iniweb.inicis.com/app/publication/apReceiptOpenMarket100.jsp?noTid=${response?.data.result}&noMerchantoid=&flag=0&noMethod=1&tStat=null&re_mail=null`, '_blank')
            } else {
            if (response?.data?.message) {
                window.alert(response?.data?.message);
            } else {
                window.alert(
                "매출전표를 가져오지 못했습니다. 관리자에게 문의해주세요."
                );
            }
            }
        });
        },
    }
}
</script>
<style>
</style>
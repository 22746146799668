<template>
  <div></div>
</template>

<script>
export default {
  name: "WaterMark",
  props: {},
  components: {},
  data() {
    return {
      watermarkText: "SAMPLE \n REPORT",
      watermarkTexts: [
        "Watermark Line 1\nSecond Line",
        "Watermark Line 2",
        "Watermark Line 3\nAdditional Line",
      ],
    };
  },
  beforeCreate() { },
  created() { },
  beforeMount() { },
  mounted() {
    this.addWatermark();
    // this.addWatermarks();
  },
  beforeUpdate() { },
  updated() { },
  beforeUnmount() { },
  unmounted() { },
  methods: {
    addWatermarks() {
      const container = this.$el.querySelector(".report-view");
      this.watermarkTexts.forEach(text => {
        const watermark = document.createElement("div");
        watermark.className = "watermark";
        watermark.innerHTML = text.replace(/\n/g, "<br/>");
        watermark.style.transform = `rotate(-30deg) translate(-50%, -50%)`;
        container.appendChild(watermark);
      });
    },
    addWatermark() {
      const screenHeight = window.innerHeight;
      const screenWidth = window.innerWidth;

      // 워터마크 사이의 간격을 늘립니다.
      const gapBetweenWatermarks = 100;
      const watermarkHeight = 50;
      const totalHeightNeeded = watermarkHeight + gapBetweenWatermarks;
      const numberOfWatermarksPerColumn = Math.floor(screenHeight / totalHeightNeeded);
      let columns;
      let leftPositions; // 열의 위치를 저장할 배열
      if (screenWidth > 1440) {
        columns = 3;
        leftPositions = ["20%", "50%", "80%"];
      } else if (screenWidth > 1080) {
        columns = 2;
        leftPositions = ["20%", "80%"];
      } else {
        columns = 1;
        leftPositions = ["50%"];
      }
      for (let i = -2 * numberOfWatermarksPerColumn; i < 2 * numberOfWatermarksPerColumn; i++) {
        for (let column = 0; column < columns; column++) {
          const watermark = document.createElement("div");
          watermark.className = "watermark";
          watermark.innerText = this.watermarkText;

          watermark.style.top = `${i * totalHeightNeeded * 5}px`;
          watermark.style.left = leftPositions[column];
          this.$el.appendChild(watermark);
        }
      }
    },
  },
};
</script>

<style>
.report-view {
  position: relative;
  width: 100%;
  height: 100%;
}

.watermark {
  position: absolute;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  color: rgba(197, 197, 197, 1);
  pointer-events: none;
  white-space: pre-line;
  transform: translateX(-50%) rotate(-30deg);
  letter-spacing: 30px;
  line-height: 80px;
  z-index: 10000;
}
</style>

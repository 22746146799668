<template>
<span class="line-height-lg">
    <span v-if="loaded==true">
        <span v-if="type!='link'" class="mr-3 mb-2">
            <a v-if="lang != 'ENG'" class=" cursor" :style="main_color" :href="$store.state.static_host+path" target="_blank"><fa icon="file-alt" class="mr-1"/> {{ lang!='ENG'? title : title_e }} </a>
            <a v-else class=" cursor" :style="main_color" :href="$store.state.static_host+path_e" target="_blank"><fa icon="file-alt" class="mr-1"/> {{ title_e }} </a>
        </span>                                                         
        <span v-else class="mr-3 mb-2">
            <a v-if="lang !='ENG'" class=" cursor" :style="main_color" :href="link" target="_blank"><fa icon="external-link-square-alt" class="mr-1"/> {{ title }} </a>
            <a v-else class=" cursor" :style="main_color" :href="link_e" target="_blank"><fa icon="external-link-square-alt" class="mr-1"/> {{ title_e }} </a>
        </span>        
    </span>
    <span v-else>
        <b-skeleton width="10%"></b-skeleton>
    </span>
</span>
</template>
    
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import * as ih from '@/components/util'

export default {
    components:{
    },
    data() {
        return {
            title: '',
            description: '',
            type: '',
            file_name: '',
            path: '',
            link: '',
            time_stamp: '',
            file_name_e: '',
            path_e: '',
            link_e: '',
            time_stamp_e: '',
            title_e: '',
            description_e: '',
            loaded: false,
        }
    },    
    props:{
        document_id: {type: [String, Number], default: ''},
        document_obj: { type: Object, default() { return {} }},
        lazy_load: { type: Boolean, default: true }
    },
    computed:{
        ...mapState(['backend_host']),
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapState('documents', ['document_list']), 
        ...mapGetters('report_list', ['selected_report']),
        main_color(){
            return `color:`+this.selected_report.main_color
        },
        main_color_sub(){
            return `color:`+this.selected_report.main_color+`; border-top: 1px solid `+this.selected_report.main_color+`!important`+`; border-bottom: 1px solid `+this.selected_report.main_color
        },          
    },
    methods: {
        readDocumentEntity(){   
            const path = this.backend_host + '/read_document_entity'

            return axios.get(path, { params: {
                document_id : this.document_id
            }})
            .then( response => {
                this.title = response.data.title
                this.description = response.data.description
                this.type = response.data.type
                this.file_name = response.data.file_name
                this.path = response.data.path
                this.link = response.data.link
                this.time_stamp = response.data.time_stamp
                this.file_name_e = response.data.file_name_e
                this.path_e = response.data.path_e
                this.link_e = response.data.link_e
                this.time_stamp_e = response.data.time_stamp_e
                this.title_e = response.data.title_e
                this.description_e = response.data.description_e
                this.loaded = true
            })
        },
    },
    created(){
        //1. Prop 있는 경우 그냥 최우선
        if(this.document_obj.hasOwnProperty('document_id')==true){
            this.title = this.document_obj.title
            this.description = this.document_obj.description
            this.type = this.document_obj.type
            this.file_name = this.document_obj.file_name
            this.path = this.document_obj.path
            this.link = this.document_obj.link
            this.time_stamp = this.document_obj.time_stamp
            this.file_name_e = this.document_obj.file_name_e
            this.path_e = this.document_obj.path_e
            this.link_e = this.document_obj.link_e
            this.time_stamp_e = this.document_obj.time_stamp_e
            this.title_e = this.document_obj.title_e
            this.description_e = this.document_obj.description_e
            this.loaded = true
        }

        //2. Lazy Load 옵션 줘서 직접 읽는 경우
        if(this.lazy_load==true && this.loaded == false) { this.readDocumentEntity(); }

        //3. lazy load 하지 않고 미리 읽은 상태 & prop 전달 못 받은 경우, Store Table List에서 검색.
        if(this.lazy_load==false && this.loaded == false) {
            let idx = this.document_list.findIndex(item => { return item.document_id == this.document_id })

            if(idx > -1){
                this.title = this.document_list[idx].title
                this.description = this.document_list[idx].description
                this.type = this.document_list[idx].type
                this.file_name = this.document_list[idx].file_name
                this.path = this.document_list[idx].path
                this.link = this.document_list[idx].link
                this.time_stamp = this.document_list[idx].time_stamp
                this.file_name_e = this.document_list[idx].file_name_e
                this.path_e = this.document_list[idx].path_e
                this.link_e = this.document_list[idx].link_e
                this.time_stamp_e = this.document_list[idx].time_stamp_e
                this.title_e = this.document_list[idx].title_e
                this.description_e = this.document_list[idx].description_e
                this.loaded = true
            }
            else{ //아직 로딩되지 않은 상태
                this.readDocumentEntity()
            }
        }
    }
}


</script>
<template>
<div>
    <b-card class="general-card mb-5">
        <b-card-header>TCFD 기본 지표 추천받기</b-card-header>
        <div class="py-3">
            <p class="gray-600 mb-45">
                보고 조직에 대해 다음의 사항을 입력하여 기본 지표를 추천받으세요. GRI Core Option을 기준으로 기본 지표를 추천해 드립니다. <br>
                추천 받지 않고 직접 지표를 선택하려면 [추천 필요 없어요. 건너뛰기]를 클릭해 주세요.
            </p>
            <b-row no-gutters class="hover-gray py-2 mb-4">
                <b-col cols="3"><h6 class="gray-550">소속 산업</h6> </b-col>
                <b-col > 
                    <p class="fw-400">보고 조직이 소속된 산업을 선택해 주세요.</p>
                    <b-row class="mb-3">
                        <b-col> <span class="f-85 gray-550"> 소속 산업 검색하기 </span>
                            <b-input-group>
                                <b-form-input type="text" v-model.trim="search_keyword" placeholder="찾으시는 산업을 입력해주세요" @keyup.enter="searchIndustry()" :disabled="is_reading_topics_indicators"></b-form-input> 
                                <b-input-group-append><b-button @click="searchIndustry()" variant="teal"><fa icon="search"/> </b-button></b-input-group-append>
                            </b-input-group>
                                <p class="f-85 red pt-2" v-if="not_found==true"> <fa icon="info-circle" class="mr-1"/>검색된 산업이 없습니다. 검색어를 다시 입력하거나, 아래 분류를 선택해주세요.</p>
                                <div class="mb-2" v-if="searched_industry_list.length > 0"> 
                                    <b-list-group id="industry-search-list">
                                        <b-list-group-item button class="px-3 py-2 search-result" v-for="item in searched_industry_list" :key="item.key" @click="selectIndustry(item)">
                                            <div class="f-90 gray-700"> {{ item.industry_name_large }} > {{ item.industry_name_medium }} > {{ item.industry_name_small }} </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                    <p class="f-85 teal pt-2"> <fa icon="info-circle" class="mr-1"/>목록에 찾으시는 산업명이 없다면, 아래에서 분류를 선택해주세요.</p>
                                </div>
                                <p v-if="is_selected == true" class="f-85 teal7 pt-2"> <fa icon="check-circle" class="mr-1"/>선택하신 산업 정보가 입력되었습니다.</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col> 
                            <div class="d-flex"> 
                                <div class="mr-1" style="width: calc(50% - 15px);"><span class="f-85 gray-550"> 대분류 </span>
                                    <b-form-select placeholder="" v-model="industry_large" @change="matchIndustries('lar')" :options="category_large_options" :disabled="is_reading_topics_indicators"></b-form-select> 
                                </div>
                                <div class="mr-1" style="width: calc(50% - 15px);"> <span class="f-85 gray-550"> 중분류 </span>
                                    <b-form-select placeholder="" v-model="industry_medium" @change="matchIndustries('mid')" :options="category_medium_options" :disabled="is_reading_topics_indicators"></b-form-select> 
                                </div>
                                <div class="mr-1" style="width: calc(50% - 15px);"> <span class="f-85 gray-550"> 소분류 </span>
                                    <b-form-select placeholder="" v-model="industry_small" @change="matchIndustries('sml')" :options="category_small_options" :disabled="is_reading_topics_indicators"></b-form-select> 
                                </div>
                                <!-- <div style="width: calc(50% - 15px);"><span class="f-85 gray-550"> 초기화 </span>
                                    <br/><b-button class="mr-1" variant="white" @click="handleRedo()"><fa icon="redo-alt" class="mr-1"/></b-button></div> -->
                            </div>
                        </b-col>
                    </b-row>
                </b-col>  
            </b-row>
            <b-row no-gutters class="hover-gray py-2 mb-4">
                <b-col cols="3"><h6 class="gray-550">보고 조직의 임직원 규모</h6> </b-col>
                <b-col> 
                    <p class="fw-400">보고 조직의 상시 근로자 규모를 선택해 주세요.</p>
                    <b-form-radio-group v-model="num_of_employee" :options="num_of_employee_options" :disabled="is_reading_topics_indicators"></b-form-radio-group>
                </b-col>  
            </b-row>
            <b-row no-gutters class="hover-gray py-2 mb-5">
                <b-col cols="3"><h6 class="gray-550">보고 조직 매출 규모</h6> </b-col>
                <b-col> 
                    <p class="fw-400">보고 조직의 직전년도 매출 규모를 선택해 주세요.</p>
                    <b-form-radio-group v-model="revenue" :options="revenue_options" :disabled="is_reading_topics_indicators"></b-form-radio-group>
                </b-col>  
            </b-row>

            <b-button v-if="is_skipping_topics_indicators==false" variant="teal-border" class="mr-3 px-3" @click="skipRecommendation()"> 추천 필요 없어요. 건너뛰기 </b-button>
            <b-button v-else variant="teal-border" class="mr-3 px-3" disabled> 건너뛰는 중 <b-spinner small></b-spinner></b-button>
            
            <b-button v-if="is_reading_topics_indicators==false" variant="teal" class="px-3" @click="getRecommendation()">기본 지표 추천받기</b-button>
            <b-button v-else variant="teal" class="px-3" disabled> 추천 지표 불러오는 중 <b-spinner small></b-spinner></b-button>

        </div>
    </b-card>

</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            not_found: false,
            search_keyword: '',
            searched_industry_list: [], 

            category_large_options: [],
            category_medium_options: [],
            category_small_options: [],

            all_industry_list: [],

            industry_large: '',
            industry_medium: '',
            industry_small: '',

            num_of_employee: '',
            num_of_employee_options: [
                { text: '20인 미만', value: '20인 미만' },
                { text: '20인 ~ 100인', value: '20인 ~ 100인' },
                { text: '100인 ~ 500인', value: '100인 ~ 500인' },
                { text: '500인 이상', value: '500인 이상' }
            ],
            revenue: '',
            revenue_options: [
                { text: '30억원 미만', value: '30억원 미만' },
                { text: '30억원 ~ 100억원', value: '30억원 ~ 100억원' },
                { text: '100억원 ~ 500억원', value: '100억원 ~ 500억원' },
                { text: '500억원 ~ 2000억원', value: '500억원 ~ 2000억원' },
                { text: '2000억원 ~ 1조원', value: '2000억원 이상 ~ 1조원' },
                { text: '1조원 이상', value: '1조원 이상'}
            ],
            is_reading_topics_indicators: false,
            is_skipping_topics_indicators: false,
            is_selected: false,

        }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
    },
    methods:{
        ...mapActions('report_indicators', ['readReportTopics','readReportIndicators']),
        ...mapActions('report_list', ['saveReportPreference']),
        checkForm(){
            if(this.industry_small==''){
                alert('소속 산업을 선택해 주세요.')
                return false
            }
            else if(this.num_of_employee == ''||this.num_of_employee == null) {
                alert('임직원 규모를 선택해 주세요.')
                return false
            }
            else if(this.revenue == ''||this.revenue == null) {
                alert('매출 규모를 선택해 주세요.')
                return false
            }
            else return true
        },
        async getRecommendation(){
            if (this.checkForm() == true){
                this.is_reading_topics_indicators = true
                await this.setDefaultIndicators() 

                let idx = this.all_industry_list.findIndex(item => item.industry_name_small == this.industry_small)
                if(idx >-1){
                    let industry_id = this.all_industry_list[idx].id

                    const path = this.$store.state.backend_host + '/get_recommendation'
                    let formData = new FormData()
                    formData.append('report_code', this.report_code)
                    formData.append('industry_id', industry_id)
                    formData.append('size', this.num_of_employee)
                    formData.append('revenue', this.revenue)
                    return axios.post(path, formData)
                    .then((response) => {
                        // console.log(response);
                        this.$emit('indicator_recommended') // IndicatorMaster로 추천지표 받았다는 표시 보내기
                        this.pageGo()
                        this.$EventBus.$emit('make-toast', '지표 추천 완료', '기본 지표가 선택되었습니다.')
                    })
                }
            }            
        },
        async skipRecommendation(){
            this.is_skipping_topics_indicators = true
            await this.setDefaultIndicators()
            this.pageGo()       
        },
        setDefaultIndicators(){ // 100번대 default 저장 및 기존 selected topics & indicators 초기화
            const path = this.$store.state.backend_host + '/set_default_indicators'
            let formData = new FormData()
            formData.append('report_code', this.report_code)
            return axios.post(path, formData)
            .then((response) => {
                // console.log(response);
            })
        },
        async pageGo(){
            this.saveReportPreference({ report_code: this.report_code, state : 1 }) 
            //1. 라우터 이동 전 indicators와 topics 미리 읽기
            await this.readReportTopics({report_code: this.report_code})
            await this.readReportIndicators({report_code: this.report_code}) 

            //2. 라우터 이동 
            this.$router.push({ name: 'SelectGriIndicators', params: {is_recommended: true}})
        },
        searchIndustry(){
            this.is_selected = false
            if( this.search_keyword == '' || this.search_keyword == ' ' ){
                alert('찾으시는 산업명을 입력해 주세요.')
            } else {
                this.searched_industry_list = []

                let temp_all_list = this.all_industry_list
                this.searched_industry_list = temp_all_list.filter(item => {
                    return (item.industry_name_small.includes(this.search_keyword) || item.industry_name_medium.includes(this.search_keyword))
                })
                // console.log(this.searched_industry_list);
                if( this.searched_industry_list.length > 0 ){
                    this.not_found = false
                } else {
                    this.not_found = true
                }
            }
        },
        selectIndustry(selected_item){  //검색된 목록 중 선택시 data값을 바꾸도록 함. > v-model을 통해 화면에 표시됨.
            this.industry_small = selected_item.industry_name_small
            this.matchIndustries('sml') // 현재 소분류 기준으로 대-중분류 채우기
                         
            this.is_selected = true
            this.search_keyword = ''
            this.searched_industry_list = []
        },
        readAllIndustry(){ // back에서 산업 분류 관련 정보 불러오게 하는 로직 & 산업 목록 요청하기. > created에서 실행함.
            const path = this.$store.state.backend_host + '/read_all_industry'
            return axios.get(path)
            .then(response => {
                this.all_industry_list = response.data
                this.makeCategoryOptions()
            })
        },
        makeCategoryOptions(){ // select option들로 대/중/소 분류 full로 넣어주기
            if(this.all_industry_list.length > 0){
                this.category_large_options = []
                this.category_medium_options = []
                this.category_small_options = []
                let temp_large_options = []
                let temp_medium_options = []
                this.all_industry_list.forEach(x => temp_large_options.push(x.industry_name_large) )
                this.category_large_options = [...new Set(temp_large_options)]
                this.all_industry_list.forEach(y => temp_medium_options.push(y.industry_name_medium) )
                this.category_medium_options = [...new Set(temp_medium_options)]
                this.all_industry_list.forEach(z => this.category_small_options.push(z.industry_name_small) )
            }
        },
        matchIndustries(change_code){
            if (change_code=='lar'){ // form select에서 large 선택 시 mid, sml select 리스트 제한
                this.category_medium_options = []
                let temp_medium_options = []
                this.category_small_options = []                
                this.all_industry_list.forEach(y => {
                    if (y.industry_name_large== this.industry_large){
                        temp_medium_options.push(y.industry_name_medium) 
                        this.category_small_options.push(y.industry_name_small)
                    }
                })
                this.category_medium_options = [...new Set(temp_medium_options)]
            }
            else if (change_code=='mid'){ // form select에서 mid 선택 시 sml select 리스트 제한, large 자동 채우기
                this.category_small_options = []
                this.all_industry_list.forEach(z => {
                    if (z.industry_name_medium== this.industry_medium){
                        this.category_small_options.push(z.industry_name_small)
                    }
                })
                let hdx = this.all_industry_list.findIndex(h => h.industry_name_medium == this.industry_medium)
                if(hdx > -1){
                    this.industry_large = this.all_industry_list[hdx].industry_name_large
                }
            }
            else if (change_code=='sml'){ // form select에서 sml 선택 시 lar, mid 자동 채우기
                let gdx = this.all_industry_list.findIndex(g => g.industry_name_small == this.industry_small)
                if(gdx > -1){
                    this.industry_large = this.all_industry_list[gdx].industry_name_large
                    this.industry_medium = this.all_industry_list[gdx].industry_name_medium
                }
            }
        }
        // toggleWaiting(){
        //     this.is_reading_topics_indicators = !this.is_reading_topics_indicators
        // },
        // handleRedo(){
        //     this.category_medium_options = []
        //     this.category_small_options = []
        //     this.industry_large = ''
        //     this.industry_medium = ''
        //     this.industry_small = ''
        //     this.is_selected = false
        //     this.not_found = false
        //     this.handleCategory('L')
        // },
    },
    created(){
        this.readAllIndustry()
    },
}
</script>


<template>
<div>
    <h4>서면검증</h4>
    <p class="f-95 mb-4 gray-600">보고서 서면검증 결과입니다.</p>

        <b-card class="general-card mb-5">
            <b-card-header>서면검증 의견</b-card-header>
            <div class="py-3">
                <p class="gray-600 mb-5">
                    서면검증 의견을 확인하여 주십시오. <br> 각 검증 의견에 답글을 달거나 보고서 내용을 보완하여 대면 인터뷰를 준비하여 주십시오. 직접 보완 또는 추가 자료 제시가 어려운 경우, 대면 인터뷰에서 확인할 수 있도록 관련 자료를 준비하여 주십시오.
                </p>
            </div>

            <b-table-simple class="disclosure-table">
                <b-thead><b-tr>
                    <b-td style="width: 20%;">지표</b-td><b-td style="width: 32%;"> 보고 내용 </b-td><b-td>검증 의견</b-td>
                    <!-- <b-td>Open/Close</b-td> -->
                </b-tr></b-thead>
                <b-tbody>
                    <b-tr v-for="content in assurance_review_contents" :key="content.id">
                        <b-td> 
                            <div class="f-100 mb-2">{{ content.gri_code }} </div>
                            <p class="mb-0 pr-1 gray-600">{{ findName(content.gri_code) }}</p>
                        </b-td>
                        <b-td> 
                            <p class="mb-0 pr-1 gray-600">{{ findContents(content.gri_code) }}</p>

                            <!-- disclosure items -->
                            <div class="" v-if="findDisclosureItems(content.gri_code)">
                                <span v-for="dt in findDisclosureItems(content.gri_code).data_tables" :key="dt.id" class="">
                                    <a class="mb-2 cursor mr-3" @click="show_data_table[dt]= true"><fa icon="table" class="mr-1"/>{{ hashed_data_tables[dt].title }}</a>
                                    <div v-show="show_data_table[dt]==true" class="position-fixed hj-modal-left-bottom pb-3 bg-white overflow-auto shadow rounded">
                                        <h6 class="mb-3 bgray-700 gray-300 py-2 px-3"><b-badge variant="border-light" class="mr-2">Data Table.</b-badge> {{ hashed_data_tables[dt].title }}
                                            <b-button class="float-right py-0 px-2" variant="icon-light" @click="show_data_table[dt]=false"><fa icon="times"></fa> </b-button>
                                        </h6> 
                                        <div class="px-3">
                                            <b-table-simple responsive class="bor-bg-table">
                                                <b-thead><b-tr>
                                                    <b-td v-for="(property, col_index) in hashed_data_tables[dt].header" :key="col_index">{{ property }}</b-td>
                                                </b-tr></b-thead>
                                                <b-tbody><b-tr v-for="row_item in hashed_data_tables[dt].contents" :key="row_item.id">
                                                    <b-td v-for="property in hashed_data_tables[dt].header" :key="property.id">{{ row_item[property] }}</b-td>
                                                </b-tr></b-tbody>
                                            </b-table-simple>
                                        </div>
                                    </div>
                                </span>
                                <span v-for="x in findDisclosureItems(content.gri_code).documents" :key="x.id">
                                    <span v-if="hashed_docs[x].type!='link'" class="mr-3">
                                        <a class=" cursor" :href="$store.state.static_host+hashed_docs[x].path" target="_blank"><fa icon="file-alt" class="mr-1"/> {{ hashed_docs[x].title }} </a>  
                                    </span>                                                         
                                </span>
                                <span v-for="y in findDisclosureItems(content.gri_code).documents" :key="y.id">
                                    <span v-if="hashed_docs[y].type=='link'" class="mr-3">
                                        <a class=" cursor" :href="hashed_docs[y].link" target="_blank"><fa icon="external-link-square-alt" class="mr-1"/> {{ hashed_docs[y].title }} </a>  
                                    </span>
                                </span>
                                <span v-for="bc in findDisclosureItems(content.gri_code).business_cases" :key="bc.id">
                                    <a class="mb-2 cursor mr-3" @click="show_business_case[bc]= true"><fa icon="quote-left" class="mr-1"/>{{ hashed_business_cases[bc].title }}</a>
                                    <div v-show="show_business_case[bc]==true" class="hj-modal-left-bottom bg-white rounded shadow pb-3">
                                        <h6 class="mb-3 bgray-700 gray-300 py-2 px-3"><b-badge variant="border-light" class="mr-2">Case.</b-badge> {{ hashed_business_cases[bc].title }}
                                            <b-button class="float-right py-0 px-2" variant="icon-light" @click="show_business_case[bc]=false"><fa icon="times"></fa> </b-button>
                                        </h6> 
                                        <div class="px-3">
                                            <simple-business-case :business_case_id="hashed_business_cases[bc].business_case_id"></simple-business-case>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </b-td>

                        <b-td class="pr-3">  
                                <div v-for="(msg, index) in content.comments" :key="msg.id"  :class="index+1 < content.comments.length?'mb-3':''" class="position-relative">
                                    <template v-if="msg.comment_id!=editing_comment_id">
                                        <div :class="[msg.author_type=='auditor'? 'shadow-sm':'bgray-200 gray-600','rounded py-2 px-3']" >
                                            <p class="fw-400" style="width:calc(100% - 30px)">{{ msg.author_type!='auditor'? 'Re:':''}} {{ msg.comments }} </p>
                                            <p style="width:calc(100% - 30px)"> <span v-for="fi in msg.files" :key="fi.id" class="mr-2"> 
                                                    <a :href="$store.state.static_host+fi.file_path" target="_blank" class="cursor"><fa icon="paperclip" class="mr-1"/> {{fi.file_name}} </a>
                                                </span> 
                                            </p>
                                            <div class="d-flex justify-content-between">
                                                <p class="f-85 mb-0 gray-500"> {{ msg.time_stamp.slice(0,16) }} <span class="f-80 mx-2">|</span> {{ msg.author_type=='auditor'? 'Auditor' : msg.author_e_mail }} </p>  
                                                <div v-if="comment_box_show[content.review_item_id]!=true">
                                                    <b-button v-if="msg.author_type!='auditor'" variant="icon-gray" class="py-0 px-1" size="sm" @click="editComment(content.review_item_id, msg.comment_id, msg.comments, msg.files)"><fa icon="pen"></fa></b-button>
                                                    <b-button v-if="msg.author_type!='auditor'" variant="icon-gray" class="py-0 px-1 ml-1" size="sm" @click="handleDeleteComment(msg.comment_id)"><fa icon="trash-alt"></fa></b-button>
                                                    <b-button v-else variant="icon-teal" class="p-0 f-90" :style="content.comments.length>=2? '':''"
                                                    @click="openCommentBox(content.review_item_id)">답글달기<fa icon="reply" class="ml-1"/></b-button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </template>
                                </div>                                 
                                <div v-show="comment_box_show[content.review_item_id]==true" class="pt-3">
                                    <div class="bgray-200 rounded p-2">
                                        <p class="f-90 mb-1">답글 작성</p>                                    
                                        <b-form-textarea v-model="comment_reply" placeholder="답글 작성" class="mb-3"></b-form-textarea>

                                        <div v-if="temp_uploaded_files.length>0" class="f-90 gray-600 mb-1"> 업로드된 파일:
                                            <span v-for="item in temp_uploaded_files" :key="item.id" class="ml-1 mr-2"> 
                                                <a :href="$store.state.static_host+item.file_path" target="_blank">{{item.file_name}}</a> 
                                                <b-button variant="icon-teal" class="f-85 pb-1 py-0 px-1 ml-1" @click="handleDeleteFile(item.file_id)"><fa icon="trash-alt"></fa></b-button>
                                            </span>
                                        </div>              
                                        <p v-else class="f-90 mb-1" >파일 업로드</p>        
                                        <b-form-file v-model="file" placeholder="여기로 끌어오기 또는 파일찾기" class="mb-2" 
                                        @input="uploadCommentFile({assurance_report_id: content.assurance_report_id})"></b-form-file>

                                        <p class="f-85 mb-3 gray-600"> <fa icon="info-circle" class="mr-1"/> 크기가 20MB 이상인 파일은 업로드할 수 없습니다.</p>
                                        
                                        <b-button variant="teal" class="f-90" @click="haneldSaveComment(content.review_item_id)">답글 저장하기</b-button>
                                    </div>
                                </div>
                        </b-td>
                        <!-- <b-td> <span :class="content.status=='open'?'': 'gray-500'">{{ content.status }} </span></b-td> -->
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-card>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import SimpleBusinessCase from '@/components/writing_report/business_case_subs/SimpleBusinessCase'

export default {
    data() {
        return {
            comment_reply: '',
            file:null, 
            temp_uploaded_files: [],
            comment_box_show: {},
            editing_comment_id: null,
            show_data_table: {},
            show_business_case: {}
        }
    },
    computed:{
        ...mapState(['user_id']),
        ...mapState('report_list', ['report_code']),
        ...mapState('report_contents', ['report_contents']),
        ...mapState('data_tables', ['table_list']),
        ...mapGetters('data_tables', ['hashed_data_tables']),
        ...mapGetters('documents', ['hashed_docs']),
        ...mapState('business_cases', ['business_case_list']),
        ...mapGetters('business_cases', ['hashed_business_cases']),
        ...mapState('assurance', ['assurance_review_contents']),
        ...mapGetters('assurance', ['assurance_id'])

    },
    methods:{
        ...mapActions('assurance', ['readAssuranceReviewContent', 'saveAssuranceComment', 'deleteAssuranceComment']),
        findName(gri_code){
            let idx = this.report_contents.findIndex(x => x.gri_code == gri_code)
            if (idx >-1){
                return this.report_contents[idx].indicator_name
            }
        },
        findContents(gri_code){
            let idx = this.report_contents.findIndex(x => x.gri_code == gri_code)
            if (idx >-1){
                return this.report_contents[idx].direct_report
            }
        },
        findDisclosureItems(gri_code){
            let idx = this.report_contents.findIndex(x => x.gri_code == gri_code)
            if (idx >-1){
                let disclosure_items = {
                    data_tables: this.report_contents[idx].data_tables,
                    documents: this.report_contents[idx].documents,
                    business_cases: this.report_contents[idx].business_cases
                }
                return disclosure_items
            }
        },
        async openCommentBox(new_id){
            this.initiateForm()
            await this.initiateShow()
            this.comment_box_show[new_id] = true            
        },
        async editComment(new_id, comment_id, comment_reply, files){
            this.editing_comment_id = comment_id
            this.comment_reply = comment_reply
            this.temp_uploaded_files = files
            await this.initiateShow()
            this.comment_box_show[new_id] = true
        },
        async haneldSaveComment(review_item_id){
            let temp_file_ids = []
            this.temp_uploaded_files.forEach(z => temp_file_ids.push(z.file_id))

            await this.saveAssuranceComment({
                comment_id: this.editing_comment_id,
                review_item_id: review_item_id,
                author_id: this.user_id,
                comments: this.comment_reply,          
                files_id: temp_file_ids     
            })
            this.initiateForm()
            this.comment_box_show[review_item_id] = false
        },
        handleDeleteComment(comment_id){
            if( confirm('답글을 삭제하시겠습니까?') == true){
                this.deleteAssuranceComment(comment_id)
            }
        },
        uploadCommentFile({ assurance_report_id }){
            const path = this.$store.state.backend_host + '/upload_comment_file'

            let formData = new FormData()
            formData.append('assurance_report_id', assurance_report_id)
            formData.append('type', 'file')
            formData.append('file', this.file)
            return axios.post(path, formData)
            .then( response => {
                let new_file_obj = {
                    file_id: response.data.file_id, 
                    comment_id: response.data.comment_id,
                    type: response.data.type, 
                    file_name: response.data.file_name, 
                    file_path: response.data.file_path,
                    time_stamp: response.data.time_stamp
                }
                this.temp_uploaded_files.push(new_file_obj)
            })
        },
        handleDeleteFile(file_id){
            const path = this.$store.state.backend_host + '/delete_comment_file'
            let formData = new FormData()
            formData.append('file_id', file_id)
            return axios.post(path, formData)
            .then( response => {
                let kdx = this.temp_uploaded_files.findIndex(k => k.file_id == response.data.file_id) 
                if (kdx > -1){
                    this.temp_uploaded_files.splice(kdx, 1)
                }
            })
        },
        initiateShow(){
            this.assurance_review_contents.forEach(e => {
                this.$set(this.comment_box_show, e.review_item_id, false )
            })
        },
        initiateForm(){
            this.editing_comment_id = null
            this.comment_reply = ''
            this.temp_uploaded_files = []
            this.file = null
        },
        initiateShowDataTables(){
            this.show_data_table = {}
            for (let t=0; t<this.table_list.length; t++){
                this.$set(this.show_data_table, this.table_list[t].table_id, false)
            }
        },
        initiateShowBusinessCases(){
            this.show_business_case = {}
            for (let b=0; b<this.business_case_list.length; b++){
                this.$set(this.show_business_case, this.business_case_list[b].business_case_id, false)
            }
        }
    },
    async created() {
        let assuranceReviewResult = null
        if (this.assurance_id!=''&&this.assurance_id!=null ){
            assuranceReviewResult = await this.readAssuranceReviewContent({assurance_id: this.assurance_id})
        }
        this.initiateShow()
        this.initiateShowDataTables()
        this.initiateShowBusinessCases()
        // this.initiateFiles()

        if (!assuranceReviewResult) {
            this.$router.push("check_report")
        }
    },
    components:{
        SimpleBusinessCase
    },
    mounted(){
        // console.log(this.assurance_review_contents);
    },
    
}
</script>

<style>
.co-reply-btn{
    bottom: 6px; 
    right: 15px;
}
.co-edit-btn{
    top:5px;
    right: 10px;
}
.co-delete-btn{
    top: 32px;
    right: 10px
}

</style>
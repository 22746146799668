<template>
    <div>
        
        <!-- <b-row class="">
            <b-col cols="12">
                <h5>공통 옵션</h5>
            </b-col>

            <b-col cols="12">
                <div class="border rounded p-3">
                    <b-row>
                        <b-col cols="12" lg="3">
                            <b-form-group
                                :id="`fieldset-input-common-config-beginAtZero`"
                                label="축 시작점"
                                :label-for="`input-common-config-beginAtZero`"
                                aria-describedby="input-feedback-beginAtZero"
                                :state="validation.beginAtZero.state"
                                :disabled="!common_option.show_beginAtZero"
                                class="mb-0"
                                >
                                <b-form-checkbox v-model="common_option.beginAtZero"
                                    :id="`input-common-config-beginAtZero`"
                                    switch
                                    :disabled="!common_option.show_beginAtZero"
                                    @change="updateChart_beginAtZero"
                                    >
                                    {{ common_option.beginAtZero ? '데이터 기반 최소점' : '0부터 시작'  }}
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <b-form-group
                                :id="`fieldset-input-common-config-stack`"
                                label="일반/누적 차트 설정"
                                :label-for="`input-common-config-stack`"
                                aria-describedby="input-feedback-stack"
                                :state="validation.stack.state"
                                :disabled="!common_option.show_stack"
                                class="mb-0"
                                >
                                <b-form-checkbox v-model="common_option.stack"
                                    :id="`input-common-config-stack`"
                                    :disabled="!common_option.show_stack"
                                    @input="updateChartDataset_stack"
                                    switch
                                    >
                                    {{ common_option.stack ? '누적' : '일반' }}
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <b-form-group
                                :id="`fieldset-input-common-config-axes-min`"
                                label="축 최소값"
                                label-for="input-common-config-axes-min"
                                :state="validation.min.state"
                                :disabled="!common_option.show_axes_min"
                                class="mb-0"
                                >

                                <b-form-input type="number"
                                    :id="`input-common-config-axes-min`"
                                    v-model="common_option.axes_min"
                                    @input="updateChart_minmax('min', common_option.axes_min)"
                                    :disabled="!common_option.beginAtZero"
                                    size="sm"
                                    min="0"
                                    />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="3">
                            <b-form-group
                                :id="`fieldset-input-common-config-axes-max`"
                                label="축 최대값"
                                :label-for="`input-common-config-axes-max`"
                                aria-describedby="input-feedback-max"
                                :state="validation.max.state"
                                :disabled="!common_option.show_max"
                                class="mb-0"
                                >
                                <b-form-input type="number"
                                    :id="`input-common-config-axes-max`"
                                    v-model="common_option.axes_max"
                                    @input="updateChart_minmax('max', common_option.axes_max)"
                                    :disabled="!common_option.show_axes_max"
                                    size="sm"
                                    min="0"
                                    />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row> -->

        <!-- <b-row class="my-3">
            <b-col cols="12">
                <hr>
            </b-col>
        </b-row> -->

        <!-- <b-row class="">
            <b-col cols="12">
                <h5>데이터별 옵션</h5>
            </b-col>
        </b-row> -->

        <b-tabs content-class="border-right border-left border-bottom rounded-bottom">
            <b-tab v-for="(editible_data, editible_index) in editibleDatasets"
                :key="`edit-chart-data-${editible_index}`"
                :title="editible_data.label"
                >
                <div class="p-3">
                    <b-row>
                        <b-col cols="12" lg="3">
                            <b-form-group
                                :id="`fieldset-input-${editible_index}-config-type`"
                                label="차트 타입"
                                :label-for="`input-${editible_index}-config-type`"
                                aria-describedby="input-feedback-type"
                                :state="validation.type.state"
                                >
                                <b-form-select :id="`input-${editible_index}-config-type`"
                                    v-model="editible_data.type"
                                    :options="chart_type_options"
                                    @change="updateChart_type($event, editible_index)"
                                    />
                            </b-form-group>

                            <b-form-invalid-feedback id="input-feedback-type">
                                {{ validation.type.feedback }}
                            </b-form-invalid-feedback>
                        </b-col>

                        <template v-if="editibleOptionProps.yAxisID.includes(editible_data.type)">
                            <b-col cols="12" lg="3">
                                <b-form-group
                                    :id="`fieldset-input-${editible_index}-config-y-axis`"
                                    label="Y축 선택"
                                    :label-for="`input-${editible_index}-config-y-axis`"
                                    aria-describedby="input-feedback-y-axis"
                                    :state="validation.yAxisID.state"
                                    :disabled="single_chart.includes(editible_data.type)"
                                    >

                                    <b-form-select :id="`input-${editible_index}-config-y-axis`"
                                        v-model="editible_data.yAxisID"
                                        :options="y_options"
                                        :disabled="single_chart.includes(editible_data.type)"
                                        @change="updateChartDataset_YAxis(editible_index, $event)"
                                        />
                                </b-form-group>

                                <b-form-invalid-feedback id="input-feedback-y-axis">
                                    {{ validation.yAxisID.feedback }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </template>

                        <template v-if="editibleOptionProps.barPercentage.includes(editible_data.type)">
                            <b-col cols="12" lg="3">
                                <b-form-group
                                    :id="`fieldset-input-${editible_index}-config-barPercentage`"
                                    label="막대 그래프 - 막대 너비"
                                    :label-for="`input-${editible_index}-config-barPercentage`"
                                    aria-describedby="input-feedback-barPercentage"
                                    :state="validation.barPercentage.state"
                                    >
                                    <b-form-spinbutton 
                                        :id="`input-${editible_index}-config-barPercentage`"
                                        v-model="editible_data['barPercentage']"
                                        @input="updateChart_default(editible_index, 'barPercentage')"
                                        :min="0.1" :max="1" :step="0.1"
                                        />
                                </b-form-group>

                                <b-form-invalid-feedback id="input-feedback-barPercentage">
                                    {{ validation.type.feedback }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </template>

                        <!-- <template v-if="editibleOptionProps.yAxisID.includes(editible_data.type)"> -->
                        <template>
                            <b-col cols="12" lg="3">
                                <b-form-group
                                    :id="`fieldset-input-${editible_index}-config-y-axis-unit`"
                                    label="Y축 단위"
                                    :label-for="`input-${editible_index}-config-y-axis-unit`"
                                    >
                                    <b-form-input :id="`input-${editible_index}-config-y-axis-unit`"
                                        v-model="editible_data.unit"
                                        @input="updateChart_default(editible_index, 'unit')"
                                        />
                                </b-form-group>
                            </b-col>
                        </template>

                        <template v-if="editibleOptionProps.borderWidth.includes(editible_data.type)">
                            <b-col cols="12" lg="3">
                                <b-form-group
                                    :id="`fieldset-input-${editible_index}-config-border-width`"
                                    label="선 굵기"
                                    :label-for="`input-${editible_index}-config-border-width`"
                                    aria-describedby="input-feedback-border-width"
                                    :state="validation.borderWidth.state"
                                    >
                                    <b-form-input type="number"
                                        :id="`input-${editible_index}-config-border-width`"
                                        v-model="editible_data['borderWidth']"
                                        @input="updateChart_default(editible_index, 'borderWidth')"
                                        />
                                </b-form-group>

                                <b-form-invalid-feedback id="input-feedback-border-width">
                                    {{ validation.type.feedback }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </template>


                        <b-col cols="12">
                            <hr>
                        </b-col>

                        <b-col cols="12">
                            <div class="d-flex align-items-center justify-content-between">
                                <h5>색 설정</h5>

                                <div>
                                    <b-btn size="sm" class="mr-2">컬러 자동 조정</b-btn>
                                    <b-btn size="sm">컬러 팔레트 보기</b-btn>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div v-for="_b_dx in editible_data.data.length"
                                    :key="`dataset-${editible_index}-colorSet-${_b_dx - 1}`"
                                    class="border border-light rounded bg-light color-setting-card"
                                    >
                                    <div class="border-bottom border-light text-center p-2">
                                        <h6 class="mb-0">{{ labels[_b_dx - 1] }}</h6>
                                    </div>
                                    <div class="p-2">
                                        <div>
                                            <template v-if="!!editible_data.borderColor && !!editible_data.borderColor[_b_dx - 1]">
                                                <b-dropdown size="lg" variant="link"
                                                    toggle-class="text-decoration-none p-0"
                                                    menu-class="p-0"
                                                    no-caret
                                                    block
                                                    @hidden="updateChartData_color(editible_index, _b_dx - 1, 'borderColor')"
                                                    >
                                                    <template #button-content>
                                                        <div class="border border-light rounded">
                                                            <div class="h6 rounded text-white px-5 py-2 mb-0"
                                                                :style="{ backgroundColor: convertingColor(editible_data.borderColor[_b_dx - 1]) }"
                                                                >
                                                                선색
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <b-dropdown-form class="p-0" form-class="p-0">
                                                        <b-form-group
                                                            :id="`fieldset-input-${editible_index}-borderColor`"
                                                            :label-for="`input-${editible_index}-borderColor`"
                                                            class="mb-0"
                                                            >
                                                            <chrome-picker :id="`input-${editible_index}-borderColor`"
                                                                v-model="editible_data.borderColor[_b_dx - 1]"
                                                                />
                                                        </b-form-group>
                                                    </b-dropdown-form>
                                                </b-dropdown>
                                            </template>
                                            <template v-else>
                                                <b-btn size="sm"
                                                    variant="light"
                                                    class="mb-2"
                                                    @click="createDataColor(editible_index, _b_dx - 1, 'borderColor')"
                                                    block
                                                    >
                                                    선색 생성하기
                                                </b-btn>
                                            </template>
                                        </div>
                                        
                                        <div>
                                            <template v-if="editible_data.backgroundColor && editible_data.backgroundColor[_b_dx - 1]">
                                                <b-dropdown size="lg" variant="link"
                                                    toggle-class="text-decoration-none p-0"
                                                    menu-class="p-0"
                                                    no-caret
                                                    block
                                                    @hidden="updateChartData_color(editible_index, _b_dx - 1, 'backgroundColor')"
                                                    >
                                                    <template #button-content>
                                                        <div class="border border-light rounded">
                                                            <!-- py-2 px-2 -->
                                                            <div class="h6 rounded text-white px-5 py-2 mb-0"
                                                                :style="{ backgroundColor: convertingColor(editible_data.backgroundColor[_b_dx - 1]) }"
                                                                >
                                                                배경색
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <b-dropdown-form class="p-0" form-class="p-0">
                                                        <b-form-group
                                                            :id="`fieldset-input-${editible_index}-backgroundColor`"
                                                            :label-for="`input-${editible_index}-backgroundColor`"
                                                            class="mb-0"
                                                            >
                                                            <chrome-picker :id="`input-${editible_index}-backgroundColor`"
                                                                v-model="editible_data.backgroundColor[_b_dx - 1]"
                                                                />
                                                        </b-form-group>
                                                    </b-dropdown-form>
                                                </b-dropdown>
                                            </template>
                                            <template v-else>
                                                <!-- <p>지정된 배경색이 없습니다.</p> -->
                                                <b-btn size="sm"
                                                    variant="light"
                                                    class="mb-2"
                                                    @click="createDataColor(editible_index, _b_dx - 1, 'backgroundColor')"
                                                    block
                                                    >
                                                    배경색 생성하기
                                                </b-btn>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { Chrome } from 'vue-color';
import debounce from 'lodash.debounce';
import tinycolor from "tinycolor2";

// import {
//     option_scales
// } from '@/util/chart/chart.utils.js';

import {
    // point_style_options,
    chart_type_options,
    // legend_position_options,
} from "./chart";

import { y_options, option_scales_common } from "@/util/chart/chart.utils.js";

export default {
    name: 'EditChartLocalConfig',
    components: {
        'chrome-picker': Chrome,
    },
    props: {
        datasets: {
            type: Array,
            required: true,
            default: () => ([])
        },
        labels: {
            type: [Array, Object],
            required: true,
            default: () => ([])
        },
        options: {
            type: Object,
            required: true,
            default: () => ({})
        },
        formData: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data() {
        return {
            chart_type_options,
            y_options,
            option_scales_common,

            editibleDatasets: [],
            editibleOptions: {},

            // common_option: {
            //     stack: false,
            //     show_stack: false,
                
            //     beginAtZero: false,
            //     show_beginAtZero: false,
            //     axes_min: null,
            //     show_axes_min: false,
            //     axes_max: null,
            //     show_axes_max: false,

            //     beginAtZero_y_right: false,
            //     show_beginAtZero_y_right: false,
            //     axes_min_y_right: null,
            //     show_axes_min_y_right: false,
            //     axes_max_y_right: null,
            //     show_axes_max_y_right: false
            // },

            single_chart: ['pie', 'doughnut', 'polarArea', 'radar'],
            multi_chart: ['line', 'bar', 'mixed'],

            // Form helper Data
            validation: {
                type: {
                    state: null,
                    feedback: null
                },
                yAxisID: {
                    state: null,
                    feedback: null
                },
                backgroundColor: {
                    state: null,
                    feedback: null
                },
                borderColor: {
                    state: null,
                    feedback: null
                },
                borderWidth: {
                    state: null,
                    feedback: null
                },
                borderRadius: {
                    state: null,
                    feedback: null
                },
                barPercentage: {
                    state: null,
                    feedback: null
                },
                stack: {
                    state: null,
                    feedback: null
                },
                beginAtZero: {
                    state: null,
                    feedback: null
                },
                min: {
                    state: null,
                    feedback: null
                },
                max: {
                    state: null,
                    feedback: null
                }
            },
            disabledState: {
                type: false,
                yAxisID: false,
                backgroundColor: false,
                borderColor: false,
                borderWidth: false,
                borderRadius: false,
                barPercentage: false,
                stack: false,
                beginAtZero: false,
                min: false,
                max: false,
            }
        }
    },
    computed: {
        editibleOptionProps () {
            return {
                // Scales Option
                yAxisID:         ['bar', 'line', 'mixed'],
                beginAtZero:     ['bar', 'line', 'polarArea', 'radar', 'mixed'],
                min:             ['bar', 'line', 'polarArea', 'radar', 'mixed'],
                max:             ['bar', 'line', 'polarArea', 'radar', 'mixed'],
                // Dataset Option
                barPercentage:   ['bar'],
                stack:           ['bar', 'line', 'mixed'],
                borderRadius:    ['bar', 'doughnut', 'pie', 'polarArea', 'radar', 'mixed'],
                borderWidth:     ['bar', 'line', 'doughnut', 'pie', 'polarArea', 'radar', 'mixed'],
                backgroundColor: ['bar', 'line', 'doughnut', 'pie', 'polarArea', 'radar', 'mixed'],
                borderColor:     ['bar', 'line', 'doughnut', 'pie', 'polarArea', 'radar', 'mixed'],
                unit:            ['bar', 'line', 'doughnut', 'pie', 'polarArea', 'radar', 'mixed']
            }
        }
    },
    watch: {
        datasets: {
            deep: true,
            handler (_updated_datasets) {
                this.editibleDatasets = this.preprocessingDatasets(_updated_datasets)
            }
        },
        options: {
            deep: true,
            handler (_updated_options) {
                this.editibleOptions = JSON.parse(JSON.stringify(_updated_options))
            }
        }
    },
    created() {
        this.editibleDatasets = this.preprocessingDatasets(this.datasets)
        this.editibleOptions = JSON.parse(JSON.stringify(this.options))
        
    },
    mounted() {},
    destroyed () {
    },
    methods: {
        updateChart_default: debounce( function (_edit_data_idx, _prop) {
            let _editibleDatasets = JSON.parse(JSON.stringify([ ...this.editibleDatasets ]))

            if ([ 'backgroundColor', 'borderColor' ].includes(_prop)) {
                _editibleDatasets[_edit_data_idx][_prop] = this.cleansingColor(_edit_data_idx, _prop)
            }            

            this.$emit('updateChartDataSets', _editibleDatasets)
        }, 450),
        preprocessingDatasets (_datasets) {
            let _editibleDatasets = JSON.parse(JSON.stringify(_datasets));
            if (_editibleDatasets.length) {
                _editibleDatasets = _editibleDatasets.reduce((_datasets, _data) => {
                    if (this.editibleOptionProps.yAxisID.includes(_data.type)) {
                        _data.yAxisID = !_data.yAxisID ? 'y' : _data.yAxisID
                    }

                    if (!_data.borderColor) {
                        _data.borderColor = [...Array(_data.data.length).keys()]
                        _data.borderColor.fill('#dedede')
                    }

                    if (!_data.backgroundColor) {
                        _data.backgroundColor = [...Array(_data.data.length).keys()]
                        _data.backgroundColor.fill('#dedede')
                    }

                    _datasets.push(_data)
                    return _datasets
                }, [])
            }

            // const _has_control_beginAtZero = _editibleDatasets.every(({ type }) => ['line', 'bar', 'polarArea', 'radar'].includes(type))
            // const _has_control_stack = _editibleDatasets.every(({ type }) => ['line', 'bar'].includes(type))
            // console.log(` ~ _has_control_stack => `, _has_control_stack);
            
            // this.common_option.show_beginAtZero = _has_control_beginAtZero
            // this.common_option.show_stack = _has_control_stack
            // this.common_option.show_axes_min = _has_control_beginAtZero
            // this.common_option.show_axes_max = _has_control_beginAtZero

            return _editibleDatasets
        },
        updateChart_type (changed_type, changed_data_index) {
            let _chart_type = null
            let _editibleOptions = JSON.parse(JSON.stringify(this.options))
            let _editibleDatasets = JSON.parse(JSON.stringify([ ...this.editibleDatasets ]))
            
            if (['line', 'bar'].includes(changed_type)) {
                _chart_type = changed_type
                _editibleDatasets = _editibleDatasets.reduce((_dataset, _data) => {
                    if (!['line', 'bar'].includes(_data.type)) _data.type = changed_type
                    if (_data.type !== changed_type) _chart_type = 'mixed'
                    _dataset.push(_data)
                    return _dataset
                }, [])
            } else {
                _chart_type = changed_type
                _editibleDatasets = _editibleDatasets.reduce((_dataset, _data) => {
                    _data.type = changed_type
                    _dataset.push(_data)
                    return _dataset
                }, [])
            }

            let _old_type = this.datasets[changed_data_index].type
            if (_old_type !== changed_type) {
                if (this.single_chart.includes(_chart_type) || (this.single_chart.includes(_old_type) && this.multi_chart.includes(_chart_type))) {
                    _editibleOptions['scales'] = {}
                } else if (this.multi_chart.includes(_old_type) && this.single_chart.includes(_chart_type)) {
                    if (_editibleOptions?.scales.x) delete _editibleOptions['scales']['x']
                    if (_editibleOptions?.scales.y) delete _editibleOptions['scales']['y']
                    if (_editibleOptions?.scales['y-right']) delete _editibleOptions['scales']['y-right']
                }
            }

            _editibleDatasets = _editibleDatasets.reduce((_dataset, _data) => {
                // Scales Option
                if (!this.editibleOptionProps.yAxisID.includes(_data.type)) {
                    if (_data['yAxisID']) delete _data['yAxisID']
                }

                if (!this.editibleOptionProps.beginAtZero.includes(_data.type)) {
                    if (_data['beginAtZero']) delete _data['beginAtZero']
                }

                if (!this.editibleOptionProps.min.includes(_data.type)) {
                    if (_data['min']) delete _data['min']
                }

                if (!this.editibleOptionProps.max.includes(_data.type)) {
                    if (_data['max']) delete _data['max']
                }

                // Dataset Option
                if (!this.editibleOptionProps.barPercentage.includes(_data.type)) {
                    if (_data['barPercentage']) delete _data['barPercentage']
                }

                if (!this.editibleOptionProps.barPercentage.includes(_data.type)) {
                    if (_data['stack']) delete _data['stack']
                }

                if (!this.editibleOptionProps.borderRadius.includes(_data.type)) {
                    if (_data['borderRadius']) delete _data['borderRadius']
                }

                _dataset.push(_data)
                return _dataset
            }, [])

            this.editibleDatasets = [ ..._editibleDatasets ]            
            this.$emit('updateChart', {
                data: { datasets: this.editibleDatasets },
                options: _editibleOptions
            })
        },
        updateChartDataset_YAxis () {
            let _editibleDatasets = JSON.parse(JSON.stringify([ ...this.editibleDatasets ]))
            let _editibleOptions = JSON.parse(JSON.stringify({ ...this.editibleOptions }))
            const _has_YAxis_right_datasets = _editibleDatasets.some(({ yAxisID }) => yAxisID === 'y-right')
            
            if (!_has_YAxis_right_datasets) {
                _editibleDatasets = _editibleDatasets.reduce((_datasets, _data) => {
                    if (_data.yAxisID) _data.yAxisID = 'y'
                    _datasets.push(_data)
                    return _datasets
                }, [])
                
                delete _editibleOptions.scales['y-right']
            } else {
                if (!_editibleOptions.scales['y-right']) {
                    _editibleOptions.scales['y-right'] = { ...option_scales_common['y-right'] }
                }

                const _is_All_YAxis_right_datasets = _editibleDatasets.every(({ yAxisID }) => yAxisID === 'y-right')
                if (_is_All_YAxis_right_datasets || _editibleDatasets.length === 1) {
                    _editibleOptions.scales['y-right'].grid.display = true
                    delete _editibleOptions.scales['y']
                } else {
                    _editibleOptions.scales['y-right'].grid.display = false
                }
            }

            this.editibleDatasets = _editibleDatasets
            this.editibleOptions = _editibleOptions

            this.$emit('updateChart', {
                data: { datasets: _editibleDatasets },
                options: _editibleOptions
            })
        },
        updateChartData_color (dataset_idx, data_idx, prop_color) {
            let _updated_color = this.editibleDatasets[dataset_idx][prop_color][data_idx]
            _updated_color = this.convertingColor(_updated_color)
            
            this.editibleDatasets[dataset_idx][prop_color][data_idx] = _updated_color
            this.$emit('updateChartDataSets', [ ...this.editibleDatasets ])
        },
        // updateChart_beginAtZero () {
        //     let _types = this.editibleDatasets.map(({ type }) => type)
        //     const _is_axes_type_cartesian = _types.every(_type => ['line', 'bar'].includes(_type))
        //     const _is_axes_type_radial = _types.every(_type => ['polarArea', 'radar'].includes(_type))

        //     const _beginAtZero = !this.common_option.beginAtZero
        //     const _editibleOptions = { ...this.editibleOptions }

        //     const _editibleOption_min = this.func_minmax('min', this.common_option.axes_min, true)
        //     const _editibleOption_max = this.func_minmax('max', this.common_option.axes_max, true)

        //     let _axis = _is_axes_type_cartesian? 'y' : 'r'

        //     _editibleOptions.scales[_axis].beginAtZero = _beginAtZero
        //     _editibleOptions.scales[_axis] = {
        //         ..._editibleOptions.scales[_axis],
        //         ..._editibleOption_max[_axis]
        //     }

        //     if (!_beginAtZero) {
        //         _editibleOptions.scales[_axis].min = _editibleOption_min.scales[_axis].min
        //     } else {
        //         delete _editibleOptions.scales[_axis].min
        //     }

        //     if (_is_axes_type_cartesian || _is_axes_type_radial) {
        //         this.$emit('updateChart', {
        //             data: { datasets: this.editibleDatasets },
        //             options: _editibleOptions
        //         })
        //     }
        // },
        // 
        // updateChart_minmax: debounce(function (_type, _num) {
        //     this.func_minmax(_type, _num)
        // }, 1000),
        // func_minmax(_num_type, _num, func = false) {
        //     if (_num_type === 'min' && !this.common_option.show_beginAtZero) {
        //         return false
        //     }
            
        //     const _is_axis_cartesian = this.editibleDatasets.every(({ type }) => ['line', 'bar'].includes(type))
        //     const _is_axis_radial = this.editibleDatasets.every(({ type }) => ['polarArea', 'radar'].includes(type))

        //     const _editibleOptions = { ...this.editibleOptions }
        //     if (_is_axis_cartesian) {
        //         if (_num === '') {
        //             delete _editibleOptions.scales.y[_num_type]
        //         } else {
        //             _editibleOptions.scales.y[_num_type] = Number.parseInt(_num)
        //         }
        //     }

        //     if (_is_axis_radial) {
        //         if (_num === '') {
        //             delete _editibleOptions.scales.r[_num_type]
        //         } else {
        //             _editibleOptions.scales.r[_num_type] = Number.parseInt(_num)
        //         }
        //     }

        //     if (func) {
        //         return _editibleOptions
        //     } else {
        //         if (_is_axis_cartesian || _is_axis_radial) {
        //             this.$emit('updateChart', {
        //                 data: { datasets: this.editibleDatasets },
        //                 options: _editibleOptions
        //             })
        //         }
        //     }
        // },
        // updateChartDataset_stack () {
        //     const _is_mixed_chart = this.editibleDatasets.reduce((_types, { type }) => {
        //         _types.line = _types.line || type === 'bar'
        //         _types.bar = _types.bar || type === 'line'
        //         return _types
        //     }, { line: false, bar: false })

        //     const _stack_state = this.common_option.stack

        //     const _editibleOptions = { ...this.editibleOptions }
        //     if (_is_mixed_chart.bar && _is_mixed_chart.line) {
        //         _editibleOptions.scales.x.stacked = false
        //         _editibleOptions.scales.y.stacked = _stack_state
        //     } else if (_is_mixed_chart.bar && !_is_mixed_chart.line) {
        //         _editibleOptions.scales.x.stacked = _stack_state
        //         _editibleOptions.scales.y.stacked = _stack_state
        //     } else if (!_is_mixed_chart.bar && _is_mixed_chart.line) {
        //         _editibleOptions.scales.x.stacked = _stack_state
        //         _editibleOptions.scales.y.stacked = _stack_state
        //     }

        //     let _editibleDatasets = [ ..._editibleDatasets ].reduce((_datasets, _data) => {
        //         if (_is_mixed_chart.bar && _is_mixed_chart.line) {
        //             _data.stack = 'combined'
        //         } else {
        //             delete _data['stack']
        //         }

        //         _datasets.push(_data)
        //         return _datasets
        //     }, [])

        //     this.$emit('updateChart', {
        //         data: { datasets: this.editibleDatasets },
        //         options: _editibleOptions
        //     })
            
        // },
        convertingColor (color) {
            // console.log(`convertingColor ~ `);
            
            if (typeof color === 'string') {
                return color
            } else if (typeof color === 'object' && 'hex' in color) {
                return color.hex8
            } else {
                return 'rgba(239, 239, 239, 1)'
            }
        },
        createDataColor (dataset_idx, data_idx, prop_color) {
            const _data_has_no_color = !!this.editibleDatasets[dataset_idx]['backgroundColor'][data_idx] && !!this.editibleDatasets[dataset_idx]['borderColor'][data_idx]
            if (_data_has_no_color) {
                const _new_color = tinycolor.random();
                const [_border_color, _background_color] = [ _new_color.setAlpha(0.7), _new_color.setAlpha(0.5) ]

                this.editibleDatasets[dataset_idx]['borderColor'][data_idx] = _border_color;
                this.editibleDatasets[dataset_idx]['backgroundColor'][data_idx] = _background_color
            } else {
                let _sample_prop = prop_color === 'borderColor' ? 'backgroundColor' : 'borderColor';
                let _sample_color = this.editibleDatasets[dataset_idx][_sample_prop][data_idx]

                const _is_light_color = tinycolor(_sample_color).isLight()
                const _color_alpha = _is_light_color
                    ? prop_color === 'borderColor' ? 10 : 15
                    : prop_color === 'borderColor' ? 0.3 : 0.6
                
                let _prop_new_color = _is_light_color
                    ? tinycolor(_sample_color).darken(_color_alpha)
                    : tinycolor(_sample_color).setAlpha(_color_alpha)

                this.$set(this.editibleDatasets[dataset_idx][prop_color], data_idx, `#${_prop_new_color.toHex8()}`)
                this.$emit('updateChartDataSets', [ ...this.editibleDatasets ])
            }
        },
    }
}
</script>

<style lang="scss">
.color-setting-card + .color-setting-card {
    margin-left: 15px;
}
</style>
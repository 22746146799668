<template>
<div>
    <router-view name="editor_header"></router-view>

    <b-container class="pt-5">
        <h3 class="mb-45"> {{ page_check[0]==true? '보고서 검토' :'보고서 검증' }}</h3>
        <b-card class="general-card mb-5" :no-body="page_check[0]!=true" :class="page_check[0]==true? '':'border-0'">
            <b-card-header v-if="page_check[0]==true">작성한 보고서 검토하기</b-card-header>
            <div v-if="page_check[0]==true" class="py-3">
                <p class="gray-600 mb-0">
                    <!-- 본 보고서는 GRI Standards의 보고 표준에 따른 보고 옵션 중 <a class="fw-400 cursor" @click.stop="showCoreOption()">{{ current_gri_option }}</a>합니다. <br> -->
                    보고서 검토 후 ‘검증받기’를 진행할 경우 전문가 검토를 거쳐 제3자 검증 의견서를 받을 수 있습니다. 검증 의견서는 지속가능성 보고 국제 표준인 AA1000 라이선스를 기반으로 제공됩니다. 
                </p>
            </div>
            <b-row v-else class="py-3">
                <b-col> <div :class="page_check[1]==true?'border-bottom-dark-bold fw-500 gray-800':'border-bottom gray-600'">검증 안내</div> 
                    <p class="f-85 pt-2 mb-0" :class="page_check[1]==true?'fw-400':'gray-600'"> - 제3자 검증 서비스 </p>
                </b-col>
                <b-col> <div :class="page_check[2]==true?'border-bottom-dark-bold fw-500 gray-800':'border-bottom gray-600'">검증 신청</div> 
                    <p class="f-85 pt-2 mb-0" :class="page_check[2]==true?'fw-400':'gray-600'"> - 검증 의뢰하기 </p>
                </b-col>
                <b-col> <div class="border-bottom gray-600"> 서면검증 진행 </div>
                    <p class="f-85 pt-2 mb-0 gray-600"> - 약 2~3일 소요 <br> - 보완사항 도출</p>
                </b-col>
                <b-col> <div :class="page_check[3]==true?'border-bottom-dark-bold fw-500 gray-800':'border-bottom gray-600'"> 검증의견 확인 및 보완 </div> 
                    <p class="f-85 pt-2 mb-0 indent-sm" :class="page_check[3]==true?'fw-400':'gray-600'"> - 서면검증 의견 확인 <br> - 대면 인터뷰 전까지 보완사항 보완</p>
                </b-col>
                <b-col> <div class="border-bottom gray-600">대면 인터뷰 </div>
                    <p class="f-85 pt-2 mb-0 gray-600"> - 약 2~5시간 소요 <br> - 최종 검증 의견 확정 </p>
                </b-col>
                <b-col> <div :class="page_check[4]==true?'border-bottom-dark-bold fw-500 gray-800':'border-bottom gray-600'">검증 의견서 산출</div> 
                    <p class="f-85 pt-2 mb-0" :class="page_check[4]==true?'fw-400':'gray-600'"> - 대면 인터뷰 후 1일 소요</p>
                </b-col>
            </b-row>
        </b-card>
    </b-container>
    <b-container v-if="page_check[3]!=true">
        <b-row class="mx-0">
            <b-col cols="3">
                <side-nav-assure class="sticky-top pt-4"></side-nav-assure>
            </b-col>
            <b-col class="pt-4">                        
                <router-view></router-view> 
            </b-col>
        </b-row>
    </b-container>
    <div v-else>
        <b-row class="px-3 px-lg-5 mx-0">
            <b-col cols="4" md="3" lg="2">
                <side-nav-assure class="sticky-top pt-4"></side-nav-assure>
            </b-col>
            <b-col class="pt-4">                        
                <router-view></router-view> 
            </b-col>
        </b-row>
    </div>
    <hr class="border-0 py-5">  

    <router-view name="editor_footer"></router-view>     

    <b-button v-b-modal.need-help class="need-help-btn" variant="tealgray" v-b-tooltip.hover title="도움이 필요하신가요?"><fa icon="question"/></b-button>

    <b-modal id="todo-preference" ref="todo-modal" hide-footer size="xl" scrollable >
        <template #modal-header> <h4 class="mt-1">보고서 작성 로드맵</h4></template>
        <todo-roadmap @hide-todo-modal="hideTodoModal"></todo-roadmap>
    </b-modal>
    <b-modal id="core-option" ref="core-option-modal" size="lg" title="보고 요건 충족 기준" hide-footer scrollable>
        <core-or-comprehensive></core-or-comprehensive>
    </b-modal>

    <b-modal id="need-help" hide-footer size="" title="문의하기" scrollable >
        <need-help></need-help>
    </b-modal>
          
</div>    
</template>
<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import SideNavAssure from '@/components/assurance/SideNavAssure'
import CoreOrComprehensive from '@/components/static_examples/CoreOrComprehensive'

export default {
    data() {
        return {
            
        }
    },    
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapGetters('report_list', ['selected_report']), 
        ...mapState('assurance', ['assurance_list_loaded']),

        ...mapState('report_indicators', ['gri_option_status']),

        page_check(){
            if(this.$route.path == '/check_report'){
                return [true, false, false, false, false]
            }
            if(this.$route.path == '/assurance_info'){
                return [false, true, false, false, false]
            }
            if(this.$route.path == '/apply_assurance'){
                return [false, false, true, false, false]
            }
            if(this.$route.path == '/co_verification'){
                return [false, false, false, true, false]
            }
            if(this.$route.path == '/get_assurance_report'){
                return [false, false, false, false, true]
            }
        },
        current_gri_option(){
            if (this.gri_option_status.is_core_aligned==true&&this.gri_option_status.is_comprehensive_aligned==false){
                return '핵심 부합 (Core option) 충족'
            }
            else if (this.gri_option_status.is_comprehensive_aligned==true){
                return '포괄적 부합 (Comprehensive option) 충족'
            }
            else return '핵심 부합 (Core option) 미충족'
        }
    },
    methods:{
        ...mapActions('assurance', ['readAssuranceListByReportCode']),
        ...mapMutations('assurance', ['update_show_apply_estimate', 'update_assurance_list_loaded']),
        ...mapMutations('report_contents', ['update_report_contents_loaded']),
        ...mapMutations('business_cases', ['update_business_case_loaded']),

        ...mapMutations('report_indicators', ['update_gri_option_status']),
        ...mapActions('report_indicators', ['checkGriOption']),

        setTodoVisible (){
            if (localStorage) {
                let str_key = 'hide_todo_'+this.report_code
                let hide_todo_until = new Date(localStorage.getItem(str_key));
                if (hide_todo_until < new Date()) { // expire 시간이 지남 
                    return true
                }
                else return false  // 아직 expire 시간이 안지남 
            }
            return true;
        }, 
        showTodoModal(){
            if (this.setTodoVisible() == true){
                setTimeout(() => {
                    this.$refs['todo-modal'].show()
                }, 200)
            }
        },
        hideTodoModal(){
            this.$refs['todo-modal'].hide()
        },
        showCoreOption(){
            this.$refs['core-option-modal'].show()
        },
        hideCoreOption(){
            this.$refs['core-option-modal'].hide()
        }

    },
    created(){
        this.readAssuranceListByReportCode({report_code: this.report_code})
        if(this.gri_option_status.is_core_aligned==undefined){
            this.checkGriOption(this.report_code)
        }
        
    },
    mounted(){
        if(this.selected_report&&this.selected_report.state==3){
            this.showTodoModal()
        }
    },
    beforeDestroy(){
        this.update_report_contents_loaded(false)
        this.update_business_case_loaded(false)

        this.update_show_apply_estimate(true)
        this.update_assurance_list_loaded(false)

        this.update_gri_option_status({})
    },
    components:{
        SideNavAssure,
        CoreOrComprehensive,
    },

}
</script>
import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import store from '@/store'
import VueRouter from 'vue-router'
import router from './router'
import BootstrapVue from 'bootstrap-vue'

import cssVars from 'css-vars-ponyfill'

import '@/assets/custom.scss'
import '@/assets/fonts.css'
import '@/assets/basic.css'
import '@/assets/btn_badge.css'
import '@/assets/forms.css'
import '@/assets/modal_toasts.css'
import '@/assets/line_border_table.css'
import '@/assets/no_line_border_table.css'
import '@/assets/custom.css'
import MD from '@/components/global_components/MD'
import TodoRoadmap from '@/components/global_components/TodoRoadmap'
import BackToTop from '@/components/global_components/BackToTop'
import NeedHelp from '@/components/global_components/NeedHelp'
import '@/plugins/start'
import checkView from 'vue-check-view'



Vue.component('md', MD)
Vue.component('todo-roadmap', TodoRoadmap)
Vue.component('back-to-top', BackToTop)
Vue.component('need-help', NeedHelp)

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(checkView)

Vue.prototype.$EventBus = new Vue()

cssVars({
    rootElement: document,
    shadowDOM: false,
  
    include: 'link[rel=stylesheet], style',
    exclude: '',
    variables: {
        '--fa-primary-color' :'#494949',
        '--fa-primary-opacity' : '1',
        '--fa-secondary-color' : '#494949',
        '--fa-secondary-opacity': '1',
    },
  
    preserveStatic: true,
    preserveVars: false,
    silent: false,
    updateDOM: true,
    updateURLs: false,
    
    onlyLegacy: false,
    watch: true,

})

const vue_instance = new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')


export {
    vue_instance
}



<template>
  <div>
    <h3>결제 내역</h3>

    <b-container fluid>
      <!-- Main table element -->
      <b-table
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
        hover
        empty-filtered-html="검색 결과가 없습니다 😥"
        tbody-tr-class="payment-row"
      >
        <template #cell(productPrice)="row">
          {{ row.value }}
        </template>

        <template #cell(discountCode)="row">
          <span v-if="row.value !== ''">
            {{ row.value }}
            <b-icon icon="check-circle" scale="1" variant="primary"></b-icon>
          </span>

          <div v-else>
            <b-icon icon="x-circle" scale="1" variant="secondary"></b-icon>
          </div>
        </template>

        <template #cell(payment_status)="row">
          <div v-if="row.value == true">
            <b-icon icon="check-circle" scale="1.5" variant="success"></b-icon>
          </div>
          <div v-else @click="checkPayment">
            <b-icon
              icon="exclamation-circle-fill"
              scale="1.5"
              variant="danger"
              animation="throb"
            ></b-icon>
          </div>
        </template>

        <template #cell(actions)="row">
          <b-button size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? "창 닫기" : "세부보기" }}
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <!-- Detail 부분 -->
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>

      <!-- Info modal -->
      <b-modal
        :id="infoModal.id"
        :title="infoModal.title"
        ok-only
        @hide="resetInfoModal"
      >
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <!-- User Interface controls -->
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { formatCurrency } from "@/components/payment/payment";

export default {
  data() {
    return {
      items: [
        {
          payment_status: true,
          productPrice: 400000,
          productName: "어쩌구 정기결제서비스",
        },
        {
          payment_status: false,
          productPrice: 21000,
          discountCode: "무슨회사 할인",
          productName: "어쩌구 정기결제서비스2",
        },
        {
          payment_status: false,
          productPrice: 89000,
          productName: "어쩌구 정기결제서비스3",
        },
      ],
      fields: [
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
        {
          key: "productName",
          label: "상품명",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "productPrice",
          label: "상품가격",
          sortable: true,
          class: "text-center",
          formatter: (value) => {
            return formatCurrency(value) + "원";
          },
        },
        {
          key: "discountCode",
          label: "할인코드",
          sortable: true,
          class: "text-center",
        },
        {
          key: "payment_status",
          label: "사용여부",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          class: "text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    const table_rows = document.querySelectorAll(".payment-row");
    table_rows.forEach((element) => {
      element.addEventListener("click", this.openRowDetail);
    });

    this.totalRows = this.items.length;
  },
  methods: {
    openRowDetail(e) {
      e.stopPropagation();
      let currentElement = e.target;
      if (currentElement.tagName == "BUTTON") {
        try {
          currentElement.firstChild.querySelector("button").click();
        } catch (e) {
          return null;
        }
        return;
      }
      while (currentElement && currentElement.tagName !== "TR") {
        currentElement = currentElement.parentElement;
      }

      if (currentElement && currentElement.tagName === "TR") {
        // <tr> 태그를 찾은 경우에 대한 로직을 작성합니다.
        currentElement.firstChild.querySelector("button").click();
      }
    },
    checkPayment() {
      // TODO: 사용자의 결제 연장 유도
      console.log("결제연장!");
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style></style>

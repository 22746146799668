<template>
    <div class="py-2">
        <div v-if="is_confirmed!=true" class="mb-5">
            <h5 class="tail-line-dark mb-3"> 이메일 인증 </h5>
            <p> <fa icon="info-circle"/> 이메일을 인증해 주세요. 미인증시 보고서 생성 및 편집이 제한됩니다. </p>
            <b-input-group class="mb-3">
                <b-form-input v-model="confirm_code" placeholder="인증코드를 입력해주세요." @keyup.enter="localUserConfirmed()"></b-form-input>
                <b-input-group-append><b-button variant="red" @click="localUserConfirmed()"> {{is_checking==false? `인증하기` : `인증 중` }} <b-spinner small class="ml-1" v-if="is_checking==true"></b-spinner> </b-button> </b-input-group-append>
            </b-input-group>
            <div class="text-right f-90 fw-400 gray-600 mb-2">
                인증 코드를 잊으셨나요? <b-button class="ml-2 py-1" size="sm" variant="red-border" :disabled="send_retry==true" @click="sendRetry()"> {{send_retry==true?'인증코드 재발송 중': '인증코드 다시받기' }} <b-spinner v-if="send_retry==true" class="ml-1" small></b-spinner></b-button>
            </div>
            <div class="text-right f-90 red"><fa v-if="local_msg!=''" icon="check-circle"/> {{ local_msg }}</div>
        </div>

        <h5 class="tail-line-dark mb-3"> 내 정보 관리 </h5>            
            <b-table-simple hover responsive class="txt-table mb-4">
                <b-tbody>
                    <b-tr>
                        <b-td>내 계정</b-td>
                        <b-td class="py-2">
                            {{ e_mail }}
                            <div v-if="is_editing==true" class="mt-2"><b-button class="py-1" :variant="update_password!=true?'teal-border':'teal'" @click="updatePassword()">{{ update_password!=true ? '비밀번호 변경하기' : '비밀번호 변경 취소' }}</b-button></div>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="update_password==true"><b-td>비밀번호 변경</b-td>
                        <b-td class="py-2">
                            <b-input class="mb-1" type="password" v-model="password_old" :class="password_old?'bgray-200':''" placeholder="기존 비밀번호"></b-input>
                            <b-input class="mb-1" type="password" v-model="password_new1" placeholder="새 비밀번호"></b-input>
                            <b-input class="mb-1" type="password" v-model="password_new2" placeholder="새 비밀번호 확인" :state="check_password"></b-input>
                        </b-td>
                    </b-tr>
                    <!-- <b-tr>
                        <b-td>정기결제 정보</b-td>
                        <b-td class="py-2">
                            <template v-if="is_editing!=true">{{ subscription == true ? "ESG, 지속가능경영 관련 소식 정기결제 중" : "ESG, 지속가능경영 관련 소식 미정기결제" }}</template>
                            <template v-else> <b-form-checkbox v-model="local_subscription">ESG, 지속가능경영 관련 소식 정기결제하기</b-form-checkbox> </template>
                        </b-td>
                    </b-tr> -->
                    <b-tr v-if="licenseInfo && licenseInfo.available_report_cnt_info">
                        <b-td>결제 정보</b-td>
                        <b-td class="py-2">
                            <p>
                                올해 {{ licenseInfo.available_report_cnt_info.created_report_cnt }}개의 리포트를 생성하셨습니다. 
                            </p>
                            <template v-if="licenseInfo.isUsed && licenseInfo.payment_status">
                                <p>
                                    {{licenseInfo.subscriptionId}}를 사용중이십니다.
                                </p>
                                <p v-if="licenseInfo.available_report_cnt_info.code === true">
                                    현재 리포트 추가 생성이 {{licenseInfo.available_report_cnt_info.remain_report_cnt}}개 가능합니다. 
                                </p>
                                <p v-else>
                                    (현재 리포트 추가 생성 불가합니다.)
                                </p>
                            </template>
                            <template v-if="licenseInfo.isUsed && !licenseInfo.payment_status">
                                해당 라이센스에 대한 결제가 이뤄지지 않았습니다. 결제를 다시 진행하거나 결제 수단을 변경해주세요.
                            </template>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            
        <b-button v-if="is_editing!=true" variant="teal-border" class="" @click.stop.prevent="is_editing=true"> 내 정보 수정하기 </b-button> 
        <div v-else> 
            <b-button variant="teal" class="" @click="saveMyInfo()" :disabled="is_saving==true">{{is_saving==true?'저장 중':'내 정보 저장하기'}} <b-spinner small v-if="is_saving==true"></b-spinner></b-button> 
            <b-button class="ml-2" @click="cancleEdit()"> 취소 </b-button>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            is_saving: false,
            is_editing:false,
            update_password: false,
            send_retry: false,

            password_old: '',
            password_new1: '',
            password_new2: '',

            confirm_code: '',
            local_subscription: '',

            local_msg: '',
            is_checking: false
        }
    },
    props:{

    },
    computed:{
        ...mapState(['user_id', 'e_mail', 'subscription', 'is_confirmed']),
        ...mapState(["licenseInfo"]),
        check_password(){
            if(this.password_new1.length == 0) { return null }
            if(this.password_new1 != this.password_new2) { return false }
        }
    },
    methods:{
        ...mapActions(['saveUserInfo', 'userConfirmed']),
        localUserConfirmed(){
            if(this.confirm_code==''||this.confirm_code==' '){
                alert('이메일로 받으신 5자리 인증 코드를 입력해 주세요.')
                return false
            }
            if(this.confirm_code.length!=5){
                alert('인증 코드는 5자리 입니다. 입력하신 코드를 다시 확인해 주세요.')
                return false
            }
            else { 
                this.is_checking = true
                this.userConfirmed({ e_mail: this.e_mail, confirm_code: this.confirm_code })
                .then( response => {
                    if( response.data.code != "failure"){
                        this.$EventBus.$emit('make-toast', '계정 인증 완료', '이메일 계정이 인증되었습니다.')
                    } else {
                        alert(response.data.msg)
                        console.log(response.data.code)
                    }
                })
                .catch( error => { console.log(error) })
                this.is_checking = false
            }
        },
        cancleEdit(){
            this.update_password = false
            this.password_old = false
            this.password_new1 = false
            this.password_new2 = false
            this.local_subscription = this.subscription
            this.is_editing = false
        },
        async saveMyInfo(){
            this.is_saving = true
            if( this.update_password != true ){
                await this.saveUserInfo({ user_id: this.user_id, subscription: this.local_subscription })
                this.$EventBus.$emit('make-toast', '내 정보 수정', '정기결제 정보가 업데이트 되었습니다.')
                this.cancleEdit()
            } 
            else {
                if(this.checkForm()==true){
                    await this.saveUserInfo({ 
                        user_id: this.user_id, 
                        password_old: this.password_old, 
                        password_new: this.password_new1, 
                        subscription: this.local_subscription 
                    })
                    .then(response => {
                        if(response.data.code != 'success'){
                            alert('기존 비밀번호가 일치하지 않습니다. 비밀번호를 다시 확인해 주세요.')
                        }
                        else {
                            this.$EventBus.$emit('make-toast', '내 정보 수정', '계정 정보가 업데이트 되었습니다.')
                            this.cancleEdit()
                        }
                    })
                }
            }  
            this.is_saving = false    
        },
        sendRetry(){
            this.send_retry = true
            this.requestConfirm()
            this.local_msg = '인증번호를 발송하였습니다. 이메일을 확인해주세요.'
            this.send_retry = false
        },
        updatePassword(){
            if (this.update_password == true) {
                this.update_password = false
            } else {
                this.update_password = true
            }
        },
        requestConfirm(){
            const path = this.$store.state.backend_host + '/request_confirm'
            return axios.get( path, { params: {
                e_mail: this.e_mail
                }
            })
        },
        checkForm(){
            if( this.password_old == '' ){
                alert('기존 비밀번호를 입력해주세요.')
                return false
            }
            else if( this.password_new1 == '' ){
                alert('새 비밀번호를 입력해주세요.')
                return false
            }
            else if( this.password_new2 == '' ){
                alert('새 비밀번호 확인을 입력해주세요.')
                return false
            }
            else return true
        }
    },
    created(){
        this.local_subscription = this.subscription
    }
}
</script>

<style>

</style>
const charts = {
  namespaced: true,
  state: {
    chart_data: [],
    chart_field: [],
    toc_list: [],


    // 데이터센터 연결
    chart_linked_indicators: {},
    table_linked_indicators: {},

    // 지표 연결
    gri_linked_indicators: {},
    sasb_linked_indicators: {},
    tcfd_linked_indicators: {},

    selected_chart_id: null,
    selected_chart_data: null,

    edited_chart_id: null

    
  },

  mutations: {
    init_chart_indicator(state, payload) {
      state.gri_linked_indicators = payload.gri_linked_indicators
      state.sasb_linked_indicators = payload.sasb_linked_indicators
      state.tcfd_linked_indicators = payload.tcfd_linked_indicators
    },
    update_chart_indicator(state, payload) {
      // "type": type,
      // "editing_code": editing_code,
      // "payload": payload
      if (payload.type === 'GRI') {
        // state.gri_linked_indicators[chart_id] = []
        // for (let chart_id of payload.payload) {
        //   state.gri_linked_indicators[chart_id].push(payload.editing_code)
        // }
        for (let key of Object.keys(state.gri_linked_indicators)) {
          if (payload.payload.includes(Number(key))) {
            if (!state.gri_linked_indicators[Number(key)].includes(payload.editing_code)) {
              state.gri_linked_indicators[Number(key)].push(payload.editing_code)
            }
          } else {
            state.gri_linked_indicators[Number(key)] = state.gri_linked_indicators[Number(key)].filter(code => code !== payload.editing_code)
          }
        }

      } else if (payload.type === 'SASB') {
        // state.sasb_linked_indicators[chart_id] = []
        // for (let chart_id of payload.payload) {
        //   state.sasb_linked_indicators[chart_id].push(payload.editing_code)
        // }
        for (let key of Object.keys(state.sasb_linked_indicators)) {
          if (payload.payload.includes(Number(key))) {
            if (!state.sasb_linked_indicators[Number(key)].includes(payload.editing_code)) {
              state.sasb_linked_indicators[Number(key)].push(payload.editing_code)
            }
          } else {
            state.sasb_linked_indicators[Number(key)] = state.sasb_linked_indicators[Number(key)].filter(code => code !== payload.editing_code)
          }
        }

      } else if (payload.type === 'TCFD') {
        // state.tcfd_linked_indicators[chart_id] = []
        // for (let chart_id of payload.payload) {
        //   state.tcfd_linked_indicators[chart_id].push(payload.editing_code)
        // }
        for (let key of Object.keys(state.tcfd_linked_indicators)) {
          if (payload.payload.includes(Number(key))) {
            if (!state.tcfd_linked_indicators[Number(key)].includes(payload.editing_code)) {
              state.tcfd_linked_indicators[Number(key)].push(payload.editing_code)
            }
          } else {
            state.tcfd_linked_indicators[Number(key)] = state.tcfd_linked_indicators[Number(key)].filter(code => code !== payload.editing_code)
          }
        }
      }
    },
    update_selected_chart_id(state, selected_chart_id) {
      state.selected_chart_id = selected_chart_id;
    },
    update_selected_chart_data(state, selected_chart_data) {
      state.selected_chart_data = selected_chart_data;
    },
    init_linked_indicator(state, linked_indicators) {
      state.chart_linked_indicators = linked_indicators.chart_linked_indicator;
      state.table_linked_indicators = linked_indicators.table_linked_indicator;

      state.gri_linked_indicators = linked_indicators.gri_linked_indicators;
      state.sasb_linked_indicators = linked_indicators.sasb_linked_indicators;
      state.tcfd_linked_indicators = linked_indicators.tcfd_linked_indicators;
    },
    delete_linked_indicators(state, id) {

      state.chart_linked_indicators = Object.keys(state.chart_linked_indicators).reduce((result, key) => {
        if (Number(key) !== id) {
          result[key] = state.chart_linked_indicators[key];
        }
        return result;
      }, {});
      
      state.table_linked_indicators = Object.keys(state.table_linked_indicators).reduce((result, key) => {
        if (Number(key) !== id) {
          result[key] = state.table_linked_indicators[key];
        }
        return result;
      }, {});
      

      state.gri_linked_indicators = Object.keys(state.gri_linked_indicators).reduce((result, key) => {
        if (Number(key) !== id) {
          result[key] = state.gri_linked_indicators[key];
        }
        return result;
      }, {});
      
      state.sasb_linked_indicators = Object.keys(state.sasb_linked_indicators).reduce((result, key) => {
        if (Number(key) !== id) {
          result[key] = state.sasb_linked_indicators[key];
        }
        return result;
      }, {});
      
      state.tcfd_linked_indicators = Object.keys(state.tcfd_linked_indicators).reduce((result, key) => {
        if (Number(key) !== id) {
          result[key] = state.tcfd_linked_indicators[key];
        }
        return result;
      }, {});


      state.chart_data = state.chart_data.filter(obj => obj['chart_id'] !== id);
      
      
    },
    update_linked_indicator(state, payload) {
      const type = payload.type;
      const pk = payload.new_lv_info.key;

      if (state.table_linked_indicators[payload.id] === undefined) {
        state.table_linked_indicators[payload.id] = []
      }
      if (state.chart_linked_indicators[payload.id] === undefined) {
        state.chart_linked_indicators[payload.id] = []
      }

      for (let i of Object.keys(state.table_linked_indicators)) {
        if (state.table_linked_indicators[i] !== undefined) {
          for (let table_lv_index in state.table_linked_indicators[i]) {
            if (state.table_linked_indicators[i][table_lv_index].lv3_info.key === pk) {
              state.table_linked_indicators[i].splice(table_lv_index, 1);
              break;
            }
          }
        }
      }
      for (let i of Object.keys(state.chart_linked_indicators)) {
        if (state.chart_linked_indicators[i] !== undefined) {
          for (let chart_lv_index in state.chart_linked_indicators[i]) {
            if (state.chart_linked_indicators[i][chart_lv_index].lv3_info.key === pk) {
              state.chart_linked_indicators[i].splice(chart_lv_index, 1);
              break;
            }
          }
        }
      }
      
      // payload.new_lv_info.type = payload.type;
      // payload.new_lv_info.item_id = payload.id;
      const new_lv_info = {
        "lv1_info": {
          "key": payload.lv_info[0].key,
          "name": payload.lv_info[0].name
        },
        "lv2_info": {
          "key": payload.lv_info[1].key,
          "name": payload.lv_info[1].name
        },
        "lv3_info": {
          "key": payload.new_lv_info.key
        }
      }

      if (type === 'table') {
        state.table_linked_indicators[payload.id].push(new_lv_info)
      } else if (type === 'chart') {
        state.chart_linked_indicators[payload.id].push(new_lv_info)
      }
      
      state.table_linked_indicators = {...state.table_linked_indicators}
      state.chart_linked_indicators = {...state.chart_linked_indicators}

    },


    // remove_chart_linked_indicator(state, new_linked_indicators) {
    //   index = state.chart_linked_indicators.indexOf(new_linked_indicators);
    //   if (index !== -1) {
    //     state.chart_linked_indicators.splice(index, 1);
    //   }
    // },
    // remove_table_linked_indicator(state, new_linked_indicators) {
    //   index = state.table_linked_indicators.indexOf(new_linked_indicators);
    //   if (index !== -1) {
    //     state.table_linked_indicators.splice(index, 1);
    //   }
    // },


    init_chart_field(state, field) {
      state.chart_field = field;
    },
    update_chart_data(state, new_chart_data) {
      state.chart_data = new_chart_data;
    },
    update_toc_list(state, new_toc_list) {
      state.toc_list = new_toc_list;
    },
    add_chart_data(state, new_chart_data) {
      if (new_chart_data.is_new) {
        state.chart_data.push(new_chart_data);

        // 각 지표(gri, sasb, tcfd, dc) 연결에 빈리스트 추가
        state.gri_linked_indicators[new_chart_data.chart_id] = []
        state.sasb_linked_indicators[new_chart_data.chart_id] = []
        state.tcfd_linked_indicators[new_chart_data.chart_id] = []
        state.chart_linked_indicators[new_chart_data.chart_id] = []
        
      } else {
        state.chart_data.some((item, idx) => {
          if (item.chart_id === Number(new_chart_data.chart_id)) {
            // let temp_chart_data = [...state.chart_data]
            Object.assign(state.chart_data[idx], new_chart_data);

            // state.chart_data = temp_chart_data
            // item = new_chart_data;
            // state.chart_data = {
            //   ...state.chart_data
            // }

            return;
          }
        });



        // for (let item of state.chart_data) {
        //   if (item.chart_id === new_chart_data.chart_id) {
        //     item = {...new_chart_data};
        //     break;
        //   }
        // }
        
      }
    },

    update_edited_chart_id(state, value) {
      state.edited_chart_id = value;
    }
  },
  actions: {
    async saveAddChart({ commit, rootState }, { report_code, payload, cid=null, lang="KOR" }) {
      try {
        const response = await axios.post(
            rootState.backend_host + "/update_chart_data",
            {
              payload: payload,
              report_code: report_code,
              cid: cid,
              lang: lang
            }
        )
  
        if (response.data.code) {
          commit("add_chart_data", response.data.result);
        }
        
      } catch (e) {
        window.alert(e)
      }
    },
    

    async reinitiateChartTranslate({ commit, rootState }, { chart_id, re_initiate }) {
      console.log('chart.js - reinitiateChartTranslate')

      let formData = new FormData()
      formData.append('chart_id', chart_id)
      if(re_initiate != null && re_initiate != undefined) formData.append('re_initiate', re_initiate ) 

      try {
        const response = await axios.post(
            rootState.backend_host + "/translate_chart",
            formData
        )
  
        if (response.data.code) {
          commit("add_chart_data", response.data.result);
        }
        
      } catch (e) {
        window.alert(e)
      }
    },

    async getChartList({ commit, rootState }, { report_code, is_editing }) {
      try {
        const response = await axios.get(
          `${rootState.backend_host}/get_chart_list_by_report_code?report_code=${report_code}&is_editing=${is_editing}`
        );

        if (response.data.code) {
          commit("update_chart_data", response.data.result.items);
          commit("init_chart_field", response.data.result.fields);
          commit("init_linked_indicator", response.data.result.linked_indicators);

          

        } else {
          window.alert(response.data.msg);
        }
      } catch (error) {
        window.alert(error);
      }
    },

    async getDCToC({ commit, rootState }, { report_code }) {
      try {
        const response = await axios.get(
          rootState.backend_host + "/getTocInfo?rc=" + report_code
        );

        if (response.data.code) {
          commit("update_toc_list", response.data.result.contents);
        } else {
          window.alert(
            "목차정보를 불러오는데 실패했습니다. 다시 시도해주세요."
          );
        }
      } catch (error) {
          window.alert("목차정보를 불러오는데 실패했습니다. 다시 시도해주세요.");
      }
    },


    async updateDCToC({ commit, rootState }, { report_code, contents }) {
      try {
        const response = await axios.post(
          rootState.backend_host + "/update_toc",
          {
            report_code: report_code,
            contents: contents
          }
        );

        if (response.data.code) {
          commit("update_toc_list", contents);
          commit("init_linked_indicator", response.data.linked_indicator);

        } else {
          console.log(response.data?.msg)
          window.alert(
            "저장에 실패했습니다. 다시 시도해주세요."
          );
        }
      } catch (error) {
          console.log(error)
          window.alert("저장에 실패했습니다. 다시 시도해주세요.");
      }
    },



    async updateDCIndicator({ commit, rootState }, { report_code, type, id, payload }) {
      try {
        // updateDCIndicator
        const response = await axios.post(
          rootState.backend_host + "/updateDCIndicator",
          {
            "report_code": report_code,
            "type": type,
            "cid": id,
            "payload": payload
          }
        );

        if (response.data.code) {
          
          commit("update_toc_list", response.data.result);
          commit('update_linked_indicator', {
            'id': id,
            'type': type,
            'lv_info': payload,
            "new_lv_info": response.data.new_lv_info
          })

          

          
        } else {
          window.alert(response.data.msg)
        }


      } catch (error) {
        window.alert(error)
      }
    },


    async update_delete_chart({ commit, rootState }, { report_code, chart_id, linked_indicators }) {
      try {
        const response = await axios.post(
          rootState.backend_host + "/delete_chart",
          {
            "report_code": report_code,
            "chart_id": chart_id,
            "linked_indicators": linked_indicators
          }
        );

        console.log("delete chart")

        if (response.data.code) {
          
          
          // 차트가 제거되고 나면 toc도 업데이트 되어야 함.
            // update toc info
  
          // 해당 차트를 참조하는 gri, sasb, tcfd지표에도 변화가 있어야 함. 
            // update gri linked, sasb linked, tcfd linked, chart linked..., 
            
          commit("update_toc_list", response.data.toc_info);
          commit("delete_linked_indicators", chart_id);

          commit('report_contents/update_delete_chart', chart_id, {root: true});

          // chart_data (리스트)에서 해당 chart_id를 제거해야 함
          
          
          
          
        } else {
          console.log(response.data.msg)
        }

        
      } catch (error) {
        console.log(error)
      }


    },
    async updateChartIndicator({ commit, rootState }, { report_code, type, editing_code, payload }) {
      try {
        // updateDCIndicator
        const response = await axios.post(
          rootState.backend_host + "/updateChartIndicator",
          {
            "report_code": report_code,
            "type": type,
            "editing_code": editing_code,
            "payload": payload
          }
        );

        if (response.data.code) {
          commit("update_chart_indicator", {
            "type": type,
            "editing_code": editing_code,
            "payload": payload
          })
          commit('report_contents/update_chart_relation', {
            [editing_code]: payload
          }, { root: true }) 

          // commit("update_toc_list", response.data.result);
          // commit('update_linked_indicator', {
          //   'id': id,
          //   'type': type,
          //   'lv_info': payload
          // })

        } else {
          window.alert(response.data.msg)
        }


      } catch (error) {
        window.alert(error)
      }
    }



  },
};

export default charts;

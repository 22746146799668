<template>
<div>
    <div class="py-2">
        <p class="fw-400 f-95 mb-2">문서 영문명 설정</p>
        <b-form-input v-model="local_file.title_e" placeholder="문서 영문명" class="mb-4"></b-form-input>
        <p class="fw-400 f-95 mb-2">파일 업로드 (<fa icon="info-circle" class="mr-1"></fa> 문서의 영문본이 있을 경우 새로 첨부해 주세요.)</p>
        <div v-if="document_id!=''" class="f-95 teal7 mb-2">현재 업로드된 파일 : <a :href="$store.state.static_host+local_file.path_e" target="_blank">{{local_file.file_name_e}}</a> </div>
        <b-form-file v-model="local_file.file" placeholder="여기로 끌어오기 또는 파일찾기" class="mb-2"></b-form-file>
        <p class="f-85 mb-5 gray-600"> <fa icon="info-circle" class="mr-1"/> 크기가 20MB 이상인 파일은 업로드할 수 없습니다.</p>
    </div>
    <p class="mb-3 f-85 teal"> <fa icon="info-circle"/> 문서는 초기화 시 영문명만 AI 자동 번역본으로 초기화되며, 업로드된 문서는 바뀌지 않습니다. </p>
    <b-button variant="teal" class="mr-3 mb-3" @click="handleInitiate" :disabled="initiating==true"> {{initiating==true? '초기화 중': '번역 초기화'}} <fa icon="redo" :class="initiating==true?'spinning-icon':''"/> </b-button>
    <b-button variant="red" class="mr-3 mb-3" @click="handleSave()" :disabled="is_saving==true||initiating==true">  {{is_saving==true? '저장 중' : '저장하기'}} <b-spinner small v-if="is_saving==true"></b-spinner></b-button>
    <b-button @click.once="handleCancle" class="mb-3"> 취소 </b-button>
    
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            local_file : {},
            is_saving: false,
            initiating: false
        }
    },
    props:{
        document_id : { default: '' }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapGetters('documents', ['doc_list'])
    },
    methods:{
        ...mapActions('documents', ['saveDocumentEm', 'reInitiateDocument']),
        async handleSave(){
            if (this.checkForm()==true ) {
                await this.saveDocumentEm({
                    document_id: this.local_file.document_id,
                    title: this.local_file.title_e,
                    type: this.local_file.type,
                    file: this.local_file.file
                })
                this.$EventBus.$emit('make-toast', '문서 영문 저장', `${this.local_file.title_e} 영문 버전이 저장되었습니다.`)
                this.handleCancle()
            }
        },
        handleCancle(){
            this.initiateEdit()
            this.$emit('cancle-edit')
        },
        async handleInitiate(){
            this.initiating = true
            await this.reInitiateDocument({
                document_id: this.document_id,
                re_initiate: true
            })
            let idx = this.doc_list.findIndex(x => x.document_id == this.document_id)
            if(idx>-1){
                this.local_file = {...this.doc_list[idx]}
                if(this.doc_list[idx].title_em!=''&&this.doc_list[idx].title_em!=null){
                    this.local_file.title_e = this.doc_list[idx].title_em
                }else this.local_file.title_e = this.doc_list[idx].title_ea
            }
            this.initiating = false
            this.$EventBus.$emit('make-toast', '문서명 번역 초기화', `영문 번역이 AI 자동번역 상태로 초기화되었습니다.`)
        },
        initiateEdit(){ // 기본 초기화
            if (this.document_id!=''){
                let idx = this.doc_list.findIndex(x => x.document_id == this.document_id)
                if(idx>-1){
                    this.local_file = {...this.doc_list[idx]}
                    if(this.doc_list[idx].title_em!=''&&this.doc_list[idx].title_em!=null){
                        this.local_file.title_e = this.doc_list[idx].title_em
                    }else this.local_file.title_e = this.doc_list[idx].title_ea
                }
            }
        },
        checkForm() {
            if ( this.local_file.title_e == null || this.local_file.title_e == undefined || this.local_file.title_e == '' || this.local_file.title_e == ' '){
                alert ('문서 영문명을 입력해 주세요.') 
                return false
            }
            let idx = this.doc_list.findIndex( doc => doc.title_em == this.local_file.title_e ) // 동일한 title이 있는지 확인
            let doc_idx = this.doc_list.findIndex( doc_i => doc_i.document_id == this.document_id ) // document_id가 동일한지 확인
            if ( idx > -1 && idx != doc_idx ){
                alert ('중복되는 문서 영문명이 있습니다. 다른 이름으로 변경해 주세요.') 
                return false 
            } else {
                return true
            }
        }
    },
    created(){
        this.initiateEdit()
        // console.log(this.doc_list)
    }

}
</script>

<style>

</style>
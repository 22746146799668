//"선택"한 보고서가 가지고 있는 데이터테이블 리스트와, 
//각 데이터테이블의 내용 해시

const data_tables = {
    namespaced: true, 
    state: {
        table_list:[
            // {
            //     "table_id": 2,
            //     "report_code": "r11",
            //     "title": "table title",
            //     "title_ea": "",
            //     "title_em": "",
            //     "description": "table description",
            //     "description_ea": "",
            //     "description_em": "",
            //     "header": [],
            //     "header_ea": [],
            //     "header_em": [],
            //     "contents": [],
            //     "contents_ea": [],
            //     "contents_em": [],
            //     "footnote": "wow this foot note",
            //     "footnote_ea": "wow this foot note",
            //     "footnote_em": "wow this foot note",
            //     "options": {},
            //     "time_stamp": "2020-08-13 17:11:29",
            //     "time_stamp_e": "2020-08-13 17:11:29",
            //     "used_codes": [
            //         "404-1"
            //     ]
            // }
        ],
        table_list_loaded: false
    },
    mutations: {
        update_table_list(state, new_table_list){
            state.table_list = new_table_list
        },
        add_or_update_table(state, new_table_obj ){
            let temp_table_list = [ ...state.table_list ]
            let idx = temp_table_list.findIndex(item => item.table_id == new_table_obj.table_id)
            if(idx > -1){
                let temp_table_obj = { ...new_table_obj }
                temp_table_obj.used_codes = temp_table_list[idx].used_codes
                temp_table_list.splice(idx, 1, temp_table_obj)
            }
            else{
                new_table_obj.used_codes = []
                temp_table_list.push(new_table_obj)
            }
            // console.log(temp_table_list);
            state.table_list = temp_table_list            
        },
        update_data_table_e(state, new_translated_obj){
            let temp_table_list = [ ...state.table_list ]
            let idx = temp_table_list.findIndex(item => item.table_id == new_translated_obj.data_table_id)
            if(idx > -1){
                temp_table_list[idx].title_em = new_translated_obj.title_em,
                temp_table_list[idx].title_ea = new_translated_obj.title_ea,
                temp_table_list[idx].description_em = new_translated_obj.description_em,
                temp_table_list[idx].description_ea = new_translated_obj.description_ea,
                temp_table_list[idx].header_em = new_translated_obj.header_em,
                temp_table_list[idx].header_ea = new_translated_obj.header_ea,
                temp_table_list[idx].contents_em = new_translated_obj.contents_em,
                temp_table_list[idx].contents_ea = new_translated_obj.contents_ea,
                temp_table_list[idx].footnote_em = new_translated_obj.footnote_em,
                temp_table_list[idx].footnote_ea = new_translated_obj.footnote_ea
            }
            state.table_list = temp_table_list
        },
        update_data_table_relation(state, gri_codes_by_table_id){
            let temp_table_list = [ ...state.table_list ]
            temp_table_list.forEach( table => {
                if (gri_codes_by_table_id.hasOwnProperty(table.table_id)) {
                    table['used_codes'] = gri_codes_by_table_id[table.table_id]

                    // if( framework.includes("GRI")){
                    //     table['used_codes'] = gri_codes_by_table_id[table.table_id]
                    // } else if (framework.includes("SASB")) {
                    //     table['used_codes_sasb'] = gri_codes_by_table_id[table.table_id]
                    // } else if (framework.includes("TCFD")) {
                    //     table['used_codes_tcfd'] = gri_codes_by_table_id[table.table_id]
                    // }
                }
            })
            //State 인식
            state.table_list = temp_table_list
        },
        update_delete_data_table(state, deleted_table_id){
            let temp_table_list = [ ...state.table_list ]
            let idx = temp_table_list.findIndex(x => x.table_id == deleted_table_id)
            if (idx > -1){
                temp_table_list.splice(idx, 1)
            }
            state.table_list = temp_table_list
        },
        update_table_list_loaded(state, flag){
            state.table_list_loaded = flag
        }
    },
    getters: {
        hashed_data_tables(state){
            let temp_table_list = [...state.table_list]
            let temp_table_obj = {}
            for (let i=0; i<temp_table_list.length; i++){
                let table_id = temp_table_list[i].table_id
                let table_item = {...temp_table_list[i]}

                if(temp_table_list[i].title_em!=''&&temp_table_list[i].title_em!=null){
                    table_item['title_e'] = temp_table_list[i].title_em
                }else table_item['title_e'] = temp_table_list[i].title_ea
                if(temp_table_list[i].description_em!=''&&temp_table_list[i].description_em!=null){
                    table_item['description_e'] = temp_table_list[i].description_em
                }else table_item['description_e'] = temp_table_list[i].description_ea
                if(temp_table_list[i].header_em!=[]&&temp_table_list[i].header_em!=['']&&temp_table_list[i].header_em!=null){
                    table_item['header_e'] = temp_table_list[i].header_em
                }else table_item['header_e'] = temp_table_list[i].header_ea
                if(temp_table_list[i].contents_em!=[]&&temp_table_list[i].contents_em!=['']&&temp_table_list[i].contents_em!=null){
                    table_item['contents_e'] = temp_table_list[i].contents_em
                }else table_item['contents_e'] = temp_table_list[i].contents_ea
                if(temp_table_list[i].footnote_em!=''&&temp_table_list[i].footnote_em!=null){
                    table_item['footnote_e'] = temp_table_list[i].footnote_em
                }else table_item['footnote_e'] = temp_table_list[i].footnote_ea

                temp_table_obj[table_id] = table_item
            }
            return temp_table_obj
        },
        table_100_list(state){
            let temp_list = []
            state.table_list.filter( item => { 
                for (let i=0; i<item.used_codes.length; i++){
                    if (is_100(item.used_codes[i]) == true ){
                        temp_list.push(item)
                    }
                }
            })
            return temp_list
        },
        table_200_list(state){
            let temp_list = []
            state.table_list.filter( item => { 
                for (let i=0; i<item.used_codes.length; i++){
                    if (is_200(item.used_codes[i]) == true ){
                        temp_list.push(item)
                    }
                }
            })
            return temp_list
        },
        table_300_list(state){
            let temp_list = []
            state.table_list.filter( item => { 
                for (let i=0; i<item.used_codes.length; i++){
                    if (is_300(item.used_codes[i]) == true ){
                        temp_list.push(item)
                    }
                }
            })
            return temp_list
        },
        table_400_list(state){
            let temp_list = []
            state.table_list.filter( item => { 
                for (let i=0; i<item.used_codes.length; i++){
                    if (is_400(item.used_codes[i]) == true ){
                        temp_list.push(item)
                    }
                }
            })
            return temp_list
        },
        table_used_codes(state){
            let temp_list = []
            let table_arr = [...state.table_list]
            for (let i=0; i<table_arr.length; i++){
                table_arr[i].used_codes.forEach(x =>{
                    temp_list.push(x)
                })
            }
            return [...new Set(temp_list)]
        },
        table_list_e(state){
            let temp_table_list = [...state.table_list]
            for (let i=0; i<temp_table_list.length; i++){
                let table_item = {...temp_table_list[i]}
                if(temp_table_list[i].title_em!=''&&temp_table_list[i].title_em!=null){
                    table_item['title_e'] = temp_table_list[i].title_em
                }else table_item['title_e'] = temp_table_list[i].title_ea
                if(temp_table_list[i].description_em!=''&&temp_table_list[i].description_em!=null){
                    table_item['description_e'] = temp_table_list[i].description_em
                }else table_item['description_e'] = temp_table_list[i].description_ea
                if(temp_table_list[i].header_em!=[]&&temp_table_list[i].header_em!=['']&&temp_table_list[i].header_em!=null){
                    table_item['header_e'] = temp_table_list[i].header_em
                }else table_item['header_e'] = temp_table_list[i].header_ea
                if(temp_table_list[i].contents_em!=[]&&temp_table_list[i].contents_em!=['']&&temp_table_list[i].contents_em!=null){
                    table_item['contents_e'] = temp_table_list[i].contents_em
                }else table_item['contents_e'] = temp_table_list[i].contents_ea
                if(temp_table_list[i].footnote_em!=''&&temp_table_list[i].footnote_em!=null){
                    table_item['footnote_e'] = temp_table_list[i].footnote_em
                }else table_item['footnote_e'] = temp_table_list[i].footnote_ea

                temp_table_list[i] = table_item
            }
            return temp_table_list          
        }
    },
    actions: {
        readDataTableList({ commit, rootState}, { report_code }){
            const path = rootState.backend_host + '/read_data_table_list'

            return axios.get(path, { params: {
                report_code: report_code
            }})
            .then( response => {
                commit('update_table_list', response.data)
                commit('update_table_list_loaded', true)
            })
        },
        saveDataTable({commit, rootState}, { report_code, table_id, title, description, header, contents, footnote, options }){
            const path = rootState.backend_host + '/save_data_table'

            let formData = new FormData()
            if(table_id != null && table_id != undefined && table_id != ''){
                formData.append('table_id', table_id) //신규일 경우, table_id를 백엔드로 보내지 않아야 신규 생성하고 id를 부여받음
            }
            if(options == null || options ==undefined){
                formData.append('options', JSON.stringify({})) // 2020년 11월 현재 options 안쓰므로 빈 객체 보냄
            }
            formData.append('report_code', report_code)
            formData.append('title', title)
            formData.append('description', description)
            formData.append('header', JSON.stringify(header)) //JSON
            formData.append('contents', JSON.stringify(contents)) //JSON
            formData.append('footnote', footnote) 
            // formData.append('options', JSON.stringify(options)) //JSON

            return axios.post(path, formData)
            .then( response => {
                let new_table_obj = {
                    table_id: response.data.table_id,
                    report_code: response.data.report_code,
                    title: response.data.title,
                    description: response.data.description,
                    header: response.data.header,
                    contents: response.data.contents,
                    footnote: response.data.footnote,
                    options: response.data.options,
                    time_stamp: response.data.time_stamp,
                    // used_codes: 
                }
                // console.log(new_table_obj);
                commit('add_or_update_table', new_table_obj )
            })
        },
        updateDataTableRelation({ commit, rootState }, { report_code, gri_code, link_payload, framework }) {
            console.log("updateDataTableRelation")
            const path = rootState.backend_host + '/link_disclosure_data_tables'

            let formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('gri_code', gri_code)
            formData.append('framework', framework)
            formData.append('link_payload', JSON.stringify(link_payload)) //{ "table_id": true } 형식의 object

            return axios.post(path, formData)
                .then(response => {
                let table_ids_by_gri_code = response.data.table_ids_by_gri_code
                //{ gri_code: [ data_table_ids ] }
                let gri_codes_by_table_id = response.data.gri_codes_by_table_id
                //{ 'data_table_id': [ gri_codes ]}
                commit('update_data_table_relation', gri_codes_by_table_id)
                commit('report_contents/update_data_table_relation', table_ids_by_gri_code, {root: true }) 
            })
        },
        deleteDataTable({commit, rootState},  table_id){
            const path = rootState.backend_host + '/delete_data_table'
            
            let formData = new FormData()
            formData.append('table_id', table_id)

            return axios.post(path, formData)
            .then( response =>{
                let deleted_table_id = response.data.table_id
                commit('update_delete_data_table', deleted_table_id)
                commit('report_contents/update_delete_data_table', deleted_table_id, {root: true})
            }) 
        },
        saveDataTableEm({commit, rootState}, { table_id, title, description, header, contents, footnote } ){
            const path = rootState.backend_host + '/save_data_table_em'

            let formData = new FormData()
            formData.append('table_id', table_id)
            if(title != null && title != undefined) formData.append('title_em', title)
            if(description != null && description != undefined) formData.append('description_em', description)
            if(header != null && header != undefined) formData.append('header_em', JSON.stringify(header)) //JSON
            if(contents != null && contents != undefined) formData.append('contents_em', JSON.stringify(contents)) //JSON
            if(footnote != null && footnote != undefined) formData.append('footnote_em', footnote) 

            return axios.post(path, formData)
            .then( response => {
                commit('update_data_table_e', response.data)
            })
        },
        reInitiateDataTable({commit, rootState}, { table_id, re_initiate }){
            const path = rootState.backend_host + '/translate_data_table'

            let formData = new FormData()
            formData.append('table_id', table_id)
            if(re_initiate != null && re_initiate != undefined) formData.append('re_initiate', re_initiate ) //re_initiate 를 true로 보내면, direct_report_em을 삭제함
            return axios.post(path, formData )
            .then( response => {
                // console.log(response.data);
                commit('update_data_table_e', response.data)
            })
        },
        
    }
}
function is_100(element){
    if(element[0] =='1'){
        return true
    }
}
function is_200(element){
    if(element[0] =='2'){
        return true
    }
}
function is_300(element){
    if(element[0] =='3'){
        return true
    }
}
function is_400(element){
    if(element[0] =='4'){
        return true
    }
}

export default data_tables
<template lang="">
  <div class="card-body" id="regularPaymentForm">
    <!-- <b-modal id="need-help" hide-footer size="" title="문의하기" scrollable>
      <need-help></need-help>
    </b-modal> -->
    <b-overlay :show="loadingShow" no-wrap></b-overlay>
    <b-card class="shadow" id="regular-price-table-popup">
      <caption>
        <!-- <p>
          * 기업이 별다른 도움없이 스스로 GRI 보고서를 작성하는 경우,
          컨설팅(선택사항), 기술지원(선택사항)없이 '공개하기'로만
          진행가능합니다. <br />
          ** GRI작성자문 컨설팅은 기본 Tool사용교육을 포함합니다. (주요내용은
          다음페이지 참조) <br />
          *** API는 서버에서 JSON형태로 저장된 데이터/값 만을 실시간 전송합니다.
          이를 토대로 웹페이지 제작 전문업체에서 원하는 형태의 디자인으로 직접
          제작 가능합니다. <br />

          * 1월 1일에 다시 보고서 소유 권한이 부여됩니다.
        </p> -->
      </caption>
      <template #header>
          <h6 class="mb-0 fw-600">보고서 생성/편집을 위한 서비스 플랜</h6>
      </template>

      <b-row class="mb-4 pl-lg-4">
          <b-col cols="2">
            <h6 class="fw-600 text-left">서비스 플랜</h6>
          </b-col>

          <b-col cols="5" class="pr-lg-5">
            <h5 class="fw-600 bg-teal8 text-white text-center py-1">Standard <span class="f-100">(스탠다드)</span></h5>
            <div class="bgray-100 p-3">
              <!-- <b-list-group-item
                  href="#"
                  class="flex-column align-items-start productBtn mb-3 shadow-sm"
                  @click="showPayModal"
                  id="standard_month"
                  :disabled='subscriptingLicense.includes("standard_month")'
                >
                  <div
                    class="d-flex w-100 justify-content-between"
                    v-if="
                      discountCodeInfo &&
                        discountCodeInfo.subscription_id === 'standard_month'
                    "
                  >
                    <h6 class="mb-1">
                      월간
                      <b-badge variant="danger"
                        ><b-icon
                          icon="exclamation-circle"
                          scale="1"
                          variant="warning"
                        ></b-icon
                        >쿠폰 할인가</b-badge
                      >
                    </h6>
                    <div>
                      <p class="mb-0">
                        <del
                          >{{
                            priceTable !== null
                              ? this.formatCurrency(
                                  priceTable["standard_month"]["service_fee"]
                                )
                              : ""
                          }}원</del
                        >
                      </p>
                      <p class="mb-0 red fw-400">
                        {{ this.formatCurrency(discountCodeInfo.discount_price) }}원 / 30일
                      </p>
                    </div>
                  </div>

                  <div class="d-flex w-100 justify-content-between" v-else>
                    <h6 class="mb-1">월간</h6>
                    <p class="mb-0 fw-400">
                      {{
                        priceTable !== null
                          ? this.formatCurrency(
                              priceTable["standard_month"]["service_fee"]
                            )
                          : ""
                      }}원 / 30일</SMALL>
                    </p>
                  </div>
              </b-list-group-item> -->
              <b-list-group-item
                  href="#"
                  class="flex-column align-items-start productBtn shadow-sm"
                  @click="showPayModal"
                  id="standard_year"
                  :disabled='subscriptingLicense.includes("standard_year")'

                >
                  <div
                    class="d-flex w-100 justify-content-between"
                    v-if="
                      discountCodeInfo &&
                        discountCodeInfo.subscription_id === 'standard_year'
                    "
                  >
                    <h6 class="mb-1">
                      연간
                      <b-badge variant="danger"
                        ><b-icon
                          icon="exclamation-circle"
                          scale="1"
                          variant="warning"
                        ></b-icon
                        >쿠폰 할인가</b-badge
                      >
                    </h6>
                    <div>
                      <p class="mb-0 text-right">
                        <del>{{
                          priceTable !== null
                            ? this.formatCurrency(
                                priceTable["standard_year"]["service_fee"]
                              )
                            : ""
                        }}</del>
                      </p>
                      <p class="mb-0 red fw-400">
                        {{ this.formatCurrency(discountCodeInfo.discount_price) }}원 / 12개월
                      </p>
                    </div>
                  </div>

                  <div class="d-flex w-100 justify-content-between" v-else>
                    <h6 class="mb-1">
                      연간 
                    </h6>
                    <div>
                      <p class="mb-0 text-right"><del>2,400,000원</del> / 12개월</p>
                      <p class="mb-0 red fw-400 text-right">
                        <b-badge variant="danger" class="mr-1">10% 할인</b-badge>
                        {{
                          priceTable !== null
                            ? this.formatCurrency(
                                priceTable["standard_year"]["service_fee"]
                              )
                            : ""
                        }}원 / 12개월
                      </p>
                    </div>
                  </div>
              </b-list-group-item>

              <p class="fw-600 mb-2 mt-4">편집권한 : 1개* 보고서 편집 가능</p>
              <p class="mb-0 f-90">* 유료 서비스 이용기간 중 1개 보고서 편집 가능</p>
            </div>
          </b-col>

          <b-col cols="5" class="pr-lg-5">
            <h5 class="fw-600 bg-tealgray700 text-white text-center py-1">Premium <span class="f-100">(프리미엄)</span></h5>
            <div class="bgray-100 p-3">
              <!-- <b-list-group-item
                  href="#"
                  class="flex-column align-items-start productBtn mb-3 shadow-sm"
                  @click="showPayModal"
                  id="premium_month"
                  :disabled='subscriptingLicense.includes("premium_month")'
                >
                  <div
                    class="d-flex w-100 justify-content-between"
                    v-if="
                      discountCodeInfo &&
                        discountCodeInfo.subscription_id === 'premium_month'
                    "
                  >
                    <h6 class="mb-1">
                      월간
                      <b-badge variant="danger"
                        ><b-icon
                          icon="exclamation-circle"
                          scale="1"
                          variant="warning"
                        ></b-icon
                        >쿠폰 할인가</b-badge
                      >
                    </h6>
                    <div>
                      <p class="mb-0 fw-400">
                        <del
                          >{{
                            priceTable !== null
                              ? this.formatCurrency(
                                  priceTable["premium_month"]["service_fee"]
                                )
                              : ""
                          }}원</del
                        >
                        / 30일
                      </p>
                      <p class="mb-0 red fw-400">
                        {{ this.formatCurrency(discountCodeInfo.discount_price) }}원 / 30일
                      </p>
                    </div>
                  </div>

                  <div class="d-flex w-100 justify-content-between" v-else>
                    <h6 class="mb-1">월간</h6>
                    <p class="mb-0 fw-400">
                      {{
                        priceTable !== null
                          ? this.formatCurrency(
                              priceTable["premium_month"]["service_fee"]
                            )
                          : ""
                      }}원 / 30일
                    </p>
                  </div>
              </b-list-group-item> -->
              <b-list-group-item
                  href="#"
                  class="flex-column align-items-start productBtn shadow-sm"
                  @click="showPayModal"
                  id="premium_year"
                  :disabled='subscriptingLicense.includes("premium_year")'

                >
                  <div
                    class="d-flex w-100 justify-content-between"
                    v-if="
                      discountCodeInfo &&
                        discountCodeInfo.subscription_id === 'premium_year'
                    "
                  >
                    <h6 class="mb-1">
                      연간
                      <b-badge variant="danger"
                        ><b-icon
                          icon="exclamation-circle"
                          scale="1"
                          variant="warning"
                        ></b-icon
                        >쿠폰 할인가</b-badge
                      >
                    </h6>
                    <div>
                      <p class="mb-0 text-right">
                        <del>{{
                          priceTable !== null
                            ? this.formatCurrency(
                                priceTable["premium_year"]["service_fee"]
                              )
                            : ""
                        }}</del>
                      </p>
                      <p class="mb-0 red text-right">
                        {{ this.formatCurrency(discountCodeInfo.discount_price) }}원 / 12개월
                      </p>
                    </div>
                  </div>

                  <div class="d-flex w-100 justify-content-between" v-else>
                    <h6 class="mb-1">
                      연간
                    </h6>
                    <div>
                      <p class="mb-0 text-right"><del>6,480,000원</del> / 12개월</p>
                      <p class="mb-0 red fw-400 text-right">
                        <b-badge variant="danger" class="mr-1">10% 추가할인</b-badge>
                        {{
                          priceTable !== null
                            ? this.formatCurrency(
                                priceTable["premium_year"]["service_fee"]
                              )
                            : ""
                        }}원 / 12개월
                      </p>
                    </div>
                  </div>
              </b-list-group-item>

              <p class="fw-600 mb-2 mt-4">편집권한 : 3개* 보고서 편집 가능</p>
              <p class="mb-0 f-90">* 유료 서비스 이용기간 중 3개 보고서 편집 가능</p>
              <p class="mb-0 ml-2 f-90"> 3개 이상 이용 원하실 경우 <a href="#" v-b-modal.need-help>문의 주세요.</a> </p>
            </div>
          </b-col>
        </b-row>


        <b-row class=" pl-lg-4">
          <b-col cols="2">
            <div class="payment-table-end-line mb-3"></div>
            <h6 class="fw-600 text-left">상품 설명</h6>
          </b-col>
          <b-col cols="10" class="pr-lg-5">
            <div class="payment-table-end-line mb-3"></div>
            <ul class="pl-3">
                <li class="mb-2">
                  매년 1월 1일 연도가 바뀌면 새 보고서 생성 가능 개수가 초기화
                  됩니다. (다음해로 누적 이월되지 않음)<br />
                  <small class="text-muted">
                    (ex. 올해 9월 <b>스탠다드</b> 결제 후 새 보고서를 생성한 경우, 다음
                    해 1월 1일에 <b>1개</b>의 새 보고서 생성 가능 권한이 새롭게 부여됩니다.)
                  </small>
                  <br />
                  <small class="text-muted">
                    (ex. 올해 9월 <b>프리미엄</b> 결제 후 연말까지 1~3개의 보고서를 생성한 경우,
                    다음 해 1월 1일에 <b>3개</b>의 새 보고서 생성 가능 권한이 부여됩니다.)
                  </small>
                </li>
                <li class="mb-2">
                  보고서 생성 및 공개를 위한 플랫폼 사용료 외 검증 등 별도 상품은
                  <a href="#" v-b-modal.need-help>문의하기</a>를 통해
                  문의해주세요.
                </li>
                <li>
                  모든 이용 요금은 부가세(VAT) 별도 입니다.
                </li>
              </ul>
          </b-col>
        </b-row>

        <b-row class="mb-3 pl-lg-4">
          <b-col cols="2">
            <div class="payment-table-end-line mb-3"></div>
            <h6 class="fw-600 text-left">할인 코드 입력</h6>
          </b-col>
          <b-col cols="10" class="pr-lg-5">
            <div class="payment-table-end-line mb-3"></div>
            <b-input-group>
              <template #prepend>
                <b-input-group-text>할인코드</b-input-group-text>
              </template>
              <b-form-input v-model="discountCode"></b-form-input>

              <b-input-group-append>
                <b-button class="light-primary" @click="applyDiscountCode"
                  >할인적용</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row class="pl-lg-4" v-if="nextPaymentDate">
          <b-col cols="2">
            <div class="payment-table-end-line mb-3"></div>
            <h6 class="fw-600 text-left">다음 결제 예정일</h6>
          </b-col>
          <b-col cols="10" class="pr-lg-5">
            <div class="payment-table-end-line mb-3"></div>
            오늘 결제 하신 다면 다음 결제 예정일은
              <b>{{ nextPaymentDate }}</b> 입니다.
          </b-col>
        </b-row>
    </b-card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { preparePayment } from "@/components/payment/payment";
// import requestWithQESG from "@/components/customAxios";

export default {
  mounted() {
    this.fetchRegularServicePriceTable();
    this.fetchUid();
    this.fetchSubscriptingLicense();
  },
  computed: {
    ...mapState(["e_mail", "user_id", "is_confirmed"]),
  },
  components: {},
  data() {
    return {
      nextPaymentDate:null,
      loadingShow: true,
      priceTable: null,
      muid: null,
      priceSum: 0,
      discountCode: "",
      discountCodeInfo: null,
      selectedService: null,
      subscriptingLicense: [],
      is_subscripting: [true * 4],
    };
  },
  props: {
    needPaymentMethod: {
      type: Boolean,
      default: true,
    },
  },
  watch: {},
  methods: {
    ...mapActions(["updateLicenseInfo"]),
    fetchSubscriptingLicense() {
      // axios
      //   .post(
      //     this.$store.state.backend_host +
      //       "/payment/retrieveSubscriptingLicense",
      //     {
      //       e_mail: this.e_mail,
      //       user_id: this.user_id,
      //     }
      //   )
      //   .then((response) => {
      //     this.subscriptingLicense = response.data;

      //     console.log("여기서 확인"); // is_subscripting
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // requestWithQESG("POST", "/payment/retrieveSubscriptingLicense", {
      //   e_mail: this.e_mail,
      //   user_id: this.user_id,
      // })
      //   .then((response) => {
      //     this.subscriptingLicense = response.data;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      axios.post(this.$store.state.backend_host + "/payment/retrieveSubscriptingLicense", {
        e_mail: this.e_mail,
        user_id: this.user_id,
      })
        .then((response) => {
          this.subscriptingLicense = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

    },
    fetchUid() {
      axios
        .get(
          this.$store.state.backend_host +
            "/payment/init_id?request_type=report"
        )
        .then((response) => {
          this.muid = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formatCurrency: function(amount) {
      // TODO: 이런건 util.js같이 utility 함수파일을 별도로 만들어서 쓸 수 있게 하고싶은데..
      const formatter = new Intl.NumberFormat("ko-KR");
      return formatter.format(amount);
    },
    fetchRegularServicePriceTable() {
      axios
        .get(
          this.$store.state.backend_host +
            "/payment/get_subscription_info_table"
        )
        .then((response) => {
          this.loadingShow = false;
          this.priceTable = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showPayModal(event) {
      if (
        document.querySelector(".productSelectedBtn") != null &&
        event.target.closest(".productBtn") !=
          document.querySelector(".productSelectedBtn")
      ) {
        document
          .querySelector(".productSelectedBtn")
          .classList.remove("productSelectedBtn");
      }
      const targetButton = event.target.closest(".productBtn");
      targetButton.classList.add("productSelectedBtn");
      this.selectedService = event.target.closest(".productBtn").id;

      let nextDays = 30
      if (this.selectedService.split("_")[1] === "year") {
        nextDays = 365
      }
      const today = new Date();
      today.setDate(today.getDate() + nextDays);
      this.nextPaymentDate = today.toISOString().slice(0, 10);



    },
    applyDiscountCode() {
      // discount쿠폰 적용해서 가격테이블 업데이트
      this.loadingShow = true;

      axios
        .get(
          this.$store.state.backend_host +
            `/payment/getSubscriptionCouponInfo?code=${this.discountCode}`
        )
        .then((response) => {
          this.loadingShow = false;
          if (response.data.code) {
            this.discountCodeInfo = response.data.info;
          }

          window.alert(response.data.message);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style>
#regular-price-table-popup th,
#regular-price-table-popup td {
  vertical-align: middle;
  height: 10px;
}



/* #regular-price-table-popup .pay-ticket{
  border-radius: 16px !important;
  padding-bottom: 0px;

} */

/* #regular-price-table-popup tbody tr:nth-child(n+1):nth-child(-n+4) td:nth-child(2) {
  padding-right: 2.7rem !important;
  
}

#regular-price-table-popup tbody tr:nth-child(n+1):nth-child(-n+4) td:nth-child(3) {
  padding-left: 2.7rem !important;
} */


/* #regular-price-table-popup tbody tr:nth-child(3){
  padding-bottom: 15px;
  border-bottom: 2px solid #d1def5;
} */


/* #regular-price-table-popup tbody tr:nth-child(-n+3) td:nth-child(-n+3) {
  border-right: 20px solid transparent;

}


#regular-price-table-popup tbody tr:nth-child(3) > td:nth-child(2){
  padding-bottom: 15px;
  border-bottom: 2px solid #d1def5;
}

#regular-price-table-popup tbody tr:nth-child(3) > td:nth-child(3){
  padding-bottom: 15px;
  border-bottom: 2px solid #d1def5;

  
} */


/* #regular-price-table-popup tbody tr th {
  1열
  padding-top: 20px;
} */

/* #regular-price-table-popup tbody tr:nth-child(4) td:nth-child(-n+3){
  서비스 제공범위 행의 스탠다드, 프리미엄 열
  padding-top: 20px;
  margin-bottom: 10px;
} */


/* #regular-price-table-popup tbody tr:nth-child(5) td:nth-child(-n+3){
  상품설명 내용 td
  padding-top: 20px;
  border-top: 2px solid #d1def5;
  border-bottom: 2px solid #d1def5;
} */

/* #regular-price-table-popup tbody > tr:nth-child(1) td:nth-child(n+2) {
  padding-left: 100px;
  padding-bottom: 0px;
} */




</style>

<!-- src/components/MyChart.vue -->

<template>
  <div>

    <b-button variant="teal" v-b-toggle.collapse-1 class="mr-2">
      <b-icon-bar-chart-fill></b-icon-bar-chart-fill>
      샘플 보기
    </b-button>
    <b-button  variant="primary" @click="resetChart" class="mr-2">
      <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
      리셋하기
    </b-button>
    <b-button  variant="success" @click="saveChart">
      <b-icon-save></b-icon-save>
      저장하기
    </b-button>

    <b-collapse id="collapse-1" class="mt-2">
      <b-card>
        <p class="card-text">샘플을 적용해보세요</p>
        <b-button
          v-for="(chart_data, idx) in singleChart"
          :key="idx"
          size="sm" 
          class="mr-2 mb-2"
          @click="applySample(idx)"

        >
          {{ chart_data.label }}
        </b-button>

      </b-card>
    </b-collapse>

    <canvas :style="'max-height: ' + chartData?.options?.chart_height + 'px;'" ref="myChart"></canvas>
    <small v-if="chartData && chartData.options" class="text-muted p-4">
      {{chartData.options.footnote}}
    </small>

    <b-table-simple class="mt-4">
      <b-thead>
        <b-tr>
          <b-td></b-td>
          <b-td v-for="(x_label, idx) in form.header" :key="idx">{{
            x_label
          }}</b-td>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td></b-td>
          <b-td v-for="(value, idx) in form.data_set.data" :key="idx">{{ value }}</b-td>
          <!-- <b-td v-for="(data_value, idx) in data_set.data" :key="idx">{{
            data_value
          }}</b-td> -->
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div>
      <div>
        <b-card class="mb-5">
          <b-form-group
            label-for="input-1"
            description="해당 차트의 정보를 입력해주세요"
          >
            <b-row>
              <b-col md="2">
                <label for="" class="fw-500">차트 제목</label>
              </b-col>
              <b-col md="10">
                <b-form-input
                  class="mb-2"
                  @change="renderChart"
                  v-model="form.chart_main_title"
                  placeholder="차트의 이름을 입력해주세요."
                  required
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                <label for="">차트 부제목</label>
              </b-col>
              <b-col md="10">
                <b-form-input
                  class="mb-2"
                  @change="renderChart"
                  v-model="form.chart_sub_title"
                  placeholder="차트의 이름을 입력해주세요."
                  required
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row >
              <b-col md="2">
                x축 보이기
              </b-col>

              <b-col md="10">
                <b-form-checkbox
                  v-model="form.show_x_axis"
                  switch
                  size="sm"
                  @change="renderChart"
                ></b-form-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="form.show_x_axis">
              <b-col md="2">
                <label for="">x축의 이름</label>
              </b-col>
              <b-col md="10">
                <b-form-input
                  class="mb-2"
                  @change="renderChart"
                  v-model="form.x_name"
                  placeholder="x축의 이름을 입력해주세요."
                  required
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                y축 보이기
              </b-col>

              <b-col  md="10">
                <b-form-checkbox
                  v-model="form.show_y_axis"
                  switch
                  size="sm"
                  @change="renderChart"
                ></b-form-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="form.show_y_axis">
              <b-col md="2" >
                <label for="">y축 단위명</label>
              </b-col>
              <b-col md="10">
                <b-form-input
                  class="mb-2"
                  @change="renderChart"
                  v-model="form.y_level"
                  placeholder="y축의 단위명을 입력해주세요."
                  required
                ></b-form-input>
              </b-col>
            </b-row>


            <b-row v-if="form.show_y_axis">
              <b-col md="2" >
                <label for="">y축의 이름</label>
              </b-col>
              <b-col md="10">
                <b-form-input
                  class="mb-2"
                  @change="renderChart"
                  v-model="form.y_name"
                  placeholder="y축의 이름을 입력해주세요."
                  required
                ></b-form-input>
              </b-col>
            </b-row>


            
            <b-row v-if="form.show_y_axis">
              <b-col md="2">
                <label for="">y축 최솟값</label>
              </b-col>
              <b-col md="10">
                <b-form-input
                type="number"
                class="mb-2"
                @change="renderChart"
                v-model="form.y_min"
                placeholder="y축의 최솟값을 입력해주세요."
                required
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row v-if="form.show_y_axis">
              <b-col md="2">
                <label for="">y축 최댓값</label>
              </b-col>
              <b-col md="10">
                <b-form-input
                type="number"
                class="mb-2"
                @change="renderChart"
                v-model="form.y_max"
                placeholder="y축의 최댓값을 입력해주세요."
                required
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row v-if="form.show_y_axis">
              <b-col md="2">
                <label for="">y축 step</label>
              </b-col>
              <b-col md="10">
                <b-form-input
                type="number"
                class="mb-2"
                @change="renderChart"
                v-model="form.y_stepSize"
                placeholder="y축의 step값을 입력해주세요."
                required
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row >
              <b-col md="2">
                <label for="">x축 누적</label>
              </b-col>
              <b-col md="10">
                <b-form-checkbox
                  v-model="form.x_stacked"
                  switch
                  size="sm"
                  @change="renderChart"
                ></b-form-checkbox>
              </b-col>
            </b-row>

            <b-row >
              <b-col md="2">
                <label for="">y축 누적</label>
              </b-col>
              <b-col md="10">
                <b-form-checkbox
                  v-model="form.y_stacked"
                  switch
                  size="sm"
                  @change="renderChart"
                ></b-form-checkbox>
              </b-col>
            </b-row>


          </b-form-group>
        </b-card>

        <b-form>
          <b-button @click="add_column">헤더 추가</b-button>
          <b-card-group deck>
            <b-card class="mb-5" v-for="idx in form.x_cnt + 1" :key="idx">
              <b-form-group
                label="헤더명"
                label-for="input-1"
              >
                <b-form-input
                  @change="renderChart"
                  v-model="form.header[idx - 1]"
                  :placeholder="(idx - 1).toString()"
                  required
                ></b-form-input>
              </b-form-group>
              <b-button size="sm" @click="remove_x_label(idx - 1)" v-if="form.x_cnt > 0">
                <b-icon-trash></b-icon-trash>
                제거
              </b-button>
            </b-card>
          </b-card-group>

          <b-card-group deck>
            <b-card
              class="mb-3"
              v-for="data_idx in form.data_cnt + 1"
              :key="data_idx"
            >
              <b-form-group
                class="mb-5"
                label-for="input-1"
                description="해당 데이터 그룹의 명을 입력해주세요"
              >
                <b-form-input
                  class="mb-3"
                  @change="renderChart"
                  v-model="form.data_set.label"
                  required
                ></b-form-input>
              </b-form-group>

              <b-card
                v-if="form.chart_type !== 'bar'"
                class="mb-5"
                title="원 및 포인트 스타일 지정"
              >
                <b-form-group
                  class="mb-5"
                  label-for="input-1"
                  description="line 차트의 옵션값을 입력해주세요"
                >
                  <b-row>
                    <b-col md="2">
                      <label for="">포인트 스타일</label>
                    </b-col>
                    <b-col md="10">
                      <b-form-select
                        @change="renderChart"
                        v-model="form.data_set.pointStyle"
                        :options="point_style_options"
                      ></b-form-select>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="2">
                      <label for="">포인트 크기</label>
                    </b-col>
                    <b-col md="10">
                      <b-form-input
                        class="mb-2"
                        type="number"
                        @change="renderChart"
                        v-model="form.data_set.radius"
                        placeholder="포인트의 크기를 입력해주세요."
                        required
                      ></b-form-input>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="2">
                      <label for="">라인 경직도</label>
                    </b-col>
                    <b-col md="10">
                      <b-form-input
                        class="mb-2"
                        type="number"
                        @change="renderChart"
                        v-model="form.data_set.tension"
                        placeholder="선의 경직도정도를 입력해주세요"
                        required
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>

              <b-form-group
                class="mb-5"
                label-for="input-1"
                description="차트 디자인"
              >
                <label for="">차트 두께</label>
                <b-form-input
                  type="number"
                  class="mb-3"
                  @change="renderChart"
                  v-model="form.data_set.barThickness"
                  required
                ></b-form-input>

                <label for="">차트 border 두께</label>
                <b-form-input
                  type="number"
                  class="mb-3"
                  @change="renderChart"
                  v-model="form.data_set.borderWidth"
                  required
                ></b-form-input>
              </b-form-group>



              <b-card-group deck>
              <b-card
                style="min-width: 30% !important;" class="mb-5"
                v-for="x_idx in form.x_cnt + 1"
                :key="x_idx"
              >

                <div class="ml-4" style="width: 200px">
                  <b-row>
                    <b-col md="5" >
                      {{ form.header[x_idx-1] ?  form.header[x_idx-1] : `${x_idx}번째 헤더`}}:

                    </b-col>
                    <b-col md="7" >
                      <b-form-input
                        
                        type="number"
                        class="mb-2"
                        @change="renderChart"
                        v-model="form.data_set.data[x_idx - 1]"
                        :placeholder="(data_idx - 1).toString()"
                        required
                      ></b-form-input>

                    </b-col>
                  </b-row>
                    <chrome-picker
                      v-model="form.data_set.backgroundColor[x_idx - 1]"
                      @input="handleColorChange(x_idx - 1, 'backgroundColor')"
                    />
                    차트 배경색

                    <chrome-picker
                      v-model="form.data_set.borderColor[x_idx - 1]"
                      @input="handleColorChange(x_idx - 1, 'borderColor')"
                    />
                    차트 경계색
                </div>
              </b-card>
              </b-card-group>

              <b-button
                @click="remove_data_sets(x_idx - 1)"
                v-if="form.data_cnt > 0"
              >
                <b-icon-trash></b-icon-trash>
                제거
              </b-button>
            </b-card>
          </b-card-group>
        </b-form>
        <b-card class="mt-3" header="데이터 셋">
          <pre class="m-0">{{ form }}</pre>
        </b-card>
      </div>
    </div>

    <b-form-checkbox
      v-if="form.chart_type === 'line' || form.chart_type === 'bar'"
      v-model="form.x_axis"
      switch
      size="sm"
      @change="renderChart"
    >
      <p v-if="form.x_axis">x축 중심</p>
      <p v-else>y축 중심</p>
    </b-form-checkbox>
    <b-form-checkbox
      v-model="form.show_legend"
      switch
      size="sm"
      @change="renderChart"
    >
      <p v-if="form.x_axis">범례 보이기</p>
      <p v-else>범례 보이기</p>
    </b-form-checkbox>
    <div class="mb-3">
      <label for="">범례 위치</label>
      <b-form-select
        @change="renderChart"
        v-model="form.legend_position"
        :options="legend_position_options"
      ></b-form-select>
    </div>

    <div class="mb-3">
      <label for="">차트 타입</label>
      <b-form-select
        @change="renderChart"
        v-model="form.chart_type"
        :options="chart_type_options"
      ></b-form-select>
    </div>

    <div class="mt-3">
      Selected: <strong>{{ form.chart_type }}</strong>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Chart from "chart.js/auto"; // 최신 버전의 Chart.js를 사용하려면 'chart.js/auto'를 사용합니다.
import { Chrome } from "vue-color";
import { singleChart } from './chartSample';


import { point_style_options, chart_type_options, legend_position_options } from "./chart";

export default {
  computed: {
    ...mapState("report_list", ["report_code"]),
  },
  data() {
    return {
      singleChart,
      point_style_options,
      chart_type_options,
      legend_position_options,
      form: null,
      original_form: {
        type: "multi",
        footnote: null,
        y_min: null,
        y_max: null,
        y_stepSize: null,
        x_stacked: false,
        y_stacked: false,
        x_axis: true,
        legend_position: "top",
        show_legend: true,

        x_cnt: 0,
        data_cnt: 0,
        show_x_axis: true,
        show_y_axis: true,
        chart_main_title: "",
        chart_sub_title: "",
        y_level: "",
        x_name: "",
        y_name: "",
        header: [],
        data_set: {
          type: "pie",
          label: null,
          data: [],
          fill: false,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: ["#5FFFBC4D"],
          borderColor: ["#5FFFBC"],
          pointStyle: "circle",
          radius: 30,
          tension: 0.4,
        },
      },
      show: true,
      chart: null,
      
      
    };
  },
  mounted() {
    this.renderChart();
  },
  created() {
    this.form = {
      ...this.original_form
    }
  },
  components: {
    "chrome-picker": Chrome,
  },
  methods: {
    saveChart() {
      
    },
    resetChart() {
      this.form = Object.assign({}, this.form, 
        this.original_form
      )
      this.renderChart();
    },
    applySample(idx) {
      this.form = Object.assign({}, this.form, 
        this.singleChart[idx]['formData']
      )
      this.renderChart();
    },
    add_column() {
      this.form.x_cnt += 1;
      this.form.data_set.backgroundColor.push("#FFFFF");
      this.form.data_set.borderColor.push("#FFFFF");
    },
    remove_data_sets(idx) {
      this.form.data_set_list.splice(idx, 1);
      this.form.data_cnt -= 1;

      this.renderChart();
    },
    remove_x_label(idx) {
      this.form.header.splice(idx, 1);
      this.form.x_cnt -= 1;

      this.renderChart();
    },
    renderChart() {
      const ctx = this.$refs.myChart.getContext("2d");
      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(ctx, {
        type: this.form.chart_type,
        data: {
          labels: this.form.header,
          datasets: [this.form.data_set],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              stacked: this.form.x_stacked,
              display: this.form.show_x_axis,
              title: {
                display: true,
                text: this.form.x_name,
                font: {
                  size: 10,
                  weight: "bold",
                  lineHeight: 1.2,
                },
                padding: { top: 20, left: 0, right: 0, bottom: 0 },
              },
            },
            y: {
              stacked:this.form.y_stacked,
              display: this.form.show_y_axis,
              title: {
                display: true,
                text: this.form.y_name,
                font: {
                  size: 20,
                  style: "normal",
                  lineHeight: 1.2,
                },
                padding: { top: 30, left: 0, right: 0, bottom: 0 },
              },
              min: this.form.y_min,
              max: this.form.y_max,
              ticks: {
                stepSize: this.form.y_stepSize
              }
            },
          },
          indexAxis: this.form.x_axis ? "x" : "y",
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  return context.parsed.y !== undefined ? `${context.dataset.label}: ${context.parsed.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (단위: ${this.form.y_level})` : `${context.dataset.label}: ${context.parsed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (단위: ${this.form.y_level})`;
                },
              },
            },
            // legend: {
            //   display: this.form.show_legend,
            //   position: this.form.legend_position,
            //   labels: {
            //     font: {
            //       size: 16,
            //     },
            //   },
            // },
            // labels: this.form.header,
            labels: [
                'Red',
                'Yellow',
                'Blue','34','42','423','32'
            ],
            title: {
              display: true,
              text: this.form.chart_main_title,
              font: {
                size: 25,
              },
            },
            subtitle: {
              display: true,
              text: this.form.chart_sub_title,
              font: {
                size: 20,
              },
            },
          },
        },
      });
    },
    handleColorChange(data_idx, key) {
      this.form.data_set[key][data_idx] = this.form.data_set[key][data_idx].hex8
      this.renderChart();
    },
  },
  beforeDestroy() {
    // 컴포넌트가 파괴되기 전에 차트를 파괴
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>

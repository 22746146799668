<template>
<div>
    <h4>비즈니스 사례 관리</h4>
    <p class="f-95 mb-5 gray-600">보고서 작성에 적용할 비즈니스 사례를 관리합니다.</p>

    <business-case-list @open-edit-modal="openEditModal" @open-manage-modal="openManageModal"></business-case-list>

    <b-modal ref="edit-business-case" id="global-editor-modal" title="비즈니스 사례" hide-footer size="lg" scrollable>
        <template v-if="edit_mode==true">
            <edit-business-case :business_case_id="business_case_id" @cancle-edit="cancleEdit"></edit-business-case>
        </template>
        <template v-else>
            <manage-business-case :business_case_id="business_case_id" @cancle-edit="cancleEdit"></manage-business-case>
        </template>        
    </b-modal>
    <hr class="border-0 mb-5">
    
</div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import BusinessCaseList from '@/components/writing_report/business_case_subs/BusinessCaseList'
import EditBusinessCase from '@/components/writing_report/business_case_subs/EditBusinessCase'
import ManageBusinessCase from '@/components/writing_report/business_case_subs/ManageBusinessCase'

export default {
    data() {
        return {
            business_case_id: '',
            edit_mode : false
        }
    },
    computed:{
        ...mapState('business_cases', ['business_case_list']),

    },
    methods:{
        openEditModal(business_case_id){
            this.edit_mode = true
            this.openModal(business_case_id)
        },
        openManageModal(business_case_id){
            this.edit_mode = false
            this.openModal(business_case_id)
        },
        cancleEdit(){
            this.$refs['edit-business-case'].hide()
        },
        openModal(business_case_id){
            this.business_case_id = business_case_id
            this.$refs['edit-business-case'].show()
        }       

    },
    created(){
    },
    mounted(){
        console.log(this.business_case_list);
    },
    components:{
        BusinessCaseList,
        EditBusinessCase,
        ManageBusinessCase
    }
}
</script>

<style>

</style>


//"선택"한 보고서가 가지고 있는 도큐먼트 리스트와, 
//각 도큐먼트의 내용 해시

const documents = {
    namespaced: true, 
    state: {
        document_list:[
            {
                // "document_id": 2,
                // "report_code": "r11",
                // "title": "my title",
                // "title_ea": "my title",
                // "title_em": "my title",
                // "description": "wow description",
                // "description_ea": "wow description",
                // "description_em": "wow description",
                // "type": "file", //file or url
                // "file_name": "Welcome to Show.show",
                // "file_name_e" : "",
                // "path": "C:\\Inetpub\\gri_backend\\app\\../uploads\\documents\\2", //path는 파일 경로
                // "path_e": "",
                // "link" : "http://qesg.co.kr", //link는 URL 경로
                // "link_e" : "",
                // "time_stamp": "2020-08-13 17:29:11",
                // "used_codes": [
                //     "404-1"
                // ]
            }
        ]
    },
    mutations: {
        update_document_list(state, new_document_list){
            state.document_list = new_document_list
        },
        add_or_update_document(state, new_document_obj ){
            let temp_document_list = [ ...state.document_list ]
            let idx = temp_document_list.findIndex(item => item.document_id == new_document_obj.document_id)
            if(idx > -1){
                let temp_document_obj = { ...new_document_obj }
                temp_document_obj.used_codes = temp_document_list[idx].used_codes
                temp_document_list.splice(idx, 1, temp_document_obj)
            }
            else{
                new_document_obj.used_codes = []
                temp_document_list.push(new_document_obj)
            }
            state.document_list = temp_document_list            
        },
        update_document_e(state, new_translated_obj){
            let temp_document_list = [...state.document_list]
            let idx = temp_document_list.findIndex(item => item.document_id == new_translated_obj.document_id)
            if(idx > -1){
                temp_document_list[idx].title_em = new_translated_obj.title_em,
                temp_document_list[idx].title_ea = new_translated_obj.title_ea,
                temp_document_list[idx].file_name_e = new_translated_obj.file_name_e,
                temp_document_list[idx].link_e = new_translated_obj.link_e,
                temp_document_list[idx].path_e = new_translated_obj.path_e
            }
            state.document_list = temp_document_list
        },
        update_document_relation(state, gri_codes_by_document_id){
            let temp_document_list = [ ...state.document_list ]
            temp_document_list.forEach( document => {
                if(gri_codes_by_document_id.hasOwnProperty(document.document_id)){
                    document['used_codes'] = gri_codes_by_document_id[document.document_id]
                }
            })
            //State 인식
            state.document_list = temp_document_list
        },
        update_delete_document(state, deleted_document_id){
            let temp_document_list = [ ...state.document_list ]
            let idx = temp_document_list.findIndex(x => x.document_id == deleted_document_id)
            if (idx > -1){
                temp_document_list.splice(idx, 1)
            }
            state.document_list = temp_document_list
        }
    },
    getters: {
        url_list(state){
            return state.document_list.filter(x => x.type == 'link')
        },
        doc_list(state){
            return state.document_list.filter(x => x.type != 'link')
        },
        hashed_docs(state){
            let temp_doc_list = [...state.document_list]
            let temp_doc_obj = {}
            for (let i=0; i<temp_doc_list.length; i++){
                let doc_id = temp_doc_list[i].document_id
                let doc_item = {...temp_doc_list[i]}
                if(temp_doc_list[i].title_em!=''&&temp_doc_list[i].title_em!=null){
                    doc_item['title_e'] = temp_doc_list[i].title_em
                }else doc_item['title_e'] = temp_doc_list[i].title_ea
                if(temp_doc_list[i].description_em!=''&&temp_doc_list[i].description_em!=null){
                    doc_item['description_e'] = temp_doc_list[i].description_em
                }else doc_item['description_e'] = temp_doc_list[i].description_ea
                
                temp_doc_obj[doc_id] = doc_item
            }
            return temp_doc_obj
        },
    },
    actions: {
        readDocumentList({state, commit, rootState}, { report_code }){
            const path = rootState.backend_host + '/read_document_list'

            return axios.get(path, { params: {
                report_code: report_code
            }})
            .then( response => {
                commit('update_document_list', response.data)
            })
        },
        saveDocument({commit, rootState}, { report_code, document_id, title, description, type, link, file }){
            const path = rootState.backend_host + '/save_document'

            let formData = new FormData()
            if(document_id != null && document_id != undefined && document_id != ''){
                formData.append('document_id', document_id) //신규일 경우, document_id를 백엔드로 보내지 않아야 신규 생성하고 id를 부여받음
            }
            formData.append('report_code', report_code)
            formData.append('title', title)
            formData.append('description', description)
            formData.append('type', type)
            formData.append('link', link)
            formData.append('file', file)

            return axios.post(path, formData)
            .then( response => {
                let new_document_obj = {
                    document_id: response.data.document_id, 
                    report_code: response.data.report_code, 
                    title: response.data.title, 
                    description: response.data.description, 
                    type: response.data.type, 
                    file_name: response.data.file_name, 
                    path: response.data.path, 
                    link: response.data.link,
                    time_stamp: response.data.time_stamp
                }
                // console.log(new_document_obj);
                commit('add_or_update_document', new_document_obj )
            })

        },
        updateDocumentRelation({commit, rootState}, { report_code, gri_code, link_payload, framework }){
            const path = rootState.backend_host + '/link_disclosure_documents'

            let formData = new FormData()
            formData.append('report_code', report_code)
            formData.append('gri_code', gri_code)
            formData.append('framework', framework)
            formData.append('link_payload', JSON.stringify(link_payload)) //{ "table_id": true } 형식의 object

            return axios.post(path, formData)
            .then( response=> {
                let document_ids_by_gri_code = response.data.document_ids_by_gri_code
                //{ gri_code: [ document_ids ] }
                let gri_codes_by_document_id = response.data.gri_codes_by_document_id
                //{ 'document_id': [ gri_codes ]}
                commit('update_document_relation', gri_codes_by_document_id )
                commit('report_contents/update_document_relation', document_ids_by_gri_code, {root: true }) 
            })
        },
        deleteDocument({commit, rootState},  document_id ){
            const path = rootState.backend_host + '/delete_document'
            
            let formData = new FormData()
            formData.append('document_id', document_id)

            return axios.post(path, formData)
            .then( response =>{
                let deleted_document_id = response.data.document_id
                commit('update_delete_document', deleted_document_id)
                commit('report_contents/update_delete_document', deleted_document_id, {root: true})
            }) 
        },
        saveDocumentEm({commit, rootState}, { document_id, title, description, type, link, file }){
            const path = rootState.backend_host + '/save_document_em'

            let formData = new FormData()
            formData.append('document_id', document_id)
            if(title != null && title != undefined) formData.append('title_em', title)
            if(description != null && description != undefined) formData.append('description_em', description)
            formData.append('type', type)
            if(link != null && link != undefined) formData.append('link', link)
            if(file != null && file != undefined) formData.append('file', file)

            return axios.post(path, formData)
            .then( response => {
                // console.log(response.data); 
                commit('update_document_e', response.data )
            })
        }, 
        reInitiateDocument({commit, rootState}, { document_id, re_initiate }){
            const path = rootState.backend_host + '/translate_document'

            let formData = new FormData()
            formData.append('document_id', document_id)
            if(re_initiate != null && re_initiate != undefined) formData.append('re_initiate', re_initiate ) //re_initiate 를 true로 보내면, direct_report_em을 삭제함
            return axios.post(path, formData )
            .then( response => {
                let new_translated_obj = {...response.data}
                new_translated_obj['file_name_e'] = response.data.file_name,
                new_translated_obj['link_e'] = response.data.link,
                new_translated_obj['path_e'] = response.data.path
                commit('update_document_e', new_translated_obj)
            })
        },
        
    }
}

export default documents
<template>
<div>
    <h4>GRI 100 - Universal Standards</h4>
    <p class="f-95 mb-5 gray-600">GRI 100의 각 보고 지표에 대해 내용을 작성합니다.</p>
    
    <h5>GRI 102 - 일반 공개사항 <span class="gray-500 f-105 fw-400 ml-1">General Disclosures</span></h5>
    <div class="py-3 mb-4" v-for="(part, index) in series_100" :key="part.id">
        <h6 :id="'w'+(index+1)" class="mb-4 pb-1 gray-600 border-bottom-gray-550">{{index+1}}. {{ part.name }}</h6>
        
        <template v-if="report_contents_loaded==true && business_case_loaded==true">
            <b-table-simple responsive class="wri-table"> 
                <b-thead><b-tr>
                    <b-td>지표</b-td><b-td style="width:45%">보고 내용 작성 <div class="d-inline-block float-right mx-2 f-90 green"><fa icon="check-circle" class="ml-1"/> : 저장됨 </div></b-td><b-td style="width:40%">작성 도우미</b-td>
                </b-tr></b-thead>
                <b-tbody>
                    <b-tr v-for="item in series_contents[part.name]" :key="item.id">
                        <b-td><span :id="'w'+item.gri_code" class="f-160">{{ item.gri_code }}</span> <p class=""> {{ item.indicator_name }}</p></b-td>
                        <b-td>
                                <!-- 직접 작성 -->
                                <div v-if="is_writing_direct[item.gri_code]==true" class="mb-45">
                                    <b-form-textarea v-model="item.direct_report" rows="2" placeholder="지표에 대해 보고할 내용을 직접 작성합니다."
                                    @blur="textInputSave(item.gri_code, item.direct_report)" :autofocus="is_writing_direct[item.gri_code]==true"
                                    ></b-form-textarea>
                                    <template v-if="save_state[item.gri_code]=='saved'"><fa icon="check-circle" class="ml-1 green"/></template>
                                    <template v-else-if="save_state[item.gri_code]=='saving'"><b-spinner class="green" small></b-spinner></template>
                                </div>
                                <div v-else @click.stop.prevent="changeWritingMode(item.gri_code)" class="mb-3">
                                    <md :contents="item.direct_report" v-if="item.direct_report!=''&&item.direct_report!=null" class="hover-border pb-4">
                                        <template v-if="save_state[item.gri_code]=='saved'"><fa icon="check-circle" class="ml-1 green" /></template>
                                        <template v-else-if="save_state[item.gri_code]=='saving'"><b-spinner class="green" small></b-spinner></template>
                                    </md>
                                    <div v-else class="hover-border gray-500 rounded pb-4">클릭하여 작성하기</div>
                                </div>            
                                <!-- 연결된 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
                                <div v-if="attach_loaded==false"><b-skeleton class="w-50 ml-2"></b-skeleton></div>
                                <section class="mb-5 px-3" v-if="attach_loaded==true&&item.data_tables.length>0">
                                    <div class="border-bottom-gray-550 gray-600 f-90 pb-1"><fa icon="table" class="mr-1"/> 데이터 테이블</div>                               
                                    <div v-for="dt in item.data_tables" :key="dt.id"> 
                                        <div class="mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center">{{ hashed_data_tables[dt].title }} 
                                            <div style="min-width: 70px;">
                                                <b-button variant="light" :disabled="is_unlinked==true" class="py-1 px-2" v-b-tooltip.hover title="편집" @click.stop.prevent="addTables({gri_code: item.gri_code, table_id: dt})"><fa icon="pen"/></b-button>
                                                <b-button variant="light" :disabled="is_unlinked==true" class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click.stop.prevent="handleUnlink({gri_code: item.gri_code, table_id: dt})"><fa icon="unlink"/></b-button>
                                            </div>
                                        </div>
                                        <b-table-simple responsive class="bor-bg-table">
                                            <b-thead><b-tr>
                                                <b-td v-for="(property, col_index) in hashed_data_tables[dt].header" :key="col_index">{{ property }}</b-td>
                                            </b-tr></b-thead>
                                            <b-tbody>
                                                <b-tr v-for="(row_item, row_index) in hashed_data_tables[dt].contents" :key="row_item.id">
                                                    <template v-for="property in hashed_data_tables[dt].header"><b-td v-if="differentPrevious(hashed_data_tables[dt].contents, row_index, property, hashed_data_tables[dt].header)==true" :key="property.id" :rowspan="getSameLength(hashed_data_tables[dt].contents, row_index, property, hashed_data_tables[dt].header)">{{ row_item[property] }}</b-td></template>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </div>
                                </section>
                                <section class="mb-5 px-3" v-if="attach_loaded==true&&item.documents.length>0"> 
                                    <div class="border-bottom-gray-550 gray-600 f-90 pb-1"><fa icon="paperclip" class="mr-1"/> 문서 및 링크 </div>
                                    <div v-for="x in item.documents" :key="x.id">
                                        <div v-if="hashed_docs[x].type!='link'" class="mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center">
                                            <a class=" cursor" :href="$store.state.static_host+hashed_docs[x].path" target="_blank"><fa icon="file-alt" class="mr-1"/> {{ hashed_docs[x].title }} </a>  
                                            <div style="min-width: 70px;">
                                                <b-button variant="light" :disabled="is_unlinked==true" class="py-1 px-2" v-b-tooltip.hover title="편집" @click.stop.prevent="addDocuments({gri_code: item.gri_code, document_id: x, type: hashed_docs[x].type})"><fa icon="pen"/></b-button>
                                                <b-button variant="light" :disabled="is_unlinked==true" class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click.stop.prevent="handleUnlink({gri_code: item.gri_code, document_id: x})"><fa icon="unlink"/></b-button>
                                            </div>
                                        </div>                                                         
                                    </div>
                                    <div v-for="y in item.documents" :key="y.id">
                                        <div v-if="hashed_docs[y].type=='link'" class="mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center">
                                            <a class=" cursor" :href="hashed_docs[y].link" target="_blank"><fa icon="external-link-square-alt" class="mr-1"/> {{ hashed_docs[y].title }} </a>  
                                            <div style="min-width: 70px;">
                                                <b-button variant="light" :disabled="is_unlinked==true" class="py-1 px-2" v-b-tooltip.hover title="편집" @click.stop.prevent="addDocuments({gri_code: item.gri_code, document_id: y, type: hashed_docs[y].type})"><fa icon="pen"/></b-button>
                                                <b-button variant="light" :disabled="is_unlinked==true" class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click.stop.prevent="handleUnlink({gri_code: item.gri_code, document_id: y})"><fa icon="unlink"/></b-button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="mb-5 px-3" v-if="attach_loaded==true&&item.business_cases.length>0">
                                    <div class="border-bottom-gray-550 gray-600 f-90 pb-1"><fa icon="quote-left" class="mr-1"/> 비즈니스 사례</div>
                                    <div v-for="bc in item.business_cases" :key="bc.id">
                                        <div class="mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center"> 
                                            <a class=" cursor" v-b-modal="`bc_${bc}${item.gri_code}`">{{ hashed_business_cases[bc].title }} <fa icon="expand" class="ml-1"/></a>
                                            <div style="min-width: 70px;">
                                                <b-button variant="light" :disabled="is_unlinked==true" class="py-1 px-2" v-b-tooltip.hover title="편집" @click.stop.prevent="addBusinessCases({gri_code: item.gri_code, business_case_id: bc})"><fa icon="pen"/></b-button>
                                                <b-button variant="light" :disabled="is_unlinked==true" class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click.stop.prevent="handleUnlink({gri_code: item.gri_code, business_case_id: bc})"><fa icon="unlink"/></b-button>
                                            </div>
                                        </div>
                                        <b-modal :id="`bc_${bc}${item.gri_code}`" hide-footer size="xl">
                                            <template #modal-title>
                                                <b-badge variant="basic" class="mr-2">Case.</b-badge> {{ hashed_business_cases[bc].title }}
                                            </template>
                                            <simple-business-case :business_case_id="bc"></simple-business-case>
                                        </b-modal>
                                    </div>
                                </section>
                                <!-- 추가하기 버튼 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
                                <div class="hover-border">
                                    <b-button variant="light" :disabled="is_unlinked==true" class="py-1 px-2 mr-1" v-b-tooltip.hover.bottom title="데이터 테이블 추가" @click.stop.prevent="addTables({gri_code: item.gri_code})"><fa icon="table"/></b-button>  
                                    <b-button variant="light" :disabled="is_unlinked==true" class="py-1 px-2 mr-1" v-b-tooltip.hover.bottom title="문서 및 링크 추가" @click.stop.prevent="addDocuments({gri_code: item.gri_code})"><fa icon="paperclip"/></b-button>                   
                                    <b-button variant="light" :disabled="is_unlinked==true" class="py-1 px-2" v-b-tooltip.hover.bottom title="비즈니스 사례 추가" @click.stop.prevent="addBusinessCases({gri_code: item.gri_code})"><fa icon="quote-left"/></b-button>                   
                                </div>
                        </b-td>
                        <b-td class="bgray-100" style="padding-left:0.75rem;">
                            <div class="d-flex justify-content-between f-90 mb-2"> <p class="gray-600 mb-0"> {{ show_example[item.gri_code]!=true? '다음의 내용을 보고해야 합니다':'작성 예시'}} : </p>
                                <a @click.stop.prevent="showExample(item.gri_code)" class="cursor"> {{ show_example[item.gri_code]!=true?'예시보기':'예시닫기'}} <fa :icon="show_example[item.gri_code]!=true?'expand':'compress-alt'"/></a>
                            </div>
                            <div v-if="show_example[item.gri_code]!=true" class="bg-white px-2 py-2" >
                                <p class="mb-1 f-90 gray-600"> <md :contents="item.indicator_requirement"></md></p>
                            </div> 
                            <div v-else class="px-2 py-2">
                                <example :gri_code="item.gri_code"></example>
                            </div> 
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </template>
        <template v-else>
            <b-table-simple responsive class="wri-table">
                <b-thead><b-tr>
                    <b-td>지표</b-td><b-td style="width:45%">보고 내용 작성</b-td><b-td style="width:40%">작성 도우미</b-td>
                </b-tr></b-thead>
                <b-tbody>
                    <b-tr v-for="dummy in 5" :key="dummy">
                        <b-td><b-skeleton class="mb-5"></b-skeleton></b-td><b-td><b-skeleton class="mb-5"></b-skeleton></b-td><b-td><b-skeleton class="mb-5"></b-skeleton></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </template>
    </div>

</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Example from '@/components/writing_report/write_report_subs/Example'
import SimpleBusinessCase from '@/components/writing_report/business_case_subs/SimpleBusinessCase'
import { gri_mixin } from './gri_mixin'
import * as ih from '@/components/util'

export default {
    mixins: [gri_mixin],
    data() {
        return {
            is_writing_direct : {},
            save_state:{},
            show_example: {}
        }
    },
    computed:{
        ...mapState('report_contents', ['report_contents_loaded', 'series_100', 'attach_loaded']),
        ...mapGetters('report_contents', ['gri_100_all_contents']),
        ...mapState('business_cases', ['business_case_loaded']),
        series_contents(){
            let temp_obj = {}
            for (let q=0; q<this.series_100.length; q++) {
                let temp_list = []
                for (let w=0; w<this.series_100[q].codes.length; w++){
                    let idx = this.gri_100_all_contents.findIndex(x => x.gri_code == this.series_100[q].codes[w])
                    if (idx > -1) {
                        temp_list.push(this.gri_100_all_contents[idx])
                    }
                }
                this.$set(temp_obj, this.series_100[q].name, temp_list)
            }
            // console.log(temp_obj);
            return temp_obj
        }
    },
    methods:{
        ...mapActions('report_contents', ['saveDisclosure']),
        ...mapMutations('cursor', ['update_editing_gri_code']), 
        async textInputSave(gri_code, txt) {
            if (txt != null){
                this.$set( this.save_state, gri_code, 'saving')
                await this.saveDisclosure({report_code: this.report_code, gri_code: gri_code, direct_report: txt })
                this.initiateWritingMode()
                this.initiateSavingState()
            }
        },
        initiateWritingMode(){
            this.is_writing_direct = {}
            let temp_100_contents = [...this.gri_100_all_contents]
            for (let h=0; h<temp_100_contents.length; h++){
                this.$set(this.is_writing_direct, temp_100_contents, false)
            }
        },
        showExample(gri_code){
            // console.log(gri_code);
            if(this.show_example[gri_code]==false){
                this.show_example[gri_code]=true
            }else this.show_example[gri_code]=false
        },
        initiateShowExample(){
            this.show_example = {}
            for (let i=0; i<this.gri_100_all_contents.length; i++){
                this.$set( this.show_example, this.gri_100_all_contents[i].gri_code, false)
            }
        },
        initiateSavingState(){
            this.save_state = {}
            for (let i=0; i<this.gri_100_all_contents.length; i++){
                if(this.gri_100_all_contents[i].direct_report != ''&&this.gri_100_all_contents[i].direct_report != undefined) {
                    this.$set( this.save_state, this.gri_100_all_contents[i].gri_code, 'saved')
                }
                else this.$set( this.save_state, this.gri_100_all_contents[i].gri_code, '')
            }       
        }
    },
    updated(){
        // this.initiateShowExample()
        // this.initiateSavingState()
    },
    components:{
        Example,
        SimpleBusinessCase
    },
    beforeDestroy(){
        this.update_editing_gri_code('')
    },
    watch:{
        report_contents_loaded:{
            immediate: true,
            handler(newV){
                if(newV==true){
                    this.initiateSavingState()
                    this.initiateShowExample()
                }
            }
        }
    }
}
</script>

<style>

</style>
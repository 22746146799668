<template>
<div>
    <h4>데이터 테이블 관리</h4>
    <p class="f-95 mb-5 gray-600">보고서 작성에 적용할 데이터 테이블을 관리합니다.</p>
    <tables-list @open-edit-modal="openEditModal" @open-manage-modal="openManageModal"></tables-list>
    <b-modal ref="edit-table" title="데이터 테이블" id="global-editor-modal" hide-footer size="lg" scrollable>
        <template v-if="edit_mode===true">
            <edit-data-table :table_id="table_id" @cancle-edit="cancleEdit"></edit-data-table>
        </template>
        <template v-else>
            <manage-tables :table_id="table_id" @cancle-edit="cancleEdit"></manage-tables>
        </template>        
    </b-modal>
    <hr class="border-0 mb-5">

</div>
</template>

<script>
import TablesList from '@/components/writing_report/data_table_subs/TablesList'
import EditDataTable from '@/components/writing_report/data_table_subs/EditDataTable'
import ManageTables from '@/components/writing_report/data_table_subs/ManageTables'
import {mapState, mapActions, mapGetters} from 'vuex'

export default {
    data() {
        return {
            table_id: '',
            edit_mode : false
        }
    },
    computed:{
        ...mapState('data_tables', ['table_list']),
    },
    methods:{
        openEditModal(table_id){
            this.edit_mode = true
            this.openModal(table_id)
        },
        openManageModal(table_id){
            this.edit_mode = false
            this.openModal(table_id)
        },
        cancleEdit(){
            this.$refs['edit-table'].hide()
        },
        openModal(table_id){
            this.table_id = table_id
            this.$refs['edit-table'].show()
        }

    },
    created(){
    },
    components:{
        TablesList,
        EditDataTable,
        ManageTables
    }
}
</script>

<style>

</style>
<template>
<div>
    <!-- {{ selected_report.sample_report}} -->
    <h4 id="ps1">공개하기</h4>
    <p class="f-95 mb-4 gray-600">보고서를 공개합니다.</p>

    <b-card v-if="!selected_report.sample_report" class="general-card mb-5" >
        <b-overlay :show="is_publishing" no-wrap></b-overlay>
        
        <b-card-header>공개하기</b-card-header>
        <p class="py-3 mb-3 gray-600">
            {{is_published==false? '보고서 작성 완료 후 아래 [공개하기] 버튼을 클릭하면 공개 링크가 생성됩니다.' : '보고서가 공개되었습니다.'}} <br>
            {{is_published==false? '':'비공개로 전환 시 설정된 공개 링크가 비활성화됩니다.' }}
        </p>

        <b-row no-gutters v-if="is_published==false" class="hover-gray py-2 mb-5">
            <b-col cols="3"><div class="gray-600">진행 상태</div> </b-col>  
            <b-col>Step 0{{selected_report.state+1}}. {{ report_state }} 
                <p class="pt-2 f-85 gray-600"> <fa icon="info-circle" class="mr-1"/>Step 01. 기본설정 → Step 02. 지표 선택 → Step 03. 작성 중 → Step 04. 검증 → Step 05. 공개됨</p>
            </b-col>
        </b-row>
        <b-row no-gutters v-else class="hover-gray py-2 mb-5">
            <b-col cols="3">
                <div class="gray-600 mb-3">공개 상태</div> 
                <div class="gray-600 mb-3">최초 공개일</div> 
                <div class="gray-600 mb-3">최신 공개일</div> 
            </b-col>  
            <b-col> 
                <div class="gray-600 mb-3"> 공개됨 </div>  
                <div class="gray-600 mb-3"> {{ selected_report.published_date }} </div> 
                <div class="gray-600 mb-3"> {{ latest_published }} 
                    <!-- <b-spinner small v-if="latest_published===''"></b-spinner> -->
                    <!--  -->
                    <b-spinner small v-if="!selected_report && !latest_published"></b-spinner>
                
                </div> 
            </b-col>
        </b-row>
        <b-button
            :variant="is_published==false?'teal':'teal-border'" 
            class="px-3" 
            @click="showNotificationBeforehandlePublish"
        > 
            {{ is_published==false ?'공개하기':'비공개 전환하기'}} 
        </b-button>
    </b-card>



    <b-card
        v-else
    >
        <template #modal-title>
        <h3 style="padding: 10px;">샘플 보고서는 공개할 수 없습니다.</h3>
        </template>

        샘플 보고서는 공개할 수 없습니다.<br /> <br />
        공개를 원하실 경우 <a class="cursor mr-3 f-105" @click='navigateToMain' >메인 페이지</a>에서
        샘플 보고서 우측 상단 `복제` 후<br />
        복제된 보고서로 공개하여 주십시오. <br /><br />
        <img src="/static_img/copy_report.png" alt=""> <br/><br />

        공개 링크 생성 예시는 다음과 같습니다. <br/>
        ex) <a href="https://report.qesg.co.kr/v/?r=qesg2022" target="_blank">https://report.qesg.co.kr/v/?r=qesg2022</a>
        

    </b-card>


    <b-modal  
        id="open-gri-report-modal" 
        hide-footer 
        size="md" 
        title="공개한 보고서는 GRI에 보고됩니다" 
        scrollable
    >
        <div class="py-3">
            <p class="gray-600">
                공개하기 클릭 시 30일 후 자동으로 GRI에 본 보고서를 통보해 드립니다. <br><br>
        
                GRI 통보(Notify) 일정을 늦추고 싶을 경우,
                <a href="#" @click="showChannelTalk">
                    채팅
                </a>
                을 통해 <br> 원하시는 일정을 전달 주세요. 
            </p>

        </div>

        <div class="py-3">
            <a href="#" @click="showNotifyGRI">
                <fa icon="info-circle" class="mr-1"/>
                GRI에 통보하기(Notify) 
                <fa icon="expand" class="ml-1"/> 
            </a>
        </div>

        <b-button @click="handlePublish" variant="teal" class="mt-4 float-right">공개하기</b-button>
    </b-modal>

    <b-modal
        id="open-notify-gri-report" 
        hide-footer 
        size="lg" 
        title="GRI에 통보하기(Notify)" 
        scrollable
    >
    <b-card class="general-card mb-5">
            <b-card-header>완성된 보고서를 GRI에 통보합니다.</b-card-header>
            <div class="py-3">
                <p class="gray-600 mb-30">
                    보고서 공개 시 GRI 표준을 사용하였음을 GRI에 공식적으로 통보해야 합니다.
                
                </p>
                <p class="gray-600 mb-45"> 
                    QESG는 보고조직을 대신하여 완성된 보고서를 GRI에 통보하는 서비스를 진행하고 있습니다. <br>
                    GRI에 통보하는 서비스를 원치 않으시거나, 통보 일정을 조정하고 싶으신 경우 채팅을 통해 문의주세요.
                </p>

                <div class="mb-2 fw-600">GRI에 통보하는 내용</div>
                <b-card class="bgray-200 border-0 px-2">
                    <ul>
                        <li>GRI 2-1에 작성된 보고 조직명</li>
                        <li>GRI 2-3에 작성된 보고 문의처</li>
                        <li>
                            ESG Reporting Tool로 완성된 GRI 보고서 링크 <br>
                            (GRI content index, 사용 성명을 포함한 보고서 산출물)
                        </li>
                    </ul>
                </b-card>
            </div>
        </b-card>
    
        
    </b-modal>

    <hr class="border-0">

    <div v-if="!selected_report.sample_report">

        <h4 id="ps2">공개 링크 설정</h4>
        <p class="f-95 mb-4 gray-600">보고서를 공유할 수 있는 공개 링크를 설정합니다. </p>
    
        <b-card v-if="is_published==false" class="general-card mb-5">
            <b-card-header>공개 링크</b-card-header>
            <b-row no-gutters class="hover-gray py-2 mb-5">
                <b-col cols="3"><div class="gray-600">보고서 링크</div> </b-col>  
                <b-col> 
                    <p class="gray-600"> 보고서가 공개되지 않았습니다. <br> 공개 링크를 생성하려면 보고서 작성 완료 후 '공개하기' 버튼을 눌러주세요.</p>
                </b-col>
            </b-row>
        </b-card>
    
        <b-card v-else class="general-card mb-5">
            <b-card-header>공개 링크</b-card-header>
            <div class="py-3">
                <b-row no-gutters class="hover-gray py-2 mb-5">
                    <b-col cols="3"><div class="gray-600">보고서 링크</div> </b-col>  
                    <b-col v-if="is_editing!=true"> 
                        <p class="gray-600"> 
                            <b-badge variant="advance" class="mr-2">전체 보고서</b-badge> <a :href="`https://report.qesg.co.kr/v?r=`+url_sample" target="_blank" > https://report.qesg.co.kr/v?r={{url_sample}}</a>
                            <b-button variant="icon-teal" class="ml-2 py-0 px-2" v-b-tooltip.hover title="링크 복사하기" @click.stop.prevent="copyLink('full')"><fa :icon="['far','copy']"/></b-button> 
                        </p>
                        <p class="gray-600" v-if="Object.keys(selected_report.report_type).includes('GRIv2021') || Object.keys(selected_report.report_type).includes('GRIv2016')">
                           <b-badge variant="advance" class="mr-2"> GRI Table</b-badge> <a :href="`https://report.qesg.co.kr/g?r=`+url_sample" target="_blank" > https://report.qesg.co.kr/g?r={{url_sample}}</a>
                            <b-button variant="icon-teal" class="ml-2 py-0 px-2" v-b-tooltip.hover title="링크 복사하기" @click.stop.prevent="copyLink('gri')"><fa :icon="['far','copy']"/></b-button> 
                        </p>

                        <p class="gray-600" v-if="Object.keys(selected_report.report_type).includes('SASBv2023')">
                           <b-badge variant="advance" class="mr-2"> SASB Table</b-badge> <a :href="`https://report.qesg.co.kr/s?r=`+url_sample" target="_blank" > https://report.qesg.co.kr/s?r={{url_sample}}</a>
                            <b-button variant="icon-teal" class="ml-2 py-0 px-2" v-b-tooltip.hover title="링크 복사하기" @click.stop.prevent="copyLink('sasb')"><fa :icon="['far','copy']"/></b-button> 
                        </p>

                        <p class="gray-600" v-if="Object.keys(selected_report.report_type).includes('TCFDv2021')">
                           <b-badge variant="advance" class="mr-2"> TCFD Table</b-badge> <a :href="`https://report.qesg.co.kr/t?r=`+url_sample" target="_blank" > https://report.qesg.co.kr/t?r={{url_sample}}</a>
                            <b-button variant="icon-teal" class="ml-2 py-0 px-2" v-b-tooltip.hover title="링크 복사하기" @click.stop.prevent="copyLink('tcfd')"><fa :icon="['far','copy']"/></b-button> 
                        </p>


                    </b-col>
                    <b-col v-else>
                        <div class="d-flex align-items-center mb-3">
                            <div class="gray-600 mr-2" style="min-width: 200px"> https://report.qesg.co.kr/v?r= </div>
                            <b-input-group>
                                <b-form-input v-model.trim="url_sample" class="narrow-input-form" placeholder="원하는 링크명 입력" @keyup.enter="handleURL"></b-form-input>
                                <b-input-group-append> <b-button variant="teal" class="f-95" @click="handleURL">가능여부 확인</b-button> </b-input-group-append>
                            </b-input-group>
                        </div>
                        <p class="f-90"><fa icon="info-circle"/> 공백, 특수문자(~ ! @ # $ % ^ & *  .  , ), 영어 외 문자는 URL로 사용할 수 없습니다. </p>
                    </b-col>
                </b-row>
                <b-button :variant="is_editing==true?'teal':'teal-border'" class="px-3 mr-3" @click="handleMulti"> {{ is_editing==true ?'링크 저장하기':'공개 링크 수정하기'}} </b-button>
                <!-- <b-button v-if="is_editing==true" :variant="'teal-border'" class="px-3 mr-3" @click="handleInitiate"> {{ '링크 초기화' }} </b-button> -->
                <b-button v-if="is_editing==true" class="px-3" @click="handleCancle"> 취소 </b-button>
            </div>
        </b-card>
    </div>


    <b-modal 
        v-else
        id="modal-footer-sm"
        v-model="showThisSample"
        header-bg-variant="light"
        header-text-variant="dark"
        content-class="shadow"
        button-size="md"
        b-modal
        hide-backdrop
        scrollable
        hide-header-close
        >
        <template #modal-title>
        <h3 style="padding: 10px;">샘플 보고서는 공개할 수 없습니다.</h3>
        </template>

        샘플 보고서는 공개할 수 없습니다.<br /> <br />
        공개를 원하실 경우 <a class="cursor mr-3 f-105" @click='navigateToMain' >메인 페이지</a>에서 <br />
        샘플 보고서 우측 상단 `복제` 후<br />
        복제된 보고서로 공개하여 주십시오. <br /><br />
        <img src="/static_img/copy_report.png" alt=""> <br/><br />

        공개 링크 생성 예시는 다음과 같습니다. <br/>
        ex) <a href="https://report.qesg.co.kr/v/?r=qesg2022" target="_blank">https://report.qesg.co.kr/v/?r=qesg2022</a>
        
        <template #modal-footer>
          <b-button
            class="float-right"
            size="sm"
            @click="showThisSample=false"
          >
            Close
          </b-button>
      </template>

    </b-modal>


</div> 
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import * as ih from '@/components/util'
import { gri_mixin } from '@/components/writing_report/write_report_subs/gri_mixin'
import ChannelService from "@/components/ChannelService";

export default {
    mixins: [gri_mixin],
    data() {
        return {
            is_publishing: false,
            is_editing: false,
            showThisSample:true,
            url_sample: '',
            url_confirm: false,

            temp_state: '',
            latest_published:''
        }
    },
    computed:{
        ...mapState('report_list', ['report_code', 'report_list']),
        ...mapGetters('report_list', ['selected_report']),
        is_published(){
            if(this.selected_report.state == 4){
                return true
            }else return false
        },
        report_state(){
            if(this.selected_report.state == 0){
                return `기본설정`
            }
            else if(this.selected_report.state == 1){
                return `지표 선택`
            }
            else if(this.selected_report.state == 2){
                return `작성`
            }
            else if(this.selected_report.state == 3){
                return `검증`
            }
            else if(this.selected_report.state == 4){
                return `공개`
            }
        }
    },
    methods:{
        ...mapActions('report_list', ['saveReportPreference']),
        showNotifyGRI() {
            this.$bvModal.show("open-notify-gri-report");
        },
        
        showChannelTalk() {
            ChannelService.showMessenger();
        },
        showNotificationBeforehandlePublish() {
            if (this.selected_report.state !== 4) {
                this.$bvModal.show("open-gri-report-modal");
                
            } else {
                this.handlePublish();
            }

            
        },
        async handlePublish() {
            this.is_publishing = true;

            if (this.selected_report.sample_report) {
                this.showThisSample = true;
                this.is_publishing = false;
                return
            }

            if (!this.selected_report.is_able_to_edit) {
                window.alert("서비스 정기 결제가 필요합니다.")
            } else {
                if(this.selected_report.state == 4){
                    this.saveReportPreference({ report_code: this.report_code, state: 3 }) 
                    .then( response => {
                        this.$EventBus.$emit('make-toast', '보고서 비공개 전환', `보고서가 비공개 처리 되었습니다. 보고서 링크는 비활성화 됩니다.`)
                    })
                } else if (this.selected_report.state == 2 || this.selected_report.state == 3) {
                    this.$bvModal.hide("open-gri-report-modal");
                    
                    var now = new Date()
                    let published_date = String(now.getFullYear())+'-'+String(now.getMonth()+1)+'-'+String(now.getDate())
                    let url = this.report_code
                    if (this.selected_report.report_url!=null&&this.selected_report.report_url!=''){
                        url = this.selected_report.report_url
                    }
                    await this.saveReportPreference({ report_code: this.report_code, state: 4, published_date: published_date, report_url: url })

                    this.$EventBus.$emit('make-toast', '보고서 공개 성공', `보고서 공개 링크가 활성화되었습니다.`)
                    this.getLatestPublished()
                } else { alert('보고서를 공개할 수 없습니다. 보고서 작성을 완료해주시기 바랍니다.')
                }
            }
            this.is_publishing = false;

            
        },
        copyLink(mode){
            const el = document.createElement('textarea');  
            if (mode=='full'){
                el.value = `https://report.qesg.co.kr/v?r=`+this.url_sample;  
            }
            else if (mode=='gri'){
                el.value = `https://report.qesg.co.kr/g?r=`+this.url_sample;  
            } else if (mode=='tcfd'){
                el.value = `https://report.qesg.co.kr/t?r=`+this.url_sample;  
            } else if (mode=='sasb'){
                el.value = `https://report.qesg.co.kr/s?r=`+this.url_sample;  
            }                          
            el.setAttribute('readonly', '');                
            el.style.position = 'absolute';                     
            el.style.left = '-9999px';                      
            document.body.appendChild(el);                  
            let selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;         
                                     
            el.select();                
            // console.log(selected);                      
            document.execCommand('copy');                   
            document.body.removeChild(el);                  
            if (selected) {                                 
                document.getSelection().removeAllRanges();    
                document.getSelection().addRange(selected);   
            }
            this.$EventBus.$emit('make-toast', '공개 링크 복사', '보고서 공개 링크가 복사되었습니다.')
        },
        handleMulti() {  
            if (!this.selected_report.is_able_to_edit) {
                window.alert("서비스 정기 결제가 필요합니다.")
            } else {
                if(this.is_editing==true){ // 저장버튼 누를 때, url_confirm = true일 때만, 저장 가능
                    if (this.url_confirm == true){
                        this.handleSave()
                    }
                    else alert('링크 사용 가능여부를 확인해 주세요.')
                } // 저장버튼, 초기화버튼 <-> 수정하기 버튼 토글
                this.is_editing = !this.is_editing
            }
            
        },        
        handleCancle(){ // 취소 버튼 누를 때
            this.url_confirm = false
            this.is_editing = false
            this.initiateURL()
        },
        handleURL(){ // "blank, 현재 url, 중복 url"일 때, 저장버튼이 활성화되지 않음.
            const has_pattern = /[~!@#$%^&*()+|<>?:{}]/;
            const has_korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
            const regex = /^[a-zA-Z0-9_]+$/; 

            // if(this.url_sample == ''){
            //     alert('수정할 보고서 링크를 입력해 주세요.')
            // } else if ( has_pattern.test(this.url_sample)==true||has_korean.test(this.url_sample)==true){
            //     alert('공백, 특수문자, 한글은 URL로 사용할 수 없습니다.')
            // } else {
            //     this.checkURL()
            // }

            if (has_korean.test(this.url_sample) === true) { 
                alert('한글은 URL로 사용할 수 없습니다.')
            }
            else if (/\s/.test(this.url_sample) || /[~!@#\$%\^&\*\.,]/.test(this.url_sample)  || !regex.test(this.url_sample)) {
                alert('공백, 특수문자는 URL에 사용할 수 없습니다.')
            } else {
                this.checkURL()
                this.url_sample = this.url_sample.toLowerCase()
            }

        },
        async handleSave(){
            await this.saveReportPreference({report_code: this.report_code, report_url: this.url_sample})
            this.$EventBus.$emit('make-toast', '링크 수정 완료', '입력하신 URL로 공개 링크가 변경되었습니다.')
            this.url_confirm = false
            this.initiateURL()
        },
        initiateURL(){ // 공개 링크 설정 페이지가 실행될 때, 서버에 저장된 URL을 보여주기 위한 로직
            if( this.selected_report.report_url != null || this.selected_report.report_url != undefined ){
                this.url_sample = this.selected_report.report_url
            } else {
                this.url_sample = this.report_code
            }
        },
        getLatestPublished(){
            const path = this.$store.state.backend_host + '/get_latest_published'
            return axios.get(path, { params: {
                report_code: this.report_code
            }})
            .then( response => {
                this.latest_published = response.data
            })
        },
        checkURL(){
            const path = this.$store.state.backend_host + '/check_report_url'
            return axios.get(path, { params: {
                report_code: this.report_code,
                url_sample: this.url_sample
            }})
            .then( response => {
                let cannot_use = response.data
                if(cannot_use.code=='duplicated'){
                    alert(cannot_use.msg +' 다른 URL을 입력해 주세요.')
                }else {
                    this.$EventBus.$emit('make-toast', '사용 가능 URL', '사용 가능한 URL입니다.')
                    this.url_confirm = true
                }
            })
        },
        navigateToMain() {
            this.$router.push("/main");
        },
    },
    created() {

        
        this.initiateURL()
        this.getLatestPublished()
    }
}
</script>

<style>

</style>
import * as ih from '@/components/util'

const logs = {
    namespaced: true, 
    state: {
        activity_logs: [
            // {
            //     activity: "hjkim@qesg.co.kr님이 2020 크라운 GRI Report에 zikmunt@gmail.com님을 공동작성자로 초대하였습니다."
            //     detail: null
            //     index: 0
            //     log_id: 10
            //     report_code: "voiwj201125"
            //     time_stamp: "2020-12-26"
            //     type: "activity"
            //     user_id: 2
            // }
        ],
        activity_logs_loaded: false,

        notice_list: [
                //  {
                // index: 0,
                // notice_id: 28,
                // title: "notice제목",
                // content: "notice내용",
                // sender_email: "보낸사람",
                // time_stamp: "수신일",
                // has_read: false  // 읽지 않은 메시지
                //  }
        ],
        new_notice: 0
    },
    mutations: {
        add_activity_logs(state,  new_log_list){ // -> 배열 통으로 받아서 업데이트 하는걸로 
            state.activity_logs = new_log_list
        },
        update_activity_logs_loaded(state, flag){
            state.activity_logs_loaded = flag
        },
        update_notice_list(state, new_list){
            state.notice_list = new_list
        },
        update_notice_read(state, {notice_id, code}){
            let copy_list = [ ...state.notice_list ]
            let idx = copy_list.findIndex(item => item.notice_id == notice_id)
            if(code == "read" && idx > -1){
                copy_list[idx].has_read = false
            } else if (code == "unread" && idx > -1){
                copy_list[idx].has_read = true
            } else {
                console.log('code확인 필요')
            }
            state.notice_list = copy_list
        },
        update_new_notice(state){
            let new_count = 0
            let copy_list = [...state.notice_list]
            copy_list.forEach(item => item.has_read==false ? new_count += 1 : new_count)
            state.new_notice = new_count
        }
    },
    getters:{

    },
    actions:{
        read_logs_report_code({commit, rootState}, { report_codes }){ // report_code를 배열 형태로 받음
            const path = rootState.backend_host + '/read_logs'
            let promises = []
            report_codes.forEach( item => {
                promises.push( axios.get(path, {
                    params:{
                        report_code: item
                    }
                }) )
            })
            return Promise.all(promises)
            .then( responses => {
                console.log(responses);
                let new_log_list = []
                let temp_result = responses
                for (let i=0; i<temp_result.length; i++){
                    temp_result[i].data.forEach(x => new_log_list.push(x))                    
                }
                new_log_list.sort((a,b)=>parseFloat(b.time_stamp) - parseFloat(a.time_stamp))
                commit('add_activity_logs', new_log_list)
                commit('update_activity_logs_loaded', true)

            })
            .finally(()=>{
            })
        },
        readNoticesByUser({commit, rootState, state}, {user_id}){
            const path = rootState.backend_host + '/read_notices_by_user'
            return axios.get(path, {params: {user_id: user_id}})
            .then(response => {
                let temp_list = []
                temp_list = response.data
                commit('update_notice_list', temp_list)
                commit('update_new_notice')
            })
            .catch( error => {
                console.log(error)
            })
        },
        makeNoticeRead({commit, rootState, state}, {notice_id, user_id, has_read}){
            const path = rootState.backend_host + '/make_notice_read'
            let formData = new FormData()
            formData.append('notice_id', notice_id)
            formData.append('user_id', user_id)
            formData.append('has_read', has_read)
            // formData.append('notice_list', JSON.stringify(state.notice_list))
            return axios.post(path, formData)
            .then( response => {
                let code = response.data.code
                commit('update_notice_read', {notice_id: notice_id, code: code})
                commit('update_new_notice')
            })
            .catch( error => {
                console.log(error)
            })
        }


    }
}
export default logs

const gri2021_modal_open_status = {
    namespaced: true,
    state: {
        opened_modal_id: [],
    },
    mutations: {
        add_opened_bc_id(state, new_id){
            let new_set = state.opened_bc_id
            new_set = [] //모든 창 닫기
            new_set.push('bc_' + new_id)
            new_set = new Set(new_set) //중복제거
            state.opened_modal_id = [...new_set]
        },
        delete_opened_bc_id(state, target_id){
            let idx = state.opened_modal_id.findIndex(item => item == 'bc_' + target_id)
            if (idx > -1){
                state.opened_modal_id.splice(idx, 1)
            }
        },
        add_opened_data_table_id(state, new_id){
            let new_set = state.opened_bc_id
            new_set = [] //모든 창 닫기
            new_set.push('data_table_' + new_id)
            new_set = new Set(new_set) //중복제거
            state.opened_modal_id = [...new_set]
        },
        delete_opened_data_table_id(state, new_id){
            let idx = state.opened_modal_id.findIndex(item => item == 'data_table_' + new_id)
            if (idx > -1){
                state.opened_modal_id.splice(idx, 1)
            }
        }
    },
    getters: {
    },
    actions: {

    }
}
export default gri2021_modal_open_status
<template>
  <div>
    <div v-if="loaded == true">
      <div class="custom-table" v-if="modal_table == false">
        <div v-if="show_title == true" class="custom-table-caption">
          <fa icon="table" class="mr-1" /> {{ lang != "ENG" ? title : title_e }}
        </div>
        <div class="mb-4">
          <b-table-simple v-if="lang != 'ENG'" responsive class="edi-table">
            <b-thead 
              ><b-tr>
                <b-td
                  class="custom-thead-td"
                  v-for="(property, col_index) in header"
                  :key="col_index"
                  >{{ property }}</b-td
                >
              </b-tr></b-thead
            >
            <b-tbody
              ><b-tr v-for="row_item in contents" :key="row_item.id">
                <b-td v-for="property in header" :key="property.id">{{
                  row_item[property]
                }}</b-td>
              </b-tr></b-tbody
            >
          </b-table-simple>
          <b-table-simple v-else responsive class="edi-table">
            <template v-if="header_e != null">
              <b-thead
                ><b-tr>
                  <b-td
                    v-for="(property, col_index) in header_e"
                    :key="col_index"
                    >{{ property }}</b-td
                  >
                </b-tr></b-thead
              >
              <b-tbody
                ><b-tr v-for="row_item in contents_e" :key="row_item.id">
                  <b-td v-for="property in header_e" :key="property.id">{{
                    row_item[property]
                  }}</b-td>
                </b-tr></b-tbody
              >
            </template>
          </b-table-simple>
          <p class="f-85 gray-600 mb-0">
            {{ lang != "ENG" ? footnote : footnote_e }}
          </p>
        </div>
      </div>
      <div v-else>
        <a class="mb-2 mr-3 cursor" :style="main_color" @click="showDataTable()"
          ><fa icon="table" class="mr-1" />
          {{ lang != "ENG" ? title : title_e }}
        </a>
        <div
          v-show="opened_modal_id.includes('data_table_' + data_table_id)"
          class="hj-modal-example bg-white rounded shadow"
        >
          <div
            class="hj-modal-header fw-500 d-flex align-items-center justify-content-between px-3"
          >
            <div>
              <b-badge variant="border-light" class="mr-2">Data Table.</b-badge>
              {{ lang != "ENG" ? title : title_e }}
            </div>
            <div style="min-width: 74px" align="right">
              <b-button
                variant="icon-light"
                @click="low_height = !low_height"
                class="py-0 px-2 mr-1"
                ><fa
                  :icon="low_height == true ? 'expand' : 'compress-alt'"
                ></fa> </b-button
              ><b-button
                variant="icon-light"
                @click="closeDataTable()"
                class="py-0 px-2"
                ><fa icon="times"></fa>
              </b-button>
            </div>
          </div>
          <div
            class="hj-modal-body p-3"
            :style="
              low_height == true ? 'height:calc(50vh - 50px)!important' : ''
            "
          >
            <b-table-simple
              v-if="lang != 'ENG'"
              responsive
              class="bor-bg-table"
            >
              <b-thead
                ><b-tr>
                  <b-td
                    v-for="(property, col_index) in header"
                    :key="col_index"
                    >{{ property }}</b-td
                  >
                </b-tr></b-thead
              >
              <b-tbody
                ><b-tr v-for="row_item in contents" :key="row_item.id">
                  <b-td v-for="property in header" :key="property.id">{{
                    row_item[property]
                  }}</b-td>
                </b-tr></b-tbody
              >
            </b-table-simple>
            <b-table-simple v-else responsive class="bor-bg-table">
              <b-thead
                ><b-tr>
                  <b-td
                    v-for="(property, col_index) in header_e"
                    :key="col_index"
                    >{{ property }}</b-td
                  >
                </b-tr></b-thead
              >
              <b-tbody
                ><b-tr v-for="row_item in contents_e" :key="row_item.id">
                  <b-td v-for="property in header_e" :key="property.id">{{
                    row_item[property]
                  }}</b-td>
                </b-tr></b-tbody
              >
            </b-table-simple>
            <p class="f-85 gray-600 mb-0">
              {{ lang != "ENG" ? footnote : footnote_e }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-skeleton class="mb-4"></b-skeleton>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import * as ih from "@/components/util";

export default {
  components: {},
  data() {
    return {
      title: "",
      description: "",
      header: [],
      contents: [],
      footnote: "",
      options: {},
      time_stamp: "",
      title_e: "",
      description_e: "",
      header_e: [],
      contents_e: [],
      footnote_e: "",
      time_stamp_e: "",
      loaded: false,
      low_height: false,
    };
  },
  props: {
    data_table_id: { type: [String, Number], default: "" },
    modal_table: { type: Boolean, default: true },
    lazy_load: { type: Boolean, default: true },
    data_table_obj: {
      type: Object,
      default() {
        return {};
      },
    },
    show_title: { type: Boolean, default: true },
  },
  computed: {
    ...mapState(["backend_host"]),
    ...mapState("report_list", ["report_code", "lang", "view_mode"]),
    ...mapState("gri2021_modal_open_status", ["opened_modal_id"]), //open된 bc 확인
    ...mapState("data_tables", ["table_list"]),
    ...mapGetters("report_list", ["selected_report"]),
    main_color() {
      return `color:` + this.selected_report.main_color;
    },
    main_color_sub() {
      return (
        `color:` +
        this.selected_report.main_color +
        `; border-top: 1px solid ` +
        this.selected_report.main_color +
        `!important` +
        `; border-bottom: 1px solid ` +
        this.selected_report.main_color
      );
    },
  },
  methods: {
    ...mapMutations("gri2021_modal_open_status", [
      "add_opened_data_table_id",
      "delete_opened_data_table_id",
    ]),
    readDataTableEntity() {
      const path = this.backend_host + "/read_data_table_entity";

      return axios
        .get(path, {
          params: {
            data_table_id: this.data_table_id,
          },
        })
        .then((response) => {
          this.title = response.data.title;
          this.description = response.data.description;
          this.header = response.data.header;
          this.contents = response.data.contents;
          this.footnote = response.data.footnote;
          this.options = response.data.options;
          this.time_stamp = response.data.time_stamp;
          this.time_stamp_e = response.data.time_stamp_e;
          this.title_e = response.data.title_e;
          this.description_e = response.data.description_e;
          this.header_e = response.data.header_e;
          this.contents_e = response.data.contents_e;
          this.footnote_e = response.data.footnote_e;
          this.loaded = true;
        });
    },
    showDataTable() {
      this.add_opened_data_table_id(this.data_table_id);
    },
    closeDataTable() {
      this.delete_opened_data_table_id(this.data_table_id);
    },
  },
  created() {
    //1. Prop 있는 경우 그냥 최우선
    if (this.data_table_obj.hasOwnProperty("table_id") == true) {
      this.title = this.data_table_obj.title;
      this.description = this.data_table_obj.description;
      this.header = this.data_table_obj.header;
      this.contents = this.data_table_obj.contents;
      this.footnote = this.data_table_obj.footnote;
      this.options = this.data_table_obj.options;
      this.time_stamp = this.data_table_obj.time_stamp;
      this.time_stamp_e = this.data_table_obj.time_stamp_e;
      this.title_e = this.data_table_obj.title_e;
      this.description_e = this.data_table_obj.description_e;
      this.header_e = this.data_table_obj.header_e;
      this.contents_e = this.data_table_obj.contents_e;
      this.footnote_e = this.data_table_obj.footnote_e;
      this.loaded = true;
    }

    //2. Lazy Load 옵션 줘서 직접 읽는 경우
    if (this.lazy_load == true && this.loaded == false) {
      this.readDataTableEntity();
    }

    //3. lazy load 하지 않고 미리 읽은 상태 & prop 전달 못 받은 경우, Store Table List에서 검색.
    if (this.lazy_load == false && this.loaded == false) {
      let idx = this.table_list.findIndex((item) => {
        return item.table_id == this.data_table_id;
      });

      if (idx > -1) {
        this.title = this.table_list[idx].title;
        this.description = this.table_list[idx].description;
        this.header = this.table_list[idx].header;
        this.contents = this.table_list[idx].contents;
        this.footnote = this.table_list[idx].footnote;
        this.options = this.table_list[idx].options;
        this.time_stamp = this.table_list[idx].time_stamp;
        this.time_stamp_e = this.table_list[idx].time_stamp_e;
        this.title_e = this.table_list[idx].title_e;
        this.description_e = this.table_list[idx].description_e;
        this.header_e = this.table_list[idx].header_e;
        this.contents_e = this.table_list[idx].contents_e;
        this.footnote_e = this.table_list[idx].footnote_e;
        this.loaded = true;
      } else {
        //아직 로딩되지 않은 상태
        this.readDataTableEntity();
      }
    }
  },
};
</script>

<template>
<div>
    <h5 class="f-110 mb-4 tail-line-dark">데이터 테이블 관리</h5>
    <b-row no-gutters class="py-2 mb-2">
        <b-col cols="4"> 데이터 테이블 명</b-col>
        <b-col> {{ local_table.title }} </b-col>
    </b-row>
    <b-row no-gutters class="py-2 mb-2" v-if="local_table.used_codes.length>0">
        <b-col cols="4"> 적용된 보고 지표</b-col>
        <b-col>
            <div v-for="code in local_table.used_codes" :key="code.id" class="mb-2">
                <!-- <template v-if="validateUsedCodes(code)==true"> -->
                <template >
                    <a class="cursor" @click="goHash(code)"> GRI {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                </template>
            </div>
            <div v-for="code in local_table.used_codes_sasb" :key="code.id" class="mb-2">
                <!-- <template v-if="validateUsedCodes(code)==true"> -->
                <template >
                    <a class="cursor" @click="goHash(code)"> SASB {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                </template>
            </div>
            <div v-for="code in local_table.used_codes_tcfd" :key="code.id" class="mb-2">
                <!-- <template v-if="validateUsedCodes(code)==true"> -->
                <template >
                    <a class="cursor" @click="goHash(code)"> TCFD {{ code }} 바로가기 <fa icon="link" class="ml-1"/></a>
                </template>
            </div>
            <div v-for="(linked_info, index) in table_linked_indicators[table_id]" :key="index" class="mb-2">
                <template >
                    <a class="cursor" @click="goHash(linked_info, 'DATA_CENTER')"> 
                        Data Center {{ linked_info['lv1_info']['name'] }} > 
                        {{ linked_info['lv2_info']['name'] }} <fa icon="link" class="ml-1"/>
                    </a> 
                </template>
            </div>

        </b-col>
    </b-row>
    <b-row no-gutters class="py-2 mb-2">
        <b-col cols="4"> 모든 지표에서 테이블 삭제</b-col>
        <b-col>
            <p class="mb-3" v-if="local_table.used_codes.length>0">‘{{local_table.title}}’ 데이터 테이블이 적용된 보고 지표가 있습니다. 삭제 시 적용된 모든 지표에서 해당 내용이 사라지며, 다시 복구할 수 없습니다.</p>
            <b-button variant="red" @click="handleDelete">테이블 삭제하기</b-button>
        </b-col>
    </b-row>
    <b-button class="mt-5 mb-3" @click="handleCancle"> 리스트로 돌아가기 </b-button>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import * as ih from '@/components/util'

export default {
    data() {
        return {
            local_table: {}
        }
    },
    props:{
        table_id : { type: String, default: () => '' }, // table_id 반드시 받아야 함 (WritingMaster.vue 또는 MgDataTables로 부터)
        editing_mode: { type: Boolean, default: () => false }
    },
    computed:{
        ...mapState('report_list', ['report_code']),
        ...mapState('data_tables', ['table_list']),
        ...mapState('charts', ['table_linked_indicators']),
        ...mapGetters('report_indicators', ['selected_indicators_gri_codes'])
    },
    methods:{
        ...mapActions('data_tables',['deleteDataTable']),
        handleCancle(hide_option) {
            if (!this.editing_mode) {
                if(hide_option=='hide_all') {
                    this.$emit('cancle-edit', hide_option)
                }else this.$emit('cancle-edit')
                
            } else {
                this.$emit("flag_modal_mode", "table_list")
            }
        },
        async handleDelete(){
            if (confirm('본 데이터 테이블을 사용하고 있는 모든 지표에서 삭제되며 되돌릴 수 없습니다. 삭제하시겠습니까?') ==true){
                await this.deleteDataTable(this.local_table.table_id)
            }
            this.handleCancle('hide_all')
        },
        goHash(code){
            let hash_code = '#w'+code
            this.handleCancle('hide_all')
             // this.$router.push({ name: 'Gri'+code.slice(0,1)*100, hash: hash_code })
             if (code.split("-")[0].length > 1) {
                this.$router.push({ name: 'Gri'+code.slice(0,1)*100, hash: hash_code })
                
            } else {
                this.$router.push({ name: 'Gri'+code.split("-")[0], hash: hash_code })
            }
        },
        validateUsedCodes(code){
            if (code){
                // console.log(code);
                // console.log(this.selected_indicators);
                let jdx = this.selected_indicators_gri_codes.findIndex(item => item == code)
                if (jdx > -1) {
                    return true
                }
                else return false
            }
        }
    },
    created(){
        let idx = this.table_list.findIndex(x=>x.table_id == this.table_id)
        if(idx > -1){
            this.local_table = this.table_list[idx]
        }
    }

}
</script>

<style>

</style>
<template>
    <div class="report-basic-header">
        <b-navbar
            class="report-basic-nav w-100"
            :class="[{ show: show_nav }]"
            toggleable="lg"
            fixed="top"
            :style="[show_nav && theme_option ? { backgroundColor: theme_option.nav_bar_color } : { backgroundColor: 'rgb(255 255 255 / 0%)' }]"
            >
            <b-navbar-brand v-if="selected_report.logo_path !== null">
                <b-img v-if="!theme_option.is_dark_option"
                    :src="$store.state.static_host+selected_report.logo_path"
                    class="report-logo-xs"
                    :style="{ 'max-height': '40px' }"
                    />
                <b-img v-if="theme_option.is_dark_option && dark_logo_url !== null"
                    :src="dark_logo_url"
                    class="report-logo-regular"
                    :style="{ 'max-height': '40px' }"
                    />
            </b-navbar-brand>
            
            <div class="report-basic-name ml-4"
                :class="[{ show: content_list_open }]"
                >
                {{ lang !== 'ENG' ? selected_report.report_name : selected_report.report_name_e }}
            </div>
            
            <b-navbar-nav class="flex-row ml-auto">
                <b-button-group class="mr-3">
                    <b-button 
                        v-if="active_gri_table" 
                        @click="$emit('showTableView', 'GRI')" 
                        :variant="show_gri_table ? 'dark-border-selected' : 'dark-border'"
                        size="md"
                        :disabled = show_gri_table
                    >
                        GRI
                    </b-button>
    
                    <b-button 
                        v-if="active_sasb_table" 
                        @click="$emit('showTableView', 'SASB')" 
                        :variant="show_sasb_table ? 'dark-border-selected' : 'dark-border'"
                        size="md"
                        :disabled = show_sasb_table
                    >
                        SASB
                    </b-button>
                    <b-button 
                        v-if="active_tcfd_table" 
                        @click="$emit('showTableView', 'TCFD')" 
                        :variant="show_tcfd_table ? 'dark-border-selected' : 'dark-border'"
                        size="md"
                        :disabled = show_tcfd_table
                    >
                        TCFD
                    </b-button>

                    <b-button 
                        v-if="active_dc_table" 
                        @click="$emit('showTableView', 'DATA_CENTER')" 
                        :variant="show_dc_table ? 'dark-border-selected' : 'dark-border'"
                        size="md"
                        :disabled = show_dc_table
                    >
                        <div class="f-100 d-inline">Data Center</div>
                    </b-button>
                </b-button-group>

                <b-button 
                    v-if="theme_option.show_printing_btn && !selected_report.sample_report" 
                    variant="dark-border" 
                    size="lg" 
                    class="p-0 mr-2 preview-tool-btn" 
                    v-print="'#full-report-preview'"
                    v-b-tooltip.hover 
                    :title="lang!='ENG'? '출력하기':'Print to PDF'"
                > 
                    <fa icon="print"/>
                </b-button>

                <b-button 
                    v-if="theme_option.show_translation_btn" 
                    variant="dark-border" 
                    size="lg" 
                    class="p-0 mr-2 preview-tool-btn" 
                    @click="toggleLang" 
                    v-b-tooltip.hover 
                    :title="lang!='ENG'?'영문보기':'Korean'"
                    >
                    <fa icon="language"/>
                </b-button>  
                <b-button v-if="view_mode !== 'viewer'"
                    variant="dark-border"
                    size="lg"
                    class="p-0 mr-2 preview-tool-btn"
                    @click="$router.push({ name: 'CheckReport' })"
                    v-b-tooltip.hover
                    title="미리보기 닫기"
                    >
                    <fa icon="times"/>
                </b-button> 
            </b-navbar-nav>
        </b-navbar>  

        <div class="position-relative report-basic-header-body">
            <template v-if="has_background_image">
                <div :class="['report-basic-header-bg background-img', { blured: content_list_open }]"
                    :style="main_bg_color_gradient"
                    />
            </template>
            <template v-else>
                <div :class="['report-basic-header-bg background-color', { blured: content_list_open }]"
                    :style="main_bg_color_gradient"
                    />
            </template>

            <b-container 
                class="text-center d-flex justify-content-center align-items-center flex-column position-relative report-basic-header-title" 
                :class="[{ show: !content_list_open }]"
                >
                <b-row>
                    <b-col cols="12">
                        <!-- <div class="p-3" :style="`color: ${theme_option.title_color}`"> -->
                        <div class="p-3" :style="{ color: dynamicTextColor }">

                            <h1 class="fw-600 f-290 line-height-lg"
                                :style="`font-size: ${theme_option.title_size}rem; font-family:${selected_report.font_family};`" 
                                >
                                {{ lang !== 'ENG' ? selected_report.report_name : selected_report.report_name_e }}
                            </h1>
                            <h6>
                                {{ selected_report.start_date }} ~ {{ selected_report.end_date }}
                            </h6>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            <div v-if="show_gri_table" :class="['report-basic-contents-list-wrapper', 'text-right w-100',
                { 'collapsed': !content_list_open },
                { 'pb-5': content_list_open }
                ]">
                <contents-list-basic
                    :content_list_open="content_list_open"
                    :preview_mode="true"
                    :web_view="true"
                    >
                </contents-list-basic>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState, mapGetters, mapMutations } from 'vuex';
import ContentsListBasic from './ContentsListBasic.vue'

export default {
    name: 'ViwerHeaderBacis',
    props:{
        theme_option: {
            type: Object,
            default: () => {}
        },
        dark_logo_url: {
            type: String,
            default: () => null
        },
        main_bg_color_gradient: {
            type: [Object, String]
        },
        content_list_open: {
            type: Boolean,
            default: () => false
        },
        show_nav: {
            type: Boolean,
            default: () => false
        },
        web_view: {
            type: Boolean,
            default: () => false
        },
        active_gri_table: {
            type: Boolean,
            default: () => false,
        },
        active_sasb_table: {
            type: Boolean,
            default: () => false,
        },
        active_tcfd_table: {
            type: Boolean,
            default: () => false,
        },
        show_gri_table: {
            type: Boolean,
            default: () => false,
        },
        show_sasb_table: {
            type: Boolean,
            default: () => false,
        },
        show_tcfd_table: {
            type: Boolean,
            default: () => false,
        },
        active_dc_table: {
            type: Boolean,
            default: () => false
        },
        show_dc_table: {
            type: Boolean,
            default: () => false
        },
    },
    components:{
        ContentsListBasic
    },
    data() {
        return {
            dynamicTextColor: null
        }
    },
    computed: {
        ...mapState('report_list', ['report_code', 'lang', 'view_mode']),
        ...mapGetters('report_list', ['selected_report']),        
        main_color(){
            return this.selected_report.main_color
        },
        has_background_image () {
            return !!this.main_bg_color_gradient['background-image']
        },
    },
    created() {
        if (this.theme_option) {
            if (this.theme_option.title_color === '#FFFFFF') {
                // #FFFFFF가 가장 기본값이라서.
                if (this.has_background_image === true) {
                    this.dynamicTextColor = "white"
                } else {
                    this.dynamicTextColor = "black"

                }
                
            } else {
                this.dynamicTextColor = this.theme_option.title_color;
            }
        }
    },
    methods: {
        ...mapMutations('report_list', ['change_lang']),

        toggleLang () {
            if (this.lang=='KOR') { 
                this.change_lang('ENG')
            } else {
                this.change_lang('KOR')
            }
        }
    }
}

</script>

<style lang="scss" scoped>

@media print {
    @page {
        margin: 0cm; /* 여백 조절 */
    }
}
.report-basic-header {
    &-body {
        min-height: 100vh;
        padding-top: 70px;
    }

    &-title {
        transition-property: opacity, min-height, height;
        transition-duration: 0.5s, 1s, 1s;
        transition-delay: 0s, 0s, 0s;
        transition-timing-function: ease, ease, ease;
        min-height: 170px;
        height: 0px;
        opacity: 0;

        &.show {
            // transition-duration: 1s, 0.7s, 0.7s;
            transition-duration: 1s, 0.5s, 0.5s;
            transition-delay: 1s, 0s, 0s;
            min-height: 170px;
            height: calc(80vh - 69.8px);
            opacity: 1;
        }
    }

    &-bg {
        transition-property: opacity;
        transition-duration: 0.75s;
        transition-timing-function: ease;
        transition-delay: 0.5s;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;

        &.blured {
            transition-delay: 0s;

            &.background-img {
                opacity: 0.15 !important;
            }

            &.background-color {
                opacity: 0.35 !important;
            }
        }
    }
}

.report-basic-nav {
    transition: background-color 0.3s ease-in-out;
    z-index: 999;
}

.report-basic-name {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;

    &.show {
        opacity: 1;
    }
}
</style>

<template>
    <div>
        <div class="mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center">
            <!-- {{ chartData.label }} -->
            <div
                :id="`chart-${chart_id}`"
                class="business-case-link cursor mb-2 fw-500"
                :style="view_mode==='viewer' ? main_color : 'color: #00B5C0;'"
                @click="open_chart_canvas"
            >
                <b-icon class="mr-1" icon="graph-up" scale="1" shift-v="1.25" aria-hidden="true" /> 
                <span v-if="chartData">
                    {{ chartData.label }}
                </span>
            </div>

            <div v-if="!viewer_mode" >
                <b-button 
                    variant="light" 
                    :disabled="is_unlinked==true" 
                    class="py-1 px-2" 
                    v-b-tooltip.hover 
                    title="편집" 
                    @click.stop.prevent="editChart"
                >
                    <fa icon="pen"/>
                </b-button>
                <b-button 
                    variant="light" 
                    :disabled="is_unlinked==true" 
                    class="ml-1 py-1 px-2" 
                    v-b-tooltip.hover 
                    title="없애기" 
                    @click.stop.prevent="handleUnlink"
                >
                    <fa icon="unlink"/>
                </b-button>
            </div>
            
        </div>


        


        <b-modal 
            :id="'chart_modal_'+ chart_id" 
            v-model="modalVisible" 
            size="lg"
            centered
            hide-footer
        >
            <template #modal-title>
                <!-- <b-badge variant="basic" class="mr-2">Case.</b-badge>
                <span v-if="hashed_business_cases && hashed_business_cases[bc]">{{ hashed_business_cases[bc].title }}</span>  -->
            </template>
            <canvas
                :id="'chart_canvas_' + chart_id"
                ref="qesgChart" 
                :style="'max-height: ' + chartData?.options?.chart_height + 'px;'"
                class="border border-light shadow-sm rounded"
            ></canvas>
            <small v-if="chartData && chartData.options" class="text-muted p-4">
				{{chartData.options.footnote}}
			</small>
        </b-modal>



        <b-modal ref="edit-chart-case" id="global-chart-editor-modal" title="차트 사례" hide-footer size="xl" scrollable @hidden="is_default_chart=true">
            <DefaultChart
                v-if="is_default_chart"
                @closeEditChartModal = "goToChartList"
                :lang="lang"
            />

            <ChartCaseList
                v-else
                :editing_mode = true
                :selected_chart_list="selected_chart_list"
                @closeModal="closeEditChartModal"
                @flag_modal_mode="flag_modal_mode"
            />

        </b-modal>
        

    </div>
</template>
<script>
import Chart from "chart.js/auto"; // 최신 버전의 Chart.js를 사용하려면 'chart.js/auto'를 사용합니다.
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import ChartCanvas from "@/components/writing_report/chart_subs/ChartCanvas.vue";
import DefaultChart from '@/components/writing_report/chart_subs/DefaultChart'
import ChartCaseList from '@/components/writing_report/chart_subs/ChartCaseList.vue';

export default {
    name: 'ChartTextCanvas',
    props: {
        payload : { type: Object, default: () => null },
        chart_id: { type: Number, default: () => null },
        is_able_to_edit: { type: Boolean, default: () => false },
        editing_gri_code: { type: String, default: () => '' },
        selected_chart_list: { type: Array, default: () => [] },
        viewer_mode: { type: Boolean, default: () => false },
        lang: { type: String, default: () => 'KOR' },
    },
    components: {
        ChartCanvas,
        DefaultChart,
        ChartCaseList
    },
    computed: {
        ...mapState('report_list', ['report_code', 'view_mode', 'standard_mode']), 
        ...mapGetters('report_list', ['selected_report']),

        main_color(){
            return `color:`+this.selected_report.main_color
        },

    },
    data() {
        return {
            always_true: true,
            chart: null,
            chartData: null,
            is_rendered: true,
            show_chart: true,
            modalVisible: false,
            is_default_chart: true,
            is_unlinked: false,

        }
    },
    beforeCreate() {},
    created() {
    },
    beforeMount() {},
    mounted() {
        if (this.payload !== null) {
            this.chartData = { ...this.payload };
        }
        // if (this.chart_id !== null) {
        //     this.getChartData();
        // }
        
        
        this.renderChart();
    },
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    methods: {
        // async getChartData() {
        //     const response = await axios.get(`${this.$store.state.backend_host}/getChartDataById?id=${this.chart_id}`)

        //     if (response.data.code) {
        //         this.chartData = response.data.result.contents

        //         // this.chartData = Object.assign({}, this.chartData, 
        //         //     response.data.result.contents 
        //         // )
        //     } else {
        //         console.log('여긴뭐지')
        //     }


        // },
        ...mapActions("charts", ["updateChartIndicator"]),
        ...mapMutations('charts', ['update_selected_chart_id', 'update_selected_chart_data']),
        goToChartList() {
            this.is_default_chart = false
        },
        open_chart_canvas() {
            this.modalVisible = true;
            this.$nextTick(() => {
                this.renderChart();
            // 여기에서 this.$refs.ref_id를 사용하세요.
            });


        },
        async renderChart() {
            if (this.chartData === null) {
                const response = await axios.get(`${this.$store.state.backend_host}/getChartDataById?id=${this.chart_id}`)
                if (response.data.code) {
                    if (this.lang === 'ENG') {
                        this.chartData = response.data.result.contents_em ? response.data.result.contents_em : response.data.result.contents_ea
                    } else {
                        this.chartData = response.data.result.contents
                    }
                }
            }

            this.chartData.plugins = [
				{
					id: 'customCanvasBackgroundColor',
					beforeDraw: (chart, args, options) => {
						const { ctx } = chart;
						ctx.save();
						ctx.globalCompositeOperation = 'destination-over';
						ctx.fillStyle = options.color || 'white';
						ctx.fillRect(0, 0, chart.width, chart.height);
						ctx.restore();
					}
				}
            ]

            this.chartData.options.plugins['tooltip'] = {
              intersect: true,
              callbacks: {
                label: (context) => {
                  // return `${context.dataset.label}: ${context.parsed} (단위: ${context.dataset?.y_level})`;
                  return context.parsed.y !== undefined ? `${context.dataset.label}: ${context.parsed.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (단위: ${context.dataset?.unit})` : `${context.dataset.label}: ${context.parsed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (단위: ${context.dataset?.unit})`;
                }
              }
            }
			
			if (this.chart) {
				this.chart.destroy();
			}

            // const ctx = this.$refs.qesgChart.getContext("2d");
            // nextTick으로 해결이 안됨... :)....
            setTimeout(() => {
                if (document.getElementById('chart_canvas_' + this.chart_id)) {
                    const ctx = document.getElementById('chart_canvas_' + this.chart_id).getContext("2d")
                    this.chart = new Chart(ctx, this.chartData)
                    this.is_rendered = false;
                }
            }, 1)
        },


        editChart() {
            this.update_selected_chart_id(this.chart_id)
            this.update_selected_chart_data(this.chartData)

            if (this.editing_mode) {
                this.$emit("flag_modal_mode", "chart_edit")
            } else {
                this.$refs['edit-chart-case'].show()
            }
            

        },

        closeEditChartModal() {
            this.update_selected_chart_id(null)
            this.update_selected_chart_data(null)
        
            this.$refs['edit-chart-case'].hide()
            
        },

        handleUnlink() {
            this.is_unlinked = true;
            this.updateChartIndicator({
                "report_code": this.report_code,
                "type": this.standard_mode,
                "editing_code": this.editing_gri_code,
                "payload": this.selected_chart_list.filter(chart_id => chart_id !== this.chart_id)
            })


            this.is_unlinked = false;

        }
    },

    beforeDestroy() {
        this.update_selected_chart_id(null)
        this.update_selected_chart_data(null)
    },
}
</script>
<style>
</style>
<template lang="">
  <div class="card-body">
    {{priceTable}}
    <h6>당사 제공 솔루션(ESG Reporting Tool)을 활용하여, 국제표준(GRI)기반 ESG 정보 공시를 지원합니다. (*약 4~6주 소요)</h6>
    <table class="table text-center table-bordered b-table-fixed table-hover">
      <caption>
        <p>
          * 연 단위로 1개의 ESG보고서를 생성하고, 수정 및 편집할 수 있습니다. 정기결제이 비활성되면, 작성된 보고서는 유지되나 추가 수정/편집이 불가능합니다.<br />
          ** 컨설팅 서비스는 기본 연간 툴 사용료를 포함합니다. (별도지불 필요 x) <br />
        </p>
      </caption>
      <thead class="thead-light table-sm">
        <tr class="table-sm ">
          <th scope="col" rowspan="2" colspan="2">구분</th>
          <th scope="col" rowspan="2">
            Tool활용(A)<b class="text-danger">*</b>
            <!-- <p>
              <small class="text-muted">(자체작성/시험작성)</small>
            </p> -->
          </th>
          <th scope="col" colspan="4">
            컨설팅(선택사항)
          </th>
        </tr>
        <tr>
          <th scope="col">
            기본사항
            <!-- <p>
              <small class="text-muted">(AA1000AS)</small>
            </p> -->
          </th>
          <th scope="col" class="centered">
            +검증 서비스
            <p>
              <small class="text-muted">(AA1000AS)</small>
            </p>
          </th>
          <th scope="col">+자문 서비스
            <p>
              <small class="text-muted">(이중 중요성평가/콘텐츠 자문)</small>
            </p></th>
          <th scope="col">커스텀 <br>웹사이트 제작</th>
        </tr>
      </thead>
      <tbody>
        <tr >
          <th scope="row" colspan="2">매출30억미만</th>
          <td rowspan="3">
            20만원/월 </br>
            216만원/연간
          </td>
          <td rowspan="3">
            컨설팅 신청 시 </br>
            연간 툴 활용료 </br>
            포함하여 제공
          </td>
          <td>
            <b-form-checkbox
              id="small_verification_service"
              v-model="reportingProduction"
              name="small_verification_service"
              value="small_verification_service"
              unchecked-value="False"
            >
              {{
                priceTable !== null
                  ? priceTable["small_verification_service"]["price"]
                  : ""
              }}만원 (1회)
            </b-form-checkbox>
          </td>
          <td>
            <!-- <b-form-checkbox
              id="smallCompanies_griReportingGuidelines"
              v-model="reportingProduction"
              name="smallCompanies_griReportingGuidelines"
              value="smallCompanies_griReportingGuidelines"
              unchecked-value="False"
            >
              {{
                priceTable !== null
                  ? priceTable["smallCompanies_griReportingGuidelines"]["price"]
                  : ""
              }}만원 (5주)
            </b-form-checkbox> -->
          </td>
          <td>
            <!-- <b-form-checkbox
              id="smallCompanies_apiService"
              v-model="reportingProduction"
              name="smallCompanies_apiService"
              value="smallCompanies_apiService"
              unchecked-value="False"
            >
              {{
                priceTable !== null
                  ? priceTable["smallCompanies_apiService"]["price"]
                  : ""
              }}만원 / 년
            </b-form-checkbox> -->
          </td>
        </tr>
        <tr>
          <th scope="row" rowspan="2">매출30억이상</th>
          <td>
            300인 미만
          </td>
          <td>
            <b-form-checkbox
              id="medium_verification_service"
              v-model="reportingProduction"
              name="medium_verification_service"
              value="medium_verification_service"
              unchecked-value="False"
            >
              {{
                priceTable !== null
                  ? priceTable["medium_verification_service"]["price"]
                  : ""
              }}만원 / 리포트
            </b-form-checkbox>
          </td>
          <td>
            <!-- <b-form-checkbox
              id="mediumCompanies_griReportingGuidelines"
              v-model="reportingProduction"
              name="mediumCompanies_griReportingGuidelines"
              value="mediumCompanies_griReportingGuidelines"
              unchecked-value="False"
            >
              {{
                priceTable !== null
                  ? priceTable["mediumCompanies_griReportingGuidelines"][
                      "price"
                    ]
                  : ""
              }}만원 (5주)
            </b-form-checkbox> -->
          </td>
          <td>
            <!-- <b-form-checkbox
              id="mediumCompanies_apiService"
              v-model="reportingProduction"
              name="mediumCompanies_apiService"
              value="mediumCompanies_apiService"
              unchecked-value="False"
            >
              {{
                priceTable !== null
                  ? priceTable["mediumCompanies_apiService"]["price"]
                  : ""
              }}만원/년
            </b-form-checkbox> -->
          </td>
        </tr>
        <tr>
          <td>
            300인 이상
          </td>
          
          <td>
            <b-form-checkbox
              id="large_verification_service"
              v-model="reportingProduction"
              name="large_verification_service"
              value="large_verification_service"
              unchecked-value="False"
            >
              {{
                priceTable !== null
                  ? priceTable["large_verification_service"]["price"]
                  : ""
              }}만원 / 리포트
            </b-form-checkbox>
          </td>
          <td>문의필요</td>
          <td>
            <!-- <b-form-checkbox
              id="largeCompanies_apiService"
              v-model="reportingProduction"
              name="largeCompanies_apiService"
              value="largeCompanies_apiService"
              unchecked-value="False"
            >
              {{
                priceTable !== null
                  ? priceTable["largeCompanies_apiService"]["price"]
                  : ""
              }}만원/년
            </b-form-checkbox> -->
          </td>
        </tr>
      </tbody>
    </table>
    <div>체크된 값들: {{ reportingProduction }}</div>
    <div>결제 비용: {{ this.formatCurrency(this.priceSum) }}</div>

    <div>
      선택한것들:
      <div v-for="item in reportingProduction" :key="item.id">
        {{ priceTable !== null?priceTable[item]["describe"] : null }}
      </div>
    </div>

    <div>muid: {{ muid }}</div>

    <div>
      <b-form-select
        v-model="payOption"
        :options="payOptions"
        class="mb"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
        size="sm"
        style="width:20%;"
      ></b-form-select>

      <div class="mt-3">
        PayOption: <strong>{{ payOption }}</strong>
      </div>
    </div>
    <button @click="requestPay">결제버튼</button>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { preparePayment } from "@/components/payment/payment";

export default {
  // mounted() {
  //   const IMP = window.IMP; // const IMP를 추가합니다.
  // },
  mounted() {
    this.fetchPriceTable();
    this.fetchUid();
  },
  computed: {
    ...mapState([
      "e_mail",
      "user_name",
      "tel",
      "is_confirmed",
      "authenticated",
    ]),
  },
  data() {
    let nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    nextDay = nextDay
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, "");

    return {
      nextDay: nextDay,
      payOption: null,
      payOptions: [
        {
          value: null,
          name: "결제수단 선택",
        },
        {
          item: "card",
          name: "카드",
        },
        {
          item: "vbank",
          name: "가상계좌",
        },
      ],
      priceTable: null,
      muid: null,
      priceSum: 0,
      reportingProduction: [],
    };
  },
  watch: {
    reportingProduction: {
      deep: true,
      handler(selectedOptions) {
        //TODO: 기업규모별(smallCompanies, medium, large..) 로 교차 선택은 불가하게 이벤트 만들어야 할거같은데
        this.priceSum = 0;
        selectedOptions.map((item, index) => {
          this.priceSum += this.priceTable[item]["price"];
        });
      },
    },
  },
  methods: {
    requestPay: function() {
      if (this.payOption == null) {
        window.alert("결제수단을 선택해주세요.");
        return;
      }

      if (this.reportingProduction.length === 0) {
        // 선택된 상품 목록이 없는 경우
        console.log("선택된 상품 목록이 없는 경우");
        window.alert("무언가를 선택!");
      } else if (this.reportingProduction.length !== 0 && this.priceSum == 0) {
        // 선택된 상품 목록은 있는데 무료만 선택된 경우
        console.log("선택된 상품 목록은 있는데 무료만 선택된 경우");
      } else {
        // 선택된 상품 목록이 있고 유료 상품을 선택한 경우
        console.log("선택된 상품 목록이 있고 유료 상품을 선택한 경우");

        // TODO: 여기서 백엔드 서버와 통신해서 확인해야함..,결제 금액 대조라든가.
        preparePayment(this.muid, this.priceSum, {
          // param
          pg: "html5_inicis.INIpayTest", // 테스트
          pay_method: this.payOption,
          merchant_uid: this.muid,
          name: "QESG Reporting 결제", // 상품명
          amount: this.priceSum, // 결제 가격
          buyer_email: this.e_mail,
          buyer_name: this.user_name, //
          buyer_tel: this.tel,
          vbank_due: this.nextDay,
          // vbank_holder: "퀀티파이드이에스지",
          // buyer_addr: "구매자주소",
          // buyer_postcode: "구매자 우편번호",
        }).then((response) => {
          if (response) {
            this.showPayForm();
          } else {
            // 아마 개발자가 콘솔로 뭔가 잘못된 정보를 기입하려고 했을 때
            window.alert("결제정보가 잘못되었습니다. 관리자에게 문의해주세요.");
            window.location.reload();
          }
        });

        // let formData = new FormData();
        // formData.append("test", "test");
        // return axios.post(path, formData).then((response) => {
        //   console.log(response.data);
        // });
      }
    },
    showPayForm: function() {
      // 임시 주석처리 ===================================================================================================================
      const IMP = window.IMP;
      // IMP.init(this.$store.state.imp_pk_code); // 가맹점 식별코드
      IMP.agency(this.$store.state.imp_pk_code, 'GRI'); // 가맹점 식별코드
      IMP.request_pay(
        {
          // param
          pg: "html5_inicis.INIpayTest", // 테스트
          pay_method: this.payOption,
          merchant_uid: this.muid,
          name: "QESG Reporting 결제", // 상품명
          amount: this.priceSum, // 결제 가격
          buyer_email: this.e_mail,
          buyer_name: this.user_name, //
          buyer_tel: this.tel,
          vbank_due: this.nextDay,
          // vbank_holder: "퀀티파이드이에스지",
          // buyer_addr: "구매자주소",
          // buyer_postcode: "구매자 우편번호",
        },
        (rsp) => {
          // callback
          if (rsp.success) {
            // ...,
            // 결제 성공 시 로직,
            // ...
            const path =
              this.$store.state.backend_host + "/payment/update_payment";

            const sendData = {
              selectedProduct: this.reportingProduction,
              pSum: this.priceSum,
              muid: this.muid,
              userId: this.e_mail,
              rsp: rsp,
            };

            // axios를 사용하여 POST 요청 보내기
            return axios
              .post(path, sendData, {
                headers: {
                  "Content-Type": "application/json",
                  // 기타 보안 관련 헤더 설정
                },
                // 기타 axios 설정
              })
              .then((response) => {
                console.log("응답:", response.data);

                // 페이지 이동처리!
                this.$router.push("/payment/testDetail");
              })
              .catch((error) => {
                // 오류 처리
                console.error("오류:", error);
              });
          } else {
            // 결제 실패 시 로직,
            console.log("결제실패");
            window.location.reload();
          }
        }
      );
      // ==============================================================================================================================================
    },
    formatCurrency: function(amount) {
      // TODO: 이런건 util.js같이 utility 함수파일을 별도로 만들어서 쓸 수 있게 하고싶은데..
      const formatter = new Intl.NumberFormat("ko-KR");
      return formatter.format(amount);
    },

    fetchPriceTable() {
      axios
        .get(this.$store.state.backend_host + "/payment/get_price_table")
        .then((response) => {
          this.priceTable = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    fetchUid() {
      axios
        .get(
          this.$store.state.backend_host +
            "/payment/init_id?request_type=report"
        )
        .then((response) => {
          this.muid = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style>
/* th,
td {
  vertical-align: middle;
  height: 10px;
} */
</style>

<template>
  <div>
    <b-overlay :show="loadingShow" no-wrap></b-overlay>

    <h3>사용자 관리</h3>
    <b-container fluid>
      <!-- Main table element -->
      <b-table
        :items="this.user_list"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
        hover
        empty-filtered-html="검색 결과가 없습니다 😥"
        empty-text="사용자가 없습니다."

      >
      <template #table-caption>
        <span v-if="user_list !== null" class="mb-0 red">{{totalRows}}</span>명 조회됨
      </template>

        <template #cell(productName)="row">
          {{ row.value.first }} {{ row.value.last }}
        </template>

        <template #cell(actions)="row">
          <b-button
            size="sm"
            @click="info(row.item, row.index, $event.target)"
            class="mr-1"
          >
            Info modal
          </b-button>
          <b-button size="sm" @click="row.toggleDetails">
            {{ row.detailsShowing ? "Hide" : "Show" }} Details
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <!-- Detail 부분 -->
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>

      <!-- Info modal -->
      <b-modal
        :id="infoModal.id"
        :title="infoModal.title"
        ok-only
        @hide="resetInfoModal"
      >
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <!-- User Interface controls -->
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "e_mail",
          label: "이메일(아이디)",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "user_name",
          label: "성명",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "tel",
          label: "연락처",
          sortable: true,
          class: "text-center",
          sortDirection: "desc",
        },
        {
          key: "confirm_code",
          label: "확인코드",
          sortable: true,
          class: "text-center",
          sortDirection: "desc",
        },
        {
          key: "is_active",
          label: "활동여부",
          sortable: true,
          class: "text-center",
          sortDirection: "desc",
        },
        {
          key: "is_admin",
          label: "관리자여부",
          sortable: true,
          class: "text-center",
          sortDirection: "desc",
          formatter: (value, key, item) => {
            return value ? "Yes" : "No";
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "payment_status",
          label: "Is Active",
          formatter: (value, key, item) => {
            return value ? "Yes" : "No";
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        { key: "actions", label: "수정버튼(추후 작업..)" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      user_list: null,
      loadingShow: true,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.fetchAllUserListForAdmin();
  },
  methods: {
    fetchAllUserListForAdmin() {
      const path = this.$store.state.backend_host + "/fetchAllUserListForAdmin";

      return axios.post(path).then((response) => {
        this.user_list = response.data;
        this.totalRows = this.user_list.length;
        this.loadingShow = false;
      });
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {
    this.fetchAllUserListForAdmin();
  },
};
</script>
<style lang=""></style>

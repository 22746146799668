<template>
    <b-container fluid class="p-0 mb-4">
        <b-card class="border-0 px-3 py-5 fw-400">
            <div class="px-md-2 px-lg-4">
                <div class="d-flex mb-4 "><h4 class=""> 독립된 검증인의 제3자 검증 보고서</h4></div>
                <h6 class="f-110 fw-500">주식회사 코웨이 이해관계자 귀중</h6>
                <p>퀀티파이드이에스지(이하 ‘검증인’)는 주식회사 코웨이(이하 ‘보고 기관’)가 발행한 ‘2019 코웨이 지속가능성 웹보고서(이하 ‘보고서’)에 대하여 제3자 검증(3rd Party assurance engagement)을 수행하였으며, 다음과 같이 검증성명서(이하 ‘검증 의견’)를 제출합니다.
                </p>
                <p class="mb-45 indent-lg"> &rtrif; 검증대상: 2019 코웨이 지속가능성 웹보고서 http://sustainability.coway.co.kr <br>
                    (2020년 6월 30일 기준, 보고기간: 2019년 1월 ~ 2019년 12월)
                </p>
                <h6 class="f-110 fw-500">검증의 범위 및 제한 사항</h6>
                <p class="mb-45"> 검증인의 검증 업무는 검증대상 보고서에 나타난 정보에 국한되었으며, 검증인은 외부 감사인에게 감사를 받는 재무 정보에 대해서는 검증하지 않았습니다. 또한 검증인은 보고 기관이 제시한 미래 전망(Forward-looking statements)에 대해서는 검증하지 않았습니다.
                </p>
                <h6 class="f-110 fw-500">코웨이 경영진의 책무</h6>
                <p> 코웨이 경영진은 정확한 정보가 기술된 보고서를 작성하기 위한 내부 통제제도의 구현과 보고서에 표현된 정보에 대한 최종적 책임이 있습니다. 보고 기관은 다음 기준을 준용하여 보고서를 작성하였습니다. 
                </p>
                <p class="indent-lg"> &rtrif; GRI(Global Reporting Initiatives) 보고 표준 – 핵심 부합방법(Core Option)<sup>1)</sup>
                </p>
                <div class="mb-45 fw-300 gray-600 f-80">1) GRI 부합 방법(In Accordance Criteria): GRI Standards는 GRI 보고 부합방법(In Accordance)을 ‘핵심(Core)’적 부합과 ‘포괄(Comprehensive)’적 부합으로 구분합니다. 핵심적(Core) 부합방법은 일반표준공개 일부와, 파악된 모든 중요 측면(Material Aspects)의 경영접근(Management Approach, MA)와 하나 이상의 특정표준공개지표를 보고하는 것으로 충족됩니다.
                </div>
                <h6 class="f-110 fw-500">독립성 및 내부 통제</h6>
                <p class="mb-45"> 본 검증인은 독립된 검증 기관으로서 보고 내용의 생산, 작성에 관여하지 않았으며, 작성된 보고서에 대한 객관적 검증 업무를 토대로 검증 의견을 작성할 책임을 지니고 있습니다. 본 검증인은 검증 업무를 수행하는데 독립성을 저해할 수 있는 어떠한 이해관계도 보고 기관과 맺고 있지 않습니다.
                </p>
                <h6 class="f-110 fw-500">검증인의 책무와 검증 진행경과</h6>
                <p class="mb-2"> 검증인은 AA1000AS(2008) Type II 검증 유형<sup>2)</sup>의 중간(Moderate) 수준의 신뢰도<sup>3)</sup>를 달성하기 위한 검증 절차(assurance engagement process)를 따랐습니다. AA1000AP(2018) 4대 원칙인 포괄성, 중요성, 대응성, 영향에 대한 준수 여부를 검토하였습니다. 보고서에 나타난 지표 및 정보의 균형성, 비교가능성, 정확성, 적시성, 명확성, 신뢰성을 보고 기관으로부터 제시 받은 문서 기반 정보 및 인터뷰를 통해 확인하였습니다. 
                </p>
                <p>본 검증 업무는 다음과 같은 방법으로 진행되었습니다.
                </p>
                <ul class="f-95 mb-4 fw-300">
                    <li><span class="gray7">지속가능성 전략 및 중요 성과 지표(Materiality Matrix)에 대한 책임자 인터뷰</span></li>
                    <li><span class="gray7">이해관계자 참여 과정, 결과, 외부 미디어 및 동종 기업 지속가능성 보고서를 통해 결정된 중요 주제에 대한 리뷰</span></li>
                    <li><span class="gray7">GRI 보고 표준에 따라 작성된 각 지표별 데이터 수집 및 작성 절차, 내부 통제의 신뢰성 확인을 위한 담당자 인터뷰</span></li>
                    <li><span class="gray7">보고 기관의 지속가능성 정보 수집, 분석, 종합 절차의 평가를 위한 샘플링 기반의 서면 자료 평가</span></li>
                    <li><span class="gray7">GRI 보고 핵심 부합방법(Core Option) 충족 여부 판단</span></li>
                    <li><span class="gray7">SASB 가전제품 제조, 전문 및 상업서비스 주요 지표 보고사항에 대한 리뷰</span></li>
                    <li><span class="gray7">전반적인 지속가능성 보고 품질에 대한 리뷰</span></li>
                </ul>
                <div class="mb-2 fw-300 gray-600 f-80">2) 검증 유형(Type of Engagement): AA1000AS(2008)의 검증 유형은 AA1000AP(2018)에서 제시하는 4대 원칙(포괄성, 중요성, 대응성, 영향)에 대한 부합성을 검증하는 Type I 검증과, 원칙 검증에 더해 중요 지표의 신뢰성까지 검증하는 Type II 검증으로 구분됩니다.
                </div>
                <div class="mb-5 fw-300 gray-600 f-80">3) 신뢰도(Level of Assurance): AA1000AS(2008)의 신뢰도 수준은 높은 수준(High)의 검증과 중간(Moderate) 수준의 검증으로 구분됩니다. 중간(Moderate) 수준 검증은 진술에 대한 증거를 확인하기 위하여 제한적 깊이의 근거 확인, 낮은 수준의 샘플링을 통해 정보의 타당성을 확인하는데 주안점을 두고 있으며, 정보가 생산되는 절차의 신뢰성을 통해 결론을 내립니다.
                </div>
                    
                <h6 class="f-110 fw-500">검증 결과</h6>
                <p> 상기 기술된 검증 절차에 따른 검증 수행 결과 검증인은 관련 보고 기준 및 이해관계자의 판단에 영향을 미칠 수 있는 모든 중요한 측면에 대하여, 보고서가 적절치 않게 준비되었다고 결론 내릴 수 있는 어떠한 증거도 발견하지 못하였습니다.
                </p>

                <p><span class="fw-500">포괄성(Inclusivity): 이해관계자의 참여 &nbsp;|</span> &nbsp;보고 기관은 지속가능경영 이슈와 관련하여 포괄적인 이해관계자 참여를 실시하고 있습니다. 이를 위해 고객, 주주 및 투자자, 임직원, 파트너 및 협력사, 지역사회, 학계 및 언론 등 6개 그룹으로 주요 이해관계자를 정의하고 있으며, 각 그룹의 커뮤니케이션 채널과 기대사항을 명확히 구분, 적극적인 소통을 통해 이해관계자들의 의견을 경영정책에 반영하고 있음을 확인하였습니다.
                </p>
                <p><span class="fw-500">중요성(Materiality): 주요 이슈의 선정 및 보고 &nbsp;|</span> &nbsp;보고 기관은 GRI Standards, SASB 등 글로벌 지속가능성 표준 및 이니셔티브를 비롯해 미디어 및 대외 커뮤니케이션 키워드 분석을 토대로 지속가능경영 이슈를 26개로 정리하고 있음을 확인하였습니다. 또한 내·외부 이해관계자 의견 분석을 통해 최종 10개의 핵심이슈를 도출하였으며, 보고서 각 URL에 균형적으로 반영되어 있음을 확인하였습니다.
                </p>
                <p><span class="fw-500">대응성(Responsiveness): 이슈에 대한 조직의 대응 &nbsp;|</span> &nbsp;보고 기관은 이해관계자의 성과에 영향을 미치는 주요 기대사항을 파악하고, 파악된 주요 이슈에 대응하기 위한 활동을 실시했으며, 그 내용들을 보고서에 적절히 기술하였음을 확인하였습니다.
                </p>
                <p><span class="fw-500">영향(Impact): 조직이 미치는 영향에 대한 고려 &nbsp;|</span> &nbsp;보고 기관은 경영 활동이 더욱 넓은 생태계시스템에 미치는 영향을 모니터링하고 있습니다. 경제, 사회, 환경 부문의 영향 산정을 고도화하기 위한 방안으로 영향에 대한 정량 지표 산출을 위해 노력할 것을 권고 드립니다.
                </p>
                <hr class="border-0">
                <div class="text-right"> 2020년 07월</div>
                <hr class="border-0">
                <div class="text-right"> (주)퀀티파이드이에스지 <span class="mx-3"> 대 표 </span> 배 익 현</div>

                <hr class="border-bottom-gray py-0 mt-5 mb-3">
                <b-img src="/static_img/aa1000as.png" class="mr-4" style="width:150px;"></b-img>
                <b-img src="/static_img/qesg.png" class="" style="width:130px;"></b-img>
            </div>
        </b-card>

               
    </b-container>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    props:{

    },
    computed:{

    },
    methods:{
 
    },
    watch:{
        
    },
    created(){
        
    }

}
</script>

<style>

</style>
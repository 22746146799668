<template>
<div>
    <h4>GRI Topic - Economic</h4>
    <p class="f-95 mb-5 gray-600">경제 보고 주제의 각 보고 지표에 대해 내용을 작성합니다.</p>

    <div v-for="item in gri_200_topics" :key="item.id" class="mb-5">
    <h5 :id="'w'+item.topic_id" class="mb-4 border-bottom-dark pb-2">GRI {{item.topic_id}} - {{item.topic_name}} 
        <span class="gray-500 f-105 fw-400 ml-1">{{item.topic_name_e}}</span>
        <span class="gray-400 f-90 fw-400 ml-3">{{item.topic_publication_date}}</span>
    </h5>

        <b-table-simple responsive class="wri-table">
            <b-thead><b-tr>
                <b-td>지표</b-td><b-td style="width:45%">보고 내용 작성 <div class="d-inline-block float-right mx-2 f-90 green"><fa icon="check-circle" class="ml-1"/> : 저장됨 </div></b-td><b-td style="width:40%">작성 도우미</b-td>
            </b-tr></b-thead>
            <b-tbody>
                <b-tr v-for="item in series_contents[item.topic_name]" :key="item.id">
                    <b-td><span :id="'w'+item.gri_code" class="f-160" >{{ item.gri_code }}</span> <p class=""> {{ item.indicator_name }}</p></b-td>
                    <b-td>
                        <!-- 직접 작성 -->
                        <b-overlay :show="item.nan_category !== null">
                            <template #overlay>
                                <div
                                    ref="dialog"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-modal="false"
                                    aria-labelledby="form-confirm-label"
                                    class="text-center p-3"
                                >
                                    <p><strong id="form-confirm-label">보고가 생략된 지표입니다.</strong></p>
                                </div>
                            </template>
                        <div v-if="is_writing_direct[item.gri_code]==true" class="mb-45">
                            <b-form-textarea 
                            v-model="item.direct_report" 
                            rows="2" 
                            placeholder="지표에 대해 보고할 내용을 직접 작성합니다."
                            @blur="textInputSave(item.gri_code, item.direct_report)" 
                            :autofocus="is_writing_direct[item.gri_code]==true"
                            lazy="true"
                            ></b-form-textarea>
                            <template v-if="save_state[item.gri_code]=='saved'"><fa icon="check-circle" class="ml-1 green"/></template>
                            <template v-else-if="save_state[item.gri_code]=='saving'"><b-spinner class="green" small></b-spinner></template>
                        </div>
                        <div v-else @click.stop.prevent="changeWritingMode(item.gri_code)" class="mb-3">
                            <md :contents="item.direct_report" v-if="item.direct_report!=''&&item.direct_report!=undefined" class="hover-border pb-4">
                                <template v-if="save_state[item.gri_code]=='saved'"><fa icon="check-circle" class="ml-1 green"/></template>
                                <template v-else-if="save_state[item.gri_code]=='saving'"><b-spinner class="green" small></b-spinner></template>
                            </md>
                            <div v-else>

                                <div v-if="selected_report.is_able_to_edit" class="hover-border gray-500 rounded pb-4">클릭하여 작성하기</div>
                                <div v-else class="gray-500 rounded pb-4">
                                    작성된 내용이 없습니다.
                                </div>
                            </div>
                        </div>
                        <!-- 연결된 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
                        <section class="mb-5 px-3" v-if="item.data_tables.length>0">
                            <div class="border-bottom-gray-550 gray-600 f-90 pb-1"><fa icon="table" class="mr-1"/> 데이터 테이블</div>
                            <div v-for="dt in item.data_tables" :key="dt.id">
                                <div class="mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center">{{ hashed_data_tables[dt].title }} 
                                    <div style="min-width: 70px;">
                                        <b-button variant="light" :disabled="is_unlinked===true " class="py-1 px-2" v-b-tooltip.hover title="편집" @click.stop.prevent="addTables({gri_code: item.gri_code, table_id: dt})"><fa icon="pen"/></b-button>
                                        <b-button variant="light" :disabled="is_unlinked===true " class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click.stop.prevent="handleUnlink({gri_code: item.gri_code, table_id: dt})"><fa icon="unlink"/></b-button>
                                    </div>
                                </div>
                                <b-table-simple responsive class="bor-bg-table">
                                    <b-thead><b-tr>
                                        <b-td v-for="(property, col_index) in hashed_data_tables[dt].header" :key="col_index">{{ property }}</b-td>
                                    </b-tr></b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(row_item, row_index) in hashed_data_tables[dt].contents" :key="row_item.id">
                                            <template v-for="property in hashed_data_tables[dt].header">
                                                <b-td 
                                                    v-if="differentPrevious(hashed_data_tables[dt].contents, row_index, property, hashed_data_tables[dt].header)==true" 
                                                    :key="property.id" 
                                                    :rowspan="getSameLength(hashed_data_tables[dt].contents, row_index, property, hashed_data_tables[dt].header)"
                                                >
                                                    <span 
                                                        v-html=formattedText(row_item[property])>
                                                    </span> 
                                                </b-td>
                                            </template>
                                        </b-tr>
                                    </b-tbody>
                                    <caption v-if="hashed_data_tables[dt].footnote" class="mb-2">
                                        {{ hashed_data_tables[dt].footnote }}
                                    </caption>
                                </b-table-simple>
                            </div>
                        </section>
                        <section class="mb-5 px-3" v-if="item.documents.length>0"> 
                            <div class="border-bottom-gray-550 gray-600 f-90 pb-1"><fa icon="paperclip" class="mr-1"/> 문서 및 링크 </div>
                            <div v-for="x in item.documents" :key="x.id">
                                <div v-if="hashed_docs[x].type!='link'" class="mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center">
                                    <a class=" cursor" :href="$store.state.static_host+hashed_docs[x].path" target="_blank"><fa icon="file-alt" class="mr-1"/> {{ hashed_docs[x].title }} </a>  
                                    <div style="min-width: 70px;">
                                        <b-button variant="light" :disabled="is_unlinked===true " class="py-1 px-2" v-b-tooltip.hover title="편집" @click.stop.prevent="addDocuments({gri_code: item.gri_code, document_id: x, type: hashed_docs[x].type})"><fa icon="pen"/></b-button>
                                        <b-button variant="light" :disabled="is_unlinked===true " class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click.stop.prevent="handleUnlink({gri_code: item.gri_code, document_id: x})"><fa icon="unlink"/></b-button>
                                    </div>
                                </div>                                                         
                            </div>
                            <div v-for="y in item.documents" :key="y.id">
                                <div v-if="hashed_docs[y].type=='link'" class="mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center">
                                    <a class=" cursor" :href="hashed_docs[y].link" target="_blank"><fa icon="external-link-square-alt" class="mr-1"/> {{ hashed_docs[y].title }} </a>  
                                    <div style="min-width: 70px;">
                                        <b-button variant="light" :disabled="is_unlinked===true " class="py-1 px-2" v-b-tooltip.hover title="편집" @click.stop.prevent="addDocuments({gri_code: item.gri_code, document_id: y, type: hashed_docs[y].type})"><fa icon="pen"/></b-button>
                                        <b-button variant="light" :disabled="is_unlinked===true " class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click.stop.prevent="handleUnlink({gri_code: item.gri_code, document_id: y})"><fa icon="unlink"/></b-button>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="mb-5 px-3" v-if="item.business_cases.length>0">
                            <div class="border-bottom-gray-550 gray-600 f-90 pb-1"><fa icon="quote-left" class="mr-1"/> 비즈니스 사례</div>
                            <div v-for="bc in item.business_cases" :key="bc.id">
                                <div class="mb-2 mt-2 fw-500 hover-gray d-md-flex justify-content-md-between align-items-center">
                                    <a class=" cursor" v-b-modal="`bc_${bc}${item.gri_code}`">{{ hashed_business_cases[bc].title }} <fa icon="expand" class="ml-1"/></a>
                                    <div style="min-width: 70px;">
                                        <b-button variant="light" :disabled="is_unlinked===true " class="py-1 px-2" v-b-tooltip.hover title="편집" @click.stop.prevent="addBusinessCases({gri_code: item.gri_code, business_case_id: bc})"><fa icon="pen"/></b-button>
                                        <b-button variant="light" :disabled="is_unlinked===true " class="ml-1 py-1 px-2" v-b-tooltip.hover title="없애기" @click.stop.prevent="handleUnlink({gri_code: item.gri_code, business_case_id: bc})"><fa icon="unlink"/></b-button>
                                    </div>
                                </div>
                                <b-modal :id="`bc_${bc}${item.gri_code}`" hide-footer size="xl">
                                    <template #modal-title>
                                        <b-badge variant="basic" class="mr-2">Case.</b-badge> {{ hashed_business_cases[bc].title }}
                                    </template>
                                    <simple-business-case :business_case_id="bc"></simple-business-case>
                                </b-modal>
                            </div>

                        </section>
                        <section class="mb-5 px-3" v-if="item.charts.length >0">
                            <div class="border-bottom-gray-550 gray-600 f-90 pb-1"><b-icon icon="bar-chart-fill" class="mr-1" /> 차트 </div>
                            <div v-for="chart_id in item.charts" :key="'chart_' + chart_id">
                                <ChartTextCanvas 
                                    :is_able_to_edit="selected_report.is_able_to_edit"
                                    :chart_id="chart_id"
                                    :editing_gri_code="item.gri_code"
                                    :selected_chart_list="item.charts"
                                /> 
                            </div>
                        </section>
                        <!-- 추가하기 버튼 데이터 테이블, 문서 및 링크, 비즈니스 사레 -->
                        <div :class="selected_report.is_able_to_edit === true? 'hover-border' : ''">
                            <b-button variant="light" :disabled="is_unlinked===true " class="py-1 px-2 mr-1" v-b-tooltip.hover.bottom title="데이터 테이블 추가" @click.stop.prevent="addTables({gri_code: item.gri_code})"><fa icon="table"/></b-button>  
                            <b-button variant="light" :disabled="is_unlinked===true " class="py-1 px-2 mr-1" v-b-tooltip.hover.bottom title="문서 및 링크 추가" @click.stop.prevent="addDocuments({gri_code: item.gri_code})"><fa icon="paperclip"/></b-button>                   
                            <b-button variant="light" :disabled="is_unlinked===true " class="py-1 px-2 mr-1" v-b-tooltip.hover.bottom title="비즈니스 사례 추가" @click.stop.prevent="addBusinessCases({gri_code: item.gri_code})"><fa icon="quote-left"/></b-button>                   
                            <ChartBtn
                                :is_able_to_edit="selected_report.is_able_to_edit"
                                :editing_gri_code="item.gri_code"
                                :selected_chart_list="item.charts"
                            />
                        </div>
                        
                    </b-overlay>
                    <div :class="selected_report.is_able_to_edit === true? 'hover-border' : ''">
                        <DisclosureNaN
                            :nan_category = item.nan_category
                            :nan_reason_contents = item.nan_reason_contents
                            :gri_code = item.gri_code
                            :disclosure_id="item.disclosure_id"
                        />
                    </div>

                    </b-td>
                    <b-td class="bgray-100" style="padding-left:0.75rem;">
                        <div class="d-flex justify-content-between f-90 mb-2"> <p class="gray-600 mb-0"> {{ show_example[item.gri_code]!=true? '다음의 내용을 보고해야 합니다':'작성 예시'}} : </p>
                            <a @click.stop.prevent="showExample(item.gri_code)" class="cursor"> {{ show_example[item.gri_code]!=true?'예시보기':'예시닫기'}} <fa :icon="show_example[item.gri_code]!=true?'expand':'compress-alt'"/></a>
                        </div>
                        <div v-if="show_example[item.gri_code]!=true" class="bg-white px-2 py-2" >
                            <p class="mb-1 f-90 gray-600"> <md :contents="item.indicator_requirement"></md></p>
                        </div> 
                        <div v-else class="px-2 py-2">
                            <example :gri_code="item.gri_code"></example>
                        </div>
                        
                        <GPTForm
                            :indicator_requirement="item.indicator_requirement" 
                            :user_writed="item.direct_report"

                        />
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>

</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Example from '@/components/writing_report/write_report_subs/Example'
import SimpleBusinessCase from '@/components/writing_report/business_case_subs/SimpleBusinessCase'
import { gri_mixin } from './gri_mixin'
import { formattedText } from '@/components/util'

import GPTForm from '../../helper_with_gpt/GPTForm.vue'
import DisclosureNaN from '@/components/writing_report/write_report_subs/DisclosureNaN.vue'
import ChartBtn from "@/components/writing_report/chart_subs/ChartBtn";
import ChartTextCanvas from "@/components/writing_report/chart_subs/ChartTextCanvas.vue";


export default {
    mixins: [gri_mixin],
    data() {
        return {
            is_writing_direct : {},
            save_state:{},
            show_example: {}
        }
    },    
    computed:{
        ...mapGetters('report_contents', ['gri_200_all_contents']),
        gri_200_topics(){
            let topics = []
            for(let i=0; i<this.gri_200_all_contents.length; i++){
                let new_topics = {
                    topic_id: this.gri_200_all_contents[i].topic_id, 
                    topic_name: this.gri_200_all_contents[i].topic_name,
                    topic_name_e: this.gri_200_all_contents[i].topic_name_e,
                    topic_publication_date: this.gri_200_all_contents[i].topic_publication_date,
                }
                let idx = topics.findIndex(x => x.topic_id == new_topics.topic_id)
                if (idx == -1){
                    topics.push(new_topics)
                }
            }
            return topics
        },
        series_contents(){
            let temp_obj = {}
                let temp_list = []
                for (let w=0; w<this.gri_200_topics.length; w++){
                    temp_list = this.gri_200_all_contents.filter(x => x.topic_id == this.gri_200_topics[w].topic_id)                    
                    this.$set(temp_obj, this.gri_200_topics[w].topic_name, temp_list)
                }
            // console.log(temp_obj);
            return temp_obj
        }
    },
    methods: {
        formattedText,
        ...mapActions('report_contents', ['saveDisclosure', 'saveNaNDisclosure']),
        ...mapMutations('cursor', ['update_editing_gri_code']),        
        async textInputSave(gri_code, txt) {
            if (txt != null){
                this.$set( this.save_state, gri_code, 'saving')
                await this.saveDisclosure({report_code: this.report_code, gri_code: gri_code, direct_report: txt })
                this.initiateWritingMode()
                this.initiateSavingState()
            }
        },
        initiateWritingMode(){
            this.is_writing_direct = {}
            let temp_200_contents = [...this.gri_200_all_contents]
            for (let h=0; h<temp_200_contents.length; h++){
                this.$set(this.is_writing_direct, temp_200_contents, false)
            }
        },
        showExample(gri_code){
            if(this.show_example[gri_code]==false){
                this.show_example[gri_code]=true
            }else this.show_example[gri_code]=false
        },
        initiateShowExample(){
            this.show_example = {}
            for (let i=0; i<this.gri_200_all_contents.length; i++){
                this.$set( this.show_example, this.gri_200_all_contents[i].gri_code, false)
            }
        },
        initiateSavingState(){
            this.save_state = {}
            for (let i=0; i<this.gri_200_all_contents.length; i++){
                if(this.gri_200_all_contents[i].direct_report != ''&&this.gri_200_all_contents[i].direct_report != undefined) {
                    this.$set( this.save_state, this.gri_200_all_contents[i].gri_code, 'saved')
                }
                else this.$set( this.save_state, this.gri_200_all_contents[i].gri_code, '')
            }       
        }
    },
    created(){
        this.initiateShowExample()
        this.initiateSavingState()
    },
    components:{
        Example,
        SimpleBusinessCase,
        DisclosureNaN,
        GPTForm,
        ChartBtn,
        ChartTextCanvas
    },
    beforeDestroy(){
        this.update_editing_gri_code('')
    },
    watch:{
        gri_200_all_contents:{
            immediate: true,
            handler(newV){
                if(newV){
                    // console.log(newV);
                    // console.log(this.save_state);
                    if (Object.keys(this.save_state).length==0){
                        this.initiateSavingState()
                    }                    
                }
            }
        }
    }
}
</script>

<style>

</style>
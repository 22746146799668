<template>
  <div
    class="mb-5"
  >
    <!-- <b-form-checkbox v-model="sector_selected">
      {{ sasb_sector_name }}
    </b-form-checkbox> -->

    <h6 :id="sasb_sector_id" class="fw-600 teal8 mb-3 bgray-200 hover-bg d-flex justify-content-between px-3"
      v-b-toggle="`toggle`+sasb_sector_id">
        {{ sasb_sector_name }}
        <div><fa icon="angle-up"></fa> </div>
    </h6>

    <b-collapse visible :id="`toggle`+sasb_sector_id">
      <div
        v-for="(industryInfo, child_idx) in industries"
        :item="industryInfo"
        :key="child_idx"
        class="mb-2"
      >
        <SasbIndustrySelector
          :sasb_industry_id="industryInfo.sasb_industry_id"
          :sasb_industry_name="industryInfo.sasb_industry_name"
          :sasb_industry_name_e="industryInfo.sasb_industry_name_e"
          :sasb_industry_checked="industryInfo.checked"
        />
      </div>
    </b-collapse>
  </div>
</template>
<script>
import SasbIndustrySelector from "@/components/managing_indicators/sasb_2023/sasb_selector/SasbIndustrySelector.vue";

export default {
  name: "",
  props: {
    sasb_sector_id: { type: Number },
    sasb_sector_name: { type: String },
    sasb_sector_name_e: { type: String },
    industries: { type: Array },
  },
  components: {
    SasbIndustrySelector,
  },
  data() {
    return {
      sector_selected: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  watch: {},
  methods: {
  },
};
</script>
<style></style>

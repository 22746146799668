export const multiChart = [
  {
    name: "E_온실가스배출량",
    label: "E_온실가스배출량",
    formData: {
      "type": "single",
      "footnote": null,
      "y_min": "0",
      "y_max": "12",
      "y1_min": "0",
      "y1_max": "4.5",
      "y_stepSize": null,
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "bottom",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 3,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": true,
      "chart_main_title": "온실가스 배출량",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y1",
          "type": "line",
          "label": "총 온실가스 배출량 (Scope 1&2) 원단위",
          "data": [
            "4",
            "2",
            "1"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": "4",
          "backgroundColor": "#FFE35FFF",
          "borderColor": "#FFE35FFF",
          "pointStyle": "line",
          "y_level": "tCO2eq/억원",
          "radius": "5",
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "직접 온실가스 배출량 (Scope 1)",
          "data": [
            "10",
            "10",
            "10"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#3876FF29",
          "borderColor": "#3876FFB5",
          "pointStyle": "circle",
          "y_level": "tCO2eq",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "간접 온실가스 배출량 (Scope 2)",
          "data": [
            "5",
            "5",
            "5"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#FFA43E45",
          "borderColor": "#FFA43EC9",
          "pointStyle": "circle",
          "y_level": "tCO2eq",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "기타 간접 온실가스 배출량 (Scope 3)",
          "data": [
            "8",
            "8",
            "8"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#8483832B",
          "borderColor": "#848383D6",
          "pointStyle": "circle",
          "y_level": "tCO2eq",
          "radius": 30,
          "tension": 0.4
        }
      ]
    }
  },

  {
    name: "E_에너지 사용량",
    label: "E_에너지 사용량",
    formData: {
      "type": "single",
      "footnote": "",
      "y_min": "0",
      "y_max": "12",
      "y1_min": "0",
      "y1_max": "4.5",
      "y_stepSize": null,
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "bottom",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 2,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": true,
      "chart_main_title": "에너지 사용량",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y1",
          "type": "line",
          "label": "총 에너지 사용량 원단위",
          "data": [
            "4",
            "2",
            "1"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#F7850257",
          "borderColor": "#F78502FF",
          "pointStyle": "dash",
          "y_level": "MJ/억원",
          "radius": "4",
          "tension": "0"
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "총 에너지 사용량",
          "data": [
            "10",
            "9",
            "11"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#3359FFFF",
          "borderColor": "#3359FF29",
          "pointStyle": "circle",
          "y_level": "MJ",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "재생에너지 사용량",
          "data": [
            "3",
            "3",
            "4"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#C6C6C6FF",
          "borderColor": "#C6C6C638",
          "pointStyle": "circle",
          "y_level": "MJ",
          "radius": 30,
          "tension": 0.4
        }
      ]
    }
  },

  {
    name: "E_용수취수량",
    label: "E_용수취수량",
    formData: {
      "type": "single",
      "footnote": "",
      "y_min": "10.5",
      "y_max": "12.5",
      "y1_min": "0",
      "y1_max": "5",
      "y_stepSize": null,
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "bottom",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 1,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": true,
      "chart_main_title": "용수 취수량",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y1",
          "type": "line",
          "label": "용수 취수량 원단위",
          "data": [
            "4",
            "2",
            "1"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#FFB300FF",
          "borderColor": "#FFB300FF",
          "pointStyle": "star",
          "y_level": "톤/억원",
          "radius": "5",
          "tension": "0"
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "용수 취수량",
          "data": [
            "12",
            "11",
            "12"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#5F6DFFFF",
          "borderColor": "#5F6DFF6B",
          "pointStyle": "circle",
          "y_level": "톤",
          "radius": 30,
          "tension": 0.4
        }
      ]
    }
  },

  {
    name: "E_총대기오염물질배출량",
    label: "E_총대기오염물질배출량",
    formData: {
      "type": "single",
      "footnote": null,
      "y_min": null,
      "y_max": null,
      "y1_min": null,
      "y1_max": null,
      "y_stepSize": 2,
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "top",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 0,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": false,
      "chart_main_title": "대기오염물질 배출량",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "총 대기오염물질 배출량",
          "data": [
            "8",
            "7",
            "9"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": "50",
          "borderWidth": "2",
          "backgroundColor": "#FFA310FF",
          "borderColor": "#FFA310FF",
          "pointStyle": "circle",
          "y_level": "톤",
          "radius": 30,
          "tension": 0.4
        }
      ]
    }
  },

  {
    name: "E_폐기물 발생량 및 재활용률",
    label: "E_폐기물 발생량 및 재활용률",
    formData: {
      "type": "single",
      "footnote": null,
      "y_min": null,
      "y_max": "6",
      "y1_min": "90",
      "y1_max": "100",
      "y_stepSize": null,
      "x_stacked": true,
      "y_stacked": true,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "bottom",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 2,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": true,
      "chart_main_title": "폐기물 발생량 및 재활용률",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y1",
          "type": "line",
          "label": "총 폐기물 재활용률",
          "data": [
            "93",
            "95",
            "99"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#B2B2B2EB",
          "borderColor": "#B2B2B2E3",
          "pointStyle": "circle",
          "y_level": "%",
          "radius": "5",
          "tension": "0"
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "일반폐기물 발생량",
          "data": [
            "2",
            "3",
            "2"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": "50",
          "borderWidth": 2,
          "backgroundColor": "#1711FFDE",
          "borderColor": "#1711FFDE",
          "pointStyle": "circle",
          "y_level": "톤",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "지정폐기물 발생량",
          "data": [
            "1",
            "2",
            "1"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": "50",
          "borderWidth": 2,
          "backgroundColor": "#FF8C1ADE",
          "borderColor": "#FF8C1ADE",
          "pointStyle": "circle",
          "y_level": "",
          "radius": 30,
          "tension": 0.4
        }
      ],
      "y1_stepSize": "1"
    }
  },





  {
    name: "S_구성원",
    label: "S_구성원",
    formData: {
      "type": "single",
      "footnote": null,
      "y_min": "0",
      "y_max": "120",
      "y1_min": null,
      "y1_max": null,
      "y_stepSize": "20",
      "x_stacked": true,
      "y_stacked": true,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "bottom",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 1,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": false,
      "chart_main_title": "구성원 현황",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "남성 구성원 수",
          "data": [
            "60",
            "65",
            "65"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": "60",
          "borderWidth": 2,
          "backgroundColor": "#FFB222F7",
          "borderColor": "#FFB222F7",
          "pointStyle": "circle",
          "y_level": "",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "여성 구성원 수",
          "data": [
            "40",
            "42",
            "43"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": "60",
          "borderWidth": 2,
          "backgroundColor": "#1200FFD1",
          "borderColor": "#1200FFD1",
          "pointStyle": "circle",
          "y_level": "",
          "radius": 30,
          "tension": 0.4
        }
      ],
      "y1_stepSize": "1"
    }
  },


  {
    name: "S_다양성",
    label: "S_다양성",
    formData: {
      "type": "single",
      "footnote": null,
      "y_min": "0",
      "y_max": "50",
      "y1_min": null,
      "y1_max": null,
      "y_stepSize": "10",
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "top",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 2,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": false,
      "chart_main_title": "구성원 다양성 현황",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y",
          "type": "line",
          "label": "여성 관리직(직책자) 비율",
          "data": [
            "30",
            "40",
            "35"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#705FFFFF",
          "borderColor": "#705FFFFF",
          "pointStyle": "circle",
          "y_level": "%",
          "radius": "3",
          "tension": "0"
        },
        {
          "yAxisID": "y",
          "type": "line",
          "label": "주요 매출 발생 부서 여성 비율",
          "data": [
            "20",
            "15",
            "20"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#FF6608D4",
          "borderColor": "#FF6608D4",
          "pointStyle": "circle",
          "y_level": "%",
          "radius": "3",
          "tension": "0"
        },
        {
          "yAxisID": "y",
          "type": "line",
          "label": "장애인 구성원 비율",
          "data": [
            "3",
            "2",
            "2"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#B3B3B3F2",
          "borderColor": "#B3B3B3F2",
          "pointStyle": "circle",
          "y_level": "%",
          "radius": "3",
          "tension": "0"
        }
      ],
      "y1_stepSize": "1"
    }
  },

  {
    name: "S_동등 급여",
    label: "S_동등 급여",
    formData: {
      "type": "single",
      "footnote": null,
      "y_min": "78",
      "y_max": "92",
      "y1_min": null,
      "y1_max": null,
      "y_stepSize": "2",
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "bottom",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 0,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": true,
      "chart_main_title": "동등 급여",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "남성 기본급 대비 여성 기본급 비율",
          "data": [
            "83",
            "85",
            "90"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": "55",
          "borderWidth": 2,
          "backgroundColor": "#5F61FFDE",
          "borderColor": "#5F61FFDE",
          "pointStyle": "circle",
          "y_level": "",
          "radius": 30,
          "tension": 0.4
        }
      ],
      "y1_stepSize": "1"
    }
  },

  {
    name: "S_안전보건",
    label: "S_안전보건",
    formData: {
      "type": "single",
      "footnote": null,
      "y_min": "0",
      "y_max": "0.25",
      "y1_min": null,
      "y1_max": null,
      "y_stepSize": "0.05",
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "bottom",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 1,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": false,
      "chart_main_title": "안전보건 관리 지표(구성원, 협력사)",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "구성원 - 근로손실재해율",
          "data": [
            "0.2",
            "0.15",
            "0.1"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#FFCC74FF",
          "borderColor": "#FFCC74FF",
          "pointStyle": "circle",
          "y_level": "LTIR",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "협력사 - 근로손실재해율",
          "data": [
            "0.1",
            "0.12",
            "0.1"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#C2C2C2FC",
          "borderColor": "#C2C2C2FC",
          "pointStyle": "circle",
          "y_level": "LTIR",
          "radius": 30,
          "tension": 0.4
        }
      ],
      "y1_stepSize": "1"
    }
  },

  {
    name: "S_공급망",
    label: "S_공급망",
    formData: {
      "type": "single",
      "footnote": null,
      "y_min": "0",
      "y_max": "120",
      "y1_min": null,
      "y1_max": null,
      "y_stepSize": "20",
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "bottom",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 4,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": false,
      "chart_main_title": "공급망 ESG 평가",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y",
          "type": "line",
          "label": "ESG 역량 강화를 지원한 협력사 ",
          "data": [
            "2",
            "10",
            "20"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#099CFFDE",
          "borderColor": "#099CFFDE",
          "pointStyle": "circle",
          "y_level": "개",
          "radius": "3",
          "tension": "0"
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "공급망 ESG 평가 대상 협력사 수",
          "data": [
            "100",
            "105",
            "110"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#1243FFDB",
          "borderColor": "#1243FFDB",
          "pointStyle": "circle",
          "y_level": "",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "공급망 ESG 평가에 참여한 협력사수",
          "data": [
            "20",
            "30",
            "40"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#FF7119FC",
          "borderColor": "#FF7119FC",
          "pointStyle": "circle",
          "y_level": "",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "평가 결과에 따라 리스크가 도출된 협력사수",
          "data": [
            "10",
            "8",
            "12"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#A6A6A6F2",
          "borderColor": "#A6A6A6F2",
          "pointStyle": "circle",
          "y_level": "",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "리스크 도출된 협력사 중 개선계획 수립/이행한 협력사 수",
          "data": [
            "10",
            "8",
            "12"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#FFDA11FF",
          "borderColor": "#FFDA11FF",
          "pointStyle": "circle",
          "y_level": "",
          "radius": 30,
          "tension": 0.4
        }
      ],
      "y1_stepSize": "1"
    }
  },

  {
    name: "S_지역사회",
    label: "S_지역사회",
    formData: {
      "type": "single",
      "footnote": null,
      "y_min": null,
      "y_max": null,
      "y1_min": "0",
      "y1_max": "1600",
      "y_stepSize": "200",
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "bottom",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 1,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": false,
      "chart_main_title": "사회공헌 성과",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "사회공헌 투자 비용",
          "data": [
            "500",
            "550",
            "530"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": "30",
          "borderWidth": 2,
          "backgroundColor": "#3798FFF2",
          "borderColor": "#3798FFF2",
          "pointStyle": "circle",
          "y_level": "",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "구성원 자원봉사 참여 시간",
          "data": [
            "1200",
            "1000",
            "1500"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": "30",
          "borderWidth": 2,
          "backgroundColor": "#FFAF5FFF",
          "borderColor": "#FFAF5FFF",
          "pointStyle": "circle",
          "y_level": "",
          "radius": 30,
          "tension": 0.4
        }
      ],
      "y1_stepSize": "1"
    }
  },
  {
    name: "S_정보보안",
    label: "S_정보보안",
    formData: {
      "type": "single",
      "footnote": null,
      "y_min": "12",
      "y_max": "18",
      "y1_min": "12",
      "y1_max": "18",
      "y_stepSize": "1",
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "bottom",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 0,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": false,
      "chart_main_title": "정보보안",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y",
          "type": "line",
          "label": "정보보안 투자 비율",
          "data": [
            "14",
            "16",
            "17"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#FFBC5FFA",
          "borderColor": "#FFBC5FFA",
          "pointStyle": "circle",
          "y_level": "%",
          "radius": "2",
          "tension": "0"
        }
      ],
      "y1_stepSize": "1"
    }
  },

  {
    name: "G_이사회",
    label: "G_이사회",
    formData: {
      "type": "single",
      "footnote": null,
      "y_min": "0",
      "y_max": "3.5",
      "y1_min": "87",
      "y1_max": "96",
      "y_stepSize": "0.5",
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "bottom",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 3,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": true,
      "chart_main_title": "이사회 구성 및 운영",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y1",
          "type": "line",
          "label": "이사회 참석률",
          "data": [
            "90",
            "90",
            "95"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": "30",
          "borderWidth": "3",
          "backgroundColor": "#FFE300FF",
          "borderColor": "#FFE300FF",
          "pointStyle": "circle",
          "y_level": "%",
          "radius": "3",
          "tension": "0"
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "사내이사",
          "data": [
            "2",
            "2",
            "2"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#72C5FFFF",
          "borderColor": "#72C5FFFF",
          "pointStyle": "circle",
          "y_level": "명",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "사외이사",
          "data": [
            "2",
            "3",
            "3"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#FF9A23FF",
          "borderColor": "#FF9A23FF",
          "pointStyle": "circle",
          "y_level": "명",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "기타비상무이사",
          "data": [
            "1",
            "1",
            "1"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#D3D3D3FF",
          "borderColor": "#D3D3D3FF",
          "pointStyle": "circle",
          "y_level": "명",
          "radius": 30,
          "tension": 0.4
        }
      ],
      "y1_stepSize": "1"
    }
  },

  {
    name: "G_윤리경영",
    label: "G_윤리경영",
    formData: {
      "type": "single",
      "footnote": null,
      "y_min": "0",
      "y_max": "14",
      "y1_min": "0",
      "y1_max": "120",
      "y_stepSize": "2",
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "chart_type": "bar",
      "legend_position": "bottom",
      "show_legend": true,
      "x_cnt": 2,
      "data_cnt": 2,
      "show_x_axis": true,
      "show_y_axis": true,
      "show_y1_axis": true,
      "chart_main_title": "윤리제보 현황",
      "chart_sub_title": "",
      "x_name": "",
      "y_name": "",
      "header": [
        "2021",
        "2022",
        "2023"
      ],
      "data_set_list": [
        {
          "yAxisID": "y1",
          "type": "line",
          "label": "윤리제보 처리율",
          "data": [
            "80",
            "83.33",
            "100"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#B7B7B7FF",
          "borderColor": "#B7B7B7FF",
          "pointStyle": "circle",
          "y_level": "윤리제보 처리 건수",
          "radius": "2",
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "윤리제보 접수 건수",
          "data": [
            "10",
            "12",
            "11"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#53C0FFFF",
          "borderColor": "#53C0FFFF",
          "pointStyle": "circle",
          "y_level": "",
          "radius": 30,
          "tension": 0.4
        },
        {
          "yAxisID": "y",
          "type": "bar",
          "label": "윤리제보 처리 건수",
          "data": [
            "8",
            "10",
            "11"
          ],
          "fill": false,
          "borderRadius": 0,
          "barThickness": 30,
          "borderWidth": 2,
          "backgroundColor": "#FFAB6AFF",
          "borderColor": "#FFAB6AFF",
          "pointStyle": "circle",
          "y_level": "",
          "radius": 30,
          "tension": 0.4
        }
      ],
      "y1_stepSize": "20"
    }
  },

  {
    name: "G_재무실적",
    label: "G_재무실적",
    formData: {}
  },

  {
    name: "G_주주",
    label: "G_주주",
    formData: {}
  },


  {
    name: "multiBarSample",
    label: "막대차트",
    formData: {
      y_min: null,
      y_max: null,
      y_stepSize: null,
      x_stacked: false,
      y_stacked: false,

      x_axis: true,
      chart_type: "bar",
      legend_position: "bottom",
      show_legend: true,
      x_cnt: 2,
      data_cnt: 2,
      show_x_axis: true,
      show_y_axis: true,
     "show_y1_axis": true,
      chart_main_title: "막대차트 예시",
      chart_sub_title: "여러 비교군과 함께",
      x_name: "x축 이름입니다",
      y_name: "y축 이름입니다",
      header: ["전체", "비정규직", "정규직"],
      data_set_list: [
        {
          type: "bar",
          label: "A기업",
          data: ["43", "25", "32"],
          fill: false,
          borderRadius: 30,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: "#5FFFBC4D",
          borderColor: "#5FFFBC",
          pointStyle: "circle",
          y_level: "명",
          radius: 200,
          tension: 0.4,
        },
        {
          type: "bar",
          label: "B기업",
          data: ["54", "65", "65"],
          fill: false,
          borderRadius: 0,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: "#FC53534D",
          borderColor: "#FC5353E3",
          pointStyle: "circle",
          y_level: "명",
          radius: 200,
          tension: 0.4,
        },
        {
          type: "bar",
          label: "C기업",
          data: ["47", "52", "46"],
          fill: false,
          borderRadius: 0,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: "#8451F94D",
          borderColor: "#8451F9D4",
          pointStyle: "circle",
          y_level: "명",
          radius: 200,
          tension: 0.4,
        },
      ],
    },
  },
  {
    name: "stackedBarSample",
    label: "누적막대차트",
    formData: {
      y_min: null,
      y_max: null,
      y_stepSize: null,
      x_stacked: true,
      y_stacked: true,
      x_axis: true,
      chart_type: "bar",
      legend_position: "bottom",
      show_legend: true,
      x_cnt: 2,
      data_cnt: 2,
      show_x_axis: true,
      show_y_axis: true,
     "show_y1_axis": true,
      chart_main_title: "막대차트 예시",
      chart_sub_title: "여러 비교군과 함께",
      x_name: "x축 이름입니다",
      y_name: "y축 이름입니다",
      header: ["전체", "비정규직", "정규직"],
      data_set_list: [
        {
          type: "bar",
          label: "A기업",
          data: ["43", "25", "32"],
          fill: false,
          borderRadius: 0,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: "#9FFF8FFF",
          borderColor: "#9FFF8F42",
          pointStyle: "circle",
          y_level: "명",
          radius: 200,
          tension: 0.4,
        },
        {
          type: "bar",
          label: "B기업",
          data: ["54", "65", "65"],
          fill: false,
          borderRadius: 0,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: "#00DAFFFF",
          borderColor: "#00DAFF5C",
          pointStyle: "circle",
          y_level: "명",
          radius: 200,
          tension: 0.4,
        },
        {
          type: "bar",
          label: "C기업",
          data: ["47", "52", "46"],
          fill: false,
          borderRadius: 0,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: "#D8C7FFFF",
          borderColor: "#D8C7FF4D",
          pointStyle: "circle",
          y_level: "명",
          radius: 200,
          tension: 0.4,
        },
      ],
    },
  },
  {
    name: "multiLineSample",
    label: "라인차트",
    formData: {
      y_min: null,
      y_max: null,
      y_stepSize: null,
      x_stacked: false,
      y_stacked: false,

      x_axis: true,
      chart_type: "bar",
      legend_position: "top",
      show_legend: true,
      x_cnt: 6,
      data_cnt: 2,
      show_x_axis: true,
      show_y_axis: true,
     "show_y1_axis": true,
      chart_main_title: "라인차트",
      chart_sub_title: "라인차트예시",
      x_name: "x축예시",
      y_name: "y축예시",
      header: ["1월", "2월", "3월", "4월", "5월", "6월", "7월"],
      data_set_list: [
        {
          type: "line",
          label: "A기업",
          data: ["20", "40", "55", "33", "25", "60", "55"],
          fill: false,
          borderRadius: 0,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: "#5FFFBC4D",
          borderColor: "#5FFFBC",
          pointStyle: "circle",
          y_level: "명",
          radius: "5",
          tension: 0.3,
        },
        {
          type: "line",
          label: "B기업",
          data: ["10", "35", "23", "86", "75", "24", "15"],
          fill: false,
          borderRadius: 0,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: "#2E30FF4D",
          borderColor: "#2E30FFBA",
          pointStyle: "rectRounded",
          y_level: "명",
          radius: "5",
          tension: 0.3,
        },
        {
          type: "line",
          label: "C기업",
          data: ["54", "32", "87", "32", "17", "75", "21"],
          fill: false,
          borderRadius: 0,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: "#FF965F57",
          borderColor: "#FF965FFF",
          pointStyle: "star",
          y_level: "명",
          radius: "5",
          tension: 0.3,
        },
      ],
    },
  },
  {
    name: "multiLineSample",
    label: "라인막대차트",
    formData: {
      y_min: null,
      y_max: null,
      y_stepSize: null,
      x_stacked: false,
      y_stacked: false,

      x_axis: true,
      chart_type: "bar",
      legend_position: "top",
      show_legend: true,
      x_cnt: 6,
      data_cnt: 2,
      show_x_axis: true,
      show_y_axis: true,
     "show_y1_axis": true,
      chart_main_title: "라인차트",
      chart_sub_title: "라인차트예시",
      x_name: "x축예시",
      y_name: "y축예시",
      header: ["1월", "2월", "3월", "4월", "5월", "6월", "7월"],
      data_set_list: [
        {
          type: "bar",
          label: "A기업",
          data: ["20", "40", "55", "33", "25", "60", "55"],
          fill: false,
          borderRadius: 0,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: "#5FFFBC4D",
          borderColor: "#5FFFBC",
          pointStyle: "circle",
          y_level: "명",
          radius: "5",
          tension: 0.4,
        },
        {
          type: "line",
          label: "B기업",
          data: ["10", "35", "23", "86", "75", "24", "15"],
          fill: false,
          borderRadius: 0,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: "#2E30FF4D",
          borderColor: "#2E30FFBA",
          pointStyle: "rectRounded",
          y_level: "명",
          radius: "5",
          tension: 0.1,
        },
        {
          type: "line",
          label: "C기업",
          data: ["54", "32", "87", "32", "17", "75", "21"],
          fill: false,
          borderRadius: 0,
          barThickness: 30,
          borderWidth: 2,
          backgroundColor: "#FF965F57",
          borderColor: "#FF965FFF",
          pointStyle: "star",
          y_level: "명",
          radius: "5",
          tension: 0.1,
        },
      ],
    },
  },
];

export const singleChart = [
  {
    name: "G주주",
    label: "G주주",
    formData: {
      chart_type: "doughnut",
      "sample_type": "multi",
      "footnote": null,
      "y_min": null,
      "y_max": null,
      "y_stepSize": null,
      "x_stacked": false,
      "y_stacked": false,
      "x_axis": true,
      "legend_position": "top",
      "show_legend": true,
      "x_cnt": 3,
      "data_cnt": 0,
      "show_x_axis": false,
      "show_y_axis": false,
      "chart_main_title": "FY2023 주주 현황",
      "chart_sub_title": "",
      "y_level": "%",
      "x_name": "",
      "y_name": "",
      "header": [
        "주주A 지분율",
        "주주B 지분율",
        "주주C 지분율",
        "기타 지분율"
      ],
      "data_set": {
        "label": "지분율",
        "data": [
          "75",
          "13",
          "5",
          "5"
        ],
        "fill": false,
        "barThickness": 30,
        "borderWidth": 2,
        "backgroundColor": [
          "#1090FAFF",
          "#FF7D1FFF",
          "#B2B2B2FF",
          "#FFD72BFF"
        ],
        "borderColor": [
          "#1090FAFF",
          "#FF7D1FFF",
          "#B2B2B2FF",
          "#FFD72BFF"
        ],
        "pointStyle": "circle",
        "radius": "200",
        "tension": 0.4
      },
      "show_y1_axis": true
    }
  },


  {
    name: "singleBarSample",
    label: "파이차트",
    formData: {
      chart_type: "pie",
      x_axis: true,
      legend_position: "right",
      show_legend: true,
      x_cnt: 6,
      data_cnt: 0,
      show_x_axis: false,
      show_y_axis: false,
      show_y1_axis: true,
      chart_main_title: "차트",
      chart_sub_title: "차트예시",
      y_level: "명",
      x_name: "x축예시",
      y_name: "y축예시",
      header: ["1월", "2월", "3월", "4월", "4월", "6월", "7월"],
      data_set: {
        label: "A기업",
        data: ["43", "23", "34", "65", "43", "56", "43"],
        fill: false,
        borderRadius: 0,
        barThickness: 30,
        borderWidth: 2,
        backgroundColor: [
          "#0AFF9A4D",
          "#FF00002B",
          "#3D35FF47",
          "#49FF0054",
          "#A15AFF47",
          "#FFDB401C",
          "#DA2DFFFF",
          "#FFFFF",
        ],
        borderColor: [
          "#5FFFBC",
          "#FF0000FF",
          "#3D35FFFF",
          "#49FF00FF",
          "#A15AFFFF",
          "#FFDB40FF",
          "#DA2DFF26",
          "#FFFFF",
        ],
        pointStyle: "cross",
        radius: "180",
        tension: 0.4,
      },
    },
  },
  {
    name: "singleBarSample",
    label: "막대차트",
    formData: {
      chart_type: "bar",
      x_axis: true,
      legend_position: "right",
      show_legend: true,
      x_cnt: 6,
      data_cnt: 0,
      show_x_axis: true,
      show_y_axis: true,
      show_y1_axis: true,
      chart_main_title: "차트",
      chart_sub_title: "차트예시",
      y_level: "명",
      x_name: "x축예시",
      y_name: "y축예시",
      header: ["1월", "2월", "3월", "4월", "4월", "6월", "7월"],
      data_set: {
        label: "A기업",
        data: ["43", "23", "34", "65", "43", "56", "43"],
        fill: false,
        borderRadius: 0,
        barThickness: 30,
        borderWidth: 2,
        backgroundColor: [
          "#0AFF9A4D",
          "#FF00002B",
          "#3D35FF47",
          "#49FF0054",
          "#A15AFF47",
          "#FFDB401C",
          "#DA2DFFFF",
          "#FFFFF",
        ],
        borderColor: [
          "#5FFFBC",
          "#FF0000FF",
          "#3D35FFFF",
          "#49FF00FF",
          "#A15AFFFF",
          "#FFDB40FF",
          "#DA2DFF26",
          "#FFFFF",
        ],
        pointStyle: "cross",
        radius: "180",
        tension: 0.4,
      },
    },
  },
];

<template>
<div>

    <h5> ContractSheet Component에 내용 채워야 함 </h5>
    전자계약서 내용입니다 전자계약서 내용입니다 전자계약서 내용입니다<br>전자계약서 내용입니다 전자계약서 내용입니다 전자계약서 내용입니다<br>전자계약서 내용입니다 전자계약서 내용입니다 전자계약서 내용입니다<br>전자계약서 내용입니다 전자계약서 내용입니다 전자계약서 내용입니다<br>전자계약서 내용입니다 전자계약서 내용입니다 전자계약서 내용입니다<br>전자계약서 내용입니다 전자계약서 내용입니다 전자계약서 내용입니다<br>전자계약서 내용입니다 전자계약서 내용입니다 전자계약서 내용입니다<br>
</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
        return {
        }
    },
    computed:{
        ...mapState('assurance', ['assurance_list']),
        ...mapGetters('assurance', ['assurance_id'])

    },
    methods:{

    }
}
</script>

<style>

</style>